<template>
  <div v-if="isValidForGcs" class="col-md-12 noPadding bulmaStyles">
    <div class="buttonScreen">
      <button
        v-for="item in buttonList.filter((x) => x.isActive)"
        :key="item.tableName"
        class="btn btn-primary mr-1"
        :class="{ 'btn-success': activeItem == item.tableName }"
        @click="changeTab(item.tableName)"
      >
        {{ item.buttonName }}
      </button>
    </div>
    <div class="tableScreen pt-1">
      <div class="row">
        <div class="col-md-12">
          <button
            type="button"
            class="btn btn-info mb-1 mr-2 btn-sm float-left"
            v-show="
              activeItem === TABLE_TYPES.INFORMATION &&
              Customer.CustomerGlobalInformation.IsDpg == false &&
              this.checkAuth(54125)
            "
            @click="phonePayInfoButton"
          >
            Phone Pay Info
          </button>
          <button
            v-show="activeItem != TABLE_TYPES.STATEMENT"
            type="button"
            class="btn btn-info btn-sm float-left"
            @click="refreshGrid()"
            :disabled="isLoading"
            :style="isLoading ? 'opacity:0.5;' : 'opacity:1;'"
          >
            <i class="fe-refresh-cw mr-1"></i>
            Refresh
          </button>
        </div>
      </div>

      <div class="row">
        <div
          class="col-12 datagrid-table gcsTabsTableScroll"
          :style="isLoading ? 'opacity:0.5' : 'opacity:1;'"
        >
          <o-table
            v-if="activeItem === TABLE_TYPES.INFORMATION && infoData"
            :data="infoData"
            :bordered="isBordered"
            :striped="isStriped"
            :hoverable="isHoverable"
            :focusable="isFocusable"
          >
            <o-table-column
              field="CompareItem"
              label="Compare Item"
              v-slot="props"
              sortable
            >
              {{ props.row.CompareItem }}
            </o-table-column>

            <o-table-column
              field="CRMInformation"
              label="CRM Information"
              v-slot="props"
              sortable
            >
              <span
                :style="
                  props.row.CSMInformation != props.row.GCSInformation
                    ? 'color:#e5253b;'
                    : ''
                "
              >
                {{ props.row.CSMInformation }}
              </span>
            </o-table-column>

            <o-table-column
              field="GCSInformation"
              :label="
                Customer.CustomerGlobalInformation.IsDpg
                  ? 'DPG Information'
                  : 'Global Information'
              "
              v-slot="props"
              sortable
            >
              {{ props.row.GCSInformation }}
            </o-table-column>
          </o-table>
          <div>
            <o-table
              v-show="activeItem === TABLE_TYPES.NOTES && sortedNoteList"
              :data="sortedNoteList"
              :bordered="isBordered"
              :striped="isStriped"
              :hoverable="isHoverable"
              :focusable="isFocusable"
            >
              <o-table-column field="Note" label="Note" v-slot="props" sortable>
                {{ props.row.Note }}
              </o-table-column>

              <o-table-column
                field="CreatedDate"
                label="Created Date"
                v-slot="props"
                sortable
              >
                {{ formatGridDate(props.row.Date) }}
              </o-table-column>

              <o-table-column
                field="ModifiedDate"
                label="Modified Date"
                v-slot="props"
                sortable
              >
                {{ formatGridDate(props.row.ModifiedDate) }}
              </o-table-column>
              <o-table-column
                field="ModifiedBy"
                label="Modified By"
                v-slot="props"
                sortable
              >
                {{ props.row.ModifiedBy }}
              </o-table-column>
            </o-table>
          </div>

          <o-table
            v-show="activeItem === TABLE_TYPES.DRAFTS && draftList"
            :data="filteredDrafts"
            :bordered="isBordered"
            :striped="isStriped"
            :hoverable="isHoverable"
            :focusable="isFocusable"
          >
            <o-table-column field="" label="" width="1%">
              <template v-slot="props">
                <strong v-if="props.row.IsSummary"></strong>
                <span v-if="!props.row.IsSummary">
                  <div
                    style="width: 14px; height: 33px"
                    :style="
                      Customer.CustomerGlobalInformation.PaymentProcessorID == 3
                        ? getDynamicStyleForCFT(props.row.Status)
                        : getDynamicStyle(props.row.Status, props.row.NsfStatus)
                    "
                  ></div>
                </span>
              </template>
            </o-table-column>

            <o-table-column
              field="EffectiveDate"
              label="Effective Date"
              sortable
              searchable
              width="8%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="draftsEffectiveDateFilter"
                    placeholder=""
                  />
                </div>
              </template>
              <template v-slot="props">
                {{ formatShortDate(props.row.EffectiveDate) }}
              </template>
            </o-table-column>

            <o-table-column
              field="Amount"
              label="Amount"
              sortable
              searchable
              width="8%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="draftsAmountFilter"
                    placeholder=""
                  />
                </div>
              </template>
              <template v-slot="props">
                <strong v-if="props.row.IsSummary">
                  {{ props.row.Amount }}
                </strong>
                <span v-if="!props.row.IsSummary">
                  {{ formatMoney(props.row.Amount) }}
                </span>
              </template>
            </o-table-column>

            <o-table-column
              field="Status"
              label="Status"
              sortable
              searchable
              width="8%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="draftsStatusFilter"
                    placeholder=""
                  />
                </div>
              </template>
              <template v-slot="props">
                {{ props.row.Status }}
                {{
                  Customer.CustomerGlobalInformation.PaymentProcessorID != 3 &&
                  props.row.NsfStatus != null &&
                  props.row.NsfStatus != "" &&
                  props.row.Status != "Open"
                    ? "(" + props.row.NsfStatus + ")"
                    : ""
                }}
              </template>
            </o-table-column>

            <o-table-column
              field="Memo"
              label="Memo"
              sortable
              searchable
              width="8%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="draftsMemoFilter"
                    placeholder=""
                  />
                </div>
              </template>
              <template v-slot="props">
                {{ props.row.Memo }}
              </template>
            </o-table-column>
            <o-table-column
              field="Active"
              label="Active"
              sortable
              searchable
              width="3%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="draftsActiveFilter"
                    placeholder=""
                  />
                </div>
              </template>
              <template v-slot="props">
                {{ props.row.Active }}
              </template>
            </o-table-column>
            <o-table-column
              field="Remaining"
              label="Remaining"
              sortable
              searchable
              width="3%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="draftsRemainingFilter"
                    placeholder=""
                  />
                </div>
              </template>
              <template v-slot="props">
                <strong v-if="props.row.IsSummary">
                  {{ props.row.Remaining }}
                </strong>
                <span v-if="!props.row.IsSummary">
                  {{ props.row.Remaining }}
                </span>
              </template>
            </o-table-column>
            <o-table-column
              field="CreationDate"
              label="Creation Date"
              sortable
              searchable
              width="8%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="draftsCreationDateFilter"
                    placeholder=""
                  />
                </div>
              </template>
              <template v-slot="props">
                {{ formatShortDate(props.row.CreationDate) }}
              </template>
            </o-table-column>
            <o-table-column
              field="ModifiedDate"
              label="Modified Date"
              sortable
              searchable
              width="8%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="draftsModifiedDateFilter"
                    placeholder=""
                  />
                </div>
              </template>
              <template v-slot="props">
                {{ formatShortDate(props.row.ModifiedDate) }}
              </template>
            </o-table-column>
            <o-table-column
              field="ModifiedBy"
              label="Modified By"
              sortable
              searchable
              width="8%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="draftsModifiedByFilter"
                    placeholder=""
                  />
                </div>
              </template>
              <template v-slot="props">
                {{ props.row.ModifiedBy }}
              </template>
            </o-table-column>

            <section
              v-if="filteredDrafts.length == 0"
              class="section"
              style="text-align: center"
            >
              <div class="content has-text-grey has-text-centered mb-4">
                <p class="noMargin">
                  <i
                    style="font-size: 24px; color: #939393"
                    class="ri-question-line"
                  ></i>
                </p>
                <p class="noMargin">Nothing here.</p>
              </div>
            </section>
          </o-table>

          <o-table
            v-show="activeItem === TABLE_TYPES.FEES && feeList"
            :data="filteredFees"
            :bordered="isBordered"
            :striped="isStriped"
            :hoverable="isHoverable"
            :focusable="isFocusable"
          >
            <o-table-column
              field="EffectiveDate"
              label="Effective Date"
              sortable
              searchable
              width="10%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="feesEffectiveDateFilter"
                    placeholder=""
                  />
                </div>
              </template>
              <template v-slot="props">
                {{ formatGridDate(props.row.EffectiveDate) }}
              </template>
            </o-table-column>
            <o-table-column
              field="CreditorName"
              label="Payee"
              sortable
              searchable
              width="10%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="feesPayeeFilter"
                    placeholder=""
                  />
                </div>
              </template>
              <template v-slot="props">
                {{ props.row.CreditorName }}
              </template>
            </o-table-column>
            <o-table-column
              field="AccountNumber"
              label="Acct #"
              sortable
              searchable
              width="10%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="feesAccountNumberFilter"
                    placeholder=""
                  />
                </div>
              </template>
              <template v-slot="props">
                {{ props.row.AccountNumber }}
              </template>
            </o-table-column>

            <o-table-column
              field="Day"
              label="Day"
              sortable
              searchable
              width="5%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="feesDayFilter"
                    placeholder=""
                  />
                </div>
              </template>
              <template v-slot="props">
                {{ props.row.Day }}
              </template>
            </o-table-column>

            <o-table-column
              field="Type"
              label="Type"
              sortable
              searchable
              width="10%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="feesTypeFilter"
                    placeholder=""
                  />
                </div>
              </template>
              <template v-slot="props">
                {{ props.row.Type }}
              </template>
            </o-table-column>
            <o-table-column
              field="Occurs"
              label="Occurs"
              sortable
              searchable
              width="5%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="feesOccursFilter"
                    placeholder=""
                  />
                </div>
              </template>
              <template v-slot="props">
                {{ props.row.Occurs }}
              </template>
            </o-table-column>

            <o-table-column
              field="Amount"
              label="Amount"
              sortable
              searchable
              width="10%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="feesAmountFilter"
                    placeholder=""
                  />
                </div>
              </template>

              <template v-slot="props">
                <strong v-if="props.row.IsSummary">
                  {{ props.row.Amount }}
                </strong>
                <span v-if="!props.row.IsSummary">
                  {{ props.row.Amount }}
                </span>
              </template>
            </o-table-column>

            <o-table-column
              field="Memo"
              label="Memo"
              searchable
              sortable
              width="10%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="feesMemoFilter"
                    placeholder=""
                  />
                </div>
              </template>
              <template v-slot="props">
                {{ props.row.Memo }}
              </template>
            </o-table-column>

            <o-table-column
              field="Active"
              label="Active"
              sortable
              searchable
              width="5%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="feesActiveFilter"
                    placeholder=""
                  />
                </div>
              </template>
              <template v-slot="props">
                {{ props.row.Active }}
              </template>
            </o-table-column>

            <o-table-column
              field="Remaining"
              label="Remaining"
              searchable
              sortable
              width="10%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="feesRemainingFilter"
                    placeholder=""
                  />
                </div>
              </template>
              <template v-slot="props">
                <strong v-if="props.row.IsSummary">
                  {{ props.row.Remaining }}
                </strong>
                <span v-if="!props.row.IsSummary">
                  {{ props.row.Remaining }}
                </span>
              </template>
            </o-table-column>

            <o-table-column
              field="FeeStatus"
              label="Status"
              sortable
              searchable
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="feesStatusFilter"
                    placeholder=""
                  />
                </div>
              </template>
              <template v-slot="props">
                {{ props.row.FeeStatus }}
              </template>
            </o-table-column>

            <o-table-column
              field="CreationDate"
              label="Creation Date"
              sortable
              searchable
              width="10%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="feesCreationDateFilter"
                    placeholder=""
                  />
                </div>
              </template>
              <template v-slot="props">
                {{ formatGridDate(props.row.CreationDate) }}
              </template>
            </o-table-column>
            <o-table-column
              field="ModifiedDate"
              label="Modified Date"
              sortable
              searchable
              width="10%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="feesModifiedDateFilter"
                    placeholder=""
                  />
                </div>
              </template>
              <template v-slot="props">
                {{ formatGridDate(props.row.ModifiedDate) }}
              </template>
            </o-table-column>

            <o-table-column
              field="ModifiedBy"
              label="Modified By"
              sortable
              searchable
              width="10%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="feesModifiedByFilter"
                    placeholder=""
                  />
                </div>
              </template>
              <template v-slot="props">
                {{ props.row.ModifiedBy }}
              </template>
            </o-table-column>
          </o-table>

          <o-table
            v-show="activeItem === TABLE_TYPES.PAYMENTS && paymentList"
            :data="filteredPayments"
            :bordered="isBordered"
            :striped="isStriped"
            :hoverable="isHoverable"
            :focusable="isFocusable"
          >
            <o-table-column
              field="EffectiveDate"
              label="Effective Date"
              sortable
              searchable
              width="11%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="paymentsEffectiveDateFilter"
                    placeholder=""
                  />
                </div>
              </template>

              <template v-slot="props">
                <strong v-if="props.row.IsSummary"
                  >{{ props.row.EffectiveDate }}
                </strong>

                <span v-if="!props.row.IsSummary">
                  {{ formatGridDate(props.row.EffectiveDate) }}
                </span>
              </template>
            </o-table-column>

            <o-table-column
              field="Payee"
              label="Payee"
              sortable
              searchable
              width="10%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="paymentsPayeeFilter"
                    placeholder=""
                  />
                </div>
              </template>
              <template v-slot="props">
                {{ props.row.Payee }}
              </template>
            </o-table-column>

            <o-table-column
              field="AccountNumber"
              label="Acct #"
              sortable
              searchable
              width="6%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="paymentsAccountNumberFilter"
                    placeholder=""
                  />
                </div>
              </template>
              <template v-slot="props">
                {{ props.row.AccountNumber }}
              </template>
            </o-table-column>

            <o-table-column
              field="PaymentClass"
              label="Pmt Class"
              sortable
              searchable
              width="7%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="paymentsClassFilter"
                    placeholder=""
                  />
                </div>
              </template>
              <template v-slot="props">
                {{ props.row.PaymentClass }}
              </template>
            </o-table-column>

            <o-table-column
              field="PaymentType"
              label="Pmt Type"
              sortable
              searchable
              width="6%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="paymentsTypeFilter"
                    placeholder=""
                  />
                </div>
              </template>
              <template v-slot="props">
                {{ props.row.PaymentType }}
              </template>
            </o-table-column>

            <o-table-column
              field="PaymentAmount"
              label="Pmt Amt"
              sortable
              searchable
              width="10%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="paymentsAmountFilter"
                    placeholder=""
                  />
                </div>
              </template>
              <template v-slot="props">
                <strong v-if="props.row.IsSummary"
                  >{{ props.row.PaymentAmount }}
                </strong>
                <span v-if="!props.row.IsSummary">
                  {{ props.row.PaymentAmount }}
                </span>
              </template>
            </o-table-column>
            <o-table-column
              field="FeeAmount"
              label="Fee Amt"
              sortable
              searchable
              width="10%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="paymentsFeeAmountFilter"
                    placeholder=""
                  />
                </div>
              </template>
              <template v-slot="props">
                <strong v-if="props.row.IsSummary"
                  >{{ props.row.FeeAmount }}
                </strong>
                <span v-if="!props.row.IsSummary">
                  {{ props.row.FeeAmount }}
                </span>
              </template>
            </o-table-column>
            <o-table-column
              field="Active"
              label="Active"
              sortable
              searchable
              width="4%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="paymentsActiveFilter"
                    placeholder=""
                  />
                </div>
              </template>
              <template v-slot="props">
                <strong v-if="props.row.IsSummary"
                  >{{ props.row.Active }}
                </strong>
                <span v-if="!props.row.IsSummary">
                  {{ props.row.Active }}
                </span>
              </template>
            </o-table-column>
            <o-table-column
              field="PaymentStatus"
              label="Pmt Status"
              sortable
              searchable
              width="10%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="paymentsStatusFilter"
                    placeholder=""
                  />
                </div>
              </template>
              <template v-slot="props">
                {{ props.row.PaymentStatus }}
              </template>
            </o-table-column>
            <o-table-column
              field="MergedNumbers"
              label="Check/Tracking Number"
              sortable
              searchable
              width="10%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="mergedNumbersFilter"
                    placeholder=""
                  />
                </div>
              </template>

              <template v-slot="props">
                <strong v-if="props.row.IsSummary"
                  >{{ props.row.MergedNumbers }}
                </strong>
                <span v-if="!props.row.IsSummary">
                  {{ props.row.MergedNumbers }}
                  <button
                    v-if="props.row.TrackingNumber != ''"
                    class="btn btn-sm btn-info"
                    @click="openTrackingLink(props.row.TrackingNumber)"
                  >
                    <i class="fe-box"></i>
                  </button>
                </span>
              </template>
            </o-table-column>
            <o-table-column
              field="ClearedDate"
              label="Cleared Date"
              sortable
              searchable
              width="11%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="paymentsClearedDateFilter"
                    placeholder=""
                  />
                </div>
              </template>
              <template v-slot="props">
                {{ formatGridDate(props.row.ClearedDate) }}
              </template>
            </o-table-column>
            <o-table-column
              field="ModifiedBy"
              label="Modified By"
              sortable
              searchable
              width="10%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="paymentsModifiedByFilter"
                    placeholder=""
                  />
                </div>
              </template>
              <template v-slot="props">
                {{ props.row.ModifiedBy }}
              </template>
            </o-table-column>
          </o-table>
          <o-table
            v-show="activeItem === TABLE_TYPES.CHARGES && chargeList"
            :data="filteredCharges"
            :bordered="isBordered"
            :striped="isStriped"
            :hoverable="isHoverable"
            :focusable="isFocusable"
          >
            <o-table-column
              field="EffectiveDate"
              label="Effective Date"
              sortable
              searchable
              width="11%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="chargesEffectiveDateFilter"
                    placeholder=""
                  />
                </div>
              </template>

              <template v-slot="props">
                <strong v-if="props.row.IsSummary"
                  >{{ props.row.EffectiveDate }}
                </strong>

                <span v-if="!props.row.IsSummary">
                  {{ formatGridDate(props.row.EffectiveDate) }}
                </span>
              </template>
            </o-table-column>

            <o-table-column
              field="TransactionCombined"
              label="Transaction Class/Type"
              sortable
              searchable
              width="10%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="chargesTransactionFilter"
                    placeholder=""
                  />
                </div>
              </template>
              <template v-slot="props">
                {{ props.row.TransactionCombined }}
              </template>
            </o-table-column>

            <o-table-column
              field="Amount"
              label="Amount"
              sortable
              searchable
              width="7%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="chargesAmountFilter"
                    placeholder=""
                  />
                </div>
              </template>
              <template v-slot="props">
                <strong v-if="props.row.IsSummary"
                  >{{ props.row.Amount }}
                </strong>
                <span v-if="!props.row.IsSummary">
                  {{ props.row.Amount }}
                </span>
              </template>
            </o-table-column>

            <o-table-column
              field="Active"
              label="Active"
              sortable
              searchable
              width="6%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="chargesActiveFilter"
                    placeholder=""
                  />
                </div>
              </template>
              <template v-slot="props">
                {{ props.row.Active }}
              </template>
            </o-table-column>

            <o-table-column
              field="Status"
              label="Status"
              sortable
              searchable
              width="10%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="chargesStatusFilter"
                    placeholder=""
                  />
                </div>
              </template>
              <template v-slot="props">
                <strong v-if="props.row.IsSummary"
                  >{{ props.row.Status }}
                </strong>
                <span v-if="!props.row.IsSummary">
                  {{ props.row.Status }}
                </span>
              </template>
            </o-table-column>
            <o-table-column
              field="CreatedBy"
              label="Created By"
              sortable
              searchable
              width="10%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="chargesCreatedByFilter"
                    placeholder=""
                  />
                </div>
              </template>
              <template v-slot="props">
                <strong v-if="props.row.IsSummary"
                  >{{ props.row.CreatedBy }}
                </strong>
                <span v-if="!props.row.IsSummary">
                  {{ props.row.CreatedBy }}
                </span>
              </template>
            </o-table-column>
            <o-table-column
              field="CreatedDate"
              label="Created Date"
              sortable
              searchable
              width="10%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="chargesCreatedDateFilter"
                    placeholder=""
                  />
                </div>
              </template>
              <template v-slot="props">
                <strong v-if="props.row.IsSummary"
                  >{{ formatShortDate(props.row.CreatedDate) }}
                </strong>
                <span v-if="!props.row.IsSummary">
                  {{ formatShortDate(props.row.CreatedDate) }}
                </span>
              </template>
            </o-table-column>

            <o-table-column
              field="ModifiedBy"
              label="Modified By"
              sortable
              searchable
              width="10%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="chargesModifiedByFilter"
                    placeholder=""
                  />
                </div>
              </template>
              <template v-slot="props">
                {{ props.row.ModifiedBy }}
              </template>
            </o-table-column>
            <o-table-column
              field="ModifiedDate"
              label="Modified Date"
              sortable
              searchable
              width="10%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="chargesModifiedDateFilter"
                    placeholder=""
                  />
                </div>
              </template>

              <template v-slot="props">
                <strong v-if="props.row.IsSummary"
                  >{{ formatShortDate(props.row.ModifiedDate) }}
                </strong>
                <span v-if="!props.row.IsSummary">
                  {{ formatShortDate(props.row.ModifiedDate) }}
                </span>
              </template>
            </o-table-column>
          </o-table>
          <o-table
            v-show="activeItem === TABLE_TYPES.DEPOSIT && depositList"
            :data="filteredDeposits"
            :bordered="isBordered"
            :striped="isStriped"
            :hoverable="isHoverable"
            :focusable="isFocusable"
          >
            <o-table-column
              field="DepositType"
              label="Deposit Type"
              sortable
              searchable
              width="10%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="depositsDepositTypeFilter"
                    placeholder=""
                  />
                </div>
              </template>
              <template v-slot="props">
                <strong v-if="props.row.IsSummary"
                  >{{ props.row.DepositType }}
                </strong>

                <span v-if="!props.row.IsSummary">
                  {{ props.row.DepositType }}
                </span>
              </template>
            </o-table-column>

            <o-table-column
              field="CheckNumber"
              label="Check Number"
              sortable
              searchable
              width="10%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="depositsCheckNumberFilter"
                    placeholder=""
                  />
                </div>
              </template>
              <template v-slot="props">
                {{ props.row.CheckNumber }}
              </template>
            </o-table-column>

            <o-table-column
              field="CheckDate"
              label="Check Date"
              sortable
              searchable
              width="10%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="depositsCheckDateFilter"
                    placeholder=""
                  />
                </div>
              </template>
              <template v-slot="props">
                {{ formatGridDate(props.row.CheckDate) }}
              </template>
            </o-table-column>
            <o-table-column
              field="DepositAmount"
              label="Deposit Amount"
              sortable
              searchable
              width="10%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="depositsDepositAmountFilter"
                    placeholder=""
                  />
                </div>
              </template>
              <template v-slot="props">
                <strong v-if="props.row.IsSummary"
                  >{{ props.row.DepositAmount }}
                </strong>
                <span v-if="!props.row.IsSummary">
                  {{ props.row.DepositAmount }}
                </span>
              </template>
            </o-table-column>
            <o-table-column
              field="DepositStatus"
              label="Deposit Status"
              sortable
              searchable
              width="10%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="depositsDepositStatusFilter"
                    placeholder=""
                  />
                </div>
              </template>
              <template v-slot="props">
                {{ props.row.DepositStatus }}
              </template>
            </o-table-column>
            <o-table-column
              field="NsfReason"
              label="Nsf Reason"
              sortable
              searchable
              width="10%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="depositsNsfReasonFilter"
                    placeholder=""
                  />
                </div>
              </template>
              <template v-slot="props">
                {{ props.row.NsfReason }}
              </template>
            </o-table-column>
            <o-table-column
              field="NsfDate"
              label="Nsf Date"
              sortable
              searchable
              width="10%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="depositsNsfDateFilter"
                    placeholder=""
                  />
                </div>
              </template>
              <template v-slot="props">
                {{ formatGridDate(props.row.NsfDate) }}
              </template>
            </o-table-column>
            <o-table-column
              field="NsfEnteredDate"
              label="Nsf Entered Date"
              sortable
              searchable
              width="10%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="depositsNsfEnteredDateFilter"
                    placeholder=""
                  />
                </div>
              </template>
              <template v-slot="props">
                {{ formatGridDate(props.row.NsfEnteredDate) }}
              </template>
            </o-table-column>
            <o-table-column
              field="CreatedDate"
              label="Created Date"
              sortable
              searchable
              width="10%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="depositsCreatedDateFilter"
                    placeholder=""
                  />
                </div>
              </template>
              <template v-slot="props">
                {{ formatGridDate(props.row.CreatedDate) }}
              </template>
            </o-table-column>
            <o-table-column
              field="EstimatedPostDate"
              label="Estimated Post Date"
              sortable
              searchable
              width="10%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="depositsEstimatedPostDateFilter"
                    placeholder=""
                  />
                </div>
              </template>
              <template v-slot="props">
                {{ formatGridDate(props.row.EstimatedPostDate) }}
              </template>
            </o-table-column>
            <o-table-column
              field="ModifiedDate"
              label="Modified Date"
              sortable
              searchable
              width="10%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="depositsModifiedDateFilter"
                    placeholder=""
                  />
                </div>
              </template>
              <template v-slot="props">
                {{ formatGridDate(props.row.ModifiedDate) }}
              </template>
            </o-table-column>
            <o-table-column
              field="ModifiedBy"
              label="Modified By"
              sortable
              searchable
              width="10%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="depositsModifiedByFilter"
                    placeholder=""
                  />
                </div>
              </template>
              <template v-slot="props">
                {{ props.row.ModifiedBy }}
              </template>
            </o-table-column>
          </o-table>
          <o-table
            v-show="activeItem === TABLE_TYPES.TRANSACTION && transactionList"
            :data="filteredTransactions"
            :bordered="isBordered"
            :striped="isStriped"
            :hoverable="isHoverable"
            :focusable="isFocusable"
          >
            <o-table-column
              field="TransactionDate"
              label="Transaction Date"
              sortable
              searchable
              width="10%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="transactionsTransactionDateFilter"
                    placeholder=""
                  />
                </div>
              </template>
              <template v-slot="props">
                <strong v-if="props.row.IsSummary"
                  >{{ props.row.TransactionDate }}
                </strong>
                <span v-if="!props.row.IsSummary">
                  {{ formatGridDate(props.row.TransactionDate) }}
                </span>
              </template>
            </o-table-column>
            <o-table-column
              field="DebitDate"
              label="Debit Date"
              sortable
              searchable
              width="10%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="transactionsDebitDateFilter"
                    placeholder=""
                  />
                </div>
              </template>
              <template v-slot="props">
                {{ formatGridDate(props.row.DebitDate) }}
              </template>
            </o-table-column>
            <o-table-column
              field="PostDate"
              label="Post Date"
              sortable
              searchable
              width="10%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="transactionsPostDateFilter"
                    placeholder=""
                  />
                </div>
              </template>
              <template v-slot="props">
                {{ formatGridDate(props.row.PostDate) }}
              </template>
            </o-table-column>
            <o-table-column field="Status" label="Status" searchable sortable>
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="transactionsStatusFilter"
                    placeholder=""
                  />
                </div>
              </template>
              <template v-slot="props">
                {{ props.row.Status }}
              </template>
            </o-table-column>
            <o-table-column
              field="Amount"
              label="Amount"
              sortable
              searchable
              width="14%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="transactionsAmountFilter"
                    placeholder=""
                  />
                </div>
              </template>
              <template v-slot="props">
                <strong v-if="props.row.IsSummary"
                  >{{ props.row.Amount }}
                </strong>
                <span v-if="!props.row.IsSummary">
                  {{ props.row.Amount }}
                </span>
              </template>
            </o-table-column>
            <o-table-column
              field="TransactionClass"
              label="Transaction Class"
              sortable
              searchable
              width="10%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="transactionsTransactionClassFilter"
                    placeholder=""
                  />
                </div>
              </template>
              <template v-slot="props">
                {{ props.row.TransactionClass }}
              </template>
            </o-table-column>
            <o-table-column
              field="TransactionType"
              label="Transaction Type"
              sortable
              searchable
              width="10%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="transactionsTransactionTypeFilter"
                    placeholder=""
                  />
                </div>
              </template>
              <template v-slot="props">
                {{ props.row.TransactionType }}
              </template>
            </o-table-column>
            <o-table-column field="Memo" label="Memo" searchable sortable>
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="transactionsMemoFilter"
                    placeholder=""
                  />
                </div>
              </template>
              <template v-slot="props">
                {{ props.row.Memo }}
              </template>
            </o-table-column>
            <o-table-column
              field="FromAccount"
              label="From Account"
              sortable
              searchable
              width="10%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="transactionsFromAccountFilter"
                    placeholder=""
                  />
                </div>
              </template>
              <template v-slot="props">
                {{ props.row.FromAccount }}
              </template>
            </o-table-column>
            <o-table-column
              field="ToAccount"
              label="To Account"
              sortable
              searchable
              width="10%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="transactionsToAccountFilter"
                    placeholder=""
                  />
                </div>
              </template>
              <template v-slot="props">
                {{ props.row.ToAccount }}
              </template>
            </o-table-column>
            <o-table-column
              field="CreatedDate"
              label="Created Date"
              sortable
              searchable
              width="10%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="transactionsCreatedDateFilter"
                    placeholder=""
                  />
                </div>
              </template>
              <template v-slot="props">
                {{ formatGridDate(props.row.CreatedDate) }}
              </template>
            </o-table-column>
            <o-table-column
              field="ModifiedDate"
              label="Modified Date"
              sortable
              searchable
              width="10%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="transactionsModifiedDateFilter"
                    placeholder=""
                  />
                </div>
              </template>
              <template v-slot="props">
                {{ formatGridDate(props.row.ModifiedDate) }}
              </template>
            </o-table-column>
            <o-table-column
              field="ModifiedBy"
              label="Modified By"
              sortable
              searchable
              width="10%"
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="transactionsModifiedByFilter"
                    placeholder=""
                  />
                </div>
              </template>
              <template v-slot="props">
                {{ props.row.ModifiedBy }}
              </template>
            </o-table-column>
          </o-table>
          <div
            v-show="activeItem === TABLE_TYPES.STATEMENT"
            style="min-height: 420px"
          >
            <div
              v-if="Customer.CustomerGlobalInformation.PaymentProcessorID != 3"
              class="col-md-4"
            >
              <div class="row">
                <div class="col-md-4"><label> Start Date:</label></div>
                <div class="col-md-8 pb-4">
                  <DatePicker
                    mode="date"
                    :locale="'en-US'"
                    v-model="statement.startDate"
                  >
                    <template v-slot="{ inputValue, inputEvents }">
                      <input
                        id="startDate"
                        class="form-control"
                        :value="inputValue"
                        v-on="inputEvents"
                      />
                    </template>
                  </DatePicker>
                </div>
                <div class="col-md-4"><label>End Date:</label></div>
                <div class="col-md-8">
                  <DatePicker
                    mode="date"
                    :locale="'en-US'"
                    v-model="statement.endDate"
                  >
                    <template v-slot="{ inputValue, inputEvents }">
                      <input
                        id="endDate"
                        class="form-control"
                        :value="inputValue"
                        v-on="inputEvents"
                      />
                    </template>
                  </DatePicker>
                </div>
                <div class="col-md-12 text-right">
                  <button
                    type="button"
                    class="btn btn-info"
                    @click="generateStatement()"
                  >
                    Generate
                    <i class="fas fa-angle-right ml-2"></i>
                  </button>
                </div>
              </div>
            </div>
            <div
              v-if="Customer.CustomerGlobalInformation.PaymentProcessorID == 3"
              class="col-xl-8 col-lg-8 col-md-12"
            >
              <div class="row">
                <div class="col-md-12 d-flex align-items-center">
                  <label style="width: 80px" class="mt-1 mr-2"> Period:</label>
                  <select
                    v-model="statementMonth"
                    placeholder="Select Disposition*"
                    class="form-control mr-2"
                    style="width: 120px"
                  >
                    <option
                      v-for="item in statementMonthList"
                      v-show="
                        currentYear == statementYear
                          ? item.Id <= lastMonth
                          : true
                      "
                      :key="item.Id"
                      :value="item.Id"
                    >
                      {{ item.Name }}
                    </option>
                  </select>
                  <select
                    v-model="statementYear"
                    @change="statementYearChanged"
                    placeholder="Select Disposition*"
                    class="form-control mr-2"
                    style="width: 120px"
                  >
                    <option
                      v-for="item in statementYearList"
                      :key="item"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>
                  <button
                    type="button"
                    class="btn btn-info"
                    style="width: 120px"
                    @click="generateStatement(3)"
                  >
                    Generate
                    <i class="fas fa-angle-right ml-2"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <o-table
            v-show="activeItem === TABLE_TYPES.DOCUMENTS && sortedDocumentList"
            :data="sortedDocumentList"
            :bordered="isBordered"
            :striped="isStriped"
            :hoverable="isHoverable"
            :focusable="isFocusable"
          >
            <o-table-column field="Name" label="Name" v-slot="props" sortable>
              {{ props.row.Name }}
            </o-table-column>

            <o-table-column field="Type" label="Type" v-slot="props" sortable>
              {{ props.row.Type }}
            </o-table-column>

            <o-table-column
              field="Status"
              label="Status"
              v-slot="props"
              sortable
            >
              {{ props.row.Status }}
            </o-table-column>
            <o-table-column
              field="CreatedDate"
              label="CreatedDate"
              v-slot="props"
              sortable
            >
              {{ formatGridDate(props.row.CreatedDate) }}
            </o-table-column>
            <o-table-column
              field="ModifiedDate"
              label="Modified Date"
              v-slot="props"
              sortable
            >
              {{ formatGridDate(props.row.ModifiedDate) }}
            </o-table-column>
          </o-table>
          <div
            v-show="activeItem === TABLE_TYPES.CASHFLOW && sortedCashFlowList"
            class="col-md-12 pt-1"
            style="display: flex; justify-content: flex-end"
          >
            <input
              @click="isClickOngoingSettlements()"
              type="checkbox"
              v-model="showOngoingSettlements"
              class="checkbox-input generalCheckBox"
              style="
                border-radius: 4px !important;
                border: solid 1px #d1d1d1 !important;
              "
            />
            <label class="generalLabel">Show Ongoing Settlements </label>
          </div>
          <!-- <o-table
            v-show="activeItem === TABLE_TYPES.CASHFLOW && cashFlowList"
            :data="cashFlowList"
            :bordered="isBordered"
            :striped="isStriped"
            :hoverable="isHoverable"
            :focusable="isFocusable"
          >
            <o-table-column
              field="DateRaw"
              label="Date"
              v-slot="props"
              sortable
            >
              {{ props.row.Date }}
            </o-table-column>

            <o-table-column
              field="Drafts"
              label="Drafts"
              v-slot="props"
              sortable
            >
              {{ formatMoney(props.row.Drafts) }}
            </o-table-column>

            <o-table-column field="Fees" label="Fees" v-slot="props" sortable>
              {{ formatMoney(props.row.Fees) }}
            </o-table-column>
            <o-table-column
              field="Payments"
              label="Payments"
              v-slot="props"
              sortable
            >
              {{ formatMoney(props.row.Payments) }}
            </o-table-column>
            <o-table-column
              field="Charges"
              label="Charges"
              v-slot="props"
              sortable
            >
              {{ formatMoney(props.row.Charges) }}
            </o-table-column>

            <o-table-column
              v-if="showOngoingSettlements"
              field="OngoingSettlements"
              label="Ongoing Settlements"
              v-slot="props"
              sortable
            >
              {{ formatMoney(props.row.OngoingSettlements) }}
            </o-table-column>

            <o-table-column field="Net" label="Net Cash" sortable>
              <template v-slot="props">
                <div v-bind:class="{ textRed: props.row.Net < 0 }">
                  {{ formatMoney(props.row.Net) }}
                </div>
              </template>
            </o-table-column>
          </o-table> -->
          <perfect-scrollbar
            class="col-md-12"
            style="max-height: 400px; position: relative"
          >
            <table
              v-show="activeItem === TABLE_TYPES.CASHFLOW && sortedCashFlowList"
              class="col-md-12 datagrid-table creditorTable"
            >
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Transaction Type</th>
                  <th v-if="Customer.CustomerGlobalInformation.IsDpg == false">
                    Transaction Type Desc
                  </th>
                  <th>Date</th>
                  <th>Status</th>
                  <th>Amount</th>
                  <th>Memo</th>
                  <th>Balance</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in sortedCashFlowList" :key="item.Id">
                  <td
                    :style="
                      item.IsOngoingSettlements == true
                        ? 'color:red'
                        : 'color:black'
                    "
                  >
                    {{ item.Id }}
                  </td>
                  <td
                    :style="
                      item.IsOngoingSettlements == true
                        ? 'color:red'
                        : 'color:black'
                    "
                  >
                    {{ item.TransactionType }}
                  </td>
                  <td
                    v-if="Customer.CustomerGlobalInformation.IsDpg == false"
                    :style="
                      item.IsOngoingSettlements == true
                        ? 'color:red'
                        : 'color:black'
                    "
                  >
                    {{ item.TransactionTypeDesc }}
                  </td>
                  <td
                    :style="
                      item.IsOngoingSettlements == true
                        ? 'color:red'
                        : 'color:black'
                    "
                  >
                    {{ formatShortDate(item.Date) }}
                  </td>
                  <td
                    :style="
                      item.IsOngoingSettlements == true
                        ? 'color:red'
                        : 'color:black'
                    "
                  >
                    {{ item.Status }}
                  </td>
                  <td
                    :style="
                      item.IsOngoingSettlements == true
                        ? 'color:red'
                        : 'color:black'
                    "
                  >
                    {{ formatMoney(item.Amount) }}
                  </td>
                  <td
                    :style="
                      item.IsOngoingSettlements == true
                        ? 'color:red'
                        : 'color:black'
                    "
                  >
                    {{ item.Memo }}
                  </td>
                  <td
                    :style="
                      item.IsOngoingSettlements == true
                        ? 'color:red'
                        : 'color:black'
                    "
                  >
                    {{ formatMoney(item.Balance) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </perfect-scrollbar>

          <section
            v-show="
              (activeItem === TABLE_TYPES.INFORMATION &&
                infoData.length == 0) ||
              (activeItem === TABLE_TYPES.NOTES && gcsNoteList.length == 0) ||
              (activeItem === TABLE_TYPES.DRAFTS && draftList.length == 0) ||
              (activeItem === TABLE_TYPES.FEES && feeList.length == 0) ||
              (activeItem === TABLE_TYPES.PAYMENTS &&
                paymentList.length == 0) ||
              (activeItem === TABLE_TYPES.DEPOSIT && depositList.length == 0) ||
              (activeItem === TABLE_TYPES.TRANSACTION &&
                transactionList.length == 0) ||
              (activeItem === TABLE_TYPES.DOCUMENTS &&
                documentList.length == 0) ||
              (activeItem === TABLE_TYPES.CASHFLOW && cashFlowList.length == 0)
            "
            class="section"
            style="text-align: center"
          >
            <div class="content has-text-grey has-text-centered mb-4">
              <p class="noMargin">
                <i
                  style="font-size: 24px; color: #939393"
                  class="ri-question-line"
                ></i>
              </p>
              <p class="noMargin">Nothing here.</p>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const TABLE_TYPES = {
  INFORMATION: "INFORMATION",
  NOTES: "NOTES",
  DRAFTS: "DRAFTS",
  FEES: "FEES",
  PAYMENTS: "PAYMENTS",
  DEPOSIT: "DEPOSIT",
  TRANSACTION: "TRANSACTION",
  STATEMENT: "STATEMENT",
  DOCUMENTS: "DOCUMENTS",
  CASHFLOW: "CASHFLOW",
  CHARGES: "CHARGES",
};
import types from "./types";
import globalTypes from "@/store/types";
import { mapState } from "vuex";
import _orderby from "lodash";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import { DatePicker } from "v-calendar";
import deepCopy from "../../../../helpers/deepCopy";
import formatMixin from "@/mixins/formatMixin";

export default {
  name: "GCSTabs",
  components: {
    DatePicker,
  },
  props: {
    Customer: {
      type: Object,
      default: () => {},
    },
    tabClicked: {
      Type: Boolean,
      Default: false,
    },
  },
  watch: {
    tabClicked(newVal, oldVal) {
      if (newVal != oldVal && newVal) {
        this.watcherRefresh();
      }
    },
  },
  mixins: [utilitiesMixin, formatMixin],
  data() {
    return {
      //statement cft fields start
      lastMonth: 0,
      statementMonth: 0,
      statementMonthList: [
        { Id: 1, Name: "January" },
        { Id: 2, Name: "February" },
        { Id: 3, Name: "March" },
        { Id: 4, Name: "April" },
        { Id: 5, Name: "May" },
        { Id: 6, Name: "June" },
        { Id: 7, Name: "July" },
        { Id: 8, Name: "August" },
        { Id: 9, Name: "September" },
        { Id: 10, Name: "October" },
        { Id: 11, Name: "November" },
        { Id: 12, Name: "December" },
      ],
      statementYear: 0,
      currentYear: 0,
      statementYearList: [],
      //statement cft fields end
      paymentTypes:[],
      processorId:0,
      showOngoingSettlements: false,
      ongoingSettlementsList: [],
      isLoading: false,
      cashFlowListOldData: [],
      infoList: [],
      gcsNoteList: [],
      draftList: [],
      feeList: [],
      paymentList: [],
      depositList: [],
      chargeList: [],
      transactionList: [],
      documentList: [],
      cashFlowList: [],
      statement: {
        startDate: new Date().toDateString(),
        endDate: this.addDays(new Date(), 1).toDateString(),
      },

      TABLE_TYPES,
      isValidForGcs: false,
      infoData: [],
      isBordered: true,
      isStriped: true,
      isHoverable: true,
      isFocusable: true,

      buttonList: [
        {
          buttonName: "Information",
          tableName: TABLE_TYPES.INFORMATION,
          isActive: true,
        },
        { buttonName: "Notes", tableName: TABLE_TYPES.NOTES, isActive: true },
        { buttonName: "Drafts", tableName: TABLE_TYPES.DRAFTS, isActive: true },
        { buttonName: "Fees", tableName: TABLE_TYPES.FEES, isActive: true },
        {
          buttonName: "Payments",
          tableName: TABLE_TYPES.PAYMENTS,
          isActive: true,
        },
        {
          buttonName: "Charges",
          tableName: TABLE_TYPES.CHARGES,
          isActive: true,
        },
        {
          buttonName: "Deposits",
          tableName: TABLE_TYPES.DEPOSIT,
          isActive: true,
        },
        {
          buttonName: "Transactions",
          tableName: TABLE_TYPES.TRANSACTION,
          isActive: true,
        },
        {
          buttonName: "Statements",
          tableName: TABLE_TYPES.STATEMENT,
          isActive: true,
        },
        {
          buttonName: "Documents",
          tableName: TABLE_TYPES.DOCUMENTS,
          isActive: true,
        },
        {
          buttonName: "Cash Flow",
          tableName: TABLE_TYPES.CASHFLOW,
          isActive: true,
        },
      ],
      activeItem: TABLE_TYPES.INFORMATION,

      crmInfo: {},
      feesInitSummary: {
        Active: "",
        Amount: "",
        CreationDate: null,
        FeeStatus: null,
        Day: "",
        EffectiveDate: null,
        Memo: "",
        ModifiedBy: "",
        ModifiedDate: null,
        Occurs: "",
        Remaining: "",
        Type: "",
      },
      draftsInitSummary: {
        EffectiveDate: null,
        Day: "",
        Occurs: "",
        Amount: "",
        Status: "",
        Memo: "",
        Active: "",
        Remaining: "",
        CreationDate: null,
        ModifiedDate: null,
        ModifiedBy: "",
      },
      paymentsInitSummary: {
        EffectiveDate: null,
        Day: "",
        Occurs: "",
        Amount: "",
        Memo: "",
        Active: "",
        Remaining: "",
        CreationDate: null,
        ModifiedDate: null,
        ModifiedBy: "",
      },
      chargesInitSummary: {
        EffectiveDate: null,
        Amount: "",
      },
      depositsInitSummary: {
        DepositType: "",
        CheckNumber: "",
        DepositAmount: "",
        DepositStatus: "",
        NsfReason: "",
        ModifiedBy: "",
        CheckDate: null,
        NsfDate: null,
        NsfEnteredDate: null,
        CreatedDate: null,
        EstimatedPostDate: null,
        ModifiedDate: null,
      },
      transactionsInitSummary: {
        Status: "",
        Amount: "",
        TransactionClass: "",
        TransactionType: "",
        Memo: "",
        FromAccount: "",
        ToAccount: "",
        ModifiedBy: "",
        CreatedDate: null,
        ModifiedDate: null,
        TransactionDate: null,
        DebitDate: null,
        PostDate: null,
      },

      // Filter Start
      feesEffectiveDateFilter: "",
      feesPayeeFilter: "",
      feesAccountNumberFilter: "",
      feesDayFilter: "",
      feesTypeFilter: "",
      feesOccursFilter: "",
      feesAmountFilter: "",
      feesMemoFilter: "",
      feesActiveFilter: "",
      feesRemainingFilter: "",
      feesCreationDateFilter: "",
      feesStatusFilter: "",
      feesModifiedDateFilter: "",
      feesModifiedByFilter: "",

      draftsEffectiveDateFilter: "",
      draftsDayFilter: "",
      draftsOccursFilter: "",
      draftsAmountFilter: "",
      draftsStatusFilter: "",
      draftsMemoFilter: "",
      draftsActiveFilter: "",
      draftsRemainingFilter: "",
      draftsCreationDateFilter: "",
      draftsModifiedDateFilter: "",
      draftsModifiedByFilter: "",

      paymentsEffectiveDateFilter: "",
      paymentsPayeeFilter: "",
      paymentsAccountNumberFilter: "",
      paymentsClassFilter: "",
      paymentsTypeFilter: "",
      paymentsStatusFilter: "",
      paymentsAmountFilter: "",
      paymentsFeeAmountFilter: "",
      paymentsActiveFilter: "",
      mergedNumbersFilter: "",
      paymentsClearedDateFilter: "",
      paymentsModifiedByFilter: "",

      chargesEffectiveDateFilter: "",
      chargesAmountFilter: "",
      chargesTransactionFilter: "",
      chargesActiveFilter: "",
      chargesStatusFilter: "",
      chargesCreatedByFilter: "",
      chargesCreatedDateFilter: "",
      chargesModifiedByFilter: "",
      chargesModifiedDateFilter: "",

      depositsDepositTypeFilter: "",
      depositsCheckNumberFilter: "",
      depositsCheckDateFilter: "",
      depositsDepositAmountFilter: "",
      depositsDepositStatusFilter: "",
      depositsNsfReasonFilter: "",
      depositsNsfDateFilter: "",
      depositsNsfEnteredDateFilter: "",
      depositsCreatedDateFilter: "",
      depositsEstimatedPostDateFilter: "",
      depositsModifiedDateFilter: "",
      depositsModifiedByFilter: "",

      transactionsTransactionDateFilter: "",
      transactionsDebitDateFilter: "",
      transactionsPostDateFilter: "",
      transactionsStatusFilter: "",
      transactionsAmountFilter: "",
      transactionsTransactionClassFilter: "",
      transactionsTransactionTypeFilter: "",
      transactionsMemoFilter: "",
      transactionsFromAccountFilter: "",
      transactionsToAccountFilter: "",
      transactionsCreatedDateFilter: "",
      transactionsModifiedDateFilter: "",
      transactionsModifiedByFilter: "",
    };
  },

  computed: mapState({
    accountTypeList: (state) => state.globals.accountTypeList,
    states: (state) => state.globals.states,
    filteredFees() {
      let rtn = [];
      let tmpList = this.sortedFeeList.filter((item) => {
        return (
          new Date(item.EffectiveDate)
            .toLocaleDateString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "numeric",
              minute: "2-digit",
            })
            .indexOf(this.feesEffectiveDateFilter) > -1 &&
          this.filterString(item.CreditorName, this.feesPayeeFilter) &&
          this.filterString(item.FeeStatus, this.feesStatusFilter) &&
          this.filterString(item.AccountNumber, this.feesAccountNumberFilter) &&
          this.filterNumeric(item.Day, this.feesDayFilter) &&
          this.filterString(
            item.Type + " " + item.CreditorName,
            this.feesTypeFilter
          ) &&
          this.filterNumeric(item.Occurs, this.feesOccursFilter) &&
          this.filterString(item.Amount, this.feesAmountFilter) &&
          this.filterString(item.Memo, this.feesMemoFilter) &&
          this.filterString(item.Active, this.feesActiveFilter) &&
          this.filterNumeric(item.Remaining, this.feesRemainingFilter) &&
          this.filterString(item.ModifiedBy, this.feesModifiedByFilter) &&
          new Date(item.CreationDate)
            .toLocaleDateString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "numeric",
              minute: "2-digit",
            })
            .indexOf(this.feesCreationDateFilter) > -1 &&
          new Date(item.ModifiedDate)
            .toLocaleDateString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "numeric",
              minute: "2-digit",
            })
            .indexOf(this.feesModifiedDateFilter) > -1
        );
      });
      let totalAmount = 0.0;
      let totalCount = tmpList.length.toString();
      for (let listItem of tmpList) {
        totalAmount += parseFloat(listItem.Amount);
      }
      let totalAmountStr = totalAmount.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
      this.feesInitSummary.Amount = "Total: " + totalAmountStr;
      this.feesInitSummary.Remaining = "Count: " + totalCount;
      this.feesInitSummary.IsSummary = true;
      rtn.push(...tmpList);
      rtn.push(this.feesInitSummary);
      return rtn;
    },
    filteredPayments() {
      let rtn = [];
      let tmpList = this.sortedPaymentList.filter((item) => {
        return (
          new Date(item.EffectiveDate)
            .toLocaleDateString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "numeric",
              minute: "2-digit",
            })
            .indexOf(this.paymentsEffectiveDateFilter) > -1 &&
          this.filterString(item.Payee, this.paymentsPayeeFilter) &&
          this.filterString(
            item.AccountNumber,
            this.paymentsAccountNumberFilter
          ) &&
          this.filterString(item.PaymentClass, this.paymentsClassFilter) &&
          this.filterString(item.PaymentType, this.paymentsTypeFilter) &&
          this.filterString(item.PaymentStatus, this.paymentsStatusFilter) &&
          this.filterString(item.PaymentAmount, this.paymentsAmountFilter) &&
          this.filterString(item.FeeAmount, this.paymentsFeeAmountFilter) &&
          this.filterString(item.Active, this.paymentsActiveFilter) &&
          this.filterString(item.ModifiedBy, this.paymentsModifiedByFilter) &&
          this.filterNumeric(item.MergedNumbers, this.mergedNumbersFilter) &&
          new Date(item.ClearedDate)
            .toLocaleDateString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "numeric",
              minute: "2-digit",
            })
            .indexOf(this.paymentsClearedDateFilter) > -1
        );
      });
      let totalAmount = 0.0;
      let totalFeeAmount = 0.0;
      let totalCount = tmpList.length.toString();
      for (let listItem of tmpList) {
        totalAmount += parseFloat(listItem.PaymentAmount);
        totalFeeAmount += parseFloat(listItem.FeeAmount);
      }
      let totalAmountStr = totalAmount.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });

      let totalFeeAmountStr = totalFeeAmount.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
      this.paymentsInitSummary.PaymentAmount = "Total: " + totalAmountStr;
      this.paymentsInitSummary.FeeAmount = "Total: " + totalFeeAmountStr;
      this.paymentsInitSummary.EffectiveDate = "Count: " + totalCount;
      this.paymentsInitSummary.IsSummary = true;
      rtn.push(...tmpList);
      rtn.push(this.paymentsInitSummary);

      return rtn;
    },
    filteredCharges() {
      let rtn = [];
      let tmpList = this.sortedChargeList.filter((item) => {
        return (
          new Date(item.EffectiveDate)
            .toLocaleDateString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "numeric",
              minute: "2-digit",
            })
            .indexOf(this.chargesEffectiveDateFilter) > -1 &&
          this.filterString(
            item.TransactionCombined,
            this.chargesTransactionFilter
          ) &&
          this.filterString(item.Amount, this.chargesAmountFilter) &&
          this.filterString(item.Active, this.chargesActiveFilter) &&
          this.filterString(item.Status, this.chargesStatusFilter) &&
          this.filterString(item.CreatedBy, this.chargesCreatedByFilter) &&
          new Date(item.CreatedDate)
            .toLocaleDateString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "numeric",
              minute: "2-digit",
            })
            .indexOf(this.chargesCreatedDateFilter) > -1 &&
          this.filterString(item.ModifiedBy, this.chargesModifiedByFilter) &&
          new Date(item.ModifiedDate)
            .toLocaleDateString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "numeric",
              minute: "2-digit",
            })
            .indexOf(this.chargesModifiedDateFilter) > -1
        );
      });
      let totalAmount = 0.0;
      let totalCount = tmpList.length.toString();
      for (let listItem of tmpList) {
        totalAmount += parseFloat(listItem.Amount);
      }
      let totalAmountStr = totalAmount.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });

      this.chargesInitSummary.Amount = "Total: " + totalAmountStr;
      this.chargesInitSummary.EffectiveDate = "Count: " + totalCount;
      this.chargesInitSummary.IsSummary = true;
      rtn.push(...tmpList);
      rtn.push(this.chargesInitSummary);

      return rtn;
    },
    filteredDrafts() {
      let rtn = [];
      if (this.sortedDraftList == null || this.sortedDraftList.length == 0)
        return rtn;
      let tmpList = this.sortedDraftList.filter((item) => {
        return (
          new Date(item.EffectiveDate)
            .toLocaleDateString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "numeric",
              minute: "2-digit",
            })
            .indexOf(this.draftsEffectiveDateFilter) > -1 &&
          this.filterNumeric(item.Day, this.draftsDayFilter) &&
          this.filterNumeric(item.Occurs, this.draftsOccursFilter) &&
          this.filterString(item.Amount, this.draftsAmountFilter) &&
          this.filterString(item.Status, this.draftsStatusFilter) &&
          this.filterString(item.Memo, this.draftsMemoFilter) &&
          this.filterString(item.Active, this.draftsActiveFilter) &&
          this.filterNumeric(item.Remaining, this.draftsRemainingFilter) &&
          this.filterString(item.ModifiedBy, this.draftsModifiedByFilter) &&
          new Date(item.CreationDate)
            .toLocaleDateString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "numeric",
              minute: "2-digit",
            })
            .indexOf(this.draftsCreationDateFilter) > -1 &&
          new Date(item.ModifiedDate)
            .toLocaleDateString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "numeric",
              minute: "2-digit",
            })
            .indexOf(this.draftsModifiedDateFilter) > -1
        );
      });
      let totalAmount = 0.0;
      let totalCount = tmpList.length.toString();
      for (let listItem of tmpList) {
        totalAmount += parseFloat(listItem.Amount);
      }
      let totalAmountStr = totalAmount.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
      this.draftsInitSummary.Amount = "Total: " + totalAmountStr;
      this.draftsInitSummary.Remaining = "Count: " + totalCount;
      this.draftsInitSummary.IsSummary = true;
      rtn.push(...tmpList);
      rtn.push(this.draftsInitSummary);

      return rtn;
    },
    filteredDeposits() {
      let rtn = [];
      if (
        this.sortedDepositsList == null ||
        this.sortedDepositsList.length == 0
      )
        return rtn;
      let tmpList = this.sortedDepositsList.filter((item) => {
        return (
          this.filterString(item.DepositType, this.depositsDepositTypeFilter) &&
          this.filterNumeric(
            item.CheckNumber,
            this.depositsCheckNumberFilter
          ) &&
          this.filterString(
            item.DepositAmount,
            this.depositsDepositAmountFilter
          ) &&
          this.filterString(
            item.DepositStatus,
            this.depositsDepositStatusFilter
          ) &&
          this.filterString(item.NsfReason, this.depositsNsfReasonFilter) &&
          this.filterString(item.ModifiedBy, this.depositsModifiedByFilter) &&
          new Date(item.CheckDate)
            .toLocaleDateString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "numeric",
              minute: "2-digit",
            })
            .indexOf(this.depositsCheckDateFilter) > -1 &&
          new Date(item.NsfDate)
            .toLocaleDateString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "numeric",
              minute: "2-digit",
            })
            .indexOf(this.depositsNsfDateFilter) > -1 &&
          new Date(item.NsfEnteredDate)
            .toLocaleDateString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "numeric",
              minute: "2-digit",
            })
            .indexOf(this.depositsNsfEnteredDateFilter) > -1 &&
          new Date(item.CreatedDate)
            .toLocaleDateString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "numeric",
              minute: "2-digit",
            })
            .indexOf(this.depositsCreatedDateFilter) > -1 &&
          new Date(item.EstimatedPostDate)
            .toLocaleDateString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "numeric",
              minute: "2-digit",
            })
            .indexOf(this.depositsEstimatedPostDateFilter) > -1 &&
          new Date(item.ModifiedDate)
            .toLocaleDateString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "numeric",
              minute: "2-digit",
            })
            .indexOf(this.depositsModifiedDateFilter) > -1
        );
      });
      let totalAmount = 0.0;
      let totalCount = tmpList.length.toString();
      for (let listItem of tmpList) {
        totalAmount += parseFloat(listItem.DepositAmount);
      }
      let totalAmountStr = totalAmount.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
      this.depositsInitSummary.DepositAmount = "Total: " + totalAmountStr;
      this.depositsInitSummary.DepositType = "Count: " + totalCount;
      this.depositsInitSummary.IsSummary = true;
      rtn.push(...tmpList);
      rtn.push(this.depositsInitSummary);
      return rtn;
    },
    filteredTransactions() {
      let rtn = [];
      if (
        this.sortedTransactionList == null ||
        this.sortedTransactionList.length == 0
      )
        return rtn;
      let tmpList = this.sortedTransactionList.filter((item) => {
        return (
          this.filterString(item.Status, this.transactionsStatusFilter) &&
          this.filterString(item.Amount, this.transactionsAmountFilter) &&
          this.filterString(
            item.TransactionClass,
            this.transactionsTransactionClassFilter
          ) &&
          this.filterString(
            item.TransactionType,
            this.transactionsTransactionTypeFilter
          ) &&
          this.filterString(item.Memo, this.transactionsMemoFilter) &&
          this.filterNumeric(
            item.FromAccount,
            this.transactionsFromAccountFilter
          ) &&
          this.filterNumeric(
            item.ToAccount,
            this.transactionsToAccountFilter
          ) &&
          this.filterString(
            item.ModifiedBy,
            this.transactionsModifiedByFilter
          ) &&
          new Date(item.CreatedDate)
            .toLocaleDateString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "numeric",
              minute: "2-digit",
            })
            .indexOf(this.transactionsCreatedDateFilter) > -1 &&
          new Date(item.ModifiedDate)
            .toLocaleDateString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "numeric",
              minute: "2-digit",
            })
            .indexOf(this.transactionsModifiedDateFilter) > -1 &&
          new Date(item.TransactionDate)
            .toLocaleDateString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "numeric",
              minute: "2-digit",
            })
            .indexOf(this.transactionsTransactionDateFilter) > -1 &&
          new Date(item.DebitDate)
            .toLocaleDateString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "numeric",
              minute: "2-digit",
            })
            .indexOf(this.transactionsDebitDateFilter) > -1 &&
          new Date(item.PostDate)
            .toLocaleDateString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "numeric",
              minute: "2-digit",
            })
            .indexOf(this.transactionsPostDateFilter) > -1
        );
      });
      let totalAmount = 0.0;
      let totalCount = tmpList.length;
      for (let listItem of tmpList) {
        totalAmount += parseFloat(listItem.Amount);
      }
      let totalAmountStr = totalAmount.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
      this.transactionsInitSummary.Amount = "Total: " + totalAmountStr;
      this.transactionsInitSummary.TransactionDate = "Count: " + totalCount;
      this.transactionsInitSummary.IsSummary = true;
      rtn.push(...tmpList);
      rtn.push(this.transactionsInitSummary);
      return rtn;
    },
    sortedFeeList() {
      return this.feeList.sort((a, b) => {
        return new Date(a.EffectiveDate) - new Date(b.EffectiveDate);
      });
    },
    sortedDraftList() {
      return this.draftList.sort((a, b) => {
        return new Date(a.EffectiveDate) - new Date(b.EffectiveDate);
      });
    },
    sortedPaymentList() {
      return this.paymentList
        .map((payment) => {
          let check = payment.CheckNumber !== 0 ? payment.CheckNumber : "-";
          let tracking =
            payment.TrackingNumber.trim() !== "" ? payment.TrackingNumber : "-";
          let mergedNumbers = `${check} / ${tracking}`;
          if(mergedNumbers.replace(" ","").toLowerCase().includes("null")){
            mergedNumbers="";
          }
          return {
            ...payment,
            MergedNumbers: mergedNumbers,
          };
        })

        .sort((a, b) => new Date(a.EffectiveDate) - new Date(b.EffectiveDate));
    },
    sortedChargeList() {
      return this.chargeList.sort((a, b) => {
        return new Date(a.EffectiveDate) - new Date(b.EffectiveDate);
      });
    },
    sortedDepositsList() {
      return this.depositList.sort((a, b) => {
        return new Date(a.CheckDate) - new Date(b.CheckDate);
      });
    },
    sortedTransactionList() {
      return this.transactionList.sort((a, b) => {
        return new Date(a.TransactionDate) - new Date(b.TransactionDate);
      });
    },
    sortedDocumentList() {
      return this.documentList.sort((a, b) => {
        return new Date(a.CreatedDate) - new Date(b.CreatedDate);
      });
    },
    sortedCashFlowList() {
      return this.cashFlowList.sort((a, b) => {
        return new Date(a.Date) - new Date(b.Date);
      });
    },
    sortedNoteList() {
      return this.gcsNoteList.sort((a, b) => {
        return new Date(a.Date) - new Date(b.Date);
      });
    },
  }),
  unmounted() {
    // this.emptyTheObjectsOnUnmount();
  },
  async mounted() {
    this.$Emitter.on("refreshCashFlow", async (data) => {
      if (data && this.Customer && data.CustomerId == this.Customer.Id) {
        this.cashFlowList = [];
        await this.getCashFlow();
      }
    });
    await this.$store.dispatch(globalTypes.GET_ACCOUNT_TYPES_LIST);
    if (this.Customer && this.Customer.CustomerGlobalInformation) {
      this.processorId = parseInt(
        this.Customer.CustomerGlobalInformation.PaymentProcessorID
      );

      if( this.processorId == 1)
      {
         await this.getPaymentTypes(this.Customer.CustomerGlobalInformation.PolicyGroupId);
      }

      let excludedList = [];
      if (this.Customer.CustomerGlobalInformation.IsDpg) {
        excludedList = this.getExcludedButtonListByPaymentProcessorId(2);
      } else {
        excludedList =
          this.getExcludedButtonListByPaymentProcessorId(this.processorId);
      }

      for (let item of this.buttonList) {
        if (excludedList.includes(item.buttonName)) {
          item.isActive = false;
        }
      }

      this.lastMonth = new Date().getMonth();
      this.currentYear = new Date().getFullYear();
      this.statementYearList = [];
      for (let i = this.currentYear; i > 1999; i--) {
        this.statementYearList.push(i);
      }
      this.statementYear = this.currentYear;
      this.statementMonth = this.lastMonth;
    }
  },
  methods: {
    async getPaymentTypes(policyGroupId) {
      if (this.paymentTypes.length > 0) return;

      let err, result;
      [err, result] = await this.$store.dispatch(
        types.GET_PAYMENT_TYPES_WITH_FEES_GCS,
        {PolicyGroupId: policyGroupId}
      );
      if (result && result.Data) {
        this.paymentTypes = deepCopy(result.Data);
      }
    },
    getPaymentTypeTransactionFee(paymentTypeId) {
      let rtn = 0;
      if (this.processorId == 1 && this.paymentTypes.length > 0) {
        let indx = this.paymentTypes.findIndex((x) => x.Id == paymentTypeId);
        if (indx > -1) rtn = this.paymentTypes[indx].TransactionFee;
      }
     
      return rtn;
    },
    statementYearChanged() {
      if (this.statementYear == this.currentYear) {
        this.statementMonth = this.lastMonth;
      }
    },
    getExcludedButtonListByPaymentProcessorId(paymentProcessorId) {
      let rtn = [];
      if (paymentProcessorId == 2) {
        //DPG
        rtn = ["Notes", "Statements", "Deposits", "Charges"];
      } else if (paymentProcessorId == 3) {
        //CFT
        rtn = ["Notes", "Charges"];
      }
      return rtn;
    },
    getAccountTypeName(id) {
      let rtn = "";
      if (this.accountTypeList && this.accountTypeList.length > 0) {
        let indx = this.accountTypeList.findIndex((x) => x.Id == id);
        if (indx > -1) {
          rtn = this.accountTypeList[indx].Name;
        }
      }
      return rtn;
    },
    getDynamicStyle(status, nsfStatus) {
      if (status == "Cleared") {
        return {
          "background-color": "#63d294",
        };
      } else if (status == "Returned / NSF") {
        return {
          "background-color": "#f1bfbf",
        };
      } else if (status == "Open") {
        return {
          "background-color": "transparent",
        };
      } else if (status != null && nsfStatus != null) {
        return {
          "background-color": "#f1bfbf",
        };
      } else if (status != null) {
        return {
          "background-color": "#63d294",
        };
      } else if (nsfStatus != null) {
        return {
          "background-color": "#f1bfbf",
        };
      } else {
        return {};
      }
    },
    getDynamicStyleForCFT(status) {
      if (status == "COMPLETED") {
        return {
          "background-color": "#63d294",
        };
      } else if (status == "PENDING" || status=="FAILED") {
        return {
          "background-color": "#f1bfbf",
        };
      } else {
        return {
          "background-color": "transparent",
        };
      }
    },
    getCarrierLink(trackingNumber) {
      if (trackingNumber == null || trackingNumber == "") return null;
      if (trackingNumber.includes("1Z"))
        return "https://www.ups.com/track?tracknum=" + trackingNumber;
      if (trackingNumber.length >= 12 && trackingNumber.length < 14)
        return "https://www.fedex.com/fedextrack/?trknbr=" + trackingNumber;
      if (trackingNumber.length >= 20 && trackingNumber.length < 22)
        return (
          "https://tools.usps.com/go/TrackConfirmAction?tLabels=" +
          trackingNumber
        );
      return null;
    },

    openTrackingLink(trackingNumber) {
      let link = this.getCarrierLink(trackingNumber);
      if (link) {
        window.open(link, "_blank");
      }
    },

    refreshGrid() {
      if (this.isLoading) return;

      this.changeTab(this.activeItem, true);
    },
    async getCustomerInformation() {
      let err, result;

      this.isLoading = true;
      [err, result] = await this.$store.dispatch(types.GET_INFO, {
        CustomerId: this.Customer.Id,
        OfficeId: this.Customer.OfficeId,
      });
      this.isLoading = false;
      if (result && result.Data) {
        this.infoList = deepCopy(result.Data);
      }
    },

    async isClickOngoingSettlements() {
      this.showOngoingSettlements = !this.showOngoingSettlements;
      if (this.showOngoingSettlements == true) {
        let list = deepCopy(this.cashFlowListOldData);
        for (let i of this.ongoingSettlementsList) {
          list.push({
            Id: 1,
            TransactionType: "P",
            TransactionTypeDesc: "Ongoing Negotiation",
            Date: i.PaymentDate,
            Status: "Ongoing",
            Amount: parseFloat(i.PaymentAmount) + parseFloat(i.FeeAmount) + parseFloat(this.getPaymentTypeTransactionFee(i.PaymentTypeId)),
            Memo: i.CreditorName + " - " + this.formatShortDate(i.PaymentDate),
            Balance: 0,
            IsOngoingSettlements: true,
          });
        }
        list.sort((a, b) => new Date(a.Date) - new Date(b.Date));
        for (let i = 0; i < list.length; i++) {
          if (list[i].IsOngoingSettlements == true) {
            if (i > 0) {
              list[i].Balance = parseFloat(
                list[i - 1].Balance - list[i].Amount
              );
              list[i].Id = parseInt(list[i - 1].Id + 1);
            }

            for (let j = i + 1; j < list.length; j++) {
              list[j].Balance = parseFloat(list[j].Balance - list[i].Amount);
              list[j].Id += 1;
            }
          }
        }

        this.cashFlowList = deepCopy(list);
      } else {
        this.cashFlowList = deepCopy(this.cashFlowListOldData);
      }
    },

    async watcherRefresh() {
      if (this.Customer.Id == 0 || this.Customer.OfficeId == 0) {
        this.$swal("Error!", "CustomerId or OfficeId is not valid.", "error");
        this.isValidForGcs = false;
        return;
      } else {
        this.isValidForGcs = true;
      }

      await this.$store.dispatch(globalTypes.GET_STATES);

      this.changeTab(TABLE_TYPES.INFORMATION);
    },
    async bindInformationTab() {
      await this.getCustomerInformation();
      this.infoData = [];

      this.crmInfo = {
        FirstName: this.Customer.CustomerProfile.FirstName.trim(),
        LastName: this.Customer.CustomerProfile.LastName.trim(),
        DoB: this.Customer.CustomerProfile.DateOfBirth,
        SSN: this.Customer.CustomerProfile.SsnEncrypted,
        EnrolledDate: this.Customer.CustomerGlobalInformation.EnrolledDate,
        Account: this.Customer.Bank.AccountNumberEncrypted,
        Routing: this.Customer.Bank.RoutingNumber,
        AccountType: this.getAccountTypeName(this.Customer.Bank.AccountTypeId),
        Street: this.Customer.CustomerProfile.Address.Line1,
        Apartment: this.Customer.CustomerProfile.Address.Line2,
        City: this.Customer.CustomerProfile.Address.City,
        State: this.Customer.CustomerProfile.Address.StateId,
        Zip: this.Customer.CustomerProfile.Address.Zip,
        Balance: "",
        PendingBalance: "",
        DraftsAuthorized: "",
        ValidationStatus: "",
      };

      (this.crmInfo.EnrolledDate = this.formatShortDate(
        this.Customer.CustomerGlobalInformation.EnrolledDate
      )),
        (this.crmInfo.SSN = await this.AES256_GCM_decrypt(this.crmInfo.SSN));

      this.crmInfo.Account = await this.AES256_GCM_decrypt(
        this.crmInfo.Account
      );

      if (this.states.length > 0) {
        let indx = this.states.findIndex((x) => x.Id == this.crmInfo.State);
        if (indx > -1) {
          this.crmInfo.State = this.states[indx].ShortCode;
        }
      }

      let objects = Object.keys(this.infoList);

      for (let i = 0; i < objects.length; i++) {
        let subNodes = Object.keys(this.infoList[objects[i]]);
        for (let j = 0; j < subNodes.length; j++) {
          if (subNodes[j] == "SSN") {
            let ssnEncrypted = this.infoList[objects[i]][subNodes[j]];
            let decryptedVal = "";

            if (ssnEncrypted && ssnEncrypted != "") {
              decryptedVal = await this.AES256_GCM_decrypt(ssnEncrypted);
            }
            this.infoList[objects[i]][subNodes[j]] = decryptedVal;
          }

          this.infoData.push({
            CompareItem: subNodes[j],
            CSMInformation: "",
            GCSInformation: this.infoList[objects[i]][subNodes[j]],
          });
        }
      }

      for (let index = 0; index < this.infoData.length; index++) {
        this.infoData[index].CSMInformation =
          this.crmInfo[this.infoData[index].CompareItem];
      }
    },
    emptyTheObjectsOnUnmount() {
      this.statement = null;
      this.TABLE_TYPES = null;
      this.isValidForGcs = null;
      this.infoData.length = 0;
      this.isBordered = null;
      this.isStriped = null;
      this.isHoverable = null;
      this.isFocusable = null;
      this.buttonList = null;
      this.activeItem = null;

      this.crmInfo = {};
      this.feesInitSummary = null;
      this.draftsInitSummary = null;
      this.paymentsInitSummary = null;
      this.EffectiveDate = null;
      this.depositsInitSummary = null;
      this.transactionsInitSummary = null;

      // Filter Start
      this.feesEffectiveDateFilter = null;
      this.feesDayFilter = null;
      this.feesTypeFilter = null;
      this.feesOccursFilter = null;
      this.feesAmountFilter = null;
      this.feesMemoFilter = null;
      this.feesActiveFilter = null;
      this.feesRemainingFilter = null;
      this.feesStatusFilter = null;
      this.feesCreationDateFilter = null;
      this.feesModifiedDateFilter = null;
      this.feesModifiedByFilter = null;

      this.draftsEffectiveDateFilter = null;
      this.draftsDayFilter = null;
      this.draftsOccursFilter = null;
      this.draftsAmountFilter = null;
      this.draftsStatusFilter = null;
      this.draftsMemoFilter = null;
      this.draftsActiveFilter = null;
      this.draftsRemainingFilter = null;
      this.draftsCreationDateFilter = null;
      this.draftsModifiedDateFilter = null;
      this.draftsModifiedByFilter = null;

      this.paymentsEffectiveDateFilter = null;
      this.paymentsPayeeFilter = null;
      this.paymentsAccountNumberFilter = null;
      this.paymentsClassFilter = null;
      this.paymentsTypeFilter = null;
      this.paymentsStatusFilter = null;
      this.paymentsAmountFilter = null;
      this.paymentsFeeAmountFilter = null;
      this.paymentsActiveFilter = null;
      this.mergedNumbersFilter = null;
      this.paymentsClearedDateFilter = null;
      this.paymentsModifiedByFilter = null;

      this.chargesEffectiveDateFilter = null;
      this.chargesAmountFilter = null;
      this.chargesTransactionFilter = null;
      this.chargesActiveFilter = null;
      this.chargesStatusFilter = null;
      this.chargesCreatedByFilter = null;
      this.chargesCreatedDateFilter = null;
      this.chargesModifiedByFilter = null;
      this.chargesModifiedDateFilter = null;

      this.depositsDepositTypeFilter = null;
      this.depositsCheckNumberFilter = null;
      this.depositsCheckDateFilter = null;
      this.depositsDepositAmountFilter = null;
      this.depositsDepositStatusFilter = null;
      this.depositsNsfReasonFilter = null;
      this.depositsNsfDateFilter = null;
      this.depositsNsfEnteredDateFilter = null;
      this.depositsCreatedDateFilter = null;
      this.depositsEstimatedPostDateFilter = null;
      this.depositsModifiedDateFilter = null;
      this.depositsModifiedByFilter = null;

      this.transactionsTransactionDateFilter = null;
      this.transactionsDebitDateFilter = null;
      this.transactionsPostDateFilter = null;
      this.transactionsStatusFilter = null;
      this.transactionsAmountFilter = null;
      this.transactionsTransactionClassFilter = null;
      this.transactionsTransactionTypeFilter = null;
      this.transactionsMemoFilter = null;
      this.transactionsFromAccountFilter = null;
      this.transactionsToAccountFilter = null;
      this.transactionsCreatedDateFilter = null;
      this.transactionsModifiedDateFilter = null;
      this.transactionsModifiedByFilter = null;
    },

    formatGridDate(date) {
      return date == null || date == ""
        ? ""
        : new Date(date).toLocaleDateString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "numeric",
            minute: "2-digit",
          });
    },

    async phonePayInfoButton() {
      let err, result;

      [err, result] = await this.$store.dispatch(types.PHONE_PAY_INFO, {
        CustomerId: this.Customer.Id,
        OfficeId: this.Customer.OfficeId,
      });
      if (result) {
        this.$swal(
          "",
          "Account Number: " +
            result.Data.AccountNumber +
            "<hr>Routing Number: " +
            result.Data.RoutingNumber,
          "success"
        );
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
    },

    async generateStatement(paymentProcessorId = 0) {
      let startDate = new Date(this.statement.startDate);
      let endDate = new Date(this.statement.endDate);
      if (paymentProcessorId == 3) {
        //CFT
        startDate = new Date(this.statementYear, this.statementMonth - 1, 1);
        endDate = new Date(this.statementYear, this.statementMonth - 1, 2);
      }

      if (startDate > endDate) {
        this.$swal(
          "Warning!",
          "End date must be greater than start date!",
          "warning"
        );

        return;
      }

      let payload = {
        CustomerId: this.Customer.Id,
        OfficeId: this.Customer.OfficeId,
        StartDate: startDate.toLocaleDateString("en-US"),
        EndDate: endDate.toLocaleDateString("en-US"),
      };
      let err, result;

      [err, result] = await this.$store.dispatch(types.GET_STATEMENT, payload);
      if (result) {
        let fileName = `statement-customer-${
          this.Customer.Id
        }-date-${startDate.toLocaleDateString(
          "en-US"
        )}-${endDate.toLocaleDateString("en-US")}.pdf`;
        this.saveByteArray(fileName, result);
      }
    },
    saveByteArray(name, byte) {
      let blob = new Blob([byte], { type: "application/pdf" });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      let fileName = name;
      link.download = fileName;
      link.click();
    },
    added() {
      this.clicked = true;
    },
    async getGcsNotes(isRefresh) {
      if (this.gcsNoteList.length > 0 && !isRefresh) return;
      let err, result;
      this.isLoading = true;
      [err, result] = await this.$store.dispatch(types.GET_GCS_NOTES, {
        CustomerId: this.Customer.Id,
        OfficeId: this.Customer.OfficeId,
      });
      this.isLoading = false;
      if (result && result.Data) {
        this.gcsNoteList = deepCopy(result.Data);
      }
    },
    async getDrafts(isRefresh) {
      if (this.draftList.length > 0 && !isRefresh) return;

      let err, result;
      this.isLoading = true;
      [err, result] = await this.$store.dispatch(types.GET_DRAFTS, {
        CustomerId: this.Customer.Id,
        OfficeId: this.Customer.OfficeId,
      });
      this.isLoading = false;
      if (result && result.Data) {
        this.draftList = deepCopy(result.Data);
      }
    },
    async getFees(isRefresh) {
      if (this.feeList.length > 0 && !isRefresh) return;

      let err, result;
      this.isLoading = true;
      [err, result] = await this.$store.dispatch(types.GET_FEES, {
        CustomerId: this.Customer.Id,
        OfficeId: this.Customer.OfficeId,
      });
      this.isLoading = false;
      if (result && result.Data) {
        this.feeList = deepCopy(result.Data);
      }
    },
    async getPayments(isRefresh) {
      if (this.paymentList.length > 0 && !isRefresh) return;
      let err, result;
      this.isLoading = true;
      [err, result] = await this.$store.dispatch(types.GET_PAYMENTS, {
        CustomerId: this.Customer.Id,
        OfficeId: this.Customer.OfficeId,
      });
      this.isLoading = false;
      if (result && result.Data) {
        this.paymentList = deepCopy(result.Data);
      }
    },
    async getCharges(isRefresh) {
      if (this.chargeList.length > 0 && !isRefresh) return;
      let err, result;
      this.isLoading = true;
      [err, result] = await this.$store.dispatch(types.GET_CHARGES, {
        CustomerId: this.Customer.Id,
        OfficeId: this.Customer.OfficeId,
      });
      this.isLoading = false;
      if (result && result.Data) {
        this.chargeList = deepCopy(result.Data);
      }
    },
    async getDeposits(isRefresh) {
      if (this.depositList.length > 0 && !isRefresh) return;

      let err, result;
      this.isLoading = true;
      [err, result] = await this.$store.dispatch(types.GET_DEPOSITS, {
        CustomerId: this.Customer.Id,
        OfficeId: this.Customer.OfficeId,
      });
      this.isLoading = false;
      if (result && result.Data) {
        this.depositList = deepCopy(result.Data);
      }
    },
    async getTransactions(isRefresh) {
      if (this.transactionList.length > 0 && !isRefresh) return;

      let err, result;
      this.isLoading = true;
      [err, result] = await this.$store.dispatch(types.GET_TRANSACTIONS, {
        CustomerId: this.Customer.Id,
        OfficeId: this.Customer.OfficeId,
      });
      this.isLoading = false;
      if (result && result.Data) {
        this.transactionList = deepCopy(result.Data);
      }
    },
    async getDocuments(isRefresh) {
      if (this.documentList.length > 0 && !isRefresh) return;

      let err, result;
      this.isLoading = true;
      [err, result] = await this.$store.dispatch(types.GET_DOCUMENTS, {
        CustomerId: this.Customer.Id,
        OfficeId: this.Customer.OfficeId,
      });
      this.isLoading = false;
      if (result && result.Data) {
        this.documentList = deepCopy(result.Data);
      }
    },
    async getCashFlow(isRefresh) {
      if (this.cashFlowList.length > 0 && !isRefresh) return;

      let err, result;
      this.isLoading = true;
      [err, result] = await this.$store.dispatch(types.GET_NEW_CASHFLOW, {
        CustomerId: this.Customer.Id,
        OfficeId: this.Customer.OfficeId,
      });
      this.isLoading = false;
      if (result && result.Data) {
        if (result.Data) {
          this.cashFlowList = deepCopy(result.Data.CashFlows);
          this.cashFlowListOldData = deepCopy(result.Data.CashFlows);
          this.ongoingSettlementsList = deepCopy(
            result.Data.OngoingSettlements
          );
        }
      }
      this.showOngoingSettlements = false;
    },

    async changeTab(tableName, isRefresh = false) {
      this.activeItem = tableName;
      switch (tableName) {
        case TABLE_TYPES.INFORMATION:
          this.bindInformationTab(isRefresh);
          break;

        case TABLE_TYPES.FEES:
          await this.getFees(isRefresh);
          break;
        case TABLE_TYPES.NOTES:
          await this.getGcsNotes(isRefresh);

          break;

        case TABLE_TYPES.DRAFTS:
          await this.getDrafts(isRefresh);

          break;
        case TABLE_TYPES.PAYMENTS:
          await this.getPayments(isRefresh);

          break;
        case TABLE_TYPES.CHARGES:
          await this.getCharges(isRefresh);
          break;

        case TABLE_TYPES.DEPOSIT:
          await this.getDeposits(isRefresh);

          break;

        case TABLE_TYPES.TRANSACTION:
          await this.getTransactions(isRefresh);

          break;

        case TABLE_TYPES.STATEMENT:
          break;
        case TABLE_TYPES.DOCUMENTS:
          await this.getDocuments(isRefresh);

          break;
        case TABLE_TYPES.CASHFLOW:
          await this.getCashFlow(isRefresh);
          break;

        default:
          break;
      }
    },
  },
};
</script>

//
<style lang="scss">
.gcsTabsTableScroll {
  overflow-y: auto;
  overflow-x: auto;
}
.textRed {
  color: red;
}
</style>
