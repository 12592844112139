import types from "./types";
import api from "@/services/admin/api";
import to from "@/helpers/to";
export default {
  state: {
    companyUiSettings: [],
  },
  actions: {
    INSERT_UI_SETTING: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.insertCompanyUiSetting(payload));
      return [err, result];
    },

    UPDATE_UI_SETTING: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.updateCompanyUiSetting(payload));
      return [err, result];
    },

    GET_ALL_UI_SETTINGS: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.getCompanyUiSettings(payload));
      if (result) {
        commit(types.SET_ALL_UI_SETTINGS, result.Data);
      }
    },
  },
  mutations: {
    SET_ALL_UI_SETTINGS: (state, payload) => {
      state.companyUiSettings = payload;
    },
  },
};
