<template>
  <div :style="'height:' + panelHeight + 'px;'" style="overflow: hidden">
    <div class="pt-2 pr-2 pb-2 pl-2">Last 5 Dispositions</div>
    <div class="last5Table">
      <section>
        <o-table
          :data="isEmpty ? [] : last5Dispositions"
          :striped="isStriped"
          :hoverable="isHoverable"
          :loading="isLoading"
        >
          <template>
            <o-table-column
              field="ScreenName"
              label="CreatedBy"
              width="20%"
              sortable
              v-slot="props"
              >{{ props.row.ScreenName }}</o-table-column
            >
            <o-table-column
              field="Name"
              label="Name"
              width="25%"
              sortable
              v-slot="props"
            >
              {{ props.row.Name }}
            </o-table-column>

            <o-table-column
              field="CommentText"
              label="Comment"
              width="35%"
              sortable
              v-slot="props"
              >{{ props.row.CommentText }}</o-table-column
            >

            <o-table-column
              field="DispositionDate"
              label="Date"
              centered
              width="20%"
              sortable
              v-slot="props"
              >{{ formatDate(props.row.DispositionDate) }}</o-table-column
            >
          </template>
        </o-table>
        <section
          v-if="last5Dispositions.length == 0"
          class="section"
          style="text-align: center"
        >
          <div class="content has-text-grey has-text-centered">
            <p class="noMargin">
              <i
                style="font-size: 24px; color: #939393"
                class="ri-question-line"
              ></i>
            </p>
            <p class="noMargin">Nothing here.</p>
          </div>
        </section>
      </section>
    </div>
  </div>
</template>
<script>
import globalTypes from "@/store/types";
import formatMixin from "@/mixins/formatMixin";
export default {
  name: "Last5Dispositions",
  mixins: [formatMixin],
  props: {
    customerId: {
      type: Number,
      default: () => 0,
    },
    panelHeight: {
      type: Number,
      default: () => 250,
    },
  },
  data() {
    return {
      last5Dispositions: [],
      isStriped: true,
      isHoverable: true,
      isLoading: false,
      isEmpty: false,
    };
  },
  async mounted() {
    await this.bindLogs();
  },
  methods: {
    async bindLogs() {
      if (this.customerId && this.customerId > 0) {
        let err, result;
        [err, result] = await this.$store.dispatch(
          globalTypes.GET5_DISPOS,
          this.customerId
        );
        if (result && result.Data) {
          this.last5Dispositions = result.Data;
        }
      }
    },
  },
};
</script>
