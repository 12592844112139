<template>
  <div class="admin-page">
    <div v-if="isLoading" class="loading">
      <div class="text-center" style="margin-top: 350px">
        <i class="spinner-grow text-info m-2"></i>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 panelWrapper noMargin">
        <div class="panelContent" style="padding: 0 20px">
          <div class="form-group row">
            <div class="col-md-12">
              <label class="generalLabel">Outbound Call Did</label>
              <select
                v-model="outboundCallMethodId"
                id="toStatus"
                class="form-control"
              >
                <option :value="0">Please Select</option>
                <option
                  v-for="item in sortedDidassignType"
                  :key="item.Id"
                  :value="item.Id"
                >
                  {{ item.Name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-12 text-right">
            <button
              @click="updateOutboundCallMethod()"
              type="button"
              style="width: 200px"
              class="btn btn-success btn-md waves-effect waves-light mt-2 mr-3"
              :disabled="isLoading"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import _orderby from "lodash";
export default {
  name: "OutboundCallSettingsManagement",
  data() {
    return {
      outboundCallMethodId: 0,
      oldCallMethodId: 0,
      isLoading: false,
    };
  },
  computed: mapState({
    didassignType: (state) =>
      state.outboundCallSettingsManagement.didassignType,
    sortedDidassignType() {
      return _orderby.orderBy(this.didassignType, "Name", "asc");
    },
  }),
  async mounted() {
    await this.$store.dispatch(types.GET_DIDASSIGN_TYPE_OCSM);
    let err, result;
    [err, result] = await this.$store.dispatch(
      types.GET_OUTBOUND_CALL_SETTINGS_OCSM
    );

    if (result) {
      this.outboundCallMethodId = result.Data.DidAssignTypeId;
      this.oldCallMethodId = result.Data.DidAssignTypeId;
    }
  },
  methods: {
    async updateOutboundCallMethod() {
      this.isLoading = true;
      if (this.oldCallMethodId != this.outboundCallMethodId) {
        let err, result;
        [err, result] = await this.$store.dispatch(
          types.UPDATE_OUTBOUND_CALL_METHOD_OCSM,
          this.outboundCallMethodId
        );
        if (result) {
          this.$swal.fire("Success!", "Settings updated.", "success");

          this.oldCallMethodId = this.outboundCallMethodId;
        }
      } else {
        this.$swal.fire("Warning!", "No changes detected.", "warning");
      }
      this.isLoading = false;
    },
  },
};
</script>

<style></style>
