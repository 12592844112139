import types from "./types";
import api from "@/services/admin/api";
import customerApi from "@/services/client/customerApi";
import to from "@/helpers/to";
export default {
  state: {
    isOnline: false,
    isOnCall: false,
    sipUri: "",
    outboundDids: [],
    employees: [],
    endPointList: [],
    dispositionList: [],
    availableVmDrops: [],
    negotiatorContacts: [],
    announceDepartments: [],
  },
  actions: {
    ASSIGN_CUSTOMER_FROM_PHONE_PANEL: async ({ commit },payload) => {
      let err, result;

      [err, result] = await to(api.assignCustomerFromPhonePanel(payload));

      return [err, result];
    },
    GET_3RD_PARTY_LIST: async ({ commit }) => {
      let err, result;

      [err, result] = await to(api.get3rdPartyList());

      return [err, result];
    },

    GET_ANNOUNCE_DEPARTMENT_DIDS: async ({ commit, state }, payload) => {
      if (state.announceDepartments.length > 0) return;

      let err, result;

      [err, result] = await to(api.getAnnounceCallDepartments(payload));
      if (result && result.Data) {
        commit(types.SET_ANNOUNCE_DEPARTMENT_DIDS, result.Data);
      }
    },
    CONVERT_MAILER_DUMP_TO_CUSTOMER: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.convertMailerDump(payload));

      return [err, result];
    },
    GET_ANNOUNCE_CALL_LOG: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.getAnnounceCallLog(payload));

      return [err, result];
    },
    INSERT_ANNOUNCE_CALL_LOG: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.insertAnnounceCallLog(payload));

      return [err, result];
    },
    UPDATE_ANNOUNCE_CALL_LOG: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.updateAnnounceCallLog(payload));

      return [err, result];
    },
    SEND_INCOMING_CALL_LOG: async ({ commit }, payload) => {
      await to(api.sendIncomingCallLog(payload));
    },
    DELETE_CONFERENCE: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.deleteConference(payload));

      return [err, result];
    },
    TRANSFER_BOTH_SIDE: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.transferBothSideToConference(payload));

      return [err, result];
    },
    KICK_CONFERENCE: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.kickMemberFromConference(payload));

      return [err, result];
    },
    GET_ENDPOINT: async ({ commit }) => {
      let err, result;

      [err, result] = await to(api.getEndPoint());
      if (result && result.Data) {
        commit(types.SET_ENDPOINT, result.Data);
      }
    },
    GET_ENDPOINT_LIST: async ({ commit }) => {
      let err, result;
      [err, result] = await to(api.getEndPointList());
      if (result && result.Data) {
        commit(types.SET_ENDPOINT_LIST, result.Data);
      }
    },
    GET_DID_LIST: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.getCompanyDids(payload));

      return result;
    },
    GET_EMPLOYEES: async ({ commit, state }, payload) => {
      let err, result;
      if (state.employees.length > 0) return;
      [err, result] = await to(
        api.getEmployees(payload.retired, payload.office, payload.role)
      );
      if (result) {
        commit(types.SET_EMPLOYEES, result.Data);
      }
    },
    GET_CALLER_ID_ENDPOINT: async ({ commit }) => {
      let err, result;

      [err, result] = await to(api.getCallerIdEndPoint());

      return result;
    },
    GET_DETAILS_BY_PHONE: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.getDetailsByPhoneNumber(payload));

      return [err, result];
    },
    HOLD_CALL: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.holdCall(payload));

      return result;
    },
    GRAB_CALL: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.grabCall(payload));

      return result;
    },
    GET_DISPOSITIONS: async ({ commit }, customerId) => {
      let err, result;

      [err, result] = await to(customerApi.getDispositions(customerId));

      if (result) {
        commit(types.SET_DISPOSITIONS, result.Data);
      }
    },
    TRANSFER_TO_AGENT: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.transferToAgent(payload));

      if (result) {
        return result;
      }
    },
    TRANSFER_TO_DID: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.transferToDid(payload));

      if (result) {
        return result;
      }
    },
    GET_AVAILABLE_VM_DROPS: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.getAvailableVMDrops(payload));
      if (result) {
        commit(types.SET_AVAILABLE_VM_DROPS, result.Data);
      }
    },
    TRANSFER_TO_VM_DROP: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.transferToVmDrop(payload));

      if (result) {
        return result;
      }
    },
    TRANSFER_TO_AGENT_VM: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.transferToVmBox(payload));

      if (result) {
        return result;
      }
    },
    TRANSFER_TO_INGROUP: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.transferToIngroup(payload));

      if (result) {
        return result;
      }
    },
    INSERT_MANDATORY_DISPO: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.insertMandatoryDispo(payload));

      if (result) {
        return result;
      }
    },
    GET_MANDATORY_DISPO: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.getMandatoryDispo(), {});
      if (result) {
        return result;
      }
    },
    INSERT_CUSTOMER_DISPO_LOG: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(customerApi.insertDispositionV2(payload));
      return [err, result];
    },
    NEXT_CALL_LIST: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(customerApi.getCustomers(payload));

      if (result) {
        return result;
      }
    },
    RESET_STATES: async ({ commit }) => {
      commit(types.SET_RESET_STATES);
    },
    GET_CAMPAIGN_LEADS: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.getCampaignLeadsV2(payload));

      return [err, result];
    },
    GET_LIVE_CONFERENCE_NAMES: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.getLiveConferenceNames(payload));

      return [err, result];
    },
    TRANSFER_TO_CONFERENCE: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.transferToConference(payload));

      return [err, result];
    },
    KICK_3RD_PARTY: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.kick3rdParty(payload));

      return [err, result];
    },
    TRANSFER_TO_NEW_CONFERENCE: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.transferToNewConference(payload));

      return [err, result];
    },
    GET_CREDITOR_CONTACTS: async ({ commit, state }, payload) => {
      let forceRefresh = false;
      if (payload && payload.ForceRefresh) {
        forceRefresh = payload.ForceRefresh;
      }

      if (!forceRefresh && state.negotiatorContacts.length > 0) return;

      let err, result;

      [err, result] = await to(api.getAllCreditorContact({}));

      if (result) {
        commit(types.SET_CREDITOR_CONTACTS, result.Data);
      }
    },
    INSERT_CREDITOR_CONTACT: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.createCreditorContact(payload));

      return [err, result];
    },
    UPDATE_CREDITOR_CONTACT: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.updateCreditorContact(payload));

      return [err, result];
    },
    DELETE_CREDITOR_CONTACT: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.deleteCreditorContact(payload));

      return [err, result];
    },
    REQUEST_CSR_TASK: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(customerApi.distributeCsrTask(payload));

      return [err, result];
    },
    CHECK_CAN_REQUEST_TASK: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(customerApi.checkCanRequestTask(payload));

      return [err, result];
    },
    GET_CALLER_ID_BY_CUSTOMER: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.getCallerId(payload));

      return [err, result];
    },
    RESET_CAMPAIGN_LEADS: async ({ commit }) => {
      commit(types.SET_RESET_CAMPAING_LEADS, []);
    },
  },
  mutations: {
    SET_ENDPOINT: (state, payload) => {
      state.isOnline = payload.IsOnline;
      state.isOnCall = payload.IsOnCall;
      state.sipUri = payload.SipUri;
    },
    SET_EMPLOYEES: (state, payload) => {
      state.employees = payload;
    },
    SET_ENDPOINT_LIST: (state, payload) => {
      state.endPointList = payload;
    },
    SET_DISPOSITIONS: (state, payload) => {
      state.dispositionList = payload;
    },
    SET_AVAILABLE_VM_DROPS: (state, payload) => {
      state.availableVmDrops = payload;
    },
    SET_RESET_STATES: (state) => {
      state.employees = [];
      state.negotiatorContacts = [];
    },
    SET_CAMPAIGN_LEADS: (state, payload) => {
      state.campaignLeads = payload;
    },
    SET_CREDITOR_CONTACTS: (state, payload) => {
      state.negotiatorContacts = payload;
    },
    SET_ANNOUNCE_DEPARTMENT_DIDS: (state, payload) => {
      state.announceDepartments = payload;
    },
  },
};
