<template>
  <div class="col-md-12">
    <ReportSummary
      :exportData="reportData"
      :reportName="'CustomerDispositionSettingsReport'"
      :recordCount="recordCount"
      @updateLoadingStatus="reportLoading = $event"
      @clearReport="clearReport"
      @setPagination="setPagination"
      @refreshReport="refreshReport"
      @viewChanged="viewChanged"
      :loadingSpinner="loadingSpinner"
    />
    <div v-if="view == 'graphView'" class="col-md-12">
      <label class="generalLabel" for="">Visualize Option: </label>
      <select
        v-model="graphVisualizeOption"
        class="generalSelectBox ml-2"
        @change="graphVisualizationChanged"
        style="width: 200px !important"
      >
        <option value="Contained">Contained</option>
        <option value="All">All</option>
      </select>
    </div>
    <div
      v-if="view == 'gridView'"
      class="datagrid-table gcsTabsTableScroll report-wrapper"
      :style="'max-height: ' + reportsPanelHeight + 'px; overflow-y:auto;'"
    >
      <o-table
        :data="filteredCustomerDispositionSettingsReport"
        :paginated="
          isPaginated && filteredCustomerDispositionSettingsReport.length > 0
        "
        :per-page="perPage"
        :pagination-simple="isPaginationSimple"
        :pagination-position="paginationPosition"
        :sticky-header="true"
      >
        <o-table-column
          field="Disposition"
          label="Disposition"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="DispositionFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.Disposition }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.Disposition }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="FromStatuses"
          label="From Statuses"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="FromStatusesFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.FromStatuses }}
          </template>
        </o-table-column>

        <o-table-column field="ToStatus" label="To Status" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="ToStatusFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.ToStatus }}
          </template>
        </o-table-column>

        <o-table-column field="Roles" label="Roles" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="RolesFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.Roles }}
          </template>
        </o-table-column>
      </o-table>
      <section
        v-if="filteredCustomerDispositionSettingsReport.length == 0"
        class="section"
        style="text-align: center"
      >
        <div class="content has-text-grey has-text-centered mb-4">
          <p class="noMargin">
            <i
              style="font-size: 24px; color: #939393"
              class="ri-question-line"
            ></i>
          </p>
          <p class="noMargin">Nothing here.</p>
        </div>
      </section>
    </div>
    <div class="datagrid-table" v-if="view == 'graphView'">
      <iframe
        v-if="showIframe"
        style="border: none !important"
        width="100%"
        height="1000"
        :src="iframeSrc"
        @load="onLoad"
      ></iframe>
    </div>
  </div>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import ReportSummary from "./ReportSummary.vue";
import utilitiesMixin from "@/mixins/utilitiesMixin";

export default {
  name: "CustomerDispositionSettingsReport",
  components: { ReportSummary },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      view: "gridView",
      iframeSrc: "smcat.html",
      reportsPanelHeight: 300,
      loadingSpinner: false,
      isPaginationSimple: false,
      isPaginated: true,
      paginationPosition: "both",
      currentPage: 1,
      perPage: 200,

      recordCount: 0,
      reportData: [],
      reportLoading: false,

      customerDispositionSettingsReportlInitSummary: {
        Disposition: "",
        FromStatuses: "",
        ToStatus: "",
        Roles: "",
      },
      DispositionFilter: "",
      FromStatusesFilter: "",
      ToStatusFilter: "",
      RolesFilter: "",
      graphData: {
        Visualize: "Contained",
        Data: null,
      },
      graphVisualizeOption: "Contained",
      statusList: [],
      showIframe: true,
    };
  },
  computed: mapState({
    dispositionSettingsList: (state) => state.reports.dispositionSettingsList,

    filteredCustomerDispositionSettingsReport() {
      let rtn = [];

      let tmpList =
        this.dispositionSettingsList.length > 0
          ? this.dispositionSettingsList.filter((item) => {
              return (
                this.filterString(item.Disposition, this.DispositionFilter) &&
                this.filterString(item.FromStatuses, this.FromStatusesFilter) &&
                this.filterString(item.ToStatus, this.ToStatusFilter) &&
                this.filterString(item.Roles, this.RolesFilter)
              );
            })
          : [];

      if (tmpList.length == 0) {
        this.reportData = [];
        return rtn;
      }
      let totalDispo = tmpList.length.toString();

      this.customerDispositionSettingsReportlInitSummary.Disposition =
        "Total: " + totalDispo;
      this.recordCount = tmpList.length;
      this.customerDispositionSettingsReportlInitSummary.IsSummary = true;

      rtn.push(...tmpList);
      rtn.push(this.customerDispositionSettingsReportlInitSummary);
      this.reportData = rtn;

      this.graphData = {
        Visualize: this.graphVisualizeOption,
        Data: tmpList,
      };

      return rtn;
    },
  }),
  async mounted() {
    this.reportsPanelHeight = this.setReportGridHeight(
      window.innerHeight,
      document.getElementById("reportsSummary")
    );
    await this.sleep(1000);
    this.fixStickyHeaders();
  },
  methods: {
    async graphVisualizationChanged() {
      this.graphData.Visualize = this.graphVisualizeOption;
      this.showIframe = false;
      await this.sleep(500);
      this.showIframe = true;
    },
    onLoad(frame) {
      frame.srcElement.contentWindow.initialize(this.graphData);
    },
    viewChanged(data) {
      if (data) {
        this.view = data;
      }
    },
    setPagination(data) {
      this.isPaginated = data;
    },
    async refreshReport(value) {
      this.loadingSpinner = true;
      await this.$store.dispatch(types.GET_DISPOSITION_SETTINGS_REPORT, value);
      this.loadingSpinner = false;
      await this.sleep(500);
      this.fixStickyHeaders();
    },
    clearReport() {
      this.$store.dispatch(types.RESET_REPORTS);
      this.reportData = [];
    },
  },
};
</script>

<style scoped></style>
