<template>
  <div class="col-md-12">
    <div v-if="loading" class="ticketDashboardLoading">
      <div class="text-center" style="margin-top: 15%">
        <i class="spinner-grow text-info m-2"></i>
      </div>
    </div>
    <div class="row" style="background: gray">
      <div style="margin-top: -50px; margin-left: 170px">
        <span
          @click="refreshTicketDashboard()"
          style="
            font-size: 1.4em;
            font-weight: bold;
            padding: 8px;
            cursor: pointer;
          "
          class="text-primary"
        >
          <i class="fe-refresh-cw"></i>
        </span>
      </div>
    </div>

    <div
      v-if="checkAuth(200030)"
      style="display: flex; justify-content: flex-start; gap: 5px"
    >
      <label>Filters Enabled:</label>
      <input
        @change="isEnabledCBox"
        type="checkbox"
        class="generalCheckBox mobilMargin"
        style="margin-bottom: 7px"
        v-model="isFiltered"
      />
    </div>

    <div class="filter mb-2" v-if="checkAuth(200030)">
      <label v-if="isFiltered">Start Date:</label>
      <DatePicker
        v-if="isFiltered"
        mode="date"
        :locale="'en-US'"
        v-model="startDate"
      >
        <template v-slot="{ inputValue, inputEvents }">
          <input
            class="form-control"
            :value="inputValue"
            v-on="inputEvents"
            placeholder="Start Date"
          />
        </template>
      </DatePicker>
      <label v-if="isFiltered">End Date:</label>
      <DatePicker
        v-if="isFiltered"
        mode="date"
        :locale="'en-US'"
        v-model="endDate"
      >
        <template v-slot="{ inputValue, inputEvents }">
          <input
            class="form-control"
            :value="inputValue"
            v-on="inputEvents"
            placeholder="End Date"
          />
        </template>
      </DatePicker>
      <label v-if="isFiltered">Role:</label>
      <select
        v-if="isFiltered"
        v-model="role"
        class="form-control filterSelect"
        @change="resetUser"
      >
        <option :value="0">All Roles</option>
        <option v-for="item in sortedRoles" :key="item.Id" :value="item.Id">
          {{ item.Name }}
        </option>
      </select>
      <label v-if="isFiltered">User:</label>
      <select
        v-if="isFiltered"
        v-model="user"
        class="form-control filterSelect"
        @change="resetRole"
      >
        <option :value="0">All Users</option>
        <option v-for="item in allEmployees" :key="item.Id" :value="item.Id">
          {{ item.Name }}
        </option>
      </select>
      <button
        v-if="isFiltered"
        @click="refreshTicketDashboard()"
        class="btn btn-primary refreshButton"
      >
        <i class="fe-refresh-cw"></i>
      </button>
    </div>

    <div
      class="row text-center justify-content-center"
      v-if="ticketDashboardData"
    >
      <div class="col-xl-4 col-lg-6 col-md-12 col-12 col-xs-12">
        <div class="card border border-primary mb-4">
          <div class="card-header bg-primary text-white">Due Today</div>
          <div
            class="card-body text-primary"
            style="background-color: ##fdfdfd"
          >
            <div class="row">
              <div class="col-md-6 text-center">
                <div class="row">
                  <div class="col-md-12">
                    <span class="pointCircle">{{
                      ticketDashboardData.TodayTaskCount
                    }}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 text-center pointLabel">
                    Assigned to me
                  </div>
                </div>
              </div>
              <div class="col-md-6 text-center">
                <div class="row">
                  <div class="col-md-12">
                    <span class="pointCircle">{{
                      ticketDashboardData.TodayRoleCount
                    }}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 text-center pointLabel">Total Due</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-lg-6 col-md-12 col-12 col-xs-12">
        <div class="card border border-danger mb-4">
          <div class="card-header bg-danger text-white">Past Due</div>
          <div
            class="card-body text-primary"
            style="background-color: ##fdfdfd"
          >
            <div class="row">
              <div class="col-md-6 text-center">
                <div class="row">
                  <div class="col-md-12">
                    <span class="pointCircle pointCircleDue dueColor">{{
                      ticketDashboardData.PassTaskCount
                    }}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 text-center pointLabel">
                    Assigned to me
                  </div>
                </div>
              </div>
              <div class="col-md-6 text-center">
                <div class="row">
                  <div class="col-md-12">
                    <span class="pointCircle pointCircleDue dueColor">{{
                      ticketDashboardData.PassRoleCount
                    }}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 text-center pointLabel">
                    Total Past Due
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="checkAuth(200040)" class="filterBulk mb-2">
      <label>Bulk Operations:</label>
      <select
        @change="changeBulkRole"
        v-model="changeRoleSelect"
        class="form-control filterSelect"
      >
        <option :value="0">Assign Role</option>
        <option :value="null">Default Role</option>
        <option v-for="item in sortedRoles" :key="item.Id" :value="item.Id">
          {{ item.Name }}
        </option>
      </select>
      <label>-</label>

      <select
        @change="changeBulkAgent"
        v-model="changeUserSelect"
        class="form-control filterSelect"
      >
        <option :value="0">Assign Agent</option>
        <option :value="null">Unassign</option>
        <option v-for="item in allEmployees" :key="item.Id" :value="item.Id">
          {{ item.Name }}
        </option>
      </select>
      <label>-</label>
      <DatePicker
        mode="date"
        :locale="'en-US'"
        v-model="changeDueDate"
        :min-date="new Date()"
      >
        <template v-slot="{ inputValue, inputEvents }">
          <input
            class="form-control"
            :value="inputValue"
            v-on="inputEvents"
            placeholder="Change Due Date"
          />
        </template>
      </DatePicker>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div
          class="datagrid-table gcsTabsTableScroll report-wrapper"
          style="overflow-y: auto"
        >
          <div
            v-if="ticketGridData.length != 0"
            style="
              width: 22px;
              height: 50px;
              position: absolute;
              top: 0px;
              left: 29px;
              z-index: 40;
            "
          >
            <input
              @change="selectAllTickets()"
              :checked="allTicketSelected"
              type="checkbox"
              class="checkbox-input generalCheckBox"
              style="
                border-radius: 4px !important;
                border: solid 1px #d1d1d1 !important;
                float: left;
                margin-top: 80%;
              "
            />
          </div>

          <o-table :data="ticketGridData">
            <o-table-column field="Assign" label="" v-slot="props">
              <input
                type="checkbox"
                @change="selectTicket(props.row.TicketId)"
                :checked="isTicketSelected(props.row.TicketId)"
                class="checkbox-input generalCheckBox"
                style="
                  border-radius: 4px !important;
                  border: solid 1px #d1d1d1 !important;
                  vertical-align: text-top;
                "
              />
            </o-table-column>

            <o-table-column field="Star" label="" sortable>
              <template v-slot="props">
                <i
                  style="font-size: 20px; color: orange"
                  v-if="props.row.Star == true"
                  class="ri-star-s-fill"
                ></i>
              </template>
            </o-table-column>
            <o-table-column field="TicketId" label="Ticket #" sortable>
              <template v-slot="props">
                <span
                  class="badge badge-info"
                  style="cursor: pointer; padding: 5px; font-size: 12px"
                  @click="
                    openTask(props.row.TicketId, props.row.AssignedUserId)
                  "
                >
                  {{ props.row.TicketId }}
                </span>
              </template>
            </o-table-column>
            <o-table-column field="Priority" label="Priority" sortable>
              <template v-slot="props">
                {{ props.row.Priority }}
              </template>
            </o-table-column>

            <o-table-column field="Type" label="Type" sortable>
              <template v-slot="props">{{ props.row.Type }} </template>
            </o-table-column>
            <o-table-column field="CustomerName" label="Customer Name" sortable>
              <template v-slot="props">
                {{ props.row.CustomerName }}
              </template>
            </o-table-column>
            <o-table-column field="" label="Assigned To" sortable>
              <template v-slot="props">
                {{ getAssignedText(props.row) }}
              </template>
            </o-table-column>
            <o-table-column field="DueDate" label="Due Date" sortable>
              <template v-slot="props">
                {{ formatGridDate(props.row.DueDate) }}
              </template>
            </o-table-column>
            <o-table-column field="LastAction" label="Last Action" sortable>
              <template v-slot="props">
                {{ formatGridDate(props.row.LastAction) }}
              </template>
            </o-table-column>
          </o-table>
          <div v-if="loadingSpinner" class="text-center" style="margin: 6em 0">
            <i class="spinner-border avatar-lg text-primary m-2"></i>
          </div>
          <section
            v-if="ticketGridData.length == 0 && !loadingSpinner"
            class="section"
            style="text-align: center"
          >
            <div class="content has-text-grey has-text-centered mb-4">
              <p class="noMargin">
                <i
                  style="font-size: 24px; color: #939393"
                  class="ri-question-line"
                ></i>
              </p>
              <p class="noMargin">Nothing here.</p>
            </div>
          </section>
        </div>
      </div>
    </div>
    <div class="row">
      <div
        class="backgroundLock"
        v-if="csrTaskMouduleOpen"
        style="-webkit-box-align: center; align-items: center; z-index: 400"
      >
        <div
          style="position: relative; margin: 80px auto; overflow-y: auto"
          :style="isMobileDevice() ? 'max-height:100%;' : 'max-height:40vmax;'"
          class="bg-white offset-xl-2 col-xl-8 col-lg-12 col-md-12 col-sm-12 col-xs-12"
        >
          <div
            class="exitButton"
            style="right: 22px; top: -5px; color: white"
            @click="closeTaskPopup()"
          >
            <i class="fas fa-window-close"></i>
          </div>
          <CsrTaskModule
            :ticketId="selectedTicketId"
            @closeTaskPopup="closeTaskPopup"
          ></CsrTaskModule>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import globalTypes from "@/store/types";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import formatMixin from "@/mixins/formatMixin";
import types from "./types";
import deepCopy from "@/helpers/deepCopy";
import CsrTaskModule from "@/views/agent/CsrTaskDistro/CsrTaskDistro";
import { DatePicker } from "v-calendar";
import _orderby from "lodash";

export default {
  name: "CsrTickets",
  components: { CsrTaskModule, DatePicker },
  mixins: [utilitiesMixin, formatMixin],
  data() {
    return {
      isFiltered: false,
      startDate: new Date(),
      endDate: null,
      role: 0,
      user: 0,
      changeRoleSelect: 0,
      changeUserSelect: 0,
      changeDueDate: null,
      userId: null,
      userRoles: [],
      loadingSpinner: false,
      loading: false,
      ticketDashboardData: null,
      ticketGridData: [],
      csrTaskMouduleOpen: false,
      selectedTicketId: 0,
      allTicketSelected: false,
      checkedTicketIds: [],
      ticketAccessRoles: [],
    };
  },

  computed: mapState({
    allEmployees: (state) => state.globals.allEmployees,
    sortedRoles() {
      return _orderby.orderBy(this.ticketAccessRoles, "Name", "asc");
    },
  }),

  unmounted() {
    this.$Emitter.off("updateTicketDashboard");
  },
  async mounted() {
    await Promise.all([
      this.$store.dispatch(globalTypes.GET_ALL_ACTIVE_EMPLOYEES),
      this.bindTicketAccessRoles(),
    ]);
    await this.refreshTicketDashboard();
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo) {
      this.userId = userInfo.userId;
      this.userRoles = userInfo.roles;
    }
    this.$Emitter.on("updateTicketDashboard", (data) => {
      if (data) {
        let task = null;
        if (data.TicketId) {
          let indx = this.ticketGridData.findIndex(
            (x) => x.TicketId == data.TicketId
          );
          if (indx > -1) {
            task = this.ticketGridData[indx];
          }
        }

        if (task != null) {
          if (data.UserId && data.UserId > 0) {
            if (this.userId != data.UserId) {
              this.ticketGridData = this.ticketGridData.filter(
                (x) => x.TicketId != task.TicketId
              );
            }
          } else if (data.Roles && data.Roles.length > 0) {
            let roleValid = data.Roles.some((item) =>
              this.userRoles.includes(item)
            );
            if (roleValid == false) {
              this.ticketGridData = this.ticketGridData.filter(
                (x) => x.TicketId != task.TicketId
              );
            }
          }
        }
      }
    });

    this.endDate = this.addDays(this.startDate, 1);
    this.role != 0;
  },
  watch: {
    changeDueDate(newVal, oldVal) {
      if (newVal != null) {
        this.changeBulkDate();
      }
    },
  },
  methods: {
    resetUser() {
      if (this.role != 0) {
        this.user = 0;
      }
    },
    resetRole() {
      if (this.user != 0) {
        this.role = 0;
      }
    },
    async bindTicketAccessRoles() {
      let err, result;

      [err, result] = await this.$store.dispatch(
        types.GET_TICKET_ACCESS_ROLES_FOR_TICKETS
      );
      if (result) {
        this.ticketAccessRoles = deepCopy(result.Data);
      }
    },
    getAssignedText(row) {
      let rtn = "Default";
      if (row.AssignedUserId != null && row.AssignedUserId > 0) {
        rtn = this.getEmployeeName(row.AssignedUserId);
      } else if (row.AssignedRoleId != null && row.AssignedRoleId > 0) {
        rtn = this.getRoleName(row.AssignedRoleId);
      }

      return rtn;
    },
    getEmployeeName(id) {
      let rtn = "";
      let indx = this.allEmployees.findIndex((x) => x.Id == id);
      rtn = indx > -1 ? this.allEmployees[indx].Name : "";
      return rtn;
    },
    getRoleName(id) {
      let rtn = "";
      let indx = this.ticketAccessRoles.findIndex((x) => x.Id == id);
      rtn = indx > -1 ? this.ticketAccessRoles[indx].Name : "";
      return rtn;
    },
    async isEnabledCBox() {
      await this.refreshTicketDashboard();
    },
    resetBulkAssignOperations() {
      this.changeUserSelect = 0;
      this.checkedTicketIds = [];
      this.allTicketSelected = false;
      this.changeRoleSelect = 0;
      this.changeDueDate = null;
    },
    changeBulkAgent() {
      if (this.changeUserSelect > 0 || this.changeUserSelect == null) {
        let agentIndex = this.allEmployees.findIndex(
          (x) => x.Id == this.changeUserSelect
        );
        let agentName =
          agentIndex > -1 ? this.allEmployees[agentIndex].Name : "";
        let message =
          this.checkedTicketIds.length > 0
            ? "Are you sure to change the assigned agent for " +
              this.checkedTicketIds.length +
              " ticket(s) to " +
              agentName +
              "?"
            : "No customer selected!";

        if (this.changeUserSelect == null) {
          message =
            "Are you sure you want to unassign " +
            this.checkedTicketIds.length +
            " ticket(s)?";
        }
        this.$swal
          .fire({
            title: "Confirm",
            text: message,
            type: "warning",
            showCancelButton: this.checkedTicketIds.length > 0 ? true : false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
          })
          .then(async (dialog) => {
            if (dialog.value) {
              if (
                this.checkedTicketIds.length > 0 &&
                (this.changeUserSelect > 0 || this.changeUserSelect == null)
              ) {
                let payload = {
                  CsrTaskIds: this.checkedTicketIds.join(","),
                  UserId: this.changeUserSelect,
                };

                let err,
                  result = await this.$store.dispatch(
                    types.UPDATE_BULK_USER,
                    payload
                  );

                if (result) {
                  this.$swal("Success", "", "success");
                  this.checkedTicketIds = [];
                  this.allTicketSelected = false;
                  await this.refreshTicketDashboard();
                } else {
                  let errMsg = this.getApiErrorMessage(err);
                  this.$swal("Error!", errMsg, "error");
                }

                this.resetBulkAssignOperations();
              } else {
                this.changeUserSelect = 0;
              }
            } else {
              this.changeUserSelect = 0;
            }
          });
      }
    },
    changeBulkRole() {
      if (this.changeRoleSelect > 0 || this.changeRoleSelect == null) {
        let agentRoleIndex = this.ticketAccessRoles.findIndex(
          (x) => x.Id == this.changeRoleSelect
        );
        let agentName =
          agentRoleIndex > -1
            ? this.ticketAccessRoles[agentRoleIndex].Name
            : "";
        let message =
          this.checkedTicketIds.length > 0
            ? "Are you sure you want to change the assigned role of " +
              this.checkedTicketIds.length +
              " ticket(s) to " +
              agentName +
              "?"
            : "No customer selected!";

        if (this.changeRoleSelect == null) {
          message =
            "Are you sure you want to set " +
            this.checkedTicketIds.length +
            " ticket(s) to default role?";
        }

        this.$swal
          .fire({
            title: "Confirm",
            text: message,
            type: "warning",
            showCancelButton: this.checkedTicketIds.length > 0 ? true : false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
          })
          .then(async (dialog) => {
            if (dialog.value) {
              if (
                this.checkedTicketIds.length > 0 &&
                (this.changeRoleSelect > 0 || this.changeRoleSelect == null)
              ) {
                let payload = {
                  CsrTaskIds: this.checkedTicketIds.join(","),
                  RoleId: this.changeRoleSelect,
                };

                let err,
                  result = await this.$store.dispatch(
                    types.UPDATE_BULK_ROLE,
                    payload
                  );

                if (result) {
                  this.$swal("Success", "", "success");
                  this.checkedTicketIds = [];
                  this.allTicketSelected = false;
                  await this.refreshTicketDashboard();
                } else {
                  let errMsg = this.getApiErrorMessage(err);
                  this.$swal("Error!", errMsg, "error");
                }

                this.resetBulkAssignOperations();
              } else {
                this.changeRoleSelect = 0;
              }
            } else {
              this.changeRoleSelect = 0;
            }
          });
      }
    },
    changeBulkDate() {
      if (this.changeDueDate > 0 || this.changeDueDate == null) {
        let message =
          this.checkedTicketIds.length > 0
            ? "Are you sure you want to change the due date of the selected " +
              this.checkedTicketIds.length +
              " ticket(s) to " +
              this.formatShortDate(this.changeDueDate) +
              " ?"
            : "No customer selected!";
        this.$swal
          .fire({
            title: "Confirm",
            text: message,
            type: "warning",
            showCancelButton: this.checkedTicketIds.length > 0 ? true : false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
          })
          .then(async (dialog) => {
            if (dialog.value) {
              if (
                this.checkedTicketIds.length > 0 &&
                (this.changeDueDate > 0 || this.changeDueDate == null)
              ) {
                let payload = {
                  CsrTaskIds: this.checkedTicketIds.join(","),
                  DueDate: new Date(this.changeDueDate),
                };

                let err,
                  result = await this.$store.dispatch(
                    types.UPDATE_BULK_DATE,
                    payload
                  );

                if (result) {
                  this.$swal("Success", "", "success");
                  this.checkedTicketIds = [];
                  this.allTicketSelected = false;
                  await this.refreshTicketDashboard();
                } else {
                  let errMsg = this.getApiErrorMessage(err);
                  this.$swal("Error!", errMsg, "error");
                }
                this.resetBulkAssignOperations();
              } else {
                this.changeDueDate = null;
              }
            } else {
              this.changeDueDate = null;
            }
          });
      }
    },

    selectAllTickets() {
      let ticketIds = this.ticketGridData.map((x) => x.TicketId);
      this.allTicketSelected = !this.allTicketSelected;
      if (ticketIds.length == this.checkedTicketIds.length) {
        this.checkedTicketIds = [];
      } else if (!this.allTicketSelected && this.checkedTicketIds.length > 0) {
        this.checkedTicketIds = [];
      } else {
        this.checkedTicketIds = [];
        for (let index = 0; index < ticketIds.length; index++) {
          this.checkedTicketIds.push(ticketIds[index]);
        }
      }
    },
    selectTicket(ticketId) {
      let indx = this.checkedTicketIds.findIndex((x) => x == ticketId);
      if (indx > -1) {
        this.checkedTicketIds.splice(indx, 1);
      } else {
        this.checkedTicketIds.push(ticketId);
      }
    },

    isTicketSelected(taskId) {
      return this.checkedTicketIds.findIndex((x) => x == taskId) > -1;
    },

    async closeTaskPopup() {
      this.csrTaskMouduleOpen = false;
      await this.sleep(2000);
      await this.refreshTicketDashboard();
    },
    async refreshTicketDashboard() {
      if (this.startDate != null && this.endDate != null) {
        if (new Date(this.startDate) > new Date(this.endDate)) {
          this.$swal(
            "Warning!",
            "End date must be greater than start date.",
            "warning"
          );
          return;
        }
      }

      this.loading = true;

      let payload = {
        UserId: this.user,
        RoleId: this.role,
        Option: "customdaterange",
        StartDate:
          this.startDate == null ? null : this.formatShortDate(this.startDate),
        EndDate:
          this.endDate == null ? null : this.formatShortDate(this.endDate),
        IsFiltered: this.isFiltered,
      };

      let err, result;
      [err, result] = await this.$store.dispatch(
        types.GET_CSR_TICKET_DASHBOARD,
        payload
      );
      if (result) {
        if (typeof result.Data != "undefined" && result.Data) {
          this.ticketDashboardData = deepCopy(result.Data);
          if (
            typeof result.Data.GridData != "undefined" &&
            result.Data.GridData
          ) {
            this.ticketGridData = deepCopy(result.Data.GridData);
          }
        }
      }
      await this.sleep(500);
      this.loading = false;
    },
    async openTask(ticketId, taskUserId) {
      this.csrTaskMouduleOpen = true;
      this.selectedTicketId = ticketId;
      if (taskUserId != this.userId) {
        if (this.checkAuth(200020)) {
          let err, result;
          [err, result] = await this.$store.dispatch(
            types.ASSIGN_TICKET_TO_ME,
            {
              Id: ticketId,
              AssignedUserId: this.userId,
            }
          );
        } else {
          this.$swal(
            "Warning",
            "You are not authorized to assign this task.",
            "warning"
          );
        }
      }
    },
  },
};
</script>
<style scoped>
.pointCircle {
  display: inline-block;
  min-width: 120px !important;
  max-width: 120px !important;
  max-height: 120px !important;
  overflow: hidden;
  border: 8px solid #3bafda;
  border-radius: 50%;
  padding: 35px 20px;
  font-size: 1.8em;
  font-weight: bold;
}
.pointCircleDue {
  border: 8px solid #f1556c;
}
.pointLabel {
  color: #333;
  font-size: 1.2em;
}
.dueColor {
  color: #f1556c !important;
}
.ticketDashboardLoading {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.6);
  z-index: 1000;
}

.filter {
  display: flex;
  align-items: flex-end;
  gap: 15px;
  justify-content: center;
}
.filterBulk {
  display: flex;
  align-items: flex-end;
  gap: 15px;
  justify-content: flex-start;
}

.filterSelect {
  width: 12% !important;
}

@media (max-width: 992px) {
  .filter {
    display: flow;
  }
  .filterBulk {
    display: flow;
  }
  .filterSelect {
    width: 100% !important;
  }
  .refreshButton {
    width: 100% !important;
    margin-top: 20px;
  }
  .mobilMargin {
    margin-left: 5px;
  }
}
</style>
