<template>
  <div class="account-pages mt-5 mb-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6 col-xl-5">
          <div class="card">
            <div class="card-body p-4">
              <div class="text-center w-75 m-auto">
                <a href="index.html">
                  <span>
                    <img :src="loginLogo" alt />
                  </span>
                </a>
              </div>
              <div class="form-group row">
                <div
                  class="col-md-12 mt-5"
                  style="
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                  "
                >
                  <label
                    style="font-size: 20px; margin-top: -15px; color: #61b4fa"
                    >NEW PASSWORD</label
                  >
                </div>

                <div class="col-md-12">
                  <label class="generalLabel">Password:</label>
                  <input
                    v-model="password"
                    class="form-control"
                    placeholder="Password"
                    @input="checkPasswordStrength"
                    type="password"
                    autocomplete="new-password"
                  />
                </div>
                <div class="col-md-12">
                  <label class="generalLabel" for="confirmPassword"
                    >Confirm Password:</label
                  >
                  <input
                    v-model="confirmPassword"
                    id="confirmPassword"
                    class="form-control"
                    type="password"
                    placeholder="Confirm Password"
                    autocomplete="off"
                  />
                </div>
                <div class="password-container col-md-12">
                  <div
                    class="password-strength-bar"
                    :class="passwordStrengthClass"
                  ></div>
                  <!-- <div class="password-strength-text">{{ passwordStrengthText }}</div> -->
                </div>
                <div class="col-md-12">
                  <div class="checkText">
                    <a class="ml-1">Password must include at least one:</a>
                    <a
                      class="ml-2"
                      :style="
                        passwordLength == false ? 'color:#9fa5aa' : 'color:grey'
                      "
                      ><i
                        :style="
                          passwordLength == false
                            ? 'color:#68c1e3'
                            : 'color:#9fa5aa'
                        "
                        class="fas fa-check-circle"
                      ></i>
                      Minimum length of the password is 8 characters</a
                    >
                    <a
                      class="ml-2"
                      :style="
                        hasLowerCase == true ? 'color:#9fa5aa' : 'color:grey'
                      "
                      ><i
                        :style="
                          hasLowerCase == true
                            ? 'color:#68c1e3'
                            : 'color:#9fa5aa'
                        "
                        class="fas fa-check-circle"
                      ></i>
                      Lower-case character</a
                    >
                    <a
                      class="ml-2"
                      :style="
                        hasUpperCase == true ? 'color:#9fa5aa' : 'color:grey'
                      "
                      ><i
                        :style="
                          hasUpperCase == true
                            ? 'color:#68c1e3'
                            : 'color:#9fa5aa'
                        "
                        class="fas fa-check-circle"
                      ></i>
                      Upper-case character</a
                    >
                    <a
                      class="ml-2"
                      :style="
                        hasNumber == true ? 'color:#9fa5aa' : 'color:grey'
                      "
                      ><i
                        :style="
                          hasNumber == true ? 'color:#68c1e3' : 'color:#9fa5aa'
                        "
                        class="fas fa-check-circle"
                      ></i>
                      Number</a
                    >

                    <a
                      class="ml-2"
                      :style="
                        hasSymbol == true ? 'color:#9fa5aa' : 'color:grey'
                      "
                      ><i
                        :style="
                          hasSymbol == true ? 'color:#68c1e3' : 'color:#9fa5aa'
                        "
                        class="fas fa-check-circle"
                      ></i>
                      Symbols: ( ) * ! @ # $ % - or _</a
                    >
                  </div>
                </div>

                <div class="col-md-12">
                  <button
                    @click="resetPsw()"
                    class="col-md-12 btn btn-primary"
                    style="background-color: #61b4fa"
                  >
                    Change Password
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utilitiesMixin from "@/mixins/utilitiesMixin";
import localStorageManagerMixin from "@/mixins/localStorageManagerMixin";
import useValidate from "@vuelidate/core";
import { email, required } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import api from "@/services/login.service";
import to from "@/helpers/to";

export default {
  name: "Login",
  mixins: [utilitiesMixin, localStorageManagerMixin],
  components: {},

  data() {
    return {
      v$: useValidate(),
      loading: false,
      expireUTC: "",
      loginLogo: "",

      passwordStrength: 0,
      password: "",
      confirmPassword: "",
      saving: false,
    };
  },
  methods: {
    checkPasswordStrength() {
      let strength = 0;
      if (/\d/.test(this.password)) {
        strength += 1;
      }
      if (/[A-Z]/.test(this.password)) {
        strength += 1;
      }
      if (/[()*!@#$%\-_]/g.test(this.password)) {
        strength += 1;
      }
      if (this.password.length >= 12) {
        strength += 1;
      }

      this.passwordStrength = strength;
    },
    async resetPsw() {
      if (this.password.length <= 0 || this.confirmPassword.length <= 0) {
        this.$swal(
          "Warning!",
          "Please fill in the password fields.",
          "warning"
        );
        return;
      }

      if (this.password != this.confirmPassword) {
        this.$swal("Warning!", "Passwords do not match.", "warning");
        return;
      }

      if (
        !this.passwordLength &&
        this.hasLowerCase &&
        this.hasUpperCase &&
        this.hasNumber &&
        this.hasSymbol
      ) {
        let currentPageURL = window.location.href;
        let tokenRegex = /token=([^&]+)/;
        let matches = currentPageURL.match(tokenRegex);

        if (matches && matches.length > 1) {
          let token = matches[1];

          let userPassword = await this.sha256(this.password);

          let payload = {
            Password: userPassword,
            Token: token,
          };
          let err, result;
          [err, result] = await to(api.resetPassword(payload));

          if (result) {
            this.$swal("Success!", result.Message, "Success");
            this.sleep(500);
            window.location.href = "/login";
          } else {
            let errMsg = this.getApiErrorMessage(err);
            this.$swal("Error!", errMsg, "error");
          }
        }
      } else {
        this.$swal(
          "Warning!",
          "Please make sure your password meets the requirements.",
          "warning"
        );
        return;
      }

      this.$emit("closeChangePasswordPopUp");
    },

    closePanel() {
      this.$emit("closeChangePasswordPopUp");
    },
  },

  computed: mapState({
    passwordStrengthClass() {
      if (this.passwordStrength == 0 || this.passwordStrength == 1) {
        return "password-strength-weak";
      } else if (this.passwordStrength == 2) {
        return "password-strength-fair";
      } else if (this.passwordStrength == 3) {
        return "password-strength-good";
      } else {
        return "password-strength-strong";
      }
    },
    passwordStrengthText() {
      if (this.passwordStrength === 0 || this.passwordStrength === 1) {
        return "Weak";
      } else if (this.passwordStrength === 2) {
        return "Fair";
      } else if (this.passwordStrength === 3) {
        return "Good";
      } else {
        return "Strong";
      }
    },
    passwordMatch() {
      return this.password !== this.confirmPassword;
    },
    passwordLength() {
      return this.password.length < 8;
    },
    hasUpperCase() {
      return /[A-Z]/.test(this.password);
    },
    hasLowerCase() {
      return /[a-z]/.test(this.password);
    },
    hasNumber() {
      return /\d/.test(this.password);
    },
    hasSymbol() {
      let regex = /[()*!@#$%\-_]/g;
      return regex.test(this.password);
    },
  }),
  async mounted() {
    this.crmVersion = process.env.VUE_APP_CRM_VERSION;
    this.loginLogo =
      this.$loginLogo +
      "?v=" +
      (Math.floor(Math.random() * 1000) + 1).toString();
    this.$emit("authenticated", false);
  },
  validations() {
    return {};
  },
};
</script>
<style></style>
