<template>
  <div class="col-md-12 newPanelWrapper noMargin">
    <div>
      <div>
        <label style="position: absolute; top: 5px; left: 15px; font-size: 15px"
          >Edit Documents</label
        >
      </div>
      <div class="closeButton" v-on:click.prevent.stop="closePanel">
        <i class="fas fa-window-close"></i>
      </div>
      <hr />
    </div>
    <perfect-scrollbar style="width: 100%; height: auto; max-height: 650px">
      <div style="margin-right: 15px" class="panelContent" v-if="newData">
        <Placeholders
          :typeId="0"
          :availablePlaceHolders="availablePlaceholder"
          @setPlaceholderVisibility="isPlaceholdersOpen = $event"
          @setPlaceholderContent="setPlaceholder($event)"
          v-if="isPlaceholdersOpen"
        ></Placeholders>
        <div class="form-group row">
          <div class="col-md-12">
            <label for="txtdocumentName" class="form-label"
              >Document Name: *</label
            >
            <input
              autocomplete="off"
              id="txtdocumentName"
              class="form-control"
              type="text"
              placeholder="Enter Document Name"
              v-model="newData.DocumentName"
              :class="v$.newData.DocumentName.$error ? 'has-error' : ''"
              @blur="v$.newData.DocumentName.$touch"
            />
          </div>

          <div class="col-md-6 mt-2">
            <label for="officesddl">Office:</label>
            <label class="form-control readonlyInput" for="">{{
              newData.Office.Name
            }}</label>
          </div>

          <div class="col-md-6 mt-2">
            <label for="stateddl">State:</label>
            <label class="form-control readonlyInput" for="">{{
              newData.State.Short
            }}</label>
          </div>

          <div class="col-md-6 mt-2">
            <label for="policyGroups">Policy Groups:</label>
            <label class="form-control readonlyInput" for="">{{
              newData.PolicyGroupId
            }}</label>
          </div>

          <div class="col-md-6 mt-2">
            <label for="txtEmailSubject">Email Subject: *</label>
            <input
              autocomplete="off"
              id="txtdocumentName"
              v-model="newData.EmailSubject"
              class="form-control"
              type="text"
              placeholder="Email Subject"
              :class="v$.newData.EmailSubject.$error ? 'has-error' : ''"
              @blur="v$.newData.EmailSubject.$touch"
            />
          </div>

          <div class="col-md-6 mt-2">
            <label for="templateTypeddl">Docusign Template Type:</label>
            <label class="form-control readonlyInput" for="">{{
              newData.DocusignTemplateType.Name
            }}</label>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label for="txtSelectFile">Document: (.docx file only !) *</label>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-xl-3 col-lg-4 col-md-12 col-xs-12">
            <input
              autocomplete="off"
              type="file"
              ref="uploader"
              style="display: none"
              accept="docx"
              @click="handleFileClick"
              @change="handleFileChange"
            />
            <button
              id="txtSelectFile"
              type="button"
              class="btn btn-info waves-effect waves-light"
              style="width: 100%"
              v-on:click.prevent.stop="$refs.uploader.click()"
            >
              Select File
            </button>
          </div>
          <div
            class="col-xl-6 col-lg-4 col-md-12 col-xs-12"
            v-if="getDownloadableDocumentName() != ''"
          >
            <label
              for="title"
              class="btn-outline-success"
              style="
                border: 1px solid #1abc9c;
                padding: 0.45rem 0.9rem;
                font-size: 0.9rem;
                line-height: 1.5;
                border-radius: 0.15rem;
                cursor: default !important;
                background-color: transparent !important;
                color: #6c757d;
              "
              >{{ getDownloadableDocumentName() }}
            </label>
          </div>
        </div>

        <div class="form-group row mt-4">
          <div class="col-xl-3 col-lg-4 col-md-6 col-xs-12">
            <button
              type="button"
              title="manage"
              id="pholder1btn-ddm"
              @click="openPlaceholders()"
              class="btn btn-primary fas fa-hashtag is-small cursor-pointer text-light rounded"
            ></button>
          </div>

          <div class="col-md-12">
            <button
              style="width: 100%"
              class="btn btn-success form-control"
              :disabled="v$.$invalid || saving"
              @click="() => handleSave()"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
import useValidate from "@vuelidate/core";
import { required } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import _orderby from "lodash";
import types from "./types";
import deepCopy from "@/helpers/deepCopy";
import Placeholders from "@/components/Placeholders/Placeholders";
import globalTypes from "@/store/types";
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";

export default {
  name: "DocusignDocumentAddNewPopup",
  components: {
    Placeholders,
  },
  props: {
    document: {
      type: Object,
      default: () => null,
    },
  },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      saving: false,
      isEditing: false,
      v$: useValidate(),
      file: null,
      validExtensions: ["docx"],
      uploadData: null,
      newData: null,
      isPlaceholdersOpen: false,
      hasAccessOtherOffices: false,
      userOfficeId: 0,
    };
  },
  computed: mapState({
    availablePlaceholder: (state) =>
      state.docusignManagement.availablePlaceholder,
  }),
  async mounted() {
    await Promise.all([this.$store.dispatch(types.GET_DOCUSIGN_PLACEHOLDER)]);

    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo != null && userInfo.moduleFunctions != null) {
      this.userOfficeId = userInfo.officeId;
    }
    this.hasAccessOtherOffices = this.canSeeAllOffices();

    if (this.document) {
      this.newData = deepCopy(this.document);
    } else {
      this.newData = {
        DocumentName: "",
        Office: [],
        State: [],
        EmailSubject: "",
        DocusignTemplateType: { Id: 0, Name: "Select" },
        PolicyGroupIds: [],
      };
    }
  },
  methods: {
    closePanel() {
      this.$emit("closeEditPopup");
    },
    setPlaceholder(value) {
      navigator.clipboard.writeText(value);
    },
    getDownloadableDocumentName() {
      let rtn = "";
      if (this.uploadData) {
        rtn = this.uploadData.Name;
      } else if (this.newData.DocumentName) {
        rtn = this.cropFileNames(this.newData.DocumentName);
      }
      return rtn;
    },
    openPlaceholders() {
      this.isPlaceholdersOpen = true;
    },
    async handleSave() {
      this.saving = true;

      let dataToSave = {
        Id: this.newData.Id,
        DocumentName: this.newData.DocumentName,
        EmailSubject: this.newData.EmailSubject,
      };

      if (
        this.uploadData != null &&
        this.uploadData.Extension != null &&
        this.uploadData.DocumentData != null
      ) {
        dataToSave.Extension = this.uploadData.Extension;
        dataToSave.DocumentData = this.uploadData.DocumentData;
      }

      let err, result;

      [err, result] = await this.$store.dispatch(
        types.UPDATE_EDIT_DOCUSIGN_TEMPLATE,
        dataToSave
      );

      if (result) {
        this.$swal("Success!", result.Message, "success");
        this.uploadData = null;
        this.$emit("documentUpdated", dataToSave);
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }

      this.saving = false;
    },
    handleFileClick() {
      this.$refs.uploader.value = "";
      const input = this.$refs.uploader;
      input.type = "file";
    },
    async handleFileChange(e) {
      if (e.target.files && e.target.files.length > 0) {
        const file = e.target.files[0];
        let fileExtension = "";
        if (file) {
          fileExtension = file.name.split(".").pop();

          if (fileExtension) {
            if (!this.validExtensions.includes(fileExtension.toLowerCase())) {
              this.$swal.fire(
                "Warning!",
                "File type is not valid. Only docx files are allowed.",
                "warning"
              );
              e.preventDefault();
              return;
            }
          }

          if (!this.isValidFileSize(file.size, 20)) {
            this.$swal(
              "Warning!",
              "Maximum allowed file size is 20 MB for document. Please resize your file.",
              "warning"
            );
            return;
          }

          let url = URL.createObjectURL(file);

          const toBase64 = (file) =>
            new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => resolve(reader.result);
              reader.onerror = (error) => reject(error);
            });
          this.showUploadButton = true;

          let data = await toBase64(file);
          data = data.replace(
            "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,",
            ""
          );
          data = data.replace("data:application/pdf;base64,", "");
          this.uploadData = {
            Type: "insert",
            Name: this.cropFileNames(file.name),
            FullName: file.name,
            Extension: "." + fileExtension,
            DocumentData: data,
          };
        }
      }
    },

    saveByteArray(name, byte) {
      let blob = new Blob([byte], { type: "application/docx" });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      let fileName = name;
      link.download = fileName;
      link.click();
    },
    isValidFileSize(size, limitMb) {
      let rtn = true;
      if (size > 1024 * 1024 * limitMb) {
        rtn = false;
      }

      return rtn;
    },
  },

  validations() {
    return {
      newData: {
        DocumentName: { required },
        EmailSubject: { required },
        DocusignTemplateType: {
          hasValue(templateType) {
            return templateType.Id > 0;
          },
        },
      },
    };
  },
};
</script>

<style></style>
