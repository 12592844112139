export default {  
  CREATE_WITHDRAWAL_REQUEST:"CREATE_WITHDRAWAL_REQUEST",
  GET_PAYMENT_CHANGES_PAYMENT_TYPES_WITH_FEES:"GET_PAYMENT_CHANGES_PAYMENT_TYPES_WITH_FEES",
  GET_SETTLEMENT_FEE_INFO:"GET_SETTLEMENT_FEE_INFO",
  GET_ALL_CHARGE_TYPES: "GET_ALL_CHARGE_TYPES",
  SET_ALL_CHARGE_TYPES: "SET_ALL_CHARGE_TYPES",
  CREATE_CHARGE_CHANGES:"CREATE_CHARGE_CHANGES",
  GET_CHARGE_LIST:"GET_CHARGE_LIST",
  GET_CUSTOMER_CANCELLATION_INFO: "GET_CUSTOMER_CANCELLATION_INFO",
  GET_PAYMENT_CHANGES_GRID_WITH_CREDITOR:
    "GET_PAYMENT_CHANGES_GRID_WITH_CREDITOR",
  CREATE_PAYMENT_CHANGE: "CREATE_PAYMENT_CHANGE",
  PAYMENT_GET_DETAILS: "PAYMENT_GET_DETAILS",
  PAYMENT_PAYEE_SEARCH: "PAYMENT_PAYEE_SEARCH",
  PAYMENT_NEW_PAYEE: "PAYMENT_NEW_PAYEE",
  ADD_PAYEE_BANK: "ADD_PAYEE_BANK",
  UPDATE_PAYEE_BANK: "UPDATE_PAYEE_BANK",
  REMOVE_PAYEE_BANK: "REMOVE_PAYEE_BANK",
  ADD_PAYEE_ADDRESS: "ADD_PAYEE_ADDRESS",
  UPDATE_PAYEE_ADDRESS: "UPDATE_PAYEE_ADDRESS",
  REMOVE_PAYEE_ADDRESS: "REMOVE_PAYEE_ADDRESS",
  CREATE_SKIP_PAYMENT: "CREATE_SKIP_PAYMENT",
  CREATE_ADDITIONAL_NOTE: "CREATE_ADDITIONAL_NOTE",
  CREATE_EXTRA_PAYMENT: "CREATE_EXTRA_PAYMENT",
  CREATE_DRAFT: "CREATE_DRAFT",
  CREATE_CLIENT_STATUS: "CREATE_CLIENT_STATUS",
  GET_FEES_TYPES: "GET_FEES_TYPES",
  GET_EXTRA_PAYMENT_DRAFT_TYPES: "GET_EXTRA_PAYMENT_DRAFT_TYPES",
  GET_DRAFT_CHANGES_GRID: "GET_DRAFT_CHANGES_GRID",
  SET_DRAFT_CHANGES_GRID: "SET_DRAFT_CHANGES_GRID",
  GET_CLIENT_STATUS_RELEASE_TYPES: "GET_CLIENT_STATUS_RELEASE_TYPES",
  GET_DRAFT_LIST: "GET_DRAFT_LIST",
  CREATE_FEE_CHANGE: "CREATE_FEE_CHANGE",
  GET_FEE_CHANGES_GRID: "GET_FEE_CHANGES_GRID",
  GET_FEE_LIST: "GET_FEE_LIST",
  SET_FEE_CHANGES_GRID: "SET_FEE_CHANGES_GRID",
  GET_PAYMENT_CHANGES_GRID: "GET_PAYMENT_CHANGES_GRID",
  SET_PAYMENT_CHANGES_GRID: "SET_PAYMENT_CHANGES_GRID",
  GET_PAYMENT_LIST: "GET_PAYMENT_LIST",
  GET_PAYMENT_CHANGES_PAYMENT_TYPES: "GET_PAYMENT_CHANGES_PAYMENT_TYPES",
  GET_CUSTOMER_CANCELATION_REASON_STATUS_CHANGE:
    "GET_CUSTOMER_CANCELATION_REASON_STATUS_CHANGE",
  SET_CUSTOMER_CANCELATION_REASON_STATUS_CHANGE:
    "SET_CUSTOMER_CANCELATION_REASON_STATUS_CHANGE",
  GET_CHARGES_CHANE_GRID: "GET_CHARGES_CHANE_GRID",
  SET_CHARGES_CHANE_GRID: "SET_CHARGES_CHANE_GRID",
};
