import types from "./types";
import api from "@/services/admin/api";
import to from "@/helpers/to";
export default {
  state: {
    placeholderList: [],
  },
  actions: {
    GET_PLACEHOLDERS: async ({ commit, state }) => {
      let err, result;

      [err, result] = await to(api.getPlaceholders());
      if (result) {
        commit(types.SET_PLACEHOLDERS, result.Data);
      }
    },
    RESET_PLACEHOLDERS: async ({ commit }) => {
      commit(types.SET, []);
    },
    PROCESS_PLACEHOLDERS: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.processPlaceholder(payload));

      return [err, result];
    },
  },
  mutations: {
    SET_PLACEHOLDERS: (state, payload) => {
      state.placeholderList = payload;
    },
  },
};
