<template>
  <div class="col-md-12">
    <div v-if="isLoading" class="loading">
      <div class="text-center" style="margin-top: 275px">
        <i class="spinner-grow text-info m-2"></i>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-12">
        <form class="newPanelWrapper">
          <div class="row">
            <div class="col-xl-2 col-lg-3 col-md-12 col-sm-12 float-left">
              <label class="generalLabel" for="">Creditor Name</label>
              <input
                type="text"
                class="generalTextBox form-control"
                placeholder="Creditor Name"
                v-model="CreditorName"
              />
            </div>
            <div class="col-xl-2 col-lg-3 col-md-12 col-sm-12 float-left">
              <label class="generalLabel" for="">Account Type</label>
              <input
                type="text"
                class="generalTextBox form-control"
                placeholder="Account Type"
                v-model="AccountType"
              />
            </div>
            <div class="col-xl-2 col-lg-3 col-md-12 col-sm-12 float-left">
              <label class="generalLabel" for="">Account Number</label>
              <input
                type="text"
                class="generalTextBox form-control"
                placeholder="Account Number"
                v-model="AccountNumber"
                v-myMask="{
                  alias: 'numeric',
                  rightAlign: false,
                  min: 0,
                }"
              />
            </div>
            <div class="col-xl-2 col-lg-3 col-md-6 col-sm-12 noMargin">
              <button
                type="button"
                class="generalButton btn-sm btn-primary"
                @click="downloadReport()"
                style="
                  width: 75%;
                  background-color: #ff7979;
                  color: #ffffff;
                  margin-top: 27px;
                "
              >
                Download
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import types from "./types";
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";

export default {
  name: "CreditorReport",
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      isLoading: false,
      CreditorName: "",
      AccountType: "",
      AccountNumber: "",
    };
  },

  methods: {
    async downloadReport() {
      if (!this.CreditorName && !this.AccountType && !this.AccountNumber) {
        this.$swal("Warning!", "Search parameter required", "warning");
        return;
      }
      let err, result;
      this.isLoading = true;
      [err, result] = await this.$store.dispatch(types.GET_CREDITOR_REPORT, {
        IsExport: true,
        CreditorName: this.CreditorName,
        AccountType: this.AccountType,
        AccountNumber: this.AccountNumber,
      });
      this.isLoading = false;
      if (result) {
        let tableElement = document.createElement("div");
        tableElement.innerHTML = result;
        this.exportExcel(
          tableElement,
          "CreditorReport-" + new Date().getTime()
        );
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
    },
  },
};
</script>

<style scoped></style>
