<template>
  <div
    class="table-responsive gridHeight newPanelWrapper"
    style="box-shadow: 0 0 1px 1px #d1d1d1 !important; padding: 1px !important"
  >
    <form>
      <table
        class="datagrid-table"
        id="btn-editable-eg"
        style="
          width: 100%;
          border: none !important;
          background-color: rgb(255, 255, 255);
        "
      >
        <thead>
          <tr>
            <th>Title</th>
            <th>Settlement Percentage</th>
            <th>Payment Terms</th>
            <th>Payment Type</th>
            <th>Days Delinquent</th>
            <th></th>
          </tr>

          <tr v-if="isNewRowActive" v-click-outside="outsideOfNewRow">
            <th>
              <input
                v-on:keydown.esc.prevent="
                  () => {
                    newRow.Title = '';
                  }
                "
                class="generalTextBox form-control"
                id="NewTitleRowInp"
                type="text"
                v-model="newRow.Title"
                :class="v$.newRow.Title.$error ? 'has-error' : ''"
                @blur="v$.newRow.Title.$touch"
              />
            </th>
            <th>
              <input
                class="generalTextBox form-control"
                type="text"
                v-model="newRow.SettlementPercentage"
                v-myMask="{
                  alias: 'decimal',
                  groupSeparator: '',
                  digits: 2,
                  rightAlign: false,
                  min: 0,
                  max: 100,
                  allowMinus: false,
                }"
              />
            </th>
            <th>
              <input
                class="generalTextBox form-control"
                type="text"
                v-model="newRow.PaymentTerms"
                v-myMask="{
                  alias: 'decimal',
                  rightAlign: false,
                }"
              />
            </th>

            <th>
              <select
                v-model="newRow.PaymentTypeId"
                class="form-control generalSelectBox"
              >
                <option :value="null">Please Select</option>
                <option
                  v-for="item in paymentTypes"
                  :value="item.Id"
                  :key="item.Id"
                >
                  {{ item.Name }}
                </option>
              </select>
            </th>
            <th>
              <input
                class="generalTextBox form-control"
                type="text"
                v-model="newRow.DaysDelinquent"
                v-myMask="{
                  alias: 'decimal',
                  rightAlign: false,
                }"
              />
            </th>
            <th>
              <button
                :disabled="v$.$invalid || myInvalid"
                type="button"
                class="btn"
                style="background-color: #24d15f"
                v-on:click.prevent="handleNewRowSave"
              >
                <i class="fas fa-save"></i>
              </button>
            </th>
          </tr>
        </thead>

        <tbody v-if="value">
          <tr v-for="item in value" :key="item.Id">
            <td>
              <div v-show="!item.Edit">
                <button
                  v-if="false"
                  type="button"
                  class="btn btn-link waves-effect"
                  style="background-color: #ccab11"
                >
                  <i class="fe-mail"></i>
                </button>
                <span class="tabledit-span tabledit-identifier">
                  {{ item.Title }}
                </span>
              </div>

              <input
                class="generalTextBox form-control"
                type="text"
                name="col1"
                v-model="item.Title"
                style="display: none"
                :key="'em' + item.Id"
                :id="'emid' + item.Id"
                v-show="item.Edit"
              />
            </td>

            <td class="tabledit-view-mode">
              <span v-show="!item.Edit" class="tabledit-span">
                {{ item.SettlementPercentage }}
              </span>
              <input
                class="generalTextBox form-control"
                type="text"
                v-model="item.SettlementPercentage"
                v-show="item.Edit"
                v-myMask="{
                  alias: 'decimal',
                  rightAlign: false,
                  max: 100,
                }"
              />
            </td>

            <td class="tabledit-view-mode">
              <span v-show="!item.Edit" class="tabledit-span">
                {{ item.PaymentTerms }}
              </span>
              <input
                class="generalTextBox form-control"
                type="text"
                v-model="item.PaymentTerms"
                v-show="item.Edit"
                v-myMask="{
                  alias: 'decimal',
                  rightAlign: false,
                }"
              />
            </td>

            <td class="tabledit-view-mode">
              <span v-show="!item.Edit" class="tabledit-span">
                {{ getPaymentTypeName(item.PaymentTypeId) }}
              </span>
              <select
                v-show="item.Edit"
                v-model="item.PaymentTypeId"
                class="form-control generalSelectBox"
              >
                <option
                  v-for="item in paymentTypes"
                  :value="item.Id"
                  :key="item.Id"
                >
                  {{ item.Name }}
                </option>
              </select>
            </td>

            <td class="tabledit-view-mode">
              <span v-show="!item.Edit" class="tabledit-span">
                {{ item.DaysDelinquent }}
              </span>
              <input
                class="generalTextBox form-control"
                type="text"
                v-model="item.DaysDelinquent"
                v-show="item.Edit"
                v-on:keydown.enter.prevent="updateRow(item)"
                v-on:keydown.esc.prevent="updateRow(item)"
              />
            </td>

            <td style="white-space: nowrap; width: 1%">
              <button
                v-show="!item.Edit"
                type="button"
                title="Edit"
                class="btn"
                :disabled="myInvalid"
                style="background-color: #24d171"
                v-on:click.prevent.stop="
                  () => {
                    editRow(item);
                  }
                "
              >
                <i v-show="!item.Edit" class="ri-edit-line"></i>
              </button>
              <button
                v-show="item.Edit"
                type="button"
                title="Edit"
                class="btn"
                :disabled="myInvalid"
                style="background-color: #24d171"
                v-on:click.prevent.stop="
                  () => {
                    updateRow(item);
                  }
                "
              >
                <i v-show="item.Edit" class="fe-check"></i>
              </button>
              <button
                type="button"
                title="Delete"
                class="btn"
                style="background-color: #ed3030"
                :disabled="myInvalid"
                v-on:click.prevent.stop="() => handleDelete(item.Id)"
              >
                <i class="fe-trash-2"></i>
              </button>
            </td>
          </tr>
          <tr
            v-if="!isNewRowActive"
            style="background-color: #ffffff !important"
          >
            <td colspan="4">
              <button
                type="button"
                title="addNew"
                class="addNewButton"
                :disabled="myInvalid"
                v-on:click.prevent.stop="() => startNewRow()"
              >
                <i style="font-size: 18px" class="fe-plus-circle"></i>
                Add New Rule
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import useValidate from "@vuelidate/core";
import { required } from "vuelidate/lib/validators";
import deepCopy from "@/helpers/deepCopy";
import _orderby from "lodash";
export default {
  name: "CreditorRuleGrid",
  props: {
    settlementRules: {
      type: Array,
      default: () => [],
    },
    paymentTypes: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      initRow: Object.assign({}, this.initRowFnc()),
      value: Object.assign([], this.settlementRules),
      v$: useValidate(),
      isNewRowActive: false,
      newRow: Object.assign({}, this.initRowFnc()),
      myInvalid: false,
      valueOld: Object.assign([], this.settlementRules),
    };
  },

  watch: {
    settlementRules(newVal, oldVal) {
      if (JSON.stringify(newVal) != JSON.stringify(oldVal)) {
        this.value = Object.assign([], this.settlementRules);
        this.sortValue();
      }
    },
  },
  async mounted() {
    this.sortValue();
  },
  methods: {
    sortValue() {
      this.value = this.value
        ? _orderby.orderBy(this.value, "DaysDelinquent", "asc")
        : [];
    },
    getPaymentTypeName(value) {
      let rtn = "";
      if (this.paymentTypes && this.paymentTypes.length > 0) {
        let indx = this.paymentTypes.findIndex((x) => x.Id == value);
        if (indx > -1) {
          rtn = this.paymentTypes[indx].Name;
        }
      }
      return rtn;
    },
    initRowFnc() {
      return {
        Title: "",
        SettlementPercentage: 0,
        PaymentTerms: 0,
        PaymentTypeId: null,
        DaysDelinquent: 0,
        Edit: false,
      };
    },
    updateRow(obj) {
      let duplicateList = this.value.filter(
        (x) => parseInt(x.DaysDelinquent) == parseInt(obj.DaysDelinquent)
      );
      if (duplicateList.length > 1) {
        this.$swal(
          "Warning!",
          "A rule with the specified delinquent days already exists.",
          "warning"
        );
        this.value = deepCopy(this.valueOld);
      }
      this.resetEdit(obj);
      obj.Edit = !obj.Edit;
      this.isNewRowActive = false;
    },
    editRow(obj) {
      this.valueOld = deepCopy(this.value);
      this.resetEdit(obj);
      obj.Edit = !obj.Edit;
      this.isNewRowActive = false;
    },
    resetEdit(obj) {
      if (this.myInvalid) return;

      for (let item of this.value) {
        if (item != obj) item.Edit = false;
      }

      this.$emit("updateSettlementRules", this.value);
      this.value = this.value
        ? _orderby.orderBy(this.value, "DaysDelinquent", "asc")
        : [];
    },
    resetEnter() {
      this.resetEdit(null);
    },

    startNewRow() {
      if (this.myInvalid) return;
      this.resetEdit(null);
      this.isNewRowActive = true;
    },
    hasSameDaysDeliquent() {
      let rtn = false;
      if (this.newRow && this.value) {
        let indx = this.value.findIndex(
          (x) => x.DaysDelinquent == this.newRow.DaysDelinquent
        );
        rtn = indx > -1;
      }
      return rtn;
    },
    handleNewRowSave() {
      if (this.hasSameDaysDeliquent()) {
        this.$swal(
          "Warning!",
          "A rule with the specified delinquent days already exists.",
          "warning"
        );
        return;
      }

      this.resetEdit(null);
      this.isNewRowActive = false;
      let newId = -1;
      if (this.value.length > 0) {
        let minId = Math.min(...this.value.map((x) => x.Id));
        if (minId < 0) {
          newId = minId - 1;
        }
      }
      this.newRow.Id = newId;
      if (!this.checkSameTitleExists(this.newRow.Title)) {
        this.value.push(this.newRow);
      } else {
        this.$swal("Warning!", "This title already exists.", "warning");
      }
      this.newRow = Object.assign({}, this.initRow);

      this.$emit("updateSettlementRules", this.updateIds(this.value));
    },

    updateIds(settlementRules) {
      return settlementRules.map((rule) => {
        if (rule.Id === -1) {
          rule.Id = 0;
        }
        return rule;
      });
    },

    titleValidation(target) {
      let regex = /^[A-Za-z\s]+$/;
      this.myInvalid = false;
      if (target.value != null) {
        if (!regex.test(target.value)) {
          this.myInvalid = true;
        } else if (
          this.value.filter((x) => x.Title === target.value).length > 1
        ) {
          this.myInvalid = true;
          this.$swal("Warning!", "This title already exists.", "warning");
        } else {
          this.myInvalid = false;
        }
      }
    },
    outsideOfNewRow() {
      this.titleValidation(document.getElementById("NewTitleRowInp"));

      if (this.myInvalid || this.hasSameDaysDeliquent()) {
        this.newRow = Object.assign({}, this.initRow);
        this.isNewRowActive = false;
        this.myInvalid = false;
        return;
      }
      this.resetEdit(null);
      this.isNewRowActive = false;
      let newId = -1;
      if (this.value.length > 0) {
        let minId = Math.min(...this.value.map((x) => x.Id));
        if (minId < 0) {
          newId = minId - 1;
        }
      }
      this.newRow.Id = newId;
      if (!this.checkSameTitleExists(this.newRow.Title)) {
        this.value.push(this.newRow);
      } else {
        this.$swal("Warning!", "This title already exists.", "warning");
      }
      this.newRow = Object.assign({}, this.initRow);
      this.$emit("updateSettlementRules", this.value);
    },

    checkSameTitleExists(title) {
      let rtn = false;
      rtn = this.value.some(({ Title }) => Title == title);
      return rtn;
    },

    async handleDelete(itemId) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then((dialog) => {
          if (dialog.value) {
            let rowIndex = this.value.findIndex((x) => x.Id == itemId);
            if (rowIndex > -1) {
              this.value.splice(rowIndex, 1);
              this.$emit("updateSettlementRules", this.value);
            }

            this.isNewRowActive = false;
          }
        });
    },
  },
  validations() {
    return {
      newRow: {
        Title: { required },
      },
    };
  },
};
</script>
<style scoped>
.has-error {
  border: 2px solid red !important;
}
</style>
