<template>
  <div
    v-click-outside="closePanel"
    class="col-md-12 newPanelWrapper noMargin deh-mediaScreen"
    style="
      background-color: #ffffff;
      z-index: 1000;
      padding-left: 0;
      padding-right: 0;
    "
  >
    <div class="envelopeHistoryClose">
      <div class="closeButton" v-on:click.prevent.stop="closePanel">
        <i class="fas fa-window-close"></i>
      </div>
    </div>
    <perfect-scrollbar style="max-height: 500px">
      <div class="col-md-12 datagrid-table">
        <o-table
          :data="docusignHistoryCopy"
          :bordered="isBordered"
          :striped="isStriped"
          :narrowed="isNarrowed"
          :hoverable="isHoverable"
          :loading="isLoading"
          :focusable="isFocusable"
        >
          <o-table-column field="sendDate" label="Sent Date" v-slot="props">
            {{ new Date(props.row.SentDate).toLocaleString() }}
          </o-table-column>

          <o-table-column field="statusName" label="Status Name" v-slot="props">
            {{ props.row.StatusName }}
          </o-table-column>

          <o-table-column
            field="LastCompletedRecipient"
            label="Last Completed Recipient"
            v-slot="props"
          >
            {{ props.row.LastCompletedRecipient }}
          </o-table-column>

          <o-table-column field="sentBy" label="Sent By" v-slot="props">
            {{ props.row.SentBy }}
          </o-table-column>

          <o-table-column
            field="templateType"
            label="Template Type"
            v-slot="props"
          >
            {{ props.row.TemplateTypes.join(", ") }}
          </o-table-column>
          <o-table-column field="icon" v-slot="props">
            {{ props.row.icon }}
            <button
              type="button"
              title="Check Status"
              class="btn btn-sm ri-file-unknow-fill text-light rounded docusignButtons"
              style="background-color: #f0932b"
              :disabled="
                props.row.StatusName == 'voided' ||
                props.row.StatusName == 'envelope-voided' ||
                props.row.StatusName == 'completed' ||
                props.row.StatusName == 'envelope-completed' ||
                props.row.StatusName == 'Signed' ||
                docusignLoading == true
              "
              @click="handleCheckStatus(customerId, props.row.EnvelopeId)"
            ></button>
            <button
              type="button"
              title="Re-send Paperwork"
              class="btn btn-sm ri-file-transfer-fill text-light rounded docusignButtons"
              style="background-color: #22a6b3"
              @click="handleSendReminder(customerId, props.row.EnvelopeId)"
              :disabled="
                props.row.StatusName == 'voided' ||
                props.row.StatusName == 'envelope-voided' ||
                props.row.StatusName == 'completed' ||
                props.row.StatusName == 'envelope-completed' ||
                props.row.StatusName == 'Signed' ||
                docusignLoading == true
              "
            ></button>
            <button
              type="button"
              title="Void Envelope"
              class="btn btn-sm ri-file-forbid-fill text-light rounded docusignButtons"
              style="background-color: #eb4d4b"
              @click="handleVoidDocument(customerId, props.row.EnvelopeId)"
              :disabled="
                props.row.StatusName == 'voided' ||
                props.row.StatusName == 'envelope-voided' ||
                props.row.StatusName == 'completed' ||
                props.row.StatusName == 'envelope-completed' ||
                props.row.StatusName == 'Signed' ||
                docusignLoading == true
              "
            ></button>
          </o-table-column>
        </o-table>
      </div>
    </perfect-scrollbar>
    <div class="col-md-12">
      <label
        for=""
        class="generalLabel text-primary mr-1"
        v-if="resultMsg.Type == 1"
        >{{ resultMsg.Message }}</label
      >
      <label
        for=""
        class="generalLabel text-success mr-1"
        v-if="resultMsg.Type == 2"
        >{{ resultMsg.Message }}</label
      >
      <label
        for=""
        class="generalLabel text-danger"
        v-if="resultMsg.Type == 3"
        >{{ resultMsg.Message }}</label
      >
    </div>
  </div>
</template>

<script>
import types from "./types";
import utilitiesMixin from "@/mixins/utilitiesMixin";
export default {
  name: "DocusignEnvelopeHistory",
  mixins: [utilitiesMixin],
  components: {},
  props: {
    customerId: {
      type: Number,
      default: () => 0,
    },
    docusignHistory: {
      type: Array,
      default: () => [],
    },
  },
  unmounted() {
    // this.emptyTheObjectsOnUnmount();
  },
  mounted() {},

  data() {
    return {
      docusignLoading: false,
      isBordered: true,
      isStriped: true,
      isNarrowed: true,
      isHoverable: true,
      isFocusable: true,
      resultMsg: {
        Type: 0,
        Message: "",
      },
      docusignHistoryCopy: Object.assign([], this.docusignHistory),
    };
  },

  methods: {
    emptyTheObjectsOnUnmount() {
      this.isBordered = null;
      this.isStriped = null;
      this.isNarrowed = null;
      this.isHoverable = null;
      this.isFocusable = null;
      this.resultMsg = null;
      this.docusignHistoryCopy = null;
    },
    closePanel() {
      this.$emit("closeEnvelopeHistoryPopUp");
    },
    async handleCheckStatus(customerId, envelopeId) {
      if (this.docusignLoading == true) {
        return;
      }
      this.docusignLoading = true;
      let err, result;
      let data4Status = {
        CustomerId: customerId,
        EnvelopeId: envelopeId,
      };
      [err, result] = await this.$store.dispatch(
        types.GET_DOCUSIGN_ENVELOPE_STATUS,
        data4Status
      );
      this.docusignLoading = false;
      if (result && result.Data) {
        this.resultMsg.Type = 1;
        this.resultMsg.Message = result.Data.EnvelopeStatus;
      } else {
        this.resultMsg.Type = 3;
        let errMsg = "An Error Occured!";

        if (!result) {
          if (err.response.data.Errors)
            errMsg = err.response.data.Errors.join(",");
        }

        if (
          result.Message &&
          result.Message.toLowerCase().indexOf("an error") > -1
        ) {
          if (result.Errors) {
            errMsg = result.Errors.join(",");
          }
        }
        this.resultMsg.Message = errMsg;
      }
    },
    async handleSendReminder(customerId, envelopeId) {
      if (this.docusignLoading == true) {
        return;
      }
      this.docusignLoading = true;
      let err, result;
      let data4Reminder = {
        CustomerId: customerId,
        EnvelopeId: envelopeId,
      };
      [err, result] = await this.$store.dispatch(
        types.SEND_DOCUSIGN_REMINDER,
        data4Reminder
      );
      this.docusignLoading = false;

      if (result) {
        this.resultMsg.Type = 1;
        this.resultMsg.Message = result.Message;
      } else {
        this.resultMsg.Type = 3;
        let errMsg = this.getApiErrorMessage(err);
        this.resultMsg.Message = errMsg;
        this.$swal("Error!", errMsg, "error");
      }
    },
    async handleVoidDocument(customerId, envelopeId) {
      if (this.docusignLoading == true) {
        return;
      }
      this.docusignLoading = true;
      let err, result;
      let errMsg = "";
      let datatoVoid = {
        CustomerId: customerId,
        EnvelopeId: envelopeId,
      };
      [err, result] = await this.$store.dispatch(
        types.VOID_DOCUSIGN_ENVELOPE,
        datatoVoid
      );
      this.docusignLoading = false;

      if (result && result.Message) {
        if (result.Message.toLowerCase().indexOf("an error") > -1) {
          errMsg = result.Message;
          if (result.Errors) {
            errMsg = result.Errors.join(",");
          }
          this.resultMsg.Type = 3;
          this.resultMsg.Message = errMsg;
        } else {
          this.resultMsg.Type = 2;
          this.resultMsg.Message = result.Message;

          let indx = this.docusignHistoryCopy.findIndex(
            (x) => x.EnvelopeId == envelopeId
          );
          this.docusignHistoryCopy[indx].StatusName = "voided";
          this.docusignHistoryCopy.push();
        }
      } else {
        this.resultMsg.Type = 3;
        let errMsg = this.getApiErrorMessage(err);
        this.resultMsg.Message = errMsg;
        this.$swal("Error!", errMsg, "error");
      }
    },
  },
};
</script>

<style>
.envelopeHistoryClose {
  width: 100%;
  height: 20px;
}
@media screen and (max-height: 650px) {
  .deh-mediaScreen {
    max-height: 400px !important;
    overflow-y: scroll;
  }
}
@media screen and (max-height: 400px) {
  .deh-mediaScreen {
    max-height: 200px !important;
    overflow-y: scroll;
  }
}
</style>
