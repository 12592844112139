<template>
  <div class="row admin-page officePage">
    <div v-if="isLoading" class="loading">
      <div class="text-center" style="margin-top: 150px">
        <i class="spinner-grow text-info m-2"></i>
      </div>
    </div>
    <div class="col-xl-3 col-lg-4 col-md-12 col-sm-12">
      <div class="table is-striped table is-hoverable">
        <button class="col-xl-12 btn btn-primary waves-effect waves-light addTemplateButton" @click="handleAddItem()">
          Add New POA Settings
        </button>
        <div class="col-lg-12 col-md-12 col-sm-12 noPadding">
          <input autocomplete="off" type="text" class="form-control" v-model="poaSettingsFilter"
            placeholder="Filter POA Settings" />
        </div>
        <div class="list col-md-12">
          <div class="item row" v-for="(item, index) in filteredPOA" :class="{ active: activeItem === item.Id }"
            :disabled="isLoading || deleting" :key="item.Id" @click="() => handleListSelection(item, index)" :style="[
              index % 2 === 0
                ? { 'background-color': '#F1EFEF' }
                : { 'background-color': '#FFFFFF' },
            ]">
            <span :class="{ adminActiveMenuItem: activeItem === item.Id }">
              <i class="ri-bank-fill" style="vertical-align: middle"></i>
              <span style="margin-left: 10px">{{ item.Name }}</span>
            </span>
          </div>
        </div>
        <div v-if="isMobileDevice()" style="
            border-top: 1px solid rgb(0, 0, 0, 0);
            margin: 20px 0 0 9px;
            padding-bottom: 20px;
          " class="col-md-12 scrollToSelectedItem"></div>
      </div>
    </div>
    <div class="col-xl-9 col-lg-8 col-md-12 col-sm-12 row" v-if="isEditing">
      <div class="col-xl-6 col-md-12" style="display: flex; flex-direction: column">
        <label for="name" class="generalLabel">Name*</label>
        <input type="text" class="form-control" placeholder="Name" @keydown="openSuggestBox" autocomplete="new-name"
          v-model="selectedRow.Name" :class="selectedRow.Name.trim().length == 0 ? 'has-error' : ''"
          @blur="suggestBoxBlur" />

        <div v-if="suggestBoxOpen" v-click-outside="closeSuggestBoxPopup" class="datagrid-table"
          style="position: absolute; top: 66px; left: 15px; z-index: 1000">
          <SuggestBox :list="suggestCreditorNames" :searchParam="selectedRow.Name"
            @assignCreditorName="assignCreditorName">
          </SuggestBox>
        </div>
      </div>
      <div class="col-xl-1"></div>

      <div v-if="!isLoading" class="col-xl-6 col-md-12" style="display: flex; flex-direction: column">
        <label class="generalLabel">Phones</label>
        <CreditorPhoneGrid :Phones="selectedRow.CreditorContactPhones" :ContactId="selectedRow.Id"
          :ContactName="selectedRow.Name" @updatePhones="updatePhones" style="boder-radius: 8px"></CreditorPhoneGrid>
      </div>

      <div class="col-md-12 row noPadding">
        <div class="col-md-6" style="display: flex; flex-direction: column">
          <label class="generalLabel">Email</label>
          <input type="text" class="form-control mb-2" placeholder="Email" autocomplete="new-email"
            v-model="selectedRow.Email" />
          <label for="fax" class="generalLabel">Fax</label>
          <input id="fax" type="text" class="form-control" placeholder="Fax" autocomplete="off"
            v-myMask="{ mask: ['(999) 999-9999'] }" v-model="selectedRow.Fax" />
        </div>

        <div class="col-md-6" style="display: flex; flex-direction: column">
          <label for="email2" class="generalLabel">Email 2</label>
          <input id="email2" type="text" class="form-control mb-2" placeholder="Email 2" autocomplete="new-email"
            v-model="selectedRow.Email2" />
          <label for="fax2" class="generalLabel">Fax 2</label>
          <input id="fax2" type="text" class="form-control" placeholder="Fax 2" autocomplete="new-name"
            v-myMask="{ mask: ['(999) 999-9999'] }" v-model="selectedRow.Fax2" />
        </div>
      </div>

      <div v-if="validatePOAFields()" class="col-md-12 noPadding" style="display: flex; align-items: flex-end">
        <div style="width: 20px; margin: 0 12px">
          <input v-model="selectedRow.AutoPoa" type="checkbox" class="form-control" @click="cboxEmptying" />
        </div>
        <label class="generalLabel">Auto POA Delivery</label>
      </div>

      <div v-if="selectedRow.AutoPoa" class="col-md-12 noPadding row">
        <div class="col-md-6">
          <label for="deliveryMethod" class="generalLabel">Delivery Method</label>
          <select id="deliveryMethod" class="form-control" v-model="selectedRow.DeliveryMethod">
            <option :value="null">Please Select</option>

            <option v-for="item in deliveryMethodList" :key="item.Id" :value="item.Id">
              {{ item.Name }}
            </option>
          </select>
        </div>
        <div class="col-md-6">
          <label for="deliveryFrequency" class="generalLabel">Delivery Frequency</label>
          <select id="deliveryFrequency" class="form-control" v-model="selectedRow.DeliveryFrequency">
            <option :value="null">Please Select</option>
            <option v-for="item in deliveryFrequencyList" :key="item.Id" :value="item.Id">
              {{ item.Name }}
            </option>
          </select>
        </div>

        <div class="col-md-6 mt-2">
          <label class="generalLabel">Customer Statuses</label>
          <VueMultiselect v-model="selectedRow.CustomerStatuses" :options="customerStatuses" :multiple="true"
            :close-on-select="false" placeholder="Please Select" label="Name" track-by="Id" />
        </div>
      </div>


      <div class="col-xl-2  col-lg-4 col-md-4 col-sm-12">
        <button @click="handleDelete(selectedRow.Id)" type="button" style="width: 100%" class="btn btn-danger">
          <i v-if="deleting" class="spinner-border spinner-border-sm"></i>Delete
        </button>
      </div>
      <div class="col-xl-2 col-lg-4 col-md-4 col-sm-12">
        <button @click="handleSave" type="button" style="width: 100%" class="btn btn-success"
          :disabled="!validateForm() && isLoading">
          <i v-if="isLoading" class="spinner-border spinner-border-sm"></i>
          Save
        </button>


      </div>
      <div v-if="selectedRow.Id > 0" class="col-xl-3 offset-xl-5 col-lg-4 col-md-4 col-sm-12 ">
        <button @click="showChangeLogs()" type="button" style="width: 100%"
          class="btn  btn-primary waves-effect waves-light">
          Change Logs
          <span class="float-right">
            <i v-if="!showLogs" class="fas fa-angle-down"></i>
            <i v-if="showLogs" class="fas fa-angle-up"></i>
          </span>
        </button>
      </div>
      <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 datagrid-table gcsTabsTableScroll mt-2" v-if="showLogs">
        <o-table :data="userActivityList">
          <o-table-column field="FullName" width="20%" label="User" sortable>
            <template v-slot="props">
              {{ props.row.FullName }}
            </template>
          </o-table-column>

          <o-table-column field="LogDate" width="20%" label="Log Date" sortable>

            <template v-slot="props">
              {{ formatGridDate(props.row.LogDate) }}
            </template>
          </o-table-column>
          <o-table-column field="AdditionalNotes" width="60%" label="Changes" sortable>
            <template v-slot="props">
              <span v-html="props.row.AdditionalNotes"></span>
            </template>
          </o-table-column>
        </o-table>
        <section v-if="userActivityList.length == 0" class="section" style="text-align: center">
          <div class="content has-text-grey has-text-centered mb-4">
            <p class="noMargin">
              <i style="font-size: 24px; color: #939393" class="ri-question-line"></i>
            </p>
            <p class="noMargin">Nothing here.</p>
          </div>
        </section>

      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import types from "./types";
import deepCopy from "@/helpers/deepCopy";
import formatMixin from "@/mixins/formatMixin.js";
import _orderby from "lodash";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import VueMultiselect from "vue-multiselect";
import globalTypes from "@/store/types";
import CreditorPhoneGrid from "@/components/Grids/CreditorPhoneGrid.vue";
import SuggestBox from "@/components/SuggestBox.vue";

const init = {
  Id: 0,
  Name: "",
  Fax: "",
  Fax2: null,
  Email: "",
  Email2: null,
  Notes: "",
  AutoPoa: null,
  DeliveryMethod: null,
  DeliveryFrequency: null,
  CustomerStatuses: null,
  CreditorContactPhones: [],
};

export default {
  name: "POASettingsManagement",
  mixins: [formatMixin, utilitiesMixin],
  components: { VueMultiselect, CreditorPhoneGrid, SuggestBox },
  data() {
    return {
      regex: /^[a-zA-Z0-9._-]+@([a-zA-Z0-9.-]+.)+([.])+[a-zA-Z0-9.-]{2,4}$/,
      deliveryFrequencyList: [
        { Id: 1, Name: "Once" },
        { Id: 2, Name: "Weekly" },
        { Id: 3, Name: "Bi-weekly" },
        { Id: 4, Name: "Monthly" },
        { Id: 5, Name: "Bi-monthly" },
      ],
      deliveryMethodList: [
        { Id: 1, Name: "Email" },
        { Id: 2, Name: "Fax" },
        { Id: 3, Name: "Both" },
      ],
      deleting: false,
      isEditing: false,
      activeItem: null,
      isCancelledBeforeSave: false,
      poaSettingsFilter: "",
      oldValue: deepCopy(init),
      selectedRow: deepCopy(init),
      valueToRevert: deepCopy(init),
      suggestBoxOpen: false,
      isLoading: false,
      showLogs: false,
      logsLoaded: false,
      userActivityList: [],
    };
  },

  async mounted() {
    await Promise.all([
      this.$store.dispatch(types.GET_POA_CREDITOR_CONTACT),
      this.$store.dispatch(globalTypes.GET_STATUS_NAMES),
      this.$store.dispatch(globalTypes.GET_SUGGEST_CREDITOR_PATTERN),
    ]);

  },
  computed: mapState({
    creditorContactlist: (state) =>
      state.poaSettingsManagement.creditorContactlist,
    customerStatuses: (state) => state.globals.customerStatuses,
    suggestCreditorNames: (state) => state.globals.suggestCreditorNames,

    filteredPOA() {
      return _orderby.orderBy(
        this.creditorContactlist.filter(
          (item) =>
            item.Name.toLowerCase().indexOf(
              this.poaSettingsFilter.toLowerCase()
            ) > -1
        ),
        "Name",
        "asc"
      );
    },

  }),
  methods: {
    async getActivityLogs() {
      if (this.selectedRow && this.selectedRow.Id > 0) {
        let err, result;
        [err, result] = await this.$store.dispatch(types.GET_USER_ACTIVITY_POA, {
          ExternalTableId: parseInt(this.selectedRow.Id),
          UserActivityTypeId: 12,
        }
        );
        if (result && result.Data) {
          this.userActivityList = result.Data;
        }
      }
    },

    async showChangeLogs() {
      if (this.showLogs == false && this.logsLoaded == false) {
        await this.getActivityLogs();
      }
      this.showLogs = !this.showLogs;
    },
    getDeliveryMethodName(id) {
      let rtn = "";
      if (id > 0 && this.deliveryMethodList.length > 0) {
        let indx = this.deliveryMethodList.findIndex((x) => x.Id == id);
        if (indx > -1) {
          rtn = this.deliveryMethodList[indx].Name;
        }
      }
      return rtn;
    },
    getDeliveryFrequencyName(id) {
      let rtn = "";
      if (id > 0 && this.deliveryFrequencyList.length > 0) {
        let indx = this.deliveryFrequencyList.findIndex((x) => x.Id == id);
        if (indx > -1) {
          rtn = this.deliveryFrequencyList[indx].Name;
        }
      }
      return rtn;
    },
    validatePOAFields() {
      let isValid =
        (this.selectedRow.Fax && this.selectedRow.Fax.length == 10) ||
        (this.selectedRow.Fax2 && this.selectedRow.Fax2.length == 10) ||
        (this.selectedRow.Email && this.selectedRow.Email.length > 0) ||
        (this.selectedRow.Email2 && this.selectedRow.Email2.length > 0);

      if (!isValid) {
        this.selectedRow.AutoPoa = false;
      }
      return isValid;
    },

    cboxEmptying() {
      this.selectedRow.DeliveryMethod = null;
      this.selectedRow.DeliveryFrequency = null;
      this.selectedRow.CustomerStatuses = null;
    },

    validateForm() {
      return this.selectedRow.Name && this.selectedRow.Name.trim().length > 0;
    },
    //Suggest box methods
    openSuggestBox() {
      this.suggestBoxOpen = true;
    },
    closeSuggestBoxPopup() {
      this.suggestBoxOpen = false;
    },
    suggestBoxBlur() {
      /*if (
        !this.suggestCreditorNames.includes(this.selectedRow.Name) &&
        this.checkAuth(55004)
      ) {
        this.selectedRow.Name = "";
      }*/
    },
    assignCreditorName(value) {
      this.selectedRow.Name = value;
      this.suggestBoxOpen = false;
    },
    //
    updatePhones(value) {
      this.selectedRow.CreditorContactPhones = value;
    },
    reset() {
      this.activeItem = null;
      this.selectedRow = deepCopy(init);
      this.isEditing = false;
    },
    async handleAddItem() {
      if (
        JSON.stringify(this.oldValue) != JSON.stringify(this.selectedRow) &&
        JSON.stringify(this.selectedRow) != JSON.stringify(init)
      ) {
        await this.$swal({
          title: "You have unsaved changes",
          text: "You will lose it if you don't save",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, continue without saving!",
        }).then(async (dialog) => {
          if (dialog.value) {
            this.isCancelledBeforeSave = true;
          } else {
            this.isCancelledBeforeSave = false;
            return;
          }
        });
      } else {
        this.oldValue = deepCopy(init);
        this.selectedRow = deepCopy(init);
        this.activeItem = null;
        this.isEditing = true;
        this.showLogs = false;
      }
      this.isLoading = true;
      await this.sleep(200);

      if (this.isCancelledBeforeSave) {
        this.selectedRow = deepCopy(this.valueToRevert);

        let indx = this.creditorContactlist.findIndex(
          (x) => x.Id == this.valueToRevert.Id
        );
        this.creditorContactlist[indx] = this.valueToRevert;
        this.creditorContactlist.push();

        this.isCancelledBeforeSave = false;
        this.selectedRow = deepCopy(init);
        this.oldValue = deepCopy(init);
        this.activeItem = null;
        this.isEditing = true;
        this.showLogs = false;
      }
      this.isLoading = false;
    },
    async handleListSelection(row) {
      if (
        JSON.stringify(this.oldValue) != JSON.stringify(this.selectedRow) &&
        JSON.stringify(this.selectedRow) != JSON.stringify(init)
      ) {
        await this.$swal({
          title: "You have unsaved changes",
          text: "You will lose it if you don't save",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, continue without saving!",
        }).then(async (dialog) => {
          if (dialog.value) {
            this.isCancelledBeforeSave = true;
          } else {
            this.isCancelledBeforeSave = false;
            return;
          }
        });
      } else {
        this.valueToRevert = deepCopy(row);
        this.oldValue = deepCopy(row);
        this.activeItem = row.Id;
        this.selectedRow = row;
        this.isCancelledBeforeSave = false;
        this.showLogs = false;
        this.logsLoaded = false;
      }
      this.isLoading = true;
      await this.sleep(200);
      this.isEditing = true;
      if (this.isCancelledBeforeSave) {
        this.selectedRow = deepCopy(this.valueToRevert);
        let indx = this.creditorContactlist.findIndex(
          (x) => x.Id == this.valueToRevert.Id
        );
        this.creditorContactlist[indx] = this.valueToRevert;
        this.creditorContactlist.push();
        this.valueToRevert = deepCopy(row);
        this.oldValue = deepCopy(row);
        this.activeItem = row.Id;
        this.selectedRow = row;
        this.isCancelledBeforeSave = false;
        this.showLogs = false;
        this.logsLoaded = false;
      }
      this.isLoading = false;
      let el = this.$el.querySelector(".scrollToSelectedItem");
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },

    async handleSave() {
      if (JSON.stringify(this.oldValue) == JSON.stringify(this.selectedRow)) {
        this.$swal("Warning!", "No Changes Detected!", "warning");
        return;
      }

      if (!this.suggestCreditorNames.includes(this.selectedRow.Name)) {
        if (!this.checkAuth(10038)) {
          this.$swal(
            "Error saving settings.",
            "Please pick a creditor from the approved list.",
            "warning"
          );
          return;
        }
      }

      if (this.selectedRow.DeliveryMethod == 1) {
        if (
          !this.regex.test(this.selectedRow.Email || this.selectedRow.Email2)
        ) {
          this.$swal(
            "Warning!",
            "At least one email required for this delivery method.",
            "warning"
          );
          return;
        }
      }

      if (this.selectedRow.DeliveryMethod == 2) {
        if (
          !(
            (this.selectedRow.Fax && this.selectedRow.Fax.length == 10) ||
            (this.selectedRow.Fax2 && this.selectedRow.Fax2.length == 10)
          )
        ) {
          this.$swal(
            "Warning!",
            "At least one fax required for this delivery method.",
            "warning"
          );
          return;
        }
      }

      if (this.selectedRow.DeliveryMethod == 3) {
        if (
          !(
            (this.selectedRow.Fax && this.selectedRow.Fax.length == 10) ||
            (this.selectedRow.Fax2 && this.selectedRow.Fax2.length == 10)
          ) ||
          !this.regex.test(this.selectedRow.Email || this.selectedRow.Email2)
        ) {
          this.$swal(
            "Warning!",
            "Email and fax required for this delivery method.",
            "warning"
          );
          return;
        }
      }

      if (this.selectedRow.AutoPoa == true) {
        if (
          this.selectedRow.DeliveryMethod == null ||
          this.selectedRow.DeliveryMethod == ""
        ) {
          this.$swal("Warning!", "Delivery method cannot be empty!", "warning");
          return;
        }
        if (
          this.selectedRow.DeliveryFrequency == null ||
          this.selectedRow.DeliveryFrequency == ""
        ) {
          this.$swal(
            "Warning!",
            "Delivery frequency cannot be empty!",
            "warning"
          );
          return;
        }
        if (
          this.selectedRow.CustomerStatuses == null ||
          this.selectedRow.CustomerStatuses == []
        ) {
          this.$swal(
            "Warning!",
            "Customer statuses cannot be empty!",
            "warning"
          );
          return;
        }
      }

      if (this.selectedRow.Email) {
        if (this.regex.test(this.selectedRow.Email) == false) {
          this.$swal("Warning!", "Email address is not valid!", "warning");
          return;
        }
      }
      if (this.selectedRow.Email2) {
        if (this.regex.test(this.selectedRow.Email2) == false) {
          this.$swal(
            "Warning!",
            "Second email address is not valid!",
            "warning"
          );
          return;
        }
      }

      let err, result, type;

      let isPOASettingsExists =
        this.creditorContactlist.filter(
          (x) => x.Name.toLowerCase() == this.selectedRow.Name.toLowerCase()
        ).length > 0;

      if (this.selectedRow.Id != 0) {
        type = types.UPDATE_POA_CREDITOR_CONTACT;

        isPOASettingsExists =
          this.creditorContactlist.filter(
            (x) => x.Name.toLowerCase() == this.selectedRow.Name.toLowerCase()
          ).length > 1;
      } else {
        type = types.INSERT_POA_CREDITOR_CONTACT;
      }
      if (isPOASettingsExists && type == types.INSERT_POA_CREDITOR_CONTACT) {
        this.$swal(
          "Warning!",
          "This creditor name already exists in list!",
          "warning"
        );
        return;
      }

      if (isPOASettingsExists && type == types.UPDATE_POA_CREDITOR_CONTACT) {
        this.$swal(
          "Warning!",
          "This creditor name already exists in list!",
          "warning"
        );

        return;
      }

      let rowToSave = deepCopy(this.selectedRow);
      if (this.selectedRow.AutoPoa) {
        if (
          rowToSave.CustomerStatuses &&
          rowToSave.CustomerStatuses.length > 0
        ) {
          rowToSave.CustomerStatuses = rowToSave.CustomerStatuses.map(
            (x) => x.Id
          ).join(",");
        }
      }
      this.isLoading = true;
      [err, result] = await this.$store.dispatch(type, rowToSave);

      if (result) {
        this.$swal("Success!", result.Message, "success");
        if (type == types.INSERT_POA_CREDITOR_CONTACT) {
          this.selectedRow.Id = result.Data.Id;
          await this.insertUserActivityLog();
          this.activeItem = result.Data.Id;
          this.creditorContactlist.push(this.selectedRow);
          this.oldValue = deepCopy(this.selectedRow);
        } else {
          await this.insertUserActivityLog();
          this.oldValue = deepCopy(this.selectedRow);
        }
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }

      this.isLoading = false;
    },
    async handleDelete(id) {
      this.deleting = true;
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "Would you like to continue?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then(async (dialog) => {
          if (dialog.value) {
            let rowIndex = this.creditorContactlist.indexOf(this.selectedRow);
            let err, result;

            [err, result] = await this.$store.dispatch(
              types.DELETE_POA_CREDITOR_CONTACT,
              { Id: id }
            );
            if (!err) {
              this.reset();
            }

            if (result) {
              await this.insertUserActivityLog(
                "Settings Deleted: " + this.selectedRow.Name
              );
              await this.sleep(500);
              this.creditorContactlist.splice(rowIndex, 1);
              this.reset();
              this.$swal("Deleted!", result.Message, "success");

            } else {
              let errMsg = this.getApiErrorMessage(err);
              this.$swal("Error!", errMsg, "error");
            }
          }
        });
      this.deleting = false;
    },
    async insertUserActivityLog(changeLogText = "") {
      let userInfo = this.getUserInfo();
      if (userInfo && userInfo.userId > 0) {
        let payload = {
          CustomerId: null,
          CreditorId: null,
          ExternalTableId: this.selectedRow.Id,
          UserId: userInfo.userId,
          UserActivityTypeId: 12,
          AdditionalNotes:
            changeLogText != ""
              ? changeLogText
              : this.getChangeLogNote(this.selectedRow, this.oldValue),
        };
        if (this.checkAuth(99665)) {

          await this.$store.dispatch(globalTypes.INSERT_USER_ACTIVITY, payload);
          this.showLogs = false;
          await this.getActivityLogs();
          this.showLogs = true;

        }
      }
    },
    getChangeLogNote(data, oldData) {
      let rtn = "";
      let isNewRecord = false;
      if (data && oldData) {
        let keys = Object.keys(data);
        for (let k of keys) {
          if (
            k != null &&
            typeof data[k] != "undefined" &&
            data[k] != oldData[k]
          ) {
            if (k.toLowerCase() == "id" && oldData[k] == 0) {
              rtn += "New record inserted.</br>";
              isNewRecord = true;
            } else {
              if (!Array.isArray(data[k])) {
                if (k.toLowerCase() == "name") {
                  rtn +=
                    isNewRecord == true
                      ? "Settings Name: " + data[k] + "<br>"
                      : "Settings Name Updated: " +
                      oldData[k] +
                      " --> " +
                      data[k] +
                      " </br>";
                } else if (
                  k.toLowerCase() == "fax" ||
                  k.toLowerCase() == "fax2" ||
                  k.toLowerCase() == "email" ||
                  k.toLowerCase() == "email2"
                ) {
                  let oldDataValue = oldData[k] != null && oldData[k] != "" ? oldData[k] : "Empty";
                  let newDataValue = data[k] != null &&  data[k] != "" ? data[k] : "Empty";
                  rtn +=
                    isNewRecord == true
                      ? k + ": " + data[k]
                      : k +
                      " Updated: " +
                      oldDataValue +
                      " --> " +
                      newDataValue +
                      "</br>";
                } else if (k.toLowerCase() == "autopoa") {
                  rtn +=
                    data[k] == true
                      ? "Auto POA Delivery Flag: Set" + " </br>"
                      : "Auto POA Delivery Flag: Unset" + " </br>";
                } else if (k.toLowerCase() == "deliverymethod") {
                  let oldDataValue =
                    oldData[k] != null
                      ? this.getDeliveryMethodName(oldData[k])
                      : "Empty";
                  let newDataValue =
                    data[k] != null
                      ? this.getDeliveryMethodName(data[k])
                      : "Empty";
                  rtn +=
                    "Delivery Method Updated: " +
                    oldDataValue +
                    " --> " +
                    newDataValue +
                    "</br>";
                } else if (k.toLowerCase() == "deliveryfrequency") {
                  let oldDataValue =
                    oldData[k] != null
                      ? this.getDeliveryFrequencyName(oldData[k])
                      : "Empty";
                  let newDataValue =
                    data[k] != null
                      ? this.getDeliveryFrequencyName(data[k])
                      : "Empty";
                  rtn +=
                    "Delivery Frequenct Updated: " +
                    oldDataValue +
                    " --> " +
                    newDataValue +
                    "</br>";
                }
              } else {
                if (k.toLowerCase() == "customerstatuses") {
                  let oldList = "";
                  if (oldData[k] != null && oldData[k].length > 0) {
                    oldList = oldData[k].map((x) => x.Name);
                  }

                  if (
                    typeof oldList != "undefined" &&
                    oldList &&
                    oldList.length > 0
                  ) {
                    oldList = oldList.join(",");
                  }

                  let newList = "";
                  if (data[k] != null && data[k].length > 0) {
                    newList = data[k].map((x) => x.Name);
                  }

                  if (
                    typeof newList != "undefined" &&
                    newList &&
                    newList.length > 0
                  ) {
                    newList = newList.join(",");
                  }
                  if (oldList != newList) {
                    let oldListValue =
                      oldList.length == 0 ? "Empty list" : oldList;
                    let newListValue =
                      newList.length == 0 ? "Empty list" : newList;
                    if (oldListValue != newListValue) {
                      rtn +=
                        isNewRecord == true
                          ? "Customer Statuses: " + newListValue + "<br>"
                          : "Customer Statuses Updated: " +
                          oldListValue +
                          " --> " +
                          newListValue +
                          " </br>";
                    }
                  }
                } else if (k.toLowerCase() == "creditorcontactphones") {
                  let oldList = "";
                  if (oldData[k] != null && oldData[k].length > 0) {
                    oldList = oldData[k].map((x) => x.Phone);
                  }

                  if (
                    typeof oldList != "undefined" &&
                    oldList &&
                    oldList.length > 0
                  ) {
                    oldList = oldList.join(",");
                  }

                  let newList = "";
                  if (data[k] != null && data[k].length > 0) {
                    newList = data[k].map((x) => x.Phone);
                  }

                  if (oldList != newList) {
                    let oldListValue =
                      oldList.length == 0 ? "Empty list" : oldList;
                    let newListValue =
                      newList.length == 0 ? "Empty list" : newList;
                    if (oldListValue != newListValue) {
                      rtn +=
                        isNewRecord == true
                          ? "Phone Numbers: " + newListValue + "<br>"
                          : "Phone Numbers Updated: " +
                          oldListValue +
                          " --> " +
                          newListValue +
                          " </br>";
                    }
                  }
                }
              }
            }
          }
        }
      }

      return rtn;
    },
  },
};
</script>
<style></style>
