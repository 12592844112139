<template>
  <div class="col-md-12">
    <ReportSummary
      :exportData="reportData"
      :reportName="'DebtChangesPostEnrollmentReport'"
      :recordCount="recordCount"
      @updateLoadingStatus="reportLoading = $event"
      @setPayload="setPayload"
      @clearReport="clearReport"
      @setPagination="setPagination"
      :loadingSpinner="loadingSpinner"
    />

    <div
      class="datagrid-table gcsTabsTableScroll report-wrapper"
      :style="'max-height: ' + reportsPanelHeight + 'px; overflow-y:auto;'"
    >
      <o-table
        :data="debtChangesPostEnrollmentReport"
        :paginated="isPaginated && debtChangesPostEnrollmentReport.length > 0"
        :per-page="perPage"
        :pagination-simple="isPaginationSimple"
        :pagination-position="paginationPosition"
        :sticky-header="true"
      >
        <o-table-column
          field="CustomerId"
          label="Customer Id"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CustomerIdFilter"
                placeholder=""
                v-myMask="{
                  alias: 'numeric',
                  rightAlign: false,
                }"
              />
            </div>
          </template>

          <template v-slot="props">
            <span
              @click="
                viewCustomer(
                  props.row.CustomerId,
                  props.row.CustomerFirstName,
                  props.row.CustomerLastName
                )
              "
              :class="
                getTagReportHyperLinkClass(
                  props.row.CustomerId &&
                    props.row.CustomerFirstName &&
                    props.row.CustomerLastName
                )
              "
            >
              <strong v-if="props.row.IsSummary"
                >{{ props.row.CustomerId }}
              </strong>
              <span v-if="!props.row.IsSummary">
                {{ addPrefixToCustomerId(props.row.CustomerId) }}
              </span>
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="CustomerFirstName"
          label="Customer Name"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CustomerNameFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.CustomerFirstName }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{
                props.row.CustomerFirstName + " " + props.row.CustomerLastName
              }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="AgentName"
          label="Agent Name"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="AgentNameFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.AgentName }}
          </template>
        </o-table-column>

        <o-table-column
          field="EnrolledDate"
          label="Enrolled Date"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="EnrolledDateFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.EnrolledDate }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatGridDate(props.row.EnrolledDate) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="ClearDraftsCount"
          label="# of Cleared Drafts"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="ClearDraftsCountFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.ClearDraftsCount }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.ClearDraftsCount }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="DraftFrequency"
          label="Draft Frequency"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="DraftFrequencyFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.DraftFrequency }}
          </template>
        </o-table-column>

        <o-table-column
          field="SettlemetFee"
          label="Settlement Fee"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="SettlemetFeeFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.SettlemetFee }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.SettlemetFee != null ?  formatMoney(props.row.SettlemetFee) : "" }}
            </span>
          </template>
        </o-table-column>

        <o-table-column field="DropDate" label="Drop Date" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="DropDateFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.DropDate }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatGridDate(props.row.DropDate) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="CustomerStatus"
          label="Customer Status"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CustomerStatusFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.CustomerStatus }}
          </template>
        </o-table-column>

        <o-table-column
          field="CreditorName"
          label="Creditor Name"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CreditorNameFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.CreditorName }}
          </template>
        </o-table-column>

        <o-table-column field="Event" label="Event" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="EventFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.Event }}
          </template>
        </o-table-column>

        <o-table-column
          field="DebtChanges"
          label="Debt Changes"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="DebtChangesFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.DebtChanges }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatMoney(props.row.DebtChanges) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="DebtAtEnrollment"
          label="Debt At Enrollment"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="DebtAtEnrollmentFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.DebtAtEnrollment }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatMoney(props.row.DebtAtEnrollment) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="NewDebtAmount"
          label="New Debt Amount"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="NewDebtAmountFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.NewDebtAmount }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatMoney(props.row.NewDebtAmount) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="DebtModifiedDate"
          label="Debt Modified Date"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="DebtModifiedDateFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.DebtModifiedDate }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatGridDate(props.row.DebtModifiedDate) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field=""
          label="View Customer"
          position="centered"
          v-slot="props"
        >
          <button
            @click="
              viewCustomer(
                props.row.CustomerId,
                props.row.CustomerFirstName,
                props.row.CustomerLastName
              )
            "
            class="btn btn-info waves-effect waves-light ml-3"
            v-if="!props.row.IsSummary"
          >
            <i class="far fa-address-card"></i>
          </button>
        </o-table-column>
      </o-table>
      <section
        v-if="debtChangesPostEnrollmentReport.length == 0"
        class="section"
        style="text-align: center"
      >
        <div class="content has-text-grey has-text-centered mb-4">
          <p class="noMargin">
            <i
              style="font-size: 24px; color: #939393"
              class="ri-question-line"
            ></i>
          </p>
          <p class="noMargin">Nothing here.</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import ReportSummary from "./ReportSummary.vue";
import utilitiesMixin from "@/mixins/utilitiesMixin";

export default {
  name: "DebtChangesPostEnrollmentReport",
  components: { ReportSummary },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      customerPrefix: null,
      reportsPanelHeight: 300,
      loadingSpinner: false,
      isPaginationSimple: false,
      isPaginated: true,
      paginationPosition: "both",
      currentPage: 1,
      perPage: 200,
      userInfo: null,
      recordCount: 0,
      reportLoading: false,
      reportData: [],

      debtChangesPostEnrollmentReportInitSummary: {
        CustomerId: "",
        CustomerFirstName: "",
        CreditorName: "",
        NewDebtAmount: "",
        Event: "",
        DebtAtEnrollment: "",
        DebtChanges: "",
        DebtModifiedDate: "",
        CustomerStatus: "",
        EnrolledDate: "",
        ClearDraftsCount: "",
        DraftFrequency: "",
        SettlemetFee: "",
        AgentName: "",
        DropDate: "",
      },
      CustomerIdFilter: "",
      CustomerNameFilter: "",
      CreditorNameFilter: "",
      EventFilter: "",
      DebtChangesFilter: "",
      DebtAtEnrollmentFilter: "",
      NewDebtAmountFilter: "",
      DebtModifiedDateFilter: "",
      CustomerStatusFilter: "",
      EnrolledDateFilter: "",
      ClearDraftsCountFilter: "",
      DraftFrequencyFilter: "",
      SettlemetFeeFilter: "",
      AgentNameFilter: "",
      DropDateFilter: "",
    };
  },
  computed: mapState({
    debtChangesPortEnrollmentList: (state) =>
      state.reports.debtChangesPortEnrollmentList,
    debtChangesPostEnrollmentReport() {
      let rtn = [];

      let tmpList =
        this.debtChangesPortEnrollmentList.length > 0
          ? this.debtChangesPortEnrollmentList.filter((item) => {
              return (
                this.filterString(item.CustomerId, this.CustomerIdFilter) &&
                this.filterString(item.AgentName, this.AgentNameFilter) &&
                this.filterString(
                  item.ClearDraftsCount,
                  this.ClearDraftsCountFilter
                ) &&
                this.filterString(
                  item.CustomerStatus,
                  this.CustomerStatusFilter
                ) &&
                this.filterString(
                  item.DraftFrequency,
                  this.DraftFrequencyFilter
                ) &&
                this.filterString(item.SettlemetFee, this.SettlemetFeeFilter) &&
                this.filterString(
                  item.CustomerFirstName,
                  this.CustomerNameFilter
                ) &&
                this.filterString(item.CreditorName, this.CreditorNameFilter) &&
                this.filterString(item.Event, this.EventFilter) &&
                this.filterString(item.DebtChanges, this.DebtChangesFilter) &&
                this.filterString(
                  item.DebtAtEnrollment,
                  this.DebtAtEnrollmentFilter
                ) &&
                this.filterString(
                  item.NewDebtAmount,
                  this.NewDebtAmountFilter
                ) &&
                new Date(item.DebtModifiedDate)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .indexOf(this.DebtModifiedDateFilter) > -1 &&
                new Date(item.EnrolledDate)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .indexOf(this.EnrolledDateFilter) > -1 &&
                new Date(item.DropDate)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .indexOf(this.DropDateFilter) > -1
              );
            })
          : [];

      if (tmpList.length == 0) {
        this.reportData = [];
        return rtn;
      }
      let totalCustomer = tmpList.length.toString();

      this.debtChangesPostEnrollmentReportInitSummary.CustomerId =
        "Total: " + totalCustomer;
      this.recordCount = tmpList.length;
      this.debtChangesPostEnrollmentReportInitSummary.IsSummary = true;

      rtn.push(...tmpList);
      rtn.push(this.debtChangesPostEnrollmentReportInitSummary);
      this.reportData = rtn;

      return rtn;
    },
  }),
  async mounted() {
    this.reportsPanelHeight = this.setReportGridHeight(
      window.innerHeight,
      document.getElementById("reportsSummary")
    );
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

    if (
      this.userInfo != null &&
      this.userInfo.customerIdPrefix != null &&
      this.userInfo.customerIdPrefix.length > 0
    ) {
      this.customerPrefix = this.userInfo.customerIdPrefix;
    }

    await this.sleep(1000);
    this.fixStickyHeaders();
  },
  methods: {
    async viewCustomer(id, firstName, lastName) {
      this.$Emitter.emit("viewCustomer", {
        customerId: id,
        customerName: firstName + lastName,
      });
    },

    addPrefixToCustomerId(customerId) {
      let rtn = customerId.toString();
      if (this.customerPrefix != null) {
        rtn = this.customerPrefix + rtn;
      }
      return rtn;
    },
    setPagination(data) {
      this.isPaginated = data;
    },
    async setPayload(value) {
      this.loadingSpinner = true;
      await this.$store.dispatch(
        types.GET_DEBT_CHANGES_POST_ENROLLMENT_REPORT,
        value
      );
      this.loadingSpinner = false;
      await this.sleep(500);
      this.fixStickyHeaders();
    },
    clearReport() {
      this.$store.dispatch(types.RESET_REPORTS);
      this.reportData = [];
    },
  },
};
</script>

<style scoped></style>
