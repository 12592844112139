<template>
  <div
    class="col-md-12 newPanelWrapper noMargin"
    style="background-color: #ffffff; z-index: 1000; width: 100%"
  >
    <div>
      <label class="generalLabel noMargin">Send Fax</label>
    </div>
    <div class="closeButton" v-on:click.prevent.stop="closePanel">
      <i class="fas fa-window-close"></i>
    </div>
    <div
      class="noPadding"
      style="
        max-height: 80vh;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 20px 12px !important;
      "
    >
      <div>
        <input
          type="text"
          class="form-control"
          v-model="creditorContactFilter"
          placeholder="Filter"
        />
      </div>
      <div>
        <div class="list col-md-12 bankNameTable">
          <div
            class="row bankNameItem"
            style="padding: 6px; cursor: pointer"
            v-for="(item, index) in filteredContactList"
            @click="handleSelection(item)"
            :key="item.Id"
            :class="{ active: activeItem == item.Id }"
            :disabled="saving || deleting"
            :style="[
              index % 2 === 0
                ? { 'background-color': '#f1f1f1' }
                : { 'background-color': '#FFFFFF' },
            ]"
          >
            <span :class="{ activeContactItem: activeItem == item.Id }">
              <span style="margin-left: 8px">{{ item.Name }}</span>
            </span>
          </div>
          <section
            v-if="filteredContactList.length == 0"
            class="section mt-3"
            style="text-align: center"
          >
            <div class="content has-text-grey has-text-centered">
              <p class="noMargin">
                <i
                  style="font-size: 24px; color: #939393"
                  class="ri-question-line"
                ></i>
              </p>
              <p class="noMargin">No contact available.</p>
            </div>
          </section>
        </div>
      </div>
      <div class="form-group row pt-2">
        <div class="col-md-3 col-xs-12 pt-1">
          <label for="creditorPhone">Creditor Phone:</label>
        </div>
        <div class="col-md-4 col-xs-12">
          <select
            id="creditorPhone"
            @change="phoneChange"
            class="form-control"
            v-model="selectedRow.CreditorPhoneId"
          >
            <i class="fas fa-angle-down" aria-hidden="true"></i>
            <option :value="null">Select Phone Number</option>
            <option
              v-for="item in selectedRow.Phones"
              :key="item.Id"
              :value="item.Id"
            >
              {{ tryFormatPhone(item.Phone) }}
            </option>
          </select>
        </div>
        <div class="col-md-1 col-xs-12 pt-1">
          <label>OR</label>
        </div>
        <div class="col-md-4 col-xs-12">
          <input
            type="text"
            class="form-control"
            v-model="selectedRow.CreditorPhoneAlternative"
            placeholder=""
            v-myMask="{ mask: ['(999) 999-9999'] }"
            @change="alternativePhoneEntered"
          />
        </div>
      </div>

      <div class="form-group row pt-1">
        <div class="col-md-3 col-xs-12 pt-1">
          <label for="Action">Creditor Fax: *</label>
        </div>

        <div class="col-md-4">
          <input
            type="text"
            class="form-control"
            placeholder="Creditor Fax"
            v-model="selectedRow.CreditorFax"
            @change="refreshValue"
            v-myMask="{ mask: ['(999) 999-9999'] }"
          />
        </div>
      </div>

      <div class="form-group row pt-1">
        <div class="col-md-3 col-xs-12 pt-1">
          <label for="Action">Acct/Ref/File - DOB - SSN:</label>
        </div>
        <div class="col-md-9">
          <input
            type="text"
            class="form-control"
            placeholder="Select Acct/Ref/File "
            v-model="selectedRow.Ref"
          />
        </div>
      </div>

      <div class="form-group row pt-1">
        <div class="col-md-3 col-xs-12 pt-1">
          <label for="Action">Subject: *</label>
        </div>
        <div class="col-md-9">
          <input
            type="text"
            class="form-control"
            placeholder="Subject"
            v-model="selectedRow.Subject"
            :class="v$.selectedRow.Subject.$error ? 'has-error' : ''"
            @blur="v$.selectedRow.Subject.$touch"
          />
        </div>
      </div>

      <div class="form-group row pt-1">
        <div class="col-md-3 col-xs-12">
          <label for="Action">Body: *</label>
        </div>

        <div class="col-md-9">
          <textarea
            class="form-control textareaInput generalTextBox"
            rows="5"
            v-model="selectedRow.Body"
            placeholder="Body"
            :class="v$.selectedRow.Body.$error ? 'has-error' : ''"
            @blur="v$.selectedRow.Body.$touch"
          ></textarea>
        </div>
      </div>
      <div class="form-group row pt-1">
        <div class="col-md-3 col-xs-12 pt-1">
          <label for="Action">Attachments:</label>
        </div>

        <div class="col-md-3">
          <input
            type="file"
            ref="uploader"
            style="display: none"
            @click="handleFileClick"
            @change="handleFileChange"
          />
          <button
            type="button"
            id="uploadNowButton1"
            class="btn btn-success"
            style="background-color: #3bafda; width: 100%"
            v-on:click.prevent.stop="$refs.uploader.click()"
          >
            Upload Now
          </button>
        </div>

        <div class="col-md-4">
          <button
            type="button"
            id="uploadNowButton1"
            class="btn btn-success"
            style="background-color: #3bafda; width: 100%"
            @click="openDocumentPopup"
          >
            Choose from documents
          </button>
        </div>

        <div class="col-md-12">
          <div
            style="
              max-height: 100px;
              max-width: 100%;
              overflow-x: auto;
              overflow-y: auto;
              border: 1px solid #ced4da;
              border-radius: 0.25rem;
              padding: 0 8px 10px 8px;
            "
            class="form-group"
            v-if="
              (selectedRow &&
                selectedRow.Attachments &&
                selectedRow.Attachments.length > 0) ||
              selectedDocumentNames.length > 0
            "
          >
            <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 noPadding">
              <div
                v-for="item in selectedRow.Attachments"
                :key="item.DocumentName"
                class="mr-2 mt-1"
                style="width: auto; float: left"
              >
                <span
                  class="badge btn-success"
                  style="padding: 3px 5px; float: left"
                >
                  {{ item.DocumentName }}</span
                >
                <i
                  @click="removeAttachment(item)"
                  style="
                    color: red;
                    margin: -4px 3px;
                    cursor: pointer;
                    float: left;
                    font-size: 16px;
                  "
                  class="ri-close-circle-fill"
                ></i>
              </div>
              <div
                v-for="item in selectedDocumentNames"
                :key="item"
                class="mr-2 mt-1"
                style="width: auto; float: left"
              >
                <span
                  class="badge btn-success"
                  style="padding: 3px 5px; float: left"
                >
                  {{ item }}</span
                >
                <i
                  @click="cancelDocument(item)"
                  style="
                    color: red;
                    margin: -4px 3px;
                    cursor: pointer;
                    float: left;
                    font-size: 16px;
                  "
                  class="ri-close-circle-fill"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group pt-3 row">
        <div :class="hasMatch == true ? 'col-md-6' : 'col-md-12'">
          <button
            :disabled="v$.$invalid || saving"
            @click="sendFax()"
            class="col-md-12 btn btn-success"
          >
            Send Fax
          </button>
        </div>
        <div v-if="hasMatch" class="col-md-6">
          <button @click="sendPOA()" class="col-md-12 btn btn-success">
            Send POA
          </button>
        </div>
      </div>
    </div>
    <div v-if="isDocumentsPopupOpen" class="backgroundLock">
      <div
        style="
          position: relative;
          margin: 2% auto;
          background-color: white;
          padding-top: 30px;
        "
        class="offset-xl-3 col-xl-6 offset-lg-2 col-lg-8 col-md-12 col-sm-12"
      >
        <div class="documentTaskPopupClose" @click="closeDocumentPopup()">
          <i class="fas fa-window-close"></i>
        </div>

        <div class="col-md-12 mt-2 noPadding">
          <perfect-scrollbar
            class="col-md-12 row noPadding"
            style="max-height: 420px; overflow-x: hidden !important"
          >
            <div class="datagrid-table table is-striped is-hoverable">
              <o-table :data="sortedCustomerDocumentList">
                <o-table-column
                  v-if="selectedDocumentNames.length > 0"
                  field=""
                  label=""
                  width="2%"
                >
                  <template v-slot="props">
                    <span v-if="selectedDocumentNames.includes(props.row.Name)">
                      <i
                        style="font-size: 18px; color: green"
                        class="fas fa-check"
                      ></i>
                    </span>
                  </template>
                </o-table-column>
                <o-table-column
                  field="Name"
                  label="File Name"
                  sortable
                  searchable
                  width="50%"
                >
                  <template v-slot:searchable="props">
                    <input
                      v-if="!sortedCustomerDocumentList.length == 0"
                      class="form-control"
                      type="text"
                      v-model="props.filters[props.column.field]"
                      placeholder="Search..."
                      icon="search"
                    />
                  </template>
                  <template v-slot="props">
                    <span>{{ props.row.Name }}</span>
                  </template>
                </o-table-column>

                <o-table-column
                  field="Modified"
                  label="Date"
                  position="centered"
                  width="15%"
                  sortable
                >
                  <template v-slot="props">
                    {{
                      new Date(props.row.Modified).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                        hour: "numeric",
                        minute: "2-digit",
                      })
                    }}
                  </template>
                </o-table-column>
                <o-table-column
                  field="UserId"
                  label="User"
                  position="centered"
                  width="25%"
                  sortable
                >
                  <template v-slot="props">
                    <span>{{ props.row.UserId }}</span>
                  </template>
                </o-table-column>
                <o-table-column field="icon" v-slot="props" width="10%">
                  <div>
                    {{ props.row.icon }}
                  </div>
                  <div>
                    <button
                      type="button"
                      class="btn btn-sm"
                      style="
                        width: 80px;
                        background-color: #00b894;
                        font-size: 13px;
                      "
                      @click="selectDocument(props.row.Name)"
                      v-if="!selectedDocumentNames.includes(props.row.Name)"
                    >
                      Select
                    </button>
                    <button
                      v-if="selectedDocumentNames.includes(props.row.Name)"
                      type="button"
                      class="btn btn-sm"
                      style="
                        width: 80px;
                        background-color: rgb(237, 48, 48);
                        font-size: 13px;
                      "
                      @click="() => cancelDocument(props.row.Name)"
                    >
                      Cancel
                    </button>
                  </div>
                </o-table-column>
              </o-table>

              <section
                v-if="sortedCustomerDocumentList.length == 0"
                class="section"
                style="text-align: center"
              >
                <div class="content has-text-grey has-text-centered mb-4">
                  <p class="noMargin">
                    <i
                      style="font-size: 24px; color: #939393"
                      class="ri-question-line"
                    ></i>
                  </p>
                  <p class="noMargin">Nothing here.</p>
                </div>
              </section>
            </div>
          </perfect-scrollbar>
        </div>
        <div
          class="col-md-12 mt-3 pb-3"
          v-if="selectedDocumentNames.length > 0"
        >
          <button
            @click="selectCustomerDocuments()"
            type="button"
            class="btn btn-sm generalButton btn-success"
            style="
              width: 100%;
              background-color: #3bafda;
              font-size: 15px;
              color: #ffffff;
            "
          >
            Submit file(s)
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import deepCopy from "@/helpers/deepCopy";
import formatMixin from "@/mixins/formatMixin.js";
import utilitiesMixin from "@/mixins/utilitiesMixin.js";
import globalTypes from "@/store/types";
import documentTypes from "@/views/agent/EditClient/types";
import { mapState } from "vuex";
import _orderby from "lodash";
import useValidate from "@vuelidate/core";
import { required } from "vuelidate/lib/validators";

const init = {
  Id: null,
  CreditorPhoneId: null,
  CreditorFax: null,
  CreditorName: "",
  Subject: null,
  Body: null,
  Ref: null,
  Attachments: [],
  CreditorPhoneAlternative: null,
  Phones: [],
};
export default {
  name: "SendFax",
  components: {},
  props: {
    customerId: {
      Type: Number,
      Default: 0,
    },
    creditorId: {
      Type: Number,
      Default: 0,
    },
    creditorName: {
      Type: String,
      Default: "",
    },
    accountNumber: {
      Type: String,
      Default: "",
    },
    dateOfBirth: {
      Type: String,
      Default: "",
    },
    ssnValue: {
      Type: String,
      Default: "",
    },
  },
  data() {
    return {
      isDocumentsPopupOpen: false,
      v$: useValidate(),
      oldValue: "",
      cancelBeforeChanged: false,
      selectedRow: deepCopy(init),
      saving: false,
      deleting: false,
      activeItem: null,
      creditorContactFilter: "",
      validFileExtensions: [
        "doc",
        "docx",
        "pdf",
        "xls",
        "xlsx",
        "jpg",
        "jpeg",
        "png",
      ],
      customerDocumentList: [],
      selectedDocumentNames: [],
    };
  },
  mixins: [formatMixin, utilitiesMixin],

  computed: mapState({
    activePoaList: (state) => state.globals.activePoaList,
    creditorContactList: (state) => state.globals.creditorContactList,
    filteredContactList() {
      return _orderby.orderBy(
        this.creditorContactList.filter(
          (item) =>
            item.Name.toLowerCase().indexOf(
              this.creditorContactFilter.toLowerCase()
            ) > -1
        )
      );
    },
    sortedCustomerDocumentList() {
      return this.customerDocumentList.length > 0
        ? this.customerDocumentList.sort(
            (a, b) => new Date(b.Modified) - new Date(a.Modified)
          )
        : [];
    },

    hasMatch() {
      for (let s = 0; s < this.activePoaList.length; s++) {
        if (this.creditorName == this.activePoaList[s].Name) {
          return true;
        }
      }

      return false;
    },
  }),
  async mounted() {
    await Promise.all([
      this.$store.dispatch(globalTypes.GET_ALL_CREDITOR_CONTACT),
      this.$store.dispatch(globalTypes.GET_ACTIVE_POA_CREDITOR),
    ]);

    if (this.creditorName != "") {
      this.creditorContactFilter = this.creditorName;
    }

    if (this.accountNumber && this.accountNumber != "") {
      this.selectedRow.Ref = this.accountNumber;
    } else {
      this.selectedRow.Ref = "(No data)";
    }

    if (this.dateOfBirth && this.dateOfBirth != "") {
      this.selectedRow.Ref = this.selectedRow.Ref + " - " + this.dateOfBirth;
    }
    if (this.ssnValue != "") {
      let ssn = await this.AES256_GCM_decrypt(this.ssnValue);
      if (typeof ssn != "undefined" && ssn != null && ssn.length > 0)
        this.selectedRow.Ref = this.selectedRow.Ref + " - " + ssn;
    }

    if (this.filteredContactList.length > 0) {
      this.handleSelection(this.filteredContactList[0]);
      //this.selectedRow.CreditorFax = this.filteredContactList[0].Fax;
    }
  },
  methods: {
    async openDocumentPopup() {
      await this.getCustomerDocuments();
      this.selectedDocumentNames = [];
      this.isDocumentsPopupOpen = true;
    },
    closeDocumentPopup() {
      this.isDocumentsPopupOpen = false;
    },

    async getCustomerDocuments() {
      let err, result;
      [err, result] = await this.$store.dispatch(
        documentTypes.GET_CUSTOMERDOCUMENTLIST,
        { CustomerId: this.customerId }
      );
      if (result && result.Data) {
        this.customerDocumentList = deepCopy(result.Data);
      }
    },

    selectDocument(value) {
      let indx = this.selectedDocumentNames.findIndex((x) => x == value);
      if (indx == -1) {
        this.selectedDocumentNames.push(value);
      }
    },
    cancelDocument(value) {
      let indx = this.selectedDocumentNames.findIndex((x) => x == value);
      if (indx > -1) {
        this.selectedDocumentNames.splice(indx, 1);
      }
    },

    selectCustomerDocuments() {
      this.isDocumentsPopupOpen = false;
    },

    refreshValue(event) {
      if (event.target.value && event.target.value != "") {
        this.selectedRow.CreditorFax = event.target.value;
      }
    },

    phoneChange() {
      if (this.selectedRow.CreditorPhoneId != null) {
        this.selectedRow.CreditorPhoneAlternative = null;
      }
    },

    alternativePhoneEntered(event) {
      let phone = event.target.value;

      if (typeof phone != "undefined" && phone != null && phone.length == 10) {
        this.selectedRow.CreditorPhoneId = null;
        this.selectedRow.CreditorPhoneAlternative = phone;
      }
    },

    async handleSelection(item) {
      this.activeItem = item.Id;
      this.selectedRow.Id = item.Id;
      this.selectedRow.CreditorFax = item.Fax;
      this.selectedRow.Phones = item.CreditorContactPhones;

      if (item.CreditorContactPhones && item.CreditorContactPhones.length > 0) {
        this.selectedRow.CreditorPhoneId = item.CreditorContactPhones[0].Id;
      }
    },

    removeAttachment(item) {
      let indx = this.selectedRow.Attachments.findIndex(
        (x) => x.DocumentName == item.DocumentName
      );
      if (indx > -1) {
        this.selectedRow.Attachments.splice(indx, 1);
      }
    },
    isValidFileSize(size, limitMb) {
      let rtn = true;
      if (size > 1024 * 1024 * limitMb) {
        rtn = false;
      }
      return rtn;
    },

    async sendFax() {
      if (
        (this.selectedRow &&
          this.selectedRow.Attachments &&
          this.selectedRow.Attachments.length > 0) ||
        (this.selectedDocumentNames && this.selectedDocumentNames.length > 0)
      ) {
        let contacts = this.filteredContactList.filter(
          (item) => item.Id == this.selectedRow.Id
        );
        let creditorPhone = "";
        if (this.selectedRow.CreditorPhoneId != null) {
          let phones = this.selectedRow.Phones.filter(
            (item) => item.Id == this.selectedRow.CreditorPhoneId
          );

          creditorPhone = phones.length > 0 ? phones[0].Phone : "";
        } else {
          creditorPhone = this.selectedRow.CreditorPhoneAlternative;
        }

        if (this.selectedRow.Id == null) {
          this.$swal("Warning!", "Please, select a company.", "warning");
          return;
        }

        if (creditorPhone == "") {
          this.$swal("Warning!", "Phone number is required.", "warning");
          return;
        }
        if (
          this.selectedRow.CreditorFax == null ||
          this.selectedRow.CreditorFax == ""
        ) {
          this.$swal("Warning!", "Fax number is required.", "warning");
          return;
        }

        let payload = {
          CustomerId: this.customerId,
          CreditorId: this.creditorId,
          CreditorFax: this.selectedRow.CreditorFax,
          CreditorPhone: creditorPhone,
          CreditorName: contacts.length > 0 ? contacts[0].Name : "",
          Subject: this.selectedRow.Subject,
          Body: this.selectedRow.Body,
          AccountNumber: this.selectedRow.Ref,
          Attachments: this.selectedRow.Attachments,
          BlobAttachments: this.selectedDocumentNames,
        };

        let err, result;
        [err, result] = await this.$store.dispatch(
          globalTypes.SEND_CREDITOR_FAX,
          payload
        );
        if (result) {
          this.$swal("Success", "Fax sent.", "success");
          this.closePanel();
        } else {
          let errMsg = this.getApiErrorMessage(err);
          this.$swal("Error!", errMsg, "error");
        }
      } else {
        this.$swal("Warning!", "You must attach a document.", "warning");
        return;
      }
    },

    async sendPOA() {
      let err, result;
      let payload = {
        CustomerId: this.customerId,
        CreditorId: this.creditorId,
        CreditorName: this.creditorName,
      };
      [err, result] = await this.$store.dispatch(
        globalTypes.SEND_POA_EFAX,
        payload
      );

      if (result) {
        this.$swal("Success", "POA sent.", "success");
        this.closePanel();
        if (this.checkAuth(3994)) {
          this.$store.dispatch(globalTypes.INSERT_USER_ACTIVITY, {
            CustomerId: this.customerId,
            UserActivityTypeId: 10,
            AdditionalNotes: "Sent POA"
          });
        }
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
    },

    async handleFileChange(e) {
      if (e.target.files && e.target.files.length > 0) {
        const file = e.target.files[0];
        let fileExtension = "";
        if (file) {
          fileExtension = file.name.split(".").pop();

          if (!this.validFileExtensions.includes(fileExtension.toLowerCase())) {
            this.$swal("Warning!", "Invalid file type!", "warning");
            return;
          }

          let fileName = this.cropFileNames(
            this.getFileNameWithoutExt(file.name)
          );
          let fileIndex = this.selectedRow.Attachments.findIndex(
            (x) => x.DocumentName == fileName
          );
          if (fileIndex > -1) {
            this.$swal("Warning!", "This file is aldready exists!", "warning");
            return;
          }

          if (!this.isValidFileSize(file.size, 25)) {
            this.$swal(
              "Warning!",
              "Maximum allowed file size is 25 MB. Please resize your file.",
              "warning"
            );
            return;
          }

          let url = URL.createObjectURL(file);

          const toBase64 = (file) =>
            new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => resolve(reader.result);
              reader.onerror = (error) => reject(error);
            });

          let data = await toBase64(file);

          if (data.indexOf("data:") > -1) {
            data = data.split(",")[1];
          }

          let uploadData = {
            DocumentName: fileName + "." + fileExtension,
            DocumentData: data,
          };

          this.selectedRow.Attachments.push(uploadData);
        }
      }
    },
    handleFileClick() {
      this.$refs.uploader.value = "";
      const input = this.$refs.uploader;
      input.type = "file";
    },
    closePanel() {
      this.$emit("closeSendFaxPopup");
    },
  },
  validations() {
    return {
      selectedRow: {
        Subject: { required },
        Body: { required },
      },
    };
  },
};
</script>

<style>
.activeContactItem {
  color: #3bafda;
  font-weight: bold;
}
.closeButton {
  position: absolute;
  top: -5px;
  right: 15px;
  z-index: 1000;
  font-size: 30px;
  cursor: pointer;
}
.closeButton:hover {
  color: #ff3860;
}
.bankNameTable {
  height: 100px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
