<template>
  <div
    class="col-md-12 newPanelWrapper noMargin dcp-mediaScreen"
    style="background-color: #ffffff; z-index: 1000"
  >
    <div
      style="display: flex; justify-content: space-between"
      v-on:click.prevent.stop="closePanel"
    >
      <label>Creditor Notes</label>
      <i class="fas fa-window-close closeButtonBulk"></i>
    </div>

    <div class="row">
      <div class="col-md-12">
        <textarea
          v-model="creditorNote"
          class="form-control textareaInput generalTextBox"
          rows="15"
        ></textarea>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-right">
        <button @click="saveNote()" class="btn btn-primary">Save Note</button>
      </div>
    </div>
  </div>
</template>

<script>
import types from "./types";
export default {
  name: "CreditorNotesPopup",
  props: {
    settlementDetailId: {
      type: Number,
      default: () => 0,
    },
    creditorId: {
      type: Number,
      default: () => 0,
    },
    note: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      oldNote: "",
      creditorNote: "",
    };
  },
  unmounted() {},
  async mounted() {
    if (this.note) {
      this.oldNote = this.note;
      this.creditorNote = this.note;
    }
  },
  methods: {
    closePanel() {
      this.$emit("closeCreditorNotePopup");
    },
    async saveNote() {
      if (this.creditorNote.trim().length == 0) {
        this.$swal("Warning!", "Note field is required", "warning");
        return;
      }

      if (this.oldNote.trim() == this.creditorNote.trim()) {
        this.$swal("Warning!", "No changes detected.", "warning");
        return;
      }

      let err, result;
      [err, result] = await this.$store.dispatch(types.UPDATE_CREDITOR_NOTE, {
        CreditorId: this.creditorId,
        Note: this.creditorNote,
      });

      if (result) {
        this.$swal("Success", result.Message, "success");
        this.$emit("updateCreditorNote", {
          CreditorId: this.creditorId,
          Note: this.creditorNote,
        });
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
    },
  },
};
</script>

<style>
.closeButton {
  position: absolute;
  top: -5px;
  right: 15px;
  z-index: 1000;
  font-size: 30px;
  cursor: pointer;
}
.closeButton:hover {
  color: #ff3860;
}
@media screen and (max-height: 390px) {
  .dcp-mediaScreen {
    max-height: 200px !important;
    overflow-y: scroll;
  }
}
</style>
