<template>
  <Popover class="transfer">
    <template v-slot:header>
      <div class="container">
        Transfer to Department
        <button
          class="no-button"
          type="button"
          v-on:click.prevent.stop="$emit('transfer', 0)"
        >
          &lt; Back
        </button>
        <input
          class="form-control"
          type="text"
          v-model="contactListFilter"
          v-on:click.prevent.stop="() => {}"
        />
      </div>
    </template>
    <template v-slot:body>
      <div class="contact-list">
        <div
          class="contact"
          v-for="item in filteredRoles"
          :key="item.Id"
          @click="handleTransferDepartment(item.Id)"
        >
          {{ item.Name }}
        </div>
      </div>
    </template>
  </Popover>
</template>

<script>
import Popover from "../../Popover";

import _orderby from "lodash";
import { mapState } from "vuex";
export default {
  components: {
    Popover,
  },
  emits: ["transfer", "transferToIngroup"],
  data() {
    return {
      contactListFilter: "",
    };
  },
  computed: mapState({
    employees: (state) => state.globals.allEmployees,
    roles: (state) => state.globals.roles,
    filteredRoles() {
      return _orderby.orderBy(
        this.roles
          .filter((x) => x.ModuleFunctions.includes(100020))
          .filter(
            (x) =>
              x.Name.toLowerCase().indexOf(
                this.contactListFilter.toLowerCase()
              ) > -1
          ),
        "Name"
      );
    },
  }),
  async mounted() {},

  methods: {
    handleTransferDepartment(roleId) {
      let endPointList = this.employees
        .filter((x) => x.EndpointName != "")
        .filter((x) => x.Roles.includes(roleId))
        .map((x) => x.EndpointName);

      if (endPointList.length > 0) {
        let departmentEndPoints = endPointList.join(",");
        this.$emit("transferToIngroup", departmentEndPoints);
      } else {
        this.$swal(
          "Warning!",
          "No Available Agents in this department",
          "warning"
        );
      }
    },
  },
};
</script>
