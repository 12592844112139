<template>
  <div
    class="col-md-12 newPanelWrapper noPadding"
    style="
      background-color: #ffffff;
      z-index: 1000;
      padding-left: 0;
      padding-right: 0;
    "
  >
    <div class="closeButton" v-on:click.prevent.stop="closePanel">
      <i class="fas fa-window-close"></i>
    </div>
    <label class="generalLabel ml-2 mt-2">Email</label>

    <div
      class="col-md-12"
      style="padding: 20px; max-height: 700px; overflow: auto"
    >
      <div
        class="col-md-12"
        style="border: 1px solid #ccc; padding: 10px"
        v-html="mailContent"
      ></div>
    </div>
  </div>
</template>

<script>
import types from "./types";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import formatMixin from "@/mixins/formatMixin";
import deepCopy from "@/helpers/deepCopy";
export default {
  name: "EmailLogsPopup",
  mixins: [formatMixin, utilitiesMixin],
  props: {
    logsId: {
      Type: Number,
      default: 0,
    },
  },
  data() {
    return {
      mailContent: null,
    };
  },
  async mounted() {
    if (this.logsId > 0) {
      let err, result;
      [err, result] = await this.$store.dispatch(types.GET_MAIL_CONTENT_BY_ID, {
        Id: this.logsId,
      });
      if (result) {
        this.mailContent = deepCopy(result.Data);
      }
    }
  },
  methods: {
    closePanel() {
      this.$emit("closeEmailPopup");
    },
  },
};
</script>

<style>
.closeButton {
  position: absolute;
  top: -5px;
  right: 15px;
  z-index: 1000;
  font-size: 30px;
  cursor: pointer;
}
.closeButton:hover {
  color: #ff3860;
}
</style>
