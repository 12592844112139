export default { 
  PC_GET_PAYMENT_TYPES_WITH_FEES:"PC_GET_PAYMENT_TYPES_WITH_FEES",
  PC_APPROVE_WITHDRAWN_REQUEST:"PC_APPROVE_WITHDRAWN_REQUEST",
  PC_DECLINE_WITHDRAWN_REQUEST:"PC_DECLINE_WITHDRAWN_REQUEST",
  GET_CASHFLOW_FOR_PENDING_CHANGES:"GET_CASHFLOW_FOR_PENDING_CHANGES",
  GET_ALL_PENDING_CHANGES: "GET_ALL_PENDING_CHANGES",
  SET_ALL_PENDING_CHANGES: "SET_ALL_PENDING_CHANGES",
  PC_APPROVE_EXTRAPAYMENTS: "PC_APPROVE_EXTRAPAYMENTS",
  PC_DECLINE_EXTRAPAYMENTS: "PC_DECLINE_EXTRAPAYMENTS",
  PC_APPROVE_DRAFTCHANGE: "PC_APPROVE_DRAFTCHANGE",
  PC_DECLINE_DRAFTCHANGE: "PC_DECLINE_DRAFTCHANGE",
  PC_APPROVE_ADDITIONALNOTE: "PC_APPROVE_ADDITIONALNOTE",
  PC_DECLINE_ADDITIONALNOTE: "PC_DECLINE_ADDITIONALNOTE",
  PC_APPROVE_AMENDMENTS: "PC_APPROVE_AMENDMENTS",
  PC_DECLINE_AMENDMENTS: "PC_DECLINE_AMENDMENTS",
  PC_APPROVE_DOCREVIEW: "PC_APPROVE_DOCREVIEW",
  PC_DECLINE_DOCREVIEW: "PC_DECLINE_DOCREVIEW",
  PC_APPROVE_SKIPPAYMENT: "PC_APPROVE_SKIPPAYMENT",
  PC_DECLINE_SKIPPAYMENT: "PC_DECLINE_SKIPPAYMENT",
  PC_APPROVE_INFOCHANGES: "PC_APPROVE_INFOCHANGES",
  PC_DECLINE_INFOCHANGES: "PC_DECLINE_INFOCHANGES",
  PC_APPROVE_STATUSCHANGES: "PC_APPROVE_STATUSCHANGES",
  PC_DECLINE_STATUSCHANGES: "PC_DECLINE_STATUSCHANGES",
  PC_APPROVE_FEECHANGES: "PC_APPROVE_FEECHANGES",
  PC_DECLINE_FEECHANGES: "PC_DECLINE_FEECHANGES",
  PC_APPROVE_PAYMENTCHANGES: "PC_APPROVE_PAYMENTCHANGES",
  PC_DECLINE_PAYMENTCHANGES: "PC_DECLINE_PAYMENTCHANGES",
  PC_APPROVE_CHARGES:"PC_APPROVE_CHARGES",
  PC_DECLINE_CHARGES:"PC_DECLINE_CHARGES"
};
