export default {
  GET_ARRAY_SETTINGS: "GET_ARRAY_SETTINGS",
  SET_ARRAY_SETTINGS: "SET_ARRAY_SETTINGS",
  UPDATE_ARRAY_SETTINGS: "UPDATE_ARRAY_SETTINGS",
  GET_ALL_CREDIT_PULL_SETTINGS: "GET_ALL_CREDIT_PULL_SETTINGS",
  GET_ALL_CREDIT_SERVICES: "GET_ALL_CREDIT_SERVICES",
  SET_ALL_CREDIT_SERVICES: "SET_ALL_CREDIT_SERVICES",
  UPDATE_CREDIT_PULL_SETTINGS: "UPDATE_CREDIT_PULL_SETTINGS",
  INSERT_CREDIT_PULL_SETTINGS: "INSERT_CREDIT_PULL_SETTINGS",
  GET_SERVICE_COLUMNS: "GET_SERVICE_COLUMNS",
  GET_ALL_BLOCKED_CREDIT_TYPES: "GET_ALL_BLOCKED_CREDIT_TYPES",
  SET_ALL_BLOCKED_CREDIT_TYPES: "SET_ALL_BLOCKED_CREDIT_TYPES",
  ADD_BLOCKED_CREDIT_TYPE: "ADD_BLOCKED_CREDIT_TYPE",
  UPDATE_BLOCKED_CREDIT_TYPE: "UPDATE_BLOCKED_CREDIT_TYPE",
  DELETE_BLOCKED_CREDIT_TYPE: "DELETE_BLOCKED_CREDIT_TYPE",
  GET_ALL_BLOCKED_CREDITORS: "GET_ALL_BLOCKED_CREDITORS",
  SET_ALL_BLOCKED_CREDITORS: "SET_ALL_BLOCKED_CREDITORS",
  ADD_BLOCKED_CREDITOR: "ADD_BLOCKED_CREDITOR",
  UPDATE_BLOCKED_CREDITOR: "UPDATE_BLOCKED_CREDITOR",
  DELETE_BLOCKED_CREDITOR: "DELETE_BLOCKED_CREDITOR",
};
