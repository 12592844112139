<template>
  <div class="col-md-12 noMargin">
    <div class="ribbon-box">
      <div class="pt-1">
        <div
          class="ribbon ribbon-primary ribonBackground"
          style="padding: 10px"
        >
          <div style="font-size: 16px">
            <i
              :class="
                widgetData.Role == 'Welcome Call'
                  ? 'fas fa-phone-alt'
                  : 'fas fa-headset'
              "
              class="mr-1"
            ></i
            >{{ widgetData.Role }}
          </div>
        </div>
        <div style="display: flex; flex-direction: column; padding-bottom: 5px">
          <div style="display: flex">
            <h4 class="mr-2" style="font-weight: 600">EN:</h4>
            <h4
              :style="
                widgetData.English == true ? 'color: #1abc9c' : 'color: #f1556c'
              "
            >
              {{ widgetData.English == true ? "OPEN" : "CLOSED" }}
            </h4>
          </div>
          <div style="display: flex">
            <h4 class="mr-2" style="font-weight: 600">ES:</h4>
            <h4
              :style="
                widgetData.Spanish == true ? 'color: #1abc9c' : 'color: #f1556c'
              "
            >
              {{ widgetData.Spanish == true ? "OPEN" : "CLOSED" }}
            </h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    widgetData: Object,
  },
};
</script>

<style>
.ribonBackground {
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  margin: -6px -17px 0px -17px !important;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
</style>
