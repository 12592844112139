import types from "./types";
import api from "@/services/admin/api";
import to from "@/helpers/to";

export default {
  state: {
    assignTypes: [],
    assignableUsers: [],
    assignableCampaigns: [],
    assignableOffices: [],
  },
  actions: {
    GET_ASSIGN_TYPES: async ({ commit }) => {
      let err, result;
      [err, result] = await to(api.getAssignTypes());
      if (result) {
        commit(types.SET_ASSIGN_TYPES, result.Data);
      }
    },
    GET_ASSIGNABLE_USERS: async ({ commit }) => {
      let err, result;
      [err, result] = await to(api.getAssignableUsers());
      if (result) {
        commit(types.SET_ASSIGNABLE_USERS, result.Data);
      }
    },
    GET_ASSIGNABLE_CAMPAIGNS: async ({ commit }) => {
      let err, result;

      [err, result] = await to(api.getAssignableCampaigns());

      if (result) {
        commit(types.SET_ASSIGNABLE_CAMPAIGNS, result.Data);
      }
    },
    GET_ASSIGNABLE_OFFICES: async ({ commit }) => {
      let err, result;

      [err, result] = await to(api.getDidAssignableOffices());

      if (result) {
        commit(types.SET_ASSIGNABLE_OFFICES, result.Data);
      }
    },
    SET_DID_ASSIGN_USER: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.updateDid(payload));

      return [err, result];
    },
    GETDIDLIST: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.getCompanyDids(payload));

      return [err, result];
    },
    UPDATE_OFFICE_DID: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.updateOfficeDid(payload));

      return [err, result];
    },
    UPDATE_COMPANY_DID: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.updateCompanyDid(payload));

      return [err, result];
    },
    SET_DID_ASSIGN_TYPE: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.updateDidAssignType(payload));

      return [err, result];
    },
    SET_DID_ASSIGN_CAMPAIGN: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.updateDidCampaign(payload));

      return [err, result];
    },
  },
  mutations: {
    SET_ASSIGN_TYPES: (state, payload) => {
      state.assignTypes = payload;
    },
    SET_ASSIGNABLE_USERS: (state, payload) => {
      state.assignableUsers = payload;
    },
    SET_ASSIGNABLE_CAMPAIGNS: (state, payload) => {
      state.assignableCampaigns = payload;
    },
    SET_ASSIGNABLE_OFFICES: (state, payload) => {
      state.assignableOffices = payload;
    },
  },
};
