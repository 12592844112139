import types from "./types";
import api from "@/services/admin/api";
import to from "@/helpers/to";
export default {
  state: {
    dashboardConfigUserList: [],
  },
  actions: {
    GET_DASHBOARD_CONFIG: async ({ commit }) => {
      let err, result;
      [err, result] = await to(api.getUsersDashboardConfig());
      if (result) {
        commit(types.SET_DASHBOARD_CONFIG, result.Data);
      }
    },
    INSERT_OR_UPDATE_DASHBOARD_CONFIG: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.insertOrUpdateDashboardConfig(payload));
      return [err, result];
    },
    DELETE_DASHBOARD_CONFIG: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.removeDashboardConfig(payload));
      return [err, result];
    },
    GET_USER_ID_DASHBOARD_CONFIG: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.getByUserIdDashboardConfig(payload));
      return [err, result];
    },
  },
  mutations: {
    SET_DASHBOARD_CONFIG: (state, payload) => {
      state.dashboardConfigUserList = payload;
    },
  },
};
