<template>
  <div class="row admin-page officePage">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
      <div class="panelWrapper">
        <div class="row panelContent">
          <div class="editPanel" style="border: none">
            <div class="editPanelColumn">
              <label class="form-label float-left" style="margin: 4px"
                >Area Code:</label
              >
            </div>
            <div class="editPanelColumn" style="width: 80px">
              <input
                autocomplete="off"
                type="text"
                id="cellPhoneInput"
                class="form-control"
                v-model="phonePattern"
                v-on:keydown.enter.prevent="getPhones()"
                v-myMask="{ mask: ['999'] }"
              />
            </div>
            <div class="editPanelColumn noMargin">
              <button
                type="button"
                :disabled="searchingPhone || phonePattern == ''"
                @click="getPhones()"
                class="btn btn-icon waves-effect waves-light btn-success float-left"
              >
                Search
                <i class="fas fa-search"></i>
                <i v-if="searchingPhone" class="fas fa-spinner mr-1"></i>
              </button>
            </div>
            <div class="editPanelColumn noMargin">
              <label
                v-if="tollFreeWarningOpen"
                class="form-label float-left mt-1 ml-2"
                style="color: red"
              >
                <u
                  >Toll-free DIDs are voice only and do not support SMS/MMS
                  traffic.</u
                >
              </label>
            </div>
          </div>
          <label
            v-if="
              ((availablePhoneList && availablePhoneList.length == 0) ||
                !availablePhoneList) &&
              isSearched
            "
            class="form-label float-left ml-1"
          >
            <u
              >No phone numbers are available with this area code at the
              moment.</u
            >
          </label>
          <div
            class="row"
            v-if="
              !(
                (availablePhoneList && availablePhoneList.length == 0) ||
                !availablePhoneList
              ) && isSearched
            "
          >
            <div class="phoneList2">
              <div
                class="row ml-1"
                v-for="(item, index) in availablePhoneList"
                :key="item"
              >
                <div class="phoneCheckBox">
                  <input
                    autocomplete="off"
                    v-model="selectedPhoneNumbers[index]"
                    type="checkbox"
                    :id="'chk' + index"
                    class="form-control"
                  />
                </div>
                <div class="roleLabel">
                  <label class="col-form-label">{{ formatPhone(item) }}</label>
                </div>
              </div>
            </div>

            <div class="purchaseButtonWrapper">
              <button
                type="button"
                @click="purchasePhones()"
                :disabled="
                  purchasingDid ||
                  !(
                    this.selectedPhoneNumbers.includes(true) ||
                    this.selectedPhoneNumbers.includes('true')
                  )
                "
                style="padding:5px margin:10px;"
                class="btn btn-icon waves-effect waves-light btn-success btn-md float-left"
              >
                Purchase
                <i v-if="purchasingDid" class="fas fa-spinner mr-1"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import types from "./types";
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import _orderby from "lodash";

export default {
  name: "DidPurchaseManagement",
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      phonePattern: "",
      availablePhoneList: [],
      selectedPhoneNumbers: [],
      isSearched: false,
      searchingPhone: false,
      purchasingDid: false,
      didList: [],
      companyDidExists: false,
      tollFreeWarningOpen: false,
    };
  },
  methods: {
    async getPhones() {
      this.tollFreeWarningOpen = false;
      this.selectedPhoneNumbers = [];
      this.selectedPhoneNumbers.push();
      this.availablePhoneList = [];
      this.availablePhoneList.push();
      if (this.phonePattern.startsWith("8")) {
        this.tollFreeWarningOpen = true;
      }
      this.searchingPhone = true;

      let err, result;
      [err, result] = await this.$store.dispatch(
        types.GET_PHONE_NUMBERS,
        this.phonePattern
      );
      this.isSearched = true;
      if (result) {
        if (result.Data) {
          this.availablePhoneList = result.Data;
        }
      }
      this.searchingPhone = false;
    },
    async purchasePhones() {
      this.purchasingDid = true;
      let tempArr = [];
      for (let [key, value] of Object.entries(this.availablePhoneList)) {
        if (typeof this.selectedPhoneNumbers[key] != "undefined") {
          if (
            this.selectedPhoneNumbers[key] == true ||
            this.selectedPhoneNumbers[key] == "true"
          ) {
            tempArr.push(value);
          }
        }
      }
      if (tempArr.length > 0) {
        let payload = tempArr.join(",");
        let err, result;
        [err, result] = await this.$store.dispatch(
          types.BUY_PHONE_NUMBERS,
          payload
        );
        if (result) {
          if (result.Data) {
            if (result.Message)
              this.$swal.fire("Success!", result.Message, "success");

            this.getPhones();
            this.bindDids();
          }
        } else {
          let errMsg = this.getApiErrorMessage(err);
          this.$swal("Error!", errMsg, "error");
        }
      }
      this.purchasingDid = false;
    },
    async bindDids() {
      let err, result;
      [err, result] = await this.$store.dispatch(types.GET_DID_LIST, 0);
      if (result) {
        if (result.Data) {
          this.didList = result.Data;
          this.companyDidExists =
            this.didList.length > 0
              ? this.didList.filter((x) => x.DidAssignTypeId == 5).length > 0
                ? true
                : false
              : false;
        }
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
    },
  },
  async mounted() {
    await Promise.all([this.bindDids()]);
  },
};
</script>

<style lang="css" scoped>
.editPanel {
  width: 100%;

  padding: 0;
  margin: 5px 2px;
  border: 2px solid #3bafda;
}
.editPanelRow {
  width: 100%;
  float: left;
}
.editPanelColumn {
  float: left;
  padding: 5px;
}
.phoneList2 {
  width: 300px;
  float: left;
  height: 400px;
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  margin: 5px 15px;
}
.purchaseButtonWrapper {
  width: 80px;
  float: left;
  margin: 5px;
}
.phoneCheckBox {
  width: 16px;
  float: left;
  margin: 0 5px;
}
</style>
