<template>
  <div class="row admin-page">
    <div class="col-xl-3 col-lg-4 col-md-12 col-sm-12 panelWrapper">
      <button
        type="button"
        class="col-md-12 btn btn-primary waves-effect waves-light"
        @click="handleAddScript"
      >
        Add New Script
      </button>
      <div class="filter form-group row">
        <div class="col-lg-12 col-md-12 col-sm-12 noPadding">
          <input
            autocomplete="off"
            type="text"
            class="form-control"
            v-model="scriptFilter"
            placeholder="Filter script"
          />
        </div>
      </div>
      <div class="list col-md-12">
        <div
          class="script item row"
          v-for="(item, index) in filteredScripts"
          :key="item.Id"
          :class="{ active: activeScript === item.Id }"
          @click="() => handleScriptSelection(item, index)"
          :disabled="saving || deleting"
          :style="[
            index % 2 === 0
              ? { 'background-color': '#F1EFEF' }
              : { 'background-color': '#FFFFFF' },
          ]"
        >
          <span :class="{ adminActiveMenuItem: activeScript === item.Id }">
            <i class="ri-file-paper-fill"></i>
            <span style="margin-left: 8px">{{ item.Title }}</span>
          </span>
        </div>
      </div>
      <div
        v-if="isMobileDevice()"
        style="
          border-top: 1px solid rgb(0, 0, 0, 0);
          margin: 20px 0 0 9px;
          padding-bottom: 40px;
        "
        class="col-md-12 scrollToSelectedItem"
      ></div>
    </div>
    <div class="col-xl-9 col-lg-8 col-md-12 col-sm-12">
      <form
        v-if="isEditing"
        class="form-horizontal formWrapper panelWrapper float-left"
        role="form"
      >
        <div class="panelContent noPadding">
          <div class="form-group row">
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-12">
              <label class="form-label" for="title">Title</label>
            </div>
            <div class="col-xl-6 col-lg-9 col-md-9 col-sm-12">
              <input
                autocomplete="off"
                type="text"
                id="title-sm"
                class="form-control"
                placeholder="Enter script title"
                v-model="selectedRow.Title"
                :class="v$.selectedRow.Title.$error ? 'has-error' : ''"
                @blur="v$.selectedRow.Title.$touch"
              />
            </div>
          </div>
          <div class="form-group row">
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-12">
              <label class="form-label" for="scriptRoles">Roles</label>
            </div>
            <div class="col-xl-6 col-lg-9 col-md-9 col-sm-12">
              <VueMultiselect
                id="scriptRoles"
                v-model="selectedRow.Roles"
                :options="sortedRoles"
                :multiple="true"
                :close-on-select="false"
                label="Name"
                track-by="Id"
                :class="v$.selectedRow.Roles.$error ? 'has-error' : ''"
                @blur="v$.selectedRow.Roles.$touch"
              />
            </div>
          </div>
          <div class="form-group row">
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-12">
              <label class="form-label" for="scriptStatus">Statuses</label>
            </div>
            <div class="col-xl-6 col-lg-9 col-md-9 col-sm-12">
              <VueMultiselect
                id="scriptStatus"
                v-model="selectedRow.Statuses"
                :options="statusList"
                :multiple="true"
                :close-on-select="false"
                label="Name"
                track-by="Id"
                :class="v$.selectedRow.Statuses.$error ? 'has-error' : ''"
                @blur="v$.selectedRow.Statuses.$touch"
              />
            </div>
          </div>
          <div class="form-group row">
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-12">
              <label class="form-label" for="displayOrder">Display Order</label>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-4 col-sm-12">
              <CustomNumericInput
                id="displayOrder"
                @updateValue="setDisplayOrder"
                :minNumber="1"
                :maxNumber="1000"
                :placeholder="'Max Postpone Days'"
                class="form-control"
                :value="selectedRow.DisplayOrder"
              />
            </div>
          </div>
          <div class="form-group row">
            <div
              class="offset-xl-2 col-xl-4 offset-lg-3 offset-md-3 col-lg-9 col-md-9 col-sm-12 noPadding"
            >
              <div style="width: 20px; float: left; margin: 0 12px">
                <input
                  type="checkbox"
                  class="form-control"
                  v-model="selectedRow.DisplayOnPullCreditOptions"
                />
              </div>
              <label class="col-form-label"
                >Display on pull credit options</label
              >
            </div>
          </div>

          <div class="form-group row">
            <div
              class="offset-xl-2 col-xl-4 offset-lg-3 offset-md-3 col-lg-9 col-md-9 col-sm-12 noPadding"
            >
              <div style="width: 20px; float: left; margin: 0 12px">
                <input
                  type="checkbox"
                  class="form-control"
                  v-model="selectedRow.DisplayOnDocusignOptions"
                />
              </div>
              <label class="col-form-label">Display on DocuSign options</label>
            </div>
          </div>

          <div class="form-group row">
            <div
              class="offset-xl-2 col-xl-4 offset-lg-3 offset-md-3 col-lg-9 col-md-4 col-sm-6 noPadding"
            >
              <div style="width: 20px; float: left; margin: 0 12px">
                <input
                  type="checkbox"
                  class="form-control"
                  v-model="selectedRow.IsActive"
                />
              </div>
              <label class="col-form-label">Active</label>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-12">
              <label class="form-label">Script</label>
            </div>
            <div
              class="editorWrapper col-xl-10 col-lg-9 col-md-9 col-sm-12"
              style="color: black"
            >
              <vue-editor
                id="textEditor"
                v-model="selectedRow.Script"
                :class="v$.selectedRow.Script.$error ? 'has-error' : ''"
                @blur="v$.selectedRow.Script.$touch"
                :editorToolbar="customToolbar"
              ></vue-editor>
            </div>
          </div>
          
          <div class="form-group row">
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-12">
              <label class="form-label">Spanish Script</label>
            </div>
            <div
              class="editorWrapper col-xl-10 col-lg-9 col-md-9 col-sm-12"
              style="color: black"
            >
              <vue-editor
                id="textEditor"
                v-model="selectedRow.SpanishScript"
                :editorToolbar="customToolbar"
              ></vue-editor>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <button
                type="button"
                id="saveButton"
                @click="handleSave"
                :disabled="saving || deleting"
                class="animated-btn btn btn-success btn-bordered-success waves-effect width-md waves-light submit-button float-right"
              >
                <i v-if="saving" class="fas fa-spinner mr-1"></i>
                Save
              </button>
              <button
                type="button"
                v-if="selectedRow.Id"
                :disabled="saving || deleting"
                @click="() => handleDelete(selectedRow.Id)"
                class="animated-btn btn btn-danger btn-bordered-danger waves-effect width-md waves-light submit-button float-right"
              >
                <i v-if="deleting" class="fas fa-spinner mr-1"></i>
                Delete
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import globalTypes from "@/store/types";
import { mapState } from "vuex";
import types from "./types";
import { VueEditor } from "vue3-editor";
import deepCopy from "@/helpers/deepCopy";
import _orderby from "lodash";
import { required } from "vuelidate/lib/validators";
import useValidate from "@vuelidate/core";
import CustomNumericInput from "@/components/CustomNumericInput";
import VueMultiselect from "vue-multiselect";
import formatMixin from "@/mixins/formatMixin";
import _orderBy from "lodash";
import utilitiesMixin from "@/mixins/utilitiesMixin";

const init = {
  Id: 0,
  Roles: [],
  Statuses: [],
  Title: "",
  IsActive: true,
  DisplayOrder: 0,
  Script: "",
  SpanishScript: "",
  DisplayOnPullCreditOptions: false,
  DisplayOnDocusignOptions: false,
};
const allRolesObj = { Id: "", Name: "All Roles" };
const allStatusObj = { Id: "", StatusName: "All Statuses" };
export default {
  name: "ScriptManagement",
  components: { CustomNumericInput, VueEditor, VueMultiselect },
  data() {
    return {
      v$: useValidate(),
      selectedRow: deepCopy(init),
      isEditing: false,
      saving: false,
      deleting: false,
      activeScript: null,
      scriptFilter: "",
      oldValue: "",
      valueToRevert: deepCopy(init),
      isCancelledBeforeSave: false,
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        [{ list: "ordered" }, { list: "bullet" }],

        [{ color: [] }, { background: [] }],

        ["link", "image"],
      ],
    };
  },
  mixins: [formatMixin, utilitiesMixin],
  computed: mapState({
    roleList: (state) => state.globals.roles,
    sortedRoles() {
      return _orderBy.orderBy(this.roleList, "Name", "asc");
    },
    statusList: (state) => state.globals.customerStatuses,
    list: (state) => state.globals.scriptList,
    filteredScripts() {
      return _orderby.orderBy(
        this.list.filter(
          (item) =>
            item.Title.toLowerCase().indexOf(this.scriptFilter.toLowerCase()) >
            -1
        ),
        "DisplayOrder"
      );
    },
    statusListLocal() {
      return _orderby.orderBy(
        this.statusList.map((item) => {
          return { Id: +item.Id, StatusName: item.Name };
        }),
        [(item) => item.StatusName.toLowerCase()],
        "asc"
      );
    },
    roleListLocal() {
      return _orderby.orderBy(
        this.roleList.map((item) => {
          return { Id: +item.Id, Name: item.Name };
        }),
        [(item) => item.Name.toLowerCase()],
        "asc"
      );
    },
  }),
  async mounted() {
    await Promise.all([
      this.$store.dispatch(globalTypes.GET_SCRIPTS),
      this.$store.dispatch(globalTypes.GET_STATUS_NAMES),
      this.$store.dispatch(globalTypes.GET_ROLES),
    ]);

    //this.statusListLocal.unshift(allStatusObj);
    //this.roleListLocal.unshift(allRolesObj);
  },

  methods: {
    setDisplayOrder(value) {
      this.selectedRow.DisplayOrder = value;
    },
    statusChanged() {
      let indx = this.selectedRow.Statuses.findIndex((x) => x.Id == "");
      if (indx > -1) {
        this.selectedRow.Statuses = [];
        this.selectedRow.Statuses.push(allStatusObj);
      }
    },
    statusSelected(selected) {
      if (selected.Id == "") {
        this.selectedRow.Status = [];
        this.selectedRow.Statuses.push(allStatusObj);
      } else {
        let indx = this.selectedRow.Statuses.findIndex((x) => x.Id == "");
        if (indx >= 0) {
          this.selectedRow.Statuses.splice(indx, 1);
        }
      }
    },
    roleChanged() {
      let indx = this.selectedRow.Roles.findIndex((x) => x.Id == "");
      if (indx > -1) {
        this.selectedRow.Roles = [];
        this.selectedRow.Roles.push(allRolesObj);
      }
    },
    roleSelected(selected) {
      if (selected.Id == "") {
        this.selectedRow.Roles = [];
        this.selectedRow.Roles.push(allRolesObj);
      } else {
        let indx = this.selectedRow.Roles.findIndex((x) => x.Id == "");
        if (indx >= 0) {
          this.selectedRow.Roles.splice(indx, 1);
        }
      }
    },

    async handleAddScript() {
      if (
        this.cleanHtml(this.oldValue) !=
          this.cleanHtml(JSON.stringify(this.selectedRow)) &&
        JSON.stringify(this.selectedRow) != JSON.stringify(init)
      ) {
        await this.$swal({
          title: "You have unsaved changes",
          text: "You will lose it if you don't save",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, continue without saving!",
        }).then(async (dialog) => {
          if (dialog.value) {
            this.isCancelledBeforeSave = true;
          } else {
            this.isCancelledBeforeSave = false;
            return;
          }
        });
      } else {
        this.selectedRow = { ...init };
        this.activeScript = null;
        this.isEditing = true;
      }
      if (this.isCancelledBeforeSave) {
        this.selectedRow = deepCopy(this.valueToRevert);
        let indx = this.list.findIndex((x) => x.Id == this.valueToRevert.Id);
        this.list[indx] = this.valueToRevert;
        this.list.push();

        this.isCancelledBeforeSave = false;
        this.selectedRow = { ...init };
        this.activeScript = null;
        this.isEditing = true;
      }
    },
    async handleSave() {
      if (this.oldValue == JSON.stringify(this.selectedRow)) {
        this.$swal.fire("Warning!", "No Changes Detected!", "warning");

        return;
      }
      this.saving = true;

      let err, result, type;

      let isScriptExists =
        this.list.filter(
          (x) => x.Title.toLowerCase() == this.selectedRow.Title.toLowerCase()
        ).length > 0;

      if (this.selectedRow.Id) {
        type = types.PUT_SCRIPT;
        isScriptExists =
          this.list.filter(
            (x) => x.Title.toLowerCase() == this.selectedRow.Title.toLowerCase()
          ).length > 1;
      } else {
        type = types.POST_SCRIPT;
      }
      if (isScriptExists) {
        this.saving = false;
        this.$swal.fire(
          "Warning!",
          "This script name is already exists in list!",
          "warning"
        );
        this.saving = false;
        return;
      }

      let scriptToSave = deepCopy(this.selectedRow);

      scriptToSave.Roles = scriptToSave.Roles.map((x) => +x.Id);
      scriptToSave.Statuses = scriptToSave.Statuses.map((x) => +x.Id);

      let allRolesSelected = scriptToSave.Roles.findIndex((x) => x == 0);
      if (allRolesSelected > -1) {
        scriptToSave.Roles = [];
      }

      let allStatusSelected = scriptToSave.Statuses.findIndex((x) => x == 0);
      if (allStatusSelected > -1) {
        scriptToSave.Statuses = [];
      }

      [err, result] = await this.$store.dispatch(type, scriptToSave);

      if (result) {
        this.$swal.fire("Success!", result.Message, "success");
        this.selectedRow = deepCopy(result.Data);
        this.selectedRow.Roles = deepCopy(result.Data);
        this.selectedRow = deepCopy(result.Data);
        for (let i = 0; i < result.Data.Statuses.length; i++) {
          this.selectedRow.Statuses[i].Id = result.Data.Statuses[i].Id;
          this.selectedRow.Statuses[i].Name =
            result.Data.Statuses[i].StatusName;
        }
        if (type == types.POST_SCRIPT) {
          this.list.push(this.selectedRow);
        } else if (type == types.PUT_SCRIPT) {
          this.list.push();
        }
        this.setRoleAndStatus(this.selectedRow);
        this.oldValue = JSON.stringify(this.selectedRow);
        this.activeScript = this.selectedRow.Id;
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
      this.saving = false;
    },
    async handleDelete(Id) {
      this.deleting = true;
      await this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (dialog) => {
        if (dialog.value) {
          let rowIndex = this.list.findIndex((x) => x.Id == Id);
          let err, result;

          [err, result] = await this.$store.dispatch(types.DELETE_SCRIPT, Id);

          if (result && rowIndex > -1) {
            this.list.splice(rowIndex, 1);
            this.reset();

            await this.$swal("Deleted!", result.Message, "success");
          } else {
            let errMsg = this.getApiErrorMessage(err);
            this.$swal("Error!", errMsg, "error");
          }
        }
      });
      this.deleting = false;
    },
    setRoleAndStatus(row) {
      if (row.Roles.length == 0) {
        row.Roles.push(allRolesObj);
      }

      if (row.Statuses.length == 0) {
        row.Statuses.push(allStatusObj);
      }
    },
    handleCancel() {
      this.isEditing = false;
    },
    async handleScriptSelection(row) {
      if (
        this.cleanHtml(this.oldValue) !=
          this.cleanHtml(JSON.stringify(this.selectedRow)) &&
        JSON.stringify(this.selectedRow) != JSON.stringify(init)
      ) {
        await this.$swal({
          title: "You have unsaved changes",
          text: "You will lose it if you don't save",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, continue without saving!",
        }).then(async (dialog) => {
          if (dialog.value) {
            this.isCancelledBeforeSave = true;
          } else {
            this.isCancelledBeforeSave = false;
            return;
          }
        });
      } else {
        this.valueToRevert = deepCopy(row);

        if (row.Roles.length == 0) {
          //row.Roles.push(allRolesObj);
        }
        if (row.Statuses.length == 0) {
          //row.Statuses.push(allStatusObj);
        }
        this.oldValue = JSON.stringify(row);
        this.activeScript = row.Id;

        this.selectedRow = row;

        this.isCancelledBeforeSave = false;
      }
      this.isEditing = true;

      if (this.isCancelledBeforeSave) {
        this.selectedRow = deepCopy(this.valueToRevert);

        let indx = this.list.findIndex((x) => x.Id == this.valueToRevert.Id);
        this.list[indx] = this.valueToRevert;
        this.list.push();

        this.valueToRevert = deepCopy(row);

        this.oldValue = JSON.stringify(row);
        this.activeScript = row.Id;

        this.selectedRow = row;
        this.isCancelledBeforeSave = false;
      }
      let el = this.$el.querySelector(".scrollToSelectedItem");
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
    reset() {
      this.activeScript = null;
      this.selectedRow = { ...init };
      this.isEditing = false;
    },
    cleanHtml(text) {
      return text
        .replace(/[^\w\s]/gi, "")
        .replace(/ /g, "")
        .replace(/ /g, "");
    },
  },
  validations() {
    return {
      selectedRow: {
        Title: { required },
        Script: { required },
        Roles: {
          atLeastOne(list) {
            return list.length > 0;
          },
        },
        Statuses: {
          atLeastOne(list) {
            return list.length > 0;
          },
        },
      },
    };
  },
};
</script>
