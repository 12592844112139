<template>
  <div class="row admin-page">
    <div v-if="isLoading" class="loading">
      <div class="text-center" style="margin-top: 350px">
        <i class="spinner-grow text-info m-2"></i>
      </div>
    </div>
    <Placeholders
      :typeId="0"
      @setPlaceholderVisibility="isPlaceholdersOpen = $event"
      @setPlaceholderContent="setPlaceholder($event)"
      v-if="isPlaceholdersOpen"
    ></Placeholders>
    <div class="col-xl-3 col-lg-4 col-md-12 col-sm-12 panelWrapper">
      <button
        @click="insertDocumentTemplate()"
        class="col-md-12 btn btn-primary waves-effect waves-light"
      >
        Add New Document Template
      </button>
      <div class="col-lg-12 col-md-12 col-sm-12 noPadding">
        <input
          autocomplete="off"
          type="text"
          class="form-control"
          v-model="templateFilter"
          placeholder="Filter templates"
        />
      </div>
      <div class="list col-md-12">
        <div
          class="item row"
          v-for="(item, index) in orderedTemplateList"
          :class="{ active: activeItem === item.Id }"
          :key="item.Id"
          @click="() => selectTemplate(item)"
          :style="[
            index % 2 === 0
              ? { 'background-color': '#F1EFEF' }
              : { 'background-color': '#FFFFFF' },
          ]"
        >
          <span :class="{ adminActiveMenuItem: activeItem === item.Id }">
            <i class="ri-file-4-line" style="vertical-align: middle"></i>
            <span style="margin-left: 10px">{{ item.DocumentName }}</span>
          </span>
        </div>
      </div>
    </div>

    <div class="col-xl-9 col-lg-8 col-md-12 col-sm-12">
      <form
        role="form"
        class="form-horizontal formWrapper panelWrapper float-left"
      >
        <div v-show="showInsertPanel">
          <div class="form-group row">
            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-12 col-xs-12">
              <label for="title" class="form-label">Template Name</label>
            </div>
            <div class="col-xl-6 col-lg-8 col-md-8 col-sm-12 col-xs-12">
              <input
                autocomplete="off"
                type="text"
                id="DTdocumentName"
                class="form-control"
                v-model="selectedRow.DocumentName"
              />
            </div>
          </div>
          <div class="form-group row mt-2">
            <div class="col-xl-8 col-lg-12 col-md-12 col-sm-12">
              <button
                type="button"
                id="placeholderBtnDTM"
                @click="openPlaceholders()"
                class="btn btn-primary fas fa-hashtag is-small cursor-pointer text-light rounded"
                style="
                  position: absolute;
                  right: 12px;
                  top: 0;
                  z-index: 100;
                  background-color: #3498db;
                "
              ></button>
            </div>
          </div>
          <div class="form-group row mt-4">
            <div
              v-if="uploadData"
              class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12"
            >
              <label class="btn btn-outline-success">
                {{ uploadData.Name }}
              </label>
            </div>
            <div class="col-xl-2 col-lg-4 col-md-12 col-sm-12">
              <input
                autocomplete="off"
                type="file"
                ref="uploader"
                style="display: none"
                accept="docx"
                @click="handleFileClick"
                @change="handleFileChange"
              />
              <button
                type="button"
                class="btn btn-info waves-effect waves-light"
                style="width: 100%"
                v-on:click.prevent.stop="$refs.uploader.click()"
              >
                Select File
              </button>
            </div>
            <div class="col-xl-2 col-lg-4 col-md-12 col-sm-12">
              <button
                type="button"
                class="btn btn-success waves-effect waves-light"
                style="width: 100%"
                v-show="showUploadButton"
                @click="uploadTemplate()"
              >
                Upload File
              </button>
            </div>
          </div>
        </div>
        <div v-show="showInfoPanel">
          <div class="form-group row">
            <div class="col-xl-8 col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <label for="title" class="form-label"
                >Template Name: {{ selectedRow.DocumentName }}
              </label>
            </div>
          </div>
          <div class="form-group row mt-4">
            <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12">
              <button
                type="button"
                class="btn btn-primary waves-effect waves-light"
                style="width: 100%"
                @click="downloadTemplate()"
                :disabled="isLoading"
              >
                Download
              </button>
            </div>
            <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12">
              <button
                type="button"
                class="btn btn-danger waves-effect waves-light"
                style="width: 100%"
                @click="deleteTemplate()"
              >
                Delete Template
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import types from "./types";
import _orderby from "lodash";
import deepCopy from "@/helpers/deepCopy";
import Placeholders from "@/components/Placeholders/Placeholders";
import utilitiesMixin from "@/mixins/utilitiesMixin";
export default {
  name: "Test",
  components: { Placeholders },
  mixins: [utilitiesMixin],
  data() {
    return {
      isLoading: false,
      showInsertPanel: false,
      showInfoPanel: false,
      showUploadButton: false,
      isPlaceholdersOpen: false,
      templateFilter: "",
      activeItem: null,
      selectedRow: {
        Id: 0,
        DocumentName: "",
      },
      validExtensions: ["docx"],
      uploadData: null,
    };
  },
  computed: mapState({
    templateList: (state) => state.documentTemplateManagement.templateList,
    orderedTemplateList() {
      return _orderby.orderBy(
        this.templateList.filter(
          (item) =>
            item.DocumentName.toLowerCase().indexOf(
              this.templateFilter.toLowerCase()
            ) > -1
        ),
        "DocumentName"
      );
    },
  }),
  async mounted() {
    await this.$store.dispatch(types.GET_DOCUMENT_TEMPLATES);
  },
  methods: {
    setPlaceholder(value) {
      navigator.clipboard.writeText(value);
    },
    openPlaceholders() {
      this.isPlaceholdersOpen = true;
    },
    async uploadTemplate() {
      if (this.selectedRow.DocumentName == "") {
        this.$swal("Warning!", "Template name is required", "warning");
        return;
      }

      if (this.uploadData != null) {
        let err, result;

        this.uploadData.Name = this.selectedRow.DocumentName;
        [err, result] = await this.$store.dispatch(
          types.INSERT_DOCUMENT_TEMPLATE,
          this.uploadData
        );
        if (result) {
          this.$swal("Success!", "Document Uploaded.", "success");
          this.selectedRow.Id = result.Data.Id;
          this.selectedRow.DocumentName = result.Data.DocumentName;
          this.templateList.push(this.selectedRow);
          this.showInsertPanel = false;
          this.showInfoPanel = false;
        } else {
          this.$swal("Error", "An error occured!", "error");
        }
      } else {
        this.$swal("Warning!", "No files selected!", "warning");
      }
    },
    async insertDocumentTemplate() {
      this.showInfoPanel = false;
      this.showInsertPanel = true;
      this.selectedRow.Id = 0;
      this.selectedRow.DocumentName = "";
      this.uploadData = null;
      this.showUploadButton = false;
    },
    selectTemplate(item) {
      this.selectedRow = deepCopy(item);
      this.showInfoPanel = true;
      this.showInsertPanel = false;
      this.activeItem = this.selectedRow.Id;
    },
    async downloadTemplate() {
      this.isLoading = true;
      if (this.selectedRow.Id > 0) {
        let err, result;
        [err, result] = await this.$store.dispatch(
          types.DOWNLOAD_DOCUMENT_TEMPLATE,
          this.selectedRow.Id
        );
        if (result) {
          this.saveByteArray("newDocument.docx", result);
        }
      }
      this.isLoading = false;
    },
    async deleteTemplate() {
      if (this.selectedRow.Id > 0) {
        await this.$swal({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        }).then(async (dialog) => {
          if (dialog.value) {
            let err, result;
            [err, result] = await this.$store.dispatch(
              types.DELETE_DOCUMENT_TEMPLATE,
              this.selectedRow.Id
            );
            if (result) {
              this.$swal("Deleted!", result.Message, "success");
              let indx = this.templateList.findIndex(
                (x) => x.Id == this.selectedRow.Id
              );

              this.templateList =
                indx >= 0
                  ? this.templateList.splice(indx, 1)
                  : this.templateList;
            } else {
              this.$swal("Error!", "An error occured!", "error");
            }
          }
        });

        this.showInsertPanel = false;
        this.showInfoPanel = false;
      }
    },
    handleFileClick() {
      this.$refs.uploader.value = "";
      const input = this.$refs.uploader;
      input.type = "file";
    },
    async handleFileChange(e) {
      if (e.target.files && e.target.files.length > 0) {
        const file = e.target.files[0];
        let fileExtension = "";
        if (file) {
          fileExtension = file.name.split(".").pop();

          if (fileExtension) {
            if (!this.validExtensions.includes(fileExtension.toLowerCase())) {
              this.$swal.fire(
                "Warning!",
                "File type is not valid. Only docx files are allowed.",
                "warning"
              );
              e.preventDefault();
              return;
            }
          }

          if (!this.isValidFileSize(file.size, 20)) {
            this.$swal(
              "Warning!",
              "Maximum allowed file size is 20 MB for document. Please resize your file.",
              "warning"
            );
            return;
          }

          let url = URL.createObjectURL(file);

          const toBase64 = (file) =>
            new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => resolve(reader.result);
              reader.onerror = (error) => reject(error);
            });
          this.showUploadButton = true;

          let data = await toBase64(file);
          data = data.replace(
            "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,",
            ""
          );
          data = data.replace("data:application/pdf;base64,", "");
          this.uploadData = {
            Type: "insert",
            Name: this.cropFileNames(file.name),
            Extension: "." + fileExtension,
            DocumentData: data,
          };
        }
      }
    },

    saveByteArray(name, byte) {
      let blob = new Blob([byte], { type: "application/docx" });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      let fileName = name;
      link.download = fileName;
      link.click();
    },
    isValidFileSize(size, limitMb) {
      let rtn = true;
      if (size > 1024 * 1024 * limitMb) {
        rtn = false;
      }

      return rtn;
    },
  },
};
</script>

<style></style>
