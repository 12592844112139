<template>
  <div class="row admin-page">
    <div class="col-xl-3 col-lg-4 col-md-12 col-sm-12 panelWrapper">
      <div class="filter form-group row">
        <div class="col-lg-12 col-md-12 col-sm-12 noPadding">
          <input
            autocomplete="none"
            type="text"
            class="form-control"
            v-model="dashboardConfigFilter"
            placeholder="Filter"
          />
        </div>
      </div>
      <div class="list col-md-12">
        <div
          v-if="defaultConfig"
          class="role item row"
          :key="0"
          :class="{ active: activeItem === defaultConfig.Id }"
          style="background-color: #f1efef"
          :disabled="saving"
          @click="() => handleListSelection(defaultConfig, 0)"
        >
          <span
            :class="{ adminActiveMenuItem: activeItem === defaultConfig.Id }"
          >
            <i class="ri-profile-fill"></i>
            <span style="margin-left: 8px"> {{ defaultConfig.Name }}</span>
          </span>
        </div>
        <div
          class="role item row"
          v-for="(item, index) in filterDashboardConfig"
          :key="item.Id"
          :class="{ active: activeItem === item.Id }"
          :style="[
            index % 2 === 0
              ? { 'background-color': '#FFFFFF' }
              : { 'background-color': '#F1EFEF' },
          ]"
          :disabled="saving"
          @click="() => handleListSelection(item, index)"
        >
          <span :class="{ adminActiveMenuItem: activeItem === item.Id }">
            <i class="ri-profile-fill"></i>
            <span style="margin-left: 8px"> {{ item.Name }}</span>
          </span>
        </div>
      </div>
      <!-- <div
          v-if="isMobileDevice()"
          style="
            border-top: 1px solid rgb(0, 0, 0, 0);
            margin: 20px 0 0 9px;
            padding-bottom: 20px;
          "
          class="col-md-12 scrollToSelectedItem"
        ></div> -->
    </div>

    <div
      class="col-xl-9 col-lg-8 col-md-12 col-sm-12 noMargin"
      v-if="isEditing"
    >
      <div class="panelContent" style="padding-top: 0">
        <div class="form-group row">
          <div class="col-md-12">
            <div class="col-md-5 mb-3">
              <label class="generalLabel">Daily Deals</label>
              <input
                type="text"
                class="form-control"
                :placeholder="
                  this.UseDefaults == true
                    ? 'Default Option Selected'
                    : 'Daily Deals'
                "
                v-model="selectedRow.DashboardConfiguration.DailyDeals"
                :disabled="UseDefaults == true && selectedRow.Id > 0"
                :class="
                  this.UseDefaults == true && selectedRow.Id > 0
                    ? 'readonlyInput'
                    : 'form-control'
                "
                v-myMask="{
                  alias: 'numeric',
                  rightAlign: false,
                  min: 0,
                }"
              />
            </div>
            <div class="col-md-5 mb-3">
              <label class="generalLabel">Daily Total Debt Submitted</label>
              <input
                type="text"
                class="form-control"
                :placeholder="
                  this.UseDefaults == true
                    ? 'Default Option Selected'
                    : 'Daily Total Debt Submitted'
                "
                :disabled="UseDefaults == true && selectedRow.Id > 0"
                :class="
                  this.UseDefaults == true && selectedRow.Id > 0
                    ? 'readonlyInput'
                    : 'form-control'
                "
                v-myMask="{
                  alias: 'currency',
                  prefix: '$',
                  rightAlign: false,
                  min: 0,
                }"
                v-model="
                  selectedRow.DashboardConfiguration.DailyTotalDebtSubmitted
                "
              />
            </div>
            <div class="col-md-5 mb-3">
              <label class="generalLabel">Daily Talk Time (mins)</label>
              <input
                type="text"
                class="form-control"
                :placeholder="
                  this.UseDefaults == true
                    ? 'Default Option Selected'
                    : 'Daily Total Debt Submitted'
                "
                :disabled="UseDefaults == true && selectedRow.Id > 0"
                :class="
                  this.UseDefaults == true && selectedRow.Id > 0
                    ? 'readonlyInput'
                    : 'form-control'
                "
                v-model="selectedRow.DashboardConfiguration.DailyTalkTime"
                v-myMask="{
                  alias: 'numeric',
                  rightAlign: false,
                  min: 0,
                }"
              />
            </div>
            <div v-if="selectedRow.Id != 0" class="col-md-5 mb-3">
              <label class="generalLabel">Use Defaults</label>
              <input
                autocomplete="off"
                type="checkbox"
                class="checkbox-input generalCheckBox ml-3"
                v-model="UseDefaults"
                @change="setDefault"
              />
            </div>
            <div
              class="col-md-5"
              style="display: flex; justify-content: flex-end"
            >
              <button
                type="button"
                class="btn btn-success btn-md waves-effect waves-light mt-2"
                style="width: 30%"
                @click="saveDashboardConfig()"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import deepCopy from "@/helpers/deepCopy";
import _orderby from "lodash";
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import globalTypes from "@/store/types";
import _orderBy from "lodash";

const init = {
  Id: 0,
  Name: "",
  DashboardConfiguration: {
    Id: null,
    DailyDeals: null,
    DailyTotalDebtSubmitted: null,
    DailyTalkTime: null,
    UserId: null,
  },
};
export default {
  name: "DashboardConfigManagement",
  components: {},
  data() {
    return {
      dashboardConfigFilter: "",
      isEditing: false,
      selectedRow: deepCopy(init),
      activeItem: null,
      saving: false,
      isCancelledBeforeSave: false,
      oldValue: "",
      UseDefaults: false,
    };
  },
  mixins: [formatMixin, utilitiesMixin],
  computed: mapState({
    dashboardConfigUserList: (state) =>
      state.dashboardConfigManagement.dashboardConfigUserList,
    defaultConfig() {
      let list = this.dashboardConfigUserList.filter((x) => x.Id == 0);
      return list.length > 0 ? list[0] : null;
    },
    filterDashboardConfig() {
      return _orderby.orderBy(
        this.dashboardConfigUserList.filter(
          (item) =>
            item.Id > 0 &&
            item.Name.toLowerCase().indexOf(
              this.dashboardConfigFilter.toLowerCase()
            ) > -1
        ),
        "Name",
        "asc"
      );
    },
  }),
  async mounted() {
    await Promise.all([this.$store.dispatch(types.GET_DASHBOARD_CONFIG)]);
  },

  methods: {
    setDefault() {
      if (this.UseDefaults == true) {
        this.selectedRow.DashboardConfiguration.DailyDeals = "";
        this.selectedRow.DashboardConfiguration.DailyTotalDebtSubmitted = "";
        this.selectedRow.DashboardConfiguration.DailyTalkTime = "";
      }
    },
    async handleListSelection(row) {
      if (
        this.oldValue != JSON.stringify(this.selectedRow) &&
        JSON.stringify(this.selectedRow) != JSON.stringify(init)
      ) {
        await this.$swal
          .fire({
            title: "You have unsaved changes",
            text: "You will lose it if you don't save",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, continue without saving!",
          })
          .then(async (dialog) => {
            if (dialog.value) {
              this.isCancelledBeforeSave = true;
            } else {
              this.isCancelledBeforeSave = false;
              return;
            }
          });
      } else {
        let rowData = deepCopy(row);
        if (rowData.DashboardConfiguration == null) {
          rowData.DashboardConfiguration = deepCopy(
            init.DashboardConfiguration
          );
          if (rowData.Id > 0) {
            this.UseDefaults = true;
          }
        } else {
          this.UseDefaults = false;
        }
        this.selectedRow = deepCopy(rowData);
        this.oldValue = JSON.stringify(rowData);
        this.activeItem = row.Id;
        this.isEditing = true;
      }
      if (this.isCancelledBeforeSave) {
        let rowData = deepCopy(row);
        if (rowData.DashboardConfiguration == null) {
          rowData.DashboardConfiguration = deepCopy(
            init.DashboardConfiguration
          );
          if (rowData.Id > 0) {
            this.UseDefaults = true;
          }
        } else {
          this.UseDefaults = false;
        }
        this.selectedRow = deepCopy(rowData);
        this.oldValue = JSON.stringify(rowData);
        this.activeItem = row.Id;
        this.isEditing = true;
      }
      this.isCancelledBeforeSave = false;
    },
    async saveDashboardConfig() {
      if (this.oldValue == JSON.stringify(this.selectedRow)) {
        this.$swal.fire("Warning!", "No changes detected!", "warning");
        return;
      }

      if (this.UseDefaults == true && this.selectedRow.Id > 0) {
        this.saving = true;
        let err, result;
        [err, result] = await this.$store.dispatch(
          types.DELETE_DASHBOARD_CONFIG,
          {
            UserId: this.selectedRow.Id,
          }
        );
        if (result) {
          this.successMsg(result);
        } else {
          this.errorMsg(err);
        }
      }
      if (this.UseDefaults == false || this.selectedRow.Id == 0) {
        let err, result;
        [err, result] = await this.$store.dispatch(
          types.INSERT_OR_UPDATE_DASHBOARD_CONFIG,
          {
            UserId: this.selectedRow.Id,
            DailyDeals: parseFloat(
              this.selectedRow.DashboardConfiguration.DailyDeals
            ),
            DailyTotalDebtSubmitted: parseFloat(
              this.selectedRow.DashboardConfiguration.DailyTotalDebtSubmitted
            ),
            DailyTalkTime: parseFloat(
              this.selectedRow.DashboardConfiguration.DailyTalkTime
            ),
          }
        );
        if (result) {
          this.successMsg(result);
        } else {
          this.errorMsg(err);
        }
      }

      this.saving = false;
    },
    successMsg(result) {
      this.$swal("Success!", result.Message, "success");
      this.oldValue = JSON.stringify(this.selectedRow);
      let indx = this.dashboardConfigUserList.findIndex(
        (x) => x.Id == this.selectedRow.Id
      );

      if (indx > 0) {
        this.dashboardConfigUserList[indx] = this.selectedRow;
        this.dashboardConfigUserList.push();
      }
    },
    errorMsg(err) {
      let errMsg = this.getApiErrorMessage(err);
      this.$swal("Error!", errMsg, "error");
    },
  },
};
</script>

<style scoped></style>
