<template>
  <div class="col-md-12">
    <ReportSummary
      :exportData="reportData"
      :reportName="'FortivaScrubReport'"
      :reportClass="'ScrubReports'"
      :recordCount="recordCount"
      @updateLoadingStatus="reportLoading = $event"
      @setPayload="setPayload"
      @clearReport="clearReport"
      @setPagination="setPagination"
      :loadingSpinner="loadingSpinner"
    />
    <div
      class="datagrid-table gcsTabsTableScroll report-wrapper"
      :style="'max-height: ' + reportsPanelHeight + 'px; overflow-y:auto;'"
    >
      <o-table
        :data="filteredFortivaScrubReport"
        :paginated="isPaginated && filteredFortivaScrubReport.length > 0"
        :per-page="perPage"
        :pagination-simple="isPaginationSimple"
        :pagination-position="paginationPosition"
        :sticky-header="true"
      >
        <o-table-column field="CustomerId" label="ClientId" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CustomerIdFilter"
                placeholder=""
                v-myMask="{
                  alias: 'numeric',
                  rightAlign: false,
                }"
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.CustomerId }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ addPrefixToCustomerId(props.row.CustomerId) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column field="LastName" label="LastName" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="LastNameFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.LastName }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.LastName }}
            </span>
          </template>
        </o-table-column>

        <o-table-column field="FirstName" label="FirstName" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="FirstNameFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.FirstName }}
          </template>
        </o-table-column>

        <o-table-column field="Ssn" label="Ssn" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="SsnFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.Ssn }}
          </template>
        </o-table-column>

        <o-table-column field="Dob" label="DOB" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="DOBFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ formatShortDate(props.row.Dob) }}
          </template>
        </o-table-column>

        <o-table-column
          field="CoCustomer.LastName"
          label="CoAppLastName"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CoAppLastNameFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{
              props.row.CoCustomer == null ? "" : props.row.CoCustomer.LastName
            }}
          </template>
        </o-table-column>

        <o-table-column
          field="CoCustomer.FirstName"
          label="CoAppFirstName"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CoAppFirstNameFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{
              props.row.CoCustomer == null ? "" : props.row.CoCustomer.FirstName
            }}
          </template>
        </o-table-column>

        <o-table-column
          field="CoCustomer.Ssn"
          label="CoAppSsn"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CoAppSsnFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.CoCustomer == null ? "" : props.row.CoCustomer.Ssn }}
          </template>
        </o-table-column>

        <o-table-column
          field="CoCustomer.Dob"
          label="CoAppDOB"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CoAppDOBFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{
              props.row.CoCustomer == null
                ? ""
                : formatShortDate(props.row.CoCustomer.Dob)
            }}
          </template>
        </o-table-column>

        <o-table-column
          field="Address.Line1"
          label="Address1"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="Address1Filter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.Address == null ? "" : props.row.Address.Line1 }}
          </template>
        </o-table-column>

        <o-table-column field="Address.City" label="City" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CityFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.Address == null ? "" : props.row.Address.City }}
          </template>
        </o-table-column>

        <o-table-column
          field="Address.ShortCode"
          label="State"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="ShortCodeFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.Address == null ? "" : props.row.Address.ShortCode }}
          </template>
        </o-table-column>

        <o-table-column field="Address.Zip" label="Zip" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="ZipFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.Address == null ? "" : props.row.Address.Zip }}
          </template>
        </o-table-column>

        <o-table-column
          field="Creditor.CurrName"
          label="CurrentCreditor"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CurrentCreditorFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.Creditor == null ? "" : props.row.Creditor.CurrName }}
          </template>
        </o-table-column>

        <o-table-column
          field="Creditor.AcctN"
          label="AccountNumber"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="AccountNumberFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.Creditor == null ? "" : props.row.Creditor.AcctN }}
          </template>
        </o-table-column>

        <o-table-column
          field="PolicyGroup"
          label="PolicyGroup"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="PolicyGroupFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.PolicyGroup }}
          </template>
        </o-table-column>
      </o-table>
      <section
        v-if="filteredFortivaScrubReport.length == 0"
        class="section"
        style="text-align: center"
      >
        <div class="content has-text-grey has-text-centered mb-4">
          <p class="noMargin">
            <i
              style="font-size: 24px; color: #939393"
              class="ri-question-line"
            ></i>
          </p>
          <p class="noMargin">Nothing here.</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import ReportSummary from "./ReportSummary.vue";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import deepCopy from "../../helpers/deepCopy";

export default {
  name: "FortivaScrubReport",
  components: { ReportSummary },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      customerPrefix: null,
      reportsPanelHeight: 300,
      loadingSpinner: false,
      isPaginationSimple: false,
      isPaginated: true,
      paginationPosition: "both",
      currentPage: 1,
      perPage: 200,

      recordCount: 0,
      reportLoading: false,
      reportData: [],

      fortivaScrubReportInitSummary: {
        CustomerId: "",
        LastName: "",
        FirstName: "",
        Ssn: "",
        Dob: "",
        CoCustomer: {
          FirstName: "",
          LastName: "",
          Name: null,
          Ssn: "",
          Dob: "",
        },
        Address: {
          Line1: "",
          City: "",
          ShortCode: "",
          Zip: "",
        },
        CurrentCreditor: "",
        AccountNumber: "",
        PolicyGroup: "",
      },
      PolicyGroupFilter: "",
      CustomerIdFilter: "",
      LastNameFilter: "",
      FirstNameFilter: "",
      SsnFilter: "",
      DOBFilter: "",
      CoAppLastNameFilter: "",
      CoAppFirstNameFilter: "",
      CoAppSsnFilter: "",
      CoAppDOBFilter: "",
      Address1Filter: "",
      CityFilter: "",
      ShortCodeFilter: "",
      ZipFilter: "",

      CurrentCreditorFilter: "",
      AccountNumberFilter: "",
    };
  },
  computed: mapState({
    fortivaScrubList: (state) => state.reports.fortivaScrubList,

    filteredFortivaScrubReport() {
      let rtn = [];
      let tmpList =
        this.fortivaScrubList.length > 0
          ? this.fortivaScrubList.filter((item) => {
              return (
                this.filterString(item.CustomerId, this.CustomerIdFilter) &&
                this.filterString(item.LastName, this.LastNameFilter) &&
                this.filterString(item.FirstName, this.FirstNameFilter) &&
                this.filterString(item.PolicyGroup, this.PolicyGroupFilter) &&
                this.filterString(item.Ssn, this.SsnFilter) &&
                new Date(item.Dob)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .indexOf(this.DOBFilter) > -1 &&
                this.filterString(
                  item.CoCustomer.LastName,
                  this.CoAppLastNameFilter
                ) &&
                this.filterString(
                  item.CoCustomer.FirstName,
                  this.CoAppFirstNameFilter
                ) &&
                this.filterString(item.CoCustomer.Ssn, this.CoAppSsnFilter) &&
                new Date(item.CoCustomer.Dob)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .indexOf(this.CoAppDOBFilter) > -1 &&
                this.filterString(item.Address.Line1, this.Address1Filter) &&
                this.filterString(item.Address.City, this.CityFilter) &&
                this.filterString(
                  item.Address.ShortCode,
                  this.ShortCodeFilter
                ) &&
                this.filterString(item.Address.Zip, this.ZipFilter) &&
                this.filterString(
                  item.Creditor.CurrName,
                  this.CurrentCreditorFilter
                ) &&
                this.filterString(item.Creditor.AcctN, this.AccountNumberFilter)
              );
            })
          : [];

      if (tmpList.length == 0) {
        this.reportData = [];
        return rtn;
      }
      let totalCustomer = tmpList.length.toString();

      this.fortivaScrubReportInitSummary.CustomerId = "Total: " + totalCustomer;
      this.recordCount = tmpList.length;
      this.fortivaScrubReportInitSummary.IsSummary = true;

      rtn.push(...tmpList);
      rtn.push(this.fortivaScrubReportInitSummary);
      this.reportData = rtn;

      return rtn;
    },
  }),
  async mounted() {
    this.reportsPanelHeight = this.setReportGridHeight(
      window.innerHeight,
      document.getElementById("reportsSummary")
    );

    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

    if (
      this.userInfo != null &&
      this.userInfo.customerIdPrefix != null &&
      this.userInfo.customerIdPrefix.length > 0
    ) {
      this.customerPrefix = this.userInfo.customerIdPrefix;
    }

    await this.sleep(1000);
    this.fixStickyHeaders();
  },
  methods: {
    setPagination(data) {
      this.isPaginated = data;
    },
    addPrefixToCustomerId(customerId) {
      let rtn = customerId.toString();
      if (this.customerPrefix != null) {
        rtn = this.customerPrefix + rtn;
      }
      return rtn;
    },
    async setPayload(value) {
      this.loadingSpinner = true;
      let err, result;
      [err, result] = await this.$store.dispatch(
        types.GET_FORTIVA_SCRUB_REPORT,
        value
      );
      if (result && result.Data) {
        let apiResult = result.Data;
        let formattedData = [];
        if (result) {
          if (apiResult.length > 0) {
            for (let item of apiResult) {
              if (item.Ssn != "") {
                item.Ssn = await this.AES256_GCM_decrypt(item.Ssn);
              }
              if (item.CoCustomer != null && item.CoCustomer.Ssn != null) {
                item.CoCustomer.Ssn = await this.AES256_GCM_decrypt(
                  item.CoCustomer.Ssn
                );
              }
            }
            for (let item of apiResult) {
              if (item.Creditors) {
                for (let c of item.Creditors) {
                  formattedData.push({
                    CustomerId: item.CustomerId,
                    FirstName: item.FirstName,
                    LastName: item.LastName,
                    Name: item.Name,
                    Ssn: item.Ssn,
                    Dob: item.Dob,
                    CoCustomer: {
                      FirstName:
                        item.CoCustomer == null
                          ? ""
                          : item.CoCustomer.FirstName,
                      LastName:
                        item.CoCustomer == null ? "" : item.CoCustomer.LastName,
                      Name: item.CoCustomer == null ? "" : item.CoCustomer.Name,
                      Ssn: item.CoCustomer == null ? "" : item.CoCustomer.Ssn,
                      Dob: item.CoCustomer == null ? "" : item.CoCustomer.Dob,
                    },
                    Address: {
                      Line1: item.Address == null ? "" : item.Address.Line1,
                      City: item.Address == null ? "" : item.Address.City,
                      ShortCode:
                        item.Address == null ? "" : item.Address.ShortCode,
                      Zip: item.Address == null ? "" : item.Address.Zip,
                    },
                    Creditor: {
                      Name: c.Name,
                      CurrName: c.CurrName,
                      AcctN: c.AcctN,
                      CurrAmt: c.CurrAmt,
                    },
                    PolicyGroup: item.PolicyGroup,
                  });
                }
              }
            }
            await this.$store.dispatch(
              types.MODIFY_FORTIVA_SCRUB_REPORT,
              formattedData
            );
          }
        }
      }
      this.loadingSpinner = false;

      await this.sleep(500);
      this.fixStickyHeaders();
    },
    clearReport() {
      this.$store.dispatch(types.RESET_REPORTS);
      this.reportData = [];
    },
  },
};
</script>

<style scoped></style>
