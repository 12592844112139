<template>
  <div class="col-md-12 newPanelWrapper noMargin">
    <div>
      <div
        class="mt-3"
        style="display: flex; flex-direction: column; align-items: center"
      >
        <label style="font-size: 16px">Delete Progress</label>
        <label v-if="deletedCount > 0" style="font-size: 16px"
          >{{ deletedCount }} / {{ idList.length }}...</label
        >
        <div class="spinner-border avatar-md text-primary m-3"></div>
      </div>
    </div>
  </div>
</template>

<script>
import types from "./types";
import utilitiesMixin from "@/mixins/utilitiesMixin";
export default {
  name: "DocusignDocumentBulkDeletePopup",
  components: {},
  props: {
    idList: {
      type: Array,
      default: () => [],
    },
  },
  mixins: [utilitiesMixin],

  data() {
    return {
      deletedCount: 0,
    };
  },

  async mounted() {
    if (this.idList.length > 0) {
      for (let item of this.idList) {
        let err, result;
        [err, result] = await this.$store.dispatch(
          types.DELETE_DOCUSIGN_TEMPLATE,
          { Id: item }
        );

        if (result) {
          this.deletedCount += 1;
        }
      }

      if (this.deletedCount == this.idList.length) {
        this.$swal("Success", "Deleted successfully.", "success");
      }

      this.$emit("closeDeletePopup");
    }
  },
  methods: {},
};
</script>

<style></style>
