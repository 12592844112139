<template>
  <div class="row admin-page officePage">
    <div class="col-xl-3 col-lg-4 col-md-12 col-sm-12">
      <div class="table is-striped table is-hoverable">
        <div class="col-lg-12 col-md-12 col-sm-12 noPadding">
          <input
            autocomplete="off"
            type="text"
            class="form-control"
            v-model="MSDCMFilter"
            placeholder="Filter"
          />
        </div>
        <div class="list col-md-12">
          <div
            v-if="defaultConfig"
            class="role item row"
            :key="0"
            :class="{ active: activeItem === defaultConfig.Id }"
            style="background-color: #f1efef"
            :disabled="saving"
            @click="() => handleListSelection(defaultConfig, 0)"
          >
            <span
              :class="{ adminActiveMenuItem: activeItem === defaultConfig.Id }"
            >
              <i class="ri-profile-fill"></i>
              <span style="margin-left: 8px"> {{ defaultConfig.Name }}</span>
            </span>
          </div>
          <div
            class="item row"
            v-for="(item, index) in filterMonthlyDashboardConfigList"
            :class="{ active: activeItem === item.Id }"
            :disabled="saving || deleting"
            :key="item.Id"
            @click="() => handleListSelection(item, index)"
            :style="[
              index % 2 === 0
                ? { 'background-color': '#FFFFFF' }
                : { 'background-color': '#F1EFEF' },
            ]"
          >
            <span :class="{ adminActiveMenuItem: activeItem === item.Id }">
              <i class="ri-profile-fill" style="vertical-align: middle"></i>
              <span style="margin-left: 10px">{{ item.Name }}</span>
            </span>
          </div>
        </div>
        <!-- <div
              v-if="isMobileDevice()"
              style="
                border-top: 1px solid rgb(0, 0, 0, 0);
                margin: 20px 0 0 9px;
                padding-bottom: 20px;
              "
              class="col-md-12 scrollToSelectedItem"
            ></div> -->
      </div>
    </div>
    <div class="col-xl-9 col-lg-8 col-md-12 col-sm-12" v-if="isEditing">
      <div
        class="col-xl-6 col-md-12"
        style="display: flex; flex-direction: column"
      >
        <label class="generalLabel">Monthly Deals</label>
        <input
          type="numeric"
          class="form-control"
          placeholder="Monthly Deals"
          v-model="selectedRow.MonthlyDashboardConfigurations.MonthlyDeals"
          v-myMask="{
            alias: 'numeric',
            rightAlign: false,
            min: 0,
          }"
        />
      </div>
      <div
        class="col-xl-6 col-md-12 mt-3"
        style="display: flex; flex-direction: column"
      >
        <label class="generalLabel">Monthly Total Debt</label>
        <input
          type="text"
          class="form-control"
          placeholder="Monthly Total Debt"
          v-model="selectedRow.MonthlyDashboardConfigurations.MonthlyTotalDebt"
          v-myMask="{
            alias: 'currency',
            prefix: '$',
            rightAlign: false,
            min: 0,
          }"
        />
      </div>

      <div
        class="offset-xl-4 col-xl-2 offset-lg-6 col-lg-3 col-md-6 col-sm-12 mt-3"
      >
        <button
          @click="handleSave"
          type="button"
          style="width: 100%"
          class="btn btn-success"
        >
          <i v-if="saving" class="spinner-border spinner-border-sm"></i>
          Save
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import types from "./types";
import deepCopy from "@/helpers/deepCopy";
import _orderby from "lodash";
import formatMixin from "@/mixins/formatMixin.js";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import globalTypes from "@/store/types";

const init = {
  Id: 0,
  Name: "",
  MonthlyDashboardConfigurations: {
    Id: null,
    RoleId: null,
    CompanyId: null,
    MonthlyDeals: null,
    MonthlyTotalDebt: null,
  },
};

export default {
  name: "MonthlySalesDashboardConfigManagement",
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      MSDCMFilter: "",
      saving: false,
      deleting: false,
      isEditing: false,
      activeItem: null,
      isCancelledBeforeSave: false,
      oldValue: deepCopy(init),
      selectedRow: deepCopy(init),
      valueToRevert: deepCopy(init),
      suggestBoxOpen: false,
      isLoading: false,
      monthlyDashboardConfigList: [],
    };
  },
  async mounted() {
    this.getConfig();
  },
  computed: mapState({
    sortedList() {
      return _orderby.orderBy(this.monthlyDashboardConfigList, "Name", "asc");
    },

    defaultConfig() {
      let list = this.monthlyDashboardConfigList.filter((x) => x.Id == 0);
      return list.length > 0 ? list[0] : null;
    },

    filterMonthlyDashboardConfigList() {
      return _orderby.orderBy(
        this.monthlyDashboardConfigList.filter(
          (item) =>
            item.Id > 0 &&
            item.Name.toLowerCase().indexOf(this.MSDCMFilter.toLowerCase()) > -1
        ),
        "Name",
        "asc"
      );
    },
  }),
  methods: {
    async getConfig() {
      let err, result;
      [err, result] = await this.$store.dispatch(
        types.GET_MONTHLY_DASHBOARD_CONFIG
      );
      if (result && result.Data) {
        this.monthlyDashboardConfigList = result.Data;
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
    },
    async handleListSelection(row) {
      if (
        this.oldValue != JSON.stringify(this.selectedRow) &&
        JSON.stringify(this.selectedRow) != JSON.stringify(init)
      ) {
        await this.$swal
          .fire({
            title: "You have unsaved changes",
            text: "You will lose it if you don't save",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, continue without saving!",
          })
          .then(async (dialog) => {
            if (dialog.value) {
              this.isCancelledBeforeSave = true;
            } else {
              this.isCancelledBeforeSave = false;
              return;
            }
          });
      } else {
        let rowData = deepCopy(row);
        if (rowData.MonthlyDashboardConfigurations == null) {
          rowData.MonthlyDashboardConfigurations = deepCopy(
            init.MonthlyDashboardConfigurations
          );
        }
        this.selectedRow = deepCopy(rowData);
        this.oldValue = JSON.stringify(rowData);
        this.activeItem = row.Id;
        this.isEditing = true;
      }
      if (this.isCancelledBeforeSave) {
        let rowData = deepCopy(row);
        if (rowData.MonthlyDashboardConfigurations == null) {
          rowData.MonthlyDashboardConfigurations = deepCopy(
            init.MonthlyDashboardConfigurations
          );
        }
        this.selectedRow = deepCopy(rowData);
        this.oldValue = JSON.stringify(rowData);
        this.activeItem = row.Id;
        this.isEditing = true;
      }
      this.isCancelledBeforeSave = false;
    },
    async handleSave() {
      if (this.oldValue == JSON.stringify(this.selectedRow)) {
        this.$swal("Warning!", "No Changes Detected!", "warning");
        return;
      }
      this.saving = true;

      let err, result, type, payload;

      if (this.selectedRow.MonthlyDashboardConfigurations.Id != null) {
        type = types.UPDATE_MONTHLY_DASHBOARD_CONFIG;
      } else {
        type = types.ADD_MONTHLY_DASHBOARD_CONFIG;
      }

      payload = {
        Id: this.selectedRow.MonthlyDashboardConfigurations.Id,
        RoleId: this.selectedRow.Id,
        MonthlyDeals: parseFloat(
          this.selectedRow.MonthlyDashboardConfigurations.MonthlyDeals
        ),
        MonthlyTotalDebt: parseFloat(
          this.selectedRow.MonthlyDashboardConfigurations.MonthlyTotalDebt
        ),
      };

      [err, result] = await this.$store.dispatch(type, payload);

      if (result) {
        this.$swal("Success!", result.Message, "success");
        if (type == types.ADD_MONTHLY_DASHBOARD_CONFIG) {
          this.selectedRow.Id = result.Data.Id;
          this.monthlyDashboardConfigList.push(this.selectedRow);
          this.oldValue = JSON.stringify(this.selectedRow);
        } else {
          this.oldValue = JSON.stringify(this.selectedRow);
        }
        await this.$store.dispatch(types.GET_MONTHLY_DASHBOARD_CONFIG);
        this.getConfig();
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
      this.saving = false;
    },
  },
};
</script>
<style></style>
