<template>
  <div
    class="col-md-12 newPanelWrapper noMargin"
    style="background-color: #ffffff; z-index: 1000"
  >
    <label
      class="generalLabel"
      style="position: absolute; top: 12px; left: 12px"
    ></label>
    <div
      class="closeButton"
      style="right: 5px"
      v-on:click.prevent.stop="closePanel"
    >
      <i class="fas fa-window-close"></i>
    </div>
    <div
      class="row mt-2"
      style="min-height:400px; max-height:400px; overflow-x: hidden; overflow-y: auto;"
    >
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-6">
            <label class="generalLabel">Date:</label>
            <DatePicker
              mode="date"
              :locale="'en-US'"
              :popover="{ visibility: 'focus' }"
              v-model="EffectiveDate"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  id="picker"
                  class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                  :value="inputValue"
                  v-on="inputEvents"
                  placeholder="Select Date"
                />
              </template>
            </DatePicker>
          </div>
          <div class="col-md-6">
            <label class="generalLabel">Amount:</label>
            <input
              class="generalTextBox form-control text-left"
              type="text"
              v-myMask="{
                alias: 'currency',
                prefix: '$',
                rightAlign: false,
              }"
              v-model="Amount"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <label class="generalLabel">Fee Type:</label>
            <select class="form-control" v-model="GlobalFeeType">
                <option :value="null">Please Select</option>
                <option
                  v-for="item in settlementFeeTypes"
                  :value="item.FeeType"
                  :key="item.FeeType"
                >
                  {{ item.Description }}
                </option>
              </select>
          </div>
         
        </div>
      </div>

      <div style="width: 96%; position: absolute; bottom: 12px; left: 2%">
        <button
          type="button"
          style="width: 100%"
          class="btn btn-success waves-effect waves-light"
          @click="submitFee()"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import _orderby from "lodash";
import { DatePicker } from "v-calendar";
import deepCopy from "@/helpers/deepCopy";
export default {
  name: "VoidSettlementEditPopup",
  components: { DatePicker },
  mixins: [formatMixin, utilitiesMixin],
  props: {
    feeTypeList: {
      type: Array,
      default: () => [],
    },
    feeDate: {
      type: String,
      default: () => "",
    },
    feeAmount: {
      type: Number,
      default: () => 0,
    },
    debitId: {
      type: Number,
      default: () => 0,
    },
    paymentId: {
      type: Number,
      default: () => 0,
    },
    isNewRow: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
 
    return {
      settlementFeeTypes:[],
      EffectiveDate: "",
      Amount: 0,
      DebitId: 0,
      PaymentId:0,
      GlobalFeeType:null,
    };
  },

  async mounted() {
    this.Amount = this.feeAmount;
    this.EffectiveDate = this.feeDate;
    this.DebitId = this.debitId;
    this.PaymentId = this.paymentId;
    if (this.isNewRow == false && document.getElementById("picker")) {
      document.getElementById("picker").focus();
    }
    if(this.feeTypeList && this.feeTypeList.length > 0)
    {
      this.settlementFeeTypes = this.feeTypeList.filter(x=> x.IsSettlementFee == true);
      if(this.settlementFeeTypes.length == 1)
      {
        this.GlobalFeeType = this.settlementFeeTypes[0].FeeType;
      }
    }
  },

  methods: {
    closePanel() {
      this.$emit("closeVoidSettlementEditPopup", null);
    },
    submitFee() {
   
        if (this.Amount == 0 || this.EffectiveDate == "" || this.GlobalFeeType == null) {
          this.$swal("Warning", "Fee Date, Fee Amount and Fee Type required.", "warning");
          return;
        }
      

      this.$emit("closeVoidSettlementEditPopup", {
        EffectiveDate: this.EffectiveDate,
        Amount: this.Amount,
        DebitId: this.debitId,
        PaymentId: this.paymentId,
        IsNewRow: this.isNewRow,
        GlobalFeeType : this.GlobalFeeType
      });
    },
  },
};
</script>
