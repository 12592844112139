<template>
  <div class="col-md-12">
    <ReportSummary
      :exportData="reportData"
      :reportName="'NewLineReport'"
      :recordCount="recordCount"
      @updateLoadingStatus="reportLoading = $event"
      @clearReport="clearReport"
      @setPagination="setPagination"
      @refreshReport="refreshReport"
      :loadingSpinner="loadingSpinner"
    />

    <div
      class="datagrid-table gcsTabsTableScroll report-wrapper"
      :style="'max-height: ' + reportsPanelHeight + 'px; overflow-y:auto;'"
    >
      <o-table
        :data="filteredNewLineReport"
        :paginated="isPaginated && filteredNewLineReport.length > 0"
        :per-page="perPage"
        :pagination-simple="isPaginationSimple"
        :pagination-position="paginationPosition"
        :sticky-header="true"
      >
        <o-table-column
          field="CustomerId"
          label="Customer Id"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CustomerIdFilter"
                placeholder=""
                v-myMask="{
                  alias: 'numeric',
                  rightAlign: false,
                }"
              />
            </div>
          </template>

          <template v-slot="props">
            <span @click="viewCustomer(props.row.CustomerId, props.row.FirstName, props.row.LastName)"
              :class="getTagReportHyperLinkClass(props.row.CustomerId && props.row.FirstName && props.row.LastName)">
              <strong v-if="props.row.IsSummary"
                >{{ props.row.CustomerId }}
              </strong>
              <span v-if="!props.row.IsSummary">
                {{ addPrefixToCustomerId(props.row.CustomerId) }}
              </span>
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="FirstName"
          label="First Name"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="FirstNameFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">{{ props.row.FirstName }} </template>
        </o-table-column>

        <o-table-column field="LastName" label="Last Name" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="LastNameFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.LastName }}
          </template>
        </o-table-column>

        <o-table-column
          field="EnrolledDate"
          label="Date Enrolled"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="EnrolledDateFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ formatShortDate(props.row.EnrolledDate) }}
          </template>
        </o-table-column>

        <o-table-column
          field="OriginalDebt"
          label="Original Debt"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="OriginalDebtFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.OriginalDebt }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatMoney(props.row.OriginalDebt) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="CurrentDebt"
          label="Current Debt"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CurrentDebtFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.CurrentDebt }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatMoney(props.row.CurrentDebt) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="ProgramLength"
          label="Program Length"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="ProgramLengthFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props"> {{ props.row.ProgramLength }} </template>
        </o-table-column>

        <o-table-column
          field="TotalDebtSettled"
          label="Total Debt Settled"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="TotalDebtSettledFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.TotalDebtSettled }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatMoney(props.row.TotalDebtSettled) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="TotalDebtUnsettled"
          label="Total Debt Unsettled"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="TotalDebtUnsettledFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.TotalDebtUnsettled }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatMoney(props.row.TotalDebtUnsettled) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="GlobalBalance"
          label="IOLTA Account Balance"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="GlobalBalanceFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.GlobalBalance }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatMoney(props.row.GlobalBalance) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="ClearedDraftCnt"
          label="# of Cleared Payments"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="ClearedDraftCntFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props"> {{ props.row.ClearedDraftCnt }} </template>
        </o-table-column>

        <o-table-column field="NsfCnt" label="# of NSFs" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="NsfCntFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props"> {{ props.row.NsfCnt }} </template>
        </o-table-column>

        <o-table-column
          field="DraftAmount"
          label="Current Monthly Payment"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="DraftAmountFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props"> {{ props.row.DraftAmount }} </template>
        </o-table-column>

        <o-table-column
          field="CreditorCnt"
          label="Creditor Count"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CreditorCntFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props"> {{ props.row.CreditorCnt }} </template>
        </o-table-column>

        <o-table-column
          v-for="item in dynamicColumns"
          :key="item.ColumnKey"
          :label="item.ColumnName"
        >
          <template v-slot="props"> {{ props.row[item.ColumnName] }} </template>
        </o-table-column>

        <o-table-column
          field=""
          label="View Customer"
          position="centered"
          v-slot="props"
        >
          <button
            @click="
              viewCustomer(
                props.row.CustomerId,
                props.row.FirstName,
                props.row.LastName
              )
            "
            class="btn btn-info waves-effect waves-light ml-2"
            v-if="!props.row.IsSummary"
          >
            <i class="far fa-address-card"></i>
          </button>
        </o-table-column>

        <o-table-column
          field=""
          label="Submit Loan"
          position="centered"
          v-slot="props"
        >
          <button
            @click="submitLoan(props.row.CustomerId)"
            :disabled="loadingSpinner"
            class="btn btn-success waves-effect waves-light ml-1"
            v-if="!props.row.IsSummary"
          >
            <i
              v-if="!loadingSpinner"
              style="font-size: 15px"
              class="fas fa-share"
            ></i>
            <i
              v-if="loadingSpinner"
              style="font-size: 12px"
              class="spinner-border spinner-border-sm ml-1 mb-1"
            ></i>
          </button>
        </o-table-column>
      </o-table>

      <section
        v-if="filteredNewLineReport.length == 0"
        class="section"
        style="text-align: center"
      >
        <div class="content has-text-grey has-text-centered mb-4">
          <p class="noMargin">
            <i
              style="font-size: 24px; color: #939393"
              class="ri-question-line"
            ></i>
          </p>
          <p class="noMargin">Nothing here.</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import ReportSummary from "./ReportSummary.vue";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import deepCopy from "@/helpers/deepCopy";
import _orderby from "lodash";
export default {
  name: "NewLineReport",
  components: { ReportSummary },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      customerPrefix: null,
      removedCustomers: [],
      dynamicColumns: [],
      updatedReportData: [],
      reportsPanelHeight: 300,
      loadingSpinner: false,
      isPaginationSimple: false,
      isPaginated: true,
      paginationPosition: "both",
      currentPage: 1,
      perPage: 200,
      recordCount: 0,
      reportLoading: false,
      reportData: [],

      newLineReportInitSummary: {
        CustomerId: "",
        FirstName: "",
        LastName: "",
        EnrolledDate: "",
        OriginalDebt: "",
        CurrentDebt: "",
        ProgramLength: "",
        TotalDebtSettled: "",
        TotalDebtUnsettled: "",
        GlobalBalance: "",
        Line1: "",
        ClearedDraftCnt: "",
        NsfCnt: "",
        Income: "",
        DraftAmount: "",
        CreditorCnt: "",
      },

      CustomerIdFilter: "",
      FirstNameFilter: "",
      LastNameFilter: "",
      EnrolledDateFilter: "",
      OriginalDebtFilter: "",
      CurrentDebtFilter: "",
      ProgramLengthFilter: "",
      TotalDebtSettledFilter: "",
      TotalDebtUnsettledFilter: "",
      GlobalBalanceFilter: "",
      ClearedDraftCntFilter: "",
      NsfCntFilter: "",

      DraftAmountFilter: "",
      CreditorCntFilter: "",
    };
  },
  computed: mapState({
    newLineList: (state) => state.reports.newLineList,

    filteredNewLineReport() {
      let rtn = [];

      let tmpList =
        this.updatedReportData.length > 0
          ? this.updatedReportData
              .filter(
                (item) => !this.removedCustomers.includes(item.CustomerId)
              )
              .filter((item) => {
                return (
                  this.filterString(item.CustomerId, this.CustomerIdFilter) &&
                  this.filterString(item.FirstName, this.FirstNameFilter) &&
                  this.filterString(item.LastName, this.LastNameFilter) &&
                  this.filterString(
                    item.OriginalDebt,
                    this.OriginalDebtFilter
                  ) &&
                  this.filterString(item.CurrentDebt, this.CurrentDebtFilter) &&
                  this.filterString(
                    item.ProgramLength,
                    this.ProgramLengthFilter
                  ) &&
                  this.filterString(
                    item.TotalDebtSettled,
                    this.TotalDebtSettledFilter
                  ) &&
                  this.filterString(
                    item.TotalDebtUnsettled,
                    this.TotalDebtUnsettledFilter
                  ) &&
                  this.filterString(
                    item.GlobalBalance,
                    this.GlobalBalanceFilter
                  ) &&
                  this.filterString(
                    item.ClearedDraftCnt,
                    this.ClearedDraftCntFilter
                  ) &&
                  this.filterString(item.NsfCnt, this.NsfCntFilter) &&
                  this.filterString(item.DraftAmount, this.DraftAmountFilter) &&
                  this.filterString(item.CreditorCnt, this.CreditorCntFilter) &&
                  new Date(item.EnrolledDate)
                    .toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                      hour: "numeric",
                      minute: "2-digit",
                    })
                    .indexOf(this.EnrolledDateFilter) > -1
                );
              })
          : [];

      if (tmpList.length == 0) {
        this.reportData = [];
        return rtn;
      }
      let totalCustomer = tmpList.length.toString();

      this.newLineReportInitSummary.CustomerId = "Total: " + totalCustomer;
      this.recordCount = tmpList.length;
      this.newLineReportInitSummary.IsSummary = true;

      rtn.push(...tmpList);
      rtn.push(this.newLineReportInitSummary);
      this.reportData = rtn;

      return rtn;
    },
  }),
  unmounted() {
    this.$Emitter.off("refreshNewLineReport");
  },
  async mounted() {
    this.reportsPanelHeight = this.setReportGridHeight(
      window.innerHeight,
      document.getElementById("reportsSummary")
    );
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

    if (
      this.userInfo != null &&
      this.userInfo.customerIdPrefix != null &&
      this.userInfo.customerIdPrefix.length > 0
    ) {
      this.customerPrefix = this.userInfo.customerIdPrefix;
    }

    this.$Emitter.on("refreshNewLineReport", (data) => {
      if (data && data.CustomerId > 0) {
        this.removedCustomers.push(data.CustomerId);
      }
    });

    await this.sleep(1000);
    this.fixStickyHeaders();
  },
  methods: {
    async viewCustomer(id, firstName, lastName) {
      this.$Emitter.emit("viewCustomer", {
        customerId: id,
        customerName: firstName + " " + lastName,
      });
    },
    async submitLoan(customerId) {
      this.loadingSpinner = true;

      let activeCustomers = [];
      if (
        this.$parent &&
        this.$parent.$parent &&
        this.$parent.$parent.$refs.customerCtrl
      ) {
        activeCustomers =
          this.$parent.$parent.$refs.customerCtrl.getActiveTabIds();
      }

      if (activeCustomers.length > 0 && activeCustomers.includes(customerId)) {
        this.$swal(
          "Warning",
          "The customer tab is currently open. Please close the customer tab to continue with the NewLine loan submission",
          "warning"
        );
        return;
      }

      let err, result;
      [err, result] = await this.$store.dispatch(
        types.NEW_LINE_SUBMIT_APPLICATION,
        {
          CustomerId: customerId,
        }
      );
      if (result) {
        if (result.Data && result.Data.IsSuccessful) {
          this.$swal(result.Message, result.Data.Display, "success");
          this.removedCustomers.push(customerId);
        } else if (result.Data && !result.Data.IsSuccessful) {
          let errorMessage = result.Errors.join(",");
          this.$swal(result.Message, errorMessage, "error");
        }
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }

      this.loadingSpinner = false;
    },
    addPrefixToCustomerId(customerId) {
      let rtn = customerId.toString();
      if (this.customerPrefix != null) {
        rtn = this.customerPrefix + rtn;
      }
      return rtn;
    },
    setPagination(data) {
      this.isPaginated = data;
    },
    async refreshReport(value) {
      this.loadingSpinner = true;
      await this.$store.dispatch(types.GET_NEW_LINE_REPORT, value);

      if (this.newLineList && this.newLineList.length > 0) {
        this.dynamicColumns = [];
        this.updatedReportData = deepCopy(this.newLineList);
        let i = 0;
        for (let item of this.updatedReportData) {
          for (let column of _orderby.orderBy(
            item.DateColums,
            "ColumnOrder",
            "asc"
          )) {
            item[column.ColumnName] = column.Value;
            if (i == 0) {
              this.dynamicColumns.push({
                ColumnKey: column.ColumnOrder,
                ColumnName: column.ColumnName,
              });
            }
          }
          i++;
        }
      }

      this.loadingSpinner = false;

      await this.sleep(500);
      this.fixStickyHeaders();
    },
    clearReport() {
      this.$store.dispatch(types.RESET_REPORTS);
      this.reportData = [];
      this.updatedReportData = [];
    },
  },
};
</script>

<style scoped></style>
