import types from "./types";
import api from "@/services/admin/api";
import to from "@/helpers/to";
export default {
  state: {
    didassignType: [],
    inboundRules: [],
    receiverTypes: [],
  },
  actions: {
    GET_INBOUND_RULES_ICRM: async ({ commit }) => {
      let err, result;
      [err, result] = await to(api.getInboundRules());
      if (result) {
        commit(types.SET_INBOUND_RULES_ICRM, result.Data);
      }
    },
    UPDATE_PRIORITY_LOCALLY: async ({ commit }, payload) => {
      commit(types.MODIFY_PRIORITY, payload);
    },
    UPDATE_INBOUND_RULES: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.updateInboundRules(payload));
      return [err, result];
    },
  },
  mutations: {
    SET_INBOUND_RULES_ICRM: (state, payload) => {
      state.inboundRules = payload.Rules;
      state.receiverTypes = payload.ReceiverTypes;
    },
    MODIFY_PRIORITY: (state, payload) => {
      for (let i = 0; i < state.inboundRules.length; i++) {
        state.inboundRules[i].Priority = payload[i].Id;
      }
    },
  },
};
