<template>
  <div
    class="col-md-12 newPanelWrapper noMargin pt-4"
    style="
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #ffffff;
    "
  >
    <div>
      <div class="closeButton" v-on:click.prevent.stop="closePanel">
        <i class="fas fa-window-close"></i>
      </div>
    </div>
    <div>
      <audio
        id="callReportPlayer"
        autoplay="autoplay"
        crossorigin="anonymous"
        controls
      >
        <source :src="soundUrl" />
      </audio>
    </div>
    <div style="display: flex">
      <label class="generalLabel mt-1">L</label>
      <input
        style="width: 200px"
        class="ml-2 mr-2"
        type="range"
        id="gainer"
        min="-1"
        max="1"
        v-model="gainRangeValue"
        step="0.01"
        @input="changeGain"
      />
      <label class="generalLabel mt-1">R</label>
    </div>
    <div
      style="
        width: auto;
        font-size: 16px;
        margin-top: 3px;
        cursor: pointer;
        padding: 0 3px;
      "
      class="ml-2"
    >
      <span @click="resetGain()" class="badge badge-danger">Reset</span>
    </div>
  </div>
</template>

<script>
import utilitiesMixin from "@/mixins/utilitiesMixin";
import formatMixin from "@/mixins/formatMixin";
var context = null;
var gainL = null;
var gainR = null;
export default {
  name: "AudioController",
  mixins: [formatMixin, utilitiesMixin],
  props: {
    soundUrl: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      gainRangeValue: 0,
    };
  },
  mounted() {
    this.initAudioPlayer();
  },
  methods: {
    changeGain(event) {
      let value = 0;
      if (event.target.value) {
        value = parseFloat(event.target.value).toFixed(2);

        if (value < 0) {
          //lefft;
          if (gainL.gain.value <= 1) gainL.gain.value = value * -1;
          else gainL.gain.value = 1;

          if (gainR.gain.value >= 0)
            gainR.gain.value = parseFloat(1 - value * -1).toFixed(2);
          else gainR.gain.value = 0;
        } else if (value == 0) {
          this.resetGain();
        } else {
          //right
          if (gainL.gain.value >= 0)
            gainL.gain.value = parseFloat(1 - value).toFixed(2);
          else gainL.gain.value = 0;

          if (gainR.gain.value <= 1) gainR.gain.value = value;
          else gainR.gain.value = 1;
        }
      }
    },
    resetGain() {
      this.gainRangeValue = 0;
      gainL.gain.value = 1;
      gainR.gain.value = 1;
    },
    initAudioPlayer() {
      var myAudio = document.getElementById("callReportPlayer");
      context = new AudioContext();
      var audioSource = context.createMediaElementSource(myAudio);
      var splitter = context.createChannelSplitter(2);
      var merger = context.createChannelMerger(2);
      merger.connect(context.destination);
      gainL = context.createGain();
      gainR = context.createGain();
      audioSource.connect(splitter);
      splitter.connect(gainL, 0, 0);
      splitter.connect(gainR, 1, 0);
      gainL.connect(merger, 0, 0);
      gainR.connect(merger, 0, 1);
      this.resetGain();
    },
    closePanel() {
      this.$emit("closeAudioControllerPopup");
    },
  },
};
</script>

<style></style>
