<template>
  <div class="row admin-page" style="position: relative">
    <div v-if="isLoading" class="loading">
      <div class="text-center" style="margin-top: 150px">
        <i class="spinner-grow text-info m-2"></i>
      </div>
    </div>
    <modal
      v-if="imageSelected"
      title="Adjust Image"
      size="xs"
      @save="handleCropChange"
      popup-type="confirm"
    >
      <cropper
        class="cropper"
        :src="selectedRow.PhotoUrl"
        :stencilProps="{ aspectRatio: 1 / 1 }"
        ref="cropper"
      ></cropper>
    </modal>
    <div class="col-xl-4 col-lg-3 col-md-12 col-sm-12 panelWrapper noPadding">
      <button
        type="button"
        class="col-md-12 btn btn-primary waves-effect waves-light"
        @click="() => handleAddEmployee(reset)"
      >
        Add New Employee
      </button>
      <div class="filter form-group form-row">
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 noPadding">
          <div class="filter">
            <input
              type="text"
              class="form-control"
              v-model="employeeFilter"
              placeholder="Filter by name"
            />
          </div>
        </div>
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 noPadding">
          <div class="checkbox-filter form-group">
            <div class="form-check">
              <input
                type="checkbox"
                id="retired"
                class="form-check-input"
                @change="handleRetiredChange"
                v-model="isRetiredIncluded"
              />
              <label class="form-check-label" for="retired">Show Retired</label>
            </div>
          </div>
        </div>
      </div>
      <div class="filter form-group form-row">
        <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 noPadding">
          <label class="form-label labelMargin" for="roleFilter"
            >Filter by Role:</label
          >
        </div>
        <div class="col-xl-8 col-lg-12 col-md-12 col-sm-12 noPadding">
          <select v-model="roleFilter" id="roleFilter" class="form-control">
            <option value>All Roles</option>
            <option
              v-for="item in sortedRolesMainFilter"
              :key="item.Id"
              :value="item.Id"
            >
              {{ item.Name }}
            </option>
          </select>
        </div>
      </div>
      <div
        class="filter form-group form-row"
        v-if="checkAuth(1454) || checkAuth(55011)"
      >
        <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 noPadding">
          <label class="form-label labelMargin" for="officeFilter"
            >Filter by Office:</label
          >
        </div>
        <div class="col-xl-8 col-lg-12 col-md-12 col-sm-12 noPadding">
          <select
            :disabled="!checkAuth(1454) && !checkAuth(55011)"
            v-model="officeFilter"
            id="officeFilter"
            class="form-control"
          >
            <option value>All Offices</option>
            <option v-for="item in offices" :key="item.Id" :value="item.Id">
              {{ item.Name }}
            </option>
          </select>
        </div>
      </div>
      <div
        style="height: 10px"
        class="col-xl-12 col-lg-12 col-md-12 col-sm-12 noPadding"
      >
        <!--<i class="fas fa-spinner mr-1"></i>-->
        <div
          v-if="gettingSelection"
          class="progress-bar progress-bar-striped bg-info employeeLoadingWrapper"
          role="progressbar"
          aria-valuenow="100"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
      <div class="list col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <div
          class="employee item row"
          v-for="(employee, index) in filteredEmployees"
          :key="employee.Id"
          :class="{ active: activeEmployee === employee.Id }"
          @click="() => handleEmployeeSelection(employee, index, reset)"
          :disabled="saving || gettingSelection"
          :style="[
            employee.IsRetired
              ? { 'background-color': '#f198a5' }
              : index % 2 === 0
              ? { 'background-color': '#F1EFEF' }
              : { 'background-color': '#fbfbfb' },
          ]"
        >
          <span
            :class="{ adminActiveMenuItem: activeEmployee === employee.Id }"
          >
            <i class="mdi mdi-account-card-details-outline"></i>
            <span style="margin-left: 8px"
              >{{ employee.FirstName }} {{ employee.LastName }}</span
            >
          </span>
        </div>
      </div>
      <div
        v-if="isMobileDevice()"
        style="
          border-top: 1px solid rgb(0, 0, 0, 0);
          margin: 50px 0 0 9px;
          padding-bottom: 20px;
        "
        class="col-md-12 scrollToSelectedItem"
      ></div>
    </div>
    <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12">
      <form
        autocomplete="nope"
        v-if="isEditing"
        class="form-horizontal col-md-12 float-right"
        role="form"
      >
        <ul class="nav nav-tabs" style="margin: 0 !important">
          <li
            v-show="(tab.id == 3 && checkAuth(921095)) || tab.id != 3"
            v-for="tab in tabs.filter((x) => x.show)"
            :key="tab.id"
            class="nav-item tab-item"
          >
            <a
              data-toggle="tab"
              :aria-expanded="tab.active"
              v-on:click.prevent="() => handleTabChange(tab.name)"
              class="tab-link"
              v-show="selectedRow.Id > 0"
              :class="{ active: tab.active }"
            >
              <span class="d-inline-block">
                <i :class="tab.iconName"></i>
              </span>
              <span class="d-none d-sm-inline-block tab-item-label">
                {{ tab.label }}
              </span>
            </a>
          </li>
        </ul>
        <div v-if="tabs[0].active" class="panelWrapper">
          <div class="form-group row panelContent">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <div class="form-group row">
                <div class="col-xl-9 col-lg-8 col-md-12 col-sm-12">
                  <div class="form-group row">
                    <label
                      class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-form-label"
                      for="firstNameinput"
                      >First Name*</label
                    >
                    <div class="col-lg-8 col-md-8 col-sm-12">
                      <input
                        autocomplete="nope"
                        type="text"
                        id="firstNameinput"
                        class="form-control"
                        v-model="selectedRow.FirstName"
                        placeholder="Enter your first name"
                        :class="
                          v$.selectedRow.FirstName.$error ? 'has-error' : ''
                        "
                        @blur="v$.selectedRow.FirstName.$touch"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label
                      class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-form-label"
                      for="lastNameinput"
                      >Last Name*</label
                    >
                    <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                      <input
                        autocomplete="nope"
                        type="text"
                        id="lastNameinput"
                        class="form-control"
                        v-model="selectedRow.LastName"
                        placeholder="Enter your last name"
                        :class="
                          v$.selectedRow.LastName.$error ? 'has-error' : ''
                        "
                        @blur="v$.selectedRow.LastName.$touch"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label
                      class="col-lg-4 col-md-4 col-sm-12 form-label"
                      for="emailinput"
                      >Email*</label
                    >
                    <div class="col-lg-8 col-md-8 col-sm-12">
                      <input
                        type="text"
                        id="emailinput"
                        class="form-control"
                        v-model="selectedRow.Email"
                        placeholder="Enter your email"
                        :class="v$.selectedRow.Email.$error ? 'has-error' : ''"
                        @blur="v$.selectedRow.Email.$touch"
                      />
                    </div>
                    <div
                      class="offset-lg-4 col-lg-12 col-md-12 col-sm-12"
                      style="margin-top: 0px; margin-bottom: 0px"
                    >
                      <span
                        v-if="selectedRow.Id"
                        type="button"
                        @click="resetPsw()"
                        :disabled="selectedRow.Email == ''"
                        class="badge badge-info"
                      >
                        Send Reset Password Email
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-xl-3 col-lg-4">
                  <div
                    class="image-upload-wrapper d-xl-block d-lg-block d-md-none d-sm-none"
                  >
                    <input
                      type="file"
                      ref="image"
                      style="display: none"
                      accept="image/x-png, image/jpeg, image/jpg, image/gif"
                      @click="handleImageClick"
                      @change="handleImageChange"
                    />
                    <div class="preview" @click="$refs.image.click()">
                      <img
                        v-if="selectedRow.PhotoUrl"
                        :src="selectedRow.PhotoUrl"
                        @error="getDefaultImage"
                      />
                      <span>Upload</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label
                  class="col-xl-3 col-lg-4 col-md-4 col-sm-12 form-label"
                  for="screenNameInput"
                  >Screen Name*</label
                >
                <div class="col-xl-6 col-lg-8 col-md-8 col-sm-12">
                  <input
                    type="text"
                    id="screenNameInput"
                    class="form-control"
                    v-model="selectedRow.ScreenName"
                    placeholder="Enter your screen name"
                    :class="v$.selectedRow.ScreenName.$error ? 'has-error' : ''"
                    @blur="v$.selectedRow.ScreenName.$touch"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label
                  type="password"
                  class="col-xl-3 col-lg-4 col-md-4 col-sm-12 form-label"
                  for="passwordInput"
                  >Password*</label
                >
                <div class="col-xl-6 col-lg-8 col-md-8 col-sm-12">
                  <input
                    type="password"
                    id="passwordInput"
                    class="form-control"
                    v-model="selectedRow.Password"
                    placeholder="Enter your password"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label
                  class="col-xl-3 col-lg-4 col-md-4 col-sm-12 form-label"
                  for="birthInput"
                  >Birth Date</label
                >
                <div class="col-xl-6 col-lg-8 col-md-8 col-sm-12">
                  <DatePicker
                    mode="date"
                    :locale="'en-US'"
                    v-model="selectedRow.BirthDate"
                  >
                    <template v-slot="{ inputValue, inputEvents }">
                      <input
                        id="birthInput"
                        placeholder="Enter your birthday"
                        class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                        :value="inputValue"
                        v-on="inputEvents"
                      />
                    </template>
                  </DatePicker>
                </div>
              </div>
              <div class="form-group row">
                <label
                  class="col-xl-3 col-lg-4 col-md-4 col-sm-12 form-label"
                  for="anniversaryInput"
                  >Anniversary Date</label
                >
                <div class="col-xl-6 col-lg-8 col-md-8 col-sm-12">
                  <DatePicker
                    mode="date"
                    :locale="'en-US'"
                    v-model="selectedRow.AnniversaryDate"
                  >
                    <template v-slot="{ inputValue, inputEvents }">
                      <input
                        id="anniversaryInput"
                        placeholder="Enter your anniversary"
                        class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                        :value="inputValue"
                        v-on="inputEvents"
                      />
                    </template>
                  </DatePicker>
                </div>
              </div>

              <div class="form-group row">
                <label
                  class="col-xl-3 col-lg-4 col-md-4 col-sm-12 form-label"
                  for="genderInput"
                  >Gender</label
                >
                <div class="col-xl-6 col-lg-8 col-md-8 col-sm-12">
                  <select
                    v-model="selectedRow.GenderId"
                    id="genderInput-em"
                    class="form-control"
                  >
                    <option :value="convertNull('null')">Select</option>
                    <option
                      v-for="item in genders"
                      :key="item.Id"
                      :value="item.Id"
                    >
                      {{ item.Name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="form-group row">
                <label
                  class="col-xl-3 col-lg-4 col-md-4 col-sm-12 form-label"
                  for="cellPhoneInput"
                  >Cell Phone</label
                >
                <div
                  class="col-xl-6 col-lg-8 col-md-8 col-sm-12"
                  style="position: relative; z-index: 1"
                >
                  <div
                    style="
                      display: flex;
                      justify-content: start;
                      align-items: center;
                    "
                    v-click-outside="closeCountryList"
                  >
                    <div
                      v-on:click.prevent.stop="openSelectBox"
                      style="
                        width: auto;
                        max-width: 120px !important;
                        padding: 0.45rem;
                        border: 1px solid #ced4da;
                        border-radius: 0.2rem;
                      "
                    >
                      <div style="display: flex">
                        <img
                          :src="flagList(selectedCountry)"
                          width="20"
                          alt="Flag"
                        />
                        <div
                          class="flex-fill ml-1"
                          style="
                            min-width: 25px !important;
                            max-width: 60px !important;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            cursor: pointer;
                          "
                        >
                          {{
                            selectedCountry.shortName == "US"
                              ? "US & CA"
                              : selectedCountry.shortName
                          }}
                        </div>
                        <div class="ml-1">
                          <i
                            v-if="!isSelectOpened"
                            class="fas fa-angle-down"
                          ></i>
                          <i v-if="isSelectOpened" class="fas fa-angle-up"></i>
                        </div>
                      </div>
                      <div
                        v-if="isSelectOpened"
                        style="
                          position: absolute;
                          top: 10px;
                          left: 12px;
                          z-index: 2;
                          width: 300px;
                          background-color: white;
                          height: 300px;
                          margin-top: 32px;
                          padding-right: 5px;
                          overflow-x: hidden;
                          overflow-y: hidden;
                          border: 1px rgb(209, 209, 209) solid;
                          border-radius: 5px;
                        "
                      >
                        <input
                          autocomplete="nope"
                          type="text"
                          class="form-control m-1"
                          v-model="countryFilter"
                          style="width: 280px"
                          placeholder="Search"
                        />
                        <perfect-scrollbar
                          style="
                            height: 258px;
                            overflow-x: hidden;
                            overflow: scroll;
                          "
                        >
                          <div
                            v-for="item in filteredCountries"
                            :key="item.code"
                            :value="item"
                            style="padding: 5px; cursor: pointer"
                            class="contryList"
                            v-on:click.prevent.stop="changeCountry(item)"
                          >
                            <img :src="flagList(item)" width="20" alt="Flag" />
                            {{ item.name }}
                          </div>
                        </perfect-scrollbar>
                      </div>
                    </div>

                    <label
                      v-if="isVisible && selectedCountry != null"
                      style="min-width: 50px"
                      class="mt-1 generalLabel text-center"
                      >+ {{ selectedCountry.code }}</label
                    >
                    <div class="flex-fill">
                      <input
                        v-if="isVisible && selectedCountry != null"
                        @change="refreshValue"
                        autocomplete="nope"
                        type="text"
                        class="form-control"
                        :class="
                          v$.selectedRow.CellPhone.$error ? 'has-error' : ''
                        "
                        v-model="selectedRow.CellPhone"
                        v-myMask="{ mask: selectedCountry.format }"
                        @blur="v$.selectedRow.CellPhone.$touch"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label
                  class="col-xl-3 col-lg-4 col-md-4 col-sm-12 form-label"
                  for="OfficeId"
                  >Office</label
                >
                <div class="col-xl-6 col-lg-8 col-md-8 col-sm-12">
                  <select
                    :disabled="!checkAuth(1454) && !checkAuth(55011)"
                    id="OfficeId"
                    :class="
                      !checkAuth(1454) && !checkAuth(55011)
                        ? 'readonlyInput'
                        : ''
                    "
                    class="form-control"
                    @change="setOfficeTimeZone()"
                    v-model="selectedRow.OfficeId"
                  >
                    <option :value="null">Select Office</option>
                    <option
                      v-for="item in offices"
                      :key="item.Id"
                      :value="item.Id"
                    >
                      {{ item.Name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <label
                  class="col-xl-3 col-lg-4 col-md-4 col-sm-12 form-label"
                  for="timeZoneId"
                  >Timezone</label
                >
                <div class="col-xl-6 col-lg-8 col-md-8 col-sm-12">
                  <select
                    v-model="selectedRow.TimeZoneId"
                    id="TimeZoneId"
                    class="form-control"
                  >
                    <option :value="convertNull('null')">
                      Select Timezone
                    </option>
                    <option
                      v-for="item in timezones"
                      :key="item.Id"
                      :value="item.Id"
                    >
                      {{ item.Name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <label
                  class="col-xl-3 col-lg-4 col-md-4 col-sm-12 form-label"
                  for="OfficeId"
                  >Role*</label
                >
                <div class="col-xl-6 col-lg-8 col-md-8 col-sm-12">
                  <VueMultiselect
                    v-model="selectedRow.RoleList"
                    :options="sortedRoles"
                    :multiple="true"
                    :close-on-select="false"
                    placeholder="Please Select"
                    label="Name"
                    track-by="Id"
                    :class="v$.selectedRow.RoleList.$error ? 'has-error' : ''"
                    @blur="v$.selectedRow.RoleList.$touch"
                    @select="onRoleListSelect"
                    @remove="onRoleListRemove"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label
                  class="col-xl-3 col-lg-4 col-md-4 col-sm-12 form-label"
                  for="SpeakSpanish"
                  >Can Speak<br />Spanish</label
                >
                <div class="col-xl-6 col-lg-8 col-md-8 col-sm-12">
                  <div style="width: 20px; float: left" class="mr-1">
                    <input
                      type="checkbox"
                      v-model="selectedRow.CanSpeakSpanish"
                      id="SpeakSpanish"
                      class="form-control"
                    />
                  </div>
                  <div
                    style="
                      width: auto;
                      float: left;
                      margin: 5px 0px;
                      font-size: 16px;
                    "
                  >
                    <span class="badge badge-success">Yes</span>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div
                  class="offset-xl-3 offset-lg-4 offset-md-4 col-xl-6 col-lg-8 col-md-8 col-sm-12"
                >
                  <button
                    type="button"
                    @click="handleSave"
                    :disabled="v$.$invalid || saving"
                    class="btn btn-success btn-bordered-success waves-effect width-md waves-light submit-button float-right"
                  >
                    <i v-if="saving" class="fas fa-spinner mr-1"></i>
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="tabs[1].active" class="panelWrapper">
          <div class="col-md-12 noPadding">
            <!-- Enable -->
            <div class="col-md-12 row">
              <div>
                <button
                  v-if="
                    phoneSettings == null ||
                    (phoneSettings.EndpointId.length == 0 &&
                      !selectedRow.IsRetired)
                  "
                  type="button"
                  :disabled="savingPhone"
                  @click="createEndpoint(selectedRow.Id)"
                  class="btn btn-success"
                >
                  <i v-if="savingPhone" class="fas fa-spinner mr-1"></i>
                  Enable Soft Phone
                </button>
              </div>
              <div
                class="col-md-12 row"
                v-if="
                  phoneSettings != null &&
                  phoneSettings.EndpointId &&
                  phoneSettings.EndpointId.length > 0
                "
              >
                <div class="col-md-3 mt-2">
                  <label class="form-label">Soft Phone Enabled</label>
                </div>
                <div class="col-md-9">
                  <button
                    type="button"
                    :disabled="savingPhone"
                    @click="deleteEndpoint(selectedRow.Id)"
                    class="btn btn-danger"
                  >
                    <i v-if="savingPhone" class="fas fa-spinner mr-1"></i>
                    Disable Soft Phone
                  </button>
                </div>
              </div>
            </div>
            <!-- Enable End -->

            <!-- Assign DID -->
            <div
              class="col-md-12 row"
              v-if="
                phoneSettings != null &&
                phoneSettings.EndpointId &&
                phoneSettings.EndpointId.length > 0 &&
                (phoneSettings.Did == null || phoneSettings.Did.length == 0)
              "
            >
              <div class="col-md-12 row mt-3">
                <div class="col-md-3 mt-2">
                  <label class="form-label" for="ddlDid"
                    >Assign DID: {{ phoneSettings.Did }}</label
                  >
                </div>
                <div class="col-md-3">
                  <select
                    id="ddlDid"
                    v-model="phoneSettings.DidId"
                    class="form-control"
                  >
                    <option :value="0">Unassigned</option>
                    <option
                      v-for="item in companyDids"
                      :key="item.Id"
                      :value="item.Id"
                    >
                      {{ item.Did }}
                    </option>
                  </select>
                </div>
                <div class="col-md-4">
                  <button
                    type="button"
                    :disabled="savingDid || phoneSettings.DidId == 0"
                    @click="saveDid()"
                    class="btn btn-success"
                  >
                    <i v-if="savingDid" class="fas fa-spinner mr-1"></i>
                    Save DID
                  </button>
                </div>
              </div>
            </div>
            <!-- Assign DID End -->

            <!-- DID -->
            <div class="col-md-12" v-if="phoneSettings">
              <div class="col-md-12 row mb-2">
                <div class="col-md-3 mt-2">
                  <label
                    v-if="phoneSettings.Did && phoneSettings.Did.length > 0"
                    class="form-label"
                    for="btnDid"
                    >DID: {{ formatPhone(phoneSettings.Did) }}</label
                  >
                </div>
                <div class="col-md-8">
                  <button
                    id="btnDid"
                    type="button"
                    v-if="phoneSettings.Did > 0"
                    @click="unAssignDid()"
                    :disabled="savingDid || phoneSettings.DidId == 0"
                    class="btn btn-warning"
                  >
                    <i v-if="savingDid" class="fas fa-spinner mr-1"></i>
                    Unassign DID
                  </button>
                </div>
              </div>
            </div>
            <!-- DID End -->

            <!-- Vm Box  -->
            <div
              class="col-md-12"
              v-if="
                phoneSettings &&
                phoneSettings.EndpointId &&
                phoneSettings.EndpointId.length > 0 &&
                !selectedRow.IsRetired
              "
            >
              <div class="col-md-12 row">
                <div class="col-md-3 mt-2">
                  <label class="form-label"
                    >Vm Box:
                    {{
                      phoneSettings.DisableVmBox ? "Disabled" : "Enabled"
                    }}</label
                  >
                </div>
                <div class="col-md-4">
                  <button
                    :disabled="savingVmBoxSetting"
                    type="button"
                    v-if="
                      phoneSettings.EndpointId &&
                      phoneSettings.EndpointId.length > 0 &&
                      !selectedRow.IsRetired &&
                      phoneSettings.DisableVmBox == true
                    "
                    @click="enableVmBox()"
                    class="btn btn-success"
                  >
                    Enable VM Box
                  </button>
                  <button
                    :disabled="savingVmBoxSetting"
                    type="button"
                    v-if="
                      phoneSettings.EndpointId &&
                      phoneSettings.EndpointId.length > 0 &&
                      !selectedRow.IsRetired &&
                      !phoneSettings.DisableVmBox
                    "
                    @click="disableVmBox()"
                    class="btn btn-danger"
                  >
                    Disable VM Box
                  </button>
                </div>
              </div>
            </div>
            <!-- Vm Box End  -->
          </div>
        </div>
        <div v-if="tabs[3].active" class="panelWrapper">
          <div class="row">
            <div class="col-md-12">
              <div
                v-if="!selectedRow.IsRetired"
                style="padding: 15px; font-size: 1.2rem"
                class="col-xl-8 col-lg-10 col-md-12 col-sm-12 text-white bg-danger"
              >
                If you retire employee, this information will be removed :
                <br />Soft phone records <br />Voicemail records
              </div>

              <button
                type="button"
                v-if="selectedRow"
                @click="handleRetire()"
                :disabled="selectedRow.Id == 0"
                class="col-xl-2 col-lg-2 col-md-4 col-sm-12 btn btn-danger btn-bordered-danger waves-effect width-md waves-light float-left mt-3"
              >
                {{ selectedRow.IsRetired ? "UNRETIRE" : "RETIRE" }}
              </button>
            </div>
          </div>
        </div>
        <div v-if="tabs[2].active && checkAuth(921095)" class="panelWrapper">
          <div class="row">
            <div class="col-md-12">
              <table class="col-md-12 datagrid-table creditorTable">
                <thead>
                  <tr>
                    <th></th>
                    <th>Name</th>
                    <th>State</th>
                    <th>Attorney Office Name</th>
                    <th>Policy Group Id</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in attorneyList" :key="item.Id">
                    <td>
                      <input
                        type="checkbox"
                        class="checkbox-input generalCheckBox"
                        style="border-radius: 4px; border: solid 1px #d1d1d1"
                        v-model="item.isSelected"
                      />
                    </td>
                    <td>{{ item.FirstName + " " + item.LastName }}</td>
                    <td>{{ item.StateCode }}</td>
                    <td>{{ item.AttorneyOfficeName }}</td>
                    <td>{{ item.PolicyGroupId }}</td>
                  </tr>
                </tbody>
              </table>
              <div class="mt-2">
                <button
                  @click="attorneySettingSave()"
                  type="button"
                  class="animated-btn btn btn-success width-md float-right"
                >
                  <i v-if="savingAttorney" class="fas fa-spinner mr-1"></i>
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import types from "./types";
import globalTypes from "@/store/types";
import { mapState } from "vuex";
import deepCopy from "@/helpers/deepCopy";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import formatMixin from "@/mixins/formatMixin";
import {orderBy, camelCase} from "lodash";
import VueMultiselect from "vue-multiselect";
import { DatePicker } from "v-calendar";
import useValidate from "@vuelidate/core";
import { email, required } from "vuelidate/lib/validators";
import Modal from "@/components/Modal";
import api from "@/services/login.service";
import to from "@/helpers/to";

const init = {
  FirstName: "",
  LastName: "",
  Email: "",
  ScreenName: "",
  Password: "",
  BirthDate: "",
  AnniversaryDate: "",
  CellPhone: "",
  GenderId: null,
  PhotoUrl: "",
  IsRetired: false,
  PhotoUrlDefault: "",
  OfficeId: null,
  CanSpeakSpanish: false,
  RoleList: [],
  TimeZoneId: null,
  UserPhoneSetting: {
    EndpointId: "",
    EndpointName: "",
    DidId: null,
    Did: "",
    DisableVmBox: false,
  },
  CountryCode: "1",
};

let tempPhotoUrl = "";
import jsonData from "@/views/admin/EmployeeManagement/jsonData.json";

export default {
  name: "EmployeeManagement",
  components: { Cropper, VueMultiselect, DatePicker, Modal },
  created() {
    this.jsonData = jsonData;
  },
  data() {
    return {
      isSelectOpened: false,
      countryFlag: "",
      isVisible: true,
      selectedCountry: {
        name: "United States",
        shortName: "US",
        code: "1",
        format: "### ###-####",
      },
      jsonData: [],
      myTimeOut: null,
      isElevated: false,
      isLoading: false,
      attorneyListOldValue: null,
      savingAttorney: false,
      attorneyList: [],
      savingVmBoxSetting: false,
      v$: useValidate(),
      selectedRow: deepCopy(init),
      isEditing: false,
      employeeFilter: "",
      roleFilter: "",
      officeFilter: "",
      imageSelected: false,
      oldValue: "",
      isRetiredIncluded: false,
      saving: false,
      activeEmployee: false,
      officeId: 0,
      roleId: 0,
      gettingSelection: false,
      valueToRevert: deepCopy(init),
      isCancelledBeforeSave: false,
      isAuthorizedAllOffices: false,
      hasAccessOtherOffices: false,
      userOfficeId: 0,

      tabs: [
        {
          id: 1,
          name: "tab1",
          label: "Profile",
          iconName: " mdi mdi-account-circle",
          active: true,
          show: true,
        },
        {
          id: 2,
          name: "tab2",
          label: "Phone Settings",
          iconName: "mdi mdi-phone-log",
          active: false,
          show: true,
        },
        {
          id: 3,
          name: "tab3",
          label: "Attorney Settings",
          iconName: "mdi mdi-scale-balance",
          active: false,
          show: true,
        },
        {
          id: 4,
          name: "tab4",
          label: "Retire",
          iconName: " mdi mdi-account-off",
          active: false,
          show: true,
        },
      ],
      maxBirthDate: this.addYearsToDate(new Date(), -18),
      minBirthDate: new Date("1-1-1900"),
      savingPhone: false,
      savingDid: false,
      phoneSettings: null,
      acceptedFileTypes: ["jpg", "jpeg", "png", "gif"],
      regex: /^[a-zA-Z0-9._-]+@([a-zA-Z0-9.-]+.)+([.])+[a-zA-Z0-9.-]{2,5}$/,
      countryFilter: "",
      userSubOffices: [],
    };
  },
  computed: mapState({
    genders: (state) => state.globals.genders,
    roles: (state) => state.globals.roles,
    sortedRoles() {
      let filteredRoles = [];
      if (this.checkAuth(1454)) {
        filteredRoles = this.roles.filter(
          (x) =>
            x.OfficeId == null ||
            (this.selectedRow.OfficeId != null &&
              x.OfficeId == this.selectedRow.OfficeId)
        );
      } else if (this.checkAuth(55011)) {
        filteredRoles =
          this.userSubOffices.length > 0
            ? this.roles.filter(
                (x) =>
                  x.OfficeId == this.userOfficeId ||
                  this.userSubOffices.includes(x.OfficeId)
              )
            : this.roles.filter((x) => x.OfficeId == x.OfficeId);

        // filteredRoles = filteredRoles.filter(x=> x.OfficeId == null || (this.selectedRow.OfficeId != null &&  x.OfficeId == this.selectedRow.OfficeId));
      } else {
        filteredRoles = this.roles.filter(
          (x) => x.OfficeId == this.userOfficeId
        );
      }

      return orderBy(filteredRoles, "Name", "asc");
    },
    sortedRolesMainFilter() {
      let filteredRoles = [];
      if (this.checkAuth(1454)) {
        filteredRoles = this.roles;
      } else if (this.checkAuth(55011)) {
        filteredRoles =
          this.userSubOffices.length > 0
            ? this.roles.filter(
                (x) =>
                  x.OfficeId == this.userOfficeId ||
                  this.userSubOffices.includes(x.OfficeId)
              )
            : this.roles.filter((x) => x.OfficeId == x.OfficeId);
      } else {
        filteredRoles = this.roles.filter(
          (x) => x.OfficeId == this.userOfficeId
        );
      }

      return orderBy(filteredRoles, "Name", "asc");
    },
    officeList: (state) => state.globals.offices,
    offices() {
      let rtn = [];
      if (this.checkAuth(1454)) {
        rtn = this.officeList;
      } else if (this.checkAuth(55011)) {
        rtn =
          this.userSubOffices.length > 0
            ? this.officeList.filter(
                (x) =>
                  x.Id == null ||
                  x.Id == this.userOfficeId ||
                  this.userSubOffices.includes(x.Id)
              )
            : this.officeList.filter(
                (x) => x.Id == null || x.Id == this.userOfficeId
              );
      } else {
        rtn = this.officeList.filter((x) => x.Id == this.userOfficeId);
      }

      return orderBy(rtn, "Name", "asc");
    },
    selectedTab() { return this.tabs.find((tab) => tab.active) },
    list: (state) => state.employeeManagement.list,
    companyDids: (state) => state.employeeManagement.companyDids,
    timezones: (state) => state.globals.timezones,
    filteredEmployees() {
      let filtered = [];
      if (this.checkAuth(1454)) {
        filtered = this.list;
      } else if (this.checkAuth(55011)) {
        let userOffices = this.list.filter(
          (x) => x.OfficeId == this.userOfficeId
        );
        if (userOffices.length > 0) {
          for (let item of userOffices) {
            filtered.push(item);
          }
        }

        if (this.userSubOffices && this.userSubOffices.length > 0) {
          let userSubOffices = this.list.filter((x) =>
            this.userSubOffices.includes(x.OfficeId)
          );
          for (let item of userSubOffices) {
            filtered.push(item);
          }
        }
      } else {
        filtered = this.list.filter((x) => x.OfficeId == this.userOfficeId);
      }

      let rtn = filtered.filter((item) => {
        let nameFilter =
          (item.FirstName + " " + item.LastName)
            .toLowerCase()
            .indexOf(this.employeeFilter.toLowerCase()) > -1;
        let officeFilter =
          this.officeFilter !== "" ? this.officeFilter === item.OfficeId : true;
        let roleFilter =
          this.roleFilter !== ""
            ? item.RoleList.includes(this.roleFilter)
            : true;

        return nameFilter && officeFilter && roleFilter;
      });

      // if (this.isAuthorizedAllOffices == false) {
      //   rtn = rtn.filter((x) => x.OfficeId == this.offices[0].Id);
      // }

      return orderBy(
        rtn,
        [(item) => item.FirstName.toLowerCase()],
        "asc"
      );
    },
    filteredCountries() {
      return this.jsonData
        ? this.jsonData.filter(
            (x) =>
              this.filterString(x.name, this.countryFilter) ||
              this.countryFilter == ""
          )
        : [];
    },
  }),

  async mounted() {
    this.$store.commit(globalTypes.SET_CURRENT_KO_PATH, this.$koPaths.admin.employees[camelCase(this.selectedTab.label)]);
    await Promise.all([
      this.$store.dispatch(globalTypes.GET_TIMEZONES),
      this.$store.dispatch(globalTypes.GET_STATES),
      this.$store.dispatch(globalTypes.GET_GENDERS),
      this.$store.dispatch(globalTypes.GET_ROLES),
      this.$store.dispatch(globalTypes.GET_OFFICE_NAMES),
      this.$store.dispatch(types.GET_COMPANY_DIDS, 1),
    ]);

    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo != null && userInfo.isElevated != null) {
      this.isElevated = userInfo.isElevated;
    }
    if (userInfo != null && userInfo.moduleFunctions != null) {
      this.userOfficeId = userInfo.officeId;
      this.userSubOffices = userInfo.subOffices;
    }
    this.hasAccessOtherOffices = this.canSeeAllOffices();

    // -----
    //     let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    //     let arrPermission = userInfo.moduleFunctions
    //       .split(",")
    //       .map(function (item) {
    //         return parseInt(item, 10);
    //       });

    //     if (!arrPermission.includes(1453)) {
    //       this.isAuthorizedAllOffices = false;
    //       this.officeFilter = userInfo.officeId;
    //     } else {
    //       this.isAuthorizedAllOffices = true;
    //       this.officeFilter = "";
    //     }
    // ------
    // if (this.isAuthorizedAllOffices == false) {
    //   rtn = rtn.filter((x) => x.OfficeId == this.offices[0].Id);
    // }
    await this.$store
      .dispatch(types.GET_ALL, {
        retired: this.isRetiredIncluded,
        office: this.officeId,
        role: this.roleId,
      })
      .catch((err) => {
        console.log(err);
      });
    this.init();
  },

  methods: {
    isOfficeDisabled(officeId) {
      let rtn = false;

      if (this.checkAuth(1454)) {
        rtn = false;
      } else if (this.checkAuth(55011)) {
        rtn = !(
          officeId == this.userOfficeId ||
          this.userSubOffices.includes(officeId)
        );
      } else {
        rtn = true;
      }
      return rtn;
    },
    closeCountryList() {
      this.isSelectOpened = false;
    },

    openSelectBox() {
      this.isSelectOpened = true;
    },

    flagList(item) {
      let rtn = require("@/assets/images/flags/" +
        item.shortName.toLowerCase() +
        ".svg");
      return rtn;
    },
    async bindCountry(item) {
      this.isVisible = false;
      this.selectedRow.CountryCode = item.code;
      this.selectedCountry = item;
      this.countryFlag = require("@/assets/images/flags/" +
        this.selectedCountry.shortName.toLowerCase() +
        ".svg");
      await this.sleep(200);
      this.isVisible = true;
    },
    async changeCountry(item) {
      this.isVisible = false;
      this.selectedRow.CellPhone = "";
      this.selectedRow.CountryCode = item.code;
      this.selectedCountry = item;
      this.countryFlag = require("@/assets/images/flags/" +
        this.selectedCountry.shortName.toLowerCase() +
        ".svg");
      await this.sleep(200);
      this.isVisible = true;
      this.isSelectOpened = false;
    },
    onRoleListSelect(val) {
      if (val.IsElevated) {
        if (!this.isElevated) {
          this.$swal(
            "Warning!",
            "You are not allowed to assign roles with elevated authorizations: {" +
              val.Name +
              "}",
            "warning"
          );
          this.myTimeOut = setTimeout(() => {
            this.removeFromSelectedRole(val.Id);
          }, 100);
          /* setTimeout(function() {
           this.removeFromSelectedRole(val.Id);
         }, 1000);*/

          return;
        }
      }
    },
    removeFromSelectedRole(id) {
      if (this.myTimeOut != null) {
        clearTimeout(this.myTimeOut);
        this.myTimeOut = null;
      }

      let indx = this.selectedRow.RoleList.findIndex((x) => x.Id == id);
      if (indx > -1) {
        this.selectedRow.RoleList.splice(indx, 1);
      }
    },
    onRoleListRemove(val) {
      if (val.Id != null && val.Id > 0) {
        let indx = this.sortedRoles.findIndex((x) => x.Id == val.Id);

        if (indx > -1) {
          if (
            this.sortedRoles[indx] != null &&
            this.sortedRoles[indx].IsElevated
          ) {
            if (!this.isElevated) {
              this.$swal(
                "Warning!",
                "You are not allowed to remove roles with elevated authorizations: {" +
                  val.Name +
                  "}",
                "warning"
              );
              this.selectedRow.RoleList.push(val);
            }
          }
        }
      }
    },
    async resetPsw() {
      if (this.selectedRow.Email) {
        if (this.regex.test(this.selectedRow.Email) == false) {
          this.$swal("Warning!", "Email address is not valid!", "warning");
          return;
        } else {
          this.isLoading = true;
          let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
          let err, result;
          [err, result] = await to(
            api.resetPasswordRequest(this.selectedRow.Email, userInfo.companyId)
          );
          this.isLoading = false;

          if (result) {
            this.$swal("Success!", result.Message, "success");
            this.$emit("closeLoginOverlayPopUp");
          } else {
            let errMsg = this.getApiErrorMessage(err);
            this.$swal("Error!", errMsg, "error");
          }
        }
      }
    },

    async attorneySettingSave() {
      if (
        JSON.stringify(this.attorneyListOldValue) ==
        JSON.stringify(this.attorneyList)
      ) {
        this.$swal.fire("Warning!", "No Changes Detected!", "warning");
        return;
      }

      this.savingAttorney = true;
      let selectedItems = this.attorneyList.filter((x) => x.isSelected == true);
      let selectedIds = [];
      if (selectedItems.length > 0) {
        selectedIds = selectedItems.map((x) => x.Id);
      }
      let err, result;
      [err, result] = await this.$store.dispatch(types.SAVE_ATTORNEY_SETTING, {
        EmployeeId: this.selectedRow.Id,
        AttorneyList: selectedIds,
      });
      this.savingAttorney = false;

      if (result) {
        this.$swal("Success!", result.Message, "success");
        this.attorneyListOldValue = deepCopy(this.attorneyList);
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
    },

    async enableVmBox() {
      this.savingVmBoxSetting = true;
      let err, result;
      [err, result] = await this.$store.dispatch(types.UPDATE_VM_BOX_ENABLED, {
        UserId: this.selectedRow.Id,
        DisableVmBox: false,
      });
      if (result) {
        this.phoneSettings.DisableVmBox = result.Data;
        this.$swal("Success", "User VM Box Enabled.", "success");
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
      this.savingVmBoxSetting = false;
    },

    async disableVmBox() {
      this.savingVmBoxSetting = true;

      let err, result;
      [err, result] = await this.$store.dispatch(types.UPDATE_VM_BOX_ENABLED, {
        UserId: this.selectedRow.Id,
        DisableVmBox: true,
      });
      if (result) {
        this.phoneSettings.DisableVmBox = result.Data;
        this.$swal("Success", "User VM Box Disabled.", "success");
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
      this.savingVmBoxSetting = false;
    },

    refreshValue(event) {
      if (event.target.value && event.target.value != "") {
        this.selectedRow.CellPhone = event.target.value;
      }
    },

    setDefaultOffice() {
      if (
        (this.selectedRow.OfficeId == null || this.selectedRow.OfficeId == 0) &&
        this.offices.length > 0
      ) {
        this.selectedRow.OfficeId = this.userOfficeId;
      }
    },
    addYearsToDate(date, year) {
      let now = date.getFullYear();
      return new Date(now + year, 1, 1);
    },
    returnAuthorizedOffices() {},
    async createEndpoint(userId) {
      this.savingPhone = true;
      let err, result;

      [err, result] = await this.$store
        .dispatch(types.CREATE_ENDPOINT, userId)
        .catch((err) => {});

      if (result) {
        if (result.Data) {
          this.phoneSettings = deepCopy(init.UserPhoneSetting);
          this.phoneSettings.EndpointId = result.Data.EndpointId;
          this.phoneSettings.EndpointName = result.Data.EndpointName;
        }
        if (result.Message) this.$swal("Success!", result.Message, "success");
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
      this.savingPhone = false;
    },
    async deleteEndpoint(userId) {
      this.savingPhone = true;
      let err, result;

      [err, result] = await this.$store
        .dispatch(types.DELETE_ENDPOINT, userId)
        .catch((err) => {});

      if (result) {
        if (result.Message) this.$swal("Success!", result.Message, "success");
        this.phoneSettings = deepCopy(init.UserPhoneSetting);
        this.$store.dispatch(types.GET_COMPANY_DIDS, 1);
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }

      this.savingPhone = false;
    },
    async saveDid() {
      let e = document.getElementById("ddlDid");
      let didText = e.options[e.selectedIndex].text;

      this.savingDid = true;
      let err, result;

      [err, result] = await this.$store
        .dispatch(types.UPDATE_DID, {
          UserId: this.selectedRow.Id,
          DidId: this.phoneSettings.DidId,
        })
        .catch((err) => {});

      if (result) {
        if (result.Message) {
          this.phoneSettings.Did = didText;
          this.$swal("Success!", result.Message, "success");
          this.$store.dispatch(types.GET_COMPANY_DIDS, 1);
        }
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }

      this.savingDid = false;
    },
    async unAssignDid() {
      this.savingDid = true;
      let err, result;

      [err, result] = await this.$store
        .dispatch(types.UNASSIGN_DID, {
          DidAssignTypeId: 1,
          DidId: this.phoneSettings.DidId,
        })
        .catch((err) => {});

      if (result) {
        if (result.Message) {
          this.phoneSettings.Did = "";
          this.phoneSettings.DidId = 0;
          this.$swal("Success!", result.Message, "success");
          this.$store.dispatch(types.GET_COMPANY_DIDS, 1);
        }
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }

      this.savingDid = false;
    },
    init() {
      this.selectedRow = deepCopy(init);
      this.isEditing = false;
      this.activeEmployee = null;

      this.setCountryCode();

      let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

      let arrPermission = userInfo.moduleFunctions
        .split(",")
        .map(function (item) {
          return parseInt(item, 10);
        });

      if (!arrPermission.includes(739)) {
        this.tabs[1].show = false;
        this.tabs.push();
      }
    },
    setCountryCode() {
      if (typeof jsonData != "undefined" && jsonData) {
        let ccode = "1";
        if (this.selectedRow && this.selectedRow.CountryCode) {
          ccode = this.selectedRow.CountryCode.toString();
        }

        let codeArr = jsonData.filter((x) => x.code == ccode);
        if (codeArr.length > 0) {
          this.bindCountry(codeArr[0]);
        }
      }
    },
    async handleAddEmployee(reset) {
      if (
        this.cleanFormatPhone(this.oldValue) !=
          this.cleanFormatPhone(JSON.stringify(this.selectedRow)) &&
        this.cleanFormatPhone(JSON.stringify(this.selectedRow)) !=
          this.cleanFormatPhone(JSON.stringify(init))
      ) {
        await this.$swal({
          title: "You have unsaved changes",
          text: "You will lose it if you don't save",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, continue without saving!",
        }).then(async (dialog) => {
          if (dialog.value) {
            this.isCancelledBeforeSave = true;
          } else {
            this.isCancelledBeforeSave = false;
            return;
          }
        });
      } else {
        reset();
        this.handleTabChange("tab1");
        this.selectedRow = Object.assign({}, init);
        this.setDefaultOffice();
        this.setOfficeTimeZone();
        this.activeEmployee = null;
        this.isEditing = true;
      }

      if (this.isCancelledBeforeSave) {
        this.selectedRow = deepCopy(this.valueToRevert);

        let indx = this.list.findIndex((x) => x.Id == this.valueToRevert.Id);
        this.list[indx] = this.valueToRevert;
        this.list.push();

        this.isCancelledBeforeSave = false;

        reset();
        this.handleTabChange("tab1");
        this.selectedRow = Object.assign({}, init);
        this.setDefaultOffice();
        this.setOfficeTimeZone();
        this.activeEmployee = null;
        this.isEditing = true;
      }
    },
    isValidFileSize(size, limitMb) {
      let rtn = true;
      if (size > 1024 * 1024 * limitMb) {
        rtn = false;
      }

      return rtn;
    },
    getDefaultImage(event) {
      event.target.src = this.selectedRow.PhotoUrlDefault;
    },
    async handleSave() {
      this.saving = true;
      let err, result, type;
      if (this.oldValue == JSON.stringify(this.selectedRow)) {
        this.$swal("Warning!", "No Changes Detected!", "warning");
        this.saving = false;
        return;
      }
      if (this.$refs.image.files) {
        if (this.$refs.image.files.length > 0) {
          this.selectedRow.PhotoData = this.selectedRow.PhotoUrl.replace(
            /^data:image\/[a-z]+;base64,/,
            ""
          );
          tempPhotoUrl = this.selectedRow.PhotoUrl;
          this.selectedRow.PhotoUrl =
            "https://globalcrmstorageaccount.blob.core.windows.net/public-user-photos/photoUploadLoader.gif";
        }
      }
      if (this.selectedRow.Id) {
        type = types.UPDATE_EMPLOYEE;
      } else {
        type = types.SAVE_EMPLOYEE;
      }
      if (this.selectedRow.Password == "##!!initPwd!!##") {
        this.selectedRow.Password = null;
      }
      if (
        this.selectedRow.Password != null &&
        this.selectedRow.Password != ""
      ) {
        this.selectedRow.Password = await this.sha256(
          this.selectedRow.Password
        );
      }
      let tempRoleList = null;
      if (this.selectedRow.RoleList) {
        tempRoleList = this.selectedRow.RoleList;
        this.selectedRow.RoleList = this.selectedRow.RoleList.map((x) => x.Id);
      }

      if (this.selectedCountry && this.selectedCountry.code != "") {
        this.selectedRow.CountryCode = this.selectedCountry.code;
      }

      [err, result] = await this.$store.dispatch(type, this.selectedRow);
      this.selectedRow.RoleList = tempRoleList;
      if (result) {
        this.$swal("Success!", result.Message, "success");
        if (type == types.SAVE_EMPLOYEE) {
          this.selectedRow.Id = result.Data.Id;
          this.selectedRow.PhotoUrl =
            result.Data.PhotoUrl +
            "?sadsad=" +
            Math.floor(Math.random() * 1000) +
            1;
          this.selectedRow.Password = "##!!initPwd!!##";
          this.selectedRow.PhotoData = "";
          this.$refs.image.files = null;
          this.oldValue = JSON.stringify(this.selectedRow);
          this.list.push(this.selectedRow);
        } else {
          this.selectedRow.PhotoUrl =
            result.Data.PhotoUrl +
            "?sadsad=" +
            Math.floor(Math.random() * 1000) +
            1;
          this.selectedRow.Password = "##!!initPwd!!##";
          this.selectedRow.PhotoData = "";
          this.$refs.image.files = null;
          this.oldValue = JSON.stringify(this.selectedRow);
          this.$store
            .dispatch(types.GET_ALL, {
              retired: this.isRetiredIncluded,
              office: this.officeId,
              role: this.roleId,
            })
            .catch((err) => {});
        }

        //this.reset();
        this.selectedRow.Password = "##!!initPwd!!##";
      } else {
        let errMsg = this.getApiErrorMessage(err);
        if (type == types.SAVE_EMPLOYEE) {
          this.selectedRow.Password = "";
        } else {
          this.selectedRow.Password = "##!!initPwd!!##";
        }
        this.$swal("Error!", errMsg, "error");
        this.selectedRow.PhotoUrl = tempPhotoUrl;
      }

      this.saving = false;
    },

    handleCancel() {
      this.isEditing = false;
    },
    async handleEmployeeSelection(row, index, reset) {
      let x = this.roles.filter(
        (x) =>
          !this.hasAccessOtherOffices ? x.OfficeId == this.userOfficeId : x //x.OfficeId == null ||
        //(this.selectedRow.OfficeId != null
        //&& x.OfficeId == this.selectedRow.OfficeId)
      );

      let selectedRowToCheck = deepCopy(this.selectedRow);
      selectedRowToCheck.Password = "##!!initPwd!!##";
      let currentvalue = JSON.stringify(this.selectedRow).replace(
        /null/g,
        '""'
      );
      let currentvalue2 = JSON.stringify(selectedRowToCheck).replace(
        /null/g,
        '""'
      );
      if (
        !(
          this.oldValue.replace(/null/g, '""') == currentvalue ||
          this.oldValue.replace(/null/g, '""') == currentvalue2
        ) &&
        currentvalue != JSON.stringify(init) &&
        this.selectedRow.FirstName != ""
      ) {
        await this.$swal({
          title: "You have unsaved changes",
          text: "You will lose it if you don't save",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, continue without saving!",
        }).then(async (dialog) => {
          if (dialog.value) {
            this.isCancelledBeforeSave = true;
          } else {
            this.isCancelledBeforeSave = false;
            return;
          }
        });
      } else {
        reset();
        let err, result;
        this.gettingSelection = true;
        [err, result] = await this.$store.dispatch(types.GETBYID, row.Id);

        if (result) {
          this.selectedRow = result.Data; //deepCopy(row);

          if (this.checkAuth(921095)) {
            [err, result] = await this.$store.dispatch(types.GET_ATTORNEY, {
              EmployeeId: this.selectedRow.Id,
            });
            if (result && result.Data) {
              this.attorneyList = deepCopy(result.Data);
              this.attorneyListOldValue = deepCopy(result.Data);
            }
          }
          this.phoneSettings = deepCopy(this.selectedRow.UserPhoneSetting);
          if (this.selectedRow.BirthDate != "") {
            this.selectedRow.BirthDate = new Date(result.Data.BirthDate);
          }
          if (this.selectedRow.AnniversaryDate != "") {
            this.selectedRow.AnniversaryDate = new Date(
              result.Data.AnniversaryDate
            );
          }
          if (this.selectedRow.CellPhone == null) {
            this.selectedRow.CellPhone = "";
          }
          if (
            this.selectedRow.TimeZoneId == null ||
            this.selectedRow.TimeZoneId == 0
          ) {
            this.setOfficeTimeZone();
          }
        } else {
          let errMsg = this.getApiErrorMessage(err);
          this.$swal("Error!", errMsg, "error");
        }
        this.activeEmployee = this.selectedRow.Id;

        this.setCountryCode();
        this.selectedRow.Password = "##!!initPwd!!##";
        this.selectedRow.PhotoUrl +=
          "?sadsad=" + Math.floor(Math.random() * 1000) + 1;
        this.oldValue = JSON.stringify(this.selectedRow);
        this.valueToRevert = deepCopy(this.selectedRow);
        this.isEditing = true;
        this.gettingSelection = false;
      }

      if (this.isCancelledBeforeSave) {
        this.selectedRow = deepCopy(this.valueToRevert);
        let indx = this.list.findIndex((x) => x.Id == this.valueToRevert.Id);
        this.list[indx] = this.valueToRevert;
        this.list.push();
        this.oldValue = JSON.stringify(this.selectedRow);

        let err, result;
        this.gettingSelection = true;
        [err, result] = await this.$store.dispatch(types.GETBYID, row.Id);

        if (result) {
          this.selectedRow = result.Data; //deepCopy(row);
        } else {
          let errMsg = this.getApiErrorMessage(err);
          this.$swal("Error!", errMsg, "error");
        }
        this.activeEmployee = this.selectedRow.Id;
        this.setCountryCode();
        this.selectedRow.Password = "##!!initPwd!!##";
        this.selectedRow.PhotoUrl +=
          "?sadsad=" + Math.floor(Math.random() * 1000) + 1;
        this.oldValue = JSON.stringify(this.selectedRow);
        this.valueToRevert = deepCopy(this.selectedRow);
        this.isEditing = true;
        this.gettingSelection = false;
      }
      this.isCancelledBeforeSave = false;

      let el = this.$el.querySelector(".scrollToSelectedItem");
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
    handleImageClick(e) {
      const input = this.$refs.image;
      input.type = "text";
      input.type = "file";
    },
    handleImageChange(e) {
      if (e.target.files && e.target.files.length > 0) {
        const file = e.target.files[0];
        if (file) {
          let fileExtension = file.name.split(".").pop();

          if (fileExtension) {
            if (!this.acceptedFileTypes.includes(fileExtension.toLowerCase())) {
              this.$swal(
                "Warning!",
                "File is not a valid image. Only JPG, PNG and GIF files are allowed.",
                "warning"
              );
              e.preventDefault();
              return;
            }
          }
          if (!this.isValidFileSize(file.size, 5)) {
            this.$swal(
              "Warning!",
              "Maximum allowed file size is 5 MB for profile picture. Please resize your file.",
              "warning"
            );
            return;
          }

          this.selectedRow.PhotoUrl = URL.createObjectURL(file);
          this.imageSelected = true;
        }
      }
    },
    handleCropChange() {
      const { coordinates, canvas } = this.$refs.cropper.getResult();
      this.selectedRow.PhotoUrl = canvas.toDataURL();
      this.imageSelected = false;
    },
    handleTabChange(tab) {
      // Update status for selected tab
      this.tabs.forEach((item) => {item.active = item.name === tab});
      
      // Update store with selected tab for ko widget
      this.$store.commit(globalTypes.SET_CURRENT_KO_PATH, this.$koPaths.admin.employees[camelCase(this.selectedTab.label)]);
    },
    reset() {
      this.init();
    },
    handleRetiredChange() {
      this.$store
        .dispatch(types.GET_ALL, {
          retired: this.isRetiredIncluded,
          office: this.officeId,
          role: this.roleId,
        })
        .catch((err) => {});
      this.init();
    },
    async handleRetire() {
      if (this.selectedRow && this.selectedRow.Id > 0) {
        let err, result;
        if (this.selectedRow.IsRetired) {
          //Unretire
          [err, result] = await this.$store.dispatch(types.UPDATE_RETIRE, {
            UserId: this.selectedRow.Id,
            IsRetired: false,
          });
          if (result) {
            this.selectedRow.IsRetired = false;
            this.oldValue = JSON.stringify(this.selectedRow);
            this.$swal("Success!", result.Message, "success");
          } else {
            let errMsg = "An Error Occured!";
            this.$swal("Error!", errMsg, "error");
          }
        } //Retire
        else {
          this.$swal({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Retire!",
          }).then(async (dialog) => {
            if (dialog.value) {
              [err, result] = await this.$store.dispatch(types.UPDATE_RETIRE, {
                UserId: this.selectedRow.Id,
                IsRetired: true,
              });

              if (result) {
                this.selectedRow.IsRetired = true;
                this.oldValue = JSON.stringify(this.selectedRow);
                this.$swal("Success!", result.Message, "success");
              } else {
                let errMsg = "An Error Occured!";
                this.$swal("Error!", errMsg, "error");
              }
            }
          });
        }
      }
    },
    setOfficeTimeZone() {
      if (this.selectedRow.OfficeId && this.selectedRow.OfficeId > 0) {
        let indx = this.offices.findIndex(
          (x) => x.Id == this.selectedRow.OfficeId
        );
        if (indx >= 0) {
          this.selectedRow.TimeZoneId = this.offices[indx].TimeZoneId;
        }
        let rolesToDelete = [];

        for (let item of this.selectedRow.RoleList) {
          let roleIndex = this.sortedRoles.findIndex((x) => x.Id == item.Id);
          if (roleIndex < 0) {
            rolesToDelete.push(item.Id);
          }
        }
        this.selectedRow.RoleList = this.selectedRow.RoleList.filter(
          (x) => !rolesToDelete.includes(x.Id)
        );
      }
    },
  },
  validations() {
    return {
      selectedRow: {
        FirstName: { required },
        LastName: { required },
        Email: { required, email },
        ScreenName: { required },
        Password: { required },
        OfficeId: {
          officeSelected(OfficeId) {
            let id = +OfficeId;
            return id > 0;
          },
        },
        RoleList: {
          atLeastOneRole(RoleList) {
            return RoleList.length > 0;
          },
        },
        CellPhone: {
          phoneValidate(phone) {
            let isValid = true;
            let format = this.selectedCountry.format;
            if (format && format.length > 0) {
              isValid = this.cleanFormatPhone(format).length == phone.length;
            }

            return phone.length == 0 || isValid;
          },
        },
      },
    };
  },
  mixins: [utilitiesMixin, formatMixin],
};
</script>
<style scoped>
.nav-tabs li {
  margin: 0 !important;
}
.multiselect__content-wrapper {
  overflow-x: hidden;
}
.has-error {
  border: 2px solid red;
}

.contryList {
  background-color: white;
}

.contryList:hover {
  background-color: #ececec71;
}
</style>
