<template>
  <div
    class="col-md-12 newPanelWrapper noMargin"
    style="background-color: #ffffff; z-index: 1000"
  >
    <div style="display: flex; justify-content: space-between">
      <label>Task Trigger Schedule</label>
      <i
        v-on:click.prevent.stop="closePanel"
        class="fas fa-window-close closeButtonBulk"
      ></i>
    </div>
    <perfect-scrollbar
      style="
        width: 100%;
        margin: 5px 0;
        max-height: 500px;
        overflow-x: hidden !important;
      "
    >
      <div
        class="col-md-12 custom-table"
        v-if="taskTriggerList && taskTriggerList.length > 0"
      >
        <o-table
          :data="taskTriggerList"
          detailed
          detail-key="CsrTaskTypeTriggerId"
        >
          <o-table-column
            field="TriggerTypeName"
            label="Trigger Type"
            sortable
            v-slot="props"
            >{{ props.row.TriggerTypeName }}</o-table-column
          >
          <o-table-column
            field="TriggerDate"
            label="Trigger Time"
            sortable
            v-slot="props"
          >
            {{ formatDate(props.row.TriggerDate) }}
          </o-table-column>

          <o-table-column
            field="StatusName"
            label="Trigger Status"
            sortable
            v-slot="props"
          >
            {{ props.row.StatusName }}
          </o-table-column>

          <o-table-column
            field="StatusDate"
            label="Status Time"
            sortable
            v-slot="props"
          >
            {{ formatDate(props.row.StatusDate) }}
          </o-table-column>
          <o-table-column field="CallRecord" label="Call Recording">
            <template v-slot:searchable>
              <div>
                <input
                  type="text"
                  class="datagrid-table creditorTable"
                  placeholder=""
                />
              </div>
            </template>
            <template v-slot="props">
              <i
                v-if="props.row.recordUrl && props.row.recordUrl != ''"
                class="fas fa-play"
                style="color: green; cursor: pointer"
                @click="playRecord(props.row.recordUrl)"
              ></i>
            </template>
          </o-table-column>
          <template v-slot:detail="props">
            <div
              v-if="props.row.logs && props.row.logs.length > 0"
              class="detailsDiv"
              v-html="createDetailsTable(props.row.logs)"
            ></div>
            <div
              v-if="
                !props.row.logs ||
                (props.row.logs && props.row.logs.length == 0)
              "
              class="detailsDiv"
            >
              No details available.
            </div>
          </template>
        </o-table>
      </div>
    </perfect-scrollbar>
    <div
      class="col-md-12 datagrid-table"
      v-if="taskTriggerList == null || taskTriggerList.length == 0"
    >
      <section class="section" style="text-align: center">
        <div class="content has-text-grey has-text-centered mb-4 mt-2">
          <p class="noMargin">
            <i
              style="font-size: 24px; color: #939393"
              class="ri-question-line"
            ></i>
          </p>
          <p class="noMargin">Nothing here.</p>
        </div>
      </section>
    </div>
    <div class="row">
      <div class="backgroundLock" v-if="isAudioControllerPopupOpen">
        <div
          v-click-outside="closeAudioControllerPopup"
          v-if="soundUrl != ''"
          style="position: relative; margin: 20% auto"
          class="offset-xl-4 col-xl-4 offset-lg-3 col-lg-6 col-md-12 col-sm-12 col-xs-12"
        >
          <AudioController
            :soundUrl="soundUrl"
            @closeAudioControllerPopup="closeAudioControllerPopup"
          ></AudioController>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import types from "./types";
import formatMixin from "@/mixins/formatMixin";
import AudioController from "@/views/reports/AudioController.vue";
export default {
  name: "ClientTaskTriggerPopUp",
  components: { AudioController },
  mixins: [formatMixin],
  props: {
    taskId: {
      Type: Number,
      default: () => 0,
    },
  },

  data() {
    return {
      isAudioControllerPopupOpen: false,
      soundUrl: "",
      taskTriggerList: [],
    };
  },
  async mounted() {
    if (this.taskId > 0) {
      let err, result;
      [err, result] = await this.$store.dispatch(
        types.GET_TASK_TRIGGERS_BY_ID,
        { CsrTaskId: this.taskId }
      );
      if (result) {
        this.taskTriggerList = result.Data;
      }
    }
  },
  methods: {
    closePanel() {
      this.$emit("closeTaskTriggerPopUp");
    },
    async playRecord(url) {
      this.isAudioControllerPopupOpen = true;
      this.soundUrl = "";
      await this.sleep(500);
      this.soundUrl = url;
      await this.sleep(500);
    },
    closeAudioControllerPopup() {
      this.isAudioControllerPopupOpen = false;
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    createDetailsTable(data) {
      let html = "";

      if (data && data != {} && data != []) {
        if (Array.isArray(data)) {
          let headerNames = Object.keys(data[0]);
          html += "<table>";
          html += "<thead style='background-color:#fafafa'>";
          html += "<tr>";
          for (let hItem of headerNames) {
            html += "<th style='border: none;'>" + hItem + "</th>";
          }
          html += "</tr>";
          html += "</thead>";
          html += "<body>";
          data.forEach((item) => {
            let columnNames = Object.keys(item);
            html += "<tr>";
            columnNames.forEach((c) => {
              if (c.toLocaleLowerCase() == "calldate") {
                html += "<td>" + this.formatGridDateSecond(item[c]) + "</td>";
              } else {
                html += "<td>" + item[c] + "</td>";
              }
            });
            html += "</tr>";
          });
          html += "</body>";
          html += "</table>";
        }
      }
      return html;
    },
  },
};
</script>

<style></style>
