import types from "./types";
import api from "@/services/admin/api";
import to from "@/helpers/to";

export default {
  state: {},
  actions: {
    GET_PHONE_NUMBERS: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.getPhoneNumbers(payload));

      return [err, result];
    },
    BUY_PHONE_NUMBERS: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.buyPhoneNumbers(payload));

      return [err, result];
    },
    GET_DID_LIST: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.getCompanyDids(payload));

      return [err, result];
    },
  },
  mutations: {},
};
