<template>

  <div class="row admin-page officePage" v-if="isValidations">
    <div class="col-md-12">
    <label class="text-danger">
        The payout automation feature provided by the system is for testing purposes only. Global Zenith makes no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of the feature or information on this system. Upon completion of your testing of this feature, Customer will acknowledge and represent that the feature is acceptable to “go live” before implementation. Under no circumstance, prior to or after the go live date will Global Zenith have any liability to Customer for any loss or damage of any kind incurred as a result of the use of this feature or reliance on the information provided therefrom.
      </label>
  </div>
    <div v-if="saving" class="loading">
      <div class="text-center" style="margin-top: 150px">
        <i class="spinner-grow text-info m-2"></i>
      </div>
    </div>
    <div class="col-xl-3 col-lg-4 col-md-12 col-sm-12">
      <button
        type="button"
        class="col-md-12 btn btn-primary waves-effect waves-light"
        @click="handleAddNew"
      >
        Add New Configuration
      </button>
      <div class="filter form-group row">
        <div class="col-lg-12 col-md-12 col-sm-12 noPadding">
          <input
            autocomplete="off"
            type="text"
            class="form-control"
            v-model="automationFilter"
            placeholder="Filter Payout"
          />
        </div>

        <div class="list col-md-12" v-if="!saving">
          <div
            class="office item row"
            v-for="(item, index) in sortedAutomation"
            :key="item.Id"
            :class="{ active: activeItem === item.Id }"
            :style="[
              index % 2 === 0
                ? { 'background-color': '#F1EFEF' }
                : { 'background-color': '#FFFFFF' },
            ]"
            :disabled="saving"
            @click="() => handleSelection(item, index)"
          >
            <span :class="{ adminActiveMenuItem: activeItem === item.Id }">
              <i class="ri-coins-fill"></i>
              <span style="margin-left: 8px">{{ item.Name }}</span>
            </span>
          </div>
        </div>
        <div
          v-if="isMobileDevice()"
          style="
            border-top: 1px solid rgb(0, 0, 0, 0);
            margin: 20px 0 0 9px;
            padding-bottom: 55px;
          "
          class="col-md-12 scrollToSelectedItem"
        ></div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-8 col-md-12 col-sm-12" v-if="isEditing">
     
      <div class="panelContent" style="padding-top: 0">
        <div class="form-group row">
          <div class="col-md-12">
            <label for="name">Name *</label>
            <input
              autocomplete="off"
              id="name"
              class="form-control"
              type="text"
              v-model="selectedRow.Name"
              placeholder="Enter Name"
              :class="v$.selectedRow.Name.$error ? 'has-error' : ''"
              @blur="v$.selectedRow.Name.$touch"
            />
          </div>
          <div class="col-md-6 mt-2">
            <label for="payout">Customer Payout %</label>
            <input
              autocomplete="off"
              id="payout"
              v-model="selectedRow.CustomerPayoutPercentage"
              class="form-control"
              type="text"
              v-myMask="{
                alias: 'decimal',
                min: 0,
                max: 100,
                rightAlign: false,
              }"
            />
          </div>
          <div class="col-md-6 mt-2">
            <label for="offices">Offices</label>
            <VueMultiselect
              id="offices"
              v-model="selectedRow.OfficeIds"
              :options="offices"
              :multiple="true"
              :close-on-select="false"
              placeholder="Please Select"
              label="Name"
              track-by="Id"
            />
          </div>

          <div class="col-md-12 mt-2">
            <label>Settlement Tier %</label>

            <table class="datagrid-table creditorTable col-md-12">
              <thead>
                <tr>
                  <th>Min Amount</th>
                  <th>Max Amount</th>
                  <th>Settlement Tier %</th>
                  <th></th>
                </tr>
                <tr>
                  <th>
                    <input
                      type="text"
                      class="generalTextBox form-control"
                      v-model="newTier.minAmount"
                      v-myMask="{
                        alias: 'numeric',
                        rightAlign: false,
                        min: 0,
                      }"
                    />
                  </th>
                  <th>
                    <input
                      type="text"
                      class="generalTextBox form-control"
                      v-model="newTier.maxAmount"
                      v-myMask="{
                        alias: 'numeric',
                        rightAlign: false,
                        min: 0,
                      }"
                    />
                  </th>
                  <th>
                    <input
                      type="text"
                      class="generalTextBox form-control"
                      v-model="newTier.settlementTierPercentage"
                      v-myMask="{
                        alias: 'decimal',
                        min: 0,
                        max: 100,
                        digits: 2,
                        rightAlign: false,
                      }"
                    />
                  </th>
                  <th>
                    <button @click="addTier" class="btn btn-success">+</button>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in selectedRow.SettlementTiers"
                  :value="item.Id"
                  :key="item.Id"
                >
                  <td>{{ formatMoney(item.MinAmount) }}</td>
                  <td>{{ formatMoney(item.MaxAmount) }}</td>
                  <td>{{ item.SettlementTierPercentage }}%</td>
                  <td>
                    <button @click="deleteTier(item)" class="btn btn-danger">
                      <i class="fe-trash-2"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="col-md-12">
              <section
                v-if="selectedRow.SettlementTiers.length == 0"
                class="section"
                style="text-align: center"
              >
                <div class="content has-text-grey has-text-centered mb-4">
                  <p class="noMargin">
                    <i
                      style="font-size: 24px; color: #939393"
                      class="ri-question-line"
                    ></i>
                  </p>
                  <p class="noMargin">Nothing here.</p>
                </div>
              </section>
            </div>
          </div>

          <div
            class="col-md-12 mt-2 noPadding"
            style="display: flex; justify-content: flex-end"
          >
            <div class="col-md-3">
              <button
                v-if="selectedRow.Id"
                style="width: 100%"
                class="btn btn-danger"
                @click="handleDelete()"
              >
                Delete
              </button>
            </div>
            <div class="col-md-3">
              <button
                style="width: 100%"
                class="btn btn-success btn-bordered-success waves-effect"
                @click="handleSave()"
                :disabled="v$.$invalid || saving"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useValidate from "@vuelidate/core";
import types from "./types";
import { required } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import _orderby from "lodash";
import deepCopy from "@/helpers/deepCopy";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import VueMultiselect from "vue-multiselect";
import globalTypes from "@/store/types";
import formatMixin from "@/mixins/formatMixin";
const init = {
  Id: 0,
  Name: "",
  CustomerPayoutPercentage: 0,
  OfficeIds: [],
  SettlementTiers: [],
};
export default {
  name: "PayoutAutomationManagement",
  components: { VueMultiselect },
  mixins: [utilitiesMixin, formatMixin],
  data() {
    return {
      isValidations: false,
      newTier: {
        payoutConfigurationId: null,
        minAmount: null,
        maxAmount: null,
        settlementTierPercentage: null,
      },
      automationList: [],
      v$: useValidate(),
      activeItem: null,
      isEditing: false,
      saving: false,
      selectedRow: Object.assign({}, init),
      creditorList: [],
      oldValue: "",
      automationFilter: "",
    };
  },
  computed: mapState({
    sortedAutomation() {
      return this.automationList.length > 0
        ? _orderby.orderBy(
            this.automationList.filter(
              (x) =>
                x.Name.toLowerCase().indexOf(
                  this.automationFilter.toLowerCase()
                ) > -1
            ),
            "Name",
            "asc"
          )
        : [];
    },
    offices: (state) => state.globals.offices,
  }),
  async mounted() {
    await this.$swal
      .fire({
        title: "Are you sure?",
        text: "The payout automation feature provided by the system is for testing purposes only. Global Zenith makes no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of the feature or information on this system.  Upon completion of your testing of this feature, Customer will acknowledge and represent that the feature is acceptable to “go live” before implementation.  Under no circumstance, prior to or after the go live date will Global Zenith have any liability to Customer for any loss or damage of any kind incurred as a result of the use of this feature or reliance on the information provided therefrom.",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Agree",
        cancelButtonText: "Decline"
      })
      .then(async (dialog) => {
        if (dialog.value) {
          this.isValidations = true;
          await Promise.all([
            this.$store.dispatch(globalTypes.GET_OFFICE_NAMES),
            this.getSettings(),
          ]);
          this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
        Message:"{module: 'snf payout admin', action: 'Agree'}"
        });
        }
        else{
          this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
          Message:"{module: 'snf payout admin', action: 'Decline'}"
        });
        }
      });
  },
  methods: {
    async handleAddNew() {
      let isCancelledBeforeSave = false;
      if (
        this.selectedRow &&
        this.selectedRow.Id != 0 &&
        JSON.stringify(this.selectedRow) != this.oldValue
      ) {
        await this.$swal
          .fire({
            title: "You have unsaved changes",
            text: "You will lose it if you don't save",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, continue without saving!",
          })
          .then(async (dialog) => {
            if (dialog.value) {
              isCancelledBeforeSave = true;
            } else {
              isCancelledBeforeSave = false;
              return;
            }
          });
      } else {
        this.selectedRow = deepCopy(init);
        this.activeItem = null;
        this.isEditing = true;
      }

      if (isCancelledBeforeSave) {
        this.selectedRow = deepCopy(init);
        this.oldValue = JSON.stringify(this.selectedRow);
        this.activeItem = null;
      }
      isCancelledBeforeSave = false;
    },
    deleteTier(item) {
      let index = this.selectedRow.SettlementTiers.indexOf(item);
      if (index != -1) {
        this.selectedRow.SettlementTiers.splice(index, 1);
      }
    },
    addTier() {
      if (
        this.newTier == null ||
        this.newTier.minAmount == null ||
        this.newTier.maxAmount == null ||
        this.newTier.settlementTierPercentage == null
      ) {
        this.$swal(
          "Warning!",
          "Minimum amount, maximum amount, settlement tier cannot be left empty.",
          "warning"
        );
        return;
      }

      let minAmount = Number(this.newTier.minAmount);
      let maxAmount = Number(this.newTier.maxAmount);
      if (minAmount > maxAmount) {
        this.$swal(
          "Warning!",
          "Minimum amount cannot be greater than maximum amount.",
          "warning"
        );
        return;
      }

      this.selectedRow.SettlementTiers.push({
        Id: 0,
        PayoutConfigurationId: this.selectedRow.Id,
        MinAmount: this.newTier.minAmount,
        MaxAmount: this.newTier.maxAmount,
        SettlementTierPercentage: this.newTier.settlementTierPercentage,
      });
      this.newTier.minAmount = null;
      this.newTier.maxAmount = null;
      this.newTier.settlementTierPercentage = null;
    },
    async getSettings() {
      let err, result;
      [err, result] = await this.$store.dispatch(
        types.GET_PAYOUT_CONFIGURATION
      );

      if (result) {
        this.automationList = result.Data ? deepCopy(result.Data) : [];

        for (let j = 0; j < this.automationList.length; j++) {
          if (
            this.automationList[j].OfficeIds &&
            this.automationList[j].OfficeIds.length > 0
          ) {
            for (let i = 0; i < this.automationList[j].OfficeIds.length; i++) {
              let id = this.automationList[j].OfficeIds[i];
              this.automationList[j].OfficeIds[i] = {
                Id: id,
                Name: this.getOfficeNames(id),
              };
            }
          }
        }
      }
    },
    async handleSelection(row) {
      let isCancelledBeforeSave = false;
      if (
        this.selectedRow &&
        JSON.stringify(this.selectedRow) != this.oldValue &&
        JSON.stringify(this.selectedRow) != JSON.stringify(init)
      ) {
        await this.$swal
          .fire({
            title: "You have unsaved changes",
            text: "You will lose it if you don't save",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, continue without saving!",
          })
          .then(async (dialog) => {
            if (dialog.value) {
              isCancelledBeforeSave = true;
            } else {
              isCancelledBeforeSave = false;
              return;
            }
          });
      } else {
        this.selectedRow = deepCopy(row);
        this.oldValue = JSON.stringify(this.selectedRow);
        this.activeItem = row.Id;
        this.isEditing = true;
      }
      if (isCancelledBeforeSave) {
        this.oldValue = JSON.stringify(this.selectedRow);
        this.activeItem = row.Id;
      }
      isCancelledBeforeSave = false;
    },
    getOfficeNames(officeId) {
      let rtn = "";
      let indx = this.offices.findIndex((x) => x.Id == officeId);

      if (indx >= 0) {
        rtn = this.offices[indx].Name;
      }
      return rtn;
    },
    async handleDelete() {
      await this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then(async (dialog) => {
          if (dialog.value) {
            let rowIndex = this.automationList.findIndex(
              (x) => x.Id == this.selectedRow.Id
            );
            let err, result;
            this.saving = true;
            [err, result] = await this.$store.dispatch(
              types.DELETE_PAYOUT_CONFIGURATION,
              { Id: this.selectedRow.Id }
            );
            if (!err) {
              this.reset();
            }
            if (result) {
              if (rowIndex > -1) {
                this.automationList.splice(rowIndex, 1);
                this.reset();
              }
              this.$swal.fire("Deleted!", result.Message, "success");
              this.selectedRow = null;
            } else {
              let errMsg = this.getApiErrorMessage(err);
              this.$swal("Error!", errMsg, "error");
            }
          }
        });
      this.saving = false;
    },
    reset() {
      this.activeItem = null;
      this.selectedRow = deepCopy(init);
      this.isEditing = false;
    },
    async handleSave() {
      if (this.oldValue == JSON.stringify(this.selectedRow)) {
        this.$swal.fire("Warning!", "No changes detected!", "warning");
      } else {
        if (
          this.selectedRow.OfficeIds == null ||
          this.selectedRow.OfficeIds == "" ||
          this.selectedRow.OfficeIds == []
        ) {
          this.$swal.fire("Warning!", "Office not selected.", "warning");
          return;
        }

        let selectedOfficeIds = this.selectedRow.OfficeIds.map((x) => x.Id);
        let isOfficeUsed = this.automationList.some(
          (x) =>
            x.Id != this.selectedRow.Id &&
            x.OfficeIds.some((officeId) =>
              selectedOfficeIds.includes(officeId.Id)
            )
        );

        if (isOfficeUsed) {
          this.$swal.fire(
            "Warning!",
            "One or more selected Offices are already used in another configuration!",
            "warning"
          );
          return;
        }

        this.saving = true;
        let payload = {
          Id: this.selectedRow.Id,
          Name: this.selectedRow.Name,
          CustomerPayoutPercentage: parseInt(
            this.selectedRow.CustomerPayoutPercentage
          ),
          OfficeIds:
            this.selectedRow.OfficeIds && this.selectedRow.OfficeIds.length > 0
              ? this.selectedRow.OfficeIds.map((x) => x.Id).join(",")
              : "",
          SettlementTiers: this.selectedRow.SettlementTiers,
        };

        if (this.selectedRow.Id == 0) {
          let err, result;
          let nameExists =
            this.automationList.filter(
              (x) => x.Name.toLowerCase() == this.selectedRow.Name.toLowerCase()
            ).length > 0;
          if (nameExists) {
            this.$swal(
              "Warning!",
              "This name is already exists in list!",
              "warning"
            );
            this.saving = false;
            return;
          }

          [err, result] = await this.$store.dispatch(
            types.ADD_PAYOUT_CONFIGURATION,
            payload
          );

          if (result) {
            this.$swal("Success!", result.Message, "success");
            this.selectedRow.Id = result.Data.Id;
            // this.selectedRow = deepCopy(result.Data);
            this.automationList.push(this.selectedRow);
            this.oldValue = JSON.stringify(this.selectedRow);
          } else {
            let errMsg = this.getApiErrorMessage(err);
            this.$swal("Error!", errMsg, "error");
          }
          this.saving = false;
        } else {
          let err, result;
          [err, result] = await this.$store.dispatch(
            types.UPDATE_PAYOUT_CONFIGURATION,
            payload
          );
          if (result) {
            this.$swal("Success!", result.Message, "success");
            this.oldValue = JSON.stringify(this.selectedRow);
            let indx = this.automationList.findIndex(
              (x) => x.Id == this.selectedRow.Id
            );

            if (indx >= 0) {
              this.automationList[indx] = deepCopy(this.selectedRow);
            }
          } else {
            let errMsg = this.getApiErrorMessage(err);
            this.$swal("Error!", errMsg, "error");
          }
          this.saving = false;
        }
      }
    },
  },

  validations() {
    return {
      selectedRow: {
        Name: { required },
      },
    };
  },
};
</script>

<style scoped></style>
