<template>
  <div class="popupWrapper">
    <template v-bind:key="index" v-for="(item, index) in notifications">
      <Notification
        :duration="30"
        :notification="item"
        :canCallCustomer="hasPhoneSystem"
      ></Notification>
    </template>
    <audio
      v-if="popupNotifications.length > 0"
      :src="soundUrl"
      autoplay="autoplay"
    ></audio>
  </div>
</template>
<script>
import Notification from "./Notification";
import _orderby from "lodash";
import { mapState } from "vuex";
import utilitiesMixin from "@/mixins/utilitiesMixin";
export default {
  name: "NotificationPopupWrapper",
  components: { Notification },
  mixins: [utilitiesMixin],
  props: {
    hasPhoneSystem: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: mapState({
    popupNotifications: (state) => state.notifications.popupNotifications,

    notifications() {
      return _orderby.orderBy(
        this.popupNotifications,
        "NotificationType.Priority",
        "asc"
      );
    },
    soundUrl() {
      return this.popupNotifications.length > 0
        ? _orderby
            .orderBy(
              this.popupNotifications,
              "NotificationType.Priority",
              "asc"
            )
            .map((x) =>
              x.NotificationType.Id == 1 && this.checkAuth(8004)
                ? "https://globalcrmstorageaccount.blob.core.windows.net/public-sounds/autoCall.wav"
                : x.NotificationType.AlertSoundUrl
            )[0]
        : "";
    },
  }),
  async mounted() {
    /*
    this.$store
      .dispatch(notificationTypes.GET_POPUPNOTIFICATIONS)
      .catch(err => {});
      */
  },
  data() {
    return {
      musicAutoPlay: true,
    };
  },
  methods: {},
};
</script>
<style scoped>
.popupWrapper {
  width: 410px;
  max-height: 700px;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1000;
}
</style>
