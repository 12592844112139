<template>
  <div
    class="col-md-12 newPanelWrapper noMargin"
    style="
      background-color: #ffffff;
      z-index: 1000;
      padding-left: 0;
      padding-right: 0;
    "
  >
    <div v-if="isLoading" class="loading">
      <div class="text-center" style="margin-top: 150px">
        <i class="spinner-grow text-info m-2"></i>
      </div>
    </div>

    <div>
      <div>
        <div
          class="col-md-12"
          style="position: relative; top: -10px; left: 10px"
        >
          <label for="Title" class="generalLabel">Correspondence Reason</label>
        </div>
        <div class="closeButtonDR" v-on:click.prevent.stop="closePanel">
          <i class="fas fa-window-close"></i>
        </div>
      </div>
    </div>
    <perfect-scrollbar
      style="
        width: 100%;
        margin: 5px 0;
        padding: 0px 10px 0px 10px;
        max-height: 800px;
        overflow-x: hidden !important;
      "
    >
      <div>
        <div class="form-group col-md-12">
          <div>
            <label class="generalLabel">Please describe the reason...</label>
          </div>
          <div>
            <textarea
              v-model="reason"
              id="noteTextEditor"
              class="form-control textareaInput generalTextBox"
              rows="7"
            ></textarea>
          </div>
        </div>
      </div>

      <div class="col-md-12">
        <button
          @click="addTask()"
          id="saveAllIcon"
          type="button"
          class="btn btn-success col-md-12 mt-3"
        >
          Submit
        </button>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
import taskTypes from "@/views/agent/EditClient/ClientTasks/types";
import noteTypes from "@/views/agent/EditClient/ClientNotes/types";
import utilitiesMixin from "@/mixins/utilitiesMixin";
export default {
  name: "CorrespondenceRaisonPanel",
  mixins: [utilitiesMixin],
  components: {},
  props: {
    taskTypeId: {
      type: Number,
      default: 0,
    },
    taskTypeName: {
      type: String,
      default: "",
    },
    taskItem: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      reason: "",
      isLoading: false,
    };
  },
  computed: {},
  mounted() {},
  methods: {
    closePanel() {
      this.$emit("closeCorrespondenceRaisonPanelPopUp");
    },
    async addTask() {
      if (this.taskItem == null || this.taskTypeId == null) {
        this.$swal("Error!", "Invalid parameters.", "error");
        return;
      }

      if (this.reason.trim().length < 5) {
        this.$swal(
          "Warning!",
          "Reason field and postpone date is required.",
          "warning"
        );
        return;
      }
      this.isLoading = true;
      let noteText =
        "Creditor Name: " +
        this.taskItem.Creditor.Name +
        " | Account Number: " +
        this.taskItem.Creditor.AccountNumber +
        " | Negotiator's Note: " +
        this.taskTypeName +
        " - " +
        this.reason;
      await this.$store.dispatch(noteTypes.ADD_NOTE, {
        CustomerId: this.taskItem.Creditor.Customer.Id,
        Note: noteText,
        CreditorId: this.taskItem.Creditor.Id,
      });
      let err, result;
      [err, result] = await this.$store.dispatch(taskTypes.ADD_TASK, {
        CustomerId: this.taskItem.Creditor.Customer.Id,
        Notes: noteText,
        CsrTaskType: { Id: this.taskTypeId },
      });
      if (result) {
        // noteText = "Task Note -> " + noteText;
        // await this.$store.dispatch(noteTypes.ADD_NOTE, {
        //   CustomerId: this.taskItem.Creditor.Customer.Id,
        //   Note: noteText,
        // });
        this.closePanel();
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.closeButtonDR {
  position: absolute;
  top: -0px;
  right: 10px;
  z-index: 1000;
  font-size: 30px;
  cursor: pointer;
}

.closeButtonDR:hover {
  color: #ff3860;
}
</style>
