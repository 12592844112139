<template>
  <div class="col-md-12">
    <ReportSummary
      :exportData="reportData"
      :reportName="'MasterScrubReport'"
      :reportClass="'ScrubReports'"
      :recordCount="recordCount"
      @updateLoadingStatus="reportLoading = $event"
      @setPayload="setPayload"
      @clearReport="clearReport"
      @setPagination="setPagination"
      :loadingSpinner="loadingSpinner"
    />
    <div
      class="datagrid-table gcsTabsTableScroll report-wrapper"
      :style="'max-height: ' + reportsPanelHeight + 'px; overflow-y:auto;'"
    >
      <o-table
        :data="filteredDiscoverScrubReport"
        :paginated="isPaginated && filteredDiscoverScrubReport.length > 0"
        :per-page="perPage"
        :pagination-simple="isPaginationSimple"
        :pagination-position="paginationPosition"
        :sticky-header="true"
      >
        <o-table-column field="CustomerId" label="ClientId" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CustomerIdFilter"
                placeholder=""
                v-myMask="{
                  alias: 'numeric',
                  rightAlign: false,
                }"
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.CustomerId }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ addPrefixToCustomerId(props.row.CustomerId) }}
            </span>
          </template>
        </o-table-column>
        <o-table-column field="FirstName" label="FirstName" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="FirstNameFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.FirstName }}
          </template>
        </o-table-column>

        <o-table-column field="LastName" label="LastName" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="LastNameFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.LastName }}
          </template>
        </o-table-column>

        <o-table-column field="Ssn" label="Ssn" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="SsnFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.Ssn }}
          </template>
        </o-table-column>

        <o-table-column
          field="CoCustomer.FirstName"
          label="CoAppFirstName"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CoAppFirstNameFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{
              props.row.CoCustomer == null ? "" : props.row.CoCustomer.FirstName
            }}
          </template>
        </o-table-column>

        <o-table-column
          field="CoCustomer.LastName"
          label="CoAppLastName"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CoAppLastNameFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{
              props.row.CoCustomer == null ? "" : props.row.CoCustomer.LastName
            }}
          </template>
        </o-table-column>

        <o-table-column
          field="CoCustomer.Ssn"
          label="CoAppSsn"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CoAppSsnFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.CoCustomer == null ? "" : props.row.CoCustomer.Ssn }}
          </template>
        </o-table-column>

        <o-table-column
          field="PolicyGroup"
          label="PolicyGroup"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="PolicyGroupFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.PolicyGroup }}
          </template>
        </o-table-column>
      </o-table>
      <section
        v-if="filteredDiscoverScrubReport.length == 0"
        class="section"
        style="text-align: center"
      >
        <div class="content has-text-grey has-text-centered mb-4">
          <p class="noMargin">
            <i
              style="font-size: 24px; color: #939393"
              class="ri-question-line"
            ></i>
          </p>
          <p class="noMargin">Nothing here.</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import ReportSummary from "./ReportSummary.vue";
import utilitiesMixin from "@/mixins/utilitiesMixin";

export default {
  name: "MasterScrubReport",
  components: { ReportSummary },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      customerPrefix: null,
      reportsPanelHeight: 300,
      loadingSpinner: false,
      isPaginationSimple: false,
      isPaginated: true,
      paginationPosition: "both",
      currentPage: 1,
      perPage: 200,

      recordCount: 0,
      reportLoading: false,
      reportData: [],

      masterScrubReportInitSummary: {
        PolicyGroup: "",
        CustomerId: "",
        LastName: "",
        FirstName: "",
        Ssn: "",
        Dob: "",
        CoCustomer: {
          FirstName: "",
          LastName: "",
          Name: null,
          Ssn: "",
          Dob: "",
        },
      },
      PolicyGroupFilter: "",
      CustomerIdFilter: "",
      LastNameFilter: "",
      FirstNameFilter: "",
      SsnFilter: "",
      CoAppLastNameFilter: "",
      CoAppFirstNameFilter: "",
      CoAppSsnFilter: "",
    };
  },
  computed: mapState({
    masterScrubList: (state) => state.reports.masterScrubList,

    filteredDiscoverScrubReport() {
      let rtn = [];
      let tmpList =
        this.masterScrubList.length > 0
          ? this.masterScrubList.filter((item) => {
              return (
                this.filterString(item.CustomerId, this.CustomerIdFilter) &&
                this.filterString(item.LastName, this.LastNameFilter) &&
                this.filterString(item.FirstName, this.FirstNameFilter) &&
                this.filterString(item.PolicyGroup, this.PolicyGroupFilter) &&
                this.filterString(item.Ssn, this.SsnFilter) &&
                this.filterString(
                  item.CoCustomer == null ? "" : item.CoCustomer.LastName,
                  this.CoAppLastNameFilter
                ) &&
                this.filterString(
                  item.CoCustomer == null ? "" : item.CoCustomer.FirstName,
                  this.CoAppFirstNameFilter
                ) &&
                this.filterString(
                  item.CoCustomer == null ? "" : item.CoCustomer.Ssn,
                  this.CoAppSsnFilter
                )
              );
            })
          : [];

      if (tmpList.length == 0) {
        this.reportData = [];
        return rtn;
      }
      let totalCustomer = tmpList.length.toString();

      this.masterScrubReportInitSummary.CustomerId = "Total: " + totalCustomer;
      this.recordCount = tmpList.length;
      this.masterScrubReportInitSummary.IsSummary = true;

      rtn.push(...tmpList);
      rtn.push(this.masterScrubReportInitSummary);
      this.reportData = rtn;

      return rtn;
    },
  }),
  async mounted() {
    this.reportsPanelHeight = this.setReportGridHeight(
      window.innerHeight,
      document.getElementById("reportsSummary")
    );

    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

    if (
      this.userInfo != null &&
      this.userInfo.customerIdPrefix != null &&
      this.userInfo.customerIdPrefix.length > 0
    ) {
      this.customerPrefix = this.userInfo.customerIdPrefix;
    }
    await this.sleep(1000);
    this.fixStickyHeaders();
  },
  methods: {
    setPagination(data) {
      this.isPaginated = data;
    },
    addPrefixToCustomerId(customerId) {
      let rtn = customerId.toString();
      if (this.customerPrefix != null) {
        rtn = this.customerPrefix + rtn;
      }
      return rtn;
    },
    async setPayload(value) {
      this.loadingSpinner = true;
      let err, result;
      [err, result] = await this.$store.dispatch(
        types.GET_MASTER_SCRUB_REPORT,
        value
      );
      if (result && result.Data) {
        let apiResult = result.Data;
        for (let item of apiResult) {
          if (item.Ssn != "") {
            item.Ssn = await this.AES256_GCM_decrypt(item.Ssn);
          }
          if (item.CoCustomer != null && item.CoCustomer.Ssn != null) {
            item.CoCustomer.Ssn = await this.AES256_GCM_decrypt(
              item.CoCustomer.Ssn
            );
          }
        }
        await this.$store.dispatch(types.MODIFY_MASTER_SCRUB_REPORT, apiResult);
      }
      this.loadingSpinner = false;

      await this.sleep(500);
      this.fixStickyHeaders();
    },
    clearReport() {
      this.$store.dispatch(types.RESET_REPORTS);
      this.reportData = [];
    },
  },
};
</script>

<style scoped></style>
