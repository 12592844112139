import types from "./types";
import api from "@/services/admin/api";
import to from "@/helpers/to";
import deepCopy from "@/helpers/deepCopy";

export default {
  state: {
    dashboardData: null,
    officePerformanceData: null,
    snfDashboardData: null,
    affiliateLeadershipDashboardData: null,
    csrDashboardReportList: [],
    individualSalesDashboardList: null,
    operationHourDashboardList: null,
    snfCustomerStatusDashboardList: null,
    welcomeCallDashboardList: null,
    snfSettlementsList: null,
    negotiatorsDashboardList: null,
    totalUnsettledDebt: null,
    settlementFeeDashboardList: null,
    negotiatorStatisticsList: null,
    affiliateEnrollmenrDashboardList: null,
    migrationAffiliateEnrollmenrDashboardList: null,
  },
  actions: {
    GET_NEGOTIATOR_STATISTICS_DASHBOARD: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getNegotiatorStatisticsDashboard(payload));
      if (result) {
        commit("SET_NEGOTIATOR_STATISTICS_DASHBOARD", result.Data);
      }
    },
    GET_SNF_SETTLEMENT_FEE_DASHBOARD: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getSNFSettlementsFeeDashboard(payload));
      if (result) {
        commit("SET_SNF_SETTLEMENT_FEE_DASHBOARD", result.Data);
      }
    },

    GET_NEGOTIATORS_DASHBOARD: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getNegotiatorsDashboard(payload));
      if (result) {
        commit("SET_NEGOTIATORS_DASHBOARD", result.Data);
      }
    },

    GET_TOTAL_UNSETTLED_DEBT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getTotalUnsettleDebt(payload));
      if (result) {
        commit("SET_TOTAL_UNSETTLED_DEBT", result.Data);
      }
    },

    GET_SNF_SETTLEMENTS_DASHBOARD: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getSNFSettlementsDashboard(payload));
      if (result) {
        commit("SET_SNF_SETTLEMENTS_DASHBOARD", result.Data);
      }
    },

    GET_SALES_MONTHLY_DASHBOARD: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getSalesMonthlyOverviewData(payload));
      return [err, result];
    },

    GET_WELCOME_CALL: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getWelcomeCallDashboard(payload));
      if (result) {
        commit("SET_WELCOME_CALL", result.Data);
      }
    },

    GET_SNF_DASHBOARD_CUSTOMER_STATUS: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getSnfDashboardCustomerStatus(payload));
      if (result) {
        commit("SET_SNF_DASHBOARD_CUSTOMER_STATUS", result.Data);
      }
    },

    GET_AFFILIATE_ENROLLMENT_DASHBOARD: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getAffilateEnrollmentDashboard(payload));
      if (result) {
        commit("SET_AFFILIATE_ENROLLMENT_DASHBOARD", result.Data);
      }
    },

    GET_MIGRATION_AFFILIATE_ENROLLMENT_DASHBOARD: async (
      { commit, state },
      payload
    ) => {
      let err, result;

      [err, result] = await to(api.getAffilateEnrollmentDashboard(payload));
      if (result) {
        commit("SET_MIGRATION_AFFILIATE_ENROLLMENT_DASHBOARD", result.Data);
      }
    },

    GET_OPERATION_HOUR_DASHBOARD: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getOperationsHourDashboard(payload));
      if (result) {
        commit("SET_OPERATION_HOUR_DASHBOARD", result.Data);
      }
    },

    GET_INDIVIDUAL_SALES_DASHBOARD: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getIndividualSalesDashboard(payload));
      if (result) {
        commit("SET_INDIVIDUAL_SALES_DASHBOARD", result.Data);
      }
    },

    GET_DB_DASHBOARD_SALES: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getDbDashboardData(payload));
      return [err, result];
    },

    GET_SNF_DASHBOARD: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getSnfDashboard(payload));
      if (result) {
        commit("SET_SNF_DASHBOARD", result.Data);
      }
    },

    GET_AFFILIATE_LEADERSHIP_DASHBOARD: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getAffiliateLeadershipDashboard(payload));
      if (result) {
        commit("SET_AFFILIATE_LEADERSHIP_DASHBOARD", result.Data);
      }
    },

    GET_CSR_DASHBOARD_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getCSRDashboardReport(payload));
      if (result) {
        commit("SET_CSR_DASHBOARD_REPORT", result.Data);
      }
    },

    GET_SNF_DASHBOARD_SALES: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getSnfDashboard(payload));
      return [err, result];
    },

    GET_OFFICE_PERFORMANCE_STATE: async ({ commit, state }, payload) => {
      let err, result;
      [err, result] = await to(api.getDashboard(payload));
      if (result) {
        commit("SET_OFFICE_PERFORMANCE_STATE", result.Data);
      }
    },
    GET_OFFICE_PERFORMANCE: async ({ commit, state }, payload) => {
      let err, result;
      [err, result] = await to(api.getDashboard(payload));
      return [err, result];
    },

    GET_SUMMARY_TILES: async ({ commit, state }, payload) => {
      let err, result;

      if (state.dashboardData == null) {
        [err, result] = await to(api.initializeDashboard());

        if (result) {
          commit(types.SET_SUMMARY_TILES, result.Data);
        }
      }
    },
    REFRESH_DASHBOARD_DATA: async ({ commit, state }, payload) => {
      let err, result;
      if (payload.Title == "OfficePerformance") {
        [err, result] = await to(
          api.getDashboard({
            Option: payload.Option,
            StartDate: payload.StartDate,
            EndDate: payload.EndDate,
          })
        );
        return [err, result];
      }
    },
    RESET: async ({ commit }) => {
      commit(types.SET_SUMMARY_TILES, null);
    },
  },
  mutations: {
    SET_MIGRATION_AFFILIATE_ENROLLMENT_DASHBOARD: (state, payload) => {
      state.migrationAffiliateEnrollmenrDashboardList = payload;
    },
    SET_NEGOTIATOR_STATISTICS_DASHBOARD: (state, payload) => {
      state.negotiatorStatisticsList = payload;
    },
    SET_OFFICE_PERFORMANCE_STATE: (state, payload) => {
      state.officePerformanceData = payload;
    },
    SET_SNF_SETTLEMENT_FEE_DASHBOARD: (state, payload) => {
      state.settlementFeeDashboardList = payload;
    },
    SET_NEGOTIATORS_DASHBOARD: (state, payload) => {
      state.negotiatorsDashboardList = payload;
    },
    SET_TOTAL_UNSETTLED_DEBT: (state, payload) => {
      state.totalUnsettledDebt = payload;
    },
    SET_SNF_SETTLEMENTS_DASHBOARD: (state, payload) => {
      state.snfSettlementsList = payload;
    },
    SET_WELCOME_CALL: (state, payload) => {
      state.welcomeCallDashboardList = payload;
    },
    SET_SNF_DASHBOARD_CUSTOMER_STATUS: (state, payload) => {
      state.snfCustomerStatusDashboardList = payload;
    },
    SET_AFFILIATE_ENROLLMENT_DASHBOARD: (state, payload) => {
      state.affiliateEnrollmenrDashboardList = payload;
    },
    SET_OPERATION_HOUR_DASHBOARD: (state, payload) => {
      state.operationHourDashboardList = payload;
    },
    SET_INDIVIDUAL_SALES_DASHBOARD: (state, payload) => {
      state.individualSalesDashboardList = payload;
    },
    SET_CSR_DASHBOARD_REPORT: (state, payload) => {
      state.csrDashboardReportList = payload;
    },
    SET_SUMMARY_TILES: (state, payload) => {
      state.dashboardData = payload;
    },
    SET_SNF_DASHBOARD: (state, payload) => {
      state.snfDashboardData = payload;
    },

    SET_AFFILIATE_LEADERSHIP_DASHBOARD: (state, payload) => {
      state.affiliateLeadershipDashboardData = payload;
    },
  },
};
