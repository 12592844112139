import types from "./types";
import to from "@/helpers/to";
import api from "@/services/client/customerApi";
export default {
  state: {
    allPendingChanges: {},
  },

  actions: {
    PC_GET_PAYMENT_TYPES_WITH_FEES: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.getPaymentTypesByPolicyGroup(payload));

      return [err, result];
    },
    PC_APPROVE_WITHDRAWN_REQUEST: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.approveWithdrawalChanges(payload));

      return [err, result];
    },
    PC_DECLINE_WITHDRAWN_REQUEST: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.declineWithdrawalChanges(payload));

      return [err, result];
    },
    GET_CASHFLOW_FOR_PENDING_CHANGES: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.getNewGcsTabCashflow(payload));

      return [err, result];
    },
    PC_APPROVE_CHARGES: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.pendingChangesChargeApprove(payload));

      return [err, result];
    },
    PC_DECLINE_CHARGES: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.pendingChangesChargeDecline(payload));

      return [err, result];
    },

    GET_ALL_PENDING_CHANGES: async ({ commit }) => {
      let err, result;

      [err, result] = await to(api.getAllPendingChanges());

      if (result) {
        commit(types.SET_ALL_PENDING_CHANGES, result.Data);
      }
    },
    PC_APPROVE_EXTRAPAYMENTS: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.gcsTabExtraPaymentApprove(payload));

      return [err, result];
    },
    PC_DECLINE_EXTRAPAYMENTS: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.gcsTabExtraPaymentDecline(payload));

      return [err, result];
    },
    PC_APPROVE_DRAFTCHANGE: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.gcsTabDraftApprove(payload));

      return [err, result];
    },
    PC_DECLINE_DRAFTCHANGE: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.gcsTabDraftDecline(payload));

      return [err, result];
    },
    PC_APPROVE_ADDITIONALNOTE: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.gcsTabAdditionalNoteApprove(payload));

      return [err, result];
    },
    PC_DECLINE_ADDITIONALNOTE: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.gcsTabAdditionalNoteDecline(payload));

      return [err, result];
    },
    PC_APPROVE_AMENDMENTS: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.gcsTabAmendmentApprove(payload));

      return [err, result];
    },
    PC_DECLINE_AMENDMENTS: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.gcsTabAmendmentDecline(payload));

      return [err, result];
    },
    PC_APPROVE_DOCREVIEW: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.gcsTabDocReviewApprove(payload));

      return [err, result];
    },
    PC_DECLINE_DOCREVIEW: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.gcsTabDocReviewDecline(payload));

      return [err, result];
    },
    PC_APPROVE_SKIPPAYMENT: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.gcsTabSkipPaymentApprove(payload));

      return [err, result];
    },
    PC_DECLINE_SKIPPAYMENT: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.gcsTabSkipPaymentDecline(payload));

      return [err, result];
    },
    PC_APPROVE_INFOCHANGES: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.gcsTabClientInfoApprove(payload));

      return [err, result];
    },
    PC_DECLINE_INFOCHANGES: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.gcsTabClientInfoDecline(payload));

      return [err, result];
    },
    PC_APPROVE_STATUSCHANGES: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.gcsTabClientStatusApprove(payload));

      return [err, result];
    },
    PC_DECLINE_STATUSCHANGES: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.gcsTabClientStatusDecline(payload));

      return [err, result];
    },

    PC_APPROVE_FEECHANGES: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.pendingChangesFeeApprove(payload));

      return [err, result];
    },
    PC_DECLINE_FEECHANGES: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.pendingChangesFeeDecline(payload));

      return [err, result];
    },
    PC_APPROVE_PAYMENTCHANGES: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.approvePaymentChanges(payload));

      return [err, result];
    },
    PC_DECLINE_PAYMENTCHANGES: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.declinePaymentChanges(payload));

      return [err, result];
    },
  },
  mutations: {
    SET_ALL_PENDING_CHANGES: (state, payload) => {
      state.allPendingChanges = payload;
    },
  },
};
