<template>
  <Popover class="internal-call">
    <template v-slot:header>
      <div class="container" style="cursor: default">
        Negotiator Contacts

        <span
          v-show-if-authorized="'6354'"
          @click="openNewContact()"
          style="
            float: right;
            margin-top: -4px;
            padding: 0 5px;
            font-size: 20px;
            font-weight: normal;
            color: #24d15f;
            cursor: pointer;
          "
        >
          <i class="ri-add-circle-line"></i>
        </span>
        <input
          class="form-control"
          type="text"
          placeholder="Filter by name"
          v-model="contactListFilter"
        />
      </div>
    </template>
    <template v-slot:body>
      <div class="contact-list">
        <div class="contact" :key="item.Id" v-for="item in filteredContacts">
          <span style="width: 80%" @click="openCreditorContactForm(item)">
            {{ item.Name }}
          </span>
        </div>
        <button
          v-show-if-authorized="'6354'"
          v-if="showNewContact"
          type="button"
          @click="openNewContact()"
          class="btn btn-success btn-success-bordered mt-2 mr-2 float-right"
        >
          New Contact
        </button>
      </div>
    </template>
  </Popover>
</template>

<script>
import Popover from "../../Popover";
import types from "../types";
import { mapState } from "vuex";

export default {
  components: {
    Popover,
  },
  data() {
    return {
      contactListFilter: "",
    };
  },
  computed: mapState({
    negotiatorContacts: (state) => state.phoneManagement.negotiatorContacts,
    filteredContacts() {
      return this.negotiatorContacts && this.negotiatorContacts.length > 0
        ? this.negotiatorContacts.filter(
            (x) =>
              x.Name.toLowerCase().indexOf(
                this.contactListFilter.toLowerCase()
              ) > -1
          )
        : [];
    },
    showNewContact() {
      return this.filteredContacts.length > 0 ? false : true;
    },
  }),
  async mounted() {
    await this.$store.dispatch(types.GET_CREDITOR_CONTACTS);
  },
  methods: {
    handleNegotiatorCall(number) {
      this.$emit("callNegotiatorContact", number);
    },
    openCreditorContactForm(item) {
      this.$emit("openCreditorContactForm", item);
    },
    openNewContact() {
      let contactItem = {
        Id: null,
        Name: this.contactListFilter,
        Email: null,
        Fax: null,
        Notes: null,
        CrditorContactPhones: [],
      };
      this.$emit("openCreditorContactForm", contactItem);
      this.contactListFilter = "";
    },
  },
};
</script>
