import types from "./types";
import api from "@/services/admin/api";
import to from "@/helpers/to";
export default {
  state: {
    distroSettings: [],
  },
  actions: {
    GETLEADDISTRO: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.getLeadDistroSettings());

      if (result) {
        return result;
      }
    },
    POSTLEADDISTRO: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.updateLeadDistroSettings(payload));

      return [err, result];
    },
    GET_AGENT_TIERS: async ({ commit }) => {
      let err, result;

      [err, result] = await to(api.getAgentTiers());

      if (result) {
        return result;
      }
    },
    POST_AGENT_TIERS: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.updateAgentTiers(payload));

      return [err, result];
    },
  },
  mutations: {
    SETLEADDISTRO: (state, payload) => {
      state.distroSettings = payload;
    },
  },
};
