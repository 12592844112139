<template>
  <div class="row admin-page officePage">
    <div v-if="isLoading" class="loading">
      <div class="text-center" style="margin-top: 150px">
        <i class="spinner-grow text-info m-2"></i>
      </div>
    </div>
    <div class="col-xl-3 col-lg-4 col-md-12 col-sm-12 panelWrapper">
      <input
        autocomplete="off"
        type="text"
        class="form-control"
        v-model="audioRecorderFilter"
        placeholder="Filter Recorder"
      />
      <div class="list col-md-12">
        <div
          class="office item row"
          v-for="(recorder, index) in filteredRecorder"
          :key="recorder.Id"
          :class="{ active: activeVMRecorder === index }"
          @click="() => handleVMRecorderSelection(recorder, index)"
          :disabled="saving || deleting || spanishDeleting"
          :style="[
            index % 2 === 0
              ? { 'background-color': '#F1EFEF' }
              : { 'background-color': '#FFFFFF' },
          ]"
        >
          <span :class="{ adminActiveMenuItem: selectedId === recorder.Id }">
            <i class="fas fa-microphone"></i>
            <span style="margin-left: 8px">{{ recorder.Name }}</span>
          </span>
        </div>
      </div>
    </div>
    <div
      v-if="selectedRow"
      style="font-size: 1rem; color: #666"
      class="col-xl-4 col-lg-4 col-md-12 col-sm-12 panelWrapper"
    >
      <div class="mt-2">
        <h4
          style="border-bottom: 2px solid #000; padding-bottom: 5px"
          v-if="selectedRow"
        >
          {{ selectedRow.Name }}
        </h4>
      </div>
      <div class="mt-2 mb-3" v-if="alreadyRecorded.length > 0">
        <span>{{ alreadyRecorded }}</span>
      </div>

      <div class="row">
        <button
          v-if="!soundUrl"
          @click="startStopRecording()"
          type="button"
          class="btn btn-success rounded-pill waves-effect waves-light ml-2 mr-2"
          style="width: 60px; height: 60px"
          v-bind:class="[isActive ? 'btn-success' : 'btn-danger']"
        >
          <i
            v-bind:class="[
              isActive ? 'fas fa-microphone' : 'fas fa-microphone-alt',
            ]"
            style="font-size: 25px"
          ></i>
        </button>

        <div class="recordingTimer" v-if="isRecordingTimer">
          <label class="recordingNumber">{{ showRecordingTimer() }}</label>
        </div>
        <div>
          <audio controls v-if="soundUrl" :src="soundUrl"></audio>
        </div>
      </div>

      <div class="mt-2">
        <button
          type="button"
          class="btn btn-primary waves-light btn-sm mr-2"
          @click="handleSaveRTC"
          v-if="
            base64RTC && base64RTC.length > 0 && alreadyRecorded.length == 0
          "
        >
          Save Recording
        </button>

        <button
          type="button"
          class="btn btn-danger waves-light btn-sm"
          @click="removeRecordingRTC"
          v-if="alreadyRecorded || base64RTC.length > 0"
        >
          {{
            alreadyRecorded.length == 0
              ? "Discard Recording"
              : "Delete Recording"
          }}
        </button>
      </div>
      <div>
        <div class="col-md-12 mt-2 noPadding">
          <label class="generalLabel">Content:</label>
        </div>
        <div
          v-if="
            this.selectedRow &&
            this.selectedRow.TemplateContent &&
            this.selectedRow.TemplateContent.length > 0
          "
        >
          {{ this.selectedRow.TemplateContent }}
        </div>
      </div>
    </div>
    <div
      v-if="selectedRow && userCanSpeakSpanish"
      style="font-size: 1rem; color: #666"
      class="col-xl-4 col-lg-4 col-md-12 col-sm-12 panelWrapper"
    >
      <div class="mt-2">
        <h4 style="border-bottom: 2px solid #000; padding-bottom: 5px">
          {{ selectedRow.Name }} (Spanish)
        </h4>
      </div>
      <div class="mt-2 mb-3" v-if="spanishAlreadyRecorded.length > 0">
        <span>{{ spanishAlreadyRecorded }}</span>
      </div>

      <div class="row">
        <button
          v-if="!spanishSoundUrl"
          @click="startStopRecordingSpanish()"
          type="button"
          class="btn btn-success rounded-pill waves-effect waves-light ml-2 mr-2"
          style="width: 60px; height: 60px"
          v-bind:class="[spanishIsActive ? 'btn-success' : 'btn-danger']"
        >
          <i
            v-bind:class="[
              spanishIsActive ? 'fas fa-microphone' : 'fas fa-microphone-alt',
            ]"
            style="font-size: 25px"
          ></i>
        </button>

        <div class="recordingTimer" v-if="spanishIsRecordingTimer">
          <label class="recordingNumber">{{
            showRecordingTimerSpanish()
          }}</label>
        </div>
        <div>
          <audio controls v-if="spanishSoundUrl" :src="spanishSoundUrl"></audio>
        </div>
      </div>

      <div class="mt-2">
        <button
          type="button"
          class="btn btn-primary waves-light btn-sm mr-2"
          @click="handleSaveSpanish"
          v-if="
            spanishBase64RTC &&
            spanishBase64RTC.length > 0 &&
            spanishAlreadyRecorded.length == 0
          "
        >
          Save Recording
        </button>

        <button
          type="button"
          class="btn btn-danger waves-light btn-sm"
          @click="removeRecordingSpanish"
          v-if="spanishAlreadyRecorded || spanishBase64RTC.length > 0"
        >
          {{
            spanishAlreadyRecorded.length == 0
              ? "Discard Recording"
              : "Delete Recording"
          }}
        </button>
      </div>

      <div>
        <div class="col-md-12 mt-2 noPadding">
          <label class="generalLabel">Spanish Content:</label>
        </div>
        <div v-if="selectedRow">
          {{ this.selectedRow.SpanishTemplate }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import types from "./types";
import _orderby from "lodash";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import recorderMixin from "@/mixins/recorderMixin";
import formatMixin from "@/mixins/formatMixin";

export default {
  name: "VMRecorderManagement",
  components: {},
  mixins: [utilitiesMixin, recorderMixin, formatMixin],
  data() {
    return {
      userCanSpeakSpanish: false,

      spanishIsRecordingTimer: false,
      spanishRecordingTimer: null,
      spanishRecordingTimerSeconds: 0,
      spanishIsActive: true,
      spanishIsRecording: false,
      spanishSoundUrl: null,
      spanishDeleting: false,
      spanishBase64RTC: "",
      spanishAlreadyRecorded: "",

      isRecordingTimer: false,
      recordingTimer: null,
      recordingTimerSeconds: 0,
      isActive: true,
      isRecording: false,
      soundUrl: null,
      deleting: false,
      base64RTC: "",
      alreadyRecorded: "",

      alreadyMsg: "Already Recorded.",
      isEditing: false,
      saving: false,
      activeVMRecorder: null,
      selectedRow: null,
      selectedId: 0,
      vmRecorderFilter: "",
      statuses: [],
      roles: [],
      beforeSave: false,
      Name: "",
      audioRecorderFilter: "",
      isLoading: false,
    };
  },
  computed: {
    ...mapState({
      tempList: (state) => state.vmRecorderManagement.tempList,
      filteredRecorder() {
        return _orderby.orderBy(
          this.tempList.filter(
            (recorder) =>
              recorder.Name.toLowerCase().indexOf(
                this.audioRecorderFilter.toLowerCase()
              ) > -1
          )
        );
      },
    }),
  },
  async mounted() {
    await this.$store.dispatch(types.GETVMRECORDER);

    this.handleVMRecorderSelection(this.tempList[0], 0);

    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo != null && userInfo.moduleFunctions != null) {
      this.userCanSpeakSpanish = userInfo.canSpeakSpanish;
    }
  },

  methods: {
    handleRecordingTimer() {
      this.recordingTimerSeconds = this.recordingTimerSeconds + 1;
    },
    handleRecordingTimerSpanish() {
      this.spanishRecordingTimerSeconds = this.spanishRecordingTimerSeconds + 1;
    },
    showRecordingTimer() {
      return this.formatSecond(this.recordingTimerSeconds);
    },
    showRecordingTimerSpanish() {
      return this.formatSecond(this.spanishRecordingTimerSeconds);
    },
    async startStopRecording() {
      this.isActive = !this.isActive;
      this.isRecordingTimer = !this.isRecordingTimer;
      this.recordingTimerSeconds = 0;
      if (!this.isRecording) {
        //record start
        this.soundUrl = null;
        this.recordingTimer = setInterval(this.handleRecordingTimer, 1000);
        await this.sleep(100);
        this.startExtRecord();
      } else {
        //record stop
        clearInterval(this.recordingTimer);
        let soundFile = null;
        soundFile = await this.stopExtRecord();
        this.soundUrl = window.URL.createObjectURL(soundFile);
        this.myAudioFile(soundFile);
      }

      this.isRecording = !this.isRecording;
    },
    async startStopRecordingSpanish() {
      this.spanishIsActive = !this.spanishIsActive;
      this.spanishIsRecordingTimer = !this.spanishIsRecordingTimer;
      this.spanishRecordingTimerSeconds = 0;
      if (!this.spanishIsRecording) {
        //record start
        this.spanishSoundUrl = null;
        this.spanishRecordingTimer = setInterval(
          this.handleRecordingTimerSpanish,
          1000
        );
        await this.sleep(100);
        this.startExtRecord();
      } else {
        //record stop
        clearInterval(this.spanishRecordingTimer);
        let soundFile = null;
        soundFile = await this.stopExtRecord();
        this.spanishSoundUrl = window.URL.createObjectURL(soundFile);
        this.myAudioFileSpanish(soundFile);
      }

      this.spanishIsRecording = !this.spanishIsRecording;
    },
    async myAudioFile(audioFile) {
      if (audioFile != null) {
        let decodedData = await this.blobToBase64(audioFile);
        this.base64RTC = "";
        if (decodedData != null) {
          decodedData = decodedData.split(",");
          if (decodedData.length > 0) {
            this.base64RTC = decodedData[1];
          }
        }
      }
    },
    async myAudioFileSpanish(audioFile) {
      if (audioFile != null) {
        let decodedData = await this.blobToBase64(audioFile);
        this.spanishBase64RTC = "";
        if (decodedData != null) {
          decodedData = decodedData.split(",");
          if (decodedData.length > 0) {
            this.spanishBase64RTC = decodedData[1];
          }
        }
      }
    },
    async removeRecordingRTC() {
      this.isLoading = true;
      this.deleting = true;
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then(async (dialog) => {
          if (dialog.value) {
            this.deleting = false;
            this.isRecording = false;
            this.soundUrl = "";
            this.base64RTC = "";
            this.beforeSave = false;

            if (this.alreadyRecorded.length == 0) {
              return;
            }

            let payload = {
              VoicemailTemplateId: this.selectedRow.Id,
              IsSpanish: false,
            };

            let err, result;
            if (this.selectedRow) {
              [err, result] = await this.$store.dispatch(
                types.DELETEVMRECORDER,
                payload
              );
              if (result) {
                this.soundUrl = "";
                this.base64RTC = "";
                this.alreadyRecorded = "";
                this.selectedRow.Recorded = false;
                let indx = -1;
                indx = this.tempList.findIndex(
                  (x) => x.Id == this.selectedRow.Id
                );
                if (indx > -1) {
                  this.tempList[indx] = this.selectedRow;
                  this.tempList.push();
                }
                this.$swal.fire("Success!", result.Message, "success");
              } else {
                this.soundUrl = "";
                this.base64RTC = "";
                let errMsg = this.getApiErrorMessage(err);
                this.$swal("Error!", errMsg, "error");
              }
            }
          }
        });
      this.isLoading = false;
    },
    async removeRecordingSpanish() {
      this.isLoading = true;
      this.spanishDeleting = true;
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then(async (dialog) => {
          if (dialog.value) {
            this.spanishDeleting = false;
            this.spanishIsRecording = false;
            this.spanishSoundUrl = "";
            this.spanishBase64RTC = "";
            this.beforeSave = false;

            if (this.spanishAlreadyRecorded.length == 0) {
              return;
            }
            let err, result;
            let payload = {
              VoicemailTemplateId: this.selectedRow.Id,
              IsSpanish: true,
            };

            if (this.selectedRow) {
              [err, result] = await this.$store.dispatch(
                types.DELETEVMRECORDER,
                payload
              );
              if (result) {
                this.spanishSoundUrl = "";
                this.spanishBase64RTC = "";
                this.spanishAlreadyRecorded = "";
                this.selectedRow.IsSpanish = false;
                let indx = -1;
                indx = this.tempList.findIndex(
                  (x) => x.Id == this.selectedRow.Id
                );
                if (indx > -1) {
                  this.tempList[indx] = this.selectedRow;
                  this.tempList.push();
                }
                this.$swal.fire("Success!", result.Message, "success");
              } else {
                this.spanishSoundUrl = "";
                this.spanishBase64RTC = "";
                let errMsg = this.getApiErrorMessage(err);
                this.$swal("Error!", errMsg, "error");
              }
            }
          }
        });
      this.isLoading = false;
    },
    async handleSaveRTC() {
      this.isLoading = true;
      if (this.base64RTC != "") {
        let err, result;
        this.saving = true;
        [err, result] = await this.$store.dispatch(types.POSTVMRECORDER, {
          Id: this.selectedRow.Id,
          base64: this.base64RTC,
          IsSpanish: false,
        });

        if (result) {
          this.soundUrl = result.Data.RecordingUrl;
          this.base64RTC = "";
          this.alreadyRecorded = this.alreadyMsg;
          this.selectedRow.Recorded = true;
          let indx = -1;
          indx = this.tempList.findIndex((x) => x.Id == this.selectedRow.Id);

          if (indx > -1) {
            this.tempList[indx] = this.selectedRow;
            this.tempList.push();
          }
          this.$swal.fire("Success!", result.Message, "success");
        } else {
          this.soundUrl = "";
          this.base64RTC = "";
          let errMsg = this.getApiErrorMessage(err);
          this.$swal("Error!", errMsg, "error");
        }
      }

      this.saving = false;
      this.beforeSave = false;
      this.isLoading = false;
    },
    async handleSaveSpanish() {
      this.isLoading = true;
      if (this.spanishBase64RTC != "") {
        let err, result;
        this.saving = true;
        [err, result] = await this.$store.dispatch(types.POSTVMRECORDER, {
          Id: this.selectedRow.Id,
          base64: this.spanishBase64RTC,
          IsSpanish: true,
        });

        if (result) {
          this.spanishSoundUrl = result.Data.RecordingUrl;
          this.spanishBase64RTC = "";
          this.spanishAlreadyRecorded = this.alreadyMsg;
          this.selectedRow.IsSpanish = true;
          let indx = -1;
          indx = this.tempList.findIndex((x) => x.Id == this.selectedRow.Id);

          if (indx > -1) {
            this.tempList[indx] = this.selectedRow;
            this.tempList.push();
          }
          this.$swal.fire("Success!", result.Message, "success");
        } else {
          this.spanishSoundUrl = "";
          this.spanishBase64RTC = "";
          let errMsg = this.getApiErrorMessage(err);
          this.$swal("Error!", errMsg, "error");
        }
      }

      this.saving = false;
      this.beforeSave = false;
      this.isLoading = false;
    },
    async handleVMRecorderSelection(row, index) {
      let continued = true;
      if (this.base64RTC.length > 5 || this.spanishBase64RTC.length > 5) {
        await this.$swal
          .fire({
            title: "You have unsaved recording",
            text: "You will lose it if you don't save",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, continue without saving!",
          })
          .then(async (dialog) => {
            if (dialog.value) {
              continued = true;
            } else {
              continued = false;
              return;
            }
          });
      }

      if (
        this.saving ||
        this.isRecording ||
        this.spanishIsRecording ||
        this.beforeSave ||
        !continued
      )
        return;

      this.soundUrl = "";
      this.spanishSoundUrl = "";
      await this.sleep(250);

      this.selectedRow = row;

      if (this.selectedRow.Recorded) {
        this.soundUrl =
          this.selectedRow.RecordingUrl + "?v=" + new Date().getTime();

        this.base64RTC = "";
        this.alreadyRecorded = this.alreadyMsg;
      } else {
        this.soundUrl = "";
        this.base64RTC = "";
        this.alreadyRecorded = "";
      }

      if (this.selectedRow.IsSpanish) {
        this.spanishSoundUrl =
          this.selectedRow.RecordingUrlSpanish + "?v=" + new Date().getTime();

        this.spanishBase64RTC = "";
        this.spanishAlreadyRecorded = this.alreadyMsg;
      } else {
        this.spanishSoundUrl = "";
        this.spanishBase64RTC = "";
        this.spanishAlreadyRecorded = "";
      }

      this.selectedId = row.Id;
    },
    async blobToBase64(blob) {
      return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    },
  },
};
</script>
<style>
.recordingTimer {
  background-color: #f1f3f4;
  height: 61px;
  width: 170px;
  border-radius: 50px;
  margin-left: -61px;
  margin-top: 0px;
}
.recordingNumber {
  font-size: 20px;
  line-height: normal;
  color: #363636;
  display: flex;
  justify-content: center;
  margin-top: 16px;
  margin-left: 15px;
}
.loading {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.6);
  z-index: 1000;
}
</style>
