import types from "./types";
import api from "@/services/admin/api";
import to from "@/helpers/to";

export default {
  state: {
    list: [],
    companyDids: [],
  },
  actions: {
    GET_ATTORNEY: async ({ commit, state }, payload) => {
      let err, result;
      [err, result] = await to(api.attorneyList(payload));
      return [err, result];
    },

    SAVE_ATTORNEY_SETTING: async ({ commit, state }, payload) => {
      let err, result;
      [err, result] = await to(api.attorneySettingSave(payload));
      return [err, result];
    },

    UPDATE_VM_BOX_ENABLED: async ({ commit }, payload) => {
      let result = await to(api.updateVmBoxEnabled(payload));
      return result;
    },
    GET_ALL: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(
        api.getEmployees(payload.retired, payload.office, payload.role)
      );
      if (result) {
        commit(types.SET_EMPLOYEES, result.Data);
      }
    },
    GETBYID: async ({ commit }, employeeId) => {
      let result = await to(api.getEmployeeById(employeeId));
      return result;
    },
    SAVE_EMPLOYEE: async ({ commit }, payload) => {
      return await to(api.saveEmployee(payload));
    },
    UPDATE_EMPLOYEE: async ({ commit }, payload) => {
      let result = await to(api.updateEmployee(payload));

      return result;
    },
    UPDATE_RETIRE: async ({ commit }, payload) => {
      let result = await to(
        api.updateRetired(payload.UserId, payload.IsRetired)
      );

      return result;
    },
    DELETE_VM_TEMPLATES: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.deleteAllVmTemplates(payload));
      return [err, result];
    },
    CREATE_ENDPOINT: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.createEndpoint(payload));
      return [err, result];
    },
    DELETE_ENDPOINT: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.deleteEndpoint(payload));
      return [err, result];
    },
    GET_COMPANY_DIDS: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.getCompanyDids(payload));
      if (result) {
        commit(types.SET_COMPANY_DIDS, result.Data);
      }
    },
    UPDATE_DID: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.updateDid(payload));
      return [err, result];
    },
    UNASSIGN_DID: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.updateDidAssignType(payload));
      return [err, result];
    },
  },
  mutations: {
    SET_EMPLOYEES: (state, payload) => {
      state.list = payload;
    },
    SET_COMPANY_DIDS: (state, payload) => {
      state.companyDids = payload;
    },
  },
};
