<template>
  <div :style="'height:' + panelHeight + 'px;'">
    <div class="pt-2 pr-2 pb-2 pl-2">Last 5 Reminders</div>
    <div class="last5Table">
      <div
        class=""
        :style="
          'height:' +
          (panelHeight - 30) +
          'px;  overflow:hidden;  font-size: 12px !important;'
        "
      >
        <section>
          <o-table
            :data="isEmpty ? [] : last5Reminders"
            :striped="isStriped"
            :loading="isLoading"
            :hoverable="isHoverable"
          >
            <o-table-column
              field="AssignedUser"
              label="User"
              width="23%"
              sortable
              v-slot="props"
              >{{ props.row.AssignedUser }}</o-table-column
            >
            <o-table-column
              field="TriggerDate"
              label="Trigger On"
              style="text-align: left"
              width="32%"
              sortable
              v-slot="props"
              >{{ formatDate(props.row.TriggerDate) }}</o-table-column
            >
            <o-table-column
              style="text-align: left"
              field="NotificationText"
              label="Reminder Text"
              width="45%"
              sortable
              v-slot="props"
            >
              {{ props.row.NotificationText }}
            </o-table-column>
          </o-table>
          <section
            v-if="last5Reminders.length == 0"
            class="section"
            style="text-align: center"
          >
            <div class="content has-text-grey has-text-centered">
              <p class="noMargin">
                <i
                  style="font-size: 24px; color: #939393"
                  class="ri-question-line"
                ></i>
              </p>
              <p class="noMargin">Nothing here.</p>
            </div>
          </section>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import globalTypes from "@/store/types";
import formatMixin from "@/mixins/formatMixin";
export default {
  name: "Last5Reminders",
  mixins: [formatMixin],
  props: {
    customerId: {
      type: Number,
      default: () => 0,
    },
    panelHeight: {
      type: Number,
      default: () => 300,
    },
  },
  data() {
    return {
      last5Reminders: [],
      isStriped: true,
      isHoverable: true,
      isLoading: false,
      isEmpty: false,
    };
  },
  async mounted() {
    await this.bindLogs();
  },
  methods: {
    async bindLogs() {
      if (this.customerId && this.customerId > 0) {
        let err, result;
        [err, result] = await this.$store.dispatch(
          globalTypes.GET5_REMINDERS,
          this.customerId
        );

        if (result && result.Data) {
          this.last5Reminders = result.Data;
        }
      }
    },
  },
};
</script>
