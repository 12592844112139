<template>
  <div
    class="backgroundLock"
    style="-webkit-box-align: center; align-items: center; display: flex"
  >
    <div style="position: relative; margin: 10% auto">
      <div class="row">
        <div class="col-md-12">
          <ColorPicker
            :color="color"
            @changePickerColorBen="changeColor"
            id="ColorPicker"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <button
            class="btn btn-success form-control"
            id="ColorOkay"
            @click="SetColor()"
          >
            OK
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import ColorPicker from "vue3-ts-picker";
export default {
  components: {
    ColorPicker,
  },
  data() {
    return {
      color: "#ffffff",
      value: "",
    };
  },
  methods: {
    changeColor(e) {
      this.value = e;
    },
    SetColor() {
      if (this.value.indexOf("rgba") > -1) {
        this.value = this.rgba2hex(this.value);
      }
      this.$emit("updateColor", this.value);
    },

    rgba2hex(rgba) {
      rgba = rgba.match(
        /^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i
      );
      return rgba && rgba.length === 4
        ? "#" +
            ("0" + parseInt(rgba[1], 10).toString(16)).slice(-2) +
            ("0" + parseInt(rgba[2], 10).toString(16)).slice(-2) +
            ("0" + parseInt(rgba[3], 10).toString(16)).slice(-2)
        : "";
    },
  },
};
</script>

<style></style>
