import types from "./types";
import api from "@/services/admin/api";
import to from "@/helpers/to";
export default {
    state: {},
    actions: {

        GET_BLOCKED_NUMBERS: async ({ commit }) => {
            let err, result;
            [err, result] = await to(api.getIncomingCallBlock());
            return [err, result];
        },
        INSERT_BLOCKED_NUMBERS: async ({ commit }, payload) => {
            let err, result;
            [err, result] = await to(api.insertIncomingCallBlock(payload));
            return [err, result];
        },

        DELETE_BLOCKED_NUMBERS: async ({ commit }, payload) => {
            let err, result;
            [err, result] = await to(api.deleteIncomingCallBlock(payload));
            return [err, result];
        },

    },
    mutations: {},
};
