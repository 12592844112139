<template>
  <div
    class="col-md-12 newPanelWrapper noMargin"
    style="background-color: #ffffff; z-index: 1000"
  >
    <div v-if="isLoading" class="newLoading">
      <div class="text-center" style="margin-top: 150px">
        <i class="spinner-grow text-info m-2"></i>
      </div>
    </div>
    <div
      style="display: flex; justify-content: space-between"
      v-on:click.prevent.stop="closePanel"
    >
    <div class="align-items-center" style="height:25px; display: flex; justify-content: left;">
      <label>Bulk Payment Request</label>
      <button
              type="button"
              class="btn btn-xs btn-danger waves-effect waves-light ml-2"
              v-on:click.prevent.stop="openCashFlowPanel"
              v-if="isCashFlowNegative"
            >
              Negative Cash Flow!
            </button>
    </div>
      <i class="fas fa-window-close closeButtonBulk"></i>
    </div>
    <perfect-scrollbar
      style="max-height: 600px; padding-right: 30px; overflow-x: hidden"
    >
      <div class="col-md-12 row" v-if="creditorList.length > 0">
        <div class="col-md-6 col-12 noPadding">
          <label class="generalLabel">Creditor: </label>
          <select
            v-model="selectedCreditorId"
            class="form-control"
            @change="creditorChanged"
          >
            <option :value="0">Please Select</option>
            <option
              v-for="item in creditorList"
              :value="item.Id"
              :key="item.Id"
            >
              {{ item.Name }}
            </option>
          </select>
        </div>
      </div>
      <div
        class="col-md-12 noPadding text-center"
        v-if="creditorList.length == 0"
      >
        <label class="generalLabel text-danger pt-3 pb-3"
          >No settled creditor available to add payment.
        </label>
      </div>
      <div class="col-md-12 admin-page" v-if="selectedCreditorId > 0">
        <div class="col-md-12 row" v-if="paymentList.length > 0">
          <div class="col-xl-4 col-lg-6 col-md-12 col-12 noPadding">
            <div
              class="mt-1"
              style="
                display: flex;
                border-bottom: 1px dashed gray;
                align-items: baseline;
              "
            >
              <div>
                <input
                  type="checkbox"
                  :checked="editPaymentDaysChecked"
                  @change="changeEditPaymentDays"
                  class="checkbox-input generalCheckBox"
                />
              </div>
              <label class="generalLabel" style="margin-right: 10px"
                >Edit Payment Days</label
              >
            </div>
            <div class="mt-1" style="display: flex; align-items: baseline">
              <div>
                <input
                  name="feeOption"
                  :checked="paymentDayOption == 1"
                  @change="changePaymentDayOption(1)"
                  type="radio"
                  class="checkbox-input generalCheckBox"
                />
              </div>
              <label class="generalLabel">Push payment forward (days):</label>
              <input
                type="text"
                :disabled="paymentDayOption != 1"
                v-model="paymentForwardDays"
                class="generalTextBox miniTextBox ml-1"
                :class="paymentDayOption != 1 ? 'readonlyInput' : ''"
                style="width: 40px"
                v-myMask="{
                  alias: 'numeric',
                  min: 0,
                  digits: 0,
                  allowMinus: false,
                  rightAlign: false,
                }"
              />
            </div>
            <div class="mt-1" style="display: flex; align-items: baseline">
              <div>
                <input
                  :checked="paymentDayOption == 2"
                  @change="changePaymentDayOption(2)"
                  type="radio"
                  class="checkbox-input generalCheckBox"
                />
              </div>
              <label class="generalLabel">Pull payment backward (days):</label>
              <input
                type="text"
                :disabled="paymentDayOption != 2"
                v-model="paymentBackwardDays"
                class="generalTextBox miniTextBox ml-1"
                :class="paymentDayOption != 2 ? 'readonlyInput' : ''"
                style="width: 40px"
                v-myMask="{
                  alias: 'numeric',
                  min: -29,
                  max: 0,
                  rightAlign: false,
                }"
              />
            </div>
            <div class="mt-2" style="display: flex; align-items: baseline">
              <div>
                <input
                  type="radio"
                  :checked="paymentDayOption == 3"
                  @change="changePaymentDayOption(3)"
                  class="checkbox-input generalCheckBox"
                />
              </div>
              <label class="generalLabel">Change payment day from:</label>
            </div>
            <div
              class="mt-1"
              style="margin-left: 31px; width: 320px"
              v-if="paymentDayOption == 3"
            >
              <perfect-scrollbar style="min-width: 320px">
                <table class="datagrid-table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Day From</th>
                      <th>Day To</th>
                      <th></th>
                    </tr>
                    <tr v-if="isDayGridNewRowActive">
                      <th>
                        <span
                          style="cursor: pointer; margin: 5px; padding: 5px"
                          class="badge badge-danger"
                          @click="dayGridCancelRow"
                          >Cancel</span
                        >
                      </th>
                      <th>
                        <input
                          v-myMask="{
                            alias: 'numeric',
                            max: 30,
                            allowMinus: false,
                            rightAlign: false,
                          }"
                          class="generalTextBox miniTextBox"
                          type="text"
                          v-model="dayGridFrom"
                          v-on:keydown.enter.prevent="dayGridSave"
                          v-on:keydown.esc.prevent="dayGridCancelRow"
                          autocomplete="off"
                        />
                      </th>
                      <th>
                        <input
                          v-myMask="{
                            alias: 'numeric',
                            max: 31,
                            allowMinus: false,
                            rightAlign: false,
                          }"
                          class="generalTextBox miniTextBox"
                          type="text"
                          v-model="dayGridTo"
                          v-on:keydown.enter.prevent="dayGridSave"
                          v-on:keydown.esc.prevent="dayGridCancelRow"
                          autocomplete="off"
                        />
                      </th>
                      <th>
                        <i
                          style="
                            color: #1abc9c;
                            font-size: 17px;
                            cursor: pointer;
                          "
                          class="ri-checkbox-circle-line"
                          @click="dayGridSave"
                        ></i>
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <span
                        v-if="!isDayGridNewRowActive"
                        style="cursor: pointer; margin: 5px; padding: 5px"
                        class="badge badge-success badgeStatusPadding"
                        @click="addNewDayGridData"
                        >Add</span
                      >
                    </tr>
                    <tr v-for="(item, index) in dayGridData" :key="item.Id">
                      <th class="text-center" style="padding: 5px">
                        <label class="generalLabel">{{ index + 1 }}</label>
                      </th>
                      <th class="text-center" style="padding: 5px">
                        <label class="generalLabel">{{ item.DayFrom }}</label>
                      </th>
                      <th class="text-center" style="padding: 5px">
                        <label class="generalLabel">{{ item.DayTo }}</label>
                      </th>
                      <th class="text-center">
                        <i
                          style="color: red; font-size: 17px; cursor: pointer"
                          class="ri-close-circle-line"
                          @click="removeDayGridItem(item.Id)"
                        ></i>
                      </th>
                    </tr>
                  </tbody>
                </table>
              </perfect-scrollbar>
            </div>
            <div class="mt-2" style="display: flex; align-items: baseline">
              <div>
                <input
                  name="paymentOption"
                  type="radio"
                  :checked="paymentDayOption == 4"
                  @change="changePaymentDayOption(4)"
                  class="checkbox-input generalCheckBox"
                />
              </div>
              <label for="pullFeeForward" class="generalLabel"
                >Change to the next day of week:</label
              >
            </div>
            <div
              class="mt-2"
              style="display: flex; align-items: baseline"
              v-if="paymentDayOption == 4"
            >
              <label class="generalLabel">Day of Week:</label>

              <select
                style="width: 200px"
                class="form-control ml-2"
                v-model="nextDayOfWeek"
              >
                <option :value="null">Please Select</option>
                <option
                  v-for="item in daysOfWeekList"
                  :value="item.Id"
                  :key="item.Id"
                >
                  {{ item.Name }}
                </option>
              </select>
            </div>
            <div class="mt-2" style="display: flex; align-items: baseline">
              <div>
                <input
                  name="paymentOption"
                  type="radio"
                  :checked="paymentDayOption == 5"
                  @change="changePaymentDayOption(5)"
                  class="checkbox-input generalCheckBox"
                />
              </div>
              <label for="pullFeeForward" class="generalLabel"
                >Change to the previous day of week:</label
              >
            </div>
            <div
              class="mt-2"
              style="display: flex; align-items: baseline"
              v-if="paymentDayOption == 5"
            >
              <label class="generalLabel">Day of Week:</label>

              <select
                style="width: 200px"
                class="form-control ml-2"
                v-model="prevDayOfWeek"
              >
                <option :value="null">Please Select</option>
                <option
                  v-for="item in daysOfWeekList"
                  :value="item.Id"
                  :key="item.Id"
                >
                  {{ item.Name }}
                </option>
              </select>
            </div>
          </div>
          <div class="offset-xl-1 col-xl-6 col-lg-6 col-md-12 col-12 noPadding">
            <div class="mt-4" style="display: flex; align-items: baseline">
              <div>
                <input
                  id="pullPaymentForward"
                  type="checkbox"
                  :checked="paymentMonthSelected"
                  @change="changeMonthsSelect()"
                  class="checkbox-input generalCheckBox"
                />
              </div>
              <label for="pullPaymentForward" class="generalLabel"
                >Push payment forward (months):</label
              >
              <input
                type="text"
                class="generalTextBox miniTextBox ml-1"
                :disabled="paymentMonthSelected == false"
                :class="paymentMonthSelected == false ? 'readonlyInput' : ''"
                style="width: 40px"
                v-model="paymentMonthCount"
                v-myMask="{
                  alias: 'numeric',
                  digits: 0,
                  allowMinus: false,
                  rightAlign: false,
                }"
              />
            </div>
            <div class="mt-1" style="display: flex; align-items: baseline">
              <div>
                <input
                  type="checkbox"
                  :checked="paymentAmountSelected"
                  @change="changePaymentAmountSelect()"
                  class="checkbox-input generalCheckBox"
                />
              </div>
              <label class="generalLabel">Change payment amount:</label>
              <input
                type="text"
                :disabled="paymentAmountSelected == false"
                :class="paymentAmountSelected == false ? 'readonlyInput' : ''"
                class="generalTextBox miniTextBox ml-1"
                style="width: 100px"
                v-model="paymentAmount"
                v-myMask="{
                  alias: 'currency',
                  prefix: '$',
                  min: 0,
                  allowMinus: false,
                  rightAlign: false,
                }"
              />
            </div>

            <div
              class="mt-1"
              style="display: flex; align-items: baseline"
              v-if="!this.isDpg"
            >
              <div>
                <input
                  type="checkbox"
                  :checked="paymentFeeSelected"
                  @change="changePaymentFeeSelect()"
                  class="checkbox-input generalCheckBox"
                />
              </div>
              <label class="generalLabel">Change payment fee:</label>
              <input
                type="text"
                :disabled="paymentFeeSelected == false"
                :class="paymentFeeSelected == false ? 'readonlyInput' : ''"
                class="generalTextBox miniTextBox ml-1"
                style="width: 100px"
                v-model="paymentFee"
                v-myMask="{
                  alias: 'currency',
                  prefix: '$',
                  min: 0,
                  allowMinus: false,
                  rightAlign: false,
                }"
              />
            </div>
          </div>
        </div>

        <!-- <div class="mt-1" style="display: flex; align-items: baseline">
          <div>
            <input
              type="checkbox"
              :checked="paymentTypeSelected"
              @change="changePaymentTypeSelect()"
              class="checkbox-input generalCheckBox"
            />
          </div>
          <label class="generalLabel">Change payment type:</label>
          <select
            v-model="paymentType"
            :disabled="paymentTypeSelected == false"
            :class="paymentTypeSelected == false ? 'readonlyInput' : ''"
            class="form-control ml-1"
            style="width: 150px"
          >
            <option value="">Please Select</option>
            <option
              v-for="item in paymentsTypesList"
              :value="item.FeeType"
              :key="item.FeeType"
            >
              {{ item.Description }}
            </option>
          </select>
        </div> -->

        <div
          class="mt-2 col-md-12 row"
          v-if="
            paymentList.length > 0 &&
            (editPaymentDaysChecked ||
              paymentMonthSelected ||
              paymentAmountSelected ||
              paymentFee)
            // paymentTypeSelected
          "
        >
          <button @click="applyChanges()" class="btn btn-primary">
            Apply Changes and Show Preview
          </button>
        </div>

        <!-- Ikinci alan -->

        <div class="col-md-12 row mt-4">
          <label
            v-if="paymentList.length > 0"
            class="generalLabel"
            style="text-decoration: underline"
            >Preview</label
          >
          <div class="mt-2" style="width: 100%">
            <table class="datagrid-table" style="width: 100%">
              <thead>
                <tr>
                  <th>
                    <input
                      id="pullFeeForward"
                      type="checkbox"
                      class="checkbox-input"
                      :checked="selectAllPayments"
                      @change="selectAllPaymentChanges()"
                    />
                  </th>
                  <th class="text-left" style="padding: 10px">
                    Effective Date
                  </th>

                  <th class="text-left" style="padding: 10px">Payee</th>
                  <th class="text-left" style="padding: 10px">Acct #</th>
                  <th class="text-left" style="padding: 10px">Payment Type</th>
                  <th class="text-left" style="padding: 10px">Pmt Amount</th>
                  <th class="text-left" style="padding: 10px">Fee Amount</th>
                  <th class="text-left" style="padding: 10px">New Date</th>
                  <th class="text-left" style="padding: 10px">
                    New Pmt Amount
                  </th>
                  <th class="text-left" style="padding: 10px">
                    New Fee Amount
                  </th>
                  <th class="text-left" style="padding: 10px">Delete</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td style="padding: 5px 0 !important">
                    <span
                      title="Add new fee"
                      style="cursor: pointer; margin: 5px 10px; padding: 5px"
                      class="badge badge-success badgeStatusPadding"
                      @click="addNewPayment"
                      >Add</span
                    >
                  </td>
                  <td
                    v-if="addedPaymentList.length > 0"
                    style="padding: 5px 0 !important"
                  >
                    <span
                      title="Cancel added payments"
                      style="cursor: pointer; float: left"
                      class="badge badge-danger badgeStatusPadding"
                      @click="cancelBulkPayment"
                      >Cancel added payments</span
                    >
                  </td>
                  <td
                      :colspan="addedPaymentList.length > 0 ? 8 : 9"
                      style="text-align: right"
                    ></td>
                    <td style="text-align: center">
                      <input
                        type="checkbox"
                        class="checkbox-input"
                        v-model="isAllDeleted"
                        @change="deleteSelected"
                      />
                    </td>
                </tr>
                <tr v-for="item in paymentList" :key="item.PaymentId">
                  <td class="text-center" style="padding: 5px">
                    <input
                      id="pullFeeForward"
                      type="checkbox"
                      class="checkbox-input"
                      :checked="item.isEditing"
                      @change="selectPayment(item)"
                    />
                  </td>
                  <td class="text-left" style="padding: 5px 10px">
                    <label class="generalLabel">{{
                      formatShortDate(item.EffectiveDate)
                    }}</label>
                  </td>

                  <td class="text-left" style="padding: 5px 10px">
                    <label class="generalLabel">{{ item.Payee }}</label>
                  </td>
                  <td class="text-left" style="padding: 5px 10px">
                    <label class="generalLabel">{{ item.AccountNumber }}</label>
                  </td>
                  <td class="text-left" style="padding: 5px 10px">
                    <label class="generalLabel">{{ item.PaymentType }}</label>
                  </td>
                  <td class="text-left" style="padding: 5px 10px">
                    <label class="generalLabel">{{
                      formatMoney(item.PaymentAmount)
                    }}</label>
                  </td>
                  <td class="text-left" style="padding: 5px 10px">
                    <label class="generalLabel">{{
                      formatMoney(item.FeeAmount)
                    }}</label>
                  </td>
                  <td class="text-left" style="padding: 5px 10px">
                    <label
                      class="generalLabel"
                      :class="
                        getPaymentColorClass(
                          item.NewDate,
                          item.EffectiveDate,
                          item.isEditing
                        )
                      "
                      >{{ formatShortDate(item.NewDate) }}</label
                    >
                  </td>
                  <td class="text-left" style="padding: 5px 10px">
                    <label
                      class="generalLabel"
                      :class="
                        getPaymentColorClass(
                          item.NewPaymentAmount,
                          item.PaymentAmount,
                          item.isEditing
                        )
                      "
                      >{{ formatMoney(item.NewPaymentAmount) }}</label
                    >
                  </td>

                  <td class="text-left" style="padding: 5px 10px">
                    <label
                      class="generalLabel"
                      :class="
                        getPaymentColorClass(
                          item.NewFeeAmount,
                          item.FeeAmount,
                          item.isEditing
                        )
                      "
                      >{{ formatMoney(item.NewFeeAmount) }}</label
                    >
                  </td>
                  <td class="text-center" style="padding: 5px">
                    <input
                      type="checkbox"
                      :disabled="!item.isEditing"
                      class="checkbox-input"
                      :checked="item.isDeleting"
                      @change="deletePayment(item)"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="col-md-6 noPadding mt-3">
            <label class="generalLabel" style="text-decoration: underline"
              >Notes:</label
            >
            <textarea
              v-model="paymentNotes"
              placeholder="Notes here..."
              class="form-control textareaInput generalTextBox"
              rows="4"
            ></textarea>
          </div>
          <div v-if="checkAuth(54983) && isDpg == false" class="col-md-12 mt-4">
            <div class="row">
              <label class="generalLabel" style="text-decoration: underline"
                >Related Fees:</label
              >
            </div>
            <div class="row">
              <div class="col-md-4 noPadding">
                <label class="generalLabel">Fee Type</label>
                <select class="form-control" v-model="extraFees.GlobalFeeType">
                  <option :value="null">Please Select</option>
                  <option
                    v-for="item in feesTypesList"
                    :value="item.FeeType"
                    :key="item.FeeType"
                  >
                    {{ item.Description }}
                  </option>
                </select>
              </div>
              <div class="col-md-3">
                <label for="serviceFee" class="generalLabel">Amount</label>
                <input
                  v-model="extraFees.FeeAmount"
                  class="form-control"
                  v-myMask="{
                    alias: 'currency',
                    prefix: '$',
                    rightAlign: false,
                  }"
                  placeholder="$0.00"
                />
              </div>
              <div class="col-md-3">
                <label for="serviceFee" class="generalLabel">Date</label>

                <DatePicker
                  mode="date"
                  :locale="'en-US'"
                  v-model="extraFees.FeeDate"
                  :model-config="modelConfig"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      id="DCP-feeDate"
                      class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                      :value="inputValue"
                      v-on="inputEvents"
                      placeholder="Select Date"
                    />
                  </template>
                </DatePicker>
              </div>
              <div
                v-showIfAuthorized="54983"
                class="col-md-1"
                style="padding-top: 28px"
              >
                <button
                  @click="addExtraFee()"
                  type="button"
                  class="btn btn-success waves-effect waves-light"
                >
                  <i class="fas fa-plus-circle"></i>
                </button>
              </div>
            </div>

            <div class="row pt-3">
              <label v-for="(item, index) in applicableFees" :key="index">
                <div style="display: flex; align-items: stretch">
                  <label class="generalLabel pr-2">{{ index + 1 }}.</label>
                  <label class="generalLabel pr-2"
                    >Fee Type:<label class="ml-1" style="color: #6c757d">{{
                      item.GlobalFeeType
                    }}</label></label
                  >
                  <label class="generalLabel pr-2"
                    >Amount:<label class="ml-1" style="color: #6c757d">{{
                      formatMoney(item.FeeAmount)
                    }}</label></label
                  >
                  <label class="generalLabel pr-1"
                    >Amount Date:<label class="ml-1" style="color: #6c757d">{{
                      item.FeeDate
                    }}</label></label
                  >

                  <span
                    style="padding: 5px; cursor: pointer; height: 20px"
                    class="badge badge-danger"
                    @click="deleteExtraFee(index)"
                  >
                    Cancel</span
                  >
                </div>
              </label>
            </div>
          </div>
          <div class="col-md-12 mt-3">
            <label class="generalLabel">Emergency 2 business day</label>
            <input
              type="checkbox"
              id="feesTakenCBoxPopUp"
              v-model="isEmergency"
              class="checkbox-input generalCheckBox mr-2"
              style="
                float: left;
                border-radius: 4px !important;
                border: solid 1px #d1d1d1 !important;
              "
            />
          </div>
   
          <div
            class="col-md-12 mt-4"
            style="display: flex; justify-content: flex-end"
          >
            <button
              :disabled="isLoading"
              @click="requestPaymentChanges()"
              class="btn btn-primary"
            >
              {{
                checkAuth(12411) ? "Request and Approve" : "Request Changes "
              }}
            </button>
          </div>
        </div>
      </div>
    </perfect-scrollbar>


    <div
      class="backgroundLock"
      v-if="isPaymentChangesPopupOpen"
      style="-webkit-box-align: center; align-items: center; display: flex"
    >
      <div
        style="position: relative; margin: 10% auto"
        class="offset-lg-12 col-xl-5 offset-lg-2 col-lg-8 col-md-12 col-sm-12"
      >
        <PaymentChangesPopUp
          @closePaymentChangesPopup="closePaymentChangesPopup"
          @addBulkPayments="addBulkPayments"
          :creditorId="selectedCreditorId"
          :customerId="customerId"
          :customerOfficeId="customerOfficeId"
          :policyGroupId="policyGroupId"   
          :isDpg="isDpg"
          :previousPayment="paymentList.length > 0 ? paymentList[0] : null"
        />
      </div>
    </div>
  </div>
</template>

<script>
import globalTypes from "@/store/types";
import types from "./types";
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import deepCopy from "@/helpers/deepCopy";
import _orderby from "lodash";
import { DatePicker } from "v-calendar";
import PaymentChangesPopUp from "./PaymentChangesPopUp";
export default {
  name: "BulkPaymentRequestPopUp",
  components: {
    PaymentChangesPopUp,
    DatePicker,
  },
  mixins: [formatMixin, utilitiesMixin],
  props: {
    customerId: {
      Type: Number,
      default: 0,
    },
    customerOfficeId: {
      Type: Number,
      default: 0,
    },
    policyGroupId: {
      Type: Number,
      default: 0,
    },
    isDpg: {
      Type: Boolean,
      default: false,
    },
    feesTypesList: {
      Type: Array,
      default: [],
    },
    cashFlowList: {
      Type: Array,
      default: [],
    }
  },
  data() {
    return {
      isCashFlowNegative:false,
      creditorList: [],
      selectedCreditorId: 0,
      isTrue: true,
      isPaymentChangesPopupOpen: false,
      paymentFeeSelected: false,
      paymentFee: 0,
      isLoading: false,
      paymentList: [],
      paymentListBackup: [],
      appliedList: [],
      addedPaymentList: [],
      selectAllPayments: true,
      editPaymentDaysChecked: false,
      paymentDayOption: 0,
      paymentForwardDays: 3,
      paymentBackwardDays: -3,
      paymentMonthSelected: false,
      paymentMonthCount: 1,
      paymentAmountSelected: false,
      paymentAmount: 0,
      paymentTypeSelected: false,
      paymentType: "",
      isDayGridNewRowActive: false,
      dayGridFrom: null,
      dayGridTo: null,
      dayGridData: [],
      changesApplied: false,
      isEmergency: false,
      paymentNotes: "",
      extraFees: {
        GlobalFeeType: null,
        FeeAmount: 0,
        FeeDate: "",
      },
      paymentTypes: [],
      applicableFees: [],
      paymentListSelection: [],
      isFeeGridNewRowActive: false,
      newFee: {
        NewDate: "",
        Amount: 0,
        GlobalFeeType: "",
      },
      modelConfig: {
        type: "string",
        mask: "MM/DD/YYYY",
      },
      nextDayOfWeek: null,
      prevDayOfWeek: null,
      daysOfWeekList: [
        { Id: 0, Name: "Sunday" },
        { Id: 1, Name: "Monday" },
        { Id: 2, Name: "Tuesday" },
        { Id: 3, Name: "Wednesday" },
        { Id: 4, Name: "Thursday" },
        { Id: 5, Name: "Friday" },
        { Id: 6, Name: "Saturday" },
      ],
      isAllDeleted: false,
    };
  },
  unmounted() {},
  async mounted() {
    this.isLoading = true;
    await Promise.all[
      (this.getSettledCreditors(),
      this.getPaymentTypes(),
      this.bindPayments())
    ];
    this.isLoading = false;
  },

  methods: {
    getPaymentTypeTransactionFee(value) {
      let rtn = 0;
      if (this.paymentTypes.length > 0 && value != "") {
        let index = this.paymentTypes.findIndex((x) => x.Id == value);
        if (index > -1) {
          rtn = parseFloat(this.paymentTypes[index].TransactionFee);
        }
      }
      return rtn;
    },
    calculateCashFlowForBulkPayment() {
      if (this.checkAuth(956782)) {
        if (this.cashFlowList && this.cashFlowList.length > 0) {
          let list = deepCopy(this.cashFlowList);
          for (let item of this.paymentList) {
            let paymentId = parseInt(item.PaymentId);

            if (item.isDeleting == true) {
              //for deleting
              let paymentAndFees = list.filter(
                (x) => parseInt(x.ReferenceId) == paymentId
              );
              if(paymentAndFees && paymentAndFees.length> 0)
              {
                for(let i=0;i<paymentAndFees.length;i++)
                {
                  let deletedIndx = list.findIndex(x=> x.Id== paymentAndFees[i].Id);
                  if(deletedIndx > -1)
                  {
                    list[deletedIndx].IsDeletedPay=true;
                  }
                }

              }
            } else if (item.isEditing == true && paymentId > 0) {
              //for updating
              let paymentAndFees = this.cashFlowList.filter(
              (x) => parseInt(x.ReferenceId) == parseInt(item.PaymentId)
            );
            
            if(paymentAndFees && paymentAndFees.length> 0)
            {
                let tType ="",tDesc="",tDate="",tMemo="";
                for(let i=0;i<paymentAndFees.length;i++)
                {
                  let deletedIndx = list.findIndex(x=> x.Id== paymentAndFees[i].Id);
                  if(deletedIndx > -1)
                  {
                    list[deletedIndx].IsDeletedPay=true;
                  }
                  if(i== 0)
                  {
                    tType=paymentAndFees[i].TransactionType;
                    tDesc=paymentAndFees[i].TransactionTypeDesc;
                    tDate=paymentAndFees[i].Date;
                    tMemo =paymentAndFees[i].Memo;
                  }
                }

                let paymentDate = item.EffectiveDate == item.NewDate
                    ? tDate
                    : item.NewDate;
   
              list.push({
                Id: "X",
                ReferenceId: parseInt(item.PaymentId),
                IsPay: true,
                TransactionType:   tType,
                TransactionTypeDesc: tDesc,
                Date:paymentDate,
                Amount: parseFloat(item.NewPaymentAmount) + parseFloat(item.NewFeeAmount),
                Memo: tMemo,
                Balance: 0,
              });

              let paymentTypeId = this.getPaymentTypeKey(item.PaymentType);
              let transactionFee = this.getPaymentTypeTransactionFee(paymentTypeId);
              if(transactionFee > 0)
              {
                let paymentTypeName = item.PaymentType;
                let firstCharOfPaymentType = "";
                if(paymentTypeName != "" && paymentTypeName.length> 0)
                {
                   firstCharOfPaymentType=paymentTypeName.substring(0,1);
                }
                list.push({
                Id: "X",
                ReferenceId: parseInt(item.PaymentId),
                IsPay: true,
                TransactionType: "P"+ firstCharOfPaymentType.toUpperCase(),
                TransactionTypeDesc: "Payment - " + paymentTypeName + " Fee",
                Date:paymentDate,
                Amount: transactionFee,
                Memo: paymentTypeName + " Payment Fee",
                Balance: 0,
              });
              }
                
            }
            } else if (item.isEditing == true) {

              //for inserting
              list.push({
                Id: "X",
                ReferenceId: 0,
                IsPay: true,
                TransactionType: "A",
                TransactionTypeDesc: item.PaymentType,
                Date: item.NewDate,
                Amount: parseFloat(item.NewPaymentAmount),
                Memo: this.getCreditorName(item.CreditorId),
                Balance: 0,
              });
              let transactionFee = this.getPaymentTypeTransactionFee(item.PaymentTypeId);
              if(transactionFee > 0)
              {
                let paymentTypeName = item.PaymentType;
                let firstCharOfPaymentType = "";
                if(paymentTypeName != "" && paymentTypeName.length> 0)
                {
                   firstCharOfPaymentType=paymentTypeName.substring(0,1);
                }
                list.push({
                Id: "X",
                ReferenceId: parseInt(item.PaymentId),
                IsPay: true,
                TransactionType: "P"+ firstCharOfPaymentType.toUpperCase(),
                TransactionTypeDesc: "Payment - " + paymentTypeName + " Fee",
                Date: item.NewDate,
                Amount: transactionFee,
                Memo: paymentTypeName + " Payment Fee",
                Balance: 0,
              });
              }
            }
          }

          let fees = this.applicableFees;
          if (fees && fees.length > 0) {
            for (let item of fees) {
              let feeAmount = parseFloat(item.FeeAmount);
              let feeDate = new Date(item.FeeDate);
              list.push({
                Id: "X",
                IsPay: true,
                TransactionType: item.GlobalFeeType,
                TransactionTypeDesc: this.getFeeTypeName(item.GlobalFeeType),
                Date: feeDate.toString("MM/dd/yyyy"),
                Amount: feeAmount,
                Memo: this.getFeeTypeName(item.GlobalFeeType),
                Balance: 0,
              });
            }
          }
          this.$emit("calculateCashFlowForBulkPayment", list);
          this.isCashFlowNegative = this.checkCashFlowNegative();
        }
      }
    },
    getFeeTypeName(value) {
      let rtn = "";
      if (this.feesTypesList.length > 0 && value != "") {
        let index = this.feesTypesList.findIndex((x) => x.FeeType == value);
        if (index > -1) {
          rtn = this.feesTypesList[index].Description;
        }
      }
      return rtn;
    },
    openCashFlowPanel() {
      this.$emit("openCashFlowPanel");
    },
    checkCashFlowNegative() {
      let rtn = false;
      if (this.$parent) {
        rtn = this.$parent.checkCashFlowNegativeForPopups();
      }
      return rtn;
    },
    getCreditorName(id){
      let rtn = "";
      let lst =  this.creditorList.filter(x=> x.Id == id);
      if(lst && lst.length>0){
        rtn = lst[0].Name;
      }
      return rtn;
    },
    deleteSelected(event) {
      if (event && event.currentTarget) {
        let checked = event.currentTarget.checked;

        for (let item of this.paymentList) {
          if (item.isEditing == true) {
            item.isDeleting = checked;
          }
        }
        this.calculateCashFlowForBulkPayment();
      }
    },
    async creditorChanged() {
      await this.bindPayments();
    },
    closePaymentChangesPopup() {
      this.isPaymentChangesPopupOpen = false;
    },

    getFeeTypeKey(value) {
      let rtn = "";
      if (this.feesTypesList.length > 0 && value != "") {
        let index = this.feesTypesList.findIndex((x) => x.Description == value);
        if (index > -1) {
          rtn = this.feesTypesList[index].FeeType;
        }
      }
      return rtn;
    },
    async getSettledCreditors() {
      let err, result;
      [err, result] = await this.$store.dispatch(
        globalTypes.GET_CUSTOMER_SETTLED_CREDITORS,
        { CustomerId: this.customerId }
      );
      if (result && result.Data) {
        this.creditorList = deepCopy(result.Data);
      }
    },
    getPaymentTypeName(value) {
      let rtn = "";
      if (this.paymentTypes.length > 0 && value != "") {
        let index = this.paymentTypes.findIndex((x) => x.FeeType == value);
        if (index > -1) {
          rtn = this.paymentTypes[index].Description;
        }
      }
      return rtn;
    },
    getPaymentTypeKey(value) {
      let rtn = "";
      if (this.paymentTypes.length > 0 && value != "") {
        let index = this.paymentTypes.findIndex((x) => x.Name == value);
        if (index > -1) {
          rtn = this.paymentTypes[index].Id;
        }
      }
      return rtn;
    },
    addExtraFee() {
      if (
        this.extraFees.GlobalFeeType != null &&
        this.extraFees.FeeAmount > 0 &&
        this.extraFees.FeeDate != ""
      ) {
        let item = deepCopy(this.extraFees);
        item.FeeDate = new Date(item.FeeDate).toString("M/d/yyyy");
        item.FeeAmount = parseFloat(item.FeeAmount);

        this.applicableFees.push(item);
        this.extraFees.GlobalFeeType = null;
        this.extraFees.FeeAmount = 0;
        this.extraFees.FeeDate = "";
        this.calculateCashFlowForBulkPayment();
      } else {
        this.$swal(
          "Warning!",
          "Fee type, amount and date fields are required.",
          "warning"
        );
      }
    },
    deleteExtraFee(indx) {
      if (indx > -1) {
        this.applicableFees.splice(indx, 1);
        this.calculateCashFlowForBulkPayment();
      }
    },
    addBulkPayments(list) {
      for (let item of list) {
        this.paymentList.unshift(item);
        this.addedPaymentList.push(item);
        this.calculateCashFlowForBulkPayment();
      }
      this.isPaymentChangesPopupOpen = false;
    },

    async bindPayments() {
      this.isLoading = true;
      let err, result;
      [err, result] = await this.$store.dispatch(types.GET_PAYMENT_LIST, {
        CustomerId: this.customerId,
        OfficeId: this.customerOfficeId,
      });
      this.isLoading = false;
      if (result && result.Data) {
        this.paymentList =
          result.Data.length > 0
            ? _orderby.orderBy(
                result.Data.filter(
                  (x) =>
                    x.Active == "Y" &&
                    ((this.isDpg && parseFloat(x.PaymentAmount) > 0) ||
                      !this.isDpg) &&
                    x.CreditorId == this.selectedCreditorId
                ).map((x) => {
                  return {
                    PaymentId: x.PaymentId,
                    PayeeId: x.PayeeId,
                    CreditorId: x.CreditorId,
                    EffectiveDate: x.EffectiveDate,
                    Payee: x.Payee,
                    AddressId: x.AddressId,
                    BankId: x.BankId,
                    ContactId: x.ContactId,
                    SettlementId: x.SettlementId,
                    AccountNumber: x.AccountNumber,
                    PaymentType: x.PaymentType,
                    PaymentAmount: x.PaymentAmount,
                    FeeAmount: x.FeeAmount,
                    isEditing: true,
                    isDeleting: false,
                    NewDate: x.EffectiveDate,
                    NewPaymentAmount: x.PaymentAmount,
                    NewFeeAmount: x.FeeAmount,
                    GlobalFeeType: this.getFeeTypeKey(x.Type),
                  };
                }),
                "EffectiveDate",
                "asc"
              )
            : [];
        this.paymentListBackup = deepCopy(this.paymentList);
        this.calculateCashFlowForBulkPayment();
      }
    },
    async requestPaymentChanges() {
      if (this.customerId == 0) {
        this.$swal("Warning!", "Invalid customer id", "warning");
        return;
      }

      let selectedPayments = this.paymentList.filter(
        (x) => x.isEditing == true
      );

      if (selectedPayments.length == 0) {
        this.$swal("Warning!", "No payment selected.", "warning");
        return;
      }

      if (this.paymentNotes.trim().length == 0) {
        this.$swal("Warning!", "Notes field is mandatory", "warning");
        return;
      }

      let changeDetails = [];
      for (let i = 0; i < selectedPayments.length; i++) {
        if (
          selectedPayments[i].EffectiveDate != selectedPayments[i].NewDate ||
          selectedPayments[i].PaymentAmount !=
            selectedPayments[i].NewPaymentAmount ||
          selectedPayments[i].FeeAmount != selectedPayments[i].NewFeeAmount ||
          selectedPayments[i].isDeleting
        ) {
          let paymentAmount = parseFloat(selectedPayments[i].NewPaymentAmount);
          let feeAmount = parseFloat(selectedPayments[i].NewFeeAmount);
          if (selectedPayments[i].isDeleting) {
            paymentAmount = 0;
            feeAmount = 0;
          }

          changeDetails.push({
            EffectiveDate: this.formatShortDate(
              selectedPayments[i].EffectiveDate
            ),
            NewDate: this.formatShortDate(selectedPayments[i].NewDate),
            PaymentId: selectedPayments[i].PaymentId,
            PaymentAmount: paymentAmount,
            FeeAmount: feeAmount,
            PaymentType: selectedPayments[i].PaymentType,
            PaymentTypeId: this.getPaymentTypeKey(
              selectedPayments[i].PaymentType
            ),
            PayeeAddressId: selectedPayments[i].AddressId,
            PayeeBankId: selectedPayments[i].BankId,
            PayeeContactId: 0,
            PayeeName: selectedPayments[i].Payee,
            PayeeId: selectedPayments[i].PayeeId,
            PayeeAccountNumber: selectedPayments[i].AccountNumber,
            Memo1: selectedPayments[i].Memo1,
            Memo2: selectedPayments[i].Memo2,
            Memo3: selectedPayments[i].Memo3,
            CreditorId: parseInt(selectedPayments[i].CreditorId),
            SettlementId: parseInt(selectedPayments[i].SettlementId),
          });
        }
      }
      //remaining fee validation

      if (changeDetails.length == 0) {
        this.$swal("Warning!", "Nothing to change", "warning");
        return;
      }

      if(this.selectedCreditorId > 0){
        let totalSettlementFeeAmount =0;
        let feesByCreditor = this.paymentList.filter(x=> x.CreditorId == this.selectedCreditorId);
        if(feesByCreditor && feesByCreditor.length > 0)
        {
          for (let f of feesByCreditor) {
            totalSettlementFeeAmount += parseFloat(f.NewFeeAmount);
          }
          let err, result;
          [err, result] = await this.$store.dispatch(
                  types.GET_SETTLEMENT_FEE_INFO,
                  {
                    CustomerId: this.customerId,
                    CreditorId:this.selectedCreditorId,
                  }
          );

          if (result && result.Data) {

            let settlementFeeMaxAmount = parseFloat(result.Data.MaxFees);

            let settlementFeeClearedAmount = parseFloat(
                    result.Data.ClearedFees
            );

            let remainingFeeAmount = parseFloat(
                    settlementFeeMaxAmount - settlementFeeClearedAmount
            );

            // calculated total fee can not be grater than remaining fee from api data.
            if (parseFloat(totalSettlementFeeAmount.toFixed(2)) > parseFloat(remainingFeeAmount.toFixed(2))) {
              this.$swal(
                      "Warning!",
                      "The fee amount must not exceed the scheduled settlement fee. Remaining fee amount: " +
                      this.getCreditorName(this.selectedCreditorId) +
                      " - " +
                      this.formatMoney(remainingFeeAmount),
                      "warning"
              );
              return;
            }
          }
        }
      }
      let payload = {
        CustomerId: this.customerId,
        ApplicableFees: this.applicableFees,
        Notes: this.paymentNotes,
        IsEmergency: this.isEmergency,
        ChangeDetails: changeDetails,
        IsNegativeCashFlow:this.isCashFlowNegative
      };

      this.$swal
        .fire({
          title: "Confirm",
          text: "Are you sure to submit this request?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!",
        })
        .then(async (dialog) => {
          this.isLoading = true;
          if (dialog.value) {
            let err, result;
            [err, result] = await this.$store.dispatch(
              types.CREATE_PAYMENT_CHANGE,
              payload
            );

            if (result) {
              let successMessage = this.checkAuth(12411)
                ? "The requested changes are approved."
                : "Payment changes requested.";
              this.$swal("Success!", successMessage, "success");
              this.$emit("closePaymentRequestPopUp",this.checkAuth(12411));
            } else {
              let errMsg = this.getApiErrorMessage(err);
              this.$swal("Error!", errMsg, "error");
            }
          }
          this.isLoading = false;
        });
    },
    addNewPayment() {
      this.isPaymentChangesPopupOpen = true;
    },

    cancelBulkPayment() {
      this.addedPaymentList = [];
      this.paymentList = this.paymentList.filter((x) => x.PaymentId > 0);
      this.calculateCashFlowForBulkPayment();
    },
    async getPaymentTypes() {
      let err, result;
      [err, result] = await this.$store.dispatch(
        types.GET_PAYMENT_CHANGES_PAYMENT_TYPES_WITH_FEES,
        {PolicyGroupId: this.policyGroupId}
      );
      if (result && result.Data) {
        this.paymentTypes = deepCopy(result.Data);
      }
    },
   
    deletePayment(item) {
      item.isDeleting = !item.isDeleting;
      this.calculateCashFlowForBulkPayment();
    },

    changeEmergency() {
      this.isEmergency = !this.isEmergency;
    },
    selectAllPaymentChanges() {
      this.selectAllPayments = !this.selectAllPayments;
      for (let item of this.paymentList) {
        item.isEditing = this.selectAllPayments;
        item.isDeleting = this.isAllDeleted;
        this.updatePaymentData(item);
      }
      this.calculateCashFlowForBulkPayment();
    },
    selectPayment(item) {
      item.isEditing = !item.isEditing;
      item.isDeleting = this.isAllDeleted;
      this.updatePaymentData(item);
      this.calculateCashFlowForBulkPayment();
    },
    updatePaymentData(item) {
      if (item.isEditing == false) {
        let indx = this.paymentListBackup.findIndex(
          (x) => x.DebitId == item.DebitId
        );
        if (indx > -1) {
          item.NewDate = this.paymentListBackup[indx].EffectiveDate;
          item.NewPaymentAmount = this.paymentListBackup[indx].PaymentAmount;
          item.NewFeeAmount = this.paymentListBackup[indx].FeeAmount;
        }
      } else {
        let indx = this.appliedList.findIndex(
          (x) => x.PaymentId == item.PaymentId
        );
        if (indx > -1) {
          item.NewDate = this.appliedList[indx].NewDate;
          item.NewPaymentAmount = this.appliedList[indx].NewPaymentAmount;
          item.NewFeeAmount = this.appliedList[indx].NewFeeAmount;
        }
      }
    },
    getPaymentColorClass(item1, item2, isEditing) {
      let rtn = "";
      if (item1 != item2 && isEditing) {
        rtn = "selectedDraft";
      }

      return rtn;
    },
    getSelection(item, index) {
      let indx = this.paymentListSelection.findIndex(
        (x) => x.PaymentId == item.PaymentId
      );
      if (indx > -1) {
        if (index > -1) {
          this.paymentList[index].isEditing =
            this.paymentListSelection[indx].isEditing;
          this.paymentList[index].isDeleting =
            this.paymentListSelection[indx].isDeleting;
        }
      }
    },
    applyChanges() {
      this.paymentListSelection = this.paymentList.map((x) => {
        return {
          PaymentId: x.PaymentId,
          isEditing: x.isEditing,
          isDeleting: x.isDeleting,
        };
      });
      this.paymentList = deepCopy(this.paymentListBackup);
      for (let item of this.paymentList) {
        this.getSelection(item, this.paymentList.indexOf(item));
      }

      for (let item of this.paymentList) {
        let forwardDays = parseInt(this.paymentForwardDays);
        let backwardDays = parseInt(this.paymentBackwardDays);
        if (this.paymentDayOption == 1 && forwardDays > 0) {
          item.NewDate = this.formatShortDate(
            this.addDays(item.NewDate, forwardDays)
          );
        } else if (this.paymentDayOption == 2 && backwardDays < 0) {
          item.NewDate = this.formatShortDate(
            this.addDays(item.NewDate, backwardDays)
          );
        } else if (this.paymentDayOption == 3 && this.dayGridData.length > 0) {
          let feeDay = new Date(item.NewDate).getDate();

          for (let dayItem of this.dayGridData) {
            if (parseInt(dayItem.DayFrom) == parseInt(feeDay)) {
              let feeDate = new Date(item.NewDate);

              let newDate = new Date(
                feeDate.getFullYear(),
                feeDate.getMonth(),
                parseInt(dayItem.DayTo)
              );
              while (newDate.getMonth() != feeDate.getMonth()) {
                newDate = this.addDays(newDate, -1);
              }

              item.NewDate = this.formatShortDate(newDate);
            }
          }
        } else if (this.paymentDayOption == 4) {
          if (this.nextDayOfWeek != null) {
            let currentDay = new Date(item.NewDate).getDay();

            if (currentDay == this.nextDayOfWeek) {
              item.NewDate = this.addDays(feeItem.NewDate, 7);
            } else if (currentDay < this.nextDayOfWeek) {
              item.NewDate = this.addDays(
                item.NewDate,
                parseInt(this.nextDayOfWeek - currentDay)
              );
            } else {
              item.NewDate = this.addDays(
                item.NewDate,
                7 - parseInt(currentDay - this.nextDayOfWeek)
              );
            }
            item.NewDate = this.formatShortDate(item.NewDate);
          } else {
            this.$swal("Warning!", "Please select a day.", "warning");
          }
        } else if (this.paymentDayOption == 5) {
          if (this.prevDayOfWeek != null) {
            let currentDay = new Date(item.NewDate).getDay();

            if (currentDay == this.prevDayOfWeek) {
              item.NewDate = this.addDays(item.NewDate, -7);
            } else if (currentDay < this.prevDayOfWeek) {
              let diff = parseInt(this.prevDayOfWeek - currentDay);
              item.NewDate = this.addDays(
                item.NewDate,
                parseInt(7 - diff) * -1
              );
            } else {
              item.NewDate = this.addDays(
                item.NewDate,
                parseInt(currentDay - this.prevDayOfWeek) * -1
              );
            }
            item.NewDate = this.formatShortDate(item.NewDate);
          } else {
            this.$swal("Warning!", "Please select a day.", "warning");
          }
        }

        let feeMonths = parseInt(this.paymentMonthCount);
        if (this.paymentMonthSelected == true && feeMonths > 0) {
          item.NewDate = this.formatShortDate(
            this.addMonthsFix(new Date(item.NewDate), feeMonths)
          );
        }

        if (
          this.paymentAmount > 0 &&
          this.paymentAmount != item.PaymentAmount
        ) {
          item.NewPaymentAmount = this.paymentAmount;
        }

        if (this.paymentFee > 0 && this.paymentFee != item.FeeAmount) {
          item.NewFeeAmount = this.paymentFee;
        }
      }

      this.changesApplied = true;
      this.appliedList = deepCopy(this.paymentList);
      for (let item of this.paymentList.filter((x) => x.isEditing == false)) {
        this.updatePaymentData(item);
      }
      for (let item of this.addedPaymentList) {
        this.paymentList.unshift(item);
      }
      this.calculateCashFlowForBulkPayment();
    },
    feeGridCancelRow() {
      this.isFeeGridNewRowActive = false;
    },

    dayGridCancelRow() {
      this.dayGridFrom = null;
      this.dayGridTo = null;
      this.isDayGridNewRowActive = false;
    },
    addNewDayGridData() {
      this.isDayGridNewRowActive = true;
    },
    dayGridSave() {
      this.dayGridFrom = parseInt(this.dayGridFrom);
      this.dayGridTo = parseInt(this.dayGridTo);

      if (this.dayGridFrom > 0 && this.dayGridTo > 0) {
        let indx = this.dayGridData.findIndex(
          (x) => x.DayFrom == this.dayGridFrom
        );
        if (indx == -1) {
          let latestItem = _orderby.orderBy(this.dayGridData, "Id", "desc");
          let latestId =
            latestItem && latestItem.length > 0 ? latestItem[0].Id + 1 : 0;

          this.dayGridData.push({
            Id: latestId,
            DayFrom: this.dayGridFrom,
            DayTo: this.dayGridTo,
          });
          this.dayGridCancelRow();
        } else {
          this.$swal(
            "Warning!",
            "Another setting already exists in grid for this day.",
            "warning"
          );
        }
      } else {
        this.$swal("Warning!", "Day parameters invalid.", "warning");
      }
    },
    removeDayGridItem(id) {
      let indx = this.dayGridData.findIndex((x) => x.Id == id);
      if (indx > -1) {
        this.dayGridData.splice(indx, 1);
      }
    },
    changePaymentDayOption(value) {
      this.paymentDayOption = parseInt(value);
      this.editPaymentDaysChecked = true;
      if (this.paymentDayOption != 4) {
        this.nextDayOfWeek = null;
      }
      if (this.paymentDayOption != 5) {
        this.prevDayOfWeek = null;
      }
    },
    changeEditPaymentDays() {
      this.editPaymentDaysChecked = !this.editPaymentDaysChecked;
      if (this.editPaymentDaysChecked == false) {
        this.paymentDayOption = 0;
        this.dayGridData = [];
      }
    },
    changeMonthsSelect() {
      this.paymentMonthSelected = !this.paymentMonthSelected;
      if (this.paymentMonthSelected == false) {
        this.paymentMonthCount = 1;
      }
    },
    changePaymentAmountSelect() {
      this.paymentAmountSelected = !this.paymentAmountSelected;
      if (this.paymentAmountSelected == false) {
        this.paymentAmount = 0;
      }
    },

    changePaymentFeeSelect() {
      this.paymentFeeSelected = !this.paymentFeeSelected;
      if (this.paymentFeeSelected == false) {
        this.paymentFee = 0;
      }
    },

    changePaymentTypeSelect() {
      this.paymentTypeSelected = !this.paymentTypeSelected;
      if (this.paymentTypeSelected == false) {
        this.feeType = "";
      }
    },
    closePanel() {
      this.$emit("closePaymentRequestPopUp",false);
    },
  },
};
</script>

<style>
.closeButtonBulk {
  z-index: 1000;
  font-size: 30px;
  cursor: pointer;
}
.closeButtonBulk:hover {
  color: #ff3860;
}
.selectedDraft {
  color: #24d15f !important;
}
.editedDraft {
  color: #000 !important;
}
.miniTextBox {
  padding: 2px 4px !important;
  line-height: 13px !important;
  height: 28px !important;
}
</style>
