<template>
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 noPadding noMargin">
    <div id="ssnLabelPanel" class="ssnLabelPanel" v-show="!isEditing">
      <label class="form-label ssnLabel" for="ssn">
        {{ formatNumber(newVal) }}
      </label>
      <i
        style="color: #24d171"
        class="bx bx-edit-alt pencilIcon"
        @click="editNumber()"
      ></i>
    </div>

    <div class="ssnEditPanel noMargin" v-show="isEditing">
      <input
        type="text"
        id="ssnofAccountNumberController"
        v-model="newVal"
        class="form-control ssnTextBox generalTextBox"
        placeholder="Account Number"
        :class="v$.newVal.$error ? 'has-error' : ''"
        @blur="v$.newVal.$touch"
      />
      <input type="hidden" id="ssnHiddenOfAccountN" v-model="newVal" />
      <span id="commandButtons" class="commandButtons">
        <i
          class="ri-check-line text-success"
          @click="saveValue(v$.$invalid)"
          :disabled="v$.$invalid"
        ></i>
        <i
          class="ri-close-line text-danger"
          @click="cancelValue()"
          style="color: red"
        ></i>
      </span>
    </div>
  </div>
</template>
<script>
import utilitiesMixin from "@/mixins/utilitiesMixin";
import useValidate from "@vuelidate/core";
export default {
  name: "AccountNumberController",
  mixins: [utilitiesMixin],
  data() {
    return {
      v$: useValidate(),

      isEditing: true,
      invalid: false,
      oldVal: "",
      newVal: "",
      isAuthorizedForEdit: true,
    };
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    authId: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    value(newVal, oldVal) {
      if (newVal != oldVal) {
        if (this.value) this.setInitialValue();
      }
    },
  },
  methods: {
    editNumber() {
      if (!this.isAuthorizedForEdit) {
        this.newVal = "";
        this.invalid = true;
      }

      this.isEditing = true;
    },
    async saveValue(isInvalid) {
      if (isInvalid) return;
      this.isEditing = false;
      this.oldVal = this.newVal;

      this.$emit("numberChanged", this.newVal);

      if (!this.isAuthorizedForEdit) this.newVal = this.getLast4(this.newVal);
    },
    cancelValue() {
      if (this.value == null || this.value == "") return;
      this.newVal = this.oldVal;
      if (!this.isAuthorizedForEdit) this.newVal = this.getLast4(this.newVal);
      this.isEditing = false;
    },
    formatNumber(deg) {
      if (deg.length == 4) {
        let passChar = "";
        for (let i = 0; i < this.oldVal.length - 4; i++) passChar += "*";
        return passChar + deg;
      }
      let val = deg.replace(/\D/g, "");
      val = val.replace(/^(\d{3})/, "$1-");
      val = val.replace(/-(\d{2})/, "-$1-");
      val = val.replace(/(\d)-(\d{4}).*/, "$1-$2");
      return deg;
    },
    getLast4(deg) {
      if (deg.length <= 4) {
        return deg;
      }

      return deg.substring(deg.length - 4, deg.length);
    },
    checkAuth() {
      let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      let arrPermission = userInfo.moduleFunctions
        .split(",")
        .map(function (item) {
          return parseInt(item, 10);
        });

      if (!arrPermission.includes(this.authId)) {
        this.isAuthorizedForEdit = false;
      } else {
        this.isAuthorizedForEdit = true;
      }
    },
    async setInitialValue() {
      let decryptedVal = "";
      this.checkAuth();

      if (this.value && this.value != "") {
        decryptedVal = await this.AES256_GCM_decrypt(this.value);
      }

      if (decryptedVal != "") {
        this.invalid = false;
        this.isEditing = false;
        this.oldVal = decryptedVal;
        if (!this.isAuthorizedForEdit) {
          this.newVal = this.getLast4(decryptedVal);
        } else this.newVal = decryptedVal;
      } else {
        this.newVal = "";
        this.oldVal = "";
        this.invalid = true;
        this.isEditing = true;
      }
    },
  },
  async mounted() {
    if (this.value) this.setInitialValue();
  },
  validations() {
    return {
      newVal: {
        isValid(val) {
          return val.length > 0;
        },
      },
    };
  },
};
</script>
<style scoped>
.ssnLabelPanel {
  width: auto;
  float: left;
}
.ssnTextBox {
  width: 150px !important;
  float: left;
}
.commandButtons {
  font-size: 24px;
  float: left;
  margin: 3px;
  font-weight: bold;
  cursor: pointer;
}
.ssnLabel {
  width: auto;
  float: left;
  font-size: 16px;
  line-height: 30px;
}
.pencilIcon {
  font-size: 20px;
  margin: 3px 5px;
  color: #3bafda;
  cursor: pointer;
}
</style>
