<template>
  <div
    class="col-md-12 row"
    style="max-width: 100%; max-height: 750px; overflow: auto"
  >
    <div v-if="isLoading" class="loading">
      <div class="text-center" style="margin-top: 150px">
        <i class="spinner-grow text-info m-2"></i>
      </div>
    </div>
    <div
      class="col-md-12 pt-3"
      style="
        display: flex;
        justify-content: space-between;
        align-items: baseline;
      "
    >
      <label class="generalLabel">Franklin Attorney Metrics</label>
      <i
        v-show="selectedPrimaryAttorney"
        class="fe-refresh-cw refresh"
        @click="refreshData()"
      ></i>
    </div>
    <div class="col-md-12 noPadding">
      <div class="row">
        <div class="col-md-6" style="height: 620px">
          <div class="col-md-12 pb-1">
            <select
              v-model="attorney"
              class="form-select col-md-12"
              @change="updateAttorney"
            >
              <option :value="null">Please select</option>
              <option
                v-for="item in attorneyAndStateList"
                :key="item.AttorneyId"
                :value="item"
              >
                {{ getDropdownName(item) }}
              </option>
            </select>
          </div>
          <div
            style="
              max-height: 600px;
              overflow-y: auto;
              overflow-x: hidden;
              padding-right: 20px;
            "
          >
            <div
              class="pl-2 pr-2 pb-1 custom-container"
              style="
                width: 100%;
                box-shadow: rgba(187, 239, 253, 0.5) 0px 0px 19px -3px;
                margin: 10px;
                border-radius: 10px;
                border: 1px #dfdfdf solid;
              "
            >
              <h5 class="text-primary">Attorney State Statistics</h5>
              <ul class="list-group">
                <li class="d-flex justify-content-between align-items-center">
                  <h5>Enrolled Customers</h5>
                  <span class="badge bg-primary" style="font-size: 14px">{{
                    selectedPrimaryAttorney &&
                    selectedPrimaryAttorney.TotalActive
                      ? selectedPrimaryAttorney.TotalActive
                      : 0
                  }}</span>
                </li>
                <li class="d-flex justify-content-between align-items-center">
                  <h5>Customers With Summons</h5>
                  <span class="badge bg-primary" style="font-size: 14px">{{
                    selectedPrimaryAttorney &&
                    selectedPrimaryAttorney.CustomerWithSummonsCnt
                      ? selectedPrimaryAttorney.CustomerWithSummonsCnt
                      : 0
                  }}</span>
                </li>
                <li class="d-flex justify-content-between align-items-center">
                  <h5>Cancelled Customer This Month</h5>
                  <span class="badge bg-primary" style="font-size: 14px">{{
                    selectedPrimaryAttorney &&
                    selectedPrimaryAttorney.ThisMonthCancelledCnt
                      ? selectedPrimaryAttorney.ThisMonthCancelledCnt
                      : 0
                  }}</span>
                </li>
                <li class="d-flex justify-content-between align-items-center">
                  <h5>Total Unsettled Debt</h5>
                  <span class="badge bg-primary" style="font-size: 14px">{{
                    selectedPrimaryAttorney &&
                    selectedPrimaryAttorney.TotalUnsettledDebt
                      ? formatMoney(selectedPrimaryAttorney.TotalUnsettledDebt)
                      : "$0.00"
                  }}</span>
                </li>
              </ul>
            </div>

            <div
              class="pl-2 pr-2 pb-1 custom-container"
              style="
                width: 100%;
                box-shadow: rgba(187, 239, 253, 0.5) 0px 0px 19px -3px;
                margin: 10px;
                border-radius: 10px;
                border: 1px #dfdfdf solid;
              "
            >
              <h5 class="text-primary">Welcome Calls</h5>
              <ul class="list-group">
                <li class="d-flex justify-content-between align-items-center">
                  <h5>Completed Today</h5>
                  <span class="badge bg-primary" style="font-size: 14px">{{
                    selectedPrimaryAttorney &&
                    selectedPrimaryAttorney.WcCompletedToday
                      ? selectedPrimaryAttorney.WcCompletedToday
                      : 0
                  }}</span>
                </li>
                <li class="d-flex justify-content-between align-items-center">
                  <h5>Remaining Welcome Calls</h5>
                  <span class="badge bg-primary" style="font-size: 14px">{{
                    selectedPrimaryAttorney &&
                    selectedPrimaryAttorney.RemainingWcCount
                      ? selectedPrimaryAttorney.RemainingWcCount
                      : 0
                  }}</span>
                </li>
                <li class="d-flex justify-content-between align-items-center">
                  <h5>Last Call Attempt</h5>
                  <span
                    v-if="!selectedPrimaryAttorney"
                    class="badge bg-primary"
                    style="font-size: 14px"
                    >N/A</span
                  >
                  <span
                    v-if="
                      selectedPrimaryAttorney &&
                      selectedPrimaryAttorney.LastAttemptMade
                    "
                    class="badge bg-primary"
                    style="font-size: 14px"
                    >{{
                      selectedPrimaryAttorney.LastAttemptMade == "N/A"
                        ? "N/A"
                        : formatDate(selectedPrimaryAttorney.LastAttemptMade)
                    }}</span
                  >
                </li>
              </ul>
            </div>

            <div
              class="pl-2 pr-2 pb-1 custom-container"
              style="
                width: 100%;
                box-shadow: rgba(187, 239, 253, 0.5) 0px 0px 19px -3px;
                margin: 10px;
                border-radius: 10px;
                border: 1px #dfdfdf solid;
              "
            >
              <h5 class="text-primary">Attorney Settlement Approvals</h5>
              <ul class="list-group">
                <li class="d-flex justify-content-between align-items-center">
                  <h5>Pending Approvals</h5>
                  <span class="badge bg-primary" style="font-size: 14px">{{
                    selectedPrimaryAttorney &&
                    selectedPrimaryAttorney.AttrApprovalCnt
                      ? selectedPrimaryAttorney.AttrApprovalCnt
                      : 0
                  }}</span>
                </li>
                <li class="d-flex justify-content-between align-items-center">
                  <h5>Completed Approvals Today</h5>
                  <span class="badge bg-primary" style="font-size: 14px">{{
                    selectedPrimaryAttorney &&
                    selectedPrimaryAttorney.TodayAttrApprovalCnt
                      ? selectedPrimaryAttorney.TodayAttrApprovalCnt
                      : 0
                  }}</span>
                </li>
                <li class="d-flex justify-content-between align-items-center">
                  <h5>Completed Approvals This Month</h5>
                  <span class="badge bg-primary" style="font-size: 14px">{{
                    selectedPrimaryAttorney &&
                    selectedPrimaryAttorney.ThisMonthAttrApprovalCnt
                      ? selectedPrimaryAttorney.ThisMonthAttrApprovalCnt
                      : 0
                  }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="col-md-6" style="height: 620px">
          <div class="">
            <div
              v-if="isOverlayVisible"
              :class="['overlay', { disabled: attorney == null }]"
              @click="handleOverlayClick"
            >
              <i
                class="add-button mdi mdi-file-compare"
                :style="{
                  backgroundColor: attorney == null ? '#dbdbdb' : '#3baeda',
                  cursor: attorney == null ? 'not-allowed' : '',
                }"
              ></i>
              <label
                class="generalLabel"
                :style="{
                  color: attorney == null ? '#dbdbdb' : '#3baeda',
                }"
              >
                Add to compare
              </label>
            </div>
            <div class="attorneyWidget">
              <div class="col-md-12 pb-1">
                <select
                  v-model="attorney2"
                  class="form-select col-md-12"
                  @change="updateSecondAttorney()"
                >
                  <option :value="null">Please select</option>
                  <option
                    v-for="item in attorneyAndStateList"
                    :key="item.AttorneyId"
                    :value="item"
                  >
                    {{ getDropdownName(item) }}
                  </option>
                </select>
              </div>

              <div
                v-if="selectedSecondaryAttorney"
                style="
                  max-height: 600px;
                  overflow-y: auto;
                  overflow-x: hidden;
                  padding-right: 20px;
                "
              >
                <div
                  class="pl-2 pr-2 pb-1 custom-container"
                  style="
                    width: 100%;
                    box-shadow: rgba(187, 239, 253, 0.5) 0px 0px 19px -3px;
                    margin: 10px;
                    border-radius: 10px;
                    border: 1px #dfdfdf solid;
                  "
                >
                  <h5 class="text-primary">Attorney State Statistics</h5>
                  <ul class="list-group">
                    <li
                      class="d-flex justify-content-between align-items-center"
                    >
                      <h5>Enrolled Customers</h5>
                      <span class="badge bg-primary" style="font-size: 14px">{{
                        selectedSecondaryAttorney.TotalActive
                      }}</span>
                    </li>
                    <li
                      class="d-flex justify-content-between align-items-center"
                    >
                      <h5>Customers With Summons</h5>
                      <span class="badge bg-primary" style="font-size: 14px">{{
                        selectedSecondaryAttorney.CustomerWithSummonsCnt
                      }}</span>
                    </li>
                    <li
                      class="d-flex justify-content-between align-items-center"
                    >
                      <h5>Cancelled Customer This Month</h5>
                      <span class="badge bg-primary" style="font-size: 14px">{{
                        selectedSecondaryAttorney.ThisMonthCancelledCnt
                      }}</span>
                    </li>
                    <li
                      class="d-flex justify-content-between align-items-center"
                    >
                      <h5>Total Unsettled Debt</h5>
                      <span class="badge bg-primary" style="font-size: 14px">{{
                        formatMoney(
                          selectedSecondaryAttorney.TotalUnsettledDebt
                        )
                      }}</span>
                    </li>
                  </ul>
                </div>

                <div
                  class="pl-2 pr-2 pb-1 custom-container"
                  style="
                    width: 100%;
                    box-shadow: rgba(187, 239, 253, 0.5) 0px 0px 19px -3px;
                    margin: 10px;
                    border-radius: 10px;
                    border: 1px #dfdfdf solid;
                  "
                >
                  <h5 class="text-primary">Welcome Calls</h5>
                  <ul class="list-group">
                    <li
                      class="d-flex justify-content-between align-items-center"
                    >
                      <h5>Completed Today</h5>
                      <span class="badge bg-primary" style="font-size: 14px">{{
                        selectedSecondaryAttorney.WcCompletedToday
                      }}</span>
                    </li>
                    <li
                      class="d-flex justify-content-between align-items-center"
                    >
                      <h5>Remaining Welcome Calls</h5>
                      <span class="badge bg-primary" style="font-size: 14px">{{
                        selectedSecondaryAttorney.RemainingWcCount
                      }}</span>
                    </li>
                    <li
                      class="d-flex justify-content-between align-items-center"
                    >
                      <h5>Last Call Attempt</h5>
                      <span class="badge bg-primary" style="font-size: 14px">{{
                        selectedSecondaryAttorney.LastAttemptMade == "N/A"
                          ? "N/A"
                          : formatDate(
                              selectedSecondaryAttorney.LastAttemptMade
                            )
                      }}</span>
                    </li>
                  </ul>
                </div>

                <div
                  class="pl-2 pr-2 pb-1 custom-container"
                  style="
                    width: 100%;
                    box-shadow: rgba(187, 239, 253, 0.5) 0px 0px 19px -3px;
                    margin: 10px;
                    border-radius: 10px;
                    border: 1px #dfdfdf solid;
                  "
                >
                  <h5 class="text-primary">Attorney Settlement Approvals</h5>
                  <ul class="list-group">
                    <li
                      class="d-flex justify-content-between align-items-center"
                    >
                      <h5>Pending Approvals</h5>
                      <span class="badge bg-primary" style="font-size: 14px">{{
                        selectedSecondaryAttorney.AttrApprovalCnt
                      }}</span>
                    </li>
                    <li
                      class="d-flex justify-content-between align-items-center"
                    >
                      <h5>Completed Approvals Today</h5>
                      <span class="badge bg-primary" style="font-size: 14px">{{
                        selectedSecondaryAttorney.TodayAttrApprovalCnt
                      }}</span>
                    </li>
                    <li
                      class="d-flex justify-content-between align-items-center"
                    >
                      <h5>Completed Approvals This Month</h5>
                      <span class="badge bg-primary" style="font-size: 14px">{{
                        selectedSecondaryAttorney.ThisMonthAttrApprovalCnt
                      }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import globalTypes from "@/store/types";
export default {
  Name: "AttorneyActivityWidget",
  mixins: [formatMixin, utilitiesMixin],
  props: {
    widgetData: Object,
  },
  data() {
    return {
      isLoading: false,
      attorney: null,
      attorney2: null,
      isOverlayVisible: true,
      attorneyAndStateList: null,
      selectedPrimaryAttorney: null,
      selectedSecondaryAttorney: null,
      welcomeCallDispoId: 369,
      wcCompleteStatusId: 267,
      cancelledStatusIds: "83,257,343",
    };
  },
  watch: {
    attorney(newValue) {
      if (newValue == null) {
        this.selectedPrimaryAttorney = null;
        this.selectedSecondaryAttorney = null;
        this.attorney2 = null;
        this.isOverlayVisible = true;
      }
    },
    attorney2(newValue) {
      if (newValue == null) {
        this.selectedSecondaryAttorney = null;
      }
    },
  },
  methods: {
    getDropdownName(item) {
      let attorneyName = "";
      let stateName = "";

      if (item.AttorneyName) {
        attorneyName = item.AttorneyName;
      }
      if (item.StateName) {
        stateName = item.StateName;
      }
      return attorneyName + " - " + stateName;
    },

    async updateAttorney() {
      if (this.attorney && this.attorney.AttorneyId && this.attorney.StateId) {
        let payload = {
          AttorneyId: this.attorney.AttorneyId,
          StateId: this.attorney.StateId,
          WelcomeCallDispoId: this.welcomeCallDispoId,
          WcCompleteStatusId: this.wcCompleteStatusId,
          CancelledStatusIds: this.cancelledStatusIds,
          StartDate: new Date(),
        };
        this.isLoading = true;
        let err, result;
        [err, result] = await this.$store.dispatch(
          globalTypes.FLG_ENHANCED_TRACKING_DASHBOARD,
          payload
        );

        if (result && result.Data) {
          this.selectedPrimaryAttorney = result.Data;
        } else {
          let errMsg = this.getApiErrorMessage(err);
          this.$swal("Error!", errMsg, "error");
        }
        this.isLoading = false;
      }
    },
    async updateSecondAttorney() {
      if (
        this.attorney2 &&
        this.attorney2.AttorneyId &&
        this.attorney2.StateId
      ) {
        let payload = {
          AttorneyId: this.attorney2.AttorneyId,
          StateId: this.attorney2.StateId,
          WelcomeCallDispoId: this.welcomeCallDispoId,
          WcCompleteStatusId: this.wcCompleteStatusId,
          CancelledStatusIds: this.cancelledStatusIds,
          StartDate: new Date(),
        };
        this.isLoading = true;
        let err, result;
        [err, result] = await this.$store.dispatch(
          globalTypes.FLG_ENHANCED_TRACKING_DASHBOARD,
          payload
        );

        if (result && result.Data) {
          this.selectedSecondaryAttorney = result.Data;
        } else {
          let errMsg = this.getApiErrorMessage(err);
          this.$swal("Error!", errMsg, "error");
        }
        this.isLoading = false;
      }
    },

    refreshData() {
      if (this.selectedPrimaryAttorney && !this.selectedSecondaryAttorney) {
        this.updateAttorney();
      } else {
        this.updateAttorney();
        this.updateSecondAttorney();
      }
    },

    handleOverlayClick() {
      if (this.attorney != null) {
        this.removeOverlay();
      }
    },
    removeOverlay() {
      this.isOverlayVisible = false;
    },
    handleAttorneyData(data) {
      this.attorney = data;
    },
    async getAttorneyAndState() {
      let err, result;
      [err, result] = await this.$store.dispatch(
        globalTypes.GET_ATTORNEY_AND_STATE
      );
      if (result && result.Data) {
        this.attorneyAndStateList = result.Data;
      }
    },
  },
  async mounted() {
    await this.getAttorneyAndState();
  },
};
</script>

<style scoped>
.card {
  border-radius: 8px;
  background-color: #ffffff;
}

.list-group-item {
  font-size: 16px;
}

.form-select {
  border-radius: 6px;
  border: 1px solid #ced4da;
  padding: 8px;
}
.custom-container {
  white-space: normal;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  z-index: 99;
}

.attorneyWidget {
  position: relative;
  z-index: 1;
}
.add-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  color: white;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
</style>
