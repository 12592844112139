<template>
  <div class="row admin-page officePage">
    <div v-if="saving" class="loading">
      <div class="text-center" style="margin-top: 350px">
        <i class="spinner-grow text-info m-2"></i>
      </div>
    </div>
    <Placeholders
      :typeId="placeholderTypeId"
      @setPlaceholderVisibility="isPlaceholdersOpen = $event"
      @setPlaceholderContent="setPlaceholder($event)"
      v-if="isPlaceholdersOpen"
    ></Placeholders>
    <div class="col-xl-3 col-lg-4 col-md-12 col-sm-12 panelWrapper">
      <button
        type="button"
        class="col-md-12 btn btn-primary waves-effect waves-light"
        @click="handleAddDripEmail"
      >
        Add New Drip Email
      </button>
      <div class="filter form-group row">
        <div class="col-lg-12 col-md-12 col-sm-12 noPadding">
          <input
            autocomplete="off"
            type="text"
            class="form-control"
            v-model="dripEmailFilter"
            placeholder="Filter Drip Email"
          />
        </div>
      </div>
      <div class="list col-md-12">
        <div
          class="office item row"
          v-for="(email, index) in filteredDripEmails"
          :key="email.Id"
          :class="{ active: activeDripEmail === email.Id }"
          @click="() => handleSelection(email)"
          :disabled="saving"
          :style="[
            index % 2 === 0
              ? { 'background-color': '#F1EFEF' }
              : { 'background-color': '#FFFFFF' },
          ]"
        >
          <span :class="{ adminActiveMenuItem: activeDripEmail === email.Id }">
            <i class="ri-mail-send-fill"></i>
            <span style="margin-left: 8px">{{ email.Title }}</span>
          </span>
        </div>
      </div>
      <div
        v-if="isMobileDevice()"
        style="
          border-top: 1px solid rgb(0, 0, 0, 0);
          margin: 20px 0 0 9px;
          padding-bottom: 20px;
        "
        class="col-md-12 scrollToSelectedItem"
      ></div>
    </div>
    <div class="col-xl-9 col-lg-8 col-md-12 col-sm-12">
      <form
        v-on:keydown.enter.prevent="() => {}"
        v-if="isEditing"
        class="form-horizontal formWrapper panelWrapper float-left"
        role="form"
      >
        <div class="panelContent">
          <div class="form-group row">
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
              <label class="form-label" for="title">Title *</label>
            </div>
            <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12">
              <input
                autocomplete="off"
                type="text"
                id="DEtitle"
                class="form-control"
                v-model="selectedRow.Title"
                :class="v$.selectedRow.Title.$error ? 'has-error' : ''"
                @blur="v$.selectedRow.Title.$touch"
              />
            </div>
          </div>
          <div class="form-group row">
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
              <label class="form-label" for="copySettings"
                >Copy Settings From</label
              >
            </div>
            <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12">
              <select
                v-model="copySettings"
                id="DEcopySettings"
                class="form-control"
                @change="handleCopySettings()"
              >
                <option value="null">Please Select</option>
                <option
                  v-for="item in filteredDripEmails.filter(
                    (x) => x.Id != selectedRow.Id
                  )"
                  :key="item.Id"
                  :value="item.Id"
                >
                  {{ item.Title }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
              <label class="form-label" for="from">From</label>
            </div>
            <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12">
              <select
                v-model="selectedRow.From"
                id="DEfrom"
                class="form-control"
                :class="v$.selectedRow.From.$error ? 'has-error' : ''"
                @blur="v$.selectedRow.From.$touch"
              >
                <option value="0">Select Type</option>
                <option
                  v-for="item in fromEmailTypes"
                  :key="item.Id"
                  :value="item.Id"
                >
                  {{ item.Name }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
              <label class="form-label" for="to">To</label>
            </div>
            <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12">
              <input
                autocomplete="off"
                type="text"
                class="form-control"
                id="dripETo"
                v-model="selectedRow.To"
                placeholder="To"
              />
              <div
                style="
                  width: 32px;
                  height: 32px;
                  position: absolute;
                  top: 0;
                  right: -24px;
                "
              >
                <button
                  type="button"
                  v-on:click.prevent="() => openPlaceholders('To', 2)"
                  class="btn btn-primary fas fa-hashtag is-small cursor-pointer text-light rounded"
                  style="
                    position: absolute;
                    top: 3px;
                    z-index: 100;
                    background-color: rgb(52, 152, 219);
                  "
                ></button>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
              <label class="form-label" for="Cc">CC</label>
            </div>
            <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12">
              <input
                autocomplete="off"
                type="text"
                class="form-control"
                id="dripEcc"
                v-model="selectedRow.Cc"
                placeholder="CC"
              />
              <div
                style="
                  width: 32px;
                  height: 32px;
                  position: absolute;
                  top: 0;
                  right: -24px;
                "
              >
                <button
                  type="button"
                  v-on:click.prevent="() => openPlaceholders('CC', 2)"
                  class="btn btn-primary fas fa-hashtag is-small cursor-pointer text-light rounded"
                  style="
                    position: absolute;
                    top: 3px;
                    z-index: 100;
                    background-color: rgb(52, 152, 219);
                  "
                ></button>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
              <label class="form-label" for="Bcc">BCC</label>
            </div>
            <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12">
              <input
                autocomplete="off"
                type="text"
                class="form-control"
                id="dripEbcc"
                v-model="selectedRow.Bcc"
                placeholder="BCC"
              />
              <div
                style="
                  width: 32px;
                  height: 32px;
                  position: absolute;
                  top: 0;
                  right: -24px;
                "
              >
                <button
                  type="button"
                  v-on:click.prevent="() => openPlaceholders('Bcc', 2)"
                  class="btn btn-primary fas fa-hashtag is-small cursor-pointer text-light rounded"
                  style="
                    position: absolute;
                    top: 3px;
                    z-index: 100;
                    background-color: rgb(52, 152, 219);
                  "
                ></button>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
              <label class="form-label" for="Subject">Subject</label>
            </div>
            <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12">
              <input
                autocomplete="off"
                type="text"
                class="form-control"
                id="dripESubject"
                v-model="selectedRow.Subject"
                placeholder="Subject"
              />
              <div
                style="
                  width: 32px;
                  height: 32px;
                  position: absolute;
                  top: 0;
                  right: -24px;
                "
              >
                <button
                  type="button"
                  v-on:click.prevent="() => openPlaceholders('Subject', 1)"
                  class="btn btn-primary fas fa-hashtag is-small cursor-pointer text-light rounded"
                  style="
                    position: absolute;
                    top: 3px;
                    z-index: 100;
                    background-color: rgb(52, 152, 219);
                  "
                ></button>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
              <label class="form-label" for="office">Offices</label>
            </div>
            <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12">
              <VueMultiselect
                id="office"
                v-model="selectedRow.Offices"
                :options="filteredOffices"
                :multiple="true"
                :close-on-select="false"
                label="Name"
                track-by="Id"
                placeholder="Please Select"
                :class="v$.selectedRow.Offices.$error ? 'has-error' : ''"
                @blur="v$.selectedRow.Offices.$touch"
              />
              <label v-if="hasAccessOtherOffices" style="font-size: 12px"
                >Leave empty for all offices.</label
              >
              <label v-if="!hasAccessOtherOffices" style="font-size: 12px"
                >Office selection is mandatory</label
              >
            </div>
          </div>

          <div class="form-group row">
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
              <label class="form-label" for="policyGroups">Policy Groups</label>
            </div>
            <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12">
              <VueMultiselect
                id="policyGroups"
                v-model="selectedRow.PolicyGroups"
                :options="companyPolicyGroups"
                :multiple="true"
                :close-on-select="false"
                label="Name"
                track-by="PolicyGroupId"
                placeholder="Please Select"
              />
              <label style="font-size: 12px"
                >Leave empty for all policy groups.</label
              >
            </div>
          </div>

          <div class="form-group row">
            <div class="col-md-12 tabPosition-dem">
              <ul class="nav" style="margin: 0 !important">
                <li
                  v-for="tab in tabs.filter((x) => x.show)"
                  :key="tab.id"
                  class="nav-item tab-item"
                >
                  <a
                    data-toggle="tab"
                    :aria-expanded="tab.active"
                    v-on:click.prevent="() => handleTabChange(tab.name)"
                    class="tab-link"
                    :class="{ active: tab.active }"
                  >
                    <span class="d-sm-inline-block tab-item-label">
                      {{ tab.label }}
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div class="noMargin form-group row">
            <div
              v-if="tabs[0].active"
              class="bodyBorder editorWrapper col-xl-11 col-lg-12 col-md-12 col-sm-12 col-xs-12"
            >
              <div
                class="col-md-12 pl-1 form-check form-switch"
                style="padding: 10px"
              >
                <div class="form-check form-check-inline">
                  <input
                    @change="handleExternalHtmlChange(false)"
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    value="option1"
                    :checked="!selectedRow.IsExternalHtml"
                  />
                  <label class="form-check-label" for="inlineRadio1"
                    >Text Editor</label
                  >
                </div>
                <div class="form-check form-check-inline">
                  <input
                    @change="handleExternalHtmlChange(true)"
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    value="option2"
                    :checked="selectedRow.IsExternalHtml"
                  />
                  <label class="form-check-label" for="inlineRadio2"
                    >Import Template</label
                  >
                </div>
              </div>
              <div style="color: black">
                <vue-editor
                  v-if="!selectedRow.IsExternalHtml"
                  id="textEditorBody"
                  v-model="editorBody"
                >
                </vue-editor>

                <textarea
                  v-model="externalTemplateBody"
                  v-if="selectedRow.IsExternalHtml"
                  id="textEditor-qnm"
                  class="form-control mt-2 pt-1"
                  rows="12"
                ></textarea>
                <label
                  v-if="selectedRow.IsExternalHtml"
                  class="generalLabel mt-2"
                  >Preview</label
                >
                <div
                  v-if="externalTemplateBody.length > 0"
                  class="mt-2"
                  style="max-height: 500px; overflow: auto"
                  @keypress.prevent="() => {}"
                  @keydown.prevent="() => {}"
                  @keyup.prevent="() => {}"
                  @contextmenu.prevent="() => {}"
                >
                  <div
                    class="col-md-12"
                    style="
                      border: 1px solid #ccc;
                      padding: 30px;
                      pointer-events: none !important;
                    "
                    v-if="selectedRow.IsExternalHtml"
                    v-html="externalTemplateBody"
                  ></div>
                </div>
              </div>
              <div
                style="
                  width: 32px;
                  height: 32px;
                  position: absolute;
                  top: -5px;
                  right: -36px;
                "
              >
                <button
                  type="button"
                  @click="openPlaceholders('Body', 1)"
                  class="btn btn-primary fas fa-hashtag is-small cursor-pointer text-light rounded"
                  style="
                    position: absolute;
                    top: 3px;
                    z-index: 100;
                    background-color: rgb(52, 152, 219);
                  "
                ></button>
              </div>
            </div>

            <div
              v-if="tabs[1].active"
              class="bodyBorder editorWrapper col-xl-11 col-lg-12 col-md-12 col-sm-12 col-xs-12"
            >
              <div
                class="col-md-12 pl-1 form-check form-switch"
                style="padding: 10px"
              >
                <div class="form-check form-check-inline">
                  <input
                    @change="handleExternalHtmlSpanishChange(false)"
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    value="option1"
                    :checked="!selectedRow.IsExternalHtmlSpanish"
                  />
                  <label class="form-check-label" for="inlineRadio1"
                    >Text Editor</label
                  >
                </div>
                <div class="form-check form-check-inline">
                  <input
                    @change="handleExternalHtmlSpanishChange(true)"
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    value="option2"
                    :checked="selectedRow.IsExternalHtmlSpanish"
                  />
                  <label class="form-check-label" for="inlineRadio2"
                    >Import Template</label
                  >
                </div>
              </div>
              <div style="color: black">
                <vue-editor
                  v-if="!selectedRow.IsExternalHtmlSpanish"
                  id="textEditorBody"
                  v-model="editorBodySpanish"
                >
                </vue-editor>
                <textarea
                  v-if="selectedRow.IsExternalHtmlSpanish"
                  v-model="spanishExternalTemplateBody"
                  id="textEditor-qnm"
                  class="form-control mt-2 pt-1"
                  rows="12"
                ></textarea>
                <div
                  v-if="spanishExternalTemplateBody.length > 0"
                  class="mt-2 mb-2"
                >
                  <label
                    v-if="selectedRow.IsExternalHtmlSpanish"
                    class="generalLabel"
                    >Preview</label
                  >
                  <div
                    class="col-md-12"
                    style="border: 1px solid #ccc; padding: 30px"
                    v-if="selectedRow.IsExternalHtmlSpanish"
                    v-html="spanishExternalTemplateBody"
                  ></div>
                </div>
              </div>
              <div
                style="
                  width: 32px;
                  height: 32px;
                  position: absolute;
                  top: 0;
                  right: -36px;
                "
              >
                <button
                  type="button"
                  @click="openPlaceholders(1)"
                  class="btn btn-primary fas fa-hashtag is-small cursor-pointer text-light rounded"
                  style="
                    position: absolute;
                    top: 3px;
                    z-index: 100;
                    background-color: rgb(52, 152, 219);
                  "
                ></button>
              </div>
            </div>
          </div>
          <div v-if="selectedRow" class="form-group row">
            <div
              class="col-xl-11 col-lg-12 col-md-12 col-sm-12 col-xs-12"
              v-if="!isRefreshing"
            >
              <EmailTriggerGrid
                :value="selectedRow"
                @updateSelectedRow="updateSelectedRow"
              ></EmailTriggerGrid>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-xl-11 col-lg-12 col-md-12 col-sm-12">
              <button
                type="button"
                v-on:click.prevent="handleSave"
                :disabled="v$.$invalid || saving"
                class="animated-btn btn btn-success btn-bordered-success waves-effect width-md waves-light submit-button float-right"
              >
                <i v-if="saving" class="fas fa-spinner mr-1"></i>
                Save
              </button>
              <div style="width: 100px; float: right; margin: 20px 0">
                <div style="width: 20px; float: left; margin: 0 12px">
                  <input
                    autocomplete="off"
                    type="checkbox"
                    :id="selectedRow.Id"
                    class="form-control"
                    v-model="selectedRow.IsActive"
                  />
                </div>
                <label class="col-form-label">Active</label>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import api from "@/services/admin/api";
import globalTypes from "../../../store/types";
import placeholdersTypes from "@/components/Placeholders/types";
import Placeholders from "@/components/Placeholders/Placeholders";
import { mapState } from "vuex";
import types from "./types";
import deepCopy from "@/helpers/deepCopy";
import EmailTriggerGrid from "./EmailTriggerGrid";
import _orderby from "lodash";
import { VueEditor } from "vue3-editor";
import useValidate from "@vuelidate/core";
import { required } from "vuelidate/lib/validators";
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import VueMultiselect from "vue-multiselect";

const init = {
  Id: 0,
  Title: "",
  From: 0,
  To: "",
  Cc: "",
  Bcc: "",
  Subject: "",
  Body: "",
  SpanishContent: "",
  IsActive: true,
  Triggers: [],
  IsExternalHtml: false,
  IsExternalHtmlSpanish: false,
  Offices: null,
  PolicyGroups: null,
};

export default {
  name: "DripEmailManagement",
  components: { Placeholders, EmailTriggerGrid, VueEditor, VueMultiselect },
  data() {
    return {
      limitedPolicyGroups: [],
      hasAccessOtherOffices: false,
      hasAccessSubOffices: false,
      subOfficeIds: [],
      userOfficeId: 0,
      editorBody: "",
      editorBodySpanish: "",
      externalTemplateBody: "",
      spanishExternalTemplateBody: "",
      v$: useValidate(),
      selectedRow: deepCopy(init),
      isEditing: false,
      saving: false,
      isRefreshing: false,
      activeDripEmail: null,
      dripEmailFilter: "",
      isPlaceholdersOpen: false,
      placeholderTypeId: 1,
      oldValue: null,
      copySettings: "null",
      selectedInput: null,
      cursorPosition: 0,
      quill: null,
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        [{ list: "ordered" }, { list: "bullet" }],

        [{ color: [] }, { background: [] }],
      ],
      tabs: [
        {
          id: 1,
          name: "tab1",
          label: "Body",
          active: true,
          show: true,
        },
        {
          id: 2,
          name: "tab2",
          label: "Spanish Body",
          active: false,
          show: true,
        },
      ],
    };
  },
  mixins: [formatMixin, utilitiesMixin],
  async mounted() {
    await Promise.all([
      this.$store
        .dispatch(globalTypes.GET_DRIP_EMAIL_FROM_TYPE)
        .catch((err) => {}),
      this.$store
        .dispatch(placeholdersTypes.GET_PLACEHOLDERS)
        .catch((err) => {}),
      this.$store.dispatch(types.GET_DRIP_EMAILS).catch((err) => {}),
    ]);

    this.hasAccessOtherOffices = this.canSeeAllOffices();
    this.hasAccessSubOffices = this.canSeeSubOffices();

    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo != null) {
      this.subOfficeIds = userInfo.subOffices;
      this.userOfficeId = userInfo.officeId;
    }
    if (this.hasAccessOtherOffices == true) {
      await this.$store.dispatch(globalTypes.GET_ALL_POLICY_GROUPS);
    } else {
      let officeList = deepCopy(this.subOfficeIds);
      officeList.push(this.userOfficeId);

      let err, result;
      [err, result] = await this.$store.dispatch(
        globalTypes.GET_COMPANY_POLICY_GROUPS_BY_OFFICE,
        { OfficeIds: officeList }
      );

      if (result) {
        this.limitedPolicyGroups = result.Data;
      }
    }
  },

  computed: {
    ...mapState({
      dripEmailList: (state) => state.dripEmailManagement.dripEmailList,
      fromEmailTypes: (state) => state.globals.dripEmailFromTypes,
      officeList: (state) => state.globals.offices,
      policyGroups: (state) => state.globals.policyGroups,
    }),
    filteredOffices() {
      let rtn = [];
      if (this.hasAccessOtherOffices) {
        rtn = this.officeList;
      } else if (
        this.hasAccessSubOffices &&
        this.subOfficeIds &&
        this.subOfficeIds.length > 0
      ) {
        rtn = this.officeList.filter(
          (x) => x.Id == this.userOfficeId || this.subOfficeIds.includes(x.Id)
        );
      } else {
        rtn = this.officeList.filter((x) => x.Id == this.userOfficeId);
      }

      return rtn;
    },
    filteredDripEmails() {
      let emailList = [];

      if (this.hasAccessOtherOffices) {
        emailList = deepCopy(this.dripEmailList);
      } else if (
        this.hasAccessSubOffices &&
        this.subOfficeIds &&
        this.subOfficeIds.length > 0
      ) {
        emailList = this.dripEmailList.filter(
          (x) =>
            x.Offices &&
            x.Offices.length > 0 &&
            !x.Offices.some(
              (y) =>
                !this.subOfficeIds.includes(y.Id) && y.Id != this.userOfficeId
            )
        );
      } else {
        emailList = this.dripEmailList.filter(
          (x) =>
            x.Offices != null &&
            x.Offices.length > 0 &&
            !x.Offices.some((y) => y.Id != this.userOfficeId)
        );
      }

      return _orderby.orderBy(
        emailList.filter(
          (item) =>
            item.Title.toLowerCase().indexOf(
              this.dripEmailFilter.toLowerCase()
            ) > -1
        ),
        "Id"
      );
    },
    companyPolicyGroups() {
      let groups = this.hasAccessOtherOffices
        ? this.policyGroups
        : this.limitedPolicyGroups;
      return groups.map((item) => {
        return {
          PolicyGroupId: item.PolicyGroupId,
          Name: item.PolicyGroupId + " - " + item.Name,
        };
      });
    },
  },
  methods: {
    setBodyContent(item) {
      if (item.IsExternalHtml == true) {
        item.Body = this.externalTemplateBody;
      } else {
        item.Body = this.editorBody;
      }

      if (item.IsExternalHtmlSpanish == true) {
        item.SpanishContent = this.spanishExternalTemplateBody;
      } else {
        item.SpanishContent = this.editorBodySpanish;
      }
    },

    handleTabChange(tab) {
      let temp = [...this.tabs];
      temp = temp.map((item) => {
        item.active = item.name === tab ? true : false;
        return item;
      });
      this.tabs = [...temp];
    },
    handleExternalHtmlChange(val) {
      if (this.selectedRow) {
        this.selectedRow.IsExternalHtml = val;
      }
    },
    handleExternalHtmlSpanishChange(val) {
      if (this.selectedRow) {
        this.selectedRow.IsExternalHtmlSpanish = val;
      }
    },
    updateSelectedRow(event) {
      this.selectedRow.Triggers = deepCopy(event.Triggers);
    },
    async handleCopySettings() {
      if (this.copySettings != "null") {
        let indx = this.filteredDripEmails.findIndex(
          (x) => x.Id == this.copySettings
        );
        if (indx > -1) {
          let temp = deepCopy(this.filteredDripEmails[indx]);
          if (temp) {
            this.selectedRow.From = temp.From;
            this.selectedRow.To = temp.To;
            this.selectedRow.Cc = temp.Cc;
            this.selectedRow.Bcc = temp.Bcc;
            this.selectedRow.Subject = temp.Subject;
            this.selectedRow.Body = temp.Body;
            this.selectedRow.IsActive = temp.IsActive;
            this.selectedRow.Triggers = temp.Triggers;
            for (let i = 0; i < this.selectedRow.Triggers.length; i++) {
              this.selectedRow.Triggers[i].Id = -1;
            }
          }
        }
      } else {
        this.selectedRow = deepCopy(this.oldValue);
      }
      this.refreshTriggerGrid();
    },
    async handleSelection(item) {
      this.handleTabChange("tab1");
      this.setBodyContent(this.selectedRow);

      let cancelBeforeChanged = true;

      if (
        this.cleanHtml(JSON.stringify(this.selectedRow)) !=
          this.cleanHtml(JSON.stringify(this.oldValue)) &&
        this.oldValue != null
      ) {
        await this.$swal
          .fire({
            title: "You have unsaved changes",
            text: "You will lose it if you don't save",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, continue without saving!",
          })
          .then(async (dialog) => {
            if (dialog.value) {
              cancelBeforeChanged = true;
            } else {
              cancelBeforeChanged = false;
            }
          });
      }

      if (cancelBeforeChanged) {
        this.activeDripEmail = item.Id;

        this.selectedRow = deepCopy(item);

        this.oldValue = deepCopy(this.selectedRow);

        if (this.selectedRow.IsExternalHtml == true) {
          this.externalTemplateBody = this.selectedRow.Body;
          this.editorBody = "";
        } else {
          this.editorBody = this.selectedRow.Body;
          this.externalTemplateBody = "";
        }

        if (this.selectedRow.IsExternalHtmlSpanish == true) {
          this.spanishExternalTemplateBody = this.selectedRow.SpanishContent;
          this.editorBodySpanish = "";
        } else {
          this.editorBodySpanish = this.selectedRow.SpanishContent;
          this.spanishExternalTemplateBody = "";
        }

        this.isEditing = true;
        this.copySettings = "null";
      }
      this.refreshTriggerGrid();

      let el = this.$el.querySelector(".scrollToSelectedItem");
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
    async handleSave() {
      this.selectedRow.Body = this.selectedRow.IsExternalHtml
        ? this.externalTemplateBody
        : this.editorBody;
      if (this.selectedRow.IsExternalHtml) {
        this.editorBody = "";
      } else {
        this.externalTemplateBody = "";
      }

      this.selectedRow.SpanishContent = this.selectedRow.IsExternalHtmlSpanish
        ? this.spanishExternalTemplateBody
        : this.editorBodySpanish;
      if (this.selectedRow.IsExternalHtmlSpanish) {
        this.editorBodySpanish = "";
      } else {
        this.spanishExternalTemplateBody = "";
      }

      if (JSON.stringify(this.selectedRow) == JSON.stringify(this.oldValue)) {
        this.$swal.fire("Warning!", "No Changes Detected!", "warning");
        return;
      }

      if (this.selectedRow.Body.trim() == "") {
        this.$swal("Warning!", "Body is required!", "warning");
        return;
      }

      if (this.selectedRow.Offices) {
        this.selectedRow.Offices = this.selectedRow.Offices.map(
          (x) => x.Id
        ).join(",");
      }

      if (this.selectedRow.PolicyGroups) {
        this.selectedRow.PolicyGroups = this.selectedRow.PolicyGroups.map(
          (x) => x.PolicyGroupId
        ).join(",");
      }

      let type = types.PUT_DRIP_EMAIL;
      if (this.selectedRow.Id == 0) {
        type = types.POST_DRIP_EMAIL;
      }

      if (this.selectedRow.IsExternalHtml == null) {
        this.selectedRow.IsExternalHtml = false;
      }
      if (this.selectedRow.IsExternalHtmlSpanish == null) {
        this.selectedRow.IsExternalHtmlSpanish = false;
      }
      this.saving = true;
      let err, result;
      [err, result] = await this.$store.dispatch(type, this.selectedRow);

      if (result) {
        this.$swal.fire("Success!", result.Message, "success");

        this.selectedRow = deepCopy(result.Data);
        if (type == types.POST_DRIP_EMAIL) {
          this.dripEmailList.push(this.selectedRow);
        } else if (type == types.PUT_DRIP_EMAIL) {
          let indx = this.dripEmailList.findIndex(
            (x) => x.Id == this.selectedRow.Id
          );
          if (indx >= 0) {
            this.dripEmailList[indx] = deepCopy(this.selectedRow);
            this.dripEmailList.push();
          }
        }
        this.oldValue = deepCopy(this.selectedRow);

        await this.$store
          .dispatch(types.MODIFY_DRIP_EMAIL, this.dripEmailList)
          .catch((err) => {});
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
      this.saving = false;
    },

    async handleAddDripEmail() {
      let cancelBeforeChanged = true;
      if (
        this.cleanHtml(JSON.stringify(this.selectedRow)) !=
          this.cleanHtml(JSON.stringify(this.oldValue)) &&
        this.oldValue != null
      ) {
        await this.$swal
          .fire({
            title: "You have unsaved changes",
            text: "You will lose it if you don't save",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, continue without saving!",
          })
          .then(async (dialog) => {
            if (dialog.value) {
              cancelBeforeChanged = true;
            } else {
              cancelBeforeChanged = false;
            }
          });
      }
      if (cancelBeforeChanged) {
        this.selectedRow = { ...init };
        this.oldValue = { ...init };
        this.activeDripEmail = null;
        this.isEditing = true;
      }

      this.refreshTriggerGrid();
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async refreshTriggerGrid() {
      this.isRefreshing = true;
      await this.sleep(250);
      this.isRefreshing = false;
    },

    setPlaceholder(value) {
      navigator.clipboard.writeText(value);
    },
    openPlaceholders(vm, typeId) {
      this.isPlaceholdersOpen = true;
      this.selectedInput = vm;
      this.placeholderTypeId = typeId;
    },

    cleanHtml(text) {
      return text.replace(/[^\w\s]/gi, "").replace(/ /g, "");
    },

    onEditorReady(event) {
      this.quill = event;
    },
    setFocus(event) {
      this.quill = event;
    },
    selectionChange(event) {
      if (event && event.index) this.cursorPosition = event.index;
    },
    textChange(event) {
      if (event && event.ops) {
        if (event.ops.length > 0) this.cursorPosition = event.ops[0].retain;
      }
    },
  },
  validations() {
    return {
      selectedRow: {
        Title: { required },
        From: {
          isValue(from) {
            return from > 0;
          },
        },
        Offices: {
          hasValue(data) {
            return this.hasAccessOtherOffices
              ? true
              : data && data.length > 0
              ? true
              : false;
          },
        },
      },
    };
  },
};
</script>

<style>
.bodyBorder {
  border: 1px solid rgb(235, 235, 235);
  padding-bottom: 10px;
  border-radius: 5px;
  box-shadow: #fafafa 0px 0px 0px;
}

.tabPosition-dem {
  position: relative;
  top: 10px;
  left: 5px;
}
</style>
