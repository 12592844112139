<template>
  <div v-if="Series.length > 0" id="chart">
    <apexchart
      height="500"
      type="bar"
      :options="chartOptions"
      :series="Series"
    ></apexchart>
  </div>
</template>

<script>
export default {
  props: {
    widgetData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      Series: [],
      Categories: [],
      Colors: [],
    };
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          type: "bar",
          stacked: true,
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
                margin: 10,
              },
            },
          },
        ],
        xaxis: {
          categories: this.Categories,
        },
        legend: {
          position: "bottom",
          offsetY: 5,
        },
        fill: {
          opacity: 1,
          colors: this.Colors,
        },
        colors: this.Colors,
      };
    },
  },
  created() {
    let officeList = {};
    let charts = null;
    if (this.widgetData.name.toLowerCase() == "snf dashboard") {
      charts = this.widgetData.list.WeekByOffice;
    } else if (this.widgetData.name.toLowerCase() == "settlements dashboard") {
      charts = this.widgetData.list.WeeklyResult;
    }

    for (let value of charts) {
      for (let data of value.Data) {
        if (!officeList[data.OfficeName]) {
          officeList[data.OfficeName] = [];
        }
      }
    }
    let categories = [];
    for (let value of charts) {
      categories.push(value.Week);
      Object.keys(officeList).forEach((office) =>
        officeList[office].push(null)
      );

      for (let data of value.Data) {
        officeList[data.OfficeName][officeList[data.OfficeName].length - 1] =
          data.Count;

        if (!this.Colors.includes(data.ColorHex)) {
          this.Colors.push(data.ColorHex);
        }
      }
    }

    this.Categories = categories;
    this.Series = Object.entries(officeList).map(([name, data]) => {
      return { name, data };
    });
  },
};
</script>

<style></style>
