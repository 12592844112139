import types from "./types";
import api from "@/services/admin/api";
import to from "@/helpers/to";

export default {
  state: {
    creditorContactlist: [],
  },

  actions: {
    GET_USER_ACTIVITY_POA: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.getCreditorAndPoaReport(payload));
      return [err, result];
    },

    GET_POA_CREDITOR_CONTACT: async ({ commit }) => {
      let err, result;
      [err, result] = await to(api.getCreditorContact());
      if (result) {
        commit(types.SET_POA_CREDITOR_CONTACT, result.Data);
      }
    },

    UPDATE_POA_CREDITOR_CONTACT: async ({ commit }, payload) => {
      let result = await to(api.updateCreditorContactManagement(payload));
      return result;
    },

    INSERT_POA_CREDITOR_CONTACT: async ({ commit }, payload) => {
      let result = await to(api.insertCreditorContactManagement(payload));
      return result;
    },

    DELETE_POA_CREDITOR_CONTACT: async ({ commit }, payload) => {
      return await to(api.deleteCreditorContactManagement(payload));
    },
  },
  mutations: {
    SET_POA_CREDITOR_CONTACT: (state, payload) => {
      state.creditorContactlist = payload;
    },
  },
};
