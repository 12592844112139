<template>
  <div class="col-md-12">
    <ReportSummary
      :exportData="reportData"
      :reportName="'SettlementCandidatesReport'"
      :recordCount="recordCount"
      @updateLoadingStatus="reportLoading = $event"
      @setPayload="setPayload"
      @clearReport="clearReport"
      @setPagination="setPagination"
      :loadingSpinner="loadingSpinner"
    />

    <div
      class="datagrid-table gcsTabsTableScroll report-wrapper"
      :style="'max-height: ' + reportsPanelHeight + 'px; overflow-y:auto;'"
    >
      <o-table
        :data="filteredSettlementCandidatesReport"
        :paginated="
          isPaginated && filteredSettlementCandidatesReport.length > 0
        "
        :per-page="perPage"
        :pagination-simple="isPaginationSimple"
        :pagination-position="paginationPosition"
        :sticky-header="true"
      >
        <o-table-column
          field="CustomerId"
          label="Customer Id"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CustomerIdFilter"
                placeholder=""
                v-myMask="{
                  alias: 'numeric',
                  rightAlign: false,
                }"
              />
            </div>
          </template>

          <template v-slot="props">
            <span
              @click="
                viewCustomer(
                  props.row.CustomerId,
                  props.row.FirstName + ' ' + props.row.LastName
                )
              "
              :class="
                getTagReportHyperLinkClass(
                  props.row.CustomerId &&
                    props.row.FirstName &&
                    props.row.LastName
                )
              "
            >
              <strong v-if="props.row.IsSummary"
                >{{ props.row.CustomerId }}
              </strong>
              <span v-if="!props.row.IsSummary">
                {{ addPrefixToCustomerId(props.row.CustomerId) }}
              </span>
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="FirstName"
          label="First Name"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="FirstNameFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">{{ props.row.FirstName }} </template>
        </o-table-column>

        <o-table-column field="LastName" label="Last Name" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="LastNameFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">{{ props.row.LastName }} </template>
        </o-table-column>

        <o-table-column
          field="CustomerStatus"
          label="Customer Status"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CustomerStatusFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">{{ props.row.CustomerStatus }} </template>
        </o-table-column>

        <o-table-column
          field="EnrolledDate"
          label="Enrolled Date"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="EnrolledDateFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props"
            >{{ formatShortDate(props.row.EnrolledDate) }}
          </template>
        </o-table-column>

        <o-table-column field="Ssn" label="SSN" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="SsnFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">{{ props.row.Ssn }} </template>
        </o-table-column>
        <o-table-column field="CoSsn" label="Co SSN" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CoSsnFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">{{ props.row.CoSsn }} </template>
        </o-table-column>
        <o-table-column field="Office" label="Office" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="OfficeFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">{{ props.row.Office }} </template>
        </o-table-column>

        <o-table-column
          field="PolicyGroup"
          label="Policy Group"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="PolicyGroupFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">{{ props.row.PolicyGroup }} </template>
        </o-table-column>

        <o-table-column
          field="GlobalBalance"
          label="Global Balance"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="GlobalBalanceFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.GlobalBalance }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatMoney(props.row.GlobalBalance) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="CreditorName"
          label="Creditor Name"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CreditorNameFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.CreditorName }}
          </template>
        </o-table-column>

        <o-table-column
          field="CurrentCreditorName"
          label="Curr. Creditor Name"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CurrentCreditorNameFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props"
            >{{ props.row.CurrentCreditorName }}
          </template>
        </o-table-column>

        <o-table-column
          field="CreditorStatus"
          label="Creditor Status"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CreditorStatusFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.CreditorStatus }}
          </template>
        </o-table-column>

        <o-table-column
          field="CreditorLastPmtDate"
          label="Creditor Last Payment Date"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CreditorLastPmtDateFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ formatShortDate(props.row.CreditorLastPmtDate) }}
          </template>
        </o-table-column>

        <o-table-column
          field="NegotiationPhase"
          label="Negotiation Phase"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="NegotiationPhaseFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.NegotiationPhase }}
          </template>
        </o-table-column>

        <o-table-column
          field="AccountNumber"
          label="Account Number"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="AccountNumberFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.AccountNumber }}
          </template>
        </o-table-column>

        <o-table-column field="Balance" label="Balance" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="BalanceFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            <strong v-if="props.row.IsSummary">{{ props.row.Balance }} </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatMoney(props.row.Balance) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="CurrentCreditorBalance"
          label="Curr. Cr. Balance"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CurrentCreditorBalanceFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.CurrentCreditorBalance }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatMoney(props.row.CurrentCreditorBalance) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="BalancePerc"
          label="Balance Perc"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="BalancePercFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.BalancePerc }}
            <a v-if="props.row.BalancePerc.length != ''">%</a>
          </template>
        </o-table-column>

        <o-table-column
          field="OngoingSettlementsCount"
          label="Ongoing Settlements Count"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="OngoingSettlementsCountFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.OngoingSettlementsCount }}
          </template>
        </o-table-column>

        <o-table-column
          field=""
          label="View Customer"
          position="centered"
          v-slot="props"
        >
          <button
            @click="
              viewCustomer(
                props.row.CustomerId,
                props.row.FirstName + ' ' + props.row.LastName
              )
            "
            class="btn btn-info waves-effect waves-light ml-3"
            v-if="!props.row.IsSummary"
          >
            <i class="far fa-address-card"></i>
          </button>
        </o-table-column>
      </o-table>
      <section
        v-if="filteredSettlementCandidatesReport.length == 0"
        class="section"
        style="text-align: center"
      >
        <div class="content has-text-grey has-text-centered mb-4">
          <p class="noMargin">
            <i
              style="font-size: 24px; color: #939393"
              class="ri-question-line"
            ></i>
          </p>
          <p class="noMargin">Nothing here.</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import ReportSummary from "./ReportSummary.vue";
import utilitiesMixin from "@/mixins/utilitiesMixin";

export default {
  name: "NSFReport",
  components: { ReportSummary },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      customerPrefix: null,
      reportsPanelHeight: 300,
      loadingSpinner: false,
      isPaginationSimple: false,
      isPaginated: true,
      paginationPosition: "both",
      currentPage: 1,
      perPage: 200,
      recordCount: 0,
      reportLoading: false,
      reportData: [],

      settlementCandidatesReportInitSummary: {
        CustomerId: "",
        FirstName: "",
        LastName: "",
        GlobalBalance: "",
        CreditorName: "",
        AccountNumber: "",
        Balance: "",
        BalancePerc: "",
        OngoingSettlementsCount: "",
        Office: "",
        PolicyGroup: "",
        EnrolledDate: "",
        CreditorLastPmtDate: "",
        CustomerStatus: "",
        CurrentCreditorBalance: "",
        CurrentCreditorName: "",
      },

      CustomerIdFilter: "",
      FirstNameFilter: "",
      LastNameFilter: "",
      GlobalBalanceFilter: "",
      CreditorNameFilter: "",
      AccountNumberFilter: "",
      BalanceFilter: "",
      BalancePercFilter: "",
      OngoingSettlementsCountFilter: "",
      OfficeFilter: "",
      PolicyGroupFilter: "",
      SsnFilter: "",
      CoSsnFilter: "",
      NegotiationPhaseFilter: "",
      CreditorStatusFilter: "",
      EnrolledDateFilter: "",
      CreditorLastPmtDateFilter: "",
      CustomerStatusFilter: "",
      CurrentCreditorBalanceFilter: "",
      CurrentCreditorNameFilter: "",
    };
  },
  computed: mapState({
    settlementCandidatesList: (state) => state.reports.settlementCandidatesList,
    filteredSettlementCandidatesReport() {
      let rtn = [];

      let tmpList =
        this.settlementCandidatesList.length > 0
          ? this.settlementCandidatesList.filter((item) => {
              return (
                this.filterString(
                  item.CurrentCreditorBalance,
                  this.CurrentCreditorBalanceFilter
                ) &&
                this.filterString(
                  item.CurrentCreditorName,
                  this.CurrentCreditorNameFilter
                ) &&
                this.filterString(item.CustomerId, this.CustomerIdFilter) &&
                this.filterString(item.FirstName, this.FirstNameFilter) &&
                this.filterString(item.LastName, this.LastNameFilter) &&
                this.filterString(item.Ssn, this.SsnFilter) &&
                this.filterString(item.CoSsn, this.CoSsnFilter) &&
                this.filterString(
                  item.GlobalBalance,
                  this.GlobalBalanceFilter
                ) &&
                this.filterString(item.CreditorName, this.CreditorNameFilter) &&
                this.filterString(
                  item.CreditorStatus,
                  this.CreditorStatusFilter
                ) &&
                this.filterString(item.Office, this.OfficeFilter) &&
                this.filterString(item.PolicyGroup, this.PolicyGroupFilter) &&
                this.filterString(
                  item.NegotiationPhase,
                  this.NegotiationPhaseFilter
                ) &&
                this.filterString(
                  item.AccountNumber,
                  this.AccountNumberFilter
                ) &&
                this.filterString(item.Balance, this.BalanceFilter) &&
                this.filterString(item.BalancePerc, this.BalancePercFilter) &&
                this.filterString(
                  item.OngoingSettlementsCount,
                  this.OngoingSettlementsCountFilter
                ) &&
                new Date(item.EnrolledDate)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .indexOf(this.EnrolledDateFilter) > -1 &&
                new Date(item.CreditorLastPmtDate)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .indexOf(this.CreditorLastPmtDateFilter) > -1 &&
                this.filterString(
                  item.CustomerStatus,
                  this.CustomerStatusFilter
                )
              );
            })
          : [];

      if (tmpList.length == 0) {
        this.reportData = [];
        return rtn;
      }
      let totalCustomer = tmpList.length.toString();

      this.settlementCandidatesReportInitSummary.CustomerId =
        "Total: " + totalCustomer;
      this.recordCount = tmpList.length;
      this.settlementCandidatesReportInitSummary.IsSummary = true;

      rtn.push(...tmpList);
      rtn.push(this.settlementCandidatesReportInitSummary);
      this.reportData = rtn;

      return rtn;
    },
  }),
  async mounted() {
    this.reportsPanelHeight = this.setReportGridHeight(
      window.innerHeight,
      document.getElementById("reportsSummary")
    );

    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

    if (
      this.userInfo != null &&
      this.userInfo.customerIdPrefix != null &&
      this.userInfo.customerIdPrefix.length > 0
    ) {
      this.customerPrefix = this.userInfo.customerIdPrefix;
    }

    await this.sleep(1000);
    this.fixStickyHeaders();
  },
  methods: {
    addPrefixToCustomerId(customerId) {
      let rtn = customerId.toString();
      if (this.customerPrefix != null) {
        rtn = this.customerPrefix + rtn;
      }
      return rtn;
    },

    setPagination(data) {
      this.isPaginated = data;
    },
    async setPayload(value) {
      this.loadingSpinner = true;
      let err, result;
      [err, result] = await this.$store.dispatch(
        types.GET_SETTLEMENT_CANDIDATES_REPORT,
        value
      );
      if (result && result.Data) {
        let apiResult = result.Data;
        for (let item of apiResult) {
          if (item.Ssn != "") {
            item.Ssn = await this.AES256_GCM_decrypt(item.Ssn);
          }
          if (item.CoSsn != "") {
            item.CoSsn = await this.AES256_GCM_decrypt(item.CoSsn);
          }
        }
        await this.$store.dispatch(
          types.MODIFY_SETTLEMENT_CANDIDATES_REPORT,
          apiResult
        );
      }
      this.loadingSpinner = false;

      await this.sleep(500);
      this.fixStickyHeaders();
    },

    async viewCustomer(id, name) {
      this.$Emitter.emit("viewCustomer", {
        customerId: id,
        customerName: name,
      });
    },
    clearReport() {
      this.$store.dispatch(types.RESET_REPORTS);
      this.reportData = [];
    },
  },
};
</script>

<style scoped></style>
