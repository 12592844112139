<template>
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
    <div class="row">
      <div class="col-md-6 noPadding">
        <div class="col-md-12">
          <label
            style="font-size: 14px"
            class="generalLabel mt-1"
            for="authTypes"
            >Authorization Type:</label
          >
          <select
            id="authTypes"
            class="generalSelectBox"
            v-model="selectedAuthId"
            @change="getAuthDetails()"
          >
            <option value="0">Select an authorization type</option>
            <option
              v-for="item in authTypeList"
              :key="item.Id"
              :value="item.Id"
            >
              {{ item.Name }}
            </option>
          </select>
        </div>

        <div class="col-md-12" v-if="authData && authData.PortalInputGroups">
          <div
            v-for="group in authData.PortalInputGroups"
            :key="group.Id"
            :style="group.IsRepeatable ? 'background-color:#f9f9f9;' : ''"
            :class="
              group.IsRepeatable
                ? 'mt-2 datagrid-table pl-1 pr-1 pt-1 pb-1'
                : 'mt-2 mb-2'
            "
          >
            <div
              class="col-md-12 noPadding"
              v-if="selectedAuthId == 30 || selectedAuthId == 19"
            >
              <div class="col-md-12 noPadding">
                <input
                  class="ml-2"
                  style="width: 20px"
                  type="radio"
                  name="atdRadioGroup"
                  :value="1"
                  v-model="atdObj.selectedDebtOption"
                />
                <label class="generalLabel">Single Debit</label>
                <input
                  class="ml-2"
                  style="width: 20px"
                  type="radio"
                  name="atdRadioGroup"
                  :value="2"
                  v-model="atdObj.selectedDebtOption"
                />
                <label class="generalLabel">Recurring Debit</label>
                <input
                  class="ml-2"
                  style="width: 20px"
                  type="radio"
                  name="atdRadioGroup"
                  :value="3"
                  v-model="atdObj.selectedDebtOption"
                />
                <label class="generalLabel">Both</label>
              </div>
            </div>

            <div class="col-md-12">
              <div
                class="row"
                v-if="
                  atdObj.selectedDebtOption == 1 ||
                  atdObj.selectedDebtOption == 3
                "
              >
                <div class="col-md-6">
                  <label class="generalLabel">Amount of Single Debit</label>
                </div>
                <div class="col-md-6">
                  <input
                    class="form-control"
                    v-model="atdObj.singleDebit"
                    type="text"
                    placeholder="$0.00"
                    style="width: 160px"
                    v-myMask="{
                      alias: 'currency',
                      prefix: '$',
                      rightAlign: false,
                    }"
                  />
                </div>
              </div>
              <div
                class="row"
                v-if="
                  atdObj.selectedDebtOption == 1 ||
                  atdObj.selectedDebtOption == 3
                "
              >
                <div class="col-md-6">
                  <label class="generalLabel">Date</label>
                </div>
                <div class="col-md-6">
                  <DatePicker
                    mode="date"
                    v-model="atdObj.singleDebtDate"
                    :locale="'en-US'"
                    :model-config="modelConfig"
                    :popover="{ visibility: 'click' }"
                  >
                    <template v-slot="{ inputValue, inputEvents }">
                      <input
                        id="PA-selectDate"
                        class="form-control px-2 py-1 border rounded"
                        :value="inputValue"
                        v-on="inputEvents"
                        placeholder="Select Date"
                        style="width: 160px"
                      />
                    </template>
                  </DatePicker>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div
                class="row"
                v-if="
                  atdObj.selectedDebtOption == 2 ||
                  atdObj.selectedDebtOption == 3
                "
              >
                <div class="col-md-6">
                  <label class="generalLabel">Amount of Recurring Debit</label>
                </div>
                <div class="col-md-6">
                  <input
                    class="form-control"
                    v-model="atdObj.recurringDebit"
                    type="text"
                    placeholder="$0.00"
                    style="width: 160px"
                    v-myMask="{
                      alias: 'currency',
                      prefix: '$',
                      rightAlign: false,
                    }"
                  />
                </div>
              </div>
              <div
                class="row"
                v-if="
                  atdObj.selectedDebtOption == 2 ||
                  atdObj.selectedDebtOption == 3
                "
              >
                <div class="col-md-6">
                  <label class="generalLabel">Date</label>
                </div>
                <div class="col-md-6">
                  <DatePicker
                    mode="date"
                    v-model="atdObj.recurringDebtDate"
                    :locale="'en-US'"
                    :model-config="modelConfig"
                    :popover="{ visibility: 'click' }"
                  >
                    <template v-slot="{ inputValue, inputEvents }">
                      <input
                        id="PA-selectDate"
                        class="form-control px-2 py-1 border rounded"
                        :value="inputValue"
                        v-on="inputEvents"
                        placeholder="Select Date"
                        style="width: 160px"
                      />
                    </template>
                  </DatePicker>
                </div>
              </div>
            </div>

            <div class="col-md-12" v-if="group.Inputs && selectedAuthId != 30">
              <div v-for="item in group.Inputs" :key="item.Id">
                <div class="row">
                  <div class="col-md-4">
                    <label class="generalLabel">{{ item.Label }}</label>
                  </div>
                  <div class="col-md-8">
                    <input
                      v-if="isTextInput(item.InputType.Type)"
                      v-model="item.Value"
                      type="text"
                      class="form-control"
                    />
                    <input
                      v-if="isAmount(item.InputType.Type)"
                      v-model="item.Value"
                      class="form-control"
                      type="text"
                      placeholder="$0.00"
                      style="width: 120px"
                      v-myMask="{
                        alias: 'currency',
                        prefix: '$',
                        rightAlign: false,
                      }"
                    />
                    <input
                      v-if="isMonth(item.InputType.Type)"
                      v-model="item.Value"
                      class="form-control"
                      type="text"
                      placeholder=""
                      style="width: 80px"
                      v-myMask="{
                        alias: 'numeric',
                        prefix: '',
                        min: 1,
                        max: 12,
                        rightAlign: false,
                      }"
                    />
                    <input
                      v-if="isNumeric(item.InputType.Type)"
                      v-model="item.Value"
                      class="form-control"
                      type="text"
                      placeholder=""
                      style="width: 120px"
                      v-myMask="{
                        alias: 'numeric',
                        prefix: '',
                        min: 0,
                        rightAlign: false,
                      }"
                    />
                    <select
                      v-model="item.Value"
                      v-if="isSelectBox(item.InputType.Type)"
                      class="generalSelectBox"
                    >
                      <option
                        v-for="op in item.InputType.Options"
                        :key="op"
                        :value="op"
                      >
                        {{ op }}
                      </option>
                    </select>
                    <DatePicker
                      v-if="isDatepicker(item.InputType.Type)"
                      v-model="item.Value"
                      mode="date"
                      :locale="'en-US'"
                      :model-config="modelConfig"
                      :popover="{ visibility: 'click' }"
                    >
                      <template v-slot="{ inputValue, inputEvents }">
                        <input
                          id="PA-selectDate"
                          class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                          :value="inputValue"
                          v-on="inputEvents"
                          placeholder="Select Date"
                        />
                      </template>
                    </DatePicker>
                  </div>
                </div>
              </div>
              <div
                class="col-md-12 text-right noPadding"
                v-if="group.IsRepeatable"
              >
                <button @click="addRepeatableField(group)" class="btn btn-info">
                  Add Record
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 noPadding mt-4">
        <div
          class="col-md-12 datagrid-table"
          style="background-color: #f3f3f3"
          v-if="authData"
        >
          <div class="col-md-12 noPadding">
            <label style="font-size: 14px" class="generalLabel"
              >Current Authorization Values:</label
            >
          </div>
          <perfect-scrollbar style="max-height: 300px; padding: 10px">
            <div v-if="selectedAuthId == 30 || selectedAuthId == 19">
              <div
                v-if="
                  atdObj.selectedDebtOption == 1 ||
                  atdObj.selectedDebtOption == 3
                "
              >
                <div class="col-md-12">
                  <label class="generalLabel mr-2"
                    >Amount of Single Debit:</label
                  >
                  <label class="generalLabel">{{
                    formatMoney(atdObj.singleDebit)
                  }}</label>
                </div>

                <div class="col-md-12">
                  <label class="generalLabel mr-2">Date:</label>
                  <label class="generalLabel">{{
                    atdObj.singleDebtDate
                  }}</label>
                </div>
              </div>
              <div
                v-if="
                  atdObj.selectedDebtOption == 2 ||
                  atdObj.selectedDebtOption == 3
                "
              >
                <div class="col-md-12">
                  <label class="generalLabel mr-2"
                    >Amount of Recurring Debit:</label
                  >
                  <label class="generalLabel">{{
                    formatMoney(atdObj.recurringDebit)
                  }}</label>
                </div>

                <div class="col-md-12">
                  <label class="generalLabel mr-2">Date:</label>
                  <label class="generalLabel">{{
                    atdObj.recurringDebtDate
                  }}</label>
                </div>
              </div>
            </div>

            <div v-if="selectedAuthId != 30">
              <div v-if="noInputAuths.length > 0" class="col-md-12 noPadding">
                <div :key="item" v-for="item in noInputAuths" class="mt-2 mb-2">
                  - {{ item }}
                </div>
              </div>
              <div
                class="col-md-12 noPadding"
                v-if="authData && authData.PortalInputGroups"
              >
                <div
                  v-for="group in authData.PortalInputGroups.filter(
                    (x) => !x.IsRepeatable
                  )"
                  :key="group.Id"
                  :class="
                    group.IsRepeatable
                      ? 'mt-2 datagrid-table pl-1 pr-1 pt-1 pb-1'
                      : 'mt-2 mb-2'
                  "
                >
                  <div class="col-md-12" v-if="group.Inputs">
                    <div v-for="item in group.Inputs" :key="item.Id">
                      <div class="row">
                        <div class="col-md-12">
                          <label class="generalLabel"
                            >{{ item.Label }}: {{ item.Value }}</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 noPadding">
                <div
                  class="col-md-12"
                  v-for="item in payload.RepeatableTexts"
                  :key="item.Id"
                >
                  <span v-html="item.Value"></span>
                </div>
              </div>
            </div>
          </perfect-scrollbar>

          <div class="col-md-12" v-if="authData">
            <button
              @click="addAuth()"
              style="width: 100%"
              class="btn btn-success"
            >
              Add Authorization
            </button>
          </div>
          <div class="col-md-12 mb-2" v-if="authData">
            <button
              @click="cancelAuth()"
              style="width: 100%"
              class="btn btn-danger"
            >
              Cancel
            </button>
          </div>
        </div>
        <div
          class="col-md-12 mt-2 datagrid-table"
          style="background-color: #f3f3f3"
          v-if="authList.length > 0"
        >
          <div class="col-md-12 noPadding">
            <label style="font-size: 14px" class="generalLabel"
              >Message Preview:</label
            >
          </div>
          <perfect-scrollbar style="max-height: 300px; padding: 10px">
            <div class="col-md-12" v-if="authList.length > 0">
              <div v-for="(item, index) in authList" :key="item.Id">
                <span class="mr-1" @click="removeAuth(index)"
                  ><i
                    class="far fa-times-circle"
                    style="color: red; font-size: 15px; cursor: pointer"
                  >
                  </i>
                </span>
                <span v-html="item.Data"></span>
              </div>
            </div>
          </perfect-scrollbar>
          <div class="col-md-12" v-if="authList.length > 0">
            <button
              @click="sendPortalUserInterface()"
              style="width: 100%"
              class="btn btn-success"
            >
              Send to portal
            </button>
          </div>
          <div class="col-md-12" v-if="authList.length > 0">
            <button
              @click="discardAll()"
              style="width: 100%"
              class="btn btn-danger"
            >
              Discard all
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import types from "./types";
import { DatePicker } from "v-calendar";
import _orderby from "lodash";
import deepCopy from "@/helpers/deepCopy";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import formatMixin from "@/mixins/formatMixin";
export default {
  name: "PortalAuthorizations",
  mixins: [utilitiesMixin, formatMixin],
  props: {
    customer: {
      Type: Object,
      default: null,
    },
    customerId: {
      Type: Number,
      default: 0,
    },
    customerEmail: {
      Type: String,
      default: "",
    },
    attorneyName: {
      Type: String,
      default: "",
    },
    tabClicked: {
      Type: Boolean,
      Default: false,
    },
  },
  watch: {
    tabClicked(newVal, oldVal) {
      if (newVal != oldVal && newVal) {
        this.watcherRefresh();
      }
    },
  },
  components: { DatePicker },
  data() {
    return {
      atdObj: {
        selectedDebtOption: 1,
        singleDebit: 0,
        singleDebtDate: "",
        recurringDebit: 0,
        recurringDebtDate: "",
      },

      selectedAuthId: 0,
      authData: null,
      authList: [],
      noInputAuths: [],
      isAddingAuth: false,
      payload: {
        Id: 0,
        Inputs: [],
        RepeatableTexts: [],
      },
      repeatableTextList: [],
      authResult: "",
      modelConfig: {
        type: "string",
        mask: "MM/DD/YYYY",
      },
      authTypeList: [],
    };
  },
  unmounted() {
    this.$Emitter.off("resetAuthorizationTab");
  },
  async mounted() {
    this.$Emitter.on("resetAuthorizationTab", () => {
      this.selectedAuthId = 0;
      this.authData = null;
      this.authList = [];
      this.resetATDFields();
    });
  },
  methods: {
    resetATDFields() {
      this.atdObj.selectedDebtOption = 1;
      this.atdObj.singleDebit = 0;
      this.atdObj.singleDebtDate = "";
      this.atdObj.recurringDebit = 0;
      this.atdObj.recurringDebtDate = "";
    },
    async watcherRefresh() {
      if (this.authTypeList.length == 0) {
        let err, result;
        [err, result] = await this.$store.dispatch(types.GET_ALL_AUTH_TYPES);
        if (result && result.Data) {
          this.authTypeList = deepCopy(result.Data);
        }
      }
    },

    async sendPortalUserInterface() {
      this.$Emitter.emit("openPortalUserInterface", {
        CustomerId: this.customerId,
        CustomerEmail: this.customerEmail,
        AuthList: this.authList,
        IsPortalAuthMessage: true,
        AttorneyName: this.attorneyName,
      });
    },
    cancelAuth() {
      this.selectedAuthId = 0;
      this.authData = null;
      this.payload = {
        Id: 0,
        Inputs: [],
        RepeatableTexts: [],
      };
    },
    discardAll() {
      this.authData = null;
      this.selectedAuthId = 0;
      this.payload = {
        Id: 0,
        Inputs: [],
        RepeatableTexts: [],
      };
      this.authList = [];
    },
    removeAuth(index) {
      if (this.authList.length > 0 && index > -1) {
        this.authList.splice(index, 1);
      }
    },
    formatInputValue(value, type) {
      if (type == "Amount") {
        return "$" + value;
      } else return value;
    },
    async addAuth() {
      this.isAddingAuth = true;

      let atdExists = this.authList.filter((x) => x.TypeId == 30).length > 0;
      let ncatdExists = this.authList.filter((x) => x.TypeId == 19).length > 0;

      if (
        (atdExists && this.selectedAuthId == 19) ||
        (ncatdExists && this.selectedAuthId == 30)
      ) {
        this.$swal(
          "Warning",
          "Combining authorizations is not allowed.",
          "warning"
        );
        return;
      }

      if (this.selectedAuthId == 30) {
        if (this.atdObj) {
          let isValid = true;
          let singleDebitText = "";
          let recurringDebitText = "";
          if (this.atdObj.selectedDebtOption == 1) {
            isValid *=
              this.atdObj.singleDebit > 0 && this.atdObj.singleDebtDate != "";
          } else if (this.atdObj.selectedDebtOption == 2) {
            isValid *=
              this.atdObj.recurringDebit > 0 &&
              this.atdObj.recurringDebtDate != "";
          } else if (this.atdObj.selectedDebtOption == 3) {
            isValid *=
              this.atdObj.singleDebit > 0 &&
              this.atdObj.singleDebtDate != "" &&
              this.atdObj.recurringDebit > 0 &&
              this.atdObj.recurringDebtDate != "";
          }

          if (isValid) {
            singleDebitText = "Amount of Single Debit";
            singleDebitText +=
              "<p>" +
              this.formatMoney(this.atdObj.singleDebit) +
              " on or after " +
              this.formatShortDate(this.atdObj.singleDebtDate) +
              "</p>";
            recurringDebitText = "Amount of Recurring  Debit";
            recurringDebitText +=
              "<p>" +
              this.formatMoney(this.atdObj.recurringDebit) +
              " on or after " +
              this.formatShortDate(this.atdObj.recurringDebtDate) +
              " and thereafter until further notice" +
              "</p>";
            if (this.atdObj.selectedDebtOption == 1) {
              this.authList.push({
                TypeId: this.selectedAuthId,
                Data: singleDebitText,
              });
            } else if (this.atdObj.selectedDebtOption == 2) {
              this.authList.push({
                TypeId: this.selectedAuthId,
                Data: recurringDebitText,
              });
            } else if (this.atdObj.selectedDebtOption == 3) {
              let authText = singleDebitText + recurringDebitText;
              this.authList.push({
                TypeId: this.selectedAuthId,
                Data: authText,
              });
            }

            this.authData = null;
            this.selectedAuthId = 0;
            this.resetATDFields();
          } else {
            this.$swal("Warning!", "Please fill all fields.", "warning");
            return;
          }
        }
      } else {
        let nonRepeatableGroups = this.authData.PortalInputGroups.filter(
          (x) => !x.IsRepeatable
        );

        for (let i = 0; i < nonRepeatableGroups.length; i++) {
          for (let j = 0; j < nonRepeatableGroups[i].Inputs.length; j++) {
            this.payload.Inputs.push({
              Name: nonRepeatableGroups[i].Inputs[j].Label,
              Value: this.formatInputValue(
                nonRepeatableGroups[i].Inputs[j].Value,
                nonRepeatableGroups[i].Inputs[j].InputType.Type
              ),
            });
          }
        }
        let emptyInputs =
          this.payload.Inputs.length > 0
            ? this.payload.Inputs.filter((x) => x.Value == "")
            : [];

        if (emptyInputs.length > 0) {
          this.$swal("Warning!", "Please fill all fields.", "warning");
          this.payload.Inputs = [];
          return;
        }

        let repetableGroups = _orderby.groupBy(
          this.payload.RepeatableTexts,
          "Id"
        );

        let repeatableTextPayload = [];
        let grupedData = Object.values(repetableGroups);
        for (let k = 0; k < grupedData.length; k++) {
          let item = { Id: 0, Value: "" };
          item.Value =
            "<ul style='padding: 5px 30px !important; margin: 0 12px !important; list-style: lower-latin !important;'>";
          for (let m = 0; m < grupedData[k].length; m++) {
            item.Id = grupedData[k][m].Id;
            item.Value = item.Value + "<li>" + grupedData[k][m].Value + "</li>";
          }
          item.Value = item.Value + "</ul>";

          repeatableTextPayload.push(item);
        }

        if (repeatableTextPayload.length > 0)
          this.payload.RepeatableTexts = repeatableTextPayload;

        let debitOptionText = "";
        if (this.selectedAuthId == 19 && this.atdObj) {
          let isValid = true;
          let singleDebitText = "";
          let recurringDebitText = "";
          if (this.atdObj.selectedDebtOption == 1) {
            isValid *=
              this.atdObj.singleDebit > 0 && this.atdObj.singleDebtDate != "";
          } else if (this.atdObj.selectedDebtOption == 2) {
            isValid *=
              this.atdObj.recurringDebit > 0 &&
              this.atdObj.recurringDebtDate != "";
          } else if (this.atdObj.selectedDebtOption == 3) {
            isValid *=
              this.atdObj.singleDebit > 0 &&
              this.atdObj.singleDebtDate != "" &&
              this.atdObj.recurringDebit > 0 &&
              this.atdObj.recurringDebtDate != "";
          }

          if (isValid) {
            singleDebitText = "Amount of Single Debit";
            singleDebitText +=
              "<p>" +
              this.formatMoney(this.atdObj.singleDebit) +
              " on or after " +
              this.formatShortDate(this.atdObj.singleDebtDate) +
              "</p>";
            recurringDebitText = "Amount of Recurring  Debit";
            recurringDebitText +=
              "<p>" +
              this.formatMoney(this.atdObj.recurringDebit) +
              " on or after " +
              this.formatShortDate(this.atdObj.recurringDebtDate) +
              " and thereafter until further notice" +
              "</p>";
            if (this.atdObj.selectedDebtOption == 1) {
              debitOptionText = singleDebitText;
            } else if (this.atdObj.selectedDebtOption == 2) {
              debitOptionText = recurringDebitText;
            } else if (this.atdObj.selectedDebtOption == 3) {
              debitOptionText = singleDebitText + recurringDebitText;
            }
            this.payload.Inputs.push({ Name: "Draft Amount", Value: 0 });
            this.payload.Inputs.push({
              Name: "Draft Date",
              Value: new Date().toLocaleDateString(),
            });
          } else {
            this.$swal("Warning!", "Please fill all fields.", "warning");
            return;
          }
        }

        let err, result;
        this.payload.Id = this.authData.Id;
        [err, result] = await this.$store.dispatch(
          types.CREATE_AUTHORIZATION,
          this.payload
        );

        if (result) {
          this.authResult = result.Data;
          this.payload.RepeatableTexts = [];
          if (debitOptionText != "") {
            this.authList.push({
              TypeId: this.selectedAuthId,
              Data: debitOptionText + "<p>" + result.Data + "</p>",
            });
            this.resetATDFields();
          } else {
            this.$swal("Error!", "An error occured!", "error");
          }

          this.authData = null;
          this.selectedAuthId = 0;
        } else {
          this.isAddingAuth = false;
          let errMsg = this.getApiErrorMessage(err);
          this.$swal("Error!", errMsg, "error");
        }
      }
    },
    async addRepeatableField(item) {
      let indx = -1;
      if (this.authData && this.authData.DocumentRepeatableTexts) {
        indx = this.authData.DocumentRepeatableTexts.findIndex(
          (x) => x.Id == item.RepeatableTextId
        );
      }

      let replacePayload = {
        Id: item.RepeatableTextId,
        Inputs: [],
      };

      if (indx > -1) {
        for (let i = 0; i < item.Inputs.length; i++) {
          replacePayload.Inputs.push({
            Name: item.Inputs[i].Label,
            Value: this.formatInputValue(
              item.Inputs[i].Value,
              item.Inputs[i].InputType.Type
            ),
          });
          item.Inputs[i].Value = "";
        }
      }

      let err, result;

      [err, result] = await this.$store.dispatch(
        types.PROCESS_REPEATABLE_TEXTS,
        replacePayload
      );
      if (result) {
        this.payload.RepeatableTexts.push({
          Id: this.authData.DocumentRepeatableTexts[indx].Id,
          Value: result.Data,
        });
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
    },
    isTextInput(type) {
      return type == "Text";
    },
    isAmount(type) {
      return type == "Amount";
    },
    isMonth(type) {
      return type == "Month";
    },
    isNumeric(type) {
      return type == "Numeric";
    },
    isSelectBox(type) {
      let rtn = false;
      if (type == "ddlStatus" || type == "ddlPaymentStatus") {
        rtn = true;
      }

      return rtn;
    },
    isDatepicker(type) {
      return type == "Date";
    },
    async getAuthDetails() {
      if (this.selectedAuthId == 0) {
        this.authData = null;
        return;
      }

      let atdExists = this.authList.filter((x) => x.TypeId == 30).length > 0;
      let ncatdExists = this.authList.filter((x) => x.TypeId == 19).length > 0;

      if (
        (atdExists && this.selectedAuthId == 19) ||
        (ncatdExists && this.selectedAuthId == 30)
      ) {
        this.selectedAuthId == 0;
        this.$swal(
          "Warning",
          "Combining authorizations is not allowed.",
          "warning"
        );
        return;
      }

      this.resetATDFields();
      let err, result;

      [err, result] = await this.$store.dispatch(types.GET_AUTHORIZATION, {
        Id: this.selectedAuthId,
      });

      if (result) {
        this.authData = result.Data;

        if (
          this.selectedAuthId == 19 &&
          this.authData &&
          this.authData.PortalInputGroups &&
          this.authData.PortalInputGroups.length > 0
        ) {
          if (this.authData.PortalInputGroups[0].Inputs) {
            let excludedList = ["Draft Date", "Draft Amount"];
            this.authData.PortalInputGroups[0].Inputs =
              this.authData.PortalInputGroups[0].Inputs.filter(
                (x) => !excludedList.includes(x.Label)
              );
          }
        }

        if (
          this.authData.PortalInputGroups.length == 0 &&
          this.authData.DocumentRepeatableTexts.length == 0
        ) {
          let indx;
          indx = this.authTypeList.findIndex(
            (x) => x.Id == this.selectedAuthId
          );
          if (indx > -1) {
            this.noInputAuths = [];
            this.noInputAuths.push(this.authTypeList[indx].Name);
          }
        }
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
    },
  },
};
</script>
