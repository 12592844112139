<template>
  <div class="row admin-page officePage">
    <div class="col-xl-3 col-lg-4 col-md-12 col-sm-12 pt-2">
      <button
        class="col-xl-12 btn btn-primary waves-effect waves-light addTemplateButton"
        @click="handleAddTemplate()"
      >
        Add New Package
      </button>
      <div class="filter form-group row">
        <div class="col-lg-12 col-md-12 col-sm-12 noPadding">
          <input
            autocomplete="off"
            type="text"
            class="form-control"
            v-model="packageFilter"
            placeholder="Filter packages"
          />
        </div>
        <div class="list col-md-12">
          <div
            class="office item row"
            v-for="(item, index) in filteredPackages"
            :key="item.Id"
            :class="{ active: activeItem === item.Id }"
            :disabled="saving || deleting"
            @click="() => handleListSelection(item, index)"
            :style="[
              index % 2 === 0
                ? { 'background-color': '#F1EFEF' }
                : { 'background-color': '#FFFFFF' },
            ]"
          >
            <span :class="{ adminActiveMenuItem: activeItem === item.Id }">
              <i class="ri-file-ppt-2-fill"></i>
              <span style="margin-left: 8px">{{ item.Name }}</span>
            </span>
          </div>
        </div>
        <div
          v-if="isMobileDevice()"
          style="
            border-top: 1px solid rgb(0, 0, 0, 0);
            margin: 20px 0 0 9px;
            padding-bottom: 20px;
          "
          class="col-md-12 scrollToSelectedItem"
        ></div>
      </div>
    </div>
    <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12" v-if="isEditing">
      <div class="panelContent" style="padding-top: 0">
        <div class="row">
          <div class="col-md-12">
            <label for="dpm-name" class="form-label">Name:</label>
          </div>
          <div class="col-md-8">
            <input
              type="text"
              autocomplete="off"
              class="form-control"
              v-model="selectedRow.Name"
              id="dpm-name"
              placeholder="Name"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label for="dpm-emailSubject" class="form-label"
              >Email Subject:</label
            >
          </div>
          <div class="col-md-8">
            <input
              type="text"
              autocomplete="off"
              class="form-control"
              v-model="selectedRow.EmailSubject"
              id="dpm-emailSubject"
              placeholder="Email Subject"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label for="dpm-emailSubject" class="form-label">Templates:</label>
          </div>
          <div class="col-md-8">
            <VueMultiselect
              v-model="selectedRow.Templates"
              :options="activeTemplate"
              :multiple="true"
              :close-on-select="false"
              placeholder="Please Select"
              label="Name"
              track-by="Id"
              style="margin-top: 0 !important"
            />
          </div>
        </div>
        <div class="row mt-5">
          <div class="offset-xl-4 col-md-2">
            <button
              class="btn btn-success form-control"
              @click="handleSave()"
              :disabled="saving || deleting"
            >
              Save
            </button>
          </div>
          <div class="col-md-2">
            <button
              class="btn btn-danger form-control"
              v-if="selectedRow.Id"
              @click="() => handleDelete(selectedRow.Id)"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueMultiselect from "vue-multiselect";
import { mapState } from "vuex";
import types from "./types";
import deepCopy from "@/helpers/deepCopy";
import formatMixin from "@/mixins/formatMixin.js";
import _orderby from "lodash";
import utilitiesMixin from "@/mixins/utilitiesMixin";

const init = {
  Id: 0,
  Name: "",
  EmailSubject: "",
  Templates: [],
};

export default {
  name: "DocusignPackageManagement",
  mixins: [formatMixin, utilitiesMixin],
  components: {
    VueMultiselect,
  },
  data() {
    return {
      oldValue: JSON.stringify(init),
      selectedRow: deepCopy(init),
      valueToRevert: deepCopy(init),
      isCancelledBeforeSave: false,
      activeItem: null,
      isEditing: false,
      packageFilter: "",
      saving: false,
      deleting: false,
    };
  },
  methods: {
    async handleAddTemplate() {
      if (
        this.cleanHtml(this.oldValue) !=
          this.cleanHtml(JSON.stringify(this.selectedRow)) &&
        this.cleanHtml(JSON.stringify(this.selectedRow)) !=
          this.cleanHtml(JSON.stringify(init))
      ) {
        await this.$swal({
          title: "You have unsaved changes",
          text: "You will lose it if you don't save",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, continue without saving!",
        }).then(async (dialog) => {
          if (dialog.value) {
            this.isCancelledBeforeSave = true;
          } else {
            this.isCancelledBeforeSave = false;
            return;
          }
        });
      } else {
        this.selectedRow = deepCopy(init);
        this.activeItem = null;
        this.isEditing = true;
      }

      if (this.isCancelledBeforeSave) {
        this.selectedRow = deepCopy(this.valueToRevert);
        let indx = this.docusignPackagesList.findIndex(
          (x) => x.Id == this.valueToRevert.Id
        );
        this.docusignPackagesList[indx] = this.valueToRevert;
        this.docusignPackagesList.push();
        this.isCancelledBeforeSave = false;
        this.selectedRow = deepCopy(init);
        this.activeItem = null;
        this.isEditing = true;
      }
    },
    async handleListSelection(row, index) {
      if (
        this.cleanHtml(this.oldValue) !=
          this.cleanHtml(JSON.stringify(this.selectedRow)) &&
        this.cleanHtml(JSON.stringify(this.selectedRow)) !=
          this.cleanHtml(JSON.stringify(init))
      ) {
        await this.$swal({
          title: "You have unsaved changes",
          text: "You will lose it if you don't save",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, continue without saving!",
        }).then(async (dialog) => {
          if (dialog.value) {
            this.isCancelledBeforeSave = true;
          } else {
            this.isCancelledBeforeSave = false;
            return;
          }
        });
      } else {
        this.valueToRevert = deepCopy(row);
        this.oldValue = JSON.stringify(row);
        this.activeItem = row.Id;
        this.selectedRow = row;
        this.isCancelledBeforeSave = false;
      }
      this.isEditing = true;

      if (this.isCancelledBeforeSave) {
        this.selectedRow = deepCopy(this.valueToRevert);

        this.valueToRevert = deepCopy(row);
        this.oldValue = JSON.stringify(row);
        this.activeItem = row.Id;
        this.selectedRow = row;
        this.isCancelledBeforeSave = false;
      }

      let el = this.$el.querySelector(".scrollToSelectedItem");
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
    async handleSave(values) {
      if (this.oldValue == JSON.stringify(this.selectedRow)) {
        this.$swal("Warning!", "No Changes Detected!", "warning");
        return;
      }
      this.saving = true;

      let err, result, type;
      let isdocusignPackagesExists =
        this.docusignPackagesList.filter(
          (x) => x.Name.toLowerCase() == this.selectedRow.Name.toLowerCase()
        ).length > 0;

      if (this.selectedRow.Id != 0) {
        type = types.UPDATE_DOCUSIGN_PACKAGE;
      } else {
        type = types.INSERT_DOCUSIGN_PACKAGE;
      }
      if (isdocusignPackagesExists && type == types.INSERT_DOCUSIGN_PACKAGE) {
        this.saving = false;
        this.$swal(
          "Warning!",
          "This Template name is already exists in list!",
          "warning"
        );
        this.saving = false;
        return;
      }

      let templatesList = null;
      if (this.selectedRow.Templates) {
        templatesList = this.selectedRow.Templates.map((x) => x.Id).join(",");
      }

      [err, result] = await this.$store.dispatch(type, {
        ...this.selectedRow,
        Templates: templatesList,
      });
      // this.selectedRow.Templates = templatesList;
      if (result) {
        this.$swal("Success!", result.Message, "success");
        if (type == types.INSERT_DOCUSIGN_PACKAGE) {
          this.selectedRow.Id = result.Data.Id;

          this.docusignPackagesList.push(this.selectedRow);
          this.oldValue = JSON.stringify(this.selectedRow);
          this.activeItem = result.Data.Id;
        } else {
          this.oldValue = JSON.stringify(this.selectedRow);
        }
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
      this.saving = false;
    },
    async handleDelete(Id) {
      this.deleting = true;
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then(async (dialog) => {
          if (dialog.value) {
            let rowIndex = this.docusignPackagesList.indexOf(this.selectedRow);
            let err, result;
            [err, result] = await this.$store.dispatch(
              types.DELETE_DOCUSIGN_PACKAGE,
              { Id }
            );
            if (!err) {
              this.reset();
            }
            if (result) {
              this.docusignPackagesList.splice(rowIndex, 1);
              this.reset();

              this.$swal("Deleted!", result.Message, "success");
            } else {
              let errMsg = this.getApiErrorMessage(err);
              this.$swal("Error!", errMsg, "error");
            }
          }
        });
      this.deleting = false;
    },
    reset() {
      this.activeItem = null;
      this.selectedRow = deepCopy(init);
      this.isEditing = false;
    },
  },
  computed: mapState({
    docusignPackagesList: (state) =>
      state.docusignPackageManagement.docusignPackagesList,
    filteredPackages() {
      return this.docusignPackagesList.filter(
        (item) =>
          item.Name.toLowerCase().indexOf(this.packageFilter.toLowerCase()) > -1
      );
    },
    templateTypeList: (state) =>
      state.docusignPackageManagement.templateTypeList,

    activeTemplate() {
      return this.templateTypeList.filter((x) => x.IsActive);
    },
  }),
  async mounted() {
    await Promise.all([
      this.$store.dispatch(types.GET_DOCUSIGN_PACKAGE),
      this.$store.dispatch(types.GET_DOCUSIGN_TEMPLATE_TYPE),
    ]);
  },
};
</script>

<style></style>
