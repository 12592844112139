<template>
  <div class="row admin-page officePage">
    <div v-if="saving" class="loading">
      <div class="text-center" style="margin-top: 350px">
        <i class="spinner-grow text-info m-2"></i>
      </div>
    </div>
    <div class="col-xl-3 col-lg-4 col-md-12 col-sm-12 pt-2">
      <button
        class="col-xl-12 btn btn-primary waves-effect waves-light addTemplateButton"
        @click="handleAddItem"
        v-if="checkAuth(8913)"
      >
        Add New Task Action
      </button>
      <div class="col-md-12 mt-2 noPadding">
        <input
          autocomplete="off"
          type="checkbox"
          id="CTshowInactive"
          class="checkbox-input generalCheckBox"
          v-model="showInactiveItems"
          style="
            border-radius: 4px !important;
            border: solid 1px #d1d1d1 !important;
            vertical-align: text-top;
          "
        />

        <label class="generalLabel" style="font-weight: normal"
          >Show Inactive Actions</label
        >
      </div>
      <div class="filter form-group row">
        <div class="col-lg-12 col-md-12 col-sm-12 noPadding">
          <input
            autocomplete="off"
            id="nameFlt"
            type="text"
            class="form-control"
            v-model="nameFilter"
            placeholder="Filter Actions"
          />
        </div>
        <div class="list col-md-12">
          <div
            class="office item row"
            v-for="(item, index) in filteredActions"
            :key="item.Id"
            :class="{ active: activeItem === item.Id }"
            @click="() => handleListSelection(item, index)"
            :disabled="saving || deleting"
            :style="[
              index % 2 === 0
                ? { 'background-color': '#F1EFEF' }
                : { 'background-color': '#FFFFFF' },
            ]"
          >
            <span :class="{ adminActiveMenuItem: activeItem === item.Id }">
              <i class="ri-task-line"></i>
              <span style="margin-left: 8px">{{ item.Name }}</span>
            </span>
          </div>
        </div>
        <div
          v-if="isMobileDevice()"
          style="
            border-top: 1px solid rgb(0, 0, 0, 0);
            margin: 20px 0 0 9px;
            padding-bottom: 20px;
          "
          class="col-md-12 scrollToSelectedItem"
        ></div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-8 col-md-12 col-sm-12" v-if="isEditing">
      <div class="panelContent" style="padding-top: 0">
        <div
          v-if="!checkAuth(8913) && checkAuth(8914)"
          style="
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 200;
            background-color: white;
            opacity: 0.4;
          "
        ></div>
        <div class="row">
          <div class="col-md-12">
            <label for="Title" class="form-label">Name: *</label>
          </div>

          <div class="col-md-12">
            <input
              autocomplete="off"
              v-model="selectedRow.Name"
              class="form-control"
              id="nameInp"
              type="text"
              placeholder="Name"
              :class="v$.selectedRow.Name.$error ? 'has-error' : ''"
              @blur="v$.selectedRow.Name.$touch"
            />
          </div>
          <div class="col-md-12">
            <label for="ToStatus" class="form-label">To Status:</label>
          </div>
          <div class="col-md-12">
            <select
              id="toStatusDdl"
              class="form-control"
              v-model="selectedRow.ToStatusId"
            >
              <option v-for="item in statuses" :key="item.Id" :value="item.Id">
                {{ item.Name }}
              </option>
            </select>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-12">
            <input
              autocomplete="off"
              v-model="selectedRow.IsActive"
              type="checkbox"
              id="cbIsActive"
              class="checkbox-input generalCheckBox"
              style="
                border-radius: 4px !important;
                border: solid 1px #d1d1d1 !important;
              "
            />

            <label class="generalLabel" style="font-weight: normal"
              >Is Active</label
            >
          </div>
          <div class="col-md-12">
            <input
              autocomplete="off"
              v-model="selectedRow.RequiresNote"
              type="checkbox"
              id="cbRequiresNote"
              class="checkbox-input generalCheckBox"
              style="
                border-radius: 4px !important;
                border: solid 1px #d1d1d1 !important;
              "
            />

            <label class="generalLabel" style="font-weight: normal"
              >Requires Note</label
            >
          </div>
          <div class="col-md-12">
            <input
              v-model="selectedRow.RequiresSms"
              type="checkbox"
              id="cbRequireSms"
              class="checkbox-input generalCheckBox"
              style="
                border-radius: 4px !important;
                border: solid 1px #d1d1d1 !important;
              "
            />

            <label class="generalLabel" style="font-weight: normal"
              >Requires Sms</label
            >
          </div>
          <div class="col-md-12">
            <input
              v-model="selectedRow.RequiresDateChange"
              type="checkbox"
              id="cbRequiresDateChange"
              class="checkbox-input generalCheckBox"
              style="
                border-radius: 4px !important;
                border: solid 1px #d1d1d1 !important;
              "
            />

            <label class="generalLabel" style="font-weight: normal"
              >Requires Date Change</label
            >
          </div>
          <div class="col-md-12">
            <input
              v-model="selectedRow.AutoAddsDraft"
              type="checkbox"
              id="CSRTautoAddsDraft"
              class="checkbox-input generalCheckBox"
              style="
                border-radius: 4px !important;
                border: solid 1px #d1d1d1 !important;
              "
            />

            <label class="generalLabel" style="font-weight: normal"
              >Auto NSF re-draft at the end of the program</label
            >
          </div>
          <div class="col-md-12">
            <input
              v-model="selectedRow.OverrideSubtaskCreation"
              type="checkbox"
              class="checkbox-input generalCheckBox"
              style="
                border-radius: 4px !important;
                border: solid 1px #d1d1d1 !important;
              "
            />

            <label class="generalLabel" style="font-weight: normal"
              >Override Subtask creation</label
            >
          </div>
        </div>
        <div class="row mt-2">
          <div
            class="offset-xl-9 offset-lg-4 col-xl-3 col-lg-4 col-md-6 col-xs-12"
          >
            <button
              class="btn btn-success form-control"
              @click="handleSave"
              :disabled="v$.$invalid || saving || deleting"
            >
              Save
            </button>
          </div>
          <div class="col-xl-3 col-lg-4 col-md-6 col-xs-12"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import globalTypes from "@/store/types";
import { mapState } from "vuex";
import types from "./types";
import deepCopy from "@/helpers/deepCopy";
import useValidate from "@vuelidate/core";
import formatMixin from "@/mixins/formatMixin";
import { required } from "vuelidate/lib/validators";
import utilitiesMixin from "@/mixins/utilitiesMixin";

const init = {
  Id: 0,
  Name: "",
  ToStatusId: 1,
  IsActive: true,
  RequiresNote: false,
  RequiresSms: false,
  RequiresDateChange: false,
  AutoAddsDraft: false,
  OverrideSubtaskCreation:false
};
export default {
  name: "CSRTaskActionManagement",

  data() {
    return {
      v$: useValidate(),
      saving: false,
      deleting: false,
      isEditing: false,
      activeItem: null,
      nameFilter: "",
      selectedRow: deepCopy(init),
      oldValue: "",
      valueToRevert: deepCopy(init),
      isCancelledBeforeSave: false,
      showInactiveItems: false,
    };
  },
  mixins: [formatMixin, utilitiesMixin],
  async mounted() {
    await Promise.all([
      this.$store.dispatch(globalTypes.GET_CSRTASKSTATUS),
      this.$store.dispatch(types.GET_TASK_ACTIONS),
    ]);
  },

  computed: mapState({
    statuses: (state) => state.globals.csrTaskStatus,
    list: (state) => state.csrTaskActionManagement.list,
    filteredActions() {
      return this.list.filter((item) => {
        let rtn =
          item.Name.toLowerCase().indexOf(this.nameFilter.toLowerCase()) > -1 &&
          ((!this.showInactiveItems && item.IsActive) ||
            this.showInactiveItems);

        return rtn;
      });
    },
  }),

  methods: {
    async handleAddItem() {
      if (
        this.oldValue != JSON.stringify(this.selectedRow) &&
        JSON.stringify(this.selectedRow) != JSON.stringify(init)
      ) {
        await this.$swal({
          title: "You have unsaved changes",
          text: "You will lose it if you don't save",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, continue without saving!",
        }).then(async (dialog) => {
          if (dialog.value) {
            this.isCancelledBeforeSave = true;
          } else {
            this.isCancelledBeforeSave = false;
            return;
          }
        });
      } else {
        this.selectedRow = deepCopy(init);
        this.activeItem = null;
        this.isEditing = true;
      }

      if (this.isCancelledBeforeSave) {
        this.selectedRow = deepCopy(this.valueToRevert);

        let indx = this.list.findIndex((x) => x.Id == this.valueToRevert.Id);
        this.list[indx] = this.valueToRevert;
        this.list.push();

        this.isCancelledBeforeSave = false;
        this.selectedRow = deepCopy(init);
        this.activeItem = null;
        this.isEditing = true;
      }
    },
    async handleListSelection(row, index) {
      if (
        this.oldValue != JSON.stringify(this.selectedRow) &&
        JSON.stringify(this.selectedRow) != JSON.stringify(init)
      ) {
        await this.$swal({
          title: "You have unsaved changes",
          text: "You will lose it if you don't save",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, continue without saving!",
        }).then(async (dialog) => {
          if (dialog.value) {
            this.isCancelledBeforeSave = true;
          } else {
            this.isCancelledBeforeSave = false;
            return;
          }
        });
      } else {
        this.valueToRevert = deepCopy(row);
        this.oldValue = JSON.stringify(row);
        this.activeItem = row.Id;

        this.selectedRow = row;
        this.isCancelledBeforeSave = false;
      }
      this.isEditing = true;
      //document.getElementsByClassName("ql-editor")[0].focus();
      if (this.isCancelledBeforeSave) {
        this.selectedRow = deepCopy(this.valueToRevert);

        let indx = this.list.findIndex((x) => x.Id == this.valueToRevert.Id);
        this.list[indx] = this.valueToRevert;
        this.list.push();

        this.valueToRevert = deepCopy(row);

        this.oldValue = JSON.stringify(row);
        this.activeItem = row.Id;

        this.selectedRow = row;
        this.isCancelledBeforeSave = false;
      }
      let el = this.$el.querySelector(".scrollToSelectedItem");
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
    async handleSave(values) {
      if (!this.checkAuth(8913) && this.checkAuth(8914)) {
        this.$swal(
          "Warning!",
          "You are not authorized to this action!",
          "warning"
        );
        return;
      }

      if (this.oldValue == JSON.stringify(this.selectedRow)) {
        this.$swal("Warning!", "No Changes Detected!", "warning");
        return;
      }
      this.saving = true;

      let err, result, type;

      let isNameExists =
        this.list.filter(
          (x) => x.Name.toLowerCase() == this.selectedRow.Name.toLowerCase()
        ).length > 0;

      if (this.selectedRow.Id != 0) {
        type = types.UPDATE_TASK_ACTION;

        isNameExists =
          this.list.filter(
            (x) => x.Name.toLowerCase() == this.selectedRow.Name.toLowerCase()
          ).length > 1;
      } else {
        type = types.INSERT_TASK_ACTION;
      }
      if (isNameExists && type == types.INSERT_TASK_ACTION) {
        this.saving = false;
        this.$swal(
          "Warning!",
          "This Task Action name is already exists in list!",
          "warning"
        );
        this.saving = false;
        return;
      }

      [err, result] = await this.$store.dispatch(type, this.selectedRow);

      if (result) {
        this.$swal("Success!", result.Message, "success");
        if (type == types.INSERT_TASK_ACTION) {
          this.selectedRow.Id = result.Data.Id;
          this.list.push(this.selectedRow);
          this.oldValue = JSON.stringify(this.selectedRow);
        } else {
          this.oldValue = JSON.stringify(this.selectedRow);
        }

        //this.reset();
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
      this.saving = false;
    },
  },
  validations() {
    return {
      selectedRow: {
        Name: { required },
      },
    };
  },
};
</script>

<style>
.has-error {
  border: 2px solid red !important;
}
</style>
