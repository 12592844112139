<template>
  <div
    class="col-md-12 newPanelWrapper noMargin smp-mediaScreen"
    style="
      background-color: #ffffff;
      z-index: 1000;
      padding-left: 0;
      padding-right: 0;
    "
  >
    <div class="superMoneyPopUpHeader">
      <label style="font-size: 15px">Global Meridian Loan</label>
    </div>
    <div>
      <div class="closeButton" v-on:click.prevent.stop="closePanel">
        <i class="fas fa-window-close"></i>
      </div>
    </div>

    <perfect-scrollbar
      style="width: 100%; max-height: 600px; overflow-x: hidden !important"
    >
      <div class="col-md-12 row">
        <div class="col-md-4">
          <label class="generalLabel">First Name</label>
          <label class="form-control" style="background: #f1efef">{{
            globalMeridiansObj.first_name
          }}</label>
        </div>

        <div class="col-md-4">
          <label class="generalLabel">Last Name</label>
          <label class="form-control" style="background: #f1efef">{{
            globalMeridiansObj.last_name
          }}</label>
        </div>

        <div class="col-md-4">
          <label class="generalLabel">Email</label>
          <label class="form-control" style="background: #f1efef">{{
            globalMeridiansObj.email
          }}</label>
        </div>

        <div class="col-md-4">
          <label class="generalLabel">Date of Birth</label>
          <label class="form-control" style="background: #f1efef">{{
            globalMeridiansObj.birth_date
          }}</label>
        </div>

        <div class="col-md-4">
          <label class="generalLabel">Ssn</label>
          <label class="form-control" style="background: #f1efef">{{
            globalMeridiansObj.ssn_number
          }}</label>
        </div>

        <div class="col-md-4">
          <label class="generalLabel">Annual Gross Income </label>
          <label class="form-control" style="background: #f1efef">{{
            formatMoney(globalMeridiansObj.annual_gross_income)
          }}</label>
        </div>

        <div class="col-md-4">
          <label class="generalLabel">Phone</label>
          <label class="form-control" style="background: #f1efef">{{
            formatPhone(globalMeridiansObj.mobile)
          }}</label>
        </div>

        <div class="col-md-4">
          <label class="generalLabel">Adress</label>
          <label class="form-control" style="background: #f1efef">{{
            globalMeridiansObj.address
          }}</label>
        </div>

        <div class="col-md-4">
          <label class="generalLabel">City</label>
          <label class="form-control" style="background: #f1efef">{{
            globalMeridiansObj.city
          }}</label>
        </div>

        <div class="col-md-4">
          <label class="generalLabel">State</label>
          <label class="form-control" style="background: #f1efef">{{
            globalMeridiansObj.state
          }}</label>
        </div>

        <div class="col-md-4">
          <label class="generalLabel">Zip Code</label>
          <label class="form-control" style="background: #f1efef">{{
            globalMeridiansObj.zip_code
          }}</label>
        </div>

        <div class="col-md-4">
          <label class="generalLabel">Loan Purpose</label>
          <select
            v-model="globalMeridiansObj.loan_purpose"
            class="form-control"
            :class="
              v$.globalMeridiansObj.loan_purpose.$error ? 'has-error' : ''
            "
            @blur="v$.globalMeridiansObj.loan_purpose.$touch"
          >
            <option :value="null">Please select</option>
            <option :value="1">Auto</option>
            <option :value="2">Baby</option>
            <option :value="3">Boat</option>
            <option :value="4">Business</option>
            <option :value="5">Car Repair</option>
            <option :value="6">Cosmetic</option>
            <option :value="7">Credit Card Refi</option>
            <option :value="8">Debt Consolidation</option>
            <option :value="9">Emergency</option>
            <option :value="10">Engagement</option>
            <option :value="11">Green</option>
            <option :value="12">Home Improvement</option>
            <option :value="13">Home Purchase</option>
            <option :value="14">Home Refi</option>
            <option :value="15">Household Expenses</option>
            <option :value="16">Large Purchases</option>
            <option :value="17">Life Event</option>
            <option :value="18">Medical Dental</option>
            <option :value="19">Motorcycle</option>
            <option :value="20">Moving Relocation</option>
            <option :value="21">RV</option>
            <option :value="22">Student Loan</option>
            <option :value="23">Student Loan Refi</option>
            <option :value="24">Taxes</option>
            <option :value="25">Vacation</option>
            <option :value="26">Wedding</option>
            <option :value="27">Other</option>
          </select>
        </div>

        <div class="col-md-4">
          <label class="generalLabel">Loan Amount</label>
          <label class="form-control" style="background: #f1efef">{{
            formatMoney(globalMeridiansObj.loan_amount)
          }}</label>
        </div>
        <div class="col-md-4">
          <label class="generalLabel">Education Level</label>
          <select
            v-model="globalMeridiansObj.education_level"
            class="form-control"
            :class="
              v$.globalMeridiansObj.education_level.$error ? 'has-error' : ''
            "
            @blur="v$.globalMeridiansObj.education_level.$touch"
          >
            <option :value="null">Please select</option>
            <option :value="1">High School</option>
            <option :value="2">Associate</option>
            <option :value="3">Masters</option>
            <option :value="4">Doctorate</option>
            <option :value="5">Other Grad Degree</option>
            <option :value="6">Other</option>
            <option :value="7">Unknown</option>
          </select>
        </div>

        <div class="col-md-4">
          <label class="generalLabel">Graduate Degree</label>
          <select
            v-model="globalMeridiansObj.graduate_degree_type"
            class="form-control"
            :class="
              v$.globalMeridiansObj.graduate_degree_type.$error
                ? 'has-error'
                : ''
            "
            @blur="v$.globalMeridiansObj.graduate_degree_type.$touch"
          >
            <option :value="null">Please select</option>
            <option :value="1">Doctor of Medicine</option>
            <option :value="2">Doctor of Osteopathic Medicine</option>
            <option :value="3">Doctor of Optometry</option>
            <option :value="4">Doctor of Dental Medicine</option>
            <option :value="5">Dentariae Medicinae Doctoris</option>
            <option :value="6">Doctor of Dental Surgery</option>
            <option :value="7">Doctor of Veterinary Medicine</option>
            <option :value="8">Veterinariae Medicinae Doctoris</option>
            <option :value="9">Master of Arts</option>
            <option :value="10">Master of Science</option>
            <option :value="11">Master of Business Administration</option>
            <option :value="12">Master of Library Science</option>
            <option :value="13">Master of Research</option>
            <option :value="14">Master of Research Project</option>
            <option :value="15">Master of Studies</option>
            <option :value="16">Master of Public Administration</option>
            <option :value="17">Master of Public Health</option>
            <option :value="18">Master of Laws</option>
            <option :value="19">Master of Arts Liberal Studies</option>
            <option :value="20">Master of Fine Arts</option>
            <option :value="21">Master of Music</option>
            <option :value="22">Master of Education</option>
            <option :value="23">Master of Engineering</option>
            <option :value="24">Master of Architecture</option>
            <option :value="25">Juris Doctor</option>
            <option :value="26">Other</option>
          </select>
        </div>
        <div class="col-md-4">
          <label class="generalLabel">Employment Status</label>
          <select
            v-model="globalMeridiansObj.employment_status"
            class="form-control"
            :class="
              v$.globalMeridiansObj.employment_status.$error ? 'has-error' : ''
            "
            @blur="v$.globalMeridiansObj.employment_status.$touch"
          >
            <option :value="null">Please select</option>
            <option :value="1">Employed</option>
            <option :value="2">Military</option>
            <option :value="3">Self Employed</option>
            <option :value="4">Retired</option>
            <option :value="5">Not Employed</option>
            <option :value="6">Unknown</option>
            <option :value="7">Other</option>
          </select>
        </div>

        <div class="col-md-4">
          <label class="generalLabel">Employment Pay Frequency </label>
          <select
            v-model="globalMeridiansObj.employment_pay_frequency"
            :class="
              v$.globalMeridiansObj.employment_pay_frequency.$error
                ? 'has-error'
                : ''
            "
            @blur="v$.globalMeridiansObj.employment_pay_frequency.$touch"
            class="form-control"
          >
            <option :value="null">Please select</option>
            <option :value="1">Weekly</option>
            <option :value="2">Biweekly</option>
            <option :value="3">Twice Monthly</option>
            <option :value="4">Monthly</option>
            <option :value="5">Unknown</option>
          </select>
        </div>

        <div class="col-md-4">
          <label class="generalLabel">Self Reported Credit Score</label>
          <select
            v-model="globalMeridiansObj.self_reported_credit_score"
            class="form-control"
            :class="
              v$.globalMeridiansObj.self_reported_credit_score.$error
                ? 'has-error'
                : ''
            "
            @blur="v$.globalMeridiansObj.self_reported_credit_score.$touch"
          >
            <option :value="null">Please select</option>
            <option :value="1">Excellent</option>
            <option :value="2">Good</option>
            <option :value="3">Fair</option>
            <option :value="4">Poor</option>
            <option :value="5">Unknown</option>
          </select>
        </div>

        <div class="col-md-4">
          <label class="generalLabel">Housing Type</label>
          <select
            v-model="globalMeridiansObj.housing_type"
            class="form-control"
            :class="
              v$.globalMeridiansObj.housing_type.$error ? 'has-error' : ''
            "
            @blur="v$.globalMeridiansObj.housing_type.$touch"
          >
            <option :value="null">Please select</option>
            <option :value="1">Rent</option>
            <option :value="2">Own With Mortgage</option>
            <option :value="3">Own Outright</option>
            <option :value="4">Unknown</option>
          </select>
        </div>

        <div class="col-md-4">
          <label class="generalLabel">Months At Address</label>
          <input
            v-model="globalMeridiansObj.months_at_address"
            type="text"
            class="form-control"
            :class="
              v$.globalMeridiansObj.months_at_address.$error ? 'has-error' : ''
            "
            @blur="v$.globalMeridiansObj.months_at_address.$touch"
          />
        </div>

        <div class="col-md-4">
          <label class="generalLabel">Customer UUId</label>
          <label class="form-control" style="background: #f1efef">{{
            globalMeridiansObj.customer_uuid
          }}</label>
        </div>

        <div class="col-md-4">
          <label class="generalLabel">Custom Debt Balance</label>
          <input
            v-model="globalMeridiansObj.custom_debt_balance"
            :class="
              v$.globalMeridiansObj.custom_debt_balance.$error
                ? 'has-error'
                : ''
            "
            @blur="v$.globalMeridiansObj.custom_debt_balance.$touch"
            type="text"
            class="form-control"
            v-myMask="{
              alias: 'currency',
              prefix: '$',
              rightAlign: false,
              min: 0,
            }"
          />
        </div>

        <div class="col-md-4">
          <label class="generalLabel">Custom Term</label>
          <input
            v-model="globalMeridiansObj.custom_term"
            :class="v$.globalMeridiansObj.custom_term.$error ? 'has-error' : ''"
            @blur="v$.globalMeridiansObj.custom_term.$touch"
            type="text"
            class="form-control"
          />
        </div>

        <div class="col-md-4">
          <label class="generalLabel">Custom Saving</label>
          <input
            v-model="globalMeridiansObj.custom_savings"
            :class="
              v$.globalMeridiansObj.custom_savings.$error ? 'has-error' : ''
            "
            @blur="v$.globalMeridiansObj.custom_savings.$touch"
            type="text"
            class="form-control"
            v-myMask="{
              alias: 'currency',
              prefix: '$',
              rightAlign: false,
              min: 0,
            }"
          />
        </div>

        <div class="col-md-4">
          <label class="generalLabel">Custom Cost</label>
          <input
            v-model="globalMeridiansObj.custom_cost"
            :class="v$.globalMeridiansObj.custom_cost.$error ? 'has-error' : ''"
            @blur="v$.globalMeridiansObj.custom_cost.$touch"
            type="text"
            class="form-control"
            v-myMask="{
              alias: 'currency',
              prefix: '$',
              rightAlign: false,
              min: 0,
            }"
          />
        </div>

        <div class="col-md-4">
          <label class="generalLabel">Custom Monthly Payment</label>
          <input
            v-model="globalMeridiansObj.custom_monthly_payment"
            :class="
              v$.globalMeridiansObj.custom_monthly_payment.$error
                ? 'has-error'
                : ''
            "
            @blur="v$.globalMeridiansObj.custom_monthly_payment.$touch"
            type="text"
            class="form-control"
            v-myMask="{
              alias: 'currency',
              prefix: '$',
              rightAlign: false,
              min: 0,
            }"
          />
        </div>
        <div class="col-md-4">
          <label class="generalLabel">Employer Name</label>
          <input
            v-model="globalMeridiansObj.employer_name"
            :class="
              v$.globalMeridiansObj.employer_name.$error ? 'has-error' : ''
            "
            @blur="v$.globalMeridiansObj.employer_name.$touch"
            type="text"
            class="form-control"
          />
        </div>
        <div class="col-md-4">
          <label class="generalLabel">Months at Employer</label>
          <input
            v-model="globalMeridiansObj.months_at_employer"
            :class="
              v$.globalMeridiansObj.months_at_employer.$error ? 'has-error' : ''
            "
            @blur="v$.globalMeridiansObj.months_at_employer.$touch"
            type="text"
            class="form-control"
            v-myMask="{
              alias: 'numeric',
              rightAlign: false,
              min: 0,
            }"
          />
        </div>

        <div class="col-md-4">
          <label class="generalLabel">Monthly Rent</label>
          <input
            v-model="globalMeridiansObj.monthly_rent"
            :class="
              v$.globalMeridiansObj.monthly_rent.$error ? 'has-error' : ''
            "
            @blur="v$.globalMeridiansObj.monthly_rent.$touch"
            type="text"
            class="form-control"
            v-myMask="{
              alias: 'currency',
              prefix: '$',
              rightAlign: false,
              min: 0,
            }"
          />
        </div>
      </div>
    </perfect-scrollbar>

    <div class="col-md-12">
      <div class="col-md-12">
        <button
          :disabled="v$.$invalid"
          @click="submitButton()"
          type="button"
          class="btn btn-success noMargin col-md-12"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin.js";
import useValidate from "@vuelidate/core";
import { required } from "vuelidate/lib/validators";
import types from "./types";

export default {
  name: "GlobalMeridians",
  components: {},
  mixins: [formatMixin, utilitiesMixin],

  props: {
    dataToGlobalMeridians: {
      Type: Object,
      Defult: null,
    },
  },
  data() {
    return {
      incomeSourceList: [
        { Id: 1, Name: "Employed" },
        { Id: 2, Name: "Social Security" },
        { Id: 3, Name: "Pension" },
        { Id: 4, Name: "Disability" },
        { Id: 5, Name: "Self Employed" },
        { Id: 6, Name: "Student" },
        { Id: 7, Name: "Unemployed" },
      ],
      payFrequencyList: [
        { Id: 1, Name: "Weekly" },
        { Id: 2, Name: "Biweekly" },
        { Id: 3, Name: "Twice Monthly" },
        { Id: 4, Name: "Monthly" },
        { Id: 5, Name: "Unknown" },
      ],
      educationLevelList: [
        { Id: 1, Name: "High School" },
        { Id: 2, Name: "Associate" },
        { Id: 3, Name: "Masters" },
        { Id: 4, Name: "Doctorate" },
        { Id: 5, Name: "Other Grad Degree" },
        { Id: 6, Name: "Other" },
        { Id: 7, Name: "Unknown" },
      ],
      employmentStatusList: [
        { Id: 1, Name: "Employed" },
        { Id: 2, Name: "Military" },
        { Id: 3, Name: "Self Employed" },
        { Id: 4, Name: "Retired" },
        { Id: 5, Name: "Not Employed" },
        { Id: 6, Name: "Unknown" },
        { Id: 7, Name: "Other" },
      ],
      selfReportedCreditScoreList: [
        { Id: 1, Name: "Excellent" },
        { Id: 2, Name: "Good" },
        { Id: 3, Name: "Fair" },
        { Id: 4, Name: "Poor" },
        { Id: 5, Name: "Unknown" },
      ],

      graduateDegreeList: [
        { Id: 1, Name: "Doctor of Medicine" },
        { Id: 2, Name: "Doctor of Osteopathic Medicine" },
        { Id: 3, Name: "Doctor of Optometry" },
        { Id: 4, Name: "Doctor of Dental Medicine" },
        { Id: 5, Name: "Dentariae Medicinae Doctoris" },
        { Id: 6, Name: "Doctor of Dental Surgery" },
        { Id: 7, Name: "Doctor of Veterinary Medicine" },
        { Id: 8, Name: "Veterinariae Medicinae Doctoris" },
        { Id: 9, Name: "Master of Arts" },
        { Id: 10, Name: "Master of Science" },
        { Id: 11, Name: "Master of Business Administration" },
        { Id: 12, Name: "Master of Library Science" },
        { Id: 13, Name: "Master of Research" },
        { Id: 14, Name: "Master of Research Project" },
        { Id: 15, Name: "Master of Studies" },
        { Id: 16, Name: "Master of Public Administration" },
        { Id: 17, Name: "Master of Public Health" },
        { Id: 18, Name: "Master of Laws" },
        { Id: 19, Name: "Master of Arts Liberal Studies" },
        { Id: 20, Name: "Master of Fine Arts" },
        { Id: 21, Name: "Master of Music" },
        { Id: 22, Name: "Master of Education" },
        { Id: 23, Name: "Master of Engineering" },
        { Id: 24, Name: "Master of Architecture" },
        { Id: 25, Name: "Juris Doctor" },
        { Id: 26, Name: "Other" },
      ],

      v$: useValidate(),
      globalMeridiansObj: {
        first_name: "",
        last_name: "",
        email: "",
        birth_date: "",
        ssn_number: "",
        annual_gross_income: null,
        education_level: null,
        graduate_degree_type: null,
        mobile: "",
        address: "",
        city: "",
        state: null,
        zip_code: null,
        loan_purpose: 8,
        loan_amount: null,
        employment_status: null,
        employment_pay_frequency: null,
        self_reported_credit_score: null,
        housing_type: null,
        agent_email: "",

        vendor_id: null,
        income_source: "1",
        months_at_address: null,
        customer_uuid: null,
        custom_debt_balance: null,
        custom_term: null,
        custom_savings: null,
        custom_cost: null,
        custom_monthly_payment: null,
        custom_lender_id: null,
        custom_offer_url: null,

        application_id: null,
        military: "0",
        employer_name: null,
        months_at_employer: null,
        monthly_rent: null,
        co_app: "0",

        university_attended: "",
        custom_interest_rate: "NA",
        purl_id: "",
        append_file: "",
      },
    };
  },

  async mounted() {
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo) {
      this.globalMeridiansObj.agent_email = userInfo.email;
    }

    this.globalMeridiansObj.customer_uuid =
      this.dataToGlobalMeridians.CustomerId.toString();

    this.globalMeridiansObj.first_name = this.dataToGlobalMeridians.FirstName;
    this.globalMeridiansObj.last_name = this.dataToGlobalMeridians.LastName;
    this.globalMeridiansObj.email = this.dataToGlobalMeridians.Email;
    this.globalMeridiansObj.birth_date = new Date(
      this.dataToGlobalMeridians.DateOfBirth
    ).toString("MM/dd/yyyy");
    this.globalMeridiansObj.ssn_number = this.formatSSN(
      this.dataToGlobalMeridians.Ssn
    );
    this.globalMeridiansObj.annual_gross_income =
      this.dataToGlobalMeridians.Income;
    this.globalMeridiansObj.mobile = this.dataToGlobalMeridians.PhoneNumber;
    this.globalMeridiansObj.city = this.dataToGlobalMeridians.City;
    this.globalMeridiansObj.state = this.dataToGlobalMeridians.ShortState;
    this.globalMeridiansObj.zip_code = this.dataToGlobalMeridians.Zip;
    this.globalMeridiansObj.loan_amount =
      this.dataToGlobalMeridians.TotalAmount;
    this.globalMeridiansObj.address = this.dataToGlobalMeridians.AddressLine1;
    this.globalMeridiansObj.annual_gross_income =
      this.dataToGlobalMeridians.AnnualIncome;
    this.globalMeridiansObj.custom_debt_balance =
      this.dataToGlobalMeridians.TotalDebt;
    this.globalMeridiansObj.custom_monthly_payment =
      this.dataToGlobalMeridians.DraftAmount;

    this.globalMeridiansObj.custom_cost = this.dataToGlobalMeridians.Cost;
    this.globalMeridiansObj.custom_savings = this.dataToGlobalMeridians.Savings;
    this.globalMeridiansObj.custom_term = this.dataToGlobalMeridians.Term;

    this.globalMeridiansObj.employer_name =
      this.dataToGlobalMeridians.EmployerName;
    this.globalMeridiansObj.months_at_address =
      this.dataToGlobalMeridians.MonthsAtAddress;
    this.globalMeridiansObj.months_at_employer =
      this.dataToGlobalMeridians.MonthsAtEmployer;

    this.globalMeridiansObj.monthly_rent =
      this.dataToGlobalMeridians.MonthlyRent;

    let customerResidence = this.dataToGlobalMeridians.ResidenceOwnershipId;

    if (customerResidence == 1) {
      this.globalMeridiansObj.housing_type = 3;
    } else if (customerResidence == 2) {
      this.globalMeridiansObj.housing_type = 1;
    } else {
      this.globalMeridiansObj.housing_type = null;
    }

    if (this.dataToGlobalMeridians.EmploymentPayFrequency) {
      let indx = this.payFrequencyList.findIndex(
        (x) =>
          this.cleanName(x.Name) ==
          this.cleanName(this.dataToGlobalMeridians.EmploymentPayFrequency)
      );

      if (indx > -1) {
        this.globalMeridiansObj.employment_pay_frequency =
          this.payFrequencyList[indx].Id;
      }
    }

    if (this.dataToGlobalMeridians.EducationLevel) {
      let indx = this.educationLevelList.findIndex(
        (x) =>
          this.cleanName(x.Name) ==
          this.cleanName(this.dataToGlobalMeridians.EducationLevel)
      );
      if (indx > -1) {
        this.globalMeridiansObj.education_level =
          this.educationLevelList[indx].Id;
      }
    }

    if (this.dataToGlobalMeridians.EmploymentStatus) {
      let indx = this.employmentStatusList.findIndex(
        (x) =>
          this.cleanName(x.Name) ==
          this.cleanName(this.dataToGlobalMeridians.EmploymentStatus)
      );
      if (indx > -1) {
        this.globalMeridiansObj.employment_status =
          this.employmentStatusList[indx].Id;
      }
    }

    if (this.dataToGlobalMeridians.SelfReportedCreditScore) {
      let indx = this.selfReportedCreditScoreList.findIndex(
        (x) =>
          this.cleanName(x.Name) ==
          this.cleanName(this.dataToGlobalMeridians.SelfReportedCreditScore)
      );
      if (indx > -1) {
        this.globalMeridiansObj.self_reported_credit_score =
          this.selfReportedCreditScoreList[indx].Id;
      }
    }

    if (this.dataToGlobalMeridians.GraduateDegree) {
      let indx = this.graduateDegreeList.findIndex(
        (x) =>
          this.cleanName(x.Name) ==
          this.cleanName(this.dataToGlobalMeridians.GraduateDegree)
      );
      if (indx > -1) {
        this.globalMeridiansObj.graduate_degree_type =
          this.graduateDegreeList[indx].Id;
      }
    }

    if (this.dataToGlobalMeridians.IncomeSource) {
      let indx = this.incomeSourceList.findIndex(
        (x) =>
          this.cleanName(x.Name) ==
          this.cleanName(this.dataToGlobalMeridians.IncomeSource)
      );
      if (indx > -1) {
        this.globalMeridiansObj.income_source = this.incomeSourceList[indx].Id;
      }
    }
  },
  methods: {
    cleanName(item) {
      let rtn = "";
      if (item) {
        rtn = item.toLowerCase();
        rtn = rtn.replaceAll(" ", "");
      }

      return rtn;
    },
    async submitButton() {
      let err, result;

      [err, result] = await this.$store.dispatch(
        types.POST_GLOBAL_MERIDIANS,
        this.globalMeridiansObj
      );
      if (result) {
        let data = result.Data;
        if (data) {
          if (data.confirmation_url && data.confirmation_url != "") {
            let link = document.createElement("a");
            link.href = data.confirmation_url;
            link.target = "_blank";
            link.click();
            this.closePanel();
          } else {
            if (data.missing_fields && data.missing_fields.legnth > 0) {
              this.$swal(
                "Warning!",
                "Missing fields: " + data.missing_fields.join(","),
                "warning"
              );
            } else if (data.message && data.message.length > 0) {
              this.$swal("Error!", data.message, "error");
            }
          }
        } else {
          this.$swal("Error!", "An error occured!", "error");
        }
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
    },

    closePanel() {
      this.$emit("closeGlobalMeridiansPopup");
    },
  },

  validations() {
    return {
      globalMeridiansObj: {
        agent_email: { required },
        first_name: { required },
        last_name: { required },
        birth_date: { required },
        ssn_number: { required },
        annual_gross_income: { required },
        education_level: { required },
        graduate_degree_type: { required },
        mobile: { required },
        email: { required },
        address: { required },
        city: { required },
        state: { required },
        zip_code: { required },
        loan_purpose: { required },
        loan_amount: { required },
        employment_status: { required },
        employment_pay_frequency: { required },
        self_reported_credit_score: { required },
        housing_type: { required },
        months_at_address: { required },
        custom_debt_balance: { required },
        custom_term: { required },
        custom_savings: { required },
        custom_cost: { required },
        custom_monthly_payment: { required },
        income_source: { required },
        employer_name: { required },
        months_at_employer: { required },
        monthly_rent: { required },
      },
    };
  },
};
</script>

<style>
.has-error {
  border: 2px solid red !important;
}
.superMoneyPopUpHeader {
  padding-left: 24px;
  margin-top: -5px;
  padding-bottom: 10px;
}
@media screen and (max-height: 730px) {
  .smp-mediaScreen {
    max-height: 600px !important;
    overflow-y: scroll;
  }
}
@media screen and (max-height: 620px) {
  .smp-mediaScreen {
    max-height: 400px !important;
    overflow-y: scroll;
  }
}
@media screen and (max-height: 420px) {
  .smp-mediaScreen {
    max-height: 250px !important;
    overflow-y: scroll;
  }
}
</style>
