<template>
  <div>
    <div class="swal2-header text-center">
      <h2 class="swal2-title" id="swal2-title" style="display: inline-block">
        {{ title }}
      </h2>
    </div>
    <div class="swal2-content mt-4">
      <div
        id="swal2-content"
        class="swal2-html-container"
        style="display: block"
      >
        {{ content }}
      </div>
    </div>
    <div class="swal2-actions mt-4">
      <button
        type="button"
        class="swal2-confirm swal2-styled"
        aria-label
        style="
          display: inline-block;
          background-color: rgb(48, 133, 214);
          border-left-color: rgb(48, 133, 214);
          border-right-color: rgb(48, 133, 214);
        "
        @click="viewCustomer()"
      >
        View Customer
      </button>
      <button
        type="button"
        class="swal2-cancel swal2-styled"
        aria-label
        style="display: inline-block; background-color: rgb(221, 51, 51)"
        @click="cancel()"
      >
        Ignore
      </button>
    </div>
  </div>
</template>
<script>
export default {
  name: "HotLeadPopup",
  props: {
    customerId: {
      type: Number,
      default: () => 0,
    },
    customerName: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      title: "Blue Rock Incoming Call",
      content: "",
    };
  },
  mounted() {
    this.content = "Customer Name: " + this.customerName;
  },
  methods: {
    viewCustomer() {
      this.$emit("handleViewCustomer", {
        customerId: this.customerId,
        customerName: this.customerName,
      });
    },
    cancel() {
      this.$emit("closeBrCallPopup");
    },
  },
};
</script>
<style></style>
