import types from "./types";
import api from "@/services/admin/api";
import to from "@/helpers/to";

export default {
  state: {
    list: [],
    modules: [],
    roleById: [],
  },
  actions: {
    GET_ALL_ROLES: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.getRoles());

      if (result) {
        commit(types.SET_ALL_ROLES, result.Data);
      }
    },
    GET_MODULES: async ({ commit }) => {
      let err, result;

      [err, result] = await to(api.getModules());

      if (result) {
        commit(types.SET_MODULES, result.Data);
      }
    },

    POST_ROLE: async ({ commit }, payload) => {
      return await to(api.saveRole(payload));
    },
    PUT_ROLE: async ({ commit }, payload) => {
      let result = await to(api.updateRole(payload));
      return result;
    },
    DELETE_ROLE: async ({ commit }, roleId) => {
      let result = await to(api.deleteRole(roleId));
      return result;
    },
  },
  mutations: {
    SET_ALL_ROLES: (state, payload) => {
      state.list = payload;
    },
    SET_MODULES: (state, payload) => {
      state.modules = payload;
    },
    SETBYID: (state, payload) => {
      state.roleById = payload;
    },
  },
};
