export default {
  OPTOUT_AUTOCALL: "OPTOUT_AUTOCALL",
  GET_TASK_TRIGGERS_BY_ID: "GET_TASK_TRIGGERS_BY_ID",
  GET_CLIENT_TASKS: "GET_CLIENT_TASKS",
  ASSIGN_TASK: "ASSIGN_TASK",
  UNASSIGN_TASK: "UNASSIGN_TASK",
  SUBMIT_TASK: "SUBMIT_TASK",
  ADD_TASK: "ADD_TASK",
  GET_TASK_BY_ID: "GET_TASK_BY_ID",
  GET_TICKET_ACCESS_ROLES: "GET_TICKET_ACCESS_ROLES",
  GET_TICKET_ACCESS_USERS: "GET_TICKET_ACCESS_USERS",
};
