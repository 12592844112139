<template>
  <div
    class="col-md-12 newPanelWrapper noMargin dcp-mediaScreen"
    style="background-color: #ffffff; z-index: 1000"
  >
    <div
      style="display: flex; justify-content: space-between"
      v-on:click.prevent.stop="closePanel"
    >
      <label>Creditor Info</label>
      <i class="fas fa-window-close closeButtonBulk"></i>
    </div>
    <div class="row">
      <div class="col-md-12" v-if="checkAuth(81406)">
        <span class="creditorInfoHeader">Creditor Id: </span>
        <span class="creditorInfoText">{{ creditorId }}</span>
      </div>
      <div class="col-md-12">
        <span class="creditorInfoHeader">Creditor Added Date: </span>
        <span class="creditorInfoText">{{ creditorAddedDate }}</span>
      </div>
      <div class="col-md-12">
        <div v-if="htmlLoaded" class="creditorInfoHeader">
          Post-Enrollment Debt Changes:
        </div>
        <div v-if="htmlLoaded" class="mt-2" v-html="content"></div>
        <div class="mt-2" v-if="creditorSettings">
          <div class="row">
            <div class="col-md-12">
              <span class="creditorInfoHeader"> Name: </span>
              <span class="creditorInfoText">
                {{ creditorSettings.CreditorName }}
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <span class="creditorInfoHeader"> Avg. Settlement Perc: </span>
              <span class="creditorInfoText">
                {{
                  creditorSettings.AvgSettlementPercentage
                    ? parseFloat(
                        creditorSettings.AvgSettlementPercentage * 100
                      ).toFixed(2)
                    : 0
                }}%
              </span>
            </div>
            <div class="col-md-6">
              <span class="creditorInfoHeader"> Is Scrub: </span>
              <span class="creditorInfoText">
                {{ creditorSettings.IsScrub ? "Yes" : "No" }}
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <span class="creditorInfoHeader"> Is Rev Share: </span>
              <span class="creditorInfoText">
                {{ creditorSettings.IsRevShare ? "Yes" : "No" }}
              </span>
            </div>
            <div class="col-md-6">
              <span class="creditorInfoHeader"> Difficulty Level: </span>
              <span class="creditorInfoText">
                {{ creditorSettings.DifficultyLevel }}
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <span class="creditorInfoHeader"> Settlement Rules: </span>
            </div>
            <div class="col-md-12">
              <div
                class="buefyWrapper datagrid-table"
                style="
                  max-height: 487px;
                  overflow-y: auto;
                  overflow-x: auto;
                  font-size: 12px !important;
                "
              >
                <section>
                  <o-table
                    v-if="creditorSettings.SettlementRules"
                    :data="creditorSettings.SettlementRules"
                    :default-sort-direction="defaultSortDirection"
                    :sort-icon="sortIcon"
                    :sort-icon-size="sortIconSize"
                    :striped="isStriped"
                    :hoverable="isHoverable"
                  >
                    <template>
                      <o-table-column
                        field="Title"
                        label="Title"
                        width="20%"
                        sortable
                        v-slot="props"
                        >{{ props.row.Title }}</o-table-column
                      >
                      <o-table-column
                        field="SettlementPercentage"
                        label="Settlement Percentage"
                        width="20%"
                        sortable
                        v-slot="props"
                        >{{ props.row.SettlementPercentage }}</o-table-column
                      >
                      <o-table-column
                        field="PaymentTerms"
                        label="Payment Terms"
                        width="20%"
                        sortable
                        v-slot="props"
                      >
                        {{ props.row.PaymentTerms }}
                      </o-table-column>
                      <o-table-column
                        field="PaymentTypeId"
                        label="PaymentType"
                        width="20%"
                        sortable
                        v-slot="props"
                        >{{
                          getPaymentNameById(props.row.PaymentTypeId)
                        }}</o-table-column
                      >
                      <o-table-column
                        field="DaysDelinquent"
                        label="Days Delinquent"
                        centered
                        width="20%"
                        sortable
                        v-slot="props"
                        >{{ props.row.DaysDelinquent }}</o-table-column
                      >
                    </template>
                  </o-table>
                  <section
                    v-if="creditorSettings.SettlementRules.length == 0"
                    class="section"
                    style="text-align: center"
                  >
                    <div class="content has-text-grey has-text-centered mb-4">
                      <p class="noMargin">
                        <i
                          style="font-size: 24px; color: #939393"
                          class="ri-question-line"
                        ></i>
                      </p>
                      <p class="noMargin">Nothing here.</p>
                    </div>
                  </section>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import deepCopy from "@/helpers/deepCopy";

export default {
  name: "ClientCreditorsInfoPopup",
  components: {},
  props: {
    infoList: {
      type: Array,
      default: () => [],
    },
    dateAdded: {
      type: String,
      default: () => "",
    },
    creditorName: {
      type: String,
      default: () => "",
    },
    creditorId: {
      type: Number,
      default: () => 0,
    }
  },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      content: "",
      htmlLoaded: false,
      creditorAddedDate: "",
      creditorSettings: null,
      paymentList: [],
    };
  },
  computed: mapState({}),
  async mounted() {
    this.creditorAddedDate = this.formatDate(this.dateAdded);
    let logList = [];
    let headers = [];
    if (this.infoList && this.infoList.length > 0) {
      if (this.infoList.map((x) => x.LogsList).length > 0) {
        logList = deepCopy(this.infoList.map((x) => x.LogsList))[0].sort(
          (a, b) => new Date(b.EventDate) - new Date(a.EventDate)
        );
        headers = Object.keys(logList[0]);
      }

      this.content = this.createContent(logList, headers);
    }

    let errPayment, resultPayment;
    [errPayment, resultPayment] = await this.$store.dispatch(
      types.GET_CLIENT_CREDITORS_PAYMENT_TYPES
    );

    if (resultPayment && resultPayment.Data) {
      this.paymentList = deepCopy(resultPayment.Data);
    }

    let err, result;
    [err, result] = await this.$store.dispatch(
      types.GET_CREDITOR_SETTINGS_BY_NAME,
      {
        CreditorName: this.creditorName,
      }
    );
    if (result && result.Data) {
      this.creditorSettings = result.Data;
    }
  },
  methods: {
    getPaymentNameById(paymentId) {
      let rtn = "";
      if (this.paymentList && this.paymentList.length > 0) {
        let tmp = this.paymentList.filter((x) => x.Id == paymentId);

        if (tmp && tmp.length > 0) {
          rtn = tmp[0].Name;
        }
      }
      return rtn;
    },
    closePanel() {
      this.$emit("closeInfoPopup");
    },
    createContent(logList, headers) {
      let rtn = this.content;
      if (logList.length > 0 && headers.length > 0) {
        this.htmlLoaded = true;
        rtn =
          "<table class='custom-table' style='width:100%; font-size: 10px !important'>";
        rtn += "<thead style='background-color:#fafafa'>";
        rtn += "<tr>";
        for (let item of headers) {
          if (item == "EventType") {
            rtn += "<th style='border: none;'>" + "Event Type" + "</th>";
          } else if (item == "EventDate") {
            rtn += "<th style='border: none;'>" + "Event Date" + "</th>";
          } else if (item == "CreditorName") {
            rtn += "<th style='border: none;'>" + "Creditor Name" + "</th>";
          } else if (item == "PrevDebtAmount") {
            rtn += "<th style='border: none;'>" + "Prev Debt Amount" + "</th>";
          } else if (item == "NewDebtAmount") {
            rtn += "<th style='border: none;'>" + "New Debt Amount" + "</th>";
          } else {
            rtn += "<th style='border: none;'>" + item + "</th>";
          }
        }
        rtn += "</tr>";
        rtn += "</thead>";
        rtn += "<tbody>";
        logList.forEach((item) => {
          let columnNames = Object.keys(item);
          rtn += "<tr>";
          columnNames.forEach((c) => {
            if (c.toLocaleLowerCase() == "eventdate") {
              rtn += "<td>" + this.formatGridDate(item[c]) + "</td>";
            } else if (c.toLocaleLowerCase() == "prevdebtamount") {
              rtn += "<td>" + this.formatMoney(item[c]) + "</td>";
            } else if (c.toLocaleLowerCase() == "newdebtamount") {
              rtn += "<td>" + this.formatMoney(item[c]) + "</td>";
            } else {
              rtn += "<td>" + item[c] + "</td>";
            }
          });
          rtn += "</tr>";
        });
        rtn += "</tbody>";
        rtn += "</table>";
      }

      return rtn;
    },
  },
};
</script>

<style>
.creditorInfoHeader {
  color: #3bafda;
  font-weight: bold;
}
.creditorInfoText {
  font-weight: bold;
}
.closeButton {
  position: absolute;
  top: -5px;
  right: 15px;
  z-index: 1000;
  font-size: 30px;
  cursor: pointer;
}
.closeButton:hover {
  color: #bdbdbd;
}
</style>
