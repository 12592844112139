export default {
  DISMISS_NOTIFICATION:"DISMISS_NOTIFICATION",
  POSTPONE_NOTIFICATION:"POSTPONE_NOTIFICATION",
  GET_POPUPNOTIFICATIONS: "GET_POPUPNOTIFICATIONS",
  MODIFY_POPUPNOTIFICATIONS: "MODIFY_POPUPNOTIFICATIONS",
  SET_POPUPNOTIFICATIONS: "SET_POPUPNOTIFICATIONS",
  GET_CALENDAREVENTS: "GET_CALENDAREVENTS",
  SET_CALENDAREVENTS: "SET_CALENDAREVENTS",
  GET_CUSTOMERREMINDERS: "GET_CUSTOMERREMINDERS",
  MODIFY_CUSTOMERREMINDERS: "MODIFY_CUSTOMERREMINDERS",
  SET_CUSTOMERREMINDERS: "SET_CUSTOMERREMINDERS",
  OUT_DISMISS: "OUT_DISMISS",
  GET_CUSTOMER_REMINDERS_LOCAL: "GET_CUSTOMER_REMINDERS_LOCAL",
};
