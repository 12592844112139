import types from "./types";
import api from "@/services/admin/api";
import to from "@/helpers/to";

export default {
  state: {
    tempList: [],
  },
  actions: {
    GETVMRECORDER: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.getVMRecordings());
      if (result) {
        commit(types.SETVMRECORDER, result.Data);
      }
    },

    POSTVMRECORDER: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(
        api.saveVMRecording({
          VoicemailTemplateId: payload.Id,
          VoiceData: payload.base64,
          IsSpanish: payload.IsSpanish,
        })
      );
      return [err, result];
    },

    DELETEVMRECORDER: async ({ dispatch, commit }, payload) => {
      let err, result;

      [err, result] = await to(api.deleteVMRecording(payload));

      return [err, result];
    },
  },
  mutations: {
    SETVMRECORDER: (state, payload) => {
      state.tempList = payload;
    },
  },
};
