<template>
  <div class="row admin-page officePage">
    <div v-if="saving" class="loading">
      <div class="text-center" style="margin-top: 350px">
        <i class="spinner-grow text-info m-2"></i>
      </div>
    </div>
    <div class="col-xl-3 col-lg-4 col-md-12 col-sm-12">
      <div class="filter form-group row">
        <div class="col-lg-12 col-md-12 col-sm-12 noPadding">
          <input
            autocomplete="off"
            type="text"
            class="form-control"
            v-model="officeFilter"
            placeholder="Filter Offices"
            v-if="checkAuth(1454)"
          />
        </div>
        <div class="list col-md-12">
          <div
            @click="handleSelection(item)"
            class="office item row"
            v-for="(item, index) in filteredOffice"
            :key="item.Id"
            :class="{ active: activeItem === item.OfficeId }"
            :disabled="saving || deleting"
            :style="[
              index % 2 === 0
                ? { 'background-color': '#F1EFEF' }
                : { 'background-color': '#FFFFFF' },
            ]"
          >
            <span
              :class="{ adminActiveMenuItem: activeItem === item.OfficeId }"
            >
              <i class="fe-settings"></i>
              <span style="margin-left: 8px">{{ item.OfficeName }}</span>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xl-6 col-lg-8 col-md-12 col-sm-12" v-if="isEditing">
      <div class="panelContent" style="padding-top: 0">
        <div class="form-group row">
          <div class="col-md-6">
            <label for="txtAccountBaseUrl">Account Base Url: *</label>
            <input
              autocomplete="off"
              id="txtAccountBaseUrl"
              class="form-control"
              type="text"
              placeholder="Enter Account Base Url"
              v-model="selectedRow.AccountBaseUrl"
              :class="v$.selectedRow.AccountBaseUrl.$error ? 'has-error' : ''"
              @blur="v$.selectedRow.AccountBaseUrl.$touch"
            />
          </div>

          <div class="col-md-6">
            <label for="txtAccountUrl">Account Url: *</label>
            <input
              autocomplete="off"
              id="txtAccountUrl"
              class="form-control"
              type="text"
              placeholder="Enter Account Url"
              v-model="selectedRow.AccountUrl"
              :class="v$.selectedRow.AccountUrl.$error ? 'has-error' : ''"
              @blur="v$.selectedRow.AccountUrl.$touch"
            />
          </div>

          <div class="col-md-6 mt-4">
            <label for="txtApiBaseUrl">Api Base Url: *</label>
            <input
              autocomplete="off"
              id="txtApiBaseUrl"
              class="form-control"
              type="text"
              placeholder="Enter Api Base Url"
              v-model="selectedRow.ApiBaseUrl"
              :class="v$.selectedRow.ApiBaseUrl.$error ? 'has-error' : ''"
              @blur="v$.selectedRow.ApiBaseUrl.$touch"
            />
          </div>

          <div class="col-md-6 mt-4">
            <label for="txtAccountId">Account Id: *</label>
            <input
              autocomplete="off"
              id="txtAccountId"
              class="form-control"
              type="text"
              placeholder="Enter Account Id"
              v-model="selectedRow.AccountId"
              :class="v$.selectedRow.AccountId.$error ? 'has-error' : ''"
              @blur="v$.selectedRow.AccountId.$touch"
            />
          </div>

          <div class="col-md-6 mt-4">
            <label for="txtIntegrationKey">Integration Key: *</label>
            <input
              autocomplete="off"
              id="txtIntegrationKey"
              class="form-control"
              type="text"
              placeholder="Enter Integration Key"
              v-model="selectedRow.IntegrationKey"
              :class="v$.selectedRow.IntegrationKey.$error ? 'has-error' : ''"
              @blur="v$.selectedRow.IntegrationKey.$touch"
            />
          </div>

          <div class="col-md-6 mt-4">
            <label for="txtDocusignUserId">DocuSign User Id: *</label>
            <input
              autocomplete="off"
              id="txtDocusignUserId"
              class="form-control"
              type="text"
              placeholder="Enter DocuSign User Id"
              v-model="selectedRow.DocuSignUserId"
              :class="v$.selectedRow.DocuSignUserId.$error ? 'has-error' : ''"
              @blur="v$.selectedRow.DocuSignUserId.$touch"
            />
          </div>

          <div class="col-md-12 mt-4">
            <label for="txtRsaPrivateKey">Rsa Private Key: *</label>
            <textarea
              id="txtRsaPrivateKey"
              class="form-control"
              rows="6"
              style="resize: none"
              placeholder="Enter Rsa Private Key"
              v-model="selectedRow.RsaPrivateKey"
              :class="v$.selectedRow.RsaPrivateKey.$error ? 'has-error' : ''"
              @blur="v$.selectedRow.RsaPrivateKey.$touch"
            ></textarea>
          </div>

          <div
            class="offset-xl-9 offset-lg-4 col-xl-3 col-lg-4 col-md-6 col-xs-12 pt-4"
          >
            <button
              class="btn btn-success form-control"
              id="txtsave"
              :disabled="v$.$invalid || saving"
              @click="handleSave()"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useValidate from "@vuelidate/core";
import types from "./types";
import { required } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import _orderby from "lodash";
import deepCopy from "@/helpers/deepCopy";
import utilitiesMixin from "@/mixins/utilitiesMixin";

const init = {
  OfficeId: 0,
  OfficeName: "",
  AccountBaseUrl: "",
  AccountUrl: "",
  ApiBaseUrl: "",
  AccountId: "",
  IntegrationKey: "",
  DocuSignUserId: "",
  RsaPrivateKey: "",
};
export default {
  name: "DocusignSettings",
  components: {},

  data() {
    return {
      saving: false,
      deleting: false,
      isEditing: false,
      activeItem: null,
      officeFilter: "",
      v$: useValidate(),
      oldValue: JSON.stringify(init),
      selectedRow: Object.assign({}, init),
      isCancelledBeforeSave: false,
      hasAccessOtherOffices: false,
      userOfficeId: 0,
    };
  },
  computed: mapState({
    officeList: (state) => state.docusignManagement.officeSettings,
    filteredOffice() {
      return _orderby.orderBy(
        this.officeList.filter((x) =>
          this.hasAccessOtherOffices
            ? x &&
              x.OfficeName.toLowerCase().indexOf(
                this.officeFilter.toLowerCase()
              ) > -1
            : x.OfficeId == this.userOfficeId
        )
      );
    },
  }),
  async mounted() {
    await this.$store.dispatch(types.GET_OFFICE_SETTINGS);

    for (let item of this.officeList) {
      item.AccountId = await this.AES256_GCM_decrypt(item.AccountId);
      item.IntegrationKey = await this.AES256_GCM_decrypt(item.IntegrationKey);
      item.DocuSignUserId = await this.AES256_GCM_decrypt(item.DocuSignUserId);
      item.RsaPrivateKey = await this.AES256_GCM_decrypt(item.RsaPrivateKey);
    }

    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo != null && userInfo.moduleFunctions != null) {
      this.userOfficeId = userInfo.officeId;
    }
    this.hasAccessOtherOffices = this.canSeeAllOffices();
    if (!this.hasAccessOtherOffices) {
      let indx = this.officeList.findIndex(
        (x) => x.OfficeId == this.userOfficeId
      );
      if (indx > -1) {
        this.handleSelection(this.officeList[indx]);
      }
    }
  },

  methods: {
    async handleSelection(item) {
      if (
        this.oldValue != JSON.stringify(this.selectedRow) &&
        JSON.stringify(this.selectedRow) != JSON.stringify(init)
      ) {
        await this.$swal({
          title: "You have unsaved changes",
          text: "You will lose it if you don't save",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, continue without saving!",
        }).then(async (dialog) => {
          if (!dialog.value) {
            this.isCancelledBeforeSave = true;
          } else {
            this.isCancelledBeforeSave = false;
            return;
          }
        });
      }
      if (!this.isCancelledBeforeSave) {
        this.selectedRow = deepCopy(item);

        this.oldValue = JSON.stringify(this.selectedRow);
        this.activeItem = item.OfficeId;
      } else {
        this.activeItem = this.selectedRow.OfficeId;
      }
      this.isCancelledBeforeSave = false;
      this.isEditing = true;
    },
    async handleSave() {
      if (this.oldValue == JSON.stringify(this.selectedRow)) {
        this.$swal("Warning!", "No Changes Detected!", "warning");

        return;
      }
      this.saving = true;
      let err, result;

      let dataToSave = deepCopy(this.selectedRow);

      delete dataToSave.OfficeName;
      dataToSave.AccountId = await this.AES256_GCM_ENCRYPT(
        dataToSave.AccountId
      );
      dataToSave.IntegrationKey = await this.AES256_GCM_ENCRYPT(
        dataToSave.IntegrationKey
      );
      dataToSave.DocuSignUserId = await this.AES256_GCM_ENCRYPT(
        dataToSave.DocuSignUserId
      );
      dataToSave.RsaPrivateKey = await this.AES256_GCM_ENCRYPT(
        dataToSave.RsaPrivateKey
      );

      [err, result] = await this.$store.dispatch(
        types.POST_DOCUSIGN_SETTINGS,
        dataToSave
      );
      if (result) {
        this.$swal("Success", result.Message, "success");
        await this.$store.dispatch(types.GET_OFFICE_SETTINGS);
        for (let item of this.officeList) {
          item.AccountId = await this.AES256_GCM_decrypt(item.AccountId);
          item.IntegrationKey = await this.AES256_GCM_decrypt(
            item.IntegrationKey
          );
          item.DocuSignUserId = await this.AES256_GCM_decrypt(
            item.DocuSignUserId
          );
          item.RsaPrivateKey = await this.AES256_GCM_decrypt(
            item.RsaPrivateKey
          );
        }
        let indx = this.officeList.findIndex(
          (x) => x.OfficeId == this.selectedRow.OfficeId
        );
        this.selectedRow = deepCopy(this.officeList[indx]);
        this.oldValue = JSON.stringify(this.selectedRow);
        this.activeItem = this.selectedRow.OfficeId;
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
      this.saving = false;
    },
  },
  mixins: [utilitiesMixin],
  validations() {
    return {
      selectedRow: {
        AccountBaseUrl: { required },
        AccountUrl: { required },
        ApiBaseUrl: { required },
        AccountId: { required },
        IntegrationKey: { required },
        DocuSignUserId: { required },
        RsaPrivateKey: { required },
      },
    };
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
