import types from "./types";
import api from "@/services/admin/api";
import downloadApi from "@/services/fileServices/downloadService";
import to from "@/helpers/to";

export default {
  state: {
    templateList: [],
  },
  actions: {
    GET_DOCUMENT_TEMPLATES: async ({ commit, state }) => {
      if (state.templateList.length == 0) {
        let err, result;

        [err, result] = await to(api.getCompanyDocumentTemplates());
        if (result) {
          commit(types.SET_DOCUMENT_TEMPLATES, result.Data);
        }
      }
    },
    DOWNLOAD_DOCUMENT_TEMPLATE: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(downloadApi.downloadDocumentTemplate(payload));
      return [err, result];
    },
    INSERT_DOCUMENT_TEMPLATE: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.insertDocumentTemplate(payload));
      return [err, result];
    },
    DELETE_DOCUMENT_TEMPLATE: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.deleteDocumentTemplate(payload));
      return [err, result];
    },
  },
  mutations: {
    SET_DOCUMENT_TEMPLATES: (state, payload) => {
      state.templateList = payload;
    },
  },
};
