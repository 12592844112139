export default {
  GET_USER_ACTIVITY_CREDITOR: "GET_USER_ACTIVITY_CREDITOR",
  GET_CREDITOR_SETTINGS: "GET_CREDITOR_SETTINGS",
  SET_CREDITOR_SETTINGS: "SET_CREDITOR_SETTINGS",
  CREATE_CREDITOR_SETTINGS: "CREATE_CREDITOR_SETTINGS",
  UPDATE_CREDITOR_SETTINGS: "UPDATE_CREDITOR_SETTINGS",
  DELETE_CREDITOR_SETTINGS: "DELETE_CREDITOR_SETTINGS",
  GET_CREDITOR_ALIAS: "GET_CREDITOR_ALIAS",
  SET_CREDITOR_ALIAS: "SET_CREDITOR_ALIAS",
  CREATE_CREDITOR_ALIAS: "CREATE_CREDITOR_ALIAS",
  UPDATE_CREDITOR_ALIAS: "UPDATE_CREDITOR_ALIAS",
  DELETE_CREDITOR_ALIAS: "DELETE_CREDITOR_ALIAS",
};
