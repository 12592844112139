export default {
  GET_ALL: "GET_ALL",
  GETBYID: "GETBYID",
  SAVE_EMPLOYEE: "SAVE_EMPLOYEE",
  UPDATE_EMPLOYEE: "UPDATE_EMPLOYEE",
  UPDATE_RETIRE: "UPDATE_RETIRE",
  SET_EMPLOYEES: "SET_EMPLOYEES",
  CREATE_ENDPOINT: "CREATE_ENDPOINT",
  DELETE_ENDPOINT: "DELETE_ENDPOINT",
  GET_COMPANY_DIDS: "GET_COMPANY_DIDS",
  SET_COMPANY_DIDS: "SET_COMPANY_DIDS",
  UPDATE_DID: "UPDATE_DID",
  UNASSIGN_DID: "UNASSIGN_DID",
  DELETE_VM_TEMPLATES: "DELETE_VM_TEMPLATES",
  UPDATE_VM_BOX_ENABLED: "UPDATE_VM_BOX_ENABLED",
  GET_ATTORNEY: "GET_ATTORNEY",
  SAVE_ATTORNEY_SETTING: "SAVE_ATTORNEY_SETTING",
};
