import types from "./types";
import api from "@/services/client/customerApi";
import to from "@/helpers/to";
export default {
  state: {
    taskItem: null,
  },

  actions: {
    GET_TASK_DISTRO: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.distributeCsrTask(payload));

      return [err, result];
    },
    RESET_TASK_ITEM: async ({ commit }, payload) => {
      commit(types.SET_TASK_DISTRO, null);
    },
    UPDATE_CSR_TASK_NOTE: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.csrTaskUpdateNote(payload));
      return [err, result];
    },
    GET_TASK_DETAIL: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.getTask(payload));
      return [err, result];
    },
  },
  mutations: {
    SET_TASK_DISTRO: (state, payload) => {
      state.taskItem = payload;
    },
  },
};
