import types from "./types";
import api from "@/services/admin/api";
import to from "@/helpers/to";
export default {
  state: {
    creditorList: [],
    creditorAliastList: [],
  },
  actions: {
    GET_USER_ACTIVITY_CREDITOR: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.getCreditorAndPoaReport(payload));
      return [err, result];
    },

    GET_CREDITOR_SETTINGS: async ({ commit }) => {
      let err, result;
      [err, result] = await to(api.getCreditorSettings());
      if (result) {
        commit(types.SET_CREDITOR_SETTINGS, result.Data);
      }
    },
    CREATE_CREDITOR_SETTINGS: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.createCreditorSettings(payload));
      return [err, result];
    },
    UPDATE_CREDITOR_SETTINGS: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.updateCreditorSettings(payload));
      return [err, result];
    },
    DELETE_CREDITOR_SETTINGS: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.deleteCreditorSettings(payload));
      return [err, result];
    },

    GET_CREDITOR_ALIAS: async ({ commit }) => {
      let err, result;
      [err, result] = await to(api.getCreditorAlias());
      if (result) {
        commit(types.SET_CREDITOR_ALIAS, result.Data);
      }
    },
    CREATE_CREDITOR_ALIAS: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.createCreditorAlias(payload));
      return [err, result];
    },
    UPDATE_CREDITOR_ALIAS: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.updateCreditorAlias(payload));
      return [err, result];
    },
    DELETE_CREDITOR_ALIAS: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.deleteCreditorAlias(payload));
      return [err, result];
    },
  },
  mutations: {
    SET_CREDITOR_SETTINGS: (state, payload) => {
      state.creditorList = payload;
    },
    SET_CREDITOR_ALIAS: (state, payload) => {
      state.creditorAliastList = payload;
    },
  },
};
