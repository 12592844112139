<template>
  <div
    class="col-md-12 newPanelWrapper noMargin"
    style="
      background-color: #ffffff;
      z-index: 1000;
      padding-left: 0;
      padding-right: 0;
    "
  >
    <div>
      <div>
        <div
          class="col-md-12"
          style="position: relative; top: -10px; left: 10px"
        >
          <label for="Title" class="generalLabel">Decline</label>
        </div>
        <div class="closeButtonDR" v-on:click.prevent.stop="closePanel">
          <i class="fas fa-window-close"></i>
        </div>
      </div>
    </div>
    <perfect-scrollbar
      style="
        width: 100%;
        margin: 5px 0;
        padding: 0px 10px 0px 10px;
        max-height: 800px;
        overflow-x: hidden !important;
      "
    >
      <div>
        <div class="form-group col-md-12 pt-3">
          <div>
            <label class="generalLabel">Please describe the reason...</label>
          </div>
          <div>
            <textarea
              v-model="reason"
              id="noteTextEditor"
              class="form-control textareaInput generalTextBox"
              rows="7"
            ></textarea>
          </div>
        </div>
      </div>

      <div class="col-md-12">
        <button
          @click="decline()"
          id="saveAllIcon"
          type="button"
          class="btn btn-success col-md-12 mt-3"
        >
          Submit
        </button>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
import types from "./types";
import utilitiesMixin from "@/mixins/utilitiesMixin";
export default {
  name: "DeclineSettlementReview",
  mixins: [utilitiesMixin],
  props: {
    taskId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      reason: "",
    };
  },
  computed: {},
  mounted() {},
  methods: {
    closePanel() {
      this.$emit("closeDeclineReviewPopup");
    },

    async decline() {
      if (this.reason.trim().length == 0) {
        this.$swal("Warning!", "Reason field is required.", "warning");
        return;
      } else if (this.reason.trim().length < 5) {
        this.$swal(
          "Warning!",
          "Minimum 5 characters required in the reason.",
          "warning"
        );
        return;
      }

      if (this.taskId > 0) {
        let err, result;
        [err, result] = await this.$store.dispatch(
          types.ATTORNEY_TASK_DECLINE,
          {
            Id: this.taskId,
            Reason: this.reason,
          }
        );

        if (result) {
          this.$swal("Success!", "", "success");

          this.$emit("reviewDeclined");
          this.closePanel();
        } else {
          let errMsg = this.getApiErrorMessage(err);
          this.$swal("Error!", errMsg, "error");
        }
      } else {
        this.$swal("Error", "Invalid Task Id !", "error");
      }
    },
  },
};
</script>

<style scoped>
.closeButtonDR {
  position: absolute;
  top: -0px;
  right: 10px;
  z-index: 1000;
  font-size: 30px;
  cursor: pointer;
}

.closeButtonDR:hover {
  color: #ff3860;
}
</style>
