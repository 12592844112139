<template>
  <div
    v-if="htmlLoaded"
    v-html="content"
    style="width: 100%; font-size: 10px !important"
  ></div>
  <div
    v-if="!htmlLoaded"
    v-html="content"
    style="width: 100%"
    class="p-2 text-center"
  ></div>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import deepCopy from "@/helpers/deepCopy";
export default {
  name: "ClientCreditorsHoverPopup",
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      content: "No Post-Enrollment Debt Changes Found.",
      htmlLoaded: false,
    };
  },
  mounted() {
    let logList = [];
    let headers = [];
    if (this.infoList && this.infoList.length > 0) {
        let creditorId = this.infoList[0].CreditorId;
      if (this.infoList.map((x) => x.LogsList).length > 0) {
        logList = deepCopy(this.infoList.map((x) => x.LogsList))[0].sort(
          (a, b) => new Date(b.EventDate) - new Date(a.EventDate)
        );
        headers = Object.keys(logList[0]);
      }

      this.content = this.createContent(logList, headers,creditorId);
    }
  },
  methods: {
    createContent(logList, headers,creditorId) {
      let rtn = this.content;
      if (logList.length > 0 && headers.length > 0) {
        this.htmlLoaded = true;
        rtn =
          "<table class='custom-table' style='width:100%; font-size: 10px !important'>";
        rtn += "<thead style='background-color:#fafafa'>";
        rtn += "<tr>";
        if(this.checkAuth(81406) && headers.length>0 && creditorId){
            rtn += "<th style='border: none;'>" + "ID" + "</th>";
        }
        for (let item of headers) {
          if (item == "EventType") {
            rtn += "<th style='border: none;'>" + "Event Type" + "</th>";
          } else if (item == "EventDate") {
            rtn += "<th style='border: none;'>" + "Event Date" + "</th>";
          } else if (item == "CreditorName") {
            rtn += "<th style='border: none;'>" + "Creditor Name" + "</th>";
          } else if (item == "PrevDebtAmount") {
            rtn += "<th style='border: none;'>" + "Prev Debt Amount" + "</th>";
          } else if (item == "NewDebtAmount") {
            rtn += "<th style='border: none;'>" + "New Debt Amount" + "</th>";
          } else {
            rtn += "<th style='border: none;'>" + item + "</th>";
          }
        }
        rtn += "</tr>";
        rtn += "</thead>";
        rtn += "<tbody>";
        logList.forEach((item) => {
          let columnNames = Object.keys(item);
          rtn += "<tr>";
            if(this.checkAuth(81406) && creditorId){
                rtn += "<td>" + creditorId.toString() + "</td>";
            }
          columnNames.forEach((c) => {
            if (c.toLocaleLowerCase() == "eventdate") {
              rtn += "<td>" + this.formatGridDate(item[c]) + "</td>";
            } else if (c.toLocaleLowerCase() == "prevdebtamount") {
              rtn += "<td>" + this.formatMoney(item[c]) + "</td>";
            } else if (c.toLocaleLowerCase() == "newdebtamount") {
              rtn += "<td>" + this.formatMoney(item[c]) + "</td>";
            } else {
              rtn += "<td>" + item[c] + "</td>";
            }
          });
          rtn += "</tr>";
        });
        rtn += "</tbody>";
        rtn += "</table>";
      }

      return rtn;
    },
  },
  props: {
    infoList: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style></style>
