<template>
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 noPadding noMargin">
    <div id="ssnLabelPanel" class="ssnLabelPanel" v-show="!isEditing">
      <label class="form-label ssnLabel" for="ssn">
        {{ formatSSN(newVal) }}
      </label>
      <i
        style="color: #24d171"
        class="bx bx-edit-alt pencilIcon"
        @click="editSsn()"
      ></i>
    </div>

    <div class="ssnEditPanel noMargin" v-show="isEditing">
      <input
        type="text"
        id="ssnOfSsnController"
        v-model="newVal"
        class="form-control ssnTextBox generalTextBox"
        placeholder="SSN"
        @change="refreshValue"
        v-myMask="{ mask: ['999-99-9999'] }"
        :class="v$.newVal.$error ? 'has-error' : ''"
        @blur="v$.newVal.$touch"
      />
      <input type="hidden" id="ssnHiddenOfSsnController" v-model="newVal" />
      <span id="commandButtons" class="commandButtons">
        <i
          class="ri-check-line text-success"
          @click="saveValue(v$.$invalid)"
          :disabled="v$.$invalid"
        ></i>
        <i
          class="ri-close-line text-danger"
          @click="cancelValue()"
          style="color: red"
        ></i>
      </span>
    </div>
  </div>
</template>

<script>
import utilitiesMixin from "@/mixins/utilitiesMixin";
import formatMixin from "@/mixins/formatMixin";
import useValidate from "@vuelidate/core";
export default {
  name: "SsnController",
  mixins: [utilitiesMixin, formatMixin],
  data() {
    return {
      v$: useValidate(),

      isEditing: true,
      invalid: false,
      oldVal: "",
      newVal: "",
      isAuthorizedForSSN: true,
    };
  },
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  watch: {
    value(newVal, oldVal) {
      if (newVal != oldVal) {
        if (this.value) this.initialEncSSN();
      }
    },
  },
  methods: {
    emptyTheObjectsOnUnmount() {
      this.isEditing = null;
      this.invalid = null;
      this.oldVal = null;
      this.newVal = null;
      this.isAuthorizedForSSN = null;
    },
    refreshValue(event) {
      if (event.target.value && event.target.value != "") {
        this.newVal = event.target.value;
      }
    },

    editSsn() {
      if (!this.isAuthorizedForSSN) {
        this.newVal = "";
        this.invalid = true;
      }

      this.isEditing = true;
    },
    async saveValue(isInvalid) {
      if (isInvalid) return;
      this.isEditing = false;
      this.oldVal = this.newVal;

      let myvalue = await this.AES256_GCM_ENCRYPT(this.newVal);

      if (!this.isAuthorizedForSSN) this.newVal = this.getLast4(this.newVal);
      this.$emit("ssnChanged", myvalue);
    },
    cancelValue() {
      if (this.value == null || this.value == "") return;
      this.newVal = this.oldVal;
      if (!this.isAuthorizedForSSN) this.newVal = this.getLast4(this.newVal);
      this.isEditing = false;
    },
    getLast4(deg) {
      if (deg.length <= 4) {
        return deg;
      }

      return deg.substring(deg.length - 4, deg.length);
    },
    ssnAuth() {
      let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      let arrPermission = userInfo.moduleFunctions
        .split(",")
        .map(function (item) {
          return parseInt(item, 10);
        });

      if (!arrPermission.includes(7521)) {
        this.isAuthorizedForSSN = false;
      } else {
        this.isAuthorizedForSSN = true;
      }
    },
    async initialEncSSN() {
      let decryptedVal = "";
      this.ssnAuth();

      if (this.value && this.value != "") {
        decryptedVal = await this.AES256_GCM_decrypt(this.value);
      }

      if (decryptedVal != "") {
        this.invalid = false;
        this.isEditing = false;
        if (!this.isAuthorizedForSSN) {
          this.newVal = this.getLast4(decryptedVal);
        } else this.newVal = decryptedVal;
        this.oldVal = this.newVal;
      } else {
        this.newVal = "";
        this.oldVal = "";
        this.invalid = true;
        this.isEditing = true;
      }
    },
  },
  unmounted() {
    // this.emptyTheObjectsOnUnmount();
  },
  async mounted() {
    if (this.value) this.initialEncSSN();
  },
  validations() {
    return {
      newVal: {
        isSSN(val) {
          return val.length == 9;
        },
      },
    };
  },
};
</script>

<style scoped>
.ssnLabelPanel {
  width: auto;
  float: left;
}
.ssnTextBox {
  width: 150px !important;
  float: left;
}
.commandButtons {
  font-size: 24px;
  float: left;
  margin: 3px;
  font-weight: bold;
  cursor: pointer;
}
.ssnLabel {
  width: auto;
  float: left;
  font-size: 16px;
  line-height: 30px;
}
.pencilIcon {
  font-size: 20px;
  margin: 3px 5px;
  color: #3bafda;
  cursor: pointer;
}
</style>
