<template>
  <Popover :height="160">
    <template #header>
      <div class="container">Cold Transfer Options</div>
    </template>
    <template #body>
      <div class="contact-list cold-transfer">
        <p
          v-if="!checkAuth(100030)"
          class="contact"
          :class="{ selected: selectedTransferType === 1 }"
          @click="transferToAgent"
        >
          Transfer to Agent
        </p>
        <p
          class="contact"
          :class="{ selected: selectedTransferType === 2 }"
          @click.prevent.stop="transferToNumber"
        >
          Transfer to Number
        </p>
        <p
          v-if="!checkAuth(100030)"
          class="contact"
          :class="{ selected: selectedTransferType === 3 }"
          @click.prevent.stop="transferToAgentVm"
        >
          Transfer to Agent VM
        </p>
        <p
          v-if="!checkAuth(100030)"
          class="contact"
          :class="{ selected: selectedTransferType === 4 }"
          @click.prevent.stop="transferToDepartment"
        >
          Transfer to Department
        </p>
      </div>
    </template>
  </Popover>
</template>

<script>
import Popover from "../../Popover";
import utilitiesMixin from "@/mixins/utilitiesMixin";

export default {
  components: {
    Popover,
  },
  props: {
    selectedTransferType: {
      type: Number,
      default: () => 0,
    },
  },
  mixins: [utilitiesMixin],
  methods: {
    transferToAgent() {
      this.$emit("transfer", 1);
    },
    transferToNumber() {
      this.$emit("transfer", 2);
    },
    transferToAgentVm() {
      this.$emit("transfer", 3);
    },
    transferToDepartment() {
      this.$emit("transfer", 4);
    },
  },
  emits: ["transfer"],
};
</script>
