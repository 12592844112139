<template>
  <div class="row admin-page officePage">
    <div class="col-xl-3 col-lg-4 col-md-12 col-sm-12 panelWrapper">
      <button
        type="button"
        class="col-md-12 btn btn-primary waves-effect waves-light"
        v-on:click.prevent="handleAddStatus"
      >
        Add New Status
      </button>
      <div class="filter form-group row">
        <div class="col-lg-12 col-md-12 col-sm-12 noPadding">
          <input
            autocomplete="off"
            type="text"
            class="form-control"
            v-model="statusFilter"
            placeholder="Filter status"
          />
        </div>
      </div>
      <div class="list col-md-12" style="max-height: 592px">
        <div
          class="office item row"
          v-for="(status, index) in filteredStatus"
          :key="status.Id"
          v-on:click.prevent="() => handleStatusSelection(status, index)"
          :disabled="saving || deleting"
          :style="[
            index % 2 === 0
              ? { 'background-color': '#F1EFEF' }
              : { 'background-color': '#FFFFFF' },
          ]"
        >
          <span :class="{ adminActiveMenuItem: activeStatus === status.Id }">
            <i class="mdi mdi-sync"></i>
            <span style="margin-left: 8px">{{ status.StatusName }}</span>
          </span>
        </div>
      </div>
      <div
        v-if="isMobileDevice()"
        style="
          border-top: 1px solid rgb(0, 0, 0, 0);
          margin: 20px 0 0 9px;
          padding-bottom: 20px;
        "
        class="col-md-12 scrollToSelectedItem"
      ></div>
    </div>
    <div class="col-xl-9 col-lg-8 col-md-12 col-sm-12">
      <form
        v-on:keydown.enter.prevent="() => {}"
        v-if="isEditing"
        class="form-horizontal formWrapper panelWrapper float-left"
        role="form"
      >
        <div class="panelContent">
          <div class="form-group row">
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
              <label class="form-label" for="txtStatusName"
                >Status Name *</label
              >
            </div>
            <div class="col-xl-6 col-lg-9 col-md-9 col-sm-9 col-xs-12">
              <input
                autocomplete="off"
                type="text"
                id="txtStatusName"
                class="form-control"
                v-model="selectedRow.StatusName"
                :class="v$.selectedRow.StatusName.$error ? 'has-error' : ''"
                @blur="v$.selectedRow.StatusName.$touch"
                placeholder="Enter status name"
              />
            </div>
          </div>

          <div class="form-group row">
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
              <label class="form-label">Priority *</label>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
              <input
                autocomplete="off"
                type="number"
                min="1"
                max="1000"
                @change="
                  () => {
                    if (
                      selectedRow.Priority < 1 ||
                      selectedRow.Priority > 1000
                    ) {
                      selectedRow.Priority = 1;
                    }
                  }
                "
                id="numPriority"
                class="form-control"
                v-model="selectedRow.Priority"
                placeholder="0"
              />
            </div>
          </div>

          <div class="form-group row">
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
              <label class="form-label">Copy Settings From</label>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <select
                v-model="copySettings"
                id="genderInput"
                class="form-control"
              >
                <option value>Please Select One</option>
                <option
                  v-for="item in sortedStatusList.filter(
                    (x) => x.Id != selectedRow.Id
                  )"
                  :key="item.Id"
                  :value="item"
                >
                  {{ item.StatusName }}
                </option>
              </select>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3">
              <button
                type="button"
                v-on:click.prevent="handleCopySettings"
                class="btn btn-primary waves-effect waves-light float-right widthFull"
              >
                Apply
              </button>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mt-4">
              <label class="form-label" for="lblColor">Status Color *</label>
              <div
                id="taskColor"
                class="ColorPickerBox rounded"
                @click="OpenNewColorPicker()"
                :style="'background-color: ' + selectedRow.StatusColor"
              ></div>

              <NewColorPicker
                v-if="isNewColorPickerOpen"
                @updateColor="updateColor"
                :color="color"
                id="ColorPicker"
              />
            </div>
            <div class="col-xl-3 col-lg-6 col-md-12 col-sm-6 mt-4">
              <label class="form-label"
                >Redist Minutes *
                <a style="font-size: 10px; margin-left: 10px"
                  >(0: do not distribute)</a
                >
              </label>
              <input
                autocomplete="off"
                type="number"
                min="0"
                @change="
                  () => {
                    if (selectedRow.RedistMinutes < 0) {
                      selectedRow.RedistMinutes = 0;
                    }
                  }
                "
                id="numRedistMinutes"
                class="form-control"
                v-model="selectedRow.RedistMinutes"
                placeholder="0"
              />
            </div>
          </div>

          <div class="form-group row mt-2">
            <div class="col-md-12 row">
              <div class="col-xl-2 col-lg-12 col-md-6 col-sm-12 noPadding">
                <div style="width: 20px; float: left" class="ml-2 mr-1">
                  <input
                    type="checkbox"
                    v-model="selectedRow.IsSold"
                    :id="selectedRow.Id"
                    class="form-control"
                  />
                </div>
                <div
                  style="
                    width: auto;
                    float: left;
                    margin: 5px 0px;
                    font-size: 16px;
                  "
                >
                  <span class="badge badge-success">Enrolled Status</span>
                </div>
              </div>
              <div class="col-xl-2 col-lg-12 col-md-6 col-sm-12 noPadding">
                <div style="width: 20px; float: left" class="ml-2 mr-1">
                  <input
                    type="checkbox"
                    v-model="selectedRow.IsXchange"
                    :id="selectedRow.Id"
                    class="form-control"
                  />
                </div>
                <div
                  style="
                    width: auto;
                    float: left;
                    margin: 5px 0px;
                    font-size: 16px;
                  "
                >
                  <span class="badge badge-success">XChange Status</span>
                </div>
              </div>
            </div>
            <div class="col-md-12 row">
              <div class="col-xl-2 col-lg-12 col-md-6 col-sm-12">
                <div style="width: 20px; float: left" class="mr-1">
                  <input
                    type="checkbox"
                    v-model="selectedRow.IsDoNotCall"
                    :id="selectedRow.Id"
                    class="form-control"
                  />
                </div>
                <div
                  style="
                    width: auto;
                    float: left;
                    margin: 5px 0px;
                    font-size: 16px;
                  "
                >
                  <span class="badge badge-danger">Do not call</span>
                </div>
              </div>
              <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 noPadding">
                <div style="width: 20px; float: left" class="ml-2 mr-1">
                  <input
                    type="checkbox"
                    class="form-control"
                    v-model="selectedRow.IsBlockMakingChange"
                    :id="selectedRow.Id"
                  />
                </div>
                <div
                  style="
                    width: auto;
                    float: left;
                    margin: 5px 0px;
                    font-size: 16px;
                  "
                >
                  <span class="badge badge-danger"
                    >Prevents certain roles from making changes</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row mt-2"></div>
          <div class="col-xl-9 col-lg-12 col-md-12 col-xs-12 legend">
            <h4>Priority Settings</h4>
            <div class="form-group row">
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <label class="form-label" for="lblPriorityText"
                  >Priority Text *</label
                >
              </div>
              <div class="col-xl-6 col-lg-9 col-md-9 col-sm-9 col-xs-12">
                <input
                  autocomplete="off"
                  type="text"
                  id="txtPriorityText"
                  class="form-control"
                  v-model="selectedRow.PriorityText"
                  :class="v$.selectedRow.PriorityText.$error ? 'has-error' : ''"
                  @blur="v$.selectedRow.PriorityText.$touch"
                  placeholder="Priority Text"
                />
              </div>
            </div>
            <div class="form-group row">
              <div class="col-xl-3 col-lg-3 col-md-6 col-xs-12 alignRight">
                <label class="form-label" for="lblPriorityDt"
                  >Priority Date Filter *</label
                >
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-xs-12 alignRight">
                <label class="form-label">
                  <u>Last disposition date greater than:</u>
                </label>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-xl-3 col-lg-4 col-md-6 col-xs-12"></div>
              <div class="col-xl-6 col-lg-8 col-md-6 col-xs-12">
                <input
                  autocomplete="off"
                  type="text"
                  id="txtPriorityHr"
                  class="numericInput"
                  v-model="selectedRow.PriorityAfterDays"
                  v-myMask="{
                    alias: 'numeric',
                    min: 0,

                    digits: 0,
                    allowMinus: false,
                    rightAlign: false,
                  }"
                />
                <label class="form-label">Days and</label>

                <input
                  autocomplete="off"
                  type="text"
                  id="txtPriorityHr"
                  class="numericInput"
                  v-model="selectedRow.PriorityAfterHours"
                  v-myMask="{
                    alias: 'numeric',
                    min: 0,

                    digits: 0,
                    allowMinus: false,
                    rightAlign: false,
                  }"
                />
                <label class="form-label">Hours</label>
              </div>
            </div>
          </div>
          <div class="col-xl-9 col-lg-12 col-md-12 col-xs-12 legend mt-4 pb-2">
            <h4>Timed Disposition Settings</h4>
            <div class="form-group row">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <label
                  class="form-label"
                  style="width: 120px"
                  for="lblPriorityText"
                  >Disposition:</label
                >
                <select
                  id="cniAutoDispositionId"
                  class="generalSelectBox"
                  style="width: 300px; margin: 0 10px"
                  v-model="selectedRow.TimedDispositionId"
                >
                  <option :value="null">Do not auto timed disposition</option>
                  <option
                    v-for="item in filteredDispos"
                    :key="item.Id"
                    :value="item.Id"
                  >
                    {{ item.Name }}
                  </option>
                </select>
              </div>
            </div>
            <div
              class="form-group row"
              v-if="selectedRow.TimedDispositionId > 0"
            >
              <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 alignRight">
                <label style="width: 120px" class="form-label"
                  >Triggered after</label
                >
                <input
                  autocomplete="off"
                  type="text"
                  id="txtPriorityHr"
                  class="numericInput"
                  v-model="selectedRow.TimedDispositionHour"
                  v-myMask="{
                    alias: 'numeric',
                    min: 1,
                    max: 99999,
                    digits: 0,
                    allowMinus: false,
                    rightAlign: false,
                  }"
                />
                <label class="form-label">hour(s)</label>
              </div>
            </div>
          </div>

          <div
            v-if="selectedRow.Id > 0"
            class="col-md-12 noPadding row mt-2 mb-2"
          >
            <button
              @click="dispositionDetails"
              type="button"
              class="btn btn-sm btn-primary waves-effect waves-light"
            >
              Available Dispositions
              <span class="btn-label-right">
                <i
                  v-if="isAvailableDispositions == false"
                  class="fas fa-angle-down"
                ></i>
                <i
                  v-if="isAvailableDispositions == true"
                  class="fas fa-angle-up"
                ></i>
              </span>
            </button>
          </div>

          <div class="form-group row">
            <div class="col-xl-9 col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <button
                type="button"
                v-on:click.prevent="handleSave"
                :disabled="v$.$invalid || saving || deleting"
                class="animated-btn btn btn-success btn-bordered-success waves-effect width-md waves-light submit-button float-right"
              >
                <i v-if="saving" class="fas fa-spinner mr-1"></i>
                Save
              </button>
            </div>
          </div>

          <div
            class="col-xl-12 col-lg-12 col-md-12 col-xs-12 datagrid-table gcsTabsTableScroll"
            v-if="isAvailableDispositions"
          >
            <o-table :data="filteredForCustomerStatusList">
              <o-table-column
                field="DispositionName"
                label="Disposition Name"
                sortable
                searchable
              >
                <template v-slot:searchable>
                  <div>
                    <input
                      type="text"
                      class="datagrid-table creditorTable"
                      v-model="DispositionNameFilter"
                      placeholder=""
                    />
                  </div>
                </template>

                <template v-slot="props">
                  <strong v-if="props.row.IsSummary"
                    >{{ props.row.DispositionName }}
                  </strong>
                  <span v-if="!props.row.IsSummary">
                    {{ props.row.DispositionName }}
                  </span>
                </template>
              </o-table-column>

              <o-table-column
                field="ToStatus"
                label="To Status"
                sortable
                searchable
              >
                <template v-slot:searchable>
                  <div>
                    <input
                      type="text"
                      class="datagrid-table creditorTable"
                      v-model="ToStatusFilter"
                      placeholder=""
                    />
                  </div>
                </template>

                <template v-slot="props">
                  {{ props.row.ToStatus }}
                </template>
              </o-table-column>

              <o-table-column field="Roles" label="Roles" sortable searchable>
                <template v-slot:searchable>
                  <div>
                    <input
                      type="text"
                      class="datagrid-table creditorTable"
                      v-model="RolesFilter"
                      placeholder=""
                    />
                  </div>
                </template>

                <template v-slot="props">
                  {{ props.row.Roles.toString() }}
                </template>
              </o-table-column>
            </o-table>
            <section
              v-if="filteredForCustomerStatusList.length == 0"
              class="section"
              style="text-align: center"
            >
              <div v-if="isLoading" class="loading">
                <div class="text-center" style="margin-top: 50px">
                  <i class="spinner-grow text-info m-2"></i>
                </div>
              </div>
              <div class="content has-text-grey has-text-centered mb-4">
                <p class="noMargin">
                  <i
                    style="font-size: 24px; color: #939393"
                    class="ri-question-line"
                  ></i>
                </p>
                <p class="noMargin">Nothing here.</p>
              </div>
            </section>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import types from "./types";
import globalTypes from "@/store/types";
import _orderby from "lodash";
import deepCopy from "@/helpers/deepCopy";
import useValidate from "@vuelidate/core";
import { required } from "vuelidate/lib/validators";
import NewColorPicker from "@/components/NewColorPicker.vue";
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
const init = {
  Id: "",
  StatusName: "",
  Priority: "",
  StatusColor: "#A54F4F",
  PriorityText: "",
  PriorityAfterDays: "",
  PriorityAfterHours: "",
  IsDoNotCall: false,
  IsSold: false,
  IsXchange: false,
  IsBlockMakingChange: false,
  RedistMinutes: "",
  TimedDispositionId: null,
  TimedDispositionHour: null,
};

export default {
  name: "CustomeStatusManagement",
  colors: "#B95F5F",
  data() {
    return {
      oldStatusId: 0,
      forCustomerStatusList: [],
      isLoading: false,
      isAvailableDispositions: false,

      selectedRow: Object.assign({}, init),
      v$: useValidate(),
      copySettings: "",
      isEditing: false,
      saving: false,
      deleting: false,
      activeStatus: null,
      statusFilter: "",
      oldValue: "",
      valueToRevert: deepCopy(init),
      isCancelledBeforeSave: false,
      isNewColorPickerOpen: false,

      forCustomerStatusSummary: {
        DispositionName: "",
        ToStatus: "",
        Roles: "",
      },

      DispositionNameFilter: "",
      ToStatusFilter: "",
      RolesFilter: "",
    };
  },
  components: {
    NewColorPicker,
  },
  mixins: [formatMixin, utilitiesMixin],
  computed: mapState({
    // forCustomerStatusList: (state) =>
    //   state.statusManagement.forCustomerStatusList,
    statusList: (state) => state.statusManagement.list,
    sortedStatusList() {
      return _orderby.orderBy(this.statusList, "StatusName", "asc");
    },
    filteredStatus() {
      return _orderby.orderBy(
        this.statusList.filter(
          (item) =>
            item.StatusName.toLowerCase().indexOf(
              this.statusFilter.toLowerCase()
            ) > -1
        ),
        "Priority"
      );
    },
    dispoList: (state) => state.globals.dispositionOptionList,
    filteredDispos() {
      return this.dispoList.length > 0
        ? _orderby.orderBy(
            this.dispoList.filter(
              (item) =>
                item.FromStatuses.map((x) => parseInt(x)).includes(
                  this.selectedRow.Id
                ) || item.FromStatuses == ""
            ),
            "Name",
            "asc"
          )
        : [];
    },

    filteredForCustomerStatusList() {
      let rtn = [];

      let tmpList =
        this.forCustomerStatusList.length > 0
          ? this.forCustomerStatusList.filter((item) => {
              return (
                this.filterString(
                  item.DispositionName,
                  this.DispositionNameFilter
                ) &&
                this.filterString(item.ToStatus, this.ToStatusFilter) &&
                this.filterString(item.Roles, this.RolesFilter)
              );
            })
          : [];

      if (tmpList.length == 0) {
        // this.reportData = [];
        return rtn;
      }
      let totalName = tmpList.length.toString();

      this.forCustomerStatusSummary.DispositionName = "Total: " + totalName;
      // this.recordCount = tmpList.length;
      this.forCustomerStatusSummary.IsSummary = true;

      rtn.push(...tmpList);
      rtn.push(this.forCustomerStatusSummary);
      // this.reportData = rtn;

      return rtn;
    },
  }),
  async mounted() {
    await Promise.all([
      this.$store.dispatch(types.GET_STATUSES),
      this.$store.dispatch(globalTypes.GET_DISPOSITION_OPTIONS_FOR_ADMIN),
    ]);
  },

  methods: {
    async dispositionDetails() {
      this.isAvailableDispositions = !this.isAvailableDispositions;
      if (this.selectedRow.Id != this.oldStatusId) {
        this.isLoading = true;
        let err, result;

        [err, result] = await this.$store.dispatch(
          types.GET_FOR_CUSTOMER_STATUS_MANAGEMENT,
          this.selectedRow.Id
        );

        if (result) {
          this.forCustomerStatusList = deepCopy(result.Data);
          this.oldStatusId = this.selectedRow.Id;
        }
        this.isLoading = false;
      }
    },

    async handleAddStatus() {
      if (
        this.oldValue != JSON.stringify(this.selectedRow) &&
        this.oldValue != "" &&
        JSON.stringify(this.selectedRow) != JSON.stringify(init)
      ) {
        await this.$swal
          .fire({
            title: "You have unsaved changes",
            text: "You will lose it if you don't save",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, continue without saving!",
          })
          .then(async (dialog) => {
            if (dialog.value) {
              this.isCancelledBeforeSave = true;
            } else {
              this.isCancelledBeforeSave = false;
              return;
            }
          });
      } else {
        this.selectedRow = { ...init };
        this.activeStatus = null;
        this.isEditing = true;

        this.isAvailableDispositions = false;
      }

      if (this.isCancelledBeforeSave) {
        this.selectedRow = deepCopy(this.valueToRevert);
        let indx = this.statusList.findIndex(
          (x) => x.Id == this.valueToRevert.Id
        );
        this.statusList[indx] = this.valueToRevert;
        this.statusList.push();
        this.copySettings = "";
        this.isEditing = true;
        this.selectedRow = { ...init };
        this.activeStatus = null;
      }
      this.isCancelledBeforeSave = false;
    },
    async handleSave() {
      if (this.selectedRow.TimedDispositionId > 0) {
        this.selectedRow.TimedDispositionHour =
          this.selectedRow.TimedDispositionHour == ""
            ? null
            : this.selectedRow.TimedDispositionHour;
        if (this.selectedRow.TimedDispositionHour == null) {
          this.$swal.fire(
            "Warning!",
            "Timed Disposition Hour is required for selected disposition.",
            "warning"
          );
          return;
        }
      } else {
        this.selectedRow.TimedDispositionHour = null;
      }

      if (this.oldValue == JSON.stringify(this.selectedRow)) {
        this.$swal.fire("Warning!", "No Changes Detected!", "warning");

        return;
      }
      this.saving = true;

      let err, result, type;

      let isStatusExists =
        this.statusList.filter(
          (x) =>
            x.StatusName.toLowerCase() ==
            this.selectedRow.StatusName.toLowerCase()
        ).length > 0;

      if (this.selectedRow.Id) {
        type = types.UPDATE_STATUS;
        isStatusExists =
          this.statusList.filter(
            (x) =>
              x.StatusName.toLowerCase() ==
              this.selectedRow.StatusName.toLowerCase()
          ).length > 1;
      } else {
        type = types.INSERT_STATUS;
      }
      if (isStatusExists) {
        this.saving = false;
        this.$swal.fire(
          "Warning!",
          "This status name is already exists in list!",
          "warning"
        );
        this.saving = false;
        return;
      }

      [err, result] = await this.$store.dispatch(type, this.selectedRow);

      if (result) {
        this.$swal.fire("Success!", result.Message, "success");
        if (type == types.INSERT_STATUS) {
          this.selectedRow.Id = result.Data.Id;
          this.statusList.push(this.selectedRow);
          this.oldValue = JSON.stringify(this.selectedRow);
          this.activeStatus = result.Data.Id;
        } else {
          this.activeStatus = this.selectedRow.Id;
          this.oldValue = JSON.stringify(this.selectedRow);
        }
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
      this.saving = false;
    },

    handleCancel() {
      this.isEditing = false;
    },
    async handleStatusSelection(row, index) {
      if (
        this.oldValue != JSON.stringify(this.selectedRow) &&
        this.oldValue != "" &&
        JSON.stringify(this.selectedRow) != JSON.stringify(init)
      ) {
        await this.$swal
          .fire({
            title: "You have unsaved changes",
            text: "You will lose it if you don't save",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, continue without saving!",
          })
          .then(async (dialog) => {
            if (dialog.value) {
              this.isCancelledBeforeSave = true;
            } else {
              this.isCancelledBeforeSave = false;
              return;
            }
          });
      } else {
        this.valueToRevert = deepCopy(row);
        this.oldValue = JSON.stringify(row);
        this.activeStatus = row.Id;
        this.copySettings = "";
        this.selectedRow = row;
        this.isEditing = true;
        this.isAvailableDispositions = false;
      }
      if (this.isCancelledBeforeSave) {
        this.selectedRow = deepCopy(this.valueToRevert);
        let indx = this.statusList.findIndex(
          (x) => x.Id == this.valueToRevert.Id
        );
        this.statusList[indx] = this.valueToRevert;
        this.statusList.push();
        this.activeStatus = row.Id;
        this.selectedRow = row;
        this.valueToRevert = deepCopy(row);
        this.oldValue = JSON.stringify(row);
        this.copySettings = "";
        this.isEditing = true;
        this.isAvailableDispositions = false;
      }
      this.isCancelledBeforeSave = false;

      let el = this.$el.querySelector(".scrollToSelectedItem");
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
    handleCopySettings() {
      if (this.copySettings.length <= 0) return;

      let temp = Object.assign({}, this.copySettings);
      if (temp) {
        this.selectedRow.Priority = temp.Priority;
        this.selectedRow.StatusColor = temp.StatusColor;
        this.selectedRow.PriorityText = temp.PriorityText;
        this.selectedRow.PriorityAfterDays = temp.PriorityAfterDays;
        this.selectedRow.PriorityAfterHours = temp.PriorityAfterHours;
        this.selectedRow.IsDoNotCall = temp.IsDoNotCall;
        this.selectedRow.RedistMinutes = temp.RedistMinutes;
      }
    },
    reset() {
      this.activeStatus = null;
      //this.$store.dispatch(types.GET);
      this.selectedRow = { ...init };
      this.isEditing = false;
    },
    updateColor(value) {
      this.selectedRow.StatusColor = value;
      this.isNewColorPickerOpen = false;
    },
    OpenNewColorPicker() {
      this.isNewColorPickerOpen = true;
    },
  },
  validations() {
    return {
      selectedRow: {
        StatusName: { required },
        PriorityText: { required },
      },
    };
  },
};
</script>
<style>
.loading {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.6);
  z-index: 1000;
}
</style>
