let storageArr = ["userInfo", "expireUTC", "userPhoneSettings", "keyInfo"];
export default {
  methods: {
    storageSetItem(userId, storageName, storageVal) {
      if (storageArr.includes(storageName)) {
        sessionStorage.setItem(storageName + "-" + userId, storageVal);
      }
    },
    storageGetItem(userId, storageName) {
      if (storageArr.includes(storageName)) {
        return sessionStorage.getItem(storageName + "-" + userId);
      }
      return null;
    },
    storageClearSession(userId) {
      for (let item of storageArr) {
        sessionStorage.removeItem(item + "-" + userId);
      }
    },
  },
};
