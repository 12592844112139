<template>
  <div class="col-md-12">
    <ReportSummary
      :exportData="reportData"
      :reportName="'PurchasesClientInformationReport'"
      @updateLoadingStatus="reportLoading = $event"
      @setPayload="setPayload"
      @clearReport="clearReport"
      @setPagination="setPagination"
      :loadingSpinner="loadingSpinner"
    />

    <div
      class="datagrid-table gcsTabsTableScroll report-wrapper"
      :style="'max-height: ' + reportsPanelHeight + 'px; overflow-y:auto;'"
    >
      <o-table
        :data="filteredPurchasesClientInformationReport"
        :paginated="
          isPaginated && filteredPurchasesClientInformationReport.length > 0
        "
        :per-page="perPage"
        :pagination-simple="isPaginationSimple"
        :pagination-position="paginationPosition"
        :sticky-header="true"
      >
        <o-table-column field="ClientId" label="Client ID" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="ClientIdFilter"
                placeholder=""
                v-myMask="{
                  alias: 'numeric',
                  rightAlign: false,
                }"
              />
            </div>
          </template>

          <template v-slot="props">
            <span
              @click="viewCustomer(props.row.ClientId, props.row.CustomerName)"
              :class="
                getTagReportHyperLinkClass(
                  props.row.ClientId && props.row.CustomerName
                )
              "
            >
              <strong v-if="props.row.IsSummary"
                >{{ props.row.ClientId }}
              </strong>
              <span v-if="!props.row.IsSummary">
                {{ addPrefixToCustomerId(props.row.ClientId) }}
              </span>
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="CustomerName"
          label="Customer Name"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CustomerNameFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.CustomerName }}
          </template>
        </o-table-column>

        <o-table-column
          field="ClientStatus"
          label="Client Status"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="ClientStatusFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.ClientStatus }}
          </template>
        </o-table-column>

        <o-table-column field="State" label="State" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="StateFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.State }}
          </template>
        </o-table-column>

        <o-table-column field="Affiliate" label="Affiliate" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="AffiliateFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.Affiliate }}
          </template>
        </o-table-column>

        <o-table-column field="Campaign" label="Campaign" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CampaignFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.Campaign }}
          </template>
        </o-table-column>

        <o-table-column
          field="ClientStartDate"
          label="Client Start Date"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="ClientStartDateFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ formatShortDate(props.row.ClientStartDate) }}
          </template>
        </o-table-column>

        <o-table-column
          field="EstimatedTotalDebt"
          label="Estimated Total Debt"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="EstimatedTotalDebtFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.EstimatedTotalDebt }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatMoney(props.row.EstimatedTotalDebt) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="OriginalDebt"
          label="Original Total Debt"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="OriginalDebtFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.OriginalDebt }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatMoney(props.row.OriginalDebt) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="ProgramLength"
          label="Program Length"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="ProgramLengthFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.ProgramLength }}
          </template>
        </o-table-column>

        <o-table-column
          field="FirstDraftDate"
          label="First Draft Date"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="FirstDraftDateFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ formatShortDate(props.row.FirstDraftDate) }}
          </template>
        </o-table-column>

        <o-table-column
          field="ProgramPayment"
          label="Program Payment"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="ProgramPaymentFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.ProgramPayment }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatMoney(props.row.ProgramPayment) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column field="FeePercentage" label="Fee %" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="FeePercentageFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.FeePercentage }}
          </template>
        </o-table-column>

        <o-table-column
          field="PurchaseBy"
          label="Purchased By"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="PurchaseByFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.PurchaseBy }}
          </template>
        </o-table-column>

        <o-table-column
          field="PurchaseDate"
          label="Purchase Date"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="PurchaseDateFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ formatShortDate(props.row.PurchaseDate) }}
          </template>
        </o-table-column>

        <o-table-column
          field="ProgramConsultant"
          label="Program Consultant"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="ProgramConsultantFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.ProgramConsultant }}
          </template>
        </o-table-column>

        <o-table-column
          field="DmbFeeSplit"
          label="DMB Fee Split"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="DmbFeeSplitFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.DmbFeeSplit }}
          </template>
        </o-table-column>

        <o-table-column
          field=""
          label="View Customer"
          position="centered"
          v-slot="props"
        >
          <button
            @click="viewCustomer(props.row.ClientId, props.row.CustomerName)"
            class="btn btn-info waves-effect waves-light ml-3"
            v-if="!props.row.IsSummary"
          >
            <i class="far fa-address-card"></i>
          </button>
        </o-table-column>
      </o-table>
      <section
        v-if="filteredPurchasesClientInformationReport.length == 0"
        class="section"
        style="text-align: center"
      >
        <div class="content has-text-grey has-text-centered mb-4">
          <p class="noMargin">
            <i
              style="font-size: 24px; color: #939393"
              class="ri-question-line"
            ></i>
          </p>
          <p class="noMargin">Nothing here.</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import ReportSummary from "./ReportSummary.vue";
import utilitiesMixin from "@/mixins/utilitiesMixin";

export default {
  name: "PurchasesClientInformationReport",
  components: { ReportSummary },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      customerPrefix: null,
      reportsPanelHeight: 300,
      loadingSpinner: false,
      isPaginationSimple: false,
      isPaginated: true,
      paginationPosition: "both",
      currentPage: 1,
      perPage: 200,
      reportLoading: false,
      reportData: [],

      purchasesClientInformationReportInitSummary: {
        ClientId: "",
      },
      ClientIdFilter: "",
      CustomerNameFilter: "",
      ClientStatusFilter: "",
      StateFilter: "",
      AffiliateFilter: "",
      CampaignFilter: "",
      ClientStartDateFilter: "",
      EstimatedTotalDebtFilter: "",
      OriginalDebtFilter: "",
      ProgramLengthFilter: "",
      FirstDraftDateFilter: "",
      ProgramPaymentFilter: "",
      FeePercentageFilter: "",
      PurchaseByFilter: "",
      PurchaseDateFilter: "",
      ProgramConsultantFilter: "",
      DmbFeeSplitFilter: "",
    };
  },
  computed: mapState({
    purchaseClientInformationList: (state) =>
      state.reports.purchaseClientInformationList,

    filteredPurchasesClientInformationReport() {
      let rtn = [];

      let tmpList =
        this.purchaseClientInformationList.length > 0
          ? this.purchaseClientInformationList.filter((item) => {
              return (
                this.filterString(item.ClientId, this.ClientIdFilter) &&
                this.filterString(item.DmbFeeSplit, this.DmbFeeSplitFilter) &&
                this.filterString(item.CustomerName, this.CustomerNameFilter) &&
                this.filterString(item.ClientStatus, this.ClientStatusFilter) &&
                this.filterString(item.State, this.StateFilter) &&
                this.filterString(item.Affiliate, this.AffiliateFilter) &&
                this.filterString(item.Campaign, this.CampaignFilter) &&
                this.filterString(
                  item.EstimatedTotalDebt,
                  this.EstimatedTotalDebtFilter
                ) &&
                this.filterString(item.OriginalDebt, this.OriginalDebtFilter) &&
                this.filterString(
                  item.ProgramLength,
                  this.ProgramLengthFilter
                ) &&
                this.filterString(
                  item.ProgramPayment,
                  this.ProgramPaymentFilter
                ) &&
                this.filterString(
                  item.FeePercentage,
                  this.FeePercentageFilter
                ) &&
                this.filterString(item.PurchaseBy, this.PurchaseByFilter) &&
                this.filterString(
                  item.ProgramConsultant,
                  this.ProgramConsultantFilter
                ) &&
                new Date(item.ClientStartDate)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .indexOf(this.ClientStartDateFilter) > -1 &&
                new Date(item.FirstDraftDate)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .indexOf(this.FirstDraftDateFilter) > -1 &&
                new Date(item.PurchaseDate)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .indexOf(this.PurchaseDateFilter) > -1
              );
            })
          : [];

      if (tmpList.length == 0) {
        this.reportData = [];
        return rtn;
      }
      let totalCustomer = tmpList.length.toString();

      this.purchasesClientInformationReportInitSummary.ClientId =
        "Total: " + totalCustomer;

      this.purchasesClientInformationReportInitSummary.IsSummary = true;

      rtn.push(...tmpList);
      rtn.push(this.purchasesClientInformationReportInitSummary);
      this.reportData = rtn;

      return rtn;
    },
  }),
  async mounted() {
    this.reportsPanelHeight = this.setReportGridHeight(
      window.innerHeight,
      document.getElementById("reportsSummary")
    );
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

    if (
      userInfo != null &&
      userInfo.customerIdPrefix != null &&
      userInfo.customerIdPrefix.length > 0
    ) {
      this.customerPrefix = userInfo.customerIdPrefix;
    }

    await this.sleep(1000);
    this.fixStickyHeaders();
  },
  methods: {
    addPrefixToCustomerId(customerId) {
      let rtn = customerId.toString();
      if (this.customerPrefix != null) {
        rtn = this.customerPrefix + rtn;
      }
      return rtn;
    },
    setPagination(data) {
      this.isPaginated = data;
    },
    async setPayload(value) {
      this.loadingSpinner = true;
      await this.$store.dispatch(
        types.GET_PURCHASE_CLIENT_INFORMATION_REPORT,
        value
      );
      this.loadingSpinner = false;
      await this.sleep(500);
      this.fixStickyHeaders();
    },
    clearReport() {
      this.$store.dispatch(types.RESET_REPORTS);
      this.reportData = [];
    },
    async viewCustomer(id, customerName) {
      this.$Emitter.emit("viewCustomer", {
        customerId: id,
        customerName: customerName,
      });
    },
  },
};
</script>

<style scoped></style>
