<template>
  <div
    class="col-md-12 newPanelWrapper noMargin"
    style="background-color: #ffffff"
  >
    <div class="form-group row">
      <div class="col-md-12 noMargin">
        <label class="generalLabel" for="Action">Action</label>
      </div>
      <div class="col-md-12 noMargin">
        <select
          v-model="selectedRow.CustomerDispositionId"
          id="selectAction"
          class="generalSelectBox form-control"
        >
          <option value="0">Select an Action</option>
          <option
            v-for="item in sortedDispoList(filteredDispos)"
            :key="item.Id"
            :value="item.Id"
          >
            {{ item.Name }}
          </option>
        </select>
      </div>
    </div>
    <div class="form-group row mt-2">
      <div class="col-md-12 noMargin">
        <label class="generalLabel" for="Comment">Comment</label>
      </div>

      <div class="col-md-12 noMargin">
        <textarea
          id="commentTextArea"
          v-model="selectedRow.CommentText"
          class="form-control textareaInput generalTextBox"
          rows="5"
        ></textarea>
      </div>
    </div>
    <div class="form-group row mt-2">
      <div class="col-md-12">
        <button
          type="button"
          @click="handleSave"
          :disabled="
            saving ||
            customerId <= 0 ||
            !isCustomerValid() ||
            selectedRow.CustomerDispositionId == 0
          "
          class="btn generalButton float-left"
          style="background-color: #3bafda; color: #ffffff"
        >
          <i v-if="saving" class="fas fa-spinner mr-1"></i>Submit
        </button>
      </div>
    </div>

    <div v-if="sortedDispositions.length > 0" class="form-group row mt-2">
      <div class="col-md-12 noMargin">
        <label class="generalLabel">Disposition Logs</label>
      </div>
    </div>
    <div
      class="datagrid-table"
      style="
        max-height: 320px;
        overflow-y: auto;
        overflow-x: auto;
        font-size: 12px !important;
      "
      v-if="!isLoading"
    >
      <o-table :data="isEmpty ? [] : sortedDispositions">
        <o-table-column
          field="ScreenName"
          label="Created By"
          sortable
          position="centered"
          width="25%"
          v-slot="props"
        >
          {{ props.row.ScreenName }}
        </o-table-column>
        <o-table-column
          field="Name"
          label="Name"
          sortable
          width="25%"
          v-slot="props"
        >
          {{ props.row.Name }}
        </o-table-column>

        <o-table-column
          field="CommentText"
          label="Comment"
          sortable
          width="25%"
          v-slot="props"
        >
          {{ props.row.CommentText }}
        </o-table-column>
        <o-table-column
          field="DispositionDate"
          label="Date"
          sortable
          position="centered"
          width="25%"
          v-slot="props"
        >
          {{ formatGridDate(props.row.DispositionDate) }}
        </o-table-column>
      </o-table>
      <section
        v-if="sortedDispositions.length == 0"
        class="section"
        style="text-align: center"
      >
        <div class="content has-text-grey has-text-centered mb-4">
          <p class="noMargin">
            <i
              style="font-size: 24px; color: #939393"
              class="ri-question-line"
            ></i>
          </p>
          <p class="noMargin">Nothing here.</p>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import api from "@/services/client/customerApi";
import to from "@/helpers/to";
import formatMixin from "@/mixins/formatMixin";
import _orderby from "lodash";
import types from "./types";
import globalTypes from "@/store/types";
import { mapState } from "vuex";
import deepCopy from "@/helpers/deepCopy";
import utilitiesMixin from "@/mixins/utilitiesMixin";
export default {
  name: "DispositionPanel",
  props: {
    customerId: {
      type: Number,
      default: () => 0,
    },
    statusId: {
      type: Number,
      default: () => null,
    },
    fromEdit: {
      type: Number,
      default: () => 0,
    },
    customerDispositionLogs: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    customerDispositionLogs(newVal, oldVal) {
      if (JSON.stringify(newVal) != JSON.stringify(oldVal)) {
        this.dispositionLogs = Object.assign([], this.customerDispositionLogs);
      }
    },
  },
  data() {
    return {
      selectedRow: {
        CustomerId: this.customerId,
        UserId: JSON.parse(sessionStorage.getItem("userInfo")).userId,
        Name: "",
        CustomerDispositionId: 0,
        CommentText: "",
      },
      saving: false,
      dispositions: [],
      defaultSortDirection: "asc",
      sortIcon: "arrow-up",
      sortIconSize: "is-small",
      isStriped: true,
      isHoverable: true,
      isLoading: false,
      isEmpty: false,
      dispositionLogs: [],
    };
  },
  mixins: [formatMixin, utilitiesMixin],
  computed: mapState({
    dispoList: (state) => state.globals.dispositionTypes,
    filteredDispos() {
      return _orderby.orderBy(
        this.dispoList.filter(
          (item) =>
            item.FromStatusList.map((x) => x.Id).includes(this.statusId) ||
            item.FromStatusList == ""
        ),
        "Priority"
      );
    },
    sortedDispositions() {
      return _orderby.orderBy(this.dispositionLogs, "DispositionDate", "desc");
    },
  }),
  unmounted() {
    // this.emptyTheObjectsOnUnmount();
  },
  async mounted() {
    this.isLoading = true;

    if (this.customerId > 0) {
      await this.$store.dispatch(globalTypes.GET_DISPOSITIONTYPES);

      if (this.fromEdit != 1) {
        let err, result;
        [err, result] = await this.$store.dispatch(
          types.GET_CUSTOMERDISPOSITIONS,
          this.customerId
        );

        if (result) {
          this.dispositionLogs = deepCopy(result.Data);
        }
      } else {
        //this.dispositionLogs = deepCopy(this.customerDispositionLogs);
        let err, result;
        [err, result] = await this.$store.dispatch(
          types.GET_CUSTOMERDISPOSITIONS,
          this.customerId
        );

        if (result) {
          this.dispositionLogs = deepCopy(result.Data);
        }
      }
    }
    this.isLoading = false;
  },

  methods: {
    sortedDispoList(filteredDispos) {
      return _orderby.orderBy(filteredDispos, "Name", "asc");
    },

    emptyTheObjectsOnUnmount() {
      this.selectedRow = null;
      this.saving = null;
      this.dispositions = null;
      this.defaultSortDirection = null;
      this.sortIcon = null;
      this.sortIconSize = null;
      this.isStriped = null;
      this.isHoverable = null;
      this.isLoading = null;
      this.isEmpty = null;
      this.dispositionLogs = null;
    },
    async handleSave() {
      this.saving = true;
      if (this.customerId > 0 && this.selectedRow.CustomerDispositionId > 0) {
        this.selectedRow.CustomerId = this.customerId;
        let selectedDisposition = this.dispoList.filter(
          (x) => x.Id == this.selectedRow.CustomerDispositionId
        );

        if (selectedDisposition.length == 0) {
          this.$swal("Error!", "An error occured!", "error");
        } else {
          if (
            selectedDisposition[0].IsCommentRequired &&
            this.selectedRow.CommentText.trim().length < 5
          ) {
            this.$swal(
              "Warning",
              "Minimum 5 characters required in the comment.",
              "warning"
            );
          } else {
            let err, result;
            [err, result] = await to(api.insertDispositionV2(this.selectedRow));

            if (result) {
              let newStatusId = result.Data.StatusId;

              if (newStatusId) {
                if (newStatusId > 0) {
                  this.$Emitter.emit("updateStatus", {
                    customerId: this.customerId,
                    statusId: newStatusId,
                  });
                }
              }
              this.selectedRow.Name = selectedDisposition[0].Name;
              let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
              let newRow = {
                ScreenName: userInfo.screenName,
                Name: this.selectedRow.Name,
                DispositionDate: new Date(),
                CommentText: this.selectedRow.CommentText,
              };

              this.dispositionLogs.unshift(newRow);

              let logs = result.Data.SystemLogs;
              if (logs) {
                if (logs.length > 0) {
                  this.$store
                    .dispatch(types.MODIFY_DISPOSITIONLOGS, logs)
                    .catch();
                }
              }

              this.$Emitter.emit("updateStatus", {
                customerId: this.customerId,
                statusId: newStatusId,
              });

              this.$swal
                .fire({
                  title: "Success!",
                  text: "Disposition added successfully.",
                  type: "success",
                  showCancelButton: false,
                  confirmButtonColor: "#3085d6",
                  confirmButtonText: "Ok",
                  allowOutsideClick: false,
                })
                .then(async (dialog) => {
                  if (dialog.value) {
                    this.selectedRow.CustomerDispositionId = 0;
                    this.selectedRow.CommentText = "";
                    if (selectedDisposition[0].IsReminderRequired) {
                      this.$Emitter.emit("openReminder", {
                        CustomerId: this.customerId,
                        IsAutoClose: true,
                      });
                    }
                  }
                });

                //todo: user_activity
              // if (this.checkAuth(3994)) {
              //   this.$store.dispatch(globalTypes.INSERT_USER_ACTIVITY, {
              //     CustomerId: this.customerId,
              //     UserActivityTypeId: 8,
              //   });
              // }
            } else {
              let errMsg = this.getApiErrorMessage(err);
              this.$swal("Error!", errMsg, "error");
            }
          }
        }
      }
      this.saving = false;
    },
    isCustomerValid() {
      let rtn = true;
      if (this.fromEdit == 1) {
        if (
          document.getElementById(
            "CustomerSaveButton" + this.customerId.toString()
          )
        ) {
          if (
            document.getElementById(
              "CustomerSaveButton" + this.customerId.toString()
            ).disabled
          ) {
            rtn = false;
          }
        }
      }
      return rtn;
    },
  },
};
</script>
