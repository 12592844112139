<template>
  <Popover class="transfer" :height="160">
    <template v-slot:header>
      <div class="container">
        Transfer to Number
        <button class="no-button" v-on:click.prevent.stop="closePopup">
          &lt; Back
        </button>
      </div>
    </template>
    <template v-slot:body>
      <div class="full-width p-12">
        <input
          class="form-control full-width"
          type="text"
          v-model="did"
          @change="refreshValue"
          v-myMask="{ mask: ['(999) 999-9999'] }"
          v-on:click.prevent.stop="() => {}"
          v-on:keydown.enter.prevent="transfer()"
        />
        <button
          type="button"
          @click="transfer()"
          class="btn btn-primary btn-primary-bordered full-width mt-12"
        >
          Call This Number
        </button>
      </div>
    </template>
  </Popover>
</template>

<script>
import Popover from "../../Popover";

export default {
  components: {
    Popover,
  },
  emits: ["transferToDid"],
  data() {
    return {
      did: "",
    };
  },
  methods: {
    refreshValue(event) {
      if (event.target.value && event.target.value != "") {
        this.did = event.target.value;
      }
    },
    transfer() {
      if (this.did != "" && this.did.length >= 10) {
        this.$emit("transferToDid", this.did);
      }
    },
    closePopup() {
      this.$emit("transfer", 0);
    },
  },
};
</script>
