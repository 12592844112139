<template>
  <div class="row admin-page officePage">
    <div v-if="saving" class="loading">
      <div class="text-center" style="margin-top: 150px">
        <i class="spinner-grow text-info m-2"></i>
      </div>
    </div>
    <div class="col-xl-3 col-lg-4 col-md-12 col-sm-12">
      <div class="table is-striped table is-hoverable">
        <button
          class="col-xl-12 btn btn-success waves-effect waves-light"
          @click="exportSettings()"
        >
          Export Settings
        </button>
        <button
          class="col-xl-12 btn btn-primary waves-effect waves-light addTemplateButton"
          @click="handleAddCreditorSettings()"
        >
          Add New Creditor Settings
        </button>
        <div class="col-lg-12 col-md-12 col-sm-12 noPadding">
          <input
            autocomplete="off"
            type="text"
            class="form-control"
            v-model="creditorSettingsFilter"
            placeholder="Filter Creditor Settings"
          />
          <div style="margin: 5px 0px 0px 5px">
            <input
              v-model="showDeleted"
              type="checkbox"
              class="checkbox-input form-control"
            />
            <label class="form-label" style="font-size: 13px"
              >Show deactivated settings</label
            >
          </div>
        </div>
        <div class="list col-md-12">
          <div
            class="item row"
            v-for="(item, index) in filteredRows"
            :class="{ active: activeItem === item.Id }"
            :disabled="saving || deleting"
            :key="item.Id"
            @click="() => handleListSelection(item, index)"
            :style="[
              index % 2 === 0
                ? { 'background-color': '#F1EFEF' }
                : { 'background-color': '#FFFFFF' },
            ]"
          >
            <span :class="{ adminActiveMenuItem: activeItem === item.Id }">
              <i
                class="ri-user-settings-fill"
                style="vertical-align: middle"
                :style="
                  item.IsDeleted == true ? 'color:#ff7979' : 'color:#6c757d'
                "
              ></i>
              <span
                :style="
                  item.IsDeleted == true ? 'color:#ff7979' : 'color:#6c757d'
                "
                style="margin-left: 10px"
                >{{ item.CreditorName }}</span
              >
            </span>
          </div>
        </div>
        <div
          v-if="isMobileDevice()"
          style="
            border-top: 1px solid rgb(0, 0, 0, 0);
            margin: 20px 0 0 9px;
            padding-bottom: 20px;
          "
          class="col-md-12 scrollToSelectedItem"
        ></div>
      </div>
    </div>

    <div class="col-xl-9 col-lg-8 col-md-12 col-sm-12 row" v-if="isEditing">
      <div class="row">
        <div class="col-md-6">
          <div class="panelContent" style="padding-top: 0">
            <div class="col-md-12">
              <label class="form-label">Creditor Name:</label>
              <p style="margin-bottom: 0px; font-size: 12px; color: #f0556c">
                (Changing the creditor name will update it for all customers in
                the CRM.)
              </p>
            </div>
            <div class="col-md-12">
              <input
                class="form-control col-md-12"
                v-model="selectedRow.CreditorName"
                type="text"
                placeholder="Creditor Name"
              />
            </div>

            <div class="col-md-12 mt-3">
              <label for="roles">Avg Settlement Percentage:</label>
              <p style="margin-bottom: 0px; font-size: 12px">
                (Enter 0 for default settlement percentage.)
              </p>
              <input
                class="form-control col-xl-8 col-lg-8 col-md-8 col-xs-12"
                :value="selectedRow.AvgSettlementPercentage * 100"
                @input="
                  selectedRow.AvgSettlementPercentage =
                    $event.target.value / 100
                "
                type="text"
                v-myMask="{
                  alias: 'decimal',
                  min: 0,
                  max: 1000,
                  digits: 2,
                  rightAlign: false,
                }"
              />
            </div>

            <div
              v-if="1 == 0"
              class="col-md-12 mt-3"
              style="display: flex; align-items: stretch"
            >
              <div>
                <input
                  type="checkbox"
                  class="checkbox-input generalCheckBox"
                  v-model="selectedRow.IsDifficult"
                />
              </div>
              <label class="form-label">Difficult </label>
            </div>
            <div
              class="col-md-12 mt-3"
              style="display: flex; align-items: stretch"
            >
              <div>
                <input
                  type="checkbox"
                  class="checkbox-input generalCheckBox"
                  v-model="selectedRow.IsScrub"
                />
              </div>
              <label class="form-label">Is Scrub Creditor </label>
            </div>
            <div
              class="col-md-12 mt-3"
              style="display: flex; align-items: stretch"
            >
              <div>
                <input
                  type="checkbox"
                  class="checkbox-input generalCheckBox"
                  v-model="selectedRow.IsRevShare"
                />
              </div>
              <label class="form-label">Is Rev Share Creditor </label>
            </div>
            <div class="col-md-12 mt-3 noMargin noPadding">
              <div class="col-md-12 mt-3">
                <label class="form-label">Difficulty Level</label>
              </div>

              <div class="col-md-12" style="display: flex; gap: 5px">
                <button
                  v-for="num in numbers"
                  :key="num"
                  class="rankBox"
                  :class="{ selected: num == selectedRow.DifficultyLevel }"
                  @click="selectNumber(num)"
                >
                  {{ num }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-1"></div>
        <div class="col-md-5">
          <div
            v-if="selectedRow.Id > 0 && selectedRow && selectedRow.Aliases"
            class="col-md-12 legend pb-3"
            style="border-color: #b7bbbf"
          >
            <h4>Aliases</h4>

            <div class="col-md-12">
              <div class="row">
                <div class="col-xl-8 col-lg-8 col-md-8 col-xs-12 noPadding">
                  <input
                    class="form-control"
                    v-model.trim="aliasName"
                    type="text"
                    placeholder="Add new alias"
                  />
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-xs-12">
                  <button class="btn btn-success" @click="addNewAlias()">
                    Add
                  </button>
                </div>
              </div>
            </div>

            <perfect-scrollbar
              style="
                width: 100%;
                max-height: 250px;
                overflow-x: hidden !important;
              "
              class="pt-3"
            >
              <div v-for="(item, index) in selectedRow.Aliases" :key="item.Id">
                <label>{{ index + 1 + ". " + item.AliasName }}</label>
                <span style="cursor: pointer" class="ml-1 badge badge-danger">
                  <i
                    @click="deleteCreditorAlias(index)"
                    class="ri-close-line"
                    style="margin-top: 2px"
                  ></i
                ></span>
              </div>
            </perfect-scrollbar>
          </div>
        </div>
        <div class="col-md-12">
          <div class="col-md-12">
            <div class="form-group row">
              <div class="col-md-12 noMargin">
                <label class="form-label">Creditor Rule</label>
              </div>
              <div class="col-md-12 noMargin" v-if="paymentTypeLoaded">
                <CreditorRuleGrid
                  :settlementRules="selectedRow.SettlementRules"
                  :paymentTypes="paymentTypes"
                  @updateSettlementRules="updateSettlementRules"
                ></CreditorRuleGrid>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row col-md-12">
        <div
          v-if="!this.selectedRow.IsDeleted"
          class="col-xl-2 col-lg-4 col-md-4 col-sm-12"
        >
          <button
            style="width: 100%"
            class="btn btn-danger"
            @click="handleDelete"
          >
            Deactivate
            <i v-if="deleting" class="spinner-border spinner-border-sm"></i>
          </button>
        </div>
        <div
          v-if="this.selectedRow.IsDeleted"
          class="col-xl-2 col-lg-4 col-md-4 col-sm-12"
        >
          <button
            style="width: 100%"
            class="btn btn-primary"
            @click="makeActive()"
          >
            Make Active
            <i v-if="deleting" class="spinner-border spinner-border-sm"></i>
          </button>
        </div>
        <div class="col-xl-2 col-lg-4 col-md-4 col-sm-12">
          <button
            class="btn btn-success"
            style="width: 100%"
            @click="handleSave()"
          >
            Save
            <i v-if="saving" class="spinner-border spinner-border-sm"></i>
          </button>
        </div>
        <div
          v-if="selectedRow.Id > 0"
          class="col-xl-3 offset-xl-5 col-lg-4 col-md-4 col-sm-12"
        >
          <button
            @click="showChangeLogs()"
            type="button"
            style="width: 100%"
            class="btn btn-primary waves-effect waves-light"
          >
            Change Logs
            <span class="float-right">
              <i v-if="!showLogs" class="fas fa-angle-down"></i>
              <i v-if="showLogs" class="fas fa-angle-up"></i>
            </span>
          </button>
        </div>
      </div>
      <div class="col-md-12">
        <div
          class="col-xl-12 col-lg-12 col-md-12 col-xs-12 datagrid-table gcsTabsTableScroll"
          v-if="showLogs"
        >
          <o-table :data="userActivityList">
            <o-table-column field="FullName" width="20%" label="User" sortable>
              <template v-slot="props">
                {{ props.row.FullName }}
              </template>
            </o-table-column>

            <o-table-column
              field="LogDate"
              width="20%"
              label="Log Date"
              sortable
            >
              <template v-slot="props">
                {{ formatGridDate(props.row.LogDate) }}
              </template>
            </o-table-column>
            <o-table-column
              field="AdditionalNotes"
              width="60%"
              label="Changes"
              sortable
            >
              <template v-slot="props">
                <span v-html="props.row.AdditionalNotes"></span>
              </template>
            </o-table-column>
          </o-table>
          <section
            v-if="userActivityList.length == 0"
            class="section"
            style="text-align: center"
          >
            <div class="content has-text-grey has-text-centered mb-4">
              <p class="noMargin">
                <i
                  style="font-size: 24px; color: #939393"
                  class="ri-question-line"
                ></i>
              </p>
              <p class="noMargin">Nothing here.</p>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import types from "./types";
import negotiatorTaskType from "@/views/agent/NegotiatorTasks/types";
import globalTypes from "@/store/types";
import deepCopy from "@/helpers/deepCopy";
import formatMixin from "@/mixins/formatMixin.js";
import useValidate from "@vuelidate/core";
import _orderby from "lodash";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import CreditorRuleGrid from "@/components/Grids/CreditorRuleGrid";

const init = {
  Id: 0,
  CreditorName: "",
  AvgSettlementPercentage: 0,
  Aliases: [],
  IsDeleted: false,
  IsDifficult: false,
  IsScrub: false,
  IsRevShare: false,
  DifficultyLevel: 1,
  SettlementRules: [],
};

export default {
  name: "CreditorSettingsManagement",
  mixins: [formatMixin, utilitiesMixin],
  components: { CreditorRuleGrid },
  data() {
    return {
      numbers: Array.from({ length: 10 }, (_, i) => i + 1),
      showLogs: false,
      logsLoaded: false,
      v$: useValidate(),
      saving: false,
      deleting: false,
      isEditing: false,
      activeItem: null,
      isCancelledBeforeSave: false,
      creditorSettingsFilter: "",
      oldValue: deepCopy(init),
      selectedRow: deepCopy(init),
      valueToRevert: deepCopy(init),
      aliasName: "",
      showDeleted: false,
      userActivityList: [],
      paymentTypes: [],
      paymentTypeLoaded: false,
    };
  },
  async mounted() {
    await Promise.all([
      this.$store.dispatch(types.GET_CREDITOR_SETTINGS),
      this.$store.dispatch(types.GET_CREDITOR_ALIAS),
      this.getPaymentTypes(),
    ]);
  },
  computed: mapState({
    creditorList: (state) => state.creditorSettingsManagement.creditorList,
    creditorAliastList: (state) =>
      state.creditorSettingsManagement.creditorAliastList,

    filteredCreditorSettings() {
      return this.creditorList.filter(
        (item) =>
          item.CreditorName.toLowerCase().indexOf(
            this.creditorSettingsFilter.toLowerCase()
          ) > -1
      );
    },
    filteredRows() {
      if (this.showDeleted) {
        return this.filteredCreditorSettings;
      } else {
        return this.filteredCreditorSettings.filter((row) => !row.IsDeleted);
      }
    },
  }),
  methods: {
    async getPaymentTypes() {
      let err, result;

      [err, result] = await this.$store.dispatch(
        negotiatorTaskType.GET_PAYMENT_TYPES
      );
      if (result && result.Data) {
        this.paymentTypes = result.Data;
      }
      this.paymentTypeLoaded = true;
    },
    getPaymentTypeName(value) {
      let rtn = "";
      if (this.paymentTypes && this.paymentTypes.length > 0) {
        let indx = this.paymentTypes.findIndex((x) => x.Id == value);
        if (indx > -1) {
          rtn = this.paymentTypes[indx].Name;
        }
      }
      return rtn;
    },
    updateSettlementRules(value) {
      this.selectedRow.SettlementRules = value;
    },

    selectNumber(num) {
      this.selectedRow.DifficultyLevel = num;
    },
    async getActivityLogs() {
      if (this.selectedRow && this.selectedRow.Id > 0) {
        let err, result;
        [err, result] = await this.$store.dispatch(
          types.GET_USER_ACTIVITY_CREDITOR,
          {
            ExternalTableId: parseInt(this.selectedRow.Id),
            UserActivityTypeId: 11,
          }
        );
        if (result && result.Data) {
          this.userActivityList = result.Data;
          this.logsLoaded = true;
        }
      }
    },

    async showChangeLogs() {
      if (this.showLogs == false && this.logsLoaded == false) {
        await this.getActivityLogs();
      }
      this.showLogs = !this.showLogs;
    },

    exportSettings() {
      let table = document.createElement("table");
      let thead = document.createElement("thead");
      let tr = document.createElement("tr");

      let th1 = document.createElement("th");
      th1.appendChild(document.createTextNode("Name"));
      tr.appendChild(th1);

      let th2 = document.createElement("th");
      th2.appendChild(document.createTextNode("%"));
      tr.appendChild(th2);

      let th3 = document.createElement("th");
      th3.appendChild(document.createTextNode("Is Deleted"));
      tr.appendChild(th3);

      thead.appendChild(tr);
      table.appendChild(thead);

      let tbdy = document.createElement("tbody");

      for (let item of this.creditorList) {
        let tr = document.createElement("tr");

        let td1 = document.createElement("td");
        td1.appendChild(document.createTextNode(item.CreditorName));
        tr.appendChild(td1);

        let td2 = document.createElement("td");
        td2.appendChild(
          document.createTextNode(item.AvgSettlementPercentage * 100 + "%")
        );
        tr.appendChild(td2);

        let td3 = document.createElement("td");
        td3.appendChild(document.createTextNode(item.IsDeleted ? "Yes" : "No"));
        tr.appendChild(td3);

        tbdy.appendChild(tr);
      }

      table.appendChild(tbdy);
      this.exportExcel(table, "CreditorSettings");
    },
    async insertUserActivityLog(changeLogText = "") {
      let userInfo = this.getUserInfo();
      if (userInfo && userInfo.userId > 0) {
        let payload = {
          CustomerId: null,
          CreditorId: null,
          ExternalTableId: this.selectedRow.Id,
          UserId: userInfo.userId,
          UserActivityTypeId: 11,
          AdditionalNotes:
            changeLogText != ""
              ? changeLogText
              : this.getChangeLogNote(this.selectedRow, this.oldValue),
        };
        if (this.checkAuth(99665)) {
          await this.$store.dispatch(globalTypes.INSERT_USER_ACTIVITY, payload);
          this.showLogs = false;
          await this.getActivityLogs();
          this.showLogs = true;
        }
      }
    },

    formatPercentage(value) {
      let rtn = "";
      if (typeof value != "undefined" && value) {
        rtn = parseFloat(value).toFixed(2);
      }
      return rtn;
    },
    getChangeLogNote(data, oldData) {
      let rtn = "";
      let isNewRecord = false;
      if (data && oldData) {
        let keys = Object.keys(data);
        for (let k of keys) {
          if (
            k != null &&
            typeof data[k] != "undefined" &&
            data[k] != oldData[k]
          ) {
            if (k.toLowerCase() == "id" && oldData[k] == 0) {
              rtn += "New record inserted.</br>";
              isNewRecord = true;
            } else {
              if (!Array.isArray(data[k])) {
                if (k.toLowerCase() == "creditorname") {
                  rtn +=
                    isNewRecord == true
                      ? "Creditor Name: " + data[k] + "<br>"
                      : "Creditor Name Updated: " +
                        oldData[k] +
                        " --> " +
                        data[k] +
                        " </br>";
                } else if (k.toLowerCase() == "avgsettlementpercentage") {
                  rtn +=
                    isNewRecord == true
                      ? "Avg Settlement Percentage: " +
                        this.formatPercentage(data[k] * 100) +
                        "%<br>"
                      : "Avg Settlement Percentage Updated: " +
                        this.formatPercentage(oldData[k] * 100) +
                        "% --> " +
                        this.formatPercentage(data[k] * 100) +
                        "% </br>";
                } else if (k.toLowerCase() == "isdifficult") {
                  rtn +=
                    data[k] == true
                      ? "Difficult Flag: Set"
                      : "Difficult Flag: Unset" + " </br>";
                } else if (k.toLowerCase() == "difficultylevel") {
                  rtn +=
                    isNewRecord == true
                      ? "Difficulty Level:" + data[k].toString() + " </br>"
                      : "Difficulty Level Updated: " +
                        oldData[k].toString() +
                        " --> " +
                        data[k].toString() +
                        " </br>";
                } else if (k.toLowerCase() == "isscrub") {
                  rtn +=
                    data[k] == true
                      ? "Scrub Creditor: Set" + " </br>"
                      : "Scrub Creditor: Unset" + " </br>";
                } else if (k.toLowerCase() == "isrevshare") {
                  rtn +=
                    data[k] == true
                      ? "Rev Share Creditor: Set" + " </br>"
                      : "Rev Share Creditor: Unset" + " </br>";
                } else if (k.toLowerCase() == "isdeleted") {
                  rtn +=
                    data[k] == true
                      ? "Settings deactivated"
                      : "Settings activated" + " </br>";
                }
              } else {
                if (k.toLowerCase() == "settlementrules") {
                  if (data[k] && data[k].length > 0) {
                    for (let ruleItem of data[k]) {
                      //insert check
                      let indx = -1;
                      if (oldData[k] && oldData[k].length > 0) {
                        indx = oldData[k].findIndex((x) => x.Id == ruleItem.Id);
                      }

                      if (indx > -1) {
                        //update rule
                        let oldRule = oldData[k][indx];
                        rtn +=
                          oldRule.Title +
                          " Rule Updated: " +
                          oldRule.Title +
                          ", Sett. Perc: " +
                          oldRule.SettlementPercentage +
                          ", Payment Terms: " +
                          oldRule.PaymentTerms +
                          ", Payment Type: " +
                          this.getPaymentTypeName(oldRule.PaymentTypeId) +
                          ", Days Delinquent: " +
                          oldRule.DaysDelinquent +
                          " --> " +
                          ruleItem.Title +
                          ", Sett. Perc: " +
                          ruleItem.SettlementPercentage +
                          ", Payment Terms: " +
                          ruleItem.PaymentTerms +
                          ", Payment Type: " +
                          this.getPaymentTypeName(ruleItem.PaymentTypeId) +
                          ", Days Delinquent: " +
                          ruleItem.DaysDelinquent +
                          " </br></br>";
                      } else {
                        //insert new rule
                        rtn +=
                          " New Rule Added: Title: " +
                          ruleItem.Title +
                          ", Sett. Perc: " +
                          ruleItem.SettlementPercentage +
                          ", Payment Terms: " +
                          ruleItem.PaymentTerms +
                          ", Payment Type: " +
                          this.getPaymentTypeName(ruleItem.PaymentTypeId) +
                          ", Days Delinquent: " +
                          ruleItem.DaysDelinquent +
                          " </br></br>";
                      }
                    }
                  }

                  // rule delete
                  if (oldData[k] && oldData[k].length > 0) {
                    for (let oldRuleItem of oldData[k]) {
                      let deletedIndx = data[k].findIndex(
                        (x) => x.Id == oldRuleItem.Id
                      );
                      if (deletedIndx == -1) {
                        rtn +=
                          oldRuleItem.Title + " Rule Deleted." + " </br></br>";
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      return rtn;
    },
    async deleteCreditorAlias(indx) {
      if (
        this.selectedRow.Aliases &&
        this.selectedRow.Aliases.length &&
        indx > -1
      ) {
        let [err, result] = await this.$store.dispatch(
          types.DELETE_CREDITOR_ALIAS,
          this.selectedRow.Aliases[indx].Id
        );

        if (result) {
          let aliasId = this.selectedRow.Aliases[indx].Id;
          let aliasName = this.selectedRow.Aliases[indx].AliasName;
          await this.insertUserActivityLog(
            "Creditor Alias Removed: " + aliasName
          );
          await this.sleep(500);
          this.selectedRow.Aliases.splice(indx, 1);
          let cindx = this.creditorList.findIndex(
            (x) => x.Id == this.selectedRow.Id
          );
          if (cindx > -1) {
            let aindex = this.creditorList[cindx].Aliases.findIndex(
              (x) => x.Id == aliasId
            );
            if (aindex > -1) {
              this.creditorList[cindx].Aliases.splice(aindex, 1);
            }
          }
        } else {
          let errMsg = this.getApiErrorMessage(err);
          this.$swal("Error!", errMsg, "error");
        }
      }
    },
    async addNewAlias() {
      if (this.selectedRow.Aliases) {
        if (this.aliasName != "") {
          if (
            this.selectedRow.Aliases.some(
              (x) => x.AliasName.toLowerCase() == this.aliasName.toLowerCase()
            )
          ) {
            this.$swal(
              "Warning!",
              "This alias is already exists in list.",
              "warning"
            );
          } else {
            let [err, result] = await this.$store.dispatch(
              types.CREATE_CREDITOR_ALIAS,
              { CreditorAverageId: this.activeItem, AliasName: this.aliasName }
            );
            if (err) {
              let errMsg = this.getApiErrorMessage(err);
              this.$swal("Error!", errMsg, "error");
              return;
            } else {
              await this.insertUserActivityLog(
                "Creditor Alias Added: " + this.aliasName
              );
            }
            this.selectedRow.Aliases.push({
              AliasName: this.aliasName,
              Id: result.Data.Id,
            });
            this.aliasName = "";
          }
        } else {
          this.$swal("Warning!", "Alias name cannot be empty.", "warning");
        }
      }
    },

    reset() {
      this.activeItem = null;
      this.selectedRow = deepCopy(init);
      this.isEditing = false;
    },
    isDataChanged(item1, item2) {
      return (
        item1.CreditorName != item2.CreditorName ||
        item1.AvgSettlementPercentage != item2.AvgSettlementPercentage ||
        item1.IsDifficult != item2.IsDifficult ||
        item1.DifficultyLevel != item2.DifficultyLevel ||
        item1.IsScrub != item2.IsScrub ||
        item1.IsRevShare != item2.IsRevShare ||
        !this.areArraysEqual(item1.SettlementRules, item2.SettlementRules)
      );
    },

    areArraysEqual(arr1, arr2) {
      if (arr1.length !== arr2.length) return false;
      for (let i = 0; i < arr1.length; i++) {
        if (JSON.stringify(arr1[i]) !== JSON.stringify(arr2[i])) {
          return false;
        }
      }
      return true;
    },
    async handleAddCreditorSettings() {
      if (
        this.isDataChanged(this.selectedRow, this.oldValue) &&
        this.isDataChanged(this.selectedRow, init)
      ) {
        await this.$swal({
          title: "You have unsaved changes",
          text: "You will lose it if you don't save",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, continue without saving!",
        }).then(async (dialog) => {
          if (dialog.value) {
            this.isCancelledBeforeSave = true;
          } else {
            this.isCancelledBeforeSave = false;
            return;
          }
        });
      } else {
        this.selectedRow = deepCopy(init);
        this.oldValue = deepCopy(init);
        this.activeItem = null;
        this.isEditing = true;
        this.showLogs = false;
      }

      if (this.isCancelledBeforeSave) {
        this.selectedRow = deepCopy(this.valueToRevert);

        let indx = this.creditorList.findIndex(
          (x) => x.Id == this.valueToRevert.Id
        );
        this.creditorList[indx] = this.valueToRevert;
        this.creditorList.push();
        this.isCancelledBeforeSave = false;
        this.selectedRow = deepCopy(init);
        this.activeItem = null;
        this.isEditing = true;
        this.showLogs = false;
      }
    },
    async handleListSelection(row) {
      if (
        this.isDataChanged(this.oldValue, this.selectedRow) &&
        this.isDataChanged(this.selectedRow, init)
      ) {
        await this.$swal({
          title: "You have unsaved changes",
          text: "You will lose it if you don't save",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, continue without saving!",
        }).then(async (dialog) => {
          if (dialog.value) {
            this.isCancelledBeforeSave = true;
          } else {
            this.isCancelledBeforeSave = false;
            return;
          }
        });
      } else {
        this.valueToRevert = deepCopy(row);
        this.oldValue = deepCopy(row);
        this.activeItem = row.Id;
        this.selectedRow = row;
        this.aliasName = "";
        this.isCancelledBeforeSave = false;
        this.showLogs = false;
        this.logsLoaded = false;
      }
      this.isEditing = true;
      if (this.isCancelledBeforeSave) {
        this.selectedRow = deepCopy(this.valueToRevert);
        let indx = this.creditorList.findIndex(
          (x) => x.Id == this.valueToRevert.Id
        );
        this.creditorList[indx] = this.valueToRevert;
        this.creditorList.push();
        this.valueToRevert = deepCopy(row);
        this.oldValue = deepCopy(row);
        this.activeItem = row.Id;
        this.selectedRow = row;
        this.isCancelledBeforeSave = false;
        this.showLogs = false;
        this.logsLoaded = false;
      }

      let el = this.$el.querySelector(".scrollToSelectedItem");
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
    async handleSave() {
      if (!this.isDataChanged(this.oldValue, this.selectedRow)) {
        this.$swal("Warning!", "No Changes Detected!", "warning");
        return;
      }

      if (this.selectedRow.AvgSettlementPercentage * 100 < 50) {
        if (!this.checkAuth(648935)) {
          this.$swal(
            "Warning!",
            "You are not authorized to add an average settlement percentage below 50%.",
            "warning"
          );
          return;
        }
      }

      this.saving = true;
      let err, result, type;
      let isCreditorSettingsExists =
        this.creditorList.filter(
          (x) =>
            x.CreditorName.toLowerCase() ==
            this.selectedRow.CreditorName.toLowerCase()
        ).length > 0;

      if (this.selectedRow.Id != 0) {
        type = types.UPDATE_CREDITOR_SETTINGS;

        isCreditorSettingsExists =
          this.creditorList.filter(
            (x) =>
              x.CreditorName.toLowerCase() ==
              this.selectedRow.CreditorName.toLowerCase()
          ).length > 1;
      } else {
        type = types.CREATE_CREDITOR_SETTINGS;
      }
      if (isCreditorSettingsExists && type == types.CREATE_CREDITOR_SETTINGS) {
        this.saving = false;
        this.$swal(
          "Warning!",
          "This creditor name is already exists in list!",
          "warning"
        );
        this.saving = false;
        return;
      }
      if (isCreditorSettingsExists && type == types.UPDATE_CREDITOR_SETTINGS) {
        this.saving = false;

        this.$swal(
          "Warning!",
          "This creditor name is already exists in list!",
          "warning"
        );
        this.saving = false;
        return;
      }

      [err, result] = await this.$store.dispatch(type, this.selectedRow);
      if (result) {
        this.$swal("Success!", result.Message, "success");

        if (type == types.CREATE_CREDITOR_SETTINGS) {
          this.selectedRow.Id = result.Data.Id;
          this.activeItem = result.Data.Id;
          this.creditorList.push(this.selectedRow);
          await this.insertUserActivityLog();
          this.oldValue = deepCopy(this.selectedRow);
        } else {
          await this.insertUserActivityLog();
          this.oldValue = deepCopy(this.selectedRow);
        }
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
      this.saving = false;
    },
    // async handleDelete(Id) {
    //   this.deleting = true;
    //   this.$swal
    //     .fire({
    //       title: "Are you sure?",
    //       text: "All aliases will be removed. Would you like to continue?",
    //       type: "warning",
    //       showCancelButton: true,
    //       confirmButtonColor: "#3085d6",
    //       cancelButtonColor: "#d33",
    //       confirmButtonText: "Yes, delete it!",
    //     })
    //     .then(async (dialog) => {
    //       if (dialog.value) {
    //         let rowIndex = this.creditorList.indexOf(this.selectedRow);
    //         let err, result;

    //         [err, result] = await this.$store.dispatch(
    //           types.DELETE_CREDITOR_SETTINGS,
    //           { Id }
    //         );

    //         if (!err) {
    //           this.reset();
    //         }

    //         if (result) {
    //           this.creditorList.splice(rowIndex, 1);
    //           this.reset();
    //           this.$swal("Deleted!", result.Message, "success");
    //         } else {
    //           let errMsg = this.getApiErrorMessage(err);
    //           this.$swal("Error!", errMsg, "error");
    //         }
    //       }
    //     });
    //   this.deleting = false;
    // },

    async handleDelete() {
      this.deleting = true;
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "Are you sure to deactivate the creditor settings?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, deactivate it!",
        })
        .then(async (dialog) => {
          if (dialog.value) {
            let err, result, type;

            type = types.UPDATE_CREDITOR_SETTINGS;

            this.selectedRow.IsDeleted = true;
            [err, result] = await this.$store.dispatch(type, this.selectedRow);

            if (result) {
              this.oldValue = deepCopy(this.selectedRow);
              this.reset();
              this.$swal(
                "Deleted!",
                "Creditor settings deactivated successfully.",
                "success"
              );
              let activatedText =
                this.selectedRow.IsDeleted == true
                  ? "Settings deactivated: "
                  : "Settings activated: ";
              await this.insertUserActivityLog(
                activatedText + this.selectedRow.CreditorName
              );
            } else {
              let errMsg = this.getApiErrorMessage(err);
              this.$swal("Error!", errMsg, "error");
            }
          }
        });
      this.deleting = false;
    },

    async makeActive() {
      this.deleting = true;
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "Are you sure to activate the creditor settings?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, activate it!",
        })
        .then(async (dialog) => {
          if (dialog.value) {
            let err, result, type;

            type = types.UPDATE_CREDITOR_SETTINGS;

            this.selectedRow.IsDeleted = false;
            [err, result] = await this.$store.dispatch(type, this.selectedRow);

            if (result) {
              this.oldValue = deepCopy(this.selectedRow);
              this.reset();
              this.$swal(
                "Activate!",
                "Creditor settings activated successfully",
                "success"
              );
              await this.insertUserActivityLog(
                "Settings activated: " + this.selectedRow.CreditorName
              );
            } else {
              let errMsg = this.getApiErrorMessage(err);
              this.$swal("Error!", errMsg, "error");
            }
          }
        });
      this.deleting = false;
    },
  },
};
</script>
<style>
.has-error {
  border: 2px solid red !important;
}

.rankBox {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: 0px solid #f8f8f8;
  background-color: #f7f7f7;
  cursor: pointer;
  color: #3ba7d5;
}
.rankBox.selected {
  background-color: #3ba7d5;
  color: white;
}
</style>
