<template>
  <div id="chart" v-if="widgetData && isChartReady">
    <div class="position">
      <label class="headerText generalLabel mt-1">{{
        widgetData.header
      }}</label>
      <label class="middleText generalLabel">{{
        widgetData.valueFormat == "money"
          ? formatMoney(widgetData.value)
          : formatNumberWithoutDecimalPoints(widgetData.value)
      }}</label>
    </div>

    <apexchart
      height="300"
      type="radialBar"
      :options="chartOptions"
      :series="series"
    ></apexchart>

    <div class="position" style="padding: 10px">
      <label class="headerText generalLabel">{{ widgetData.subHeader }}</label>
      <label class="headerText generalLabel">{{
        widgetData.valueFormat == "money"
          ? formatMoney(widgetData.subValue)
          : formatNumberWithoutDecimalPoints(widgetData.subValue)
      }}</label>
    </div>
  </div>
</template>

<script>
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
export default {
  props: {
    widgetData: Object,
  },
  mixins: [formatMixin, utilitiesMixin],

  data() {
    return {
      isChartReady: false,
      percentage: 0,
      series: [100, 0],
      chartOptions: {
        plotOptions: {
          radialBar: {
            dataLabels: {
              show: true,
              name: {
                fontSize: "22px",
              },
              value: {
                fontSize: "14px",
              },
              total: {
                show: true,
                showAlways: true,
                label: "",
                fontSize: "16px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 600,
                color: "#373d3f",
                formatter: function (w) {},
              },
            },
          },
        },
        labels: ["Goal", "Deals"],
        chart: {
          type: "radialBar",
        },
        legend: {
          position: "bottom",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
            },
          },
        ],
      },
    };
  },
  mounted() {
    if (this.widgetData) {
      let value = this.widgetData.value;
      let total = this.widgetData.bottomValue;
      let perc = this.calculatePercentage(value, total);

      if (this.widgetData.justPercantageShow == true) {
        if (!isNaN(value)) {
          this.percentage = value.toFixed(2);
          this.series[1] = this.percentage;
          this.chartOptions.plotOptions.radialBar.dataLabels.total.label =
            this.percentage + this.widgetData.bottomPerc;
        }
      } else if (this.widgetData.specialPercentageShow == true) {

        if (!isNaN(this.widgetData.specialPercentageValue))
        {
          this.percentage = parseFloat(this.widgetData.specialPercentageValue).toFixed(2);
        this.series[1] = this.percentage;
        this.chartOptions.plotOptions.radialBar.dataLabels.total.label =
        this.percentage + "%";

        }
     
      } else {
        if (!isNaN(perc)) {
          this.percentage = perc.toFixed(2);
          this.series[1] = this.percentage;
          this.chartOptions.plotOptions.radialBar.dataLabels.total.label =
            this.percentage + this.widgetData.bottomPerc;
        }
      }

      this.chartOptions.plotOptions.radialBar.dataLabels.total.formatter =
        this.getLabelValue;
      this.isChartReady = true;
    }
  },
  methods: {
    getLabelValue() {
      let rtn = "";
      if (this.widgetData) {


        rtn =
          this.widgetData.valueFormat == "money"
            ? this.formatMoney(this.widgetData.bottomValue)
            : this.formatNumberWithoutDecimalPoints(
                this.widgetData.bottomValue
              ) + this.widgetData.bottomValueText;
      }
      return rtn;
    },
  },
};
</script>

<style scoped>
.position {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.headerText {
  font-size: 20px;
}
.middleText {
  font-size: 24px;
}
.bottomText {
  font-size: 10px;
}
</style>
