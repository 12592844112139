import to from "@/helpers/to";
import api from "@/services/client/customerApi";
import adminApi from "@/services/admin/api";

export default {
  state: {
    //draftChangesGridList: [],
    //feeGridList: [],
    //paymentGridList: [],
    reasonsList: [],
    //chargesGridList: [],
    chargeTypeList:[]
  },
  actions: {
    CREATE_WITHDRAWAL_REQUEST: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.createWithdrawalChanges(payload));

      return [err, result];
    },
    GET_SETTLEMENT_FEE_INFO: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.getSettlementFeeInfo(payload));

      return [err, result];
    },

    GET_ALL_CHARGE_TYPES: async ({ commit,state }, payload) => {

      if (state.chargeTypeList.length > 0) return;
      
      let err, result;
      [err, result] = await to(api.getAllChargesTypes(payload));
      if (result) {
        commit("SET_ALL_CHARGE_TYPES", result.Data);
      }
    },
    CREATE_PAYMENT_CHANGE: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.paymentChangesCreate(payload));

      return [err, result];
    },
    CREATE_CHARGE_CHANGES: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.chargeChangesCreate(payload));

      return [err, result];
    },
    PAYMENT_GET_DETAILS: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.getPayeeDetails(payload));

      return [err, result];
    },
    PAYMENT_PAYEE_SEARCH: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.searchPayee(payload));

      return [err, result];
    },
    PAYMENT_NEW_PAYEE: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.addPayee(payload));

      return [err, result];
    },
    ADD_PAYEE_BANK: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.addPayeeBank(payload));

      return [err, result];
    },
    UPDATE_PAYEE_BANK: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.editPayeeBank(payload));

      return [err, result];
    },
    REMOVE_PAYEE_BANK: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.removePayeeBank(payload));

      return [err, result];
    },
    ADD_PAYEE_ADDRESS: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.addPayeeAddress(payload));

      return [err, result];
    },
    UPDATE_PAYEE_ADDRESS: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.editPayeeAddress(payload));

      return [err, result];
    },
    REMOVE_PAYEE_ADDRESS: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.removePayeeAddress(payload));

      return [err, result];
    },
    GET_PAYMENT_CHANGES_PAYMENT_TYPES: async ({ commit }) => {
      let err, result;
      [err, result] = await to(api.getNegotiatorTasksPaymentTypes());
      return [err, result];
    },
    GET_PAYMENT_CHANGES_PAYMENT_TYPES_WITH_FEES: async ({ commit },payload) => {
      let err, result;
      [err, result] = await to(api.getPaymentTypesByPolicyGroup(payload));
      return [err, result];
    },

    GET_PAYMENT_CHANGES_GRID: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.getGcsTabPayments(payload));
      return [err,result];
      // if (result) {
      //   commit("SET_PAYMENT_CHANGES_GRID", result.Data);
      // }
    },
    GET_CHARGES_CHANE_GRID: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.getChargesInfo(payload));
      return [err,result];
      // if (result) {
      //   commit("SET_CHARGES_CHANE_GRID", result.Data);
      // }
    },
    GET_CHARGE_LIST: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.getChargesInfo(payload));
      return [err, result];
    },
    GET_PAYMENT_CHANGES_GRID_WITH_CREDITOR: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.getGcsTabPayments(payload));
      return [err, result];
    },
    GET_FEE_LIST: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.getGcsTabFees(payload));
      return [err, result];
    },
    GET_PAYMENT_LIST: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.getGcsTabPayments(payload));
      return [err, result];
    },
    CREATE_FEE_CHANGE: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.feeChangesCreate(payload));
      return [err, result];
    },
    GET_FEE_CHANGES_GRID: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getGcsTabFees(payload));
      return [err, result];
      // if (result) {
      //   commit("SET_FEE_CHANGES_GRID", result.Data);
      // }
    },

    CREATE_SKIP_PAYMENT: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.gcsTabSkipPaymentCreate(payload));
      return [err, result];
    },

    CREATE_ADDITIONAL_NOTE: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.gcsTabAdditionalNoteCreate(payload));
      return [err, result];
    },

    CREATE_EXTRA_PAYMENT: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.gcsTabExtraPaymentCreate(payload));
      return [err, result];
    },

    CREATE_DRAFT: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.gcsTabDraftCreate(payload));
      return [err, result];
    },

    CREATE_CLIENT_STATUS: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.gcsTabClientStatusCreate(payload));
      return [err, result];
    },

    GET_EXTRA_PAYMENT_DRAFT_TYPES: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.gcsTabExtraPaymentDraftTypes(payload));

      return [err, result];
    },

    GET_FEES_TYPES: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.gcsTabFeesGetTypes(payload));

      return [err, result];
    },

    GET_DRAFT_CHANGES_GRID: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getGcsTabDrafts(payload));

      return [err,result];
      // if (result) {
      //   commit("SET_DRAFT_CHANGES_GRID", result.Data);
      // }
    },

    GET_DRAFT_LIST: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getGcsTabDrafts(payload));

      return [err, result];
    },

    GET_CLIENT_STATUS_RELEASE_TYPES: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.gcsTabClientStatusReleaseTypes(payload));
      return [err, result];
    },

    GET_CUSTOMER_CANCELLATION_INFO: async ({ commit, state }, payload) => {
      let err, result;
      [err, result] = await to(api.getCustomerCancellationInfo(payload));
      return [err, result];
    },
    GET_CUSTOMER_CANCELATION_REASON_STATUS_CHANGE: async (
      { commit },
      payload
    ) => {
      let err, result;
      [err, result] = await to(adminApi.getCustomerCancelationReason(payload));
      if (result) {
        commit("SET_CUSTOMER_CANCELATION_REASON_STATUS_CHANGE", result.Data);
      }
    },
  },
  mutations: {
    SET_CUSTOMER_CANCELATION_REASON_STATUS_CHANGE: (state, payload) => {
      state.reasonsList = payload;
    },
    // SET_DRAFT_CHANGES_GRID: (state, payload) => {
    //   state.draftChangesGridList = payload;
    // },

    // SET_FEE_CHANGES_GRID: (state, payload) => {
    //   state.feeGridList = payload;
    // },
    // SET_PAYMENT_CHANGES_GRID: (state, payload) => {
    //   state.paymentGridList = payload;
    // },
    // SET_CHARGES_CHANE_GRID: (state, payload) => {
    //   state.chargesGridList = payload;
    // },
    SET_ALL_CHARGE_TYPES: (state, payload) => {
      state.chargeTypeList = payload;
    },
  },
};
