<template>
  <div class="col-md-12">
    <ReportSummary
      :exportData="reportData"
      :reportName="'PurchasesCreditorInformationReport'"
      :recordCount="recordCount"
      @updateLoadingStatus="reportLoading = $event"
      @setPayload="setPayload"
      @clearReport="clearReport"
      @setPagination="setPagination"
      :loadingSpinner="loadingSpinner"
    />

    <div
      class="datagrid-table gcsTabsTableScroll report-wrapper"
      :style="'max-height: ' + reportsPanelHeight + 'px; overflow-y:auto;'"
    >
      <o-table
        :data="filteredPurchasesCreditorInsuranceReport"
        :paginated="
          isPaginated && filteredPurchasesCreditorInsuranceReport.length > 0
        "
        :per-page="perPage"
        :pagination-simple="isPaginationSimple"
        :pagination-position="paginationPosition"
        :sticky-header="true"
      >
        <o-table-column field="ClientId" label="Client ID" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="ClientIdFilter"
                placeholder=""
                v-myMask="{
                  alias: 'numeric',
                  rightAlign: false,
                }"
              />
            </div>
          </template>

          <template v-slot="props">
            <span
              @click="viewCustomer(props.row.ClientId, props.row.CustomerName)"
              :class="
                getTagReportHyperLinkClass(
                  props.row.ClientId && props.row.CustomerName
                )
              "
            >
              <strong v-if="props.row.IsSummary"
                >{{ props.row.ClientId }}
              </strong>
              <span v-if="!props.row.IsSummary">
                {{ addPrefixToCustomerId(props.row.ClientId) }}
              </span>
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="CreditorId"
          label="Unique Debt ID"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CreditorIdFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.CreditorId }}
          </template>
        </o-table-column>

        <o-table-column
          field="OriginalCreditor"
          label="Original Creditor"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="OriginalCreditorFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.OriginalCreditor }}
          </template>
        </o-table-column>

        <o-table-column
          field="OriginalBalance"
          label="Original Balance"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="OriginalBalanceFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.OriginalBalance }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatMoney(props.row.OriginalBalance) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="CurrentBalance"
          label="Current Balance"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CurrentBalanceFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.CurrentBalance }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatMoney(props.row.CurrentBalance) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="CreditorStatus"
          label="Debt Status"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CreditorStatusFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.CreditorStatus }}
          </template>
        </o-table-column>

        <o-table-column
          field="SettlementStatus"
          label="Settlement Status"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="SettlementStatusFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.SettlementStatus }}
          </template>
        </o-table-column>

        <o-table-column
          field="DebtEnrollmentTime"
          label="Debt Enrollment Timing"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="DebtEnrollmentTimeFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ formatShortDate(props.row.DebtEnrollmentTime) }}
          </template>
        </o-table-column>

        <o-table-column
          field=""
          label="View Customer"
          position="centered"
          v-slot="props"
        >
          <button
            @click="viewCustomer(props.row.ClientId, props.row.CustomerName)"
            class="btn btn-info waves-effect waves-light ml-3"
            v-if="!props.row.IsSummary"
          >
            <i class="far fa-address-card"></i>
          </button>
        </o-table-column>
      </o-table>
      <section
        v-if="filteredPurchasesCreditorInsuranceReport.length == 0"
        class="section"
        style="text-align: center"
      >
        <div class="content has-text-grey has-text-centered mb-4">
          <p class="noMargin">
            <i
              style="font-size: 24px; color: #939393"
              class="ri-question-line"
            ></i>
          </p>
          <p class="noMargin">Nothing here.</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import ReportSummary from "./ReportSummary.vue";
import utilitiesMixin from "@/mixins/utilitiesMixin";

export default {
  name: "PurchasesCreditorInformationReport",
  components: { ReportSummary },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      customerPrefix: null,
      reportsPanelHeight: 300,
      loadingSpinner: false,
      isPaginationSimple: false,
      isPaginated: true,
      paginationPosition: "both",
      currentPage: 1,
      perPage: 200,
      recordCount: 0,
      reportLoading: false,
      reportData: [],

      purchasesCreditorInsuranceReportInitSummary: {
        ClientId: "",
        OriginalCreditor: "",
        OriginalBalance: "",
        CurrentBalance: "",
        SettlementStatus: "",
        CreditorId: "",
        CreditorStatus: "",
        DebtEnrollmentTime: "",
      },
      ClientIdFilter: "",
      OriginalCreditorFilter: "",
      OriginalBalanceFilter: "",
      CurrentBalanceFilter: "",
      SettlementStatusFilter: "",
      CreditorIdFilter: "",
      CreditorStatusFilter: "",
      DebtEnrollmentTimeFilter: "",
    };
  },
  computed: mapState({
    purchaseCreditorInsuranceList: (state) =>
      state.reports.purchaseCreditorInsuranceList,

    filteredPurchasesCreditorInsuranceReport() {
      let rtn = [];

      let tmpList =
        this.purchaseCreditorInsuranceList.length > 0
          ? this.purchaseCreditorInsuranceList.filter((item) => {
              return (
                this.filterString(item.ClientId, this.ClientIdFilter) &&
                this.filterString(
                  item.OriginalCreditor,
                  this.OriginalCreditorFilter
                ) &&
                this.filterString(
                  item.OriginalBalance,
                  this.OriginalBalanceFilter
                ) &&
                this.filterString(
                  item.CurrentBalance,
                  this.CurrentBalanceFilter
                ) &&
                this.filterString(
                  item.SettlementStatus,
                  this.SettlementStatusFilter
                ) &&
                this.filterString(item.CreditorId, this.CreditorIdFilter) &&
                this.filterString(
                  item.CreditorStatus,
                  this.CreditorStatusFilter
                ) &&
                new Date(item.DebtEnrollmentTime)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .indexOf(this.DebtEnrollmentTimeFilter) > -1
              );
            })
          : [];

      if (tmpList.length == 0) {
        this.reportData = [];
        return rtn;
      }
      let totalCustomer = tmpList.length.toString();

      this.purchasesCreditorInsuranceReportInitSummary.ClientId = "Total: " + totalCustomer;
      this.recordCount = tmpList.length;
      this.purchasesCreditorInsuranceReportInitSummary.IsSummary = true;

      rtn.push(...tmpList);
      rtn.push(this.purchasesCreditorInsuranceReportInitSummary);
      this.reportData = rtn;

      return rtn;
    },
  }),
  async mounted() {
    this.reportsPanelHeight = this.setReportGridHeight(
      window.innerHeight,
      document.getElementById("reportsSummary")
    );
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

    if (
      userInfo != null &&
      userInfo.customerIdPrefix != null &&
      userInfo.customerIdPrefix.length > 0
    ) {
      this.customerPrefix = userInfo.customerIdPrefix;
    }

    await this.sleep(1000);
    this.fixStickyHeaders();
  },
  methods: {
    addPrefixToCustomerId(customerId) {
      let rtn = customerId.toString();
      if (this.customerPrefix != null) {
        rtn = this.customerPrefix + rtn;
      }
      return rtn;
    },
    setPagination(data) {
      this.isPaginated = data;
    },
    async setPayload(value) {
      this.loadingSpinner = true;
      await this.$store.dispatch(
        types.GET_PURCHASE_CREDITOR_INSURANCE_REPORT,
        value
      );
      this.loadingSpinner = false;
      await this.sleep(500);
      this.fixStickyHeaders();
    },
    clearReport() {
      this.$store.dispatch(types.RESET_REPORTS);
      this.reportData = [];
    },
    async viewCustomer(id, name) {
      this.$Emitter.emit("viewCustomer", {
        customerId: id,
        customerName: name,
      });
    },
  },
};
</script>

<style scoped></style>
