<template>
  <div class="col-md-12">
    <ReportSummary
      :exportData="reportData"
      :reportName="'GlobalPaymentsAndFeesReport'"
      :recordCount="recordCount"
      @updateLoadingStatus="reportLoading = $event"
      @setPayload="setPayload"
      @clearReport="clearReport"
      @setPagination="setPagination"
      :loadingSpinner="loadingSpinner"
    />

    <div
      class="datagrid-table gcsTabsTableScroll report-wrapper"
      :style="'max-height: ' + reportsPanelHeight + 'px; overflow-y:auto;'"
    >
      <o-table
        :data="filteredGlobalPaymentsAndFeesReport"
        :paginated="
          isPaginated && filteredGlobalPaymentsAndFeesReport.length > 0
        "
        :per-page="perPage"
        :pagination-simple="isPaginationSimple"
        :pagination-position="paginationPosition"
        :sticky-header="true"
      >
        <o-table-column
          field="CustomerId"
          label="Customer Id"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CustomerIdFilter"
                placeholder=""
                v-myMask="{
                  alias: 'numeric',
                  rightAlign: false,
                }"
              />
            </div>
          </template>

          <template v-slot="props">
            <span
              @click="
                viewCustomer(props.row.CustomerId, props.row.CustomerName)
              "
              :class="
                getTagReportHyperLinkClass(
                  props.row.CustomerId && props.row.CustomerName
                )
              "
            >
              <strong v-if="props.row.IsSummary"
                >{{ props.row.CustomerId }}
              </strong>
              <span v-if="!props.row.IsSummary">
                {{ props.row.CustomerId }}
              </span>
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="DpgContactId"
          label="DPG Contact Id"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="DpgContactIdFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.DpgContactId }}
          </template>
        </o-table-column>

        <o-table-column
          field="CustomerName"
          label="Customer Name"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CustomerNameFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.CustomerName }}
          </template>
        </o-table-column>

        <o-table-column field="Office" label="Office" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="OfficeFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.Office }}
          </template>
        </o-table-column>

        <o-table-column field="State" label="State" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="StateFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.State }}
          </template>
        </o-table-column>

        <o-table-column field="Creditor" label="Creditor" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CreditorFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.Creditor }}
          </template>
        </o-table-column>

        <o-table-column
          field="CreditorAcctNum"
          label="Account Number"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CreditorAcctNumFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.CreditorAcctNum }}
          </template>
        </o-table-column>

        <o-table-column
          field="OriginalBalance"
          label="Original Balance"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="OriginalBalanceFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.OriginalBalance }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatMoney(props.row.OriginalBalance) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column field="Memo" label="Memo" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="MemoFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.Memo }}
          </template>
        </o-table-column>

        <o-table-column field="TranType" label="Tran Type" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="TranTypeFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.TranType }}
          </template>
        </o-table-column>

        <o-table-column
          field="TransAmount"
          label="Tran Amount"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="TransAmountFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.TransAmount }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatMoney(props.row.TransAmount) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column field="TransDate" label="Tran Date" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="TransDateFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ formatShortDate(props.row.TransDate) }}
          </template>
        </o-table-column>

        <o-table-column
          field="TransStatus"
          label="Tran Status"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="TransStatusFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.TransStatus }}
          </template>
        </o-table-column>

        <o-table-column
          field="ClearedDate"
          label="Cleared Date"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="ClearedDateFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ formatShortDate(props.row.ClearedDate) }}
          </template>
        </o-table-column>

        <o-table-column
          field="SettlementDate"
          label="Settlement Date"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="SettlementDateFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ formatShortDate(props.row.SettlementDate) }}
          </template>
        </o-table-column>

        <o-table-column
          field="SettlementAmount"
          label="Settlement Amount"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="SettlementAmountFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.SettlementAmount }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatMoney(props.row.SettlementAmount) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="FeeAmount"
          label="Fee Amount"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="FeeAmountFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.FeeAmount }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatMoney(props.row.FeeAmount) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="PmtCnt"
          label="Duration of Settlement Payments"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="PmtCntFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.PmtCnt }}
          </template>
        </o-table-column>

        <o-table-column
          field="FeeCnt"
          label="Duration of Settlement Fees"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="FeeCntFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.FeeCnt }}
          </template>
        </o-table-column>

        <o-table-column
          field="ClearedPmtAmt"
          label="Cleared Pmt Amt"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="ClearedPmtAmtFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.ClearedPmtAmt }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{
                props.row.ClearedPmtAmt == null
                  ? formatMoney(0)
                  : formatMoney(props.row.ClearedPmtAmt)
              }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="ClearedFeeAmt"
          label="Cleared Fee Amt"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="ClearedFeeAmtFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.ClearedFeeAmt }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{
                props.row.ClearedFeeAmt == null
                  ? formatMoney(0)
                  : formatMoney(props.row.ClearedFeeAmt)
              }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="RemainFeeAmt"
          label="Remaining Fee Amt"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="RemainFeeAmtFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.RemainFeeAmt }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{
                props.row.RemainFeeAmt == null
                  ? formatMoney(0)
                  : formatMoney(props.row.RemainFeeAmt)
              }}
            </span>
          </template>
        </o-table-column>
        <o-table-column
          field=""
          label="View Customer"
          position="centered"
          v-slot="props"
        >
          <button
            @click="viewCustomer(props.row.CustomerId, props.row.CustomerName)"
            class="btn btn-info waves-effect waves-light ml-3"
            v-if="!props.row.IsSummary"
          >
            <i class="far fa-address-card"></i>
          </button>
        </o-table-column>
      </o-table>
      <section
        v-if="filteredGlobalPaymentsAndFeesReport.length == 0"
        class="section"
        style="text-align: center"
      >
        <div class="content has-text-grey has-text-centered mb-4">
          <p class="noMargin">
            <i
              style="font-size: 24px; color: #939393"
              class="ri-question-line"
            ></i>
          </p>
          <p class="noMargin">Nothing here.</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import ReportSummary from "./ReportSummary.vue";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import deepCopy from "@/helpers/deepCopy";
export default {
  name: "GlobalPaymentsAndFeesReport",
  components: { ReportSummary },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      customerPrefix: null,
      reportsPanelHeight: 300,
      loadingSpinner: false,
      isPaginationSimple: false,
      isPaginated: true,
      paginationPosition: "both",
      currentPage: 1,
      perPage: 200,
      recordCount: 0,
      reportLoading: false,
      reportData: [],
      // canSeePaymentPlanner: false,
      globalPaymentsAndFeesReportInitSummary: {
        CustomerId: "",
      },

      CustomerIdFilter: "",
      DpgContactIdFilter: "",
      CustomerNameFilter: "",
      OfficeFilter: "",
      StateFilter: "",
      CreditorFilter: "",
      CreditorAcctNumFilter: "",
      OriginalBalanceFilter: "",
      MemoFilter: "",
      TranTypeFilter: "",
      TransAmountFilter: "",
      TransDateFilter: "",
      TransStatusFilter: "",
      ClearedDateFilter: "",
      SettlementDateFilter: "",
      SettlementAmountFilter: "",
      FeeAmountFilter: "",
      PmtCntFilter: "",
      FeeCntFilter: "",
      ClearedPmtAmtFilter: "",
      ClearedFeeAmtFilter: "",
      RemainFeeAmtFilter: "",
    };
  },
  computed: mapState({
    dpgPaymentandFeesList: (state) => state.reports.dpgPaymentandFeesList,

    filteredGlobalPaymentsAndFeesReport() {
      let rtn = [];
      let tmpList =
        this.dpgPaymentandFeesList.length > 0
          ? this.dpgPaymentandFeesList.filter((item) => {
              return (
                this.filterString(item.CustomerId, this.CustomerIdFilter) &&
                this.filterString(item.DpgContactId, this.DpgContactIdFilter) &&
                this.filterString(item.CustomerName, this.CustomerNameFilter) &&
                this.filterString(item.Office, this.OfficeFilter) &&
                this.filterString(item.State, this.StateFilter) &&
                this.filterString(item.Creditor, this.CreditorFilter) &&
                this.filterString(
                  item.CreditorAcctNum,
                  this.CreditorAcctNumFilter
                ) &&
                this.filterString(
                  item.OriginalBalance,
                  this.OriginalBalanceFilter
                ) &&
                this.filterString(item.Memo, this.MemoFilter) &&
                this.filterString(item.TranType, this.TranTypeFilter) &&
                this.filterString(item.TransAmount, this.TransAmountFilter) &&
                this.filterString(item.TransStatus, this.TransStatusFilter) &&
                this.filterString(
                  item.SettlementAmount,
                  this.SettlementAmountFilter
                ) &&
                this.filterString(item.FeeAmount, this.FeeAmountFilter) &&
                this.filterString(item.PmtCnt, this.PmtCntFilter) &&
                this.filterString(item.FeeCnt, this.FeeCntFilter) &&
                this.filterString(
                  item.ClearedPmtAmt,
                  this.ClearedPmtAmtFilter
                ) &&
                this.filterString(
                  item.ClearedFeeAmt,
                  this.ClearedFeeAmtFilter
                ) &&
                this.filterString(item.RemainFeeAmt, this.RemainFeeAmtFilter) &&
                new Date(item.TransDate)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .indexOf(this.TransDateFilter) > -1 &&
                new Date(item.ClearedDate)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .indexOf(this.ClearedDateFilter) > -1 &&
                new Date(item.SettlementDate)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .indexOf(this.SettlementDateFilter) > -1
              );
            })
          : [];

      if (tmpList.length == 0) {
        this.reportData = [];
        return rtn;
      }
      let totalCustomer = tmpList.length.toString();

      this.globalPaymentsAndFeesReportInitSummary.CustomerId =
        "Total: " + totalCustomer;
      this.recordCount = tmpList.length;
      this.globalPaymentsAndFeesReportInitSummary.IsSummary = true;

      rtn.push(...tmpList);
      rtn.push(this.globalPaymentsAndFeesReportInitSummary);
      this.reportData = rtn;

      return rtn;
    },
  }),
  async mounted() {
    this.reportsPanelHeight = this.setReportGridHeight(
      window.innerHeight,
      document.getElementById("reportsSummary")
    );
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

    if (
      this.userInfo != null &&
      this.userInfo.customerIdPrefix != null &&
      this.userInfo.customerIdPrefix.length > 0
    ) {
      this.customerPrefix = this.userInfo.customerIdPrefix;
    }
    // if (this.userInfo != null) {
    //   let arrPermission = this.userInfo.moduleFunctions
    //     .split(",")
    //     .map(function (item) {
    //       return parseInt(item, 10);
    //     });
    //   if (arrPermission.includes(55015)) {
    //     this.canSeePaymentPlanner = true;
    //   }
    // }
    await this.sleep(1000);
    this.fixStickyHeaders();
  },
  methods: {
    // getPaymentPlannerValue(settDate) {
    //   let rtn = "No";
    //   let initialDate = "2024-01-12";
    //   if (Date.parse(settDate.toString()) >= Date.parse(initialDate)) {
    //     rtn = "Yes";
    //   }
    //   return rtn;
    // },
    addPrefixToCustomerId(customerId) {
      let rtn = customerId.toString();
      if (this.customerPrefix != null) {
        rtn = this.customerPrefix + rtn;
      }
      return rtn;
    },
    setPagination(data) {
      this.isPaginated = data;
    },
    async setPayload(value) {
      this.loadingSpinner = true;
      await this.$store.dispatch(types.GET_DPG_PAYMENTS_AND_FEES_REPORT, value);
      this.loadingSpinner = false;

      await this.sleep(500);
      this.fixStickyHeaders();
    },
    clearReport() {
      this.$store.dispatch(types.RESET_REPORTS);
      this.reportData = [];
    },
    async viewCustomer(id, name) {
      this.$Emitter.emit("viewCustomer", {
        customerId: id.replace(/\D/g, ""),
        customerName: name,
      });
    },
  },
};
</script>

<style scoped></style>
