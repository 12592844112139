let plivoBrowserSdk = null;
export default {
  methods: {
    InitializeSDK(
      onLoginCallBack,
      onLoginFailedCallBack,
      onLogoutCallBack,
      onCallingCallback,
      onCallFailedCallback,
      onCallRemoteRingingCallback,
      onCallAnsweredCallback,
      onCallTerminatedCallback,
      onIncomingCallCallback,
      onConnectionChangeCallback,
      onIncomingCallCanceledCallback,
      mediaMetricsCallback
    ) {
      var options = {
        debug: "OFF",
        permOnClick: true,
        enableTracking: true,
        enableQualityTracking: "all",
        allowMultipleIncomingCalls: true,
      };

      try {
        if (!plivoBrowserSdk) plivoBrowserSdk = new Plivo(options);
      } catch {
        return null;
      }

      if (!plivoBrowserSdk) return;

      plivoBrowserSdk.client.setRingTone(false);

      plivoBrowserSdk.client.removeAllListeners();
      //Register SDK Events
      if (onLoginCallBack !== null) {
        plivoBrowserSdk.client.on("onLogin", onLoginCallBack);
      }

      if (onLoginFailedCallBack !== null) {
        plivoBrowserSdk.client.on("onLoginFailed", onLoginFailedCallBack);
      }

      if (onLogoutCallBack !== null) {
        plivoBrowserSdk.client.on("onLogout", onLogoutCallBack);
      }

      if (onCallingCallback !== null) {
        plivoBrowserSdk.client.on("onCalling", onCallingCallback);
      }

      if (onCallFailedCallback !== null) {
        plivoBrowserSdk.client.on("onCallFailed", onCallFailedCallback);
      }

      if (onCallRemoteRingingCallback !== null) {
        plivoBrowserSdk.client.on(
          "onCallRemoteRinging",
          onCallRemoteRingingCallback
        );
      }

      if (onCallAnsweredCallback !== null) {
        plivoBrowserSdk.client.on("onCallAnswered", onCallAnsweredCallback);
      }

      if (onCallTerminatedCallback !== null) {
        plivoBrowserSdk.client.on("onCallTerminated", onCallTerminatedCallback);
      }

      if (onIncomingCallCallback !== null) {
        plivoBrowserSdk.client.on("onIncomingCall", onIncomingCallCallback);
      }

      if (onConnectionChangeCallback !== null) {
        plivoBrowserSdk.client.on(
          "onConnectionChange",
          onConnectionChangeCallback
        );
      }

      if (onIncomingCallCanceledCallback !== null) {
        plivoBrowserSdk.client.on(
          "onIncomingCallCanceled",
          onIncomingCallCanceledCallback
        );
      }

      if (mediaMetricsCallback !== null) {
        plivoBrowserSdk.client.on("mediaMetrics", mediaMetricsCallback);
      }

      return plivoBrowserSdk;
    },
  },
};
