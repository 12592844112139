<template>
  <div class="col-md-12 newPanelWrapper noMargin pt-1">
    <div>
      <div>
        <label>Call Log Details</label>
      </div>
      <div class="closeButton" v-on:click.prevent.stop="closePanel">
        <i class="fas fa-window-close"></i>
      </div>
      <hr />
    </div>

    <div v-if="callDetails" class="col-md-12">
      <div style="display: flex; justify-content: space-evenly">
        <div>
          <label class="mr-2 generalLabel">{{ customer }}:</label>
          <label v-if="!missedCallReason" class="generalLabel">{{
            callType
          }}</label>
          <label v-if="missedCallReason" class="generalLabel">{{
            missedCallReason
          }}</label>
        </div>
        <div
          v-if="callDetails.CallStartTime && callDetails.CallStartTime != null"
        >
          <label class="mr-2 generalLabel">Start Time:</label>
          <label class="generalLabel">{{
            new Date(callDetails.CallStartTime).toLocaleTimeString("en-US", {
              hour: "2-digit",
              minute: "2-digit",
            })
          }}</label>
        </div>
        <div v-if="callDetails.CallEndTime && callDetails.CallEndTime != null">
          <label class="mr-2 generalLabel">End Time:</label>
          <label class="generalLabel">{{
            new Date(callDetails.CallEndTime).toLocaleTimeString("en-US", {
              hour: "2-digit",
              minute: "2-digit",
            })
          }}</label>
        </div>
        <div v-if="callDetails.AnswerTime && callDetails.AnswerTime != null">
          <label class="mr-2 generalLabel">Hold Time (SLA):</label>
          <label class="generalLabel">{{ callDetails.AnswerTime }}</label>
        </div>
      </div>
      <div v-if="callDetails.Details.length > 0">
        <perfect-scrollbar
          style="max-height: 50vh; overflow-y: auto; overflow-x: auto"
        >
          <table class="col-md-12 datagrid-table">
            <thead style="position: sticky; top: 0">
              <tr>
                <th>Screen Name</th>
                <th
                  style="text-align: center"
                  v-for="item in minutes"
                  :key="item"
                >
                  {{
                    new Date(item).toLocaleTimeString("en-US", {
                      hour: "2-digit",
                      minute: "2-digit",
                    })
                  }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in callDetails.Details"
                :key="item.UserId"
              >
                <td
                  :style="
                    item.Statuses == null
                      ? 'background-color:#ea2027; color:white'
                      : 'background-color:rgba(234, 32, 39, 0)'
                  "
                >
                  {{ index + 1 }}. {{ item.Name }}
                </td>
                <td
                  v-for="col in item.Minutes"
                  :key="col"
                  :class="'status-' + getUserStatus(col, item.Statuses)"
                ></td>
              </tr>
            </tbody>
          </table>
        </perfect-scrollbar>
      </div>

      <div class="col-md-12 noPadding">
        <a>Measurements are taken in 30 sec increments.</a>
      </div>

      <section
        v-if="callDetails.Details.length == 0"
        class="section"
        style="text-align: center"
      >
        <div class="content has-text-grey has-text-centered mb-4">
          <p class="noMargin">
            <i
              style="font-size: 24px; color: black"
              class="ri-question-line"
            ></i>
          </p>
          <p class="noMargin generalLabel">Nothing here.</p>
        </div>
      </section>
      <div
        v-if="callDetails.Details.length > 0"
        style="display: flex; justify-content: space-evenly"
        class="mt-3"
      >
        <div
          style="
            display: flex;
            justify-content: center;
            height: 26px;
            width: 120px;
            background-color: #ea2027;
            color: white;
            font-variant: all-petite-caps;
          "
        >
          <label>Offline</label>
        </div>
        <div
          style="
            display: flex;
            justify-content: center;
            height: 26px;
            width: 120px;
            background-color: #f9bb42;
            color: white;
            font-variant: all-petite-caps;
          "
        >
          <label>Away</label>
        </div>
        <div
          style="
            display: flex;
            justify-content: center;
            height: 26px;
            width: 120px;
            background-color: #1cbf64;
            color: white;
            font-variant: all-petite-caps;
          "
        >
          <label>Online</label>
        </div>
        <div
          style="
            display: flex;
            justify-content: center;
            height: 26px;
            width: 120px;
            background-color: #108042;
            color: white;
            font-variant: all-petite-caps;
          "
        >
          <label>Incoming Call</label>
        </div>
        <div
          style="
            display: flex;
            justify-content: center;
            height: 26px;
            width: 120px;
            background-color: #68c1e3;
            color: white;
            font-variant: all-petite-caps;
          "
        >
          <label>On a call</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utilitiesMixin from "@/mixins/utilitiesMixin";
import formatMixin from "@/mixins/formatMixin";
import types from "./types";
import deepCopy from "@/helpers/deepCopy";

export default {
  name: "CallLogDetailsPopUp",
  mixins: [formatMixin, utilitiesMixin],
  props: {
    callLogId: {
      type: Number,
      default: () => 0,
    },
    missedCallReason: {
      type: String,
      default: () => "",
    },
    customer: {
      type: String,
      default: () => "",
    },
    callType: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      callDetails: null,
      minutes: [],
    };
  },
  async mounted() {
    let err, result;
    [err, result] = await this.$store.dispatch(types.GET_CALL_LOG_DETAIL, {
      Id: this.callLogId,
    });
    if (result) {
      this.callDetails = deepCopy(result.Data);
      this.setTable();
    } else {
      let errMsg = this.getApiErrorMessage(err);
      this.$swal("Error!", errMsg, "error");
    }
  },
  methods: {
    setTable() {
      let minDate = 0;
      let maxDate = 0;
      let duration = 0;

      for (let item of this.callDetails.Details) {
        if (item.Statuses != null) {
          for (let status of item.Statuses) {
            if (
              minDate == 0 ||
              new Date(status.EventDate).getTime() < minDate
            ) {
              minDate = new Date(status.EventDate).getTime();
            }
            if (
              maxDate == 0 ||
              new Date(status.EventDate).getTime() > maxDate
            ) {
              maxDate = new Date(status.EventDate).getTime();
            }
          }
        }
      }
      duration = parseFloat(maxDate - minDate) / 1000;

      let startTime = deepCopy(minDate);

      if (duration > 0) {
        for (let i = 0; i <= duration; i += 60) {
          this.minutes.push(startTime);
          startTime = startTime + 60000;
        }
      }
      for (let detail of this.callDetails.Details) {
        detail.Minutes = this.minutes;
      }
    },

    getUserStatus(min, statusList) {
      let rtn = 0;
      if (statusList != null) {
        let indx = statusList.findIndex(
          (x) => new Date(x.EventDate).getTime() == new Date(min).getTime()
        );
        if (indx > -1) {
          rtn = statusList[indx].LoginStatusId;
        }
      }
      return rtn;
    },

    closePanel() {
      this.$emit("closeCallLogDetailsPopUp");
    },
  },
};
</script>

<style scoped>
.status-0 {
  background-color: #ea2027;
}
.status-1 {
  background-color: #1cbf64;
}
.status-2 {
  background-color: #f9bb42;
}
.status-3 {
  background-color: #68c1e3;
}
.status-4 {
  background-color: #108042;
}
td {
  border: 1px solid white;
}
</style>
