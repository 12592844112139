<template>
  <div
    class="col-md-12 newPanelWrapper noMargin dcp-mediaScreen"
    style="background-color: #ffffff; z-index: 1000"
  >
    <div
      style="display: flex; justify-content: space-between"
      v-on:click.prevent.stop="closePanel"
    >
      <label>Add Bulk Charges</label>
      <i class="fas fa-window-close closeButtonBulk"></i>
    </div>
    <!-- <div class="row" v-if="isDpg && creditorList.length > 0">
      <div class="col-md-12 mb-2">
        <label class="generalLabel">Creditor: </label>
        <select v-model="feeItem.CreditorId" class="form-control">
          <option :value="0">Please Select</option>
          <option v-for="item in creditorList" :value="item.Id" :key="item.Id">
            {{ item.Name }}
          </option>
        </select>
      </div>
    </div> -->
    <div class="row">
      <div class="col-md-4">
        <label for="serviceFee" class="generalLabel">Start Date:</label>

        <DatePicker
          mode="date"
          :locale="'en-US'"
          v-model="chargeItem.StartDate"
          :model-config="modelConfig"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <input
              id="DCP-feeDate"
              class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
              :value="inputValue"
              v-on="inputEvents"
              placeholder="Select Date"
            />
          </template>
        </DatePicker>
      </div>
      <div class="col-md-4">
        <label for="serviceFee" class="generalLabel">Charge Amount:</label>
        <input
          v-model="chargeItem.Amount"
          class="form-control"
          v-myMask="{
            alias: 'currency',
            prefix: '$',
            min: 0.01,
            max: 99999.99,
            allowMinus: false,
            rightAlign: false,
          }"
          placeholder="$0.00"
        />
      </div>
      <div class="col-md-3">
        <label for="serviceFee" class="generalLabel">Charge Count:</label>
        <input
          v-model="chargeItem.Count"
          class="form-control"
          v-myMask="{
            alias: 'numeric',
            min: 1,
            max: 120,
            digits: 0,
            allowMinus: false,
            rightAlign: false,
          }"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <label class="generalLabel">Charge Type:</label>

        <select
          style="width: 200px"
          class="form-control"
          v-model="chargeItem.TransactionType"
        >
          <option value="">Please Select</option>
          <option v-for="item in chargeTypeList" :value="item.GlobalFeeType" :key="item.GlobalFeeType">
            {{ item.GroupingKey }}
          </option>
        </select>
      </div>
      <div class="col-md-4">
        <label class="generalLabel">Schedule Type:</label>

        <select
          style="width: 200px"
          class="form-control"
          v-model="chargeItem.ScheduleType"
        >
          <option :value="null">Please Select</option>
          <option v-for="item in schedules" :value="item.Id" :key="item.Id">
            {{ item.Value }}
          </option>
        </select>
      </div>
      <div class="col-md-3" v-if="chargeItem && chargeItem.ScheduleType == 2">
        <label class="generalLabel">Day:</label>
        <CustomNumericInput
                        class="generalTextBox ml-1"
         
                        :value="twiceAmonthDay"
                        @updateValue="updateTwiceAMonthDay"
                        :minNumber="1"
                        :maxNumber="
                          getMaxDayofMonth(
                            chargeItem.StartDate
                          )
                        "
                        placeholder="Day"
                      />
       
      </div>
    </div>
    <div class="row">
      <div class="col-md-11 text-right">
        <button @click="applyChanges()" class="btn btn-primary">
          Submit Charges
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import types from "./types";
import formatMixin from "@/mixins/formatMixin";
import { DatePicker } from "v-calendar";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import { mapState } from "vuex";
import CustomNumericInput from "@/components/CustomNumericInput";
export default {
  name: "BulkAddChargePopup",
  components: { DatePicker,CustomNumericInput },
  props: {
    feeTypes: {
      Type: Array,
      default: [],
    },
    customerId: {
      Type: Number,
      default: 0,
    },
    officeId: {
      Type: Number,
      default: 0,
    },
    policyGroupId: {
      Type: Number,
      default: 0,
    },
    isDpg: {
      Type: Boolean,
      default: false,
    },
  },
  mixins: [formatMixin, utilitiesMixin],
  computed: mapState({
    chargeTypeList: (state) => state.draftChanges.chargeTypeList
  }),
  data() {
    return {
      chargeItem: {
        StartDate: "",
        Amount: 0.01,
        TransactionType: "",
        Count: 1,
        ScheduleType: 1,
      },
      addedList: [],
      creditorList: [],
      schedules: [
        { Id: 1, Value: "Monthly" },
        { Id: 2, Value: "Twice a Month" },
        { Id: 3, Value: "Bi-weekly" },
        { Id: 4, Value: "Weekly" },
    
      ],
      twiceAmonthDay:0,
      modelConfig: {
        type: "string",
        mask: "MM/DD/YYYY",
      },
    };
  },
  unmounted() {},
  async mounted() {


    await this.$store.dispatch(types.GET_ALL_CHARGE_TYPES,{

        OfficeId: this.officeId,
        PolicyGroupId: this.policyGroupId

    });

    //await this.getSettledCreditors();
  },
  methods: {
   
    //getCreditorName(id) {
    //   let rtn = "";
    //   if (this.creditorList && this.creditorList.length > 0) {
    //     let indx = this.creditorList.findIndex((x) => x.Id == id);
    //     if (indx > -1) {
    //       rtn = this.creditorList[indx].Name;
    //     }
    //   }
    //   return rtn;
    // },
    // async getSettledCreditors() {
    //   if (this.isDpg == true) {
    //     let err, result;

    //     [err, result] = await this.$store.dispatch(
    //       globalTypes.GET_CUSTOMER_SETTLED_CREDITORS,
    //       { CustomerId: this.customerId }
    //     );
    //     if (result && result.Data) {
    //       this.creditorList = deepCopy(result.Data);
    //     }
    //   }
    // },
    updateTwiceAMonthDay(value){
      this.twiceAmonthDay = value;
    },
    closePanel() {
      this.$emit("closeBulkAddChargePopup");
    },
    getChargeObject() {
      return {
        Active:"Y",
        EffectiveDate: "",
        ChargeId: 0,
        Amount: 0,
        TransactionType:"",
        TransactionCombined:"",
        isEditing: true,
        isDeleting: false,
        NewDate: "",
        NewAmount: 0,
      };
    },
    applyChanges() {
      if (this.chargeItem.StartDate == "") {
        this.$swal("Warning!", "Start Date is required", "warning");
        return;
      }

      let amount = parseFloat(this.chargeItem.Amount);
      if (amount < 0.01) {
        this.$swal("Warning!", "Charge Amount is required", "warning");
        return;
      }

      let count = parseInt(this.chargeItem.Count);
      if (count < 1) {
        this.$swal("Warning!", "Charge Count is required", "warning");
        return;
      }



      if (this.chargeItem.TransactionType == "") {
        this.$swal("Warning!", "Charge Type is required", "warning");
        return;
      }

      if (this.chargeItem.ScheduleType == 2) {
      
        if(this.twiceAmonthDay == 0)
        {
        this.$swal("Warning!", "The 'Day' field is required for this schedule type.", "warning");
        return;
        }
        else if(this.twiceAmonthDay  == new Date(this.chargeItem.StartDate).getDate())
        {
          this.$swal("Warning!", "The day for twice-a-month scheduling cannot be the same as the start date.", "warning");
            return;
        }
    }

      this.addedList = [];

      for (let i = 0; i < this.chargeItem.Count; i++) {
        let charge = this.getChargeObject();
        if (i > 0) {
          if (this.chargeItem.ScheduleType == 1) {
            charge.NewDate = this.formatShortDate(
              this.addMonthsFix(new Date(this.chargeItem.StartDate), i)
            );
          }
          else if(this.chargeItem.ScheduleType == 2)
          {
            let addMonths = Math.floor(i/2);
            let startDate = new Date(this.chargeItem.StartDate);
            if(i%2== 0)
            {
              charge.NewDate = this.formatShortDate(
              this.addMonthsFix(startDate, addMonths)
              );
            }
            else
            {
               let startDay = startDate.getDate();
               let newDate = this.addMonthsFix(startDate, addMonths);
               let month = newDate.getMonth();
               let year = newDate.getFullYear();
               if(this.twiceAmonthDay > 0)
               {
                let maxDay = this.getMaxDayofMonth(new Date(year,month,1));
                let monthsToAdd = startDay > this.twiceAmonthDay ? 1 :0;
                charge.NewDate = new Date(year,month+monthsToAdd,this.twiceAmonthDay > maxDay ? maxDay : this.twiceAmonthDay);
               }

            }
          }
           else if (this.chargeItem.ScheduleType == 3) {
            charge.NewDate = this.formatShortDate(
              this.addDays(new Date(this.chargeItem.StartDate), i * 14)
            );
          } else if (this.chargeItem.ScheduleType == 4) {
            charge.NewDate = this.formatShortDate(
              this.addDays(new Date(this.chargeItem.StartDate), i * 7)
            );
          }
          charge.ChargeId = -1 * i;
          charge.NewAmount = amount;
          charge.TransactionType = this.chargeItem.TransactionType;
          charge.TransactionCombined = this.chargeItem.TransactionType;
          this.addedList.unshift(charge);
        } else {
          charge.NewDate = this.formatShortDate(new Date(this.chargeItem.StartDate));
          charge.NewAmount = amount;
          charge.TransactionType = this.chargeItem.TransactionType;
          charge.TransactionCombined = this.chargeItem.TransactionType;
          this.addedList.unshift(charge);
        }
      }

      if (this.addedList.length > 0) {
        this.$emit("addBulkCharges", this.addedList);
      } else {
        this.$swal("Warning!", "No charge to add.", "warning");
      }
    },
  },
};
</script>

<style>
.closeButton {
  position: absolute;
  top: -5px;
  right: 15px;
  z-index: 1000;
  font-size: 30px;
  cursor: pointer;
}
.closeButton:hover {
  color: #ff3860;
}
@media screen and (max-height: 390px) {
  .dcp-mediaScreen {
    max-height: 200px !important;
    overflow-y: scroll;
  }
}
</style>
