<template>
  <div
    class="col-md-12 row newPanelWrapper noMargin"
    style="
      background-color: #ffffff;
      z-index: 1000;
      padding-left: 0;
      padding-right: 0;
    "
  >
    <div>
      <div class="closeButton" v-on:click.prevent.stop="closePanel">
        <i class="fas fa-window-close"></i>
      </div>
    </div>
    <perfect-scrollbar style="max-height: 600px; padding: 10px 30px 10px 10px">
      <div class="col-md-12 row">
        <div class="w-100 row" v-for="(g, index) in groups" :key="index">
          <div class="w-100 col-md-12">
            <label
              style="
                font-size: 1.5rem;
                padding: 10px 0;
                border-bottom: 2px solid #3bafda;
                color: #3bafda;
              "
              class="w-100"
              >{{ g.SectionName.replace(".", " => ") }}</label
            >
          </div>
          <div v-for="item in g.Value" :key="item.Key" class="col-md-3">
            <label class="generalLabel">{{ item.Key }}</label>
            <label class="w-100"> {{ item.Value }} </label>
          </div>
          <br />
        </div>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
export default {
  name: "ApplicationPopUp",
  props: {
    loanResult: {
      type: Array,
      default: () => [],
    },
  },
  components: {},
  computed: {
    flattenedData() {
      let flattened = [];
      if (this.loanResult) {
        for (let item of this.loanResult) {
          this.flattenObject(flattened, item);
        }
      }

      return flattened;
    },
  },
  mounted() {
    for (let item of this.loanResult) {
      this.getSections(item, "");
    }

    let sections = Object.groupBy(
      this.formattedData,
      ({ SectionName }) => SectionName
    );

    let keys = Object.keys(sections);
    for (let item of keys) {
      this.groups.push({ SectionName: item, Value: sections[item] });
    }
  },

  data() {
    return {
      formattedData: [],
      groups: [],
    };
  },
  methods: {
    getSections(item, parentKey) {
      if (typeof item == "object") {
        if (item) {
          let itemKeys = Object.keys(item);
          for (let key of itemKeys) {
            if (item[key]) {
              if (typeof item[key] != "object") {
                this.formattedData.push({
                  Key: key,
                  Value: item[key],
                  SectionName: parentKey,
                });
              } else {
                this.getSections(
                  item[key],
                  parentKey != "" ? parentKey + "." + key : key
                );
              }
            } else {
              this.formattedData.push({
                Key: key,
                Value: item[key],
                SectionName: parentKey,
              });
            }
          }
        } else {
          this.formattedData.push({
            Key: key,
            Value: item[key],
            SectionName: parentKey,
          });
        }
      }
    },

    closePanel() {
      this.$emit("closeApplicationPopUp");
    },
    flattenObject(flattened, source, parentKey = "", sectionName = "") {
      for (let key in source) {
        let value = source[key];
        let newKey = parentKey ? `${parentKey}.${key}` : key;

        if (value !== null && typeof value === "object") {
          this.flattenObject(flattened, value, newKey, key);
        } else {
          flattened.push({ key: newKey, value: value, sectionName: key });
        }
      }
    },
    getLastKey(fullKey) {
      let keyParts = fullKey.split(".");
      return keyParts[keyParts.length - 1];
    },
  },
};
</script>

<style>
.has-error {
  border: 2px solid red !important;
}
</style>
