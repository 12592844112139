<template>
  <div class="account-pages mt-5 mb-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6 col-xl-5">
          <div class="card">
            <div v-if="authenticate && !isAuthenticationCode">
              <MultiFactorAuth
                :authenticate="authenticate"
                @authCode="authenticationCode"
              ></MultiFactorAuth>
            </div>
            <div v-if="isAuthenticationCode">
              <MultiFactorAuthCode
                @authCode="authenticationCode"
              ></MultiFactorAuthCode>
            </div>

            <div v-if="!authenticate" class="card-body p-4">
              <div class="text-center w-75 m-auto">
                <a href="index.html">
                  <span>
                    <img :src="loginLogo" alt />
                  </span>
                </a>
                <p class="text-muted mb-4 mt-3">
                  Enter your email address and password to access the CRM.
                </p>
              </div>
              <!--test 111-->
              <form @submit.prevent="handleAuthentication" novalidate>
                <div class="form-group mb-3">
                  <label for="emailaddress">Email</label>
                  <input
                    name="username"
                    ref="username"
                    class="form-control"
                    type="text"
                    v-model="formUserName"
                    placeholder="Enter your email address"
                    @blur="v$.formUserName.$touch"
                  />

                  <div class="errorText" v-if="v$.formUserName.$error">
                    Email is required
                  </div>
                </div>

                <div class="form-group mb-3">
                  <br />
                  <label for="password">Password</label>
                  <input
                    name="password"
                    ref="password"
                    class="form-control"
                    type="password"
                    v-model="formPassword"
                    placeholder="Enter your password"
                    @blur="v$.formPassword.$touch"
                  />
                  <div class="errorText" v-if="v$.formPassword.$error">
                    Password is required
                  </div>
                </div>

                <div class="form-group mb-0 text-center">
                  <br />
                  <button
                    :disabled="loading"
                    class="animated-btn btn btn-primary btn-block"
                  >
                    <i v-if="loading" class="fas fa-spinner mr-1"></i>
                    Log In
                  </button>
                </div>

                <div class="form-group text-center mt-2">
                  <div style="margin-bottom: 6px" v-if="errStatus">
                    Wrong username or password...
                  </div>

                  <p class="forgotPasswordText" @click="openLoginOverlayPopUp">
                    Forgot Password?
                  </p>
                </div>
              </form>
              <div
                style="
                  width: 100%;
                  margin: 10px 0;
                  text-align: center;
                  font-size: 10px;
                "
              >
                v {{ crmVersion }}
              </div>
            </div>
            <!-- end card-body -->
          </div>
        </div>
        <!-- end col -->
      </div>
    </div>
    <!-- end container -->
    <div
      class="backgroundLock"
      v-if="isLoginOverlayPopUp"
      style="-webkit-box-align: center; align-items: center; display: flex"
    >
      <div
        style="position: relative; margin: 10% auto"
        class="col-xl-3 col-lg-9 col-md-12 col-sm-12"
      >
        <LoginOverlayPopUp
          @closeLoginOverlayPopUp="closeLoginOverlayPopUp"
          :formUserName="formUserName"
        >
        </LoginOverlayPopUp>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/services/login.service";
import to from "@/helpers/to";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import localStorageManagerMixin from "@/mixins/localStorageManagerMixin";
import globalTypes from "@/store/types";
//import plivoTypes from "@/components/PlivoParts/types";
import placeholdersTypes from "@/components/Placeholders/types";
//import  quickSmsTypes from "@/views/admin/QuickSmsManagement/types";
//import  scriptTypes from "@/views/admin/ScriptManagement/types";
import useValidate from "@vuelidate/core";
import { email, required } from "vuelidate/lib/validators";
import LoginOverlayPopUp from "@/components/LoginOverlayPopUp";
import MultiFactorAuth from "@/MultiFactorAuth";
import MultiFactorAuthCode from "@/MultiFactorAuthCode";

export default {
  name: "Login",
  mixins: [utilitiesMixin, localStorageManagerMixin],
  components: { LoginOverlayPopUp, MultiFactorAuth, MultiFactorAuthCode },
  emits: ["authenticated", "authCode"],
  data() {
    return {
      authenticate: false,
      isAuthenticationCode: false,
      isLoginOverlayPopUp: false,
      crmVersion: "",
      v$: useValidate(),
      errStatus: false,
      loading: false,
      formUserName: "",
      formPassword: "",
      keyInfo: {
        iv: null,
        key: null,
      },
      userInfo: {
        mfa: null,
        passwordExpirationDate: null,
        roles: null,
        moduleFunctions: null,
        token: "",
        screenName: "",
        userId: null,
        firstName: "",
        lastName: "",
        photoUrl: "",
        defaultPhotoUrl: "",
        companyName: "",
        leadTier: 1,
        tierTimeOut: 5,
        officeId: 0,
        companyId: 0,
        email: "",
        canSpeakSpanish: false,
        customerIdPrefix: null,
        hasAttorneyConfig: false,
        tierSettings: [],
        isElevated: false,
        subOffices: [],
      },
      userPhoneSettings: {
        endPointId: null,
        endPointAuthId: null,
        endPointName: null,
        endPointPassword: null,
        busKey: "",
        companyId: 0,
        isAutoAcceptCall: false,
        outboundCallMethod: 0,
        outboundCallerId: "",
        peerId: "",
        userDid: "",
        iceServers: null,
      },

      expireUTC: "",
      loginLogo: "",
      
      mfaInfo: {
        UserName: "",
        MfaToken: null,
      },
    };
  },
  async mounted() {
    sessionStorage.clear();
    this.crmVersion = process.env.VUE_APP_CRM_VERSION;
    this.loginLogo =
      this.$loginLogo +
      "?v=" +
      (Math.floor(Math.random() * 1000) + 1).toString();
    this.$emit("authenticated", false);

    
  },
  validations() {
    return {
      formUserName: { required, email },
      formPassword: { required },
    };
  },
  methods: {
    authenticationCode(value) {
      this.isAuthenticationCode = value;
    },

    openLoginOverlayPopUp() {
      this.isLoginOverlayPopUp = true;
    },
    closeLoginOverlayPopUp() {
      this.isLoginOverlayPopUp = false;
    },

    async handleAuthentication() {
      this.v$.$validate(); // checks all inputs

      if (!this.v$.$error) {
        // if ANY fail validation

        await Promise.all([
          this.$store.dispatch(globalTypes.RESET_STATES),
          //this.$store.dispatch(plivoTypes.RESET_STATES),
          //this.$store.dispatch(placeholdersTypes.RESET),
          //this.$store.dispatch(quickSmsTypes.RESET),
          //this.$store.dispatch(scriptTypes.RESET),
        ]);
        let err, responseVal;
        this.loading = true;

        try {
          let encPwd = await this.sha256(this.formPassword);
          let mfaToken = await this.getTokenFromLocalStorage(this.formUserName);
          const payload = {
            UserName: this.formUserName,
            MfaToken: mfaToken,
            Password: encPwd,
            Version: this.crmVersion,
          };


          [err, responseVal] = await to(api.authenticate(payload));

          if (responseVal.UserId == 0) {
            this.$swal("Warning!", responseVal.Message, "warning");
            this.loading = false;
            return;
          }
          this.keyInfo.iv = responseVal.IV;
          this.keyInfo.key = responseVal.Key;

          sessionStorage.setItem("keyInfo", JSON.stringify(this.keyInfo));

          this.userInfo.moduleFunctions = await this.AES256_GCM_decrypt(
            responseVal.ModuleFunctions
          );

          this.userInfo.busKey = await this.AES256_GCM_decrypt(
            responseVal.BusKey
          );
          this.userInfo.mfa = responseVal.Mfa;
          this.userInfo.isElevated = responseVal.IsElevated;
          this.userInfo.roles = responseVal.Roles;
          this.userInfo.officeId = responseVal.OfficeId;
          this.userInfo.companyId = responseVal.CompanyId;
          this.userInfo.token = responseVal.Token;
          this.expireUTC = responseVal.ExpireUtc;
          this.userInfo.screenName = responseVal.ScreenName;
          this.userInfo.userId = responseVal.UserId;
          this.userInfo.firstName = responseVal.FirstName;
          this.userInfo.lastName = responseVal.LastName;
          this.userInfo.photoUrl = responseVal.PhotoUrl;
          this.userInfo.defaultPhotoUrl = responseVal.DefaultPhotoUrl;
          this.userInfo.companyName = responseVal.CompanyName;
          this.userInfo.email = responseVal.Email;
          this.userInfo.notificationTimerInterval =
            responseVal.NotificationInterval * 1000;
          this.userInfo.tierTimeOut = responseVal.TierTimeout;
          this.userInfo.leadTier = responseVal.TierNumber;
          this.userInfo.canSpeakSpanish = responseVal.CanSpeakSpanish;
          this.userPhoneSettings.endPointId = responseVal.EndpointId;
          this.userPhoneSettings.endPointAuthId = responseVal.EndPointAuthId;
          this.userPhoneSettings.endPointName = responseVal.EndPointName;
          this.userPhoneSettings.endPointPassword =
            responseVal.EndPointPassword;
          this.userPhoneSettings.isAutoAcceptCall =
            responseVal.IsAutoAcceptCall;
          this.userPhoneSettings.outboundCallMethod =
            responseVal.DidAssignTypeId;
          this.userPhoneSettings.outboundCallerId =
            responseVal.OutboundCallerId;
          this.userPhoneSettings.userDid = responseVal.UserDid;
          this.userPhoneSettings.iceServers = responseVal.IceServers;
          this.userInfo.customerIdPrefix = responseVal.CustomerIdPrefix;
          this.userInfo.hasAttorneyConfig = responseVal.HasAttorneyConfig;
          this.userInfo.tierSettings = responseVal.TierSettings;
          this.userInfo.subOffices = responseVal.SubOffices;
          this.userInfo.passwordExpirationDate =
            responseVal.PasswordExpirationDate;
          if (this.userInfo.officeId == 54) {
            this.userInfo.moduleFunctions += ",8004";
          }

          sessionStorage.setItem("userInfo", JSON.stringify(this.userInfo));
          sessionStorage.setItem(
            "userPhoneSettings",
            JSON.stringify(this.userPhoneSettings)
          );
          sessionStorage.setItem("expireUTC", this.expireUTC),
            this.$emit("authenticated", true);
          this.loading = false;
          if (
            this.userInfo != null &&
            this.userInfo.mfa != null &&
            this.userInfo.mfa.MfaRequired == true
          ) {
            this.authenticate = true;
          } else {
            await this.resetTokenStorage(
              this.userInfo.email,
              this.userInfo.mfa.MfaToken
            );
            this.$router.replace({ name: "home" });
          }
        } catch (err) {
          this.errStatus = "401";
          this.loading = false;
        }
      } else {
        this.errStatus = true;
      }
      this.$store.dispatch(globalTypes.RESET_IDLE);

      if (this.userPhoneSettings.endPointId == null) {
        await this.$store.dispatch(globalTypes.GET_ONLINE_STATUS, {
          IsOnline: true,
          PauseReasonId: 1,
        });
      }
    },
  },
};
</script>
<style>
.errorText {
  margin: 5px;
  color: #f1556c;
  font-weight: 600;
}

.forgotPasswordText {
  font-size: 12px;
  color: #61b1f4;
  cursor: pointer;
}
.forgotPasswordText:hover {
  color: #a3cff3;
}
</style>
