<template>
  <div class="col-md-12">
    <div class="form-group row">
      <div class="col-md-12">
        <form class="newPanelWrapper">
          <div class="row mt-2">
            <div class="col-xl-2 col-lg-3 col-md-6 col-sm-12">
              <label class="generalLabel">Date</label>
              <DatePicker mode="date" :locale="'en-US'" v-model="date1">
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                    class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                    :value="inputValue"
                    v-on="inputEvents"
                    placeholder="Select Date"
                  />
                </template>
              </DatePicker>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-6 col-sm-12">
              <label class="generalLabel">Start Time</label>
              <DatePicker mode="time" :locale="'en-US'" v-model="startTime">
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                    placeholder="Start Time"
                    autocomplete="off"
                    class="form-control px-2 py-1 border rounded"
                    :value="inputValue"
                    v-on="inputEvents"
                  />
                </template>
              </DatePicker>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-6 col-sm-12">
              <label class="generalLabel">End Time</label>
              <DatePicker mode="time" :locale="'en-US'" v-model="endTime">
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                    placeholder="End Time"
                    autocomplete="off"
                    class="form-control px-2 py-1 border rounded"
                    :value="inputValue"
                    v-on="inputEvents"
                  />
                </template>
              </DatePicker>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-6 col-sm-12">
              <label class="generalLabel">Agent</label>
              <select
                v-model="agents"
                class="form-control px-2 py-1 border rounded"
                placeholder="Select Agent"
              >
                <option :value="null">Select Agent</option>
                <option
                  v-for="item in filteredAgents"
                  :key="item.Id"
                  :value="item.Id"
                >
                  {{ item.Name }}
                </option>
              </select>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-6 col-sm-12">
              <button
                type="button"
                class="generalButton btn-sm btn-primary"
                :disabled="agents == null || date1 == null"
                style="
                  width: 75%;
                  background-color: #3bafda;
                  color: #ffffff;
                  margin-top: 27px;
                "
                @click="filterReport()"
              >
                <i
                  v-if="loadingSpinner"
                  class="spinner-border spinner-border-sm mr-1"
                ></i>
                Filter
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div style="display: flex" class="col-md-12">
      <div
        v-if="
          agentStatustList &&
          agentStatustList.OnlineTime &&
          agentStatustList.OnlineTime != null
        "
        class="col-md-3"
        style="display: flex; flex-direction: column; align-items: center"
      >
        <label class="generalLabel">Total Online Time</label>
        <label>{{ formattedTime(agentStatustList.OnlineTime) }}</label>
      </div>
      <div
        v-if="
          agentStatustList &&
          agentStatustList.AwayTime &&
          agentStatustList.AwayTime != null
        "
        style="display: flex; flex-direction: column; align-items: center"
        class="col-md-3"
      >
        <label class="generalLabel">Total Away Time</label>
        <label>{{ formattedTime(agentStatustList.AwayTime) }}</label>
      </div>
      <div
        v-if="
          agentStatustList &&
          agentStatustList.OnCallTime &&
          agentStatustList.OnCallTime != null
        "
        style="display: flex; flex-direction: column; align-items: center"
        class="col-md-3"
      >
        <label class="generalLabel">Total Call Time</label>
        <label>{{ formattedTime(agentStatustList.OnCallTime) }}</label>
      </div>
      <div
        v-if="
          agentStatustList &&
          agentStatustList.OfflineTime &&
          agentStatustList.OfflineTime != null
        "
        style="display: flex; flex-direction: column; align-items: center"
        class="col-md-3"
      >
        <label class="generalLabel">Total Offline Time</label>
        <label>{{ formattedTime(agentStatustList.OfflineTime) }}</label>
      </div>
    </div>

    <div
      v-if="agentStatustList"
      class="datagrid-table gcsTabsTableScroll report-wrapper"
      :style="'max-height: ' + reportsPanelHeight + 'px; overflow-y:auto;'"
    >
      <perfect-scrollbar>
        <table style="margin-bottom: 14px" class="col-md-12">
          <thead>
            <tr>
              <th style="position: sticky; left: 0">Hour</th>
              <th
                style="text-align: center"
                v-for="(item, index) in minutesList"
                :key="index"
              >
                {{ item }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(hour, index) in hoursList" :key="index">
              <td
                style="
                  position: sticky;
                  left: 0;
                  background-color: white;
                  white-space: nowrap;
                "
              >
                {{ getTime12Hours(hour, 0) }}
              </td>
              <td
                style="padding: 0; border: 1px solid white"
                v-for="(item, index) in minutesList"
                :key="index"
                :style="getStatusColor(item, hour)"
              >
                <table
                  style="padding: 0"
                  v-if="
                    agentStatustList.Status &&
                    agentStatustList.Status.length > 0
                  "
                >
                  <tr>
                    <td
                      v-for="i in getColumnSeconds(item, hour)"
                      :key="i.Seconds"
                      :style="setColor(i.Status)"
                    >
                      {{ i.Seconds }}s
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </perfect-scrollbar>
    </div>

    <div
      v-if="agentStatustList && agentStatustList.Status.length != 0"
      class="col-md-12 noPadding"
    >
      <label>Measurements are taken in 30 sec increments.</label>
    </div>

    <div
      v-if="agentStatustList && agentStatustList.Status.length != 0"
      style="display: flex; justify-content: space-evenly"
      class="mt-3"
    >
      <div
        style="
          display: flex;
          justify-content: center;
          height: 26px;
          width: 120px;
          background-color: #ea2027;
          color: white;
          font-variant: all-petite-caps;
        "
      >
        <label>Offline</label>
      </div>
      <div
        style="
          display: flex;
          justify-content: center;
          height: 26px;
          width: 120px;
          background-color: #f9bb42;
          color: white;
          font-variant: all-petite-caps;
        "
      >
        <label>Away</label>
      </div>
      <div
        style="
          display: flex;
          justify-content: center;
          height: 26px;
          width: 120px;
          background-color: #1cbf64;
          color: white;
          font-variant: all-petite-caps;
        "
      >
        <label>Online</label>
      </div>
      <div
        style="
          display: flex;
          justify-content: center;
          height: 26px;
          width: 120px;
          background-color: #108042;
          color: white;
          font-variant: all-petite-caps;
        "
      >
        <label>Incoming Call</label>
      </div>
      <div
        style="
          display: flex;
          justify-content: center;
          height: 26px;
          width: 120px;
          background-color: #68c1e3;
          color: white;
          font-variant: all-petite-caps;
        "
      >
        <label>On a call</label>
      </div>
    </div>
  </div>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import deepCopy from "@/helpers/deepCopy";
import { DatePicker } from "v-calendar";
import globalTypes from "@/store/types";
import _orderby from "lodash";

export default {
  name: "AgentPhoneStatusReport",
  components: { DatePicker },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      loadingSpinner: false,
      reportsPanelHeight: 300,
      date1: null,
      startTime: new Date(2023, 1, 1, 9, 0, 0),
      endTime: new Date(2023, 1, 1, 17, 0, 0),
      agents: null,
      hasAccessOtherOffices: false,
      userOfficeId: null,
      minutesList: [],
      hoursList: [],
      userSubOffices: [],
    };
  },
  computed: mapState({
    agentStatustList: (state) => state.reports.agentStatustList,
    allEmployees: (state) => state.globals.allEmployees,
    filteredAgents() {
      let rtn = [];
      if (this.checkAuth(1454)) {
        rtn = this.allEmployees.filter((x) => x.IsPhoneEnabled == true);
      } else if (this.checkAuth(55011)) {
        rtn = this.userSubOffices
          ? this.allEmployees.filter(
              (x) =>
                this.userSubOffices.includes(x.Id) ||
                x.Id == this.userOfficeId ||
                x.OfficeId == null
            )
          : this.allEmployees.filter(
              (x) => x.OfficeId == this.userOfficeId || x.OfficeId == null
            );
      } else {
        rtn = this.allEmployees.filter(
          (x) => x.OfficeId == this.userOfficeId || x.OfficeId == null
        );
      }

      return _orderby.orderBy(rtn, "Name", "asc");
    },
  }),

  async mounted() {
    await this.$store.dispatch(globalTypes.GET_ALL_ACTIVE_EMPLOYEES);

    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo != null && userInfo.moduleFunctions != null) {
      this.userOfficeId = userInfo.officeId;
      this.userSubOffices = userInfo.subOffices;
    }
    this.hasAccessOtherOffices = this.canSeeAllOffices();

    this.reportsPanelHeight = this.setReportGridHeight(
      window.innerHeight,
      document.getElementById("reportsSummary")
    );

    for (let i = 1; i < 60; i++) {
      this.minutesList.push(i);
    }

    let retrievedData = sessionStorage.getItem(
      "reportFilters-" + "AgentPhoneStatusReport"
    );
    if (typeof retrievedData != undefined && retrievedData) {
      let data = JSON.parse(retrievedData);
      if (data) {
        this.agents = data.UserId;
        this.startTime = data.StartTime;
        this.endTime = data.EndTime;
        this.date1 = new Date(data.EndTime);

        let date = new Date(this.date1);
        let start = new Date(this.startTime);
        let end = new Date(this.endTime);

        let hour1 = start.getHours();
        let hour2 = end.getHours();

        this.hoursList = [];
        for (let i = hour1; i < hour2; i++) {
          this.hoursList.push(i);
        }
      }
    }
  },
  methods: {
    getColumnSeconds(minute, hour) {
      let rtn = [];

      if (
        this.agentStatustList.Status == null ||
        this.agentStatustList.Status.length == 0
      ) {
        return rtn;
      }

      let indx = this.agentStatustList.Status.findIndex(
        (x) => this.convertToLocalHour(x.Hour) == hour
      );
      if (indx > -1) {
        if (
          this.agentStatustList.Status[indx].Status != null &&
          this.agentStatustList.Status[indx].Status.length > 0
        ) {
          let matchList = this.agentStatustList.Status[indx].Status.filter(
            (x) => new Date(x.EventDate).getMinutes() == minute
          );
          for (let item of matchList) {
            rtn.push({
              Seconds: new Date(item.EventDate).getSeconds(),
              Status: item.Status,
            });
          }
        }
      }

      return rtn;
    },
    getStatusColor(minute, hour) {
      let rtn = 0;

      if (
        this.agentStatustList.Status == null ||
        this.agentStatustList.Status.length == 0
      ) {
        return this.setColor(rtn);
      }

      let indx = this.agentStatustList.Status.findIndex(
        (x) => this.convertToLocalHour(x.Hour) == hour
      );
      if (indx > -1) {
        if (
          this.agentStatustList.Status[indx].Status != null &&
          this.agentStatustList.Status[indx].Status.length > 0
        ) {
          let matchList = this.agentStatustList.Status[indx].Status.filter(
            (x) => new Date(x.EventDate).getMinutes() == minute
          );
          if (matchList.length > 0) {
            rtn = matchList[0].Status;
          }
        }
      }

      return this.setColor(rtn);
    },
    formattedTime(value) {
      let [hours, minutes, seconds] = value.split(":");
      let formatted = "";

      if (hours != "00") {
        formatted += parseInt(hours) + " hr ";
      }
      if (minutes !== "00") {
        formatted += parseInt(minutes) + " mins ";
      }

      formatted += parseInt(seconds) + " secs";
      return formatted.trim();
    },
    dateToTime(date) {
      let hours = date.getHours();
      let minutes = date.getMinutes();
      let seconds = date.getSeconds();

      if (hours < 10) {
        hours = "0" + hours;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      if (hours > 0) return hours + ":" + minutes + ":" + seconds;
      else return minutes + ":" + seconds;
    },
    async filterReport() {
      let date = new Date(this.date1);
      let start = new Date(this.startTime);
      let end = new Date(this.endTime);

      let hour1 = start.getHours();
      let hour2 = end.getHours();

      if (hour1 >= hour2) {
        this.$swal(
          "Warning!",
          "End time must be greater than start time",
          "warning"
        );
        return;
      }
      this.hoursList = [];
      for (let i = hour1; i < hour2; i++) {
        this.hoursList.push(i);
      }

      this.loadingSpinner = true;
      let payload = {
        UserId: this.agents,
        StartTime: new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          start.getHours(),
          start.getMinutes()
        ),
        EndTime: new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          end.getHours(),
          end.getMinutes()
        ),
      };
      sessionStorage.setItem(
        "reportFilters-" + "AgentPhoneStatusReport",
        JSON.stringify({
          UserId: this.agents,
          StartTime: new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            start.getHours(),
            start.getMinutes()
          ),
          EndTime: new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            end.getHours(),
            end.getMinutes()
          ),
        })
      );

      await this.$store.dispatch(types.GET_AGENT_STATUS_REPORT, payload);

      this.loadingSpinner = false;
    },
    convertToLocalHour(time) {
      let timeString = "2023-3-23 " + time + ":00:00Z";
      return new Date(timeString).getHours();
    },
    setColor(color) {
      if (color == 0) {
        return { backgroundColor: "#ea2027" };
      } else if (color == 1) {
        return { backgroundColor: "#1cbf64" };
      } else if (color == 2) {
        return { backgroundColor: "#f9bb42" };
      } else if (color == 3) {
        return { backgroundColor: "#68c1e3" };
      } else if (color == 4) {
        return { backgroundColor: "#108042" };
      }
    },
  },
};
</script>

<style scoped></style>
