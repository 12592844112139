<template>
  <div
    class="col-md-12 newPanelWrapper noMargin smp-mediaScreen"
    style="
      background-color: #ffffff;
      z-index: 1000;
      padding-left: 0;
      padding-right: 0;
    "
  >
    <div v-if="isLoading" class="loading">
      <div class="text-center" style="margin-top: 300px">
        <i class="spinner-grow text-info m-2"></i>
      </div>
    </div>
    <div class="monevoPopupHeader">
      <label style="font-size: 15px">Monevo</label>
    </div>
    <div>
      <div class="closeButton" v-on:click.prevent.stop="closePanel">
        <i class="fas fa-window-close"></i>
      </div>
    </div>
    <div class="col-md-12 row" v-if="monevoResult.ResultId > 0">
      <div
        class="col-md-12"
        style="padding: 5px 15px 3px 15px; background-color: #37cde6"
      >
        <label style="color: white !important" class="generalLabel"
          >Result: {{ monevoResult.Message }}</label
        >
        <label style="color: white !important" class="generalLabel ml-4"
          >Accepted Offer Count: {{ monevoResult.OfferAccepted }}</label
        >
        <label style="color: white !important" class="generalLabel ml-4"
          >Rejected Offer Count: {{ monevoResult.OfferRejected }}</label
        >
        <label style="color: white !important" class="generalLabel ml-4"
          >Awaiting Offer Count: {{ monevoResult.OfferAwaitingResponse }}</label
        >
      </div>
    </div>
    <div
      class="col-md-12 row"
      v-if="
        monevoResult.ResultId > 0 &&
        monevoResult.Offers &&
        monevoResult.Offers.length == 0
      "
    >
      <section class="section" style="width: 100%; text-align: center">
        <div class="content has-text-grey has-text-centered mb-4">
          <p class="noMargin">
            <i
              style="font-size: 24px; color: #939393"
              class="ri-question-line"
            ></i>
          </p>
          <p class="noMargin">No offer available.</p>
        </div>
      </section>
    </div>
    <perfect-scrollbar
      v-if="monevoResult.ResultId == 0"
      style="
        width: 100%;
        height: auto;
        max-height: 600px;
        overflow-x: hidden !important;
        margin-top: -10px;
      "
    >
      <div>
        <div class="col-md-12 row">
          <div class="col-md-4">
            <label for="sm-firstName" class="generalLabel">First Name</label>

            <label class="form-control" style="background: #f1efef">{{
              data.FirstName
            }}</label>
          </div>
          <div class="col-md-4">
            <label for="sm-lastName" class="generalLabel">Last Name</label>
            <label class="form-control" style="background: #f1efef">{{
              data.LastName
            }}</label>
          </div>
          <div class="col-md-4">
            <label for="sm-dateofBirth" class="generalLabel"
              >Date of Birth</label
            >
            <label class="form-control" style="background: #f1efef">{{
              data.DateOfBirth != null
                ? new Date(data.DateOfBirth).toString("yyyy-MM-dd")
                : ""
            }}</label>
          </div>

          <div class="col-md-8">
            <label for="sm-email" class="generalLabel">Email</label>
            <label
              class="form-control"
              style="
                background: #f1efef;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              "
              >{{ data.Email }}</label
            >
          </div>
          <div class="col-md-4">
            <label for="sm-phone" class="generalLabel">Phone</label>
            <label class="form-control" style="background: #f1efef">{{
              tryFormatPhone(data.Phone)
            }}</label>
          </div>
          <div class="col-md-4">
            <label for="sm-loanAmount" class="generalLabel">SSN</label>
            <label class="form-control" style="background: #f1efef">{{
              data.Ssn
            }}</label>
          </div>

          <div class="col-md-4">
            <label for="sm-loanAmount" class="generalLabel">Loan Amount</label>
            <input
              v-model="monevoObj.loan_amount"
              autocomplete="none"
              type="text"
              class="form-control"
              placeholder="Loan Amount"
              v-myMask="{
                alias: 'numeric',
                allowMinus: false,
                rightAlign: false,
              }"
              :class="v$.monevoObj.loan_amount.$error ? 'has-error' : ''"
              @blur="v$.monevoObj.loan_amount.$touch"
            />
          </div>

          <div class="col-md-4">
            <label for="sm-annualIncome" class="generalLabel"
              >Monthly Income</label
            >
            <label class="form-control" style="background: #f1efef">{{
              formatMoney(data.MonthlyIncome)
            }}</label>
          </div>

          <div class="col-md-4">
            <label for="sm-employmentStatus" class="generalLabel"
              >Residence Type</label
            >

            <select
              class="form-control"
              id="sm-employmentStatus"
              v-model="monevoObj.residence_type"
              :class="v$.monevoObj.residence_type.$error ? 'has-error' : ''"
              @blur="v$.monevoObj.residence_type.$touch"
            >
              <option :value="null">Select</option>
              <option value="1">Home Owner</option>
              <option value="2">Rent</option>
              <option value="3">Live with Family</option>
              <option value="4">Other</option>
            </select>
          </div>

          <div class="col-md-4">
            <label class="generalLabel"
              >Monthly Rent
              <i
                class="fe-info"
                style="cursor: pointer"
                title="The customer’s monthly rent / housing payment / mortgage."
              ></i
            ></label>
            <label
              v-if="data.MonthlyRent"
              class="form-control"
              style="background: #f1efef"
              >{{ formatMoney(data.MonthlyRent) }}</label
            >
            <input
              v-if="data.MonthlyRent == null || data.MonthlyRent == 0"
              v-model="monevoObj.monthly_rent"
              autocomplete="off"
              type="text"
              class="form-control"
              placeholder="Rent Monthly"
              v-myMask="{
                alias: 'numeric',
                allowMinus: false,
                rightAlign: false,
              }"
              :class="v$.monevoObj.monthly_rent.$error ? 'has-error' : ''"
              @blur="v$.monevoObj.monthly_rent.$touch"
            />
          </div>
          <div class="col-md-4">
            <label for="monthsAtAddress" class="generalLabel"
              >Months At Address
              <i
                class="fe-info"
                style="cursor: pointer"
                title="The number of months since the customer has moved in to his/her address."
              ></i
            ></label>
            <label
              v-if="data.MonthsAtAddress && data.MonthsAtAddress > 0"
              class="form-control"
              style="background: #f1efef"
              >{{ data.MonthsAtAddress }}</label
            >
            <input
              v-if="data.MonthsAtAddress == null || data.MonthsAtAddress == 0"
              autocomplete="off"
              type="text"
              v-myMask="{
                alias: 'numeric',
                allowMinus: false,
                rightAlign: false,
              }"
              id="monthsAtAddress"
              class="form-control"
              v-model="monevoObj.months_at_address"
              placeholder="Months At Address"
              :class="v$.monevoObj.months_at_address.$error ? 'has-error' : ''"
              @blur="v$.monevoObj.months_at_address.$touch"
            />
          </div>
          <div class="col-md-4">
            <label for="sm-employmentStatus" class="generalLabel"
              >Income Source</label
            >
            <label
              v-if="data.IncomeSource"
              class="form-control"
              style="background: #f1efef"
              >{{ data.IncomeSource }}</label
            >
            <select
              v-if="data.IncomeSource == null"
              class="form-control"
              id="sm-employmentStatus"
              v-model="monevoObj.income_source"
              :class="v$.monevoObj.income_source.$error ? 'has-error' : ''"
              @blur="v$.monevoObj.income_source.$touch"
            >
              <option :value="null">Select</option>
              <option value="1">Employed</option>
              <option value="2">Social Security</option>
              <option value="3">Pension</option>
              <option value="4">Disability</option>
              <option value="5">Self Employed</option>
              <option value="6">Student</option>
              <option value="7">Unemployed</option>
            </select>
          </div>

          <div class="col-md-4">
            <label for="employerName" class="generalLabel"
              >Employer Name
              <i
                class="fe-info"
                style="cursor: pointer"
                title="If self employed pass 'self' or the customers trading name. If retired or unemployed pass 'none'"
              ></i>
            </label>
            <label
              v-if="data.EmployerName"
              class="form-control"
              style="background: #f1efef"
              >{{ data.EmployerName }}</label
            >
            <input
              autocomplete="off"
              type="text"
              class="form-control"
              v-if="data.EmployerName == null"
              v-model="monevoObj.employer_name"
              placeholder="Employer Name"
              :class="v$.monevoObj.employer_name.$error ? 'has-error' : ''"
              @blur="v$.monevoObj.employer_name.$touch"
            />
          </div>

          <div class="col-md-4">
            <label for="months_at_employer" class="generalLabel"
              >Months At Employer
              <i
                class="fe-info"
                style="cursor: pointer"
                title="The number of months the customer has been with his/her current employer. If unemployed pass 0. If self employed pass the number of months the customer has been self employed. If on benefits pass the number of months the customer has been on benefits or pass 0"
              ></i
            ></label>
            <label
              v-if="data.MonthsAtEmployer && data.MonthsAtEmployer > 0"
              class="form-control"
              style="background: #f1efef"
              >{{ data.MonthsAtEmployer }}</label
            >
            <input
              v-if="data.MonthsAtEmployer == null || data.MonthsAtEmployer == 0"
              autocomplete="off"
              type="text"
              v-myMask="{
                alias: 'numeric',
                allowMinus: false,
                rightAlign: false,
              }"
              id="months_at_employer"
              class="form-control"
              v-model="monevoObj.months_at_employer"
              placeholder="Months At Employer"
              :class="v$.monevoObj.months_at_employer.$error ? 'has-error' : ''"
              @blur="v$.monevoObj.months_at_employer.$touch"
            />
          </div>

          <div class="col-md-4">
            <label for="sm-payFrequency" class="generalLabel"
              >Pay Frequency</label
            >
            <label
              v-if="data.PayFrequency"
              class="form-control"
              style="background: #f1efef"
              >{{ data.PayFrequency }}</label
            >
            <select
              v-if="data.PayFrequency == null"
              class="form-control"
              id="sm-payFrequency"
              v-model="monevoObj.pay_frequency"
              :class="v$.monevoObj.pay_frequency.$error ? 'has-error' : ''"
              @blur="v$.monevoObj.pay_frequency.$touch"
            >
              <option :value="null">Select</option>
              <option value="1">Weekly</option>
              <option value="2">Biweekly</option>
              <option value="3">Twice a Month</option>
              <option value="4">Monthly</option>
              <option value="5">4 Weekly</option>
              <option value="6">Other</option>
            </select>
          </div>

          <div class="col-md-4">
            <label for="sm-payFrequency" class="generalLabel"
              >Loan Purpose</label
            >
            <label class="form-control" style="background: #f1efef">
              Debt Consolidation
            </label>
          </div>

          <div class="col-md-4">
            <label for="sm-address1" class="generalLabel">Address1</label>
            <label class="form-control" style="background: #f1efef">{{
              data.Line1
            }}</label>
          </div>

          <div class="col-md-4">
            <label for="sm-city" class="generalLabel">City</label>
            <label class="form-control" style="background: #f1efef">{{
              data.City
            }}</label>
          </div>

          <div class="col-md-4">
            <label for="sm-state" class="generalLabel">State</label>
            <label class="form-control" style="background: #f1efef">{{
              data.StateShort
            }}</label>
          </div>

          <div class="col-md-4">
            <label for="sm-zip" class="generalLabel">Zip Code</label>
            <label class="form-control" style="background: #f1efef">{{
              data.Zip
            }}</label>
          </div>

          <!-- <div class="col-md-12 row noPadding">
             <input
              type="checkbox"
              class="checkbox-input generalCheckBox pt-2"
              style="
                width: 54px;
                border-radius: 4px !important;
                border: solid 1px #d1d1d1 !important;
              "
              id="termsConsent"
              v-model="monevoObj.terms_consent"
            />
            <label for="termsConsent" class="generalLabel"
              >I agree all consents (FCRA, TCPA, Terms, Privacy &
              E-Consent).</label
            >
           
          </div> -->

          <!-- <div class="col-md-12 row noPadding">
             <input
              id="marketing_opt_in"
              type="checkbox"
              class="checkbox-input generalCheckBox"
              style="
                width: 54px;
                border-radius: 4px !important;
                border: solid 1px #d1d1d1 !important;
              "
              v-model="monevoObj.marketing_opt_in"
            />
            <label for="marketing_opt_in" class="generalLabel"
              >Marketing Opted-in</label
            >
           
          </div> -->
        </div>
      </div>
    </perfect-scrollbar>

    <perfect-scrollbar
      v-if="monevoResult.ResultId > 0"
      style="
        width: 100%;
        max-height: 480px;
        overflow-x: hidden !important;
        margin-top: -10px;
      "
    >
      <div
        class="col-md-12 row"
        v-for="(item, i) in monevoResult.Offers"
        :key="item.Id"
      >
        <div class="col-md-12" v-if="item.Id > 0">
          <div class="col-md-12 noPadding">
            <label
              for=""
              class="generalLabel"
              style="width: 100%; padding: 5px; background-color: #ebebeb"
            >
              {{ i + 1 }} .Offer
            </label>
          </div>
          <div class="col-md-12 noPadding">
            <label for="" class="generalLabel">Redirect Url: </label>
            <label for="" class="textLabel ml-1">{{
              substringText(item.RedirectUrl, 80)
            }}</label>
            <button
              type="button"
              class="btn btn-success btn-xs ml-2"
              @click="copyRedirectUrl(item.RedirectUrl)"
            >
              Copy Url
            </button>
          </div>
          <div class="col-md-12 noPadding" v-if="item.Loan">
            <label :title="item.Loan.Tooltip" class="generalLabel"
              >Loan Description:
            </label>
            <label class="textLabel ml-1">{{
              item.Loan.LoanDescription
            }}</label>
            <table class="datagrid-table" style="width: 100%">
              <thead>
                <tr>
                  <th v-if="item.Loan.LoanAmount">Loan Amount</th>
                  <th v-if="item.Loan.Apr">Apr</th>
                  <th v-if="item.Loan.Term">Term</th>
                  <th v-if="item.Loan.Fees">Fees</th>
                  <th v-if="item.Loan.MonthlyRepayment">Monthly Repayment</th>
                  <th v-if="item.Loan.TotalRepayment">Total Repayment</th>
                  <th v-if="item.Loan.CostOfCredit">Cost of Credit</th>
                  <th v-if="item.Loan.Type">Type</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td v-if="item.Loan.LoanAmount">
                    {{ item.Loan.LoanAmount }}
                  </td>
                  <td v-if="item.Loan.Apr">{{ item.Loan.Apr }}</td>
                  <td v-if="item.Loan.Term">{{ item.Loan.Term }}</td>
                  <td v-if="item.Loan.Fees">{{ item.Loan.Fees }}</td>
                  <td v-if="item.Loan.MonthlyRepayment">
                    {{ item.Loan.MonthlyRepayment }}
                  </td>
                  <td v-if="item.Loan.TotalRepayment">
                    {{ item.Loan.TotalRepayment }}
                  </td>
                  <td v-if="item.Loan.CostOfCredit">
                    {{ item.Loan.CostOfCredit }}
                  </td>
                  <td v-if="item.Loan.Type">{{ item.Loan.Type }}</td>
                </tr>
              </tbody>
            </table>
            <table class="datagrid-table mt-2" style="width: 100%">
              <thead>
                <tr>
                  <th v-if="item.Loan.Prequalified">Prequalified</th>
                  <th v-if="item.Loan.Preapproved">Preapproved</th>
                  <th v-if="item.Loan.Secured">Secured</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td v-if="item.Loan.Prequalified">
                    {{
                      (item.Loan.Prequalified == true ||
                        item.Loan.Prequalified) == "true"
                        ? "Yes"
                        : "No"
                    }}
                  </td>
                  <td v-if="item.Loan.Preapproved">
                    {{
                      (item.Loan.Preapproved == true ||
                        item.Loan.Preapproved) == "true"
                        ? "Yes"
                        : "No"
                    }}
                  </td>
                  <td v-if="item.Loan.Secured">
                    {{
                      (item.Loan.Secured == true || item.Loan.Secured) == "true"
                        ? "Yes"
                        : "No"
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            class="col-md-12 noPadding"
            v-if="item.Loan.Features && item.Loan.Features.length > 0"
          >
            <label class="generalLabel">Features:</label>
            <table class="datagrid-table" style="width: 100%">
              <tbody>
                <tr>
                  <td v-for="f in item.Loan.Features" :key="f">{{ f }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            class="col-md-12 noPadding"
            v-if="item.Loan.Terms && item.Loan.Terms.length > 0"
          >
            <label class="generalLabel">Terms:</label>
            <table class="datagrid-table" style="width: 100%">
              <tbody>
                <tr>
                  <td v-for="t in item.Loan.Terms" :key="t">{{ t }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-md-12 noPadding mt-2" v-if="item.Lender">
            <label class="generalLabel">Lender: </label>
            <table class="datagrid-table" style="width: 100%">
              <tbody>
                <tr>
                  <td v-if="item.Lender.LogoUrl && item.Lender.LogoUrl != ''">
                    <img :src="item.Lender.LogoUrl" width="120" />
                  </td>
                  <td v-if="item.Lender.Name && item.Lender.Name != ''">
                    Lender Name: {{ item.Lender.Name }}
                  </td>
                  <td v-if="item.Lender.Name && item.Lender.Phone != ''">
                    Phone: {{ this.tryFormatPhone(item.Lender.Phone) }}
                  </td>
                  <td v-if="item.Lender.Name && item.Lender.Url != ''">
                    Url: {{ item.Lender.Url }}
                  </td>
                </tr>
                <tr></tr>
                <tr
                  v-if="
                    item.Lender.Description && item.Lender.Description != ''
                  "
                >
                  <td colspan="4">{{ item.Lender.Description }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </perfect-scrollbar>
    <div v-if="monevoResult.Disclaimer != ''" class="col-md-12 row">
      <div class="col-md-12 pt-2" style="background-color: #efefef">
        <label
          v-html="monevoResult.Disclaimer"
          class="generalLabel monevoDisclaimerLabel"
        >
        </label>
      </div>
    </div>
    <div class="col-md-12 mt-2" v-if="monevoResult.ResultId == 0">
      <button
        :disabled="v$.$invalid"
        type="button"
        class="btn btn-success noMargin col-md-12"
        @click="sendRequest()"
      >
        Submit
      </button>
    </div>
  </div>
</template>

<script>
import formatMixin from "@/mixins/formatMixin";
import useValidate from "@vuelidate/core";
import { required } from "vuelidate/lib/validators";
import globalTypes from "@/store/types";
export default {
  name: "MonevoPopup",
  components: {},
  mixins: [formatMixin],

  props: {
    data: {
      Type: Object,
      Defult: null,
    },
  },
  data() {
    return {
      isLoading: false,
      v$: useValidate(),
      incomeSourceList: [
        { Id: 1, Name: "Employed" },
        { Id: 2, Name: "Social Security" },
        { Id: 3, Name: "Pension" },
        { Id: 4, Name: "Disability" },
        { Id: 5, Name: "Self Employed" },
        { Id: 6, Name: "Student" },
        { Id: 7, Name: "Unemployed" },
      ],
      payFrequencyList: [
        { Id: 1, Name: "Weekly" },
        { Id: 2, Name: "Every two weeks" },
        { Id: 3, Name: "Twice a month" },
        { Id: 4, Name: "Monthly" },
        { Id: 5, Name: "4 Weekly" },
        { Id: 6, Name: "Other" },
      ],
      monevoObj: {
        first_name: "",
        last_name: "",
        loan_amount: 0, //integer
        email: "",
        mobile_phone: "",
        dob: "", // YYYY-MM-DD format
        address1: "",
        city: "",
        state: null, // 2 digit state abbreviation
        zipcode: null,
        residence_type: null, // 1:Home Owner,2:Rent, 3:Live with Family, 4:Other
        months_at_address: null, // The number of months since the customer has moved in to his/her address.
        monthly_rent: null, //	The customer’s monthly rent / housing payment / mortgage. Numeric value greater than zero, no decimals.
        social_security_number: "", //	The customer’s nine digit social security number. Numbers only, no space or dash.
        income_source: null, //1 Employed, 2 Social Security, 3 Pension, 4 Disability, 5 Self Employed, 6 Student, 7 Unemployed
        pay_frequency: null, //1 Weekly, 2 Every two weeks, 3 Twice a month, 4 Monthly, 5: 4 Weekly, 6: Other
        monthly_income: null, //The customer’s monthly income. Numeric value greater than zero, no decimal.
        employer_name: "",
        months_at_employer: null, //The number of months the customer has been with his/her current employer. If unemployed pass 0. If self employed pass the number of months the customer has been self employed. If on benefits pass the number of months the customer has been on benefits or pass 0.
        loan_purpose: 1,
        /*1 Debt Consolidation
        2 Home improvement / Pool / Solar
        3 Vacation / Travel
        4 New auto purchase
        5 Large Purchase
        6 Pay off credit cards
        7 Student Loan Refinancing
        8 Education
        9 Special Occasion
        10 Cosmetic Procedures
        11 Moving and Relocation
        12 Household Expenses
        13 Medical/Dental
        14 Taxes
        15 Business
        16 Other
        17 Funeral expenses
        18 Auto loan refinance
        19 Used auto purchase
        20 Auto lease buyout
        21 Baby / Adoption expenses
        22 Emergency expenses
        23 Wedding / Engagement
        24 Auto repairs
        25 Equipment Purchase
        */
        terms_consent: 0, //Indicates whether the customer has agreed to all consents (FCRA, TCPA, Terms, Privacy & E-Consent). Valid values: “1” (Yes, the customer has accepted); “0” (No, the customer has not accepted).
        marketing_opt_in: 0, //Indicates whether the customer has opted in for marketing. Valid values are:1 Yes, the customer has opted in to marketing,  0 No, the customer has not opted in to marketing
        mode: "LIVE",
        campaign_code: "cF9ebQMGCwdFTX4", //Company's monevo campaign code
        affiliate_app_id: "", //(Not Required but recommended)The lead or application ID from the supply partner. Up to 100 alphanumeric characters.
        site_url: "debtblue.globalholdings.app", //	The URL of the site on which the user applied for the loan.,
        site_ip: "20.49.104.48", //The IP address of the your site/server submitting the loan application to Monevo USA in XXX.XXX.XXX.XXX format.
        client_ip: "", //The IP address of the browser of the customer requesting the loan in XXX.XXX.XXX.XXX format.
        ref: "",
        co_app: 0,
        v1: "", // CustomerId
        v2: "", //agent Name
      },
      monevoResult: {
        ResultId: 0,
        OfferAccepted: 0,
        OfferRejected: 0,
        OfferAwaitingResponse: 0,
        Message: "",
        Disclaimer: "",
        ErrorCode: "",
        ErrorDescription: "",
        Offers: [],
      },
    };
  },
  unmounted() {
    this.emptyTheObjectsOnUnmount();
  },
  async mounted() {
    let err, result;

    this.isLoading = true;
    [err, result] = await this.$store.dispatch(globalTypes.GET_AGENT_IP_ADDR);

    if (result && result.Data) {
      this.monevoObj.client_ip = result.Data.ip;
    }

    this.monevoObj.first_name = this.data.FirstName;
    this.monevoObj.last_name = this.data.LastName;
    this.monevoObj.middle_name = this.data.MiddleName;
    //this.monevoObj.loan_amount = this.data.LoanAmount;
    this.monevoObj.email = this.data.Email;
    this.monevoObj.mobile_phone = this.data.Phone;
    this.monevoObj.dob = new Date(this.data.DateOfBirth).toString("yyyy-MM-dd");
    this.monevoObj.address1 = this.data.Line1;
    this.monevoObj.city = this.data.City;
    this.monevoObj.state = this.data.StateShort;
    this.monevoObj.zipcode = this.data.Zip;
    this.monevoObj.social_security_number = "000123000"; // todo: this.data.Ssn;
    this.monevoObj.monthly_income = this.data.MonthlyIncome;
    this.monevoObj.v1 = this.data.CustomerId;
    this.monevoObj.affiliate_app_id = this.data.CustomerId;
    this.monevoObj.ref = this.data.CustomerId;
    this.monevoObj.v2 = this.data.AgentName;
    this.monevoObj.employer_name = this.data.EmployerName;
    this.monevoObj.months_at_address = this.data.MonthsAtAddress;
    this.monevoObj.months_at_employer = this.data.MonthsAtEmployer;
    this.monevoObj.monthly_rent = this.data.MonthlyRent;
    if (this.data.IncomeSource) {
      let indx = this.incomeSourceList.findIndex(
        (x) => this.cleanName(x.Name) == this.cleanName(this.data.IncomeSource)
      );
      if (indx > -1) {
        this.monevoObj.income_source = this.incomeSourceList[indx].Id;
      }
    }
    if (this.data.PayFrequency) {
      let indx = this.payFrequencyList.findIndex(
        (x) => this.cleanName(x.Name) == this.cleanName(this.data.PayFrequency)
      );
      if (indx > -1) {
        this.monevoObj.pay_frequency = this.payFrequencyList[indx].Id;
      }
    }

    if (this.data.ResidenceOwnership) {
      let rid = parseInt(this.data.ResidenceOwnership);

      if (rid > 0) {
        this.monevoObj.residence_type = rid;
      } else {
        this.monevoObj.residence_type = null;
      }
    }
    this.isLoading = false;
  },
  methods: {
    cleanName(item) {
      let rtn = "";
      if (item) {
        rtn = item.toLowerCase();
        rtn = rtn.replaceAll(" ", "");
      }

      return rtn;
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    copyRedirectUrl(url) {
      navigator.clipboard.writeText(url);
      this.$swal("", "Copied", "success");
    },
    substringText(text, limit) {
      text = text.length > limit ? text.substr(0, limit) + "..." : text;
      return text;
    },
    emptyTheObjectsOnUnmount() {
      this.monevoObj = null;
    },
    closePanel() {
      this.$emit("closeMonevoPopup");
    },
    getNodeValue(node) {
      let rtn = "";
      if (
        typeof node != "undefined" &&
        node != null &&
        node.textContent != null
      ) {
        rtn = node.textContent;
      }
      return rtn;
    },
    async sendSecondRequest(url) {
      this.isLoading = true;
      let response;
      let vueObj = this;
      var request = new XMLHttpRequest();
      request.onreadystatechange = async () => {
        try {
          if (request.readyState === 4) {
            response = request.responseText;
            let parser = new DOMParser();
            let xmlDoc = parser.parseFromString(response, "text/xml");

            if (typeof xmlDoc != "undefined" && xmlDoc != null) {
              let result = xmlDoc.querySelector("result");
              if (typeof result != "undefined" && result != null) {
                vueObj.monevoResult.ResultId = vueObj.getNodeValue(result);
              }

              let message = xmlDoc.querySelector("message");
              if (typeof message != "undefined" && message != null) {
                vueObj.monevoResult.Message = vueObj.getNodeValue(message);
              }

              let disclaimer = xmlDoc.querySelector("disclaimer");
              if (typeof disclaimer != "undefined" && disclaimer != null) {
                vueObj.monevoResult.Disclaimer =
                  vueObj.getNodeValue(disclaimer);
              }

              let offerResults = xmlDoc.querySelector("offer_results");
              if (typeof offerResults != "undefined" && offerResults != null) {
                vueObj.monevoResult.OfferAccepted = vueObj.getNodeValue(
                  offerResults.querySelector("accepted")
                );
                vueObj.monevoResult.OfferRejected = vueObj.getNodeValue(
                  offerResults.querySelector("rejected")
                );
                vueObj.monevoResult.OfferAwaitingResponse = vueObj.getNodeValue(
                  offerResults.querySelector("awaiting_response")
                );
              }

              let error = xmlDoc.querySelector("error");
              if (typeof error != "undefined" && error != null) {
                let errorCode = error.querySelector("code");
                if (typeof errorCode != "undefined" && errorCode != null) {
                  vueObj.monevoResult.ErrorCode =
                    vueObj.getNodeValue(errorCode);
                }
                let errorDesc = error.querySelector("desc");
                if (typeof errorDesc != "undefined" && errorDesc != null) {
                  vueObj.monevoResult.ErrorDescription =
                    vueObj.getNodeValue(errorDesc);
                }
              }

              if (vueObj.monevoResult.ErrorDescription.trim() == "") {
                let offers = xmlDoc.querySelector("offers");
                if (typeof offers != "undefined" && offers != null) {
                  let offerList = offers.querySelectorAll("offer");
                  if (typeof offerList != "undefined" && offerList != null) {
                    for (let item of offerList) {
                      let offerItem = {
                        Id: 0,
                        Number: 0,
                        RedirectUrl: "",
                        Loan: {
                          LoanAmount: "",
                          Apr: "",
                          Term: "",
                          Fees: "",
                          MonthlyRepayment: "",
                          TotalRepayment: "",
                          CostOfCredit: "",
                          Type: "",
                          Features: [],
                          Terms: [],
                          Prequalified: false,
                          Preapproved: false,
                          Secured: false,
                          LoanDescription: "",
                          Tooltip: "",
                        },
                        Lender: {
                          Name: "",
                          Description: "",
                          Url: "",
                          Phone: "",
                          LogoUrl: "",
                        },
                      };

                      let offerIdNode = item.querySelector("id");
                      offerItem.Id = vueObj.getNodeValue(offerIdNode);

                      let offerNumberNode = item.querySelector("offer_number");
                      offerItem.Number = vueObj.getNodeValue(offerNumberNode);

                      let redirectUrlNode = item.querySelector("redirect_url");
                      offerItem.RedirectUrl =
                        vueObj.getNodeValue(redirectUrlNode);

                      let loanNode = item.querySelector("loan");
                      if (typeof loanNode != "undefined" && loanNode != null) {
                        offerItem.Loan.LoanAmount = vueObj.getNodeValue(
                          loanNode.querySelector("loan_amount")
                        );
                        offerItem.Loan.Apr = vueObj.getNodeValue(
                          loanNode.querySelector("apr")
                        );
                        offerItem.Loan.Term = vueObj.getNodeValue(
                          loanNode.querySelector("term")
                        );
                        offerItem.Loan.Fees = vueObj.getNodeValue(
                          loanNode.querySelector("fees")
                        );
                        offerItem.Loan.MonthlyRepayment = vueObj.getNodeValue(
                          loanNode.querySelector("monthly_repayment")
                        );
                        offerItem.Loan.TotalRepayment = vueObj.getNodeValue(
                          loanNode.querySelector("total_repayment")
                        );
                        offerItem.Loan.CostOfCredit = vueObj.getNodeValue(
                          loanNode.querySelector("cost_of_credit")
                        );
                        offerItem.Loan.Type = vueObj.getNodeValue(
                          loanNode.querySelector("type")
                        );
                        offerItem.Loan.Prequalified = vueObj.getNodeValue(
                          loanNode.querySelector("prequalified")
                        );

                        offerItem.Loan.Preapproved = vueObj.getNodeValue(
                          loanNode.querySelector("preapproved")
                        );
                        offerItem.Loan.Secured = vueObj.getNodeValue(
                          loanNode.querySelector("secured")
                        );

                        let featuresNode = loanNode.querySelector("features");
                        if (
                          typeof featuresNode != "undefined" &&
                          featuresNode != null
                        ) {
                          let features =
                            featuresNode.querySelectorAll("feature");
                          for (let f of features) {
                            let featureContent = vueObj.getNodeValue(f);
                            if (featureContent.trim() != "") {
                              offerItem.Loan.Features.push(featureContent);
                            }
                          }
                        }

                        let termsNode = loanNode.querySelector("terms");
                        if (
                          typeof termsNode != "undefined" &&
                          termsNode != null
                        ) {
                          let terms = featuresNode.querySelectorAll("term");
                          for (let t of terms) {
                            let termContent = vueObj.getNodeValue(t);
                            if (termContent.trim() != "") {
                              offerItem.Loan.Terms.push(termContent);
                            }
                          }
                        }

                        let caveatNode = loanNode.querySelector("caveat");
                        if (
                          typeof caveatNode != "undefined" &&
                          caveatNode != null
                        ) {
                          offerItem.Loan.LoanDescription = vueObj.getNodeValue(
                            caveatNode.querySelector("description")
                          );
                          offerItem.Loan.Tooltip = vueObj.getNodeValue(
                            caveatNode.querySelector("tooltip")
                          );
                        }
                      }
                      let lenderNode = item.querySelector("lender");
                      if (
                        typeof lenderNode != "undefined" &&
                        lenderNode != null
                      ) {
                        offerItem.Lender.Name = vueObj.getNodeValue(
                          lenderNode.querySelector("name")
                        );
                        offerItem.Lender.Description = vueObj.getNodeValue(
                          lenderNode.querySelector("description")
                        );
                        offerItem.Lender.Url = vueObj.getNodeValue(
                          lenderNode.querySelector("url")
                        );
                        offerItem.Lender.Phone = vueObj.getNodeValue(
                          lenderNode.querySelector("phone_number")
                        );
                        offerItem.Lender.LogoUrl = vueObj.getNodeValue(
                          lenderNode.querySelector("logo_url")
                        );
                      }

                      vueObj.monevoResult.Offers.push(offerItem);
                    }
                  }
                }
              } else {
                this.$swal(
                  "Error",
                  vueObj.monevoResult.ErrorDescription.trim(),
                  "error"
                );
              }
            }
          }
          this.isLoading = false;
        } catch {
          this.isLoading = false;
          //console.log("Error");
        }
      };
      request.open("GET", url);
      request.send();
    },
    async sendRequest() {
      this.isLoading = true;

      let linkStr = "https://usa.monevo.com/api?";
      //let linkStr = " https://ptusaapi.mybronte.co.uk/api?";

      linkStr +=
        "campaign_code=" +
        this.monevoObj.campaign_code +
        "&first_name=" +
        this.monevoObj.first_name +
        "&last_name=" +
        this.monevoObj.last_name +
        "&loan_amount=" +
        parseInt(this.monevoObj.loan_amount) +
        "&dob=" +
        this.monevoObj.dob +
        "&email=" +
        this.monevoObj.email +
        "&mobile_phone=" +
        this.monevoObj.mobile_phone +
        "&address1=" +
        this.monevoObj.address1 +
        "&city=" +
        this.monevoObj.city +
        "&state=" +
        this.monevoObj.state +
        "&zipcode=" +
        this.monevoObj.zipcode +
        "&residence_type=" +
        parseInt(this.monevoObj.residence_type) +
        "&months_at_address=" +
        parseInt(this.monevoObj.months_at_address) +
        "&monthly_rent=" +
        parseInt(this.monevoObj.monthly_rent) +
        "&social_security_number=" +
        this.monevoObj.social_security_number +
        "&income_source=" +
        parseInt(this.monevoObj.income_source) +
        "&pay_frequency=" +
        parseInt(this.monevoObj.income_source) +
        "&monthly_income=" +
        parseInt(this.monevoObj.monthly_income) +
        "&employer_name=" +
        this.monevoObj.employer_name +
        "&months_at_employer=" +
        this.monevoObj.months_at_employer +
        "&loan_purpose=" +
        parseInt(this.monevoObj.loan_purpose) +
        "&terms_consent=0" +
        "&marketing_opt_in=0" +
        "&mode=" +
        this.monevoObj.mode +
        "&affiliate_app_id=" +
        this.monevoObj.affiliate_app_id +
        "&site_url=" +
        this.monevoObj.site_url +
        "&site_ip=" +
        this.monevoObj.site_ip +
        "&client_ip=" +
        this.monevoObj.client_ip +
        "&co_app=" +
        this.monevoObj.co_app +
        "&v1=" +
        this.monevoObj.v1 +
        "&v2=" +
        this.monevoObj.v2;

      let response;
      let vueObj = this;
      var request = new XMLHttpRequest();
      request.onreadystatechange = async () => {
        try {
          if (request.readyState === 4) {
            response = request.responseText;
            let parser = new DOMParser();
            let xmlDoc = parser.parseFromString(response, "text/xml");

            let resultsUrl = xmlDoc.querySelector("results_url");
            let errors = xmlDoc.querySelector("error");

            if (typeof resultsUrl != "undefined" && resultsUrl != null) {
              let url = vueObj.getNodeValue(resultsUrl);
              if (typeof url != "undefined" && url != null && url != "") {
                await this.sleep(1000);
                this.sendSecondRequest(url);
              } else {
                this.isLoading = false;
                this.showErrorMessage(errors);
              }
            } else {
              this.isLoading = false;
              this.showErrorMessage(errors);
            }
          }
        } catch (err) {
          this.isLoading = false;
          this.$swal("Error", "An error occured!", "error");
        }
      };
      request.open("GET", linkStr);
      request.send();

      // request.open(
      //   "GET",
      //   "https://ptusaapi.mybronte.co.uk/api?campaign_code=cF5YYgwJAgND&social_security_number=000123000&loan_purpose=1&credit_rating=1&education_level=1&monthly_rent=200&keyword=&match_type=&network=&device=&ref=&gclid=&v1=&v2=&v3=&v4=&v5=&i1=&i2=&i3=&i4=&i5=&loan_amount=3500&military=1&dob=1959-04-06&first_name=Test&last_name=Test&email=batman%40batcave.com&home_phone=9176530352&mobile_phone=9176530352&best_call_time=3&zipcode=19901&address1=615&city=Dover&state=DE&residence_type=2&years_at_address=5&months_at_address=30&income_source=5&employer_name=Test&months_at_employer=4&pay_frequency=2&terms_consent=1&marketing_opt_in=1&client_ip=199.47.74.66&client_agent=Mozilla%2F5.0+%28iPhone%3B+CPU+iPhone+OS+7_1+like+Mac+OS+X%29+AppleWebKit%2F537.51.2+%28KHTML%2C+like+Gecko%29+Version%2F7.0+Mobile%2F11D167+Safari%2F9537.53&country=USA&mode=LIVE&monthly_income=875&site_url=www.test.com"
      // );
      // request.send();
    },
    showErrorMessage(errors) {
      if (typeof errors != "undefined" && errors != null) {
        let errorDesc = errors.querySelector("desc");
        if (typeof errorDesc != "undefined" && errorDesc != null) {
          errorDesc = vueObj.getNodeValue(errorDesc);
          if (errorDesc != null && errorDesc.trim() != "") {
            this.$swal("Error", errorDesc.trim(), "error");
          }
        }
      }
    },
  },

  validations() {
    return {
      monevoObj: {
        residence_type: { required },
        monthly_rent: { required },
        months_at_address: { required },
        income_source: { required },
        employer_name: { required },
        months_at_employer: { required },
        pay_frequency: { required },
        loan_amount: { required },
      },
    };
  },
};
</script>

<style>
.has-error {
  border: 2px solid red !important;
}
.monevoPopupHeader {
  padding-left: 15px;
  margin-top: -10px;
}
.textLabel {
  font-family: "Open Sans";
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #666;
}
@media screen and (max-height: 730px) {
  .smp-mediaScreen {
    max-height: 600px !important;
    overflow-y: scroll;
  }
}
@media screen and (max-height: 620px) {
  .smp-mediaScreen {
    max-height: 400px !important;
    overflow-y: scroll;
  }
}
@media screen and (max-height: 420px) {
  .smp-mediaScreen {
    max-height: 250px !important;
    overflow-y: scroll;
  }
}
.loading {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.6);
  z-index: 1000;
}
.monevoDisclaimerLabel {
  font-size: 0.72em;
  color: #999;
}
.monevoDisclaimerLabel p {
  margin: 5px 0;
  font-size: 0.72em;
  color: #999;
}
</style>
