<template>
  <div class="row mt-2">
    <div class="col-md-12 newPanelWrapper" style="min-height: 200px">
      <div v-if="isLoading" class="loading">
        <div class="text-center" style="margin-top: 50px">
          <i class="spinner-grow text-info m-2"></i>
        </div>
      </div>
      <div class="popupNameLabel">
        <label class="generalLabel" style="font-size: 15px"
          >{{ campaignName }} Data Upload</label
        >
      </div>
      <div
        v-if="isLoading == false"
        class="closeButton"
        v-on:click.prevent.stop="closePanel"
      >
        <i class="fas fa-window-close"></i>
      </div>
      <div class="col-md-12">
        <label class="generalLabel">Rules:</label>
      </div>
      <div class="col-md-12">
        <label class=""
          >1. The data file must be a
          <span class="generalLabel">CSV file</span> - UTF8 formatted and comma
          delimited.
        </label>
      </div>
      <div class="col-md-12">
        <label class=""
          >2. The data file must have
          <span class="generalLabel">exactly</span> the following column names
          as the first row:
        </label>
      </div>
      <div class="col-md-12">
        <ul
          style="
            width: 100%;
            padding: 0 20px !important;
            font-weight: 600 !important;
          "
        >
          <li>FirstName (text)</li>
          <li>LastName (text)</li>
          <li>MiddleInitial (text - can be empty)</li>
          <li>Address (text)</li>
          <li>City (text)</li>
          <li>State (text - 2 characters of a U.S. state abbreviation)</li>
          <li>Zip (text)</li>
          <li>VendorLeadId (text - unique code)</li>
          <li>DebtAmount (number - default value is 10000 if empty)</li>
          <li>PhoneNumber (text - can be empty)</li>
          <li>Source (text - can be empty)</li>
        </ul>
      </div>
      <o-field class="col-md-12 mt-2" v-if="!isFileValid">
        <o-upload
          v-model="dropFiles"
          drag-drop
          type="file"
          accept=".csv"
          @input="handleFileChange"
        >
          <section
            class="text-center"
            style="
              margin: 30px;
              display: flex;
              flex-direction: column;
              align-items: center;
            "
          >
            <i class="fas fa-cloud-upload-alt folderIcon"> </i>
            <label class="mt-1"
              >Drop your files here or click to upload.
            </label>
          </section>
        </o-upload>
      </o-field>
      <div class="col-md-12 mt-4" v-if="isFileValid">
        <label
          class="generalLabel"
          style="font-size: 15px; padding: 0.45rem 0.9rem"
          >{{ fileName }}</label
        >
        <button @click="uploadFile()" class="btn btn-success ml-2">
          Upload
        </button>
        <button @click="cancelFile()" class="btn btn-danger ml-2">
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import formatMixin from "@/mixins/formatMixin";
import types from "./types";
import utilitiesMixin from "@/mixins/utilitiesMixin";
export default {
  name: "CampaignDataUploadPopup",
  props: {
    campaignId: {
      type: Number,
      default: () => 0,
    },
    campaignName: {
      type: String,
      default: () => "",
    },
  },
  watch: {
    dropFiles(newVal, oldVal) {
      if (oldVal != newVal && newVal != null) {
        this.fileToUpload = newVal;
        this.checkFile();
      } else {
        this.$swal(
          "Warning!",
          "File type is not valid. Only csv files allowed.",
          "warning"
        );
      }
    },
  },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      dropFiles: [],
      fileToUpload: null,
      fileName: "",
      isFileValid: false,
      isLoading: false,
    };
  },
  methods: {
    closePanel() {
      this.$emit("closeDataUploadPopup");
    },
    cancelFile() {
      this.isFileValid = false;
      this.fileToUpload = null;
    },
    async uploadFile() {
      if (this.fileToUpload) {
        let file = this.fileToUpload;
        this.isLoading = true;
        try {
          const toBase64 = (file) =>
            new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => resolve(reader.result);
              reader.onerror = (error) => reject(error);
            });

          let data = await toBase64(file);
          if (data.indexOf("data:") > -1) {
            data = data.split(",")[1];
          }

          let err, result;

          [err, result] = await this.$store.dispatch(
            types.UPLOAD_CAMPAIGN_DATA,
            { CampaignId: this.campaignId, FileData: data }
          );
          if (typeof result != "undefined" && result != null) {
            this.$swal(
              "Success",
              "Campaign data uploaded successfully.",
              "success"
            );
            this.$emit("closeDataUploadPopup");
          } else {
            let errMsg = this.getApiErrorMessage(err);
            this.$swal("Error!", errMsg, "error");
          }
        } catch {
          this.$swal("Error", "An Error Occured!", "error");
        }
        this.isLoading = false;
      }
    },
    saveByteArray(name, byte) {
      let blob = new Blob([byte], { type: "application/docx" });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      let fileName = name;
      link.download = fileName;
      link.click();
    },
    handleFileClick() {
      this.$refs.uploader.value = "";
      const input = this.$refs.uploader;
      input.type = "file";
    },

    checkFile() {
      if (this.fileToUpload) {
        let file = this.fileToUpload;
        let fileExtension = file.name.split(".").pop();
        if (fileExtension != "csv") {
          this.$swal(
            "Warning!",
            "File type is not valid. Only csv files allowed.",
            "warning"
          );
          return;
        }
        if (!this.isValidFileSize(file.size, 50)) {
          this.$swal(
            "Warning!",
            "Maximum allowed file size is 50 MB. Please resize your file.",
            "warning"
          );
          return;
        }

        this.isFileValid = true;
        let fileSize =
          parseInt(file.size / 1024) > 0
            ? parseFloat(file.size / (1024 * 1024)).toFixed(2) + " mb"
            : file.size + "bytes";
        this.fileName = "File: " + file.name + " (Size: " + fileSize + ")";
      } else {
        this.$swal(
          "Warning!",
          "File type is not valid. Only csv files allowed.",
          "warning"
        );
      }
    },
    async handleFileChange(e) {
      if (e.target.files && e.target.files.length > 0) {
        this.fileToUpload = e.target.files[0];
        this.checkFile();
      }
    },
    isValidFileSize(size, limitMb) {
      let rtn = true;
      if (size > 1024 * 1024 * limitMb) {
        rtn = false;
      }
      return rtn;
    },
  },
};
</script>
