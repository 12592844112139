<template>
  <input
    type="number"
    style="-webkit-appearance: none !important; margin: 0"
    v-model="changedValue"
    @keyup="keyChange"
    @change="changeInput($event)"
    :placeholder="placeholder"
  />
</template>
<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0,
    },
    minNumber: {
      type: Number,
      default: 0,
    },
    maxNumber: {
      type: Number,
      default: 1000,
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      changedValue: 0,
    };
  },
  unmounted() {
    // this.emptyTheObjectsOnUnmount();
  },
  mounted() {
    this.changedValue = this.value;
    this.setValue(this.value);
  },
  computed: {},
  watch: {
    value: function (val) {
      this.changedValue = val;
    },
  },
  methods: {
    emptyTheObjectsOnUnmount() {
      this.changedValue = null;
    },
    keyChange(event) {
      this.setValue(event.target.value);
    },
    changeInput(event) {
      this.setValue(event.target.value);
    },
    setValue(val) {
      if (val < this.minNumber) {
        this.changedValue = this.minNumber;
      } else if (val > this.maxNumber) {
        this.changedValue = this.maxNumber;
      }

      this.$emit("updateValue", this.changedValue);
    },
  },
};
</script>
