import types from "./types";
import api from "@/services/admin/api";
import to from "@/helpers/to";
export default {
  state: {
    globalSettingList: [],
  },
  actions: {
    GET_POLICY_GROUPS: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.getPolicyGroupsByCompany(payload));
      return [err, result];
    },
    GET_GLOBAL_SETTINGS: async ({ commit }) => {
      let err, result;

      [err, result] = await to(api.getCompanyGlobalSettings());
      if (result) {
        commit(types.SET_GLOBAL_SETTINGS, result.Data);
      }
    },
    INSERT_GLOBAL_SETTING: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.insertCompanyGlobalSettings(payload));
      return [err, result];
    },
    UPDATE_GLOBAL_SETTING: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.updateCompanyGlobalSettings(payload));
      return [err, result];
    },
    DELETE_GLOBAL_SETTING: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.deleteCompanyGlobalSettings(payload));
      return [err, result];
    },
  },
  mutations: {
    SET_GLOBAL_SETTINGS: (state, payload) => {
      state.globalSettingList = payload;
    },
  },
};
