<template>
  <div class="col-md-12">
    <ReportSummary
      :exportData="reportData"
      :reportName="reportName"
      @updateLoadingStatus="reportLoading = $event"
      @setPayload="setPayload"
      @clearReport="clearReport"
      @setPagination="setPagination"
      :recordCount="recordCount"
      :loadingSpinner="loadingSpinner"
    />
    <div
      class="datagrid-table gcsTabsTableScroll report-wrapper"
      :style="'max-height: ' + reportsPanelHeight + 'px; overflow-y:auto;'"
    >
      <o-table
        :data="filteredCallLogsReport"
        :paginated="isPaginated && filteredCallLogsReport.length > 0"
        :per-page="perPage"
        :pagination-simple="isPaginationSimple"
        :pagination-position="paginationPosition"
        v-model:current-page="currentPage"
        @sort="sortChange"
        v-model:default-sort="defaultSortColumn"
        v-model:default-sort-direction="defaultSortDirection"
        :sticky-header="true"
      >
        <o-table-column field="User" label="Who" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="userFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary">{{ props.row.User }} </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.User }}
            </span>
          </template>
        </o-table-column>

        <o-table-column field="Office" label="Office" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="officeFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.Office }}
          </template>
        </o-table-column>

        <o-table-column field="LogText" label="Direction" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="logTextFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.LogText }}
          </template>
        </o-table-column>
        <o-table-column field="Phone" label="Phone Number" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="phoneFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ tryFormatPhone(props.row.Phone) }}
          </template>
        </o-table-column>

        <o-table-column
          field="CustomerOffice"
          label="Customer Office"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="customerOfficeFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.CustomerOffice }}
          </template>
        </o-table-column>

        <o-table-column field="CompanyDid" label="Company Did" searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="companyDidFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            <div
              v-if="
                props.row.CallType == 'Missed Call' ||
                props.row.CallType == 'InBound'
              "
            >
              {{ tryFormatPhone(props.row.CompanyDid) }}
            </div>
          </template>
        </o-table-column>

        <o-table-column
          field="RecordingDuration"
          label="Duration"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="recordingDurationFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.RecordingDuration }}
          </template>
        </o-table-column>
        <o-table-column field="Campaign" label="Campaign" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="campaingFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.Campaign }}
          </template>
        </o-table-column>
        <o-table-column field="LogDate" label="Log Date" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="logDateFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ formatGridDate(props.row.LogDate) }}
          </template>
        </o-table-column>
        <o-table-column field="CallRecord" label="Call Recording">
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            <i
              v-if="!props.row.IsSummary"
              class="fas fa-play"
              style="color: green; cursor: pointer"
              @click="playRecord(props.row.CallRecord)"
            ></i>
          </template>
        </o-table-column>

        <o-table-column field="CheckDetails" label="Check Details">
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            <button
              v-if="
                !props.row.IsSummary &&
                props.row.CallType != 'OutBound' &&
                props.row.MissedCallReason != 'IVR'
              "
              class="btn btn-primary fas fa-file-medical-alt"
              @click="
                openCallLogDetailsPopUp(
                  props.row.Id,
                  props.row.MissedCallReason,
                  props.row.User,
                  props.row.CallType
                )
              "
            ></button>
          </template>
        </o-table-column>
      </o-table>
      <section
        v-if="filteredCallLogsReport.length == 0"
        class="section"
        style="text-align: center"
      >
        <div class="content has-text-grey has-text-centered mb-4">
          <p class="noMargin">
            <i
              style="font-size: 24px; color: #939393"
              class="ri-question-line"
            ></i>
          </p>
          <p class="noMargin">Nothing here.</p>
        </div>
      </section>
    </div>
    <div class="row">
      <div class="backgroundLock" v-if="isAudioControllerPopupOpen">
        <div
          v-click-outside="closeAudioControllerPopup"
          v-if="soundUrl != ''"
          style="position: relative; margin: 20% auto"
          class="offset-xl-4 col-xl-4 offset-lg-3 col-lg-6 col-md-12 col-sm-12 col-xs-12"
        >
          <AudioController
            :soundUrl="soundUrl"
            @closeAudioControllerPopup="closeAudioControllerPopup"
          ></AudioController>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="backgroundLock" v-if="isCallLogDetailsPopUp">
        <div
          style="position: relative; margin: 5% auto; z-index: 2000"
          class="offset-xl-4 col-xl-7 offset-lg-3 col-lg-6 col-md-12 col-sm-12 col-xs-12"
        >
          <CallLogDetailsPopUp
            @closeCallLogDetailsPopUp="closeCallLogDetailsPopUp"
            :callLogId="callLogId"
            :missedCallReason="missedCallReason"
            :customer="customer"
            :callType="callType"
          >
          </CallLogDetailsPopUp>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import ReportSummary from "./ReportSummary.vue";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import AudioController from "./AudioController.vue";
import CallLogDetailsPopUp from "./CallLogDetailsPopUp.vue";

export default {
  name: "CallLogsReport",
  components: { ReportSummary, AudioController, CallLogDetailsPopUp },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      reportName: "CallLogsReport",
      callLogId: 0,
      missedCallReason: "",
      customer: "",
      callType: "",
      reportsPanelHeight: 300,
      isAudioControllerPopupOpen: false,
      isCallLogDetailsPopUp: false,
      loadingSpinner: false,
      isPaginationSimple: false,
      isPaginated: true,
      paginationPosition: "both",
      currentPage: 1,
      perPage: 200,
      recordCount: 0,
      reportData: [],
      soundUrl: "",
      reportLoading: false,
      callLogsInitSummary: {
        User: "",
        Office: "",
        LogDate: null,
        CallType: "",
        Phone: "",
        CompanyDid: "",
        Customer: "",
        RecordingDuration: null,
        Campaign: "",
        MissedCallReason: "",
        LogText: "",
      },
      userFilter: "",
      officeFilter: "",
      logDateFilter: "",
      callTypeFilter: "",
      phoneFilter: "",
      customerOfficeFilter: "",
      companyDidFilter: "",
      customerFilter: "",
      recordingDurationFilter: "",
      campaingFilter: "",
      missedCallReasonFilter: "",
      logTextFilter: "",
      defaultSortColumn: "",
      defaultSortDirection: "asc",
    };
  },
  computed: mapState({
    documentsCallLogList: (state) => state.reports.callLogList,

    filteredCallLogsReport() {
      let rtn = [];

      let tmpList =
        this.documentsCallLogList.length > 0
          ? this.documentsCallLogList.filter((item) => {
              return (
                this.filterString(item.User, this.userFilter) &&
                this.filterString(item.LogText, this.logTextFilter) &&
                this.filterString(item.Office, this.officeFilter) &&
                new Date(item.LogDate)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .indexOf(this.logDateFilter) > -1 &&
                this.filterString(item.CallType, this.callTypeFilter) &&
                (this.filterString(this.tryFormatPhone(item.Phone), this.phoneFilter.trimLeft()) ||   this.filterString(item.Phone, this.cleanFormatPhone(this.phoneFilter.trimLeft()))) &&
                this.filterString(
                  item.CustomerOffice,
                  this.customerOfficeFilter
                ) &&
                (this.filterString(this.tryFormatPhone(item.CompanyDid), this.companyDidFilter.trimLeft()) ||   this.filterString(item.CompanyDid, this.cleanFormatPhone(this.companyDidFilter.trimLeft())) )&&
                this.filterString(item.Customer, this.customerFilter) &&
                this.filterNumeric(
                  item.RecordingDuration,
                  this.recordingDurationFilter
                ) &&
                this.filterString(item.Campaign, this.campaingFilter) &&
                this.filterString(
                  item.MissedCallReason,
                  this.missedCallReasonFilter
                )
              );
            })
          : [];

      if (tmpList.length == 0) {
        this.reportData = [];
        return rtn;
      }

      let totalCount = tmpList.length.toString();

      this.callLogsInitSummary.User = "Total: " + totalCount;
      this.recordCount = tmpList.length;
      this.callLogsInitSummary.IsSummary = true;

      rtn.push(...tmpList);
      rtn.push(this.callLogsInitSummary);
      this.reportData = rtn;
      return rtn;
    },
  }),
  unmounted() {
    let settings = {
      PagingNumber: this.currentPage,
      SortColumn: this.defaultSortColumn,
      SortDirection: this.defaultSortDirection,
      Filters: {
        userFilter: this.userFilter,
        officeFilter: this.officeFilter,
        logDateFilter: this.logDateFilter,
        callTypeFilter: this.callTypeFilter,
        phoneFilter: this.phoneFilter,
        customerOfficeFilter: this.customerOfficeFilter,
        companyDidFilter: this.companyDidFilter,
        customerFilter: this.customerFilter,
        recordingDurationFilter: this.recordingDurationFilter,
        campaingFilter: this.campaingFilter,
        missedCallReasonFilter: this.missedCallReasonFilter,
        logTextFilter: this.logTextFilter,
      },
    };
    sessionStorage.setItem(
      "tableSettings-" + this.reportName,
      JSON.stringify(settings)
    );
  },
  async mounted() {
    this.reportsPanelHeight = this.setReportGridHeight(
      window.innerHeight,
      document.getElementById("reportsSummary")
    );

    let tableSettings = sessionStorage.getItem(
      "tableSettings-" + this.reportName
    );
    if (typeof tableSettings != "undefined" && tableSettings) {
      let settings = JSON.parse(tableSettings);

      if (typeof settings != "undefined" && settings) {
        this.currentPage = settings.PagingNumber;
        this.defaultSortColumn = settings.SortColumn;
        this.defaultSortDirection = settings.SortDirection;
        if (settings.Filters) {
          let keys = Object.keys(settings.Filters);
          if (keys && keys.length > 0)
            for (let item of keys) {
              this[item] = settings.Filters[item];
            }
        }
      }
    }
    await this.sleep(1000);
    this.fixStickyHeaders();
  },
  methods: {
    sortChange(column, direction) {
      this.defaultSortColumn = column;
      this.defaultSortDirection = direction;
    },
    setPagination(data) {
      this.isPaginated = data;
    },
    async setPayload(value) {
      this.loadingSpinner = true;
      await this.$store.dispatch(types.GET_CALL_LOG_REPORT, value);
      this.loadingSpinner = false;
      await this.sleep(500);
      this.fixStickyHeaders();
    },
    async playRecord(url) {
      this.isAudioControllerPopupOpen = true;
      this.soundUrl = "";
      await this.sleep(500);
      this.soundUrl = url;
      await this.sleep(500);
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    clearReport() {
      this.$store.dispatch(types.RESET_REPORTS);
      this.reportData = [];
    },
    closeAudioControllerPopup() {
      this.isAudioControllerPopupOpen = false;
    },
    closeCallLogDetailsPopUp() {
      this.isCallLogDetailsPopUp = false;
    },
    openCallLogDetailsPopUp(id, callReason, customer, callType) {
      this.isCallLogDetailsPopUp = true;
      this.callLogId = id;
      this.missedCallReason = callReason;
      this.customer = customer;
      this.callType = callType;
    },
  },
};
</script>
