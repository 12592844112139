<template>
  <div class="col-md-12">
    <ReportSummary
      :exportData="reportData"
      :reportName="'AgentDropRateReport'"
      :recordCount="recordCount"
      @updateLoadingStatus="reportLoading = $event"
      @setPayload="setPayload"
      @clearReport="clearReport"
      @setPagination="setPagination"
      :loadingSpinner="loadingSpinner"
    />

    <div
      class="datagrid-table gcsTabsTableScroll report-wrapper"
      :style="'max-height: ' + reportsPanelHeight + 'px; overflow-y:auto;'"
    >
      <o-table
        :data="filteredAgentDropRateReport"
        :paginated="isPaginated && filteredAgentDropRateReport.length > 0"
        :per-page="perPage"
        :pagination-simple="isPaginationSimple"
        :pagination-position="paginationPosition"
        :sticky-header="true"
      >
        <o-table-column
          field="AgentName"
          label="Agent Name"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="AgentNameFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.AgentName }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.AgentName }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="Enrollments"
          label="# of Enrollments"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="EnrollmentsFilter"
                placeholder=""
                style="width: 60px"
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.Enrollments }}
          </template>
        </o-table-column>

        <o-table-column field="Drops" label="# of Drops" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="DropsFilter"
                placeholder=""
                style="width: 60px"
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.Drops }}
          </template>
        </o-table-column>

        <o-table-column label="Drop %">
          <template v-slot="props">
            <span v-if="!props.row.IsSummary">
              {{ dropPercantage(props.row.Drops, props.row.Enrollments) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="Drops0Pmt"
          label="# of Drops - No Payments"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="Drops0PmtFilter"
                placeholder=""
                style="width: 60px"
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.Drops0Pmt }}
          </template>
        </o-table-column>

        <o-table-column label="Drop %">
          <template v-slot="props">
            <span v-if="!props.row.IsSummary">
              {{ dropsNoPayments(props.row.Drops, props.row.Drops0Pmt) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="Drops2Pmt"
          label="# of Drops - 1-2 Payments"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="Drops2PmtFilter"
                placeholder=""
                style="width: 60px"
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.Drops2Pmt }}
          </template>
        </o-table-column>

        <o-table-column label="Drop %">
          <template v-slot="props">
            <span v-if="!props.row.IsSummary">
              {{ dropPercantage(props.row.Drops2Pmt, props.row.Drops) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="Drops3Pmt"
          label="# of Drops - 3+ Payments"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="Drops3PmtFilter"
                placeholder=""
                style="width: 60px"
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.Drops3Pmt }}
          </template>
        </o-table-column>

        <o-table-column label="Drop %">
          <template v-slot="props">
            <span v-if="!props.row.IsSummary">
              {{ dropPercantage(props.row.Drops3Pmt, props.row.Drops) }}
            </span>
          </template>
        </o-table-column>
      </o-table>
      <section
        v-if="filteredAgentDropRateReport.length == 0"
        class="section"
        style="text-align: center"
      >
        <div class="content has-text-grey has-text-centered mb-4">
          <p class="noMargin">
            <i
              style="font-size: 24px; color: #939393"
              class="ri-question-line"
            ></i>
          </p>
          <p class="noMargin">Nothing here.</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import ReportSummary from "./ReportSummary.vue";
import utilitiesMixin from "@/mixins/utilitiesMixin";

export default {
  name: "AgentDropRateReport",
  components: { ReportSummary },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      reportsPanelHeight: 300,
      loadingSpinner: false,
      isPaginationSimple: false,
      isPaginated: true,
      paginationPosition: "both",
      currentPage: 1,
      perPage: 200,
      recordCount: 0,
      reportLoading: false,
      reportData: [],
      agentDropRateReportInitSummary: {
        AgentName: "",
        Enrollments: "",
        Drops: "",
        Drops0Pmt: "",
        Drops2Pmt: "",
        Drops3Pmt: "",
      },
      AgentNameFilter: "",
      EnrollmentsFilter: "",
      DropsFilter: "",
      Drops0PmtFilter: "",
      Drops2PmtFilter: "",
      Drops3PmtFilter: "",
    };
  },
  computed: mapState({
    agentDropRateList: (state) => state.reports.agentDropRateList,

    filteredAgentDropRateReport() {
      let rtn = [];

      let tmpList =
        this.agentDropRateList.length > 0
          ? this.agentDropRateList.filter((item) => {
              return (
                this.filterString(item.AgentName, this.AgentNameFilter) &&
                this.filterString(item.Enrollments, this.EnrollmentsFilter) &&
                this.filterString(item.Drops, this.DropsFilter) &&
                this.filterString(item.Drops0Pmt, this.Drops0PmtFilter) &&
                this.filterString(item.Drops2Pmt, this.Drops2PmtFilter) &&
                this.filterString(item.Drops3Pmt, this.Drops3PmtFilter)
              );
            })
          : [];

      if (tmpList.length == 0) {
        this.reportData = [];
        return rtn;
      }
      let totalCustomer = tmpList.length.toString();

      this.agentDropRateReportInitSummary.AgentName = "Total: " + totalCustomer;
      this.recordCount = tmpList.length;
      this.agentDropRateReportInitSummary.IsSummary = true;

      rtn.push(...tmpList);
      rtn.push(this.agentDropRateReportInitSummary);
      this.reportData = rtn;

      return rtn;
    },
  }),
  async mounted() {
    this.reportsPanelHeight = this.setReportGridHeight(
      window.innerHeight,
      document.getElementById("reportsSummary")
    );
    await this.sleep(1000);
    this.fixStickyHeaders();
  },
  methods: {
    dropPercantage(d, e) {
      let perc = 0;
      if (e == 0) {
        perc = 0 + "%";
        return perc;
      }
      if (e && d) {
        perc = parseFloat((d * 100) / e);
        perc = perc.toFixed(2) + "%";
      } else {
        perc = 0 + "%";
      }
      return perc;
    },

    dropsNoPayments(drops, drops0) {
      let perc = 0;
      if (drops == 0) {
        perc = 0 + "%";
        return perc;
      }
      if (drops && drops0) {
        perc = drops - drops0;
        perc = parseFloat((perc * 100) / drops);
        perc = perc.toFixed(2) + "%";
      } else {
        perc = 0 + "%";
      }
      return perc;
    },

    setPagination(data) {
      this.isPaginated = data;
    },
    async setPayload(value) {
      this.loadingSpinner = true;
      await this.$store.dispatch(types.GET_AGENT_DROP_RATE_REPORT, value);
      this.loadingSpinner = false;

      await this.sleep(500);
      this.fixStickyHeaders();
    },
    clearReport() {
      this.$store.dispatch(types.RESET_REPORTS);
      this.reportData = [];
    },
  },
};
</script>

<style scoped></style>
