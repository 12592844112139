<template>
  <div
    v-click-outside="closePanel"
    class="col-md-12 newPanelWrapper noMargin smp-mediaScreen"
    style="
      background-color: #ffffff;
      z-index: 1000;
      padding-left: 0;
      padding-right: 0;
    "
  >
    <div class="superMoneyPopUpHeader">
      <label style="font-size: 15px">Super Money</label>
    </div>
    <div>
      <div class="closeButton" v-on:click.prevent.stop="closePanel">
        <i class="fas fa-window-close"></i>
      </div>
    </div>

    <perfect-scrollbar
      style="width: 100%; max-height: 600px; overflow-x: hidden !important"
    >
      <div class="col-md-12 row">
        <div class="col-md-4">
          <label for="sm-firstName" class="generalLabel">First Name</label>

          <label class="form-control" style="background: #f1efef">{{
            dataToPassSuperMoney.FirstName
          }}</label>
        </div>

        <div class="col-md-4">
          <label for="sm-lastName" class="generalLabel">Last Name</label>
          <label class="form-control" style="background: #f1efef">{{
            dataToPassSuperMoney.LastName
          }}</label>
        </div>

        <div class="col-md-4">
          <label for="sm-email" class="generalLabel">Email</label>
          <label class="form-control" style="background: #f1efef">{{
            dataToPassSuperMoney.CustomerEmail
          }}</label>
        </div>

        <div class="col-md-4">
          <label for="sm-phone" class="generalLabel">Phone</label>
          <label class="form-control" style="background: #f1efef">{{
            dataToPassSuperMoney.PhoneNumber
          }}</label>
        </div>
        <div class="col-md-4">
          <label for="sm-dateofBirth" class="generalLabel">Date of Birth</label>
          <label class="form-control" style="background: #f1efef">{{
            new Date(dataToPassSuperMoney.DateOfBirth).toString("yyyy-MM-dd")
          }}</label>
        </div>

        <div class="col-md-4">
          <label for="sm-loanAmount" class="generalLabel">Loan Amount</label>
          <label class="form-control" style="background: #f1efef">{{
            formatMoney(dataToPassSuperMoney.TotalAmount)
          }}</label>
        </div>

        <div class="col-md-4">
          <label for="sm-loanReason" class="generalLabel">Loan Reason</label>
          <label class="form-control" style="background: #f1efef">{{
            superMoneyObj.loanReason
          }}</label>
        </div>

        <div class="col-md-4">
          <label for="sm-creditScore" class="generalLabel">Credit Score</label>
          <input
            type="text"
            id="sm-creditScore"
            class="form-control"
            autocomplete="none"
            placeholder="Credit Score"
            v-myMask="{
              alias: 'numeric',
              prefix: '',
              min: 300,
              max: 850,
              rightAlign: false,
              radixPoint: '',
            }"
            v-model="superMoneyObj.creditScore"
          />
        </div>

        <div class="col-md-4">
          <label for="sm-annualIncome" class="generalLabel"
            >Annual Income</label
          >
          <label class="form-control" style="background: #f1efef">{{
            formatMoney(dataToPassSuperMoney.Income)
          }}</label>
        </div>

        <div class="col-md-4">
          <label for="sm-employmentStatus" class="generalLabel"
            >Employment Status</label
          >

          <select
            class="form-control"
            id="sm-employmentStatus"
            v-model="superMoneyObj.employmentStatus"
          >
            <option value="Employed Full-Time">Employed Full-Time</option>
            <option value="Employed Part-Time'">Employed Part-Time</option>
            <option value="Self-Employed">SelfEmployed</option>
            <option value="Retired">Retired</option>
            <option value="Unemployed">Unemployed</option>
            <option value="Other">Other</option>
          </select>
        </div>

        <div class="col-md-4">
          <label for="sm-payFrequency" class="generalLabel"
            >Pay Frequency</label
          >
          <select
            class="form-control"
            id="sm-payFrequency"
            v-model="superMoneyObj.payFrequency"
          >
            <option value="biweekly">Biweekly</option>
            <option value="twice_monthly">Twice a Month</option>
            <option value="monthly">Monthly</option>
            <option value="weekly">Weekly</option>
            <option value="other">Other</option>
          </select>
        </div>

        <div class="col-md-4">
          <label for="sm-payMethod" class="generalLabel">Pay Method</label>
          <select
            class="form-control"
            id="sm-payMethod"
            v-model="superMoneyObj.payMethod"
          >
            <option value="direct_deposit">Direct Deposit</option>
            <option value="check_prepaid_card">
              Payroll Check or Prepaid Card
            </option>
            <option value="cash_other">Cash / Other</option>
            <option value="1099">1099 Misc.Income</option>
          </select>
        </div>

        <div class="col-md-4">
          <label for="sm-militaryStatus" class="generalLabel"
            >Military Status</label
          >
          <select
            class="form-control"
            id="sm-militaryStatus"
            v-model="superMoneyObj.militaryStatus"
          >
            <option value="non_military">Not Military</option>
            <option value="active_duty_and_dependents">
              Active or Reserve Duty
            </option>
            <option value="military_dependent">Dependent of Active Duty</option>
            <option value="veteran">Veteran</option>
          </select>
        </div>

        <div class="col-md-4">
          <label for="sm-residencyStatus" class="generalLabel">Residency</label>
          <select
            class="form-control"
            id="sm-residencyStatus"
            v-model="superMoneyObj.residencyStatus"
          >
            <option value="us_citizen">U.S.Citizen</option>
            <option value="us_permanent_resident">Permanent Resident</option>
            <option value="temp_resident_visa_holders">
              Temporary Resident Visa Holders
            </option>
            <option value="non_resident">Non-Resident</option>
          </select>
        </div>

        <div class="col-md-4">
          <label for="sm-checkingAccount" class="generalLabel"
            >Checking Account</label
          >
          <label class="form-control" style="background: #f1efef">{{
            dataToPassSuperMoney.CheckingAccount
          }}</label>
        </div>

        <div class="col-md-4">
          <label for="sm-housingStatus" class="generalLabel"
            >Housing Status</label
          >
          <select
            class="form-control"
            id="sm-housingStatus"
            v-model="superMoneyObj.housingStatus"
          >
            <option value="Own with mortgage">Own with mortgage</option>
            <option value="Own with no mortgage’">Own with no mortgage</option>
            <option value="Rent">Rent</option>
            <option value="Other">Other</option>
          </select>
        </div>

        <div class="col-md-4">
          <label for="sm-address1" class="generalLabel">Address1</label>
          <label class="form-control" style="background: #f1efef">{{
            dataToPassSuperMoney.AddressLine1
          }}</label>
        </div>

        <div class="col-md-4">
          <label for="sm-city" class="generalLabel">City</label>
          <label class="form-control" style="background: #f1efef">{{
            dataToPassSuperMoney.City
          }}</label>
        </div>

        <div class="col-md-4">
          <label for="sm-state" class="generalLabel">State</label>
          <label class="form-control" style="background: #f1efef">{{
            dataToPassSuperMoney.ShortState
          }}</label>
        </div>

        <div class="col-md-4">
          <label for="sm-zip" class="generalLabel">Zip Code</label>
          <label class="form-control" style="background: #f1efef">{{
            dataToPassSuperMoney.Zip
          }}</label>
        </div>
      </div>
    </perfect-scrollbar>
    <div class="col-md-12">
      <div class="col-md-12">
        <button
          type="button"
          class="btn btn-success noMargin col-md-12"
          @click="superMoneySendButton()"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import formatMixin from "@/mixins/formatMixin";

export default {
  name: "SuperMoneyPopup",
  components: {},
  mixins: [formatMixin],

  props: {
    dataToPassSuperMoney: {
      Type: Object,
      Defult: null,
    },
  },
  data() {
    return {
      superMoneyObj: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        dob: "",
        loanAmount: null,
        loanReason: "debt_consolidation",
        creditScore: 300,
        annualIncome: null,
        employmentStatus: "Employed Full-Time",
        payFrequency: "biweekly",
        payMethod: "direct_deposit",
        militaryStatus: "non_military",
        residencyStatus: "us_citizen",
        checkingAccount: "",
        housingStatus: "Own with mortgage",
        address1: "",
        city: "",
        state: null,
        zip: null,
        customerId: 0
      },
    };
  },
  unmounted() {
    // this.emptyTheObjectsOnUnmount();
  },
  async mounted() {
    this.superMoneyObj.customerId = this.dataToPassSuperMoney.CustomerId;
    this.superMoneyObj.checkingAccount =
      this.dataToPassSuperMoney.CheckingAccount;
    this.superMoneyObj.address1 = this.dataToPassSuperMoney.AddressLine1;
    this.superMoneyObj.city = this.dataToPassSuperMoney.City;
    this.superMoneyObj.email = this.dataToPassSuperMoney.CustomerEmail;
    this.superMoneyObj.dob = new Date(
      this.dataToPassSuperMoney.DateOfBirth
    ).toString("yyyy-MM-dd");
    this.superMoneyObj.firstName = this.dataToPassSuperMoney.FirstName;
    this.superMoneyObj.annualIncome = this.dataToPassSuperMoney.Income;
    this.superMoneyObj.lastName = this.dataToPassSuperMoney.LastName;
    this.superMoneyObj.phone = this.dataToPassSuperMoney.PhoneNumber;
    this.superMoneyObj.state = this.dataToPassSuperMoney.ShortState;
    this.superMoneyObj.loanAmount = this.dataToPassSuperMoney.TotalAmount;
    this.superMoneyObj.zip = this.dataToPassSuperMoney.Zip;
  },
  methods: {
    emptyTheObjectsOnUnmount() {
      this.superMoneyObj = null;
    },
    closePanel() {
      this.$emit("closeSuperMoneyPopup");
    },

    superMoneySendButton() {
      let linkStr = "https://www.supermoney.com/business/application?";
      linkStr +=
        "firstName=" +
        this.superMoneyObj.firstName +
        "&lastName=" +
        this.superMoneyObj.lastName +
        "&email=" +
        this.superMoneyObj.email;
      linkStr +=
        "&phone=" +
        this.superMoneyObj.phone +
        "&dob=" +
        this.superMoneyObj.dob +
        "&loanAmount=" +
        this.superMoneyObj.loanAmount;
      linkStr +=
        "&loanReason=" +
        parseInt(this.superMoneyObj.loanReason) +
        "&creditScore=" +
        this.superMoneyObj.creditScore +
        "&annualIncome=" +
        this.superMoneyObj.annualIncome;
      linkStr +=
        "&employmentStatus=" +
        this.superMoneyObj.employmentStatus +
        "&payFrequency=" +
        this.superMoneyObj.payFrequency +
        "&payMethod=" +
        this.superMoneyObj.payMethod;
      linkStr +=
        "&militaryStatus=" +
        this.superMoneyObj.militaryStatus +
        "&residencyStatus=" +
        this.superMoneyObj.residencyStatus +
        "&checkingAccount=" +
        this.superMoneyObj.checkingAccount;
      linkStr +=
        "&housingStatus=" +
        this.superMoneyObj.housingStatus +
        "&address1=" +
        this.superMoneyObj.address1 +
        "&city=" +
        this.superMoneyObj.city;
      linkStr +=
        "&state=" + this.superMoneyObj.state + "&zip=" + this.superMoneyObj.zip;
        linkStr +=
        "&partnerUID=" + this.superMoneyObj.customerId;

      let link = document.createElement("a");
      link.href = linkStr;
      link.target = "_blank";
      link.click();
    },
  },
};
</script>

<style>
.has-error {
  border: 2px solid red !important;
}
.superMoneyPopUpHeader {
  padding-left: 24px;
  margin-top: -5px;
  padding-bottom: 10px;
}
@media screen and (max-height: 730px) {
  .smp-mediaScreen {
    max-height: 600px !important;
    overflow-y: scroll;
  }
}
@media screen and (max-height: 620px) {
  .smp-mediaScreen {
    max-height: 400px !important;
    overflow-y: scroll;
  }
}
@media screen and (max-height: 420px) {
  .smp-mediaScreen {
    max-height: 250px !important;
    overflow-y: scroll;
  }
}
</style>
