import types from "./types";
import api from "@/services/admin/api";
import to from "@/helpers/to";

export default {
  state: {
    list: [],
    forCustomerStatusList: [],
  },
  actions: {
    GET_FOR_CUSTOMER_STATUS_MANAGEMENT: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.getForCustomerStatusManagement(payload));
      return [err, result];
    },

    GET_STATUSES: async ({ commit }) => {
      let err, result;

      [err, result] = await to(api.getCustomerStatuses());
      if (result) {
        commit(types.SET_STATUSES, result.Data);
      }
    },
    INSERT_STATUS: async ({ commit }, payload) => {
      return await to(api.insertCustomerStatus(payload));
    },
    UPDATE_STATUS: async ({ commit }, payload) => {
      let result = await to(api.updateCustomerStatus(payload));
      return result;
    },
  },
  mutations: {
    SET_STATUSES: (state, payload) => {
      state.list = payload;
    },
  },
};
