import types from "./types";
import api from "@/services/admin/api";
import to from "@/helpers/to";
export default {
  state: {
    auditMatrixConfigList: [],
  },
  actions: {
    GET_AUDIT_MATRIX_CONFIG: async ({ commit }) => {
      let err, result;
      [err, result] = await to(api.getAuditMatrixConfigs());
      if (result) {
        commit(types.SET_AUDIT_MATRIX_CONFIG, result.Data);
      }
    },
    UPDATE_AUDIT_MATRIX_CONFIG: async ({ commit }, payload) => {
      let result = await to(api.saveAuditMatrixConfigs(payload));
      return result;
    },
  },
  mutations: {
    SET_AUDIT_MATRIX_CONFIG: (state, payload) => {
      state.auditMatrixConfigList = payload;
    },
  },
};
