<template>
  <div
    class="col-md-12 newPanelWrapper noMargin des-mediaScreen"
    style="
      background-color: #ffffff;
      z-index: 1000;
      padding-left: 0;
      padding-right: 0;
    "
  >
    <div v-if="isLoading" class="loading">
      <div class="text-center" style="margin-top: 150px">
        <i class="spinner-grow text-info m-2"></i>
      </div>
    </div>
    <div>
      <div class="closeButton" v-on:click.prevent.stop="closePanel">
        <i class="fas fa-window-close"></i>
      </div>
    </div>
    <div class="col-md-12 pt-3">
      <label for="templateType" class="generalLabel">Packages: </label>
      <VueMultiselect
        id="dtPackages"
        :options="availablePackages"
        v-model="selectedRow.selectedPackages"
        @select="handlePackageSelection"
        @remove="handlePackageRemove"
        :multiple="true"
        :close-on-select="false"
        placeholder="Please Select"
        label="Name"
        track-by="Id"
      />
    </div>
    <div class="col-md-12 pt-3" v-if="!checkAuth(1774)">
      <label for="templateType" class="generalLabel">{{
        selectedRow != null &&
        selectedRow.selectedPackages != null &&
        selectedRow.selectedPackages.length > 0
          ? "Additional Template Types:"
          : "Docusign Template Types: *"
      }}</label>
      <VueMultiselect
        id="dtemplateType"
        v-model="selectedRow.selectedTemplates"
        :options="availableTemplates"
        :multiple="true"
        :close-on-select="false"
        placeholder="Please Select"
        label="Name"
        track-by="DocusignTemplateTypeId"
        @select="handleEmailSubjectSelection"
        :class="v$.selectedRow.selectedTemplates.$error ? 'has-error' : ''"
        @blur="v$.selectedRow.selectedTemplates.$touch"
      />
    </div>
    <div class="col-md-12 pt-2">
      <label class="generalLabel">Email Subject: *</label>
      <input
        type="text"
        id="emailSubImp"
        autocomplete="nope"
        class="generalTextBox form-control"
        v-model="selectedRow.selectedEmailSubject"
        :class="v$.selectedRow.selectedEmailSubject.$error ? 'has-error' : ''"
        @blur="v$.selectedRow.selectedEmailSubject.$touch"
      />
    </div>
    <div class="col-md-12 pt-2">
      <label class="generalLabel">Sending Option: *</label>
    </div>
    <div class="col-md-12 pl-2 form-check form-switch">
      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="radio"
          id="inlineRadio1"
          name="inlineRadioOptions"
          value="email"
          v-model="sendingOption"
        />
        <label class="form-check-label" for="inlineRadio1">Email</label>
      </div>
      <div class="form-check form-check-inline ml-4">
        <input
          class="form-check-input"
          type="radio"
          id="inlineRadio2"
          name="inlineRadioOptions"
          value="sms"
          v-model="sendingOption"
        />
        <label class="form-check-label" for="inlineRadio2">SMS</label>
      </div>
      <div class="form-check form-check-inline ml-4">
        <input
          class="form-check-input"
          type="radio"
          id="inlineRadio3"
          name="inlineRadioOptions"
          value="both"
          v-model="sendingOption"
        />
        <label class="form-check-label" for="inlineRadio3">Both</label>
      </div>
    </div>

    <div class="col-md-12 docusignESendButton pt-2">
      <button
        id="docusignSendButton"
        class="btn btn-success"
        :disabled="v$.$invalid || saving"
        @click="handleSendSignature()"
      >
        Send
      </button>
    </div>
  </div>
</template>

<script>
import VueMultiselect from "vue-multiselect";
import useValidate from "@vuelidate/core";
import { required } from "vuelidate/lib/validators";
import types from "./types";
import utilitiesMixin from "@/mixins/utilitiesMixin";
export default {
  name: "DocusignEnvelopeSend",
  components: { VueMultiselect },
  mixins: [utilitiesMixin],
  props: {
    customerId: {
      type: Number,
      default: () => 0,
    },
    officeId: {
      type: Number,
      default: () => 0,
    },
    customerAvailableTemplates: {
      type: Array,
      default: () => [],
    },
    isDpg: {
      type: Boolean,
      default: false,
    },
  },
  unmounted() {
    // this.emptyTheObjectsOnUnmount();
  },
  mounted() {
    this.saving = false;
    this.selectedRow = {
      selectedPackages: [],
      selectedTemplates: [],
      selectedEmailSubject: "",
    };

    this.availablePackages = this.customerAvailableTemplates.filter(
      (x) => x.Id > 0
    );

    for (let item of this.customerAvailableTemplates) {
      for (let template of item.Templates) {
        this.addToAllTemplates(template);
      }
    }
  },

  data() {
    return {
      sendingOption: "",
      availablePackages: [],
      availableTemplates: [],
      packageTemplates: [],
      v$: useValidate(),
      selectedRow: {
        selectedPackages: [],
        selectedTemplates: [],
        selectedEmailSubject: "",
      },
      saving: false,
      isLoading: false,
    };
  },
  methods: {
    emptyTheObjectsOnUnmount() {
      this.selectedRow = null;
      this.saving = null;
      this.isLoading = null;
    },
    closePanel() {
      this.$emit("closeEnvelopeSendPopUp");
    },

    handleEmailSubjectSelection(e) {
      if (
        e &&
        e.EmailSubject &&
        this.selectedRow.selectedPackages.length == 0
      ) {
        this.selectedRow.selectedEmailSubject = e.EmailSubject;
      }
    },
    handlePackageSelection(val) {
      if (typeof val != "undefined" && val && val.Templates) {
        if (val.EmailSubject) {
          this.selectedRow.selectedEmailSubject = val.EmailSubject;
        }
        val.Templates.forEach((item) => {
          this.addToPackageTemplates(item);
          this.removeFromAllTemplates(item);
          this.removeFromSelectedTemplates(item);
        });
      }
    },
    handlePackageRemove(val) {
      if (typeof val != "undefined" && val && val.Templates) {
        val.Templates.forEach((item) => {
          this.addToAllTemplates(item);
          this.removeFromPackageTemplates(item);
        });
      }
    },
    removeFromPackageTemplates(template) {
      let indx = this.packageTemplates.findIndex(
        (x) => x.DocusignTemplateTypeId == template.DocusignTemplateTypeId
      );
      if (indx > -1) {
        this.packageTemplates.splice(indx, 1);
      }
    },
    removeFromSelectedTemplates(template) {
      let indx = this.selectedRow.selectedTemplates.findIndex(
        (x) => x.DocusignTemplateTypeId == template.DocusignTemplateTypeId
      );
      if (indx > -1) {
        this.selectedRow.selectedTemplates.splice(indx, 1);
      }
    },
    addToPackageTemplates(template) {
      let indx = this.packageTemplates.findIndex(
        (x) => x.DocusignTemplateTypeId == template.DocusignTemplateTypeId
      );
      if (indx == -1) {
        this.packageTemplates.push(template);
      }
    },
    addToAllTemplates(template) {
      let indx = this.availableTemplates.findIndex(
        (x) => x.DocusignTemplateTypeId == template.DocusignTemplateTypeId
      );
      if (indx == -1) {
        this.availableTemplates.push(template);
      }
    },
    removeFromAllTemplates(template) {
      let indx = this.availableTemplates.findIndex(
        (x) => x.DocusignTemplateTypeId == template.DocusignTemplateTypeId
      );
      if (indx > -1) {
        this.availableTemplates.splice(indx, 1);
      }
    },

    async handleSendSignature() {
      if (this.sendingOption == "") {
        this.$swal("Warning!", "Sending option is required.", "warning");
        return;
      }

      this.saving = true;
      this.isLoading = true;
      let gohead = true;
      if (this.isDpg != true) {
        let err, result;
        let data4PreValidation = {
          CustomerId: this.customerId,
          OfficeId: this.officeId,
        };

        [err, result] = await this.$store.dispatch(
          types.GET_DOCUSIGN_PREVALIDATE,
          data4PreValidation
        );

        if (!result) {
          let errMsg = this.getApiErrorMessage(err);
          this.$swal("Error!", errMsg, "error");

          if (errMsg.length > 0) {
            await this.$swal({
              title: "Validation Error",
              html:
                errMsg +
                "<br/>Would you like to continue sending the document?",
              type: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes, Send Document!",
            }).then(async (dialog) => {
              if (dialog.value) {
                gohead = true;
              } else {
                gohead = false;
              }
            });
          }
        }
      }

      if (gohead) {
        this.selectedRow.selectedTemplates.forEach((item) =>
          this.addToPackageTemplates(item)
        );

        let dataToSend = {
          CustomerId: this.customerId,
          DocusignTemplateTypeId: this.packageTemplates.map(
            (x) => x.DocusignTemplateTypeId
          ),
          EmailSubject: this.selectedRow.selectedEmailSubject,
          SendOption: this.sendingOption,
        };
        let err2,result2;
        [err2, result2] = await this.$store.dispatch(
          types.SEND_DOCUSIGN_ENVELOPE,
          dataToSend
        );

        if (result2) {
          this.packageTemplates = [];
          this.$swal("Success!", result2.Message, "success");
          this.closePanel();
        } else {
          let errMsg = this.getApiErrorMessage(err2);
          this.$swal("Error!", errMsg, "error");
        }
      }

      this.isLoading = false;
      this.saving = false;
    },
  },
  validations() {
    return {
      selectedRow: {
        selectedEmailSubject: { required },
        selectedTemplates: {
          atLeastOneRole(selectedTemplates) {
            return this.selectedRow &&
              this.selectedRow.selectedPackages &&
              this.selectedRow.selectedPackages.length > 0
              ? true
              : selectedTemplates.length > 0;
          },
        },
      },
    };
  },
};
</script>

<style>
.docusignESendButton {
  display: flex;
  justify-content: flex-end;
}
.has-error {
  border: 2px solid red !important;
}

@media screen and (max-height: 290px) {
  .des-mediaScreen {
    max-height: 200px !important;
    overflow-y: scroll;
  }
}
.loading {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.6);
  z-index: 1000;
}
</style>
