<template>
  <div class="col-md-12">
    <ReportSummary
      :exportData="reportData"
      :reportName="'EnrollmentReport'"
      :recordCount="recordCount"
      @updateLoadingStatus="reportLoading = $event"
      @setPayload="setPayload"
      @clearReport="clearReport"
      @setPagination="setPagination"
      :loadingSpinner="loadingSpinner"
    />

    <div
      class="datagrid-table gcsTabsTableScroll report-wrapper"
      :style="'max-height: ' + reportsPanelHeight + 'px; overflow-y:auto;'"
    >
      <o-table
        :data="filteredEnrollmentReport"
        :paginated="isPaginated && filteredEnrollmentReport.length > 0"
        :per-page="perPage"
        :pagination-simple="isPaginationSimple"
        :pagination-position="paginationPosition"
        :sticky-header="true"
      >
        <o-table-column
          field="CustomerId"
          label="Customer Id"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="customerIdFilter"
                placeholder=""
                v-myMask="{
                  alias: 'numeric',
                  rightAlign: false,
                }"
              />
            </div>
          </template>

          <template v-slot="props">
            <span
              @click="
                viewCustomer(props.row.CustomerId, props.row.CustomerName)
              "
              :class="
                getTagReportHyperLinkClass(
                  props.row.CustomerId && props.row.CustomerName
                )
              "
            >
              <strong v-if="props.row.IsSummary"
                >{{ props.row.CustomerId }}
              </strong>
              <span v-if="!props.row.IsSummary">
                {{ addPrefixToCustomerId(props.row.CustomerId) }}
              </span>
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="CustomerName"
          label="Customer Name"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="customerNameFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.CustomerName }}
          </template>
        </o-table-column>

        <o-table-column field="Campaign" label="Campaign" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="campaignFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.Campaign }}
          </template>
        </o-table-column>

        <o-table-column
          field="CampaignCode"
          label="Reference Code"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CampaignCodeFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.CampaignCode }}
          </template>
        </o-table-column>

        <o-table-column field="AgeEnrollment" label="Age" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="ageEnrollmentFilter"
                placeholder=""
                style="width: 120px"
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.AgeEnrollment }}
          </template>
        </o-table-column>

        <o-table-column
          field="PhoneNumber"
          label="Phone Number"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="phoneNumberFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ tryFormatPhone(props.row.PhoneNumber) }}
          </template>
        </o-table-column>

        <o-table-column field="Email" label="Email" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="emailFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.Email }}
          </template>
        </o-table-column>

        <o-table-column field="Office" label="Office" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="officeFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.Office }}
          </template>
        </o-table-column>

        <o-table-column
          field="PolicyGroup"
          label="Policy Group"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="policyGroupFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.PolicyGroup }}
          </template>
        </o-table-column>

        <o-table-column field="Status" label="Status" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                style="width: 110px"
                type="text"
                class="datagrid-table creditorTable"
                v-model="statusFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.Status }}
          </template>
        </o-table-column>

        <o-table-column
          field="CreatedDate"
          label="Created Date"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                style="width: 100px"
                type="text"
                class="datagrid-table creditorTable"
                v-model="createdDateFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.CreatedDate }}
          </template>
        </o-table-column>
        <o-table-column
          field="SalesUser"
          label="Sales User"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="salesUserFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.SalesUser }}
          </template>
        </o-table-column>

        <o-table-column
          field="EnrolledDate"
          label="Enrolled Date"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                style="width: 100px"
                type="text"
                class="datagrid-table creditorTable"
                v-model="enrolledDateFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.EnrolledDate }}
          </template>
        </o-table-column>

        <o-table-column field="DropDate" label="Drop Date" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                style="width: 100px"
                type="text"
                class="datagrid-table creditorTable"
                v-model="dropDateFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.DropDate }}
          </template>
        </o-table-column>

        <o-table-column
          field="DraftAmount"
          label="Draft Amount"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                style="width: 100px"
                type="text"
                class="datagrid-table creditorTable"
                v-model="draftAmountFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.DraftAmount }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatMoney(props.row.DraftAmount) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column field="State" label="State" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="stateFilter"
                placeholder=""
                style="width: 50px"
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.State }}
          </template>
        </o-table-column>
        <o-table-column
          field="StartDate"
          label="1st Draft Date"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                style="width: 100px"
                type="text"
                class="datagrid-table creditorTable"
                v-model="startDateFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.StartDate }}
          </template>
        </o-table-column>

        <o-table-column
          field="FirstDraftPostedDate"
          label="1st Draft Posted Date"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                style="width: 100px"
                type="text"
                class="datagrid-table creditorTable"
                v-model="FirstDraftPostedDateFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ formatShortDate(props.row.FirstDraftPostedDate) }}
          </template>
        </o-table-column>

        <o-table-column
          field="ClearedDraftCount"
          label="Cleared Draft Count"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                style="width: 100px"
                type="text"
                class="datagrid-table creditorTable"
                v-model="ClearedDraftCountFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.ClearedDraftCount }}
          </template>
        </o-table-column>

        <o-table-column field="Term" label="Term" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="termFilter"
                placeholder=""
                style="width: 50px"
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.Term }}
          </template>
        </o-table-column>

        <o-table-column
          v-if="checkAuth(51004)"
          field="SN_TermOverride"
          label="Term Override"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="termOverrideFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.SN_TermOverride }}
          </template>
        </o-table-column>

        <o-table-column
          v-if="checkAuth(51004)"
          field="SN_ServiceFeeOverride"
          label="Svc Fee Override"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="svcFeeOverrideFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.SN_ServiceFeeOverride }}
          </template>
        </o-table-column>

        <o-table-column
          v-if="checkAuth(51004)"
          field="SN_FeesOverride"
          label="Fees Override"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="SN_FeesOverrideFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.SN_FeesOverride }}
          </template>
        </o-table-column>

        <o-table-column
          field="GlobalBalance"
          label="Global Balance"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="globalBalanceFilter"
                placeholder=""
                style="width: 100px"
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.GlobalBalance }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatMoney(props.row.GlobalBalance) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="DebtAmount"
          label="Debt Amount"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="debtAmountFilter"
                placeholder=""
                style="width: 100px"
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.DebtAmount }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatMoney(props.row.DebtAmount) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="FeePercentage"
          label="Settlement Fee %"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="FeePercentageFilter"
                placeholder=""
                style="width: 100px"
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.FeePercentage }}
          </template>
        </o-table-column>

        <o-table-column
          field=""
          label="View Customer"
          position="centered"
          v-slot="props"
        >
          <button
            @click="viewCustomer(props.row.CustomerId, props.row.CustomerName)"
            class="btn btn-info waves-effect waves-light ml-3"
            v-if="!props.row.IsSummary"
          >
            <i class="far fa-address-card"></i>
          </button>
        </o-table-column>
      </o-table>
      <section
        v-if="filteredEnrollmentReport.length == 0"
        class="section"
        style="text-align: center"
      >
        <div class="content has-text-grey has-text-centered mb-4">
          <p class="noMargin">
            <i
              style="font-size: 24px; color: #939393"
              class="ri-question-line"
            ></i>
          </p>
          <p class="noMargin">Nothing here.</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import ReportSummary from "./ReportSummary.vue";
import utilitiesMixin from "@/mixins/utilitiesMixin";

export default {
  name: "EnrollmentReport",
  components: { ReportSummary },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      customerPrefix: null,
      reportsPanelHeight: 300,
      loadingSpinner: false,
      isPaginationSimple: false,
      isPaginated: true,
      paginationPosition: "both",
      currentPage: 1,
      perPage: 200,

      recordCount: 0,
      reportLoading: false,
      reportData: [],

      enrollmentReportInitSummary: {
        CustomerName: "",
        PhoneNumber: null,
        Email: "",
        Status: "",
        CreatedDate: null,
        SalesUser: "",
        EnrolledDate: null,
        State: "",
        StartDate: null,
        FirstDraftPostedDate: null,
        Term: null,
        DebtAmount: "",
        Office: "",
        PolicyGroup: "",
        AgeEnrollment: null,
        GlobalBalance: "",
        SN_TermOverride: "",
        SN_ServiceFeeOverride: "",
        CustomerId: "",
        SN_FeesOverride: "",
        Campaign: "",
        DraftAmount: "",
        DropDate: null,
        CampaignCode: "",
        ClearedDraftCount: "",
        FeePercentage: "",
      },

      customerNameFilter: "",
      phoneNumberFilter: "",
      emailFilter: "",
      statusFilter: "",
      createdDateFilter: "",
      salesUserFilter: "",
      enrolledDateFilter: "",
      stateFilter: "",
      startDateFilter: "",
      FirstDraftPostedDateFilter: "",
      termFilter: "",
      debtAmountFilter: "",
      officeFilter: "",
      policyGroupFilter: "",
      ageEnrollmentFilter: "",
      globalBalanceFilter: "",
      termOverrideFilter: "",
      svcFeeOverrideFilter: "",
      customerIdFilter: "",
      SN_FeesOverrideFilter: "",
      campaignFilter: "",
      draftAmountFilter: "",
      dropDateFilter: "",
      CampaignCodeFilter: "",
      ClearedDraftCountFilter: "",
      FeePercentageFilter: "",
    };
  },
  computed: mapState({
    documentsEnrollmentList: (state) => state.reports.enrollmentList,

    filteredEnrollmentReport() {
      let rtn = [];

      let tmpList =
        this.documentsEnrollmentList.length > 0
          ? this.documentsEnrollmentList.filter((item) => {
              return (
                this.filterString(item.CustomerId, this.customerIdFilter) &&
                this.filterString(item.CampaignCode, this.CampaignCodeFilter) &&
                this.filterString(
                  item.ClearedDraftCount,
                  this.ClearedDraftCountFilter
                ) &&
                this.filterString(item.DraftAmount, this.draftAmountFilter) &&
                this.filterString(item.PolicyGroup, this.policyGroupFilter) &&
                this.filterString(item.Campaign, this.campaignFilter) &&
                this.filterString(item.CustomerName, this.customerNameFilter) &&
                this.filterNumeric(
                  item.AgeEnrollment,
                  this.ageEnrollmentFilter
                ) &&
                this.filterString(item.PhoneNumber, this.phoneNumberFilter) &&
                this.filterString(item.Email, this.emailFilter) &&
                this.filterString(item.Office, this.officeFilter) &&
                this.filterString(item.Status, this.statusFilter) &&
                new Date(item.CreatedDate)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .indexOf(this.createdDateFilter) > -1 &&
                this.filterString(item.SalesUser, this.salesUserFilter) &&
                new Date(item.EnrolledDate)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .indexOf(this.enrolledDateFilter) > -1 &&
                this.filterString(item.State, this.stateFilter) &&
                new Date(item.StartDate)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .indexOf(this.startDateFilter) > -1 &&
                new Date(item.FirstDraftPostedDate)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .indexOf(this.FirstDraftPostedDateFilter) > -1 &&
                this.filterString(item.Term, this.termFilter) &&
                this.filterString(item.DebtAmount, this.debtAmountFilter) &&
                this.filterString(
                  item.SN_FeesOverride,
                  this.SN_FeesOverrideFilter
                ) &&
                this.filterString(
                  item.GlobalBalance,
                  this.globalBalanceFilter
                ) &&
                this.filterString(
                  item.SN_TermOverride,
                  this.termOverrideFilter
                ) &&
                this.filterString(
                  item.SN_ServiceFeeOverride,
                  this.svcFeeOverrideFilter
                ) &&
                new Date(item.DropDate)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .indexOf(this.dropDateFilter) > -1 &&
                this.filterString(item.FeePercentage, this.FeePercentageFilter)
              );
            })
          : [];

      if (tmpList.length == 0) {
        this.reportData = [];
        return rtn;
      }
      let totalCustomer = tmpList.length.toString();

      let totalAmount = 0.0;
      for (let item of tmpList) {
        totalAmount += parseFloat(item.DebtAmount);
      }
      let totalAmountStr = totalAmount.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
      this.enrollmentReportInitSummary.DebtAmount = "Total: " + totalAmountStr;
      this.enrollmentReportInitSummary.CustomerId = "Total: " + totalCustomer;
      this.recordCount = tmpList.length;
      this.enrollmentReportInitSummary.IsSummary = true;

      rtn.push(...tmpList);
      rtn.push(this.enrollmentReportInitSummary);
      this.reportData = rtn;

      return rtn;
    },
  }),
  async mounted() {
    this.reportsPanelHeight = this.setReportGridHeight(
      window.innerHeight,
      document.getElementById("reportsSummary")
    );
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

    if (
      this.userInfo != null &&
      this.userInfo.customerIdPrefix != null &&
      this.userInfo.customerIdPrefix.length > 0
    ) {
      this.customerPrefix = this.userInfo.customerIdPrefix;
    }
    await this.sleep(1000);
    this.fixStickyHeaders();
  },
  methods: {
    addPrefixToCustomerId(customerId) {
      let rtn = customerId.toString();
      if (this.customerPrefix != null) {
        rtn = this.customerPrefix + rtn;
      }
      return rtn;
    },
    setPagination(data) {
      this.isPaginated = data;
    },
    async setPayload(value) {
      this.loadingSpinner = true;
      await this.$store.dispatch(types.GET_ENROLLMENT_REPORT, value);
      this.loadingSpinner = false;
      await this.sleep(500);
      this.fixStickyHeaders();
    },
    clearReport() {
      this.$store.dispatch(types.RESET_REPORTS);
      this.reportData = [];
    },
    async viewCustomer(id, name) {
      this.$Emitter.emit("viewCustomer", {
        customerId: id,
        customerName: name,
      });
    },
  },
};
</script>

<style scoped></style>
