import types from "./types";
import api from "@/services/admin/api";
import to from "@/helpers/to";
export default {
  state: {
    creditPullServices: [],
    blockedCreditTypeList: [],
    blockedCreditorsList: [],
  },
  actions: {
    GET_ALL_BLOCKED_CREDIT_TYPES: async ({ commit }) => {
      let err, result;
      [err, result] = await to(api.getAllBlockedCreditTypes());
      if (result) {
        commit(types.SET_ALL_BLOCKED_CREDIT_TYPES, result.Data);
      }
    },

    ADD_BLOCKED_CREDIT_TYPE: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.addBlockedCreditType(payload));
      return [err, result];
    },

    UPDATE_BLOCKED_CREDIT_TYPE: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.updateBlockedCreditType(payload));
      return [err, result];
    },

    DELETE_BLOCKED_CREDIT_TYPE: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.deleteBlockedCreditType(payload));
      return [err, result];
    },

    GET_ALL_BLOCKED_CREDITORS: async ({ commit }) => {
      let err, result;
      [err, result] = await to(api.getAllBlockedCreditors());
      if (result) {
        commit(types.SET_ALL_BLOCKED_CREDITORS, result.Data);
      }
    },

    ADD_BLOCKED_CREDITOR: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.addBlockedCreditor(payload));
      return [err, result];
    },

    UPDATE_BLOCKED_CREDITOR: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.updateBlockedCreditor(payload));
      return [err, result];
    },

    DELETE_BLOCKED_CREDITOR: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.deleteBlockedCreditor(payload));
      return [err, result];
    },

    GET_ARRAY_SETTINGS: async ({ commit }) => {
      let err, result;
      [err, result] = await to(api.getCompanyArraySettings());
      return [err, result];
    },
    UPDATE_ARRAY_SETTINGS: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.updateCompanyArraySettings(payload));
      return [err, result];
    },
    GET_ALL_CREDIT_PULL_SETTINGS: async ({ commit }) => {
      let err, result;
      [err, result] = await to(api.getAllCreditPullSettings());
      return [err, result];
    },
    INSERT_CREDIT_PULL_SETTINGS: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.insertCreditPullSettings(payload));
      return [err, result];
    },
    UPDATE_CREDIT_PULL_SETTINGS: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.updateCreditPullSettings(payload));
      return [err, result];
    },
    GET_ALL_CREDIT_SERVICES: async ({ commit }) => {
      let err, result;
      [err, result] = await to(api.getAllCreditServices());
      if (result) {
        commit(types.SET_ALL_CREDIT_SERVICES, result.Data);
      }
    },
    GET_SERVICE_COLUMNS: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.getServiceDefaultColumns(payload));
      return [err, result];
    },
  },
  mutations: {
    SET_ALL_CREDIT_SERVICES: (state, payload) => {
      state.creditPullServices = payload;
    },
    SET_ALL_BLOCKED_CREDIT_TYPES: (state, payload) => {
      state.blockedCreditTypeList = payload;
    },
    SET_ALL_BLOCKED_CREDITORS: (state, payload) => {
      state.blockedCreditorsList = payload;
    },
  },
};
