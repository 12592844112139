import types from "./types";
import api from "@/services/admin/api";
import to from "@/helpers/to";

export default {
  state: {
    list: [],
  },
  actions: {
    GET_DISPOSITIONSMngmt: async ({ commit }) => {
      let err, result;

      [err, result] = await to(api.getDispositionForManagement());
      if (result) {
        commit(types.SET_DISPOSITIONSMngmt, result.Data);
      }
    },
    INSERT_DISPOSITIONMngmt: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.insertDispositionOption(payload));

      return [err, result];
    },
    UPDATE_DISPOSITIONMngmt: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.updateDispositionOption(payload));
      return [err, result];
    },
  },
  mutations: {
    SET_DISPOSITIONSMngmt: (state, payload) => {
      state.list = payload;
    },
  },
};
