<template>
  <div
    class="col-md-12 newPanelWrapper noMargin dcp-mediaScreen"
    style="background-color: #ffffff; z-index: 1000"
  >
    <div
      style="display: flex; justify-content: space-between"
      v-on:click.prevent.stop="closePanel"
    >
      <label>Add Bulk Fees</label>
      <i class="fas fa-window-close closeButtonBulk"></i>
    </div>
  
    <div class="row">
      <div class="col-md-4">
        <label for="serviceFee" class="generalLabel">Start Date:</label>

        <DatePicker
          mode="date"
          :locale="'en-US'"
          v-model="feeItem.StartDate"
          :model-config="modelConfig"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <input
              id="DCP-feeDate"
              class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
              :value="inputValue"
              v-on="inputEvents"
              placeholder="Select Date"
            />
          </template>
        </DatePicker>
      </div>
      <div class="col-md-4">
        <label for="serviceFee" class="generalLabel">Fee Amount:</label>
        <input
          v-model="feeItem.Amount"
          class="form-control"
          v-myMask="{
            alias: 'currency',
            prefix: '$',
            min: 0.01,
            max: 99999.99,
            allowMinus: false,
            rightAlign: false,
          }"
          placeholder="$0.00"
        />
      </div>
      <div class="col-md-3">
        <label for="serviceFee" class="generalLabel">Fee Count:</label>
        <input
          v-model="feeItem.Count"
          class="form-control"
          v-myMask="{
            alias: 'numeric',
            min: 1,
            max: 120,
            digits: 0,
            allowMinus: false,
            rightAlign: false,
          }"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-xl-4 col-lg-6 col-md-12 col-12">
        <label class="generalLabel">Fee Type:</label>
        <select class="form-control" v-model="feeItem.GlobalFeeType">
          <option value="">Please Select</option>
          <option
            v-for="item in feeTypes"
            :value="item.FeeType"
            :key="item.FeeType"
            :class="
              isDpg &&
              !(
                item.FeeType == 'performance-fee' ||
                item.FeeType == 'ach-credit-fee'
              )
                ? 'readonlyInput'
                : ''
            "
            :disabled="
              isDpg &&
              !(
                item.FeeType == 'performance-fee' ||
                item.FeeType == 'ach-credit-fee'
              )
            "
          >
            {{ item.Description }}
          </option>
        </select>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-12 col-12"  v-if="isDpg || isSettlementFee(feeItem.GlobalFeeType) == true">
        <label v-if="creditorList.length > 0" class="generalLabel">Creditor: </label>
        <label v-else class="generalLabel text-danger mt-4">No settled creditor available.</label>
        <select v-if="creditorList.length > 0" v-model="feeItem.CreditorId" class="form-control">
          <option :value="0">Please Select</option>
          <option v-for="item in creditorList" :value="item.Id" :key="item.Id">
            {{ item.Name }}
          </option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <label class="generalLabel">Schedule Type:</label>

        <select
          style="width: 200px"
          class="form-control"
          v-model="feeItem.ScheduleType"
        >
          <option :value="null">Please Select</option>
          <option v-for="item in schedules" :value="item.Id" :key="item.Id">
            {{ item.Value }}
          </option>
        </select>
      </div>
      <div class="col-md-3" v-if="feeItem && feeItem.ScheduleType == 2">
        <label class="generalLabel">Day:</label>
        <CustomNumericInput
                        class="generalTextBox ml-1"
         
                        :value="twiceAmonthDay"
                        @updateValue="updateTwiceAMonthDay"
                        :minNumber="1"
                        :maxNumber="
                          getMaxDayofMonth(
                            feeItem.StartDate
                          )
                        "
                        placeholder="Day"
                      />
       
      </div>
    </div>
    <div class="row">
      <div class="col-md-11 text-right">
        <button @click="applyChanges()" class="btn btn-primary">
          Submit Fees
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import globalTypes from "@/store/types";
import formatMixin from "@/mixins/formatMixin";
import { DatePicker } from "v-calendar";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import deepCopy from "@/helpers/deepCopy";
import CustomNumericInput from "@/components/CustomNumericInput";
export default {
  name: "BulkAddFeePopup",
  components: { DatePicker,CustomNumericInput },
  props: {
    feeTypes: {
      Type: Array,
      default: [],
    },
    customerId: {
      Type: Number,
      default: 0,
    },
    isDpg: {
      Type: Boolean,
      default: false,
    },
  },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      feeItem: {
        StartDate: "",
        Amount: 0.01,
        Type: "",
        Count: 1,
        ScheduleType: 1,
        GlobalFeeType: "",
        CreditorId: 0,
      },
      twiceAmonthDay:0,
      addedList: [],
      creditorList: [],
      schedules: [
        { Id: 1, Value: "Monthly" },
        { Id: 2, Value: "Twice a Month" },
        { Id: 3, Value: "Bi-weekly" },
        { Id: 4, Value: "Weekly" },
    
      ],
      modelConfig: {
        type: "string",
        mask: "MM/DD/YYYY",
      },
    };
  },
  unmounted() {},
  async mounted() {

    await this.getSettledCreditors();
  },
  methods: {
    updateTwiceAMonthDay(value){
      this.twiceAmonthDay = value;
    },
    isSettlementFee(feeType)
    {
       let rtn = false;

       if(this.feeTypes.length > 0)
       {
         rtn = this.feeTypes.some(x=> x.FeeType == feeType && x.IsSettlementFee == true);
       }

       return rtn;
       

    },
    getCreditorName(id) {
      let rtn = "";
      if (this.creditorList && this.creditorList.length > 0) {
        let indx = this.creditorList.findIndex((x) => x.Id == id);
        if (indx > -1) {
          rtn = this.creditorList[indx].Name;
        }
      }
      return rtn;
    },
    async getSettledCreditors() {

        let err, result;

        [err, result] = await this.$store.dispatch(
          globalTypes.GET_CUSTOMER_SETTLED_CREDITORS,
          { CustomerId: this.customerId }
        );
        if (result && result.Data) {
          this.creditorList = deepCopy(result.Data);
        }
      
    },
    closePanel() {
      this.$emit("closeBulkAddFeePopup");
    },
    getFeeObject() {
      return {
        EffectiveDate: "",
        DebitId: 0,
        Amount: 0,
        Type: "",
        Memo: "",
        isEditing: true,
        isDeleting: false,
        NewDate: "",
        NewAmount: 0,
        GlobalFeeType: "",
        CreditorId: 0,
      };
    },
    applyChanges() {
      if (this.feeItem.StartDate == "") {
        this.$swal("Warning!", "Start Date is required", "warning");
        return;
      }

      let feeAmount = parseFloat(this.feeItem.Amount);
      if (feeAmount < 0.01) {
        this.$swal("Warning!", "Fee Amount is required", "warning");
        return;
      }

      let feeCount = parseInt(this.feeItem.Count);
      if (feeCount < 1) {
        this.$swal("Warning!", "Fee Count is required", "warning");
        return;
      }

      let feeType = this.feeItem.GlobalFeeType;
      if (feeType == "") {
        this.$swal("Warning!", "Fee Type is required", "warning");
        return;
      }

      if ((this.isDpg || this.isSettlementFee(this.feeItem.GlobalFeeType))  && this.feeItem.CreditorId == 0) {
        this.$swal("Warning!", "Creditor is required for this fee type.", "warning");
        return;
      }


      if (this.feeItem.ScheduleType == 2) {
      
        if(this.twiceAmonthDay == 0)
        {
        this.$swal("Warning!", "The 'Day' field is required for this schedule type.", "warning");
        return;
        }
        else if(this.twiceAmonthDay  == new Date(this.feeItem.StartDate).getDate())
        {
          this.$swal("Warning!", "The day for twice-a-month scheduling cannot be the same as the start date.", "warning");
            return;
        }
    }


      this.addedList = [];

      for (let i = 0; i < this.feeItem.Count; i++) {
        let fee = this.getFeeObject();
        if (i > 0) {
          if (this.feeItem.ScheduleType == 1) {
            fee.NewDate = this.formatShortDate(
              this.addMonthsFix(new Date(this.feeItem.StartDate), i)
            );
          }
          else if(this.feeItem.ScheduleType == 2)
          {
            let addMonths = Math.floor(i/2);
            let startDate = new Date(this.feeItem.StartDate);
            if(i%2== 0)
            {
              fee.NewDate = this.formatShortDate(
              this.addMonthsFix(startDate, addMonths)
              );
            }
            else
            {
        
              let startDay = startDate.getDate();
               let newDate = this.addMonthsFix(startDate, addMonths);
               let month = newDate.getMonth();
               let year = newDate.getFullYear();
               if(this.twiceAmonthDay > 0)
               {
                let maxDay = this.getMaxDayofMonth(new Date(year,month,1));
                let monthsToAdd = startDay > this.twiceAmonthDay ? 1 :0;

                fee.NewDate = new Date(year,month+monthsToAdd,this.twiceAmonthDay > maxDay ? maxDay : this.twiceAmonthDay);
               }

            }
          }
           else if (this.feeItem.ScheduleType == 3) {
            fee.NewDate = this.formatShortDate(
              this.addDays(new Date(this.feeItem.StartDate), i * 14)
            );
          } else if (this.feeItem.ScheduleType == 4) {
            fee.NewDate = this.formatShortDate(
              this.addDays(new Date(this.feeItem.StartDate), i * 7)
            );
          }
          fee.DebitId = -1 * i;
          fee.NewAmount = feeAmount;
          fee.GlobalFeeType = feeType;
          fee.CreditorId = this.feeItem.CreditorId;
          fee.CreditorName = this.getCreditorName(this.feeItem.CreditorId);
          this.addedList.unshift(fee);
        } else {
          fee.NewDate = this.formatShortDate(new Date(this.feeItem.StartDate));
          fee.NewAmount = feeAmount;
          fee.GlobalFeeType = feeType;
          fee.CreditorId = this.feeItem.CreditorId;
          fee.CreditorName = this.getCreditorName(this.feeItem.CreditorId);
          this.addedList.unshift(fee);
        }
      }

      if (this.addedList.length > 0) {
        this.$emit("addBulkFees", this.addedList);
      } else {
        this.$swal("Warning!", "No fee to add.", "warning");
      }
    },
  },
};
</script>

<style>
.closeButton {
  position: absolute;
  top: -5px;
  right: 15px;
  z-index: 1000;
  font-size: 30px;
  cursor: pointer;
}
.closeButton:hover {
  color: #ff3860;
}
@media screen and (max-height: 390px) {
  .dcp-mediaScreen {
    max-height: 200px !important;
    overflow-y: scroll;
  }
}
</style>
