export default {
  GET_ASSIGN_TYPES: "GET_ASSIGN_TYPES",
  SET_ASSIGN_TYPES: "SET_ASSIGN_TYPES",
  GET_ASSIGNABLE_USERS: "GET_ASSIGNABLE_USERS",
  SET_ASSIGNABLE_USERS: "SET_ASSIGNABLE_USERS",
  GET_ASSIGNABLE_CAMPAIGNS: "GET_ASSIGNABLE_CAMPAIGNS",
  SET_ASSIGNABLE_CAMPAIGNS: "SET_ASSIGNABLE_CAMPAIGNS",
  GET_ASSIGNABLE_OFFICES: "GET_ASSIGNABLE_OFFICES",
  SET_ASSIGNABLE_OFFICES: "SET_ASSIGNABLE_OFFICES",
  SET_DID_ASSIGN_USER: "SET_DID_ASSIGN_USER",
  GETDIDLIST: "GETDIDLIST",
  UPDATE_OFFICE_DID: "UPDATE_OFFICE_DID",
  UPDATE_COMPANY_DID: "UPDATE_COMPANY_DID",
  SET_DID_ASSIGN_TYPE: "SET_DID_ASSIGN_TYPE",
  SET_DID_ASSIGN_CAMPAIGN: "SET_DID_ASSIGN_CAMPAIGN",
};
