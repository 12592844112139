<template>
  <div
    class="col-lg-12 col-md-12 col-sm-12 newPanelWrapper noMargin"
    style="background-color: #ffffff"
  >
    <div class="form-group row">
      <div class="col-xl-3">
        <label class="generalLabel">Call Logs</label>
      </div>
      <div
        class="col-md-12"
        style="display: flex; flex-direction: column; align-items: center"
      >
        <div>
          <audio
            id="callLogPlayer"
            v-if="soundUrl != ''"
            autoplay="autoplay"
            crossorigin="anonymous"
            controls
          >
            <source :src="soundUrl" />
          </audio>
        </div>
        <div style="display: flex" v-if="soundUrl != ''">
          <label class="generalLabel mt-1">L</label>
          <input
            style="width: 200px"
            class="ml-2 mr-2"
            type="range"
            id="gainer"
            min="-1"
            max="1"
            v-model="gainRangeValue"
            step="0.01"
            @input="changeGain"
          />
          <label class="generalLabel mt-1">R</label>
        </div>
        <div
          style="
            width: auto;
            font-size: 16px;
            margin-top: 3px;
            cursor: pointer;
            padding: 0 3px;
          "
          class="ml-2"
        >
          <span
            v-if="soundUrl != ''"
            @click="resetGain()"
            class="badge badge-danger"
            >Reset</span
          >
        </div>
      </div>
    </div>

    <div
      class="datagrid-table"
      style="
        max-height: 487px;
        overflow-y: auto;
        overflow-x: auto;
        font-size: 12px !important;
      "
    >
      <section>
        <o-table
          :data="isEmpty ? [] : callLogs"
          :default-sort-direction="defaultSortDirection"
          :sort-icon="sortIcon"
          :sort-icon-size="sortIconSize"
          :striped="isStriped"
          :hoverable="isHoverable"
        >
          <o-table-column
            field="PhoneNumber"
            label="Phone Number"
            width="20%"
            sortable
            v-slot="props"
            >{{ formatPhone(props.row.PhoneNumber) }}</o-table-column
          >
          <o-table-column
            field="CallStartTime"
            label="Start Time"
            width="17%"
            sortable
            v-slot="props"
            >{{ formatGridDate(props.row.CallStartTime) }}</o-table-column
          >
          <o-table-column
            field="Type"
            label="Type"
            width="11%"
            v-slot="props"
            sortable
          >
            {{ props.row.Type }}
          </o-table-column>
          <o-table-column
            field="RecordingDuration"
            label="Duration"
            width="12%"
            sortable
            v-slot="props"
          >
            {{ formatSecond(props.row.RecordingDuration) }}
            &nbsp;
            <i
              @click="playRecord(props.row.RecordingUrl)"
              class="fas fa-play"
              style="color: green; cursor: pointer"
            ></i>
          </o-table-column>
          <o-table-column
            field="User"
            label="User"
            centered
            width="20%"
            sortable
            v-slot="props"
            >{{ props.row.User }}</o-table-column
          >
          <o-table-column
            field="CallerId"
            label="CallerId"
            centered
            width="20%"
            sortable
            v-slot="props"
            >{{ formatPhone(props.row.CallerId) }}</o-table-column
          >
        </o-table>
        <section
          v-if="callLogs.length == 0"
          class="section"
          style="text-align: center"
        >
          <div class="content has-text-grey has-text-centered mb-4">
            <p class="noMargin">
              <i
                style="font-size: 24px; color: #939393"
                class="ri-question-line"
              ></i>
            </p>
            <p class="noMargin">Nothing here.</p>
          </div>
        </section>
      </section>
    </div>
  </div>
</template>
<script>
import formatMixin from "@/mixins/formatMixin";
import types from "./types";
import utilitiesMixin from "@/mixins/utilitiesMixin";
var context = null;
var gainL = null;
var gainR = null;
export default {
  name: "CallLogs",
  props: {
    phones: {
      type: Array,
      default: () => [],
    },
    tabClicked: {
      Type: Boolean,
      Default: false,
    },
  },
  watch: {
    tabClicked(newVal, oldVal) {
      if (newVal != oldVal && newVal) {
        this.watcherRefresh();
      }
    },
  },
  async mounted() {
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if(userInfo && userInfo.officeId){
      this.officeId = userInfo.officeId;
    }
    if(userInfo && userInfo.subOffices){
      this.subOfficeIds = userInfo.subOffices;
      if(this.subOfficeIds.length>0){
        this.subOfficeIds.push(this.officeId);
      }
    }
  },
  data() {
    return {
      callLogs: [],
      defaultSortDirection: "asc",
      sortIcon: "arrow-up",
      sortIconSize: "is-small",
      isStriped: true,
      isHoverable: true,
      isLoading: false,
      isEmpty: false,
      soundUrl: "",
      gainRangeValue: 0,
      subOfficeIds:[],
      officeId:0,
    };
  },
  methods: {
    async watcherRefresh() {
      if (this.checkAuth(2543)) {
        let payload = [];
        if (this.phones.length > 0) {
          let numbers = this.phones.map((x) => x.PhoneNumber).join(",");
          if (numbers.length > 1) {
            payload = numbers.split(",");
          } else {
            payload = numbers;
          }

          let err, result;
          [err, result] = await this.$store
            .dispatch(types.GET_CUSTOMER_CALL_LOGS, { PhoneNumbers: payload })
            .catch();

          if (result) {
            if (result.Data) {
              if(this.checkAuth(1454)){ //all offices
                this.callLogs = result.Data.sort((x) => x.CallStartTime);
              }
              else if (this.checkAuth(55011) && this.subOfficeIds.length>0){ //sub offices
                this.callLogs = result.Data.filter(
                        (x) =>
                                this.subOfficeIds.includes(x.OfficeId)
                ).sort((x) => x.CallStartTime);
              }
              else{ // single office
                this.callLogs = result.Data.filter((x) => x.OfficeId == this.officeId).sort((x) => x.CallStartTime);
              }

            }
          }
        }
      }
    },
    emptyTheObjectsOnUnmount() {
      this.callLogs = null;
      this.defaultSortDirection = null;
      this.sortIcon = null;
      this.sortIconSize = null;
      this.isStriped = null;
      this.isHoverable = null;
      this.isLoading = null;
      this.isEmpty = null;
      this.soundUrl = null;
    },
    changeGain(event) {
      let value = 0;
      if (event.target.value) {
        value = parseFloat(event.target.value).toFixed(2);

        if (value < 0) {
          //left;
          if (gainL.gain.value <= 1) gainL.gain.value = value * -1;
          else gainL.gain.value = 1;

          if (gainR.gain.value >= 0)
            gainR.gain.value = parseFloat(1 - value * -1).toFixed(2);
          else gainR.gain.value = 0;
        } else if (value == 0) {
          this.resetGain();
        } else {
          //right
          if (gainL.gain.value >= 0)
            gainL.gain.value = parseFloat(1 - value).toFixed(2);
          else gainL.gain.value = 0;

          if (gainR.gain.value <= 1) gainR.gain.value = value;
          else gainR.gain.value = 1;
        }
      }
    },
    resetGain() {
      this.gainRangeValue = 0;
      gainL.gain.value = 1;
      gainR.gain.value = 1;
    },
    initAudioPlayer() {
      var myAudio = document.getElementById("callLogPlayer");

      context = new AudioContext();
      var audioSource = context.createMediaElementSource(myAudio);

      var splitter = context.createChannelSplitter(2);
      var merger = context.createChannelMerger(2);
      merger.connect(context.destination);

      gainL = context.createGain();
      gainR = context.createGain();

      audioSource.connect(splitter);

      splitter.connect(gainL, 0, 0);
      splitter.connect(gainR, 1, 0);

      gainL.connect(merger, 0, 0);
      gainR.connect(merger, 0, 1);

      this.resetGain();
    },
    async playRecord(url) {
      this.soundUrl = "";
      await this.sleep(500);
      this.soundUrl = url;

      await this.sleep(500);
      this.initAudioPlayer();
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
  },
  mixins: [formatMixin, utilitiesMixin],
};
</script>
