import types from "./types";
import api from "@/services/admin/api";
import to from "@/helpers/to";
export default {
  state: {
    campaignList: [],
    agentList: [],
  },
  actions: {
    UPLOAD_CAMPAIGN_DATA: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.uploadCampaignData(payload));
      return [err, result];
    },
    GETCAMPAIGNS: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.getCampaigns());

      if (result) {
        commit(types.SETCAMPAIGNS, result.Data);
      }
    },
    POSTCAMPAIGN: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.insertCampaignV2(payload));

      return [err, result];
    },
    PUTCAMPAIGN: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.updateCampaignV2(payload));

      return [err, result];
    },
    CAMPAIGN_All_COMPANY_DIDS: async ({ commit }) => {
      let err, result;
      [err, result] = await to(api.getCompanyDids(0));
      if (result) {
        return result;
      }
    },
    CAMPAIGN_GET_AGENTS: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.getCampaignAgents());

      if (result) {
        commit(types.CAMPAIGN_SET_AGENTS, result.Data);
      }
    },
  },
  mutations: {
    SETCAMPAIGNS: (state, payload) => {
      state.campaignList = payload;
    },
    CAMPAIGN_SET_AGENTS: (state, payload) => {
      state.agentList = payload;
    },
  },
};
