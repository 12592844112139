<template>
  <div v-if="isDeclinePopupOpen" class="backgroundLock">
    <div
      style="position: relative; margin: 10% auto"
      class="offset-xl-4 col-xl-3 offset-lg-3 col-lg-6 col-md-12 col-sm-12 col-xs-12"
    >
      <DeclineReasonPopup
        :parentName="'extraPayment'"
        @closeDeclineReasonPopup="closeDeclineReasonPopup"
        @extraPaymentDecline="extraPaymentDecline"
      ></DeclineReasonPopup>
    </div>
  </div>
  <div class="datagrid-table gcsTabsTableScroll creditorTable">
    <o-table :data="amendmentsList">
      <o-table-column
        field="Customer.Name"
        label="Customer Name"
        sortable
        searchable
      >
        <template v-slot="props">
          {{ props.row.Customer.Name }}
        </template>
      </o-table-column>
      <o-table-column
        field="Request.RequestedBy.Name"
        label="Requested By"
        sortable
        searchable
      >
        <template v-slot="props">
          {{ props.row.Request.RequestedBy.Name }}
        </template>
      </o-table-column>
      <o-table-column field="Notes" label="Notes" sortable searchable>
        <template v-slot="props">
          <div
            v-html="MakeEmptyIfNull(props.row.Notes)"
            style="max-width: 400px"
          ></div>
        </template>
      </o-table-column>
      <o-table-column
        field="Request.RequestDate"
        label="Request Date"
        sortable
        searchable
      >
        <template v-slot="props">
          {{ formatGridDate(props.row.Request.RequestDate) }}
        </template>
      </o-table-column>

      <o-table-column field="ViewClients" label="View Clients">
        <template v-slot="props">
          <div class="text-center">
            <button
              class="btn btn-primary btn-xs"
              @click="
                openClient(props.row.Customer.Id, props.row.Customer.Name)
              "
            >
              <i class="ri-search-line"></i>
            </button>
          </div>
        </template>
      </o-table-column>
      <o-table-column field="ViewNotes" label="View Notes">
        <template v-slot="props">
          <div class="text-center">
            <button
              class="btn btn-xs"
              style="background-color: #f7b84b"
              @click="openNotes(props.row.Customer.Id)"
            >
              <i class="ri-file-edit-line"></i>
            </button>
          </div>
        </template>
      </o-table-column>

      <o-table-column field="Approve" label="Approve">
        <template v-slot="props">
          <div class="text-center">
            <button
              class="btn btn-info btn-xs"
              @click="handleApprove(props.row.Id)"
              :disabled="isApproveLoading"
            >
              <i v-if="isApproveLoading == false" class="ri-check-line"></i>
              <i
                v-if="isApproveLoading"
                class="spinner-border spinner-border-sm text-light"
                style="margin-bottom: 3px; margin-left: 1px"
              ></i>
            </button>
          </div>
        </template>
      </o-table-column>

      <o-table-column field="Decline" label="Decline">
        <template v-slot="props">
          <div class="text-center">
            <button
              class="btn btn-danger btn-xs"
              @click="handleDecline(props.row.Id)"
              :disabled="isLoading"
            >
              <i class="ri-close-line"></i>
            </button>
          </div>
        </template>
      </o-table-column>
    </o-table>
  </div>
</template>

<script>
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import types from "./types";
import { mapState } from "vuex";
import DeclineReasonPopup from "./DeclineReasonPopup";
export default {
  name: "Amendment-a",
  mixins: [formatMixin, utilitiesMixin],
  components: { DeclineReasonPopup },
  props: {
    amendmentsList: {
      Type: Array,
      default: () => [],
    },
  },
  emits: ["refreshData"],
  data() {
    return {
      isDeclinePopupOpen: false,
      selectedId: 0,
      isLoading: false,
      isApproveLoading: false,
    };
  },

  computed: mapState({}),
  mounted() {},
  methods: {
    openClient(customerId, customerName) {
      this.$Emitter.emit("viewCustomer", {
        customerId: customerId,
        customerName: customerName,
      });
    },
    openNotes(customerId) {
      this.$Emitter.emit("viewNotePanel", { CustomerId: customerId });
    },
    async handleApprove(dataId) {
      this.isApproveLoading = true;
      let err, result;
      let dataToSend = {
        Id: dataId,
      };

      [err, result] = await this.$store.dispatch(
        types.PC_APPROVE_AMENDMENTS,
        dataToSend
      );

      if (result) {
        this.refreshData();
        this.$swal("Success!", result.Message, "success");
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
      this.isApproveLoading = false;
    },
    async handleDecline(dataId) {
      this.selectedId = dataId;
      this.isDeclinePopupOpen = true;
    },
    refreshData() {
      this.$emit("refreshData");
    },
    closeDeclineReasonPopup() {
      this.isDeclinePopupOpen = false;
    },
    async extraPaymentDecline(reason, isRevert) {
      this.isLoading = true;
      let err, result;
      let dataToSend = {
        Id: this.selectedId,
        DeclineReason: reason,
      };

      [err, result] = await this.$store.dispatch(
        types.PC_DECLINE_AMENDMENTS,
        dataToSend
      );

      if (result) {
        this.refreshData();
        this.$swal("Success!", result.Message, "success");
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
      this.isLoading = false;
    },
    MakeEmptyIfNull(val) {
      let rtn = "";
      if (val) {
        rtn = val;
      }
      return rtn;
    },
  },
};
</script>

<style scoped></style>
