<template>
  <div>
    <div class="col-md-12">
      <div v-if="!Customer.Id" class="form-group row">
        <div class="col-md-12 newPanelWrapper noMargin">
          <h4>Please save customer to add co customers.</h4>
        </div>
      </div>
      <div v-if="Customer.Id" class="form-group row">
        <div
          v-for="customerItem in coProfiles"
          :key="customerItem.Id"
          class="col-xl-6 col-lg-6 col-md-12 col-sm-12 alignLeft"
          style="position: relative"
        >
          <div class="col-md-12 newPanelWrapper noMargin">
            <div class="row noMargin">
              <div class="col-md-9 col-xs-9 noPadding">
                <label
                  style="
                    width: 80%;
                    height: 30px;
                    font-size: 18px;
                    color: #2a404c;
                  "
                  class="generalLabel"
                  :class="customerItem.IsExcluded ? 'excludedLabel' : ''"
                  for="contactInformation"
                  >{{ customerItem.FirstName }}
                  {{ customerItem.LastName }}</label
                >
              </div>
              <div class="col-md-3 col-xs-3 noPadding">
                <div v-if="customerItem.Id > 0">
                  <input
                    autocomplete="off"
                    v-model="customerItem.IsExcluded"
                    @change="changeExclude(customerItem)"
                    type="checkbox"
                    class="checkbox-input generalCheckBox float-right ml-2"
                    style="
                      border-radius: 4px !important;
                      border: solid 1px #d1d1d1 !important;
                    "
                  />
                  <label class="generalLabel float-right" for="">Exclude</label>
                </div>
                <button
                  v-if="customerItem.Id == 0"
                  @click="discardProfile(customerItem.Id)"
                  class="btn btn-danger float-right"
                >
                  <span class="btn-label"
                    ><i class="mdi mdi-close-circle-outline"></i></span
                  >Discard
                </button>
              </div>
            </div>
            <form
              class="form"
              role="form"
              :class="customerItem.IsExcluded ? 'disabledForm' : ''"
              @click="() => $emit('update:Customer', CustomerCopy)"
            >
              <div class="form-group row">
                <div class="col-md-6 col-sm-12 noPadding">
                  <div class="col-md-12 noMargin">
                    <label
                      class="generalLabel"
                      :for="'CoFirstName' + customerItem.Id"
                      >First Name *</label
                    >
                  </div>
                  <div class="col-md-12 noMargin">
                    <input
                      maxlength="50"
                      autocomplete="off"
                      type="text"
                      :id="'CoFirstName' + customerItem.Id"
                      class="generalTextBox"
                      placeholder="First Name"
                      v-model="customerItem.FirstName"
                      :class="
                        validateRequired(customerItem.FirstName, '')
                          ? 'has-error'
                          : ''
                      "
                      @blur="
                        validateRequired(
                          customerItem.FirstName,
                          'CoFirstName' + customerItem.Id
                        )
                      "
                    />
                    <p
                      class="noPadding noMargin"
                      style="color: #ff7979"
                      v-if="
                        customerItem.FirstName &&
                        customerItem.FirstName.length == 50
                      "
                    >
                      Maximum 50 characters are allowed!
                    </p>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 noPadding">
                  <div class="col-md-12 noMargin">
                    <label
                      class="generalLabel"
                      :for="'CoLastName' + customerItem.Id"
                      >Last Name *</label
                    >
                  </div>
                  <div class="col-md-12 noMargin">
                    <input
                      maxlength="50"
                      autocomplete="off"
                      type="text"
                      :id="'CoLastName' + customerItem.Id"
                      class="generalTextBox"
                      placeholder="Last Name"
                      v-model="customerItem.LastName"
                      :class="
                        validateRequired(customerItem.LastName, '')
                          ? 'has-error'
                          : ''
                      "
                      @blur="
                        validateRequired(
                          customerItem.LastName,
                          'CoLastName' + customerItem.Id
                        )
                      "
                    />
                    <p
                      class="noPadding noMargin"
                      style="color: #ff7979"
                      v-if="
                        customerItem.LastName &&
                        customerItem.LastName.length == 50
                      "
                    >
                      Maximum 50 characters are allowed!
                    </p>
                  </div>
                </div>
              </div>
              <div class="form-group row mt-2">
                <div class="col-md-6 col-sm-12 noPadding">
                  <div class="col-md-12 noMargin">
                    <label
                      class="generalLabel"
                      :for="'CoDateB' + customerItem.Id"
                      >Date of Birth</label
                    >
                  </div>
                  <div class="col-md-12 noMargin">
                    <DatePicker
                      mode="date"
                      :locale="'en-US'"
                      v-model="customerItem.DateOfBirth"
                    >
                      <template v-slot="{ inputValue, inputEvents }">
                        <input
                          autocomplete="off"
                          :id="'CoDateB' + customerItem.Id"
                          class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                          :value="inputValue"
                          v-on="inputEvents"
                        />
                      </template>
                    </DatePicker>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 noPadding">
                  <div class="col-md-12 noMargin">
                    <label
                      class="generalLabel"
                      :for="'CoIncome' + customerItem.Id"
                      >Income</label
                    >
                  </div>
                  <div class="col-md-12 noMargin">
                    <input
                      autocomplete="off"
                      class="generalTextBox"
                      :id="'CoIncome' + customerItem.Id"
                      type="text"
                      v-model="customerItem.Income"
                      v-myMask="{
                        alias: 'currency',
                        prefix: '$',
                        rightAlign: false,
                      }"
                    />
                  </div>
                </div>
              </div>
              <div class="form-group row mt-2">
                <div class="col-md-6 col-sm-12 noPadding">
                  <div class="col-md-12 noMargin">
                    <label
                      class="generalLabel"
                      :for="'CoMiddleInitial' + customerItem.Id"
                      >Middle Initial</label
                    >
                  </div>
                  <div class="col-md-12 noMargin">
                    <input
                      autocomplete="off"
                      type="text"
                      :id="'CoMiddleInitial' + customerItem.Id"
                      class="generalTextBox"
                      placeholder=""
                      maxlength="1"
                      v-model="customerItem.MiddleInitial"
                    />
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 noPadding">
                  <div class="col-md-12 noMargin">
                    <label
                      class="generalLabel"
                      :for="'CoMothersMaiden' + customerItem.Id"
                      >Mothers Maiden Name</label
                    >
                  </div>
                  <div class="col-md-12 noMargin">
                    <input
                      autocomplete="off"
                      type="text"
                      :id="'CoMothersMaiden' + customerItem.Id"
                      class="generalTextBox"
                      placeholder=""
                      v-model="customerItem.MothersMaidenName"
                    />
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-6 col-sm-12 noPadding">
                  <div class="col-md-12 noMargin">
                    <label
                      class="generalLabel"
                      :for="'CoGender' + customerItem.Id"
                      >Gender</label
                    >
                  </div>
                  <div class="col-md-12 noMargin">
                    <select
                      v-model="customerItem.GenderId"
                      :id="'CoGender' + customerItem.Id"
                      class="generalSelectBox form-control"
                    >
                      <option :value="convertNull('0')">Select</option>
                      <option
                        v-for="item in genderList"
                        :key="item.Id"
                        :value="item.Id"
                      >
                        {{ item.Name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 noPadding">
                  <div class="col-md-12 noMargin">
                    <label class="generalLabel" :for="'CoSsn' + customerItem.Id"
                      >SSN:</label
                    >
                  </div>
                  <div class="col-md-12 noMargin">
                    <SsnController
                      :value="customerItem.SsnEncrypted"
                      @ssnChanged="ssnChanged($event, customerItem)"
                    />
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-xl-4 col-lg-8 col-md-6 col-sm-12"></div>
              </div>
              <div class="form-group row mt-2">
                <div class="col-md-12 noMargin">
                  <label class="generalLabel">Phones</label>
                </div>
                <div class="col-md-12 noMargin">
                  <PhoneGrid
                    :Phones="customerItem.CoCustomerPhones"
                    @updatePhones="updatePhones($event, customerItem)"
                    :customerId="Customer.Id"
                    :coCustomerId="customerItem.Id"
                    style="boder-radius: 8px"
                  ></PhoneGrid>
                </div>
              </div>
              <div class="form-group row mt-4">
                <div class="col-md-12 noMargin">
                  <label class="generalLabel">Emails</label>
                </div>
                <div class="col-md-12 noMargin">
                  <EmailGrid
                    :Emails="customerItem.CoCustomerEmails"
                    @updateEmails="updateEmails($event, customerItem)"
                    :customerId="Customer.Id"
                    :coCustomerId="customerItem.Id"
                  ></EmailGrid>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div
          v-if="isFormValid"
          class="col-xl-6 col-lg-6 col-md-12 col-sm-12 alignLeft"
          style="position: relative; z-index: 15"
        >
          <div
            class="col-md-12 newItemButton"
            style="font-size: 20vh; color: #1abc9c"
          >
            <i
              @click="addCoCustomer()"
              class="bx bxs-plus-circle"
              style="cursor: pointer"
            ></i>
          </div>
          <div class="col-md-12 newPanelWrapper newItem">
            <div class="row noMargin">
              <label
                style="font-size: 18px; color: #2a404c"
                class="generalLabel"
                for="contactInformation"
                >New Customer Profile</label
              >
            </div>
            <form
              class="form"
              role="form"
              :class="newProfileDisabled ? 'disabledForm' : ''"
            >
              <div class="form-group row">
                <div class="col-md-6 col-sm-12 noPadding">
                  <div class="col-md-12 noMargin">
                    <label class="generalLabel">First Name *</label>
                  </div>
                  <div class="col-md-12 noMargin">
                    <input
                      autocomplete="off"
                      type="text"
                      class="generalTextBox"
                      placeholder="First Name"
                    />
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 noPadding">
                  <div class="col-md-12 noMargin">
                    <label class="generalLabel">Last Name *</label>
                  </div>
                  <div class="col-md-12 noMargin">
                    <input
                      autocomplete="off"
                      type="text"
                      class="generalTextBox"
                      placeholder="Last Name"
                    />
                  </div>
                </div>
              </div>
              <div class="form-group row mt-2">
                <div class="col-md-6 col-sm-12 noPadding">
                  <div class="col-md-12 noMargin">
                    <label>Date of Birth</label>
                  </div>
                  <div class="col-md-12 noMargin">
                    <input
                      autocomplete="off"
                      type="text"
                      class="generalTextBox"
                      placeholder=""
                    />
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 noPadding">
                  <div class="col-md-12 noMargin">
                    <label class="generalLabel">Income</label>
                  </div>
                  <div class="col-md-12 noMargin">
                    <input
                      autocomplete="off"
                      class="generalTextBox"
                      type="text"
                      v-myMask="{
                        alias: 'currency',
                        prefix: '$',
                        rightAlign: false,
                      }"
                    />
                  </div>
                </div>
              </div>
              <div class="form-group row mt-2">
                <div class="col-md-4 col-sm-12 noPadding">
                  <div class="col-md-12 noMargin">
                    <label class="generalLabel">Middle Initial</label>
                  </div>
                  <div class="col-md-12 noMargin">
                    <input
                      autocomplete="off"
                      type="text"
                      class="generalTextBox"
                      placeholder=""
                      maxlength="1"
                    />
                  </div>
                </div>
                <div class="col-md-4 col-sm-12 noPadding">
                  <div class="col-md-12 noMargin">
                    <label class="generalLabel">Mothers Maiden Name</label>
                  </div>
                  <div class="col-md-12 noMargin">
                    <input
                      autocomplete="off"
                      type="text"
                      class="generalTextBox"
                      placeholder=""
                    />
                  </div>
                </div>
                <div class="col-md-4 col-sm-12 noPadding">
                  <div class="col-md-12 noMargin">
                    <label class="generalLabel">Gender</label>
                  </div>
                  <div class="col-md-12 noMargin">
                    <select class="generalSelectBox form-control">
                      <option value="0">Select</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-xl-4 col-lg-8 col-md-6 col-sm-12"></div>
              </div>
              <div class="form-group row mt-2">
                <div class="col-md-12 noMargin">
                  <label class="generalLabel">Phones</label>
                </div>
                <div class="col-md-12 noMargin">
                  <!-- <PhoneGrid
                    style="boder-radius: 8px"
                  ></PhoneGrid> -->
                </div>
              </div>
              <div class="form-group row mt-4">
                <div class="col-md-12 noMargin">
                  <label class="generalLabel">Emails</label>
                </div>
                <div class="col-md-12 noMargin">
                  <!-- <EmailGrid
                 
                    :Emails="customerItem.CoCustomerEmails"
                     @updateEmails="updateEmails($event,customerItem.CoCustomerEmails)"

                  ></EmailGrid> -->
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EmailGrid from "@/components/Grids/EmailGrid";
import PhoneGrid from "@/components/Grids/PhoneGrid";
import { DatePicker } from "v-calendar";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import formatMixin from "@/mixins/formatMixin";
import { mapState } from "vuex";
import globalTypes from "@/store/types";
import SsnController from "@/components/SsnController";
import _orderby from "lodash";
export default {
  components: {
    EmailGrid,
    PhoneGrid,
    DatePicker,
    SsnController,
  },
  props: {
    Customer: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    Customer(newVal, oldVal) {
      if (JSON.stringify(newVal) != JSON.stringify(oldVal)) {
        this.CustomerCopy = Object.assign({}, this.Customer);
      }
    },
  },
  mixins: [utilitiesMixin, formatMixin],
  computed: mapState({
    coProfiles() {
      return _orderby.orderBy(
        this.CustomerCopy.CoCustomerProfiles,
        "IsExcluded",
        "asc"
      );
    },
    genderList: (state) => state.globals.genders,
    isFormValid() {
      return this.CustomerCopy.CoCustomerProfiles.findIndex((x) => x.Id == 0) >
        -1
        ? false
        : this.formErrors
        ? this.formErrors.length > 0
          ? false
          : true
        : true;
    },
  }),
  unmounted() {
    // this.emptyTheObjectsOnUnmount();
  },
  async mounted() {
    await this.$store.dispatch(globalTypes.GET_GENDERS);
  },
  data() {
    return {
      CustomerCopy: Object.assign({}, this.Customer),
      defaultProfile: {
        Id: 0,
        FirstName: "",
        LastName: "",
        MiddleInitial: "",
        GenderId: null,
        MothersMaidenName: "",
        Income: null,
        DateOfBirth: "",
        CustomerPhones: [],
        CustomerEmails: [],
      },
      datepickerConfig: {
        start: this.addYearsToDate(new Date(), -100),
        end: this.addYearsToDate(new Date(), -18),
      },
      formErrors: [],
      newProfileDisabled: true,
    };
  },
  methods: {
    emptyTheObjectsOnUnmount() {
      this.CustomerCopy = null;
      this.defaultProfile = null;
      this.datepickerConfig = null;
      this.formErrors = null;
      this.newProfileDisabled = null;
    },
    addCoCustomer() {
      this.CustomerCopy.CoCustomerProfiles.unshift(
        Object.assign({}, this.defaultProfile)
      );
      this.newProfileDisabled = false;
      window.scrollTo(0, 0);
    },
    validateRequired(value, item) {
      let rtn = false;
      if (value.trim().length == 0) {
        rtn = true;
        if (item != "") {
          this.formErrors.push({ Id: item });
        }
      } else {
        if (item != "") {
          let indx = this.formErrors.findIndex((x) => x.Id == item);
          if (indx > -1) this.formErrors.splice(indx, 1);
        }
      }

      return rtn;
    },
    updateEmails(value, item) {
      let indx = this.CustomerCopy.CoCustomerProfiles.findIndex(
        (x) => x.Id == item.Id
      );
      if (indx > -1)
        this.CustomerCopy.CoCustomerProfiles[indx].CoCustomerEmails = value;
    },
    updatePhones(value, item) {
      let indx = this.CustomerCopy.CoCustomerProfiles.findIndex(
        (x) => x.Id == item.Id
      );
      if (indx > -1)
        this.CustomerCopy.CoCustomerProfiles[indx].CoCustomerPhones = value;
    },
    ssnChanged(value, item) {
      let indx = this.CustomerCopy.CoCustomerProfiles.findIndex(
        (x) => x.Id == item.Id
      );
      if (indx > -1) {
        this.CustomerCopy.CoCustomerProfiles[indx].SsnEncrypted = value;
      }
    },
    async changeExclude(item) {
      if (item.IsExcluded) {
        await this.$swal({
          title: "Confirm",
          text: "Are you sure to exclude this profile?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, exclude it!",
        }).then(async (dialog) => {
          if (!dialog.value) {
            item.IsExcluded = false;
          }
        });
      }

      let indx = this.CustomerCopy.CoCustomerProfiles.findIndex(
        (x) => x.Id == item.Id
      );
      if (indx > -1) {
        this.CustomerCopy.CoCustomerProfiles[indx].IsExcluded = item.IsExcluded;
        this.$emit("update:Customer", this.CustomerCopy);
      }
    },

    async discardProfile(itemId) {
      await this.$swal({
        title: "Confirm",
        text: "Are you sure to discard this profile?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, discard it!",
      }).then(async (dialog) => {
        if (dialog.value) {
          let indx = this.CustomerCopy.CoCustomerProfiles.findIndex(
            (x) => x.Id == itemId
          );
          if (indx > -1) this.CustomerCopy.CoCustomerProfiles.splice(indx, 1);
        }
      });
    },
  },
};
</script>
<style lang="css" scoped>
.newItem {
  opacity: 0.1;
  z-index: 1010;
}
.newItemButton {
  position: absolute;
  width: 99%;
  height: 100%;
  margin-top: -1px;
  background: rgba(0, 0, 0, 0.1);
  text-align: center;
  z-index: 1011;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.disabledForm {
  opacity: 0.2;
  pointer-events: none;
}
.excludedLabel {
  text-decoration: line-through red;
}
</style>
