import types from "./types";
import api from "@/services/admin/api";
import to from "@/helpers/to";
export default {
  state: {
    customerTaglist: [],
  },
  actions: {
    DELETE_CUSTOMER_TAG: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.deleteTagManagement(payload));
      return [err, result];
    },

    UPDATE_CUSTOMER_TAG: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.updateTagManagement(payload));
      return [err, result];
    },

    CREATE_CUSTOMER_TAG: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.createTagManagement(payload));
      return [err, result];
    },

    GET_ALL_CUSTOMER_TAG: async ({ commit }) => {
      let err, result;
      [err, result] = await to(api.getAllTagManagement());
      if (result) {
        commit(types.SET_ALL_CUSTOMER_TAG, result.Data);
      }
    },
  },
  mutations: {
    SET_ALL_CUSTOMER_TAG: (state, payload) => {
      state.customerTaglist = payload;
    },
  },
};
