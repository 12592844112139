import types from "./types";
import api from "@/services/admin/api";
import to from "@/helpers/to";
export default {
  state: {
    didassignType: [],
  },
  actions: {
    GET_DIDASSIGN_TYPE_OCSM: async ({ commit }) => {
      let err, result;

      [err, result] = await to(api.getDidassignTypes());
      if (result) {
        commit(types.SET_DIDASSIGN_TYPE_OCSM, result.Data);
      }
    },
    UPDATE_OUTBOUND_CALL_METHOD_OCSM: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.updateOutboundCallMethod(payload));
      return [err, result];
    },
    GET_OUTBOUND_CALL_SETTINGS_OCSM: async ({ commit }) => {
      let err, result;
      [err, result] = await to(api.getCompanyCallSettings());
      return [err, result];
    },
  },
  mutations: {
    SET_DIDASSIGN_TYPE_OCSM: (state, payload) => {
      state.didassignType = payload;
    },
  },
};
