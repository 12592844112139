<template>
  <div class="col-md-12">
    <ReportSummary
      :exportData="reportData"
      :reportName="'EnrollmentStatusChangeReport'"
      :recordCount="recordCount"
      @updateLoadingStatus="reportLoading = $event"
      @setPayload="setPayload"
      @clearReport="clearReport"
      @setPagination="setPagination"
      :loadingSpinner="loadingSpinner"
    />

    <div
      class="datagrid-table gcsTabsTableScroll report-wrapper"
      :style="'max-height: ' + reportsPanelHeight + 'px; overflow-y:auto;'"
    >
      <o-table
        :data="filteredEnrollmentStatusChangeReport"
        :paginated="
          isPaginated && filteredEnrollmentStatusChangeReport.length > 0
        "
        :per-page="perPage"
        :pagination-simple="isPaginationSimple"
        :pagination-position="paginationPosition"
        :sticky-header="true"
      >
        <o-table-column
          field="CustomerId"
          label="Customer Id"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CustomerIdFilter"
                placeholder=""
                v-myMask="{
                  alias: 'numeric',
                  rightAlign: false,
                }"
              />
            </div>
          </template>

          <template v-slot="props">
            <span @click="viewCustomer(props.row.CustomerId, props.row.FirstName, props.row.LastName)"
              :class="getTagReportHyperLinkClass(props.row.CustomerId && props.row.FirstName && props.row.LastName)">
              <strong v-if="props.row.IsSummary"
                >{{ props.row.CustomerId }}
              </strong>
              <span v-if="!props.row.IsSummary">
                {{ addPrefixToCustomerId(props.row.CustomerId) }}
              </span>
            </span>
          </template>
        </o-table-column>
        <o-table-column
          field="FirstName"
          label="First Name"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="firstNameFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.FirstName }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.FirstName }}
            </span>
          </template>
        </o-table-column>

        <o-table-column field="LastName" label="Last Name" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="lastNameFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.LastName }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.LastName }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="PhoneNumber"
          label="Phone Number"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="phoneNumberFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ tryFormatPhone(props.row.PhoneNumber) }}
          </template>
        </o-table-column>

        <o-table-column
          field="CustomerAge"
          label="Customer's Age"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="customerAgeFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.CustomerAge }}
          </template>
        </o-table-column>

        <o-table-column field="Date" label="Drop Date" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="dateFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.Date }}
          </template>
        </o-table-column>

        <o-table-column
          field="CurrentStatus"
          label="Current Status"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="currentStatusFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.CurrentStatus }}
          </template>
        </o-table-column>

        <o-table-column field="Office" label="Office" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="officeFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.Office }}
          </template>
        </o-table-column>

        <o-table-column
          field="PolicyGroup"
          label="Policy Group"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="policyGroupFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.PolicyGroup }}
          </template>
        </o-table-column>

        <o-table-column
          field="AssignedAgent"
          label="Assigned Agent"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="assignedAgentFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.AssignedAgent }}
          </template>
        </o-table-column>

        <o-table-column field="State" label="State" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="stateFilter"
                placeholder=""
                style="width: 100px"
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.State }}
          </template>
        </o-table-column>

        <o-table-column
          field="TotalClearedDrafts"
          label="Total Cleared Drafts"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="totalClearedDraftsFilter"
                placeholder=""
                style="width: 100px"
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.TotalClearedDrafts }}
          </template>
        </o-table-column>

        <o-table-column
          field="EnrollmentStartDate"
          label="Enrollment Start Date"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="enrollmentStartDateFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.EnrollmentStartDate }}
          </template>
        </o-table-column>

        <o-table-column
          field="MonthsInProgram"
          label="Months in Program"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="monthsInProgramFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.MonthsInProgram }}
          </template>
        </o-table-column>

        <o-table-column
          field="ProgramLength"
          label="Program Length"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="programLengthFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.ProgramLength }}
          </template>
        </o-table-column>

        <o-table-column field="DebtLoad" label="Debt Load" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="debtLoadFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary">
              {{ formatMoney(props.row.DebtLoad) }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatMoney(props.row.DebtLoad) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="StatusName"
          label="Status Name"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="statusNameFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.StatusName }}
          </template>
        </o-table-column>
        <o-table-column
          field=""
          label="View Customer"
          position="centered"
          v-slot="props"
        >
          <button
            @click="
              viewCustomer(
                props.row.CustomerId,
                props.row.FirstName,
                props.row.LastName
              )
            "
            class="btn btn-info waves-effect waves-light ml-3"
            v-if="!props.row.IsSummary"
          >
            <i class="far fa-address-card"></i>
          </button>
        </o-table-column>
      </o-table>
      <section
        v-if="filteredEnrollmentStatusChangeReport.length == 0"
        class="section"
        style="text-align: center"
      >
        <div class="content has-text-grey has-text-centered mb-4">
          <p class="noMargin">
            <i
              style="font-size: 24px; color: #939393"
              class="ri-question-line"
            ></i>
          </p>
          <p class="noMargin">Nothing here.</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import ReportSummary from "./ReportSummary.vue";
import utilitiesMixin from "@/mixins/utilitiesMixin";

export default {
  name: "EnrollmentStatusChangeReport",
  components: { ReportSummary },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      customerPrefix: null,
      reportsPanelHeight: 300,
      loadingSpinner: false,

      isPaginationSimple: false,
      isPaginated: true,
      paginationPosition: "both",
      currentPage: 1,
      perPage: 200,

      recordCount: 0,
      reportLoading: false,
      reportData: [],

      enrollmentStatusChangeReportInitSummary: {
        CustomerId: "",
        FirstName: "",
        LastName: "",
        PhoneNumber: null,
        CustomerAge: "",
        Date: "",
        Office: "",
        PolicyGroup: "",
        StatusName: "",
        MonthsInProgram: "",
        ProgramLength: "",
        DebtLoad: "",
        CurrentStatus: "",
        AssignedAgent: "",
        TotalClearedDrafts: "",
        State: "",
        EnrollmentStartDate: "",
      },
      CustomerIdFilter: "",
      firstNameFilter: "",
      lastNameFilter: "",
      phoneNumberFilter: "",
      customerAgeFilter: "",
      dateFilter: "",
      statusNameFilter: "",
      monthsInProgramFilter: "",
      programLengthFilter: "",
      debtLoadFilter: "",
      currentStatusFilter: "",
      assignedAgentFilter: "",
      totalClearedDraftsFilter: "",
      stateFilter: "",
      officeFilter: "",
      policyGroupFilter: "",
      enrollmentStartDateFilter: "",
    };
  },
  computed: mapState({
    documentsEnrollmentStatusChangeList: (state) =>
      state.reports.enrollmentStatusChangeList,

    filteredEnrollmentStatusChangeReport() {
      let rtn = [];

      let tmpList =
        this.documentsEnrollmentStatusChangeList.length > 0
          ? this.documentsEnrollmentStatusChangeList.filter((item) => {
              return (
                this.filterString(item.CustomerId, this.CustomerIdFilter) &&
                this.filterString(item.FirstName, this.firstNameFilter) &&
                this.filterString(item.LastName, this.lastNameFilter) &&
                this.filterString(item.PhoneNumber, this.phoneNumberFilter) &&
                this.filterNumeric(item.CustomerAge, this.customerAgeFilter) &&
                this.filterString(item.Date, this.dateFilter) &&
                this.filterString(item.StatusName, this.statusNameFilter) &&
                this.filterString(item.Office, this.officeFilter) &&
                this.filterString(item.PolicyGroup, this.policyGroupFilter) &&
                this.filterString(
                  item.CurrentStatus,
                  this.currentStatusFilter
                ) &&
                this.filterString(
                  item.AssignedAgent,
                  this.assignedAgentFilter
                ) &&
                this.filterString(item.State, this.stateFilter) &&
                this.filterNumeric(
                  item.MonthsInProgram,
                  this.monthsInProgramFilter
                ) &&
                this.filterNumeric(
                  item.ProgramLength,
                  this.programLengthFilter
                ) &&
                this.filterNumeric(
                  item.TotalClearedDrafts,
                  this.totalClearedDraftsFilter
                ) &&
                this.filterString(item.DebtLoad, this.debtLoadFilter) &&
                this.filterString(
                  item.EnrollmentStartDate,
                  this.enrollmentStartDateFilter
                )
              );
            })
          : [];

      if (tmpList.length == 0) {
        this.reportData = [];
        return rtn;
      }
      let totalCustomer = tmpList.length.toString();
      let totalDebtLoad = 0;

      for (let item of tmpList) {
        totalDebtLoad += item.DebtLoad;
      }

      this.enrollmentStatusChangeReportInitSummary.DebtLoad = totalDebtLoad;
      this.enrollmentStatusChangeReportInitSummary.CustomerId =
        "Total: " + totalCustomer;
      this.recordCount = tmpList.length;
      this.enrollmentStatusChangeReportInitSummary.IsSummary = true;

      rtn.push(...tmpList);
      rtn.push(this.enrollmentStatusChangeReportInitSummary);
      this.reportData = rtn;

      return rtn;
    },
  }),
  async mounted() {
    this.reportsPanelHeight = this.setReportGridHeight(
      window.innerHeight,
      document.getElementById("reportsSummary")
    );

    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (
      this.userInfo != null &&
      this.userInfo.customerIdPrefix != null &&
      this.userInfo.customerIdPrefix.length > 0
    ) {
      this.customerPrefix = this.userInfo.customerIdPrefix;
    }

    await this.sleep(1000);
    this.fixStickyHeaders();
  },
  methods: {
    addPrefixToCustomerId(customerId) {
      let rtn = customerId.toString();
      if (this.customerPrefix != null) {
        rtn = this.customerPrefix + rtn;
      }
      return rtn;
    },
    setPagination(data) {
      this.isPaginated = data;
    },
    async setPayload(value) {
      this.loadingSpinner = true;
      await this.$store.dispatch(
        types.GET_ENROLLMENT_STATUS_CHANGE_REPORT,
        value
      );
      this.loadingSpinner = false;
      await this.sleep(500);
      this.fixStickyHeaders();
    },
    clearReport() {
      this.$store.dispatch(types.RESET_REPORTS);
      this.reportData = [];
    },
    async viewCustomer(id, FirstName, LastName) {
      this.$Emitter.emit("viewCustomer", {
        customerId: id,
        customerName: FirstName + " " + LastName,
      });
    },
  },
};
</script>

<style scoped></style>
