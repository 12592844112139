import types from "./types";
import api from "@/services/admin/api";
import to from "@/helpers/to";
export default {
  state: {
    docusignPackagesList: [],
    templateTypeList: [],
  },
  actions: {
    GET_DOCUSIGN_PACKAGE: async ({ commit }) => {
      let err, result;
      [err, result] = await to(api.getDocusignPackageAll());
      if (result) {
        commit(types.SET_DOCUSIGN_PACKAGE, result.Data);
      }
    },
    GET_DOCUSIGN_TEMPLATE_TYPE: async ({ commit }) => {
      let err, result;
      [err, result] = await to(api.getDocusignTemplateType());
      if (result) {
        commit(types.SET_DOCUSIGN_TEMPLATE_TYPE, result.Data);
      }
    },
    INSERT_DOCUSIGN_PACKAGE: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.insertDocusignPackage(payload));
      return [err, result];
    },
    UPDATE_DOCUSIGN_PACKAGE: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.updateDocusignPackage(payload));
      return [err, result];
    },
    DELETE_DOCUSIGN_PACKAGE: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.deleteDocusignPackage(payload));
      return [err, result];
    },
  },
  mutations: {
    SET_DOCUSIGN_PACKAGE: (state, payload) => {
      state.docusignPackagesList = payload;
    },
    SET_DOCUSIGN_TEMPLATE_TYPE: (state, payload) => {
      state.templateTypeList = payload;
    },
  },
};
