<template>
  <div class="col-md-12">
    <ReportSummary
      :exportData="reportData"
      :reportName="'AgentPerformanceNewReport'"
      :recordCount="recordCount"
      @updateLoadingStatus="reportLoading = $event"
      @setPayload="setPayload"
      @clearReport="clearReport"
      @setPagination="setPagination"
      :loadingSpinner="loadingSpinner"
    />
    <div class="col-md-12 noPadding">
      <div
        style="display: inline-block"
        class="mr-1 mb-1"
        v-for="item in reportColumns"
        :key="item.Key"
      >
        <button
          v-if="item.Auth"
          @click="toggleColumnVisiblity(item)"
          class="btn btn-xs"
          :class="item.Open == true ? 'btn-success' : 'btn-secondary'"
        >
          {{ item.Label }}
        </button>
      </div>
      <div style="display: inline-block" class="mr-1 mb-1">
        <button
          @click="showAllColumns()"
          class="btn btn-xs"
          :class="allColumnsSelected == true ? 'btn-success' : 'btn-secondary'"
        >
          Select All
        </button>
      </div>
      <div style="display: inline-block" class="mr-1 mb-1">
        <button
          @click="hideAllColumns()"
          class="btn btn-xs"
          :class="
            allColumnsUnselected == true ? 'btn-success' : 'btn-secondary'
          "
        >
          Unselect All
        </button>
      </div>
    </div>
    <div
      class="datagrid-table gcsTabsTableScroll report-wrapper"
      :style="'max-height: ' + reportsPanelHeight + 'px; overflow-y:auto;'"
    >
      <o-table
        :data="filteredAgentPerformanceNewReport"
        :paginated="isPaginated && filteredAgentPerformanceNewReport.length > 0"
        :per-page="perPage"
        :pagination-simple="isPaginationSimple"
        :pagination-position="paginationPosition"
        :sticky-header="true"
      >
        <o-table-column
          v-if="showColumn('UserName')"
          field="UserName"
          label="User"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="UserNameFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.UserName }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.UserName }}
            </span>
          </template>
        </o-table-column>
        <o-table-column
          v-if="showColumn('OnlineTime')"
          field="OnlineTime"
          label="Logged Time"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="OnlineTimeFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ convertSeconds(props.row.OnlineTime) }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ convertSeconds(props.row.OnlineTime) }}
            </span>
          </template>
        </o-table-column>
        <o-table-column
          v-if="showColumn('AgentIdleTime')"
          field="AgentIdleTime"
          label="Idle Time"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="AgentIdleTimeFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ convertSeconds(props.row.AgentIdleTime) }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ convertSeconds(props.row.AgentIdleTime) }}
            </span>
          </template>
        </o-table-column>
        <o-table-column
          v-if="showColumn('AwayTime')"
          field="AwayTime"
          label="Away Time"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="AwayTimeFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ convertSeconds(props.row.AwayTime) }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ convertSeconds(props.row.AwayTime) }}
            </span>
          </template>
        </o-table-column>
        <o-table-column
          v-if="showColumn('OnlineTimePerc')"
          field="OnlineTimePerc"
          label="Online %"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="OnlineTimePercFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.OnlineTimePerc }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.OnlineTimePerc }}%
            </span>
          </template>
        </o-table-column>
        <o-table-column
          v-if="showColumn('AvgAnswerTime')"
          field="AvgAnswerTime"
          label="Avg Answer Time"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="AvgAnswerTimeFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.AvgAnswerTime }} secs
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.AvgAnswerTime.toFixed(2) }} secs
            </span>
          </template>
        </o-table-column>
        <o-table-column
          v-if="showColumn('TotalCalls')"
          field="TotalCalls"
          label="Total Calls"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="TotalCallsFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.TotalCalls }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.TotalCalls }}
            </span>
          </template>
        </o-table-column>
        <o-table-column
          v-if="showColumn('TotalTalkTime')"
          field="TotalTalkTime"
          label="Total Talk Time"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="TotalTalkTimeFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ convertSeconds(props.row.TotalTalkTime) }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ convertSeconds(props.row.TotalTalkTime) }}
            </span>
          </template>
        </o-table-column>
        <o-table-column
          v-if="showColumn('AvgTalkTime')"
          field="AvgTalkTime"
          label="Average Talk Time"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="AvgTalkTimeFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary">
              {{ props.row.AvgTalkTime }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ convertSeconds(props.row.AvgTalkTime) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          v-if="showColumn('InboundCalls')"
          field="InboundCalls"
          label="Inbound Calls"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="InboundCallsFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.InboundCalls }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.InboundCalls }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          v-if="showColumn('InboundCallPerc')"
          field="InboundCallPerc"
          label="Inbound %"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="InboundCallPercFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.InboundCallPerc }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.InboundCallPerc }}%
            </span>
          </template>
        </o-table-column>

        <o-table-column
          v-if="showColumn('OutboundCalls')"
          field="OutboundCalls"
          label="Outbound Calls"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="OutboundCallsFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.OutboundCalls }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.OutboundCalls }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          v-if="showColumn('OutboundCallsPerc')"
          field="OutboundCallsPerc"
          label="Oubound %"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="OutboundCallsPercFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.OutboundCallsPerc }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.OutboundCallsPerc }}%
            </span>
          </template>
        </o-table-column>

        <o-table-column
          v-if="showColumn('MissedCalls')"
          field="MissedCalls"
          label="Direct Missed Calls"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="MissedCallsFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.MissedCalls }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.MissedCalls }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          v-if="showColumn('MissedCallsPerc')"
          field="MissedCallsPerc"
          label="Missed %"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="MissedCallsPercFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.MissedCallsPerc }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.MissedCallsPerc }}%
            </span>
          </template>
        </o-table-column>

        <o-table-column
          v-if="showColumn('AvgTimeForMissedCalls')"
          field="AvgTimeForMissedCalls"
          label="Avg Time for Missed Calls"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="AvgTimeForMissedCallsFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.AvgTimeForMissedCalls }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.AvgTimeForMissedCalls }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          v-if="showColumn('VmDropCalls')"
          field="VmDropCalls"
          label="Total Vm Drops"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="VmDropCallsFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.VmDropCalls }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.VmDropCalls }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          v-if="showColumn('VmDropPerc')"
          field="VmDropPerc"
          label="VM drop %"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="VmDropPercFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.VmDropPerc }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.VmDropPerc }}%
            </span>
          </template>
        </o-table-column>

        <o-table-column
          v-if="showColumn('EnrolledSales')"
          field="EnrolledSales"
          label="Enrolled Sales"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="EnrolledSalesFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.EnrolledSales }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.EnrolledSales }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          v-if="showColumn('SummitedDealsCount') && checkAuth(81660)"
          field="SummitedDealsCount"
          label="Submitted Deals"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="SummitedDealsCountFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.SummitedDealsCount }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.SummitedDealsCount }}
            </span>
          </template>
        </o-table-column>
        <o-table-column
          v-if="showColumn('EnrolledDebt')"
          field="EnrolledDebt"
          label="Enrolled Debt Load"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="EnrolledDebtFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ formatMoney(props.row.EnrolledDebt) }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatMoney(props.row.EnrolledDebt) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          v-if="showColumn('DebtAmountAvg')"
          field="DebtAmountAvg"
          label="Average Debt Load"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="DebtAmountAvgFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ formatMoney(props.row.DebtAmountAvg) }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatMoney(props.row.DebtAmountAvg) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          v-if="showColumn('TotalCreditPulls')"
          field="TotalCreditPulls"
          label="Total Credit Pulls"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="TotalCreditPullsFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.TotalCreditPulls }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.TotalCreditPulls }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          v-if="showColumn('CancelThirty')"
          field="CancelThirty"
          label="Cancel % - 30 days"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CancelThirtyFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.CancelThirty }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.CancelThirty }}%
            </span>
          </template>
        </o-table-column>

        <o-table-column
          v-if="showColumn('CancelNinety')"
          field="CancelNinety"
          label="Cancel % - 90 days"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CancelNinetyFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.CancelNinety }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.CancelNinety }}%
            </span>
          </template>
        </o-table-column>

        <o-table-column
          v-if="showColumn('CancelOneEighty')"
          field="CancelOneEighty"
          label="Cancel % - 180 Days"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CancelOneEightyFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.CancelOneEighty }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.CancelOneEighty }}%
            </span>
          </template>
        </o-table-column>
      </o-table>
    </div>
  </div>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import ReportSummary from "./ReportSummary.vue";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import deepCopy from "@/helpers/deepCopy";
export default {
  name: "AgentPerformanceNewReport",
  components: { ReportSummary },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      reportsPanelHeight: 300,
      loadingSpinner: false,
      isPaginationSimple: false,
      isPaginated: true,
      paginationPosition: "both",
      currentPage: 1,
      perPage: 200,

      recordCount: 0,
      reportLoading: false,
      reportData: [],
      allColumnsSelected: false,
      allColumnsUnselected: false,
      reportColumns: [
        { Key: "UserName", Label: "User", Open: true, Auth: true },
        { Key: "OnlineTime", Label: "Logged Time", Open: true, Auth: true },
        { Key: "AgentIdleTime", Label: "Idle Time", Open: true, Auth: true },
        { Key: "AwayTime", Label: "Away Time", Open: true, Auth: true },
        { Key: "OnlineTimePerc", Label: "Online %", Open: true, Auth: true },
        {
          Key: "AvgAnswerTime",
          Label: "Avg Answer Time",
          Open: true,
          Auth: true,
        },
        { Key: "TotalCalls", Label: "Total Calls", Open: true, Auth: true },
        {
          Key: "TotalTalkTime",
          Label: "Total Talk Time",
          Open: true,
          Auth: true,
        },
        {
          Key: "AvgTalkTime",
          Label: "Average Talk Time",
          Open: true,
          Auth: true,
        },
        { Key: "InboundCalls", Label: "Inbound Calls", Open: true, Auth: true },
        { Key: "InboundCallPerc", Label: "Inbound %", Open: true, Auth: true },
        {
          Key: "OutboundCalls",
          Label: "OutBound Calls",
          Open: true,
          Auth: true,
        },
        {
          Key: "OutboundCallsPerc",
          Label: "OutBound %",
          Open: true,
          Auth: true,
        },
        {
          Key: "MissedCalls",
          Label: "Direct Missed Calls",
          Open: true,
          Auth: true,
        },
        { Key: "MissedCallsPerc", Label: "Missed %", Open: true, Auth: true },
        {
          Key: "AvgTimeForMissedCalls",
          Label: "Avg Time for Missed Calls",
          Open: true,
          Auth: true,
        },
        { Key: "VmDropCalls", Label: "Total Vm Drops", Open: true, Auth: true },
        { Key: "VmDropPerc", Label: "Vm drop %", Open: true, Auth: true },
        {
          Key: "EnrolledSales",
          Label: "Enrolled Sales",
          Open: true,
          Auth: true,
        },
        {
          Key: "SummitedDealsCount",
          Label: "Submitted Deals",
          Open: true,
          Auth: this.checkAuth(81660),
        },
        {
          Key: "EnrolledDebt",
          Label: "Enrolled Debt Load",
          Open: true,
          Auth: true,
        },
        {
          Key: "DebtAmountAvg",
          Label: "Average Debt Load",
          Open: true,
          Auth: true,
        },
        {
          Key: "TotalCreditPulls",
          Label: "Total Credit Pulls",
          Open: true,
          Auth: true,
        },
        {
          Key: "CancelThirty",
          Label: "Cancel % - 30 days",
          Open: true,
          Auth: true,
        },
        {
          Key: "CancelNinety",
          Label: "Cancel % - 90 days",
          Open: true,
          Auth: true,
        },
        {
          Key: "CancelOneEighty",
          Label: "Cancel % - 180 Days",
          Open: true,
          Auth: true,
        },
      ],

      AgentPerformanceNewReportInitSummary: {
        UserName: "",
        TotalCalls: "",
        TotalTalkTime: "",
        AvgTalkTime: "",
        AvgAnswerTime: "",
        InboundCalls: "",
        InboundCallPerc: "",
        OutboundCalls: "",
        OutboundCallsPerc: "",
        MissedCalls: "",
        MissedCallsPerc: "",
        VmDropCalls: "",
        VmDropPerc: "",
        EnrolledDebt: "",
        EnrolledSales: "",
        TotalCreditPulls: "",
        OnlineTime: "",
        OnlineTimePerc: "",
        AwayTime: "",
        AgentIdleTime: "",
        CancelThirty: "",
        CancelNinety: "",
        CancelOneEighty: "",
        AvgTimeforMissedCalls: "",
        SummitedDealsCount: "",
        DebtAmountAvg: "",
      },

      UserNameFilter: "",
      TotalCallsFilter: "",
      TotalTalkTimeFilter: "",
      AvgTalkTimeFilter: "",
      AvgAnswerTimeFilter: "",
      InboundCallsFilter: "",
      InboundCallPercFilter: "",
      OutboundCallsFilter: "",
      OutboundCallsPercFilter: "",
      MissedCallsFilter: "",
      MissedCallsPercFilter: "",
      VmDropCallsFilter: "",
      VmDropPercFilter: "",
      EnrolledDebtFilter: "",
      EnrolledSalesFilter: "",
      TotalCreditPullsFilter: "",
      OnlineTimeFilter: "",
      OnlineTimePercFilter: "",
      AwayTimeFilter: "",
      AgentIdleTimeFilter: "",
      CancelThirtyFilter: "",
      CancelNinetyFilter: "",
      CancelOneEightyFilter: "",
      AvgTimeForMissedCallsFilter: "",
      SummitedDealsCountFilter: "",
      DebtAmountAvgFilter: "",
    };
  },
  computed: mapState({
    agentPerformanceNewList: (state) => state.reports.agentPerformanceNewList,

    filteredAgentPerformanceNewReport() {
      let rtn = [];

      let tmpList =
        this.agentPerformanceNewList.length > 0
          ? this.agentPerformanceNewList.filter((item) => {
              return (
                this.filterString(item.TotalCalls, this.TotalCallsFilter) &&
                this.filterString(
                  item.DebtAmountAvg,
                  this.DebtAmountAvgFilter
                ) &&
                this.filterString(
                  item.SummitedDealsCount,
                  this.SummitedDealsCountFilter
                ) &&
                this.filterString(item.UserName, this.UserNameFilter) &&
                this.filterString(
                  item.TotalTalkTime,
                  this.TotalTalkTimeFilter
                ) &&
                this.filterString(item.AvgTalkTime, this.AvgTalkTimeFilter) &&
                this.filterString(
                  item.AvgAnswerTime,
                  this.AvgAnswerTimeFilter
                ) &&
                this.filterString(item.InboundCalls, this.InboundCallsFilter) &&
                this.filterString(
                  item.InboundCallPerc,
                  this.InboundCallPercFilter
                ) &&
                this.filterString(
                  item.OutboundCalls,
                  this.OutboundCallsFilter
                ) &&
                this.filterString(
                  item.OutboundCallsPerc,
                  this.OutboundCallsPercFilter
                ) &&
                this.filterString(item.MissedCalls, this.MissedCallsFilter) &&
                this.filterString(
                  item.MissedCallsPerc,
                  this.MissedCallsPercFilter
                ) &&
                this.filterString(item.VmDropCalls, this.VmDropCallsFilter) &&
                this.filterString(item.VmDropPerc, this.VmDropPercFilter) &&
                this.filterString(item.EnrolledDebt, this.EnrolledDebtFilter) &&
                this.filterString(
                  item.EnrolledSales,
                  this.EnrolledSalesFilter
                ) &&
                this.filterString(
                  item.TotalCreditPulls,
                  this.TotalCreditPullsFilter
                ) &&
                this.filterString(item.OnlineTime, this.OnlineTimeFilter) &&
                this.filterString(
                  item.OnlineTimePerc,
                  this.OnlineTimePercFilter
                ) &&
                this.filterString(item.AwayTime, this.AwayTimeFilter) &&
                this.filterString(
                  item.AgentIdleTime,
                  this.AgentIdleTimeFilter
                ) &&
                this.filterString(item.CancelThirty, this.CancelThirtyFilter) &&
                this.filterString(item.CancelNinety, this.CancelNinetyFilter) &&
                this.filterString(
                  item.CancelOneEighty,
                  this.CancelOneEightyFilter
                ) &&
                this.filterString(
                  item.AvgTimeforMissedCalls,
                  this.AvgTimeForMissedCallsFilter
                )
              );
            })
          : [];

      if (tmpList.length == 0) {
        this.reportData = [];
        return rtn;
      }
      let totalCustomer = tmpList.length.toString();
      this.AgentPerformanceNewReportInitSummary.UserName =
        "Total: " + totalCustomer;
      this.recordCount = tmpList.length;
      this.AgentPerformanceNewReportInitSummary.IsSummary = true;

      let totalInboundCall = 0.0;
      let totalOutBoundCall = 0.0;
      let totalMissedCall = 0.0;
      let totalVmDrop = 0.0;
      let totalCall = 0.0;
      let totalTalkTime = 0.0;
      let totalAvgAnswerTime = 0.0;
      let totalEnrolledDebt = 0.0;
      let totalEnrolledSales = 0.0;
      let totalCreditPulls = 0.0;
      let totalAwayTime = 0.0;
      let totalAgentIdleTime = 0.0;
      let totalAvgTimeforMissedCalls = 0.0;
      let totalLogged = 0.0;
      let totalcancel30 = 0.0;
      let totalcancel90 = 0.0;
      let totalcancel180 = 0.0;
      let timeCount = tmpList.length;
      let totalSummitedDealsCount = 0;
      let totalDebtAmountAvg = 0;

      for (let listItem of tmpList) {
        totalOutBoundCall += listItem.OutboundCalls;
        totalInboundCall += listItem.InboundCalls;
        totalMissedCall += listItem.MissedCalls;
        totalVmDrop += listItem.VmDropCalls;
        totalAvgTimeforMissedCalls +=
          listItem.AvgTimeForMissedCalls * listItem.MissedCalls;
        totalcancel30 += listItem.CancelThirty;
        totalcancel90 += listItem.CancelNinety;
        totalcancel180 += listItem.CancelOneEighty;
        totalCall += listItem.TotalCalls;
        totalTalkTime += listItem.TotalTalkTime;
        totalAvgAnswerTime += listItem.AvgAnswerTime * listItem.InboundCalls;
        totalEnrolledDebt += listItem.EnrolledDebt;
        totalEnrolledSales += listItem.EnrolledSales;
        totalCreditPulls += listItem.TotalCreditPulls;
        totalAwayTime += listItem.AwayTime;
        totalAgentIdleTime += listItem.AgentIdleTime;
        totalLogged += listItem.OnlineTime;

        totalSummitedDealsCount += listItem.SummitedDealsCount;
        totalDebtAmountAvg += listItem.DebtAmountAvg;
      }
      let totalOutBoundCalls = totalOutBoundCall;
      this.AgentPerformanceNewReportInitSummary.OutboundCalls =
        totalOutBoundCalls;

      let totalInboundCalls = totalInboundCall;
      this.AgentPerformanceNewReportInitSummary.InboundCalls =
        totalInboundCalls;

      let totalMissedCalls = totalMissedCall;
      this.AgentPerformanceNewReportInitSummary.MissedCalls = totalMissedCalls;

      let totalavgforMissedCalls = (
        totalAvgTimeforMissedCalls / totalMissedCalls
      ).toFixed(2);
      this.AgentPerformanceNewReportInitSummary.AvgTimeForMissedCalls =
        totalavgforMissedCalls;

      let total_calls = totalCall;
      this.AgentPerformanceNewReportInitSummary.TotalCalls = total_calls;

      let total_Talk_Time = totalTalkTime;
      this.AgentPerformanceNewReportInitSummary.TotalTalkTime = total_Talk_Time;

      let avgTalkTime = totalTalkTime / totalCall;
      if (totalTalkTime == 0 || totalCall == 0) avgTalkTime = 0;
      this.AgentPerformanceNewReportInitSummary.AvgTalkTime =
        this.convertSeconds(avgTalkTime);

      let avgAnswerPerc = (totalAvgAnswerTime / totalInboundCalls).toFixed(2);
      this.AgentPerformanceNewReportInitSummary.AvgAnswerTime = avgAnswerPerc;

      this.AgentPerformanceNewReportInitSummary.EnrolledDebt =
        totalEnrolledDebt.toString();
      this.AgentPerformanceNewReportInitSummary.EnrolledSales =
        totalEnrolledSales.toString();
      this.AgentPerformanceNewReportInitSummary.TotalCreditPulls =
        totalCreditPulls.toString();
      this.AgentPerformanceNewReportInitSummary.AwayTime =
        totalAwayTime.toString();
      this.AgentPerformanceNewReportInitSummary.AgentIdleTime =
        totalAgentIdleTime.toString();

      this.AgentPerformanceNewReportInitSummary.DebtAmountAvg =
        totalDebtAmountAvg.toString();
      this.AgentPerformanceNewReportInitSummary.SummitedDealsCount =
        totalSummitedDealsCount.toString();

      let totalInboundCallPerc =
        ((totalInboundCalls / totalCall) * 100).toFixed(2) + "%";
      if ((totalInboundCalls = 0 || totalCall == 0)) totalInboundCallPerc = 0;
      this.AgentPerformanceNewReportInitSummary.InboundCallPerc =
        totalInboundCallPerc;

      let totalOutboundCallPerc =
        ((totalOutBoundCalls / totalCall) * 100).toFixed(2) + "%";
      if ((totalOutBoundCall = 0 || totalCall == 0)) totalOutboundCallPerc = 0;
      this.AgentPerformanceNewReportInitSummary.OutboundCallsPerc =
        totalOutboundCallPerc;

      let missedCallPerc =
        ((totalMissedCalls / totalInboundCall) * 100).toFixed(2) + "%";
      if (totalMissedCalls == 0 || totalInboundCall == 0) missedCallPerc = "0%";
      this.AgentPerformanceNewReportInitSummary.MissedCallsPerc =
        missedCallPerc;

      let vmDropPerc = ((totalVmDrop / timeCount) * 100).toFixed(2) + "%";
      this.AgentPerformanceNewReportInitSummary.VmDropPerc = vmDropPerc;

      let onlineTimePerc =
        (((totalAgentIdleTime + totalTalkTime) / totalLogged) * 100).toFixed(
          2
        ) + "%";
      if (totalAgentIdleTime == 0 || totalTalkTime == 0 || totalLogged == 0)
        onlineTimePerc = "0%";
      this.AgentPerformanceNewReportInitSummary.OnlineTimePerc = onlineTimePerc;

      let cancelThirtyTotal = (totalcancel30 / timeCount).toFixed(2) + "%";
      this.AgentPerformanceNewReportInitSummary.CancelThirty =
        cancelThirtyTotal;

      let cancelNinetyTotal = (totalcancel90 / timeCount).toFixed(2) + "%";
      this.AgentPerformanceNewReportInitSummary.CancelNinety =
        cancelNinetyTotal;

      let cancelOneEightyTotal = (totalcancel180 / timeCount).toFixed(2) + "%";
      this.AgentPerformanceNewReportInitSummary.CancelOneEighty =
        cancelOneEightyTotal;

      let totalVmDrops = totalVmDrop.toString();
      this.AgentPerformanceNewReportInitSummary.VmDropCalls = totalVmDrops;

      let totalOnlineTimes = totalLogged.toString();
      this.AgentPerformanceNewReportInitSummary.OnlineTime = totalOnlineTimes;

      rtn.push(...tmpList);
      rtn.push(this.AgentPerformanceNewReportInitSummary);
      this.reportData = rtn;
      return rtn;
    },
  }),
  unmounted() {
    sessionStorage.setItem(
      "tableSettings-AgentPerformanceReport",
      JSON.stringify(this.reportColumns)
    );
  },
  async mounted() {
    this.reportsPanelHeight = this.setReportGridHeight(
      window.innerHeight,
      document.getElementById("reportsSummary")
    );

    await this.sleep(500);
    this.fixStickyHeaders();

    let tableSettings = sessionStorage.getItem(
      "tableSettings-AgentPerformanceReport"
    );
    if (tableSettings) {
      let columnSettings = JSON.parse(tableSettings);
      if (typeof columnSettings != "undefined" && columnSettings) {
        this.reportColumns = deepCopy(columnSettings);
      }
    }
  },
  methods: {
    toggleColumnVisiblity(item) {
      item.Open = !item.Open;
    },
    showColumn(field) {
      return this.reportColumns.some((x) => x.Key == field && x.Open == true);
    },
    async showAllColumns() {
      this.allColumnsSelected = true;
      this.allColumnsUnselected = false;
      this.reportColumns.forEach((x) => (x.Open = true));
      await this.sleep(500);
      this.fixStickyHeaders();
    },
    hideAllColumns() {
      this.allColumnsSelected = false;
      this.allColumnsUnselected = true;
      this.reportColumns.forEach((x) => (x.Open = false));
    },
    convertSeconds(seconds) {
      let hours = Math.floor(seconds / 3600);
      seconds %= 3600;
      let minutes = Math.floor(seconds / 60);
      seconds %= 60;
      let formattedSeconds = seconds.toFixed(0).toString().padStart(2, "0");
      return `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${formattedSeconds}`;
    },

    setPagination(data) {
      this.isPaginated = data;
    },
    async setPayload(value) {
      this.loadingSpinner = true;
      await this.$store.dispatch(types.GET_AGENT_PERFORMANCE_NEW_REPORT, value);
      this.loadingSpinner = false;
      await this.sleep(500);
      this.fixStickyHeaders();
    },
    clearReport() {
      this.$store.dispatch(types.RESET_REPORTS);
      this.reportData = [];
    },
  },
};
</script>

<style>
.stickyColumns {
  background-color: #fafafa;
  position: -webkit-sticky;
  position: sticky;
  right: 0;
}
.stickyColumnsLeft,
.stickyColumnsRight {
  background-color: #fafafa;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 1;
}
.stickyColumnsRight {
  left: 3%;
}
</style>
