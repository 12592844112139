<template>
  <div class="col-md-12">
    <ReportSummary
      :exportData="reportData"
      :reportName="'LeadTracEnrollmentsReport'"
      :recordCount="recordCount"
      @updateLoadingStatus="reportLoading = $event"
      @clearReport="clearReport"
      @setPagination="setPagination"
      @setPayload="setPayload"
      :loadingSpinner="loadingSpinner"
    />

    <div
      class="datagrid-table gcsTabsTableScroll report-wrapper"
      :style="'max-height: ' + reportsPanelHeight + 'px; overflow-y:auto;'"
    >
      <o-table
        :data="filteredLeadTracEnrollmentsReport"
        :paginated="isPaginated && filteredLeadTracEnrollmentsReport.length > 0"
        :per-page="perPage"
        :pagination-simple="isPaginationSimple"
        :pagination-position="paginationPosition"
        :sticky-header="true"
      >
        <o-table-column
          field="CustomerId"
          label="Customer Id"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CustomerIdFilter"
                placeholder=""
                v-myMask="{
                  alias: 'numeric',
                  rightAlign: false,
                }"
              />
            </div>
          </template>

          <template v-slot="props">
            <span @click="viewCustomer(props.row.CustomerId, props.row.CustomerName)"
              :class="getTagReportHyperLinkClass(props.row.CustomerId && props.row.CustomerName)">
              <strong v-if="props.row.IsSummary"
                >{{ props.row.CustomerId }}
              </strong>
              <span v-if="!props.row.IsSummary">
                {{ addPrefixToCustomerId(props.row.CustomerId) }}
              </span>
            </span>
          </template>
        </o-table-column>

        <o-table-column field="CustomerName" label="Name" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CustomerNameFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.CustomerName }}
          </template>
        </o-table-column>

        <o-table-column
          field="GlobalAccount"
          label="Global Account"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="GlobalAccountFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.GlobalAccount }}
          </template>
        </o-table-column>

        <o-table-column
          field="PolicyGroupId"
          label="Policy Group Id"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="PolicyGroupIdFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.PolicyGroupId }}
          </template>
        </o-table-column>

        <o-table-column
          field="LeadTracId"
          label="Lead Trac Id"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="LeadTracIdFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.LeadTracId }}
          </template>
        </o-table-column>

        <o-table-column
          field="LeadTracStatus"
          label="Lead Trac Status"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="LeadTracStatusFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.LeadTracStatus }}
          </template>
        </o-table-column>

        <o-table-column
          field=""
          label="View Customer"
          position="centered"
          v-slot="props"
        >
          <button
            @click="viewCustomer(props.row.CustomerId, props.row.CustomerName)"
            class="btn btn-info waves-effect waves-light ml-3"
            v-if="!props.row.IsSummary"
          >
            <i class="far fa-address-card"></i>
          </button>
        </o-table-column>

        <o-table-column
          field=""
          label="Connect"
          position="centered"
          v-slot="props"
        >
          <button
            @click="
              connectGlobalAccount(
                props.row.CustomerId,
                props.row.GlobalAccount,
                props.row.PolicyGroupId
              )
            "
            class="btn btn-info waves-effect waves-light ml-3"
            v-if="!props.row.IsSummary"
          >
            <i class="ri-shuffle-fill"></i>
          </button>
        </o-table-column>
      </o-table>
      <section
        v-if="filteredLeadTracEnrollmentsReport.length == 0"
        class="section"
        style="text-align: center"
      >
        <div class="content has-text-grey has-text-centered mb-4">
          <p class="noMargin">
            <i
              style="font-size: 24px; color: #939393"
              class="ri-question-line"
            ></i>
          </p>
          <p class="noMargin">Nothing here.</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import ReportSummary from "./ReportSummary.vue";
import utilitiesMixin from "@/mixins/utilitiesMixin";

export default {
  name: "LeadTracEnrollmentsReport",
  components: { ReportSummary },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      reportsPanelHeight: 300,
      loadingSpinner: false,
      isPaginationSimple: false,
      isPaginated: true,
      paginationPosition: "both",
      currentPage: 1,
      perPage: 200,
      recordCount: 0,
      reportLoading: false,
      reportData: [],

      leadTracEnrollmentsReportInitSummary: {
        CustomerId: "",
        CustomerName: "",
        LeadTracId: "",
        LeadTracStatus: "",
        GlobalAccount: "",
        PolicyGroupId: "",
      },

      CustomerIdFilter: "",
      CustomerNameFilter: "",
      LeadTracIdFilter: "",
      LeadTracStatusFilter: "",
      GlobalAccountFilter: "",
      PolicyGroupIdFilter: "",
    };
  },
  computed: mapState({
    leadTracEnrollmentList: (state) => state.reports.leadTracEnrollmentList,

    filteredLeadTracEnrollmentsReport() {
      let rtn = [];

      let tmpList =
        this.leadTracEnrollmentList.length > 0
          ? this.leadTracEnrollmentList.filter((item) => {
              return (
                this.filterString(item.CustomerId, this.CustomerIdFilter) &&
                this.filterString(item.CustomerName, this.CustomerNameFilter) &&
                this.filterNumeric(
                  item.PolicyGroupId,
                  this.PolicyGroupIdFilter
                ) &&
                this.filterString(item.LeadTracId, this.LeadTracIdFilter) &&
                this.filterString(
                  item.LeadTracStatus,
                  this.LeadTracStatusFilter
                ) &&
                this.filterString(item.GlobalAccount, this.GlobalAccountFilter)
              );
            })
          : [];

      if (tmpList.length == 0) {
        this.reportData = [];
        return rtn;
      }
      let totalCustomer = tmpList.length.toString();

      this.leadTracEnrollmentsReportInitSummary.CustomerId =
        "Total: " + totalCustomer;
      this.recordCount = tmpList.length;
      this.leadTracEnrollmentsReportInitSummary.IsSummary = true;

      rtn.push(...tmpList);
      rtn.push(this.leadTracEnrollmentsReportInitSummary);
      this.reportData = rtn;

      return rtn;
    },
  }),
  async mounted() {
    this.reportsPanelHeight = this.setReportGridHeight(
      window.innerHeight,
      document.getElementById("reportsSummary")
    );

    await this.sleep(1000);
    this.fixStickyHeaders();
  },
  methods: {
    async connectGlobalAccount(id, accountNumber, policyGroupId) {
      let err, result;
      [err, result] = await this.$store.dispatch(
        types.GET_CONNECT_GLOBAL_ACCOUNT,
        {
          CustomerId: id,
          GlobalAccountNumber: accountNumber,
          PolicyGroupId: policyGroupId,
        }
      );

      if (result) {
        let indexToRemove = this.leadTracEnrollmentList.findIndex(
          (item) => item.CustomerId == id
        );
        if (indexToRemove != -1) {
          this.leadTracEnrollmentList.splice(indexToRemove, 1);
        }
        this.$swal("Success!", result.Message, "success");
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
    },
    addPrefixToCustomerId(customerId) {
      let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      let customerPrefix = null;

      if (
        userInfo != null &&
        userInfo.customerIdPrefix != null &&
        userInfo.customerIdPrefix.length > 0
      ) {
        customerPrefix = userInfo.customerIdPrefix;
      }

      let rtn = customerId.toString();
      if (customerPrefix != null) {
        rtn = customerPrefix + rtn;
      }
      return rtn;
    },
    setPagination(data) {
      this.isPaginated = data;
    },
    async setPayload(value) {
      this.loadingSpinner = true;
      await this.$store.dispatch(types.GET_LEAD_TRAC_ENROLLMENTS_REPORT, value);
      this.loadingSpinner = false;
      await this.sleep(500);
      this.fixStickyHeaders();
    },
    clearReport() {
      this.$store.dispatch(types.RESET_REPORTS);
      this.reportData = [];
    },
    async viewCustomer(id, name) {
      this.$Emitter.emit("viewCustomer", {
        customerId: id,
        customerName: name,
      });
    },
  },
};
</script>

<style scoped></style>
