<template>
  <div class="col-md-12">
    <ReportSummary
      :exportData="reportData"
      :reportName="'FirstClearDraftReport'"
      :recordCount="recordCount"
      @updateLoadingStatus="reportLoading = $event"
      @setPayload="setPayload"
      @setPagination="setPagination"
      @clearReport="clearReport"
      :loadingSpinner="loadingSpinner"
    />

    <div
      class="datagrid-table gcsTabsTableScroll report-wrapper"
      :style="'max-height: ' + reportsPanelHeight + 'px; overflow-y:auto;'"
    >
      <o-table
        :data="filteredFirstClearDraftReport"
        :paginated="isPaginated && filteredFirstClearDraftReport.length > 0"
        :per-page="perPage"
        :pagination-simple="isPaginationSimple"
        :pagination-position="paginationPosition"
        :sticky-header="true"
      >
        <o-table-column
          field="CustomerId"
          label="Customer Id"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CustomerIdFilter"
                placeholder=""
                v-myMask="{
                  alias: 'numeric',
                  rightAlign: false,
                }"
              />
            </div>
          </template>

          <template v-slot="props">
            <span @click="viewCustomer(props.row.CustomerId, props.row.CustomerName)"
              :class="getTagReportHyperLinkClass(props.row.CustomerId && props.row.CustomerName)">
              <strong v-if="props.row.IsSummary"
                >{{ props.row.CustomerId }}
              </strong>
              <span v-if="!props.row.IsSummary">
                {{ addPrefixToCustomerId(props.row.CustomerId) }}
              </span>
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="CustomerName"
          label="Customer Name"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="customerNameFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.CustomerName }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.CustomerName }}
            </span>
          </template>
        </o-table-column>
        <o-table-column
          field="OfficeName"
          label="Office Name"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="officeNameFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.OfficeName }}
          </template>
        </o-table-column>

        <o-table-column
          field="Campaign"
          label="Campaign Name"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="campaignFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.Campaign }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.Campaign }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="CustomerStatus"
          label="Customer Status"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="customerStatusFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.CustomerStatus }}
          </template>
        </o-table-column>

        <o-table-column
          field="SalesUser"
          label="Sales User"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="salesUserFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.SalesUser }}
          </template>
        </o-table-column>

        <o-table-column field="State" label="State" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="StateFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.State }}
          </template>
        </o-table-column>

        <o-table-column
          field="DraftAmount"
          label="Draft Amount"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="draftAmountFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.DraftAmount }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatMoney(props.row.DraftAmount) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="DebtAmount"
          label="Debt Amount"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="debtAmountFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.DebtAmount }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatMoney(props.row.DebtAmount) }}
            </span>
          </template>
        </o-table-column>
        <o-table-column
          field="ClearedDate"
          label="Cleared Date"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="clearedDateFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ formatGridDate(props.row.ClearedDate) }}
          </template>
        </o-table-column>

        <o-table-column
          field="EnrolledDate"
          label="Enrolled Date"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="EnrolledDateFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ formatGridDate(props.row.EnrolledDate) }}
          </template>
        </o-table-column>

        <o-table-column
          field="EnrollmentDebt"
          label="Enrollment Debt"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="EnrollmentDebtFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.EnrollmentDebt }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatMoney(props.row.EnrollmentDebt) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="ProgramLength"
          label="Program Length"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="ProgramLengthFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.ProgramLength }}
          </template>
        </o-table-column>

        <o-table-column
          field="PolicyGroup"
          label="Policy Group Name"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="PolicyGroupFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.PolicyGroup }}
          </template>
        </o-table-column>

        <o-table-column
          field="SumOfEarnedPerformanceFee"
          label="Sum Of Earned Performance Fee"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="SumOfEarnedPerformanceFeeFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.SumOfEarnedPerformanceFee }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatMoney(props.row.SumOfEarnedPerformanceFee) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field=""
          label="View Customer"
          position="centered"
          v-slot="props"
        >
          <button
            @click="viewCustomer(props.row.CustomerId, props.row.CustomerName)"
            class="btn btn-info waves-effect waves-light ml-3"
            v-if="!props.row.IsSummary"
          >
            <i class="far fa-address-card"></i>
          </button>
        </o-table-column>
      </o-table>
      <section
        v-if="filteredFirstClearDraftReport.length == 0"
        class="section"
        style="text-align: center"
      >
        <div class="content has-text-grey has-text-centered mb-4">
          <p class="noMargin">
            <i
              style="font-size: 24px; color: #939393"
              class="ri-question-line"
            ></i>
          </p>
          <p class="noMargin">Nothing here.</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import ReportSummary from "./ReportSummary.vue";
import utilitiesMixin from "@/mixins/utilitiesMixin";

export default {
  name: "FirstClearDraftReport",
  components: { ReportSummary },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      customerPrefix: null,
      reportsPanelHeight: 300,
      loadingSpinner: false,
      isPaginationSimple: false,
      isPaginated: true,
      paginationPosition: "both",
      currentPage: 1,
      perPage: 200,

      recordCount: 0,
      reportLoading: false,
      reportData: [],

      firstClearDraftInitSummary: {
        CustomerName: "",
        SalesUser: "",
        Campaign: "",
        DraftAmount: "",
        ClearedDate: "",
        OfficeName: "",
        PolicyGroup: "",
        DebtAmount: "",
        CustomerId: "",
        EnrolledDate: "",
        EnrollmentDebt: "",
        SumOfEarnedPerformanceFee: "",
        CustomerStatus: "",
        State: "",
        ProgramLength: "",
      },

      customerNameFilter: "",
      campaignFilter: "",
      salesUserFilter: "",
      draftAmountFilter: "",
      clearedDateFilter: "",
      officeNameFilter: "",
      PolicyGroupFilter: "",
      debtAmountFilter: "",
      CustomerIdFilter: "",
      EnrolledDateFilter: "",
      EnrollmentDebtFilter: "",
      SumOfEarnedPerformanceFeeFilter: "",
      customerStatusFilter: "",
      StateFilter: "",
      ProgramLengthFilter: "",
    };
  },
  computed: mapState({
    firstClearDraftList: (state) => state.reports.firstClearDraftList,

    filteredFirstClearDraftReport() {
      let rtn = [];

      let tmpList =
        this.firstClearDraftList.length > 0
          ? this.firstClearDraftList.filter((item) => {
              return (
                this.filterString(item.CustomerId, this.CustomerIdFilter) &&
                this.filterString(
                  item.CustomerStatus,
                  this.customerStatusFilter
                ) &&
                this.filterString(item.CustomerName, this.customerNameFilter) &&
                this.filterString(item.State, this.StateFilter) &&
                this.filterString(item.Campaign, this.campaignFilter) &&
                this.filterString(item.OfficeName, this.officeNameFilter) &&
                this.filterString(item.PolicyGroup, this.PolicyGroupFilter) &&
                this.filterString(item.SalesUser, this.salesUserFilter) &&
                this.filterString(item.DraftAmount, this.draftAmountFilter) &&
                this.filterString(item.DebtAmount, this.debtAmountFilter) &&
                this.filterString(
                  item.ProgramLength,
                  this.ProgramLengthFilter
                ) &&
                this.filterString(item.EnrolledDate, this.EnrolledDateFilter) &&
                this.filterString(
                  item.EnrollmentDebt,
                  this.EnrollmentDebtFilter
                ) &&
                this.filterString(
                  item.SumOfEarnedPerformanceFee,
                  this.SumOfEarnedPerformanceFeeFilter
                ) &&
                new Date(item.ClearedDate)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .indexOf(this.clearedDateFilter) > -1
              );
            })
          : [];

      if (tmpList.length == 0) {
        this.reportData = [];
        return rtn;
      }
      let totalCustomer = tmpList.length.toString();

      this.firstClearDraftInitSummary.CustomerId = "Total: " + totalCustomer;
      this.recordCount = tmpList.length;
      this.firstClearDraftInitSummary.IsSummary = true;

      rtn.push(...tmpList);
      rtn.push(this.firstClearDraftInitSummary);
      this.reportData = rtn;

      return rtn;
    },
  }),
  async mounted() {
    this.reportsPanelHeight = this.setReportGridHeight(
      window.innerHeight,
      document.getElementById("reportsSummary")
    );

    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

    if (
      this.userInfo != null &&
      this.userInfo.customerIdPrefix != null &&
      this.userInfo.customerIdPrefix.length > 0
    ) {
      this.customerPrefix = this.userInfo.customerIdPrefix;
    }

    await this.sleep(1000);
    this.fixStickyHeaders();
  },
  methods: {
    addPrefixToCustomerId(customerId) {
      let rtn = customerId.toString();
      if (this.customerPrefix != null) {
        rtn = this.customerPrefix + rtn;
      }
      return rtn;
    },
    setPagination(data) {
      this.isPaginated = data;
    },
    async setPayload(value) {
      this.loadingSpinner = true;
      await this.$store.dispatch(types.GET_FIRST_CLEAR_DRAFT_REPORT, value);
      this.loadingSpinner = false;
      await this.sleep(500);
      this.fixStickyHeaders();
    },
    clearReport() {
      this.$store.dispatch(types.RESET_REPORTS);
      this.reportData = [];
    },
    async viewCustomer(id, name) {
      this.$Emitter.emit("viewCustomer", {
        customerId: id,
        customerName: name,
      });
    },
  },
};
</script>

<style scoped></style>
