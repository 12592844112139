<template>
  <div class="account-pages mt-5 mb-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6 col-xl-5">
          <div class="card">
            <div v-if="authenticate && !isAuthenticationCode">
              <MultiFactorAuth
                :authenticate="authenticate"
                @authCode="authenticationCode"
              ></MultiFactorAuth>
            </div>
            <div v-if="isAuthenticationCode">
              <MultiFactorAuthCode
                @authCode="authenticationCode"
              ></MultiFactorAuthCode>
            </div>

            <div v-if="!authenticate" class="card-body p-4">
              <div class="text-center w-75 m-auto">
                <a href="index.html">
                  <span>
                    <img :src="loginLogo" alt />
                  </span>
                </a>
                <p class="text-muted mb-4 mt-3">
                  Enter your email address and password to access the CRM Documentation.
                </p>
              </div>
              <!--test 111-->
              <form @submit.prevent="handleAuthentication" novalidate>
                <div class="form-group mb-3">
                  <label for="emailaddress">Email</label>
                  <input
                    name="username"
                    ref="username"
                    class="form-control"
                    type="text"
                    v-model="formUserName"
                    placeholder="Enter your email address"
                    @blur="v$.formUserName.$touch"
                  />

                  <div class="errorText" v-if="v$.formUserName.$error">
                    Email is required
                  </div>
                </div>

                <div class="form-group mb-3">
                  <br />
                  <label for="password">Password</label>
                  <input
                    name="password"
                    ref="password"
                    class="form-control"
                    type="password"
                    v-model="formPassword"
                    placeholder="Enter your password"
                    @blur="v$.formPassword.$touch"
                  />
                  <div class="errorText" v-if="v$.formPassword.$error">
                    Password is required
                  </div>
                </div>

                <div class="form-group mb-0 text-center">
                  <br />
                  <button
                    :disabled="loading"
                    class="animated-btn btn btn-primary btn-block"
                  >
                    <i v-if="loading" class="fas fa-spinner mr-1"></i>
                    Log In
                  </button>
                </div>

                <div class="form-group text-center mt-2">
                  <div style="margin-bottom: 6px; color:#ed3030;" v-if="errStatus">
                  {{  errText }}
                  </div>
                </div>
              </form>
              <div
                style="
                  width: 100%;
                  margin: 10px 0;
                  text-align: center;
                  font-size: 10px;
                "
              >
                v {{ crmVersion }}
              </div>
            </div>
            <!-- end card-body -->
          </div>
        </div>
        <!-- end col -->
      </div>
    </div>
    <!-- end container -->
  </div>
</template>

<script>
import api from "@/services/login.service";
import to from "@/helpers/to";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import localStorageManagerMixin from "@/mixins/localStorageManagerMixin";
import globalTypes from "@/store/types";
import useValidate from "@vuelidate/core";
import { email, required } from "vuelidate/lib/validators";
import MultiFactorAuth from "@/MultiFactorAuth";
import MultiFactorAuthCode from "@/MultiFactorAuthCode";

export default {
  name: "Login",
  mixins: [utilitiesMixin, localStorageManagerMixin],
  components: { MultiFactorAuth, MultiFactorAuthCode },
  emits: ["authenticated", "authCode"],
  data() {
    return {
      authenticate: false,
      isAuthenticationCode: false,
      isLoginOverlayPopUp: false,
      crmVersion: "",
      v$: useValidate(),
      errStatus: false,
      errText:"",
      loading: false,
      formUserName: "",
      formPassword: "",
      loginLogo: "",
      
      mfaInfo: {
        UserName: "",
        MfaToken: null,
      },
    };
  },
  async mounted() {
    this.crmVersion = process.env.VUE_APP_CRM_VERSION;
    this.loginLogo =
      this.$loginLogo +
      "?v=" +
      (Math.floor(Math.random() * 1000) + 1).toString();

  },
  validations() {
    return {
      formUserName: { required, email },
      formPassword: { required },
    };
  },
  methods: {
    authenticationCode(value) {
      this.isAuthenticationCode = value;
    },

    openLoginOverlayPopUp() {
      this.isLoginOverlayPopUp = true;
    },
    closeLoginOverlayPopUp() {
      this.isLoginOverlayPopUp = false;
    },

    async handleAuthentication() {
      this.v$.$validate(); // checks all inputs

      if (!this.v$.$error) {

        let  err,result;
        this.loading = true;

        try {
          let encPwd = await this.sha256(this.formPassword);
          let mfaToken = await this.getTokenFromLocalStorage(this.formUserName);
          
          let payload = {
            UserName: this.formUserName,
            MfaToken: mfaToken,
            Password: encPwd,
            Version: this.crmVersion,
          };
          if (typeof this.$route != "undefined" && this.$route && this.$route.query) {
              let data = this.$route.query;
              if (data["r"]) {
                payload.RedirectUrl = data["r"];
              }
            }
            [err, result] = await to(api.koAuthenticate(payload));
        
              if (result) {
                window.location.href = result;
              }
              else
              {
                this.errStatus = true;
                this.errText = this.getApiErrorMessage(err);
              }
            }
            catch (err) {
              this.errText= JSON.stringify(err);
              this.errStatus = true;
            }

          }
          this.loading = false;
    
  }
}
};
</script>
<style>
.errorText {
  margin: 5px;
  color: #f1556c;
  font-weight: 600;
}

.forgotPasswordText {
  font-size: 12px;
  color: #61b1f4;
  cursor: pointer;
}
.forgotPasswordText:hover {
  color: #a3cff3;
}
</style>
