<template>
  <div class="col-md-12">
    <ReportSummary
      :exportData="reportData"
      :reportName="'SettlementsReport'"
      :recordCount="recordCount"
      @updateLoadingStatus="reportLoading = $event"
      @setPayload="setPayload"
      @clearReport="clearReport"
      @setPagination="setPagination"
      :loadingSpinner="loadingSpinner"
    />

    <div
      class="datagrid-table gcsTabsTableScroll report-wrapper"
      :style="'max-height: ' + reportsPanelHeight + 'px; overflow-y:auto;'"
    >
      <o-table
        :data="filteredSettlementsReport"
        :paginated="isPaginated && filteredSettlementsReport.length > 0"
        :per-page="perPage"
        :pagination-simple="isPaginationSimple"
        :pagination-position="paginationPosition"
        :sticky-header="true"
      >
        <o-table-column
          field="CustomerId"
          label="Customer ID"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CustomerIdFilter"
                placeholder=""
                v-myMask="{
                  alias: 'numeric',
                  rightAlign: false,
                }"
              />
            </div>
          </template>

          <template v-slot="props">
            <span
              @click="
                viewCustomer(props.row.CustomerId, props.row.CustomerName)
              "
              :class="
                getTagReportHyperLinkClass(
                  props.row.CustomerId && props.row.CustomerName
                )
              "
            >
              <strong v-if="props.row.IsSummary"
                >{{ props.row.CustomerId }}
              </strong>
              <span v-if="!props.row.IsSummary">
                {{ addPrefixToCustomerId(props.row.CustomerId) }}
              </span>
            </span>
          </template>
        </o-table-column>

        <o-table-column
          v-if="checkAuth(29001)"
          field="DpgContactId"
          label="DPG Contact Id"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="DpgContactIdFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.DpgContactId }}
          </template>
        </o-table-column>

        <o-table-column
          v-if="checkAuth(29001)"
          field="PaymentProcessor"
          label="Payment Processor"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="PaymentProcessorFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.PaymentProcessor }}
          </template>
        </o-table-column>

        <o-table-column
          field="CreditorId"
          label="Creditor ID"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CreditorIdFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.CreditorId }}
          </template>
        </o-table-column>

        <o-table-column
          field="CreditorName"
          label="Creditor Name"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CreditorNameFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.CreditorName }}
          </template>
        </o-table-column>

        <o-table-column
          field="CurrentCreditorName"
          label="Current Creditor Name"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CurrentCreditorNameFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.CurrentCreditorName }}
          </template>
        </o-table-column>

        <o-table-column field="Agent" label="Agent" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="AgentFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.Agent }}
          </template>
        </o-table-column>
        <o-table-column
          field="Negotiator"
          label="Negotiator"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="NegotiatorFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.Negotiator }}
          </template>
        </o-table-column>

        <o-table-column field="Office" label="Office" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="OfficeFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.Office }}
          </template>
        </o-table-column>

        <o-table-column
          field="PolicyGroup"
          label="Policy Group"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="PolicyGroupFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.PolicyGroup }}
          </template>
        </o-table-column>

        <o-table-column field="State" label="State" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="StateFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.State }}
          </template>
        </o-table-column>

        <o-table-column
          field="CustomerName"
          label="Customer Name"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CustomerNameFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.CustomerName }}
          </template>
        </o-table-column>

        <o-table-column
          field="StatusName"
          label="Customer Status"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="StatusNameFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.StatusName }}
          </template>
        </o-table-column>

        <o-table-column
          field="AccountNumber"
          label="Account Number"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="AccountNumberFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.AccountNumber }}
          </template>
        </o-table-column>

        <o-table-column
          field="CurrentCreditorAccountNumber"
          label="Current Creditor Account Number"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CurrentCreditorAccountNumberFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.CurrentCreditorAccountNumber }}
          </template>
        </o-table-column>

        <o-table-column
          field="SettlementDate"
          label="Settlement Date"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="SettlementDateFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ formatGridDate(props.row.SettlementDate) }}
          </template>
        </o-table-column>

        <o-table-column
          field="FirstSettlementDate"
          label="First Settlement Date"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="FirstSettlementDateFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ formatGridDate(props.row.FirstSettlementDate) }}
          </template>
        </o-table-column>

        <o-table-column
          field="SettlementFeePercentage"
          label="Settlement Fee %"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="SettlementFeePercentageFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary">
              {{ props.row.SettlementFeePercentage }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ (props.row.SettlementFeePercentage * 100).toFixed() }}%
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="EnrolledDate"
          label="Enrolled Date"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="EnrolledDateFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ formatShortDate(props.row.EnrolledDate) }}
          </template>
        </o-table-column>
        <o-table-column field="TotalFee" label="Total Fee" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="TotalFeeFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary">
              {{ formatMoney(props.row.TotalFee) }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatMoney(props.row.TotalFee) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="TotalOffer"
          label="Total Offer"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="TotalOfferFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ formatMoney(props.row.TotalOffer) }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatMoney(props.row.TotalOffer) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="OriginalBalance"
          label="Original Balance"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="OriginalBalanceFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ formatMoney(props.row.OriginalBalance) }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatMoney(props.row.OriginalBalance) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="SettlementPercantage"
          label="Settlement Percentage"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="SettlementPercantageFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary">
              {{ formatFloatingNumber(props.row.SettlementPercantage) }}%
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatFloatingNumber(props.row.SettlementPercantage) }}%
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="CurrentBalance"
          label="Current Balance"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CurrentBalanceFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary">
              {{ props.row.CurrentBalance }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatMoney(props.row.CurrentBalance) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field=""
          label="Curr. Balance Settlement %"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                style="width: 167px"
                type="text"
                class="datagrid-table creditorTable"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <span v-if="!props.row.IsSummary">
              {{
                props.row.CurrentBalance == 0 || props.row.TotalOffer == 0
                  ? ""
                  : formatFloatingNumber(
                      (props.row.TotalOffer * 100) / props.row.CurrentBalance
                    ) + "%"
              }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="CurrentCreditorBalance"
          label="Current Creditor Balance"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CurrentCreditorBalanceFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary">
              {{ props.row.CurrentCreditorBalance }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatMoney(props.row.CurrentCreditorBalance) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field=""
          label="Curr. Cr. Balance Settlement %"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <span v-if="!props.row.IsSummary">
              {{
                props.row.CurrentCreditorBalance == 0 ||
                props.row.TotalOffer == 0
                  ? ""
                  : formatFloatingNumber(
                      (props.row.TotalOffer * 100) /
                        props.row.CurrentCreditorBalance
                    ) + "%"
              }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="TermCount"
          label="Term Count"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="TermCountFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.TermCount }}
          </template>
        </o-table-column>
        <o-table-column
          field="FirstTermFee"
          label="First Term Fee"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="FirstTermFeeFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary">
              {{ formatMoney(props.row.FirstTermFee) }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatMoney(props.row.FirstTermFee) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="SettledCount"
          label="Settled Count"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="SettledCountFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.SettledCount }}
          </template>
        </o-table-column>

        <o-table-column
          field="VoidCount"
          label="Void Count"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="VoidCountFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.VoidCount }}
          </template>
        </o-table-column>
        <o-table-column
          v-if="canSeePaymentPlanner"
          field="PaymentPlanner"
          label="Payment Planner"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="PaymentPlannerFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ getPaymentPlannerValue(props.row.SettlementDate) }}
          </template>
        </o-table-column>

        <o-table-column
          field="AddedAfterEnrollment"
          label="Added After Enrollment"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="AddedAfterEnrollmentFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.AddedAfterEnrollment }}
          </template>
        </o-table-column>

        <o-table-column
          field=""
          label="View Customer"
          position="centered"
          v-slot="props"
        >
          <button
            @click="viewCustomer(props.row.CustomerId, props.row.CustomerName)"
            class="btn btn-info waves-effect waves-light ml-3"
            v-if="!props.row.IsSummary"
          >
            <i class="far fa-address-card"></i>
          </button>
        </o-table-column>
      </o-table>
      <section
        v-if="filteredSettlementsReport.length == 0"
        class="section"
        style="text-align: center"
      >
        <div class="content has-text-grey has-text-centered mb-4">
          <p class="noMargin">
            <i
              style="font-size: 24px; color: #939393"
              class="ri-question-line"
            ></i>
          </p>
          <p class="noMargin">Nothing here.</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import ReportSummary from "./ReportSummary.vue";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import deepCopy from "@/helpers/deepCopy";

export default {
  name: "SettlementsReport",
  components: { ReportSummary },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      customerPrefix: null,
      reportsPanelHeight: 300,
      loadingSpinner: false,
      isPaginationSimple: false,
      isPaginated: true,
      paginationPosition: "both",
      currentPage: 1,
      perPage: 200,

      recordCount: 0,
      reportLoading: false,
      reportData: [],
      canSeePaymentPlanner: false,

      settlementsReportInitSummary: {
        CreditorName: "",
        CustomerName: "",
        AccountNumber: "",
        SettlementDate: "",
        TotalFee: "",
        TotalOffer: "",
        TermCount: "",
        CustomerId: "",
        SettlementPercantage: "",
        OriginalBalance: "",
        Office: "",
        PolicyGroup: "",
        State: "",
        Negotiator: "",
        CurrentBalance: "",
        CurrentCreditorBalance: "",
        SettledCount: "",
        FirstTermFee: "",
        CurrentCreditorName: "",
        SettlementFeePercentage: "",
        StatusName: "",
        FirstSettlementDate: "",
        VoidCount: "",
        DpgContactId: "",
        PaymentProcessor: "",
        CurrentCreditorAccountNumber: "",
        AddedAfterEnrollment: "",
        CreditorId: "",
      },
      CreditorIdFilter: "",
      CurrentCreditorNameFilter: "",
      StatusNameFilter: "",
      CreditorNameFilter: "",
      CustomerNameFilter: "",
      AccountNumberFilter: "",
      SettlementDateFilter: "",
      SettlementFeePercentageFilter: "",
      TotalFeeFilter: "",
      TotalOfferFilter: "",
      TermCountFilter: "",
      CustomerIdFilter: "",
      SettlementPercantageFilter: "",
      OriginalBalanceFilter: "",
      OfficeFilter: "",
      PolicyGroupFilter: "",
      StateFilter: "",
      AgentFilter: "",
      EnrolledDateFilter: "",
      NegotiatorFilter: "",
      CurrentBalanceFilter: "",
      CurrentCreditorBalanceFilter: "",
      SettledCountFilter: "",
      FirstTermFeeFilter: "",
      PaymentPlannerFilter: "",
      FirstSettlementDateFilter: "",
      VoidCountFilter: "",
      DpgContactIdFilter: "",
      PaymentProcessorFilter: "",
      CurrentCreditorAccountNumberFilter: "",
      AddedAfterEnrollmentFilter: "",
    };
  },
  computed: mapState({
    settlementsList: (state) => state.reports.settlementsList,
    filteredSettlementsReport() {
      let rtn = [];
      let data = deepCopy(this.settlementsList);

      for (let item of data) {
        item["SettlementPercantage"] = this.percantageValue(
          item.TotalOffer,
          item.OriginalBalance
        );
        item["PaymentPlanner"] = this.getPaymentPlannerValue(
          item["SettlementDate"]
        );
      }

      let tmpList =
        data.length > 0
          ? data.filter((item) => {
              return (
                this.filterString(item.DpgContactId, this.DpgContactIdFilter) &&
                this.filterString(item.CreditorId, this.CreditorIdFilter) &&
                this.filterString(
                  item.PaymentProcessor,
                  this.PaymentProcessorFilter
                ) &&
                this.filterString(item.VoidCount, this.VoidCountFilter) &&
                this.filterString(item.SettledCount, this.SettledCountFilter) &&
                this.filterString(
                  item.AddedAfterEnrollment,
                  this.AddedAfterEnrollmentFilter
                ) &&
                this.filterString(item.FirstTermFee, this.FirstTermFeeFilter) &&
                this.filterString(
                  item.CurrentCreditorAccountNumber,
                  this.CurrentCreditorAccountNumberFilter
                ) &&
                this.filterString(
                  item.CurrentCreditorName,
                  this.CurrentCreditorNameFilter
                ) &&
                this.filterString(
                  item.OriginalBalance,
                  this.OriginalBalanceFilter
                ) &&
                this.filterString(
                  item.CurrentBalance,
                  this.CurrentBalanceFilter
                ) &&
                this.filterString(
                  item.CurrentCreditorBalance,
                  this.CurrentCreditorBalanceFilter
                ) &&
                this.filterString(item.Office, this.OfficeFilter) &&
                this.filterString(item.PolicyGroup, this.PolicyGroupFilter) &&
                this.filterString(item.State, this.StateFilter) &&
                this.filterString(item.Negotiator, this.NegotiatorFilter) &&
                this.filterString(item.CustomerId, this.CustomerIdFilter) &&
                this.filterString(item.StatusName, this.StatusNameFilter) &&
                this.filterString(item.CreditorName, this.CreditorNameFilter) &&
                this.filterString(item.CustomerName, this.CustomerNameFilter) &&
                this.filterString(
                  item.SettlementFeePercentage,
                  this.SettlementFeePercentageFilter
                ) &&
                this.filterString(item.Agent, this.AgentFilter) &&
                this.filterString(
                  item.AccountNumber,
                  this.AccountNumberFilter
                ) &&
                new Date(item.FirstSettlementDate)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .indexOf(this.FirstSettlementDateFilter) > -1 &&
                new Date(item.SettlementDate)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .indexOf(this.SettlementDateFilter) > -1 &&
                this.filterString(item.TotalFee, this.TotalFeeFilter) &&
                new Date(item.EnrolledDate)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  })
                  .indexOf(this.EnrolledDateFilter) > -1 &&
                this.filterString(item.TotalOffer, this.TotalOfferFilter) &&
                this.filterNumeric(item.TermCount, this.TermCountFilter) &&
                this.filterString(
                  item.SettlementPercantage,
                  this.SettlementPercantageFilter
                ) &&
                this.filterString(
                  item.PaymentPlanner,
                  this.PaymentPlannerFilter
                )
              );
            })
          : [];

      if (tmpList.length == 0) {
        this.reportData = [];
        return rtn;
      }
      let totalCustomer = tmpList.length.toString();

      let totalTotalFee = 0;
      let totalTotalOffer = 0;
      let totalOriginalBalance = 0;
      let totalFirstTermFee = 0;

      for (let item of tmpList) {
        totalTotalFee += item.TotalFee;
        totalTotalOffer += item.TotalOffer;
        totalOriginalBalance += item.OriginalBalance;
        totalFirstTermFee += item.FirstTermFee;
      }

      let totalSettlementPercentage =
        (totalTotalOffer / totalOriginalBalance) * 100;

      this.settlementsReportInitSummary.TotalFee = totalTotalFee;
      this.settlementsReportInitSummary.TotalOffer = totalTotalOffer;
      this.settlementsReportInitSummary.OriginalBalance = totalOriginalBalance;
      this.settlementsReportInitSummary.FirstTermFee = totalFirstTermFee;
      this.settlementsReportInitSummary.SettlementPercantage =
        totalSettlementPercentage;

      this.settlementsReportInitSummary.CustomerId = "Total: " + totalCustomer;
      this.recordCount = tmpList.length;

      this.settlementsReportInitSummary.IsSummary = true;

      rtn.push(...tmpList);
      rtn.push(this.settlementsReportInitSummary);
      this.reportData = rtn;

      return rtn;
    },
  }),
  async mounted() {
    this.reportsPanelHeight = this.setReportGridHeight(
      window.innerHeight,
      document.getElementById("reportsSummary")
    );
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (
      this.userInfo != null &&
      this.userInfo.customerIdPrefix != null &&
      this.userInfo.customerIdPrefix.length > 0
    ) {
      this.customerPrefix = this.userInfo.customerIdPrefix;
    }
    if (this.userInfo != null) {
      let arrPermission = this.userInfo.moduleFunctions
        .split(",")
        .map(function (item) {
          return parseInt(item, 10);
        });
      if (arrPermission.includes(55015)) {
        this.canSeePaymentPlanner = true;
      }
    }
    await this.sleep(1000);
    this.fixStickyHeaders();
  },
  methods: {
    getPaymentPlannerValue(settDate) {
      let rtn = "No";
      let initialDate = "2024-01-12";
      if (Date.parse(settDate.toString()) > Date.parse(initialDate)) {
        rtn = "Yes";
      }
      return rtn;
    },
    addPrefixToCustomerId(customerId) {
      let rtn = customerId.toString();
      if (this.customerPrefix != null) {
        rtn = this.customerPrefix + rtn;
      }
      return rtn;
    },
    percantageValue(val1, val2) {
      let rtn = 0;
      if (val2 == 0 || val1 == 0 || val2 == null || val1 == null) {
        rtn = 0;
      } else {
        rtn = parseFloat(parseFloat(val1) / parseFloat(val2)) * 100;
      }
      return rtn;
    },

    setPagination(data) {
      this.isPaginated = data;
    },
    async setPayload(value) {
      this.loadingSpinner = true;
      await this.$store.dispatch(types.GET_SETTLEMENTS_REPORT, value);
      this.loadingSpinner = false;
      await this.sleep(500);
      this.fixStickyHeaders();
    },
    clearReport() {
      this.$store.dispatch(types.RESET_REPORTS);
      this.reportData = [];
    },
    async viewCustomer(id, name) {
      this.$Emitter.emit("viewCustomer", {
        customerId: id,
        customerName: name,
      });
    },
  },
};
</script>

<style scoped></style>
