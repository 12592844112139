import types from "./types";
import api from "@/services/client/customerApi";
import to from "@/helpers/to";

export default {
  state: {},
  actions: {
    GET_CSR_TICKET_DASHBOARD: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.getCsrTicketDashboard(payload));
      return [err, result];
    },
    ASSIGN_TICKET_TO_ME: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.assignTask(payload));
      return [err, result];
    },
    GET_TICKET_ACCESS_ROLES_FOR_TICKETS: async ({ commit }) => {
      let err, result;

      [err, result] = await to(api.getUserAccessRoles());

      return [err, result];
    },

    UPDATE_BULK_ROLE: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.updateBulkRole(payload));
      return [err, result];
    },

    UPDATE_BULK_USER: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.updateBulkUser(payload));
      return [err, result];
    },

    UPDATE_BULK_DATE: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.updateBulkDate(payload));
      return [err, result];
    },
  },
  mutations: {},
};
