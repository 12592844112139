import api from "@/services/client/customerApi";
import to from "@/helpers/to";

export default {
  state: {},
  actions: {
    OPTOUT_AUTOCALL: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.OptOutAutoCall(payload));

      return [err, result];
    },
    GET_TASK_TRIGGERS_BY_ID: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.getContactTriggersByTaskId(payload));

      return [err, result];
    },
    GET_TICKET_ACCESS_ROLES: async ({ commit }) => {
      let err, result;

      [err, result] = await to(api.getUserAccessRoles());

      return [err, result];
    },
    GET_TICKET_ACCESS_USERS: async ({ commit }) => {
      let err, result;

      [err, result] = await to(api.getAllUsersAccessTickets());

      return [err, result];
    },
    GET_CLIENT_TASKS: async ({ commit }, data) => {
      let err, result;
      [err, result] = await to(api.getAllTasks(data));
      return [err, result];
    },
    GET_TASK_BY_ID: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.getTask(payload));
      return [err, result];
    },
    ASSIGN_TASK: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.assignTask(payload));
      return [err, result];
    },
    UNASSIGN_TASK: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.unassignTask(payload));
      return [err, result];
    },
    SUBMIT_TASK: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.submitTask(payload));

      return [err, result];
    },
    ADD_TASK: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.addNewTask(payload));
      return [err, result];
    },
  },
};
