import types from "./types";
import api from "@/services/admin/api";
import to from "@/helpers/to";

export default {
  state: {},

  actions: {
    GET_XCHANGE: async ({ commit }) => {
      let err, result;
      [err, result] = await to(api.getXChangeManagement());
      return [err, result];
    },
    ADD_XCHANGE: async ({ commit }, payload) => {
      let result = await to(api.addXChangeManagement(payload));
      return result;
    },
    UPDATE_XCHANGE: async ({ commit }, payload) => {
      let result = await to(api.updateXChangeManagement(payload));
      return result;
    },
  },
  mutations: {},
};
