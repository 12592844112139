<template>
  <div class="row admin-page officePage">
    <div v-if="saving" class="loading">
      <div class="text-center" style="margin-top: 250px">
        <i class="spinner-grow text-info m-2"></i>
      </div>
    </div>

    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
      <div class="col-md-12 noPadding" v-show="checkAuth(9988)">
        <ul
          class="nav nav-tabs"
          style="border-bottom: 2px solid #3bafda !important; max-width: 500px"
        >
          <li v-for="tab in tabs" :key="tab.id" class="nav-item tab-item">
            <a
              data-toggle="tab"
              :aria-expanded="tab.active"
              v-on:click.prevent="() => handleTabChange(tab.name)"
              class="tab-link"
              :class="{ active: tab.active }"
            >
              <span class="d-sm-inline-block tab-item-label">
                {{ tab.label }}
              </span>
            </a>
          </li>
        </ul>
      </div>

      <div
        v-show="tabs[0].active"
        class="form-group row"
        v-if="checkAuth(9988)"
      >
        <div class="col-xl-3 col-lg-4 col-md-12 col-sm-12">
          <button class="col-xl-12 btn btn-primary" @click="addNewCreditors">
            Add New Creditor
          </button>
          <div class="filter form-group row">
            <div class="col-lg-12 col-md-12 col-sm-12 noPadding">
              <input
                autocomplete="off"
                type="text"
                class="form-control"
                placeholder="Filter Creditors Name"
                v-model="creditorsFilter"
              />
            </div>
            <div class="list col-md-12">
              <div
                class="office item row"
                v-for="(item, index) in filteredBlockedCreditors"
                :key="item.Id"
                :class="{ active: creditorActiveItem === index }"
                :style="[
                  index % 2 === 0
                    ? { 'background-color': '#F1EFEF' }
                    : { 'background-color': '#FFFFFF' },
                ]"
                :disabled="saving"
                @click="() => handleSelectionCreditors(item, index)"
              >
                <span
                  :class="{
                    adminActiveMenuItem: creditorActiveItem === item.Id,
                  }"
                >
                  <i class="ri-list-settings-fill"></i>
                  <span style="margin-left: 8px">{{ item.Name }}</span>
                </span>
              </div>
            </div>
            <div
              v-if="isMobileDevice()"
              style="
                border-top: 1px solid rgb(0, 0, 0, 0);
                margin: 20px 0 0 9px;
                padding-bottom: 55px;
              "
              class="col-md-12 scrollToSelectedItem"
            ></div>
          </div>
        </div>

        <div
          class="col-xl-9 col-lg-8 col-md-12 col-sm-12"
          v-if="creditorIsEditing"
        >
          <div class="col-xl-4 col-lg-6 col-md-12 col-xs-12">
            <label for="asm-creditorName" class="generalLabel"
              >Creditor Name</label
            >
            <input
              id="asm-creditorName"
              class="form-control"
              type="text"
              autocomplete="off"
              placeholder="Creditor Name"
              v-model="creditorSelectedRow.Name"
            />
          </div>

          <div class="col-xl-4 col-lg-6 col-md-12 col-xs-12 text-right">
            <button
              type="button"
              style="width: 100px"
              class="btn btn-danger mt-2 mr-2"
              @click="deleteCreditors(creditorSelectedRow.Id)"
              v-if="creditorSelectedRow.Id > 0"
            >
              Delete
            </button>
            <button
              type="button"
              style="width: 100px"
              class="btn btn-success mt-2"
              @click="saveCreditors()"
              :disabled="saving"
            >
              Save
            </button>
          </div>
        </div>
      </div>
      <div
        v-show="tabs[1].active"
        class="form-group row"
        v-if="checkAuth(9988)"
      >
        <div class="col-xl-3 col-lg-4 col-md-12 col-sm-12">
          <button class="col-xl-12 btn btn-primary" @click="addNewCreditTypes">
            Add New Credit Type
          </button>
          <div class="filter form-group row">
            <div class="col-lg-12 col-md-12 col-sm-12 noPadding">
              <input
                autocomplete="off"
                type="text"
                class="form-control"
                placeholder="Filter Credit Types"
                v-model="creditTypeFilter"
              />
            </div>
            <div class="list col-md-12">
              <div
                class="office item row"
                v-for="(item, index) in filteredBlockedCreditTypeList"
                :key="item.Id"
                :class="{ active: creditTypeActiveItem === index }"
                :style="[
                  index % 2 === 0
                    ? { 'background-color': '#F1EFEF' }
                    : { 'background-color': '#FFFFFF' },
                ]"
                :disabled="saving"
                @click="() => handleSelectionCreditTypes(item, index)"
              >
                <span
                  :class="{
                    adminActiveMenuItem: creditTypeActiveItem === item.Id,
                  }"
                >
                  <i class="ri-list-settings-fill"></i>
                  <span style="margin-left: 8px">{{ item.Type }}</span>
                </span>
              </div>
            </div>
            <div
              v-if="isMobileDevice()"
              style="
                border-top: 1px solid rgb(0, 0, 0, 0);
                margin: 20px 0 0 9px;
                padding-bottom: 55px;
              "
              class="col-md-12 scrollToSelectedItem"
            ></div>
          </div>
        </div>
        <div
          class="col-xl-9 col-lg-8 col-md-12 col-sm-12"
          v-if="creditTypeIsEditing"
        >
          <div class="col-xl-4 col-lg-6 col-md-12 col-xs-12">
            <label for="asm-creditType" class="generalLabel">Credit Type</label>

            <input
              id="asm-creditType"
              class="form-control"
              type="text"
              autocomplete="off"
              placeholder="Credit Type"
              v-model="creditTypeSelectedRow.Type"
            />
          </div>

          <div class="col-xl-4 col-lg-6 col-md-12 col-xs-12 text-right">
            <button
              type="button"
              style="width: 100px"
              class="btn btn-danger mt-2 mr-2"
              @click="deleteCreditTypes(creditTypeSelectedRow.Id)"
              v-if="creditTypeSelectedRow.Id > 0"
            >
              Delete
            </button>
            <button
              type="button"
              style="width: 100px"
              class="btn btn-success mt-2"
              @click="saveCreditTypes"
              :disabled="saving"
            >
              Save
            </button>
          </div>
        </div>
      </div>
      <div v-show="tabs[2].active" class="form-group row">
        <div class="col-xl-3 col-lg-4 col-md-12 col-sm-12">
          <div class="filter form-group row">
            <div class="col-lg-12 col-md-12 col-sm-12 noPadding">
              <input
                autocomplete="off"
                type="text"
                class="form-control"
                placeholder="Filter Api Setting"
                v-model="creditPullFilter"
              />
            </div>
            <div class="list col-md-12">
              <div
                class="office item row"
                v-for="(item, index) in filterCreditPull"
                :key="item.Id"
                :class="{ active: activeItem === index }"
                :style="[
                  index % 2 === 0
                    ? { 'background-color': '#F1EFEF' }
                    : { 'background-color': '#FFFFFF' },
                ]"
                :disabled="saving"
                @click="() => handleSelection(item, index)"
              >
                <span :class="{ adminActiveMenuItem: activeItem === index }">
                  <i class="ri-list-settings-fill"></i>
                  <span style="margin-left: 8px">{{ item.Name }}</span>
                </span>
              </div>
            </div>
            <div
              v-if="isMobileDevice()"
              style="
                border-top: 1px solid rgb(0, 0, 0, 0);
                margin: 20px 0 0 9px;
                padding-bottom: 55px;
              "
              class="col-md-12 scrollToSelectedItem"
            ></div>
          </div>
        </div>

        <div class="col-xl-9 col-lg-8 col-md-12 col-sm-12" v-if="isEditing">
          <div class="col-xl-4 col-lg-6 col-md-12 col-xs-12">
            <label for="asm-service" class="generalLabel">Service</label>
            <input
              id="asm-service"
              class="form-control readonlyInput"
              type="text"
              autocomplete="off"
              placeholder="Service"
              :disabled="true"
              :value="selectedService"
            />
          </div>
          <div
            v-if="fieldMatch('AppKey')"
            class="col-xl-4 col-lg-6 col-md-12 col-xs-12 pt-3"
          >
            <label for="asm-appKey" class="generalLabel">App Key</label>
            <input
              id="asm-appKey"
              class="form-control"
              type="text"
              autocomplete="off"
              placeholder="App Key"
              v-model="selectedRow.AppKey"
            />
          </div>
          <div
            v-if="fieldMatch('ClientToken')"
            class="col-xl-4 col-lg-6 col-md-12 col-xs-12 pt-3"
          >
            <label for="asm-clientToken" class="generalLabel"
              >Client Token</label
            >
            <input
              id="asm-clientToken"
              class="form-control"
              type="text"
              autocomplete="off"
              placeholder="Client Token"
              v-model="selectedRow.ClientToken"
            />
          </div>
          <div
            v-if="fieldMatch('ProductCodes')"
            class="col-xl-4 col-lg-6 col-md-12 col-xs-12 pt-3"
          >
            <label for="asm-productCode" class="generalLabel"
              >Product Code</label
            >
            <select
              class="form-control"
              v-model="selectedRow.ProductCodeId"
              id="asm-productCode"
            >
              <i class="fas fa-angle-down" aria-hidden="true"></i>
              <option :value="0">Please Select</option>
              <option
                v-for="item in selectedRow.ProductCodes"
                :key="item.Id"
                :value="item.Id"
              >
                {{ item.Name }}
              </option>
            </select>
          </div>
          <div
            v-if="fieldMatch('IsTest')"
            class="col-xl-4 col-lg-6 col-md-12 col-xs-12 pt-3"
          >
            <label class="generalLabel" for="isTestCbox" style="float: left"
              >Is Test</label
            >
            <input
              autocomplete="off"
              id="isTestCbox"
              v-model="selectedRow.IsTest"
              type="checkbox"
              class="checkbox-input generalCheckBox ml-2"
              @change="isTestChange"
              style="
                border-radius: 4px !important;
                border: solid 1px #d1d1d1 !important;
              "
            />
          </div>
          <div
            v-if="fieldMatch('ApiUserName')"
            class="col-xl-4 col-lg-6 col-md-12 col-xs-12 pt-3"
          >
            <label for="asm-clientToken" class="generalLabel"
              >Api User Name</label
            >
            <input
              id="asm-apiusername"
              class="form-control"
              type="text"
              autocomplete="off"
              placeholder="Api User Name"
              v-model="selectedRow.ApiUserName"
            />
          </div>
          <div
            v-if="fieldMatch('ApiUserPassword')"
            class="col-xl-4 col-lg-6 col-md-12 col-xs-12 pt-3"
          >
            <label for="asm-clientToken" class="generalLabel"
              >Api User Password</label
            >
            <input
              id="asm-apiuserpassword"
              class="form-control"
              type="text"
              autocomplete="off"
              placeholder="Api User Password"
              v-model="selectedRow.ApiUserPassword"
            />
          </div>
          <div class="col-xl-4 col-lg-6 col-md-12 col-xs-12 text-right">
            <button
              type="button"
              style="width: 100px"
              class="btn btn-success mt-2"
              @click="saveArraySettings()"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import types from "./types";
import formatMixin from "@/mixins/formatMixin";
import deepCopy from "@/helpers/deepCopy";
import {orderBy, camelCase} from "lodash";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import globalTypes from "@/store/types";

const init = {
  Id: null,
  AppKey: "",
  ClientToken: "",
  ProductCode: null,
  ProductCodeId: null,
  ProductCodes: [],
  IsTest: false,
  ServiceId: 0,
  ApiUserName: "",
  ApiUserPassword: "",
  RequiredFields: [],
};

const blockedCreditTypesInit = {
  Id: null,
  CompanyId: null,
  Type: "",
};

const blockedCreditorsInit = {
  Id: null,
  CompanyId: null,
  Name: "",
};

export default {
  name: "ArraySettingsManagement",
  components: {},
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      allSettings: [],
      selectedService: "",
      creditPullFilter: "",
      isEditing: false,
      activeItem: null,
      selectedRow: Object.assign({}, init),
      oldValue: "",
      saving: false,
      settingsBinded: false,

      creditTypeFilter: "",
      creditTypeIsEditing: false,
      creditTypeSelectedRow: deepCopy(blockedCreditTypesInit),
      creditTypesOldValue: "",
      creditTypeActiveItem: null,
      creditTypeValueToRevert: deepCopy(blockedCreditTypesInit),
      creditTypeIsCancelledBeforeSave: false,

      creditorsFilter: "",
      creditorIsEditing: false,
      creditorSelectedRow: deepCopy(blockedCreditorsInit),
      creditorOldValue: "",
      creditorActiveItem: null,
      creditorValueToRevert: deepCopy(blockedCreditorsInit),
      creditorIsCancelledBeforeSave: false,

      tabs: [
        {
          id: 1,
          name: "blockedCreditors",
          label: "Blocked Creditors",
          iconName: "",
          active: true,
        },
        {
          id: 3,
          name: "blockedCreditTypes",
          label: "Blocked Credit Types",
          iconName: "",
          active: false,
        },
        {
          id: 3,
          name: "apiSettings",
          label: "Api Settings",
          iconName: "",
          active: this.checkAuth(9988) ? false : true,
        },
      ],
    };
  },
  computed: mapState({
    creditPullServices: (state) =>
      state.arraySettingsManagement.creditPullServices,
    blockedCreditTypeList: (state) =>
      state.arraySettingsManagement.blockedCreditTypeList,
    blockedCreditorsList: (state) =>
      state.arraySettingsManagement.blockedCreditorsList,
    filteredBlockedCreditTypeList() {
      return this.blockedCreditTypeList.filter(
        (item) =>
          item.Type.toLowerCase().indexOf(this.creditTypeFilter.toLowerCase()) >
          -1
      );
    },

    filteredBlockedCreditors() {
      return this.blockedCreditorsList.filter(
        (item) =>
          item.Name.toLowerCase().indexOf(this.creditorsFilter.toLowerCase()) >
          -1
      );
    },

    filterCreditPull() {
      return this.creditPullServices
        ? orderBy(
            this.creditPullServices.filter(
              (item) =>
                item.Name.toLowerCase().indexOf(
                  this.creditPullFilter.toLowerCase()
                ) > -1
            )
          )
        : [];
    },
    selectedTab() { return this.tabs.find((tab) => tab.active) },
  }),
  async mounted() {
    this.$store.commit(globalTypes.SET_CURRENT_KO_PATH, this.$koPaths.admin.creditPullSettings[camelCase(this.selectedTab.label)]);
    this.saving = true;
    await this.$store.dispatch(types.GET_ALL_CREDIT_SERVICES);

    if (this.checkAuth(9988)) {
      await this.$store.dispatch(types.GET_ALL_BLOCKED_CREDIT_TYPES);
      await this.$store.dispatch(types.GET_ALL_BLOCKED_CREDITORS);
    }

    let err, result;
    [err, result] = await this.$store.dispatch(
      types.GET_ALL_CREDIT_PULL_SETTINGS
    );
    if (result) {
      this.allSettings = result.Data;
    }
    this.settingsBinded = true;
    this.saving = false;

  },
  methods: {
    async addNewCreditTypes() {
      if (
        this.creditTypeSelectedRow &&
        this.creditTypeSelectedRow.Id != null &&
        JSON.stringify(this.creditTypeSelectedRow) != this.creditTypesOldValue
      ) {
        await this.$swal
          .fire({
            title: "You have unsaved changes",
            text: "You will lose it if you don't save",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, continue without saving!",
          })
          .then(async (dialog) => {
            if (dialog.value) {
              this.creditTypeIsCancelledBeforeSave = true;
            } else {
              this.creditTypeIsCancelledBeforeSave = false;
              return;
            }
          });
      } else {
        this.creditTypeSelectedRow = deepCopy(blockedCreditTypesInit);
        this.creditTypeActiveItem = null;
        this.creditTypeIsEditing = true;
      }
      if (this.creditTypeIsCancelledBeforeSave) {
        this.creditTypeSelectedRow = deepCopy(this.creditTypeValueToRevert);
        let indx = this.blockedCreditTypeList.findIndex(
          (x) => x.Id == this.creditTypeValueToRevert.Id
        );
        this.blockedCreditTypeList[indx] = this.creditTypeValueToRevert;
        this.blockedCreditTypeList.push();
        this.creditTypeIsCancelledBeforeSave = false;
        this.creditTypeSelectedRow = deepCopy(init);
        this.creditTypeActiveItem = null;
        this.creditTypeIsEditing = true;
      }
    },

    async saveCreditTypes() {
      if (
        this.creditTypesOldValue == JSON.stringify(this.creditTypeSelectedRow)
      ) {
        this.$swal("Warning!", "No Changes Detected!", "warning");
        return;
      }
      this.saving = true;

      let err, result, type;

      let isTypeExists =
        this.blockedCreditTypeList.filter(
          (x) =>
            x.Type.toLowerCase() ==
            this.creditTypeSelectedRow.Type.toLowerCase()
        ).length > 0;

      if (this.creditTypeSelectedRow.Id) {
        type = types.UPDATE_BLOCKED_CREDIT_TYPE;
        isTypeExists =
          this.blockedCreditTypeList.filter(
            (x) =>
              x.Type.toLowerCase() ==
              this.creditTypeSelectedRow.Type.toLowerCase()
          ).length > 1;
      } else {
        type = types.ADD_BLOCKED_CREDIT_TYPE;
      }
      if (isTypeExists) {
        this.$swal(
          "Warning!",
          "This credit type is already exists in list!",
          "warning"
        );
        this.saving = false;
        return;
      }
      this.saving = true;

      [err, result] = await this.$store.dispatch(
        type,
        this.creditTypeSelectedRow
      );

      if (result) {
        this.$swal.fire("Success!", result.Message, "success");

        if (type == types.ADD_BLOCKED_CREDIT_TYPE) {
          this.creditTypeSelectedRow = deepCopy(result.Data);
          this.blockedCreditTypeList.push(this.creditTypeSelectedRow);
        } else if (type == types.UPDATE_BLOCKED_CREDIT_TYPE) {
          this.creditTypeSelectedRow = deepCopy(result.Data);
          let indx = this.blockedCreditTypeList.findIndex(
            (x) => x.Id == this.creditTypeSelectedRow.Id
          );
          if (indx >= 0) {
            this.blockedCreditTypeList[indx] = deepCopy(result.Data);
            this.blockedCreditTypeList.push();
          }
        }
        this.creditTypesOldValue = JSON.stringify(result.Data);
        this.creditTypeIsEditing = false;
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
      this.saving = false;
    },

    async deleteCreditTypes(Id) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then(async (dialog) => {
          if (dialog.value) {
            let rowIndex = this.blockedCreditTypeList.indexOf(
              this.creditTypeSelectedRow
            );
            let err, result;
            [err, result] = await this.$store.dispatch(
              types.DELETE_BLOCKED_CREDIT_TYPE,
              { Id: Id }
            );
            if (!err) {
              this.resetCreditTypes();
            }
            if (result) {
              this.blockedCreditTypeList.splice(rowIndex, 1);
              this.resetCreditTypes();

              this.$swal("Deleted!", result.Message, "success");
            } else {
              let errMsg = this.getApiErrorMessage(err);
              this.$swal("Error!", errMsg, "error");
            }
          }
        });
    },

    async handleSelectionCreditTypes(row) {
      if (
        this.creditTypesOldValue !=
          JSON.stringify(this.creditTypeSelectedRow) &&
        this.creditTypesOldValue != "" &&
        JSON.stringify(this.creditTypeSelectedRow) !=
          JSON.stringify(blockedCreditTypesInit)
      ) {
        await this.$swal
          .fire({
            title: "You have unsaved changes",
            text: "You will lose it if you don't save",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, continue without saving!",
          })
          .then(async (dialog) => {
            if (dialog.value) {
              this.creditTypeIsCancelledBeforeSave = true;
            } else {
              this.creditTypeIsCancelledBeforeSave = false;
              return;
            }
          });
      } else {
        this.creditTypeValueToRevert = deepCopy(row);
        this.creditTypesOldValue = JSON.stringify(row);
        this.creditTypeActiveItem = row.Id;
        this.creditTypeSelectedRow = row;
        this.creditTypeIsEditing = true;
      }
      if (this.creditTypeIsCancelledBeforeSave) {
        this.creditTypeSelectedRow = deepCopy(this.creditTypeValueToRevert);
        let indx = this.blockedCreditTypeList.findIndex(
          (x) => x.Id == this.creditTypeValueToRevert.Id
        );
        this.blockedCreditTypeList[indx] = this.creditTypeValueToRevert;
        this.blockedCreditTypeList.push();
        this.creditTypeActiveItem = row.Id;
        this.creditTypeSelectedRow = row;
        this.creditTypeValueToRevert = deepCopy(row);
        this.creditTypesOldValue = JSON.stringify(row);
        this.creditTypeIsEditing = true;
      }
      this.creditTypeIsCancelledBeforeSave = false;

      let el = this.$el.querySelector(".scrollToSelectedItem");
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
    resetCreditTypes() {
      this.creditTypeActiveItem = null;
      this.creditTypeSelectedRow = deepCopy(blockedCreditTypesInit);
      this.creditTypeIsEditing = false;
    },

    async handleSelectionCreditors(row) {
      if (
        this.creditorOldValue != JSON.stringify(this.creditorSelectedRow) &&
        this.creditorOldValue != "" &&
        JSON.stringify(this.creditorSelectedRow) !=
          JSON.stringify(blockedCreditorsInit)
      ) {
        await this.$swal
          .fire({
            title: "You have unsaved changes",
            text: "You will lose it if you don't save",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, continue without saving!",
          })
          .then(async (dialog) => {
            if (dialog.value) {
              this.creditorIsCancelledBeforeSave = true;
            } else {
              this.creditorIsCancelledBeforeSave = false;
              return;
            }
          });
      } else {
        this.creditorValueToRevert = deepCopy(row);
        this.creditorOldValue = JSON.stringify(row);
        this.creditorActiveItem = row.Id;
        this.creditorSelectedRow = row;
        this.creditorIsEditing = true;
      }
      if (this.creditorIsCancelledBeforeSave) {
        this.creditorSelectedRow = deepCopy(this.creditorValueToRevert);
        let indx = this.blockedCreditorsList.findIndex(
          (x) => x.Id == this.creditorValueToRevert.Id
        );
        this.blockedCreditorsList[indx] = this.creditorValueToRevert;
        this.blockedCreditorsList.push();
        this.creditorActiveItem = row.Id;
        this.creditorSelectedRow = row;
        this.creditorValueToRevert = deepCopy(row);
        this.creditorOldValue = JSON.stringify(row);
        this.creditorIsEditing = true;
      }
      this.creditorIsCancelledBeforeSave = false;

      let el = this.$el.querySelector(".scrollToSelectedItem");
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
    async addNewCreditors() {
      if (
        this.creditorSelectedRow &&
        this.creditorSelectedRow.Id != null &&
        JSON.stringify(this.creditorSelectedRow) != this.creditorOldValue
      ) {
        await this.$swal
          .fire({
            title: "You have unsaved changes",
            text: "You will lose it if you don't save",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, continue without saving!",
          })
          .then(async (dialog) => {
            if (dialog.value) {
              this.creditorIsCancelledBeforeSave = true;
            } else {
              this.creditorIsCancelledBeforeSave = false;
              return;
            }
          });
      } else {
        this.creditorSelectedRow = deepCopy(blockedCreditTypesInit);
        this.creditorActiveItem = null;
        this.creditorIsEditing = true;
      }
      if (this.creditorIsCancelledBeforeSave) {
        this.creditorSelectedRow = deepCopy(this.creditorValueToRevert);
        let indx = this.blockedCreditorsList.findIndex(
          (x) => x.Id == this.creditorValueToRevert.Id
        );
        this.blockedCreditorsList[indx] = this.creditorValueToRevert;
        this.blockedCreditorsList.push();
        this.creditorIsCancelledBeforeSave = false;
        this.creditorSelectedRow = deepCopy(init);
        this.creditorActiveItem = null;
        this.creditorIsEditing = true;
      }
      this.creditorIsCancelledBeforeSave = false;
    },

    async saveCreditors() {
      if (this.creditorOldValue == JSON.stringify(this.creditorSelectedRow)) {
        this.$swal("Warning!", "No Changes Detected!", "warning");
        return;
      }
      this.saving = true;
      let err, result, type;

      let isNameExist =
        this.blockedCreditorsList.filter(
          (x) =>
            x.Name.toLowerCase() == this.creditorSelectedRow.Name.toLowerCase()
        ).length > 0;

      if (this.creditorSelectedRow.Id) {
        type = types.UPDATE_BLOCKED_CREDITOR;
        isNameExist =
          this.blockedCreditorsList.filter(
            (x) =>
              x.Name.toLowerCase() ==
              this.creditorSelectedRow.Name.toLowerCase()
          ).length > 1;
      } else {
        type = types.ADD_BLOCKED_CREDITOR;
      }
      if (isNameExist) {
        this.$swal(
          "Warning!",
          "This creditor name is already exists in list!",
          "warning"
        );
        this.saving = false;
        return;
      }
      this.saving = true;

      [err, result] = await this.$store.dispatch(
        type,
        this.creditorSelectedRow
      );

      if (result) {
        this.$swal.fire("Success!", result.Message, "success");

        if (type == types.ADD_BLOCKED_CREDITOR) {
          this.creditorSelectedRow = deepCopy(result.Data);
          this.blockedCreditorsList.push(this.creditorSelectedRow);
        } else if (type == types.UPDATE_BLOCKED_CREDITOR) {
          this.creditorSelectedRow = deepCopy(result.Data);
          let indx = this.blockedCreditorsList.findIndex(
            (x) => x.Id == this.creditorSelectedRow.Id
          );
          if (indx >= 0) {
            this.blockedCreditorsList[indx] = deepCopy(result.Data);
            this.blockedCreditorsList.push();
          }
        }
        this.creditorOldValue = JSON.stringify(result.Data);
        this.creditorIsEditing = false;
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
      this.saving = false;
    },

    async deleteCreditors(Id) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then(async (dialog) => {
          if (dialog.value) {
            let rowIndex = this.blockedCreditorsList.indexOf(
              this.creditorSelectedRow
            );
            let err, result;
            [err, result] = await this.$store.dispatch(
              types.DELETE_BLOCKED_CREDITOR,
              { Id: Id }
            );
            if (!err) {
              this.resetCreditors();
            }
            if (result) {
              this.blockedCreditorsList.splice(rowIndex, 1);
              this.resetCreditors();

              this.$swal("Deleted!", result.Message, "success");
            } else {
              let errMsg = this.getApiErrorMessage(err);
              this.$swal("Error!", errMsg, "error");
            }
          }
        });
    },

    resetCreditors() {
      this.creditorActiveItem = null;
      this.creditorSelectedRow = deepCopy(blockedCreditorsInit);
      this.creditorIsEditing = false;
    },

    handleTabChange(tabName) {
      // Update status for selected tab
      this.tabs.forEach((item) => {item.active = item.name === tabName});
      
      // Update store with selected tab for ko widget
      this.$store.commit(globalTypes.SET_CURRENT_KO_PATH, this.$koPaths.admin.creditPullSettings[camelCase(this.selectedTab.label)]);
    },
    async getRequiredFields(serviceId) {
      let rtn = [];

      let err, result;
      [err, result] = await this.$store.dispatch(types.GET_SERVICE_COLUMNS, {
        ServiceId: serviceId,
      });

      if (result && result.Data) {
        rtn = result.Data.RequiredFields;
      }

      return rtn;
    },
    fieldMatch(key) {
      let rtn = true;
      if (
        this.selectedRow &&
        this.selectedRow.RequiredFields &&
        this.selectedRow.RequiredFields.length > 0
      ) {
        rtn = this.selectedRow.RequiredFields.includes(key);
      }
      return rtn;
    },
    updateSettingList() {
      let indx = this.allSettings.findIndex((x) => x.Id == this.selectedRow.Id);
      if (indx > -1) {
        this.allSettings[indx] = deepCopy(this.selectedRow);
      }
    },
    isTestChange() {
      if (this.selectedRow.IsTest == false) {
        this.selectedRow.IsTest == true;
      } else {
        this.selectedRow.IsTest == false;
      }
    },
    async saveArraySettings() {
      if (this.selectedRow.AppKey == "" && this.fieldMatch("AppKey")) {
        this.$swal.fire("Warning!", "AppKey is required.", "warning");
        return;
      }
      if (
        this.selectedRow.ClientToken == "" &&
        this.fieldMatch("ClientToken")
      ) {
        this.$swal.fire("Warning!", "Client Token is required.", "warning");
        return;
      }
      if (
        this.selectedRow.ProductCodeId == 0 &&
        this.fieldMatch("ProductCodes")
      ) {
        this.$swal.fire("Warning!", "Product Code is required.", "warning");
        return;
      }
      if (
        this.selectedRow.ApiUserName == "" &&
        this.fieldMatch("ApiUserName")
      ) {
        this.$swal.fire("Warning!", "Api User Name  is required.", "warning");
        return;
      }
      if (
        this.selectedRow.ApiUserPassword == "" &&
        this.fieldMatch("ApiUserPassword")
      ) {
        this.$swal.fire(
          "Warning!",
          "Api User Password  is required.",
          "warning"
        );
        return;
      }

      if (JSON.stringify(this.selectedRow) != this.oldValue) {
        this.saving = true;

        let type =
          this.selectedRow.Id == null
            ? types.INSERT_CREDIT_PULL_SETTINGS
            : types.UPDATE_CREDIT_PULL_SETTINGS;
        let err, result;
        [err, result] = await this.$store.dispatch(type, this.selectedRow);
        if (result) {
          this.$swal.fire("Success!", "Settings updated.", "success");
          this.selectedRow = result.Data;
          this.oldValue = JSON.stringify(this.selectedRow);
          if (type == types.UPDATE_CREDIT_PULL_SETTINGS) {
            this.updateSettingList();
          } else {
            this.allSettings.push(this.selectedRow);
          }
        } else {
          let errMsg = this.getApiErrorMessage(err);
          this.$swal("Error!", errMsg, "error");
        }
        this.saving = false;
      } else {
        this.$swal.fire("Warning!", "No changes detected.", "warning");
      }
    },
    async handleSelection(row, index) {
      if (this.settingsBinded == false) {
        return;
      }

      let isCancelled = false;
      if (
        this.selectedRow &&
        JSON.stringify(this.selectedRow) != this.oldValue &&
        JSON.stringify(this.selectedRow) != JSON.stringify(init)
      ) {
        await this.$swal
          .fire({
            title: "You have unsaved changes",
            text: "You will lose it if you don't save",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, continue without saving!",
          })
          .then(async (dialog) => {
            if (dialog.value) {
              isCancelled = false;
            } else {
              isCancelled = true;
              return;
            }
          });
      }

      if (!isCancelled) {
        let indx = this.allSettings.findIndex((x) => x.ServiceId == row.Id);
        if (indx > -1) {
          this.selectedRow = deepCopy(this.allSettings[indx]);
          this.oldValue = JSON.stringify(this.selectedRow);
          this.activeItem = index;
          this.isEditing = true;
          this.selectedService = row.Name;
        } else {
          this.selectedRow = deepCopy(init);
          this.selectedRow.RequiredFields = await this.getRequiredFields(
            row.Id
          );
          this.selectedRow.ServiceId = row.Id;
          this.oldValue = JSON.stringify(this.selectedRow);
          this.activeItem = index;
          this.isEditing = true;
          this.selectedService = row.Name;
        }
      }
      isCancelled = false;

      let el = this.$el.querySelector(".scrollToSelectedItem");
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>

<style scoped>
.tab-item .active {
  background-color: #3bafda;
  color: #ffffff !important;
}
</style>
