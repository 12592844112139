export default {
  GET_TASK_TYPES: "GET_TASK_TYPES",
  GET_TASK_TYPES_Simplified: "GET_TASK_TYPES_Simplified",
  SET_TASK_TYPE: "SET_TASK_TYPE",
  SET_TASK_TYPE_Simplified: "SET_TASK_TYPE_Simplified",
  INSERT_TASK_TYPE: "INSERT_TASK_TYPE",
  UPDATE_TASK_TYPE: "UPDATE_TASK_TYPE",
  GET_QUICK_EMAILS: "GET_QUICK_EMAILS",
  SET_QUICK_EMAILS: "SET_QUICK_EMAILS",
  GET_TASK_TYPE_NAMES_TEST: "GET_TASK_TYPE_NAMES_TEST",
  SET_TASK_TYPE_NAMES_TEST: "SET_TASK_TYPE_NAMES_TEST",
  GET_CSR_TASK_TRIGGER_TYPE: "GET_CSR_TASK_TRIGGER_TYPE",
  SET_CSR_TASK_TRIGGER_TYPE: "SET_CSR_TASK_TRIGGER_TYPE",
  GET_SPEECH_TEXT: "GET_SPEECH_TEXT",
};
