<template>
  <div v-show="feeGridLoading" class="loading">
    <div class="">
      <div style="width: 150px; display: flex; justify-content: center">
        <div class="calculate-loader"></div>
      </div>
      <button class="btn btn-primary mt-4" type="button" disabled="">
        Calculating Fees...
      </button>
    </div>
  </div>
  <div
    class="col-md-12 row newPanelWrapper noPadding"
    style="border-top-right-radius: 0px; border-top-left-radius: 0px"
  >
    <div v-if="isLoading" class="loading">
      <div class="text-center" style="margin-top: 150px">
        <i class="spinner-grow text-info m-2"></i>
      </div>
    </div>
    <div v-if="settlementTask" class="col-md-12 row noPadding noMargin">
      <div class="col-xl-12 col-md-12 col-sm-12 col-12">
        <div
          class="col-md-12 row ent-border"
          style="position: relative"
          :style="
            attorneyInfo != null
              ? 'background: linear-gradient(rgb(244, 249, 255) 0%, rgb(255, 219, 219) 100%)'
              : 'background-color: #f4f9ff'
          "
        >
          <div class="taskButtonsWrapper">
            <button
              type="button"
              class="btn mt-1 float-right taskButton"
              title="Go to Profile"
              @click="showCustomer()"
            >
              <i style="font-size: 16px" class="fas fa-user"></i>
            </button>
            <button
              type="button"
              style="background-color: #1abc9c !important"
              class="btn mt-1 float-right taskButton"
              :disabled="activeCoCustomers.length == 0"
              :style="
                activeCoCustomers.length == 0 ? 'opacity:0.2' : 'opacity:1'
              "
              title="Co-Customers"
              @click="openCoCustomerInfoPopup"
            >
              <i
                style="font-size: 16px; margin-left: -4px !important"
                class="fas fa-users"
              ></i>
            </button>
            <button
              type="button"
              class="btn mt-2 float-right taskButton"
              title="Customer Notes"
              @click="openViewNotes"
              v-if="checkAuth(3816)"
            >
              <i style="font-size: 16px" class="fas fa-file-alt"></i>
            </button>
            <button
              type="button"
              class="btn mt-2 float-right taskButton"
              @click="openQuickEmails()"
              v-if="checkAuth(5146)"
              title="Quick Emails"
            >
              <i style="font-size: 16px" class="fas fa-envelope"></i>
            </button>
            <button
              type="button"
              class="btn mt-2 float-right taskButton"
              title="Reminders"
              @click="openReminder"
            >
              <i style="font-size: 16px" class="fas fa-clock"></i>
            </button>
          </div>
          <div class="form-group col-xl-4 col-lg-5 col-md-11 col-xs-12">
            <div class="row">
              <div class="col-md-3 noPadding">
                <label class="generalLabel">Cust. Name</label>
              </div>
              <div class="col-md-9 noPadding">
                <label class="textLabel">{{
                  settlementTask.Creditor.Customer.Name
                }}</label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3 noPadding">
                <label class="generalLabel">Customer Id </label>
              </div>
              <div class="col-md-3 noPadding">
                <label
                  v-if="settlementTask && settlementTask.Creditor.Customer.Id"
                  class="textLabel badge badge-info"
                  style="font-size: 13px"
                >
                  {{
                    addPrefixToCustomerId(settlementTask.Creditor.Customer.Id)
                  }}</label
                >
              </div>
              <div class="col-md-2 noPadding">
                <label class="generalLabel">Task Id</label>
              </div>
              <div class="col-md-2 noPadding">
                <label
                  class="textLabel badge badge-secondary"
                  style="font-size: 13px"
                  >{{ settlementTask.Id }}</label
                >
              </div>
            </div>
            <div class="row">
              <div class="col-md-3 noPadding">
                <label class="generalLabel">SSN</label>
              </div>
              <div class="col-md-3 noPadding">
                <label class="textLabel"
                  >{{ formatSSN(settlementTask.Creditor.Customer.Ssn) }}
                </label>
              </div>
              <div class="col-md-2 noPadding">
                <label class="generalLabel">DOB</label>
              </div>
              <div class="col-md-3 noPadding">
                <label class="textLabel"
                  >{{
                    formatShortDate(settlementTask.Creditor.Customer.BirthDay)
                  }}
                </label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3 noPadding">
                <label class="generalLabel">Email</label>
              </div>
              <div class="col-md-9 noPadding">
                <label class="textLabel">{{
                  settlementTask.Creditor.Customer.Email
                }}</label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3 noPadding">
                <label class="generalLabel">Phone</label>
              </div>
              <div class="col-md-3 noPadding">
                <label class="textLabel">{{
                  settlementTask.Creditor &&
                  settlementTask.Creditor.Customer &&
                  settlementTask.Creditor.Customer.PhoneNumber
                    ? formatPhone(settlementTask.Creditor.Customer.PhoneNumber)
                    : ""
                }}</label>
              </div>
              <div v-if="checkAuth(81406)" class="col-md-2 noPadding">
                <label class="generalLabel">Creditor Id</label>
              </div>
              <div v-if="checkAuth(81406)" class="col-md-2 noPadding">
                <label
                  class="textLabel badge badge-secondary"
                  style="font-size: 13px"
                  >{{ settlementTask.Creditor.Id }}</label
                >
              </div>
            </div>
            <div class="row">
              <div class="col-md-3 noPadding">
                <label class="generalLabel">Address</label>
              </div>
              <div class="col-md-9 noPadding">
                <label class="textLabel">{{
                  settlementTask.Creditor.Customer.Address
                }}</label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3 noPadding">
                <label class="generalLabel">Income</label>
              </div>
              <div class="col-md-3 noPadding">
                <label class="textLabel">{{ formatMoney(getIncome()) }}</label>
              </div>
              <div class="col-md-3 noPadding">
                <label class="generalLabel">Expenses</label>
              </div>
              <div class="col-md-3 noPadding">
                <label class="textLabel">{{
                  formatMoney(getTotalExpenses())
                }}</label>
              </div>
            </div>
          </div>
          <div class="form-group col-xl-3 col-lg-6 col-md-11 col-xs-12">
            <div class="row">
              <div class="col-md-6 noPadding">
                <label class="generalLabel">Global Balance</label>
              </div>
              <div class="col-md-6 noPadding">
                <label
                  class="textLabel"
                  :style="
                    settlementTask.GlobalBalanceOffline >= 0
                      ? 'color:green;'
                      : 'color:red;'
                  "
                  >{{ formatMoney(settlementTask.GlobalBalanceOffline) }}</label
                >
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 noPadding">
                <label class="generalLabel">Creditor Name</label>
              </div>
              <div class="col-md-6 noPadding">
                <label class="textLabel">{{
                  settlementTask.Creditor.Name
                }}</label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 noPadding">
                <label class="generalLabel">Account Number</label>
              </div>
              <div class="col-md-6 noPadding">
                <input
                  autocomplete="off"
                  v-model="settlementTask.Creditor.AccountNumber"
                  type="text"
                  class="blueBoxControl readonlyInput"
                  disabled="disabled"
                  style="width: 150px"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 noPadding">
                <label class="generalLabel">Account Holder</label>
              </div>
              <div class="col-md-6 noPadding">
                <label class="textLabel"
                  >{{ settlementTask.Creditor.AccountHolder }}
                </label>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 noPadding">
                <label class="generalLabel">Original Balance</label>
              </div>
              <div class="col-md-6 noPadding">
                <label class="textLabel"
                  >{{ formatMoney(settlementTask.Creditor.OriginalBalance) }}
                </label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 noPadding">
                <label class="generalLabel">Office Name</label>
              </div>
              <div class="col-md-6 noPadding">
                <label class="textLabel"
                  >{{ settlementTask.Creditor.Customer.Office }}
                </label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 noPadding">
                <label class="generalLabel">Enrolled Debt - Date</label>
              </div>
              <div class="col-md-6 noPadding">
                <label class="textLabel"
                  >{{
                    formatMoney(settlementTask.Creditor.Customer.EnrolledDebt)
                  }}
                  -
                  {{
                    formatShortDate(
                      settlementTask.Creditor.Customer.EnrolledDate
                    )
                  }}
                </label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 noPadding">
                <label class="generalLabel">Days Delinquent</label>
              </div>
              <div class="col-md-6 noPadding">
                <label class="textLabel">{{
                  settlementTask.Delinquent != null ? settlementTask.Delinquent : "N/A"
                }}</label>
              </div>
            </div>
          </div>
          <div class="form-group col-xl-4 col-lg-11 col-md-11 col-xs-12">
            <div class="row">
              <div class="col-md-6 noPadding">
                <label class="generalLabel">Settlement Request Date</label>
              </div>
              <div class="col-md-6 noPadding">
                <label class="generalLabel"
                  >{{
                    settlementTask.Creditor.SettlementRequestDate != null
                      ? new Date(
                          settlementTask.Creditor.SettlementRequestDate
                        ).toLocaleDateString()
                      : ""
                  }}
                </label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 noPadding">
                <label class="generalLabel">Current Creditor Name</label>
              </div>
              <div class="col-md-6 noPadding">
                <label class="generalLabel">
                  {{ settlementTask.Creditor.CurrentCreditorName }}</label
                >
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 noPadding">
                <label class="generalLabel">Current Creditor Account #</label>
              </div>
              <div class="col-md-6 noPadding">
                <input
                  autocomplete="off"
                  v-model="settlementTask.Creditor.CurrentCreditorAccountNumber"
                  type="text"
                  class="blueBoxControl readonlyInput"
                  disabled="disabled"
                  style="width: 150px"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 noPadding">
                <label class="generalLabel">Current Creditor Balance</label>
              </div>
              <div class="col-md-6 noPadding">
                <input
                  autocomplete="off"
                  v-model="settlementTask.Creditor.CurrentCreditorBalance"
                  type="text"
                  :disabled="!checkAuth(32992)"
                  :class="
                    !checkAuth(32992)
                      ? 'blueBoxControl readonlyInput'
                      : 'blueBoxControl'
                  "
                  style="width: 150px"
                  v-myMask="{
                    alias: 'currency',
                    prefix: '$',
                    rightAlign: false,
                  }"
                  @change="updateOriginalBalancePercentage"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-2 noPadding">
                <label class="generalLabel">Hardship:</label>
              </div>
              <div class="col-md-6 noPadding">
                <label
                  class="textLabel"
                  v-if="settlementTask.Creditor.Customer.Hardship"
                  >{{ settlementTask.Creditor.Customer.Hardship }}
                  <i
                    v-if="settlementTask.Creditor.Customer.HardshipStatement"
                    :class="isHardshipStatementPopupOpen ? 'closeColor' : ''"
                    @click="openHardshipStatementPopup"
                    style="cursor: pointer"
                    class="fas fa-sticky-note"
                  ></i>
                </label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 noPadding">
                <label class="generalLabel"
                  >Unsettled / Total # of Accounts</label
                >
              </div>
              <div class="col-md-6 noPadding">
                <label class="textLabel">
                  {{
                    settlementTask.Creditor.Customer.NumberOfUnsettledAccount
                  }}
                  / {{ settlementTask.Creditor.Customer.NumberOfAccounts }}
                </label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 noPadding">
                <label class="generalLabel">Next Draft Amount - Date</label>
              </div>
              <div class="col-md-6 noPadding">
                <label class="textLabel">
                  {{
                    settlementTask.NextDraftAmount == null
                      ? "$0.00"
                      : formatMoney(settlementTask.NextDraftAmount)
                  }}
                  {{ settlementTask.NextDraftDate == null ? "" : "-" }}
                  {{ formatShortDate(settlementTask.NextDraftDate) }}
                </label>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 noPadding">
                <label class="generalLabel">Remaining Draft Count</label>
              </div>
              <div class="col-md-6 noPadding">
                <label class="textLabel">{{
                  settlementTask.RemainingDraftCount
                }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 row noPadding">
      <div
        :style="
          settlementTask.Creditor.SettlementStatus.Id == 4
            ? 'pointer-events: none; opacity:0.7;'
            : ''
        "
        class="col-xl-8 col-lg-12 col-md-12 col-xs-12"
      >
        <div class="col-md-12 row ent-border">
          <div class="form-group col-xl-4 pt-3">
            <label class="generalLabel">Payee</label>

            <input
              autocomplete="off"
              v-model="settlementTask.Payee"
              type="text"
              id="payeInp"
              class="form-control"
              placeholder="Payee"
            />
          </div>

          <div class="form-group col-xl-4 pt-3">
            <label class="generalLabel">Payee Phone #</label>
            <input
              autocomplete="off"
              v-model="settlementTask.PayeePhone"
              @change="payeePhoneChanged"
              type="text"
              id="payeePhoneInp"
              class="form-control"
              v-myMask="{ mask: ['(999) 999-9999'] }"
              placeholder="Payee Phone"
            />
          </div>

          <div class="form-group col-xl-4 pt-3">
            <label class="generalLabel">Settlement Offer</label>

            <input
              placeholder="Settlement Offer"
              autocomplete="off"
              class="form-control"
              id="settlementOfferInp"
              type="text"
              v-model="settlementTask.Offer"
              @input="
                () => {
                  getCashFlowData();
                  if (this.paymentPlannerId == 1) {
                    this.setPaymentDetails();
                  } else if (this.paymentPlannerId == 2) {
                    this.setPaymentDetailsPaymentPlanner();
                  }
                  updateOriginalBalancePercentage();
                }
              "
              v-myMask="{
                alias: 'currency',
                prefix: '$',
                rightAlign: false,
              }"
            />
          </div>

          <div
            class="form-group pt-3"
            :class="
              overrideFeeCheckBox && amountWaived > 0 ? 'col-xl-2' : 'col-xl-4'
            "
          >
            <div>
              <label class="generalLabel" style="color: red"
                >Settlement Fee</label
              >
              <div
                v-if="
                  settlementTask.SettlementFeeOverride != null ||
                  checkAuth(572945)
                "
                style="display: flex; align-items: baseline"
              >
                <label style="font-size: 12px">Override Fee</label>
                <input
                  v-model="overrideFeeCheckBox"
                  type="checkbox"
                  class="ml-1"
                  :disabled="!checkAuth(572945)"
                  @change="overrideFeeChange()"
                />
              </div>
            </div>

            <input
              v-if="overrideFeeCheckBox == true"
              class="form-control"
              :disabled="!checkAuth(572945)"
              :class="checkAuth(572945) ? '' : 'readOnlyInput'"
              type="text"
              v-model="settlementTask.SettlementFee"
              v-myMask="{
                alias: 'currency',
                prefix: '$',
                rightAlign: false,
              }"
              @change="
                () => {
                  if (checkAuth(572945)) {
                    if (this.paymentPlannerId == 1) {
                      this.setPaymentDetails();
                    } else if (this.paymentPlannerId == 2) {
                      this.setPaymentDetailsPaymentPlanner();
                    }
                    calculateAmountWaived();
                  }
                }
              "
            />

            <label
              v-if="overrideFeeCheckBox == false"
              class="generalLabel full-width"
              >{{
                remainingFeeTotal > 0
                  ? formatMoney(settlementFeeBackup)
                  : formatMoney(settlementTask.SettlementFee)
              }}</label
            >
            <label
              v-if="
                overrideFeeCheckBox == false &&
                remainingFeeTotal &&
                remainingFeeTotal > 0
              "
              class="w-100"
              style="font-size: 12px; color: #3bafda"
              >({{ formatMoney(remainingFeeTotal) }} remaining)</label
            >
          </div>
          <div
            class="form-group col-xl-2 pt-3"
            v-if="overrideFeeCheckBox && amountWaived > 0"
          >
            <label class="generalLabel" style="color: red"
              >Amount Waived:</label
            >
            <label class="generalLabel full-width">{{
              formatMoney(amountWaived)
            }}</label>
          </div>
          <div class="form-group col-xl-4 pt-3">
            <div style="display: flex; align-items: baseline">
              <label class="generalLabel">Payment Type</label>
              <a
                v-if="settlementTask.PaymentType.Id == 6"
                style="font-size: 16px; cursor: pointer; color: #37cde6"
                class="fas fa-info-circle ml-2"
                @click="phonePayInfoButton"
              >
              </a>
            </div>
            <div>
              <select
                v-model="settlementTask.PaymentType.Id"
                class="form-control"
                id="selectPaymentType"
                @change="paymentTypeChange"
              >
                <option value="0">Select a Type</option>
                <option
                  v-for="item in filteredPaymentTypes"
                  :key="item.Id"
                  :value="item.Id"
                >
                  {{ item.Name }}
                  {{
                    settlementTask.IsDpg ||
                    settlementTask.PaymentProcessorId == 3
                      ? ""
                      : "(" + formatMoney(item.TransactionFee) + ")"
                  }}
                </option>
              </select>
            </div>
          </div>

          <div class="form-group col-xl-4 pt-3">
            <div>
              <label class="generalLabel">Payment Day</label>
            </div>
            <div>
              <DatePicker
                mode="date"
                :locale="'en-US'"
                v-model="settlementTask.PaymentDay"
                :model-config="modelConfig"
                :min-date="new Date(1, 1, 1970)"
                :max-date="new Date(2100, 12, 31)"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                    autocomplete="off"
                    id="paymentDayDate"
                    class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                    :value="inputValue"
                    v-on="inputEvents"
                    placeholder="Payment Day"
                  />
                </template>
              </DatePicker>
            </div>
          </div>

          <div class="form-group col-xl-3 pt-3" v-if="checkAuth(33662)">
            <label class="generalLabel"> Today’s Creditor Balance</label>

            <input
              autocomplete="off"
              class="blueBoxControl"
              id="currentBalanceImp"
              type="text"
              v-model="settlementTask.Creditor.CurrentBalance"
              v-myMask="{
                alias: 'currency',
                prefix: '$',
                rightAlign: false,
              }"
              @change="updateOriginalBalancePercentage"
            />
          </div>
          <div class="form-group col-xl-3 pt-3" v-if="!checkAuth(33662)"></div>
          <div class="form-group col-xl-1 pt-3"></div>
          <div class="form-group col-xl-4 pt-3" v-if="checkAuth(33662)">
            <label class="generalLabel">Today's Balance %</label>
            <label class="generalLabel full-width"
              >{{ currentBalancePerc }}%</label
            >
          </div>
          <div class="form-group col-xl-4 pt-3" v-if="!checkAuth(33662)"></div>
          <div class="form-group col-xl-4 pt-3">
            <label class="generalLabel">Balance %</label>
            <input
              autocomplete="off"
              v-myMask="{
                alias: 'decimal',
                min: 0,
                max: 1000,
                digits: 2,
                allowMinus: false,
                rightAlign: false,
              }"
              class="generalTextBox form-control"
              type="text"
              v-model="originalBalancePercentage"
              @input="originalBalanceChange"
            />
          </div>

          <div class="form-group row col-xl-4 pt-3">
            <div>
              <label class="generalLabel pr-2">Client Notified</label>
            </div>
            <div style="display: flex">
              <input
                autocomplete="off"
                v-model="settlementTask.IsClientNotified"
                type="checkbox"
                id="clientNotifiedImp"
                class="checkbox-input generalCheckBox"
                style="
                  border-radius: 4px !important;
                  border: solid 1px #d1d1d1 !important;
                "
              />
            </div>
          </div>
          <div class="form-group row col-xl-4 pt-3">
            <div>
              <label class="generalLabel pr-2">POA</label>
            </div>

            <div style="display: flex">
              <input
                autocomplete="off"
                v-model="settlementTask.Creditor.IsPOA"
                type="checkbox"
                id="poaCheckBox"
                class="checkbox-input generalCheckBox"
                style="
                  border-radius: 4px !important;
                  border: solid 1px #d1d1d1 !important;
                "
              />
            </div>
          </div>

          <div class="form-group col-xl-4 row pt-3">
            <div>
              <label class="generalLabel pr-2">Lawsuit</label>
            </div>

            <div style="display: flex">
              <input
                autocomplete="off"
                v-model="settlementTask.Creditor.IsLawSuit"
                type="checkbox"
                id="lawsuitCheckBox"
                class="checkbox-input generalCheckBox"
                style="
                  border-radius: 4px !important;
                  border: solid 1px #d1d1d1 !important;
                "
              />
            </div>
          </div>

          <div class="form-group col-xl-4 row pt-3">
            <div>
              <label class="generalLabel pr-2">Additional Fund</label>
            </div>

            <div style="display: flex">
              <input
                autocomplete="off"
                v-model="settlementTask.IsAdditionalFund"
                type="checkbox"
                id="additionalFundCheckBox"
                class="checkbox-input generalCheckBox"
                style="
                  border-radius: 4px !important;
                  border: solid 1px #d1d1d1 !important;
                "
              />
            </div>
            <div style="display: flex">
              <input
                autocomplete="off"
                v-if="settlementTask.IsAdditionalFund"
                class="blueBoxControl"
                id="additionalFundLab"
                style="padding: 0 3px"
                type="text"
                v-model="settlementTask.AdditionalFundAmount"
                v-myMask="{
                  alias: 'currency',
                  prefix: '$',
                  rightAlign: false,
                }"
              />
            </div>
          </div>

          <div class="form-group col-xl-4 row pt-3" v-if="hasAuth(53345)">
            <div>
              <label class="generalLabel pr-2">EG Attorney</label>
            </div>

            <div style="display: flex">
              <input
                autocomplete="off"
                v-model="settlementTask.EGAttorney"
                type="checkbox"
                id="IsEGAttorney"
                class="checkbox-input generalCheckBox"
                style="
                  border-radius: 4px !important;
                  border: solid 1px #d1d1d1 !important;
                "
              />
            </div>
            <div style="display: flex" v-if="settlementTask.EGAttorney">
              <input
                placeholder="Attorney Name"
                autocomplete="off"
                class="blueBoxControl"
                style="padding: 0 3px; width: 166px"
                type="text"
                v-model="settlementTask.AttorneyName"
              />
            </div>
          </div>

          <div
            class="form-group col-xl-4 row pt-3"
            v-if="
              checkAuth(921096) &&
              settlementTask.Creditor &&
              settlementTask.Creditor.SettlementStatus &&
              !settlementStatusIds.includes(
                settlementTask.Creditor.SettlementStatus.Id
              )
            "
          >
            <div>
              <label class="generalLabel pr-2"
                >Disable Ongoing Settlements</label
              >
            </div>

            <div style="display: flex">
              <input
                autocomplete="off"
                type="checkbox"
                v-model="disableOngoingChk"
                @change="disableOngoingSettlementChange"
                id="lawsuitCheckBox"
                class="checkbox-input generalCheckBox"
                style="
                  border-radius: 4px !important;
                  border: solid 1px #d1d1d1 !important;
                "
              />
            </div>
          </div>

          <div class="form-group row col-xl-12 pt-3 noPadding">
            <div class="mt-2 ml-2">
              <label class="generalLabel pr-2">Term Settlement</label>
            </div>
            <div style="display: flex" class="mt-2">
              <input
                autocomplete="off"
                v-model="settlementTask.IsTermSettlement"
                @change="termSettlementChanged"
                type="checkbox"
                id="termSettlementInp"
                class="checkbox-input generalCheckBox"
                style="
                  border-radius: 4px !important;
                  border: solid 1px #d1d1d1 !important;
                "
              />
            </div>
            <div v-if="negativeCashFlowAlert" class="mt-2 ml-2 flex-fill">
              <div class="negativeCashFlowPanel">
                <label>Negative Cash Flow !</label>
              </div>
            </div>
            <div class="col-md-12 noPadding noMargin">
              <ul
                class="nav nav-tabs"
                style="margin-top: 10px !important"
                v-if="
                  settlementTask &&
                  settlementTask.IsTermSettlement &&
                  checkAuth(10155)
                "
              >
                <li
                  v-for="tab in tabs.filter((x) => x.show)"
                  :key="tab.id"
                  class="nav-item tab-item"
                >
                  <a
                    data-toggle="tab"
                    :aria-expanded="tab.active"
                    v-on:click.prevent="() => handleTabChange(tab)"
                    class="tab-link"
                    :class="{ active: tab.active }"
                  >
                    <span class="d-none d-sm-inline-block tab-item-label">
                      {{ tab.label }}
                    </span>
                  </a>
                </li>
              </ul>
            </div>
            <div class="plannerWrapper">
              <div
                v-if="tableLoading"
                class="loadingAbsoluteBox"
                style="background: rgba(255, 255, 255, 0.5)"
              >
                <div class="loadingSpinner">
                  <i class="spinner-border text-primary m-2"></i>
                </div>
              </div>
              <div
                v-if="
                  settlementTask.IsTermSettlement &&
                  tabs[0].active &&
                  !checkAuth(10155)
                "
                class="col-md-12 noPadding noMargin"
                style="border: 1px solid rgb(209, 209, 209); padding-top: 20px"
              >
                <div
                  class="col-md-12 row noMargin"
                  style="display: flex; align-items: center"
                >
                  <div class="col-md-12 mt-2">
                    <label class="generalLabel"># of payments</label>
                    <input
                      type="number"
                      class="generalTextBox ml-2"
                      style="width: 80px"
                      :disabled="
                        settlementTask.Creditor.SettlementStatus.Id == 4
                      "
                      :class="
                        settlementTask.Creditor.SettlementStatus.Id == 4
                          ? 'readonlyInput'
                          : ''
                      "
                      v-model="settlementTask.NumberOfPayments"
                      @change="
                        updateNumberOfPayments(settlementTask.NumberOfPayments)
                      "
                    />

                    <!-- <CustomNumericInput
                    id="numberOfPaymentsInput"
                    :value="settlementTask.NumberOfPayments"
                    :disabled="settlementTask.Creditor.SettlementStatus.Id == 4"
                    @updateValue="updateNumberOfPayments"
                    class="generalTextBox ml-2"
                    style="width: 80px"
                  /> -->

                    <div style="width: auto; float: right">
                      <div style="width: 20px; float: left" class="ml-2 mr-1">
                        <input
                          type="checkbox"
                          class="form-control"
                          v-model="autoCalculateOpen"
                          :disabled="
                            settlementTask.Creditor.SettlementStatus.Id == 4
                          "
                        />
                      </div>
                      <div
                        :style="
                          settlementTask.Creditor.SettlementStatus.Id == 4
                            ? 'opacity:0.4'
                            : ''
                        "
                        style="
                          width: auto;
                          float: left;
                          margin: 5px 0px;
                          font-size: 16px;
                        "
                      >
                        <span class="badge badge-success">Auto-Calculate</span>
                      </div>
                    </div>
                    <div
                      :style="
                        settlementTask.Creditor.SettlementStatus.Id == 4
                          ? 'opacity:0.4'
                          : ''
                      "
                      :disabled="
                        settlementTask.Creditor.SettlementStatus.Id == 4
                      "
                      @click="adjustDates()"
                      style="
                        width: auto;
                        float: right;
                        margin: 5px 12px 10px 10px;
                        font-size: 16px;
                        cursor: pointer;
                      "
                    >
                      <span style="padding: 5px" class="badge badge-info"
                        >Adjust Dates</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-12 noPadding">
                  <div
                    class="col-md-12 row"
                    style="display: flex; align-items: center"
                  >
                    <div class="col-md-12" style="display: flex">
                      <label class="generalLabel">Repeats:</label>
                      <input
                        type="radio"
                        name="repeatsPayment"
                        :disabled="
                          settlementTask.Creditor.SettlementStatus.Id == 4
                        "
                        @change="paymentRepeatsChanged"
                        :checked="!repeatsDayActive"
                        id="repeatsMonth"
                        class="checkbox-input generalCheckBox ml-1"
                        style="
                          border-radius: 4px !important;
                          border: solid 1px #d1d1d1 !important;
                        "
                      />

                      <label class="generalLabel ml-2">Every Month</label>
                      <input
                        type="radio"
                        name="repeatsPayment"
                        :disabled="
                          settlementTask.Creditor.SettlementStatus.Id == 4
                        "
                        @change="paymentRepeatsChanged"
                        :checked="repeatsDayActive"
                        id="repeatsDay"
                        class="checkbox-input generalCheckBox ml-2"
                        style="
                          border-radius: 4px !important;
                          border: solid 1px #d1d1d1 !important;
                        "
                      />
                      <label class="generalLabel ml-2">Every</label>

                      <input
                        autocomplete="off"
                        v-model="settlementTask.RepeatsOfPayments"
                        :disabled="
                          !repeatsDayActive ||
                          settlementTask.Creditor.SettlementStatus.Id == 4
                        "
                        @input="updateRepeatsOfPayments"
                        type="text"
                        id="repeatsOfPaymentInput"
                        class="generalTextBox ml-2"
                        style="width: 80px; margin-top: -8px"
                        v-myMask="{
                          alias: 'numeric',
                          prefix: '',
                          min: 1,
                          max: 90,
                          rightAlign: false,
                        }"
                      />
                      <label class="generalLabel ml-2"> days</label>
                    </div>
                  </div>
                </div>
                <perfect-scrollbar style="max-height: 60vh">
                  <div
                    class="col-md-12 negotiatorTableScroll noPadding"
                    v-click-outside="outsideGridRow"
                  >
                    <table
                      class="datagrid-table creditorTable paymentGrid"
                      style="
                        width: 100%;
                        height: 100%;
                        border: none !important;
                        background-color: rgb(255, 255, 255);
                      "
                    >
                      <thead>
                        <tr>
                          <th width="20%">Pmt Date</th>
                          <th width="20%">Pmt Amount</th>
                          <th width="25%">Pmt Type</th>
                          <th width="15%">Fee Amount</th>
                          <th width="15%">Fee %</th>
                          <th width="10%"></th>
                          <th width="10%"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in settlementTask.PaymentDetails"
                          :key="item.Id"
                        >
                          <td>
                            <!-- <span
                            class="tabledit-span tabledit-identifier"
                            v-show="!item.isEditing"
                          >
                            {{ formatShortDate(item.PaymentDate) }}
                          </span> -->
                            <div class="datepickerWrapper">
                              <DatePicker
                                mode="date"
                                :locale="'en-US'"
                                v-model="item.PaymentDate"
                                :min-date="new Date(1, 1, 1970)"
                                :max-date="new Date(2100, 12, 31)"
                                :model-config="modelConfig"
                              >
                                <template v-slot="{ inputValue, inputEvents }">
                                  <input
                                    autocomplete="off"
                                    id="patmentDateInput"
                                    class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                                    :value="inputValue"
                                    v-on="inputEvents"
                                    @blur="paymentDateChange(item)"
                                  />
                                </template>
                              </DatePicker>
                            </div>
                          </td>
                          <td>
                            <!-- <span
                            class="tabledit-span tabledit-identifier"
                            v-show="!item.isEditing"
                          >
                            {{ formatMoney(item.PaymentAmount) }}
                          </span> -->
                            <span class="paymentGridCurrencyIcon">$</span>
                            <input
                              autocomplete="off"
                              class="generalTextBox form-control paymentGridDecimalInputs"
                              type="number"
                              min="0"
                              v-model="item.PaymentAmount"
                              @keyup="paymentAmountKeyUp(item)"
                              @change="paymentAmountChange(item)"
                            />
                          </td>
                          <td>
                            <select
                              :disabled="!checkAuth(56962)"
                              :class="!checkAuth(56962) ? 'readonlyInput' : ''"
                              class="form-control"
                              v-model="item.PaymentTypeId"
                              @change="paymentTypeChanged()"
                            >
                              <option :value="null">Please select</option>
                              <option v-if="paymentPlannerId == 1" :value="0">
                                Not selected
                              </option>
                              <option
                                v-for="t in filteredPaymentTypes"
                                :value="t.Id"
                                :key="t.Id"
                              >
                                {{ t.Name }}
                              </option>
                            </select>
                          </td>
                          <td>
                            <!-- <span
                            class="tabledit-span tabledit-identifier"
                            v-show="!item.isEditing"
                          >
                            {{ formatMoney(item.FeeAmount) }}
                          </span> -->
                            <span class="paymentGridCurrencyIcon">$</span>
                            <input
                              autocomplete="off"
                              class="generalTextBox form-control paymentGridDecimalInputs"
                              type="number"
                              v-model="item.FeeAmount"
                              min="0"
                              @keyup="feeAmountKeyUp(item)"
                              @change="feeAmountChange(item, $event)"
                            />
                          </td>
                          <td>
                            <!-- <span
                            class="tabledit-span tabledit-identifier"
                            v-show="!item.isEditing"
                          >
                            {{
                              item.FeePercentage
                                ? parseFloat(item.FeePercentage).toFixed(2) +
                                  "%"
                                : "0%"
                            }}
                          </span> -->
                            <input
                              autocomplete="off"
                              v-myMask="{
                                alias: 'decimal',
                                min: 0,
                                max: 100,
                                digits: 2,
                                rightAlign: false,
                              }"
                              class="generalTextBox form-control"
                              type="text"
                              v-model="item.FeePercentage"
                              @change="feePercentageChange(item, $event)"
                            />
                          </td>
                          <td style="padding: 11px 0px">
                            <button
                              v-show="
                                settlementTask.Creditor.SettlementStatus.Id != 4
                              "
                              @click="removeTermRow(item)"
                              type="button"
                              class="btn btn-danger"
                            >
                              <i class="fas fa-times"></i>
                            </button>
                          </td>
                          <td style="padding: 11px 0px">
                            <!-- <button
                            v-if="
                              settlementTask.Creditor.SettlementStatus.Id != 4
                            "
                            type="button"
                            class="btn"
                            style="
                              background-color: #24d171;
                              margin-right: 16px !important;
                            "
                            v-on:click.prevent.stop="
                              () => {
                                if (item.isEditing) {
                                  item.isEditing = false;
                                } else {
                                  outsideGridRow();
                                  item.isEditing = true;
                                }
                              }
                            "
                          >
                            <i
                              v-show="!item.isEditing"
                              class="ri-edit-line"
                            ></i>
                            <i
                              v-show="item.isEditing"
                              @click="acceptPaymentDetail(item)"
                              class="fas fa-save"
                            ></i>
                          </button> -->
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </perfect-scrollbar>

                <div
                  v-if="
                    settlementTask.PaymentDetails != null &&
                    settlementTask.PaymentDetails.length > 0
                  "
                  class="col-md-12"
                  style="display: flex"
                >
                  <div style="width: 20%">
                    <label class="generalLabel">Total:</label>
                  </div>
                  <div style="width: 45%">
                    <label class="generalLabel">{{
                      formatMoney(getTotalPmtAmount())
                    }}</label>
                  </div>
                  <div style="width: 15%">
                    <label class="generalLabel">{{
                      formatMoney(getTotalFeeAmount())
                    }}</label>
                  </div>
                  <div>
                    <label class="generalLabel"
                      >%{{ formatFloatingNumber(getTotalFee()) }}</label
                    >
                  </div>
                </div>
              </div>
              <div
                v-if="
                  settlementTask &&
                  settlementTask.IsTermSettlement &&
                  tabs[1].active &&
                  checkAuth(10155)
                "
                class="col-md-12 noPadding noMargin"
                style="border: 1px solid rgb(209, 209, 209); padding-top: 20px"
              >
                <div
                  class="col-md-12 row noMargin"
                  style="display: flex; align-items: center"
                >
                  <div class="col-md-12 mt-2">
                    <label class="generalLabel"># of payments</label>
                    <input
                      type="text"
                      class="generalTextBox ml-2"
                      style="width: 60px"
                      :disabled="
                        settlementTaskCopy.Creditor.SettlementStatus.Id == 4
                      "
                      :class="
                        settlementTaskCopy.Creditor.SettlementStatus.Id == 4
                          ? 'readonlyInput'
                          : ''
                      "
                      v-myMask="{
                        alias: 'numeric',
                        prefix: '',
                        min: 2,
                        max: 120,
                        rightAlign: false,
                      }"
                      v-model="settlementTaskCopy.NumberOfPayments"
                      @change="
                        updateNumberOfPaymentsPaymentPlanner(
                          settlementTaskCopy.NumberOfPayments
                        )
                      "
                    />

                    <!-- <CustomNumericInput
                    id="numberOfPaymentsInput"
                    :value="settlementTaskCopy.NumberOfPayments"
                    :disabled="
                      settlementTaskCopy.Creditor.SettlementStatus.Id == 4
                    "
                    @updateValue="updateNumberOfPaymentsPaymentPlanner"
                    :minNumber="2"
                    class="generalTextBox ml-2"
                    style="width: 80px"
                  /> -->
                    <div style="width: auto; float: right">
                      <div
                        v-if="!feeGridLoading"
                        :style="
                          settlementTask.Creditor.SettlementStatus.Id == 4
                            ? 'opacity:0.4'
                            : ''
                        "
                        style="
                          width: auto;
                          float: left;
                          margin: 5px 0px;
                          font-size: 16px;
                          cursor: pointer;
                        "
                        @click="calculateFeesForPaymentPlanner"
                      >
                        <span style="padding: 5px" class="badge badge-success"
                          >Calculate Fees</span
                        >
                      </div>
                    </div>
                    <!-- <div style="width: auto; float: right">
                    <div
                      :style="
                        settlementTaskCopy.Creditor.SettlementStatus.Id == 4
                          ? 'opacity:0.4'
                          : ''
                      "
                      style="
                        width: auto;
                        float: left;
                        margin: 5px 0px;
                        font-size: 16px;
                        cursor: pointer;
                      "
                      @click="reCalculateFeeList()"
                    >
                      <span style="padding: 5px" class="badge badge-warning"
                        >Re-calculate Fees</span
                      >
                    </div>
                  </div> -->
                    <div
                      :style="
                        settlementTaskCopy.Creditor.SettlementStatus.Id == 4
                          ? 'opacity:0.4'
                          : ''
                      "
                      :disabled="
                        settlementTaskCopy.Creditor.SettlementStatus.Id == 4
                      "
                      @click="adjustDatesPaymentPlanner()"
                      style="
                        width: auto;
                        float: right;
                        margin: 5px 12px 10px 10px;
                        font-size: 16px;
                        cursor: pointer;
                      "
                    >
                      <span style="padding: 5px" class="badge badge-info"
                        >Adjust Dates</span
                      >
                    </div>
                    <div style="width: auto; float: right">
                      <div
                        @click="setOnlyPaymentsPaymentPlanner()"
                        :style="
                          settlementTask.Creditor.SettlementStatus.Id == 4
                            ? 'opacity:0.4'
                            : ''
                        "
                        style="
                          width: auto;
                          float: left;
                          margin: 5px 0px;
                          font-size: 16px;
                          cursor: pointer;
                        "
                      >
                        <span style="padding: 5px" class="badge badge-success"
                          >Reset Payments</span
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-12 noPadding">
                  <div
                    class="col-md-12 row"
                    style="display: flex; align-items: center"
                  >
                    <div class="col-md-12" style="display: flex">
                      <label class="generalLabel">Repeats:</label>
                      <input
                        type="radio"
                        name="repeatsPayment"
                        :disabled="
                          settlementTaskCopy.Creditor.SettlementStatus.Id == 4
                        "
                        @change="paymentRepeatsChangedPaymentPlanner"
                        :checked="!repeatsDayActiveCopy"
                        id="repeatsMonth"
                        class="checkbox-input generalCheckBox ml-1"
                        style="
                          border-radius: 4px !important;
                          border: solid 1px #d1d1d1 !important;
                        "
                      />

                      <label class="generalLabel ml-2">Every Month</label>
                      <input
                        type="radio"
                        name="repeatsPayment"
                        :disabled="
                          settlementTaskCopy.Creditor.SettlementStatus.Id == 4
                        "
                        @change="paymentRepeatsChangedPaymentPlanner"
                        :checked="repeatsDayActiveCopy"
                        id="repeatsDay"
                        class="checkbox-input generalCheckBox ml-2"
                        style="
                          border-radius: 4px !important;
                          border: solid 1px #d1d1d1 !important;
                        "
                      />
                      <label class="generalLabel ml-2">Every</label>

                      <input
                        autocomplete="off"
                        v-model="settlementTaskCopy.RepeatsOfPayments"
                        :disabled="
                          !repeatsDayActiveCopy ||
                          settlementTaskCopy.Creditor.SettlementStatus.Id == 4
                        "
                        @input="updateRepeatsOfPaymentsPaymentPlanner"
                        type="text"
                        id="repeatsOfPaymentInput"
                        class="generalTextBox ml-2"
                        style="width: 80px; margin-top: -8px"
                        v-myMask="{
                          alias: 'numeric',
                          prefix: '',
                          min: 1,
                          max: 90,
                          rightAlign: false,
                        }"
                      />
                      <label class="generalLabel ml-2"> days</label>
                      <!-- <div class="flex-fill text-right">
                      <label class="generalLabel ml-2">Min. Fee:</label>
                      <input
                        autocomplete="off"
                        type="text"
                        id="repeatsOfPaymentInput"
                        class="generalTextBox ml-2"
                        v-model="minFeeChargeForPaymentPlanner"
                        style="width: 50px; margin-top: -8px"
                        v-myMask="{
                          alias: 'numeric',
                          prefix: '',
                          min: 0,
                          max: settlementTask.SettlementFee,
                          rightAlign: false,
                        }"
                      />
                    </div> -->
                    </div>
                  </div>
                </div>
                <perfect-scrollbar style="max-height: 60vh">
                  <div class="col-md-12 row noPadding noMargin">
                    <div
                      class="col-md-6 negotiatorTableScroll noPadding"
                      v-click-outside="outsideGridRow"
                    >
                      <table
                        class="datagrid-table creditorTable paymentGrid"
                        id="btn-editable-ent"
                        style="
                          width: 100%;

                          border: none !important;
                          background-color: rgb(255, 255, 255);
                        "
                      >
                        <thead>
                          <tr>
                            <th width="30%">Pmt Date</th>
                            <th width="25%">Pmt Amount</th>
                            <th width="40%">Pmt Type</th>
                            <th width="5%"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="item in settlementTaskCopy.PaymentDetails"
                            :key="item.Id"
                          >
                            <td>
                              <!-- <span
                              class="tabledit-span tabledit-identifier"
                              v-show="!item.isEditing"
                            >
                              {{ formatShortDate(item.PaymentDate) }}
                            </span> -->
                              <div class="datepickerWrapper">
                                <DatePicker
                                  mode="date"
                                  :locale="'en-US'"
                                  v-model="item.PaymentDate"
                                  :min-date="new Date(1, 1, 1970)"
                                  :max-date="new Date(2100, 12, 31)"
                                  :model-config="modelConfig"
                                >
                                  <template
                                    v-slot="{ inputValue, inputEvents }"
                                  >
                                    <input
                                      autocomplete="off"
                                      id="patmentDateInput"
                                      class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                                      :value="inputValue"
                                      v-on="inputEvents"
                                      @blur="paymentDateChange(item)"
                                    />
                                  </template>
                                </DatePicker>
                              </div>
                            </td>
                            <td>
                              <span class="paymentGridCurrencyIcon">$</span>
                              <input
                                autocomplete="off"
                                class="generalTextBox form-control paymentGridDecimalInputs"
                                type="number"
                                min="0"
                                v-model="item.PaymentAmount"
                                @keyup="paymentAmountKeyUp(item)"
                                @change="
                                  paymentAmountChangePaymentPlanner(item)
                                "
                              />
                              <!-- <span
                              class="tabledit-span tabledit-identifier"
                              v-show="!item.isEditing"
                            >
                              {{ formatMoney(item.PaymentAmount) }}
                            </span> -->
                            </td>
                            <td>
                              <select
                                :disabled="!checkAuth(56962)"
                                :class="
                                  !checkAuth(56962) ? 'readonlyInput' : ''
                                "
                                class="form-control"
                                v-model="item.PaymentTypeId"
                                @change="paymentTypeChangedPaymentPlanner()"
                              >
                                <option :value="null">Please select</option>
                                <option
                                  v-for="t in filteredPaymentTypes"
                                  :value="t.Id"
                                  :key="t.Id"
                                >
                                  {{ t.Name }}
                                </option>
                              </select>
                            </td>

                            <td style="padding: 11px 0px">
                              <button
                                v-show="
                                  !item.isEditing &&
                                  settlementTaskCopy.Creditor.SettlementStatus
                                    .Id != 4
                                "
                                @click="removeTermRowPaymentPlanner(item)"
                                type="button"
                                class="btn btn-danger"
                              >
                                <i class="fas fa-times"></i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div
                      class="col-md-6 negotiatorTableScroll noPadding"
                      v-click-outside="outsideGridRow"
                    >
                      <table
                        v-if="!feeGridLoading"
                        class="datagrid-table creditorTable paymentGrid narrowGrid"
                        id="btn-editable-ent"
                        style="
                          width: 100%;

                          border: none !important;
                          background-color: rgb(255, 255, 255);
                        "
                      >
                        <thead>
                          <tr>
                            <th width="30%">Fee Date</th>
                            <th width="20%">Fee Amount</th>
                            <th width="20%">Fee %</th>
                            <th width="5%"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td
                              colspan="4"
                              style="vertical-align: top !important"
                            >
                              <button
                                type="button"
                                class="generalButton btn-sm btn-primary float-left"
                                style="
                                  width: 100%;
                                  font-size: 12px;
                                  background-color: #3bafda;
                                  color: #ffffff;
                                "
                                @click="addNewFee()"
                              >
                                <i class="fe-plus-circle"></i>
                                Add new fee
                              </button>
                            </td>
                          </tr>
                          <tr v-if="isNewFeeRow">
                            <td>
                              <div class="datepickerWrapper">
                                <DatePicker
                                  v-on:keydown.esc.prevent="
                                    () => {
                                      newFee.FeeDate = null;
                                    }
                                  "
                                  mode="date"
                                  :locale="'en-US'"
                                  :min-date="new Date(1, 1, 1970)"
                                  :max-date="new Date(2100, 12, 31)"
                                  :model-config="modelConfig"
                                  v-model="newFee.FeeDate"
                                >
                                  <template
                                    v-slot="{ inputValue, inputEvents }"
                                  >
                                    <input
                                      autocomplete="off"
                                      class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                                      :value="inputValue"
                                      v-on="inputEvents"
                                    />
                                  </template>
                                </DatePicker>
                              </div>
                            </td>
                            <td>
                              <input
                                autocomplete="off"
                                v-myMask="{
                                  alias: 'currency',
                                  prefix: '$',
                                  rightAlign: false,
                                }"
                                class="generalTextBox form-control"
                                type="text"
                                v-model="newFee.FeeAmount"
                              />
                            </td>

                            <td colspan="2" style="padding: 11px 0px">
                              <button
                                type="button"
                                class="btn btn-success"
                                @click="submitNewFee()"
                              >
                                <i class="fas fa-save"></i>
                              </button>
                              <button
                                type="button"
                                class="btn btn-danger"
                                @click="cancelNewFee()"
                              >
                                <i class="fas fa-times"></i>
                              </button>
                            </td>
                          </tr>
                          <tr
                            v-for="item in feeListPaymentPlanner"
                            :key="item.Id"
                            :style="
                              item.Id < 0
                                ? 'background-color:#baf5ba !important'
                                : ''
                            "
                          >
                            <td>
                              <!-- <span class="tabledit-span tabledit-identifier">
                              {{ formatShortDate(item.FeeDate) }}
                            </span> -->
                              <div class="datepickerWrapper">
                                <DatePicker
                                  mode="date"
                                  :locale="'en-US'"
                                  v-model="item.FeeDate"
                                  :min-date="new Date(1, 1, 1970)"
                                  :max-date="new Date(2100, 12, 31)"
                                >
                                  <template
                                    v-slot="{ inputValue, inputEvents }"
                                  >
                                    <input
                                      autocomplete="off"
                                      id="patmentDateInput"
                                      class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                                      :value="inputValue"
                                      v-on="inputEvents"
                                      @blur="feeDateChangePaymentPlanner(item)"
                                    />
                                  </template>
                                </DatePicker>
                              </div>
                            </td>
                            <td>
                              <!-- <span class="tabledit-span tabledit-identifier">
                              {{ formatMoney(item.FeeAmount) }}
                            </span> -->
                              <span class="paymentGridCurrencyIcon">$</span>
                              <input
                                autocomplete="off"
                                class="generalTextBox form-control paymentGridDecimalInputs"
                                type="number"
                                v-model="item.FeeAmount"
                                min="0"
                                @keyup="feeAmountKeyUp(item)"
                                @change="
                                  feeAmountChangePaymentPlanner(item, $event)
                                "
                              />
                            </td>
                            <td>
                              <!-- <span class="tabledit-span tabledit-identifier">
                              {{
                                item.FeePercentage
                                  ? parseFloat(item.FeePercentage).toFixed(2) +
                                    "%"
                                  : "0%"
                              }}
                            </span> -->
                              <input
                                autocomplete="off"
                                v-myMask="{
                                  alias: 'decimal',
                                  min: 0,
                                  max: 100,
                                  digits: 2,
                                  rightAlign: false,
                                }"
                                class="generalTextBox form-control"
                                type="text"
                                v-model="item.FeePercentage"
                                @change="
                                  feePercentageChangePaymentPlanner(
                                    item,
                                    $event
                                  )
                                "
                              />
                            </td>

                            <td style="padding: 11px 0px">
                              <button
                                v-show="
                                  settlementTaskCopy.Creditor.SettlementStatus
                                    .Id != 4
                                "
                                @click="removeFeeRowPaymentPlanner(item)"
                                type="button"
                                class="btn btn-danger"
                              >
                                <i class="fas fa-times"></i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </perfect-scrollbar>
                <div class="col-md-12 row">
                  <div
                    v-if="
                      settlementTaskCopy.PaymentDetails != null &&
                      settlementTaskCopy.PaymentDetails.length > 0
                    "
                    class="col-md-6 noPadding"
                    style="display: flex"
                  >
                    <div style="width: 31%">
                      <label class="generalLabel"></label>
                    </div>
                    <div style="width: 21%">
                      <label class="generalLabel">{{
                        formatMoney(getTotalPmtAmountPaymentPlanner())
                      }}</label>
                    </div>
                  </div>
                  <div
                    v-if="
                      settlementTaskCopy.PaymentDetails != null &&
                      settlementTaskCopy.PaymentDetails.length > 0
                    "
                    class="col-md-6 noPadding"
                    style="display: flex"
                  >
                    <div style="width: 44%">
                      <label class="generalLabel"></label>
                    </div>
                    <div style="width: 30%">
                      <label class="generalLabel">{{
                        formatMoney(getTotalFeeAmountPaymentPlanner())
                      }}</label>
                    </div>
                    <div style="width: 21%">
                      <label class="generalLabel">{{
                        getTotalFeePercentagePaymentPlanner()
                      }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="form-group col-md-12 pt-3">


            <label class="generalLabel">Note</label>
            <div>
              <textarea
                v-model="settlementTask.Note"
                id="noteTextEditor"
                class="form-control"
                rows="6"
                placeholder="Note"
                @blur="removeLeadingSpaces"
              ></textarea>
            </div>
          </div> -->

          <div class="form-group col-md-6 pt-3 pb-3">
            <div>
              <label class="generalLabel">Account Number</label>
            </div>
            <div>
              <input
                v-model="settlementTask.GlobalMemo"
                class="form-control generalTextBox"
                placeholder="Account Number"
                @blur="removeLeadingSpaces"
              />
            </div>
          </div>

          <div class="form-group col-md-6 pt-3 pb-3">
            <div>
              <label class="generalLabel">Global Memo</label>
            </div>
            <div>
              <input
                v-model="settlementTask.GlobalMemo1"
                class="form-control generalTextBox"
                placeholder="Account Number"
                @blur="removeLeadingSpaces"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-xl-4 col-lg-6 col-md-12 col-xs-12 noPadding"
        id="editNegotiatorTask"
      >
        <div
          class="col-12"
          style="width: 100%; height: 100%; position: absolute; z-index: 100"
        >
          <div class="cashflowMoveable">
            <div class="col-md-12 noPadding">
              <label class="generalLabel text-center w-100"
                >Cash Flow
                <span style="color: red">
                  (You can drag and drop this panel)</span
                ></label
              >
              <perfect-scrollbar style="max-height: 664px; position: relative">
                <div class="datagrid-table" style="background-color: #fafafa">
                  <o-table :data="cashFlowList">
                    <o-table-column
                      field="TransactionType"
                      label="T.Type"
                      v-slot="props"
                    >
                      <span
                        v-bind:class="{
                          textGreen:
                            props.row.IsCurrentTask && props.row.Balance >= 0,
                          textRed2:
                            props.row.IsCurrentTask && props.row.Balance < 0,
                        }"
                      >
                        {{ formatTransactionLabel(props.row.TransactionType) }}
                      </span>
                    </o-table-column>
                    <o-table-column
                      v-if="
                        settlementTask && settlementTask.PaymentProcessorId == 3
                      "
                      field="TransactionTypeDesc"
                      label="T.Desc"
                      v-slot="props"
                    >
                      <span
                        v-bind:class="{
                          textGreen:
                            props.row.IsCurrentTask && props.row.Balance >= 0,
                          textRed2:
                            props.row.IsCurrentTask && props.row.Balance < 0,
                        }"
                      >
                        {{
                          formatTransactionLabel(props.row.TransactionTypeDesc)
                        }}
                      </span>
                    </o-table-column>
                    <o-table-column field="Date" label="Date" v-slot="props">
                      <span
                        v-bind:class="{
                          textGreen:
                            props.row.IsCurrentTask && props.row.Balance >= 0,
                          textRed2:
                            props.row.IsCurrentTask && props.row.Balance < 0,
                        }"
                      >
                        {{ formatShortDate(props.row.Date) }}
                      </span>
                    </o-table-column>

                    <o-table-column field="Balance" label="Net Cash">
                      <template v-slot="props">
                        <div
                          v-bind:class="{
                            textGreen:
                              props.row.IsCurrentTask && props.row.Balance >= 0,
                            textRed: props.row.Balance < 0,
                            textRed2:
                              props.row.IsCurrentTask && props.row.Balance < 0,
                          }"
                        >
                          {{ formatMoney(props.row.Balance) }}
                        </div>
                      </template>
                    </o-table-column>
                    <o-table-column field="Memo" label="Memo" v-slot="props">
                      <span
                        v-bind:class="{
                          textGreen:
                            props.row.IsCurrentTask && props.row.Balance >= 0,
                          textRed2:
                            props.row.IsCurrentTask && props.row.Balance < 0,
                        }"
                      >
                        {{ props.row.Memo }}
                      </span>
                    </o-table-column>
                  </o-table>
                  <div
                    v-if="tableLoading"
                    class="loadingAbsoluteBox"
                    style="background: #fff"
                  >
                    <div class="loadingSpinner">
                      <i class="spinner-border text-primary m-2"></i>
                    </div>
                  </div>
                  <section
                    v-if="!tableLoading && cashFlowList.length == 0"
                    class="section"
                    style="text-align: center"
                  >
                    <div class="content has-text-grey has-text-centered mb-4">
                      <p class="noMargin">
                        <i
                          style="font-size: 24px; color: #939393"
                          class="ri-question-line"
                        ></i>
                      </p>
                      <p class="noMargin">Nothing here.</p>
                    </div>
                  </section>
                </div>
              </perfect-scrollbar>
            </div>
          </div>

          <Moveable v-bind="moveable" @drag="handleDrag" />
        </div>
      </div>
    </div>

    <div class="col-md-12">
      <label class="generalLabel">Last Creditor Note:</label>
      <div class="datagrid-table" style="background-color: white">
        <o-table :data="latestCreditorNote">
          <o-table-column field="UserName" label="User" v-slot="props">
            {{ props.row.UserName }}
          </o-table-column>
          <o-table-column field="UserRoles" label="User Roles" v-slot="props">
            {{ parseUserRoles(props.row.UserRoles) }}
          </o-table-column>
          <o-table-column field="Note" label="Note" v-slot="props">
            <span
              style="display: block; overflow-x: auto"
              class="noteContent"
              v-html="props.row.Note"
            ></span>
          </o-table-column>
          <o-table-column field="CreatedDate" label="Date" v-slot="props">
            {{ formatGridDate(props.row.CreatedDate) }}
          </o-table-column>
        </o-table>
      </div>
    </div>

    <div class="col-md-12">
      <div
        style="
          background-color: white;
          border: 1px rgb(233, 233, 233) solid;
          border-radius: 5px;
        "
      >
        <div
          style="
            display: flex;
            justify-content: center;
            padding: 10px 5px 0 5px;
          "
        >
          <label class="generalLabel">All Creditor Notes</label>
        </div>
        <div>
          <div v-if="seeAllCreditorNotes" style="background-color: #fff">
            <ClientNotes
              :customerId="settlementTask.Creditor.Customer.Id"
              :tabClicked="false"
              :creditorId="settlementTask.Creditor.Id"
              :notesImported="creditorNotes"
              :quickNotesImported="quickNotes"
              @noteAdded="noteAdded"
            ></ClientNotes>
          </div>
          <div class="animation-container" @click="seeAllNotes()">
            <i
              v-if="!seeAllCreditorNotes"
              style="font-size: 25px"
              class="fas fa-angle-down"
            ></i>
            <i
              v-if="seeAllCreditorNotes"
              style="font-size: 25px"
              class="fas fa-angle-up"
            ></i>
          </div>
        </div>
      </div>
    </div>

    <div
      class="col-md-12 row pt-3"
      style="display: flex; justify-content: space-evenly"
    >
      <div
        v-if="settlementTask.Creditor.SettlementStatus.Id != 4"
        class="col-xl-3 col-lg-12 col-md-12 col-12 form-group"
      >
        <div class="bottomBoxCenter">
          <label class="generalLabel">Negotiation Phase</label>
        </div>

        <div class="col-md-12">
          <select
            v-model="settlementTask.NegotiationPhase.Id"
            class="form-control"
            id="selectPhase"
          >
            <option value="0">Select a phase</option>
            <option
              v-for="item in negotiationPhaseList"
              :key="item.Id"
              :value="item.Id"
            >
              {{ item.Name }}
            </option>
          </select>
        </div>
        <hr />
        <div class="col-md-12 bottomBoxCenter">
          <label class="generalLabel">Settlement Agreement</label>
        </div>
        <div class="col-md-12" style="text-align: center">
          <label
            style="cursor: pointer"
            class="generalLabel"
            @click="openDocumentPopup"
          >
            <i
              id="fileChosenIcon"
              class="fas fa-plus-circle pr-1"
              style="color: #1abc9c; cursor: pointer"
            ></i>
          </label>
          <label
            v-if="settlementTask.SettlementAgreementFileName == ''"
            style="cursor: pointer"
            class="generalLabel"
          >
            No file chosen</label
          >
          <label
            @click="dowmloadSettlementAgreement"
            v-if="settlementTask.SettlementAgreementFileName != ''"
            style="cursor: pointer"
            class="generalLabel"
          >
            {{ settlementTask.SettlementAgreementFileName }}</label
          >
        </div>
      </div>
      <div
        v-if="settlementTask.Creditor.SettlementStatus.Id == 2"
        class="col-xl-3 col-lg-12 col-md-12 col-12 form-group"
      >
        <div class="bottomBoxCenter">
          <label class="generalLabel">Auditor</label>
        </div>

        <div class="col-md-12">
          <select
            v-model="settlementTask.Creditor.AuditorId"
            class="form-control"
            :disabled="!checkAuth(55842)"
            :class="!checkAuth(55842) ? 'readonlyInput' : ''"
          >
            <option :value="null">Un-assign</option>
            <option v-for="item in auditorList" :key="item.Id" :value="item.Id">
              {{ item.Name }}
            </option>
          </select>
        </div>
      </div>
      <div
        v-if="settlementTask.Creditor.SettlementStatus.Id != 4"
        class="col-xl-3 col-lg-12 col-md-12 col-12 form-group"
      >
        <div class="bottomBoxCenter">
          <label class="generalLabel">CSR Correspondence</label>
        </div>
        <div>
          <select v-model="selectedCorrespondenceTaskId" class="form-control">
            <option :value="null">Select a Correspondence</option>
            <option
              v-for="item in csrCorrespondenceList"
              :key="item.Id"
              :value="item.Id"
            >
              {{ item.Name }}
            </option>
          </select>
        </div>
        <div class="col-md-12 noPadding">
          <button
            @click="sendCsrCorrespondence"
            :disabled="!selectedCorrespondenceTaskId"
            id="sendButton"
            type="button"
            class="btn btn-success col-md-12"
          >
            Send
          </button>
        </div>
      </div>
      <div
        v-if="
          settlementTask.Creditor.SettlementStatus.Id == 1 &&
          (!negativeCashFlowAlert || checkAuth(944457))
        "
        class="col-xl-3 col-lg-12 col-md-12 col-12 form-group mt-3"
      >
        <button
          @click="requestSettlement()"
          id="requestSettlementButton"
          type="button"
          class="btn btn-success col-md-12"
          :disabled="requestSettlementSpinner"
        >
          <i
            v-if="requestSettlementSpinner"
            class="spinner-border spinner-border-sm"
          ></i>
          Request Settlement
        </button>
      </div>
      <div
        class="col-xl-2 col-lg-12 col-md-12 col-12 form-group mt-2"
        v-if="
          settlementTask.Creditor.SettlementStatus.Id == 2 ||
          settlementTask.Creditor.SettlementStatus.Id == 4
        "
      >
        <div class="col-md-12 row noPadding">
          <div class="col-md-12">
            <div v-if="!negativeCashFlowAlert || checkAuth(944457)">
              <button
                v-if="settlementTask.Creditor.SettlementStatus.Id == 2"
                type="button"
                class="btn btn-info waves-effect waves-light col-md-12"
                @click="openPaymentSetup()"
              >
                Payment Setup
              </button>
              <button
                v-if="
                  (settlementTask.Creditor.SettlementStatus.Id == 2 &&
                    checkAuth(99655)) ||
                  settlementTask.Creditor.SettlementStatus.Id == 4
                "
                @click="approveSettlement()"
                type="button"
                class="btn btn-success waves-effect waves-light col-md-12"
                :disabled="aproveSpinner"
              >
                <i
                  v-if="aproveSpinner"
                  class="spinner-border spinner-border-sm"
                ></i>
                Approve
              </button>
            </div>
            <button
              v-if="
                settlementTask.Creditor.SettlementStatus.Id == 2 ||
                settlementTask.Creditor.SettlementStatus.Id == 4
              "
              @click="declineSettlement()"
              type="button"
              class="btn btn-danger waves-effect waves-light col-md-12"
            >
              Decline
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="settlementTask.Creditor.SettlementStatus.Id != 4"
      class="col-md-12"
    >
      <button
        @click="saveTask()"
        id="saveAllIcon"
        type="button"
        class="btn btn-success col-md-12 mt-3"
        :disabled="saveAllSpinner"
      >
        <i v-if="saveAllSpinner" class="spinner-border spinner-border-sm"></i>
        Save All
      </button>
    </div>
    <div class="row">
      <div class="backgroundLock" v-if="isHardshipStatementPopupOpen">
        <div
          style="position: relative; margin: 10% auto"
          class="offset-xl-4 col-xl-4 offset-lg-3 col-lg-6 col-md-12 col-sm-12 col-xs-12"
        >
          <HardshipStatementPopup
            :statement="settlementTask.Creditor.Customer.HardshipStatement"
            @closeHardshipStatementPopup="closeHardshipStatementPopup"
          ></HardshipStatementPopup>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="backgroundLock" v-if="isCoCustomerInfoPopupOpen">
        <div
          style="position: relative; margin: 10% auto"
          class="offset-xl-4 col-xl-3 offset-lg-3 col-lg-6 col-md-12 col-sm-12 col-xs-12"
        >
          <CoCustomerInfoPopup
            :coCustomers="activeCoCustomers"
            @closeCoCustomerInfoPopup="closeCoCustomerInfoPopup"
          ></CoCustomerInfoPopup>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="backgroundLock" v-if="isQuickEmailPopupOpen">
        <div
          style="position: relative; margin: 10% auto"
          class="offset-xl-4 col-xl-3 offset-lg-3 col-lg-6 col-md-12 col-sm-12 col-xs-12"
        >
          <QuickEmails
            :customerId="settlementTask.Creditor.Customer.Id"
            @closeQuickEmailPopup="closeQuickEmailPopup"
            @quickEmailSelected="quickEmailSelected"
          ></QuickEmails>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="backgroundLock" v-if="isCorrespondenceRaisonPanelPopupOpen">
        <div
          style="position: relative; margin: 10% auto"
          class="offset-xl-4 col-xl-3 offset-lg-3 col-lg-6 col-md-12 col-sm-12 col-xs-12"
        >
          <CorrespondenceRaisonPanel
            :taskTypeId="CorrespondenceTaskTypeId"
            :taskTypeName="CorrespondenceTaskTypeName"
            :taskItem="settlementTask"
            @closeCorrespondenceRaisonPanelPopUp="
              closeCorrespondenceRaisonPanelPopUp
            "
          ></CorrespondenceRaisonPanel>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="backgroundLock" v-if="isPaymentSetupPopUp">
        <div
          style="position: relative; margin: 5% auto"
          class="offset-xl-3 col-xl-6 offset-lg-3 col-lg-6 col-md-12 col-sm-12 col-xs-12"
        >
          <PaymentSetupPopup
            v-if="isSettlementApproval"
            :task="settlementTask"
            :paymentTypeList="filteredPaymentTypes"
            @closePaymentSetupPopUp="closePaymentSetupPopUp"
          ></PaymentSetupPopup>
        </div>
      </div>
    </div>
    <div v-if="isDocumentsPopupOpen && checkAuth(4218)" class="backgroundLock">
      <div
        style="
          position: relative;
          margin: 2% auto;
          background-color: white;
          padding-top: 30px;
        "
        class="offset-xl-3 col-xl-6 offset-lg-2 col-lg-8 col-md-12 col-sm-12 negotiatorDocumentPopup"
      >
        <div class="documentTaskPopupClose" @click="closeDocumentPopup()">
          <i class="fas fa-window-close"></i>
        </div>

        <ClientDocuments
          @documentSelected="documentSelected"
          :customerId="settlementTask.Creditor.Customer.Id"
          :isNegotiatorTaskPopup="true"
        ></ClientDocuments>
      </div>
    </div>
    <div v-if="isDeclineReviewPopupOpen" class="backgroundLock">
      <div
        style="position: relative; margin: 10% auto"
        class="offset-xl-4 col-xl-3 offset-lg-3 col-lg-6 col-md-12 col-sm-12 col-xs-12"
      >
        <DeclineSettlementReview
          :taskId="taskId"
          @closeDeclineReviewPopup="closeDeclineReviewPopup"
          @reviewDeclined="reviewDeclined"
        ></DeclineSettlementReview>
      </div>
    </div>
  </div>
</template>

<script>
//import CustomNumericInput from "@/components/CustomNumericInput";
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import { DatePicker } from "v-calendar";
import QuickEmails from "@/components/QuickEmails.vue";
import CorrespondenceRaisonPanel from "@/views/agent/NegotiatorTasks/CorrespondenceRaisonPanel";
import PaymentSetupPopup from "@/views/agent/NegotiatorTasks/PaymentSetupPopup";
import ClientDocuments from "@/views/agent/EditClient/ClientDocuments";
import globalTypes from "@/store/types";
import types from "./types";
import clientTypes from "@/views/agent/EditClient/ClientNotes/types";
import { mapState } from "vuex";
import deepCopy from "@/helpers/deepCopy";
import _orderby from "lodash";
import customerTypes from "@/views/agent/EditClient/types";
import DeclineSettlementReview from "./DeclineSettlementReview";
import HardshipStatementPopup from "@/components/HardshipStatementPopup";
import CoCustomerInfoPopup from "@/views/agent/NegotiatorTasks/CoCustomerInfoPopup";
import ClientNotes from "@/views/agent/EditClient/ClientNotes/ClientNotes";
import Moveable from "vue3-moveable";
let feeTimer = null;
export default {
  name: "EditNegotiatorTask",
  components: {
    //CustomNumericInput,
    DatePicker,
    QuickEmails,
    CorrespondenceRaisonPanel,
    PaymentSetupPopup,
    ClientDocuments,
    DeclineSettlementReview,
    HardshipStatementPopup,
    CoCustomerInfoPopup,
    ClientNotes,
    Moveable,
  },
  mixins: [formatMixin, utilitiesMixin],
  emits: ["closeEditNegotiatorTaskPopUp"],
  props: {
    taskId: {
      type: Number,
      default: 0,
    },
    isSettlementApproval: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      allFeeCleared: false,
      amountWaived: 0,
      oneTimeSettlementFee: 0,
      disableOngoingChk: false,
      consecutiveDraftCheckCount: 3,
      isTouchDevice: false,
      moveable: {
        target: [".cashflowMoveable"],
        draggable: true,
        scalable: false,
        rotatable: false,
        pinchable: false,
        origin: false,
      },
      seeAllCreditorNotes: false,
      activeCoCustomers: [],
      isCoCustomerInfoPopupOpen: false,
      auditorList: [],
      isHardshipStatementPopupOpen: false,
      customerPrefix: null,
      isLoading: false,
      tableLoading: false,
      attorneyInfo: null,
      settlementFeeBackup: 0,
      overrideFeeCheckBox: false,
      maxFeeRatio: 100,
      autoCalculateOpen: false,
      saveAllSpinner: false,
      requestSettlementSpinner: false,
      aproveSpinner: false,
      arrPermission: [],
      selectedCorrespondenceTaskId: null,
      CorrespondenceTaskTypeId: null,
      CorrespondenceTaskTypeName: "",
      settlementTask: {
        Id: null,
        Payee: null,
        PayeePhone: null,
        Offer: null,
        IsTermSettlement: null,
        NegotiatorName: null,
        IsAdditionalFund: null,
        AdditionalFundAmount: null,
        EGAttorney: null,
        AttorneyName: null,
        SettlementDate: null,
        SettlementFee: null,
        SettlementFeeOverride: null,
        NegotiationPhase: {
          Id: null,
          Name: null,
        },
        PaymentDay: null,
        NegPhaseDueDate: null,
        PercentBalance: null,
        SettlementAgreementFileName: null,
        NumberOfPayments: null,
        RepeatsOfPayments: null,
        PaymentType: { Id: 0, Name: null },
        SpokeTo: null,
        IsClientNotified: null,
        Note: null,
        GlobalMemo: null,
        GlobalMemo1: null,
        GlobalBalanceOffline: null,
        PaymentDetails: [],
        Creditor: {
          Name: null,
          AccountNumber: null,
          AccountHolder: null,
          SettlementRequestedUserId: null,
          CurrentCreditorName: null,
          CurrentCreditorAccountNumber: null,
          MonthlyPayment: null,
          OriginalBalance: null,
          CurrentBalance: null,
          CurrentCreditorBalance: null,
          AuditorId: null,
          AuditorName: "",
          Customer: {
            Id: null,
            Name: null,
            Address: null,
            Email: null,
            PhoneNumber: null,
            OfficeId: null,
            Ssn: null,
            StateId: null,
            CustomerCreatedDate: null,
            Office: null,
            BirthDay: null,
            Hardship: null,
            HardshipStatement: null,
            CustomerProfile: null,
            CoCustomerProfiles: null,
            Expenses: null,
            EnrolledDebt: null,
            EnrolledDate: null,
            NumberOfAccounts: null,
            NumberOfUnsettledAccount: null,
          },
          SettlementRequestDate: null,
          SettlementStatus: {
            Id: null,
            Name: null,
          },
          IsPOA: null,
          IsLawSuit: null,
        },
        PolicyGroupId: null,
        AttorneyRequestType: null,
        IsDpg: null,
        PaymentProcessorId: 1,
        NextDraftAmount: null,
        NextDraftDate: null,
        RemainingDraftCount: null,
        Delinquent:null
      },
      settlementTaskCopy: null,
      paymentTypes: [],
      docusignHistory: [],
      isQuickEmailPopupOpen: false,
      isCorrespondenceRaisonPanelPopupOpen: false,
      isPaymentSetupPopUp: false,
      repeatsDayActive: false,
      repeatsDayActiveCopy: false,
      oldValue: null,
      modelConfig: {
        type: "date",
        mask: "MM/DD/YYYY",
      },
      isDocumentsPopupOpen: false,
      hasSettlementAgreementFile: false,
      isDeclineReviewPopupOpen: false,
      cashFlowList: [],
      cashFlowListOldValue: [],
      ongoingSettlements: [],
      userId: null,
      paymentDetailsOld: [],
      originalBalancePercentage: 0.0,
      requestedTime: null,
      tabs: [
        {
          id: 1,
          name: "defaultPlanner",
          label: "Default Planner",
          iconName: " mdi mdi-account-circle",
          active: true,
          show: !this.checkAuth(10155),
        },
        {
          id: 2,
          name: "paymentPlanner",
          label: "Payment Planner",
          iconName: "mdi mdi-phone-log",
          active: false,
          show: this.checkAuth(10155),
        },
      ],
      paymentPlannerId: 1,
      feeListPaymentPlanner: [],
      isNewFeeRow: false,
      newFee: { Id: 0, FeeDate: null, FeeAmount: 0, FeePercentage: 0 },
      feeGridLoading: false,

      settlementStatusIds: [2, 4], // settlement approval, attorney approval
      creditorNotes: [],
      quickNotes: [],
      remainingFeeTotal: 0,
      negativeCashFlowAlert: false,
      minFeeChargeForPaymentPlanner: 20,
      calculateFeeDelay: 5000,
    };
  },
  computed: mapState({
    negotiationPhaseList: (state) => state.negotiatorTasks.negotiationPhaseList,
    csrCorrespondenceList: (state) =>
      state.negotiatorTasks.csrCorrespondenceList,
    // originalBalancePerc() {
    //   return this.settlementTask
    //     ? this.settlementTask.Offer > 0 &&
    //       this.settlementTask.Creditor.OriginalBalance > 0
    //       ? parseFloat(
    //           (parseFloat(this.settlementTask.Offer) /
    //             parseFloat(this.settlementTask.Creditor.OriginalBalance)) *
    //             100
    //         ).toFixed(2)
    //       : 0
    //     : 0;
    // },

    currentBalancePerc() {
      return this.settlementTask
        ? this.settlementTask.Creditor.OriginalBalance > 0 &&
          this.settlementTask.Creditor.CurrentBalance > 0
          ? parseFloat(
              (parseFloat(this.settlementTask.Creditor.CurrentBalance) /
                parseFloat(this.settlementTask.Creditor.OriginalBalance)) *
                100
            ).toFixed(2)
          : 0
        : 0;
    },
    hasZeroPayment() {
      if (this.paymentPlannerId == 1) {
        return this.settlementTask.PaymentDetails.some(
          (value) => value.PaymentAmount == 0 && value.FeeAmount == 0
        );
      } else if (this.paymentPlannerId == 2) {
        return this.settlementTaskCopy.PaymentDetails.some(
          (value) => value.PaymentAmount == 0 && value.FeeAmount == 0
        );
      } else {
        return false;
      }
    },
    latestCreditorNote() {
      let notes = [];
      if (this.creditorNotes) {
        let sortedNotes = _orderby.orderBy(
          this.creditorNotes,
          "CreatedDate",
          "desc"
        );
        if (sortedNotes.length > 0) {
          notes.push(sortedNotes[0]);
        }
      }
      return notes;
    },
    filteredPaymentTypes() {
      if (
        this.settlementTask.PaymentProcessorId == 3 &&
        this.paymentTypes &&
        this.paymentTypes.length > 0
      ) {
        return this.paymentTypes.filter((x) => x.Id != 10);
      } else {
        return this.paymentTypes;
      }
    },
  }),
  watch: {
    "settlementTask.PaymentDay": async function (val) {
      if (this.paymentPlannerId == 1) {
        this.cashFlowList = deepCopy(this.cashFlowListOldValue);
        this.setPaymentDetails(val, true);
      } else if (this.paymentPlannerId == 2) {
        await this.getCashFlowData();
        await this.sleep(500);

        this.setPaymentDetailsPaymentPlanner(val);
      }
    },
    "settlementTask.EGAttorney": {
      handler(value) {
        if (!value) {
          this.settlementTask.AttorneyName = "";
        }
      },
    },
    autoCalculateOpen(newVal, oldVal) {
      if (oldVal == false && newVal == true) {
        this.setPaymentDetails(null, true);
      }
    },
  },
  async mounted() {
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    this.arrPermission = userInfo.moduleFunctions
      .split(",")
      .map(function (item) {
        return parseInt(item, 10);
      });
    this.userId = userInfo.userId;
    await Promise.all([
      this.$store.dispatch(types.GET_NEGOTIATOR_PHASES),

      this.$store.dispatch(types.GET_NEGOTIATOR_CSR_CORRESPONDENCE),
      this.bindAuditors(),
    ]);

    this.isTouchDevice = false;
    if ("ontouchstart" in document.documentElement) {
      this.isTouchDevice = true;
    }

    if (this.taskId > 0) {
      this.isLoading = true;
      let err, result;
      [err, result] = await this.$store.dispatch(
        types.GET_NEGOTIATOR_TASK_DETAILS,
        {
          Id: this.taskId,
        }
      );
      this.isLoading = false;

      if (result) {
        await this.bindData(result);
        await this.getCashFlowData();
        this.setPaymentPlannerId();

        await this.checkHasAttorney();
        if (
          result.Data &&
          result.Data.Creditor &&
          result.Data.Creditor.Id > 0
        ) {
          await this.getProcessedFees();
          await this.getCreditorNotes();
        }

        await this.getQuickNotes();
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
        return;
      }

      if (
        this.settlementTask.Payee == "" ||
        this.settlementTask.Payee == null
      ) {
        this.settlementTask.Payee = this.settlementTask.Creditor.Name;
      }

      if (this.settlementTask.Creditor.CurrentCreditorName != "") {
        this.settlementTask.Payee =
          this.settlementTask.Creditor.CurrentCreditorName;
      }

      if (
        this.settlementTask.Creditor.CurrentCreditorAccountNumber == "" ||
        this.settlementTask.Creditor.CurrentCreditorAccountNumber == null
      ) {
        this.settlementTask.Creditor.CurrentCreditorAccountNumber =
          this.settlementTask.Creditor.AccountNumber;
      }

      // if (
      //   this.settlementTask.Creditor.CurrentCreditorBalance > 0
      // ) {
      //   this.settlementTask.Creditor.CurrentBalance =
      //     this.settlementTask.Creditor.CurrentCreditorBalance;
      // }

      if (
        this.settlementTask.GlobalMemo == "" ||
        this.settlementTask.GlobalMemo == null
      ) {
        this.settlementTask.GlobalMemo =
          this.settlementTask.Creditor.AccountNumber;
      }

      if (this.settlementTask.Creditor.Id > 0) {
        let err, result;
        [err, result] = await this.$store.dispatch(
          types.GET_ONE_TIME_SETTLEMENT_FEE,
          {
            CreditorId: this.settlementTask.Creditor.Id,
          }
        );

        if (result && result.Data) {
          if (result.Data.OneTimeSettlementFee) {
            this.oneTimeSettlementFee = parseFloat(
              result.Data.OneTimeSettlementFee
            );
            this.calculateNetCash();
          }
        }
      }
    }

    this.$Emitter.on("updateNegotiatorTask", (data) => {
      if (data) {
        this.dataSyncProcessCreditor(
          [data.CustomerId],
          data.Id,
          this.userId,
          "You"
        );
      }
    });
  
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

    if (
      this.userInfo != null &&
      this.userInfo.customerIdPrefix != null &&
      this.userInfo.customerIdPrefix.length > 0
    ) {
      this.customerPrefix = this.userInfo.customerIdPrefix;
    }
  },

  methods: {
    dataSyncForCashFlow(data,activeTabId){
      if (data) {
         if(data.CustomerId > 0 && this.settlementTask )
         {
          if(this.settlementTask.Creditor && this.settlementTask.Creditor.Customer.Id == data.CustomerId && this.settlementTask.Id != data.TaskId)
          {
            this.getCashFlowData();
            if(this.$route && this.$route.fullPath && this.$route.fullPath.includes("negotiator-tasks") && this.settlementTask.Id == activeTabId)
            {
              this.$swal("Warning","Another settlement has affected the customer's cash flow.","warning");
            }
           
          } 
        }
      }
    },
    formatTransactionLabel(value) {
      let rtn = "";
      if (typeof value != "undefined" && value && value != "") {
        rtn = value.replaceAll("_", " ");
      }
      return rtn;
    },
    calculateAmountWaived() {
      this.amountWaived = 0;

      if (this.settlementTask) {
        let tmpSettlementOverRide = parseFloat(
          this.settlementTask.SettlementFeeOverride
        );
        let tmpSettlementFeeBackup = parseFloat(this.settlementFeeBackup);
        let tmpSettlementFee = parseFloat(this.settlementTask.SettlementFee);
        if (!isNaN(tmpSettlementFee) && tmpSettlementFee > 0) {
          if (isNaN(tmpSettlementOverRide)) {
            tmpSettlementOverRide = 0;
          }
          if (this.overrideFeeCheckBox) {
            if (!isNaN(tmpSettlementFeeBackup) && tmpSettlementFeeBackup > 0) {
              this.amountWaived = tmpSettlementFeeBackup - tmpSettlementFee;
            } else if (!isNaN(tmpSettlementFee) && tmpSettlementFee > 0) {
              this.amountWaived = tmpSettlementFee - tmpSettlementOverRide;
            }
          }
        }
      }
    },
    disableOngoingSettlementChange() {
      this.calculateNetCash();
    },
    handleDrag({ target, transform }) {
      if (this.isTouchDevice) {
        return;
      }
      try {
        //matrix(1, 0, 0, 1, 0, 0) translate(-308px, -701px)
        if (target && transform.includes("translate")) {
          let index = transform.indexOf("translate(");
          let str = transform.slice(index, transform.length);
          str = str.replace("translate(", "");
          str = str.replace(")", "");

          let maxHeight = window.innerHeight;
          let customerPanel = document.getElementById("editNegotiatorTask");
          if (typeof customerPanel != "undefined" && customerPanel != null) {
            maxHeight = customerPanel.clientHeight;
          }

          if (str.length > 0) {
            let positions = str.split(",");
            if (positions.length > 0) {
              let posX = parseInt(positions[0].replace("px", ""));
              let posY = parseInt(positions[1].replace("px", ""));
              let winWidth = window.innerWidth;
              let controlWidth = target.offsetWidth;
              let controlHeight = target.offsetHeight;
              let minX = -controlWidth + 50;
              let minY = -controlHeight + 50;
              if (
                posX > minX &&
                posY > minY &&
                posX < parseInt(winWidth - controlWidth) &&
                posY < parseInt(maxHeight)
              ) {
                target.style.transform = transform;
              }
            }
          }
        }
      } catch {
        //todo:log errors
      }
    },
    getTotalSettlementAmount() {
      let totalAmount = 0.0;
      if (this.settlementTask.IsTermSettlement) {
        if (this.paymentPlannerId == 1) {
          if (
            this.settlementTask.PaymentDetails &&
            this.settlementTask.PaymentDetails.length > 0
          ) {
            for (let item of this.settlementTask.PaymentDetails) {
              totalAmount =
                parseFloat(totalAmount) + parseFloat(item.PaymentAmount);
            }
          }
        } else if (this.paymentPlannerId == 2) {
          if (
            this.settlementTaskCopy.PaymentDetails &&
            this.settlementTaskCopy.PaymentDetails.length > 0
          ) {
            for (let item of this.settlementTaskCopy.PaymentDetails) {
              totalAmount =
                parseFloat(totalAmount) + parseFloat(item.PaymentAmount);
            }
          }
        }
      } else {
        totalAmount = this.settlementTask.Offer;
      }

      return totalAmount;
    },
    getTotalSettlementFeeAmount() {
      let totalfAmount = 0.0;
      if (this.settlementTask.IsTermSettlement) {
        if (this.paymentPlannerId == 1) {
          if (
            this.settlementTask.PaymentDetails &&
            this.settlementTask.PaymentDetails.length > 0
          ) {
            for (let item of this.settlementTask.PaymentDetails) {
              totalfAmount =
                parseFloat(totalfAmount) + parseFloat(item.FeeAmount);
            }
          }
        } else if (this.paymentPlannerId == 2) {
          if (this.feeListPaymentPlanner.length > 0) {
            for (let item of this.feeListPaymentPlanner) {
              totalfAmount =
                parseFloat(totalfAmount) + parseFloat(item.FeeAmount);
            }
          }
        }
      } else {
        totalfAmount = this.settlementTask.SettlementFee;
      }

      return totalfAmount;
    },

    setPaymentPlannerId() {
      this.paymentPlannerId = this.checkAuth(10155) ? 2 : 1;

      if (this.settlementTask.IsTermSettlement) {
        this.handleTabChange({ id: this.paymentPlannerId });
      }
      if (this.paymentPlannerId == 1) {
        this.autoCalculateOpen = !(
          this.paymentDetailsOld && this.paymentDetailsOld.length > 0
        );
        this.setPaymentDetails();
      } else if (this.paymentPlannerId == 2) {
        let autoCalculatePayments = !(
          this.settlementTask && this.settlementTask.PaymentDetails.length > 0
        );

        this.setPaymentDetailsPaymentPlanner(
          null,
          false,
          autoCalculatePayments
        );
        this.setExistedFees();
      }
      this.calculateNetCash();
    },
    async getProcessedFees() {
      if (
        this.settlementTask.PaymentProcessorId == 1 ||
        this.settlementTask.PaymentProcessorId == 3
      ) {
        let err, result;
        [err, result] = await this.$store.dispatch(types.GET_PROCESSED_FEES, {
          CustomerId: this.settlementTask.Creditor.Customer.Id,
          CreditorId: this.settlementTask.Creditor.Id,
          OfficeId: this.settlementTask.Creditor.Customer.OfficeId,
        });
        if (result && result.Data) {
          let processedFeeTotal = parseFloat(result.Data.ProcessedFees);

          if (processedFeeTotal > 0) {
            this.remainingFeeTotal =
              parseFloat(this.settlementFeeBackup) - processedFeeTotal;
            this.remainingFeeTotal =
              this.remainingFeeTotal < 0 ? 0 : this.remainingFeeTotal;
            this.settlementTask.SettlementFee = this.remainingFeeTotal;
            if (this.remainingFeeTotal == 0) {
              this.allFeeCleared = true;
            }
            this.calculateAmountWaived();
          }
        }
      }
    },
    noteAdded(note) {
      let indx = this.creditorNotes.findIndex((x) => x.Id == note.Id);
      if (indx == -1) {
        this.creditorNotes.push(note);
      }
    },
    async getQuickNotes() {
      let err, result;
      [err, result] = await this.$store.dispatch(
        clientTypes.GET_QUICKNOTE_LIST
      );
      if (result && result.Data) {
        this.quickNotes = deepCopy(result.Data);
      }
    },
    async getCreditorNotes() {
      if (
        this.settlementTask &&
        this.settlementTask.Creditor.Id > 0 &&
        this.settlementTask.Creditor.Customer.Id > 0
      ) {
        let err, result;
        [err, result] = await this.$store.dispatch(clientTypes.GET_NOTES, {
          CustomerId: this.settlementTask.Creditor.Customer.Id,
          CreditorId: this.settlementTask.Creditor.Id,
        });
        if (result && result.Data) {
          this.creditorNotes = deepCopy(result.Data);
        }
      }
    },
    parseUserRoles(userRoles) {
      let rtn = "-";
      if (userRoles != null) {
        if (userRoles.length > 0) {
          if (userRoles.length == 1) {
            rtn = userRoles[0];
          } else {
            rtn = userRoles.join(", ");
          }
        }
      }
      return rtn;
    },
    seeAllNotes() {
      this.seeAllCreditorNotes = !this.seeAllCreditorNotes;
    },
    openCoCustomerInfoPopup() {
      this.isCoCustomerInfoPopupOpen = true;
    },
    closeCoCustomerInfoPopup() {
      this.isCoCustomerInfoPopupOpen = false;
    },
    getIncome() {
      let rtn = 0;
      if (
        this.settlementTask &&
        this.settlementTask.Creditor &&
        this.settlementTask.Creditor.Customer &&
        this.settlementTask.Creditor.Customer.CustomerProfile &&
        this.settlementTask.Creditor.Customer.CustomerProfile.Income
      ) {
        rtn = this.settlementTask.Creditor.Customer.CustomerProfile.Income;
      }

      return rtn;
    },
    getTotalExpenses() {
      let rtn = 0;
      if (
        this.settlementTask &&
        this.settlementTask.Creditor &&
        this.settlementTask.Creditor.Customer &&
        this.settlementTask.Creditor.Customer.Expenses
      ) {
        for (let item of this.settlementTask.Creditor.Customer.Expenses) {
          rtn += parseFloat(item.Amount);
        }
      }

      return rtn;
    },
    async bindAuditors() {
      let err, result;
      [err, result] = await this.$store.dispatch(types.GET_AUDITOR_USERS);
      if (result) {
        if (result.Data) this.auditorList = deepCopy(result.Data);
      }
    },
    getTotalFeePercentagePaymentPlanner() {
      let rtn = 0;

      if (this.feeListPaymentPlanner) {
        for (let item of this.feeListPaymentPlanner) {
          if (item.FeePercentage) {
            rtn += parseFloat(item.FeePercentage);
          }
        }
      }

      return rtn < 100 ? rtn.toFixed(2) + "%" : "100%";
    },
    calculateFeePercentage() {
      for (let item of this.feeListPaymentPlanner) {
        item.FeePercentage =
          (parseFloat(item.FeeAmount) /
            parseFloat(this.settlementTask.SettlementFee)) *
          100;
      }
    },
    addNewFee() {
      this.isNewFeeRow = true;
    },
    submitNewFee() {
      if (this.newFee.FeeDate == null || this.newFee.FeeDate == "") {
        this.$swal("Warning", "Fee Date required", "warning");
        return;
      }

      if (new Date(this.newFee.FeeDate) < new Date(2000, 1, 1)) {
        this.$swal("Warning", "Fee Date is not valid.", "warning");
        return;
      }

      if (this.newFee.FeeAmount == 0) {
        this.$swal("Warning", "Fee Amount required", "warning");
        return;
      }

      let feeId = this.feeListPaymentPlanner.length * -1;
      let newFee = deepCopy(this.newFee);
      newFee.Id = feeId;
      newFee.FeeDate = new Date(newFee.FeeDate);

      this.feeListPaymentPlanner.push(newFee);

      this.feeDateChangePaymentPlanner(newFee);

      this.feeGridLoading = true;
      this.newFee.Id = 0;
      this.newFee.FeeAmount = 0;
      this.newFee.FeeDate = null;
      this.isNewFeeRow = false;
      this.feeGridLoading = false;
      this.calculateFeePercentage();
      this.calculateNetCash();
    },
    cancelNewFee() {
      this.newFee.FeeAmount = 0;
      this.newFee.FeeDate = null;
      this.isNewFeeRow = false;
    },
    getPaymentFeeAmount(paymentId) {
      let rtn = 0;
      let indx = this.feeListPaymentPlanner.findIndex((x) => x.Id == paymentId);

      if (indx > -1) {
        rtn = parseFloat(this.feeListPaymentPlanner[indx].FeeAmount);
      }

      return rtn;
    },
    removeFeeRowPaymentPlanner(item) {
      let indx = this.feeListPaymentPlanner.findIndex((x) => x.Id == item.Id);

      if (indx > -1) {
        this.feeGridLoading = true;
        this.feeListPaymentPlanner.splice(indx, 1);
        this.feeGridLoading = false;
        this.calculateNetCash();
      }
    },
    async setOnlyPaymentsPaymentPlanner(paymentDate = null) {
      let settlementOffer = this.settlementTask.Offer;
      let repeatsOfPayment =
        this.settlementTaskCopy.RepeatsOfPayments > 0
          ? this.settlementTaskCopy.RepeatsOfPayments
          : 0;
      let firstPaymentDate =
        this.settlementTaskCopy.PaymentDetails.length > 0
          ? new Date(this.settlementTaskCopy.PaymentDetails[0].PaymentDate)
          : new Date(paymentDate);

      if (paymentDate != null) {
        firstPaymentDate = new Date(paymentDate);
      } else {
        firstPaymentDate = new Date(this.settlementTask.PaymentDay);
      }

      let paymentTypeId = this.settlementTask.PaymentType
        ? this.settlementTask.PaymentType.Id
        : null;

      let numberOfPayments = this.settlementTaskCopy.NumberOfPayments;

      let equalPaymentAmount = Math.floor(
        parseFloat(settlementOffer / numberOfPayments)
      );
      let firstPaymentAmount = parseFloat(
        settlementOffer - equalPaymentAmount * (numberOfPayments - 1)
      );

      if (this.checkAuth(10194)) {
        equalPaymentAmount = parseFloat(
          parseFloat(settlementOffer / numberOfPayments).toFixed(2)
        );
        let remainedAmount = parseFloat(
          parseFloat(
            settlementOffer - equalPaymentAmount * (numberOfPayments - 1)
          ).toFixed(2)
        );

        firstPaymentAmount =
          remainedAmount > equalPaymentAmount
            ? remainedAmount
            : equalPaymentAmount;
      }

      this.settlementTaskCopy.PaymentDetails = [];

      let totalPaymentAmount = 0;
      for (
        let index = 0;
        index < this.settlementTaskCopy.NumberOfPayments;
        index++
      ) {
        let paymentDate =
          index == 0
            ? firstPaymentDate
            : this.repeatsDayActiveCopy
            ? new Date(
                firstPaymentDate.getFullYear(),
                firstPaymentDate.getMonth(),
                firstPaymentDate.getDate()
              ).addDays(repeatsOfPayment * index)
            : new Date(
                firstPaymentDate.getFullYear(),
                firstPaymentDate.getMonth(),
                firstPaymentDate.getDate()
              ).addMonths(index);

        if (index == 0) {
          this.settlementTaskCopy.PaymentDetails.push({
            Id: index + 1,
            PaymentDate: new Date(paymentDate).toString("M/d/yyyy"),
            PaymentAmount: parseFloat(firstPaymentAmount.toFixed(2)),
            PaymentTypeId: paymentTypeId,
            FeeAmount: 0,
            FeePercentage: 0,
          });
          totalPaymentAmount = parseFloat(
            parseFloat(
              parseFloat(totalPaymentAmount) + parseFloat(firstPaymentAmount)
            ).toFixed(2)
          );

          this.calculateNetCash();
        } else if (index < this.settlementTaskCopy.NumberOfPayments - 1) {
          this.settlementTaskCopy.PaymentDetails.push({
            Id: index + 1,
            PaymentDate: new Date(paymentDate).toString("M/d/yyyy"),
            PaymentAmount: parseFloat(equalPaymentAmount.toFixed(2)),
            PaymentTypeId: paymentTypeId,
            FeeAmount: 0,
            FeePercentage: 0,
          });
          totalPaymentAmount = parseFloat(
            parseFloat(
              parseFloat(totalPaymentAmount) + parseFloat(equalPaymentAmount)
            ).toFixed(2)
          );

          this.calculateNetCash();
        } else {
          this.settlementTaskCopy.PaymentDetails.push({
            Id: index + 1,
            PaymentDate: new Date(paymentDate).toString("M/d/yyyy"),
            PaymentAmount: parseFloat(
              parseFloat(settlementOffer - totalPaymentAmount).toFixed(2)
            ),
            PaymentTypeId: paymentTypeId,
            FeeAmount: 0,
            FeePercentage: 0,
          });

          this.calculateNetCash();
        }
      }
    },

    async calculateFeesForPaymentPlanner() {
      clearTimeout(feeTimer);
      feeTimer = null;
      this.feeGridLoading = true;
      await this.sleep(5);
      let feeListPaymentPlannerOld = deepCopy(this.feeListPaymentPlanner);
      this.feeListPaymentPlanner = [];
      let totalFeeAmount = 0;
      const settlementFee = parseFloat(
        parseFloat(this.settlementTask.SettlementFee).toFixed(2)
      );
      this.calculateNetCash();
      this.feeListPaymentPlanner = deepCopy(feeListPaymentPlannerOld);
      const firstPaymentDate = new Date(this.settlementTask.PaymentDay);
      const maxPaymentDate =
        this.cashFlowList[this.cashFlowList.length - 1].Date;
      const differenceInDays = Math.ceil(
        (new Date(maxPaymentDate) - new Date()) / (1000 * 60 * 60 * 24)
      );

      let negativeCashList = this.cashFlowList.some(
        (x) => x.Balance < 0 && new Date(x.Date) >= firstPaymentDate
      );

      let netCashRevertValue,
        latestNet,
        feeRemained,
        possibleFeeAmount,
        charge2Percent;

      if (!negativeCashList) {
        this.feeListPaymentPlanner = [];
        let i = 0;

        while (totalFeeAmount < settlementFee && i < differenceInDays) {
          let date = new Date(
            firstPaymentDate.getFullYear(),
            firstPaymentDate.getMonth(),
            firstPaymentDate.getDate()
          ).addDays(i);
          date.setHours(23, 59, 59);

          let nextCashValues = this.cashFlowList.filter(
            (x) => new Date(x.Date) <= new Date(date)
          );
          if (nextCashValues && nextCashValues.length > 0) {
            netCashRevertValue = [...this.cashFlowList];
            latestNet = nextCashValues[nextCashValues.length - 1].Balance;
            feeRemained = settlementFee - totalFeeAmount;
            possibleFeeAmount = Math.min(latestNet, feeRemained);
            //charge2Percent = possibleFeeAmount * 0.02;

            if (this.minFeeChargeForPaymentPlanner > 0) {
              let testCharge = {
                Id: (this.feeListPaymentPlanner.length + 1) * 1000,
                FeeDate: date,
                FeeAmount: this.minFeeChargeForPaymentPlanner,
                FeePercentage: 0,
              };

              this.feeListPaymentPlanner.push(testCharge);
              this.calculateNetCash();
              let negativeCashListAfter2Percent = this.cashFlowList.some(
                (x) => x.Balance < 0 && new Date(x.Date) >= firstPaymentDate
              );

              if (
                negativeCashListAfter2Percent &&
                this.minFeeChargeForPaymentPlanner < feeRemained
              ) {
                this.feeListPaymentPlanner.pop();
                this.cashFlowList = deepCopy(netCashRevertValue);
              } else {
                this.feeListPaymentPlanner.pop();
                this.cashFlowList = deepCopy(netCashRevertValue);

                let j = 0;
                while (
                  ((possibleFeeAmount >= this.minFeeChargeForPaymentPlanner &&
                    this.minFeeChargeForPaymentPlanner <= feeRemained) ||
                    this.minFeeChargeForPaymentPlanner > feeRemained) &&
                  j < possibleFeeAmount &&
                  totalFeeAmount < settlementFee
                ) {
                  let chargeAmount =
                    possibleFeeAmount > this.minFeeChargeForPaymentPlanner
                      ? parseFloat(parseFloat(possibleFeeAmount - j).toFixed(2))
                      : parseFloat(parseFloat(feeRemained).toFixed(2));

                  let remainingFee = parseFloat(
                    parseFloat(settlementFee - totalFeeAmount).toFixed(2)
                  );

                  chargeAmount =
                    chargeAmount > remainingFee ? remainingFee : chargeAmount;

                  let feeId = (this.feeListPaymentPlanner.length + 1) * 1000;
                  if (
                    (chargeAmount >= this.minFeeChargeForPaymentPlanner ||
                      chargeAmount >= remainingFee) &&
                    chargeAmount > 0
                  ) {
                    this.feeListPaymentPlanner.push({
                      Id: feeId,
                      FeeDate: new Date(date),
                      FeeAmount: chargeAmount,
                      FeePercentage: 0,
                    });

                    this.calculateNetCash();

                    let negativeCashList2 = this.cashFlowList.some(
                      (x) =>
                        x.Balance < 0 && new Date(x.Date) >= firstPaymentDate
                    );
                    if (negativeCashList2) {
                      this.feeListPaymentPlanner.pop();
                      this.cashFlowList = deepCopy(netCashRevertValue);
                    } else {
                      totalFeeAmount = parseFloat(
                        parseFloat(
                          parseFloat(totalFeeAmount) + parseFloat(chargeAmount)
                        ).toFixed(2)
                      );

                      break;
                    }
                  }

                  j = j + this.minFeeChargeForPaymentPlanner;
                }
              }
            }
          }
          i++;
        }
      }
      this.calculateFeePercentage();
      this.feeGridLoading = false;
    },
    resetCalculateFeeTimer() {
      if (feeTimer != null) {
        clearTimeout(feeTimer);
      }
      if (this.settlementTask.IsTermSettlement) {
        feeTimer = setTimeout(
          this.calculateFeesForPaymentPlanner,
          this.calculateFeeDelay
        );
      }
    },
    async setPaymentDetailsPaymentPlanner(
      paymentDate = null,
      calculateFees = true,
      calculatePayments = true
    ) {
      if (this.settlementTask.IsTermSettlement) {
        //this.feeListPaymentPlanner = [];
        if (calculatePayments == true) {
          this.setOnlyPaymentsPaymentPlanner(paymentDate);
        }
        if (calculateFees == true) {
          this.resetCalculateFeeTimer();
        }
      } else {
        this.calculateNetCash();
      }
    },
    handleTabChange(tab) {
      let temp = [...this.tabs];
      temp = temp.map((item) => {
        item.active = item.id === tab.id ? true : false;
        return item;
      });
      this.tabs = [...temp];
      this.paymentPlannerId = tab.id;
      this.calculateNetCash();
    },
    openHardshipStatementPopup() {
      this.isHardshipStatementPopupOpen = true;
    },
    closeHardshipStatementPopup() {
      this.isHardshipStatementPopupOpen = false;
    },
    getPaymentTypeTransactionFee(paymentTypeId) {
      let rtn = 0;
      if (this.settlementTask.PaymentProcessorId == 1) {
        let indx = this.paymentTypes.findIndex((x) => x.Id == paymentTypeId);
        if (indx > -1) rtn = this.paymentTypes[indx].TransactionFee;
      }
      return rtn;
    },
    removeLeadingSpaces() {
      this.settlementTask.GlobalMemo =
        this.settlementTask.GlobalMemo.trimLeft();
      this.settlementTask.Note = this.settlementTask.Note.trimLeft();
    },
    async phonePayInfoButton() {
      let err, result;

      [err, result] = await this.$store.dispatch(
        types.PHONE_PAY_INFO_EDIT_NEGO_TASK,
        {
          CustomerId: this.settlementTask.Creditor.Customer.Id,
          OfficeId: this.settlementTask.Creditor.Customer.OfficeId,
        }
      );
      if (result) {
        this.$swal(
          "",
          "Account Number: " +
            result.Data.AccountNumber +
            "<hr>Routing Number: " +
            result.Data.RoutingNumber,
          "success"
        );
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
    },
    getHighestBalance() {
      return this.settlementTask && this.settlementTask.Creditor
        ? this.settlementTask.Creditor.CurrentBalance >=
          this.settlementTask.Creditor.OriginalBalance
          ? parseFloat(this.settlementTask.Creditor.CurrentBalance)
          : this.settlementTask.Creditor.OriginalBalance > 0
          ? parseFloat(this.settlementTask.Creditor.OriginalBalance)
          : 0
        : 0;
    },
    getBalanceByPriority() {
      let rtn = 0;

      if (this.settlementTask && this.settlementTask.Creditor) {
        let todaysCreditorBalance = parseFloat(
          this.settlementTask.Creditor.CurrentBalance
        );
        let currentCreditorBalance = parseFloat(
          this.settlementTask.Creditor.CurrentCreditorBalance
        );
        let originalBalance = parseFloat(
          this.settlementTask.Creditor.OriginalBalance
        );
        if (
          !isNaN(todaysCreditorBalance) &&
          todaysCreditorBalance > 0 &&
          this.checkAuth(33662)
        ) {
          //Today’s Creditor Balance is first priority.
          rtn = todaysCreditorBalance;
        } else if (
          !isNaN(currentCreditorBalance) &&
          currentCreditorBalance > 0
        ) {
          // 2nd priority Current Creditor Balance
          rtn = currentCreditorBalance;
        } else if (!isNaN(originalBalance) && originalBalance > 0) {
          // 3rd Original Balance
          rtn = originalBalance;
        }
      }
      return rtn;
    },
    removeTermRow(item) {
      if (
        this.settlementTask &&
        this.settlementTask.Creditor.SettlementStatus.Id != 4 &&
        this.settlementTask.PaymentDetails &&
        this.settlementTask.PaymentDetails.length > 0
      ) {
        let indx = this.settlementTask.PaymentDetails.findIndex(
          (x) => x.Id == item.Id
        );
        if (indx > -1) {
          this.settlementTask.PaymentDetails.splice(indx, 1);
          this.settlementTask.NumberOfPayments -= 1;
        }
      }
      this.calculateNetCash();
    },
    removeTermRowPaymentPlanner(item) {
      if (
        this.settlementTaskCopy &&
        this.settlementTaskCopy.Creditor.SettlementStatus.Id != 4 &&
        this.settlementTaskCopy.PaymentDetails &&
        this.settlementTaskCopy.PaymentDetails.length > 0
      ) {
        let indx = this.settlementTaskCopy.PaymentDetails.findIndex(
          (x) => x.Id == item.Id
        );
        if (indx > -1) {
          this.settlementTaskCopy.PaymentDetails.splice(indx, 1);
          this.settlementTaskCopy.NumberOfPayments -= 1;
          this.feeListPaymentPlanner = [];
        }
      }
      this.calculateNetCash();
      this.resetCalculateFeeTimer();
    },
    adjustDatesPaymentPlanner() {
      if (
        this.settlementTaskCopy &&
        this.settlementTaskCopy.Creditor.SettlementStatus.Id != 4 &&
        this.settlementTaskCopy.PaymentDetails &&
        this.settlementTaskCopy.PaymentDetails.length > 0
      ) {
        let firstPaymentDate = new Date(this.settlementTask.PaymentDay);

        let repeatsOfPayment =
          this.settlementTaskCopy.RepeatsOfPayments > 0
            ? this.settlementTaskCopy.RepeatsOfPayments
            : 0;

        for (
          let index = 0;
          index < this.settlementTaskCopy.PaymentDetails.length;
          index++
        ) {
          let paymentDate =
            index == 0
              ? firstPaymentDate
              : this.repeatsDayActiveCopy
              ? new Date(
                  firstPaymentDate.getFullYear(),
                  firstPaymentDate.getMonth(),
                  firstPaymentDate.getDate()
                ).addDays(repeatsOfPayment * index)
              : new Date(
                  firstPaymentDate.getFullYear(),
                  firstPaymentDate.getMonth(),
                  firstPaymentDate.getDate()
                ).addMonths(index);

          this.settlementTaskCopy.PaymentDetails[index].PaymentDate = new Date(
            paymentDate
          ).toString("M/d/yyyy");
        }

        this.calculateNetCash();
      }
    },
    adjustDates() {
      if (
        this.settlementTask &&
        this.settlementTask.Creditor.SettlementStatus.Id != 4 &&
        this.settlementTask.PaymentDetails &&
        this.settlementTask.PaymentDetails.length > 0
      ) {
        let firstPaymentDate = new Date(this.settlementTask.PaymentDay);

        let repeatsOfPayment =
          this.settlementTask.RepeatsOfPayments > 0
            ? this.settlementTask.RepeatsOfPayments
            : 0;

        for (
          let index = 0;
          index < this.settlementTask.PaymentDetails.length;
          index++
        ) {
          let paymentDate =
            index == 0
              ? firstPaymentDate
              : this.repeatsDayActive
              ? new Date(
                  firstPaymentDate.getFullYear(),
                  firstPaymentDate.getMonth(),
                  firstPaymentDate.getDate()
                ).addDays(repeatsOfPayment * index)
              : new Date(
                  firstPaymentDate.getFullYear(),
                  firstPaymentDate.getMonth(),
                  firstPaymentDate.getDate()
                ).addMonths(index);

          this.settlementTask.PaymentDetails[index].PaymentDate = new Date(
            paymentDate
          ).toString("M/d/yyyy");
        }
      }
    },
    overrideFeeChange() {
      if (this.overrideFeeCheckBox == false) {
        this.settlementTask.SettlementFee = this.settlementFeeBackup;
      } else if (this.allFeeCleared) {
        this.settlementTask.SettlementFee = 0;
      } else {
        this.settlementTask.SettlementFee =
          this.settlementTask.SettlementFeeOverride;
      }

      if (this.paymentPlannerId == 1) {
        this.setPaymentDetails();
      } else if (this.paymentPlannerId == 2) {
        this.setPaymentDetailsPaymentPlanner();
      }
      this.calculateAmountWaived();
    },
    getTotalPmtAmount() {
      let totalPmt = 0;
      for (let item of this.settlementTask.PaymentDetails) {
        totalPmt = parseFloat(totalPmt) + parseFloat(item.PaymentAmount);
      }
      return totalPmt;
    },
    getTotalPmtAmountPaymentPlanner() {
      let totalPmt = 0;
      for (let item of this.settlementTaskCopy.PaymentDetails) {
        totalPmt = parseFloat(totalPmt) + parseFloat(item.PaymentAmount);
      }
      return totalPmt;
    },

    getTotalFeeAmount() {
      let totalFee = 0;
      for (let item of this.settlementTask.PaymentDetails) {
        totalFee = parseFloat(totalFee) + parseFloat(item.FeeAmount);
      }
      return totalFee;
    },
    getTotalFeeAmountPaymentPlanner() {
      let totalFee = 0;
      if (this.feeListPaymentPlanner && this.feeListPaymentPlanner.length > 0) {
        for (let item of this.feeListPaymentPlanner) {
          totalFee = parseFloat(totalFee) + parseFloat(item.FeeAmount);
        }
      }
      return totalFee;
    },
    getTotalFee() {
      let totalFeePercentage = 0;
      for (let item of this.settlementTask.PaymentDetails) {
        totalFeePercentage =
          parseFloat(totalFeePercentage) + parseFloat(item.FeePercentage);
      }
      return totalFeePercentage;
    },

    originalBalanceChange() {
      this.settlementTask.Offer =
        this.getBalanceByPriority() *
        parseFloat(this.originalBalancePercentage);
      if (isNaN(this.settlementTask.Offer)) {
        this.settlementTask.Offer = 0;
      } else {
        this.settlementTask.Offer = parseFloat(
          this.settlementTask.Offer / 100
        ).toFixed(2);
      }

      if (this.paymentPlannerId == 1) {
        this.setPaymentDetails();
      } else if (this.paymentPlannerId == 2) {
        this.setPaymentDetailsPaymentPlanner();
      }
    },
    paymentTypeChange() {
      if (this.paymentPlannerId == 1) {
        this.setPaymentDetails();
      } else if (this.paymentPlannerId == 2) {
        this.setPaymentDetailsPaymentPlanner();
      }
    },
    updateOriginalBalancePercentage() {
      if (this.settlementTask.Offer && this.settlementTask.Offer >= 0) {
        if (this.settlementTask.Offer > 0) {
          let highestBalance = this.getBalanceByPriority();
          let percValue =
            highestBalance > 0
              ? parseFloat(this.settlementTask.Offer) / highestBalance
              : 0;
          percValue = parseFloat(percValue * 100).toFixed(2);

          this.originalBalancePercentage = percValue;
        } else {
          this.originalBalancePercentage = 0;
        }
      }
    },
    async getCashFlowData() {
      if (
        this.settlementTask.Creditor.Customer.Id &&
        this.settlementTask.Creditor.Customer.OfficeId
      ) {
        this.tableLoading = true;
        let err, result;
        [err, result] = await this.$store.dispatch(
          types.GET_CASHFLOW_CREDITOR,
          {
            CustomerId: this.settlementTask.Creditor.Customer.Id,
            OfficeId: this.settlementTask.Creditor.Customer.OfficeId,
            CreditorId: this.settlementTask.Creditor.Id,
          }
        );

        if (result && result.Data) {
          if (result.Data.CashFlows) {
            this.cashFlowList = deepCopy(result.Data.CashFlows);
            this.cashFlowListOldValue = deepCopy(result.Data.CashFlows);
          }

          if (
            !this.settlementStatusIds.includes(
              this.settlementTask.Creditor.SettlementStatus.Id
            )
          ) {
            if (result.Data.OngoingSettlements) {
              this.ongoingSettlements = deepCopy(
                result.Data.OngoingSettlements
              );
            }
          }

          this.calculateNetCash();
          this.tableLoading = false;
        }
      }
    },

    async dataSyncProcessCreditor(customerList, creditorId, userId, userName,taskId) {
      if (!customerList.includes(this.settlementTask.Creditor.Customer.Id))
        return;

      if (creditorId != this.settlementTask.Creditor.Id) return;

      if (!(this.taskId > 0)) return;

      if(this.taskId !=  taskId) return;

      let err, result;
      let updateOldTask = true;
      [err, result] = await this.$store.dispatch(
        types.GET_NEGOTIATOR_TASK_DETAILS,
        {
          Id: this.taskId,
        }
      );

      if (result) {
        if (result.Data) {
          let showMessage = false;
          let localChangesArr = [];
          let remoteChangesArr = [];

          if (
            !this.checkAuth(7478) &&
            result.Data.Creditor.NegotiatorId != this.userId
          ) {
            this.$swal(
              "Warning",
              "This task assigned to another negotiator. Please contact your manager.",
              "warning"
            );
            this.closePanel();
          }

          if (
            this.settlementTask.Creditor.SettlementStatus.Id !=
            result.Data.Creditor.SettlementStatus.Id
          ) {
            this.$swal(
              "Warning",
              "This settlement approved or declined. Please contact your manager.",
              "warning"
            );
            this.closePanel();
          }

          if (
            this.settlementTask.SettlementFee != result.Data.SettlementFee &&
            this.autoCalculateOpen == true
          ) {
            this.setPaymentDetails();
          }

          // Change Detection Process
          //get local change Array
          if (JSON.stringify(this.settlementTask) != this.oldValue) {
            // there are local changes. fill up local change array
            this.recursiveChangeComparison(
              this.settlementTask,
              JSON.parse(this.oldValue),
              localChangesArr
            );
            showMessage = true;
            updateOldTask = false;
          }

          let scrollX = window.pageXOffset;
          let scrollY = window.pageYOffset;

          let tmpOldVal = this.oldValue;
          if (!showMessage) {
            let tmp = Object.assign({}, result.Data);
            this.settlementTask = Object.assign({}, tmp);
          } else {
            showMessage = false;
            //get remote change array
            this.recursiveChangeComparison(
              result.Data,
              JSON.parse(tmpOldVal),
              remoteChangesArr
            );

            // compare local and remote changes arrays. if there is any similarity show message.
            for (let item of localChangesArr) {
              if (remoteChangesArr.includes(item)) {
                showMessage = true;
                break;
              }
            }

            //update changed values only...
            if (remoteChangesArr.length > 0) {
              for (let item of remoteChangesArr) {
                let changedPath = this.findPath(this.settlementTask, item);
                if (changedPath.startsWith(".")) {
                  changedPath = changedPath.slice(1, changedPath.length);
                }
                let resultObj = deepCopy(result.Data);
                let newValue = this.getObjectValueFromPath(
                  resultObj,
                  changedPath
                );
                this.setValueFromPath(
                  this.settlementTask,
                  changedPath,
                  newValue
                );
              }
            }
          }

          if (updateOldTask)
            this.oldValue = JSON.stringify(this.settlementTask);

          await this.handleScroll(scrollX, scrollY);

          if (showMessage && this.userId != null && this.userId == userId) {
            this.$swal(
              "Warning",
              "This creditor has been updated by another component.",
              "warning"
            );

            this.refreshTaskData();
          } else if (
            showMessage &&
            userName != "System" &&
            this.userId != userId
          ) {
            this.$swal(
              "Warning",
              userName + " just made some changes on some fields you edited.",
              "warning"
            );

            this.refreshTaskData();
          }
        }
      }

      this.$forceUpdate();
    },
    async refreshTaskData() {
      await this.getCashFlowData();
    },
    recursiveChangeComparison(obj1, obj2, changeArray) {
      let r = changeArray;
      Object.keys(obj1).forEach((key) => {
        let value1 = obj1[key];
        let value2 = obj2[key];

        if (
          typeof value1 !== "object" &&
          typeof value2 !== "object" &&
          value1 != value2
        ) {
          r.push(key);
        } else if (
          Array.isArray(value1) &&
          Array.isArray(value2) &&
          JSON.stringify(value1) != JSON.stringify(value2)
        ) {
          r.push(key);
        } else if (
          typeof value1 === "object" &&
          value1 != null &&
          typeof value2 === "object" &&
          value2 != null
        ) {
          this.recursiveChangeComparison(value1, value2, changeArray);
        }
      });
      return r;
    },
    async handleScroll(x, y) {
      await this.sleep(10);
      window.scrollTo(x, y);
    },
    getObjectValueFromPath(object, path) {
      path = path.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
      path = path.replace(/^\./, ""); // strip a leading dot
      let a = path.split(".");
      for (let i = 0; i < a.length; i++) {
        let k = a[i];
        if (k in object) {
          object = object[k];
        } else {
          return;
        }
      }
      return object;
    },
    findPath(ob, key) {
      let path = [];
      let keyExists = function (obj) {
        if (!obj || (typeof obj !== "object" && !Array.isArray(obj))) {
          return false;
        } else if (Object.prototype.hasOwnProperty.call(obj, key)) {
          return true;
        } else if (Array.isArray(obj)) {
          let parentKey = path.length ? path.pop() : "";

          for (let i = 0; i < obj.length; i++) {
            path.push(`${parentKey}[${i}]`);
            const result = keyExists(obj[i], key);
            if (result) {
              return result;
            }
            path.pop();
          }
        } else {
          for (let k in obj) {
            path.push(k);
            let result = keyExists(obj[k], key);
            if (result) {
              return result;
            }
            path.pop();
          }
        }
        return false;
      };

      keyExists(ob);

      return path.join(".") + "." + key;
    },
    setValueFromPath(object, path, value) {
      path
        .split(".")
        .reduce(
          (o, p, i) =>
            (o[p] = path.split(".").length === ++i ? value : o[p] || {}),
          object
        );
    },

    feeAmountChange(item, event) {
      this.autoCalculateOpen = false;

      if (item && event && event.target && event.target.value) {
        let currentFeeAmount = parseFloat(event.target.value);
        if (!isNaN(currentFeeAmount) && currentFeeAmount > 0) {
          this.calculateFees(item, currentFeeAmount);
        } else {
          item.FeeAmount = 0;
          item.FeePercentage = 0;
        }

        this.calculateNetCash();
      } else if (item && event && event.target && event.target.value == "") {
        item.FeeAmount = 0;
        item.FeePercentage = 0;
        this.calculateNetCash();
      }
    },
    feeAmountChangePaymentPlanner(item, event) {
      if (item && event && event.target && event.target.value) {
        let currentFeeAmount = parseFloat(event.target.value);
        if (!isNaN(currentFeeAmount)) {
          this.calculateFeesPaymentPlanner(item, currentFeeAmount);
        } else {
          item.FeeAmount = 0;
          item.FeePercentage = 0;
        }

        this.calculateNetCash();
      } else if (item && event && event.target && event.target.value == "") {
        item.FeeAmount = 0;
        item.FeePercentage = 0;
        this.calculateNetCash();
      }
      this.calculateFeePercentage();
    },
    calculateFeesPaymentPlanner(item, currentFeeAmount) {
      if (
        this.settlementTaskCopy.PaymentDetails &&
        this.settlementTaskCopy.PaymentDetails.length > 0
      ) {
        let totalFees = parseFloat(this.settlementTaskCopy.SettlementFee);

        let oldFees = this.feeListPaymentPlanner.filter(
          (x) =>
            x.Id != item.Id && new Date(x.FeeDate) <= new Date(item.FeeDate)
        );

        let oldFeesTotal = 0;
        for (let p of oldFees) {
          oldFeesTotal += parseFloat(p.FeeAmount);
        }

        let fees = this.feeListPaymentPlanner.filter(
          (x) => x.Id != item.Id && new Date(x.FeeDate) > new Date(item.FeeDate)
        );

        let equalFeesTotal = 0;
        let feesSum = parseFloat(
          totalFees - parseFloat(oldFeesTotal + currentFeeAmount)
        );

        if (feesSum > 0) {
          for (let f of fees) {
            if (feesSum > 0) {
              f.FeeAmount = Math.floor(
                parseFloat(
                  parseFloat(
                    totalFees - parseFloat(oldFeesTotal + currentFeeAmount)
                  ) / fees.length
                )
              );
              equalFeesTotal += f.FeeAmount;
            }
          }
        } else {
          currentFeeAmount = parseFloat(totalFees - oldFeesTotal);
          item.FeeAmount = parseFloat(currentFeeAmount.toFixed(2));
          for (let f of fees) {
            f.FeeAmount = 0;
          }
        }
        if (
          parseFloat(equalFeesTotal + currentFeeAmount + oldFeesTotal) !=
          totalFees
        ) {
          let gapAmount = parseFloat(
            totalFees -
              parseFloat(equalFeesTotal + currentFeeAmount + oldFeesTotal)
          );
          if (fees.length > 0) {
            fees[0].FeeAmount += parseFloat(gapAmount.toFixed(2));
          } else {
            item.FeeAmount += parseFloat(gapAmount.toFixed(2));
          }
        }
        item.FeePercentage =
          parseFloat(
            currentFeeAmount / parseFloat(this.settlementTaskCopy.SettlementFee)
          ) * 100;

        for (let f of fees) {
          f.FeePercentage = parseFloat(
            (
              parseFloat(
                f.FeeAmount / parseFloat(this.settlementTaskCopy.SettlementFee)
              ) * 100
            ).toFixed(2)
          );
        }
      }
    },
    calculateFees(item, currentFeeAmount) {
      if (
        !this.autoCalculateOpen &&
        this.settlementTask.PaymentDetails &&
        this.settlementTask.PaymentDetails.length > 0
      ) {
        let totalFeeAmount = parseFloat(this.settlementTask.SettlementFee);

        let oldPayments = this.settlementTask.PaymentDetails.filter(
          (x) =>
            x.Id != item.Id &&
            new Date(x.PaymentDate) <= new Date(item.PaymentDate)
        );

        let oldFeesTotal = 0;
        for (let p of oldPayments) {
          oldFeesTotal += parseFloat(p.FeeAmount);
        }
        let payments = this.settlementTask.PaymentDetails.filter(
          (x) =>
            x.Id != item.Id &&
            new Date(x.PaymentDate) > new Date(item.PaymentDate)
        );

        let equalFeesTotal = 0;
        let feesSum = parseFloat(
          totalFeeAmount - parseFloat(oldFeesTotal + currentFeeAmount)
        );
        if (feesSum > 0) {
          for (let p of payments) {
            if (feesSum > 0) {
              p.FeeAmount = Math.floor(
                parseFloat(
                  parseFloat(
                    totalFeeAmount - parseFloat(oldFeesTotal + currentFeeAmount)
                  ) / payments.length
                )
              );
              equalFeesTotal += p.FeeAmount;
            }
          }
        } else {
          currentFeeAmount = parseFloat(totalFeeAmount - oldFeesTotal);
          item.FeeAmount = currentFeeAmount;
          for (let p of payments) {
            p.FeeAmount = 0;
          }
        }
        if (
          parseFloat(equalFeesTotal + currentFeeAmount + oldFeesTotal) !=
          totalFeeAmount
        ) {
          let gapAmount = parseFloat(
            totalFeeAmount -
              parseFloat(equalFeesTotal + currentFeeAmount + oldFeesTotal)
          );
          if (payments.length > 0) {
            payments[0].FeeAmount += parseFloat(gapAmount.toFixed(2));
          } else {
            item.FeeAmount += parseFloat(gapAmount.toFixed(2));
          }
        }
        item.FeePercentage =
          parseFloat(
            currentFeeAmount / parseFloat(this.settlementTask.SettlementFee)
          ) * 100;

        for (let p of payments) {
          p.FeePercentage = parseFloat(
            (
              parseFloat(
                p.FeeAmount / parseFloat(this.settlementTask.SettlementFee)
              ) * 100
            ).toFixed(2)
          );
        }
      }
    },
    feePercentageChange(item, event) {
      if (item && event && event.target && event.target.value) {
        this.autoCalculateOpen = false;
        let value = parseFloat(event.target.value);
        let feeAmount = parseFloat(this.settlementTask.SettlementFee * value);
        feeAmount = parseFloat(feeAmount / 100).toFixed(2);
        item.FeeAmount = parseFloat(feeAmount);
        this.calculateFees(item, item.FeeAmount);
        this.calculateNetCash();
      }
    },
    feePercentageChangePaymentPlanner(item, event) {
      if (item && event && event.target && event.target.value) {
        let value = parseFloat(event.target.value);
        let feeAmount = parseFloat(
          this.settlementTaskCopy.SettlementFee * value
        );
        feeAmount = parseFloat(feeAmount / 100).toFixed(2);
        item.FeeAmount = parseFloat(feeAmount);
        this.calculateFeesPaymentPlanner(item, item.FeeAmount);
        this.calculateNetCash();
      }
    },
    paymentDateChange(item) {
      let indx = this.paymentDetailsOld.findIndex((x) => x.Id == item.Id);

      if (indx > -1) {
        let oldDate = new Date(this.paymentDetailsOld[indx].PaymentDate);
        let newDate = new Date(item.PaymentDate);

        if (
          oldDate.getMonth() != newDate.getMonth() ||
          oldDate.getFullYear() != newDate.getFullYear()
        ) {
          this.$swal("Warning", "Only day value can be changed.", "warning");
          item.PaymentDate = oldDate.toLocaleDateString();
        } else {
          this.acceptPaymentDetail(item);
        }
      } else {
        this.acceptPaymentDetail(item);
      }
    },
    feeDateChangePaymentPlanner(item) {
      this.feeGridLoading = true;
      this.feeListPaymentPlanner = _orderby.orderBy(
        this.feeListPaymentPlanner,
        "FeeDate",
        "asc"
      );
      this.feeGridLoading = false;
      this.calculateNetCash();
    },
    paymentAmountKeyUp(item) {
      if (item.PaymentAmount != "" && !isNaN(item.PaymentAmount)) {
        let value = parseFloat(item.PaymentAmount);
        if (value >= 0) {
          item.PaymentAmount = parseFloat(item.PaymentAmount.toFixed(2));
        } else {
          item.PaymentAmount = 0;
        }
      }
    },
    feeAmountKeyUp(item) {
      if (item.FeeAmount != "" && !isNaN(item.FeeAmount)) {
        let value = parseFloat(item.FeeAmount);
        if (value >= 0) {
          item.FeeAmount = parseFloat(item.FeeAmount.toFixed(2));
        } else {
          item.FeeAmount = 0;
        }
      }
    },
    paymentAmountChange(item, event) {
      if (item && event && event.target && event.target.value == "") {
        item.PaymentAmount = 0;
      }

      this.autoCalculateOpen = false;

      if (
        !this.autoCalculateOpen &&
        this.settlementTask.PaymentDetails &&
        this.settlementTask.PaymentDetails.length > 0
      ) {
        let currentPaymentAmount = parseFloat(item.PaymentAmount);
        let totalPaymentAmount = parseFloat(this.settlementTask.Offer);

        let oldPayments = this.settlementTask.PaymentDetails.filter(
          (x) =>
            x.Id != item.Id &&
            new Date(x.PaymentDate) <= new Date(item.PaymentDate)
        );

        let oldPaymentsTotal = 0;
        for (let p of oldPayments) {
          oldPaymentsTotal += parseFloat(p.PaymentAmount);
        }
        let payments = this.settlementTask.PaymentDetails.filter(
          (x) =>
            x.Id != item.Id &&
            new Date(x.PaymentDate) > new Date(item.PaymentDate)
        );
        let equalPaymentsTotal = 0;
        let paymentsSum = parseFloat(
          totalPaymentAmount -
            parseFloat(oldPaymentsTotal + currentPaymentAmount)
        );
        if (paymentsSum > 0) {
          for (let p of payments) {
            if (paymentsSum > 0) {
              p.PaymentAmount = Math.floor(
                parseFloat(
                  parseFloat(
                    totalPaymentAmount -
                      parseFloat(oldPaymentsTotal + currentPaymentAmount)
                  ) / payments.length
                )
              );
              equalPaymentsTotal += p.PaymentAmount;
            }
          }
        } else {
          currentPaymentAmount = parseFloat(
            totalPaymentAmount - oldPaymentsTotal
          );
          item.PaymentAmount = currentPaymentAmount;
          for (let p of payments) {
            p.PaymentAmount = 0;
          }
        }
        if (
          parseFloat(
            equalPaymentsTotal + currentPaymentAmount + oldPaymentsTotal
          ) != totalPaymentAmount
        ) {
          let gapAmount = parseFloat(
            totalPaymentAmount -
              parseFloat(
                equalPaymentsTotal + currentPaymentAmount + oldPaymentsTotal
              )
          );
          if (payments.length > 0) {
            payments[0].PaymentAmount += parseFloat(gapAmount.toFixed(2));
          } else {
            item.PaymentAmount += parseFloat(gapAmount.toFixed(2));
          }
        }

        //item.PaymentAmount = this.formatMoney(item.PaymentAmount);

        this.calculateNetCash();
      }
    },
    paymentAmountChangePaymentPlanner(item, event) {
      if (item && event && event.target && event.target.value == "") {
        item.PaymentAmount = 0;
      }

      if (
        this.settlementTaskCopy.PaymentDetails &&
        this.settlementTaskCopy.PaymentDetails.length > 0
      ) {
        let currentPaymentAmount = parseFloat(item.PaymentAmount);
        let totalPaymentAmount = parseFloat(this.settlementTask.Offer);

        let oldPayments = this.settlementTaskCopy.PaymentDetails.filter(
          (x) =>
            x.Id != item.Id &&
            new Date(x.PaymentDate) <= new Date(item.PaymentDate)
        );

        let oldPaymentsTotal = 0;
        for (let p of oldPayments) {
          oldPaymentsTotal += parseFloat(p.PaymentAmount);
        }
        let payments = this.settlementTaskCopy.PaymentDetails.filter(
          (x) =>
            x.Id != item.Id &&
            new Date(x.PaymentDate) > new Date(item.PaymentDate)
        );
        let equalPaymentsTotal = 0;
        let paymentsSum = parseFloat(
          totalPaymentAmount -
            parseFloat(oldPaymentsTotal + currentPaymentAmount)
        );
        if (paymentsSum > 0) {
          for (let p of payments) {
            if (paymentsSum > 0) {
              p.PaymentAmount = Math.floor(
                parseFloat(
                  parseFloat(
                    totalPaymentAmount -
                      parseFloat(oldPaymentsTotal + currentPaymentAmount)
                  ) / payments.length
                )
              );
              equalPaymentsTotal += p.PaymentAmount;
            }
          }
        } else {
          currentPaymentAmount = parseFloat(
            totalPaymentAmount - oldPaymentsTotal
          );
          item.PaymentAmount = currentPaymentAmount;
          for (let p of payments) {
            p.PaymentAmount = 0;
          }
        }
        if (
          parseFloat(
            equalPaymentsTotal + currentPaymentAmount + oldPaymentsTotal
          ) != totalPaymentAmount
        ) {
          let gapAmount = parseFloat(
            totalPaymentAmount -
              parseFloat(
                equalPaymentsTotal + currentPaymentAmount + oldPaymentsTotal
              )
          );
          if (payments.length > 0) {
            payments[0].PaymentAmount += parseFloat(gapAmount.toFixed(2));
          } else {
            item.PaymentAmount += parseFloat(gapAmount.toFixed(2));
          }
        }

        //item.PaymentAmount = this.formatMoney(item.PaymentAmount);
        this.feeGridLoading = true;
        this.feeListPaymentPlanner = [];
        this.feeGridLoading = false;
        this.calculateNetCash();
        this.resetCalculateFeeTimer();
      }
    },
    paymentTypeChangedPaymentPlanner() {
      this.feeGridLoading = true;
      this.feeListPaymentPlanner = [];
      this.feeGridLoading = false;
      this.calculateNetCash();
      this.resetCalculateFeeTimer();
    },
    paymentTypeChanged() {
      this.calculateNetCash();
    },

    acceptPaymentDetail(item) {
      if (
        item.PaymentDate == "" ||
        isNaN(new Date(item.PaymentDate)) ||
        new Date(item.PaymentDate) == "Invalid Date" ||
        new Date(item.PaymentDate) <= new Date(1970, 1, 1)
      ) {
        this.$swal("Warning", "Please enter a valid date.", "warning");
      } else {
        item.PaymentDate = this.formatDateWithoutLocalization(item.PaymentDate);
        this.calculateNetCash();
      }
    },
    getDateEndOfDay(date) {
      return new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        23,
        59,
        59
      );
    },
    calculateNetCash() {
      let disableOngoingSettlements = this.settlementStatusIds.includes(
        this.settlementTask.Creditor.SettlementStatus.Id
      );

      if (disableOngoingSettlements == false) {
        disableOngoingSettlements = this.disableOngoingChk;
      }

      let paymentTypeTransactionFee = parseFloat(
        this.getPaymentTypeTransactionFee(this.settlementTask.PaymentType.Id)
      );

      let list = deepCopy(this.cashFlowListOldValue);
      if (!disableOngoingSettlements) {
        for (let i of this.ongoingSettlements) {
          list.push({
            Id: "X",
            TransactionType: "P",
            Date: i.PaymentDate,
            Amount: i.PaymentAmount + i.FeeAmount + parseFloat(
        this.getPaymentTypeTransactionFee(i.PaymentTypeId)
      ),
            Balance: 0,
            Memo: i.CreditorName + " - " + this.formatShortDate(i.PaymentDate),
            IsOngoingSettlements: true,
          });
        }
      }

      if (
        this.oneTimeSettlementFee > 0 &&
        this.settlementTask.PaymentDay &&
        this.settlementTask.PaymentDay != ""
      ) {
        list.push({
          Id: "X",
          TransactionType: "P",
          Date: this.getDateEndOfDay(new Date(this.settlementTask.PaymentDay)),
          Amount: parseFloat(this.oneTimeSettlementFee),
          Balance: 0,
          Memo: "One Time Settlement Fee",
          IsOngoingSettlements: true,
        });
      }

      if (this.paymentPlannerId == 1) {
        if (this.settlementTask) {
          if (
            this.settlementTask.IsTermSettlement &&
            this.settlementTask.PaymentDetails &&
            this.settlementTask.PaymentDetails.length > 0
          ) {
            for (let item of this.settlementTask.PaymentDetails) {
              if (item.PaymentDate) {
                list.push({
                  Id: "X",
                  TransactionType: "P",
                  Date: this.getDateEndOfDay(new Date(item.PaymentDate)),
                  Amount:
                    parseFloat(item.PaymentAmount) +
                    parseFloat(item.FeeAmount) +
                    parseFloat(
                      this.getPaymentTypeTransactionFee(
                        item.PaymentTypeId > 0
                          ? item.PaymentTypeId
                          : this.settlementTask.PaymentType.Id
                      )
                    ),
                  Balance: 0,
                  Memo:
                    this.settlementTask.Creditor.Name +
                    " - " +
                    this.formatShortDate(item.PaymentDate),
                  IsOngoingSettlements: true,
                  IsCurrentTask: true,
                });
              }
            }
          } else {
            if (this.settlementTask.PaymentDay) {
              let paymentAmount = parseFloat(this.settlementTask.Offer);
              let feeAmount = parseFloat(this.settlementTask.SettlementFee);

              list.push({
                Id: "X",
                TransactionType: "P",
                Date: this.getDateEndOfDay(
                  new Date(this.settlementTask.PaymentDay)
                ),
                Amount: paymentAmount + feeAmount + paymentTypeTransactionFee,
                Balance: 0,
                Memo:
                  this.settlementTask.Creditor.Name +
                  " - " +
                  this.formatShortDate(this.settlementTask.PaymentDay),
                IsOngoingSettlements: true,
                IsCurrentTask: true,
              });
            }
          }
        }
      } else if (this.paymentPlannerId == 2) {
        if (this.settlementTaskCopy) {
          if (
            this.settlementTaskCopy.IsTermSettlement &&
            this.settlementTaskCopy.PaymentDetails &&
            this.settlementTaskCopy.PaymentDetails.length > 0
          ) {
            for (let item of this.settlementTaskCopy.PaymentDetails) {
              if (item.PaymentDate) {
                list.push({
                  Id: "X",
                  TransactionType: "P",
                  Date: this.getDateEndOfDay(new Date(item.PaymentDate)),
                  Amount:
                    parseFloat(item.PaymentAmount) +
                    parseFloat(
                      this.getPaymentTypeTransactionFee(
                        item.PaymentTypeId > 0
                          ? item.PaymentTypeId
                          : this.settlementTask.PaymentType.Id
                      )
                    ),
                  Balance: 0,
                  Memo:
                    this.settlementTaskCopy.Creditor.Name +
                    " - " +
                    this.formatShortDate(item.PaymentDate),
                  IsOngoingSettlements: true,
                  IsCurrentTask: true,
                });
              }
            }

            for (let fee of this.feeListPaymentPlanner) {
              if (fee.FeeDate) {
                list.push({
                  Id: "X",
                  TransactionType: "P",
                  Date: this.getDateEndOfDay(new Date(fee.FeeDate)),
                  Amount: parseFloat(fee.FeeAmount),
                  Balance: 0,
                  Memo:
                    this.settlementTaskCopy.Creditor.Name +
                    " - Fee - " +
                    this.formatShortDate(fee.FeeDate),
                  IsOngoingSettlements: true,
                  IsCurrentTask: true,
                });
              }
            }
          } else {
            if (this.settlementTask.PaymentDay) {
              let paymentAmount = parseFloat(this.settlementTask.Offer);
              let feeAmount = parseFloat(this.settlementTask.SettlementFee);

              list.push({
                Id: "X",
                TransactionType: "P",
                Date: this.getDateEndOfDay(
                  new Date(this.settlementTask.PaymentDay)
                ),
                Amount: paymentAmount + feeAmount + paymentTypeTransactionFee,
                Balance: 0,
                Memo:
                  this.settlementTask.Creditor.Name +
                  " - " +
                  this.formatShortDate(this.settlementTask.PaymentDay),
                IsOngoingSettlements: true,
                IsCurrentTask: true,
              });
            }
          }
        }
      }

      list.sort((a, b) => new Date(a.Date) - new Date(b.Date));

      for (let i = 0; i < list.length; i++) {
        if (list[i].IsOngoingSettlements == true) {
          if (i > 0) {
            list[i].Balance = parseFloat(
              parseFloat(list[i - 1].Balance - list[i].Amount).toFixed(2)
            );
            list[i].Id = parseInt(list[i - 1].Id + 1);
          }

          for (let j = i + 1; j < list.length; j++) {
            list[j].Balance = parseFloat(
              parseFloat(list[j].Balance - list[i].Amount).toFixed(2)
            );
            list[j].Id += 1;
          }
        }
      }

      this.cashFlowList = deepCopy(list);

      this.negativeCashFlowAlert =
        this.cashFlowList.filter(
          (x) =>
            x.Balance < 0 &&
            new Date(x.Date) >= new Date(this.settlementTask.PaymentDay)
        ).length > 0;
    },
    hasAuth(mfid) {
      return this.arrPermission.includes(mfid);
    },
    async reviewDeclined() {
      this.closePanel(
        this.settlementTask.Id,
        this.settlementTask.Creditor.Customer.Name
      );
    },
    closeDeclineReviewPopup() {
      this.isDeclineReviewPopupOpen = false;
    },
    async termSettlementChanged() {
      if (!this.settlementTask.IsTermSettlement) {
        await this.$swal({
          title: "Confirm",
          text: "All term settlement data will be removed and the creditor status will be changed from Term Settlement to Active. Would you like to continue ?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then(async (dialog) => {
          if (dialog.value) {
            if (this.paymentPlannerId == 1) {
              this.settlementTask.PaymentDetails = [];
              this.settlementTask.RepeatsOfPayments = 0;
              this.settlementTask.NumberOfPayments = 2;
            } else if (this.paymentPlannerId == 2) {
              this.settlementTaskCopy.PaymentDetails = [];
              this.settlementTaskCopy.RepeatsOfPayments = 0;
              this.settlementTaskCopy.NumberOfPayments = 12;
              this.feeListPaymentPlanner = [];
            }
            this.repeatsDayActive = false;
            this.handleTabChange({ id: this.paymentPlannerId });
          } else {
            this.settlementTask.IsTermSettlement = true;
            this.handleTabChange({ id: this.paymentPlannerId });
          }
        });
      } else {
        if (
          this.settlementTask.PaymentType == null ||
          !(this.settlementTask.PaymentType.Id > 0)
        ) {
          this.$swal("Warning", "Please select a payment type", "warning");
          this.settlementTask.IsTermSettlement = false;
          return;
        }

        if (
          this.settlementTask.PaymentDay == null ||
          this.settlementTask.PaymentDay == ""
        ) {
          await this.$swal({
            title: "Confirm",
            text: "First payment day will be set to tomorrow. Would you like to continue ?",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Continue",
          }).then(async (dialog) => {
            if (dialog.value) {
              this.settlementTask.PaymentDay = new Date().addDays(1);
            } else {
              this.settlementTask.IsTermSettlement = false;
            }
          });
        }

        this.settlementTaskCopy = deepCopy(this.settlementTask);
        for (let item of this.settlementTaskCopy.PaymentDetails) {
          item.FeeAmount = 0;
          item.FeePercentage = 0;
        }

        if (this.paymentPlannerId == 1) {
          this.settlementTask.NumberOfPayments = 2;
          this.autoCalculateOpen = true;
          this.setPaymentDetails();
        } else if (this.paymentPlannerId == 2) {
          this.settlementTaskCopy.NumberOfPayments = 12;
          this.setPaymentDetailsPaymentPlanner();
        }

        this.handleTabChange({ id: this.paymentPlannerId });
      }
      this.calculateNetCash();
    },
    setExistedFees() {
      if (
        this.settlementTask.IsTermSettlement &&
        this.settlementTask.PaymentDetails &&
        this.settlementTask.PaymentDetails.length > 0
      ) {
        this.feeListPaymentPlanner = [];
        let fees = this.settlementTask.PaymentDetails.filter(
          (x) => parseFloat(x.FeeAmount) > 0
        );
        if (fees.length > 0) {
          for (let item of fees) {
            if (parseFloat(item.FeeAmount) > 0) {
              this.feeListPaymentPlanner.push({
                Id: item.Id,
                FeeDate: new Date(item.PaymentDate),
                FeeAmount: parseFloat(item.FeeAmount),
                FeePercentage: item.FeePercentage,
              });
            }
          }
        }
      } else {
        this.feeListPaymentPlanner = [];
      }
    },
    showCustomer() {
      this.$Emitter.emit("viewCustomer", {
        customerId: this.settlementTask.Creditor.Customer.Id,
        customerName: this.settlementTask.Creditor.Customer.Name,
      });
    },
    documentSelected(documentName) {
      this.settlementTask.SettlementAgreementFileName = documentName;
      this.isDocumentsPopupOpen = false;
    },
    async dowmloadSettlementAgreement() {
      let fileName = this.settlementTask.SettlementAgreementFileName;
      let fileExtension = "";
      fileExtension = this.getExtension(fileName);
      if (fileExtension != "") {
        let err, result;
        let dataToDownload = {
          Name: this.getFileNameWithoutExt(fileName),
          Extension: fileExtension,
          CustomerId: this.settlementTask.Creditor.Customer.Id,
        };

        [err, result] = await this.$store.dispatch(
          customerTypes.DOWNLOAD_CUSTOMER_DOCUMENT,
          dataToDownload
        );

        if (result) {
          this.saveByteArray(fileName, result);
        }
      }
    },
    saveByteArray(name, byte) {
      let blob = new Blob([byte], { type: "application/docx" });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      let fileName = name;
      link.download = fileName;
      link.click();
    },
    openDocumentPopup() {
      this.isDocumentsPopupOpen = true;
    },
    closeDocumentPopup() {
      this.isDocumentsPopupOpen = false;
    },
    outsideGridRow() {
      for (let i = 0; i < this.settlementTask.PaymentDetails.length; i++) {
        if (this.settlementTask.PaymentDetails[i].isEditing) {
          this.settlementTask.PaymentDetails[i].isEditing = false;
          this.acceptPaymentDetail(this.settlementTask.PaymentDetails[i]);
        }
      }
    },
    closePanel() {
      let gridType = "negotiatorTask";
      if (this.settlementTask) {
        if (this.settlementTask.Creditor.SettlementStatus.Id == 2) {
          gridType = "settlementApproval";
        } else if (this.settlementTask.Creditor.SettlementStatus.Id == 4) {
          gridType = "attorneyApproval";
        }
      }

      this.$emit("closeEditNegotiatorTaskPopUp", {
        GridType: gridType,
        TaskId: this.taskId,
      });
    },
    async checkConsecutiveDraft() {
      let rtn = true;
      let err, result;

      let payload = {
        CustomerId: this.settlementTask.Creditor.Customer.Id,
        Months: this.consecutiveDraftCheckCount,
      };
      [err, result] = await this.$store.dispatch(
        globalTypes.CHECK_CONSECUTIVE_DRAFTS,
        payload
      );

      if (result) {
        rtn = result.IsConsecutiveDrafts;
      }

      return rtn;
    },
    async requestSettlement() {
      if (
        this.requestedTime &&
        this.getTimeDifference(new Date(), this.requestedTime) < 3
      ) {
        return;
      }
      this.requestedTime = new Date();

      if (!(this.settlementTask.PaymentType.Id > 0)) {
        this.$swal("Warning", "Please select a payment type", "warning");
        return;
      }

      if (this.settlementTask.IsTermSettlement) {
        if (
          this.settlementTask.PaymentDay == null ||
          this.settlementTask.PaymentDay == ""
        ) {
          this.$swal("Warning!", "Payment day is required", "warning");

          return;
        }

        let invalidDates = this.settlementTask.PaymentDetails.filter(
          (x) =>
            new Date(x.PaymentDate) == "Invalid Date" || x.PaymentDate == ""
        );
        if (invalidDates.length > 0) {
          this.$swal(
            "Warning",
            "Please fix the invalid dates in the term details.",
            "warning"
          );
          return;
        }

        let payments = this.settlementTask.PaymentDetails.filter(
          (x) => parseFloat(x.PaymentAmount) > 0
        );
        let group = _orderby.groupBy(payments, "PaymentDate");
        let dateValues = Object.keys(group);

        if (dateValues.length != payments.length) {
          this.$swal(
            "Warning!",
            "Cannot have 2 payments in same day!",
            "warning"
          );

          return;
        }

        if (
          this.formatMoney(this.settlementTask.Offer) !=
          this.formatMoney(this.getTotalSettlementAmount())
        ) {
          this.$swal(
            "Warning!",
            "Settlement offer amount does not match term settlement payment amounts total!",
            "warning"
          );

          return;
        }

        if (
          this.formatMoney(this.settlementTask.SettlementFee) !=
          this.formatMoney(this.getTotalSettlementFeeAmount())
        ) {
          this.$swal(
            "Warning!",
            "Settlement fee amount does not match term settlement fee amounts total!",
            "warning"
          );

          return;
        }

        if (this.paymentPlannerId == 2) {
          this.settlementTask.PaymentDetails = deepCopy(
            this.settlementTaskCopy.PaymentDetails
          );
          if (this.feeListPaymentPlanner) {
            for (let item of this.feeListPaymentPlanner) {
              if (parseFloat(item.FeeAmount) > 0) {
                this.settlementTask.PaymentDetails.push({
                  Id: this.settlementTask.PaymentDetails.length + 1,
                  PaymentDate: new Date(item.FeeDate).toString("M/d/yyyy"),
                  PaymentAmount: 0,
                  FeeAmount: parseFloat(item.FeeAmount),
                  FeePercentage: parseFloat(item.FeePercentage.toFixed(2)),
                });
              }
            }
          }
          this.settlementTask.NumberOfPayments =
            this.settlementTask.PaymentDetails.length;
        }
      }

      if (
        this.settlementTask.SettlementAgreementFileName == "" &&
        !this.checkAuth(1858)
      ) {
        this.$swal(
          "Warning!",
          "Settlement Agreement File is required!",
          "warning"
        );
        return;
      }

      let continueSave = true;

      if (this.checkAuth(572950)) {
        let consecutiveDraftValid = await this.checkConsecutiveDraft();
        if (consecutiveDraftValid == false) {
          await this.$swal({
            title: "Consecutive Drafts Check",
            text:
              "Customer does not have " +
              this.consecutiveDraftCheckCount +
              " consecutive drafts. Do you want to move forward with this settlement request?",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
          }).then(async (dialog) => {
            if (dialog.value) {
              continueSave = true;
            } else {
              continueSave = false;
            }
          });
          if (!continueSave) return;
        }
      }

      let saveSuccess = false;

      if (this.settlementTask.IsTermSettlement && !this.repeatsDayActive) {
        this.settlementTask.RepeatsOfPayments = 0;
      }
      if (this.checkAuth(572945)) {
        if (this.overrideFeeCheckBox == true) {
          this.settlementTask.SettlementFeeOverride =
            this.settlementTask.SettlementFee;
        } else {
          this.settlementTask.SettlementFeeOverride = null;
          this.settlementTask.SettlementFee = this.settlementFeeBackup;
        }
      }

      if (
        this.attorneyInfo != null &&
        this.attorneyInfo.SettlementPreapprovalThreshold != null
      ) {
        if (
          parseFloat(this.originalBalancePercentage) >=
          parseFloat(this.attorneyInfo.SettlementPreapprovalThreshold)
        ) {
          this.settlementTask.AttorneyRequestType = 2;
        } else {
          this.settlementTask.AttorneyRequestType = 1;
        }
      }

      this.requestSettlementSpinner = true;

      if (this.settlementTask) {
        let err, result;

        [err, result] = await this.$store.dispatch(
          types.SAVE_NEGOTIATOR_TASK,
          this.settlementTask
        );
        this.requestSettlementSpinner = false;

        if (result) {
          saveSuccess = true;
        } else {
          saveSuccess = false;
          let errMsg = this.getApiErrorMessage(err);
          this.$swal("Error!", errMsg, "error");
        }

        if (saveSuccess) {
          this.$Emitter.emit("refreshCashFlow", {
            CustomerId: this.settlementTask.Creditor.Customer.Id,
          });

          this.requestSettlementSpinner = true;

          let err, result;

          let payload = {
            Id: this.settlementTask.Id,
            PercentBalance: parseFloat(this.originalBalancePercentage),
            AttorneyRequestType: this.settlementTask.AttorneyRequestType,
          };

          [err, result] = await this.$store.dispatch(
            types.REQUEST_SETTLEMENT,
            payload
          );
          if (result) {
            this.closePanel();
            this.$swal("Success!", "Settlement Requested.", "success");
            this.$Emitter.emit(
              "refreshCreditorGrid",
              this.settlementTask.Creditor.Customer.Id
            );
            if (this.checkAuth(3994)) {
              this.$store.dispatch(globalTypes.INSERT_USER_ACTIVITY, {
                CustomerId: this.settlementTask.Creditor.Customer.Id,
                UserActivityTypeId: 14,
                AdditionalNotes: "Submitted a settlement offer",
              });
            }
          } else {
            let errMsg = this.getApiErrorMessage(err);
            this.$swal("Error!", errMsg, "error");
          }
          this.requestSettlementSpinner = false;
        }
      }
    },
    updateNumberOfPayments(value) {
      if (this.settlementTask.Creditor.SettlementStatus.Id != 4) {
        if (value > 120) {
          this.settlementTask.NumberOfPayments = 120;
        } else if (value > 1) {
          this.settlementTask.NumberOfPayments = value;
        } else {
          this.settlementTask.NumberOfPayments = 2;
        }

        this.setPaymentDetails();
      }
    },
    updateNumberOfPaymentsPaymentPlanner(value) {
      if (this.settlementTaskCopy.Creditor.SettlementStatus.Id != 4) {
        if (value > 120) {
          this.settlementTaskCopy.NumberOfPayments = 120;
        } else if (value > 1) {
          this.settlementTaskCopy.NumberOfPayments = value;
        } else {
          this.settlementTaskCopy.NumberOfPayments = 2;
        }
        this.setPaymentDetailsPaymentPlanner();
      }
    },
    paymentRepeatsChanged(event) {
      if (this.settlementTask.Creditor.SettlementStatus.Id != 4) {
        this.repeatsDayActive = event.target.id == "repeatsDay" ? true : false;
        this.setPaymentDetails();
      }
    },
    paymentRepeatsChangedPaymentPlanner(event) {
      if (this.settlementTaskCopy.Creditor.SettlementStatus.Id != 4) {
        this.repeatsDayActiveCopy =
          event.target.id == "repeatsDay" ? true : false;
        this.setPaymentDetailsPaymentPlanner();
      }
    },
    updateRepeatsOfPayments() {
      if (this.settlementTask.Creditor.SettlementStatus.Id != 4) {
        this.setPaymentDetails();
      }
    },
    updateRepeatsOfPaymentsPaymentPlanner() {
      if (this.settlementTaskCopy.Creditor.SettlementStatus.Id != 4) {
        this.setPaymentDetailsPaymentPlanner();
      }
    },
    isPaymentPossible(paymentDate, paymentAmount, feeAmount) {
      let rtn = 0;

      if (paymentDate) {
        // this.cashFlowList = deepCopy(this.cashFlowListOldValue);

        paymentDate = new Date(paymentDate);
        paymentDate.setHours(23, 59, 59);

        let matchlist = this.cashFlowList.filter(
          (x) =>
            !x.IsOngoingSettlements && new Date(x.Date) <= new Date(paymentDate)
        );

        if (matchlist && matchlist.length > 0) {
          let latestNet = matchlist[matchlist.length - 1];

          if (latestNet) {
            let pAmount = parseFloat(paymentAmount);
            let fAmount = parseFloat(feeAmount);
            let totalValue = parseFloat(pAmount + fAmount + 15);
            rtn = parseFloat(
              parseFloat(latestNet.Balance) - totalValue
            ).toFixed(2);
          }
        }
      }

      return rtn > 0 ? feeAmount : rtn;
    },

    getMaxFeeAmount(paymentDate, paymentAmount, totalFeeAmount) {
      let maxAvailableFee = 0;
      let i = this.maxFeeRatio;

      while (i >= 4) {
        let ratio = parseFloat(i / 100);

        maxAvailableFee = this.isPaymentPossible(
          paymentDate,
          paymentAmount,
          parseFloat(
            parseFloat(this.settlementTask.SettlementFee - totalFeeAmount) *
              ratio
          )
        );
        if (maxAvailableFee >= 0) break;
        i = i - 4;
      }

      return maxAvailableFee > 0 ? parseFloat(maxAvailableFee).toFixed(2) : 0;
    },
    setPaymentDetails(pmtDate = null, forceCalc = false) {
      if (!this.settlementTask.IsTermSettlement) {
        this.calculateNetCash();
        return;
      }

      if (
        JSON.stringify(this.settlementTask) == this.oldValue &&
        forceCalc == false
      ) {
        this.calculateNetCash();
        return;
      }

      let totalPaymentAmount = 0;
      let totalFeeAmount = 0;

      let repeatsOfPayment =
        this.settlementTask.RepeatsOfPayments > 0
          ? this.settlementTask.RepeatsOfPayments
          : 0;
      let firstPaymentDate =
        this.settlementTask.PaymentDetails.length > 0
          ? new Date(this.settlementTask.PaymentDetails[0].PaymentDate)
          : new Date(pmtDate);

      if (pmtDate != null) {
        firstPaymentDate = new Date(pmtDate);
      } else {
        firstPaymentDate = new Date(this.settlementTask.PaymentDay);
      }

      let settlementOffer = this.settlementTask.Offer;
      let settlementFee = parseFloat(this.settlementTask.SettlementFee);

      if (this.autoCalculateOpen == true) {
        let paymentAmount =
          this.settlementTask.NumberOfPayments > 0
            ? Math.ceil(
                parseFloat(
                  settlementOffer / this.settlementTask.NumberOfPayments
                )
              )
            : settlementOffer;

        let numberOfPayments = this.settlementTask.NumberOfPayments;

        let equalPaymentAmount = Math.floor(
          parseFloat(settlementOffer / numberOfPayments)
        );
        let firstPaymentAmount = parseFloat(
          settlementOffer - equalPaymentAmount * (numberOfPayments - 1)
        );

        if (this.checkAuth(10194)) {
          equalPaymentAmount = parseFloat(
            parseFloat(settlementOffer / numberOfPayments).toFixed(2)
          );
          let remainedAmount = parseFloat(
            parseFloat(
              settlementOffer - equalPaymentAmount * (numberOfPayments - 1)
            ).toFixed(2)
          );

          firstPaymentAmount =
            remainedAmount > equalPaymentAmount
              ? remainedAmount
              : equalPaymentAmount;
        }

        let feeAmount =
          this.settlementTask.NumberOfPayments > 0
            ? Math.ceil(
                parseFloat(
                  settlementFee / this.settlementTask.NumberOfPayments
                ) * 100
              ) / 100
            : settlementFee;

        let paymentTypeId = this.settlementTask.PaymentType
          ? this.settlementTask.PaymentType.Id
          : null;

        this.settlementTask.PaymentDetails = [];

        for (
          let index = 0;
          index < this.settlementTask.NumberOfPayments;
          index++
        ) {
          let paymentDate =
            index == 0
              ? firstPaymentDate
              : this.repeatsDayActive
              ? new Date(
                  firstPaymentDate.getFullYear(),
                  firstPaymentDate.getMonth(),
                  firstPaymentDate.getDate()
                ).addDays(repeatsOfPayment * index)
              : new Date(
                  firstPaymentDate.getFullYear(),
                  firstPaymentDate.getMonth(),
                  firstPaymentDate.getDate()
                ).addMonths(index);

          if (index == 0) {
            feeAmount =
              this.checkAuth(89414) && this.cashFlowList.length > 0
                ? this.getMaxFeeAmount(
                    paymentDate,
                    paymentAmount,
                    totalFeeAmount
                  )
                : feeAmount;

            this.settlementTask.PaymentDetails.push({
              Id: index + 1,
              PaymentDate: new Date(paymentDate).toString("M/d/yyyy"),
              PaymentAmount: parseFloat(firstPaymentAmount.toFixed(2)),
              PaymentTypeId: paymentTypeId,
              FeeAmount: parseFloat(parseFloat(feeAmount).toFixed(2)),
              FeePercentage: 0,
            });
            totalPaymentAmount = parseFloat(
              parseFloat(
                parseFloat(totalPaymentAmount) + parseFloat(firstPaymentAmount)
              ).toFixed(2)
            );
            totalFeeAmount = parseFloat(
              parseFloat(
                parseFloat(totalFeeAmount) + parseFloat(feeAmount)
              ).toFixed(2)
            );

            this.calculateNetCash();
          } else if (index < this.settlementTask.NumberOfPayments - 1) {
            let remainingFee = parseFloat(settlementFee - totalFeeAmount);

            if (parseFloat(feeAmount) <= remainingFee) {
              feeAmount = parseFloat(parseFloat(feeAmount).toFixed(2));
            } else {
              feeAmount = parseFloat(remainingFee.toFixed(2));
            }

            this.settlementTask.PaymentDetails.push({
              Id: index + 1,
              PaymentDate: new Date(paymentDate).toString("M/d/yyyy"),
              PaymentAmount: parseFloat(equalPaymentAmount.toFixed(2)),
              PaymentTypeId: paymentTypeId,
              FeeAmount: feeAmount,
              FeePercentage: 0,
            });
            totalPaymentAmount = parseFloat(
              parseFloat(
                parseFloat(totalPaymentAmount) + parseFloat(equalPaymentAmount)
              ).toFixed(2)
            );

            totalFeeAmount = parseFloat(
              parseFloat(
                parseFloat(totalFeeAmount) + parseFloat(feeAmount)
              ).toFixed(2)
            );

            this.calculateNetCash();
          } else {
            this.settlementTask.PaymentDetails.push({
              Id: index + 1,
              PaymentDate: new Date(paymentDate).toString("M/d/yyyy"),
              PaymentAmount: parseFloat(
                parseFloat(settlementOffer - totalPaymentAmount).toFixed(2)
              ),
              PaymentTypeId: paymentTypeId,
              FeeAmount: parseFloat(
                parseFloat(settlementFee - totalFeeAmount).toFixed(2)
              ),
              FeePercentage: 0,
            });

            this.calculateNetCash();
          }
        }
        this.setFeePercentage();
        this.calculateNetCash();
      } else {
        if (
          this.settlementTask.NumberOfPayments >
          this.settlementTask.PaymentDetails.length
        ) {
          let addedCount = parseInt(
            this.settlementTask.NumberOfPayments -
              this.settlementTask.PaymentDetails.length
          );

          for (let i = 0; i < addedCount; i++) {
            this.pushTermSettlementRow();
          }
        } else {
          if (
            this.settlementTask.NumberOfPayments <
            this.settlementTask.PaymentDetails.length
          ) {
            let removeCount = 0;
            let diff = parseInt(
              this.settlementTask.PaymentDetails.length -
                this.settlementTask.NumberOfPayments
            );
            for (let i = 0; i < diff; i++) {
              removeCount++;
            }

            for (let j = 0; j < removeCount; j++) {
              this.settlementTask.PaymentDetails.pop();
            }
          }
        }
        this.calculateNetCash();
      }
    },
    pushTermSettlementRow() {
      let descendingList = _orderby.orderBy(
        this.settlementTask.PaymentDetails,
        "Id",
        "desc"
      );
      if (descendingList.length > 0) {
        let latestId = descendingList[0].Id;
        let latestDate = new Date(descendingList[0].PaymentDate);
        let repeatsOfPayment =
          this.settlementTask.RepeatsOfPayments > 0
            ? this.settlementTask.RepeatsOfPayments
            : 0;
        let paymentDate = this.repeatsDayActive
          ? new Date(
              latestDate.getFullYear(),
              latestDate.getMonth(),
              latestDate.getDate()
            ).addDays(repeatsOfPayment)
          : new Date(
              latestDate.getFullYear(),
              latestDate.getMonth(),
              latestDate.getDate()
            ).addMonths(1);
        this.settlementTask.PaymentDetails.push({
          Id: latestId + 1,
          PaymentDate: new Date(paymentDate).toString("M/d/yyyy"),
          PaymentAmount: 0,
          FeeAmount: 0,
          FeePercentage: 0,
          PaymentTypeId: 0,
        });
      }
    },
    pushTermSettlementRowPaymentPlanner() {
      let descendingList = _orderby.orderBy(
        this.settlementTaskCopy.PaymentDetails,
        "Id",
        "desc"
      );
      if (descendingList.length > 0) {
        let latestId = descendingList[0].Id;
        let latestDate = new Date(descendingList[0].PaymentDate);
        let repeatsOfPayment =
          this.settlementTaskCopy.RepeatsOfPayments > 0
            ? this.settlementTaskCopy.RepeatsOfPayments
            : 0;
        let paymentDate = this.repeatsDayActive
          ? new Date(
              latestDate.getFullYear(),
              latestDate.getMonth(),
              latestDate.getDate()
            ).addDays(repeatsOfPayment)
          : new Date(
              latestDate.getFullYear(),
              latestDate.getMonth(),
              latestDate.getDate()
            ).addMonths(1);
        this.settlementTaskCopy.PaymentDetails.push({
          Id: latestId + 1,
          PaymentDate: new Date(paymentDate).toString("M/d/yyyy"),
          PaymentAmount: 0,
          FeeAmount: 0,
          FeePercentage: 0,
        });
      }
    },
    setFeePercentage() {
      let totalFeePercentage = 0.0;
      for (let item of this.settlementTask.PaymentDetails) {
        let index = parseInt(item.Id - 1);

        let percentage = this.calculatePercentage(
          item.FeeAmount,
          this.settlementTask.SettlementFee
        ).toFixed(2);

        if (index < this.settlementTask.NumberOfPayments - 1) {
          totalFeePercentage =
            parseFloat(totalFeePercentage) + parseFloat(percentage);
          item.FeePercentage = percentage;
        } else {
          item.FeePercentage = parseFloat(100 - totalFeePercentage);
        }
      }
    },
    async bindData(result) {
      this.settlementTask.Id = result.Data.Id;
      this.settlementTask.Payee = result.Data.Payee;
      this.settlementTask.PayeePhone = result.Data.PayeePhone;
      this.settlementTask.Offer = result.Data.Offer;
      this.settlementTask.IsAdditionalFund = result.Data.IsAdditionalFund;
      this.settlementTask.AdditionalFundAmount =
        result.Data.AdditionalFundAmount;
      this.settlementTask.EGAttorney = result.Data.EGAttorney;
      this.settlementTask.AttorneyName = result.Data.AttorneyName;
      this.settlementTask.IsTermSettlement = result.Data.IsTermSettlement;
      this.settlementFeeBackup = result.Data.SettlementFee;

      let settlementFeeOverride = result.Data.SettlementFeeOverride;
      this.settlementTask.SettlementFeeOverride = settlementFeeOverride;
      if (settlementFeeOverride != null) {
        this.settlementTask.SettlementFee = settlementFeeOverride;
        this.overrideFeeCheckBox = true;
      } else {
        this.settlementTask.SettlementFee = result.Data.SettlementFee;
        this.overrideFeeCheckBox = false;
      }

      this.settlementTask.NegotiatorName = result.Data.NegotiatorName;
      this.settlementTask.SettlementDate =
        result.Data.SettlementDate != null
          ? this.formatShortDate(result.Data.SettlementDate)
          : null;
      this.settlementTask.NegotiationPhase = result.Data.NegotiationPhase;
      this.settlementTask.Creditor.Id = result.Data.Creditor.Id;
      this.settlementTask.Creditor.Name = result.Data.Creditor.Name;
      this.settlementTask.Creditor.AccountNumber =
        result.Data.Creditor.AccountNumber;

      this.settlementTask.Creditor.AccountHolder =
        result.Data.Creditor.AccountHolder;

      this.settlementTask.Creditor.SettlementRequestedUserId =
        result.Data.Creditor.SettlementRequestedUserId;
      this.settlementTask.Creditor.CurrentCreditorName =
        result.Data.Creditor.CurrentCreditorName;
      this.settlementTask.Creditor.CurrentCreditorAccountNumber =
        result.Data.Creditor.CurrentCreditorAccountNumber;
      this.settlementTask.Creditor.MonthlyPayment =
        result.Data.Creditor.MonthlyPayment;
      this.settlementTask.Creditor.OriginalBalance =
        result.Data.Creditor.OriginalBalance;
      this.settlementTask.Creditor.CurrentBalance =
        result.Data.Creditor.CurrentBalance;
      this.settlementTask.Creditor.CurrentCreditorBalance =
        result.Data.Creditor.CurrentCreditorBalance;
      this.settlementTask.Creditor.AuditorId = result.Data.Creditor.AuditorId;
      this.settlementTask.Creditor.AuditorName =
        result.Data.Creditor.AuditorName;

      this.settlementTask.Creditor.Customer.Id =
        result.Data.Creditor.Customer.Id;
      this.settlementTask.Creditor.Customer.Name =
        result.Data.Creditor.Customer.Name;
      this.settlementTask.Creditor.Customer.Address =
        result.Data.Creditor.Customer.Address;
      this.settlementTask.Creditor.Customer.Email =
        result.Data.Creditor.Customer.Email;

      this.settlementTask.Creditor.Customer.PhoneNumber =
        result.Data.Creditor.Customer.PhoneNumber;

      this.settlementTask.Creditor.Customer.OfficeId =
        result.Data.Creditor.Customer.OfficeId;
      this.settlementTask.Creditor.Customer.StateId =
        result.Data.Creditor.Customer.StateId;

      this.settlementTask.Creditor.Customer.CustomerCreatedDate =
        result.Data.Creditor.Customer.CustomerCreatedDate;

      this.settlementTask.Creditor.Customer.Ssn =
        result.Data.Creditor.Customer.Ssn;

      this.settlementTask.Creditor.Customer.Office =
        result.Data.Creditor.Customer.Office;

      this.settlementTask.Creditor.Customer.BirthDay =
        result.Data.Creditor.Customer.BirthDay;

      this.settlementTask.Creditor.Customer.Hardship =
        result.Data.Creditor.Customer.Hardship;

      this.settlementTask.Creditor.Customer.HardshipStatement =
        result.Data.Creditor.Customer.HardshipStatement;

      this.settlementTask.Creditor.Customer.CustomerProfile =
        result.Data.Creditor.Customer.CustomerProfile;

      this.settlementTask.Creditor.Customer.CoCustomerProfiles =
        result.Data.Creditor.Customer.CoCustomerProfiles;

      if (
        this.settlementTask.Creditor.Customer.CoCustomerProfiles &&
        this.settlementTask.Creditor.Customer.CoCustomerProfiles.length > 0
      ) {
        this.activeCoCustomers =
          this.settlementTask.Creditor.Customer.CoCustomerProfiles.filter(
            (x) => x.IsExcluded == false
          );
      }

      this.settlementTask.NextDraftAmount = result.Data.NextDraftAmount;

      this.settlementTask.NextDraftDate = result.Data.NextDraftDate;

      this.settlementTask.RemainingDraftCount = result.Data.RemainingDraftCount;

      this.settlementTask.Delinquent = result.Data.Delinquent;


      this.settlementTask.Creditor.Customer.Expenses =
        result.Data.Creditor.Customer.Expenses;

      this.settlementTask.Creditor.Customer.EnrolledDebt =
        result.Data.Creditor.Customer.EnrolledDebt;
      this.settlementTask.Creditor.Customer.EnrolledDate =
        result.Data.Creditor.Customer.EnrolledDate;
      this.settlementTask.Creditor.Customer.NumberOfAccounts =
        result.Data.Creditor.Customer.NumberOfAccounts;

      this.settlementTask.Creditor.Customer.NumberOfUnsettledAccount =
        result.Data.Creditor.Customer.NumberOfUnsettledAccount;

      this.settlementTask.Creditor.SettlementRequestDate =
        result.Data.Creditor.SettlementRequestDate != null
          ? this.formatShortDate(result.Data.Creditor.SettlementRequestDate)
          : null;
      this.settlementTask.Creditor.SettlementStatus.Id =
        result.Data.Creditor.SettlementStatus.Id;
      this.settlementTask.Creditor.SettlementStatus.Name =
        result.Data.Creditor.SettlementStatus.Name;
      this.settlementTask.Creditor.IsPOA = result.Data.Creditor.IsPOA;
      this.settlementTask.Creditor.IsLawSuit = result.Data.Creditor.IsLawSuit;

      this.settlementTask.NegPhaseDueDate =
        result.Data.NegPhaseDueDate != null
          ? this.formatShortDate(result.Data.NegPhaseDueDate)
          : null;
      this.settlementTask.PercentBalance = result.Data.PercentBalance;
      this.settlementTask.SettlementAgreementFileName =
        result.Data.SettlementAgreementFileName;

      this.hasSettlementAgreementFile =
        result.Data.SettlementAgreementFileName != "";
      //this.settlementTask.NumberOfPayments = result.Data.NumberOfPayments;

      this.settlementTask.RepeatsOfPayments = result.Data.RepeatsOfPayments;
      this.repeatsDayActive = result.Data.RepeatsOfPayments > 0;

      if (
        this.settlementTask.RepeatsOfPayments == null ||
        this.settlementTask.RepeatsOfPayments == 0
      ) {
        this.repeatsDayActive = false;
        this.settlementTask.RepeatsOfPayments = 30;
      }
      if (
        this.settlementTask.NumberOfPayments == null ||
        this.settlementTask.NumberOfPayments == 0
      ) {
        this.settlementTask.NumberOfPayments = 2;
      }

      this.settlementTask.PaymentDetails = result.Data.PaymentDetails;
      if (
        this.settlementTask.PaymentDetails &&
        this.settlementTask.PaymentDetails.length > 0
      ) {
        this.autoCalculateOpen = false;
        this.paymentDetailsOld = deepCopy(this.settlementTask.PaymentDetails);
      }
      if (
        this.settlementTask &&
        this.settlementTask.PaymentDetails &&
        this.settlementTask.PaymentDetails.length > 0
      ) {
        if (this.paymentPlannerId == 1) {
          this.settlementTask.NumberOfPayments =
            this.settlementTask.PaymentDetails.length;
        } else if (this.paymentPlannerId == 2) {
          this.settlementTask.NumberOfPayments =
            this.settlementTask.PaymentDetails.filter(
              (x) => parseFloat(x.PaymentAmount) > 0
            ).length;
        }
      }

      this.settlementTask.PaymentDay =
        result.Data.PaymentDay != null
          ? this.formatShortDate(result.Data.PaymentDay)
          : null;
      this.settlementTask.PaymentType.Id = result.Data.PaymentType.Id;
      this.settlementTask.PaymentType.Name = result.Data.PaymentType.Name;
      this.settlementTask.SpokeTo = result.Data.SpokeTo;
      this.settlementTask.IsClientNotified = result.Data.IsClientNotified;
      this.settlementTask.Note = result.Data.Note;
      this.settlementTask.GlobalMemo = result.Data.GlobalMemo;
      this.settlementTask.GlobalMemo1 = result.Data.GlobalMemo1;
      this.settlementTask.PolicyGroupId = result.Data.PolicyGroupId;
      this.settlementTask.GlobalBalanceOffline =
        result.Data.GlobalBalanceOffline;
      this.settlementTask.IsDpg = result.Data.IsDpg;
      this.settlementTask.PaymentProcessorId = result.Data.PaymentProcessorId;
      if (
        this.settlementTask.IsDpg == true ||
        this.settlementTask.PaymentProcessorId == 3
      ) {
        await this.getPaymentTypesForDpg();
      } else {
        await this.getPaymentTypesByPolicyGroup(result.Data.PolicyGroupId);
      }
      this.updateOriginalBalancePercentage();

      for (let item of this.settlementTask.PaymentDetails) {
        item.PaymentTypeId =
          item.PaymentTypeId == null ? 0 : item.PaymentTypeId;
      }

      this.settlementTaskCopy = deepCopy(this.settlementTask);
      for (let item of this.settlementTaskCopy.PaymentDetails) {
        item.FeeAmount = 0;
        item.FeePercentage = 0;
        item.PaymentTypeId =
          item.PaymentTypeId == null
            ? this.settlementTaskCopy.PaymentType.Id
            : item.PaymentTypeId;
      }

      if (this.settlementTaskCopy) {
        if (
          this.settlementTaskCopy.IsTermSettlement &&
          this.settlementTaskCopy.PaymentDetails &&
          this.settlementTaskCopy.PaymentDetails.length > 0
        ) {
          let onlyPayments = this.settlementTaskCopy.PaymentDetails.filter(
            (x) => parseFloat(x.PaymentAmount) > 0
          );
          this.settlementTaskCopy.PaymentDetails = onlyPayments;
          this.settlementTaskCopy.NumberOfPayments =
            onlyPayments.length > 2 ? onlyPayments.length : 2;
          this.feeListPaymentPlanner = [];
          let fees = this.settlementTask.PaymentDetails.filter(
            (x) => parseFloat(x.FeeAmount) > 0
          );
          for (let item of fees) {
            if (parseFloat(item.FeeAmount) > 0) {
              this.feeListPaymentPlanner.push({
                Id: item.Id,
                FeeDate: new Date(item.PaymentDate),
                FeeAmount: parseFloat(item.FeeAmount),
                FeePercentage: 0,
              });
            }
          }
          this.calculateFeePercentage();
        }
      }

      this.oldValue = JSON.stringify(this.settlementTask);
      this.calculateAmountWaived();
    },
    async getPaymentTypesByPolicyGroup(policyGroupId) {
      if (policyGroupId > 0) {
        let err, result;

        [err, result] = await this.$store.dispatch(
          types.GET_PAYMENT_TYPES_BY_POLICY_GROUP,
          { PolicyGroupId: policyGroupId }
        );
        if (result && result.Data) {
          this.paymentTypes = result.Data;
        }
      }
    },
    async getPaymentTypesForDpg() {
      let err, result;

      [err, result] = await this.$store.dispatch(types.GET_PAYMENT_TYPES);
      if (result && result.Data) {
        this.paymentTypes = result.Data;
      }
    },
    async checkHasAttorney() {
      let err, result;
      [err, result] = await this.$store.dispatch(types.GET_ATTONERY_INFO, {
        StateId: this.settlementTask.Creditor.Customer.StateId,
        PolicyGroupId: this.settlementTask.PolicyGroupId,
        CreatedDate: this.settlementTask.Creditor.Customer.CustomerCreatedDate,
      });
      if (result) {
        if (result.Data) {
          this.attorneyInfo = deepCopy(result.Data);
        }
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal(
          "Attorney Api Error",
          "Getting attorney info: " + errMsg,
          "error"
        );
      }
    },
    async saveTask() {
      if (this.settlementTask.IsTermSettlement == true) {
        let invalidDates = this.settlementTask.PaymentDetails.filter(
          (x) =>
            new Date(x.PaymentDate) == "Invalid Date" || x.PaymentDate == ""
        );
        if (invalidDates.length > 0) {
          this.$swal(
            "Warning",
            "There is invalid date in payment details.",
            "warning"
          );
          return;
        }
      }

      let invalidPaymentsTypes =
        this.paymentPlannerId == 2
          ? this.settlementTaskCopy.PaymentDetails.filter(
              (x) => !x.PaymentTypeId > 0
            )
          : this.settlementTask.PaymentDetails.filter(
              (x) => x.PaymentAmount > 0 && !(x.PaymentTypeId > 0)
            );

      if (invalidPaymentsTypes.length > 0) {
        this.$swal(
          "Warning",
          "Please select a payment type for all payments.",
          "warning"
        );
        return;
      }

      this.saveAllSpinner = true;
      if (!this.repeatsDayActive) {
        this.settlementTask.RepeatsOfPayments = 0;
      }

      if (this.checkAuth(572945)) {
        if (this.overrideFeeCheckBox == true) {
          this.settlementTask.SettlementFeeOverride =
            this.settlementTask.SettlementFee;
        } else {
          this.settlementTask.SettlementFeeOverride = null;
          this.settlementTask.SettlementFee =
            this.remainingFeeTotal > 0
              ? this.remainingFeeTotal
              : this.settlementFeeBackup;
        }
      }

      if(this.settlementTask.SettlementFeeOverride == "")
      {
        this.settlementTask.SettlementFeeOverride = 0;
      }
      let offer = parseFloat(this.settlementTask.Offer);
      if (!isNaN(offer) && offer >= 0) {
        this.settlementTask.Offer = offer;
      } else {
        this.settlementTask.Offer = 0;
      }

      if (this.settlementTask) {
        if (this.paymentPlannerId == 2) {
          this.settlementTask.PaymentDetails = deepCopy(
            this.settlementTaskCopy.PaymentDetails
          );
          if (this.feeListPaymentPlanner) {
            for (let item of this.feeListPaymentPlanner) {
              if (parseFloat(item.FeeAmount) > 0) {
                this.settlementTask.PaymentDetails.push({
                  Id: this.settlementTask.PaymentDetails.length + 1,
                  PaymentDate: new Date(item.FeeDate).toString("M/d/yyyy"),
                  PaymentAmount: 0,
                  FeeAmount: parseFloat(item.FeeAmount),
                  FeePercentage: parseFloat(item.FeePercentage.toFixed(2)),
                });
              }
            }
          }
          this.settlementTask.NumberOfPayments =
            this.settlementTask.PaymentDetails.length;
        }

        for (let p of this.settlementTask.PaymentDetails) {
          p.PaymentTypeId =
            p.PaymentTypeId == null
              ? parseInt(this.settlementTask.PaymentType.Id)
              : parseInt(p.PaymentTypeId);
        }

        if (
          this.settlementTask.PaymentDay &&
          this.settlementTask.PaymentDay != "" &&
          this.settlementTask.PaymentDay != "Invalid Date"
        ) {
          this.settlementTask.PaymentDay = this.formatDateWithoutLocalization(
            this.settlementTask.PaymentDay
          );
        }

        let err, result;

        [err, result] = await this.$store.dispatch(
          types.SAVE_NEGOTIATOR_TASK,
          this.settlementTask
        );

        if (result) {
          await this.bindData(result);
          if (this.remainingFeeTotal > 0) {
            this.settlementTask.SettlementFee = this.remainingFeeTotal;
          }

          this.$Emitter.emit("refreshCashFlow", {
            CustomerId: this.settlementTask.Creditor.Customer.Id,
          });

          this.$Emitter.emit(
            "refreshCreditorGrid",
            this.settlementTask.Creditor.Customer.Id
          );

          this.$swal("Success!", "Saved.", "success");
        } else {
          let errMsg = this.getApiErrorMessage(err);
          this.$swal("Error!", errMsg, "error");
        }
      }
      this.saveAllSpinner = false;
    },
    async approveSettlement() {
      if (!(this.settlementTask.PaymentType.Id > 0)) {
        this.$swal("Warning", "Please select a payment type", "warning");
        return;
      }

      if (this.settlementTask.IsTermSettlement == true) {
        let invalidDates = this.settlementTask.PaymentDetails.filter(
          (x) =>
            new Date(x.PaymentDate) == "Invalid Date" || x.PaymentDate == ""
        );
        if (invalidDates.length > 0) {
          this.$swal(
            "Warning",
            "There is invalid date in payment details.",
            "warning"
          );
          return;
        }
      }

      if (
        this.settlementTask.PaymentDay == null ||
        this.settlementTask.PaymentDay == ""
      ) {
        this.$swal("Warning!", "Payment day is required", "warning");
        return;
      }

      let currentDate = new Date();
      let paymentDate = new Date(this.settlementTask.PaymentDay);
      paymentDate.setHours(23, 59, 59, 999);

      if (paymentDate < currentDate) {
        this.$swal(
          "Error!",
          "Payment day can not be less than today.",
          "error"
        );
        return;
      }

      if (
        this.settlementTask.IsTermSettlement &&
        this.settlementTask.PaymentDetails.length > 0
      ) {
        let payments = _orderby.orderBy(
          this.settlementTask.PaymentDetails,
          "PaymentDate",
          "asc"
        );

        let oldestDate = new Date(payments[0].PaymentDate);
        oldestDate.setHours(23, 59, 59, 999);

        if (oldestDate < currentDate) {
          this.$swal(
            "Error!",
            "First payment day can not be less than today.",
            "error"
          );
          return;
        }
      }

      if (this.settlementTask.IsTermSettlement) {
        let payments = this.settlementTask.PaymentDetails.filter(
          (x) => parseFloat(x.PaymentAmount) > 0
        );
        let group = _orderby.groupBy(payments, "PaymentDate");
        let dateValues = Object.keys(group);

        if (dateValues.length != payments.length) {
          this.$swal(
            "Warning!",
            "Cannot have 2 payments in same day!",
            "warning"
          );
          return;
        }

        if (
          this.formatMoney(this.settlementTask.Offer) !=
          this.formatMoney(this.getTotalSettlementAmount())
        ) {
          this.$swal(
            "Warning!",
            "Settlement offer amount does not match term settlement payment amounts total!",
            "warning"
          );

          return;
        }

        if (
          this.formatMoney(this.settlementTask.SettlementFee) !=
          this.formatMoney(this.getTotalSettlementFeeAmount())
        ) {
          this.$swal(
            "Warning!",
            "Settlement fee amount does not match term settlement fee amounts total!",
            "warning"
          );

          return;
        }

        if (this.paymentPlannerId == 2) {
          this.settlementTask.PaymentDetails = deepCopy(
            this.settlementTaskCopy.PaymentDetails
          );
          if (this.feeListPaymentPlanner) {
            for (let item of this.feeListPaymentPlanner) {
              if (parseFloat(item.FeeAmount) > 0) {
                this.settlementTask.PaymentDetails.push({
                  Id: this.settlementTask.PaymentDetails.length + 1,
                  PaymentDate: new Date(item.FeeDate).toString("M/d/yyyy"),
                  PaymentAmount: 0,
                  FeeAmount: parseFloat(item.FeeAmount),
                  FeePercentage: parseFloat(item.FeePercentage.toFixed(2)),
                });
              }
            }
          }
          this.settlementTask.NumberOfPayments =
            this.settlementTask.PaymentDetails.length;
        }
      }

      let err, result;
      let saveSuccess = false;
      if (this.settlementTask) {
        this.aproveSpinner = true;
        [err, result] = await this.$store.dispatch(
          types.SAVE_NEGOTIATOR_TASK,
          this.settlementTask
        );
        this.aproveSpinner = false;
        if (result) {
          saveSuccess = true;
        } else {
          saveSuccess = false;
          let errMsg = this.getApiErrorMessage(err);
          this.$swal("Error!", errMsg, "error");
        }
      }

      if (saveSuccess) {
        this.$Emitter.emit("refreshCashFlow", {
          CustomerId: this.settlementTask.Creditor.Customer.Id,
        });

        let type = types.ATTORNEY_TASK_APPROVE;
        let successMessage = "Settlement Approved.";
        if (this.settlementTask.Creditor.SettlementStatus.Id == 4) {
          type = types.REQUEST_SETTLEMENT;
          successMessage = "Approved.";
        }

        this.aproveSpinner = true;
        let err, result;

        [err, result] = await this.$store.dispatch(type, {
          Id: this.settlementTask.Id,
        });
        this.aproveSpinner = false;

        if (result) {
          this.$swal("Success!", successMessage, "success");
          this.closePanel();
          this.$Emitter.emit(
            "refreshCreditorGrid",
            this.settlementTask.Creditor.Customer.Id
          );
        } else {
          let errMsg = this.getApiErrorMessage(err);
          this.$swal("Error!", errMsg, "error");
        }
      }
    },
    async declineSettlement() {
      this.isDeclineReviewPopupOpen = true;
    },
    sendCsrCorrespondence() {
      let indx = this.csrCorrespondenceList.findIndex(
        (x) => x.Id == this.selectedCorrespondenceTaskId
      );
      if (indx > -1) {
        this.CorrespondenceTaskTypeName = this.csrCorrespondenceList[indx].Name;
        this.CorrespondenceTaskTypeId =
          this.csrCorrespondenceList[indx].TaskTypeId;
      }
      this.isCorrespondenceRaisonPanelPopupOpen = true;
      // if (this.correspondenceId != null) {
      // }
    },
    closeCorrespondenceRaisonPanelPopUp() {
      this.isCorrespondenceRaisonPanelPopupOpen = false;
    },

    openPaymentSetup() {
      if (
        this.settlementTask.PaymentDay == null ||
        this.settlementTask.PaymentDay == ""
      ) {
        this.$swal("Warning!", "Payment day is required", "warning");
        return;
      }

      let currentDate = new Date();
      let paymentDate = new Date(this.settlementTask.PaymentDay);

      paymentDate.setHours(23, 59, 59, 999);

      if (paymentDate < currentDate) {
        this.$swal(
          "Error!",
          "Payment day can not be less than today.",
          "error"
        );
        return;
      }

      if (
        this.settlementTask.IsTermSettlement &&
        this.settlementTask.PaymentDetails.length > 0
      ) {
        let payments = _orderby.orderBy(
          this.settlementTask.PaymentDetails,
          "PaymentDate",
          "asc"
        );

        let oldestDate = new Date(payments[0].PaymentDate);
        oldestDate.setHours(23, 59, 59, 999);

        if (oldestDate < currentDate) {
          this.$swal(
            "Error!",
            "First payment day can not be less than today.",
            "error"
          );
          return;
        }
        if (this.hasZeroPayment) {
          this.$swal(
            "Warning!",
            "The payment amount and the fee amount cannot be $0 at the same time.",
            "warning"
          );
          return;
        }
      }

      if (!this.settlementTask.IsTermSettlement) {
        if (
          this.settlementTask.Offer == 0 &&
          this.settlementTask.SettlementFee == 0
        ) {
          this.$swal(
            "Warning!",
            "Settlement offer and settlement fee can't be 0.",
            "warning"
          );
          return;
        }
      }

      if (this.settlementTask.IsTermSettlement) {
        let payments = this.settlementTask.PaymentDetails.filter(
          (x) => parseFloat(x.PaymentAmount) > 0
        );
        let group = _orderby.groupBy(payments, "PaymentDate");
        let dateValues = Object.keys(group);

        if (dateValues.length != payments.length) {
          this.$swal(
            "Warning!",
            "Cannot have 2 payments in same day!",
            "warning"
          );
          return;
        }

        if (
          this.formatMoney(this.settlementTask.Offer) !=
          this.formatMoney(this.getTotalSettlementAmount())
        ) {
          this.$swal(
            "Warning!",
            "Settlement offer amount does not match term settlement payment amounts total!",
            "warning"
          );

          return;
        }

        if (
          this.formatMoney(this.settlementTask.SettlementFee) !=
          this.formatMoney(this.getTotalSettlementFeeAmount())
        ) {
          this.$swal(
            "Warning!",
            "Settlement fee amount does not match term settlement fee amounts total!",
            "warning"
          );

          return;
        }

        if (this.paymentPlannerId == 2) {
          this.settlementTask.PaymentDetails = deepCopy(
            this.settlementTaskCopy.PaymentDetails
          );
          if (this.feeListPaymentPlanner) {
            for (let item of this.feeListPaymentPlanner) {
              if (parseFloat(item.FeeAmount) > 0) {
                this.settlementTask.PaymentDetails.push({
                  Id: this.settlementTask.PaymentDetails.length + 1,
                  PaymentDate: new Date(item.FeeDate).toString("M/d/yyyy"),
                  PaymentAmount: 0,
                  FeeAmount: parseFloat(item.FeeAmount),
                  FeePercentage: parseFloat(item.FeePercentage.toFixed(2)),
                });
              }
            }
          }
          this.settlementTask.NumberOfPayments =
            this.settlementTask.PaymentDetails.length;
        }
      }

      if (this.settlementTask.PaymentType.Id > 0) {
        let indx = this.paymentTypes.findIndex(
          (x) => x.Id == this.settlementTask.PaymentType.Id
        );
        if (indx > -1) {
          this.settlementTask.PaymentType.Name = this.paymentTypes[indx].Name;
        }
        this.isPaymentSetupPopUp = true;
      } else {
        this.$swal("Warning", "Please select a payment type", "warning");
      }
    },
    async closePaymentSetupPopUp(data) {
      this.isPaymentSetupPopUp = false;
      if (typeof data != "undefined" && data && data.Approved) {
        await this.$store.dispatch(
          types.SAVE_NEGOTIATOR_TASK,
          this.settlementTask
        );
        this.closePanel();
        this.$Emitter.emit(
          "refreshCreditorGrid",
          this.settlementTask.Creditor.Customer.Id
        );
      }
    },
    openQuickEmails() {
      if (
        this.settlementTask &&
        this.settlementTask.Creditor.Customer.Email &&
        this.settlementTask.Creditor.Customer.Email.length > 0
      ) {
        this.isQuickEmailPopupOpen = true;
      } else {
        this.$swal("Warning", "This customer has no email address", "warning");
      }
    },
    closeQuickEmailPopup() {
      this.isQuickEmailPopupOpen = false;
    },

    quickEmailSelected(item) {
      this.isQuickEmailPopupOpen = false;

      let toEmail = this.settlementTask.Creditor.Customer.Email;
      if (item.TypeId == 2) {
        let activeCoCustomers =
          this.settlementTask.Creditor.Customer.CoCustomerProfiles;
        if (activeCoCustomers.length > 0) {
          let emails = [];
          for (let item of activeCoCustomers) {
            if (item.Email && item.Email.length > 0) {
              if (toEmail != item.Email) {
                if (emails.filter((x) => x == item.Email).length == 0) {
                  emails.push(item.Email);
                }
              }
            }
          }

          if (emails.length > 0) {
            toEmail = toEmail + ";" + emails.join(";");
          }
        }
      } else if (item.TypeId == 3 && item.ToAddress != "") {
        toEmail = item.ToAddress;
      }

      this.$Emitter.emit("openPortalUserInterface", {
        CustomerId: this.settlementTask.Creditor.Customer.Id,
        CustomerEmail: toEmail,
        QuickEmail: item,
        IsPortalMessage: this.hasAuth(3958),
        IsPortalAuthMessage: false,
        AuthList: [],
      });
    },

    openReminder() {
      this.$Emitter.emit("openReminder", {
        CustomerId: this.settlementTask.Creditor.Customer.Id,
        IsAutoClose: false,
      });
    },
    openViewNotes() {
      this.$Emitter.emit("viewNotePanel", {
        CustomerId: this.settlementTask.Creditor.Customer.Id,
      });
    },
    payeePhoneChanged(event) {
      if (event.target.value) {
        this.settlementTask.PayeePhone = event.target.value;
      }
    },
    addPrefixToCustomerId(customerId) {
      let rtn = customerId.toString();
      if (this.customerPrefix != null) {
        rtn = this.customerPrefix + rtn;
      }
      return rtn;
    },
  },
};
</script>

<style>
.paymentGrid input::-webkit-outer-spin-button,
.paymentGrid input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.paymentGridDecimalInputs {
  width: 85% !important;
  margin-left: 1%;
  display: inline-block !important;
}
.paymentGridCurrencyIcon {
  width: 12% !important;
  display: inline-block;
}

.narrowGrid td {
  padding: 11px 5px !important;
}
.taskButtonsWrapper {
  width: 50px;
  position: absolute;
  top: 40px;
  right: 5px;
  z-index: 100;
}

.taskButton {
  width: 44px;
  height: 36px;
  cursor: pointer;
  padding: 5px 5px;
  background-color: #3bafda !important;
}

.textLabel {
  color: #777;
}

.blockTaskEdit {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: white;
  opacity: 0.4;
}

.bottomBoxCenter {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.closeButtonEnt {
  position: absolute;
  top: -15px;
  right: 15px;
  z-index: 1000;
  font-size: 30px;
  cursor: pointer;
}

.closeButtonEnt:hover {
  color: #ff3860;
}

.informationHeader {
  background-color: #3bafda;
}

.headerButtonsPosition {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.blueBoxControl {
  width: 120px;
  float: left;
  border-radius: 6px;
  padding: 5px 4%;
  border: solid 1px #d1d1d1;
  background-color: #ffffff;
  font-family: "Open Sans";
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  color: #2a404c;
}

.contactInformation {
  background-color: white;
  border-radius: 100px;
  width: 200px;
  align-items: center;
}

.o-drop {
  display: flex !important;
  position: relative;
  vertical-align: top;
}

.headerTextColor {
  color: white;
}

.documentTaskPopupClose {
  position: absolute;
  top: 0px;
  right: 10px;
  z-index: 1001;
  font-size: 2em;
  cursor: pointer;
}

.documentTaskPopupClose:hover {
  color: #ff3860;
}

.negotiatorDocumentPopup .datagrid-table tbody tr {
  cursor: pointer;
}

@media (min-width: 1200px) {
  .NTmobilScreen {
    display: flex;
    justify-content: space-evenly;
  }
}

.o-drop__item--active {
  background-color: #d1d1d171;
  color: #000000;
}

.o-drop__item {
  font-size: revert !important;
}

.NTblueBox {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255);
  overflow: hidden;
}

.NTblueBox::before {
  content: "";
  position: absolute;
  top: 0;
  left: -50%;
  width: 100%;
  height: 100%;
  background: #45b6dff3;
  pointer-events: none;
  transform: skewX(-100deg);
}

.ent-border {
  background-color: #fafafa;
  border: 1px rgb(219, 219, 219) solid;
}

.textRed {
  color: red;
}
.textGreen {
  color: #1abc9c;
  font-weight: bold;
}

.textRed2 {
  color: red;
}
.closeColor {
  color: #1abc9c;
}
.activeColor {
  color: rgb(75, 75, 75);
}

.negativeCashFlowPanel {
  float: right;
  padding: 5px 12px 0 12px;
  background-color: #f1556c;
  color: #fff;
  text-align: center;
}

.loading {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.6);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.animation-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  margin-top: -5px;
}

.animation-container:hover i {
  opacity: 1;
  color: #3bafda;
}
.plannerWrapper {
  width: 100%;
  position: relative;
}
.loadingAbsoluteBox {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
}
.loadingSpinner {
  display: flex;
  margin-top: 100px;
  justify-content: center;
  align-items: center;
}

.cashflowMoveable {
  position: absolute;
  min-width: 480px;
  height: auto;
  margin: 0 auto;
  padding: 0px;
  border-radius: 8px;
  box-shadow: 0 0 5px 2px #b7b3b3;
  background-color: #ffffff;
  cursor: pointer;
  z-index: 2000;
}
.moveable-control-box {
  display: none !important;
}

.calculate-loader {
  width: 40px;
  aspect-ratio: 1;
  border-radius: 50%;
  margin-top: -30px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  transform-origin: 50% 116.5%;
  animation: l17 2s infinite linear;
}
.calculate-loader:before {
  content: "";
  min-width: 233%;
  height: 233%;
  background: radial-gradient(farthest-side, #00da3c 90%, #0000) top,
    radial-gradient(farthest-side, #00cbe7 90%, #0000) left,
    radial-gradient(farthest-side, #fd8603 90%, #0000) bottom,
    radial-gradient(farthest-side, #00cbe7 90%, #0000) right;
  background-size: 43% 43%;
  background-repeat: no-repeat;
  animation: inherit;
  animation-direction: reverse;
}
@keyframes l17 {
  100% {
    transform: rotate(360deg);
  }
}
</style>
