<template>
  <div class="col-md-12">
    <ReportSummary
      :exportData="reportData"
      :reportName="'DebtBlueSalesCompensationReport'"
      :recordCount="recordCount"
      @updateLoadingStatus="reportLoading = $event"
      @setPayload="setPayload"
      @setPagination="setPagination"
      @clearReport="clearReport"
      :loadingSpinner="loadingSpinner"
    />

    <div
      class="gcsTabsTableScroll report-wrapper"
      :style="'max-height: ' + reportsPanelHeight + 'px; overflow-y:auto;'"
    >
      <div
        class="col-md-12 noPadding table-wrapper"
        v-if="!isLoading && agentList && agentList.length > 0"
      >
        <table
          class="custom-table mb-0"
          style="
            width: 100%;
            min-width: 1200px;
            border: none !important;
            background-color: #ffffff;
          "
        >
          <thead>
            <tr>
              <th class="idColumn"><span> </span></th>
              <th
                :class="item.WidthClass"
                style="cursor: pointer"
                @click="sortGrid(item)"
                v-for="item in columnsHeader"
                :key="item.Id"
              >
                {{ item.Label }}

                <span v-if="item.Asc">
                  <i class="mdi mdi-arrow-down-thick"></i>
                </span>
                <span v-else>
                  <i class="mdi mdi-arrow-up-thick"></i>
                </span>
              </th>
            </tr>
            <tr>
              <th class="idColumn"><span> </span></th>
              <th class="column14Percent">
                <div>
                  <input
                    type="text"
                    class="custom-table-input"
                    v-model="agentNameFilter"
                    placeholder=""
                  />
                </div>
              </th>
              <th class="column8Percent">
                <div>
                  <input
                    type="text"
                    class="custom-table-input"
                    v-model="customerIdFilter"
                    placeholder=""
                  />
                </div>
              </th>
              <th class="column14Percent">
                <div>
                  <input
                    type="text"
                    class="custom-table-input"
                    v-model="customerNameFilter"
                    placeholder=""
                  />
                </div>
              </th>
              <th class="column10Percent">
                <div>
                  <input
                    type="text"
                    class="custom-table-input"
                    v-model="firstClearedDraftDateFilter"
                    placeholder=""
                  />
                </div>
              </th>
              <th class="column10Percent">
                <div>
                  <input
                    type="text"
                    class="custom-table-input"
                    v-model="totalClearedDraftsFilter"
                    placeholder=""
                  />
                </div>
              </th>
              <th class="column10Percent">
                <div>
                  <input
                    type="text"
                    class="custom-table-input"
                    v-model="totalEnrolledDebtFilter"
                    placeholder=""
                  />
                </div>
              </th>
              <th class="column10Percent">
                <div>
                  <input
                    type="text"
                    class="custom-table-input"
                    v-model="originalDebtFilter"
                    placeholder=""
                  />
                </div>
              </th>
              <th class="column10Percent">
                <div>
                  <input
                    type="text"
                    class="custom-table-input"
                    v-model="totalPerformanceFeeFilter"
                    placeholder=""
                  />
                </div>
              </th>
              <th class="column10Percent">
                <div>
                  <input
                    type="text"
                    class="custom-table-input"
                    v-model="totalBonusFilter"
                    placeholder=""
                  />
                </div>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="item in filteredSalesCompensationReport"
              :key="item.AgentId"
            >
              <td colspan="10" style="padding: 0">
                <table style="width: 100%">
                  <tr style="background-color: #cde5f1 !important">
                    <td class="idColumn" @click="toggleDetails(item)">
                      <span v-if="item.DetailOpen" style="cursor: pointer">
                        <i class="mdi mdi-chevron-up mdi-24px"></i>
                      </span>
                      <span v-else style="cursor: pointer">
                        <i class="mdi mdi-chevron-down mdi-24px"></i>
                      </span>
                    </td>
                    <td class="column14Percent">
                      <span>
                        {{ item.AgentName }}
                      </span>
                    </td>
                    <td class="column8Percent">
                      <span> </span>
                    </td>
                    <td class="column14Percent">
                      <span> </span>
                    </td>
                    <td class="column10Percent" style="text-align: center">
                      <span> </span>
                    </td>
                    <td class="column10Percent" style="text-align: center">
                      <span>
                        {{ item.TotalClearedDrafts }}
                      </span>
                    </td>
                    <td class="column10Percent" style="text-align: center">
                      <span>
                        {{ formatMoney(item.TotalEnrolledDebt) }}
                      </span>
                    </td>
                    <td class="column10Percent">
                      <span> </span>
                    </td>
                    <td class="column10Percent">
                      <span>
                        {{ formatMoney(item.TotalPerformanceFee) }}
                      </span>
                    </td>
                    <td class="column10Percent">
                      <span>
                        {{ formatMoney(item.TotalBonus) }}
                      </span>
                    </td>
                  </tr>
                </table>
                <table v-if="item.DetailOpen" style="width: 100%">
                  <tr
                    v-for="customer in filteredCustomers(item)"
                    :key="customer.CustomerId"
                  >
                    <td class="idColumn"></td>
                    <td class="column14Percent">
                      <span> </span>
                    </td>
                    <td class="column8Percent">
                      <span>
                        {{ customerPrefix }}{{ customer.CustomerId }}
                      </span>
                    </td>
                    <td class="column14Percent">
                      <span>
                        {{ customer.CustomerName }}
                      </span>
                    </td>
                    <td class="column10Percent">
                      <span>
                        {{ formatShortDate(customer.FirstClearedDraftDate) }}
                      </span>
                    </td>
                    <td class="column10Percent" style="text-align: center">
                      <span>
                        {{ customer.ClearDraft }}
                      </span>
                    </td>
                    <td class="column10Percent" style="text-align: center">
                      <span>
                        {{ formatMoney(customer.EnrolledDebt) }}
                      </span>
                    </td>
                    <td class="column10Percent" style="text-align: center">
                      <span>
                        {{ formatMoney(customer.DebtAtEnrollment) }}
                      </span>
                    </td>
                    <td class="column10Percent">
                      <span>
                        {{ formatMoney(customer.PerformanceFee) }}
                      </span>
                    </td>
                    <td class="column10Percent">
                      <span>
                        {{ formatMoney(customer.Bonus) }}
                      </span>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr v-if="!isLoading">
              <td class="idColumn"></td>
              <td class="column14Percent">
                <span> {{ reportInitSummary.AgentName }} </span>
              </td>
              <td class="column8Percent">
                <span> </span>
              </td>
              <td class="column14Percent">
                <span> </span>
              </td>
              <td class="column10Percent">
                <span> </span>
              </td>
              <td class="column10Percent" style="text-align: center">
                <span>
                  {{ reportInitSummary.TotalClearedDrafts }}
                </span>
              </td>
              <td class="column10Percent" style="text-align: center">
                <span>
                  {{ formatMoney(reportInitSummary.TotalEnrolledDebt) }}
                </span>
              </td>
              <td class="column10Percent" style="text-align: center">
                <span>
                  {{ formatMoney(reportInitSummary.TotalDebtAtEnrollment) }}
                </span>
              </td>
              <td class="column10Percent">
                <span>
                  {{ formatMoney(reportInitSummary.TotalPerformanceFee) }}
                </span>
              </td>
              <td class="column10Percent">
                <span>
                  {{ formatMoney(reportInitSummary.TotalBonus) }}
                </span>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div v-if="loadingSpinner" class="text-center" style="margin: 6em 0">
        <i class="spinner-border avatar-lg text-primary m-2"></i>
      </div>
      <section
        v-if="agentList && agentList.length == 0 && !loadingSpinner"
        class="section custom-table"
        style="text-align: center"
      >
        <div class="content has-text-grey has-text-centered mt-4 mb-4">
          <p class="noMargin">
            <i
              style="font-size: 24px; color: #939393"
              class="ri-question-line"
            ></i>
          </p>
          <p class="noMargin">Nothing here.</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import types from "./types";
import globalTypes from "@/store/types";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import ReportSummary from "./ReportSummary.vue";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import _orderby from "lodash";
import deepCopy from "../../helpers/deepCopy";
export default {
  name: "DebtBlueSalesCompensationReport",
  components: { ReportSummary },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      isLoading: false,
      reportsPanelHeight: 300,
      customerPrefix: "",
      loadingSpinner: false,
      isPaginationSimple: false,
      isPaginated: true,
      paginationPosition: "both",
      currentPage: 1,
      perPage: 200,

      recordCount: 0,
      reportLoading: false,
      reportData: [],

      reportInitSummary: {
        AgentName: "",
        TotalClearedDrafts: "",
        TotalEnrolledDebt: "",
        TotalPerformanceFee: "",
        TotalBonus: "",
      },

      agentNameFilter: "",
      totalClearedDraftsFilter: "",
      totalEnrolledDebtFilter: "",
      totalPerformanceFeeFilter: "",
      totalBonusFilter: "",
      customerNameFilter: "",
      customerIdFilter: "",
      firstClearedDraftDateFilter: "",
      originalDebtFilter: "",
      agentList: [],
      columnsHeader: [
        {
          Id: 1,
          Name: "AgentName",
          Label: "Agent Name",
          WidthClass: "column14Percent",
          Asc: true,
          DataType: "string",
        },

        {
          Id: 2,
          Name: "CustomerId",
          Label: "Customer Id",
          WidthClass: "column8Percent",
          Asc: true,
          DataType: "number",
        },
        {
          Id: 3,
          Name: "CustomerName",
          Label: "Customer Name",
          WidthClass: "column14Percent",
          Asc: true,
          DataType: "string",
        },
        {
          Id: 4,
          Name: "FirstClearedDraftDate",
          Label: "1st Cleared Draft Date",
          WidthClass: "column10Percent",
          Asc: true,
          DataType: "date",
        },
        {
          Id: 5,
          Name: "TotalClearedDrafts",
          Label: "# Cleared Drafts",
          WidthClass: "column10Percent",
          Asc: true,
          DataType: "number",
        },
        {
          Id: 6,
          Name: "TotalEnrolledDebt",
          Label: "Enrolled Debt",
          WidthClass: "column10Percent",
          Asc: true,
          DataType: "money",
        },
        {
          Id: 7,
          Name: "DebtAtEnrollment",
          Label: "Original Debt",
          WidthClass: "column10Percent",
          Asc: true,
          DataType: "money",
        },
        {
          Id: 8,
          Name: "TotalPerformanceFee",
          Label: "Performance Fee",
          WidthClass: "column10Percent",
          Asc: true,
          DataType: "money",
        },
        {
          Id: 9,
          Name: "TotalBonus",
          Label: "Bonus",
          WidthClass: "column10Percent",
          Asc: true,
          DataType: "money",
        },
      ],
    };
  },
  computed: mapState({
    filteredSalesCompensationReport() {
      let rtn = [];

      let tmpList =
        this.agentList.length > 0
          ? this.agentList.filter((item) => {
              return (
                this.filterString(item.AgentName, this.agentNameFilter) &&
                this.filterString(
                  item.TotalClearedDrafts,
                  this.totalClearedDraftsFilter
                ) &&
                this.filterString(
                  item.TotalEnrolledDebt,
                  this.totalEnrolledDebtFilter
                ) &&
                this.filterString(
                  item.TotalPerformanceFee,
                  this.totalPerformanceFeeFilter
                ) &&
                this.filterString(item.TotalBonus, this.totalBonusFilter) &&
                item.Customers.some((x) =>
                  this.filterString(x.CustomerName, this.customerNameFilter)
                ) &&
                item.Customers.some((x) =>
                  this.filterString(x.CustomerId, this.customerIdFilter)
                ) &&
                item.Customers.some(
                  (x) =>
                    this.formatShortDate(x.FirstClearedDraftDate).indexOf(
                      this.firstClearedDraftDateFilter
                    ) > -1
                ) &&
                item.Customers.some((x) =>
                  this.filterString(x.DebtAtEnrollment, this.originalDebtFilter)
                )
              );
            })
          : [];

      if (tmpList.length == 0) {
        this.reportData = [];
        return rtn;
      }
      let totalRecord = tmpList.length;
      this.recordCount = totalRecord;
      let totalcd = 0;
      let totaled = 0.0;
      let totalpf = 0.0;
      let totalbo = 0.0;
      let totaldebtAtEnrollment = 0.0;

      for (let listItem of tmpList) {
        totalcd += listItem.TotalClearedDrafts;
        totaled += parseFloat(listItem.TotalEnrolledDebt);
        totalpf += parseFloat(listItem.TotalPerformanceFee);
        totalbo += parseFloat(listItem.TotalBonus);

        for (let cust of listItem.Customers) {
          if (cust.DebtAtEnrollment) {
            totaldebtAtEnrollment += parseFloat(cust.DebtAtEnrollment);
          }
        }
      }
      this.reportInitSummary.TotalClearedDrafts = totalcd;

      this.reportInitSummary.TotalEnrolledDebt = totaled;

      this.reportInitSummary.TotalPerformanceFee = totalpf;

      this.reportInitSummary.TotalBonus = totalbo;

      this.reportInitSummary.TotalDebtAtEnrollment = isNaN(
        totaldebtAtEnrollment
      )
        ? ""
        : totaldebtAtEnrollment;

      this.reportInitSummary.AgentName = "Total: " + totalRecord;

      rtn.push(...tmpList);
      this.reportData = rtn;

      return rtn;
    },
  }),
  async mounted() {
    this.reportsPanelHeight = this.setReportGridHeight(
      window.innerHeight,
      document.getElementById("reportsSummary")
    );
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

    if (
      this.userInfo != null &&
      this.userInfo.customerIdPrefix != null &&
      this.userInfo.customerIdPrefix.length > 0
    ) {
      this.customerPrefix = this.userInfo.customerIdPrefix;
    }
  },
  methods: {
    filteredCustomers(item) {
      let rtn = [];
      if (typeof item != "undefined" && item.Customers != null) {
        let tmpList =
          item.Customers.length > 0
            ? item.Customers.filter((i) => {
                return (
                  this.filterString(i.CustomerName, this.customerNameFilter) &&
                  this.filterString(i.CustomerId, this.customerIdFilter)
                );
              })
            : [];

        if (tmpList.length == 0) {
          return rtn;
        }

        rtn.push(...tmpList);
      }

      return rtn;
    },
    setPagination(data) {
      this.isLoading = true;
      this.agentList.forEach((x) => (x.DetailOpen = true));
      this.isLoading = false;
    },

    toggleDetails(item) {
      this.isLoading = true;
      item.DetailOpen = !item.DetailOpen;

      this.isLoading = false;
    },

    sortByCustomerName(sortingDirection) {
      if (typeof this.agentList != "undefined" && this.agentList != null) {
        this.isLoading = true;
        let tmpList = [];
        let allCustomers = [];
        let matchList = this.agentList.map(function (elem) {
          return { MainItem: elem, Customers: elem.Customers };
        });
        for (let item of this.agentList) {
          for (let cust of item.Customers) {
            allCustomers.push(cust);
          }
        }

        let orderedCustomers = _orderby.orderBy(
          allCustomers,
          "CustomerName",
          sortingDirection
        );

        if (matchList.length > 0 && orderedCustomers.length > 0) {
          for (let item of orderedCustomers) {
            let indx = this.agentList.findIndex((x) =>
              x.Customers.map((y) => y.CustomerId).includes(item.CustomerId)
            );
            if (indx > -1) {
              if (
                tmpList.filter((x) => x.AgentId == this.agentList[indx].AgentId)
                  .length == 0
              ) {
                this.agentList[indx].Customers = _orderby.orderBy(
                  this.agentList[indx].Customers,
                  "CustomerName",
                  sortingDirection
                );
                tmpList.push(this.agentList[indx]);
              }
            }
          }
        }

        if (tmpList.length > 0) {
          this.agentList = [];
          this.agentList = deepCopy(tmpList);
        }
      }
      this.isLoading = false;
    },
    sortByCustomerId(sortingDirection) {
      if (typeof this.agentList != "undefined" && this.agentList != null) {
        this.isLoading = true;
        let tmpList = [];
        let allCustomers = [];
        let matchList = this.agentList.map(function (elem) {
          return { MainItem: elem, Customers: elem.Customers };
        });
        for (let item of this.agentList) {
          for (let cust of item.Customers) {
            allCustomers.push(cust);
          }
        }

        let orderedCustomers = allCustomers.sort(function (a, b) {
          return parseInt(a["CustomerId"]) - parseInt(b["CustomerId"]);
        });
        if (sortingDirection == "desc") {
          orderedCustomers = allCustomers.sort(function (a, b) {
            return parseInt(b["CustomerId"]) - parseInt(a["CustomerId"]);
          });
        }

        if (matchList.length > 0 && orderedCustomers.length > 0) {
          for (let item of orderedCustomers) {
            let indx = this.agentList.findIndex((x) =>
              x.Customers.map((y) => y.CustomerId).includes(item.CustomerId)
            );
            if (indx > -1) {
              if (
                tmpList.filter((x) => x.AgentId == this.agentList[indx].AgentId)
                  .length == 0
              ) {
                if (sortingDirection == "asc") {
                  this.agentList[indx].Customers.sort(function (a, b) {
                    return (
                      parseInt(a["CustomerId"]) - parseInt(b["CustomerId"])
                    );
                  });
                } else if (sortingDirection == "desc") {
                  this.agentList[indx].Customers.sort(function (a, b) {
                    return (
                      parseInt(b["CustomerId"]) - parseInt(a["CustomerId"])
                    );
                  });
                }
                tmpList.push(this.agentList[indx]);
              }
            }
          }
        }

        if (tmpList.length > 0) {
          this.agentList = [];
          this.agentList = deepCopy(tmpList);
        }
      }
      this.isLoading = false;
    },
    sortByCustomerFieldsDate(sortingDirection, columnName) {
      if (typeof this.agentList != "undefined" && this.agentList != null) {
        this.isLoading = true;
        let tmpList = [];
        let allCustomers = [];
        let matchList = this.agentList.map(function (elem) {
          return { MainItem: elem, Customers: elem.Customers };
        });
        for (let item of this.agentList) {
          for (let cust of item.Customers) {
            allCustomers.push(cust);
          }
        }

        let orderedCustomers = allCustomers.sort(function (a, b) {
          return new Date(a[columnName]) - new Date(b[columnName]);
        });

        if (sortingDirection == "desc") {
          orderedCustomers = allCustomers.sort(function (a, b) {
            return new Date(b[columnName]) - new Date(a[columnName]);
          });
        }

        if (matchList.length > 0 && orderedCustomers.length > 0) {
          for (let item of orderedCustomers) {
            let indx = this.agentList.findIndex((x) =>
              x.Customers.map((y) => y[columnName]).includes(item[columnName])
            );
            if (indx > -1) {
              if (
                tmpList.filter((x) => x.AgentId == this.agentList[indx].AgentId)
                  .length == 0
              ) {
                if (sortingDirection == "asc") {
                  this.agentList[indx].Customers.sort(function (a, b) {
                    return new Date(a[columnName]) - new Date(b[columnName]);
                  });
                } else if (sortingDirection == "desc") {
                  this.agentList[indx].Customers.sort(function (a, b) {
                    return new Date(b[columnName]) - new Date(a[columnName]);
                  });
                }
                tmpList.push(this.agentList[indx]);
              }
            }
          }
        }

        if (tmpList.length > 0) {
          this.agentList = [];
          this.agentList = deepCopy(tmpList);
        }
      }
      this.isLoading = false;
    },
    sortByCustomerFieldsMoney(sortingDirection, columnName) {
      if (typeof this.agentList != "undefined" && this.agentList != null) {
        this.isLoading = true;
        let tmpList = [];
        let allCustomers = [];
        let matchList = this.agentList.map(function (elem) {
          return { MainItem: elem, Customers: elem.Customers };
        });
        for (let item of this.agentList) {
          for (let cust of item.Customers) {
            allCustomers.push(cust);
          }
        }

        let orderedCustomers = allCustomers.sort(function (a, b) {
          return parseFloat(a[columnName]) - parseFloat(b[columnName]);
        });

        if (sortingDirection == "desc") {
          orderedCustomers = allCustomers.sort(function (a, b) {
            return parseFloat(b[columnName]) - parseFloat(a[columnName]);
          });
        }

        if (matchList.length > 0 && orderedCustomers.length > 0) {
          for (let item of orderedCustomers) {
            let indx = this.agentList.findIndex((x) =>
              x.Customers.map((y) => y[columnName]).includes(item[columnName])
            );
            if (indx > -1) {
              if (
                tmpList.filter((x) => x.AgentId == this.agentList[indx].AgentId)
                  .length == 0
              ) {
                if (sortingDirection == "asc") {
                  this.agentList[indx].Customers.sort(function (a, b) {
                    return (
                      parseFloat(a[columnName]) - parseFloat(b[columnName])
                    );
                  });
                } else if (sortingDirection == "desc") {
                  this.agentList[indx].Customers.sort(function (a, b) {
                    return (
                      parseFloat(b[columnName]) - parseFloat(a[columnName])
                    );
                  });
                }
                tmpList.push(this.agentList[indx]);
              }
            }
          }
        }

        if (tmpList.length > 0) {
          this.agentList = [];
          this.agentList = deepCopy(tmpList);
        }
      }
      this.isLoading = false;
    },
    sortGrid(item) {
      this.isLoading = true;
      this.agentList = _orderby.orderBy(this.agentList, item.Name, "desc");
      if (item.DataType == "string") {
        if (item.Asc) {
          if (item.Name == "CustomerName") {
            this.sortByCustomerName("desc");
          } else {
            this.agentList = _orderby.orderBy(
              this.agentList,
              item.Name,
              "desc"
            );
          }
          item.Asc = false;
        } else {
          if (item.Name == "CustomerName") {
            this.sortByCustomerName("asc");
          } else {
            this.agentList = _orderby.orderBy(this.agentList, item.Name, "asc");
          }
          item.Asc = true;
        }
      } else if (item.DataType == "number") {
        if (item.Asc) {
          if (item.Name == "CustomerId") {
            this.sortByCustomerId("desc");
          } else {
            this.agentList.sort(function (a, b) {
              return parseInt(a[item.Name]) - parseInt(b[item.Name]);
            });
          }

          item.Asc = false;
        } else {
          if (item.Name == "CustomerId") {
            this.sortByCustomerId("asc");
          } else {
            this.agentList.sort(function (a, b) {
              return parseInt(b[item.Name]) - parseInt(a[item.Name]);
            });
          }
          item.Asc = true;
        }
      } else if (item.DataType == "money") {
        if (item.Asc) {
          if (item.Name == "DebtAtEnrollment") {
            this.sortByCustomerFieldsMoney("desc", "DebtAtEnrollment");
          } else {
            this.agentList.sort(function (a, b) {
              return parseFloat(a[item.Name]) - parseFloat(b[item.Name]);
            });
          }
          item.Asc = false;
        } else {
          if (item.Name == "DebtAtEnrollment") {
            this.sortByCustomerFieldsMoney("asc", "DebtAtEnrollment");
          } else {
            this.agentList.sort(function (a, b) {
              return parseFloat(b[item.Name]) - parseFloat(a[item.Name]);
            });
          }

          item.Asc = true;
        }
      } else if (item.DataType == "date") {
        if (item.Asc) {
          if (item.Name == "FirstClearedDraftDate") {
            this.sortByCustomerFieldsDate("desc", "FirstClearedDraftDate");
          }
          item.Asc = false;
        } else {
          if (item.Name == "FirstClearedDraftDate") {
            this.sortByCustomerFieldsDate("asc", "FirstClearedDraftDate");
          }
          item.Asc = true;
        }
      }
      this.isLoading = false;
    },

    async setPayload(value) {
      this.loadingSpinner = true;
      let err, result;
      [err, result] = await this.$store.dispatch(
        types.GET_DB_SALES_COMPENSATION_REPORT,
        value
      );
      if (result && result.Data) {
        this.agentList = deepCopy(result.Data);
      }
      this.loadingSpinner = false;
    },
    clearReport() {
      this.$store.dispatch(types.RESET_REPORTS);
      this.reportData = [];
      this.agentList = [];
    },

    async viewCustomer(id, name) {
      this.$Emitter.emit("viewCustomer", {
        customerId: id,
        customerName: name,
      });
    },
  },
};
</script>

<style>
.detailsDiv {
  font-size: 13px !important;
  margin-left: 54px;
  font-weight: normal !important;
  color: #2a404c;
}
.detailsHeader {
  color: #3bafda;
  font-size: 13px;
  font-weight: 600;
}
.idColumn {
  width: 4% !important;
  min-width: 4% !important;
  max-width: 4% !important;
  display: block;
}
.column6Percent {
  width: 6% !important;
  min-width: 6% !important;
  max-width: 6% !important;
  text-align: left;
}
.column7Percent {
  width: 7% !important;
  min-width: 7% !important;
  max-width: 7% !important;
  text-align: left;
}
.column8Percent {
  width: 8% !important;
  min-width: 8% !important;
  max-width: 8% !important;
  text-align: left;
}
.column9Percent {
  width: 9% !important;
  min-width: 9% !important;
  max-width: 9% !important;
  text-align: left;
}
.column10Percent {
  width: 10% !important;
  min-width: 10% !important;
  max-width: 10% !important;
  text-align: left;
}
.column11Percent {
  width: 11% !important;
  min-width: 11% !important;
  max-width: 11% !important;
  text-align: left;
}
.column11Percent {
  width: 11% !important;
  min-width: 11% !important;
  max-width: 11% !important;
  text-align: left;
}
.column11Percent {
  width: 11% !important;
  min-width: 11% !important;
  max-width: 11% !important;
  text-align: left;
}
.column12Percent {
  width: 12% !important;
  min-width: 12% !important;
  max-width: 12% !important;
  text-align: left;
}
.column13Percent {
  width: 13% !important;
  min-width: 13% !important;
  max-width: 13% !important;
  text-align: left;
}
.column14Percent {
  width: 14% !important;
  min-width: 14% !important;
  max-width: 14% !important;
  text-align: left;
}
.column15Percent {
  width: 15% !important;
  min-width: 15% !important;
  max-width: 15% !important;
  text-align: left;
}
.column16Percent {
  width: 16% !important;
  min-width: 16% !important;
  max-width: 16% !important;
  text-align: left;
}
.column17Percent {
  width: 17% !important;
  min-width: 17% !important;
  max-width: 17% !important;
  text-align: left;
}
.column18Percent {
  width: 18% !important;
  min-width: 18% !important;
  max-width: 18% !important;
  text-align: left;
}
.column19Percent {
  width: 19% !important;
  min-width: 19% !important;
  max-width: 19% !important;
  text-align: left;
}
.column20Percent {
  width: 20% !important;
  min-width: 20% !important;
  max-width: 20% !important;
  text-align: left;
}
.columnCentered {
  text-align: center;
}
</style>
