<template>
  <Popover class="transfer">
    <template v-slot:header>
      <div class="container">
        Transfer to Agent VM
        <button
          class="no-button"
          type="button"
          v-on:click.prevent.stop="closePopup"
        >
          &lt; Back
        </button>
        <button
          class="no-button"
          type="button"
          v-on:click.prevent.stop="$emit('transfer', 0)"
        ></button>
        <input class="form-control" type="text" v-model="contactListFilter" />
      </div>
    </template>
    <template v-slot:body>
      <div class="contact-list">
        <div
          class="contact"
          v-for="item in softPhoneEmployees"
          :key="item.Id"
          @click="handleTransferVm(item)"
        >
          {{ item.Name }}
        </div>
      </div>
    </template>
  </Popover>
</template>

<script>
import Popover from "../../Popover";
import _orderby from "lodash";
import { mapState } from "vuex";
import utilitiesMixin from "@/mixins/utilitiesMixin";

export default {
  components: {
    Popover,
  },
  props: {
    endPointName: {
      type: String,
      default: () => "",
    },
  },
  emits: ["transfer", "transferToAgentVm"],
  data() {
    return {
      contactListFilter: "",
      userOfficeId: null,
    };
  },
  mixins: [utilitiesMixin],
  computed: mapState({
    employeeList: (state) => state.globals.allEmployees,
    softPhoneEmployees() {
      return _orderby.orderBy(
        this.checkAuth(100005)
          ? this.employeeList.filter(
              (x) =>
                x.Name.toLowerCase().indexOf(
                  this.contactListFilter.toLowerCase()
                ) > -1
            )
          : this.employeeList
              .filter((x) => x.OfficeId == this.userOfficeId)
              .filter((item) => item.IsPhoneEnabled)
              .filter((item) => item.EndpointName != "")
              .filter((item) => item.EndpointName != this.endPointName)
              .filter(
                (x) =>
                  x.Name.toLowerCase().indexOf(
                    this.contactListFilter.toLowerCase()
                  ) > -1
              ),
        "Name"
      );
    },
  }),
  async mounted() {
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo) {
      this.userOfficeId = userInfo.officeId;
    }
  },
  methods: {
    handleTransferVm(item) {
      this.$emit("transferToAgentVm", item);
    },
    closePopup() {
      this.$emit("transfer", 0);
    },
  },
};
</script>
