export default {
  GET_SCHEDULE_CLEARED_FEE_CONTROLLER_REPORT:
    "GET_SCHEDULE_CLEARED_FEE_CONTROLLER_REPORT",
  SET_SCHEDULE_CLEARED_FEE_CONTROLLER_REPORT:
    "SET_SCHEDULE_CLEARED_FEE_CONTROLLER_REPORT",

  GET_GRT_PURCHASABLE_CUSTOMERS_REPORT: "GET_GRT_PURCHASABLE_CUSTOMERS_REPORT",
  SET_GRT_PURCHASABLE_CUSTOMERS_REPORT: "SET_GRT_PURCHASABLE_CUSTOMERS_REPORT",

  GET_PURCHASE_CREDITOR_INSURANCE_REPORT:
    "GET_PURCHASE_CREDITOR_INSURANCE_REPORT",
  SET_PURCHASE_CREDITOR_INSURANCE_REPORT:
    "SET_PURCHASE_CREDITOR_INSURANCE_REPORT",

  GET_FIRST_LEAD_ASSIGNMENT_REPORT: "GET_FIRST_LEAD_ASSIGNMENT_REPORT",
  SET_FIRST_LEAD_ASSIGNMENT_REPORT: "SET_FIRST_LEAD_ASSIGNMENT_REPORT",

  GET_PURCHASE_CREDITOR_REPORT: "GET_PURCHASE_CREDITOR_REPORT",
  SET_PURCHASE_CREDITOR_REPORT: "SET_PURCHASE_CREDITOR_REPORT",

  GET_PURCHASE_CLIENT_INFORMATION_REPORT:
    "GET_PURCHASE_CLIENT_INFORMATION_REPORT",
  SET_PURCHASE_CLIENT_INFORMATION_REPORT:
    "SET_PURCHASE_CLIENT_INFORMATION_REPORT",

  GET_SOFI_SCRUB_REPORT: "GET_SOFI_SCRUB_REPORT",
  SET_SOFI_SCRUB_REPORT: "SET_SOFI_SCRUB_REPORT",
  MODIFY_SOFI_SCRUB_REPORT: "MODIFY_SOFI_SCRUB_REPORT",

  GET_SCRUB_LIST_REPORT_PRA: "GET_SCRUB_LIST_REPORT_PRA",
  SET_SCRUB_LIST_REPORT_PRA: "SET_SCRUB_LIST_REPORT_PRA",
  MODIFY_SCRUB_LIST_REPORT_PRA: "MODIFY_SCRUB_LIST_REPORT_PRA",

  GET_DPG_PAYMENTS_AND_FEES_REPORT: "GET_DPG_PAYMENTS_AND_FEES_REPORT",
  SET_DPG_PAYMENTS_AND_FEES_REPORT: "SET_DPG_PAYMENTS_AND_FEES_REPORT",

  GET_GLOBAL_DRAFTS_REPORT: "GET_GLOBAL_DRAFTS_REPORT",
  SET_GLOBAL_DRAFTS_REPORT: "SET_GLOBAL_DRAFTS_REPORT",
  
  GET_VOIDED_SETTLEMENT_REPORT: "GET_VOIDED_SETTLEMENT_REPORT",
  SET_VOIDED_SETTLEMENT_REPORT: "SET_VOIDED_SETTLEMENT_REPORT",

  GET_CAPITAL_ONE_SCRUB_REPORT: "GET_CAPITAL_ONE_SCRUB_REPORT",
  SET_CAPITAL_ONE_SCRUB_REPORT: "SET_CAPITAL_ONE_SCRUB_REPORT",
  MODIFY_CAPITAL_ONE_SCRUB_REPORT: "MODIFY_CAPITAL_ONE_SCRUB_REPORT",

  GET_FIRST_BANK_SCRUB_REPORT: "GET_FIRST_BANK_SCRUB_REPORT",
  SET_FIRST_BANK_SCRUB_REPORT: "SET_FIRST_BANK_SCRUB_REPORT",
  MODIFY_FIRST_BANK_SCRUB_REPORT: "MODIFY_FIRST_BANK_SCRUB_REPORT",

  GET_USBANK_ELAN_SCRUB_REPORT: "GET_USBANK_ELAN_SCRUB_REPORT",
  SET_USBANK_ELAN_SCRUB_REPORT: "SET_USBANK_ELAN_SCRUB_REPORT",
  MODIFY_USBANK_ELAN_SCRUB_REPORT: "MODIFY_USBANK_ELAN_SCRUB_REPORT",

  GET_SNF_PAYOUT_REPORT: "GET_SNF_PAYOUT_REPORT",
  SET_SNF_PAYOUT_REPORT: "SET_SNF_PAYOUT_REPORT",

  GET_SETTLEMENTS_REQUEST_REPORT: "GET_SETTLEMENTS_REQUEST_REPORT",
  SET_SETTLEMENTS_REQUEST_REPORT: "SET_SETTLEMENTS_REQUEST_REPORT",

  GET_UNSETTLED_CREDITOR_REPORT: "GET_UNSETTLED_CREDITOR_REPORT",

  GET_NEW_LEAD_REPORT: "GET_NEW_LEAD_REPORT",

  GET_CONTRACT_STATUSES_REPORT: "GET_CONTRACT_STATUSES_REPORT",

  GET_ENROLLED_CREDITORS_REPORT: "GET_ENROLLED_CREDITORS_REPORT",

  GET_SETTLEMENTS_FEE_REPORT: "GET_SETTLEMENTS_FEE_REPORT",
  SET_SETTLEMENTS_FEE_REPORT: "SET_SETTLEMENTS_FEE_REPORT",

  GET_DEBT_CHANGES_POST_ENROLLMENT_REPORT:
    "GET_DEBT_CHANGES_POST_ENROLLMENT_REPORT",
  SET_DEBT_CHANGES_POST_ENROLLMENT_REPORT:
    "SET_DEBT_CHANGES_POST_ENROLLMENT_REPORT",

  GET_LEAD_TRAC_ENROLLMENTS_REPORT: "GET_LEAD_TRAC_ENROLLMENTS_REPORT",
  SET_LEAD_TRAC_ENROLLMENTS_REPORT: "SET_LEAD_TRAC_ENROLLMENTS_REPORT",

  GET_CONNECT_GLOBAL_ACCOUNT: "GET_CONNECT_GLOBAL_ACCOUNT",

  GET_SNF_PENDING_CANCELLATION_REPORT: "GET_SNF_PENDING_CANCELLATION_REPORT",
  SET_SNF_PENDING_CANCELLATION_REPORT: "SET_SNF_PENDING_CANCELLATION_REPORT",

  GET_ENROLLED_WITHOUT_SMS_OPTIN_REPORT:
    "GET_ENROLLED_WITHOUT_SMS_OPTIN_REPORT",
  SET_ENROLLED_WITHOUT_SMS_OPTIN_REPORT:
    "SET_ENROLLED_WITHOUT_SMS_OPTIN_REPORT",

  GET_SNF_ATTORNEY_ACTIVITY_REPORT: "GET_SNF_ATTORNEY_ACTIVITY_REPORT",
  SET_SNF_ATTORNEY_ACTIVITY_REPORT: "SET_SNF_ATTORNEY_ACTIVITY_REPORT",

  GET_NSF_GLOBAL_REPORT: "GET_NSF_GLOBAL_REPORT",
  SET_NSF_GLOBAL_REPORT: "SET_NSF_GLOBAL_REPORT",

  NEW_LINE_SUBMIT_APPLICATION: "NEW_LINE_SUBMIT_APPLICATION",

  GET_DOCUMENT_AUDIT_REPORT: "GET_DOCUMENT_AUDIT_REPORT",
  SET_DOCUMENT_AUDIT_REPORT: "SET_DOCUMENT_AUDIT_REPORT",

  GET_CSR_TASK_CONTACT_SCHEDULE_REPORT: "GET_CSR_TASK_CONTACT_SCHEDULE_REPORT",
  SET_CSR_TASK_CONTACT_SCHEDULE_REPORT: "SET_CSR_TASK_CONTACT_SCHEDULE_REPORT",

  GET_RESPONDERS_REPORT: "GET_RESPONDERS_REPORT",
  SET_RESPONDERS_REPORT: "SET_RESPONDERS_REPORT",

  GET_NSF_CANCELLATION_REPORT: "GET_NSF_CANCELLATION_REPORT",
  SET_NSF_CANCELLATION_REPORT: "SET_NSF_CANCELLATION_REPORT",

  GET_CALL_METRICS_REPORT: "GET_CALL_METRICS_REPORT",
  SET_CALL_METRICS_REPORT: "sET_CALL_METRICS_REPORT",

  GET_CSR_TASK_DISTRIBUTION_QUEUE_REPORT:
    "GET_CSR_TASK_DISTRIBUTION_QUEUE_REPORT",
  SET_CSR_TASK_DISTRIBUTION_QUEUE_REPORT:
    "SET_CSR_TASK_DISTRIBUTION_QUEUE_REPORT",

  GET_DISPOSITION_SETTINGS_REPORT: "GET_DISPOSITION_SETTINGS_REPORT",
  SET_DISPOSITION_SETTINGS_REPORT: "SET_DISPOSITION_SETTINGS_REPORT",

  GET_USER_ACTIVITY_REPORT: "GET_USER_ACTIVITY_REPORT",
  SET_USER_ACTIVITY_REPORT: "SET_USER_ACTIVITY_REPORT",

  GET_CSR_TASK_SUMMARY_REPORT: "GET_CSR_TASK_SUMMARY_REPORT",
  SET_CSR_TASK_SUMMARY_REPORT: "SET_CSR_TASK_SUMMARY_REPORT",

  GET_SETTLEMENT_ASSIST_REPORT: "GET_SETTLEMENT_ASSIST_REPORT",
  SET_SETTLEMENT_ASSIST_REPORT: "SET_SETTLEMENT_ASSIST_REPORT",
  MODIFY_SETTLEMENT_ASSIST_REPORT: "MODIFY_SETTLEMENT_ASSIST_REPORT",

  MODIFY_MASTER_SCRUB_REPORT: "MODIFY_MASTER_SCRUB_REPORT",
  MODIFY_AMERICAN_EXPRESS_SCRUB_REPORT: "MODIFY_AMERICAN_EXPRESS_SCRUB_REPORT",
  MODIFY_DISCOVER_SCRUB_REPORT: "MODIFY_DISCOVER_SCRUB_REPORT",
  MODIFY_ONE_MAIN_SCRUB_REPORT: "MODIFY_ONE_MAIN_SCRUB_REPORT",
  MODIFY_FORTIVA_SCRUB_REPORT: "MODIFY_FORTIVA_SCRUB_REPORT",

  MODIFY_SETTLEMENT_CANDIDATES_REPORT: "MODIFY_SETTLEMENT_CANDIDATES_REPORT",
  MODIFY_CREDITOR_REPORT: "MODIFY_CREDITOR_REPORT",

  GET_FORTIVA_SCRUB_REPORT: "GET_FORTIVA_SCRUB_REPORT",
  SET_FORTIVA_SCRUB_REPORT: "SET_FORTIVA_SCRUB_REPORT",

  GET_AMERICAN_EXPRESS_SCRUB_REPORT: "GET_AMERICAN_EXPRESS_SCRUB_REPORT",
  SET_AMERICAN_EXPRESS_SCRUB_REPORT: "SET_AMERICAN_EXPRESS_SCRUB_REPORT",

  GET_MASTER_SCRUB_REPORT: "GET_MASTER_SCRUB_REPORT",
  SET_MASTER_SCRUB_REPORT: "SET_MASTER_SCRUB_REPORT",

  GET_DISCOVER_SCRUB_REPORT: "GET_DISCOVER_SCRUB_REPORT",
  SET_DISCOVER_SCRUB_REPORT: "SET_DISCOVER_SCRUB_REPORT",

  GET_ONE_MAIN_SCRUB_REPORT: "GET_ONE_MAIN_SCRUB_REPORT",
  SET_ONE_MAIN_SCRUB_REPORT: "SET_ONE_MAIN_SCRUB_REPORT",

  GET_AGENT_PHONE_REPORT: "GET_AGENT_PHONE_REPORT",
  SET_AGENT_PHONE_REPORT: "SET_AGENT_PHONE_REPORT",

  GET_AGENT_PERFORMANCE_NEW_REPORT: "GET_AGENT_PERFORMANCE_NEW_REPORT",
  SET_AGENT_PERFORMANCE_NEW_REPORT: "SET_AGENT_PERFORMANCE_NEW_REPORT",

  GET_AGENT_STATUS_REPORT: "GET_AGENT_STATUS_REPORT",
  SET_AGENT_STATUS_REPORT: "SET_AGENT_STATUS_REPORT",

  GET_CALL_LOG_DETAIL: "GET_CALL_LOG_DETAIL",

  GET_NEW_LINE_REPORT: "GET_NEW_LINE_REPORT",
  SET_NEW_LINE_REPORT: "SET_NEW_LINE_REPORT",

  GET_SNF_DAA_ENROLLMENT_REPORT: "GET_SNF_DAA_ENROLLMENT_REPORT",
  SET_SNF_DAA_ENROLLMENT_REPORT: "SET_SNF_DAA_ENROLLMENT_REPORT",

  GET_DB_SALES_COMPENSATION_REPORT: "GET_DB_SALES_COMPENSATION_REPORT",

  GET_SNF_EG_REPORT: "GET_SNF_EG_REPORT",
  SET_SNF_EG_REPORT: "SET_SNF_EG_REPORT",

  GET_SNF_DNC_REPORT: "GET_SNF_DNC_REPORT",
  SET_SNF_DNC_REPORT: "SET_SNF_DNC_REPORT",

  GET_SNF_ENROLLMENT_REPORT: "GET_SNF_ENROLLMENT_REPORT",
  SET_SNF_ENROLLMENT_REPORT: "SET_SNF_ENROLLMENT_REPORT",

  GET_SNF_FIRST_DRAFT_REPORT: "GET_SNF_FIRST_DRAFT_REPORT",
  SET_SNF_FIRST_DRAFT_REPORT: "SET_SNF_FIRST_DRAFT_REPORT",

  GET_SETTLEMENT_CANDIDATES_REPORT: "GET_SETTLEMENT_CANDIDATES_REPORT",
  SET_SETTLEMENT_CANDIDATES_REPORT: "SET_SETTLEMENT_CANDIDATES_REPORT",

  GET_SETTLEMENTS_REPORT: "GET_SETTLEMENTS_REPORT",
  SET_SETTLEMENTS_REPORT: "SET_SETTLEMENTS_REPORT",

  SET_LIVE_CALL_ROLE_LIST: "SET_LIVE_CALL_ROLE_LIST",

  GET_CUSTOMER_TAG_REPORT: "GET_CUSTOMER_TAG_REPORT",
  SET_CUSTOMER_TAG_REPORT: "SET_CUSTOMER_TAG_REPORT",

  GET_CREDITOR_REPORT: "GET_CREDITOR_REPORT",
  SET_CREDITOR_REPORT: "SET_CREDITOR_REPORT",

  GET_ACTIVE_ENROLLMENTS_REPORT: "GET_ACTIVE_ENROLLMENTS_REPORT",
  MODIFY_ACTIVE_ENROLLMENTS_REPORT: "MODIFY_ACTIVE_ENROLLMENTS_REPORT",
  SET_ACTIVE_ENROLLMENTS_REPORT: "SET_ACTIVE_ENROLLMENTS_REPORT",

  GET_PENDING_DISPOSITION_REPORT: "GET_PENDING_DISPOSITION_REPORT",
  SET_PENDING_DISPOSITION_REPORT: "SET_PENDING_DISPOSITION_REPORT",

  GET_FIRST_CLEAR_DRAFT_REPORT: "GET_FIRST_CLEAR_DRAFT_REPORT",
  SET_FIRST_CLEAR_DRAFT_REPORT: "SET_FIRST_CLEAR_DRAFT_REPORT",

  GET_CLEAR_DRAFT_REPORT: "GET_CLEAR_DRAFT_REPORT",
  SET_CLEAR_DRAFT_REPORT: "SET_CLEAR_DRAFT_REPORT",

  GET_CREDIT_PULL_REPORT: "GET_CREDIT_PULL_REPORT",
  SET_CREDIT_PULL_REPORT: "SET_CREDIT_PULL_REPORT",

  GET_AGENT_PERFORMANCE_REPORT: "GET_AGENT_PERFORMANCE_REPORT",
  SET_AGENT_PERFORMANCE_REPORT: "SET_AGENT_PERFORMANCE_REPORT",

  GET_CALL_LOG_REPORT: "GET_CALL_LOG_REPORT",
  SET_CALL_LOG_REPORT: "SET_CALL_LOG_REPORT",

  GET_CUSTOMER_STATUS_REPORT: "GET_CUSTOMER_STATUS_REPORT",
  SET_CUSTOMER_STATUS_REPORT: "SET_CUSTOMER_STATUS_REPORT",

  GET_MISSED_CALL_REPORT: "GET_MISSED_CALL_REPORT",
  SET_MISSED_CALL_REPORT: "SET_MISSED_CALL_REPORT",

  GET_SMS_HISTORY_REPORT: "GET_SMS_HISTORY_REPORT",
  SET_SMS_HISTORY_REPORT: "SET_SMS_HISTORY_REPORT",

  GET_REMINDERS_REPORT: "GET_REMINDERS_REPORT",
  SET_REMINDERS_REPORT: "SET_REMINDERS_REPORT",

  GET_CSR_REALTIME_TASK_REPORT: "GET_CSR_REALTIME_TASK_REPORT",
  SET_CSR_REALTIME_TASK_REPORT: "SET_CSR_REALTIME_TASK_REPORT",

  GET_CSR_TASK_REPORT: "GET_CSR_TASK_REPORT",
  SET_CSR_TASK_REPORT: "SET_CSR_TASK_REPORT",

  GET_NSF_REPORT: "GET_NSF_REPORT",
  SET_NSF_REPORT: "SET_NSF_REPORT",

  GET_ENROLLMENT_REPORT: "GET_ENROLLMENT_REPORT",
  SET_ENROLLMENT_REPORT: "SET_ENROLLMENT_REPORT",

  GET_LEAD_ASSIGN_REPORT: "GET_LEAD_ASSIGN_REPORT",
  SET_LEAD_ASSIGN_REPORT: "SET_LEAD_ASSIGN_REPORT",

  GET_CSR_TASK_PERFORMANCE_REPORT: "GET_CSR_TASK_PERFORMANCE_REPORT",
  SET_CSR_TASK_PERFORMANCE_REPORT: "SET_CSR_TASK_PERFORMANCE_REPORT",

  GET_INBOUND_STATISTICS_REPORT: "GET_INBOUND_STATISTICS_REPORT",
  SET_INBOUND_STATISTICS_REPORT: "SET_INBOUND_STATISTICS_REPORT",

  GET_LIVE_CALL_REPORT: "GET_LIVE_CALL_REPORT",
  SET_LIVE_CALL_REPORT: "SET_LIVE_CALL_REPORT",

  GET_PENDING_CHANGES_REPORT: "GET_PENDING_CHANGES_REPORT",
  SET_PENDING_CHANGES_REPORT: "SET_PENDING_CHANGES_REPORT",

  GET_INCOMING_VM_REPORT: "GET_INCOMING_VM_REPORT",
  SET_INCOMING_VM_REPORT: "SET_INCOMING_VM_REPORT",

  GET_DOCUSIGN_ENVELOPE_HISTORY_REPORT: "GET_DOCUSIGN_ENVELOPE_HISTORY_REPORT",
  SET_DOCUSIGN_ENVELOPE_HISTORY_REPORT: "SET_DOCUSIGN_ENVELOPE_HISTORY_REPORT",

  GET_NEGOTIATOR_ASSIGN_REPORT: "GET_NEGOTIATOR_ASSIGN_REPORT",
  SET_NEGOTIATOR_ASSIGN_REPORT: "SET_NEGOTIATOR_ASSIGN_REPORT",

  GET_ENROLLMENT_STATUS_CHANGE_REPORT: "GET_ENROLLMENT_STATUS_CHANGE_REPORT",
  SET_ENROLLMENT_STATUS_CHANGE_REPORT: "SET_ENROLLMENT_STATUS_CHANGE_REPORT",

  GET_CAMPAIGN_PERFORMANCE_REPORT: "GET_CAMPAIGN_PERFORMANCE_REPORT",
  SET_CAMPAIGN_PERFORMANCE_REPORT: "SET_CAMPAIGN_PERFORMANCE_REPORT",

  GET_NEXT_GEN_REPORT: "GET_NEXT_GEN_REPORT",
  SET_NEXT_GEN_REPORT: "SET_NEXT_GEN_REPORT",

  GET_AGENT_DROP_RATE_REPORT: "GET_AGENT_DROP_RATE_REPORT",
  SET_AGENT_DROP_RATE_REPORT: "SET_AGENT_DROP_RATE_REPORT",

  GET_HOT_LEAD_AND_XCHANGE_REPORT: "GET_HOT_LEAD_AND_XCHANGE_REPORT",
  SET_HOT_LEAD_AND_XCHANGE_REPORT: "SET_HOT_LEAD_AND_XCHANGE_REPORT",

  GET_DROPPED_CUSTOMERS_REPORT: "GET_DROPPED_CUSTOMERS_REPORT",
  SET_DROPPED_CUSTOMERS_REPORT: "SET_DROPPED_CUSTOMERS_REPORT",

  GET_PAYMENTS_AND_FEES_REPORT: "GET_PAYMENTS_AND_FEES_REPORT",
  SET_PAYMENTS_AND_FEES_REPORT: "SET_PAYMENTS_AND_FEES_REPORT",

  RESET_REPORTS: "RESET_REPORTS",
  RESET_REPORTS_DATA: "RESET_REPORTS_DATA",
};
