<template>
  <div class="col-md-12">
    <ReportSummary
      :exportData="reportData"
      :reportName="'DroppedCustomersReport'"
      :recordCount="recordCount"
      @updateLoadingStatus="reportLoading = $event"
      @setPayload="setPayload"
      @clearReport="clearReport"
      @setPagination="setPagination"
      :loadingSpinner="loadingSpinner"
    />

    <div
      class="datagrid-table gcsTabsTableScroll report-wrapper"
      :style="'max-height: ' + reportsPanelHeight + 'px; overflow-y:auto;'"
    >
      <o-table
        :data="filteredDroppedCustomersReport"
        :paginated="isPaginated && filteredDroppedCustomersReport.length > 0"
        :per-page="perPage"
        :pagination-simple="isPaginationSimple"
        :pagination-position="paginationPosition"
        :sticky-header="true"
      >
        <o-table-column
          field="CustomerId"
          label="Customer Id"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CustomerIdFilter"
                placeholder=""
                v-myMask="{
                  alias: 'numeric',
                  rightAlign: false,
                }"
              />
            </div>
          </template>

          <template v-slot="props">
            <span @click="viewCustomer(props.row.CustomerId, props.row.CustomerName)"
              :class="getTagReportHyperLinkClass(props.row.CustomerId && props.row.CustomerName)">
              <strong v-if="props.row.IsSummary"
                >{{ props.row.CustomerId }}
              </strong>
              <span v-if="!props.row.IsSummary">
                {{ addPrefixToCustomerId(props.row.CustomerId) }}
              </span>
            </span>
          </template>
        </o-table-column>
        <o-table-column
          field="CustomerName"
          label="Customer Name"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CustomerNameFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.CustomerName }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.CustomerName }}
            </span>
          </template>
        </o-table-column>

        <o-table-column field="Office" label="Office" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="OfficeFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.Office }}
          </template>
        </o-table-column>

        <o-table-column
          field="SalesUser"
          label="Sales User"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="SalesUserFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.SalesUser }}
          </template>
        </o-table-column>

        <o-table-column
          field="EnrolledDate"
          label="Enrolled Date"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="EnrolledDateFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ formatShortDate(props.row.EnrolledDate) }}
          </template>
        </o-table-column>

        <o-table-column
          field="FirstClearedDraftDate"
          label="First Cleared Draft Date"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="FirstClearedDraftDateFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ formatShortDate(props.row.FirstClearedDraftDate) }}
          </template>
        </o-table-column>

        <o-table-column field="DropDate" label="Drop Date" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="DropDateFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ formatShortDate(props.row.DropDate) }}
          </template>
        </o-table-column>

        <o-table-column
          field="CancelationReason"
          label="Drop Reason"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CancelationReasonFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.CancelationReason }}
          </template>
        </o-table-column>

        <o-table-column
          field="DropReason"
          label="Drop Notes"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="DropReasonFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props"> {{ props.row.DropReason }} </template>
        </o-table-column>

        <o-table-column field="Status" label="Status" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="StatusFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">{{ props.row.Status }} </template>
        </o-table-column>

        <o-table-column
          field="PolicyGroup"
          label="Policy Group"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="PolicyGroupFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props"> {{ props.row.PolicyGroup }} </template>
        </o-table-column>

        <o-table-column field="State" label="State" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="StateFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props"> {{ props.row.State }} </template>
        </o-table-column>

        <o-table-column
          field="DebtAmountAtEnrollment"
          label="Debt Amount At Enrollment"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="DebtAmountAtEnrollmentFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.DebtAmountAtEnrollment }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatMoney(props.row.DebtAmountAtEnrollment) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field=""
          label="View Customer"
          position="centered"
          v-slot="props"
        >
          <button
            @click="viewCustomer(props.row.CustomerId, props.row.CustomerName)"
            class="btn btn-info waves-effect waves-light ml-3"
            v-if="!props.row.IsSummary"
          >
            <i class="far fa-address-card"></i>
          </button>
        </o-table-column>
      </o-table>
      <section
        v-if="filteredDroppedCustomersReport.length == 0"
        class="section"
        style="text-align: center"
      >
        <div class="content has-text-grey has-text-centered mb-4">
          <p class="noMargin">
            <i
              style="font-size: 24px; color: #939393"
              class="ri-question-line"
            ></i>
          </p>
          <p class="noMargin">Nothing here.</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import ReportSummary from "./ReportSummary.vue";
import utilitiesMixin from "@/mixins/utilitiesMixin";

export default {
  name: "DroppedCustomersReport",
  components: { ReportSummary },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      customerPrefix: null,
      reportsPanelHeight: 300,
      loadingSpinner: false,
      isPaginationSimple: false,
      isPaginated: true,
      paginationPosition: "both",
      currentPage: 1,
      perPage: 200,

      recordCount: 0,
      reportLoading: false,
      reportData: [],

      droppedCustomersReportInitSummary: {
        CustomerId: "",
        CustomerName: "",
        Office: "",
        SalesUser: "",
        EnrolledDate: "",
        FirstClearedDraftDate: "",
        DropDate: "",
        DropReason: "",
        Status: "",
        PolicyGroup: "",
        State: "",
        DebtAmountAtEnrollment: "",
        CancelationReason: "",
      },
      CustomerIdFilter: "",
      CustomerNameFilter: "",
      OfficeFilter: "",
      SalesUserFilter: "",
      EnrolledDateFilter: "",
      FirstClearedDraftDateFilter: "",
      DropDateFilter: "",
      DropReasonFilter: "",
      StatusFilter: "",
      PolicyGroupFilter: "",
      StateFilter: "",
      DebtAmountAtEnrollmentFilter: "",
      CancelationReasonFilter: "",
    };
  },
  computed: mapState({
    droppedCustomersList: (state) => state.reports.droppedCustomersList,

    filteredDroppedCustomersReport() {
      let rtn = [];

      let tmpList =
        this.droppedCustomersList.length > 0
          ? this.droppedCustomersList.filter((item) => {
              return (
                this.filterString(item.CustomerId, this.CustomerIdFilter) &&
                this.filterString(item.CustomerName, this.CustomerNameFilter) &&
                this.filterString(item.Office, this.OfficeFilter) &&
                this.filterString(
                  item.CancelationReason,
                  this.CancelationReasonFilter
                ) &&
                this.filterString(item.SalesUser, this.SalesUserFilter) &&
                this.filterString(item.SalesUser, this.SalesUserFilter) &&
                new Date(item.EnrolledDate)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .indexOf(this.EnrolledDateFilter) > -1 &&
                new Date(item.FirstClearedDraftDate)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .indexOf(this.FirstClearedDraftDateFilter) > -1 &&
                new Date(item.DropDate)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .indexOf(this.DropDateFilter) > -1 &&
                this.filterString(item.DropReason, this.DropReasonFilter) &&
                this.filterString(item.Status, this.StatusFilter) &&
                this.filterString(item.PolicyGroup, this.PolicyGroupFilter) &&
                this.filterString(item.State, this.StateFilter) &&
                this.filterString(
                  item.DebtAmountAtEnrollment,
                  this.DebtAmountAtEnrollmentFilter
                )
              );
            })
          : [];

      if (tmpList.length == 0) {
        this.reportData = [];
        return rtn;
      }
      let totalCustomer = tmpList.length.toString();

      this.droppedCustomersReportInitSummary.CustomerId =
        "Total: " + totalCustomer;
      this.recordCount = tmpList.length;
      this.droppedCustomersReportInitSummary.IsSummary = true;

      rtn.push(...tmpList);
      rtn.push(this.droppedCustomersReportInitSummary);
      this.reportData = rtn;

      return rtn;
    },
  }),
  async mounted() {
    this.reportsPanelHeight = this.setReportGridHeight(
      window.innerHeight,
      document.getElementById("reportsSummary")
    );
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

    if (
      this.userInfo != null &&
      this.userInfo.customerIdPrefix != null &&
      this.userInfo.customerIdPrefix.length > 0
    ) {
      this.customerPrefix = this.userInfo.customerIdPrefix;
    }

    await this.sleep(1000);
    this.fixStickyHeaders();
  },
  methods: {
    addPrefixToCustomerId(customerId) {
      let rtn = customerId.toString();
      if (this.customerPrefix != null) {
        rtn = this.customerPrefix + rtn;
      }
      return rtn;
    },
    setPagination(data) {
      this.isPaginated = data;
    },
    async setPayload(value) {
      this.loadingSpinner = true;
      await this.$store.dispatch(types.GET_DROPPED_CUSTOMERS_REPORT, value);
      this.loadingSpinner = false;
      await this.sleep(500);
      this.fixStickyHeaders();
    },
    clearReport() {
      this.$store.dispatch(types.RESET_REPORTS);
      this.reportData = [];
    },
    async viewCustomer(id, name) {
      this.$Emitter.emit("viewCustomer", {
        customerId: id,
        customerName: name,
      });
    },
  },
};
</script>

<style scoped></style>
