<template>
  <div
    class="col-md-12 newPanelWrapper noMargin"
    style="background-color: #ffffff; z-index: 1000"
  >
    <div style="float: right" v-on:click.prevent.stop="closePanel">
      <i class="fas fa-window-close closeButtonBulk"></i>
    </div>
    <div
      class="col-md-12 row"
      v-if="
        script.length == 0 &&
        vmScript.length == 0 &&
        policyGroupScripts.length == 0
      "
    >
      <div class="col-md-12">
        <label class="generalLabel">There is no script.</label>
      </div>
    </div>
    <perfect-scrollbar
      style="width: 100%; max-height: 400px; overflow-x: hidden !important"
    >
      <div class="col-md-12 row">
        <div class="col-md-6">
          <label class="generalLabel">Script (TTS)</label>
          <i
            v-if="script && script.length > 0"
            class="fas fa-play ml-2"
            style="color: green; cursor: pointer"
            @click="playRecord(script)"
          ></i>

          <div class="textBox" v-if="script && script.length > 0">
            <label class="generalLabel"> {{ script }}</label>
          </div>
        </div>

        <div class="col-md-6">
          <label class="generalLabel">VM Script (TTS)</label>

          <i
            v-if="vmScript && vmScript.length > 0"
            class="fas fa-play ml-2"
            style="color: green; cursor: pointer"
            @click="playRecord(vmScript)"
          ></i>

          <div class="textBox" v-if="vmScript && vmScript.length > 0">
            <label class="generalLabel"> {{ vmScript }} </label>
          </div>
        </div>
      </div>
      <div
        class="col-md-12 row"
        v-for="item in policyGroupScripts"
        :key="item.PolicyGroupId"
      >
        <div class="col-md-12 mt-4">
          <label class="generalLabel"
            >Policy Group: {{ item.PolicyGroupId }} -
            {{ getPolicyGroupName(item.PolicyGroupId) }}</label
          >
        </div>
        <div class="col-md-6">
          <label class="generalLabel">Script (TTS)</label>
          <i
            class="fas fa-play ml-2"
            v-if="item.Script && item.Script.length > 0"
            style="color: green; cursor: pointer"
            @click="playRecord(item.Script)"
          ></i>

          <div class="textBox" v-if="item.Script && item.Script.length > 0">
            <label class="generalLabel"> {{ item.Script }}</label>
          </div>
        </div>

        <div class="col-md-6">
          <label class="generalLabel">VM Script (TTS)</label>

          <i
            v-if="item.VmScript && item.VmScript.length > 0"
            class="fas fa-play ml-2"
            style="color: green; cursor: pointer"
            @click="playRecord(item.VmScript)"
          ></i>

          <div class="textBox" v-if="item.VmScript && item.VmScript.length > 0">
            <label class="generalLabel"> {{ item.VmScript }} </label>
          </div>
        </div>
      </div>
    </perfect-scrollbar>
    <div class="col-md-12 mt-2">
      <audio v-if="soundUrl != ''" autoplay="autoplay" controls preload>
        <source :src="soundUrl" />
      </audio>
    </div>
  </div>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import globalTypes from "@/store/types";
import utilitiesMixin from "@/mixins/utilitiesMixin";

export default {
  name: "ScriptPopUp",
  mixins: [utilitiesMixin],
  props: {
    script: {
      Type: String,
      Defult: null,
    },
    vmScript: {
      Type: String,
      Defult: null,
    },
    policyGroupScripts: {
      Type: Array,
      Defult: [],
    },
  },
  components: {},
  data() {
    return {
      soundUrl: "",
      oldSpeechText: "",
      userId: 0,
      scriptSound: "",
    };
  },
  async mounted() {
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

    if (userInfo) {
      this.userId = userInfo.userId;
    }

    await this.$store.dispatch(globalTypes.GET_ALL_POLICY_GROUPS);
  },
  computed: mapState({ policyGroups: (state) => state.globals.policyGroups }),
  methods: {
    getPolicyGroupName(id) {
      let rtn = "";
      let indx = this.policyGroups.findIndex((x) => x.PolicyGroupId == id);
      if (indx > -1) {
        rtn = this.policyGroups[indx].Name;
      }
      return rtn;
    },
    async playRecord(script) {
      if (this.oldSpeechText != script) {
        this.oldSpeechText = script;
        let err, result;
        [err, result] = await this.$store.dispatch(types.GET_SPEECH_TEXT, {
          UserId: this.userId,
          SpeechText: script,
          Type: "script",
        });
        if (result.Data && result.Data.SpeechUrl) {
          this.scriptSound =
            result.Data.SpeechUrl + "?v=" + new Date().getTime();
          this.soundUrl = "";
          await this.sleep(500);
          this.soundUrl = this.scriptSound;
        }
      }
    },

    closePanel() {
      this.$emit("closeScriptPopUp");
    },
  },
};
</script>

<style scoped>
.textBox {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 5px;
  max-height: 200px;
  padding: 8px;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
