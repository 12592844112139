import types from "./types";
import api from "@/services/client/customerApi";
import downloadApi from "@/services/fileServices/downloadService";

import deepCopy from "@/helpers/deepCopy";
import to from "@/helpers/to";

export default {
  state: {
    list: {
      Customers: [],
    },
    priority: {
      Categories: [],
    },
  },
  actions: {
    GET_XCHANGE_CUSTOMERS: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.getXChangeCustomers(payload));
      return [err, result];
    },
    ASSIGN_XCHANGE_CUSTOMER: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.assignXChangeCustomer(payload));
      return [err, result];
    },
    EXPORT_GRID: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(downloadApi.downloadCustomerGrid(payload));
      return [err, result];
    },
    GET_CUSTOMERS: async ({ commit }, payload) => {
      let result = await api.getCustomers(payload);
      if (payload.View === 2) {
        let temp = deepCopy(result.data.Data);
        let obj = [];

        temp.Customers.map((item) => {
          let index = obj.findIndex((it) => it.StatusName === item.StatusName);
          if (index > -1) {
            obj[index].Customers.push(item);
          } else {
            obj.push({
              StatusColor: item.StatusColor,
              StatusName: item.StatusName,
              StatusPriority: item.StatusPriority,
              StatusPriorityText: item.StatusPriorityText,
              Toggle: true,
              Customers: [],
            });
            obj[obj.length - 1].Customers.push(item);
          }
        });

        commit(types.SET_PRIORITY, obj);
      } else {
        commit(types.SET_CUSTOMERS, result.data);

        return result;
      }
    },
    DELETE_CUSTOMER: async ({ commit }, payload) => {
      let result = await to(api.deleteCustomer(payload));
      return result;
    },
    GET5_REMINDERS: async ({ commit }, customerId) => {
      let err, result;

      [err, result] = await to(api.getLast5Reminders(customerId));
      if (result) {
        return result;
      }
    },
    GET5_DISPOS: async ({ commit }, customerId) => {
      let err, result;

      [err, result] = await to(api.getLast5Dispos(customerId));
      if (result) {
        return result;
      }
    },
    BULK_UPDATE_AGENT: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.updateBulkAgent(payload));

      return [err, result];
    },
    BULK_UPDATE_STATUS: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.updateBulkStatus(payload));

      return [err, result];
    },
  },
  mutations: {
    SET_CUSTOMERS: (state, payload) => {
      state.list = Object.assign({}, payload.Data);
    },
    SET_PRIORITY: (state, payload) => {
      state.priority = Object.assign({}, payload);
    },
  },
};
