<template>
  <div class="col-md-12">
    <ReportSummary
      :exportData="reportData"
      :reportName="'SnfAttorneyActivityReport'"
      :recordCount="recordCount"
      @updateLoadingStatus="reportLoading = $event"
      @refreshReport="refreshReport"
      @clearReport="clearReport"
      @setPagination="setPagination"
      :loadingSpinner="loadingSpinner"
    />

    <div
      class="datagrid-table gcsTabsTableScroll report-wrapper"
      :style="'max-height: ' + reportsPanelHeight + 'px; overflow-y:auto;'"
    >
      <o-table
        :data="filteredSnfAttorneyActivityReport"
        :paginated="isPaginated && filteredSnfAttorneyActivityReport.length > 0"
        :per-page="perPage"
        :pagination-simple="isPaginationSimple"
        :pagination-position="paginationPosition"
        :sticky-header="true"
      >
        <o-table-column
          field="CustomerId"
          label="Customer Id"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CustomerIdFilter"
                placeholder=""
                v-myMask="{
                  alias: 'numeric',
                  rightAlign: false,
                }"
              />
            </div>
          </template>

          <template v-slot="props">
            <span @click="viewCustomer(props.row.CustomerId, props.row.CustomerName)"
              :class="getTagReportHyperLinkClass(props.row.CustomerId && props.row.CustomerName)">
              <strong v-if="props.row.IsSummary"
                >{{ props.row.CustomerId }}
              </strong>
              <span v-if="!props.row.IsSummary">
                {{ addPrefixToCustomerId(props.row.CustomerId) }}
              </span>
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="AttorneyName"
          label="Attorney"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="AttorneyNameFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.AttorneyName }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.AttorneyName }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="CustomerName"
          label="CustomerName"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CustomerNameFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.CustomerName }}
          </template>
        </o-table-column>

        <o-table-column field="Office" label="Office" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="OfficeFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.Office }}
          </template>
        </o-table-column>

        <o-table-column
          field="DispoToday"
          label="Dispo Today"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="DispoTodayFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            <strong v-if="props.row.IsSummary">
              {{ props.row.DispoToday }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.DispoToday }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="DispoYesterday"
          label="Dispo Yesterday"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="DispoYesterdayFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            <strong v-if="props.row.IsSummary">
              {{ props.row.DispoYesterday }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.DispoYesterday }}
            </span>
          </template>
        </o-table-column>

        <o-table-column field="" label="Dispositions" v-slot="props">
          <div class="col-md-12">
            <button
              v-if="!props.row.IsSummary"
              type="button"
              title="Dispositions"
              class="btn btn-link waves-effect"
              style="background-color: #8a9197"
              v-on:click.prevent.stop="
                () =>
                  openDispositionPopup(props.row.CustomerId, props.row.StatusId)
              "
              @mouseenter="last5DispositionHover(props.row.CustomerId, $event)"
              @mouseleave="last5Leave()"
            >
              <i class="ri-sound-module-fill"></i>
            </button>
          </div>
        </o-table-column>

        <o-table-column
          field=""
          label="View Customer"
          position="centered"
          v-slot="props"
        >
          <button
            @click="viewCustomer(props.row.CustomerId, props.row.CustomerName)"
            class="btn btn-info waves-effect waves-light ml-3"
            v-if="!props.row.IsSummary"
          >
            <i class="far fa-address-card"></i>
          </button>
        </o-table-column>
      </o-table>
      <section
        v-if="filteredSnfAttorneyActivityReport.length == 0"
        class="section"
        style="text-align: center"
      >
        <div class="content has-text-grey has-text-centered mb-4">
          <p class="noMargin">
            <i
              style="font-size: 24px; color: #939393"
              class="ri-question-line"
            ></i>
          </p>
          <p class="noMargin">Nothing here.</p>
        </div>
      </section>
    </div>
    <div v-if="dispositionCustomerId > 0" class="backgroundLock">
      <div
        style="position: relative; margin: 10% auto"
        class="col-xl-5 col-lg-8 col-md-12 col-sm-12"
      >
        <div class="reminderClose" @click="closeDispositionPopup()">
          <i class="fas fa-window-close"></i>
        </div>
        <DispositionPanel
          :customerId="dispositionCustomerId"
          :statusId="dispositionStatusId"
          :fromEdit="0"
        ></DispositionPanel>
      </div>
    </div>
    <div
      v-if="hoveredDispositionId > 0"
      class="hoverPanel"
      :style="
        last5DispoHoverPanelPosition +
        ' height:' +
        (last5DispoHoverPanelHeight + 2) +
        'px;'
      "
    >
      <Last5Dispositions
        :panelHeight="last5DispoHoverPanelHeight"
        :customerId="hoveredDispositionId"
      ></Last5Dispositions>
    </div>
  </div>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import ReportSummary from "./ReportSummary.vue";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import DispositionPanel from "@/views/agent/Disposition/DispositionPanel";
import Last5Dispositions from "@/components/Last5Dispositions";

export default {
  name: "SnfAttorneyActivityReport",
  components: { ReportSummary, DispositionPanel, Last5Dispositions },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      hoveredDate: new Date(),
      dispositionCustomerId: 0,
      dispositionStatusId: 0,
      windowHeight: 0,
      hoveredDispositionId: 0,
      last5DispoHoverPanelPosition: "top:45px; right:45px;",
      last5DispoHoverPanelHeight: 320,
      customerPrefix: null,
      reportsPanelHeight: 300,
      loadingSpinner: false,
      isPaginationSimple: false,
      isPaginated: true,
      paginationPosition: "both",
      currentPage: 1,
      perPage: 200,
      recordCount: 0,
      reportLoading: false,
      reportData: [],

      snfAttorneyActivityReportInitSummary: {
        CustomerId: "",
        CustomerName: "",
        AttorneyName: "",
        Office: "",
        DispoToday: "",
        DispoYesterday: "",
      },
      CustomerIdFilter: "",
      EmailFilter: "",
      CustomerNameFilter: "",
      AttorneyNameFilter: "",
      OfficeFilter: "",
      DispoTodayFilter: "",
      DispoYesterdayFilter: "",
    };
  },
  computed: mapState({
    snfAttorneyActivityList: (state) => state.reports.snfAttorneyActivityList,

    filteredSnfAttorneyActivityReport() {
      let rtn = [];

      let tmpList =
        this.snfAttorneyActivityList.length > 0
          ? this.snfAttorneyActivityList.filter((item) => {
              return (
                this.filterString(item.CustomerId, this.CustomerIdFilter) &&
                this.filterString(item.CustomerName, this.CustomerNameFilter) &&
                this.filterString(item.AttorneyName, this.AttorneyNameFilter) &&
                this.filterString(item.Office, this.OfficeFilter) &&
                this.filterString(item.DispoToday, this.DispoTodayFilter) &&
                this.filterString(
                  item.DispoYesterday,
                  this.DispoYesterdayFilter
                )
              );
            })
          : [];

      if (tmpList.length == 0) {
        this.reportData = [];
        return rtn;
      }
      let totalCustomer = tmpList.length.toString();

      this.snfAttorneyActivityReportInitSummary.CustomerId =
        "Total: " + totalCustomer;
      this.recordCount = tmpList.length;
      this.snfAttorneyActivityReportInitSummary.IsSummary = true;

      rtn.push(...tmpList);
      rtn.push(this.snfAttorneyActivityReportInitSummary);
      this.reportData = rtn;

      return rtn;
    },
  }),
  async mounted() {
    this.reportsPanelHeight = this.setReportGridHeight(
      window.innerHeight,
      document.getElementById("reportsSummary")
    );
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

    if (
      this.userInfo != null &&
      this.userInfo.customerIdPrefix != null &&
      this.userInfo.customerIdPrefix.length > 0
    ) {
      this.customerPrefix = this.userInfo.customerIdPrefix;
    }
    window.addEventListener("resize", this.onResize);
    this.onResize();

    await this.sleep(1000);
    this.fixStickyHeaders();
  },
  beforeMount() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    closeDispositionPopup() {
      this.dispositionCustomerId = 0;
      this.dispositionStatusId = 0;
    },
    openDispositionPopup(customerId, statusId) {
      this.dispositionCustomerId = customerId;
      this.dispositionStatusId = statusId;
    },
    async last5DispositionHover(itemId, event) {
      if (this.hoveredDispositionId == 0) {
        let date = new Date().getTime();

        let positioning =
          this.windowHeight - event.clientY > this.last5DispoHoverPanelHeight
            ? "top:" + event.clientY + "px; "
            : "bottom:" + (this.windowHeight - event.clientY) + "px;";

        this.hoveredDispositionId = 0;
        this.last5DispoHoverPanelPosition = positioning + " right:35vh;";
        if (parseInt(date - this.hoveredDate) > 500) {
          this.hoveredDispositionId = itemId;
          this.hoveredDate = new Date().getTime();
        }
      }
    },
    onResize() {
      this.windowHeight = window.innerHeight;
    },
    last5Leave() {
      this.hoveredDispositionId = 0;
      this.last5Dispositions = [];
    },

    addPrefixToCustomerId(customerId) {
      let rtn = customerId.toString();
      if (this.customerPrefix != null) {
        rtn = this.customerPrefix + rtn;
      }
      return rtn;
    },
    setPagination(data) {
      this.isPaginated = data;
    },
    async refreshReport(value) {
      this.loadingSpinner = true;
      await this.$store.dispatch(types.GET_SNF_ATTORNEY_ACTIVITY_REPORT, value);
      this.loadingSpinner = false;
      await this.sleep(500);
      this.fixStickyHeaders();
    },
    clearReport() {
      this.$store.dispatch(types.RESET_REPORTS);
      this.reportData = [];
    },
    async viewCustomer(id, name) {
      this.$Emitter.emit("viewCustomer", {
        customerId: id,
        customerName: name,
      });
    },
  },
};
</script>

<style scoped>
.hoverPanel {
  width: 480px;
  position: fixed;
  z-index: 999;
  background: #ffffff;
  border: 1px solid #3bafda;
}

.reminderClose {
  position: absolute;
  top: -5px;
  right: 15px;
  z-index: 1000;
  font-size: 30px;
  cursor: pointer;
}
</style>
