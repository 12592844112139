import types from "./types";
import api from "@/services/admin/api";
import to from "@/helpers/to";

export default {
  state: {
    SmsBrandsList: [],
  },
  actions: {
    GET_SMS_BRANDS: async ({ commit }) => {
      let err, result;

      [err, result] = await to(api.getSmsBrands());
      if (result) {
        commit(types.SET_SMS_BRANDS, result.Data);
      }
    },
  },
  mutations: {
    SET_SMS_BRANDS: (state, payload) => {
      state.SmsBrandsList = payload;
    },
  },
};
