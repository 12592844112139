<template>
  <div
    class="col-md-12 newPanelWrapper noMargin"
    style="overflow: auto; max-height: 75vh"
  >
    <div v-if="saving" class="loading">
      <div class="text-center" style="margin-top: 250px">
        <i class="spinner-grow text-info m-2"></i>
      </div>
    </div>
    <div>
      <div>
        <label style="position: absolute; top: 5px; left: 15px; font-size: 15px"
          >Add Documents</label
        >
      </div>
      <div class="closeButtonAddNew" v-on:click.prevent.stop="closePanel">
        <i class="fas fa-window-close"></i>
      </div>
      <hr />
    </div>

    <perfect-scrollbar style="width: 100%; height: auto; max-height: 650px">
      <div style="margin-right: 15px" class="panelContent" v-if="newData">
        <Placeholders
          :typeId="0"
          :availablePlaceHolders="availablePlaceholder"
          @setPlaceholderVisibility="isPlaceholdersOpen = $event"
          @setPlaceholderContent="setPlaceholder($event)"
          v-if="isPlaceholdersOpen"
        ></Placeholders>
        <div class="form-group row">
          <div class="col-md-12">
            <label for="txtdocumentName" class="form-label"
              >Document Name: *</label
            >
            <input
              autocomplete="off"
              id="txtdocumentName"
              class="form-control"
              type="text"
              placeholder="Enter Document Name"
              v-model="newData.DocumentName"
              :class="v$.newData.DocumentName.$error ? 'has-error' : ''"
              @blur="v$.newData.DocumentName.$touch"
            />
          </div>

          <div class="col-md-6 mt-2">
            <label style="position: absolute" for="officesddl">Office: *</label>
            <div
              class="col-md-12 noPadding"
              style="display: flex; justify-content: flex-end"
            >
              <span
                @click="addAllOffice()"
                class="badge badge-primary"
                style="width: 20px; cursor: pointer"
              >
                <span style="font-weight: 600">+</span>
              </span>

              <span
                @click="clearOffice()"
                class="badge badge-light ml-1"
                style="width: 20px; cursor: pointer"
              >
                <span class="" style="font-weight: 600">-</span>
              </span>
            </div>

            <VueMultiselect
              id="officesddl"
              v-model="newData.Office"
              :options="filteredOffices"
              :preselect-first="true"
              :multiple="true"
              :close-on-select="false"
              placeholder="Please Select"
              label="Name"
              track-by="Id"
              :class="v$.newData.Office.$error ? 'has-error' : ''"
              @blur="v$.newData.Office.$touch"
            />
          </div>

          <div class="col-md-6 mt-2">
            <label style="position: absolute" for="stateddl">State: *</label>

            <div
              class="col-md-12 noPadding"
              style="display: flex; justify-content: flex-end"
            >
              <span
                @click="addAllState()"
                class="badge badge-primary"
                :class="
                  isAddNewDoc == false
                    ? 'badge badge-primary'
                    : 'badge badge-danger'
                "
                style="width: 20px; cursor: pointer"
              >
                <span style="font-weight: 600">{{
                  isAddNewDoc == false ? "+" : "x"
                }}</span>
              </span>

              <div
                v-if="isAddNewDoc"
                style="
                  background-color: white;
                  border: 1px solid rgb(221, 221, 221);
                  border-radius: 5px;
                  position: absolute;
                  z-index: 10;
                  top: 18px;
                  right: 25px;
                  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
                "
              >
                <perfect-scrollbar style="max-height: 275px">
                  <table class="datagrid-table">
                    <tbody>
                      <tr v-for="item in companySettingsList" :key="item.Id">
                        <td
                          style="
                            width: 10px !important;
                            padding: 0 0 0 8px !important;
                          "
                        >
                          <input
                            type="checkbox"
                            class="checkbox-input generalCheckBox"
                            :checked="selectedCheckboxes.includes(item.Id)"
                            @change="toggleCheckbox(item.Id)"
                            style="
                              border-radius: 4px !important;
                              border: solid 1px #d1d1d1 !important;
                              float: left;
                              margin-top: 2px;
                            "
                          />
                        </td>
                        <td style="cursor: pointer">
                          {{ item.Name }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </perfect-scrollbar>

                <button
                  @click="addDocuments"
                  class="col-md-12 btn btn-sm btn-success noMargin"
                >
                  Add
                </button>
              </div>

              <span
                @click="clearState()"
                class="badge badge-light ml-1"
                style="width: 20px; cursor: pointer"
              >
                <span class="" style="font-weight: 600">-</span>
              </span>
            </div>
            <VueMultiselect
              id="stateddl"
              v-model="newData.State"
              :options="states"
              :multiple="true"
              :close-on-select="false"
              placeholder="Please Select"
              label="Name"
              track-by="Id"
              :class="v$.newData.State.$error ? 'has-error' : ''"
              @blur="v$.newData.State.$touch"
            />
          </div>

          <div class="col-md-6 mt-2">
            <label style="position: absolute" for="policyGroups"
              >Policy Groups: *</label
            >

            <div
              class="col-md-12 noPadding"
              style="display: flex; justify-content: flex-end"
            >
              <span
                @click="addAllPolicyGroups()"
                class="badge badge-primary"
                style="width: 20px; cursor: pointer"
              >
                <span style="font-weight: 600">+</span>
              </span>

              <span
                @click="clearPolicyGroups()"
                class="badge badge-light ml-1"
                style="width: 20px; cursor: pointer"
              >
                <span class="" style="font-weight: 600">-</span>
              </span>
            </div>

            <VueMultiselect
              id="policyGroups"
              v-model="newData.PolicyGroupIds"
              :options="companyPolicyGroups"
              :multiple="true"
              :close-on-select="false"
              placeholder="Please Select"
              label="Name"
              track-by="PolicyGroupId"
              :class="v$.newData.PolicyGroupIds.$error ? 'has-error' : ''"
              @blur="v$.newData.PolicyGroupIds.$touch"
            />
          </div>

          <div class="col-md-6 mt-2">
            <label for="txtEmailSubject">Email Subject: *</label>
            <input
              autocomplete="off"
              id="txtdocumentName"
              v-model="newData.EmailSubject"
              class="form-control"
              type="text"
              placeholder="Email Subject"
              :class="v$.newData.EmailSubject.$error ? 'has-error' : ''"
              @blur="v$.newData.EmailSubject.$touch"
            />
          </div>

          <div class="col-md-12 mt-2">
            <label for="templateTypeddl">Docusign Template Type: *</label>
            <div class="col-md-6 noPadding">
              <select
                id="templateTypeddl"
                v-model="newData.DocusignTemplateType.Id"
                class="form-control"
                :class="
                  v$.newData.DocusignTemplateType.$error ? 'has-error' : ''
                "
                @blur="v$.newData.DocusignTemplateType.$touch"
              >
                <option value="0">Select</option>
                <option
                  v-for="item in activeTemplateTypeList"
                  :key="item.Id"
                  :value="item.Id"
                >
                  {{ item.Name }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <label for="txtSelectFile">Document: (.docx file only !) *</label>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-xl-3 col-lg-4 col-md-12 col-xs-12">
            <input
              autocomplete="off"
              type="file"
              ref="uploader"
              style="display: none"
              accept="docx"
              @click="handleFileClick"
              @change="handleFileChange"
            />
            <button
              id="txtSelectFile"
              type="button"
              class="btn btn-info waves-effect waves-light"
              style="width: 100%"
              v-on:click.prevent.stop="$refs.uploader.click()"
            >
              Select File
            </button>
          </div>
          <div
            class="col-xl-6 col-lg-4 col-md-12 col-xs-12"
            v-if="getDownloadableDocumentName() != ''"
          >
            <label
              for="title"
              class="btn btn-outline-success"
              id="uploaded"
              @click="() => handleDownloadDocument()"
              style="cursor: pointer !important"
              >{{ getDownloadableDocumentName() }}
              <i class="fas fa-download"></i
            ></label>
          </div>
        </div>

        <div class="form-group row mt-4">
          <div class="col-xl-3 col-lg-4 col-md-6 col-xs-12">
            <button
              type="button"
              title="manage"
              id="pholder1btn-ddm"
              @click="openPlaceholders()"
              class="btn btn-primary fas fa-hashtag is-small cursor-pointer text-light rounded"
            ></button>
          </div>

          <div class="col-md-12">
            <button
              style="width: 100%"
              class="btn btn-success form-control"
              :disabled="v$.$invalid || uploadData == null || saving"
              @click="() => handleSave()"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
import useValidate from "@vuelidate/core";
import { required } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import _orderby from "lodash";
import types from "./types";
import deepCopy from "@/helpers/deepCopy";
import Placeholders from "@/components/Placeholders/Placeholders";
import globalTypes from "@/store/types";
import VueMultiselect from "vue-multiselect";
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";

export default {
  name: "DocusignDocumentAddNewPopup",
  components: {
    Placeholders,
    VueMultiselect,
  },
  props: {
    document: {
      type: Object,
      default: () => null,
    },
  },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      isAddNewDoc: false,
      saving: false,
      isEditing: false,
      v$: useValidate(),
      file: null,
      validExtensions: ["docx"],
      uploadData: null,
      newData: null,
      isPlaceholdersOpen: false,
      hasAccessOtherOffices: false,
      userOfficeId: 0,
      isSelected: false,
      selectedCheckboxes: [],
    };
  },
  computed: mapState({
    states: (state) => state.globals.states,
    templateTypeList: (state) => state.docusignManagement.templateTypeList,
    companySettingsList: (state) =>
      state.docusignManagement.companySettingsList,
    globalSettingList: (state) => state.docusignManagement.globalSettingList,
    offices: (state) => state.globals.offices,
    policyGroups: (state) => state.globals.policyGroups,

    companyPolicyGroups() {
      return this.policyGroups.map((item) => {
        return {
          PolicyGroupId: +item.PolicyGroupId,
          Name: item.PolicyGroupId + " - " + item.Name,
        };
      });
    },
    filteredOffices() {
      return _orderby.orderBy(
        this.offices.filter((x) =>
          this.hasAccessOtherOffices ? x : x.Id == this.userOfficeId
        )
      );
    },
    availablePlaceholder: (state) =>
      state.docusignManagement.availablePlaceholder,
    activeTemplateTypeList() {
      return this.templateTypeList.filter((item) => item.IsActive);
    },
  }),
  async mounted() {
    await Promise.all([
      this.$store.dispatch(types.GET_COMPANY_SETTINGS_LIST),
      this.$store.dispatch(types.GET_DOCUSIGN_PLACEHOLDER),
      this.$store.dispatch(types.GET_GLOBAL_SETTINGS_LIST),
    ]);

    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo != null && userInfo.moduleFunctions != null) {
      this.userOfficeId = userInfo.officeId;
    }
    this.hasAccessOtherOffices = this.canSeeAllOffices();

    if (this.document) {
      this.newData = deepCopy(this.document);
    } else {
      this.newData = {
        DocumentName: "",
        Office: [],
        State: [],
        EmailSubject: "",
        DocusignTemplateType: { Id: 0, Name: "Select" },
        PolicyGroupIds: [],
      };
    }
  },
  methods: {
    selectDocument(item) {
      let selectedSettingId = item;
      let selectedSetting = this.companySettingsList.find(
        (setting) => setting.Id === selectedSettingId
      );
      let newStates = [];
      if (selectedSetting) {
        newStates = selectedSetting.StateList.map((state) => ({
          Name: state.Name,
          Id: state.Id,
          ShortCode: state.ShortCode,
        }));
      }
      let updatedStates = [...this.newData.State, ...newStates];
      let uniqueStates = updatedStates.filter(
        (state, index, self) =>
          index === self.findIndex((s) => s.Id === state.Id)
      );
      this.newData.State = uniqueStates;
    },

    toggleCheckbox(id) {
      if (this.selectedCheckboxes.includes(id)) {
        this.selectedCheckboxes = this.selectedCheckboxes.filter(
          (checkboxId) => checkboxId !== id
        );
      } else {
        this.selectedCheckboxes.push(id);
      }
    },

    addDocuments() {
      let selectedIds = this.selectedCheckboxes;
      selectedIds.forEach((id) => {
        this.selectDocument(id);
      });
    },

    addAllPolicyGroups() {
      this.newData.PolicyGroupIds = this.policyGroups.slice();
    },
    clearPolicyGroups() {
      this.newData.PolicyGroupIds = [];
    },
    addAllOffice() {
      this.newData.Office = this.offices.slice();
    },
    clearOffice() {
      this.newData.Office = [];
    },
    addAllState() {
      this.isAddNewDoc = !this.isAddNewDoc;
      // this.newData.State = this.states.slice();
    },
    clearState() {
      this.newData.State = [];
    },

    closePanel() {
      this.$emit("closeAddPopup");
    },
    setPlaceholder(value) {
      navigator.clipboard.writeText(value);
    },
    getDownloadableDocumentName(documentName) {
      let rtn = "";
      if (this.uploadData) {
        rtn = this.uploadData.Name;
      } else if (documentName) {
        rtn = this.cropFileNames(documentName);
      }
      return rtn;
    },

    async handleSave() {
      this.saving = true;
      let dataToSave;

      //insert
      dataToSave = {
        OfficeIds: this.newData.Office.map((x) => x.Id),
        DocusignTemplateTypeId: this.newData.DocusignTemplateType.Id,
        StateIds: this.newData.State.map((x) => x.Id),
        DocumentName: this.getFileNameWithoutExt(this.uploadData.FullName),
        EmailSubject: this.newData.EmailSubject,
        Extension: this.uploadData.Extension,
        DocumentData: this.uploadData.DocumentData,
        PolicyGroupIds: this.newData.PolicyGroupIds.map((x) => x.PolicyGroupId),
      };

      let err, result;

      [err, result] = await this.$store.dispatch(
        types.POST_DOCUSIGN_TEMPLATE,
        dataToSave
      );

      if (result) {
        this.$swal("Success!", result.Message, "success");
        this.uploadData = null;
        this.$emit("newDocumentInserted");
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }

      this.saving = false;
    },
    handleFileClick() {
      this.$refs.uploader.value = "";
      const input = this.$refs.uploader;
      input.type = "file";
    },
    async handleFileChange(e) {
      if (e.target.files && e.target.files.length > 0) {
        const file = e.target.files[0];
        let fileExtension = "";
        if (file) {
          fileExtension = file.name.split(".").pop();

          if (fileExtension) {
            if (!this.validExtensions.includes(fileExtension.toLowerCase())) {
              this.$swal.fire(
                "Warning!",
                "File type is not valid. Only docx files are allowed.",
                "warning"
              );
              e.preventDefault();
              return;
            }
          }

          if (!this.isValidFileSize(file.size, 20)) {
            this.$swal(
              "Warning!",
              "Maximum allowed file size is 20 MB for document. Please resize your file.",
              "warning"
            );
            return;
          }

          let url = URL.createObjectURL(file);

          const toBase64 = (file) =>
            new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => resolve(reader.result);
              reader.onerror = (error) => reject(error);
            });
          this.showUploadButton = true;

          let data = await toBase64(file);
          data = data.replace(
            "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,",
            ""
          );
          data = data.replace("data:application/pdf;base64,", "");
          this.uploadData = {
            Type: "insert",
            Name: this.cropFileNames(file.name),
            FullName: file.name,
            Extension: "." + fileExtension,
            DocumentData: data,
          };
        }
      }
    },
    saveByteArray(name, byte) {
      let blob = new Blob([byte], { type: "application/docx" });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      let fileName = name;
      link.download = fileName;
      link.click();
    },
    isValidFileSize(size, limitMb) {
      let rtn = true;
      if (size > 1024 * 1024 * limitMb) {
        rtn = false;
      }

      return rtn;
    },
    openPlaceholders() {
      this.isPlaceholdersOpen = true;
    },
    async handleDownloadDocument(documentName) {
      let fileExtension = ".docx";
      if (this.uploadData) {
        const linkSource = `data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,${this.uploadData.DocumentData}`;
        const downloadLink = document.createElement("a");
        let fileName =
          this.getFileNameWithoutExt(this.uploadData.FullName) + ".docx";

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      } else {
        if (this.isEditing) {
          let err, result;

          [err, result] = await this.$store.dispatch(
            types.DOWNLOAD_DOCUSIGN_TEMPLATE,
            this.selectedRow.Id
          );

          if (result) {
            this.saveByteArray(documentName + ".docx", result);
          }
        }
      }
    },
  },

  validations() {
    return {
      newData: {
        DocumentName: { required },
        Office: {
          atLeastOneOffice(Office) {
            return Office.length > 0;
          },
        },
        State: {
          atLeastOneState(State) {
            return State.length > 0;
          },
        },
        PolicyGroupIds: {
          atLeastOnePolicyGroupId(PolicyGroupIds) {
            return PolicyGroupIds.length > 0;
          },
        },
        EmailSubject: { required },
        DocusignTemplateType: {
          hasValue(templateType) {
            return templateType.Id > 0;
          },
        },
      },
    };
  },
};
</script>

<style>
.closeButtonAddNew {
  position: absolute;
  top: -5px;
  right: 15px;
  font-size: 30px;
  cursor: pointer;
}
</style>
