<template>
  <div class="row admin-page officePage">
    <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 pt-2">
      <button
        v-if="this.checkAuth(8913)"
        class="col-xl-12 btn btn-primary waves-effect waves-light addTemplateButton"
        @click="handleAddItem"
      >
        Add New Task Types
      </button>
      <div class="col-md-12 noPadding row">
        <div v-if="checkAuth(200001)" class="col-md-5 noPadding">
          <input
            type="checkbox"
            class="checkbox-input generalCheckBox"
            v-model="showTicketTypes"
            style="
              border-radius: 4px !important;
              border: solid 1px #d1d1d1 !important;
              vertical-align: text-top;
            "
          />

          <label class="generalLabel" style="font-weight: normal"
            >Show Ticket Types</label
          >
        </div>
        <div class="col-md-7 noPadding">
          <input
            type="checkbox"
            class="checkbox-input generalCheckBox"
            v-model="showSystemTasks"
            style="
              border-radius: 4px !important;
              border: solid 1px #d1d1d1 !important;
              vertical-align: text-top;
            "
          />

          <label class="generalLabel" style="font-weight: normal"
            >Show System Tasks</label
          >
        </div>
        <div class="col-md-5 noPadding">
          <input
            type="checkbox"
            class="checkbox-input generalCheckBox"
            v-model="showInactiveItems"
            style="
              border-radius: 4px !important;
              border: solid 1px #d1d1d1 !important;
              vertical-align: text-top;
            "
          />

          <label class="generalLabel" style="font-weight: normal"
            >Show Inactive Types</label
          >
        </div>
        <div class="col-md-7 noPadding">
          <input
            type="checkbox"
            class="checkbox-input generalCheckBox"
            v-model="showManuallyCreatedTasks"
            style="
              border-radius: 4px !important;
              border: solid 1px #d1d1d1 !important;
              vertical-align: text-top;
            "
          />

          <label class="generalLabel" style="font-weight: normal"
            >Show Manually Created Tasks</label
          >
        </div>
      </div>
      <div
        class="col-md-12 noPadding"
        style="max-height: 55vh; overflow-y: auto"
      >
        <div class="custom-table">
          <o-table :data="filteredTypes">
            <o-table-column field="Name" label="Task Name" sortable searchable>
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    v-model="NameFilter"
                    placeholder=""
                  />
                </div>
              </template>

              <template v-slot="props">
                <span
                  :class="[
                    props.row.CsrTaskTypeSetting.IsActive
                      ? 'activeTypes'
                      : 'inactiveTypes',
                  ]"
                  style="cursor: pointer"
                  @click="handleListSelection(props.row)"
                  :disabled="saving || deleting"
                >
                  <i class="bx bx-task"></i>
                  <span style="margin-left: 8px">{{ props.row.Name }}</span>
                </span>
              </template>
            </o-table-column>

            <o-table-column
              field="CsrTaskTypeSetting.IsActive"
              label="Active"
              sortable
            >
              <template v-slot="props">
                <i
                  v-if="props.row.CsrTaskTypeSetting.IsActive == true"
                  class="far fa-check-square"
                  style="font-size: 22px; color: #1abc9c"
                ></i>
                <i
                  v-if="
                    props.row.CsrTaskTypeSetting.IsActive == false ||
                    props.row.CsrTaskTypeSetting.IsActive == null
                  "
                  class="far fa-square"
                  style="font-size: 22px; color: #c1c1c1"
                ></i>
              </template>
            </o-table-column>
            <o-table-column
              v-if="checkAuth(200001)"
              field="CsrTaskTypeSetting.IsTicketType"
              label="Ticket"
              sortable
            >
              <template v-slot="props">
                <i
                  v-if="props.row.CsrTaskTypeSetting.IsTicketType == true"
                  class="far fa-check-square"
                  style="font-size: 22px; color: #1abc9c"
                ></i>
                <i
                  v-if="
                    props.row.CsrTaskTypeSetting.IsTicketType == false ||
                    props.row.CsrTaskTypeSetting.IsTicketType == null
                  "
                  class="far fa-square"
                  style="font-size: 22px; color: #c1c1c1"
                ></i>
              </template>
            </o-table-column>
            <o-table-column
              field="CsrTaskTypeSetting.Priority"
              label="Priority"
              sortable
              searchable
            >
              <template v-slot:searchable>
                <div>
                  <input
                    type="text"
                    class="datagrid-table creditorTable"
                    placeholder=""
                    style="width: 23px"
                    v-model="PriorityFilter"
                  />
                </div>
              </template>

              <template v-slot="props">
                <label class="generalLabel">{{
                  props.row.CsrTaskTypeSetting.Priority
                }}</label>
              </template>
            </o-table-column>
          </o-table>
          <section
            v-if="filteredTypes.length == 0"
            class="section"
            style="text-align: center"
          >
            <div class="content has-text-grey has-text-centered mb-4">
              <p class="noMargin">
                <i
                  style="font-size: 24px; color: #939393"
                  class="ri-question-line"
                ></i>
              </p>
              <p class="noMargin">Nothing here.</p>
            </div>
          </section>
        </div>
      </div>
      <div
        v-if="isMobileDevice()"
        style="
          border-top: 1px solid rgb(0, 0, 0, 0);
          margin: 20px 0 0 9px;
          padding-bottom: 20px;
        "
        class="col-md-12 scrollToSelectedItem"
      ></div>
    </div>
    <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12" v-if="isEditing">
      <div class="panelContent">
        <div
          v-if="!checkAuth(8913) && checkAuth(8914)"
          style="
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 200;
            background-color: white;
            opacity: 0.4;
          "
        ></div>
        <div class="form-group row">
          <div class="col-md-6">
            <label for="txtName" class="form-label">Name: *</label>
            <input
              autocomplete="off"
              class="form-control generalTextBox"
              id="txtName"
              type="text"
              placeholder="Name"
              v-model="selectedRow.Name"
              :disabled="selectedRow.IsReadOnly"
              :class="v$.selectedRow.Name.$error ? 'has-error' : ''"
              @blur="v$.selectedRow.Name.$touch"
            />
          </div>
        </div>

        <div
          class="col-md-12 noPadding"
          v-if="selectedRow.IsReadOnly && selectedRow.Description != null"
        >
          <label for="lblTaskDescription" class="form-label"
            >Description:
          </label>
          {{ selectedRow.Description }}
        </div>

        <div class="form-group row">
          <div class="col-md-6">
            <label for="ddlsubTask">Sub Tasks: </label>
            <VueMultiselect
              id="ddlsubTask"
              v-model="selectedRow.CsrTaskTypeSetting.Subtasks"
              :options="activeTypes"
              :multiple="true"
              :close-on-select="false"
              placeholder="Please Select"
              label="Name"
              track-by="Id"
            />
          </div>
          <div class="col-md-6">
            <label for="ddlroles">Roles: *</label>
            <VueMultiselect
              id="ddlroles"
              v-model="selectedRow.CsrTaskTypeSetting.CsrTaskTypeRoles"
              :options="sortedRoles"
              :multiple="true"
              :close-on-select="false"
              placeholder="Please Select"
              label="Name"
              track-by="Id"
              :class="
                v$.selectedRow.CsrTaskTypeSetting.CsrTaskTypeRoles.$error
                  ? 'has-error'
                  : ''
              "
              @blur="v$.selectedRow.CsrTaskTypeSetting.CsrTaskTypeRoles.$touch"
            />
          </div>

          <div class="col-md-6 pt-2">
            <label for="ddlactionList">Action List: *</label>
            <VueMultiselect
              id="ddlactionList"
              v-model="selectedRow.CsrTaskTypeActions"
              :options="sortedActions"
              :multiple="true"
              :close-on-select="false"
              placeholder="Please Select"
              label="Name"
              track-by="Id"
              :class="
                v$.selectedRow.CsrTaskTypeActions.$error ? 'has-error' : ''
              "
              @blur="v$.selectedRow.CsrTaskTypeActions.$touch"
            />
          </div>

          <div class="col-md-6 pt-2">
            <label for="cniAutoDispositionId"
              >Auto Disposition on Create:</label
            >
            <select
              id="cniAutoDispositionId"
              class="form-control"
              v-model="selectedRow.CsrTaskTypeSetting.AutoDispositionId"
            >
              <option :value="null">Select a disposition</option>
              <option
                v-for="item in sortedDispoList"
                :key="item.Id"
                :value="item.Id"
              >
                {{ item.Name }}
              </option>
            </select>
          </div>

          <div class="col-md-4 pt-2">
            <label for="cniPostpone">Max Postpone Days:</label>
            <CustomNumericInput
              id="cniPostpone"
              @updateValue="updateMaxPostponeDays"
              :minNumber="1"
              :maxNumber="1000000"
              :placeholder="'Max Postpone Days'"
              class="form-control"
              :value="selectedRow.CsrTaskTypeSetting.MaxPostponeDays"
            />
          </div>

          <div class="col-md-4 pt-2">
            <label for="cniTrigger">Trigger Days:</label>
            <CustomNumericInput
              id="cniTrigger"
              @updateValue="updateTriggerDays"
              :minNumber="0"
              :maxNumber="1000000"
              :placeholder="'Trigger Days'"
              class="form-control"
              :value="selectedRow.CsrTaskTypeSetting.TriggerDays"
            />
          </div>

          <div class="col-md-4 pt-2">
            <label for="cniPriority">Priority:</label>
            <CustomNumericInput
              id="cniPriority"
              @updateValue="updatePriority"
              :minNumber="1"
              :maxNumber="1000000"
              :placeholder="'Priority'"
              class="form-control"
              :value="selectedRow.CsrTaskTypeSetting.Priority"
            />
          </div>

          <div class="col-md-12 pt-2">
            <label>Task Color:</label>
            <div
              class="ColorPickerBox rounded"
              @click="OpenNewColorPicker()"
              :style="
                'background-color: ' + selectedRow.CsrTaskTypeSetting.Color
              "
            ></div>
            <NewColorPicker
              v-if="isNewColorPickerOpen"
              @updateColor="updateColor"
              :color="color"
              id="CsrTTColorPicker"
            />
          </div>

          <div class="col-md-12 row mt-2 noPadding">
            <div class="col-md-3">
              <input
                autocomplete="off"
                id="chkisActive"
                type="checkbox"
                class="checkbox-input generalCheckBox"
                style="
                  border-radius: 4px !important;
                  border: solid 1px #d1d1d1 !important;
                "
                v-model="selectedRow.CsrTaskTypeSetting.IsActive"
              />

              <label class="generalLabel" style="font-weight: normal"
                >Is Active</label
              >
            </div>
            <div class="col-md-3" v-if="checkAuth(200001)">
              <input
                id="chkisticket"
                type="checkbox"
                class="checkbox-input generalCheckBox"
                style="
                  border-radius: 4px !important;
                  border: solid 1px #d1d1d1 !important;
                "
                v-model="selectedRow.CsrTaskTypeSetting.IsTicketType"
              />

              <label class="generalLabel" style="font-weight: normal"
                >Ticket Type</label
              >
            </div>
          </div>

          <div class="col-md-12 row noPadding">
            <div class="col-md-3">
              <input
                id="chkRequiresPhoneCall"
                type="checkbox"
                class="checkbox-input generalCheckBox"
                style="
                  border-radius: 4px !important;
                  border: solid 1px #d1d1d1 !important;
                "
                v-model="selectedRow.CsrTaskTypeSetting.RequiresPhoneCall"
              />
              <label class="generalLabel" style="font-weight: normal"
                >Requires Phone Call</label
              >
            </div>
            <div class="col-md-4">
              <input
                id="chkCreateDuplicateTask"
                type="checkbox"
                class="checkbox-input generalCheckBox"
                style="
                  border-radius: 4px !important;
                  border: solid 1px #d1d1d1 !important;
                "
                v-model="selectedRow.CsrTaskTypeSetting.CanDuplicate"
              />

              <label
                for="chkCreateDuplicateTask"
                class="generalLabel"
                style="font-weight: normal"
                >Allow Duplication per Customer</label
              >
            </div>

            <div class="col-md-4" v-if="checkAuth(55555)">
              <input
                type="checkbox"
                class="checkbox-input generalCheckBox"
                style="
                  border-radius: 4px !important;
                  border: solid 1px #d1d1d1 !important;
                "
                v-model="selectedRow.CsrTaskTypeSetting.AutoSchedule"
              />

              <label class="generalLabel" style="font-weight: normal"
                >Contact Trigger Schedule</label
              >
            </div>
          </div>

          <div
            v-if="
              selectedRow.CsrTaskTypeSetting.AutoSchedule && checkAuth(55555)
            "
            class="col-md-12 pt-2"
          >
            <div>
              <label>Contact Trigger Schedule: </label>
              <button
                @click="openTrigger"
                style="float: right"
                class="btn btn-sm btn-success"
              >
                Add New Trigger
              </button>
            </div>

            <div class="datagrid-table">
              <o-table :data="filteredTriggers">
                <o-table-column
                  field="TriggerTypeName"
                  label="Trigger Type"
                  v-slot="props"
                  >{{ triggerNames(props.row.TriggerTypeId) }}</o-table-column
                >
                <o-table-column
                  field="TriggerTime"
                  label="Trigger Time"
                  sortable
                  v-slot="props"
                >
                  {{ formatTriggerTime(props.row.TriggerTime) }}
                </o-table-column>

                <o-table-column
                  field="TemplateName"
                  label="Template Name"
                  v-slot="props"
                >
                  <div v-if="props.row.TriggerTypeId == 3">
                    <button
                      @click="
                        openScriptPopUp(
                          props.row.Script,
                          props.row.VmScript,
                          props.row.PolicyGroupScripts
                        )
                      "
                      class="btn btn-info"
                    >
                      <i class="fe-info"></i>
                    </button>
                  </div>

                  <div>
                    <a>{{
                      templateName(
                        props.row.TemplateId,
                        props.row.TriggerTypeId
                      )
                    }}</a>
                  </div>
                </o-table-column>

                <o-table-column field="" label="Delete" v-slot="props">
                  <button
                    @click="deactiveTrigger(props.row)"
                    class="btn btn-danger"
                  >
                    <i class="fe-trash-2"></i>
                  </button>
                </o-table-column>
              </o-table>
              <section
                v-if="
                  selectedRow.CsrTaskTypeSetting.CsrTaskTypeTriggers == null
                "
                class="section"
                style="text-align: center"
              >
                <div class="content has-text-grey has-text-centered mb-4">
                  <p class="noMargin">
                    <i
                      style="font-size: 24px; color: #939393"
                      class="ri-question-line"
                    ></i>
                  </p>
                  <p class="noMargin">Nothing here.</p>
                </div>
              </section>
            </div>
          </div>

          <div
            class="offset-xl-9 offset-lg-8 col-xl-3 col-lg-4 col-md-12 col-xs-12"
          >
            <button
              class="btn btn-success"
              style="width: 100%"
              id="CSRTTsave"
              @click="handleSave"
              :disabled="v$.$invalid || saving || deleting"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="backgroundLock" v-if="isTriggerPopUpOpen">
        <div
          style="position: relative; margin: 2% auto"
          class="offset-xl-4 col-xl-6 offset-lg-3 col-lg-6 col-md-12 col-sm-12 col-xs-12"
        >
          <TriggerPopUp
            @closeTriggerPopUp="closeTriggerPopUp"
            @triggerSchedule="handleTrigger"
            :allTriggers="selectedRow.CsrTaskTypeSetting.CsrTaskTypeTriggers"
          ></TriggerPopUp>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="backgroundLock" v-if="isScriptPopUpOpen">
        <div
          style="position: relative; margin: 4% auto"
          class="offset-xl-4 col-xl-6 offset-lg-3 col-lg-6 col-md-12 col-sm-12 col-xs-12"
        >
          <ScriptPopUp
            @closeScriptPopUp="closeScriptPopUp"
            :script="script"
            :vmScript="vmScript"
            :policyGroupScripts="policyGroupScripts"
          ></ScriptPopUp>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueMultiselect from "vue-multiselect";
import { defineComponent, ref } from "vue";
import NewColorPicker from "@/components/NewColorPicker.vue";
import globalTypes from "@/store/types";
import { mapState } from "vuex";
import types from "./types";
import actionTypes from "../CSRTaskActionManagement/types";
import deepCopy from "@/helpers/deepCopy";
import useValidate from "@vuelidate/core";
import { required } from "vuelidate/lib/validators";
import CustomNumericInput from "@/components/CustomNumericInput";
import formatMixin from "@/mixins/formatMixin";
import _orderby from "lodash";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import TriggerPopUp from "./TriggerPopUp.vue";
import ScriptPopUp from "./ScriptPopUp.vue";

const init = {
  Id: 0,
  Name: "",
  IsReadOnly: false,
  Description: null,
  CsrTaskTypeActions: [],
  CsrTaskTypeSetting: {
    Id: 0,
    Color: "#a0a294",
    CompanyId: 0,
    IsActive: true,
    MaxPostponeDays: 1,
    Priority: 1,
    TriggerDays: 0,
    RequiresPhoneCall: false,
    CanDuplicate: false,
    CsrTaskTypeRoles: [],
    AutoSchedule: false,
    Subtasks: [],
    AutoDispositionId: null,
    IsTicketType: false,
    CsrTaskTypeTriggers: [],
  },
};
export default {
  name: "CSRTaskActionManagement",

  components: {
    VueMultiselect,
    NewColorPicker,
    CustomNumericInput,
    TriggerPopUp,
    ScriptPopUp,
  },

  data() {
    return {
      isTriggerPopUpOpen: false,
      isScriptPopUpOpen: false,
      v$: useValidate(),
      saving: false,
      deleting: false,
      isEditing: false,
      activeItem: null,
      nameFilter: "",
      selectedRow: deepCopy(init),
      isNewColorPickerOpen: false,
      showInactiveItems: true,
      showTicketTypes: true,
      showSystemTasks: true,
      showManuallyCreatedTasks: true,
      oldValue: "",
      valueToRevert: deepCopy(init),
      isCancelledBeforeSave: false,
      NameFilter: "",
      PriorityFilter: "",
      script: "",
      vmScript: "",
      policyGroupScripts: [],
    };
  },
  mixins: [formatMixin, utilitiesMixin],
  async mounted() {
    await Promise.all([
      this.$store.dispatch(globalTypes.GET_ROLES),
      this.$store.dispatch(types.GET_TASK_TYPES),
      this.$store.dispatch(actionTypes.GET_TASK_ACTIONS),
      this.$store.dispatch(types.GET_TASK_TYPES_Simplified),
      this.$store.dispatch(globalTypes.GET_DISPOSITION_OPTIONS_FOR_ADMIN),
      this.$store.dispatch(globalTypes.GET_TASK_TYPE_NAMES),
      this.$store.dispatch(globalTypes.GET_QUICK_EMAIL_TEMPLATES),
      this.$store.dispatch(globalTypes.GET_SMS_TEMPLATES),
      this.$store.dispatch(types.GET_CSR_TASK_TRIGGER_TYPE),
    ]);
    if (!this.checkAuth(200001)) {
      this.showTicketTypes = false;
    }
  },
  computed: mapState({
    filteredTriggers() {
      if (
        this.selectedRow &&
        this.selectedRow.CsrTaskTypeSetting &&
        this.selectedRow.CsrTaskTypeSetting.CsrTaskTypeTriggers != null
      ) {
        return _orderby.orderBy(
          this.selectedRow.CsrTaskTypeSetting.CsrTaskTypeTriggers.filter(
            (x) => x.IsDeleted != true
          ),
          "TriggerTime",
          "asc"
        );
      }
    },
    quickSmsTemplates: (state) => state.globals.quickSmsTemplates,
    quickEmailTemplates: (state) => state.globals.quickEmailTemplates,
    taskTypeNames: (state) => state.globals.taskTypeNames,

    roles: (state) => state.globals.roles,
    sortedRoles() {
      return _orderby.orderBy(this.roles, "Name", "asc");
    },
    taskTypeList: (state) => state.csrTaskTypeManagement.taskTypeList,
    triggerTypeList: (state) => state.csrTaskTypeManagement.triggerTypeList,

    subTaskTypeList: (state) => state.csrTaskTypeManagement.subTaskTypeList,
    actionList: (state) =>
      state.csrTaskActionManagement.list.filter((item) => item.IsActive),

    activeTypes() {
      return this.subTaskTypeList.filter((item) => item.IsActive);
    },
    sortedActions() {
      return this.actionList.sort((a, b) => a.Name - b.Name);
    },
    dispoList: (state) => state.globals.dispositionOptionList,
    sortedDispoList() {
      return _orderby.orderBy(this.dispoList, "Name", "asc");
    },
    filteredTaskTypeList() {
      let rtn = [];
      let tmpList =
        this.taskTypeList.length > 0
          ? this.taskTypeList.filter((item) => {
              return (
                this.filterString(item.Name, this.NameFilter) &&
                this.filterString(
                  item.CsrTaskTypeSetting.Priority,
                  this.PriorityFilter
                )
              );
            })
          : [];

      if (tmpList.length == 0) {
        this.reportData = [];
        return rtn;
      }
      rtn.push(...tmpList);
      return rtn;
    },
    filteredTypes() {
      if (!this.showManuallyCreatedTasks && !this.showSystemTasks) {
        return [];
      }
      return this.filteredTaskTypeList.filter((item) => {
        let rtn = true;

        rtn =
          (!this.showTicketTypes &&
            item.CsrTaskTypeSetting &&
            !item.CsrTaskTypeSetting.IsTicketType == true) ||
          this.showTicketTypes;

        rtn =
          rtn &&
          ((!this.showInactiveItems &&
            item.CsrTaskTypeSetting &&
            item.CsrTaskTypeSetting.IsActive) ||
            this.showInactiveItems);

        if (!(this.showManuallyCreatedTasks && this.showSystemTasks)) {
          if (this.showManuallyCreatedTasks) {
            rtn = rtn && (item.Description === null || item.Description === "");
          }

          if (this.showSystemTasks) {
            rtn = rtn && item.Description !== null && item.Description !== "";
          }
        }

        return rtn;
      });
    },
  }),
  methods: {
    deactiveTrigger(v) {
      this.$swal
        .fire({
          title: "Confirm",
          text: "Are you sure to delete this trigger?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then(async (dialog) => {
          if (dialog.value) {
            if (v.TriggerId > 0) {
              let indx =
                this.selectedRow.CsrTaskTypeSetting.CsrTaskTypeTriggers.findIndex(
                  (x) => x.TriggerId == v.TriggerId
                );
              if (indx > -1) {
                this.selectedRow.CsrTaskTypeSetting.CsrTaskTypeTriggers.splice(
                  indx,
                  1
                );
                this.$swal("Deleted", "Please save for changes.", "success");
              }
            } else {
              v.IsDeleted = true;
              this.$swal("Deleted", "Please save for changes.", "success");
            }
          }
        });
    },
    templateName(tempId, triggerId) {
      let templateName = "";
      if (triggerId == 1) {
        let foundEmailTemplate = this.quickEmailTemplates.find(
          (x) => x.Id == tempId
        );
        if (foundEmailTemplate) {
          templateName = foundEmailTemplate.Title;
        }
      } else if (triggerId == 2) {
        let foundSmsTemplate = this.quickSmsTemplates.find(
          (x) => x.Id == tempId
        );
        if (foundSmsTemplate) {
          templateName = foundSmsTemplate.Title;
        }
      } else if (triggerId == 4 || triggerId == 5) {
        let foundTemplate = this.taskTypeNames.find((x) => x.Id == tempId);
        if (foundTemplate) {
          templateName = foundTemplate.Name;
        }
      }
      return templateName;
    },
    formatTriggerTime(value) {
      let rtn = "";
      let days = Math.floor(value / 24);
      let hours = value % 24;
      if (days == 0 && hours == 0) {
        rtn = "Immediately";
      } else {
        rtn = days + "d " + hours + "h";
      }

      return rtn;
    },
    triggerNames(triggerTypeId) {
      let type = "";
      if (this.triggerTypeList && this.triggerTypeList.length > 0) {
        let indx = this.triggerTypeList.findIndex((x) => x.Id == triggerTypeId);
        if (indx > -1) type = this.triggerTypeList[indx].Name;
      }
      return type;
    },

    handleTrigger(data) {
      if (this.selectedRow.CsrTaskTypeSetting.CsrTaskTypeTriggers == null) {
        this.selectedRow.CsrTaskTypeSetting.CsrTaskTypeTriggers = [];
      }
      this.selectedRow.CsrTaskTypeSetting.CsrTaskTypeTriggers.push(data);
    },
    openScriptPopUp(script, vmScript, policyGroupScripts) {
      this.isScriptPopUpOpen = true;
      this.script = script;
      this.vmScript = vmScript;
      this.policyGroupScripts = policyGroupScripts;
    },
    closeScriptPopUp() {
      this.isScriptPopUpOpen = false;
    },
    closeTriggerPopUp() {
      this.isTriggerPopUpOpen = false;
    },
    openTrigger() {
      this.isTriggerPopUpOpen = true;
    },
    updateColor(value) {
      this.selectedRow.CsrTaskTypeSetting.Color = value;
      this.isNewColorPickerOpen = false;
    },
    OpenNewColorPicker() {
      this.isNewColorPickerOpen = true;
    },
    updatePriority(value) {
      this.selectedRow.CsrTaskTypeSetting.Priority = value;
    },
    updateMaxPostponeDays(value) {
      this.selectedRow.CsrTaskTypeSetting.MaxPostponeDays = value;
    },
    updateTriggerDays(value) {
      this.selectedRow.CsrTaskTypeSetting.TriggerDays = value;
    },

    async handleListSelection(row, index) {
      if (
        this.oldValue != JSON.stringify(this.selectedRow) &&
        JSON.stringify(this.selectedRow) != JSON.stringify(init)
      ) {
        await this.$swal({
          title: "You have unsaved changes",
          text: "You will lose it if you don't save",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, continue without saving!",
        }).then(async (dialog) => {
          if (dialog.value) {
            this.isCancelledBeforeSave = true;
          } else {
            this.isCancelledBeforeSave = false;
            return;
          }
        });
      } else {
        this.valueToRevert = deepCopy(row);
        this.oldValue = JSON.stringify(row);
        this.activeItem = row.Id;

        this.selectedRow = row;

        this.isCancelledBeforeSave = false;
      }
      this.isEditing = true;
      //document.getElementsByClassName("ql-editor")[0].focus();
      if (this.isCancelledBeforeSave) {
        this.selectedRow = deepCopy(this.valueToRevert);

        let indx = this.taskTypeList.findIndex(
          (x) => x.Id == this.valueToRevert.Id
        );
        this.taskTypeList[indx] = this.valueToRevert;
        this.taskTypeList.push();

        this.valueToRevert = deepCopy(row);

        this.oldValue = JSON.stringify(row);
        this.activeItem = row.Id;

        this.selectedRow = row;

        this.isCancelledBeforeSave = false;
      }
      let el = this.$el.querySelector(".scrollToSelectedItem");
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
    async handleAddItem() {
      if (
        this.oldValue != JSON.stringify(this.selectedRow) &&
        JSON.stringify(this.selectedRow) != JSON.stringify(init)
      ) {
        await this.$swal({
          title: "You have unsaved changes",
          text: "You will lose it if you don't save",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, continue without saving!",
        }).then(async (dialog) => {
          if (dialog.value) {
            this.isCancelledBeforeSave = true;
          } else {
            this.isCancelledBeforeSave = false;
            return;
          }
        });
      } else {
        this.selectedRow = deepCopy(init);
        this.activeItem = null;
        this.isEditing = true;
      }

      if (this.isCancelledBeforeSave) {
        this.selectedRow = deepCopy(this.valueToRevert);

        let indx = this.taskTypeList.findIndex(
          (x) => x.Id == this.valueToRevert.Id
        );
        this.taskTypeList[indx] = this.valueToRevert;
        this.taskTypeList.push();

        this.isCancelledBeforeSave = false;
        this.selectedRow = deepCopy(init);
        this.activeItem = null;
        this.isEditing = true;
      }
    },

    async handleSave() {
      try {
        if (!this.checkAuth(8913) && this.checkAuth(8914)) {
          this.$swal(
            "Warning!",
            "You are not authorized to this action!",
            "warning"
          );
          return;
        }

        if (this.oldValue == JSON.stringify(this.selectedRow)) {
          this.$swal("Warning!", "No Changes Detected!", "warning");
          return;
        }
      } catch (ex) {
        this.$swal("Error", "An error occured!", "error");
      }

      if (
        this.selectedRow &&
        this.selectedRow.CsrTaskTypeSetting &&
        this.selectedRow.CsrTaskTypeSetting.AutoDispositionId &&
        this.dispoList.length > 0
      ) {
        let dispo = this.dispoList.find(
          (x) =>
            x.Id ==
            parseInt(this.selectedRow.CsrTaskTypeSetting.AutoDispositionId)
        );
        if (typeof dispo != "undefined" && dispo) {
          if (dispo.TaskTypeId == this.selectedRow.Id) {
            this.$swal(
              "Warning!",
              "The selected disposition will create the same task!",
              "warning"
            );
            return;
          }
        }
      }

      this.saving = true;
      let err, result, type;
      let isNameExists =
        this.taskTypeList.filter(
          (x) => x.Name.toLowerCase() == this.selectedRow.Name.toLowerCase()
        ).length > 0;

      if (this.selectedRow.Id != 0) {
        type = types.UPDATE_TASK_TYPE;

        isNameExists =
          this.taskTypeList.filter(
            (x) => x.Name.toLowerCase() == this.selectedRow.Name.toLowerCase()
          ).length > 1;
      } else {
        type = types.INSERT_TASK_TYPE;
      }

      if (isNameExists && type == types.INSERT_TASK_TYPE) {
        this.saving = false;
        this.$swal(
          "Warning!",
          "This Task Action name is already exists in list!",
          "warning"
        );
        this.saving = false;
        return;
      }

      let tempRoleList = null;
      if (this.selectedRow.CsrTaskTypeSetting.CsrTaskTypeRoles) {
        tempRoleList = this.selectedRow.CsrTaskTypeSetting.CsrTaskTypeRoles;
        this.selectedRow.CsrTaskTypeSetting.CsrTaskTypeRoles =
          this.selectedRow.CsrTaskTypeSetting.CsrTaskTypeRoles.map((x) => x.Id);
      }

      let tempActionList = null;
      if (this.selectedRow.CsrTaskTypeActions) {
        tempActionList = this.selectedRow.CsrTaskTypeActions;
        this.selectedRow.CsrTaskTypeActions =
          this.selectedRow.CsrTaskTypeActions.map((x) => x.Id);
      }

      let tempSubTaskList = null;
      if (this.selectedRow.CsrTaskTypeSetting.Subtasks) {
        tempSubTaskList = this.selectedRow.CsrTaskTypeSetting.Subtasks;
        this.selectedRow.CsrTaskTypeSetting.Subtasks =
          this.selectedRow.CsrTaskTypeSetting.Subtasks.map((x) => x.Id);
      }

      if (
        this.selectedRow.CsrTaskTypeSetting &&
        this.selectedRow.CsrTaskTypeSetting.CsrTaskTypeTriggers &&
        this.selectedRow.CsrTaskTypeSetting.CsrTaskTypeTriggers.length > 0
      ) {
        this.selectedRow.CsrTaskTypeSetting.CsrTaskTypeTriggers =
          this.selectedRow.CsrTaskTypeSetting.CsrTaskTypeTriggers.filter(
            (x) => !(x.TriggerId == 0 && x.IsDeleted == true)
          );
      }

      if (
        this.selectedRow.CsrTaskTypeSetting.AutoSchedule &&
        this.selectedRow.CsrTaskTypeSetting.CsrTaskTypeTriggers.length > 0
      ) {
        let allTriggers =
          this.selectedRow.CsrTaskTypeSetting.CsrTaskTypeTriggers;

        let cancelTriggers = allTriggers.filter(
          (x) => x.TriggerTypeId == 4 || x.TriggerTypeId == 5
        );
        let warningMessage =
          'The trigger types "Cancel" or "Cancel & Create" should be placed as the final options in the schedule, and only one of them is allowed in the schedule.';
        if (cancelTriggers.length > 1) {
          this.$swal("Warning!", warningMessage, "warning");
          this.selectedRow.CsrTaskTypeSetting.CsrTaskTypeRoles = tempRoleList;
          this.selectedRow.CsrTaskTypeActions = tempActionList;
          this.selectedRow.CsrTaskTypeSetting.Subtasks = tempSubTaskList;

          this.saving = false;
          return;
        } else if (cancelTriggers.length == 1) {
          let oldTriggers = allTriggers.filter(
            (x) => x.TriggerTime > cancelTriggers[0].TriggerTime
          );
          if (oldTriggers.length > 0) {
            this.$swal("Warning!", warningMessage, "warning");
            this.selectedRow.CsrTaskTypeSetting.CsrTaskTypeRoles = tempRoleList;
            this.selectedRow.CsrTaskTypeActions = tempActionList;
            this.selectedRow.CsrTaskTypeSetting.Subtasks = tempSubTaskList;

            this.saving = false;
            return;
          }
        }
      }

      [err, result] = await this.$store.dispatch(type, this.selectedRow);

      this.selectedRow.CsrTaskTypeSetting.CsrTaskTypeRoles = tempRoleList;
      this.selectedRow.CsrTaskTypeActions = tempActionList;
      this.selectedRow.CsrTaskTypeSetting.Subtasks = tempSubTaskList;

      if (result) {
        this.$swal("Success!", result.Message, "success");
        if (type == types.INSERT_TASK_TYPE) {
          this.selectedRow.Id = result.Data.Id;
          this.taskTypeList.push(this.selectedRow);
          this.oldValue = JSON.stringify(this.selectedRow);
        } else {
          this.oldValue = JSON.stringify(this.selectedRow);
        }
        await this.$store.dispatch(types.GET_TASK_TYPES_Simplified);
        //this.reset();
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
      this.saving = false;
    },
  },
  validations() {
    return {
      selectedRow: {
        Name: { required },
        CsrTaskTypeActions: {
          atLeastOneRole(CsrTaskTypeActions) {
            return CsrTaskTypeActions.length > 0;
          },
        },
        CsrTaskTypeSetting: {
          CsrTaskTypeRoles: {
            atLeastOneRole(CsrTaskTypeRoles) {
              return CsrTaskTypeRoles.length > 0;
            },
          },
        },
      },
    };
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style>
.ColorPickerBox {
  width: 50px;
  height: 37px;
  border: solid 1px #ced4da;
  cursor: pointer;
}

.activeTypes {
  color: #1abc9c;
}
.inactiveTypes {
  color: #727070;
}
</style>
