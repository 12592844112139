<template>
  <div
   
    v-click-outside="closePanel"
    class="col-md-12 newPanelWrapper noMargin"
    style="
      background-color: #ffffff;
      z-index: 1000;
      padding-left: 0;
      padding-right: 0;
    "
  >
    <div>
      <div class="closeButton" @click.prevent.stop="closePanel" v-if="isInnerComponent != true">
        <i class="fas fa-window-close"></i>
      </div>

      <div class="col-md-12">
        <label class="">Task Type: {{ taskType.Name }}</label>
      </div>
      <div
        class="col-md-12 row"
        v-if="taskType && taskType.IsTicketType == true"
      >
        <div class="col-md-12 noPadding">
          <label class="generalLabel">Assign To: </label>
          <input
            class="ml-2"
            style="width: 20px"
            type="radio"
            name="assignRadioGroup"
            :value="1"
            v-model="assignOption"
          />
          <label class="generalLabel">Default</label>
          <input
            class="ml-2"
            style="width: 20px"
            type="radio"
            name="assignRadioGroup"
            :value="2"
            v-model="assignOption"
          />
          <label class="generalLabel">Role</label>
          <input
            class="ml-2"
            style="width: 20px"
            type="radio"
            name="assignRadioGroup"
            :value="3"
            v-model="assignOption"
          />
          <label class="generalLabel">User</label>

          <input
           v-if="isInnerComponent == true"
            class="ml-2"
            style="width: 20px"
            type="radio"
            name="assignRadioGroup"
            :value="4"
            v-model="assignOption"
          />
          <label     v-if="isInnerComponent == true" class="generalLabel">Assigned to Default User</label>
        </div>
      </div>
      
      <div
        class="col-md-12 row"
        v-if="taskType && taskType.IsTicketType == true && assignOption > 1"
      >
        <div class="col-md-12 noPadding">
          <select
            v-if="assignOption == 2"
            v-model="assignedRoleId"
            id="slcTasks"
            class="noSizeSelectBox ml-2"
            style="width: 300px"
          >
            <option :value="0">Select a Role</option>
            <option
              :value="item.Id"
              :key="item.Id"
              v-for="item in ticketAccessRoles"
            >
              {{ item.Name }}
            </option>
          </select>
          <select
            v-if="assignOption == 3"
            v-model="assignedUserId"
            id="slcTasks"
            class="noSizeSelectBox ml-2"
            style="width: 300px"
          >
            <option :value="0">Select a User</option>
            <option
              :value="item.Id"
              :key="item.Id"
              v-for="item in ticketAccessUsers"
            >
              {{ item.Name }}
            </option>
          </select>
        </div>
      </div>
      <div
        class="col-md-12 row"
        v-if="taskType && taskType.IsTicketType == true"
      >
        <div class="col-md-12 noPadding">
          <label class="generalLabel">Ticket Priority: </label>
          <select
            v-model="ticketPriorityId"
            id="slcTasks"
            class="noSizeSelectBox ml-2"
            style="width: 100px"
          >
            <option :value="10">Low</option>
            <option :value="5">Normal</option>
            <option :value="1">High</option>
          </select>
        </div>
      </div>
      <div class="col-md-12 row">
        <div class="col-md-6 noPadding">
          <input
            style="width: 20px"
            type="radio"
            name="duedateRadioButton"
            :value="false"
            v-model="override"
          />
          <label class="generalLabel">Default Due Date</label>
        </div>

        <div class="col-md-6 noPadding">
          <input
            style="width: 20px"
            type="radio"
            name="duedateRadioButton"
            :value="true"
            v-model="override"
          />
          <label class="generalLabels">Override</label>
          <DatePicker
            v-if="override"
            mode="datetime"
            :locale="'en-US'"
            v-model="dueDate"
            :available-dates="datepickerConfig"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                :value="inputValue"
                v-on="inputEvents"
              />
            </template>
          </DatePicker>
        </div>
      </div>
      <div class="row noMargin" v-if="isInnerComponent && !taskType.IsTicketType">
    
        <div class="col-md-6 noPadding">
     
          <select
            v-model="assignedUserMethod"
            id="slcTasks"
            class="noSizeSelectBox ml-2"
            style="width: 300px"
          >
            <option :value="0">Assign to Default User</option>
            <option :value="1">Assign to Role Based User</option>
          </select>
          <!-- <input
            style="width: 20px"
            type="checkbox"
            v-model="assignToDefaultUser"
          />
          <label class="generalLabels">Assign to Default User</label> -->
        </div>
      </div>

      <div class="col-md-12">
        <label class="generalLabel" for="clientTaskNote">Note</label>
      </div>

      <div class="col-md-12">
        <textarea
          v-model="noteText"
          class="form-control textareaInput generalTextBox"
          placeholder="Please write a note"
          name="note"
          id="clientTaskNote"
          cols="30"
          rows="7"
        ></textarea>
        <button
          @click="addTask()"
          :disabled="isSubmit"
          type="button"
          class="btn btn-success waves-effect waves-light col-md-12 mt-2"
        >
          <i v-if="isSubmit" class="spinner-border spinner-border-sm"></i>
          Submit
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { DatePicker } from "v-calendar";
import deepCopy from "@/helpers/deepCopy";
import _lodash from "lodash";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import globalTypes from "@/store/types";
export default {
  name: "ClientAddNewTaskPopUp",
  mixins: [utilitiesMixin],
  components: { DatePicker },
  props: {
    taskType: {
      Type: Object,
      Default: null,
    },
    customerId: {
      Type: Number,
      Default: 0,
    },
    isInnerComponent: {
      Type: Boolean,
      Default: false,
    },
    previousTaskId: {
      Type: Number,
      Default: 0,
    },
  },
  data() {
    return {
      noteText: "",
      dueDate: new Date(),
      datepickerConfig: {
        start: new Date(),
      },
      override: false,
      assignOption: 1,
      assignedRoleId: 0,
      assignedUserId: 0,
      ticketPriorityId: 5,
      ticketAccessUsers: [],
      ticketAccessRoles: [],
      isSubmit: false,
      assignToDefaultUser:true,
      assignedUserMethod:0
    };
  },
  async mounted() {
    await this.bindTicketUsers();
    await this.bindTicketRoles();
    if(this.isInnerComponent)
    {
      this.assignOption = 4;
    }
    if(this.checkAuth(81419))
    {
      //Default to role based assignment when overriding subtask creation.
      // 0: Assign to Default User
      // 1: Assign to Role Based User
      this.assignedUserMethod = 1;
    }
  },
  methods: {
    async bindTicketUsers() {
      let err, result;
      [err, result] = await this.$store.dispatch("GET_TICKET_ACCESS_USERS");
      if (result) {
        if (result.Data) {
          this.ticketAccessUsers = deepCopy(result.Data);
          if (
            typeof this.ticketAccessUsers != "undefined" &&
            this.ticketAccessUsers
          ) {
            this.ticketAccessUsers = _lodash.orderBy(
              this.ticketAccessUsers,
              "Name",
              "asc"
            );
          }
        }
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
    },
    async bindTicketRoles() {
      let err, result;
      [err, result] = await this.$store.dispatch("GET_TICKET_ACCESS_ROLES");
      if (result) {
        if (result.Data) {
          this.ticketAccessRoles = deepCopy(result.Data);
          if (
            typeof this.ticketAccessRoles != "undefined" &&
            this.ticketAccessRoles
          ) {
            this.ticketAccessRoles = _lodash.orderBy(
              this.ticketAccessRoles,
              "Name",
              "asc"
            );
          }
        }
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
    },
    closePanel() {
      if(this.isInnerComponent != true)
      {
        this.$emit("closeClientAddNewTaskPopUp");
      }
      
    },

    async addTask() {
      this.isSubmit = true;
      if (this.taskType != null) {
        let err, result;

        let assignType = parseInt(this.assignedUserMethod);
        let payload = {
          CustomerId: this.customerId,
          Notes: this.noteText,
          CsrTaskType: { Id: this.taskType.Id },
          DueDate: this.override == true ? this.dueDate.toUTCString() : null,
          AssignedRoleId: this.assignedRoleId,
          AssignedUserId: this.assignedUserId,
          TicketPriority: this.ticketPriorityId,
          IsAssignedToDefaultUser: assignType == 0,
          PreviousTaskId: assignType == 1 ? 0 : this.previousTaskId
        };
     
        [err, result] = await this.$store.dispatch("ADD_TASK", payload);
        if (result && result.Errors.length == 0) {
          if (result.Data) {
            if(this.isInnerComponent == true)
            {
              this.$emit("submitTaskAction",true);
              this.closePanel();
            }
            else
            {
              this.$emit("refreshTaskGrid", result.Data);
              this.$swal("Success", "Task Added.", "success");
            }
            if (this.checkAuth(3994)) {
          this.$store.dispatch(globalTypes.INSERT_USER_ACTIVITY, {
            CustomerId: this.customerId,
            UserActivityTypeId: 15,
            AdditionalNotes: "Created a CSR Task"
          });
        }
          }
        
        } else {
          let errMsg = this.getApiErrorMessage(err);
          this.$swal("Error!", errMsg, "error");
        }
      }
      this.isSubmit = false;
    
    },
  },
};
</script>

<style>
.docusignESendButton {
  display: flex;
  justify-content: flex-end;
}
.has-error {
  border: 2px solid red !important;
}
</style>
