export default {
  GET_ALL_ROLES: "GET_ALL_ROLES",
  GET_MODULES: "GET_MODULES",
  POST_ROLE: "POST_ROLE",
  PUT_ROLE: "PUT_ROLE",
  DELETE_ROLE: "DELETE_ROLE",
  SET_ALL_ROLES: "SET_ALL_ROLES",
  SET_MODULES: "SET_MODULES",
  SETBYID: "SETBYID",
};
