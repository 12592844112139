<template>
  <div class="col-md-12">
    <ReportSummary
      :exportData="reportData"
      :reportName="'NSFCancelationReport'"
      :recordCount="recordCount"
      @updateLoadingStatus="reportLoading = $event"
      @setPayload="setPayload"
      @clearReport="clearReport"
      @setPagination="setPagination"
      :loadingSpinner="loadingSpinner"
    />

    <div
      class="datagrid-table gcsTabsTableScroll report-wrapper"
      :style="'max-height: ' + reportsPanelHeight + 'px; overflow-y:auto;'"
    >
      <o-table
        :data="filteredNSFReport"
        :paginated="isPaginated && filteredNSFReport.length > 0"
        :per-page="perPage"
        :pagination-simple="isPaginationSimple"
        :pagination-position="paginationPosition"
        :sticky-header="true"
      >
        <o-table-column
          field="CustomerId"
          label="Customer Id"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CustomerIdFilter"
                placeholder=""
                v-myMask="{
                  alias: 'numeric',
                  rightAlign: false,
                }"
              />
            </div>
          </template>

          <template v-slot="props">
            <span @click="viewCustomer(props.row.CustomerId, props.row.CustomerName)"
              :class="getTagReportHyperLinkClass(props.row.CustomerId && props.row.CustomerName)">
              <strong v-if="props.row.IsSummary"
                >{{ props.row.CustomerId }}
              </strong>
              <span v-if="!props.row.IsSummary">
                {{ addPrefixToCustomerId(props.row.CustomerId) }}
              </span>
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="CustomerName"
          label="Customer Name"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="customerNameFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.CustomerName }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.CustomerName }}
            </span>
          </template>
        </o-table-column>

        <o-table-column field="Email" label="Email" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="EmailFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.Email }}
          </template>
        </o-table-column>

        <o-table-column
          field="Status"
          label="Customer Status"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="StatusFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.Status }}
          </template>
        </o-table-column>

        <o-table-column field="Campaign" label="Campaign" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CampaignFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.Campaign }}
          </template>
        </o-table-column>

        <o-table-column
          field="OfficeName"
          label="Office Name"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="officeNameFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.OfficeName }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.OfficeName }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="PolicyGroup"
          label="Policy Group"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="PolicyGroupFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.PolicyGroup }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.PolicyGroup }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="AssignedAgent"
          label="Assigned Agent"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="assignedAgentFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.AssignedAgent }}
          </template>
        </o-table-column>

        <o-table-column
          field="PhoneNumber"
          label="Phone Number"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="phoneNumberFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{
              props.row.PhoneNumber && !isNaN(props.row.PhoneNumber)
                ? tryFormatPhone(props.row.PhoneNumber)
                : ""
            }}
          </template>
        </o-table-column>

        <o-table-column
          field="EnrolledDebt"
          label="Debt At Enrollment"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="EnrolledDebtFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.EnrolledDebt }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatMoney(props.row.EnrolledDebt) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="EnrolledDate"
          label="Enrolled Date"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="EnrolledDateFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ formatShortDate(props.row.EnrolledDate) }}
          </template>
        </o-table-column>

        <o-table-column field="NsfDate" label="Nsf Date" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="nsfDateFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ formatShortDate(props.row.NsfDate) }}
          </template>
        </o-table-column>

        <o-table-column
          field="NsfReason"
          label="Nsf Reason"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="nsfReasonFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.NsfReason }}
          </template>
        </o-table-column>

        <o-table-column
          field="DepositAmount"
          label="Deposit Amount"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="depositAmountFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.DepositAmount }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatMoney(props.row.DepositAmount) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="TotalClearedDrafts"
          label="Total Cleared Drafts"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="TotalClearedDraftsFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.TotalClearedDrafts }}
          </template>
        </o-table-column>

        <o-table-column
          field=""
          label="View Customer"
          position="centered"
          v-slot="props"
        >
          <button
            @click="viewCustomer(props.row.CustomerId, props.row.CustomerName)"
            class="btn btn-info waves-effect waves-light ml-3"
            v-if="!props.row.IsSummary"
          >
            <i class="far fa-address-card"></i>
          </button>
        </o-table-column>
      </o-table>
      <section
        v-if="filteredNSFReport.length == 0"
        class="section"
        style="text-align: center"
      >
        <div class="content has-text-grey has-text-centered mb-4">
          <p class="noMargin">
            <i
              style="font-size: 24px; color: #939393"
              class="ri-question-line"
            ></i>
          </p>
          <p class="noMargin">Nothing here.</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import ReportSummary from "./ReportSummary.vue";
import utilitiesMixin from "@/mixins/utilitiesMixin";

export default {
  name: "NSFCancellationReport ",
  components: { ReportSummary },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      customerPrefix: null,
      reportsPanelHeight: 300,
      loadingSpinner: false,
      isPaginationSimple: false,
      isPaginated: true,
      paginationPosition: "both",
      currentPage: 1,
      perPage: 200,

      recordCount: 0,
      reportLoading: false,
      reportData: [],

      nsfReportInitSummary: {
        Email: "",
        CustomerId: "",
        CustomerName: "",
        NsfDate: "",
        NsfReason: "",
        DepositAmount: "",
        PhoneNumber: "",
        AssignedAgent: "",
        Status: "",
        EnrolledDebt: "",
        EnrolledDate: "",
        TotalClearedDrafts: "",
        Campaign: "",
      },
      CustomerIdFilter: "",
      EmailFilter: "",
      customerNameFilter: "",
      nsfDateFilter: "",
      nsfReasonFilter: "",
      depositAmountFilter: "",
      phoneNumberFilter: "",
      assignedAgentFilter: "",
      officeNameFilter: "",
      PolicyGroupFilter: "",
      StatusFilter: "",
      EnrolledDebtFilter: "",
      EnrolledDateFilter: "",
      TotalClearedDraftsFilter: "",
      CampaignFilter: "",
    };
  },
  computed: mapState({
    nsfCancellationList: (state) => state.reports.nsfCancellationList,

    filteredNSFReport() {
      let rtn = [];

      let tmpList =
        this.nsfCancellationList.length > 0
          ? this.nsfCancellationList.filter((item) => {
              return (
                this.filterString(item.CustomerId, this.CustomerIdFilter) &&
                this.filterString(item.EnrolledDate, this.EnrolledDateFilter) &&
                this.filterString(item.Email, this.EmailFilter) &&
                this.filterString(item.CustomerName, this.customerNameFilter) &&
                this.filterString(item.Status, this.StatusFilter) &&
                this.filterString(item.OfficeName, this.officeNameFilter) &&
                this.filterString(item.PolicyGroup, this.PolicyGroupFilter) &&
                this.filterString(
                  item.AssignedAgent,
                  this.assignedAgentFilter
                ) &&
                new Date(item.NsfDate)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .indexOf(this.nsfDateFilter) > -1 &&
                this.filterString(item.NsfReason, this.nsfReasonFilter) &&
                this.filterNumeric(
                  item.DepositAmount,
                  this.depositAmountFilter
                ) &&
                this.filterString(
                  item.TotalClearedDrafts,
                  this.TotalClearedDraftsFilter
                ) &&
                this.filterString(item.Campaign, this.CampaignFilter) &&
                new Date(item.EnrolledDate)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .indexOf(this.EnrolledDateFilter) > -1
              );
            })
          : [];

      if (tmpList.length == 0) {
        this.reportData = [];
        return rtn;
      }
      let totalCustomer = tmpList.length.toString();

      this.nsfReportInitSummary.CustomerId = "Total: " + totalCustomer;
      this.recordCount = tmpList.length;
      this.nsfReportInitSummary.IsSummary = true;

      rtn.push(...tmpList);
      rtn.push(this.nsfReportInitSummary);
      this.reportData = rtn;

      return rtn;
    },
  }),
  async mounted() {
    this.reportsPanelHeight = this.setReportGridHeight(
      window.innerHeight,
      document.getElementById("reportsSummary")
    );
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

    if (
      this.userInfo != null &&
      this.userInfo.customerIdPrefix != null &&
      this.userInfo.customerIdPrefix.length > 0
    ) {
      this.customerPrefix = this.userInfo.customerIdPrefix;
    }
    await this.sleep(1000);
    this.fixStickyHeaders();
  },
  methods: {
    addPrefixToCustomerId(customerId) {
      let rtn = customerId.toString();
      if (this.customerPrefix != null) {
        rtn = this.customerPrefix + rtn;
      }
      return rtn;
    },
    setPagination(data) {
      this.isPaginated = data;
    },
    async setPayload(value) {
      this.loadingSpinner = true;
      await this.$store.dispatch(types.GET_NSF_CANCELLATION_REPORT, value);
      this.loadingSpinner = false;
      await this.sleep(500);
      this.fixStickyHeaders();
    },
    clearReport() {
      this.$store.dispatch(types.RESET_REPORTS);
      this.reportData = [];
    },
    async viewCustomer(id, name) {
      this.$Emitter.emit("viewCustomer", {
        customerId: id,
        customerName: name,
      });
    },
  },
};
</script>

<style scoped></style>
