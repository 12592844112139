<template>
  <div
    class="col-md-12 newPanelWrapper noMargin pchp-mediaScreen"
    style="
      background-color: #ffffff;
      z-index: 1000;
      padding-left: 0;
      padding-right: 0;
    "
  >
    <div v-if="isLoading" class="loading">
      <div class="text-center" style="margin-top: 290px">
        <i class="spinner-grow text-info m-2"></i>
      </div>
    </div>
    <div class="popupHeader generalLabel">
      <label style="font-size: 15px">Spinwheel Additional Authorization</label>
    </div>

    <div v-if="eventName != ''" class="statusHeader generalLabel">
      <label style="font-size: 15px; color: #fff"
        >{{ eventName }} : {{ eventMessage }}</label
      >
    </div>

    <div>
      <div class="closeButton_ada" v-on:click.prevent.stop="closePanel">
        <i class="fas fa-window-close"></i>
      </div>
    </div>

    <perfect-scrollbar
      style="
        width: 100%;
        height: 580px;
        min-height: 580px;
        max-height: 640px;
        overflow-x: hidden !important;
      "
    >
      <div
        style="margin-top: -50px"
        class="col-md-12"
        v-if="isSpinWheelAdditionalAuth"
      >
        <div style="height: 580px" id="spinwheel-identity-connect"></div>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
import types from "./types";
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import deepCopy from "@/helpers/deepCopy";
let form;
let handler;
let config;
export default {
  name: "SpinWheelAdditionalAuthorization",
  mixins: [utilitiesMixin, formatMixin],
  components: {},
  props: {
    spinWheelCredentials: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      isLoading: false,
      isSpinWheelAdditionalAuth: true,
      eventName: "",
      eventMessage: "",
    };
  },
  unmounted() {
    if (handler) {

      handler.exit(); // to close dropin module
      handler = null;
    }
    if (config) {
      config = null;
    }
    this.isSpinWheelAdditionalAuth = false;
  },
  async mounted() {
    form = this;
    let showError = false;

    if (this.spinWheelCredentials) {
      if (this.spinWheelCredentials.clientKey != "") {
        this.isLoading = true;

        let phoneNumber = "";
        if (
          this.spinWheelCredentials.phone &&
          this.spinWheelCredentials.phone.length > 0
        ) {
          phoneNumber = this.spinWheelCredentials.phone;
        }

        let day,
          month,
          year = 0;
        if (
          this.spinWheelCredentials.dob &&
          this.spinWheelCredentials.dob != ""
        ) {
          let date = new Date(this.spinWheelCredentials.dob);

          day = date.getDate();
          month = date.getMonth() + 1;
          year = date.getFullYear();
        }

        if (phoneNumber == "" || day == 0 || month == 0 || year == 0) {
          this.$swal(
            "Error",
            "Parameters are missing: Phone number, date of birth",
            "error"
          );
          return;
        }

        this.isSpinWheelAdditionalAuth = true;
        await this.sleep(100);
        let spinwheelDiv = document.getElementById("spinwheel-identity-connect");
        if(typeof spinwheelDiv != "undefined" && spinwheelDiv)
        {
          this.removeAllChildNodes(spinwheelDiv);
        }
        await this.sleep(100);


        config = {
          containerId: "spinwheel-identity-connect",
          env: "sandbox",
          onSuccess: (data) => {
      
            if (data) {
              this.setEventData(data.eventName, data.message);
              form.sendAuthenticationToken();
            }
          },
          onLoad: (data) => {
          
            if (data) {
              this.setEventData(data.eventName, data.message);
            }
          },
          onEvent: (data) => {
          
            if (data) {
              this.setEventData(data.eventName, data.message);
            }
          },
          onExit: (data) => {
           
            this.$emit("closeSpinWheelAdditionalAuthorization");
          },
          onError: (data) => {
           
            if (data) {
              this.setEventData(data.eventName, data.message);
              this.$swal("Error", data.message, "error");
            }
          },
          dropinConfig: {
            module: this.spinWheelCredentials.isManualRequest ? "debt-connect" : "identity-connect",
            token: this.spinWheelCredentials.clientKey,
            phoneNumber: phoneNumber,
            month: month,
            day: day,
            year: year,
          },
        };

        handler = window.Spinwheel.create(config);

        handler.open();

        this.isLoading = false;
      } else {
        showError = true;
      }
    } else {
      showError = true;
    }

    if (showError) {
      this.$swal("Error", "Parameters are missing!", "error");
    }
  },

  methods: {
   removeAllChildNodes(parent) {
    while (parent.firstChild) {
        parent.removeChild(parent.firstChild);
    }
    },
    setEventData(name, message) {
      if (name && name != "") {
        this.eventName = name;
      }

      if (message && message != "") {
        this.eventMessage = message;
      }
    },
    async sendAuthenticationToken() {
      this.$swal(
        "Success",
        "Credit pull requested, please check the status from pull history shortly.",
        "success"
      );
      this.$emit("closeSpinWheelAdditionalAuthorization");
    },
    async closePanel() {
      this.$emit("closeSpinWheelAdditionalAuthorization");
    },
  },
};
</script>

<style scope>
.has-error {
  border: 2px solid red !important;
}
.popupHeader {
  margin-left: 23px;
  margin-top: -5px;
  padding-bottom: 10px;
}
.statusHeader {
  margin: 0 12px 0 12px;
  padding: 8px 10px 3px 10px;
  background-color: #3bafda;
  color: #fff;
}
.loading {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.6);
  z-index: 1000;
}
.closeButton_ada {
  position: absolute;
  top: -5px;
  right: 15px;
  z-index: 500;
  font-size: 30px;
  cursor: pointer;
}
</style>
