import types from "./types";
import api from "@/services/admin/api";
import to from "@/helpers/to";
export default {
  state: {
    dripEmailList: [],
  },
  actions: {
    GET_DRIP_EMAILS: async ({ commit, state }) => {
      let err, result;

      [err, result] = await to(api.getDripEmail());

      if (result) {
        commit(types.SET_DRIP_EMAILS, result.Data);
      }
    },
    POST_DRIP_EMAIL: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.insertDripEmail(payload));

      return [err, result];
    },
    PUT_DRIP_EMAIL: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.updateDripEmail(payload));

      return [err, result];
    },
    DELETE_TRIGGER: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.deleteDripEmailTrigger(payload));

      return result;
    },
    MODIFY_DRIP_EMAIL: async ({ commit }, payload) => {
      commit(types.SET, payload);
    },
  },
  mutations: {
    SET_DRIP_EMAILS: (state, payload) => {
      state.dripEmailList = payload;
    },
  },
};
