<template>
  <div
    class="col-md-12 newPanelWrapper noMargin smp-mediaScreen"
    style="
      background-color: #ffffff;
      z-index: 1000;
      padding-left: 0;
      padding-right: 0;
    "
  >
    <div v-if="isLoading" class="loading">
      <div class="text-center" style="margin-top: 300px">
        <i class="spinner-grow text-info m-2"></i>
      </div>
    </div>
    <div class="monevoPopupHeader">
      <label style="font-size: 15px">Monevo CoBranded</label>
    </div>
    <div>
      <div class="closeButton" v-on:click.prevent.stop="closePanel">
        <i class="fas fa-window-close"></i>
      </div>
    </div>
    <perfect-scrollbar
      v-if="monevoResult.ResultId == 0 && isUrlCreated == false"
      style="
        width: 100%;
        height: auto;
        max-height: 600px;
        overflow-x: hidden !important;
        margin-top: -10px;
      "
    >
      <div>
        <div class="col-md-12 row">
          <div class="col-md-4">
            <label for="sm-firstName" class="generalLabel">First Name</label>

            <label class="form-control" style="background: #f1efef">{{
              data.FirstName
            }}</label>
          </div>
          <div class="col-md-4">
            <label for="sm-lastName" class="generalLabel">Last Name</label>
            <label class="form-control" style="background: #f1efef">{{
              data.LastName
            }}</label>
          </div>
          <div class="col-md-4">
            <label for="sm-dateofBirth" class="generalLabel"
              >Date of Birth</label
            >
            <label class="form-control" style="background: #f1efef">{{
              data.DateOfBirth != null
                ? new Date(data.DateOfBirth).toString("yyyy-MM-dd")
                : ""
            }}</label>
          </div>

          <div class="col-md-8">
            <label for="sm-email" class="generalLabel">Email</label>
            <label
              class="form-control"
              style="
                background: #f1efef;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              "
              >{{ data.Email }}</label
            >
          </div>
          <div class="col-md-4">
            <label for="sm-phone" class="generalLabel">Phone</label>
            <label class="form-control" style="background: #f1efef">{{
              tryFormatPhone(data.Phone)
            }}</label>
          </div>
          <div class="col-md-4">
            <label for="sm-loanAmount" class="generalLabel">SSN</label>
            <label class="form-control" style="background: #f1efef">{{
              data.Ssn
            }}</label>
          </div>

          <div class="col-md-4">
            <label for="sm-loanAmount" class="generalLabel">Loan Amount</label>
            <input
              v-model="monevoObj.loan_amount"
              autocomplete="none"
              type="text"
              class="form-control"
              placeholder="Loan Amount"
              v-myMask="{
                alias: 'numeric',
                allowMinus: false,
                rightAlign: false,
              }"
              :class="v$.monevoObj.loan_amount.$error ? 'has-error' : ''"
              @blur="v$.monevoObj.loan_amount.$touch"
            />
          </div>

          <div class="col-md-4">
            <label for="sm-annualIncome" class="generalLabel"
              >Monthly Income</label
            >
            <label class="form-control" style="background: #f1efef">{{
              formatMoney(data.MonthlyIncome)
            }}</label>
          </div>

          <div class="col-md-4">
            <label for="sm-employmentStatus" class="generalLabel"
              >Residence Type</label
            >

            <select
              class="form-control"
              id="sm-employmentStatus"
              v-model="monevoObj.residence_type"
              :class="v$.monevoObj.residence_type.$error ? 'has-error' : ''"
              @blur="v$.monevoObj.residence_type.$touch"
            >
              <option :value="null">Select</option>
              <option value="1">Home Owner</option>
              <option value="2">Rent</option>
              <option value="3">Live with Family</option>
              <option value="4">Other</option>
            </select>
          </div>

          <div class="col-md-4">
            <label class="generalLabel"
              >Monthly Rent
              <i
                class="fe-info"
                style="cursor: pointer"
                title="The customer’s monthly rent / housing payment / mortgage."
              ></i
            ></label>
            <label
              v-if="data.MonthlyRent"
              class="form-control"
              style="background: #f1efef"
              >{{ formatMoney(data.MonthlyRent) }}</label
            >
            <input
              v-if="data.MonthlyRent == null || data.MonthlyRent == 0"
              v-model="monevoObj.monthly_rent"
              autocomplete="off"
              type="text"
              class="form-control"
              placeholder="Rent Monthly"
              v-myMask="{
                alias: 'numeric',
                allowMinus: false,
                rightAlign: false,
              }"
              :class="v$.monevoObj.monthly_rent.$error ? 'has-error' : ''"
              @blur="v$.monevoObj.monthly_rent.$touch"
            />
          </div>
          <div class="col-md-4">
            <label for="monthsAtAddress" class="generalLabel"
              >Months At Address
              <i
                class="fe-info"
                style="cursor: pointer"
                title="The number of months since the customer has moved in to his/her address."
              ></i
            ></label>
            <label
              v-if="data.MonthsAtAddress && data.MonthsAtAddress > 0"
              class="form-control"
              style="background: #f1efef"
              >{{ data.MonthsAtAddress }}</label
            >
            <input
              v-if="data.MonthsAtAddress == null || data.MonthsAtAddress == 0"
              autocomplete="off"
              type="text"
              v-myMask="{
                alias: 'numeric',
                allowMinus: false,
                rightAlign: false,
              }"
              id="monthsAtAddress"
              class="form-control"
              v-model="monevoObj.months_at_address"
              placeholder="Months At Address"
              :class="v$.monevoObj.months_at_address.$error ? 'has-error' : ''"
              @blur="v$.monevoObj.months_at_address.$touch"
            />
          </div>
          <div class="col-md-4">
            <label for="sm-employmentStatus" class="generalLabel"
              >Income Source</label
            >
            <label
              v-if="data.IncomeSource"
              class="form-control"
              style="background: #f1efef"
              >{{ data.IncomeSource }}</label
            >
            <select
              v-if="data.IncomeSource == null"
              class="form-control"
              id="sm-employmentStatus"
              v-model="monevoObj.income_source"
              :class="v$.monevoObj.income_source.$error ? 'has-error' : ''"
              @blur="v$.monevoObj.income_source.$touch"
            >
              <option :value="null">Select</option>
              <option value="1">Employed</option>
              <option value="2">Social Security</option>
              <option value="3">Pension</option>
              <option value="4">Disability</option>
              <option value="5">Self Employed</option>
              <option value="6">Student</option>
              <option value="7">Unemployed</option>
            </select>
          </div>

          <div class="col-md-4">
            <label for="employerName" class="generalLabel"
              >Employer Name
              <i
                class="fe-info"
                style="cursor: pointer"
                title="If self employed pass 'self' or the customers trading name. If retired or unemployed pass 'none'"
              ></i>
            </label>
            <label
              v-if="data.EmployerName"
              class="form-control"
              style="background: #f1efef"
              >{{ data.EmployerName }}</label
            >
            <input
              autocomplete="off"
              type="text"
              class="form-control"
              v-if="data.EmployerName == null"
              v-model="monevoObj.employer_name"
              placeholder="Employer Name"
              :class="v$.monevoObj.employer_name.$error ? 'has-error' : ''"
              @blur="v$.monevoObj.employer_name.$touch"
            />
          </div>

          <div class="col-md-4">
            <label for="months_at_employer" class="generalLabel"
              >Months At Employer
              <i
                class="fe-info"
                style="cursor: pointer"
                title="The number of months the customer has been with his/her current employer. If unemployed pass 0. If self employed pass the number of months the customer has been self employed. If on benefits pass the number of months the customer has been on benefits or pass 0"
              ></i
            ></label>
            <label
              v-if="data.MonthsAtEmployer && data.MonthsAtEmployer > 0"
              class="form-control"
              style="background: #f1efef"
              >{{ data.MonthsAtEmployer }}</label
            >
            <input
              v-if="data.MonthsAtEmployer == null || data.MonthsAtEmployer == 0"
              autocomplete="off"
              type="text"
              v-myMask="{
                alias: 'numeric',
                allowMinus: false,
                rightAlign: false,
              }"
              id="months_at_employer"
              class="form-control"
              v-model="monevoObj.months_at_employer"
              placeholder="Months At Employer"
              :class="v$.monevoObj.months_at_employer.$error ? 'has-error' : ''"
              @blur="v$.monevoObj.months_at_employer.$touch"
            />
          </div>

          <div class="col-md-4">
            <label for="sm-payFrequency" class="generalLabel"
              >Pay Frequency</label
            >
            <label
              v-if="data.PayFrequency"
              class="form-control"
              style="background: #f1efef"
              >{{ data.PayFrequency }}</label
            >
            <select
              v-if="data.PayFrequency == null"
              class="form-control"
              id="sm-payFrequency"
              v-model="monevoObj.pay_frequency"
              :class="v$.monevoObj.pay_frequency.$error ? 'has-error' : ''"
              @blur="v$.monevoObj.pay_frequency.$touch"
            >
              <option :value="null">Select</option>
              <option value="1">Weekly</option>
              <option value="2">Biweekly</option>
              <option value="3">Twice a Month</option>
              <option value="4">Monthly</option>
              <option value="5">4 Weekly</option>
              <option value="6">Other</option>
            </select>
          </div>
          <div class="col-md-4">
            <label for="sm-owncar" class="generalLabel">Own Car</label>
            <label
              v-if="data.OwnCar"
              class="form-control"
              style="background: #f1efef"
              >{{ data.OwnCar }}</label
            >
            <select
              v-if="data.OwnCar == null"
              class="form-control"
              id="sm-owncar"
              v-model="monevoObj.own_car"
              :class="v$.monevoObj.own_car.$error ? 'has-error' : ''"
              @blur="v$.monevoObj.own_car.$touch"
            >
              <option :value="null">Select</option>
              <option value="1">Yes</option>
              <option value="2">No</option>
            </select>
          </div>

          <div class="col-md-4">
            <label for="sm-payFrequency" class="generalLabel"
              >Loan Purpose</label
            >
            <label class="form-control" style="background: #f1efef">
              Debt Consolidation
            </label>
          </div>

          <div class="col-md-4">
            <label for="sm-address1" class="generalLabel">Address1</label>
            <label class="form-control" style="background: #f1efef">{{
              data.Line1
            }}</label>
          </div>

          <div class="col-md-4">
            <label for="sm-city" class="generalLabel">City</label>
            <label class="form-control" style="background: #f1efef">{{
              data.City
            }}</label>
          </div>

          <div class="col-md-4">
            <label for="sm-state" class="generalLabel">State</label>
            <label class="form-control" style="background: #f1efef">{{
              data.StateShort
            }}</label>
          </div>

          <div class="col-md-4">
            <label for="sm-zip" class="generalLabel">Zip Code</label>
            <label class="form-control" style="background: #f1efef">{{
              data.Zip
            }}</label>
          </div>
          <div class="col-md-4" v-if="data.CoCustomers.length > 0">
            <label for="sm-payFrequency" class="generalLabel"
              >Co Customer</label
            >
            <select
              class="form-control"
              id="sm-payFrequency"
              v-model="monevoObj.selectedCoCustomer"
            >
              <option :value="null">Not Selected</option>
              <option
                v-for="item in data.CoCustomers"
                :key="item.Id"
                :value="item"
              >
                {{ item.FirstName }} {{ item.LastName }}
              </option>
            </select>
          </div>

          <div v-if="monevoObj.selectedCoCustomer" class="col-md-4">
            <label for="sm-city" class="generalLabel">Co First Name</label>
            <label class="form-control" style="background: #f1efef">{{
              monevoObj.selectedCoCustomer.FirstName
            }}</label>
          </div>
          <div v-if="monevoObj.selectedCoCustomer" class="col-md-4">
            <label for="sm-city" class="generalLabel">Co Last Name</label>
            <label class="form-control" style="background: #f1efef">{{
              monevoObj.selectedCoCustomer.LastName
            }}</label>
          </div>
          <div v-if="monevoObj.selectedCoCustomer" class="col-md-4">
            <label for="sm-city" class="generalLabel">Co Date of Birth</label>
            <label class="form-control" style="background: #f1efef">{{
              monevoObj.selectedCoCustomer.DateOfBirth
            }}</label>
          </div>

          <div v-if="monevoObj.selectedCoCustomer" class="col-md-4">
            <label for="sm-city" class="generalLabel">Co Annual Income</label>
            <label class="form-control" style="background: #f1efef">{{
              formatMoney(getAnnualIncome(monevoObj.selectedCoCustomer.Income))
            }}</label>
          </div>
        </div>
      </div>
    </perfect-scrollbar>

    <div v-if="isUrlCreated == false" class="col-md-12 row">
      <div class="col-md-4 mt-2"></div>
      <div class="col-md-4 mt-2 align-items-center">
        <div class="form-check form-check-inline">
          <label for="months_at_employer" class="generalLabel"
            >Send URL via:
          </label>
        </div>
        <div v-if="isSmsOptin" class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            name="inlineRadioOptions"
            id="radioSms"
            value="sms"
            v-model="selectedSendingUrlOption"
          />
          <label class="form-check-label" for="radioSms">SMS</label>
        </div>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            name="inlineRadioOptions"
            id="radioEmail"
            value="email"
            v-model="selectedSendingUrlOption"
          />
          <label class="form-check-label" for="radioEmail">Email</label>
        </div>
      </div>
      <div class="col-md-4">
        <button
          v-if="monevoResult.ResultId == 0"
          :disabled="v$.$invalid"
          type="button"
          class="btn btn-success noMargin col-md-12"
          @click="createUrl()"
        >
          Submit
        </button>
      </div>
    </div>
    <div
      v-if="isUrlCreated && selectedSendingUrlOption == 'sms'"
      class="col-md-12 row"
    >
      <div class="col-md-12">
        <div
          v-if="monevoObj.mobile_phone != null"
          class="badge badge-danger"
          style="padding: 5px; font-size: 15px"
        >
          SMS will be sent to: {{ this.formatPhone(monevoObj.mobile_phone) }}
        </div>
      </div>
      <div class="col-md-12">
        <div class="row align-items-center">
          <div class="col-md-9">
            <label for="sm-firstName" class="generalLabel">SMS Content:</label>
            <textarea
              v-model="smsContent"
              class="form-control textareaInput generalTextBox"
              rows="2"
              placeholder=""
            ></textarea>
          </div>
          <div class="col-md-3 mt-4">
            <label for="sm-firstName" class="generalLabel">+ URL</label>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="isUrlCreated && selectedSendingUrlOption == 'email'"
      class="col-md-12 row"
    >
      <div class="col-md-12">
        <div
          v-if="monevoObj.email != null"
          class="badge badge-danger"
          style="padding: 5px; font-size: 15px"
        >
          Email will be sent to: {{ monevoObj.email }}
        </div>
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-12">
              <label for="sm-firstName" class="generalLabel"
                >Email Subject:</label
              >
            </div>
            <div class="col-md-12">
              <input
                autocomplete="off"
                type="text"
                class="form-control"
                v-model="emailSubject"
                placeholder="Subject"
              />
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-md-9">
              <label for="sm-firstName" class="generalLabel">Email Body:</label>
              <textarea
                v-model="emailBody"
                class="form-control textareaInput generalTextBox"
                rows="2"
                placeholder=""
              ></textarea>
            </div>
            <div class="col-md-3 mt-4">
              <label for="sm-firstName" class="generalLabel">+ URL</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 row" v-if="isUrlCreated">
      <div class="col-md-12">
        <label for="sm-firstName" class="generalLabel">URL Preview </label>
        <textarea
          :value="requestUrl"
          class="form-control textareaInput generalTextBox readonlyInput"
          :disabled="true"
          rows="6"
          placeholder=""
        >
        </textarea>
      </div>
      <div class="col-md-12">
        <button
          type="button"
          class="btn btn-danger noMargin col-md-12"
          @click="backToForm()"
        >
          Back
        </button>
      </div>
      <div class="col-md-12">
        <button
          type="button"
          class="btn btn-success noMargin col-md-12"
          @click="sendRequest()"
        >
          {{ selectedSendingUrlOption == "sms" ? "Send SMS" : "Send Email" }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import formatMixin from "@/mixins/formatMixin";
import useValidate from "@vuelidate/core";
import { required } from "vuelidate/lib/validators";
import globalTypes from "@/store/types";
import types from "../EditClient/types";
import plivoTypes from "@/components/PlivoParts/types";
import smsTypes from "@/views/agent/SMS/types";
export default {
  name: "MonevoCoBrandedPopup",
  components: {},
  mixins: [formatMixin],

  props: {
    data: {
      Type: Object,
      Defult: null,
    },
  },
  data() {
    return {
      isLoading: false,
      v$: useValidate(),
      userInfo: null,
      incomeSourceList: [
        { Id: 1, Name: "Employed" },
        { Id: 2, Name: "Social Security" },
        { Id: 3, Name: "Pension" },
        { Id: 4, Name: "Disability" },
        { Id: 5, Name: "Self Employed" },
        { Id: 6, Name: "Student" },
        { Id: 7, Name: "Unemployed" },
      ],
      payFrequencyList: [
        { Id: 1, Name: "Weekly" },
        { Id: 2, Name: "Every two weeks" },
        { Id: 3, Name: "Twice a month" },
        { Id: 4, Name: "Monthly" },
        { Id: 5, Name: "4 Weekly" },
        { Id: 6, Name: "Other" },
      ],
      monevoObj: {
        first_name: "",
        last_name: "",
        loan_amount: 0, //integer
        email: "",
        mobile_phone: "",
        dob: "", // YYYY-MM-DD format
        address1: "",
        city: "",
        state: null, // 2 digit state abbreviation
        zipcode: null,
        residence_type: null, // 1:Home Owner,2:Rent, 3:Live with Family, 4:Other
        months_at_address: null, // The number of months since the customer has moved in to his/her address.
        monthly_rent: null, //	The customer’s monthly rent / housing payment / mortgage. Numeric value greater than zero, no decimals.
        social_security_number: "", //	The customer’s nine digit social security number. Numbers only, no space or dash.
        income_source: null, //1 Employed, 2 Social Security, 3 Pension, 4 Disability, 5 Self Employed, 6 Student, 7 Unemployed
        pay_frequency: null, //1 Weekly, 2 Every two weeks, 3 Twice a month, 4 Monthly, 5: 4 Weekly, 6: Other
        monthly_income: null, //The customer’s monthly income. Numeric value greater than zero, no decimal.
        employer_name: "",
        months_at_employer: null, //The number of months the customer has been with his/her current employer. If unemployed pass 0. If self employed pass the number of months the customer has been self employed. If on benefits pass the number of months the customer has been on benefits or pass 0.
        loan_purpose: 1,
        selectedCoCustomer: null,
        /*1 Debt Consolidation
        2 Home improvement / Pool / Solar
        3 Vacation / Travel
        4 New auto purchase
        5 Large Purchase
        6 Pay off credit cards
        7 Student Loan Refinancing
        8 Education
        9 Special Occasion
        10 Cosmetic Procedures
        11 Moving and Relocation
        12 Household Expenses
        13 Medical/Dental
        14 Taxes
        15 Business
        16 Other
        17 Funeral expenses
        18 Auto loan refinance
        19 Used auto purchase
        20 Auto lease buyout
        21 Baby / Adoption expenses
        22 Emergency expenses
        23 Wedding / Engagement
        24 Auto repairs
        25 Equipment Purchase
        */
        terms_consent: 0, //Indicates whether the customer has agreed to all consents (FCRA, TCPA, Terms, Privacy & E-Consent). Valid values: “1” (Yes, the customer has accepted); “0” (No, the customer has not accepted).
        marketing_opt_in: 0, //Indicates whether the customer has opted in for marketing. Valid values are:1 Yes, the customer has opted in to marketing,  0 No, the customer has not opted in to marketing
        mode: "LIVE",
        campaign_code: "cF9ebQMGCwdCTnc", //Company's monevo campaign code
        affiliate_app_id: "", //(Not Required but recommended)The lead or application ID from the supply partner. Up to 100 alphanumeric characters.
        site_url: "debtblue.globalholdings.app", //	The URL of the site on which the user applied for the loan.,
        site_ip: "20.49.104.48", //The IP address of the your site/server submitting the loan application to Monevo USA in XXX.XXX.XXX.XXX format.
        client_ip: "", //The IP address of the browser of the customer requesting the loan in XXX.XXX.XXX.XXX format.
        ref: "",
        co_app: 0,
        v1: "", // CustomerId
        v2: "", //agent Name
      },
      monevoResult: {
        ResultId: 0,
        OfferAccepted: 0,
        OfferRejected: 0,
        OfferAwaitingResponse: 0,
        Message: "",
        Disclaimer: "",
        ErrorCode: "",
        ErrorDescription: "",
        Offers: [],
      },
      selectedSendingUrlOption: "email",
      isSmsOptin: false,
      isUrlCreated: false,
      smsContent:
        "Please click the link to start your loan application process",
      emailSubject: "Loan Application Request",
      emailBody:
        "Please click the link below to start your loan application process",
      requestUrl: "",
    };
  },
  unmounted() {
    this.emptyTheObjectsOnUnmount();
  },
  async mounted() {
    let err, result;

    this.isLoading = true;
    // [err, result] = await this.$store.dispatch(globalTypes.GET_AGENT_IP_ADDR);

    // if (result && result.Data) {
    //   this.monevoObj.client_ip = result.Data.ip;
    // }

    let userInformation = JSON.parse(sessionStorage.getItem("userInfo"));
    if (typeof userInformation != "undefined" && userInformation != null) {
      this.userInfo = userInformation;
    }

    this.monevoObj.first_name = this.data.FirstName;
    this.monevoObj.last_name = this.data.LastName;
    this.monevoObj.middle_name = this.data.MiddleName;
    //this.monevoObj.loan_amount = this.data.LoanAmount;
    this.monevoObj.email = this.data.Email;
    this.monevoObj.mobile_phone = this.data.Phone;
    this.monevoObj.dob = new Date(this.data.DateOfBirth).toString("yyyy-MM-dd");
    this.monevoObj.address1 = this.data.Line1;
    this.monevoObj.city = this.data.City;
    this.monevoObj.state = this.data.StateShort;
    this.monevoObj.zipcode = this.data.Zip;
    this.monevoObj.social_security_number = this.data.Ssn;
    this.monevoObj.monthly_income = this.data.MonthlyIncome;
    this.monevoObj.v1 = this.data.CustomerId;
    this.monevoObj.affiliate_app_id = this.data.CustomerId;
    this.monevoObj.ref = this.data.CustomerId;
    this.monevoObj.v2 = this.data.AgentName;
    this.monevoObj.employer_name = this.data.EmployerName;
    this.monevoObj.months_at_address = this.data.MonthsAtAddress;
    this.monevoObj.months_at_employer = this.data.MonthsAtEmployer;
    this.monevoObj.monthly_rent = this.data.MonthlyRent;
    if (this.data.IncomeSource) {
      let indx = this.incomeSourceList.findIndex(
        (x) => this.cleanName(x.Name) == this.cleanName(this.data.IncomeSource)
      );
      if (indx > -1) {
        this.monevoObj.income_source = this.incomeSourceList[indx].Id;
      }
    }
    if (this.data.PayFrequency) {
      let indx = this.payFrequencyList.findIndex(
        (x) => this.cleanName(x.Name) == this.cleanName(this.data.PayFrequency)
      );
      if (indx > -1) {
        this.monevoObj.pay_frequency = this.payFrequencyList[indx].Id;
      }
    }

    if (this.data.ResidenceOwnership) {
      let rid = parseInt(this.data.ResidenceOwnership);

      if (rid > 0) {
        this.monevoObj.residence_type = rid;
      } else {
        this.monevoObj.residence_type = null;
      }
    }

    if (this.data.OwnCar) {
      this.monevoObj.own_car = this.data.OwnCar == "Yes" ? 1 : 0;
    }

    this.isLoading = false;

    if (this.data && this.data.Phone) {
      await this.checkCustomerOptinStatus(this.data.Phone);
    }
  },
  methods: {
    getAnnualIncome(monthlyIncome) {
      return parseFloat(monthlyIncome * 12);
    },
    async checkCustomerOptinStatus(phone) {
      if (phone && phone.length == 10) {
        let err, result;

        [err, result] = await this.$store.dispatch(
          types.GET_CUSTOMER_OPTIN_STATUS,
          { PhoneNumber: phone }
        );

        if (result && result.Data) {
          let optinStatusId = result.Data.SmsOptInStatus;
          this.isSmsOptin = optinStatusId == 2;
        }
      }
    },
    cleanName(item) {
      let rtn = "";
      if (item) {
        rtn = item.toLowerCase();
        rtn = rtn.replaceAll(" ", "");
      }

      return rtn;
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    copyRedirectUrl(url) {
      navigator.clipboard.writeText(url);
      this.$swal("", "Copied", "success");
    },
    substringText(text, limit) {
      text = text.length > limit ? text.substr(0, limit) + "..." : text;
      return text;
    },
    emptyTheObjectsOnUnmount() {
      this.monevoObj = null;
    },
    closePanel() {
      this.$emit("closeMonevoCoBrandedPopup");
    },
    getNodeValue(node) {
      let rtn = "";
      if (
        typeof node != "undefined" &&
        node != null &&
        node.textContent != null
      ) {
        rtn = node.textContent;
      }
      return rtn;
    },
    backToForm() {
      this.isUrlCreated = false;
      this.requestUrl = "";
    },
    async sendRequest() {
      let err, result;
      if (this.selectedSendingUrlOption == "sms") {
        let callerId = await this.getCallerId(this.monevoObj.mobile_phone);
        if (callerId != "") {
          [err, result] = await this.$store.dispatch(smsTypes.SEND_SMS, {
            PhoneNumber: this.monevoObj.mobile_phone,
            Body: this.smsContent + ": " + encodeURI(this.requestUrl),
            From: callerId,
            MmsMedia: [],
          });
          if (result) {
            this.$swal("Success", "SMS Sent.", "success");
            this.closePanel();
          } else {
            let errMsg = this.getApiErrorMessage(err);
            this.$swal("Error!", errMsg, "error");
          }
        }
      } else if (this.selectedSendingUrlOption == "email") {
        if (this.userInfo && this.userInfo.email != "") {
          let email = {
            From: this.userInfo.email,
            Alias: this.userInfo.firstName + " " + this.userInfo.lastName,
            To: this.monevoObj.email,
            ReplyTo: this.userInfo.email,
            Cc: "",
            Bcc: "",
            Subject: this.emailSubject,
            Body: this.emailBody + ": " + encodeURI(this.requestUrl),
            Attachments: [],
            CustomerId: this.data.CustomerId,
            IsExternalHtml: false,
          };

          [err, result] = await this.$store.dispatch(
            globalTypes.SEND_EMAIL_MG,
            email
          );
          if (result) {
            this.$swal("Success", result.Message, "success");
            this.closePanel();
          } else {
            let errMsg = this.getApiErrorMessage(err);
            this.$swal("Error!", errMsg, "error");
          }
        } else {
          this.$swal(
            "Error!",
            "User email address required to send email.",
            "error"
          );
        }
      }
    },
    async getCallerId(did) {
      let callerId = "";

      let err, result;
      [err, result] = await this.$store.dispatch(
        plivoTypes.GET_CALLER_ID_BY_CUSTOMER,
        { CustomerId: this.data.CustomerId, PhoneNumber: did, IsSms: true }
      );
      if (result) {
        if (result.Data && result.Data.CallerId) {
          callerId = result.Data.CallerId;
        }
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }

      return callerId;
    },
    createUrl() {
      let linkStr = "https://app.monevo.us/apply?";
      linkStr +=
        "campaign_code=" +
        this.monevoObj.campaign_code +
        "&first_name=" +
        this.monevoObj.first_name +
        "&last_name=" +
        this.monevoObj.last_name +
        "&email=" +
        this.monevoObj.email +
        "&mobile_phone=" +
        this.monevoObj.mobile_phone +
        "&dob=" +
        this.monevoObj.dob +
        "&address1=" +
        this.monevoObj.address1 +
        "&city=" +
        this.monevoObj.city +
        "&state=" +
        this.monevoObj.state +
        "&zipcode=" +
        this.monevoObj.zipcode +
        "&monthly_rent=" +
        parseInt(this.monevoObj.monthly_rent) +
        "&residence_type=" +
        parseInt(this.monevoObj.residence_type) +
        "&pay_frequency=" +
        parseInt(this.monevoObj.pay_frequency) +
        "&months_at_address=" +
        parseInt(this.monevoObj.months_at_address) +
        "&income_source=" +
        parseInt(this.monevoObj.income_source) +
        "&monthly_income=" +
        parseInt(this.monevoObj.monthly_income) +
        "&employer_name=" +
        this.monevoObj.employer_name +
        "&months_at_employer=" +
        this.monevoObj.months_at_employer +
        "&co_app=" +
        (this.monevoObj.selectedCoCustomer ? 1 : 0);

      if (this.monevoObj.selectedCoCustomer) {
        linkStr +=
          "&co_app_first_name=" + this.monevoObj.selectedCoCustomer.FirstName;
        linkStr +=
          "&co_app_last_name=" + this.monevoObj.selectedCoCustomer.LastName;
        linkStr +=
          "&co_app_dob=" + this.monevoObj.selectedCoCustomer.DateOfBirth;
        linkStr +=
          "&co_app_annualIncome=" +
          this.getAnnualIncome(this.monevoObj.selectedCoCustomer.Income);
        linkStr += "&co_app_address1=" + this.monevoObj.address1;
        linkStr += "&co_app_city=" + this.monevoObj.city;
        linkStr += "&co_app_state=" + this.monevoObj.state;
        linkStr += "&co_app_zipcode=" + this.monevoObj.zipcode;
      }

      linkStr +=
        "&loan_amount=" +
        parseInt(this.monevoObj.loan_amount) +
        "&loan_purpose=" +
        parseInt(this.monevoObj.loan_purpose) +
        "&own_car=" +
        parseInt(this.monevoObj.own_car) +
        "&v1=" +
        this.monevoObj.v1;

      this.requestUrl = linkStr;
      this.isUrlCreated = true;
    },
    showErrorMessage(errors) {
      if (typeof errors != "undefined" && errors != null) {
        let errorDesc = errors.querySelector("desc");
        if (typeof errorDesc != "undefined" && errorDesc != null) {
          errorDesc = vueObj.getNodeValue(errorDesc);
          if (errorDesc != null && errorDesc.trim() != "") {
            this.$swal("Error", errorDesc.trim(), "error");
          }
        }
      }
    },
  },

  validations() {
    return {
      monevoObj: {
        residence_type: { required },
        monthly_rent: { required },
        months_at_address: { required },
        income_source: { required },
        employer_name: { required },
        months_at_employer: { required },
        pay_frequency: { required },
        own_car: { required },
        loan_amount: { required },
      },
    };
  },
};
</script>

<style>
.has-error {
  border: 2px solid red !important;
}
.monevoPopupHeader {
  padding-left: 15px;
  margin-top: -10px;
}
.textLabel {
  font-family: "Open Sans";
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #666;
}
@media screen and (max-height: 730px) {
  .smp-mediaScreen {
    max-height: 600px !important;
    overflow-y: scroll;
  }
}
@media screen and (max-height: 620px) {
  .smp-mediaScreen {
    max-height: 400px !important;
    overflow-y: scroll;
  }
}
@media screen and (max-height: 420px) {
  .smp-mediaScreen {
    max-height: 250px !important;
    overflow-y: scroll;
  }
}
.loading {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.6);
  z-index: 1000;
}
.monevoDisclaimerLabel {
  font-size: 0.72em;
  color: #999;
}
.monevoDisclaimerLabel p {
  margin: 5px 0;
  font-size: 0.72em;
  color: #999;
}
</style>
