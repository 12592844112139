import types from "./types";
import api from "@/services/admin/api";
import to from "@/helpers/to";

export default {
  state: {
    list: [],
    quickEmailTypes: [],
  },

  actions: {
    GET_QUICKEMAILTYPES: async ({ commit }) => {
      let err, result;

      [err, result] = await to(api.getQuickEmailTypes());

      if (result) {
        commit(types.SET_QUICKEMAILTYPES, result.Data);
      }
    },
    GET_QUICKEMAILS: async ({ commit }) => {
      let err, result;

      [err, result] = await to(api.getQuickEmails());

      if (result) {
        commit(types.SET_QUICKEMAIL, result.Data);
      }
    },

    POST_QUICKEMAIL: async ({ commit }, payload) => {
      return await to(api.saveQuickEmails(payload));
    },
    PUT_QUICKEMAIL: async ({ commit }, payload) => {
      let result = await to(api.updateQuickEmails(payload));
      return result;
    },
    DELETE_QUICKEMAIL: async ({ commit }, payload) => {
      let result = await to(api.deleteQuickEmails(payload));
      return result;
    },
  },
  mutations: {
    SET_QUICKEMAIL: (state, payload) => {
      state.list = payload;
    },
    SET_QUICKEMAILTYPES: (state, payload) => {
      state.quickEmailTypes = payload;
    },
  },
};
