<template>
  <div>
    <div class="popUpBackground">
      <div
        class="mainPopUp xl-offset-5 lg-offset-6 md-offset-2 col-xl-2 col-lg-3 col-md-5 col-xs-12 shadow-lg noPadding"
        style="position: relative"
      >
        <div class="employeePanel">
          <label>
            {{ header }}
          </label>
          <i @click="close()" class="fas fa-times closeIcon"></i>
        </div>
        <div class="col-md-12">
          <input
            type="text"
            class="form-control mb-1"
            v-model="employeeListFilter"
            placeholder="Filter Employee"
          />
        </div>
        <div class="col-md-12 noPadding">
          <perfect-scrollbar class="employeeListScroll">
            <div
              class="employeeItem"
              v-for="(item, index) in filterEmployee"
              @click="selectEmployee(item.Id, item.Name)"
              :key="item.Id"
              :style="[
                index % 2 === 0
                  ? { 'background-color': '#F1EFEF' }
                  : { 'background-color': '#FFFFFF' },
              ]"
            >
              <span>
                <span style="margin-left: 8px">{{ item.Name }}</span>
              </span>
            </div>
          </perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import globalTypes from "@/store/types";
export default {
  name: "EmployeeList",
  props: {
    header: {
      Type: String,
      default: () => "Select an employee",
    },
  },
  components: {},
  data() {
    return {
      employeeListFilter: "",
      saving: false,
      deleting: false,
      activeItem: null,
    };
  },
  computed: mapState({
    employeeList: (state) => state.globals.csrs,
    filterEmployee() {
      return this.employeeList.length > 0
        ? this.employeeList.filter(
            (item) =>
              item.Name.toLowerCase().indexOf(
                this.employeeListFilter.toLowerCase()
              ) > -1
          )
        : this.employeeList;
    },
  }),
  async mounted() {
    await this.$store.dispatch(globalTypes.GET_CSRS);
  },

  methods: {
    close() {
      this.$emit("closeEmployeePopup");
    },
    selectEmployee(id, name) {
      this.$emit("selectEmployee", { EmployeeId: id, EmployeeName: name });
    },
  },
};
</script>

<style lang="css">
.popUpBackground {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.closeIcon {
  float: right;
  margin-top: 3px;
  cursor: pointer;
}

.employeeListScroll {
  height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
}

.employeeItem {
  width: 100%;
  padding: 10px 2%;
  cursor: pointer;
}

.employeeItem:hover {
  background-color: #3bafda !important;
  color: white;
}

.employeePanel {
  background-color: #0985e3b6;
  border-radius: 5px 5px 0 0;
  color: #ffffff;
  font-size: 1.25em;
  font-weight: 700;
  line-height: 1.25;
  padding: 0.75em 1em;
}
</style>
