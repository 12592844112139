import api from "@/services/client/customerApi";
import to from "@/helpers/to";

export default {
  actions: {
    GET_CUSTOMER_CALL_LOGS: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.getCallLogs(payload));

      return [err, result];
    },
  },
};
