<template>
  <div class="col-md-12">
    <ReportSummary
      :exportData="reportData"
      :reportName="'NexGenReport'"
      :recordCount="recordCount"
      @updateLoadingStatus="reportLoading = $event"
      @clearReport="clearReport"
      @setPagination="setPagination"
      @refreshReport="refreshReport"
      :loadingSpinner="loadingSpinner"
    />

    <div
      class="datagrid-table gcsTabsTableScroll report-wrapper"
      :style="'max-height: ' + reportsPanelHeight + 'px; overflow-y:auto;'"
    >
      <o-table
        :data="filteredNexGenReport"
        :paginated="isPaginated && filteredNexGenReport.length > 0"
        :per-page="perPage"
        :pagination-simple="isPaginationSimple"
        :pagination-position="paginationPosition"
        :sticky-header="true"
      >
        <o-table-column
          field="CustomerId"
          label="Customer Id"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CustomerIdFilter"
                placeholder=""
                v-myMask="{
                  alias: 'numeric',
                  rightAlign: false,
                }"
              />
            </div>
          </template>

          <template v-slot="props">
            <span
              @click="
                viewCustomer(props.row.CustomerId, props.row.CustomerName)
              "
              :class="
                getTagReportHyperLinkClass(
                  props.row.CustomerId && props.row.CustomerName
                )
              "
            >
              <strong v-if="props.row.IsSummary"
                >{{ props.row.CustomerId }}
              </strong>
              <span v-if="!props.row.IsSummary">
                {{ addPrefixToCustomerId(props.row.CustomerId) }}
              </span>
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="CustomerName"
          label="Full Name"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CustomerNameFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.CustomerName }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.CustomerName }}
            </span>
          </template>
        </o-table-column>

        <o-table-column field="DoB" label="DOB" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="DoBFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ formatShortDate(props.row.DoB) }}
          </template>
        </o-table-column>

        <o-table-column
          field="FirstPaymentClearedDate"
          label="1st Pmt Cleared Date"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="FirstPaymentClearedDateFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ formatShortDate(props.row.FirstPaymentClearedDate) }}
          </template>
        </o-table-column>

        <o-table-column
          field="LastClearedDraftDate"
          label="Last Pmt Cleared Date"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="LastClearedDraftDateFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ formatShortDate(props.row.LastClearedDraftDate) }}
          </template>
        </o-table-column>

        <o-table-column
          field="CurrentDebt"
          label="Current Debt"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CurrentDebtFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.CurrentDebt }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatMoney(props.row.CurrentDebt) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="OriginalDebt"
          label="Original Debt"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="OriginalDebtFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.OriginalDebt }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatMoney(props.row.OriginalDebt) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="EnrolledDate"
          label="Enrolled Date"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="EnrolledDateFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ formatShortDate(props.row.EnrolledDate) }}
          </template>
        </o-table-column>

        <o-table-column
          field="GraduationDate"
          label="Graduation Date"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="GraduationDateFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ formatShortDate(props.row.GraduationDate) }}
          </template>
        </o-table-column>

        <o-table-column
          field="ProgramLegth"
          label="Program Legth"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="ProgramLegthFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.ProgramLegth }}
          </template>
        </o-table-column>

        <o-table-column
          field="StatusName"
          label="Client Status"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="StatusNameFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.StatusName }}
          </template>
        </o-table-column>

        <o-table-column
          field="DroppedDate"
          label="Dropped Date"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="DroppedDateFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ formatShortDate(props.row.DroppedDate) }}
          </template>
        </o-table-column>

        <o-table-column field="State" label="State" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="StateFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.State }}
          </template>
        </o-table-column>

        <o-table-column field="Fee" label="Fee" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="FeeFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.Fee }}
          </template>
        </o-table-column>

        <o-table-column field="CompanyName" label="Company" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CompanyNameFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.CompanyName }}
          </template>
        </o-table-column>
        <o-table-column field="Affiliate" label="Affiliate" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="AffiliateFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.Affiliate }}
          </template>
        </o-table-column>

        <o-table-column
          field="InvestmentDebt"
          label="Investment Debt"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="InvestmentDebtFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.InvestmentDebt }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatMoney(props.row.InvestmentDebt) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="DraftFrequency"
          label="Draft Frequency"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="DraftFrequencyFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.DraftFrequency }}
          </template>
        </o-table-column>

        <o-table-column
          field="ClearedDraftCount"
          label="Cleared Draft Count"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="ClearedDraftCountFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.ClearedDraftCount }}
          </template>
        </o-table-column>

        <o-table-column
          field=""
          label="View Customer"
          position="centered"
          v-slot="props"
        >
          <button
            @click="viewCustomer(props.row.CustomerId, props.row.CustomerName)"
            class="btn btn-info waves-effect waves-light ml-3"
            v-if="!props.row.IsSummary"
          >
            <i class="far fa-address-card"></i>
          </button>
        </o-table-column>
      </o-table>
      <section
        v-if="filteredNexGenReport.length == 0"
        class="section"
        style="text-align: center"
      >
        <div class="content has-text-grey has-text-centered mb-4">
          <p class="noMargin">
            <i
              style="font-size: 24px; color: #939393"
              class="ri-question-line"
            ></i>
          </p>
          <p class="noMargin">Nothing here.</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import ReportSummary from "./ReportSummary.vue";
import utilitiesMixin from "@/mixins/utilitiesMixin";

export default {
  name: "NexGenReport",
  components: { ReportSummary },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      customerPrefix: null,
      reportsPanelHeight: 300,
      loadingSpinner: false,
      isPaginationSimple: false,
      isPaginated: true,
      paginationPosition: "both",
      currentPage: 1,
      perPage: 200,

      recordCount: 0,
      reportLoading: false,
      reportData: [],

      nexGenReportInitSummary: {
        CustomerId: "",
        CustomerName: "",
        FirstPaymentClearedDate: "",
        CurrentDebt: "",
        OriginalDebt: "",
        EnrolledDate: "",
        GraduationDate: "",
        StatusName: "",
        DroppedDate: "",
        State: "",
        Fee: "",
        CompanyName: "",
        Affiliate: "",
        InvestmentDebt: "",
        LastClearedDraftDate: "",
        ProgramLegth: "",
        DoB: "",
        DraftFrequency: "",
        ClearedDraftCount: "",
      },
      CustomerIdFilter: "",
      CustomerNameFilter: "",
      FirstPaymentClearedDateFilter: "",
      EnrolledDateFilter: "",
      StatusNameFilter: "",
      DroppedDateFilter: "",
      GraduationDateFilter: "",
      CompanyNameFilter: "",
      AffiliateFilter: "",
      FeeFilter: "",
      StateFilter: "",
      CurrentDebtFilter: "",
      OriginalDebtFilter: "",
      InvestmentDebtFilter: "",
      LastClearedDraftDateFilter: "",
      ProgramLegthFilter: "",
      DoBFilter: "",
      DraftFrequencyFilter: "",
      ClearedDraftCountFilter: "",
    };
  },
  computed: mapState({
    nexGenList: (state) => state.reports.nexGenList,

    filteredNexGenReport() {
      let rtn = [];

      let tmpList =
        this.nexGenList.length > 0
          ? this.nexGenList.filter((item) => {
              return (
                this.filterString(item.CustomerName, this.CustomerNameFilter) &&
                this.filterString(
                  item.InvestmentDebt,
                  this.InvestmentDebtFilter
                ) &&
                new Date(item.FirstPaymentClearedDate)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .indexOf(this.FirstPaymentClearedDateFilter) > -1 &&
                new Date(item.DoB)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .indexOf(this.DoBFilter) > -1 &&
                new Date(item.EnrolledDate)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .indexOf(this.EnrolledDateFilter) > -1 &&
                this.filterString(item.StatusName, this.StatusNameFilter) &&
                new Date(item.DroppedDate)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .indexOf(this.DroppedDateFilter) > -1 &&
                new Date(item.LastClearedDraftDate)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .indexOf(this.LastClearedDraftDateFilter) > -1 &&
                new Date(item.GraduationDate)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .indexOf(this.GraduationDateFilter) > -1 &&
                this.filterString(item.CompanyName, this.CompanyNameFilter) &&
                this.filterString(item.Affiliate, this.AffiliateFilter) &&
                this.filterString(item.Fee, this.FeeFilter) &&
                this.filterString(item.State, this.StateFilter) &&
                this.filterString(item.CurrentDebt, this.CurrentDebtFilter) &&
                this.filterString(item.ProgramLegth, this.ProgramLegthFilter) &&
                this.filterString(item.OriginalDebt, this.OriginalDebtFilter) &&
                this.filterString(
                  item.DraftFrequency,
                  this.DraftFrequencyFilter
                ) &&
                this.filterString(
                  item.ClearedDraftCount,
                  this.ClearedDraftCountFilter
                ) &&
                this.filterString(item.CustomerId, this.CustomerIdFilter)
              );
            })
          : [];

      if (tmpList.length == 0) {
        this.reportData = [];
        return rtn;
      }
      let totalCustomer = tmpList.length.toString();

      this.nexGenReportInitSummary.CustomerId = "Total: " + totalCustomer;
      this.recordCount = tmpList.length;
      this.nexGenReportInitSummary.IsSummary = true;

      rtn.push(...tmpList);
      rtn.push(this.nexGenReportInitSummary);
      this.reportData = rtn;

      return rtn;
    },
  }),
  async mounted() {
    this.reportsPanelHeight = this.setReportGridHeight(
      window.innerHeight,
      document.getElementById("reportsSummary")
    );

    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

    if (
      this.userInfo != null &&
      this.userInfo.customerIdPrefix != null &&
      this.userInfo.customerIdPrefix.length > 0
    ) {
      this.customerPrefix = this.userInfo.customerIdPrefix;
    }
    await this.sleep(1000);
    this.fixStickyHeaders();
  },
  methods: {
    addPrefixToCustomerId(customerId) {
      let rtn = customerId.toString();
      if (this.customerPrefix != null) {
        rtn = this.customerPrefix + rtn;
      }
      return rtn;
    },
    setPagination(data) {
      this.isPaginated = data;
    },
    async refreshReport(value) {
      this.loadingSpinner = true;
      await this.$store.dispatch(types.GET_NEXT_GEN_REPORT, value);
      this.loadingSpinner = false;
      await this.sleep(500);
      this.fixStickyHeaders();
    },
    clearReport() {
      this.$store.dispatch(types.RESET_REPORTS);
      this.reportData = [];
    },
    async viewCustomer(id, name) {
      this.$Emitter.emit("viewCustomer", {
        customerId: id,
        customerName: name,
      });
    },
  },
};
</script>

<style scoped></style>
