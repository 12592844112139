<template>
  <div
    style="
      border-radius: 8px;
      background: #ffffff;
      box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.1);
    "
  >
    <perfect-scrollbar
      style="
        max-height: 400px;
        overflow-y: auto;
        overflow-x: hidden !important;
        z-index: 1000;
      "
    >
      <table>
        <tbody>
          <tr v-for="(item, index) in creditorNames" :key="index + item">
            <td v-if="index < 5">
              <label
                class="noMargin"
                style="width: 150px"
                v-on:click.prevent="suggest(item)"
                >{{ item }}</label
              >
            </td>
          </tr>
        </tbody>
      </table>
    </perfect-scrollbar>
  </div>
</template>

<script>
import deepCopy from "../helpers/deepCopy";
export default {
  name: "SuggestBox",
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    searchParam: {
      type: String,
      default: () => "",
    },
  },

  watch: {
    searchParam(newVal, oldVal) {
      if (newVal != oldVal && newVal) {
        this.search(newVal);
      }
    },
  },
  data() {
    return {
      creditorNames: [],
    };
  },
  methods: {
    suggest(val) {
      this.$emit("assignCreditorName", val);
    },
    search(value) {
      if (value != "") {
        this.creditorNames = this.list.filter((x) =>
          x.toLowerCase().startsWith(value.toLowerCase())
        );

        if (this.creditorNames.length == 1) {
          if (this.creditorNames[0].length == value.length) {
            this.$emit("assignCreditorName", this.creditorNames[0]);
          }
        }
      }
    },
  },

  mounted() {
    this.creditorNames = deepCopy(this.list);
  },
};
</script>

<style></style>
