<template>
  <div
    class="col-lg-12 col-md-12 col-sm-12 newPanelWrapper noMargin"
    style="background-color: #ffffff"
  >
    <div class="row">
      <div
        class="backgroundLock"
        v-if="isEmailLogsPopupOpen"
        style="-webkit-box-align: center; align-items: center; display: flex"
      >
        <div
          style="position: relative; margin: 10% auto"
          class="offset-xl-4 col-xl-5 offset-lg-3 col-lg-6 col-md-12 col-sm-12 col-xs-12"
        >
          <EmailLogsPopup
            @closeEmailPopup="closeEmailPopup"
            :logsId="logsId"
          ></EmailLogsPopup>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-md-12">
        <label class="generalLabel">System Logs</label>
      </div>
    </div>
    <div
      class="buefyWrapper datagrid-table"
      style="
        max-height: 487px;
        overflow-y: auto;
        overflow-x: auto;
        font-size: 12px !important;
      "
    >
      <section>
        <o-table
          :data="isEmpty ? [] : sortedLogs"
          :default-sort-direction="defaultSortDirection"
          :sort-icon="sortIcon"
          :sort-icon-size="sortIconSize"
          :striped="isStriped"
          :hoverable="isHoverable"
        >
          <template>
            <o-table-column
              field="SystemLogType"
              label="Type"
              width="20%"
              sortable
              v-slot="props"
              >{{ props.row.SystemLogType }}</o-table-column
            >
            <o-table-column
              field="ScreenName"
              label="CreatedBy"
              width="20%"
              sortable
              v-slot="props"
              >{{ props.row.ScreenName }}</o-table-column
            >
            <o-table-column
              field="LogText"
              label="Text"
              width="20%"
              sortable
              v-slot="props"
            >
              {{ props.row.LogText }}
            </o-table-column>
            <o-table-column
              field="LogEvent"
              label="Event"
              width="20%"
              sortable
              v-slot="props"
              >{{ props.row.LogEvent }}</o-table-column
            >
            <o-table-column
              field="LogDate"
              label="Date"
              centered
              width="20%"
              sortable
              v-slot="props"
              >{{ formatDate(props.row.LogDate) }}</o-table-column
            >
            <o-table-column
              field=""
              label=""
              position="centered"
              v-slot="props"
            >
              <button
                v-if="props.row.IsExistMailContent"
                @click="viewEmail(props.row.Id)"
                class="btn btn-info waves-effect waves-light ml-3"
              >
                <i class="fas fa-envelope-open-text"></i>
              </button>
            </o-table-column>
          </template>
        </o-table>
        <section
          v-if="sortedLogs.length == 0"
          class="section"
          style="text-align: center"
        >
          <div class="content has-text-grey has-text-centered mb-4">
            <p class="noMargin">
              <i
                style="font-size: 24px; color: #939393"
                class="ri-question-line"
              ></i>
            </p>
            <p class="noMargin">Nothing here.</p>
          </div>
        </section>
      </section>
    </div>
  </div>
</template>

<script>
import formatMixin from "@/mixins/formatMixin";
import _orderby from "lodash";
import { mapState } from "vuex";
import deepCopy from "@/helpers/deepCopy";
import dispositionTypes from "@/views/agent/Disposition/types";
import EmailLogsPopup from "./EmailLogsPopup";
export default {
  name: "ClientLogs",
  components: { EmailLogsPopup },
  props: {
    customerId: {
      type: Number,
      default: () => 0,
    },
    systemLogs: {
      type: Array,
      default: () => [],
    },
    tabClicked: {
      Type: Boolean,
      Default: false,
    },
    agentActivityReportLogs: {
      Type: Boolean,
      Default: false,
    },
  },
  watch: {
    tabClicked(newVal, oldVal) {
      if (newVal != oldVal && newVal) {
        this.watcherRefresh();
      }
    },
  },
  computed: mapState({
    sortedLogs() {
      return _orderby.orderBy(this.logs, "LogDate", "desc");
    },
  }),
  data() {
    return {
      logs: [],
      defaultSortDirection: "asc",
      sortIcon: "arrow-up",
      sortIconSize: "is-small",
      isStriped: true,
      isHoverable: true,
      isLoading: false,
      isEmpty: false,
      isEmailLogsPopupOpen: false,
      logsId: 0,
    };
  },
  unmounted() {
    // this.emptyTheObjectsOnUnmount();
  },
  async mounted() {
    //this.logs= deepCopy(this.systemLogs);

    if (this.agentActivityReportLogs) {
      let err, result;
      [err, result] = await this.$store.dispatch(
        dispositionTypes.GET_DISPOSITIONLOGS,
        this.customerId
      );
      if (result) {
        this.logs = deepCopy(result.Data);
      }
    }
  },
  methods: {
    viewEmail(id) {
      this.isEmailLogsPopupOpen = true;
      this.logsId = id;
    },
    closeEmailPopup() {
      this.isEmailLogsPopupOpen = false;
    },
    async watcherRefresh() {
      let err, result;
      [err, result] = await this.$store.dispatch(
        dispositionTypes.GET_DISPOSITIONLOGS,
        this.customerId
      );
      if (result) {
        this.logs = deepCopy(result.Data);
      }
    },
    emptyTheObjectsOnUnmount() {
      this.logs = null;
      this.defaultSortDirection = null;
      this.sortIcon = null;
      this.sortIconSize = null;
      this.isStriped = null;
      this.isHoverable = null;
      this.isLoading = null;
      this.isEmpty = null;
    },
  },
  mixins: [formatMixin],
};
</script>

<style scoped></style>
