import axios from "axios";

const API_URL = process.env.VUE_APP_IS_DEVELOPMENT == "true" ?  process.env.VUE_APP_API_DEV : process.env.VUE_APP_API;

const instance = axios.create({
  baseURL: "",
  timeout: 120000,
  responseType: "arraybuffer",
  headers: {
    "Content-Type": "application/json",
    Accept: "*/*",
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = JSON.parse(sessionStorage.userInfo).token;

    if (token !== null && token !== undefined) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  function (err) {
    return Promise.reject(err);
  }
);
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response.status === 401) {
      window.location.href("/login");
    }
    return Promise.reject(error);
  }
);
export default {
  downloadDocumentTemplate: (id) => {
    return instance.post(`${API_URL}/api/CompanyDocumentTemplate`, {
      Type: "download",
      Id: id,
    });
  },
  generateCustomerTemplate: (data) => {
    return instance.post(`${API_URL}/api/CustomerDocument/`, {
      Type: "generatetemplate",
      Data: data,
    });
  },
  downloadCustomerDocument: (data) => {
    return instance.post(`${API_URL}/api/CustomerDocument/`, {
      Type: "download",
      Data: data,
    });
  },
  downloadStatement: (data) => {
    return instance.post(`${API_URL}/api/Global/`, {
      Type: "customer.tab.statement",
      Data: data,
    });
  },

  downloadDocusignTemplate: (id) => {
    return instance.post(`${API_URL}/api/DocusignSettings`, {
      Type: "docusign.templates.download",
      Data: { Id: id },
    });
  },
  downloadCustomerGrid: (data) => {
    return instance.post(`${API_URL}/api/CustomerGridExport`, {
      Type: "exportgrid",
      Data: data,
    });
  },
};
