<template>
  <div class="col-md-12">
    <ReportSummary
      :exportData="reportData"
      :reportName="'CampaignPerformanceReport'"
      :recordCount="recordCount"
      @updateLoadingStatus="reportLoading = $event"
      @setPayload="setPayload"
      @clearReport="clearReport"
      :loadingSpinner="loadingSpinner"
    />

    <div
      class="datagrid-table gcsTabsTableScroll report-wrapper"
      :style="'max-height: ' + reportsPanelHeight + 'px; overflow-y:auto;'"
    >
      <o-table :data="filteredCampaignPerformanceReport" :sticky-header="true">
        <o-table-column
          field="CampaignName"
          label="Campaign Name"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="campaignNameFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.CampaignName }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.CampaignName }}
            </span>
          </template>
        </o-table-column>

        <o-table-column field="Agent" label="Agent" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="AgentFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            <div>
              {{ props.row.Agent }}
            </div>
          </template>
        </o-table-column>
        <o-table-column
          field="CustomerCount"
          label="Lead Count"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                style="width: 75px; margin-left: 20px"
                type="text"
                class="datagrid-table creditorTable"
                v-model="customerCountFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            <div style="margin-left: 49px">
              {{ props.row.CustomerCount }}
            </div>
          </template>
        </o-table-column>
        <o-table-column
          field="SignedCount"
          label="Signed Count"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="signedCountFilter"
                placeholder=""
                style="width: 75px; margin-left: 7px"
              />
            </div>
          </template>
          <template v-slot="props">
            <div style="margin-left: 38px">
              {{ props.row.SignedCount }}
            </div>
          </template>
        </o-table-column>
        <o-table-column
          field="SignedPercent"
          label="Signed %"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="signedPercentFilter"
                placeholder=""
                style="width: 75px; margin-left: 14px"
              />
            </div>
          </template>
          <template v-slot="props">
            <div style="margin-left: 40px">
              {{ props.row.IsSummary ? "" : props.row.SignedPercent + "%" }}
            </div>
          </template>
        </o-table-column>
        <o-table-column
          field="EnrolledCount"
          label="Enrolled Count"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="enrolledCountFilter"
                placeholder=""
                style="width: 75px; margin-left: 14px"
              />
            </div>
          </template>
          <template v-slot="props">
            <div style="margin-left: 46px">
              {{ props.row.EnrolledCount }}
            </div>
          </template>
        </o-table-column>
        <o-table-column
          field="EnrollmentPercent"
          label="Enrollment %"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="enrollmentPercentFilter"
                placeholder=""
                style="width: 75px; margin-left: 35px"
              />
            </div>
          </template>
          <template v-slot="props">
            <div style="margin-left: 63px">
              {{ props.row.IsSummary ? "" : props.row.EnrollmentPercent + "%" }}
            </div>
          </template>
        </o-table-column>
        <o-table-column
          field="EnrolledPercent"
          label="Enrolled/Lead"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="enrolledPercentFilter"
                placeholder=""
                style="width: 75px; margin-left: 19px"
              />
            </div>
          </template>
          <template v-slot="props">
            <div style="margin-left: 45px">
              {{ props.row.IsSummary ? "" : props.row.EnrolledPercent + "%" }}
            </div>
          </template>
        </o-table-column>
        <o-table-column
          field="ActiveCount"
          label="Active Count"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="activeCountFilter"
                placeholder=""
                style="width: 75px; margin-left: 5px"
              />
            </div>
          </template>
          <template v-slot="props">
            <div style="margin-left: 39px">
              {{ props.row.ActiveCount }}
            </div>
          </template>
        </o-table-column>
        <o-table-column
          field="ActiveSignPercent"
          label="Active %"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="activeSignPercentFilter"
                placeholder=""
                style="width: 75px; margin-left: 30px"
              />
            </div>
          </template>
          <template v-slot="props">
            <div style="margin-left: 59px">
              {{ props.row.IsSummary ? "" : props.row.ActiveSignPercent + "%" }}
            </div>
          </template>
        </o-table-column>
        <o-table-column
          field="ActivePercent"
          label="Active/Lead"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="activePercentFilter"
                placeholder=""
                style="width: 75px; margin-left: 15px"
              />
            </div>
          </template>
          <template v-slot="props">
            <div style="margin-left: 40px">
              {{ props.row.IsSummary ? "" : props.row.ActivePercent + "%" }}
            </div>
          </template>
        </o-table-column>
      </o-table>
      <section
        v-if="filteredCampaignPerformanceReport.length == 0"
        class="section"
        style="text-align: center"
      >
        <div class="content has-text-grey has-text-centered mb-4">
          <p class="noMargin">
            <i
              style="font-size: 24px; color: #939393"
              class="ri-question-line"
            ></i>
          </p>
          <p class="noMargin">Nothing here.</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import ReportSummary from "./ReportSummary.vue";
import utilitiesMixin from "@/mixins/utilitiesMixin";

export default {
  name: "CampaignPerformanceReport",
  components: { ReportSummary },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      reportsPanelHeight: 300,
      loadingSpinner: false,
      recordCount: 0,
      reportLoading: false,
      reportData: [],

      campaignPerformanceReportInitSummary: {
        CampaignName: "",
        CustomerCount: null,
        SignedCount: null,
        SignedPercent: null,
        EnrolledCount: null,
        EnrollmentPercent: null,
        EnrolledPercent: null,
        ActiveCount: null,
        ActiveSignPercent: null,
        ActivePercent: null,
        Agent: null,
      },

      campaignNameFilter: "",
      customerCountFilter: "",
      signedCountFilter: "",
      signedPercentFilter: "",
      enrolledCountFilter: "",
      enrollmentPercentFilter: "",
      enrolledPercentFilter: "",
      activeCountFilter: "",
      activeSignPercentFilter: "",
      activePercentFilter: "",
      AgentFilter: "",
    };
  },
  computed: mapState({
    documentCampaignPerformanceList: (state) =>
      state.reports.campaignPerformanceList,

    filteredCampaignPerformanceReport() {
      let rtn = [];

      let tmpList =
        this.documentCampaignPerformanceList.length > 0
          ? this.documentCampaignPerformanceList.filter((item) => {
              return (
                this.filterString(item.CampaignName, this.campaignNameFilter) &&
                this.filterString(item.Agent, this.AgentFilter) &&
                this.filterNumeric(
                  item.CustomerCount,
                  this.customerCountFilter
                ) &&
                this.filterNumeric(item.SignedCount, this.signedCountFilter) &&
                this.filterNumeric(
                  item.SignedPercent,
                  this.signedPercentFilter
                ) &&
                this.filterNumeric(
                  item.EnrolledCount,
                  this.enrolledCountFilter
                ) &&
                this.filterNumeric(
                  item.EnrollmentPercent,
                  this.enrollmentPercentFilter
                ) &&
                this.filterNumeric(
                  item.EnrolledPercent,
                  this.enrolledPercentFilter
                ) &&
                this.filterNumeric(item.ActiveCount, this.activeCountFilter) &&
                this.filterNumeric(
                  item.ActiveSignPercent,
                  this.activeSignPercentFilter
                ) &&
                this.filterNumeric(item.ActivePercent, this.activePercentFilter)
              );
            })
          : [];

      if (tmpList.length == 0) {
        this.reportData = [];
        return rtn;
      }
      let totalCampaign = tmpList.length.toString();

      this.campaignPerformanceReportInitSummary.CampaignName =
        "Total: " + totalCampaign;
      this.recordCount = tmpList.length;
      this.campaignPerformanceReportInitSummary.IsSummary = true;

      rtn.push(...tmpList);
      rtn.push(this.campaignPerformanceReportInitSummary);
      this.reportData = rtn;

      return rtn;
    },
  }),
  async mounted() {
    this.reportsPanelHeight = this.setReportGridHeight(
      window.innerHeight,
      document.getElementById("reportsSummary")
    );
    await this.sleep(1000);
    this.fixStickyHeaders();
  },
  methods: {
    async setPayload(value) {
      this.loadingSpinner = true;
      await this.$store.dispatch(types.GET_CAMPAIGN_PERFORMANCE_REPORT, value);
      this.loadingSpinner = false;
      await this.sleep(500);
      this.fixStickyHeaders();
    },
    clearReport() {
      this.$store.dispatch(types.RESET_REPORTS);
      this.reportData = [];
    },
  },
};
</script>

<style scoped>
.numberCentered {
  margin-left: 30px;
}
</style>
