<template>
  <div class="col-md-12">
    <ReportSummary
      :exportData="reportData"
      :reportName="'GlobalDraftsReport'"
      :recordCount="recordCount"
      @updateLoadingStatus="reportLoading = $event"
      @setPayload="setPayload"
      @clearReport="clearReport"
      @setPagination="setPagination"
      :loadingSpinner="loadingSpinner"
    />

    <div
      class="datagrid-table gcsTabsTableScroll report-wrapper"
      :style="'max-height: ' + reportsPanelHeight + 'px; overflow-y:auto;'"
    >
      <o-table
        :data="filteredGlobalDraftsReport"
        :paginated="
          isPaginated && filteredGlobalDraftsReport.length > 0
        "
        :per-page="perPage"
        :pagination-simple="isPaginationSimple"
        :pagination-position="paginationPosition"
        :sticky-header="true"
      >
        <o-table-column
          field="CustomerId"
          label="Customer Id"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CustomerIdFilter"
                placeholder=""
                v-myMask="{
                  alias: 'numeric',
                  rightAlign: false,
                }"
              />
            </div>
          </template>

          <template v-slot="props">
            <span
              @click="
                viewCustomer(props.row.CustomerId, props.row.CustomerName)
              "
              :class="
                getTagReportHyperLinkClass(
                  props.row.CustomerId && props.row.CustomerName
                )
              "
            >
              <strong v-if="props.row.IsSummary"
                >{{ props.row.CustomerId }}
              </strong>
              <span v-if="!props.row.IsSummary">
                {{ props.row.CustomerId }}
              </span>
            </span>
          </template>
        </o-table-column>
        <o-table-column
          field="CustomerName"
          label="Customer Name"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CustomerNameFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.CustomerName }}
          </template>
        </o-table-column>

        <o-table-column 
          field="OfficeName" 
          label="Office Name"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="OfficeNameFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.OfficeName }}
          </template>
        </o-table-column>

        <o-table-column field="CampaingName" label="Campaign Name" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CampaignNameFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.CampaignName }}
          </template>
        </o-table-column>

        <o-table-column field="CustomerStatus" label="Customer Status" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CustomerStatusFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.CustomerStatus }}
          </template>
        </o-table-column>

        <o-table-column field="State" label="State" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="StateFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.State }}
          </template>
        </o-table-column>

        <o-table-column field="DraftAmount" label="Draft Amount" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="DraftAmountFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.DraftAmount }}
          </template>
        </o-table-column>

        <o-table-column
          field="TransactionDate"
          label="Transaction Date"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="TransactionDateFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ formatShortDate(props.row.TransactionDate) }}
          </template>
        </o-table-column>

        <o-table-column
          field="ClearedDate"
          label="Cleared Date"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="ClearedDateFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ formatShortDate(props.row.ClearedDate) }}
          </template>
        </o-table-column>

        <o-table-column field="DraftStatus" label="Draft Status" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="DraftStatusFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.DraftStatus }}
          </template>
        </o-table-column>

        <o-table-column field="NumberScheduledDraft" label="Number of Scheduled Draft" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="NumberOfScheduledDraftsFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.NumberOfScheduledDrafts }}
          </template>
        </o-table-column>

        <o-table-column field="ProgramLength" label="Program Length" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="ProgramLengthFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.ProgramLength}}
          </template>
        </o-table-column>
      </o-table>
      <section
        v-if="filteredGlobalDraftsReport.length == 0"
        class="section"
        style="text-align: center"
      >
        <div class="content has-text-grey has-text-centered mb-4">
          <p class="noMargin">
            <i
              style="font-size: 24px; color: #939393"
              class="ri-question-line"
            ></i>
          </p>
          <p class="noMargin">Nothing here.</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import ReportSummary from "./ReportSummary.vue";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import deepCopy from "@/helpers/deepCopy";
export default {
  name: "GlobalDraftsReport",
  components: { ReportSummary },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      customerPrefix: null,
      reportsPanelHeight: 300,
      loadingSpinner: false,
      isPaginationSimple: false,
      isPaginated: true,
      paginationPosition: "both",
      currentPage: 1,
      perPage: 200,
      recordCount: 0,
      reportLoading: false,
      reportData: [],
      GlobalDraftsReportSummary: {
        CustomerId: "",
      },
      CampaignNameFilter: "",
      ClearedDateFilter: "",
      CustomerIdFilter: "",
      CustomerNameFilter: "",
      CustomerStatusFilter: "",
      DraftAmountFilter: "",
      DraftStatusFilter: "",
      NumberOfScheduledDraftsFilter: "",
      OfficeNameFilter: "",
      ProgramLengthFilter: "",
      StateFilter: "",    
      TransactionDateFilter: ""
    };
  },
  computed: mapState({
    globalDraftsReportList: (state) => state.reports.globalDraftsReportList,

    filteredGlobalDraftsReport() {
      let rtn = [];
      let tmpList =
      this.globalDraftsReportList && this.globalDraftsReportList.length > 0
          ? this.globalDraftsReportList.filter((item) => {
            return (
              this.filterString(item.CampaignName, this.CampaignNameFilter) &&
              new Date(item.ClearedDate)
                .toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  hour: "numeric",
                  minute: "2-digit",
                })
                .indexOf(this.ClearedDateFilter) > -1 &&
              this.filterString(item.CustomerId, this.CustomerIdFilter) &&
              this.filterString(item.CustomerName, this.CustomerNameFilter) &&

              this.filterString(item.CustomerStatus, this.CustomerStatusFilter) &&
              this.filterString(item.DraftAmount, this.DraftAmountFilter) &&
              this.filterString(item.DraftStatus, this.DraftStatusFilter) &&
              this.filterString(item.NumberOfScheduledDrafts, this.NumberOfScheduledDraftsFilter) &&
              this.filterString(item.OfficeName, this.OfficeNameFilter) &&
              this.filterString(item.ProgramLength, this.ProgramLengthFilter) &&
              this.filterString(item.State, this.StateFilter) &&
              new Date(item.TransactionDate)
                .toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  hour: "numeric",
                  minute: "2-digit",
                })
                .indexOf(this.TransactionDateFilter) > -1 
            );
          })
          : [];

      if (tmpList.length == 0) {
        this.reportData = [];
        return rtn;
      }
      let totalCustomer = tmpList.length.toString();

      this.GlobalDraftsReportSummary.CustomerId =
        "Total: " + totalCustomer;
      this.recordCount = tmpList.length;
      this.GlobalDraftsReportSummary.IsSummary = true;

      rtn.push(...tmpList);
      rtn.push(this.GlobalDraftsReportSummary);
      this.reportData = rtn;

      return rtn;
    },
  }),
  async mounted() {
    this.reportsPanelHeight = this.setReportGridHeight(
      window.innerHeight,
      document.getElementById("reportsSummary")
    );
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

    if (
      this.userInfo != null &&
      this.userInfo.customerIdPrefix != null &&
      this.userInfo.customerIdPrefix.length > 0
    ) {
      this.customerPrefix = this.userInfo.customerIdPrefix;
    }
    await this.sleep(1000);
    this.fixStickyHeaders();
  },
  methods: {
    addPrefixToCustomerId(customerId) {
      let rtn = customerId.toString();
      if (this.customerPrefix != null) {
        rtn = this.customerPrefix + rtn;
      }
      return rtn;
    },
    setPagination(data) {
      this.isPaginated = data;
    },
    async setPayload(value) {
      this.loadingSpinner = true;
      await this.$store.dispatch(types.GET_GLOBAL_DRAFTS_REPORT, value);
      this.loadingSpinner = false;

      await this.sleep(500);
      this.fixStickyHeaders();
    },
    clearReport() {
      this.$store.dispatch(types.RESET_REPORTS);
      this.reportData = [];
    },
    async viewCustomer(id, name) {
      this.$Emitter.emit("viewCustomer", {
        customerId: id.replace(/\D/g, ""),
        customerName: name,
      });
    },
  },
};
</script>

<style scoped></style>
