<template>
  <div
    class="col-md-12 newPanelWrapper noMargin"
    style="background-color: #ffffff; z-index: 1000"
  >
    <div
      class="closeButton"
      style="right: 5px"
      v-on:click.prevent.stop="closePanel"
    >
      <i class="fas fa-window-close"></i>
    </div>

    <div v-if="isLoading" class="newLoading">
      <div class="text-center" style="margin-top: 275px">
        <i class="spinner-grow text-info m-2"></i>
      </div>
    </div>
    <div
      class="row"
      style="max-height: 92vh; overflow-x: hidden; overflow-y: auto"
    >
      <div class="col-md-12">
        <div style="text-align: center">
          <label
            class="generalLabel"
            style="font-size: 22px; text-decoration: underline"
            >Void {{ creditorName }} {{ creditorAcctNumber }} Settlement?</label
          >
          <p class="generalLabel" style="color: red">
            Voiding this settlement will create pending payment and fee tasks, which need to be approve to sync changes to the payment processor.
          </p>
        </div>

        <div class="col-md-12 row mt-3">
          <div class="col-md-12 noPadding">
            <div class="col-md-12 noPadding mb-2">
              <label class="generalLabel" style="font-size: 15px"
                >First Payment Cleared:
              </label>
              <label class="generalLabel" style="font-size: 15px"
                >{{ hasClearedPayment ? " Yes" : " No" }}
              </label>
            </div>

            <div class="row">
              <div class="col-md-3">
              <label
                class="generalLabel"
                style="text-decoration: underline; font-size: 15px"
                >Settlement Void Options:
              </label>
             </div>
              <div class="col-md-3">
                <div class="row">
              <div class="form-check mr-1">
                <input
                  class="form-check-input"
                  :class="canChangeFeeSchedule ? '' : 'readonlyInput'"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio1"
                  value="keep"
                  :disabled="!canChangeFeeSchedule"
                  v-model="selectedOption"
                  @change="updateFeeSchedule()"
                />
              </div>
              <label
                class="generalLabel form-check-label"
                :class="canChangeFeeSchedule ? '' : 'readonlyInput'"
                style="margin-top: 2px"
                for="inlineRadio1"
                >Keep fee schedule</label
              >
            </div>
              </div>
              <div class="col-md-3">
                <div class="row">
              <div class="form-check mr-1">
                <input
                  class="form-check-input"
                  :class="canChangeFeeSchedule ? '' : 'readonlyInput'"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio2"
                  value="void"
                  :disabled="!canChangeFeeSchedule"
                  v-model="selectedOption"
                  @change="updateFeeSchedule()"
                />
              </div>
              <label
                class="generalLabel form-check-label"
                :class="canChangeFeeSchedule ? '' : 'readonlyInput'"
                for="inlineRadio2"
                style="margin-top: 2px"
                >Void fee schedule</label
              >
            </div>
              </div>
              <div class="col-md-3">
                <div class="row">
              <div class="form-check mr-1">
                <input
                  class="form-check-input"
                  :class="canChangeFeeSchedule ? '' : 'readonlyInput'"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio3"
                  value="edit"
                  :disabled="!canChangeFeeSchedule"
                  v-model="selectedOption"
                  @change="updateFeeSchedule()"
                />
              </div>
              <label
                class="generalLabel form-check-label"
                :class="canChangeFeeSchedule ? '' : 'readonlyInput'"
                for="inlineRadio3"
                style="margin-top: 2px"
                >Edit fee schedule</label
              >
            </div>

              </div>



            </div>

           
         
          
          </div>

          <div class="col-md-12 noPadding">
            <div class="row">
                  <div class="col-md-12">
              <label class="generalLabel" style="font-size: 15px"
                >Cleared Payments: {{ formatMoney(totalClearedPayments()) }} /
                {{ totalPaymentAmount() }} 
              </label>
            </div>
            </div>
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-12 col-12">
            
            <div class=" mt-2">
              <label class="generalLabel" style="font-size: 15px"
                >Payments:
              </label>
            </div>
            <perfect-scrollbar
              style="
                width: 100%;
                max-height: 300px;

                overflow-x: hidden !important;
                overflow-y: auto;
              "
            >
              <table
                class="datagrid-table"
                style="width: 100%"
                v-if="!isLoading"
              >
                <thead>
                  <tr>
                    <th style="padding: 0"></th>
                    <th class="text-left" style="padding: 10px">Date</th>
                    <th class="text-left" style="padding: 10px">Pmt Amount</th>
                    <th class="text-left" style="padding: 10px">Status</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(item, index) in sortedPaymentList"
                    :key="item.PaymentId"
                  >
                    <td>{{ index + 1 }}.</td>
                    <td class="text-left" style="padding: 5px">
                      <span class="tabledit-span tabledit-identifier">
                        {{ formatShortDate(item.EffectiveDate) }}
                      </span>
                    </td>

                    <td class="text-left" style="padding: 5px 10px">
                      <span class="tabledit-span tabledit-identifier">
                        {{ formatMoney(item.PaymentAmount) }}
                      </span>
                    </td>

                    <td class="text-left" style="padding: 5px 10px">
                      <span class="tabledit-span tabledit-identifier">
                        {{ this.getPaymentStatus(item.PaymentStatus,false) }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </perfect-scrollbar>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-12 col-12">
                <div class=" mt-2">
              <label class="generalLabel" style="font-size: 15px"
                >Fees:
              </label>
            </div>
            <perfect-scrollbar
              style="
                width: 100%;
                height: 300px;

                overflow-x: hidden !important;
                overflow-y: auto;
              "
            >
              <table
                class="datagrid-table"
                style="width: 100%"
                v-if="!isLoading"
              >
                <thead>
                  <tr>
                    <th style="padding: 0"></th>
                    <th class="text-left" style="padding: 10px">Date</th>
                    <th class="text-left" style="padding: 10px">Fee Amount</th>
                    <th class="text-left" style="padding: 10px">Fee Type</th>
                    <th class="text-left" style="padding: 10px">Status</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(item, index) in sortedFeeList"
                    :key="index"
                  >
                    <td>{{ index + 1 }}.</td>
                    <td class="text-left" style="padding: 5px">
                      <span class="tabledit-span tabledit-identifier">
                        {{ formatShortDate(item.EffectiveDate) }}
                      </span>
                    </td>

                    <td class="text-left" style="padding: 5px 10px">
                      <span class="tabledit-span tabledit-identifier">
                        {{ formatMoney(item.Amount)   }}
                      </span>
                    </td>

                    <td class="text-left" style="padding: 5px 10px">
                      <span class="tabledit-span tabledit-identifier">
                        {{ item.Type }}
                      </span>
                    </td>
                    <td class="text-left" style="padding: 5px 10px">
                      <span class="tabledit-span tabledit-identifier">
                        {{ item.FeeStatus }}
                      </span>
                    </td>
                    <td class="text-left" style="padding: 5px">
                      <button
                        v-if="selectedOption == 'edit' && item.Active == 'Y'"
                        type="button"
                        title="Edit"
                        v-on:click.prevent.stop="handleEdit(item)"
                        class="btn"
                        style="
                          height: 22px;
                          width: 22px;
                          font-size: 15px;
                          background-color: #24d171;
                        "
                      >
                        <i class="ri-edit-line" style=""></i>
                      </button>
                    </td>
                    <td class="text-left" style="padding: 5px">
                      <button
                        v-if="item.DebitId < 0 && selectedOption == 'edit'"
                        type="button"
                        title="Edit"
                        v-on:click.prevent.stop="handleRemove(item)"
                        class="btn"
                        style="
                          background-color: rgb(237, 48, 48);
                          height: 22px;
                          width: 22px;
                          font-size: 15px;
                        "
                      >
                        <i class="ri-delete-bin-line"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </perfect-scrollbar>
            <div
              class="col-md-12 noPadding text-right mt-1"
              v-if="selectedOption == 'edit'"
            >
              <div class="row">
                <div class="col-md-12" v-if="allPayments.length > 0">
                  <span
                    title="Add new fee"
                    style="cursor: pointer; margin: 5px 0; padding: 8px"
                    class="badge badge-success badgeStatusPadding"
                    @click="addNewRow()"
                    >Add Fees</span
                  >
                </div>
              </div>
            </div>
            </div>

            </div>
            
           
          </div>
        </div>
      </div>

      <div v-showIfAuthorized="54983" class="col-md-12 mt-1">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-4">
              <label class="generalLabel">Fee Type</label>
              <select class="form-control" v-model="extraFees.GlobalFeeType">
                <option :value="null">Please Select</option>
                <option
                  v-for="item in applicableFeeTypes"
                  :value="item.FeeType"
                  :key="item.FeeType"
                >
                  {{ item.Description }}
                </option>
              </select>
            </div>
            <div class="col-md-3">
              <label for="serviceFee" class="generalLabel">Amount</label>
              <input
                v-model="extraFees.FeeAmount"
                class="form-control"
                v-myMask="{
                  alias: 'currency',
                  prefix: '$',
                  rightAlign: false,
                }"
                placeholder="$0.00"
              />
            </div>
            <div class="col-md-3">
              <label for="serviceFee" class="generalLabel">Date</label>

              <DatePicker
                mode="date"
                :locale="'en-US'"
                v-model="extraFees.FeeDate"
                :model-config="modelConfig"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                    id="DCP-feeDate"
                    class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                    :value="inputValue"
                    v-on="inputEvents"
                    placeholder="Select Date"
                  />
                </template>
              </DatePicker>
            </div>
            <div class="col-md-1" style="padding-top: 28px">
              <button
                type="button"
                @click="addExtraFee"
                class="btn btn-success waves-effect waves-light"
              >
                <i class="fas fa-plus-circle"></i>
              </button>
            </div>
            <div class="col-md-12">
              <perfect-scrollbar
                style="
                  width: 100%;
                  max-height: 500px;
                  overflow-x: hidden !important;
                "
              >
                <div class="col-md-12">
                  <label
                    v-for="(item, index) in paymentChangeData.ApplicableFees"
                    :key="index"
                  >
                    <div style="display: flex; align-items: stretch">
                      <label class="generalLabel pr-2"
                        >Fee Type:<label class="ml-1" style="color: #6c757d">{{
                          item.GlobalFeeType
                        }}</label></label
                      >
                      <label class="generalLabel pr-2"
                        >Amount:<label class="ml-1" style="color: #6c757d">{{
                          formatMoney(item.FeeAmount)
                        }}</label></label
                      >
                      <label class="generalLabel pr-1"
                        >Date:<label class="ml-1" style="color: #6c757d">{{
                          item.FeeDate
                        }}</label></label
                      >

                      <span
                        style="padding: 5px; cursor: pointer; height: 20px"
                        class="badge badge-danger"
                        @click="deleteExtraFee(index)"
                      >
                        Cancel</span
                      >
                    </div>
                  </label>
                </div>
              </perfect-scrollbar>
            </div>
            <div class="col-md-12">
              <label class="generalLabel">Notes:</label>
              <textarea
                v-model="paymentChangeData.Notes"
                placeholder="Notes here..."
                class="form-control textareaInput generalTextBox"
                rows="4"
              ></textarea>
            </div>
          </div>

          <div
            class="col-md-12"
            style="
              display: flex;
              justify-content: flex-end;
              align-items: center;
            "
          >
            <input
              v-model="paymentChangeData.IsEmergency"
              type="checkbox"
              id="emergencyCBox1"
              class="checkbox-input generalCheckBox"
              style="
                border-radius: 4px !important;
                border: solid 1px #d1d1d1 !important;
              "
            />
            <label class="generalLabel mt-1 ml-1">Emergency (2 B.days)</label>
          </div>
        </div>
      </div>

      <div class="col-md-12 text-right mt-1 mb-1">
        <button
          type="button"
          class="btn btn-success waves-effect waves-light ml-2"
          :class="checkAuth(12411) ? 'col-md-6' : 'col-md-3'"
          @click="submitChanges()"
          :disabled="isLoading"
        >
          {{ checkAuth(12411) ? "Request and Approve" : "Submit" }}
        </button>
      </div>
    </div>
    <div
      class="backgroundLock"
      v-if="isEditPopupOpen"
      style="-webkit-box-align: center; align-items: center; display: flex"
    >
      <div
        style="position: relative; margin: 10% auto"
        class="offset-lg-2 col-xl-4 offset-lg-2 col-lg-8 col-md-12 col-sm-12"
      >
        <VoidSettlementEditPopup
          :feeDate="selectedItem.EffectiveDate"
          :feeAmount="selectedItem.Amount"
          :debitId="selectedItem.DebitId"
          :paymentId="selectedItem.PaymentId"
          :isNewRow="isNewRow"
          :feeTypeList="feeTypeList"
          @closeVoidSettlementEditPopup="closeVoidSettlementEditPopup"
        />
      </div>
    </div>
  </div>
</template>

<script>
import types from "./types";
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import _orderby from "lodash";
import { DatePicker } from "v-calendar";
import deepCopy from "@/helpers/deepCopy";
import VoidSettlementEditPopup from "./VoidSettlementEditPopup.vue";
export default {
  name: "VoidSettlementsPopUp",
  components: { DatePicker, VoidSettlementEditPopup },
  mixins: [formatMixin, utilitiesMixin],
  props: {
    customerId: {
      type: Number,
      default: () => 0,
    },
    officeId: {
      type: Number,
      default: () => 0,
    },
    creditorId: {
      type: Number,
      default: () => 0,
    },
    creditorName: {
      type: String,
      default: () => "",
    },
    creditorAcctNumber: {
      type: String,
      default: () => "",
    },
    paymentProcessorId: {
      type: Number,
      default: () => 0,
    },
  },
  computed: {
    sortedPaymentList() {
      let sortedList = [...this.allPayments];

      sortedList = sortedList.filter(x=> parseFloat(x.PaymentAmount) > 0);
      if(this.paymentProcessorId == 3)
      {
        sortedList = sortedList.filter(x =>  x.Active == "Y" && ( x.PaymentStatus== "PENDING" ||  x.PaymentStatus== "SCHEDULED")); 
      }
      else
      {
        sortedList = sortedList.filter(x => (x.Active == "N" && x.PaymentStatusCode.startsWith("C")) || x.Active == "Y");
      }

      sortedList.sort((a, b) => {
        let dateA = new Date(a.EffectiveDate);
        let dateB = new Date(b.EffectiveDate);
        return dateA - dateB;
      });

      return sortedList;
    },
    sortedFeeList() {
      let sortedList = [...this.feeList];
      sortedList.sort((a, b) => {
        let dateA = new Date(a.EffectiveDate);
        let dateB = new Date(b.EffectiveDate);
        return dateA - dateB;
      });

      return sortedList;
    },
    hasClearedPayment() {
      return this.allPayments.some((x) => {
        return x.Active == "N" && x.PaymentStatusCode.startsWith("C");
      });
    },
  },
  data() {
    return {
      selectedOption: "void",
      isLoading: false,
      isEditPopupOpen: false,
      oldPaymentDate: null,
      modelConfig: {
        type: "string",
        mask: "MM/DD/YYYY",
      },
      feeTypeList: [],
      applicableFeeTypes:[],
      paymentList: [],
      allPayments: [],
      feeList:[],
      feeListOld: [],
      isNewRow: false,
      selectedItem: {
        EffectiveDate: null,
        Amount: 0,
        PaymentId: 0,
        DebitId:0,
        AddressId: 0,
        BankId: 0,
        ContactId: 0,
      },
      extraFees: {
        GlobalFeeType: null,
        FeeAmount: 0,
        FeeDate: "",
      },
      paymentChangeData: {
        Notes: "",
        IsEmergency: false,
        ApplicableFees: [],
      },
      paymentTypes: [],
      canChangeFeeSchedule: false,
    };
  },

  async mounted() {
    if (this.customerId > 0 && this.officeId > 0) {
      this.getFeeTypes();
      this.getPaymentTypes();
      if (this.creditorId > 0) {
        await this.getPayments();
        await this.getFees();
        this.updateFeeSchedule();
      }
    }
    this.canChangeFeeSchedule = this.checkAuth(56963);
  },

  methods: {
    getPaymentStatus(status,isFee)
    {
      let rtn = ""; 
      if(status && status.length > 0)
      {
        let arr = status.split("/");
        if(arr)
        {
           if(isFee == false && arr.length > 0)
           {
             rtn = arr[0];
           }
           else if(arr.length > 1 && isFee== true)
           {
             rtn = arr[1];
           }
          
        }
      }

      return rtn;

    },
    totalClearedPayments() {
      let clearedPayments = this.allPayments.filter(
        (x) =>
          x.PaymentStatus && this.getPaymentStatus(x.PaymentStatus,false).toLowerCase().includes("cleared")
      );

      let totalClearedPaymentAmount = clearedPayments.reduce(
        (total, payment) => total + payment.PaymentAmount,
        0
      );

      return totalClearedPaymentAmount;
    },

    totalPaymentAmount() {
      let rtn="$0.00";
      let totalPaymentAmount = this.allPayments.reduce(
        (total, payment) => total + parseFloat(payment.PaymentAmount),
        0
      );
      if(totalPaymentAmount > 0)
      {
         rtn = this.formatMoney(totalPaymentAmount);
      }
      return rtn;
    },

    closePanel() {
      this.$emit("closeVoidSettlementsPopUp", false);
    },
    getPaymentFeeAmount(paymentId){

      let rtn = 0;
      let paymentRelatedFees = this.feeList.filter(x=>  x.PaymentId  == paymentId && x.DebitId == 0);
      if(paymentRelatedFees.length > 0)
      {
        rtn = parseFloat(paymentRelatedFees[0].Amount);
      }

      return rtn;

    },
    getFeeTypeKey(value) {
      let rtn = "";
      if (this.feeTypeList.length > 0 && value != "") {
        let index = this.feeTypeList.findIndex((x) => x.Description == value);
        if (index > -1) {
          rtn = this.feeTypeList[index].FeeType;
        }
      }
      return rtn;
    },
    getFeeTypeName(value) {
      let rtn = "";
      if (this.feeTypeList.length > 0 && value != "") {
        let index = this.feeTypeList.findIndex((x) => x.FeeType == value);
        if (index > -1) {
          rtn = this.feeTypeList[index].Description;
        }
      }
      return rtn;
    },
    async submitChanges() {
      this.isLoading = true;
      let orderedPayments = _orderby.orderBy(
        this.allPayments,
        "EffectiveDate",
        "desc"
      );

      let latestPaymentTypeId = 0;
      let latestPayment = null;
      if (orderedPayments && orderedPayments.length > 0) {
        latestPayment = orderedPayments[0];
        latestPaymentTypeId = this.getPaymentTypeKey(latestPayment.PaymentType);
      }

      let paymentChanges = [];
      let feeChanges = [];

      let seperateFees = this.feeList.filter(x=> x.Active == "Y" && x.DebitId > 0 && x.IsChanged == true);

      let addedFees = this.feeList.filter(x=> x.DebitId < 0 && x.IsChanged == true);

      if(seperateFees.length > 0)
      {
         for(let sfee of seperateFees)
         {
          feeChanges.push({

            OriginalDate: this.getOldFeeDate(sfee.DebitId),
            FeeDate: this.formatShortDate(sfee.EffectiveDate),
            GlobalDebitId:
            sfee.DebitId > 0 ? sfee.DebitId : 0,
            FeeAmount: parseFloat(sfee.Amount),
            GlobalFeeType: this.getFeeTypeKey(sfee.Type),
            CreditorId: parseInt(sfee.CreditorId),

          });
         }
      }
      if(addedFees.length > 0)
      {
         for(let afee of addedFees)
         {
          feeChanges.push({

            OriginalDate: this.formatShortDate(afee.EffectiveDate),
            FeeDate: this.formatShortDate(afee.EffectiveDate),
            GlobalDebitId:
            afee.DebitId > 0 ? afee.DebitId : 0,
            FeeAmount: parseFloat(afee.Amount),
            GlobalFeeType: this.getFeeTypeKey(afee.Type),
            CreditorId: parseInt(afee.CreditorId),

          });
         }
      }



      if (this.allPayments.length > 0) {
        for (let item of this.allPayments.filter((x) => x.Active == "Y")) {
          paymentChanges.push({
            EffectiveDate: this.formatShortDate(
              this.getOldEffectiveDate(item.PaymentId)
            ),
            NewDate: this.formatShortDate(item.EffectiveDate),
            PaymentId: item.PaymentId,
            PaymentAmount: 0,
            FeeAmount: this.getPaymentFeeAmount(item.PaymentId),
            PaymentType: item.PaymentType,
            PaymentTypeId: latestPaymentTypeId,
            PayeeName: item.Payee,
            PayeeId: item.PayeeId,
            PayeeAccountNumber: item.AccountNumber,
            CreditorId: this.creditorId,
            SettlementId: this.creditorId,
            PayeeAddressId: latestPayment ? latestPayment.AddressId : 0,
            PayeeBankId: latestPayment ? latestPayment.BankId : 0,
            PayeeContactId: latestPayment ? latestPayment.ContactId : 0,
          });
        }
      }



      let paymentSuccess= true;
      let feeSuccess = true;
      let paymentErrors = "";
      let feeErrors ="";
      if(paymentChanges.length > 0)
      {
            let err, result;
      [err, result] = await this.$store.dispatch(types.CREATE_PAYMENT_CHANGE, {
        CustomerId: this.customerId,
        Notes: this.paymentChangeData.Notes,
        IsEmergency: this.paymentChangeData.IsEmergency,
        ChangeDetails: paymentChanges,
        ApplicableFees: this.paymentChangeData.ApplicableFees,
      });

      if (result) {
        
        this.paymentChangeData.Notes = "";
        this.paymentChangeData.IsEmergency = false;
        this.paymentChangeData.ApplicableFees = [];

      } else {
        paymentErrors = this.getApiErrorMessage(err);
        paymentSuccess = false;
      }
      }

      if(feeChanges.length > 0)
      {
            let err, result;
            [err, result] = await this.$store.dispatch(
              types.CREATE_FEE_CHANGE,
              {
                CustomerId: this.customerId,
                Notes: this.paymentChangeData.Notes,
                IsEmergency: this.paymentChangeData.IsEmergency,
                ChangeDetails: feeChanges,
                ApplicableFees: [],
                IsVoid: this.selectedOption == "void" ?  true: false,
                CreditorId: this.creditorId,
              }
            );

              if (result) {
            
              this.paymentChangeData.Notes = "";
              this.paymentChangeData.IsEmergency = false;
              this.paymentChangeData.ApplicableFees = [];

            } else {
              feeErrors = this.getApiErrorMessage(err);
                feeSuccess = false;
            }
      }



      if(paymentSuccess && feeSuccess)
      {
        let successMessage = this.checkAuth(12411)
          ? "The requested changes are approved."
          : "Payment changes and fee changes requested.";
        this.$swal("Success!", successMessage, "success");
      }
      else if(paymentSuccess && !feeSuccess)
      {
        let successMessage = this.checkAuth(12411)
          ? "The requested changes are approved with fee error:  " + feeErrors
          : "Payment changes requested. Fee Error: " + feeErrors;
        this.$swal("Success!", successMessage, "success");
      }
      else if(!paymentSuccess && feeSuccess)
      {
        this.$swal("Error!", paymentErrors, "error");
      }
      else if(!paymentSuccess && !feeSuccess)
      {
        let message = "Payment Error: "+ paymentErrors + " Fee Error: " + feeErrors;
        this.$swal("Error!", message, "error");
      }
      

      await this.sleep(2000);
      this.$emit("closeVoidSettlementsPopUp", true);
    
      this.isLoading = false;
    },
    async getPaymentTypes() {
      if (this.paymentTypes.length > 0) return;

      let err, result;
      [err, result] = await this.$store.dispatch(
        types.GET_PAYMENT_CHANGES_PAYMENT_TYPES
      );
      if (result && result.Data) {
        this.paymentTypes = deepCopy(result.Data);
      }
    },
    getPaymentTypeKey(value) {
      let rtn = 0;

      if (value && value.includes("Phone Pay")) {
        rtn = 6; //Check by Phone
      } else if (this.paymentTypes.length > 0 && value != "") {
        let index = this.paymentTypes.findIndex((x) => x.Name == value);
        if (index > -1) {
          rtn = this.paymentTypes[index].Id;
        }
      }
      return rtn;
    },
    updateFeeSchedule() {
      if (this.selectedOption == "keep" || this.selectedOption == "edit") {
      
        for (let item of this.feeList) {
          let indx = this.feeListOld.findIndex(
            (x) => (x.DebitId > 0 && x.DebitId == item.DebitId) || (x.DebitId == 0 && x.PaymentId == item.PaymentId)
          );
      
          if (indx > -1) {
            item.Amount = this.feeListOld[indx].Amount;
            item.IsChanged = false;
          }
         
        }
      } else if (this.selectedOption == "void") {
        

        if(this.feeList && this.feeList.length > 0)
        {
          for (let fee of this.feeList) {
          if (fee.Active == "Y") {
            fee.Amount = 0;
            fee.IsChanged = true;
          }
        }
        }

      }
    },
    getOldEffectiveDate(paymentId) {
      let rtn = "";
      let indx = this.allPaymentsOldData.findIndex((x) => x.PaymentId == paymentId);
      if (indx > -1) {
        rtn = this.allPaymentsOldData[indx].EffectiveDate;
      }

      return rtn;
    },
    getOldFeeDate(debitId) {
      let rtn = "";
      let indx = this.feeListOld.findIndex((x) => x.DebitId == debitId);
      if (indx > -1) {
        rtn = this.formatShortDate(this.feeListOld[indx].EffectiveDate);
      }

      return rtn;
    },
    
    addExtraFee() {
      if (
        this.extraFees.GlobalFeeType != null &&
        this.extraFees.FeeAmount > 0 &&
        this.extraFees.FeeDate != "" &&
        new Date(this.extraFees.FeeDate) > new Date(1970, 1, 1)
      ) {
        let item = deepCopy(this.extraFees);
        item.FeeDate = new Date(item.FeeDate).toString("M/d/yyyy");
        item.FeeAmount = parseFloat(item.FeeAmount);

        this.paymentChangeData.ApplicableFees.push(item);
        this.extraFees.GlobalFeeType = null;
        this.extraFees.FeeAmount = 0;
        this.extraFees.FeeDate = "";
      } else {
        this.$swal(
          "Warning!",
          "Fee type, amount and date fields are required.",
          "warning"
        );
      }
    },
    deleteExtraFee(indx) {
      if (indx > -1) {
        this.paymentChangeData.ApplicableFees.splice(indx, 1);
      }
    },
    addNewRow() {
      this.selectedItem.EffectiveDate = "";
      this.selectedItem.Amount = 0;
      this.selectedItem.PaymentId = 0;
      this.selectedItem.DebitId = 0;
      this.selectedItem.AddressId = 0;
      this.selectedItem.BankId = 0;
      this.selectedItem.ContactId = 0;
      this.isNewRow = true;
      this.isEditPopupOpen = true;
    },
    keyPress(event, item) {
      if (event.keyCode == 13) {
        this.handleEdit(item);
      }
    },
    handleEdit(row) {
      this.isNewRow = false;
      this.selectedItem.EffectiveDate = row.EffectiveDate;
      this.selectedItem.Amount = row.Amount;
      this.selectedItem.PaymentId = row.PaymentId;
      this.selectedItem.DebitId = row.DebitId;
      this.selectedItem.AddressId = row.AddressId;
      this.selectedItem.BankId = row.BankId;
      this.selectedItem.ContactId = row.ContactId;
      this.isEditPopupOpen = true;
    },
    handleRemove(item) {
      let indx = this.feeList.findIndex(
        (x) => x.DebitId == item.DebitId
      );
      if (indx > -1) {
        this.feeList.splice(indx, 1);
      }
    },
    getNewDebitId() {
      let rtn = -1;
      let list = this.feeList.filter((x) => x.DebitId < 0);

      rtn = list.length > 0 ? rtn - 1 : -1;

      return rtn;
    },
    closeVoidSettlementEditPopup(data) {
      this.isEditPopupOpen = false;
      if (data) {
        this.isLoading = true;
        if (data.IsNewRow) {

            this.feeList.push({

              DebitId: this.getNewDebitId(),
              EffectiveDate: data.EffectiveDate,
              Amount: data.Amount,
              CreditorId: this.creditorId,
              Type: this.getFeeTypeName(data.GlobalFeeType),
              Active: "Y",
              IsChanged: true
            });
          
        } else {

        
          if(data.DebitId > 0)
          {
                let indx = this.feeList.findIndex(
                (x) => x.DebitId == data.DebitId
              );
              if (indx > -1) {

        
                if(this.formatShortDate(this.feeList[indx].EffectiveDate) != this.formatShortDate(data.EffectiveDate) || 
                parseFloat(this.feeList[indx].Amount) != parseFloat(data.Amount)
                )
                {

                 
                  this.feeList[indx].EffectiveDate = data.EffectiveDate;
                  this.feeList[indx].Amount = data.Amount;
                  this.feeList[indx].IsChanged = true;
                  this.feeList[indx].Type = this.getFeeTypeName(data.GlobalFeeType);
              
                }

            
              }
          }
          else{

            let indx = this.feeList.findIndex(
                (x) => x.PaymentId == data.PaymentId
              );
              if (indx > -1) {
      
                if(this.formatShortDate(this.feeList[indx].EffectiveDate) != this.formatShortDate(data.EffectiveDate) || 
                parseFloat(this.feeList[indx].Amount) != parseFloat(data.Amount)
                )
                {
                  this.feeList[indx].EffectiveDate = data.EffectiveDate;
                  this.feeList[indx].Amount = data.Amount;
                  this.feeList[indx].Type = this.getFeeTypeName(data.GlobalFeeType);
                  this.feeList[indx].IsChanged = true;
           
                }
              }
          }

          
        }
        this.isLoading = false;
      }
    },
    async getFeeTypes() {
      if (this.feeTypeList.length > 0) return;

      let err, result;
      [err, result] = await this.$store.dispatch(types.GET_FEES_TYPES, {
        CustomerId: this.customerId,
        OfficeId: this.officeId,
      });
      if (result && result.Data) {
        this.feeTypeList = deepCopy(result.Data);
        if(this.feeTypeList && this.feeTypeList.length >0)
        {
          this.applicableFeeTypes = this.feeTypeList.filter(x=> x.IsSettlementFee == false);
        }
      }
    },
    async getPayments() {
      let err, result;
      this.isLoading = true;
      [err, result] = await this.$store.dispatch(
        types.GET_PAYMENT_CHANGES_GRID_WITH_CREDITOR,
        {
          CustomerId: this.customerId,
          OfficeId: this.officeId,
          CreditorId: this.creditorId,
        }
      );
      if (result && result.Data) {
        this.allPayments = deepCopy(result.Data);
        this.allPaymentsOldData = deepCopy(result.Data);
        this.paymentList = deepCopy(result.Data.filter((x) => x.Active == "Y"));
        this.oldData = deepCopy(result.Data.filter((x) => x.Active == "Y"));


        if(this.totalClearedPayments() > 0)
        {
            this.selectedOption = "keep";
        }
        else
        {
          this.selectedOption = "void";
        }
    
       

      }
      this.isLoading = false;
    },
    async getFees() {
      let err, result;
      this.isLoading = true;


      let payload = {
        CustomerId: this.customerId,
        OfficeId: this.officeId,
      }
      if(this.paymentProcessorId == 3)
      {
        payload = {
        CustomerId: this.customerId,
        OfficeId: this.officeId,
        CreditorId: this.creditorId
      }
      }

      [err, result] = await this.$store.dispatch(types.GET_FEE_LIST,payload);
      if (result && result.Data) {

        this.feeList = deepCopy(result.Data.filter((x) => x.Active == "Y" && parseInt(x.CreditorId) == this.creditorId));

      }

      let paymentRelatedFees = this.allPayments.filter(x=> x.FeeAmount > 0);
      for(let item of paymentRelatedFees)
      {

        this.feeList.push({ 
          DebitId: 0,
          EffectiveDate: item.EffectiveDate,
          Amount: item.FeeAmount,
          Type: "Payment Fee",
          FeeStatus: this.getPaymentStatus(item.PaymentStatus,true),
          CreditorId: item.PayeeId,
          CreditorName: item.Payee,
          Active: item.Active,
          PaymentId: item.PaymentId,

         });
      }

      this.feeListOld = deepCopy(this.feeList);

      this.isLoading = false;
    },
  },

  
};
</script>

<style scoped>
.closeButtonBulk {
  z-index: 1000;
  font-size: 30px;
  cursor: pointer;
}
.closeButtonBulk:hover {
  color: #ff3860;
}

.datagrid-table thead th {
  padding: 2px;
}
</style>
