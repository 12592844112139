<template>
  <div
    class="col-md-12 newPanelWrapper noMargin"
    style="background-color: #ffffff; z-index: 1000"
  >
    <div v-if="isLoading" class="newLoading">
      <div class="text-center" style="margin-top: 200px">
        <i class="spinner-grow text-info m-2"></i>
      </div>
    </div>
    <div style="display: flex" class="mb-4">
      <div style="position: absolute; top: 6px">
        <label>{{
          isWithdrawal != true ? "Cancelation Form" : "Create a Withdrawal"
        }}</label>
      </div>
      <div class="closeButton" v-on:click.prevent.stop="closePanel">
        <i class="fas fa-window-close"></i>
      </div>
    </div>

    <perfect-scrollbar style="max-height: 100%">
      <div class="col-md-12 mb-2 row">
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12 noPadding">
          <div class="col-md-12" style="display: flex; gap: 90px">
            <div>
              <label class="generalLabel">Balance:</label>
            </div>
            <div>
              <label
                class="generalLabel"
                :style="
                  cancellationInfoList.GlobalBalance < 0
                    ? 'color:red'
                    : 'color:black'
                "
                >{{
                  isNaN(cancellationInfoList.GlobalBalance)
                    ? "$0.00"
                    : formatMoney(cancellationInfoList.GlobalBalance)
                }}</label
              >
            </div>
          </div>
          <div class="col-md-12" style="display: flex; gap: 27px">
            <div>
              <label class="generalLabel">Pending Balance:</label>
            </div>
            <div>
              <label
                class="generalLabel"
                :style="
                  cancellationInfoList.PendingBalance < 0
                    ? 'color:red'
                    : 'color:black'
                "
                >{{
                  isNaN(cancellationInfoList.PendingBalance)
                    ? "$0.00"
                    : formatMoney(cancellationInfoList.PendingBalance)
                }}</label
              >
            </div>
          </div>
          <div
            class="col-md-12"
            style="display: flex; gap: 71px"
            v-if="isWithdrawal != true"
          >
            <div>
              <label class="generalLabel">Account Fee:</label>
            </div>
            <div>
              <label
                v-if="cancellationInfoList.AccountFee"
                class="generalLabel"
                :style="
                  cancellationInfoList.AccountFee < 0
                    ? 'color:red'
                    : 'color:black'
                "
                >{{ formatMoney(cancellationInfoList.AccountFee) }}</label
              >
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="col-md-12 noPadding mb-2">
            <label class="generalLabel">{{
              isWithdrawal != true ? "Reason" : "Notes"
            }}</label>
            <select
              v-if="filteredReasonsList && isWithdrawal != true"
              class="form-control"
              v-model="clientStatusChangeData.CancelationReasonId"
            >
              <option :value="null">Please select a reason</option>
              <option
                v-for="item in reasonsList"
                :key="item.Id"
                :value="item.Id"
                v-show="item.IsActive"
              >
                {{ item.Reason }}
              </option>
            </select>
          </div>
          <textarea
            v-model="clientStatusChangeData.Notes"
            id="noteTextEditor"
            class="form-control textareaInput generalTextBox"
            rows="5"
            :placeholder="
              isWithdrawal != true ? 'Write a reason' : 'Write a note'
            "
          ></textarea>
        </div>
      </div>

      <div
        v-if="creditorList.length > 0 && isWithdrawal != true"
        class="col-md-12 mb-2"
      >
        <div class="col-md-12">
          <label class="generalLabel">Pending Settlement Fees:</label>
        </div>

        <perfect-scrollbar class="col-md-12" style="max-height: 209px">
          <table class="datagrid-table col-md-12">
            <thead>
              <tr style="text-align: center">
                <th>Creditor</th>
                <th>Amount</th>
                <th>Enter Amount</th>
                <th>Fee Type</th>
              </tr>
            </thead>
            <tbody>
              <tr
                style="text-align: center"
                v-for="item in creditorList"
                :key="item.Id"
              >
                <td style="width: 20%">
                  <a>{{ item.Name }}</a>
                </td>
                <td style="width: 25%">{{ formatMoney(item.Fees) }}</td>
                <td style="width: 25%">
                  <input
                    type="text"
                    class="form-control"
                    :disabled="item.FirstPmtCleared == false"
                    :class="
                      item.FirstPmtCleared == false ? 'readonlyInput' : ''
                    "
                    v-model.trim="item.EnterAmount"
                    @input="sliceAmount(item)"
                    v-myMask="{
                      alias: 'currency',
                      prefix: '$',
                      rightAlign: false,
                    }"
                  />
                </td>

                <td style="width: 30%">
                  <select
                    v-model="item.FeeType"
                    class="form-control"
                    :disabled="item.FirstPmtCleared == false"
                    :class="
                      item.FirstPmtCleared == false ? 'readonlyInput' : ''
                    "
                  >
                    <option :value="null">Please Select</option>
                    <option
                      v-for="item in feeTypeList"
                      :key="item.FeeType"
                      :value="item.FeeType"
                    >
                      {{ item.Description }}
                    </option>
                  </select>
                </td>
              </tr>
            </tbody>
          </table>
        </perfect-scrollbar>
      </div>

      <div class="col-md-12 row">
        <div class="col-md-12 pb-2">
          <label class="generalLabel">Refund Type</label>
          <select
            v-if="clientStatusReleaseTypeList"
            class="form-control"
            v-model="clientStatusChangeData.ReleaseTypeId"
          >
            <option v-if="isWithdrawal != true" :value="null">Please Select</option>
            <option
              v-for="item in clientStatusReleaseTypeList"
              :value="item.ReleaseTypeId"
              :key="item.ReleaseTypeId"
            >
              {{ item.Name }}
            </option>
          </select>
        </div>

        <div
          class="col-md-5"
          v-if="
            clientStatusChangeData.ReleaseTypeId == 1 ||
            clientStatusChangeData.ReleaseTypeId == 2
          "
        >
          <label class="generalLabel">Money back amount:</label>
          <input
            v-model="clientStatusChangeData.RefundAmount"
            @change="refundAmountChange()"
            class="generalTextBox"
            style="width: 200px"
            type="text"
            v-myMask="{ alias: 'currency', prefix: '$', rightAlign: false }"
          />
        </div>
        <div class="col-md-6" v-if="isWithdrawal == true">
          <label class="generalLabel">Withdrawal Date</label>
          <DatePicker
            mode="date"
            :locale="'en-US'"
            v-model="clientStatusChangeData.WithdrawalDate"
            :model-config="modelConfig"
            :available-dates="setDatepickerAvailableDate()"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                id="DC-skipPaymentFeeDate"
                class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                :value="inputValue"
                v-on="inputEvents"
                placeholder="Select Date"
              />
            </template>
          </DatePicker>
        </div>
        <div class="col-md-12 pb-2 mt-2">
          <label class="generalLabel">Emergency 2 business day</label>
          <input
            type="checkbox"
            id="feesTakenCBoxPopUp"
            v-model="clientStatusChangeData.IsEmergency"
            class="checkbox-input generalCheckBox mr-2"
            style="
              float: left;
              border-radius: 4px !important;
              border: solid 1px #d1d1d1 !important;
            "
          />
        </div>

        <div
          v-if="checkAuth(54983) &&  isWithdrawal == true"
          class="col-md-12"
          style="display: flex; justify-content: left"
        >
          <input
            v-model="applicableFeesEnabled"
            type="checkbox"
            id="feesTakenCBox"
            class="checkbox-input generalCheckBox"
            style="
              border-radius: 4px !important;
              border: solid 1px #d1d1d1 !important;
            "
          />
          <label class="generalLabel">Related Fees</label>
        </div>

        <div class="col-md-12">
          <div
            class="col-md-12 noPadding"
            style="display: flex"
            v-if="checkAuth(54983) && applicableFeesEnabled"
          >
            <div class="col-md-4">
              <label class="generalLabel">Fee Type </label>
              <select
                class="form-control"
                v-model="withdrawalApplicableFees.GlobalFeeType"
              >
                <option :value="null">Please Select</option>
                <option
                  v-for="item in feeTypeList"
                  :value="item.FeeType"
                  :key="item.FeeType"
                >
                  {{ item.Description }}
                </option>
              </select>
            </div>
            <div class="col-md-3">
              <label for="serviceFee-sp" class="generalLabel">Amount</label>
              <input
                v-model="withdrawalApplicableFees.FeeAmount"
                class="form-control"
                id="skipPaymentAmount"
                v-myMask="{
                  alias: 'currency',
                  prefix: '$',
                  rightAlign: false,
                  allowMinus: false,
                }"
                placeholder="$0.00"
              />
            </div>
            <div class="col-md-3">
              <label for="serviceFee" class="generalLabel">Date</label>
              <DatePicker
                mode="date"
                :locale="'en-US'"
                v-model="withdrawalApplicableFees.FeeDate"
                :model-config="modelConfig"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                    id="DC-skipPaymentFeeDate"
                    class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                    :value="inputValue"
                    v-on="inputEvents"
                    placeholder="Select Date"
                  />
                </template>
              </DatePicker>
            </div>
            <div class="col-md-1" style="padding-top: 28px">
              <button
                @click="addApplicableFee()"
                type="button"
                class="btn btn-success waves-effect waves-light"
              >
                <i class="fas fa-plus-circle"></i>
              </button>
            </div>
          </div>

          <perfect-scrollbar
            style="
              width: 100%;
              max-height: 220px;
              overflow-x: hidden !important;
            "
          >
            <div class="col-md-12 pt-3">
              <label
                v-for="(item, index) in clientStatusChangeData.ApplicableFees"
                :key="index"
              >
                <div style="display: flex; align-items: stretch">
                  <label class="generalLabel pr-2"
                    >Fee Type:<label class="ml-1" style="color: #6c757d">{{
                      item.GlobalFeeType
                    }}</label></label
                  >
                  <label class="generalLabel pr-2"
                    >Amount:<label class="ml-1" style="color: #6c757d">{{
                      formatMoney(item.FeeAmount)
                    }}</label></label
                  >
                  <label class="generalLabel pr-1"
                    >Amount Date:<label class="ml-1" style="color: #6c757d">{{
                      new Date(item.FeeDate).toLocaleDateString()
                    }}</label></label
                  >

                  <span
                    style="padding: 5px; cursor: pointer; height: 20px"
                    class="badge badge-danger"
                    @click="deleteApplicableFee(index)"
                  >
                    Cancel</span
                  >
                </div>
              </label>
            </div>
          </perfect-scrollbar>
        </div>
        <div class="col-md-12 text-right mt-2" v-if="isWithdrawal == true">
          <button
            type="button"
            class="btn btn-danger waves-effect waves-light ml-2"
            @click="openCashFlowPanel"
            v-if="isCashFlowNegative"
          >
            Negative Cash Flow!
          </button>
        </div>
        <div class="col-md-12">
          <button
            @click="submitDraftChangesPopUp"
            type="button"
            class="btn btn-success waves-effect waves-light ml-2"
            :class="checkAuth(12411) ? 'col-md-6' : 'col-md-3'"
            style="float: right"
            :disabled="isLoading"
          >
            {{ checkAuth(12411) ? "Request and Approve" : "Submit" }}
          </button>
        </div>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import deepCopy from "@/helpers/deepCopy";
import { DatePicker } from "v-calendar";
export default {
  name: "ClientStatusChangePopup",
  components: { DatePicker },
  mixins: [formatMixin, utilitiesMixin],
  props: {
    customerId: {
      Type: Number,
      default: 0,
    },
    officeId: {
      Type: Number,
      default: 0,
    },
    fromStatusId: {
      Type: Number,
      default: 0,
    },
    toStatusId: {
      Type: Number,
      default: 0,
    },
    isWithdrawal: {
      Type: Boolean,
      default: false,
    },
    cashFlowList: {
      Type: Array,
      default: [],
    },
  },
  watch: {
    "clientStatusChangeData.WithdrawalDate": async function (val) {
      this.calculateCashFlowForWithdrawal();
    },
  },
  data() {
    return {
      creditorList: [],
      isLoading: false,
      cancellationInfoList: {},
      suggestBoxOpen: false,
      currentRowIndex: null,
      clientStatusReleaseTypeList: [],
      clientStatusChangeData: {
        CustomerId: 0,
        IsEmergency: false,
        Notes: "",
        FromStatusId: 0,
        ToStatusId: 0,
        ReleaseTypeId: null,
        RefundAmount: 0,
        CancelationReasonId: null,
        WithdrawalDate: null,
        ApplicableFees: [],
      },
      feeTypeList: [],
      totalFees: 0,
      availableBalance: 0,
      modelConfig: {
        type: "date",
        mask: "MM/DD/YYYY",
      },
      applicableFeesEnabled: false,
      withdrawalApplicableFees: {
        GlobalFeeType: null,
        FeeAmount: 0,
        FeeDate: "",
      },
      isCashFlowNegative: false,
    };
  },
  computed: mapState({
    reasonsList: (state) => state.draftChanges.reasonsList,

    filteredReasonsList() {
      return this.reasonsList.some((x) => x.IsActive);
    },
  }),
  async mounted() {
    this.isLoading = true;
    await this.$store.dispatch(
      types.GET_CUSTOMER_CANCELATION_REASON_STATUS_CHANGE,
      null
    );
    let err, result;
    [err, result] = await this.$store.dispatch(
      types.GET_CLIENT_STATUS_RELEASE_TYPES
    );

    if (result && result.Data) {
      this.clientStatusReleaseTypeList = deepCopy(result.Data);
      if (this.isWithdrawal == true) {
        this.clientStatusReleaseTypeList =
          this.clientStatusReleaseTypeList.filter((x) => x.ReleaseTypeId != 3);
          this.clientStatusChangeData.ReleaseTypeId = 2;
      }
    }
    await this.getFeeTypes();
    await this.getCancelliationInfo();
    if(this.isWithdrawal== true)
    {
      this.isCashFlowNegative = this.checkCashFlowNegative();
    }
    this.isLoading = false;
  },

  methods: {
    setDatepickerAvailableDate() {
      let startDate = new Date();
      let endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + 6);

      return {
        start: startDate,
        end: endDate,
      };
    },
    openCashFlowPanel() {
      this.$emit("openCashFlowPanel");
    },
    refundAmountChange() {
      this.calculateCashFlowForWithdrawal();
    },
    calculateCashFlowForWithdrawal() {
      if (this.checkAuth(956782) && this.isWithdrawal == true) {
        if (this.cashFlowList && this.cashFlowList.length > 0) {
          let list = deepCopy(this.cashFlowList);

          let withdrawalDate = new Date(
            this.clientStatusChangeData.WithdrawalDate
          );
          let withdrawalAmount = parseFloat(
            this.clientStatusChangeData.RefundAmount
          );
          if (
            withdrawalAmount > 0 &&
            withdrawalDate &&
            withdrawalDate != "Invalid Date"
          )
            list.push({
              Id: "X",
              ReferenceId: 0,
              IsPay: true,
              TransactionType: "W",
              TransactionTypeDesc: "",
              Date: withdrawalDate,
              Amount: withdrawalAmount,
              Memo: "Withdrawal",
              Balance: 0,
            });

          let fees = this.clientStatusChangeData.ApplicableFees;
          if (fees && fees.length > 0) {
            for (let item of fees) {
              let feeAmount = parseFloat(item.FeeAmount);
              let feeDate = new Date(item.FeeDate);
              list.push({
                Id: "X",
                IsPay: true,
                TransactionType: item.GlobalFeeType,
                TransactionTypeDesc: this.getFeeTypeDescription(
                  item.GlobalFeeType
                ),
                Date: feeDate.toString("MM/dd/yyyy"),
                Amount: feeAmount,
                Memo: this.getFeeTypeDescription(item.GlobalFeeType),
                Balance: 0,
              });
            }
          }
          this.$emit("calculateCashFlowForWithdrawal", list);
          this.isCashFlowNegative = this.checkCashFlowNegative();
        }
      }
    },
    getFeeTypeDescription(value) {
      let rtn = "";
      if (this.feeTypeList.length > 0 && value != "") {
        let index = this.feeTypeList.findIndex((x) => x.FeeType == value);
        if (index > -1) {
          rtn = this.feeTypeList[index].Description;
        }
      }
      return rtn;
    },
    checkCashFlowNegative() {
      let rtn = false;
      if (this.$parent) {
        rtn = this.$parent.checkCashFlowNegativeForPopups();
      }
      return rtn;
    },
    addApplicableFee() {
      if (
        this.withdrawalApplicableFees.GlobalFeeType != null &&
        this.withdrawalApplicableFees.FeeAmount > 0 &&
        this.withdrawalApplicableFees.FeeDate != ""
      ) {
        let item = deepCopy(this.withdrawalApplicableFees);
        item.FeeDate = new Date(item.FeeDate).toString("M/d/yyyy");
        item.FeeAmount = parseFloat(item.FeeAmount);

        this.clientStatusChangeData.ApplicableFees.push(item);

        this.withdrawalApplicableFees.GlobalFeeType = null;
        this.withdrawalApplicableFees.FeeAmount = 0;
        this.withdrawalApplicableFees.FeeDate = "";
        this.calculateCashFlowForWithdrawal();
      } else {
        this.$swal(
          "Warning!",
          "Fee type, amount and date fields are required",
          "warning"
        );
      }
    },
    deleteApplicableFee(indx) {
      if (indx > -1) {
        this.clientStatusChangeData.ApplicableFees.splice(indx, 1);
        this.calculateCashFlowForWithdrawal();
      }
    },
    notesAndReason() {
      let matchingReason = this.reasonsList.find(
        (x) => x.Id == this.clientStatusChangeData.CancelationReasonId
      );
      if (
        this.clientStatusChangeData.Notes &&
        this.clientStatusChangeData.CancelationReasonId
      ) {
        return (
          matchingReason.Reason + " - " + this.clientStatusChangeData.Notes
        );
      } else if (this.clientStatusChangeData.Notes) {
        return this.clientStatusChangeData.Notes;
      } else {
        return matchingReason.Reason;
      }
    },

    sliceAmount(item) {
      if (item.EnterAmount > item.Fees) {
        item.EnterAmount = item.Fees;
      }
    },
    async getFeeTypes() {
      let err, result;
      [err, result] = await this.$store.dispatch(types.GET_FEES_TYPES, {
        CustomerId: this.customerId,
        OfficeId: this.officeId,
      });
      if (result && result.Data) {
        this.feeTypeList = deepCopy(result.Data);
        if (this.isWithdrawal != true) {
          if (this.feeTypeList && this.feeTypeList.length > 0) {
            this.feeTypeList = this.feeTypeList.filter(
              (x) => x.IsSettlementFee == true
            );
          }
        }
      }
    },
    getAvailableFeeAmount(feeAmount) {
      let rtn = 0;
      rtn =
        parseFloat(feeAmount) >= parseFloat(this.availableBalance)
          ? parseFloat(this.availableBalance)
          : parseFloat(feeAmount);

      return rtn >= 0 ? rtn : 0;
    },
    async getCancelliationInfo() {
      let err, result;
      [err, result] = await this.$store.dispatch(
        types.GET_CUSTOMER_CANCELLATION_INFO,
        {
          CustomerId: this.customerId,
          OfficeId: this.officeId,
        }
      );
      if (result && result.Data) {

        if(this.isWithdrawal != true)
      {
        if (result.Data.Creditors) {
          this.creditorList = [];
          this.availableBalance =
            parseFloat(result.Data.GlobalBalance) -
            parseFloat(result.Data.AccountFee);
          this.totalFees = 0;
          for (let item of result.Data.Creditors) {
            this.creditorList.push({
              Id: item.Id,
              Name: item.Name,
              Fees: item.Fees,
              FeeType:
                this.feeTypeList && this.feeTypeList.length > 0
                  ? this.feeTypeList[0].FeeType
                  : null,
              EnterAmount: item.FirstPmtCleared
                ? this.getAvailableFeeAmount(item.Fees)
                : 0,
              FirstPmtCleared: item.FirstPmtCleared,
            });
            this.totalFees += item.FirstPmtCleared
              ? this.getAvailableFeeAmount(item.Fees)
              : 0;
          }

          this.availableBalance = parseFloat(
            this.availableBalance - this.totalFees
          );

          if (this.creditorList.length > 0) {
            if (this.availableBalance > 0) {
              this.clientStatusChangeData.ReleaseTypeId = 2;
              this.clientStatusChangeData.RefundAmount = parseFloat(
                this.availableBalance
              );
            } else {
              this.clientStatusChangeData.ReleaseTypeId = 3;
              this.clientStatusChangeData.RefundAmount = 0;
            }
          }
        }
      }
      else{
        this.clientStatusChangeData.RefundAmount = parseFloat(result.Data.GlobalBalance);
      }
        this.cancellationInfoList = deepCopy(result.Data);
      }
    },

    emptyTheObjectsOnUnmount() {
      this.clientStatusChangeData = null;
    },
    async submitDraftChangesPopUp() {
      if (
        this.filteredReasonsList == false &&
        this.clientStatusChangeData.Notes.trim().length == 0
      ) {
        this.$swal("Warning!", "Notes field is mandatory!", "warning");
        return;
      }

      if (
        this.isWithdrawal != true &&
        this.filteredReasonsList &&
        this.clientStatusChangeData.CancelationReasonId == null
      ) {
        this.$swal("Warning!", "Please select a reason!", "warning");
        return;
      }

      if (this.clientStatusChangeData.ReleaseTypeId == null) {
        this.$swal("Warning!", "Refund Type is mandatory!", "warning");
        return;
      }

      if (
        (this.clientStatusChangeData.ReleaseTypeId == 1 ||
          this.clientStatusChangeData.ReleaseTypeId == 2) &&
        this.clientStatusChangeData.RefundAmount == 0
      ) {
        this.$swal("Warning!", "Refund Amount is mandatory!", "warning");
        return;
      }
      if (this.clientStatusChangeData.ReleaseTypeId == 3) {
        this.clientStatusChangeData.RefundAmount = 0;
      }

      if (this.isWithdrawal == true) {
        let withdrawalDate = new Date(
          this.clientStatusChangeData.WithdrawalDate
        );
        let withdrawalAmount = parseFloat(
          this.clientStatusChangeData.RefundAmount
        );

        if (withdrawalAmount > this.cancellationInfoList.GlobalBalance) {
          this.$swal(
            "Warning!",
            "Refund Amount cannot be greater than Balance!",
            "warning"
          );
          return;
        }

        if(withdrawalDate < new Date())
        {
          this.$swal(
            "Warning!",
            "Please select a withdrawal date",
            "warning"
          );
          return;
        }

        if (
          withdrawalAmount > 0 &&
          withdrawalDate &&
          withdrawalDate != "Invalid Date"
        ) {
          this.$swal
            .fire({
              title: "Confirm",
              text: "Are you sure to submit this request?",
              type: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes!",
            })
            .then(async (dialog) => {
              if (dialog.value) {
                this.isLoading = true;
                let wErr, wResult;
                [wErr, wResult] = await this.$store.dispatch(
                  types.CREATE_WITHDRAWAL_REQUEST,
                  {
                    CustomerId: this.customerId,
                    ReleaseTypeId: this.clientStatusChangeData.ReleaseTypeId,
                    ApplicableFees: this.clientStatusChangeData.ApplicableFees,
                    Notes: this.clientStatusChangeData.Notes,
                    IsEmergency: this.clientStatusChangeData.IsEmergency,
                    ChangeDetails: [
                      {
                        RefundAmount: withdrawalAmount,
                        Date: this.formatDateWithoutLocalization(
                          withdrawalDate
                        ),
                        PaymentId: 0,
                      },
                    ],
                    IsNegativeCashFlow: this.isCashFlowNegative,
                  }
                );

                if (wResult) {
                  let successMessage = this.checkAuth(12411)
                    ? "The requested changes are approved."
                    : "Withdrawal requested.";
                  this.$swal("Success!", successMessage, "success");
                  this.$emit("submitWithdrawalChanges", this.checkAuth(12411));
                } else {
                  let errMsg = this.getApiErrorMessage(err);
                  this.$swal("Error!", errMsg, "error");
                }
              }
              this.isLoading = false;
            });
        }
      } else {
        //status change popup current code.
        let hasNullFeeType =
          this.creditorList.filter(
            (x) => x.FeeType == null && x.EnterAmount > 0
          ).length > 0;

        if (hasNullFeeType == true) {
          this.$swal("Warning!", "Fee Type is mandatory!", "warning");
          return;
        }

        let totalAmount = 0;
        for (let i = 0; i < this.creditorList.length; i++) {
          totalAmount += parseFloat(this.creditorList[i].EnterAmount);
        }

        let refundAmount = this.clientStatusChangeData.RefundAmount;
        let accontFee = this.cancellationInfoList.AccountFee;
        if (this.cancellationInfoList.GlobalBalance < 10.75) accontFee = 0;

        let collectedValues = parseFloat(
          parseFloat(totalAmount) +
            parseFloat(refundAmount) +
            parseFloat(accontFee)
        );

        if (
          parseFloat(collectedValues.toFixed(2)) >
          this.cancellationInfoList.GlobalBalance
        ) {
          this.$swal(
            "Warning!",
            "Sum of pending settlement fee amounts, account fee amount, and the refund amount cannot be greater than the account balance.",
            "warning"
          );
          return;
        }

        let applicableFees = [];
        for (let item of this.creditorList) {
          if (item.EnterAmount > 0) {
            applicableFees.push({
              FeeAmount: item.EnterAmount,
              GlobalFeeType: item.FeeType,
              FeeDate: this.formatShortDate(new Date()),
              CreditorId: item.Id,
            });
          }
        }

        this.$swal
          .fire({
            title: "Confirm",
            text: "Are you sure to submit this request?",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes!",
          })
          .then(async (dialog) => {
            if (dialog.value) {
              this.isLoading = true;
              let err, result;
              this.clientStatusChangeData.CustomerId = this.customerId;
              [err, result] = await this.$store.dispatch(
                types.CREATE_CLIENT_STATUS,
                {
                  CustomerId: this.clientStatusChangeData.CustomerId,
                  IsEmergency: this.clientStatusChangeData.IsEmergency,
                  ReleaseTypeId: this.clientStatusChangeData.ReleaseTypeId,
                  Notes: this.notesAndReason(),
                  RefundAmount:
                    this.clientStatusChangeData.ReleaseTypeId == 3
                      ? 0
                      : this.clientStatusChangeData.RefundAmount,
                  FromStatusId: this.fromStatusId,
                  ToStatusId: this.toStatusId,
                  ApplicableFees: applicableFees,
                  CancelationReasonId:
                    this.clientStatusChangeData.CancelationReasonId,
                }
              );

              if (result) {
                let successMessage = this.checkAuth(12411)
                  ? "The requested changes are approved."
                  : "Draft changes requested.";
                this.$swal("Success!", successMessage, "success");
                this.$emit("statusChangePopupSubmit");
                this.$emit("closeStatusChangePopup", null);
              } else {
                let errMsg = this.getApiErrorMessage(err);
                this.$swal("Error!", errMsg, "error");
              }
            }
            this.isLoading = false;
          });
      }
    },

    closePanel() {
      this.$emit("closeStatusChangePopup", this.fromStatusId);
    },
  },
};
</script>

<style scoped>
thead {
  position: sticky;
  top: 0px;
  background: white;
}
.closeButton {
  position: absolute;
  top: -5px;
  right: 15px;
  z-index: 1000;
  font-size: 30px;
  cursor: pointer;
}
.closeButton:hover {
  color: #ff3860;
}
</style>
