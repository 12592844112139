import types from "./types";
import api from "@/services/admin/api";
import to from "@/helpers/to";
export default {
  state: {},
  actions: {
    GET_PAYOUT_CONFIGURATION: async ({ commit }) => {
      let err, result;
      [err, result] = await to(api.getAllPayoutConfiguration());
      return [err, result];
    },

    GET_DEFAULT_SETTLEMENTTIER: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.getDefaultSettlementtier(payload));
      return [err, result];
    },
    GET_OFFICES_WITH_CONFIG: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.getOfficesWithConfig(payload));
      return [err, result];
    },
    UPDATE_PAYOUT_CONFIGURATION: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.updatePayoutConfiguration(payload));
      return [err, result];
    },
    UPDATE_DEFAULT_SETTLEMENTTIER: async ({ commit }) => {
      let err, result;
      [err, result] = await to(api.updateDefaultSettlementtier());
      return [err, result];
    },
    ADD_PAYOUT_CONFIGURATION: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.addPayoutConfiguration(payload));
      return [err, result];
    },
    DELETE_PAYOUT_CONFIGURATION: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.deletePayoutConfiguration(payload));
      return [err, result];
    },
  },
  mutations: {},
};
