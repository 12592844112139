<template>
  <div
    class="col-md-12 newPanelWrapper noMargin"
    style="background-color: #ffffff; z-index: 1000"
  >
    <div
      v-if="!closeButtonExpiration"
      class="closeButton"
      v-on:click.prevent.stop="closePanel"
    >
      <i class="fas fa-window-close"></i>
    </div>
    <div class="form-group row">
      <div
        class="col-md-12"
        style="display: flex; flex-direction: column; align-items: center"
      >
        <i
          style="font-size: 90px; color: #61b4fa; margin-top: -20px"
          class="ri-lock-2-fill"
        ></i>
        <label style="font-size: 25px; margin-top: -15px; color: #61b4fa"
          >NEW PASSWORD</label
        >
      </div>

      <div class="col-md-12">
        <label class="generalLabel">Password:</label>
        <input
          v-model="password"
          class="form-control"
          placeholder="Password"
          @input="checkPasswordStrength"
          type="password"
          autocomplete="new-password"
        />
      </div>
      <div class="col-md-12">
        <label class="generalLabel" for="confirmPassword"
          >Confirm Password:</label
        >
        <input
          v-model="confirmPassword"
          id="confirmPassword"
          class="form-control"
          type="password"
          placeholder="Confirm Password"
          autocomplete="off"
        />
      </div>
      <div class="password-container col-md-12">
        <div class="password-strength-bar" :class="passwordStrengthClass"></div>
        <!-- <div class="password-strength-text">{{ passwordStrengthText }}</div> -->
      </div>
      <div class="col-md-12">
        <div class="checkText">
          <a class="ml-1">Password must include at least one:</a>
          <a
            class="ml-2"
            :style="passwordLength == false ? 'color:#9fa5aa' : 'color:grey'"
            ><i
              :style="
                passwordLength == false ? 'color:#68c1e3' : 'color:#9fa5aa'
              "
              class="fas fa-check-circle"
            ></i>
            Minimum length of the password is 8 characters</a
          >
          <a
            class="ml-2"
            :style="hasLowerCase == true ? 'color:#9fa5aa' : 'color:grey'"
            ><i
              :style="hasLowerCase == true ? 'color:#68c1e3' : 'color:#9fa5aa'"
              class="fas fa-check-circle"
            ></i>
            Lower-case character</a
          >
          <a
            class="ml-2"
            :style="hasUpperCase == true ? 'color:#9fa5aa' : 'color:grey'"
            ><i
              :style="hasUpperCase == true ? 'color:#68c1e3' : 'color:#9fa5aa'"
              class="fas fa-check-circle"
            ></i>
            Upper-case character</a
          >
          <a
            class="ml-2"
            :style="hasNumber == true ? 'color:#9fa5aa' : 'color:grey'"
            ><i
              :style="hasNumber == true ? 'color:#68c1e3' : 'color:#9fa5aa'"
              class="fas fa-check-circle"
            ></i>
            Number</a
          >

          <a
            class="ml-2"
            :style="hasSymbol == true ? 'color:#9fa5aa' : 'color:grey'"
            ><i
              :style="hasSymbol == true ? 'color:#68c1e3' : 'color:#9fa5aa'"
              class="fas fa-check-circle"
            ></i>
            Symbols: (  )  *  !  @  #  $  %  - or _</a
          >
        </div>
      </div>

      <div class="col-md-12">
        <button
          @click="resetPsw()"
          class="col-md-12 btn btn-primary"
          style="background-color: #61b4fa"
        >
          Change Password
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import api from "@/services/login.service";
import to from "@/helpers/to";
import globalTypes from "@/store/types";

export default {
  name: "LoginOverlayPopUp",
  components: {},
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      passwordStrength: 0,
      password: "",
      confirmPassword: "",
      saving: false,
    };
  },
  props: {
    closeButtonExpiration: {
      Type: Boolean,
      Defult: false,
    },
  },
  methods: {
    checkPasswordStrength() {
      let strength = 0;
      if (/\d/.test(this.password)) {
        strength += 1;
      }
      if (/[A-Z]/.test(this.password)) {
        strength += 1;
      }
      if (/[()*!@#$%\-_]/g.test(this.password)) {
        strength += 1;
      }
      if (this.password.length >= 12) {
        strength += 1;
      }

      this.passwordStrength = strength;
    },
    async resetPsw() {
      if (this.password.length <= 0 || this.confirmPassword.length <= 0) {
        this.$swal(
          "Warning!",
          "Please fill in the password fields.",
          "warning"
        );
        return;
      }

      if (this.password != this.confirmPassword) {
        this.$swal("Warning!", "Passwords do not match.", "warning");
        return;
      }

      if (
        !this.passwordLength &&
        this.hasLowerCase &&
        this.hasUpperCase &&
        this.hasNumber &&
        this.hasSymbol
      ) {
        this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

        let userPassword = await this.sha256(this.password);

        let payload = {
          Password: userPassword,
        };
        let err, result;
        [err, result] = await this.$store.dispatch(
          globalTypes.CHANGE_PASSWORD,
          payload
        );
        if (result) {
          this.$swal("Success!", result.Message, "success");
          let tmpDate = Date.parse(this.userInfo.passwordExpirationDate);

          this.userInfo.passwordExpirationDate = new Date(
            tmpDate.setDate(tmpDate.getDate() + 720)
          ).toString();

          sessionStorage.setItem("userInfo", JSON.stringify(this.userInfo));

          this.$emit("closeChangePasswordPopUp");
        } else {
          let errMsg = this.getApiErrorMessage(err);
          this.$swal("Error!", errMsg, "error");
        }
        // }
      } else {
        this.$swal(
          "Warning!",
          "Please make sure your password meets the requirements.",
          "warning"
        );
        return;
      }
    },

    closePanel() {
      this.$emit("closeChangePasswordPopUp");
    },
  },
  async mounted() {},
  watch: {},
  computed: mapState({
    passwordStrengthClass() {
      if (this.passwordStrength == 0 || this.passwordStrength == 1) {
        return "password-strength-weak";
      } else if (this.passwordStrength == 2) {
        return "password-strength-fair";
      } else if (this.passwordStrength == 3) {
        return "password-strength-good";
      } else {
        return "password-strength-strong";
      }
    },
    passwordStrengthText() {
      if (this.passwordStrength === 0 || this.passwordStrength === 1) {
        return "Weak";
      } else if (this.passwordStrength === 2) {
        return "Fair";
      } else if (this.passwordStrength === 3) {
        return "Good";
      } else {
        return "Strong";
      }
    },
    passwordMatch() {
      return this.password !== this.confirmPassword;
    },
    passwordLength() {
      return this.password.length < 8;
    },
    hasUpperCase() {
      return /[A-Z]/.test(this.password);
    },
    hasLowerCase() {
      return /[a-z]/.test(this.password);
    },
    hasNumber() {
      return /\d/.test(this.password);
    },
    hasSymbol() {
      let regex = /[()*!@#$%\-_]/g;
      return regex.test(this.password);
    },
  }),
};
</script>

<style>
.closeButton {
  position: absolute;
  top: -5px;
  right: 15px;
  z-index: 1000;
  font-size: 30px;
  cursor: pointer;
}
.closeButton:hover {
  color: #ff3860;
}

.password-container {
  margin-bottom: 1rem;
}

.password-container label {
  display: block;
  margin-bottom: 0.5rem;
}

.password-strength-bar {
  height: 0.4rem;
  margin-top: 0.5rem;
  border-radius: 5px;
}

.password-strength-weak {
  background-color: #ff5a5a;
}

.password-strength-fair {
  background-color: #ffa500;
}

.password-strength-good {
  background-color: #ffd700;
}

.password-strength-strong {
  background-color: #90ee90;
}

.password-strength-text {
  margin-top: 0.25rem;
}
.checkText {
  display: flex;
  flex-direction: column;
  font-size: 14px;
}
</style>
