<template>
  <div
    class="col-md-12 newPanelWrapper noMargin"
    style="
      background-color: #ffffff;
      z-index: 1000;
      padding-left: 0;
      padding-right: 0;
    "
  >
    <div>
      <div>
        <div class="col-md-12" style="position: absolite; top: -10px">
          <label for="Title" class="generalLabel">Decline</label>
        </div>
        <div class="closeButtonDR" v-on:click.prevent.stop="closePanel">
          <i class="fas fa-window-close"></i>
        </div>
      </div>
    </div>
    <div class="form-group col-md-12 pt-3" v-if="revertValuesRequired > 0">
      <div style="margin-left: 10px">
        <label class="generalLabel">{{
          revertValuesRequired == 1 ? "Revert Status" : "Revert Values"
        }}</label>
      </div>

      <div class="col-md-12 row">
        <div
          class="col-md-6 noPadding"
          style="display: flex; align-items: center"
        >
          <input
            checked
            type="radio"
            id="revertCBox"
            name="revertRadio"
            value="true"
            v-model="isRevert"
            class="checkbox-input generalCheckBox ml-1"
            style="
              border-radius: 4px !important;
              border: solid 1px #d1d1d1 !important;
            "
          />

          <label class="generalLabel mt-1">Yes</label>
          <input
            type="radio"
            name="revertRadio"
            id="revertCBox"
            value="false"
            v-model="isRevert"
            class="checkbox-input generalCheckBox ml-2"
            style="
              border-radius: 4px !important;
              border: solid 1px #d1d1d1 !important;
            "
          />
          <label class="generalLabel mt-1">No</label>
        </div>
      </div>
    </div>
    <perfect-scrollbar
      style="
        width: 100%;
        margin: 5px 0;
        padding: 0px 10px 0px 10px;
        max-height: 800px;
        overflow-x: hidden !important;
      "
    >
      <div>
        <div class="form-group col-md-12 pt-3">
          <div>
            <label class="generalLabel">Please describe the reason...</label>
          </div>
          <div>
            <textarea
              v-model="reason"
              id="noteTextEditor"
              class="form-control textareaInput generalTextBox"
              rows="7"
            ></textarea>
          </div>
        </div>
      </div>

      <div class="col-md-12">
        <button
          @click="decline()"
          id="saveAllIcon"
          type="button"
          class="btn btn-success col-md-12 mt-3"
        >
          Submit
        </button>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
export default {
  name: "DeclineReasonPopup",
  props: {
    parentName: {
      type: String,
      default: "",
    },
    revertValuesRequired: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      reason: "",
      isRevert: "true",
    };
  },
  computed: {},
  mounted() {},
  methods: {
    closePanel() {
      this.$emit("closeDeclineReasonPopup");
    },
    convertRevertValue(revertVal) {
      let rtn = false;
      if (revertVal == "true") rtn = true;
      return rtn;
    },
    async decline() {
      if (this.reason.trim().length == 0) {
        this.$swal("Warning!", "Reason field is required.", "warning");
        return;
      }

      this.$emit(
        this.parentName + "Decline",
        this.reason,
        this.convertRevertValue(this.isRevert)
      );
      this.closePanel();
    },
  },
};
</script>

<style scoped>
.closeButtonDR {
  position: absolute;
  top: -0px;
  right: 10px;
  z-index: 1000;
  font-size: 30px;
  cursor: pointer;
}

.closeButtonDR:hover {
  color: #ff3860;
}
</style>
