<template>
  <div
    class="col-md-12 newPanelWrapper noMargin"
    style="background-color: #ffffff; z-index: 1000"
  >
    <div class="closeButton" v-on:click.prevent.stop="closePanel">
      <i class="fas fa-window-close"></i>
    </div>
    <div class="form-group row">
      <div class="col-md-12">
        <label class="generalLabel" for="extraDocumentName"
          >Document Name: {{ documentName }}</label
        >
      </div>
      <div class="col-md-12 mt-3">
        <label class="generalLabel" for="extraDocumentType"
          >Document Type: *</label
        >
      </div>
      <div class="col-md-12">
        <select
          id="extraDocumentType"
          class="form-control"
          v-model="selectedDocType"
        >
          <option value="">Select</option>
          <option
            v-for="item in globalDocumentTypeList"
            :value="item.DocType"
            :key="item.DocType"
          >
            {{ item.Decription }}
          </option>
        </select>
      </div>
      <div class="offset-xl-9 offset-lg-4 col-xl-3 col-lg-4 col-md-6 col-xs-12">
        <button
          class="btn btn-success form-control"
          id="Upload"
          :disabled="selectedDocType.length == 0 || saving"
          @click="() => handleUploadGCS()"
        >
          Upload
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import types from "@/views/agent/EditClient/types";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import globalTypes from "@/store/types";

export default {
  name: "GCSUploadPopup",
  components: {},
  props: {
    customerId: {
      type: Number,
      default: () => 0,
    },
    customerOfficeId: {
      type: Number,
      default: () => 0,
    },
    documentName: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      selectedDocType: "",
      saving: false,
    };
  },
  methods: {
    closePanel() {
      this.$emit("closeGCSUploadPopup");
    },
    async handleUploadGCS() {
      this.saving = true;
      let uploadData = {
        CustomerId: this.customerId,
        OfficeId: this.customerOfficeId,
        DocumentName: this.getFileNameWithoutExt(this.documentName),
        Extension: this.getExtension(this.documentName),
        DocType: this.selectedDocType,
      };

      if (uploadData.DocumentName != "") {
        let err, result;
        [err, result] = await this.$store.dispatch(
          types.UPLOAD_DOCUMENTTOGLOBAL,
          uploadData
        );

        if (result) {
          this.$swal("Success!", result.Message, "success");
          if (this.checkAuth(3994)) {
            this.$store.dispatch(globalTypes.INSERT_USER_ACTIVITY, {
              CustomerId: this.customerId,
              UserActivityTypeId: 5,
              AdditionalNotes:"Uploaded a document"
            });
          }

          this.$emit("closeGCSUploadPopup");
        } else {
          let errMsg = this.getApiErrorMessage(err);
          this.$swal("Error!", errMsg, "error");
        }
      }
      this.saving = false;
    },
  },
  async mounted() {},

  computed: mapState({
    globalDocumentTypeList: (state) =>
      state.clientManagement.globalDocumentTypeList,
  }),
  mixins: [formatMixin, utilitiesMixin],
};
</script>

<style>
.closeButton {
  position: absolute;
  top: -5px;
  right: 15px;
  z-index: 1000;
  font-size: 30px;
  cursor: pointer;
}
.closeButton:hover {
  color: #ff3860;
}
</style>
