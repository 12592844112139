import types from "./types";
import api from "@/services/client/customerApi";
import to from "@/helpers/to";

export default {
  actions: {
    GET_ALL_AUTH_TYPES: async ({ commit }, data) => {
      let err, result;
      [err, result] = await to(api.getAllAuthorizationType());

      return [err, result];
    },
    GET_AUTHORIZATION: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.getAuthorizationType(payload));
      return [err, result];
    },
    CREATE_AUTHORIZATION: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.createAuthorizationText(payload));
      return [err, result];
    },
    PROCESS_REPEATABLE_TEXTS: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.replaceRepeatableText(payload));
      return [err, result];
    },
    SEND_PORTAL_MESSAGE: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.getSendMessage(payload));
      return [err, result];
    },
    GET_CUSTOMER_INFO_FOR_PORTAL: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.getCustomerInfoForPortal(payload));
      return [err, result];
    },
  },
  mutations: {},
};
