<template>
  <Popover class="transfer">
    <template v-slot:header>
      <div class="container">
        Transfer to Agent
        <button
          class="no-button"
          type="button"
          v-on:click.prevent.stop="closePopup"
        >
          &lt; Back
        </button>
        <input
          v-on:click.prevent.stop="() => {}"
          class="form-control"
          type="text"
          v-model="contactListFilter"
        />
      </div>
    </template>
    <template v-slot:body>
      <div class="contact-list" v-if="agents.length > 0">
        <div
          class="contact"
          @click="transfer(item)"
          :key="item.Id"
          v-for="item in softPhoneEmployees"
        >
          {{ item.Name }}
          <i
            :style="'background-color: ' + statusColor(item.EndpointName)"
            class="ri-phone-fill"
          ></i>
        </div>
      </div>
      <div v-if="agents.length == 0">No agent found.</div>
    </template>
  </Popover>
</template>

<script>
import Popover from "../../Popover";
import _orderby from "lodash";
import types from "../types";
import { mapState } from "vuex";
import utilitiesMixin from "@/mixins/utilitiesMixin";

export default {
  components: {
    Popover,
  },
  props: {
    endPointName: {
      type: String,
      default: () => "",
    },
    agents: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["transferToAgent", "transfer"],
  data() {
    return {
      contactListFilter: "",
      userOfficeId: null,
    };
  },
  mixins: [utilitiesMixin],
  computed: mapState({
    endPointList: (state) => state.phoneManagement.endPointList,
    softPhoneEmployees() {
      return this.agents.length > 0
        ? _orderby.orderBy(
            this.checkAuth(100005)
              ? this.agents.filter(
                  (x) =>
                    x.Name.toLowerCase().indexOf(
                      this.contactListFilter.toLowerCase()
                    ) > -1
                )
              : this.agents
                  .filter((x) => x.OfficeId == this.userOfficeId)
                  .filter((item) => item.IsPhoneEnabled == true)
                  .filter((item) => item.EndpointName != "")
                  .filter((item) => item.EndpointName != this.endPointName)
                  .filter(
                    (x) =>
                      x.Name.toLowerCase().indexOf(
                        this.contactListFilter.toLowerCase()
                      ) > -1
                  ),
            "Name"
          )
        : [];
    },
  }),
  async mounted() {
    await this.$store.dispatch(types.GET_ENDPOINT_LIST);

    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo) {
      this.userOfficeId = userInfo.officeId;
    }
  },

  methods: {
    transfer(item) {
      if (
        this.endPointList &&
        this.endPointList.length > 0 &&
        this.softPhoneEmployees.length > 0
      ) {
        let indx = this.endPointList.findIndex(
          (x) => x.UserName == item.EndpointName
        );
        if (indx > -1) {
          if (this.endPointList[indx].IsOnline) {
            this.$emit("transferToAgent", {
              endPointName: item.EndpointName,
              userId: item.Id,
            });
          } else {
            this.$swal("Warning!", "Agent is not online.", "warning");
          }
        } else
          this.$swal("Error!", "Agent not found. Unable to transfer", "error");
      }
    },
    backToTransferMenu() {
      this.$emit("transfer", 0);
    },
    statusColor(EndpointName) {
      let rtn = "gray";

      if (
        this.endPointList &&
        this.endPointList.length > 0 &&
        this.softPhoneEmployees.length > 0
      ) {
        let indx = this.endPointList.findIndex(
          (x) => x.UserName == EndpointName
        );
        if (indx > -1) {
          let item = this.endPointList[indx];
          if (item.IsOnCall) {
            rtn = "#ed3030";
          } else if (item.IsOnline) {
            rtn = "#24d15f";
          }
        }
      }
      return rtn;
    },
    closePopup() {
      this.$emit("transfer", 0);
    },
  },
};
</script>

<style></style>
