export default {
  GETCAMPAIGNS: "GETCAMPAIGNS",
  SETCAMPAIGNS: "SETCAMPAIGNS",
  POSTCAMPAIGN: "POSTCAMPAIGN",
  PUTCAMPAIGN: "PUTCAMPAIGN",
  CAMPAIGN_All_COMPANY_DIDS: "CAMPAIGN_All_COMPANY_DIDS",
  CAMPAIGN_GET_AGENTS: "CAMPAIGN_GET_AGENTS",
  CAMPAIGN_SET_AGENTS: "CAMPAIGN_SET_AGENTS",
  UPLOAD_CAMPAIGN_DATA: "UPLOAD_CAMPAIGN_DATA",
};
