<template>
  <div :style="notifyStyle" class="notifyBox bg-white" role="alert">
    <div class="timer notifyTimer" style :style="{ width: perc + '%' }"></div>
    <button
      v-if="!notification.IsAutoCallReminder"
      type="button"
      class="close"
      data-dismiss="alert"
      v-on:click.prevent="closeNotification(notification)"
      aria-label="Close"
    >
      <span aria-hidden="true">×</span>
    </button>
    <!-- temp
    <NotificationItem @closeNotification="closeNotification"
     v-model="notification"></NotificationItem>
     -->
    <NotificationItem
      @closeNotification="closeNotification"
      :value="notification"
      :canCallCustomer="canCallCustomer"
    ></NotificationItem>
  </div>
</template>

<script>
import { mapState } from "vuex";
import NotificationItem from "./NotificationItem";
import notificationTypes from "./types";
export default {
  name: "Notification",
  components: { NotificationItem },
  props: {
    notification: {
      type: Object,
      default: () => null,
    },
    duration: {
      type: Number,
      default: () => 30,
    },
    canCallCustomer: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: mapState({
    popupNotifications: (state) => state.notifications.popupNotifications,

    notifyStyle: function () {
      let str = "";
      if (this.notification.NotificationType.BorderColor != "") {
        str += ` border: 2px solid ${this.notification.NotificationType.BorderColor}`;
      }
      return str;
    },
  }),
  data() {
    return {
      counter: null,
      perc: 0,
      musicAutoPlay: true,
    };
  },
  mounted() {
    if (this.notification.NotificationType.IsAutoClose) {
      let showDuration = this.duration * 1000;
      let timeLeft = showDuration;

      this.counter = window.setInterval(() => {
        this.perc = (timeLeft * 100) / showDuration;
        timeLeft -= 10;
        if (timeLeft <= 0) {
          window.clearInterval(this.counter);

          this.closeNotification(this.notification);
        }
      }, 10);
    }
  },

  methods: {
    playSound() {
      if (this.notification.NotificationType.AlertSoundUrl != "") {
        let audio = new Audio(this.notification.NotificationType.AlertSoundUrl);
        audio.play();
      }
    },
    closeNotification(item) {
      let index = this.popupNotifications.findIndex((x) => x.Id == item.Id);
      if (index >= 0) {
        this.popupNotifications.splice(index, 1);

        this.$store
          .dispatch(
            notificationTypes.MODIFY_POPUPNOTIFICATIONS,
            this.popupNotifications
          )
          .catch();
      }
    },
  },
};
</script>
<style scoped>
.notifyBox {
  width: 400px;
  padding: 5px;
  margin: 10px 0;
  background-color: #999;
  float: left;
  box-shadow: 0 5px 10px #999;
}
.notifyTimer {
  height: 5px;
  background-color: #999;
}
</style>
