<template>
  <div class="row admin-page officePage">
    <div v-if="isLoading" class="loading">
      <div class="text-center" style="margin-top: 350px">
        <i class="spinner-grow text-info m-2"></i>
      </div>
    </div>
    <div class="col-md-12 row">
      <div class="col-xl-3 col-lg-4 col-md-12 col-sm-12">
        <button
          type="button"
          class="col-md-12 btn btn-primary waves-effect waves-light"
          @click="handleAddNew"
        >
          Add New Phone Number
        </button>
        <div class="filter form-group row">
          <div class="col-lg-12 col-md-12 col-sm-12 noPadding">
            <div class="col-lg-12 col-md-12 col-sm-12 noPadding">
              <input
                autocomplete="off"
                type="text"
                class="form-control"
                placeholder="Filter Phone Number"
                v-model="phoneFilter"
              />
            </div>
          </div>
          <div class="list col-md-12">
            <div
              class="office item row"
              v-for="(item, index) in filteredPhones"
              :key="item.Id"
              :class="{ active: activeItem === item.Id }"
              :style="[
                index % 2 === 0
                  ? { 'background-color': '#F1EFEF' }
                  : { 'background-color': '#FFFFFF' },
              ]"
              @click="() => handleSelection(item)"
            >
              <span :class="{ adminActiveMenuItem: activeItem === item.Id }">
                <i class="fe-phone"></i>
                <span style="margin-left: 8px">{{
                  tryFormatPhone(item.PhoneNumber)
                }}</span>
              </span>
            </div>
          </div>
          <div
            v-if="isMobileDevice()"
            style="
              border-top: 1px solid rgb(0, 0, 0, 0);
              margin: 20px 0 0 9px;
              padding-bottom: 55px;
            "
            class="col-md-12 scrollToSelectedItem"
          ></div>
        </div>
      </div>
      <div
        class="col-xl-9 col-lg-6 col-md-12 col-sm-12 panelWrapper noMargin"
        v-if="isEditing"
      >
        <div class="panelContent">
          <div class="form-group row">
            <div class="col-md-12 col-xl-6 col-lg-9">
              <label for="phoneNumberInput" class="generalLabel"
                >Phone Number: *</label
              >

              <input
                autocomplete="none"
                type="text"
                class="form-control"
                id="phoneInput"
                v-model="selectedRow.PhoneNumber"
                :disabled="selectedRow.Id > 0"
                :class="
                  GetTxtBoxClasses(
                    selectedRow.Id,
                    v$.selectedRow.PhoneNumber.$error
                  )
                "
                @blur="v$.selectedRow.PhoneNumber.$touch"
                v-myMask="{ mask: ['(999) 999-9999'] }"
                @change="phoneChanged"
                placeholder="Enter Phone Number"
              />
            </div>
          </div>

          <div class="form-group row" v-if="selectedRow.Id">
            <div class="col-md-12 col-xl-6 col-lg-9">
              <label for="addedBy" class="generalLabel"
                >Added By:
                {{ selectedRow.ScreenName + " | " + selectedRow.Name }}</label
              >
            </div>
          </div>

          <div class="form-group row" v-if="selectedRow.Id">
            <div class="col-md-12 col-xl-6 col-lg-9">
              <label class="generalLabel"
                >Added On: {{ formatGridDate(selectedRow.CreatedDate) }}</label
              >
            </div>
          </div>

          <div class="col-md-6 row noPadding" style="gap: 10px">
            <button
              v-if="selectedRow.Id <= 0"
              type="button"
              class="btn btn-success col-md-5"
              @click="saveCallSettings()"
              :disabled="isLoading || v$.$invalid"
              style="width: 100%"
            >
              Save
            </button>
            <button
              type="button"
              v-if="selectedRow.Id && selectedRow.Id > 0"
              :disabled="isLoading"
              @click="() => handleDelete(selectedRow.Id)"
              class="btn btn-danger col-md-5"
              style="width: 100%"
            >
              <i v-if="isLoading" class="fas fa-spinner mr-1"></i>
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import types from "./types";
import _orderby from "lodash";
import deepCopy from "@/helpers/deepCopy";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin.js";
import utilitiesMixin from "@/mixins/utilitiesMixin.js";
import useValidate from "@vuelidate/core";
const init = {
  Id: 0,
  CreatedDate: null,
  CreatedBy: 0,
  PhoneNumber: "",
  OfficeId: 0,
  ScreenName: "",
  Name: "",
};

export default {
  name: "BlockedNumberManagement",
  mixins: [formatMixin, utilitiesMixin],
  components: {},
  data() {
    return {
      v$: useValidate(),
      isLoading: false,
      selectedRow: Object.assign({}, init),
      outboundCallMethodId: 0,
      oldCallMethodId: 0,
      userOfficeId: 0,
      phoneFilter: "",
      phoneNumbers: [],
      oldValue: "",
      activeOffice: null,
      isEditing: false,
      valueToRevert: deepCopy(init),
      activeItem: null,
      isCancelledBeforeSave: false,
    };
  },
  computed: mapState({
    filteredPhones() {
      return _orderby.orderBy(
        this.phoneNumbers.filter(
          (x) =>
            x.PhoneNumber.toLowerCase().indexOf(
              this.phoneFilter.toLowerCase()
            ) > -1
        ),
        "PhoneNumber",
        "asc"
      );
    },
  }),
  async mounted() {
    let err, result;
    [err, result] = await this.$store.dispatch(types.GET_BLOCKED_NUMBERS);
    if (result && result.Data) {
      this.phoneNumbers = deepCopy(result.Data);
    }

    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo != null && userInfo.moduleFunctions != null) {
      this.userOfficeId = userInfo.officeId;
    }
  },
  methods: {
    GetTxtBoxClasses(id, err) {
      let rtn = "";
      if (err) {
        rtn = "has-error ";
      }
      if (id > 0) {
        rtn += "readonlyInput";
      }
      return rtn;
    },
    async handleSelection(row) {
      if (
        this.selectedRow &&
        JSON.stringify(this.selectedRow) != this.oldValue &&
        JSON.stringify(this.selectedRow) != JSON.stringify(init)
      ) {
        await this.$swal
          .fire({
            title: "You have unsaved changes",
            text: "You will lose it if you don't save",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, continue without saving!",
          })
          .then(async (dialog) => {
            if (dialog.value) {
              this.isCancelledBeforeSave = false;
            } else {
              this.isCancelledBeforeSave = true;
              return;
            }
          });
      }
      this.isEditing = true;
      if (!this.isCancelledBeforeSave) {
        let indx = this.phoneNumbers.findIndex((x) => x.Id == row.Id);

        if (indx > -1) {
          this.selectedRow = deepCopy(this.phoneNumbers[indx]);
          this.oldValue = JSON.stringify(this.selectedRow);
          this.activeItem = row.Id;
        } else {
          this.selectedRow = deepCopy(init);
          this.oldValue = JSON.stringify(this.selectedRow);
          this.activeItem = row.Id;
        }
      }
      this.isCancelledBeforeSave = false;

      let el = this.$el.querySelector(".scrollToSelectedItem");
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
    async handleAddNew() {
      if (
        this.selectedRow &&
        JSON.stringify(this.selectedRow) != this.oldValue &&
        JSON.stringify(this.selectedRow) != JSON.stringify(init)
      ) {
        await this.$swal
          .fire({
            title: "You have unsaved changes",
            text: "You will lose it if you don't save",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, continue without saving!",
          })
          .then(async (dialog) => {
            if (dialog.value) {
              this.isCancelledBeforeSave = false;
            } else {
              this.isCancelledBeforeSave = true;
              return;
            }
          });
      }
      this.isEditing = true;
      if (!this.isCancelledBeforeSave) {
        this.selectedRow = deepCopy(init);
        this.oldValue = JSON.stringify(this.selectedRow);
        this.activeItem = null;
      }
      this.isCancelledBeforeSave = false;
    },
    async saveCallSettings() {
      this.isLoading = true;
      if (this.selectedRow.Id == 0) {
        // Add new
        let err, result;
        [err, result] = await this.$store.dispatch(
          types.INSERT_BLOCKED_NUMBERS,
          {
            PhoneNumber: this.selectedRow.PhoneNumber,
          }
        );
        if (result && result.Data) {
          //push to list update selected row
          this.$swal("Success!", result.Message, "success");
          this.selectedRow.Id = result.Data.Id;
          this.selectedRow.CreatedDate = result.Data.CreatedDate;
          this.selectedRow.CreatedBy = result.Data.CreatedBy;
          this.selectedRow.OfficeId = result.Data.OfficeId;
          this.selectedRow.PhoneNumber = result.Data.PhoneNumber;
          this.selectedRow.ScreenName = result.Data.ScreenName;
          this.selectedRow.Name = result.Data.Name;
          this.phoneNumbers.push(this.selectedRow);
          this.oldValue = JSON.stringify(this.selectedRow);
          this.activeItem = this.selectedRow.Id;
        } else {
          let errMsg = this.getApiErrorMessage(err);
          this.$swal("Error!", errMsg, "error");
        }
      } else {
        //Update
        if (JSON.stringify(this.selectedRow) != this.oldValue) {
          //delete first
          let rowIndex = this.phoneNumbers.findIndex(
            (x) => x.Id == this.selectedRow.Id
          );
          let err, result;
          [err, result] = await this.$store.dispatch(
            types.DELETE_BLOCKED_NUMBERS,
            { Id: this.selectedRow.Id }
          );
          if (result) {
            if (rowIndex > -1) {
              //insert
              err = null;
              result = null;

              this.phoneNumbers.splice(rowIndex, 1);

              [err, result] = await this.$store.dispatch(
                types.INSERT_BLOCKED_NUMBERS,
                {
                  PhoneNumber: this.selectedRow.PhoneNumber,
                }
              );
              if (result && result.Data) {
                this.$swal("Success!", result.Message, "success");
                this.selectedRow.Id = result.Data.Id;
                this.selectedRow.CreatedDate = result.Data.CreatedDate;
                this.selectedRow.CreatedBy = result.Data.CreatedBy;
                this.selectedRow.OfficeId = result.Data.OfficeId;
                this.selectedRow.PhoneNumber = result.Data.PhoneNumber;
                this.selectedRow.ScreenName = result.Data.ScreenName;
                this.selectedRow.Name = result.Data.Name;
                this.phoneNumbers.push(this.selectedRow);
                this.oldValue = JSON.stringify(this.selectedRow);
                this.activeItem = this.selectedRow.Id;
              } else {
                let errMsg = this.getApiErrorMessage(err);
                this.$swal("Error!", errMsg, "error");
              }
            }
          } else {
            let errMsg = this.getApiErrorMessage(err);
            this.$swal("Error!", errMsg, "error");
          }
        } else {
          this.$swal.fire("Warning!", "No changes detected.", "warning");
        }
      }

      this.isLoading = false;
    },

    async handleDelete(Id) {
      this.isLoading = true;
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then(async (dialog) => {
          if (dialog.value) {
            let rowIndex = this.phoneNumbers.findIndex((x) => x.Id == Id);
            let err, result;
            [err, result] = await this.$store.dispatch(
              types.DELETE_BLOCKED_NUMBERS,
              { Id: Id }
            );
            if (!err) {
              this.reset();
            }
            if (result) {
              if (rowIndex > -1) {
                this.phoneNumbers.splice(rowIndex, 1);
              }
              this.reset();

              this.$swal("Deleted!", result.Message, "success");
            } else {
              let errMsg = this.getApiErrorMessage(err);
              this.$swal("Error!", errMsg, "error");
            }
          }
        });
      this.isLoading = false;
    },
    reset() {
      this.activeItem = null;
      this.selectedRow = deepCopy(init);
      this.isEditing = false;
      this.isLoading = false;
    },
  },
  validations() {
    return {
      selectedRow: {
        PhoneNumber: {
          isPhone(phone) {
            phone = this.cleanFormatPhone(phone);
            const regex = /^[2-9]\d{2}[2-9]\d{2}\d{4}$/;
            return regex.test(phone);
          },
        },
      },
    };
  },
};
</script>
<style>
.has-error {
  border: 2px solid red !important;
}
</style>
