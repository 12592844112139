<template>
  <div
    class="col-md-12 row newPanelWrapper noMargin"
    style="
      background-color: #ffffff;
      z-index: 1000;
      padding-left: 0;
      padding-right: 0;
    "
  >
    <div class="closeButton" v-on:click.prevent.stop="closePanel">
      <i class="fas fa-window-close"></i>
    </div>

    <div class="col-md-12">
      <label>Section Name</label>
      <input
        type="text"
        v-model="sectionName"
        class="form-control"
        placeholder="Section Name"
      />
      <button
        class="col-md-12 mt-2 btn btn-success"
        @click="saveSetting"
        :disabled="sectionName == ''"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "TagSectionPopUp",
  props: {
    pinnedItems: {
      Type: Array,
      defult: null,
    },
  },
  data() {
    return {
      sectionName: "",
    };
  },
  methods: {
    async saveSetting() {
      if (this.sectionName == "") {
        this.$swal("Warning!", "The name cannot be left empty.", "warning");
        return;
      }
      let duplicateItems = this.pinnedItems.filter(
        (item) => item.Name === this.sectionName
      );
      if (duplicateItems.length > 0) {
        this.$swal(
          "Warning!",
          "There is a section with the same name.",
          "warning"
        );
        return;
      }
      this.$emit("addSection", this.sectionName);
    },
    closePanel() {
      this.$emit("closeTagSectionPopUp");
    },
  },
};
</script>

<style></style>
