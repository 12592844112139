<template>
  <div class="row admin-page">
    <div class="col-xl-3 col-lg-4 col-md-12 col-sm-12 panelWrapper">
      <button
        type="button"
        class="col-md-12 btn btn-primary waves-effect waves-light"
        @click="handleAddNew"
      >
        Add New Pause Reason
      </button>
      <div class="list col-md-12">
        <div
          class="role item row"
          v-for="(item, index) in sortedPauseOptions"
          :key="item.Id"
          :class="{ active: activeItem === index }"
          :style="[
            index % 2 === 0
              ? { 'background-color': '#F1EFEF' }
              : { 'background-color': '#FFFFFF' },
          ]"
          :disabled="saving"
          @click="() => handleSelection(item, index)"
        >
          <span :class="{ adminActiveMenuItem: activeItem === index }">
            <i class="far fa-pause-circle"></i>
            <span style="margin-left: 8px">{{ item.Name }}</span>
          </span>
        </div>
      </div>
    </div>
    <div
      class="col-xl-4 col-lg-6 col-md-12 col-sm-12 noMargin"
      v-if="selectedRow != null"
    >
      <div class="form-group row">
        <div class="col-md-12">
          <label class="col-form-label" for="PauseReasonName"
            >Pause Reason Name</label
          >
        </div>
      </div>
      <div class="form-group row">
        <div class="col-md-12">
          <input
            autocomplete="off"
            type="text"
            id="PauseReasonName"
            v-model="selectedRow.Name"
            class="form-control"
            :class="v$.selectedRow.Name.$error ? 'has-error' : ''"
            @blur="v$.selectedRow.Name.$touch"
            placeholder="Enter pause reason"
          />
        </div>
      </div>
      <div class="form-group row mt-2">
        <div
          class="col-xl-6 col-lg-6 col-md-12 col-sm-12"
          v-if="selectedRow && selectedRow.Id > 0"
        >
          <button
            type="button"
            style="width: 100%"
            @click="deletePauseReason()"
            class="float-right btn btn-danger btn-md waves-effect waves-light"
          >
            Delete
          </button>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
          <button
            type="button"
            style="width: 100%"
            class="float-right btn btn-success btn-md waves-effect waves-light"
            @click="savePauseReason()"
            :disabled="v$.$invalid || saving"
          >
            <i v-if="saving" class="fas fa-spinner mr-1"></i>Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import types from "./types";
import { mapState } from "vuex";
import deepCopy from "@/helpers/deepCopy";
import _orderby from "lodash";
import useValidate from "@vuelidate/core";
import { required } from "vuelidate/lib/validators";
import utilitiesMixin from "@/mixins/utilitiesMixin";
const init = {
  Id: 0,
  Name: "",
};
export default {
  name: "PauseReasonManagement",
  mixins: [utilitiesMixin],
  computed: {
    ...mapState({
      pauseOptions: (state) => state.pauseReasonManagement.pauseOptions,
    }),
    sortedPauseOptions() {
      return _orderby.orderBy(
        this.pauseOptions,
        [(item) => item.Name.toLowerCase()],
        "asc"
      );
    },
  },
  async mounted() {
    await this.$store.dispatch(types.GET).catch((err) => {});
  },
  data() {
    return {
      v$: useValidate(),
      selectedRow: null,
      oldValue: "",
      activeItem: null,
      saving: false,
      isCancelledBeforeSave: false,
    };
  },
  methods: {
    async handleAddNew() {
      if (
        this.selectedRow &&
        this.selectedRow.Id != 0 &&
        JSON.stringify(this.selectedRow) != this.oldValue
      ) {
        await this.$swal
          .fire({
            title: "You have unsaved changes",
            text: "You will lose it if you don't save",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, continue without saving!",
          })
          .then(async (dialog) => {
            if (dialog.value) {
              this.isCancelledBeforeSave = true;
            } else {
              this.isCancelledBeforeSave = false;
              return;
            }
          });
      } else {
        this.selectedRow = deepCopy(init);
        this.activeItem = null;
      }

      if (this.isCancelledBeforeSave) {
        this.selectedRow = deepCopy(init);
        this.oldValue = JSON.stringify(this.selectedRow);
        this.activeItem = null;
      }
      this.isCancelledBeforeSave = false;
    },
    async handleSelection(row, index) {
      if (
        this.selectedRow &&
        JSON.stringify(this.selectedRow) != this.oldValue &&
        JSON.stringify(this.selectedRow) != JSON.stringify(init)
      ) {
        await this.$swal
          .fire({
            title: "You have unsaved changes",
            text: "You will lose it if you don't save",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, continue without saving!",
          })
          .then(async (dialog) => {
            if (dialog.value) {
              this.isCancelledBeforeSave = true;
            } else {
              this.isCancelledBeforeSave = false;
              return;
            }
          });
      } else {
        this.selectedRow = deepCopy(row);
        this.oldValue = JSON.stringify(row);
        this.activeItem = index;
      }

      if (this.isCancelledBeforeSave) {
        this.selectedRow = deepCopy(row);
        this.oldValue = JSON.stringify(row);
        this.activeItem = index;
      }
      this.isCancelledBeforeSave = false;
    },
    async savePauseReason() {
      if (this.oldValue == JSON.stringify(this.selectedRow)) {
        this.$swal.fire("Warning!", "No changes detected!", "warning");
      } else {
        this.saving = true;

        let err, result, type;

        type = this.selectedRow.Id > 0 ? types.PUT : types.POST;

        [err, result] = await this.$store.dispatch(type, this.selectedRow);

        if (result) {
          this.$swal.fire("Success!", result.Message, "success");

          if (type == types.POST) {
            this.selectedRow = deepCopy(result.Data);
            this.pauseOptions.push(this.selectedRow);
          } else if (type == types.PUT) {
            this.selectedRow = deepCopy(result.Data);
            let indx = this.pauseOptions.findIndex(
              (x) => x.Id == this.selectedRow.Id
            );

            if (indx >= 0) {
              this.pauseOptions[indx] = this.selectedRow;
              this.pauseOptions.push();
            }
          }
          this.oldValue = JSON.stringify(result.Data);
        } else {
          let errMsg = this.getApiErrorMessage(err);
          this.$swal("Error!", errMsg, "error");
        }

        this.saving = false;
      }
    },
    async deletePauseReason() {
      await this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then(async (dialog) => {
          if (dialog.value) {
            let rowIndex = this.pauseOptions.findIndex(
              (x) => x.Id == this.selectedRow.Id
            );
            let err, result;
            this.saving = true;
            [err, result] = await this.$store.dispatch(
              types.DELETE,
              this.selectedRow.Id
            );

            if (result) {
              this.pauseOptions.splice(rowIndex, 1);
              this.$swal.fire("Deleted!", result.Message, "success");
              this.selectedRow = null;
            } else {
              let errMsg = this.getApiErrorMessage(err);
              this.$swal("Error!", errMsg, "error");
            }
          }
        });
      this.saving = false;
    },
  },
  validations() {
    return {
      selectedRow: {
        Name: { required },
      },
    };
  },
};
</script>
