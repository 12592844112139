<template>
  <div
    class="col-md-12 newPanelWrapper noMargin pchp-mediaScreen"
    style="
      background-color: #ffffff;
      z-index: 1000;
      padding-left: 0;
      padding-right: 0;
    "
  >
    <div v-if="isLoading" class="loading">
      <div class="text-center" style="margin-top: 290px">
        <i class="spinner-grow text-info m-2"></i>
      </div>
    </div>
    <div class="popupHeader generalLabel">
      <label style="font-size: 15px">Array Additional Authorization</label>
    </div>

    <div>
      <div class="closeButton_ada" v-on:click.prevent.stop="closePanel">
        <i class="fas fa-window-close"></i>
      </div>
    </div>

    <perfect-scrollbar
      style="
        width: 100%;
        min-height: 500px;
        max-height: 500px;
        overflow-x: hidden !important;
      "
    >
      <div
        style="margin-top: -50px"
        class="col-md-12"
        v-if="isArrayAdditionalAuth"
      >
        <array-authentication-kba
          :appKey="arrayCredentials.appKey"
          :userId="arrayCredentials.clientKey"
          :tui="true"
          :exp="true"
          :efx="true"
          showResultPages="true"
          :sandbox="arrayCredentials.isTestMode"
        >
        </array-authentication-kba>
      </div>
      <!-- <div class="col-md-12">
        <div class="col-md-12">
          <div v-for="item in questionData.Questions" :key="item.id">
            <label class="generalLabel">{{ item.text }}</label>

            <div
              style="display: flex"
              v-for="answer in item.answers"
              :key="answer.id"
              class="col-md-12"
            >
              <input
                type="radio"
                :name="item.id"
                @change="setAnswer(item.id, answer.id)"
                class="checkbox-input generalCheckBox"
                style="
                  border-radius: 4px !important;
                  border: solid 1px #d1d1d1 !important;
                "
              />
              <label class="ml-2">
                {{ answer.text }}
              </label>
            </div>
            <hr />
          </div>
        </div>
        <div class="col-md-12">
          <button @click="sendAnswers()" class="col-md-12 btn btn-success">
            Submit
          </button>
        </div>
      </div> -->
    </perfect-scrollbar>
  </div>
</template>

<script>
import types from "./types";
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import deepCopy from "@/helpers/deepCopy";
let form;
export default {
  name: "ArrayAdditionalAuthorization",
  mixins: [utilitiesMixin, formatMixin],
  components: {},
  props: {
    arrayCredentials: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      isLoading: false,
      isArrayAdditionalAuth: false,
      provider: "",
    };
  },
  unmounted() {
    window.removeEventListener("array-event", this.arrayEvent);
  },
  async mounted() {
    form = this;
    window.addEventListener("array-event", this.arrayEvent);
    let showError = false;
    if (this.arrayCredentials) {
      if (
        this.arrayCredentials.appKey != "" &&
        this.arrayCredentials.clientKey != "" &&
        this.arrayCredentials.providerName &&
        this.arrayCredentials.isTestMode != null
      ) {
        if (this.arrayCredentials.providerName.length > 3) {
          this.provider = this.arrayCredentials.providerName.substr(0, 3);
        }

        this.isLoading = true;
        if(!document.getElementById('arrayScript')){
          let arrayMainScript = document.createElement("script");
          arrayMainScript.setAttribute(
                  "src",
                  "https://embed.array.io/cms/array-web-component.js?appKey=" +
                  this.arrayCredentials.appKey
          );
          document.head.appendChild(arrayMainScript);
        }

        await this.sleep(1000);
        if(!document.getElementById('arrayKbaScript')){
          let arrayKBAScript = document.createElement("script");
          arrayKBAScript.setAttribute(
                  "src",
                  "https://embed.array.io/cms/array-authentication-kba.js?appKey=" +
                  this.arrayCredentials.appKey
          );
          document.head.appendChild(arrayKBAScript);
        }
        await this.sleep(1000);
        this.isArrayAdditionalAuth = true;
        this.isLoading = false;
      } else {
        showError = true;
      }
    } else {
      showError = true;
    }

    if (showError) {
      this.$swal(
        "Error",
        "Questions couldn't get. Some parameters are missing.",
        "error"
      );
    }
  },

  methods: {
    // setAnswer(questionId, answerId) {
    //   this.answers[questionId] = answerId;
    // },
    async arrayEvent(arrayEvent) {
      const {
        tagName, // the name of the component that emitted the event
        event, // the name of the user's action
        metadata = {}, // component-specific data
      } = arrayEvent.detail;

      if (tagName == "array-authentication-kba" && event == "success") {
        form.isArrayAdditionalAuth = false;
        await form.sendAuthenticationToken();
      } else if (tagName == "array-authentication-kba" && event == "failure") {
        form.$emit("closeArrayAdditionalAuthorization", {
          Message: {
            Text: "Authentication failed!",
            Type: "Error",
          },
          Data: null
        });
      }
    },
    async sendAuthenticationToken() {
      if (this.arrayCredentials && this.arrayCredentials.isJointRequest) {
        this.$emit("closeArrayAdditionalAuthorization", { Message:"", Data: this.arrayCredentials});
      } else {
        let err, result;
        [err, result] = await this.$store.dispatch(
          types.SEND_ARRAY_AUTH_ANSWERS,
          {
            ServiceId: 1,
            CreditPullLogId: this.arrayCredentials.logId,
          }
        );

        if (result) {
          this.$swal(
            "Success",
            "Credit pull requested, please check the status from pull history shortly.",
            "success"
          );
          this.$emit("closeArrayAdditionalAuthorization");
        } else {
          this.$swal(
            "Error",
            "An error occured. Please try again later.",
            "error"
          );
          this.$emit("closeArrayAdditionalAuthorization");
        }
      }
    },
    async closePanel() {
      await this.$swal({
        title: "Confirm",
        text: "Are you sure you want to leave without submitting the answers? You can continue submitting the answers from the credit pull history without putting a new order.",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (dialog) => {
        if (dialog.value) {
          
          this.$emit("closeArrayAdditionalAuthorization");
          
        }
      });
    },
  },
  computed: {},
};
</script>

<style scope>
.has-error {
  border: 2px solid red !important;
}
.popupHeader {
  margin-left: 23px;
  margin-top: -5px;
  padding-bottom: 10px;
}
.loading {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.6);
  z-index: 1000;
}
.closeButton_ada {
  position: absolute;
  top: -5px;
  right: 15px;
  z-index: 500;
  font-size: 30px;
  cursor: pointer;
}
</style>
