<template>
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 noPadding noMargin">
    <iframe
      v-if="showIframe"
      style="border: none !important"
      width="100%"
      height="1000"
      :src="iframeSrc"
    ></iframe>
  </div>
</template>
<script>
import utilitiesMixin from "@/mixins/utilitiesMixin";
import globalTypes from "@/store/types";
export default {
  name: "CustomerPortalScreen",
  mixins: [utilitiesMixin],
  props: {
    portalUrl: {
      Type:   String,
      Default: "",
    },
  },
  watch: {
    portalUrl(newVal, oldVal) {

      if (newVal != oldVal && newVal) {
        this.watcherRefresh();
      }
    },
  },
  data() {
    return {
      showIframe: false,
      iframeSrc: "",
    };
  },
  async mounted() {

  

  },
  methods: {
    async watcherRefresh() {

      if(this.portalUrl != '')
      {
        this.iframeSrc = this.portalUrl;
        this.showIframe=true;
      }
      else
      {
        this.showIframe=false;
      }
    }
  },
};
</script>
