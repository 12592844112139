<template>
  <div class="col-md-12">
    <ReportSummary
      :exportData="inboundStatisticsReportData"
      :reportName="'InboundStatisticsReport'"
      :recordCount="recordCount"
      @updateLoadingStatus="reportLoading = $event"
      @setPayload="setPayload"
      @clearReport="clearReport"
      :loadingSpinner="loadingSpinner"
    />
    <form class="col-md-12 newPanelWrapper mt-3" role="form">
      <span style="font-size: 16px; color: rgb(51, 51, 51)"
        >Campaign Report</span
      >
    </form>
    <div
      class="datagrid-table gcsTabsTableScroll report-wrapper"
      :style="
        'max-height: ' +
        parseInt(parseInt(reportsPanelHeight / 2) - 60) +
        'px; overflow-y:auto;'
      "
    >
      <o-table :data="filteredCampaignReport">
        <o-table-column field="Campaign" label="Campaign" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                placeholder=""
                v-model="CampaignReportCampaignFilter"
              />
            </div>
          </template>

          <template v-slot="props"> {{ props.row.Campaign }} </template>
        </o-table-column>

        <o-table-column field="User" label="User" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                placeholder=""
                v-model="CampaignReportUserFilter"
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.User }}
          </template>
        </o-table-column>
        <o-table-column
          field="InboundCallCount"
          label="Inbound Call Count"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                placeholder=""
                v-model="CampaignReportInboundCallCountFilter"
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.InboundCallCount }}
          </template>
        </o-table-column>
        <o-table-column
          field="MissedCallCount"
          label="Missed Call Count"
          sortable
          v-model="CampaignReportMissedCallCountFilter"
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                placeholder=""
                v-model="CampaignReportMissedCallCountFilter"
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.MissedCallCount }}
          </template>
        </o-table-column>
      </o-table>
      <section
        v-if="filteredCampaignReport.length == 0"
        class="section"
        style="text-align: center"
      >
        <div class="content has-text-grey has-text-centered mb-4">
          <p class="noMargin">
            <i
              style="font-size: 24px; color: #939393"
              class="ri-question-line"
            ></i>
          </p>
          <p class="noMargin">Nothing here.</p>
        </div>
      </section>
    </div>

    <form class="col-md-12 newPanelWrapper mt-3" role="form">
      <span style="font-size: 16px; color: rgb(51, 51, 51)">User Report</span>
    </form>
    <div
      class="datagrid-table gcsTabsTableScroll report-wrapper"
      :style="
        'max-height: ' +
        parseInt(parseInt(reportsPanelHeight / 2) - 60) +
        'px; overflow-y:auto;'
      "
    >
      <o-table :data="filteredUserReport">
        <o-table-column field="User" label="User" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                placeholder=""
                v-model="UserReportUserFilter"
              />
            </div>
          </template>

          <template v-slot="props"> {{ props.row.User }} </template>
        </o-table-column>

        <o-table-column
          field="InboundCallCount"
          label="Inbound Call Count"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                placeholder=""
                v-model="UserReportInboundCallCount"
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.InboundCallCount }}
          </template>
        </o-table-column>
        <o-table-column
          field="MissedCallCount"
          label="Missed Call Count"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                placeholder=""
                v-model="UserReportMissedCallCount"
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.MissedCallCount }}
          </template>
        </o-table-column>
        <o-table-column
          field="OutboundCallCount"
          label="Outbound Call Count"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                placeholder=""
                v-model="UserReportOutboundCallCount"
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.OutboundCallCount }}
          </template>
        </o-table-column>
      </o-table>
      <section
        v-if="filteredUserReport.length == 0"
        class="section"
        style="text-align: center"
      >
        <div class="content has-text-grey has-text-centered mb-4">
          <p class="noMargin">
            <i
              style="font-size: 24px; color: #939393"
              class="ri-question-line"
            ></i>
          </p>
          <p class="noMargin">Nothing here.</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import ReportSummary from "./ReportSummary.vue";
import utilitiesMixin from "@/mixins/utilitiesMixin";

export default {
  name: "InboundStatisticsReport",
  components: { ReportSummary },
  mixins: [utilitiesMixin],

  data() {
    return {
      reportsPanelHeight: 300,
      loadingSpinner: false,
      recordCount: 0,
      reportLoading: false,
      reportData: [],
      inboundStatisticsReportData: [],

      inboundStatisticsSummary: {
        CampaignReport: {
          Campaign: "",
          User: "",
          InboundCallCount: null,
          MissedCallCount: null,
        },
        UserReport: {
          User: "",
          InboundCallCount: null,
          MissedCallCount: null,
          OutboundCallCount: null,
        },
      },

      CampaignReportCampaignFilter: "",
      CampaignReportUserFilter: "",
      CampaignReportInboundCallCountFilter: "",
      CampaignReportMissedCallCountFilter: "",
      UserReportUserFilter: "",
      UserReportInboundCallCount: "",
      UserReportMissedCallCount: "",
      UserReportOutboundCallCount: "",
    };
  },
  computed: mapState({
    documentsInboundStatisticsList: (state) =>
      state.reports.inboundStatisticsList,

    filteredCampaignReport() {
      let rtn = [];

      let tmpList =
        this.documentsInboundStatisticsList != null &&
        this.documentsInboundStatisticsList.CampaignReport != null &&
        this.documentsInboundStatisticsList.CampaignReport.length > 0
          ? this.documentsInboundStatisticsList.CampaignReport.filter(
              (item) => {
                return (
                  this.filterString(
                    item.Campaign,
                    this.CampaignReportCampaignFilter
                  ) &&
                  this.filterString(item.User, this.CampaignReportUserFilter) &&
                  this.filterNumeric(
                    item.InboundCallCount,
                    this.CampaignReportInboundCallCountFilter
                  ) &&
                  this.filterNumeric(
                    item.MissedCallCount,
                    this.CampaignReportMissedCallCountFilter
                  )
                );
              }
            )
          : [];

      if (tmpList.length == 0) {
        this.reportData = [];
        return rtn;
      }
      rtn.push(...tmpList);
      this.reportData = rtn;
      this.inboundStatisticsReportData.push(rtn);

      return rtn;
    },
    filteredUserReport() {
      let rtn = [];

      let tmpList =
        this.documentsInboundStatisticsList != null &&
        this.documentsInboundStatisticsList.UserReport != null &&
        this.documentsInboundStatisticsList.UserReport.length > 0
          ? this.documentsInboundStatisticsList.UserReport.filter((item) => {
              return (
                this.filterString(item.User, this.UserReportUserFilter) &&
                this.filterNumeric(
                  item.InboundCallCount,
                  this.UserReportInboundCallCount
                ) &&
                this.filterNumeric(
                  item.MissedCallCount,
                  this.UserReportMissedCallCount
                ) &&
                this.filterNumeric(
                  item.OutboundCallCount,
                  this.UserReportOutboundCallCount
                )
              );
            })
          : [];

      if (tmpList.length == 0) {
        this.reportData = [];
        return rtn;
      }
      rtn.push(...tmpList);
      this.reportData = rtn;
      this.inboundStatisticsReportData.push(rtn);
      return rtn;
    },
  }),
  mounted() {
    this.reportsPanelHeight = this.setReportGridHeight(
      window.innerHeight,
      document.getElementById("reportsSummary")
    );
  },
  methods: {
    async setPayload(value) {
      this.loadingSpinner = true;
      await this.$store.dispatch(types.GET_INBOUND_STATISTICS_REPORT, value);
      this.loadingSpinner = false;
    },
    clearReport() {
      this.$store.dispatch(types.RESET_REPORTS);
      this.reportData = [];
    },
  },
};
</script>

<style scoped></style>
