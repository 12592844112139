<template>
  <div
    class="table-responsive gridHeight newPanelWrapper"
    style="box-shadow: 0 0 1px 1px #d1d1d1 !important; padding: 1px !important"
  >
    <form v-on:keydown.enter.prevent="() => {}">
      <table
        class="datagrid-table mb-0"
        id="btn-editable-pg"
        style="width: 100%; border: none !important; background-color: #ffffff"
      >
        <thead>
          <tr>
            <th>Phone</th>
            <th>Type</th>
            <th>Priority</th>
            <th></th>
          </tr>

          <tr v-if="isNewRowActive" v-click-outside="outsideOfNewRow">
            <th>
              <input
                @change="newPhoneChange"
                class="tabledit-input form-control generalTextBox"
                id="newPhoneInp"
                type="text"
                name="col1"
                v-model="newRow.PhoneNumber"
                v-myMask="{ mask: ['(999) 999-9999'] }"
                :class="v$.newRow.PhoneNumber.$error ? 'has-error' : ''"
                @blur="v$.newRow.PhoneNumber.$touch"
              />
            </th>
            <th>
              <select
                v-model="newRow.PhoneTypeId"
                class="form-control generalSelectBox"
              >
                <option
                  v-for="item in phoneTypes"
                  :value="item.Id"
                  :key="item.Id"
                >
                  {{ item.Name }}
                </option>
              </select>
            </th>
            <th>
              <select
                v-model="newRow.Priority"
                class="form-control generalSelectBox"
              >
                <option
                  v-for="item in priorityTypes"
                  :value="item.Id"
                  :key="item.Id"
                >
                  {{ item.Name }}
                </option>
              </select>
            </th>
            <th>
              <button
                :disabled="v$.$invalid || myInvalid"
                type="button"
                class="btn"
                style="background-color: #24d15f"
                v-on:click.prevent="handleNewRowSave"
              >
                <i class="fas fa-save"></i>
              </button>
            </th>
          </tr>
        </thead>

        <tbody v-if="value">
          <tr v-for="item in value" :key="item.Id">
            <td>
              <div v-show="!item.Edit">
                <button
                  v-if="customerId > 0 && !isMobileDevice() && hasPhone"
                  type="button"
                  class="btn"
                  style="background-color: #24d15f"
                  v-on:click.prevent="makeCall(item.PhoneNumber, customerId)"
                >
                  <i class="fe-phone"></i>
                </button>

                <span class="tabledit-span tabledit-identifier">
                  {{ formatPhoneNumber(item.PhoneNumber) }}
                </span>
              </div>

              <input
                class="tabledit-input generalTextBox form-control"
                v-on:keydown.enter.prevent="resetEnter"
                v-on:keydown.esc.prevent="submitEsc"
                type="text"
                name="col1"
                v-model="item.PhoneNumber"
                style="display: none"
                :key="'ph' + item.Id"
                :id="'phid' + item.Id"
                @input="myPhoneValidation"
                @change="editPhoneChange($event, item)"
                v-show="item.Edit"
                v-myMask="{ mask: ['(999) 999-9999'] }"
              />
            </td>
            <td class="tabledit-view-mode">
              <span v-show="!item.Edit" class="tabledit-span">
                {{
                  phoneTypes
                    .filter((x) => x.Id === item.PhoneTypeId)
                    .map((x) => x.Name)[0]
                }}
              </span>

              <select
                v-on:keydown.enter.prevent="resetEdit(null)"
                v-on:keydown.esc.prevent="resetEdit(null)"
                v-show="item.Edit"
                v-model="item.PhoneTypeId"
                class="form-control generalSelectBox"
              >
                <option
                  v-for="item in phoneTypes"
                  :value="item.Id"
                  :key="item.Id"
                >
                  {{ item.Name }}
                </option>
              </select>
            </td>
            <td class="tabledit-view-mode">
              <span v-show="!item.Edit" class="tabledit-span">
                {{
                  priorityTypes
                    .filter((x) => x.Id === item.Priority)
                    .map((x) => x.Name)[0]
                }}
              </span>
              <select
                v-on:keydown.enter.prevent="resetEdit(null)"
                v-on:keydown.esc.prevent="resetEdit(null)"
                v-show="item.Edit"
                v-model="item.Priority"
                class="form-control generalSelectBox"
              >
                <option
                  v-for="item in priorityTypes"
                  :value="item.Id"
                  :key="item.Id"
                >
                  {{ item.Name }}
                </option>
              </select>
            </td>

            <td style="white-space: nowrap; width: 1%">
              <button
                type="button"
                title="Edit"
                :disabled="myInvalid"
                class="btn"
                style="background-color: #24d171"
                v-on:click.prevent.stop="
                  () => {
                    resetEdit(item);
                    item.Edit = !item.Edit;
                    isNewRowActive = false;
                  }
                "
              >
                <i v-show="!item.Edit" class="ri-edit-line"></i>
                <i v-show="item.Edit" class="fe-check"></i>
              </button>
              <button
                type="button"
                title="Delete"
                class="btn"
                style="background-color: #ed3030"
                :disabled="myInvalid"
                v-on:click.prevent.stop="() => handleDelete(item.Id)"
              >
                <i class="fe-trash-2"></i>
              </button>
            </td>
          </tr>
          <tr
            v-if="!isNewRowActive"
            style="background-color: #ffffff !important"
          >
            <td colspan="4">
              <button
                type="button"
                title="Delete"
                class="addNewButton"
                :disabled="myInvalid"
                v-on:click.prevent.stop="() => startNewRow()"
              >
                <i style="font-size: 18px" class="fe-plus-circle"></i>
                Add New Phone
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  </div>
</template>

<script>
import types from "@/views/agent/EditClient/types";
import { mapState } from "vuex";
import useValidate from "@vuelidate/core";
import formatMixin from "@/mixins/formatMixin.js";

export default {
  name: "PhoneGrid",

  props: {
    Phones: {
      type: Array,
      default: () => [],
    },
    customerId: {
      type: Number,
      default: () => 0,
    },
    coCustomerId: {
      type: Number,
      default: () => 0,
    },
  },
  watch: {
    Phones(newVal, oldVal) {
      if (JSON.stringify(newVal) != JSON.stringify(oldVal)) {
        this.value = Object.assign([], this.Phones);
      }
    },
  },
  mixins: [formatMixin],
  data() {
    return {
      initRow: Object.assign({}, this.initRowFnc()),
      value: Object.assign([], this.Phones),
      v$: useValidate(),
      selectedPhoneType: null,
      selectedPriority: null,
      isNewRowActive: false,
      newRow: Object.assign({}, this.initRowFnc()),
      myInvalid: false,
      hasPhone: false,
    };
  },
  computed: mapState({
    phoneTypes: (state) => state.globals.phoneTypes,
    priorityTypes: (state) => state.globals.priorityTypes,
  }),
  mounted() {
    let phoneSettings = JSON.parse(sessionStorage.getItem("userPhoneSettings"));

    this.hasPhone = phoneSettings && phoneSettings.endPointName != null;
  },
  unmounted() {
    // this.emptyTheObjectsOnUnmount();
  },
  methods: {
    initRowFnc() {
      return {
        Id: 0,
        PhoneNumber: "",
        PhoneTypeId: 1,
        Priority: 1,
        Edit: false,
      };
    },
    emptyTheObjectsOnUnmount() {
      this.value = null;
      this.selectedPhoneType = null;
      this.selectedPriority = null;
      this.isNewRowActive = null;
      this.newRow = null;
      this.myInvalid = null;
    },
    newPhoneChange(event) {
      if (event.target.value && event.target.value != "") {
        this.newRow.PhoneNumber = event.target.value;
      }
    },

    editPhoneChange(event, item) {
      if (event.target.value && event.target.value != "") {
        item.PhoneNumber = event.target.value;
      }
    },

    makeCall(phone, customerId) {
      if (phone.length >= 10) {
        if (phone.length == 11 && phone.startsWith("1")) {
          phone = phone.slice(1, phone.length);
        }

        this.$Emitter.emit("makeCall", {
          phone: phone,
          customerId: customerId,
        });
      }
    },
    resetEdit(obj) {
      if (this.myInvalid) return;

      for (let item of this.value) {
        if (item !== obj) item.Edit = false;
      }
      this.$emit("updatePhones", this.value);
    },
    resetEnter({ target }) {
      this.phoneValidation(target);
      this.resetEdit(null);
    },
    submitEsc() {
      if (this.myInvalid) return;

      for (let item of this.value) {
        item.Edit = false;
      }
    },
    formatPhoneNumber(phoneNumberString) {
      let cleaned = ("" + phoneNumberString).replace(/\D/g, "");
      let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
      }
      return "";
    },
    startNewRow() {
      if (this.myInvalid) return;
      this.resetEdit(null);
      this.isNewRowActive = true;
    },
    handleNewRowSave() {
      if (
        this.newRow.PhoneNumber != "" &&
        this.newRow.PhoneNumber.length >= 10
      ) {
        this.resetEdit(null);
        this.isNewRowActive = false;
        let newId = -1;
        if (this.value.length > 0) {
          let minId = Math.min(...this.value.map((x) => x.Id));
          if (minId < 0) {
            newId = minId - 1;
          }
        }
        this.newRow.Id = newId;

        if (!this.checkSamePhoneExists(this.newRow.PhoneNumber)) {
          this.value.push(this.newRow);
        } else {
          this.$swal(
            "Warning!",
            "This phone number already exists.",
            "warning"
          );
        }

        this.newRow = Object.assign({}, this.initRow);

        this.$emit("updatePhones", this.value);
      }
    },
    outsideOfNewRow() {
      this.phoneValidation(document.getElementById("newPhoneInp"));

      if (this.myInvalid) {
        this.newRow = Object.assign({}, this.initRow);
        this.isNewRowActive = false;
        this.myInvalid = false;
        return;
      }
      this.resetEdit(null);
      this.isNewRowActive = false;
      let newId = -1;
      if (this.value.length > 0) {
        let minId = Math.min(...this.value.map((x) => x.Id));
        if (minId < 0) {
          newId = minId - 1;
        }
      }
      this.newRow.Id = newId;
      if (!this.checkSamePhoneExists(this.newRow.PhoneNumber)) {
        this.value.push(this.newRow);
      } else {
        this.$swal("Warning!", "This phone number already exists.", "warning");
      }
      this.newRow = Object.assign({}, this.initRow);
      this.$emit("updatePhones", this.value);
    },
    myPhoneValidation({ target }) {
      this.phoneValidation(target);
    },
    phoneValidation(target) {
      this.myInvalid = false;
      if (target.value != null) {
        if (target.value.length != 10) {
          this.myInvalid = true;
        } else if (
          target.value.length == 10 &&
          this.value.filter((x) => x.PhoneNumber == target.value).length > 1
        ) {
          this.myInvalid = true;
          this.$swal(
            "Warning!",
            "This phone number already exists.",
            "warning"
          );
        } else {
          this.myInvalid = false;
        }
      }
    },
    async handleDelete(itemId) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then(async (dialog) => {
          if (dialog.value) {
            let rowIndex = this.value.indexOf(itemId);
            let result;
            if (itemId > 0) {
              result = await this.$store.dispatch(types.DELETEPHONE, {
                Id: itemId,
                CustomerId: this.customerId,
                CoCustomerId: this.coCustomerId,
              });
            } else {
              result = "success";
            }
            if (result) {
              let rowIndex = this.value.findIndex((x) => x.Id == itemId);
              if (rowIndex > -1) {
                this.value.splice(rowIndex, 1);
              }
              this.isNewRowActive = false;
              this.$emit("update:Customer", this.value);
              this.$swal("Deleted!", result.Message, "success");
            } else {
              let errMsg = "An Error Occured!";

              this.$swal("Error!", errMsg, "error");
            }
          }
        });
    },
    checkSamePhoneExists(phone) {
      let rtn = false;

      rtn = this.Phones.some(({ PhoneNumber }) => PhoneNumber == phone);

      return rtn;
    },
  },
  validations() {
    return {
      newRow: {
        PhoneNumber: {
          isPhone(phone) {
            phone = this.cleanFormatPhone(phone);
            const regex = /^[2-9]\d{2}[2-9]\d{2}\d{4}$/;
            return regex.test(phone);
          },
        },
      },
    };
  },
};
</script>
