import types from "./types";
import api from "@/services/admin/api";
import to from "@/helpers/to";
export default {
  state: {
    pauseOptions: [],
  },
  actions: {
    GET: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.getPauseReasonTypes());

      if (result) {
        commit(types.SET, result.Data);
      }
    },
    POST: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.insertPauseReasonType(payload));

      return [err, result];
    },
    PUT: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.updatePauseReasonType(payload));

      return [err, result];
    },
    DELETE: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.deletePauseReasonType(payload));

      return [err, result];
    },
  },
  mutations: {
    SET: (state, payload) => {
      state.pauseOptions = payload;
    },
  },
};
