import types from "./types";
import api from "@/services/client/customerApi";
import to from "@/helpers/to";

export default {
  state: {
    creditorStatuses: [],
    accountTypes: [],
    accountHolders: [],
  },
  actions: {
    GET_CLIENT_CREDITORS_PAYMENT_TYPES: async ({ commit }) => {
      let err, result;
      [err, result] = await to(api.getNegotiatorTasksPaymentTypes());
      return [err, result];
    },

    GET_CREDITOR_SETTINGS_BY_NAME: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.getCreditorSettingsByCreditorName(payload));
      return [err, result];
    },
    GET_SETTLEMENT_DETAILS: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.getCustomerSettlementDetails(payload));

      return [err, result];
    },
    UPDATE_CREDITOR_NOTE: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.updateNegotiatorTaskNote(payload));

      return [err, result];
    },

    GET_CREDITOR_INFO: async ({ commit, state }, payload) => {
      let err, result;
      [err, result] = await to(api.getCreditorInfo(payload));

      return [err, result];
    },
    GET_CREDITORS: async ({ commit, state }, payload) => {
      let err, result;
      [err, result] = await to(api.getCustomerCreditors(payload));

      return [err, result];
    },
    GET_CREDITOR: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.getCustomerCreditor(payload));
      return [err, result];
    },
    INSERT_CREDITOR: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.addCustomerCreditor(payload));
      return [err, result];
    },
    UPDATE_CREDITOR: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.updateCustomerCreditor(payload));
      return [err, result];
    },
    DELETE_CREDITOR: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.deleteCustomerCreditor(payload));
      return [err, result];
    },
    GET_ACCOUNTTYPES: async ({ commit, state }, payload) => {
      let err, result;
      if (state.accountTypes.length > 0) return;
      [err, result] = await to(api.getCreditorAccountTypes(payload));
      if (result) {
        commit("SET_ACCOUNTTYPES", result.Data);
      }
    },
    GET_ACCOUNTHOLDERS: async ({ commit, state }, payload) => {
      let err, result;
      if (state.accountHolders.length > 0) return;
      [err, result] = await to(api.getCreditorAccountHolders(payload));
      if (result) {
        commit("SET_ACCOUNTHOLDERS", result.Data);
      }
    },
    GET_CREDITORSTATUSES: async ({ commit, state }, payload) => {
      let err, result;
      if (state.creditorStatuses.length > 0) return;
      [err, result] = await to(api.getCreditorStatus(payload));
      if (result) {
        commit("SET_CREDITORSTATUSES", result.Data);
      }
    },
    SETTLE_CREDITOR: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.settleCreditor(payload));
      return [err, result];
    },
    VOID_CREDITOR_SETTLEMENT: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.voidSettlement(payload));
      return [err, result];
    },
  },
  mutations: {
    SET_ACCOUNTTYPES: (state, payload) => {
      state.accountTypes = payload;
    },
    SET_ACCOUNTHOLDERS: (state, payload) => {
      state.accountHolders = payload;
    },
    SET_CREDITORSTATUSES: (state, payload) => {
      state.creditorStatuses = payload;
    },
  },
};
