<template>
  <div>
    <video hidden="hidden" controls id="audioElement"></video>
    <audio
      v-if="incomingCallList.length > 0"
      loop
      preload
      id="incomingRingPlayer"
    >
      <source
        :src="
          state != null && (state == 2 || state == 4 || state == 5)
            ? 'https://globalcrmstorageaccount.blob.core.windows.net/public-sounds/incomingRingToneLow.mp3'
            : 'https://globalcrmstorageaccount.blob.core.windows.net/public-sounds/incomingRingTone.mp3'
        "
        type="audio/mpeg"
      />
    </audio>
    <audio v-if="playAutoCallSound" preload autoplay id="autoCallPlayer">
      <source
        src="https://globalcrmstorageaccount.blob.core.windows.net/public-sounds/autoCall.wav"
        type="audio/wav"
      />
    </audio>
    <div
      class="pPanelWrapper"
      v-show="userLoggedIn"
      v-click-outside="resetPopups"
    >
      <div v-if="plivoLoading" class="loading">
        <div class="text-center" style="margin-top: 50px">
          <i class="spinner-grow text-info m-2"></i>
        </div>
      </div>

      <!-- incoming call list -->
      <div
        class="incomingCallWrapper"
        style="max-height: 300px; overflow: auto"
        v-if="callQueue.length > 0 && isCallQueuePanelVisible"
      >
        <!-- queue call v-for area -->
        <div
          class="row"
          v-for="item in callQueue"
          :key="item.call.callerId"
          style="background-color: #f7d0cb"
        >
          <div class="col-md-4">
            <div class="float-left">
              <div class="float-left">
                <span class="incoming">Queued Call</span>
              </div>
              <div class="float-left ml-2">
                <p :class="item.call.clientId > 0 ? 'noMargin' : 'mt-2'">
                  <span style="font-weight: bold">{{
                    item.call.phoneNumber
                  }}</span>
                </p>
              </div>
              <div v-if="item.call.ringTime" class="float-left ml-1">
                <!-- <span class="duration">{{
                  formatSecond(item.call.ringTime)
                }}</span> -->
              </div>
            </div>
          </div>
          <div class="col-md-4 text-center">
            <div v-if="item.call">
              <p v-if="item.call.clientId > 0" class="noMargin">
                {{ item.call.statusName }}
              </p>
              <p
                v-if="
                  item.call.callerId &&
                  item.call.callerId.length > 2 &&
                  !item.call.isInternal
                "
                class="noMargin"
              >
                CID: {{ getCallerId(item.call) }}
              </p>
            </div>
          </div>
          <div class="col-md-4">
            <div
              :enabled="queueButtonEnabled"
              class="button float-right"
              @click="answerFromQueue(item)"
            >
              <span class="icons">
                <i style="color: green" class="fas fa-phone"></i>
              </span>
            </div>
            <div
              v-if="
                activeCall &&
                activeCall.isCallActive &&
                item.call.otherLeg == activeCall.relatedPhoneNumber
              "
              :enabled="queueButtonEnabled"
              class="button float-right"
              @click="merge3rdPartyCall(item)"
            >
              <span
                style="background-color: #3bafda; transform: rotate(90deg)"
                class="icons"
              >
                <i style="color: white" class="mdi mdi-call-merge"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="incomingCallWrapper callPanelScroll"
        v-if="incomingCallList && incomingCallList.length > 0"
      >
        <!-- incoming call v-for area -->
        <div
          class="row"
          v-for="item in showIncomingCalls()"
          :key="item.callerId"
        >
          <div class="col-md-4">
            <div class="float-left">
              <div class="float-left">
                <span class="incoming">Incoming: </span>
              </div>
              <div class="float-left ml-2">
                <p
                  :class="
                    item.clientId != null && item.clientId > 0
                      ? 'noMargin'
                      : 'mt-2'
                  "
                >
                  <span style="font-weight: bold">{{ item.callerId }}</span>
                </p>
                <p
                  v-if="item.clientId != null && item.clientId > 0"
                  class="noMargin"
                >
                  {{ item.phoneNumber }}
                </p>
              </div>
              <div
                v-if="item.ringTime && !item.isAutoCall"
                class="float-left ml-1"
              >
                <span class="duration">{{
                  showIncallCountDown(formatSecond(item.ringTime))
                }}</span>
              </div>
            </div>
          </div>
          <div class="col-md-4 text-center">
            <p
              v-if="
                item.creditorContactId != null && item.creditorContactId > 0
              "
              class="mt-2"
            >
              {{ item.creditorContactName }}
            </p>

            <p v-if="item.clientId != null && item.clientId > 0" class="mt-2">
              {{ item.statusName }}
            </p>
            <p
              v-if="item.campaignId != null && item.campaignId > 0"
              class="mt-2"
            >
              <span style="font-weight: bold"
                >Campaign: {{ item.campaignName }}</span
              >
            </p>
          </div>
          <div class="col-md-4">
            <div
              class="button float-right hangup"
              @click="rejectCall(item.callUUID)"
            >
              <span class="icons">
                <i class="fe-phone-off"></i>
              </span>
            </div>
            <div class="button float-right" @click="answerIncomingCall(item)">
              <span class="icons">
                <i style="color: green" class="fas fa-phone"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
      <!-- incoming call list end-->
      <div
        :class="{
          'active-row ': state !== 1,
          'disposition-enabled':
            (state === 3 || state === 2 || state == 5) &&
            activeCall &&
            activeCall.clientId > 0,
        }"
      >
        <div
          class="row"
          :class="{ active: state !== 1 }"
          style="position: relative"
        >
          <div
            v-if="openInfoPanel && infoPanelText.length > 0"
            class="infoRow noMargin"
            :class="infoPanelClass"
          >
            <div v-html="infoPanelText" class="text-center"></div>
          </div>
          <div class="col-md-5">
            <div class="contact-wrapper noPadding">
              <div v-if="state == 1" class="float-left">
                <div class="float-left">
                  <span class="no-calls">No Current Call</span>
                </div>
              </div>
              <div v-if="activeCall && state != 1" class="float-left">
                <div class="float-left">
                  <span v-show="state == 2 || state == 4" class="connected"
                    >Connected</span
                  >
                  <span v-show="state == 3" class="hung-up">Hung Up</span>
                  <span v-show="state == 5" class="on-hold">On Hold</span>
                </div>
                <div class="float-left ml-1">
                  <p :class="activeCall.clientId > 0 ? 'noMargin' : 'mt-2'">
                    <span style="font-weight: bold">
                      {{ getCustomerNameArea(activeCall) }}
                    </span>
                  </p>
                  <p v-if="activeCall.clientId > 0" class="noMargin">
                    {{ outgoingClientPhone }}
                  </p>
                </div>
                <div class="float-left ml-1">
                  <span class="duration">
                    {{ formatSecond(activeCall.ringTime) }}
                  </span>
                </div>
                <div class="float-left ml-1">
                  <span
                    v-if="
                      activeCall &&
                      activeCall.phoneNumber &&
                      !activeCall.isInternal
                    "
                    v-show="state == 3"
                    v-on:click.prevent.stop="redialClick()"
                    class="duration"
                    style="
                      background-color: #24d15f;
                      color: white;
                      cursor: pointer;
                    "
                    >Redial</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3 text-center">
            <div v-if="activeCall">
              <p
                v-if="
                  activeCall.creditorContactId > 0 &&
                  activeCall.creditorContactName != ''
                "
                class="noMargin"
              >
                <span style="font-weight: bold"
                  >{{ maxNchars(activeCall.creditorContactName, 15) }}
                </span>
              </p>
              <p
                v-if="activeCall.clientId == 0 && activeCall.campaignId > 0"
                class="noMargin"
              >
                <span style="font-weight: bold"
                  >Campaign: {{ activeCall.campaignName }}
                </span>
              </p>
              <p v-if="activeCall.clientId > 0" class="noMargin">
                {{ activeCall.statusName }}
              </p>
              <p
                v-if="
                  activeCall.creditorContactId == 0 &&
                  activeCall.callerId &&
                  activeCall.callerId.length > 2 &&
                  !activeCall.isInternal
                "
                class="noMargin"
              >
                {{ outgoingCallerId }}
              </p>
            </div>
          </div>
          <div class="col-md-4">
            <div
              class="button dialnext"
              v-show="state == 1 || state == 3"
              @click="handleNextDial()"
            >
              <span>
                Dial Next
                <i class="fe-arrow-right"></i>
              </span>
            </div>

            <div
              class="button hangup"
              @click="hangUp()"
              v-show="state == 2 || state == 4 || state == 5"
            >
              <span class="icons">
                <i class="fe-phone-off"></i>
              </span>
            </div>

            <div
              class="button playline"
              @click="grabCall()"
              v-show="state == 5"
            >
              <span class="icons">
                <i class="ri-play-line"></i>
              </span>
            </div>
            <div
              class="button"
              @click="transferAnnouncedDepartment()"
              v-show="
                (state == 2 || state == 4 || state == 5) &&
                activeCall &&
                activeCall.isDepartmentAnnounceCall == true &&
                activeCall.isAnnounceCallAnswered == true
              "
            >
              <span class="icons" style="background-color: #3bafda">
                <i class="mdi mdi-transfer-right"></i>
              </span>
            </div>
            <div
              class="button"
              @click="openDtmfNumpad()"
              v-show="activeCall && (state == 2 || state == 4 || state == 5)"
            >
              <span class="icons" style="background-color: #6c757d">
                <i class="ri-keyboard-box-line"></i>
              </span>
              <div v-if="dtmfNumpadOpen">
                <Popover
                  style="width: 350px; left: 0px"
                  class="more-actons"
                  :height="260"
                >
                  <template v-slot:header>
                    <div class="container">
                      Send Key Code
                      <span
                        class="float-right"
                        v-on:click.prevent.stop="closeDtmfNumpad()"
                      >
                        <i
                          style="font-size: 20px"
                          class="fas fa-window-close"
                        ></i>
                      </span>
                    </div>
                  </template>
                  <template v-slot:body>
                    <div class="contact-list text-left">
                      <div
                        class="numPadRow"
                        v-for="row in dtmfList"
                        :key="row.Id"
                        style="margin: 15px"
                      >
                        <button
                          v-for="item in row.Numbers"
                          :key="'dtmfButtons-' + item.Key"
                          v-on:click.prevent.stop="sendDtmfFromNumpad(item.Key)"
                          class="btn btn-sm btn-info ml-1 mr-1"
                          type="button"
                          style="width: 100px; height: 50px"
                        >
                          <div style="display: flex; flex-direction: column">
                            <a style="font-size: 15px">{{ item.Value }}</a>
                            <a class="">{{ item.Keypad }}</a>
                          </div>
                        </button>
                      </div>
                    </div>
                  </template>
                </Popover>
              </div>
            </div>
            <div class="button userline" v-show="state == 4">
              <span class="icons">
                <i class="fas fa-user-times"></i>
              </span>
            </div>
            <div
              class="button action-button moreline"
              @click="handleMenuClick"
              v-show="
                activeCall && !activeCall.isInternal && state != 1 && state != 3
              "
            >
              <span class="icons">
                <i class="ri-more-line"></i>
              </span>
              <MoreActions
                v-if="menuVisible"
                :endPointName="userPhoneSettings.endPointName"
                :activeCall="activeCall"
                @sendDtmf="sendDtmf"
                @announceCall="announceCall"
                @call3rdParty="call3rdParty"
                @hangUp3rdParty="hangUp3rdParty"
                @departmentWelcomeCall="departmentWelcomeCall"
                @kickAgent="kickAgent"
              />
            </div>
            <div
              v-if="
                (state == 2 || state == 5) &&
                activeCall &&
                activeCall.campaignId > 0 &&
                activeCall.assignedUserId == 0 &&
                !(activeCall.clientId > 0)
              "
              class="button action-button"
            >
              <span class="icons" style="background-color: #3bafda">
                <i @click="handleCampaignLeadPanel" class="fe-users"></i>
              </span>
              <CampaignLeadPanel
                :campaignId="activeCall.campaignId"
                :customerPhone="cleanCallerId(activeCall.callerId)"
                @updateLeadPanelVisible="campaignLeadPanelVisible = $event"
                v-if="campaignLeadPanelVisible"
              >
              </CampaignLeadPanel>
            </div>

            <div
              class="button action-button forwardline"
              @click="handleTransferClick"
              v-if="(state == 2 || state == 5) && activeCall"
              v-show="activeCall && !activeCall.isInternal"
            >
              <span class="icons">
                <i class="fe-phone-forwarded"></i>
              </span>

              <ColdTransfer
                :selectedTransferType="selectedTransferType"
                @transfer="handleTransfer"
                v-if="transferVisible"
              />
              <TransferToAgent
                @transfer="handleTransfer"
                @transferToAgent="transferToAgent"
                :agents="employees"
                :endPointName="userPhoneSettings.endPointName"
                v-if="selectedTransferType === 1 && transferVisible"
              />
              <TransferToNumber
                @transfer="handleTransfer"
                @transferToDid="transferToDid"
                v-if="selectedTransferType === 2 && transferVisible"
              />
              <TransferToAgentVm
                :endPointName="userPhoneSettings.endPointName"
                :agents="employees"
                v-if="selectedTransferType === 3 && transferVisible"
                @transfer="handleTransfer"
                @transferToAgentVm="transferToAgentVm"
              />
              <TransferToDepartment
                v-if="selectedTransferType === 4 && transferVisible"
                :agents="employees"
                @transfer="handleTransfer"
                @transferToIngroup="transferToIngroup"
              />
            </div>
            <div
              class="button action-button recordline"
              @click="handleRecordClick"
              v-show="state == 2"
              v-if="activeCall && activeCall.clientId > 0"
            >
              <span class="icons">
                <i class="ri-record-mail-line"></i>
              </span>

              <RecordList
                v-if="recordVisible"
                @transferToVmDrop="transferToVmDrop"
              />
            </div>
            <div
              class="button pauseline"
              v-show="state == 2 || state == 4"
              @click="holdCall()"
            >
              <span class="icons">
                <i class="ri-pause-line"></i>
              </span>
            </div>

            <div class="button action-button" v-show="state == 1 || state == 3">
              <span class="icons keyboard-icon">
                <i @click="handleNumpadClick" class="ri-keyboard-line"></i>
              </span>
              <Numpad v-if="numpadVisible" @callDid="callDid" />
            </div>
            <div
              class="button action-button callline"
              v-show="(state == 1 || state == 3) && !checkAuth(100030)"
            >
              <span class="icons">
                <i @click="handleCallClick" class="fe-phone"></i>
              </span>
              <InternalCall
                v-if="contactListVisible && !checkAuth(100030)"
                :endPointName="userPhoneSettings.endPointName"
                @callEndpoint="callEndpoint"
              />
            </div>
            <div
              class="button action-button callline"
              v-show="state == 1 || state == 3"
              v-show-if-authorized="'5564'"
            >
              <span class="icons" style="background-color: #3bafda">
                <i @click="handleCreditorCallClick" class="ri-bank-line"></i>
              </span>
              <NegotiatorContactList
                v-if="creditorContactListVisible"
                @callNegotiatorContact="callNegotiatorContact"
                @openCreditorContactForm="openCreditorContactForm"
              />
            </div>
            <div
              v-show="state === 2 || state == 4"
              class="button muteButton"
              :class="blockUnmuteCall ? 'disabledMuteButton' : 'muteButton'"
              @click="muteCall()"
            >
              <span class="icons" v-if="activeCall">
                <i
                  :class="
                    activeCall.isCallMuted || blockUnmuteCall
                      ? 'fe-mic-off'
                      : 'fe-mic'
                  "
                ></i>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="disposition"
        v-if="
          (state === 3 || state === 2 || state == 5) &&
          activeCall &&
          activeCall.clientId > 0
        "
      >
        <div class="col-md-2">
          <select
            v-model="selectedDisposition"
            placeholder="Select Disposition*"
            id="dispodrop"
            class="form-control"
          >
            <option v-for="item in filteredDispos" :key="item.Id" :value="item">
              {{ item.Name }}
            </option>
          </select>
        </div>
        <div class="col-md-8">
          <input
            class="message form-control"
            type="text"
            placeholder="Enter Comment"
            v-model="dispositionComment"
          />
          <button
            type="button"
            class="btn btn-warning waves-effect waves-light btn-sm"
            style="min-width: 100px"
            :disabled="savingDispo"
            @click="handleDispositionSubmit()"
          >
            <i v-if="savingDispo" class="fas fa-spinner mr-1"></i>Save
          </button>
        </div>
        <div class="col-md-2 align-right">
          <div
            class="button ml-2 green-button dispoButtons"
            v-on:click.prevent.stop="handleViewClient()"
          >
            <i class="ri-edit-line"></i>
          </div>
          <div
            style="cursor: pointer"
            class="blue-button action-button ml-2 dispoButtons"
            v-on:click.prevent.stop="handleRemindersClick()"
          >
            <i class="fe-clock"></i>
            <div class="dispoLogPanel" v-if="last5ReminderVisible">
              <Last5Reminders
                :customerId="activeCall.clientId"
              ></Last5Reminders>
            </div>
          </div>
          <div
            style="cursor: pointer; background-color: #8a9197"
            class="green-button action-button ml-2 dispoButtons"
            v-on:click.prevent.stop="handleDispoLogsClick()"
          >
            <i class="ri-sound-module-fill"></i>
            <div class="dispoLogPanel" v-if="last5DispoVisible">
              <Last5Dispositions
                :customerId="activeCall.clientId"
              ></Last5Dispositions>
            </div>
          </div>
          <div
            style="cursor: pointer; background-color: #42cee7"
            class="green-button action-button ml-2 dispoButtons"
            v-on:click.prevent.stop="
              openSms(activeCall.phoneNumber, activeCall.clientId)
            "
          >
            <i class="fas fa-sms"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="backgroundLock" v-if="showCreditorContactForm">
    <div
      style="position: relative; margin: 90px auto"
      class="col-xl-4 col-lg-8 col-md-12 col-sm-12"
    >
      <CreditorContactForm
        @closeCreditorContactForm="closeCreditorContactForm"
        :contactData="creditorContactData"
      >
      </CreditorContactForm>
    </div>
  </div>
</template>
<script>
import ColdTransfer from "./PlivoParts/Popups/ColdTransfer";
import Numpad from "./PlivoParts/Popups/Numpad";
import MoreActions from "./PlivoParts/Popups/MoreActions";
import TransferToAgent from "./PlivoParts/Popups/TransferToAgent";
import TransferToAgentVm from "./PlivoParts/Popups/TransferToAgentVm";
import TransferToDepartment from "./PlivoParts/Popups/TransferToDepartment";
import TransferToNumber from "./PlivoParts/Popups/TransferToNumber";
import RecordList from "./PlivoParts/Popups/RecordList";
import InternalCall from "./PlivoParts/Popups/InternalCall";
import CampaignLeadPanel from "./PlivoParts/Popups/CampaignLeadPanel";
import NegotiatorContactList from "./PlivoParts/Popups/NegotiatorContactList";
import CreditorContactForm from "@/components/CreditorContactForm";
import globalTypes from "../store/types";
import types from "./PlivoParts/types";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import api from "@/services/client/customerApi";
import to from "@/helpers/to";
import Last5Dispositions from "../components/Last5Dispositions";
import Last5Reminders from "../components/Last5Reminders";
import deepCopy from "../helpers/deepCopy";
import Peer from "peerjs";
import _orderby from "lodash";
import plivoMixin from "@/mixins/plivoMixin";
import Popover from "./Popover";
import editClientTypes from "@/views/agent/EditClient/types";
import creditorTypes from "@/views/agent/EditClient/ClientCreditors/types";

const callState = {
  NoCall: 1,
  Connected: 2,
  HungUp: 3,
  Conference: 4,
  Hold: 5,
};
const queuedCallModel = {
  call: null,
  callQueueTime: null,
  callRemovingStep: 2,
};
const incomingCallModel = {
  callUUID: "",
  src: "",
  callerId: "",
  ringTime: 90,
  isCallActive: false,
  callTimer: null,
  callStartTime: 0,
  clientId: 0,
  assignedUserId: 0,
  isInbound: false,
  otherLeg: "",
  customerName: "",
  statusId: 0,
  isInternal: false,
  phoneNumber: "",
  statusName: "",
  campaignId: 0,
  campaignName: "",
  isAdCall: 0,
  isConferenceCall: false,
  conferenceName: "",
  is3rdPartyCall: false,
  relatedPhoneNumber: "",
  phone3rdParty: "",
  dispositionRequired: true,
  creditorContactId: 0,
  creditorContactName: "",
  endpointCount: 0,
  isAutoCall: false,
  isAnnounceCallClient: false,
  isCallMuted: false,

  isDepartmentAnnounceCall: false,
  isAnnounceCallAnswered: false,
  announceCallEmployee: false,
  announceCallId: 0,
  announceCallCallUUID: "",
  announceCallPhoneNumber: "",
  announceDepartmentName: "",
  announceDepartmentConferenceName: "",
  announceCallLogStep: 30,
  callMetricsNetworkIssues: [],
  callMetricsAudioIssues: [],
  muteCallOnMerge: false,
  officeId: 0,
};
const selectedDispoInit = {
  Id: 0,
  CompanyId: 0,
  Name: "Select Disposition",
  Priority: 0,
  FromStatusList: [],
  ToStatusId: 0,
  IsCommentRequired: false,
  IsReminderRequired: false,
};
export default {
  name: "PlivoInterface",
  components: {
    TransferToAgent,
    TransferToAgentVm,
    ColdTransfer,
    Numpad,
    MoreActions,
    TransferToDepartment,
    TransferToNumber,
    RecordList,
    Last5Dispositions,
    Last5Reminders,
    InternalCall,
    CampaignLeadPanel,
    NegotiatorContactList,
    CreditorContactForm,
    Popover,
  },
  mixins: [formatMixin, utilitiesMixin, plivoMixin],
  emits: [
    "updatePlivoLoggedIn",
    "updateShowPhone",
    "updateShowPhonePanel",
    "callStatusChanged",
    // "setPlivoError",
  ],
  data() {
    return {
      dtmfList: [
        {
          Id: "dtmfRow1",
          Numbers: [
            { Key: "1", Value: "1", Keypad: "" },
            { Key: "2", Value: "2", Keypad: "ABC" },
            { Key: "3", Value: "3", Keypad: "DEF" },
          ],
        },
        {
          Id: "dtmfRow2",
          Numbers: [
            { Key: "4", Value: "4", Keypad: "GHI" },
            { Key: "5", Value: "5", Keypad: "JKL" },
            { Key: "6", Value: "6", Keypad: "MNO" },
          ],
        },
        {
          Id: "dtmfRow3",
          Numbers: [
            { Key: "7", Value: "7", Keypad: "PQRS" },
            { Key: "8", Value: "8", Keypad: "TUV" },
            { Key: "9", Value: "9", Keypad: "WXYZ" },
          ],
        },
        {
          Id: "dtmfRow4",
          Numbers: [
            { Key: "*", Value: "*", Keypad: "" },
            { Key: "0", Value: "0", Keypad: "" },
            { Key: "#", Value: "#", Keypad: "" },
          ],
        },
      ],
      dtmfNumpadOpen: false,
      plivoLoading: false,
      isPlivoConnected: 0,
      disconnectCode: "",
      userFullName: "",
      callTerminatedStarted: false,
      plivosdk: null,
      answerAutoCallTime: null,
      rejectedCallList: [],
      state: 1,
      contactListVisible: false,
      numpadVisible: false,
      recordVisible: false,
      transferVisible: false,

      dispositionVisible: false,
      last5DispoVisible: false,
      last5ReminderVisible: false,
      campaignLeadPanelVisible: true,
      menuVisible: false,
      userLoggedIn: false,

      contactListFilter: "",
      callNumber: "",
      calingInfo: {
        Phone: "",
        CallerId: "",
      },
      //marqueePosition: 0,
      //marquee: null,
      callerIdList: [],
      userPhoneSettings: {
        endPointId: null,
        endPointAuthId: null,
        endPointName: null,
        endPointPassword: null,
      },
      callingInfo: {
        PhoneNumber: "",
        CallerId: "",
        IsJoinConference: false,
      },
      incomingCallList: [],
      activeCall: null,
      activeCallTimer: null,
      selectedTransferType: 0,
      selectedDisposition: selectedDispoInit,
      dispositionComment: "",
      userId: 0,
      userModuleFunctions: [],
      savingDispo: false,
      initDispo: selectedDispoInit,
      isCallRejected: false,

      adCallTimer: {},
      autoAcceptCalls: [],
      pendingIncomingCalls: [],
      callQueue: [],
      isCallQueuePanelVisible: true,
      queueButtonEnabled: true,
      mergedCallItem: null,
      liveConferenceTimer: null,
      pendingCallTimer: null,
      csrTaskCheckTimer: null,
      lastCallTimeForCsrTask: null,
      autoCallIncomingCallList: [],
      outgoingCallerId: "",
      outgoingClientPhone: "",
      callTimeout: 0,
      isPlivoFirstLogin: true,
      creditorContactListVisible: false,
      showCreditorContactForm: false,
      creditorContactData: null,
      customerDispositionList: [],
      playAutoCallSound: false,
      openInfoPanel: false,
      infoPanelClass: "errorInfo",
      infoPanelText: "",
      tierSettings: [],
      ignoreDisconnectError: false,
      blockUnmuteCall: false,
      companyId: 0,
      customerDocusignContractValidationError: "",
      coCustomerDocusignContractValidationError: "",
      creditors: [],
      totalIncome: 0,
      userCanSpeakSpanish: false,
    };
  },
  computed: mapState({
    sipUri: (state) => state.phoneManagement.sipUri,
    employees: (state) => state.globals.allEmployees,
    customerStatuses: (state) => state.globals.customerStatuses,
    dispoList: (state) => state.globals.dispositionTypes,
    isUserOnline: (state) => state.globals.isUserOnline,
    userLastCallTime: (state) => state.globals.userLastCallTime,
    customerStatusId() {
      return this.activeCall && this.activeCall.statusId
        ? this.activeCall.statusId
        : 0;
    },
    filteredDispos() {
      let rtn;

      if (this.activeCall) {
        rtn = _orderby.orderBy(
          this.dispoList.filter(
            (item) =>
              item.FromStatusList.map((x) => x.Id).includes(
                this.customerStatusId
              ) || item.FromStatusList == ""
          ),
          "Priority"
        );
      } else rtn = this.dispoList;
      if (rtn.length > 0) rtn.unshift(selectedDispoInit);

      return rtn;
    },
    pauseReasonTypes: (state) => state.globals.pauseReasonTypes,
    campaignTypes: (state) => state.globals.campaignTypes,
    campaignList: (state) => state.globals.campaignList,
    negotiatorContacts: (state) => state.phoneManagement.negotiatorContacts,
    assignedCsrTaskId: (state) => state.globals.assignedCsrTaskId,
    docusignValidationSettings: (state) =>
      state.clientManagement.docusignValidationSettings,
    customerTagTypes: (state) => state.globals.customerTagTypes,
  }),
  async mounted() {
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    let userRoles = "";
    if (userInfo != null) {
      this.userId = userInfo.userId;
      this.tierSettings = userInfo.tierSettings;
      this.companyId = userInfo.companyId;
      this.userCanSpeakSpanish = userInfo.canSpeakSpanish;
    }
    await Promise.all([
      this.$store.dispatch(globalTypes.GET_STATUS_NAMES),
      this.$store.dispatch(globalTypes.GET_ROLES),
      this.$store.dispatch(globalTypes.GET_LAST_CALL_TIME),
      this.$store.dispatch(globalTypes.GET_DISPOSITIONTYPES),
      this.$store.dispatch(globalTypes.GET_CAMPAIGNLIST, {
        Roles: userInfo.roles,
      }),
      this.$store.dispatch(globalTypes.GET_ALL_ACTIVE_EMPLOYEES),
      this.$store.dispatch(globalTypes.GET_CUSTOMER_TAG_TYPE),
    ]);

    this.answerAutoCallTime = new Date();

    this.userModuleFunctions = userInfo.moduleFunctions;
    this.userFullName = userInfo.firstName + "-" + userInfo.lastName;
    //this.handleMarquee();
    let userPhoneSettings = JSON.parse(
      sessionStorage.getItem("userPhoneSettings")
    );
    if (userPhoneSettings && userPhoneSettings.endPointId) {
      this.userPhoneSettings = userPhoneSettings;

      let result = await this.$store.dispatch(types.GET_DID_LIST, 3);
      if (result && result.Data) {
        this.callerIdList = result.Data.map((x) => this.cleanUpDid(x.Did));
      }
    }

    await this.$store.dispatch(types.GET_ENDPOINT_LIST);
    await this.$store.dispatch(globalTypes.GET_CAMPAIGNTYPES);
    this.$Emitter.on("makeCall", async (data) => {
      if (data.phone && data.phone.length >= 10) {
        this.callDid(
          data.phone,
          data.customerId,
          false,
          "",
          data.skipDispoCheck,
          false
        );
      }
    });

    this.$Emitter.on("callCreditor", (data) => {
      if (data.PhoneNumber && data.PhoneNumber.length >= 10) {
        this.callCreditor(data.PhoneNumber, data.Id, data.Name);
      }
    });

    this.$Emitter.on("listenCall", (data) => {
      if (data) {
        this.ListenCall(data);
      }
    });
    this.$Emitter.on("stopListenCall", (data) => {
      if (data) {
        this.kickConference(data.ConferenceName);
      }
    });
    this.$Emitter.on("hangUpListen", (data) => {
      if (this.plivosdk != null && this.userLoggedIn) {
        //this.plivosdk.client.hangup();
        this.kickConference(data.ConferenceName);
      }
    });
    this.$Emitter.on("refreshPhonePanelAfterCustomerSave", (data) => {
      if (data != null) {
        this.refreshActiveCall(data);
      }
    });

    this.$Emitter.on("SignAsConference", (data) => {
      if (data != null) {
        this.signCallAsConference(data);
      }
    });

    this.$Emitter.on("unmuteCall", async (data) => {
      if (data != null && this.activeCall != null) {
        if (
          this.activeCall.clientId &&
          this.activeCall.clientId == data.CustomerId
        ) {
          // console.log("Customer match");
          if (data.Mute == false) {
            // console.log("agent unmuted");
            this.blockUnmuteCall = false;
            this.plivosdk.client.unmute();
            this.activeCall.isCallMuted = false;
          }
          if (data.Hangup3rdParty == true) {
            // console.log("is 3rd Party Call: " + this.activeCall.is3rdPartyCall);
            // console.log("related phone number: " + this.activeCall.relatedPhoneNumber);
            // console.log("3rd party phone number:" + this.activeCall.phone3rdParty);

            let phone3rdParty = this.cleanFormatPhone(
              this.activeCall.phone3rdParty
            );
            await this.hangUp3rdParty(phone3rdParty);
            this.activeCall.is3rdPartyCall = false;
          }
        }
      }
    });

    this.$Emitter.on("autoAcceptCallPush", (data) => {
      if (data != null) {
        this.pushAutoAcceptCall(data.Index, data.From);
      }
    });

    this.liveConferenceTimer = window.setInterval(async () => {
      this.checkLiveConferences();
      this.clearRejectedCallList(61);
      this.checkDepartmentAnnounceCallAnswered();
      this.checkAnnounceCallInfo();
    }, 10000);

    this.pendingCallTimer = window.setInterval(async () => {
      this.checkPendingIncomingCalls();
    }, 3000);

    let err1, result1;
    let canRequestTask = false;
    [err1, result1] = await this.$store.dispatch(
      types.CHECK_CAN_REQUEST_TASK,
      this.userId
    );

    if (result1 && result1.Data) {
      canRequestTask = result1.Data;
    }

    if (canRequestTask) {
      this.csrTaskCheckTimer = window.setInterval(async () => {
        if (this.lastCallTimeForCsrTask == null) {
          this.lastCallTimeForCsrTask = new Date();
        }

        let timeDiffLastCallEnd = this.getTimeDifference(
          new Date(),
          this.lastCallTimeForCsrTask
        );

        if (
          this.isUserOnline &&
          (this.state == callState.NoCall || this.state == callState.HungUp) &&
          timeDiffLastCallEnd > 45
        ) {
          let err, result;
          [err, result] = await this.$store.dispatch(types.REQUEST_CSR_TASK, {
            UserId: this.userId,
          });

          if (result) {
            if (result.Data) {
              if (this.assignedCsrTaskId != result.Data.Id) {
                this.$router.push("csr-tasks");
              }
            }
          }
        }
      }, 10000);
    }

    // setTimeout(() => {
    //   if (!this.userLoggedIn) {
    //     this.$emit("setPlivoError", false);
    //   }
    // }, 5000);
  },
  watch: {
    "activeCall.isCallActive": "notifyCallStatusChange",
  },
  unmounted() {
    this.$Emitter.off("listenCall");
    this.$Emitter.off("stopListenCall");
    this.$Emitter.off("makeCall");
    this.$Emitter.off("hangUpListen");
    this.$Emitter.off("refreshPhonePanelAfterCustomerSave");
    this.$Emitter.off("SignAsConference");
    this.$Emitter.off("unmuteCall");
  },

  methods: {
    cleanUpDuplicatedIncomingCall(call) {
      this.incomingCallList = this.incomingCallList.filter(
        (x) => this.unformatPhone(x.src) != this.unformatPhone(call.src)
      );
    },
    showIncomingCalls() {
      let rtn = [];
      if (this.checkAuth(100040)) {
        rtn = this.incomingCallList;
      } else {
        //if there is an active call, block all other incoming calls.
        if (
          !(this.activeCall != null && this.activeCall.isCallActive) &&
          (this.callQueue == null || this.callQueue.length == 0)
        ) {
          rtn = [this.incomingCallList[0]];
        }
        // if(this.activeCall != null && this.activeCall.isCallActive)
        // {
        //   // if there is an active call, show only first direct did call
        //   let directCalls = this.incomingCallList.filter(
        //     (x) => x.endpointCount == 0
        //   );

        //   if(directCalls.length > 0)
        //   {
        //     rtn = [directCalls[0]];
        //   }
        // }
        // else
        // {
        //   // if there is no active call, show first incoming call (campaign or direct did)
        //   rtn = [this.incomingCallList[0]];
        // }
      }
      return rtn;
    },

    notifyCallStatusChange() {
      this.$emit("callStatusChanged", this.activeCall.isCallActive);
    },

    muteCall() {
      if (this.activeCall) {
        if (this.blockUnmuteCall) {
          if (this.plivosdk && this.activeCall.isCallMuted == false) {
            this.plivosdk.client.mute();
            this.activeCall.isCallMuted = true;
          }
        } else {
          if (this.plivosdk) {
            if (this.activeCall.isCallMuted) {
              this.plivosdk.client.unmute();
              this.activeCall.isCallMuted = false;
            } else {
              this.plivosdk.client.mute();
              this.activeCall.isCallMuted = true;
            }
          }
        }
      } else {
        this.$swal("Warning", "There is no active call", "warning");
      }
    },
    resetInfoPanel() {
      this.openInfoPanel = false;
      this.infoPanelClass = "";
      this.infoPanelText = "";
    },
    async sendDtmfFromNumpad(keyCode) {
      if (keyCode) {
        if (this.plivosdk != null && this.userLoggedIn) {
          let arr = keyCode.split("");
          if (arr.length > 0) {
            this.plivosdk.client.sendDtmf(arr[0]);
          }
        }
      }
    },
    openDtmfNumpad() {
      this.dtmfNumpadOpen = true;
    },
    closeDtmfNumpad() {
      this.dtmfNumpadOpen = false;
    },
    isUserOnCall() {
      return !(this.state == 1 || this.state == 3);
    },
    hasIncomingCall() {
      return (
        this.incomingCallList.length > 0 ||
        this.autoCallIncomingCallList.length > 0
      );
    },
    async checkAnnounceCallInfo() {
      try {
        if (
          this.activeCall &&
          this.activeCall.isCallActive &&
          this.activeCall.announceCallEmployee == true &&
          this.activeCall.announceCallPhoneNumber != ""
        ) {
          if (this.activeCall.announceCallLogStep <= 0) {
            this.activeCall.announceCallPhoneNumber = "";
          }

          let isTransferedAnnounceCall = false;
          let announceCallConferenceName = "";
          let announceCallId = 0;
          let err, result;
          [err, result] = await this.$store.dispatch(
            types.GET_ANNOUNCE_CALL_LOG,
            {
              PhoneNumber: this.activeCall.announceCallPhoneNumber,
            }
          );
          if (result && result.Data) {
            isTransferedAnnounceCall = result.Data.IsTransfered;
            announceCallConferenceName = result.Data.ConferenceName;
            announceCallId = result.Data.Id;
          }

          let phoneNumber = this.cleanFormatPhone(
            this.activeCall.announceCallPhoneNumber
          );
          let nonClient = false;
          if (isTransferedAnnounceCall == true) {
            this.activeCall.announceCallLogStep = 0;
            [err, result] = await this.$store.dispatch(
              types.GET_DETAILS_BY_PHONE,
              { PhoneNumber: phoneNumber }
            );

            if (result) {
              if (result.Data.Id > 0) {
                let activeCall = Object.assign({}, incomingCallModel);
                activeCall.callUUID = this.activeCall.callUUID;
                activeCall.callerId = "1" + phoneNumber;
                activeCall.otherLeg = "1" + phoneNumber;
                activeCall.isCallActive = true;
                activeCall.clientId = result.Data.Id;
                activeCall.statusId = result.Data.StatusId;
                activeCall.officeId = result.Data.OfficeId;
                activeCall.isInternal = false;
                activeCall.phoneNumber = this.formatPhone(phoneNumber);
                activeCall.statusName = result.Data.StatusName;
                activeCall.isConferenceCall = true;
                activeCall.conferenceName = announceCallConferenceName;
                activeCall.isInbound = true;
                activeCall.customerName = result.Data.Name;
                this.outgoingClientPhone = this.tryFormatPhone(phoneNumber);
                activeCall.announceCallPhoneNumber = "";
                activeCall.announceCallEmployee = false;
                this.activeCall = Object.assign({}, activeCall);
              } else {
                nonClient = true;
              }
            } else {
              nonClient = true;
            }

            if (nonClient) {
              let activeCall = Object.assign({}, incomingCallModel);
              activeCall.callUUID = this.activeCall.callUUID;
              activeCall.callerId = "1" + phoneNumber;
              activeCall.otherLeg = "1" + phoneNumber;
              activeCall.isCallActive = true;
              activeCall.isInternal = false;
              activeCall.phoneNumber = this.formatPhone(phoneNumber);
              activeCall.isConferenceCall = true;
              activeCall.conferenceName = announceCallConferenceName;
              activeCall.isInbound = true;
              this.outgoingClientPhone = this.tryFormatPhone(phoneNumber);
              activeCall.announceCallPhoneNumber = "";
              activeCall.announceCallEmployee = false;
              this.activeCall = Object.assign({}, activeCall);
            }

            let err1, result1;
            [err1, result1] = await this.$store.dispatch(
              types.UPDATE_ANNOUNCE_CALL_LOG,
              {
                Id: announceCallId,
                IsActive: false,
                IsTransfered: isTransferedAnnounceCall,
              }
            );

            if (result1) {
              this.activeCall.announceCallLogStep = 0;
              this.activeCall.announceCallPhoneNumber = "";
            }
          }

          this.activeCall.announceCallLogStep -= 1;
        }
      } catch (err) {
        this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
          Message:
            "checkAnnounceCallInfo Error: ActiveCall: " + this.activeCall
              ? JSON.stringify(this.activeCall)
              : "active call is null",
        });
      }
    },
    async checkDepartmentAnnounceCallAnswered() {
      try {
        if (
          this.activeCall &&
          this.activeCall.isDepartmentAnnounceCall == true &&
          this.activeCall.isAnnounceCallAnswered == false
        ) {
          let err, result;
          [err, result] = await this.$store.dispatch(
            types.GET_ANNOUNCE_CALL_LOG,
            {
              PhoneNumber: this.activeCall.announceCallPhoneNumber,
            }
          );
          if (result && result.Data) {
            this.activeCall.announceCallCallUUID = result.Data.CallUUID;

            this.activeCall.announceCallId = result.Data.Id;

            if (this.activeCall.announceCallCallUUID != "") {
              this.activeCall.isAnnounceCallAnswered = true;
            }
          }
        }
      } catch (err) {
        this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
          Message:
            "checkDepartmentAnnounceCallAnswered Error: ActiveCall: " +
            this.activeCall
              ? JSON.stringify(this.activeCall)
              : "active call is null",
        });
      }
    },
    async departmentWelcomeCall(data) {
      try {
        this.plivoLoading = true;
        let departmentName = "";
        let departmentDid = "";
        if (data) {
          departmentDid = data.DepartmentDid;
          departmentName = data.Department;
        }

        if (departmentDid != "") {
          let phone = this.activeCall.isInbound
            ? this.cleanFormatPhone(this.activeCall.otherLeg)
            : this.cleanFormatPhone(this.activeCall.phoneNumber);

          if (phone.startsWith("1")) {
            phone = this.cleanUpDid(phone);
          }

          if (departmentDid.startsWith("1")) {
            departmentDid = this.cleanUpDid(departmentDid);
          }

          let confName =
            "clientxxx" + this.userPhoneSettings.endPointId + "xxx1" + phone;
          if (this.activeCall && this.activeCall.conferenceName != "") {
            confName = this.activeCall.conferenceName;
          }

          let extraHeaders = {
            "X-PH-IsDidCall": "true",
            "X-PH-From": this.userPhoneSettings.endPointName,
            "X-PH-CallerId": "1" + phone,
            "X-PH-EndpointAuthId": this.userPhoneSettings.endPointAuthId,
            "X-PH-AnnouncePhoneNumber": phone,
            "X-PH-AnnounceConf": confName,
            "X-PH-IsAnnounceRoleNew": "true",
            "X-PH-CallStartTime": new Date().getTime(),
          };

          if (this.activeCall.isConferenceCall) {
            let isKicked = await this.kickConference(
              this.activeCall.conferenceName
            );
            if (isKicked) {
              this.addToCallQueue();
              await this.sleep(500);
              this.callingInfo.PhoneNumber = departmentDid;
              this.callingInfo.CallerId = this.userPhoneSettings.endPointName;
              this.callingInfo.IsJoinConference = false;
              this.plivosdk.client.call("1" + departmentDid, extraHeaders);
              let tmp = Object.assign({}, incomingCallModel);
              tmp.ringTime = 0;
              let activeCall = tmp;
              activeCall.announceDepartmentName =
                departmentName + " Department";
              activeCall.callerId = activeCall.announceDepartmentName;
              activeCall.isDepartmentAnnounceCall = true;
              activeCall.announceCallPhoneNumber = phone;
              activeCall.announceDepartmentConferenceName =
                this.activeCall.conferenceName;

              this.activeCall = Object.assign({}, activeCall);
              this.outgoingCallerId = this.callerIdFormatter(this.activeCall);
              this.outgoingClientPhone = this.tryFormatPhone(
                this.activeCall.phoneNumber
              );
            }
          } else {
            let isTransfered = await this.transferToConference(this.activeCall);

            if (isTransfered) {
              this.addToCallQueue();
              await this.sleep(500);

              this.plivosdk.client.call("1" + departmentDid, extraHeaders);
              let tmp = Object.assign({}, incomingCallModel);
              tmp.ringTime = 0;
              let activeCall = tmp;
              activeCall.announceDepartmentName =
                departmentName + " Department";
              activeCall.callerId = activeCall.announceDepartmentName;
              activeCall.isDepartmentAnnounceCall = true;
              activeCall.announceCallPhoneNumber = phone;
              activeCall.announceDepartmentConferenceName =
                this.activeCall.conferenceName;

              this.activeCall = Object.assign({}, activeCall);
              this.outgoingCallerId = this.callerIdFormatter(this.activeCall);
              this.outgoingClientPhone = this.tryFormatPhone(
                this.activeCall.phoneNumber
              );
            }
          }
        }
        this.plivoLoading = false;
      } catch (err) {
        this.plivoLoading = false;
        this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
          Message:
            "departmentWelcomeCall Error: ActiveCall: " + this.activeCall
              ? JSON.stringify(this.activeCall)
              : "active call is null",
        });
      }
    },
    async departmentAnnounceCall(data) {
      // let departmentName = "";
      // let roleId = 0;
      // if (data) {
      //   roleId = data.RoleId;
      //   departmentName = data.Department;
      // }
      // if (roleId > 0) {
      //   let phone = this.activeCall.isInbound
      //     ? this.cleanFormatPhone(this.activeCall.otherLeg)
      //     : this.cleanFormatPhone(this.activeCall.phoneNumber);
      //   if (phone.startsWith("1")) {
      //     phone = this.cleanUpDid(phone);
      //   }
      //   let confName =
      //     "clientxxx" + this.userPhoneSettings.endPointId + "xxx1" + phone;
      //   if (this.activeCall && this.activeCall.conferenceName != "") {
      //     confName = this.activeCall.conferenceName;
      //   }
      //   // let extraHeaders = {
      //   //   "X-PH-IsAnnounceRole": "true",
      //   //   "X-PH-EndpointAuthId": this.userPhoneSettings.endPointAuthId,
      //   //   "X-PH-AnnouncePhoneNumber": phone,
      //   //   "X-PH-AnnounceConf": confName,
      //   //   "X-PH-CallerUser": this.userFullName,
      //   //   "X-PH-CallerEnpoint": this.userPhoneSettings.endPointName,
      //   //   "X-PH-CallStartTime": new Date().getTime(),
      //   // };
      //   let extraHeaders = {
      //     "X-PH-IsDidCall": "true",
      //     "X-PH-From": this.userPhoneSettings.endPointName,
      //     "X-PH-CallerId": "18884319717",
      //     "X-PH-EndpointAuthId": this.userPhoneSettings.endPointAuthId,
      //   };
      //   if (this.activeCall.isConferenceCall) {
      //     let isKicked = await this.kickConference(
      //       this.activeCall.conferenceName
      //     );
      //     if (isKicked) {
      //       this.addToCallQueue();
      //       await this.sleep(500);
      //       this.plivosdk.client.call("18889102632", extraHeaders);
      //       let tmp = Object.assign({}, incomingCallModel);
      //       tmp.ringTime = 0;
      //       let activeCall = tmp;
      //       activeCall.announceDepartmentName = departmentName + " Department";
      //       activeCall.callerId = activeCall.announceDepartmentName;
      //       activeCall.isInternal = true;
      //       activeCall.isDepartmentAnnounceCall = true;
      //       activeCall.announceCallPhoneNumber = phone;
      //       activeCall.announceDepartmentConferenceName =
      //         this.activeCall.conferenceName;
      //       this.activeCall = Object.assign({}, activeCall);
      //       this.outgoingCallerId = this.callerIdFormatter(this.activeCall);
      //       this.outgoingClientPhone = this.tryFormatPhone(
      //         this.activeCall.phoneNumber
      //       );
      //     }
      //   } else {
      //     let isTransfered = await this.transferToConference(this.activeCall);
      //     if (isTransfered) {
      //       this.addToCallQueue();
      //       await this.sleep(500);
      //       this.plivosdk.client.call("18889102632", extraHeaders);
      //       //this.plivosdk.client.call(roleId, extraHeaders);
      //       let tmp = Object.assign({}, incomingCallModel);
      //       tmp.ringTime = 0;
      //       let activeCall = tmp;
      //       activeCall.announceDepartmentName = departmentName + " Department";
      //       activeCall.callerId = activeCall.announceDepartmentName;
      //       activeCall.isInternal = true;
      //       activeCall.isDepartmentAnnounceCall = true;
      //       activeCall.announceCallPhoneNumber = phone;
      //       activeCall.announceDepartmentConferenceName =
      //         this.activeCall.conferenceName;
      //       this.activeCall = Object.assign({}, activeCall);
      //       this.outgoingCallerId = this.callerIdFormatter(this.activeCall);
      //       this.outgoingClientPhone = this.tryFormatPhone(
      //         this.activeCall.phoneNumber
      //       );
      //     }
      //   }
      // }
    },
    playIncomingCallSound() {
      if (
        this.incomingCallList.length == 1 &&
        (this.activeCall == null || this.activeCall.isCallActive == false) &&
        (this.state == callState.NoCall || this.state == callState.HungUp)
      ) {
        let player = document.getElementById("incomingRingPlayer");
        if (typeof player != "undefined" && player != null) {
          player.play();
        }
      }
    },
    async transferAnnouncedDepartment() {
      try {
        this.plivoLoading = true;

        let err, result;
        if (this.activeCall) {
          let phone = this.activeCall.isInbound
            ? this.cleanFormatPhone(this.activeCall.otherLeg)
            : this.cleanFormatPhone(this.activeCall.phoneNumber);

          if (phone.startsWith("1")) {
            phone = this.cleanUpDid(phone);
          }

          let payload = {
            ConferenceName: this.activeCall.announceDepartmentConferenceName,
            CallUuid: this.activeCall.announceCallCallUUID,
            IsListen: "false",
            From: "1" + phone,
            To: "",
            CallerId: "",
          };
          this.activeCall.dispositionRequired = false;
          [err, result] = await this.$store.dispatch(
            types.TRANSFER_TO_CONFERENCE,
            payload
          );
          if (result) {
            if (result.Data) {
              await this.$store.dispatch(types.UPDATE_ANNOUNCE_CALL_LOG, {
                Id: this.activeCall.announceCallId,
                IsTransfered: true,
                IsActive: false,
              });
              let indx = this.callQueue.findIndex(
                (x) =>
                  x.call.conferenceName ==
                  this.activeCall.announceDepartmentConferenceName
              );

              if (indx > -1) {
                this.callQueue[indx].call.isAnnounceCallClient = true;
                this.callQueue = this.callQueue.filter(
                  (x) => x.call.isAnnounceCallClient == false
                );
              }
            } else {
              this.$swal(
                "Warning!",
                "Transfer failed. Please try again in 10 seconds.",
                "warning"
              );
            }
          } else {
            this.$swal(
              "Warning!",
              "Transfer failed. Please try again in 10 seconds.",
              "warning"
            );
          }
        } else {
          this.$swal(
            "Warning!",
            "There is no active call to transfer.",
            "warning"
          );
        }
        this.plivoLoading = false;
      } catch (err) {
        this.plivoLoading = false;
        this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
          Message:
            "transferAnnouncedDepartment Error: ActiveCall: " + this.activeCall
              ? JSON.stringify(this.activeCall)
              : "active call is null",
        });
      }
    },
    cancelIncomingCallRing() {
      try {
        if (!document.getElementById("incomingRingPlayer")) {
          return;
        }

        if (
          (this.state != callState.NoCall && this.state != callState.HungUp) ||
          (this.activeCall && this.activeCall.isCallActive)
        ) {
          if (
            document.getElementById("incomingRingPlayer").hasAttribute("loop")
          ) {
            document
              .getElementById("incomingRingPlayer")
              .removeAttribute("loop");
          }
        } else {
          if (
            !document.getElementById("incomingRingPlayer").hasAttribute("loop")
          ) {
            document.getElementById("incomingRingPlayer").setAttribute("loop");
          }
        }
      } catch (err) {
        this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
          Message:
            "cancelIncomingCallRing Error: ActiveCall: " + this.activeCall
              ? JSON.stringify(this.activeCall)
              : "active call is null",
        });
      }
    },

    pushToDispositionList(customerId) {
      if (
        this.customerDispositionList &&
        this.customerDispositionList.length > 0
      ) {
        let indx = this.customerDispositionList.findIndex(
          (x) => x == customerId
        );
        if (indx < 0) {
          this.customerDispositionList.push(customerId);
        }
      } else {
        this.customerDispositionList.push(customerId);
      }
    },
    removeFromDispositionList(customerId) {
      if (
        this.customerDispositionList &&
        this.customerDispositionList.length > 0
      ) {
        let indx = this.customerDispositionList.findIndex(
          (x) => x == customerId
        );
        if (indx > -1) {
          this.customerDispositionList.splice(indx, 1);
        }
      }
    },
    showIncallCountDown(val) {
      return val;
    },
    callerIdFormatter(activeCall) {
      let rtn = "";
      let cid = this.getCallerId(activeCall);
      if (activeCall && activeCall.clientId > 0) {
        cid = this.formatPhone(activeCall.phoneNumber);
      }
      if (cid.length > 0) {
        rtn = "CID: " + cid;
      }

      return rtn;
    },
    async refreshActiveCall(data) {
      try {
        if (this.activeCall) {
          let callerId = this.activeCall.isInbound
            ? this.activeCall.callerId
            : this.activeCall.phoneNumber;

          if (data && this.activeCall && callerId) {
            let matchPhones = data.PhoneList.filter(
              (x) =>
                this.unformatPhone(x.PhoneNumber) ==
                this.unformatPhone(callerId)
            );
            if (
              (data.Id == this.activeCall.clientId && matchPhones.length > 0) ||
              (this.activeCall.clientId == 0 && matchPhones.length > 0)
            ) {
              let did = matchPhones[0].PhoneNumber;
              let err, result;
              [err, result] = await this.$store.dispatch(
                types.GET_DETAILS_BY_PHONE,
                {
                  PhoneNumber: did,
                  CustomerId: data.Id,
                }
              );

              if (result) {
                if (result.Data && result.Data.Id > 0) {
                  this.activeCall.assignedUserId = data.assignedUserId;
                  this.activeCall.clientId = result.Data.Id;
                  this.activeCall.statusId = result.Data.StatusId;
                  this.activeCall.customerName = result.Data.Name;
                  this.activeCall.officeId = result.Data.OfficeId;
                  this.activeCall.phoneNumber = this.formatPhone(did);
                  this.activeCall.statusName = result.Data.StatusName;

                  this.onCallClientLists(result.Data.StatusId, result.Data.Id);
                  this.outgoingClientPhone = this.activeCall.phoneNumber;
                } else {
                  this.activeCall.callerId = this.formatPhone(did);
                  this.activeCall.phoneNumber = this.formatPhone(did);
                }
              }
            }
          }
        }
      } catch (err) {
        this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
          Message:
            "refreshActiveCall Error: ActiveCall: " + this.activeCall
              ? JSON.stringify(this.activeCall)
              : "active call is null",
        });
      }
    },
    cleanCallerId(phone) {
      return phone.replace("+1", "");
    },
    async call3rdParty(data) {
      if (this.activeCall && data) {
        if (data.ValidateDocusign == true) {
          let customer = null;
          await this.$store.dispatch(
            editClientTypes.GET_DOCUSIGN_CONTRACT_VALIDATION,
            {
              CompanyId: this.companyId,
            }
          );

          if (this.activeCall.clientId > 0) {
            let err, result;
            [err, result] = await this.$store.dispatch(
              editClientTypes.GET_CLIENT,
              this.activeCall.clientId
            );
            if (result && result.Data) {
              customer = deepCopy(result.Data);
            }
          }

          let IsDocusignValid = true;
          if (
            customer != null &&
            this.docusignValidationSettings &&
            this.docusignValidationSettings.length > 0
          ) {
            let totalCoCustomerIncome = 0;
            if (customer.CoCustomerProfiles.length) {
              let includedCoCustomers = customer.CoCustomerProfiles.filter(
                (x) => !x.IsExcluded
              );

              for (let item of includedCoCustomers) {
                totalCoCustomerIncome += parseFloat(item.Income);
              }
            }

            let additionalIncome = 0;
            if (
              customer &&
              customer.CustomerTags.length > 0 &&
              this.customerTagTypes &&
              this.customerTagTypes.length > 0
            ) {
              let incomeTagIds = this.customerTagTypes
                .filter((x) => x.TagDataType == "Income")
                .map((x) => x.Id);
              let customerIncomes = customer.CustomerTags.filter((x) =>
                incomeTagIds.includes(x.TagTypeId)
              );
              let totalTagIncome = 0;
              for (let item of customerIncomes) {
                totalTagIncome += item.TagValue ? parseFloat(item.TagValue) : 0;
              }
              additionalIncome = totalTagIncome;
            }

            this.totalIncome =
              parseFloat(customer.CustomerProfile.Income) +
              parseFloat(totalCoCustomerIncome) +
              parseFloat(additionalIncome);
            this.customerDocusignContractValidationError = "";
            this.coCustomerDocusignContractValidationError = "";
            if (this.docusignValidationSettings.length > 0) {
              for (let item of this.docusignValidationSettings) {
                await this.validateFieldsForDocusign(
                  customer,
                  item.Id,
                  item.DisplayName,
                  item.ObjectType,
                  item.MinCharLimit,
                  item.MatchKey,
                  item.MatchValue
                );
              }
              IsDocusignValid =
                this.customerDocusignContractValidationError == "" &&
                this.coCustomerDocusignContractValidationError == "";
            }
          }

          if (!IsDocusignValid) {
            let validationError = "";

            if (this.customerDocusignContractValidationError != "") {
              validationError +=
                "<b>Customer Required Fields:</b> <br>" +
                this.customerDocusignContractValidationError
                  .trimEnd()
                  .slice(0, -1);
            }

            if (this.coCustomerDocusignContractValidationError != "") {
              validationError +=
                "<br/><b>Co Customers Required Fields:</b> <br/> " +
                this.coCustomerDocusignContractValidationError
                  .trimEnd()
                  .slice(0, -1);
            }

            if (validationError != "") {
              this.$swal(
                "Docusign Contract Validation Error!",
                validationError,
                " required to send envelope!",
                "warning"
              );
              return;
            }
          }
        }
        if (this.incomingCallList && this.incomingCallList.length > 0) {
          for (let item of this.incomingCallList) {
            this.rejectCall(item.callUUID);
          }
        }
        await this.sleep(200);
        this.callDid(
          data.Did,
          "",
          true,
          this.activeCall.otherLeg,
          false,
          data.MuteOnMerge
        );
      }
    },
    async validateFieldsForDocusign(
      customer,
      fieldName,
      message,
      objectType,
      minCharLimit,
      matchKey = "",
      matchValue = ""
    ) {
      let arr = fieldName.split(".");

      let obj = customer;

      if (objectType == "Customer") {
        for (let item of arr) {
          obj = obj[item];
        }

        if (message == "Income") {
          if (this.totalIncome == 0)
            this.customerDocusignContractValidationError += message + ", ";
        } else if (message == "SSN") {
          let ssn = await this.getDecryptedValue(obj);
          if (ssn == null || ssn == "") {
            this.customerDocusignContractValidationError += message + ", ";
          }
        } else if (message == "Bank Account Number") {
          let acctNumber = await this.getDecryptedValue(obj);
          if (acctNumber == null || acctNumber == "") {
            this.customerDocusignContractValidationError += message + ", ";
          }
        } else if (message == "Draft Amount") {
          let minDraftAmountCheckValue = parseFloat(minCharLimit);

          if (
            customer &&
            customer.CustomerGlobalInformation &&
            customer.CustomerGlobalInformation.MinDraftAmount > 0
          ) {
            minDraftAmountCheckValue = parseFloat(
              customer.CustomerGlobalInformation.MinDraftAmount
            );
          }

          if (minDraftAmountCheckValue > obj) {
            this.customerDocusignContractValidationError +=
              message +
              " must be greater than " +
              this.formatMoney(minDraftAmountCheckValue);
          }
        } else if (obj == null || obj == "") {
          this.customerDocusignContractValidationError += message + ", ";
        } else if (
          minCharLimit > 0 &&
          obj != null &&
          obj.length < minCharLimit
        ) {
          this.customerDocusignContractValidationError +=
            message + ": minimum " + minCharLimit + " characters required, ";
        }
      }
      if (objectType == "Creditor") {
        if (customer.CreditorCount == 0) {
          this.customerDocusignContractValidationError += message + ", ";
        } else {
          let err, result;
          this.creditors = [];
          [err, result] = await this.$store.dispatch(
            creditorTypes.GET_CREDITORS,
            {
              CustomerId: customer.Id,
            }
          );

          if (result && result.Data) {
            this.creditors = deepCopy(result.Data);
          }

          let disabledCreditorStatusList = this.getDisabledCreditorStatus();
          let activeCreditors = this.creditors.filter(
            (x) => !disabledCreditorStatusList.includes(x.CreditorStatusId)
          );
          if (matchKey != "" && matchValue != "") {
            let matches = activeCreditors.filter(
              (x) =>
                this.cleanSpecialChars(x[matchKey]) ==
                this.cleanSpecialChars(matchValue)
            );
            let valid = true;
            for (let item of matches) {
              valid *= item[fieldName].length == minCharLimit;
            }

            if (!valid) {
              this.customerDocusignContractValidationError += message + ", ";
            }
          } else {
            if (fieldName == "OriginalBalance") {
              let valid = true;
              for (let item of activeCreditors) {
                valid *=
                  parseFloat(item[fieldName]) >= parseFloat(minCharLimit);
              }

              if (!valid) {
                this.customerDocusignContractValidationError +=
                  "<br/>Minimum debt amount is " +
                  this.formatMoney(minCharLimit) +
                  " for all creditors.<br/>";
              }
            }
          }
        }
      } else if (
        objectType == "CoCustomer" &&
        customer.CoCustomerProfiles.length > 0
      ) {
        let validForAllCoCustomers = true;
        for (let item of customer.CoCustomerProfiles.filter(
          (x) => !x.IsExcluded
        )) {
          obj = item[fieldName];
          if (message == "SSN") {
            let ssn = await this.getDecryptedValue(obj);
            if (ssn == null || ssn == "") {
              this.coCustomerDocusignContractValidationError += message + ", ";
            }
          } else if (obj == null || obj == "") {
            validForAllCoCustomers *= false;
          }
        }
        if (validForAllCoCustomers == false) {
          this.coCustomerDocusignContractValidationError += message + ", ";
        }
      }
    },
    async kickAgent() {
      if (
        this.activeCall &&
        this.activeCall.is3rdPartyCall &&
        this.activeCall.conferenceName != ""
      ) {
        await this.kickConference(this.activeCall.conferenceName);
      } else {
        this.$swal("Error", "An error occured!", "error");
      }
    },

    async hangUp3rdParty(did) {
      try {
        let phone = did;
        if (!phone.startsWith(1)) phone = "1" + phone;
        if (this.activeCall.isConferenceCall) {
          let err, result;
          let payload = {
            ConferenceName: this.activeCall.conferenceName,
            PhoneNumbers: phone,
          };
          [err, result] = await this.$store.dispatch(
            types.KICK_3RD_PARTY,
            payload
          );
          if (result) {
            this.activeCall.phone3rdParty = "";
            this.activeCall.is3rdPartyCall = false;
          }
        } else {
          this.hangUp();
          this.activeCall.phone3rdParty = "";
          this.activeCall.is3rdPartyCall = false;
        }
      } catch (err) {
        this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
          Message:
            "hangUp3rdParty Error: ActiveCall: " + this.activeCall
              ? JSON.stringify(this.activeCall)
              : "active call is null",
        });
      }
    },
    async merge3rdPartyCall(item) {
      try {
        let err, result;
        if (this.activeCall.isConferenceCall) {
          let payload = {
            CallUuid: this.activeCall.callUUID,
            ConferenceName: this.activeCall.conferenceName,
            NewConferenceName: item.call.conferenceName,
            IsListen: "false",
            CallerId: this.activeCall.callerId,
          };

          [err, result] = await this.$store.dispatch(
            types.TRANSFER_TO_NEW_CONFERENCE,
            payload
          );

          if (result) {
            item.call.is3rdPartyCall = true;
            item.call.phone3rdParty = this.activeCall.phoneNumber;
            this.mergedCallItem = item;
            if (this.activeCall.muteCallOnMerge) {
              this.plivosdk.client.mute();
              this.activeCall.isCallMuted = true;
              //this.blockUnmuteCall = true;
            }
          } else {
            this.$swal("Error!", "An error occured!", "error");
          }
        } else {
          let muteOnMerge = this.activeCall.muteCallOnMerge;
          let payload = {
            CallUuid: this.activeCall.callUUID,
            ConferenceName: item.call.conferenceName,
            From: this.userPhoneSettings.endPointId,
            To: "",
            CallerId: this.activeCall.callerId,
          };

          [err, result] = await this.$store.dispatch(
            types.TRANSFER_TO_CONFERENCE,
            payload
          );

          if (result) {
            this.updateActiveCallInfo(item.call);
            this.activeCall.isConferenceCall = true;
            this.activeCall.is3rdPartyCall = true;
            this.activeCall.conferenceName = result.Data;
            this.activeCall.relatedPhoneNumber = this.activeCall.phoneNumber;
            this.activeCall.ringTime += Math.ceil(
              this.getTimeDifference(new Date(), item.callQueueTime)
            );

            this.removeFromCallQueue(item.call.conferenceName);
            await this.sleep(500);
            await this.joinConference(this.activeCall.conferenceName);
            await this.sleep(500);
            if (muteOnMerge == true) {
              this.plivosdk.client.mute();
              this.activeCall.isCallMuted = true;
              //this.blockUnmuteCall = true;
            }
          } else {
            this.$swal("Error!", "An error occured!", "error");
          }
        }
      } catch (err) {
        this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
          Message:
            "merge3rdPartyCall Error: ActiveCall: " + this.activeCall
              ? JSON.stringify(this.activeCall)
              : "active call is null",
        });
      }
    },
    async checkIfConferenceExist(confName) {
      let rtn = false;

      let err, result;
      let payload = {
        EndpointId: this.userPhoneSettings.endPointId,
        ForceRefresh: true,
      };

      [err, result] = await this.$store.dispatch(
        types.GET_LIVE_CONFERENCE_NAMES,
        payload
      );

      if (result) {
        if (result.Data && result.Data.includes(confName)) rtn = true;
      }

      if (rtn == false) {
        if (this.callQueue && this.callQueue.length > 0) {
          let indx = this.callQueue.findIndex(
            (x) => x.call.conferenceName == confName
          );
          if (indx > -1) {
            this.callQueue.splice(indx, 1);
          }
        }
      }

      return rtn;
    },
    updateActiveCallInfo(call) {
      if (this.activeCall) {
        this.activeCall.clientId = call.clientId;
        this.activeCall.customerName = call.customerName;
        this.activeCall.statusId = call.statusId;
        this.activeCall.phoneNumber = call.phoneNumber;
        this.activeCall.statusName = call.statusName;
        this.activeCall.campaignId = call.campaignId;
        this.activeCall.isAdcall = call.isAdCall;
      }
    },
    async checkLiveConferences() {
      try {
        this.callQueue = this.callQueue.filter(
          (x) => x.call.isAnnounceCallClient == false
        );

        if (this.callQueue.length > 0) {
          let err, result;

          let payload = { EndpointId: this.userPhoneSettings.endPointId };

          [err, result] = await this.$store.dispatch(
            types.GET_LIVE_CONFERENCE_NAMES,
            payload
          );

          if (result) {
            if (this.callQueue.length > 0) {
              this.isCallQueuePanelVisible = true;
            }

            for (let i = 0; i < this.callQueue.length; i++) {
              if (
                !result.Data.includes(this.callQueue[i].call.conferenceName)
              ) {
                this.callQueue[i].callRemovingStep -= 1;
              }

              if (this.callQueue[i].callRemovingStep <= 0) {
                let call = this.callQueue[i].call;
                if (call.clientId > 0) {
                  if (this.checkAuth(1865) && call.ringTime > 10) {
                    await this.$store.dispatch(types.INSERT_MANDATORY_DISPO, {
                      CustomerId: call.clientId,
                    });
                  }
                }

                this.callQueue.splice(i, 1);
              }
            }
          }
        }
      } catch (err) {
        this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
          Message:
            "checkLiveConferences CallQueue: " + this.callQueue
              ? JSON.stringify(this.callQueue)
              : "call queue is empty",
        });
      }
    },
    async queueCall(call, newCall) {
      try {
        if (call.isConferenceCall) {
          let isKicked = await this.kickConference(call.conferenceName);
          if (isKicked) {
            this.addToCallQueue();

            this.plivosdk.client.answer(newCall.callUUID, "letring");
            this.activeCall = deepCopy(newCall);

            this.outgoingCallerId = this.callerIdFormatter(this.activeCall);
            this.outgoingClientPhone = this.tryFormatPhone(
              this.activeCall.phoneNumber
            );
          }
        } else {
          let isTransfered = await this.transferToConference(this.activeCall);

          if (isTransfered) {
            this.addToCallQueue();

            this.plivosdk.client.answer(newCall.callUUID, "letring");
            this.activeCall = deepCopy(newCall);
            this.outgoingCallerId = this.callerIdFormatter(this.activeCall);
            this.outgoingClientPhone = this.tryFormatPhone(
              this.activeCall.phoneNumber
            );
          }
        }
      } catch (err) {
        this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
          Message:
            "queueCall Error: ActiveCall: " + this.activeCall
              ? JSON.stringify(this.activeCall)
              : "active call is empty",
        });
      }
    },
    async answerFromQueue(item) {
      try {
        this.queueButtonEnabled = false;
        let conferenceExists = await this.checkIfConferenceExist(
          item.call.conferenceName
        );

        if (conferenceExists == false) return;

        if (this.activeCall && this.activeCall.isCallActive) {
          if (this.activeCall.isInternal == true) {
            this.$swal(
              "Warning!",
              "You cannot swap calls during an internal call.",
              "warning"
            );
          } else {
            if (this.state == callState.HOLD) {
              await this.grabCall();
            }
            await this.swapCalls(item);
          }
        } else {
          await this.sleep(500);
          await this.joinConference(item.call.conferenceName);
          await this.sleep(200);
          this.activeCall = deepCopy(item.call);
          this.outgoingCallerId = this.callerIdFormatter(this.activeCall);

          this.outgoingClientPhone = this.tryFormatPhone(
            this.activeCall.phoneNumber
          );
          this.activeCall.ringTime += Math.ceil(
            this.getTimeDifference(new Date(), item.callQueueTime)
          );
        }

        this.queueButtonEnabled = true;
      } catch (err) {
        this.queueButtonEnabled = true;
        this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
          Message:
            "answerFromQueue Error: ActiveCall: " + this.activeCall
              ? JSON.stringify(this.activeCall)
              : "active call is empty",
        });
      }
    },
    async swapCalls(item) {
      this.callTerminatedStarted = false;
      this.isCallQueuePanelVisible = false;
      this.plivoLoading = true;
      try {
        if (this.activeCall.isConferenceCall) {
          let isKicked = await this.kickConference(
            this.activeCall.conferenceName
          );
          if (isKicked) {
            this.addToCallQueue();

            let i = 0;
            while (this.callTerminatedStarted == false && i < 20) {
              i++;
              await this.sleep(200);
            }
            this.callTerminatedStarted = false;

            if (this.state == 3) {
              await this.joinConference(item.call.conferenceName);

              await this.sleep(200);
              this.activeCall = deepCopy(item.call);
              this.outgoingCallerId = this.callerIdFormatter(this.activeCall);
              this.outgoingClientPhone = this.tryFormatPhone(
                this.activeCall.phoneNumber
              );
              this.activeCall.ringTime += Math.ceil(
                this.getTimeDifference(new Date(), item.callQueueTime)
              );
            }
          }
        } else {
          let isTransfered = await this.transferToConference(this.activeCall);

          if (isTransfered) {
            this.addToCallQueue();

            let i = 0;
            while (this.callTerminatedStarted == false && i < 20) {
              i++;
              await this.sleep(200);
            }
            this.callTerminatedStarted = false;

            if (this.state == 3) {
              await this.joinConference(item.call.conferenceName);

              await this.sleep(200);
              this.activeCall = deepCopy(item.call);
              this.outgoingCallerId = this.callerIdFormatter(this.activeCall);
              this.outgoingClientPhone = this.tryFormatPhone(
                this.activeCall.phoneNumber
              );
              this.activeCall.ringTime += Math.ceil(
                this.getTimeDifference(new Date(), item.callQueueTime)
              );
            }
          }
        }
      } catch {
        this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
          Message:
            "swapCalls Error: ActiveCall: " + this.activeCall
              ? JSON.stringify(this.activeCall)
              : "active call is null",
        });
      }
      this.plivoLoading = false;
    },
    async transferToConference(call) {
      let rtn = true;
      this.plivoLoading = true;
      try {
        let phone = this.unformatPhone(call.phoneNumber);
        if (!phone.startsWith("1")) phone = "1" + phone;

        let callerId = this.unformatPhone(call.callerId);

        let validCallerId = /^\d+$/.test(parseInt(call.callerId));
        if (!validCallerId || callerId.length != 10) {
          callerId = phone;
        }

        if (!callerId.startsWith("1")) callerId = "1" + callerId;

        phone = call.isInbound ? callerId : phone;

        let err, result;
        let payload = {
          ConferenceName:
            "clientxxx" + this.userPhoneSettings.endPointId + "xxx" + phone,
          CallUuid: call.callUUID,
          From: this.userPhoneSettings.endPointName,
          To: phone,
          CallerId: callerId,
        };
        this.activeCall.dispositionRequired = false;
        [err, result] = await this.$store.dispatch(
          types.TRANSFER_TO_CONFERENCE,
          payload
        );
        if (result) {
          this.activeCall.conferenceName = result.Data;
          this.activeCall.isConferenceCall = true;

          rtn = true;
        }
      } catch {
        this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
          Message:
            "TransferToConference Error. UserEndpointId: " +
            this.userPhoneSettings.endPointId +
            "#Call: " +
            JSON.stringify(this.activeCall),
        });
      }
      this.plivoLoading = false;

      return rtn;
    },
    async joinConference(conferenceName) {
      try {
        let extraHeaders = {
          "X-PH-IsConferenceCall": "true",
          "X-PH-IsListener": "false",
          "X-PH-EndpointAuthId": this.userPhoneSettings.endPointAuthId,
        };
        this.callingInfo.PhoneNumber = conferenceName;
        this.callingInfo.IsJoinConference = true;
        await this.plivosdk.client.call(conferenceName, extraHeaders);
      } catch (err) {
        this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
          Message: "joinConference Error: #ConferenceName: " + conferenceName,
        });
      }
    },
    addToCallQueue() {
      try {
        let isExists = this.callQueue.includes(
          (x) => x.call.callUUID == this.activeCall.callUUID
        );
        if (!isExists) {
          let queudCallItem = Object.assign({}, queuedCallModel);
          queudCallItem.callQueueTime = new Date();
          queudCallItem.call = deepCopy(this.activeCall);
          this.callQueue.push(queudCallItem);
        }
      } catch (err) {
        this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
          Message:
            "addToCallQueue Error: " +
            JSON.stringify(err) +
            "CallQueue: " +
            this.callQueue
              ? JSON.stringify(this.callQueue)
              : "null",
        });
      }
    },
    removeFromCallQueue(conferenceName) {
      if (this.callQueue && this.callQueue.length > 0) {
        let indx = this.callQueue.findIndex(
          (x) => x.call.conferenceName == conferenceName
        );

        if (indx > -1) {
          this.callQueue.splice(indx, 1);
        }
      }
    },
    signCallAsConference(confName) {
      if (this.activeCall) {
        this.activeCall.isConferenceCall = true;
        this.activeCall.conferenceName = confName;
      }
    },
    async kickConference(conferenceName) {
      let rtn = false;
      try {
        if (conferenceName != "") {
          let err, result;
          let payload = {
            ConferenceName: conferenceName,
            PhoneNumber: this.userPhoneSettings.endPointName,
          };
          if (this.activeCall) {
            this.activeCall.dispositionRequired = false;
          }

          [err, result] = await this.$store.dispatch(
            types.KICK_CONFERENCE,
            payload
          );
          if (result && result.Message && result.Message.length > 0) {
            rtn = true;
          } else {
            //console.log(err);
          }
        }
      } catch {
        this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
          Message:
            "kickConference Error. UserEndpointId: " +
            this.userPhoneSettings.endPointId +
            "#Conference Name: " +
            conferenceName,
        });
      }

      return rtn;
    },
    async ListenCall(data) {
      if (this.activeCall && this.activeCall.isCallActive) {
        this.$swal(
          "Warning!",
          "You’re on an active call; listening is disabled!",
          "warning"
        );
      } else {
        if (data.CreateConference) {
          let err, result;
          let payload = {
            CallUuid: data.CallUuid,
            ConferenceName: data.ConferenceName,
            IsListen: "true",
            From: "",
            To: "",
            CallerId: "",
            EndpointId: data.EndpointId,
            IsForcedTransfer: "false",
          };

          try {
            [err, result] = await this.$store.dispatch(
              types.TRANSFER_BOTH_SIDE,
              payload
            );
            if (result) {
              let confName = data.ConferenceName;

              let extraHeaders = {
                "X-PH-IsConferenceCall": "true",
                "X-PH-IsListener": "true",
                "X-PH-EndpointAuthId": this.userPhoneSettings.endPointAuthId,
              };

              this.callingInfo.PhoneNumber = confName;
              this.callingInfo.IsJoinConference = true;
              this.plivosdk.client.call(confName, extraHeaders);
            } else {
              let errMsg = "Call is on hold. Listening is not available!";
              this.$swal("Warning!", errMsg, "warning");
            }
          } catch {
            this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
              Message:
                "ListenCall Error. TransferBothSide Error: " +
                this.userPhoneSettings.endPointId +
                "#Payload: " +
                JSON.stringify(payload),
            });
          }
        } else {
          try {
            let extraHeaders = {
              "X-PH-IsConferenceCall": "true",
              "X-PH-IsListener": "true",
              "X-PH-EndpointAuthId": this.userPhoneSettings.endPointAuthId,
            };
            this.callingInfo.PhoneNumber = data.ConferenceName;
            this.callingInfo.IsJoinConference = true;
            this.plivosdk.client.call(data.ConferenceName, extraHeaders);
          } catch {
            this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
              Message:
                "ListenCall Error. Not Conference Call#UserId: " +
                this.userPhoneSettings.endPointId +
                "#Name: " +
                data.ConferenceName,
            });
          }
        }
      }
    },

    async checkPendingIncomingCalls() {
      try {
        if (this.pendingIncomingCalls.length > 0) {
          if (this.isUserOnline) {
            if (this.userPhoneSettings.isAutoAcceptCall) {
              if (
                (this.state == callState.NoCall ||
                  this.state == callState.HungUp) &&
                this.getTimeDifference(new Date(), this.answerAutoCallTime) > 10
              ) {
                this.pendingIncomingCalls.sort(function (a, b) {
                  return a.callStartTime - b.callStartTime;
                });
                let call = this.pendingIncomingCalls[0];
                this.pendingIncomingCalls = this.pendingIncomingCalls.filter(
                  (x) => x.callUUID != call.callUUID
                );
                this.autoCallIncomingCallList.push(call);

                this.answerAutoCall(call);
              }
            } else if (this.incomingCallList.length == 0) {
              this.pendingIncomingCalls.sort(function (a, b) {
                return a.callStartTime - b.callStartTime;
              });
              let call = this.pendingIncomingCalls[0];
              this.pendingIncomingCalls = this.pendingIncomingCalls.filter(
                (x) => x.callUUID != call.callUUID
              );
              this.cleanUpDuplicatedIncomingCall(call);
              this.incomingCallList.push(call);
              await this.sleep(100);
              this.cancelIncomingCallRing();
              this.playIncomingCallSound();
              // document.getElementById("incomingRingPlayer").play();

              this.$emit("updateShowPhonePanel", true);
              let incomingCallIndex = this.incomingCallList.findIndex(
                (x) => x.callUUID == call.callUUID
              );
              if (incomingCallIndex > -1) {
                this.incomingCallList[incomingCallIndex].callTimer =
                  setInterval(() => {
                    if (call) {
                      if (call.ringTime > 0) {
                        if (incomingCallIndex >= 0) {
                          this.handleIncomingCallTimer(incomingCallIndex);
                        } else {
                          clearInterval(
                            this.incomingCallList[incomingCallIndex].callTimer
                          );
                          this.incomingCallList[incomingCallIndex].callTimer =
                            null;
                        }
                      } else {
                        clearInterval(
                          this.incomingCallList[incomingCallIndex].callTimer
                        );
                        this.incomingCallList[incomingCallIndex].callTimer =
                          null;
                      }
                    }
                  }, 1000);
              }
            }
          }
        }
      } catch (err) {
        this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
          Message:
            "checkPendingIncomingCalls Error: ActiveCall: " +
            this.pendingIncomingCalls
              ? JSON.stringify(this.pendingIncomingCalls)
              : "pending incoming call list is empty",
        });
      }
    },
    pushPendingIncomingCall(call) {
      if (this.pendingIncomingCalls && this.pendingIncomingCalls.length > 0) {
        let indx = this.pendingIncomingCalls.findIndex(
          (x) => x.callUUID == call.callUUID
        );
        if (indx < 0) this.pendingIncomingCalls.push(call);
      } else {
        this.pendingIncomingCalls.push(call);
      }
    },
    removePendingIncomingCall(call) {
      if (this.pendingIncomingCalls && this.pendingIncomingCalls.length > 0) {
        let indx = this.pendingIncomingCalls.findIndex(
          (x) => x.callUUID == call.callUUID
        );
        if (indx > -1) {
          this.pendingIncomingCalls.splice(indx, 1);
        }
      }
    },
    removeAutoIncomingCallList(callUUID) {
      if (
        this.autoCallIncomingCallList &&
        this.autoCallIncomingCallList.length > 0
      ) {
        this.autoCallIncomingCallList = this.autoCallIncomingCallList.filter(
          (x) => x.callUUID != callUUID
        );
        this.autoCallIncomingCallList.push();
      }
    },
    pushAutoAcceptCall(index, callerId) {
      let callItem = { EndpointIndex: index, CallerId: callerId };

      try {
        for (let i = 0; i < this.autoAcceptCalls.length; i++) {
          if (this.autoAcceptCalls[i].CallerId == callItem.CallerId) {
            this.autoAcceptCalls.splice(i, 1);
          }
        }
      } catch (err) {
        this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
          Message:
            "pushAutoAcceptCall Error: #AutoAcceptCallList: " +
            this.autoAcceptCalls
              ? JSON.stringify(this.autoAcceptCalls)
              : "auto accept call list is empty",
        });
      }

      this.autoAcceptCalls.push(callItem);
    },
    clearAutoAcceptCallList(callerId) {
      try {
        if (this.autoAcceptCalls.length > 0) {
          let indexOfAutoCallList = this.autoAcceptCalls.findIndex(
            (x) =>
              this.unformatPhone(x.CallerId) == this.unformatPhone(callerId)
          );

          if (indexOfAutoCallList > -1) {
            this.autoAcceptCalls.splice(indexOfAutoCallList, 1);
            this.autoAcceptCalls.push();
          }
        }
      } catch (err) {
        this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
          Message:
            "clearAutoAcceptCallList Error: #AutoAcceptCallList: " +
            this.autoAcceptCalls
              ? JSON.stringify(this.autoAcceptCalls)
              : "accept call list is empty",
        });
      }
    },
    clearAdCall(callInfo) {
      this.stopRing();
      if (this.adCallTimer[callInfo.callUUID]) {
        try {
          clearTimeout(this.adCallTimer[callInfo.callUUID]);

          delete this.adCallTimer[callInfo.callUUID];
        } catch (err) {
          this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
            Message:
              "clearAdCall Error: #AddCallTimer: " + this.adCallTimer
                ? JSON.stringify(this.adCallTimer)
                : "timer is null",
          });
        }
      }
    },
    stopIncomingCallPlayer() {
      try {
        if (
          typeof document.getElementById("incomingRingPlayer") != "undefined" &&
          document.getElementById("incomingRingPlayer")
        ) {
          document.getElementById("incomingRingPlayer").pause();
          document.getElementById("incomingRingPlayer").currentTime = 0;
        }
      } catch (err) {
        this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
          Message: "stopIncomingCallPlayer",
        });
      }
    },
    stopRing() {
      if (this.incomingCallList.length <= 0) {
        try {
          if (
            typeof document.getElementById("incomingRingPlayer") !=
              "undefined" &&
            document.getElementById("incomingRingPlayer")
          ) {
            document.getElementById("incomingRingPlayer").pause();
            document.getElementById("incomingRingPlayer").currentTime = 0;
          }
        } catch (err) {
          this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
            Message: "stopRing Error",
          });
        }
      }
    },
    openSms(phone, customerId) {
      try {
        if (phone.length >= 10) {
          let unformattedPhone = phone
            .replace("+", "")
            .replace("(", "")
            .replace(")", "")
            .replace("-", "")
            .replace(" ", "");
          this.$Emitter.emit("openSms", {
            Phones: unformattedPhone,
            CustomerId: customerId,
          });
        }
      } catch (err) {
        this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
          Message: "openSms Error",
        });
      }
    },
    async handleNextDial() {
      try {
        let result = await this.$store.dispatch(types.NEXT_CALL_LIST, {
          View: 2,
        });
        let noCustomer = false;
        if (
          result &&
          result.Data &&
          result.Data.Customers &&
          result.Data.Customers.length > 0
        ) {
          let availableList = result.Data.Customers.filter(
            (x) => x.Phone.length > 0
          );
          if (availableList.length > 0) {
            this.callDid(
              availableList[0].Phone,
              availableList[0].Id,
              false,
              "",
              false,
              false
            );
          } else {
            noCustomer = true;
          }
        } else {
          noCustomer = true;
        }

        if (noCustomer) {
          this.$swal("Warning!", "There is no customer to call ", "warning");
        }
      } catch (err) {
        this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
          Message: "handleNextDial Error: ",
        });
      }
    },
    isCallAvailableforNewLead() {
      let rtn = true;
      try {
        if (this.incomingCallList.length > 0) {
          rtn = false;
        } else if (this.activeCall && this.activeCall.isCallActive) {
          if (this.checkAuth(1841)) {
            //Can Take Leads when on a call shorter than 60 seconds.
            let callDuration = this.activeCall.ringTime;

            if (
              typeof callDuration != "undefined" &&
              callDuration != null &&
              callDuration < 60
            ) {
              rtn = true;
            } else {
              rtn = false;
            }
          } else {
            rtn = false;
          }
        } else if (
          this.state == callState.NoCall ||
          this.state == callState.HungUp
        ) {
          rtn = true;
        }
      } catch (err) {
        this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
          Message: "isCallAvailableforNewLead Error ",
        });
      }
      return rtn;
    },
    onCallClientLists(statusId, customerId) {
      this.$store.dispatch(types.GET_AVAILABLE_VM_DROPS, {
        CustomerStatusId: statusId,
        CustomerId: customerId,
      });
    },
    getCallerId(activeCall) {
      let cid = "";
      try {
        if (
          activeCall &&
          activeCall.callerId &&
          activeCall.callerId.length > 2
        ) {
          cid = this.formatPhone(activeCall.callerId);
        }
        if (typeof cid == "undefined" || cid == null) {
          cid = "";
        }
      } catch (err) {
        this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
          Message:
            "getCallerId Error: " + JSON.stringify(err) + "#CallerId: " + cid,
        });
      }

      return cid;
    },
    getCustomerNameArea(activeCall) {
      let rtn = "";
      try {
        if (activeCall.isInternal) {
          rtn = activeCall.callerId;
        } else {
          if (activeCall.clientId > 0) {
            rtn =
              activeCall.customerName.length > 0
                ? activeCall.customerName
                : this.formatPhone(activeCall.phoneNumber);
          } else {
            rtn = this.formatPhone(activeCall.phoneNumber);
          }
        }
      } catch (err) {
        this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
          Message:
            "getCustomerNameArea Error: " +
            JSON.stringify(err) +
            "ActiveCall: " +
            activeCall
              ? JSON.stringify(activeCall)
              : "null",
        });
      }

      return rtn;
    },
    formatCallDetails(activeCall) {
      let rtn = "";
      let cid = "";
      try {
        if (activeCall.callerId && activeCall.callerId.length > 2) {
          cid = " | CID: " + activeCall.callerId;
        }
        if (activeCall.clientId > 0) {
          rtn = activeCall.phoneNumber + cid;
          " | " + activeCall.statusName;
        } else {
          rtn = this.formatPhone(activeCall.phoneNumber);
        }
      } catch (err) {
        this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
          Message:
            "formatCallDetails Error: " +
            JSON.stringify(err) +
            "ActiveCall: " +
            activeCall
              ? JSON.stringify(activeCall)
              : "null",
        });
      }
      return rtn;
    },

    async handleDispositionSubmit() {
      try {
        if (this.selectedDisposition && this.selectedDisposition.Id > 0) {
          if (
            this.selectedDisposition.IsCommentRequired &&
            this.dispositionComment.trim().length < 5
          ) {
            this.$swal(
              "Warning",
              "Minimum 5 characters required in the comment.",
              "warning"
            );
          } else {
            this.savingDispo = true;
            let err, result;
            let payload = {
              CustomerId: this.activeCall.clientId,
              UserId: this.userId,
              Name: this.selectedDisposition.Name,
              CustomerDispositionId: this.selectedDisposition.Id,
              CommentText: this.dispositionComment,
            };

            [err, result] = await this.$store.dispatch(
              types.INSERT_CUSTOMER_DISPO_LOG,
              payload
            );

            if (result) {
              this.savingDispo = false;
              let newStatusId = result.Data.StatusId;
              this.dispositionComment = "";
              this.$swal(
                "Success!",
                "Disposition added successfully.",
                "success"
              );

              this.$Emitter.emit("updateCustomerDispoLogs", {
                CustomerId: this.activeCall.clientId,
              });

              if (newStatusId) {
                if (newStatusId > 0) {
                  this.$Emitter.emit("updateStatus", {
                    customerId: this.activeCall.clientId,
                    statusId: newStatusId,
                  });

                  let indx = this.customerStatuses.findIndex(
                    (x) => x.Id == newStatusId
                  );
                  if (indx > -1) {
                    this.activeCall.statusId = newStatusId;
                    this.activeCall.statusName =
                      this.customerStatuses[indx].Name;
                  }

                  this.activeCall.statusId = newStatusId;
                  this.activeCall.statusName =
                    indx > -1 ? this.customerStatuses[indx].Name : "";
                }
              }

              if (this.selectedDisposition.IsReminderRequired) {
                this.$Emitter.emit("openReminder", {
                  CustomerId: this.activeCall.clientId,
                  IsAutoClose: true,
                  CustomerPrimaryPhone: this.unformatPhone(
                    this.activeCall.phoneNumber
                  ),
                });
              }
            } else {
              this.savingDispo = false;
            }
            this.selectedDisposition = selectedDispoInit;
          }
        } else {
          this.$swal(
            "Warning!",
            "Please select a disposition type!",
            "warning"
          );
        }
      } catch (err) {
        this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
          Message:
            "handleDispositionSubmit Error: " +
            JSON.stringify(err) +
            "Selected Disposition: " +
            this.selectedDisposition
              ? JSON.stringify(this.selectedDisposition)
              : "null",
        });
      }
    },
    handleSelectedDisposition(dispItem) {
      this.selectedDisposition = dispItem;
      this.dispositionVisible = false;
    },
    async handleViewClient() {
      if (this.activeCall && this.activeCall.clientId > 0) {
        if (!(this.activeCall.assignedUserId > 0)) {
          let err, result;
          try {
            [err, result] = await this.$store.dispatch(
              types.ASSIGN_CUSTOMER_FROM_PHONE_PANEL,
              { CustomerId: this.activeCall.clientId }
            );
            await this.sleep(500);
            this.$Emitter.emit("viewCustomer", {
              customerId: this.activeCall.clientId,
              customerName: this.activeCall.customerName,
            });
          } catch (error) {
            this.$Emitter.emit("viewCustomer", {
              customerId: this.activeCall.clientId,
              customerName: this.activeCall.customerName,
            });
          }
        } else {
          this.$Emitter.emit("viewCustomer", {
            customerId: this.activeCall.clientId,
            customerName: this.activeCall.customerName,
          });
        }
      }
    },
    outSideCustomerClick(clientId, clientName) {
      this.$Emitter.emit("viewCustomer", {
        customerId: clientId,
        customerName: clientName,
      });
    },
    async transferToIngroup(endPointList) {
      try {
        if (this.activeCall) {
          if (this.state == 5) {
            await this.grabCall();
          }
          let payload = {
            CallUuid: this.activeCall.callUUID,
            EndpointList: endPointList,
            VmGreetingUserId: 0,
            OtherLeg: this.activeCall.otherLeg,
          };
          if (this.activeCall.conferenceName) {
            let from = this.activeCall.callerId;
            if (!from.startsWith("+")) {
              from = "+" + from;
            }
            payload = {
              CallUuid: this.activeCall.callUUID,
              EndpointList: endPointList,
              VmGreetingUserId: 0,
              OtherLeg: this.activeCall.otherLeg,
              From: from,
              ConferenceName: this.activeCall.conferenceName,
            };
          }

          if (this.state == 2) {
            await this.$store.dispatch(types.TRANSFER_TO_INGROUP, payload);
          } else {
            this.$swal(
              "Warning!",
              "Call is not active. Couldn't be transferred!",
              "warning"
            );
          }
        }
      } catch (err) {
        this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
          Message:
            "transferToIngroup Error: " +
            JSON.stringify(err) +
            "ActiveCall: " +
            this.activeCall
              ? JSON.stringify(this.activeCall)
              : "null",
        });
      }
    },
    async transferToAgentVm(agentObj) {
      try {
        if (this.activeCall) {
          if (this.state == 5) {
            await this.grabCall();
          }

          let payload = {
            CallUuid: this.activeCall.callUUID,
            ToEndpoint: agentObj.EndpointName,
            VmGreetingUserId: agentObj.Id,
          };
          if (this.activeCall.isConferenceCall) {
            payload = {
              CallUuid: this.activeCall.callUUID,
              ToEndpoint: agentObj.EndpointName,
              VmGreetingUserId: agentObj.Id,
              ConferenceName: this.activeCall.conferenceName,
            };
          }
          if (this.state == 2) {
            await this.$store.dispatch(types.TRANSFER_TO_AGENT_VM, payload);
          } else {
            this.$swal(
              "Warning!",
              "Call is not active. Couldn't be transferred!",
              "warning"
            );
          }
        }
      } catch (err) {
        this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
          Message:
            "transferToAgentVm Error: " +
            JSON.stringify(err) +
            "ActiveCall: " +
            this.activeCall
              ? JSON.stringify(this.activeCall)
              : "null",
        });
      }
    },
    async transferToVmDrop(item) {
      try {
        if (this.activeCall) {
          if (this.state == 5) {
            await this.grabCall();
          }

          let phone = this.createCallerId(
            this.activeCall.phoneNumber,
            this.activeCall.callerId
          );

          let payload = {
            CallUuid: this.activeCall.callUUID,
            UserId: this.userId,
            VoicemailTemplateId: item.VoicemailTemplateId,
            PhoneNumber: phone,
            CustomerId:
              this.activeCall && this.activeCall.clientId
                ? this.activeCall.clientId
                : 0,
          };
          if (this.activeCall.isConferenceCall) {
            payload = {
              CallUuid: this.activeCall.callUUID,
              UserId: this.userId,
              VoicemailTemplateId: item.VoicemailTemplateId,
              PhoneNumber: phone,
              ConferenceName: this.activeCall.conferenceName,
              CustomerId:
                this.activeCall && this.activeCall.clientId
                  ? this.activeCall.clientId
                  : 0,
            };
          }

          this.activeCall.dispositionRequired = false;
          if (this.state == 2) {
            let res = await this.$store.dispatch(
              types.TRANSFER_TO_VM_DROP,
              payload
            );
            if (res && this.activeCall.clientId > 0) {
              let err, result;
              let payload = {
                CustomerId: this.activeCall.clientId,
                UserId: this.userId,
                Name: "",
                CustomerDispositionId: item.CustomerDispositionId,
                CommentText: "",
              };

              if (
                item.CustomerDispositionId &&
                item.CustomerDispositionId > 0
              ) {
                [err, result] = await this.$store.dispatch(
                  types.INSERT_CUSTOMER_DISPO_LOG,
                  payload
                );
                if (result) {
                  let newStatusId = result.Data.StatusId;

                  if (newStatusId) {
                    if (newStatusId > 0) {
                      this.$Emitter.emit("updateStatus", {
                        customerId: this.activeCall.clientId,
                        statusId: newStatusId,
                      });

                      let indx = this.customerStatuses.findIndex(
                        (x) => x.Id == newStatusId
                      );
                      if (indx > -1) {
                        this.activeCall.statusId = newStatusId;
                        this.activeCall.statusName =
                          this.customerStatuses[indx].StatusName;
                      }
                    }
                  }
                }
              } else {
                await this.$store.dispatch(types.INSERT_MANDATORY_DISPO, {
                  CustomerId: this.activeCall.clientId,
                });
              }
            }
          } else {
            this.$swal(
              "Warning!",
              "Call is not active. Couldn't be transferred!",
              "warning"
            );
          }
        }
      } catch (err) {
        this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
          Message:
            "transferToVmDrop Error: " +
            JSON.stringify(err) +
            "ActiveCall: " +
            this.activeCall
              ? JSON.stringify(this.activeCall)
              : "null",
        });
      }
    },
    async transferToAgent(agentObj) {
      try {
        if (this.activeCall) {
          if (this.state == 5) {
            await this.grabCall();
          }

          let callerId = "";
          callerId = this.activeCall.callerId;

          if (!this.activeCall.isInbound) {
            callerId = this.activeCall.otherLeg;
          }

          if (callerId && typeof callerId != "undefined")
            callerId = callerId
              .replace("+", "")
              .replace("(", "")
              .replace(")", "")
              .replace("-", "")
              .replace(" ", "");

          let payload;
          if (this.activeCall.isConferenceCall) {
            payload = {
              CallUuid: this.activeCall.callUUID,
              CallerId: callerId,
              ToEndpoint: agentObj.endPointName,
              FromEndpoint: this.userPhoneSettings.endPointName,
              VmGreetingUserId: agentObj.userId,
              OtherLeg: this.activeCall.otherLeg,
              ConferenceName: this.activeCall.conferenceName,
            };
          } else {
            payload = {
              CallUuid: this.activeCall.callUUID,
              CallerId: callerId,
              ToEndpoint: agentObj.endPointName,
              FromEndpoint: this.userPhoneSettings.endPointName,
              VmGreetingUserId: agentObj.userId,
              OtherLeg: this.activeCall.otherLeg,
            };
          }

          if (this.state == 2) {
            // Connected

            await this.$store.dispatch(types.TRANSFER_TO_AGENT, payload);
          } else {
            this.$swal(
              "Warning!",
              "Call is not active. Couldn't be transferred!",
              "warning"
            );
          }
        }
      } catch (err) {
        this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
          Message:
            "transferToAgent Error: " +
            JSON.stringify(err) +
            "ActiveCall: " +
            this.activeCall
              ? JSON.stringify(this.activeCall)
              : "null",
        });
      }
    },
    async transferToDid(did) {
      try {
        if (this.activeCall) {
          if (this.state == 5) {
            await this.grabCall();
          }
          let callerId = "";
          callerId = this.activeCall.callerId;

          if (!this.activeCall.isInbound) {
            callerId = this.activeCall.otherLeg;
          }

          if (callerId && typeof callerId != "undefined")
            callerId = callerId
              .replace("+", "")
              .replace("(", "")
              .replace(")", "")
              .replace("-", "")
              .replace(" ", "");
          if (!did.startsWith("1")) {
            did = "1" + did;
          }
          let payload = {
            CallUuid: this.activeCall.callUUID,
            CallerId: callerId,
            Did: did,
            FromEndpoint: this.userPhoneSettings.endPointName,
            OtherLeg: this.activeCall.otherLeg,
          };
          if (this.activeCall.isConferenceCall) {
            payload = {
              CallUuid: this.activeCall.callUUID,
              CallerId: callerId,
              Did: did,
              FromEndpoint: this.userPhoneSettings.endPointName,
              OtherLeg: this.activeCall.otherLeg,
              ConferenceName: this.activeCall.conferenceName,
            };
          }

          if (this.state == 2) {
            await this.$store.dispatch(types.TRANSFER_TO_DID, payload);
          } else {
            this.$swal(
              "Warning!",
              "Call is not active. Couldn't be transferred!",
              "warning"
            );
          }
        }
      } catch (err) {
        this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
          Message:
            "transferToDid Error: " +
            JSON.stringify(err) +
            "ActiveCall: " +
            this.activeCall
              ? JSON.stringify(this.activeCall)
              : "null" + "#Did: " + did,
        });
      }
    },
    async announceCall(agentObj) {
      try {
        if (this.activeCall) {
          if (this.state == 5) {
            await this.grabCall();
          }

          if (this.activeCall.isConferenceCall) {
            let isKicked = await this.kickConference(
              this.activeCall.conferenceName
            );
            if (isKicked) {
              this.addToCallQueue();
              await this.sleep(500);
              this.callEndpoint(agentObj.EndpointName);
            }
          } else {
            let isTransfered = await this.transferToConference(this.activeCall);
            if (isTransfered) {
              this.addToCallQueue();
              await this.sleep(500);
              this.callEndpoint(agentObj.EndpointName);
            }
          }
        }
      } catch (err) {
        this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
          Message:
            "announceCall Error: " +
            JSON.stringify(err) +
            "ActiveCall: " +
            this.activeCall
              ? JSON.stringify(this.activeCall)
              : "null",
        });
      }
    },

    handleActiveCallTimer() {
      if (this.activeCall) {
        this.activeCall.ringTime++;
      }
    },
    handleIncomingCallTimer(indx) {
      try {
        if (
          this.incomingCallList &&
          this.incomingCallList.length > 0 &&
          indx > -1
        ) {
          if (
            typeof this.incomingCallList[indx] != "undefined" &&
            this.incomingCallList[indx] != null
          ) {
            if (this.incomingCallList[indx].ringTime > 0) {
              this.incomingCallList[indx].ringTime--;
            } else {
              clearInterval(this.incomingCallList[indx].callTimer);
              this.incomingCallList[indx].callTimer = null;
            }
          }
        }
      } catch (err) {
        this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
          Message:
            "handleIncomingCallTimer Error: " +
            JSON.stringify(err) +
            "Incoming Call List: " +
            this.incomingCallList
              ? JSON.stringify(this.incomingCallList)
              : "null",
        });
      }
    },
    async hangUp() {
      try {
        if (this.plivosdk != null && this.userLoggedIn) {
          if (!this.activeCall.isConferenceCall) {
            this.plivosdk.client.hangup();
          } else {
            this.state = callState.HungUp;

            let err, result;
            [err, result] = await this.$store.dispatch(
              types.DELETE_CONFERENCE,
              this.activeCall.conferenceName
            );
          }
        }
      } catch (err) {
        this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
          Message:
            "hangUp Error: " +
            JSON.stringify(err) +
            "ActiveCall: " +
            this.activeCall
              ? JSON.stringify(this.activeCall)
              : "null",
        });
      }
    },
    cleanUpDid(did) {
      let rtn = did;
      if (did.startsWith("1") && did.length == 11) {
        rtn = did.slice(1, did.length);
      }
      return rtn;
    },
    handleCampaignLeadPanel() {
      let temp = this.campaignLeadPanelVisible;
      this.resetPopups();
      this.campaignLeadPanelVisible = !temp;
    },
    handleCallClick() {
      let temp = this.contactListVisible;
      this.resetPopups();
      this.contactListVisible = !temp;
    },
    handleCreditorCallClick() {
      let temp = this.creditorContactListVisible;
      this.resetPopups();
      this.creditorContactListVisible = !temp;
    },
    handleNumpadClick() {
      let temp = this.numpadVisible;
      this.resetPopups();
      this.numpadVisible = !temp;
    },
    handleRecordClick() {
      let temp = this.recordVisible;
      this.resetPopups();
      this.recordVisible = !temp;
    },
    handleTransferClick() {
      if (this.selectedTransferType == 0) {
        let temp = this.transferVisible;
        this.transferVisible = !temp;
      }
    },
    handleMenuClick() {
      let temp = this.menuVisible;
      this.resetPopups();
      this.menuVisible = !temp;
    },

    handleDispositionClick() {
      let temp = this.dispositionVisible;
      this.resetPopups();
      this.dispositionVisible = !temp;
    },
    handleDispoLogsClick() {
      if (this.activeCall != null && this.activeCall.clientId > 0) {
        this.$Emitter.emit("openDisposition", {
          CustomerId: this.activeCall.clientId,
          StatusId: this.activeCall.statusId,
        });
      }
    },
    handleRemindersClick() {
      if (this.activeCall != null && this.activeCall.clientId > 0) {
        this.$Emitter.emit("openReminder", {
          CustomerId: this.activeCall.clientId,
          IsAutoClose: false,
          CustomerPrimaryPhone: this.unformatPhone(this.activeCall.phoneNumber),
        });
      }
    },
    resetPopups() {
      this.transferVisible = false;
      this.recordVisible = false;

      this.menuVisible = false;
      this.contactListVisible = false;
      this.numpadVisible = false;
      this.dispositionVisible = false;

      this.last5DispoVisible = false;
      this.last5ReminderVisible = false;
      this.campaignLeadPanelVisible = false;
      this.selectedTransferType = 0;
      this.creditorContactListVisible = false;
    },
    handleTransfer(el) {
      this.selectedTransferType = el;
      this.transferVisible = true;
    },
    loginPeerJs() {
      try {
        let iceServerList = [];
        if (this.userPhoneSettings.iceServers) {
          this.userPhoneSettings.iceServers.StunServers.forEach((x) => {
            iceServerList.push(x);
          });

          this.userPhoneSettings.iceServers.TurnServers.forEach((x) => {
            iceServerList.push(x);
          });
        }

        if (iceServerList.length == 0) {
          iceServerList = [
            { urls: "stun:stun.l.google.com:19302" },
            { urls: "stun:stun1.l.google.com:19302" },
            { urls: "stun:stun2.l.google.com:19302" },
          ];
        }

        let form = this;
        let randomPeerId = new Date().getTime();
        window.MyPeer = new Peer(
          this.userPhoneSettings.endPointId + randomPeerId,
          {
            secure: true,
            port: 443,
            host: "phone-connect-peerjsserver.herokuapp.com",
            config: {
              iceServers: iceServerList,
            },
          }
        );

        window.MyPeer.on("open", function (id) {
          //console.log("My peer ID is: " + id);
          form.updatePeerId(id);
        });

        window.MyPeer.on("connection", (conn) => {
          conn.on("data", (data) => {
            //console.log(data);
          });
          conn.on("open", () => {
            //console.log("connection open");
          });
        });

        window.MyPeer.on("error", function (err) {
          //console.log("Error:" + err);
        });

        window.MyPeer.on("call", function (call) {
          //console.log("answer call");
          //navigator.mediaDevices.getUserMedia({audio: true}).then(mediaStream => {
          let mediaStream = new MediaStream();
          call.answer(mediaStream);
          call.on("stream", function (remoteStream) {
            // Show stream in some video/canvas element.

            const audio = document.getElementById("audioElement");
            audio.onloadstart = function () {
              audio.play();
            };

            audio.srcObject = remoteStream;
          });
        });
      } catch (err) {
        this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
          Message:
            "loginPeerJs Error: " +
            JSON.stringify(err) +
            "UserId : " +
            this.userId,
        });
      }
    },
    async updatePeerId(peerId) {
      try {
        let err, result;
        let payload = { UserId: this.userId, PeerId: peerId };
        [err, result] = await this.$store.dispatch(
          globalTypes.UPDATE_PEER_ID,
          payload
        );
      } catch (err) {
        this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
          Message:
            "updatePeerId Error: " + JSON.stringify(err) + "PeerId: " + peerId,
        });
      }
    },
    async loginPlivo(calledFromTabClose = false) {
      try {
        if (calledFromTabClose == true) {
          this.ignoreDisconnectError = true;
        }

        if (this.isMobileDevice()) {
          return;
        }

        if (this.plivosdk) this.plivosdk.client.logout();

        this.userPhoneSettings = JSON.parse(
          sessionStorage.getItem("userPhoneSettings")
        );

        await this.$store.dispatch(globalTypes.GET_ALL_ACTIVE_EMPLOYEES);

        this.plivosdk = await this.sdkInit();

        if (!this.plivosdk) {
          await this.sleep(3000);
          this.plivosdk = await this.sdkInit();
          if (this.plivosdk) {
            this.plivosdk.client.login(
              this.userPhoneSettings.endPointName,
              this.userPhoneSettings.endPointPassword
            );
          }
        } else {
          this.plivosdk.client.login(
            this.userPhoneSettings.endPointName,
            this.userPhoneSettings.endPointPassword
          );
        }
      } catch (err) {
        this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
          Message:
            "loginPlivo Error: " +
            JSON.stringify(err) +
            "#UserId: " +
            this.userId,
        });
      }
    },

    async sdkInit() {
      return await this.InitializeSDK(
        () => {
          //console.log("crm-->onLogin");
          this.userLoggedIn = true;

          this.$emit("updatePlivoLoggedIn", true);
          // this.$emit("setPlivoError", false);
          this.loginPeerJs();
          navigator.mediaDevices.getUserMedia({ audio: true });
          let reasonTypes = this.pauseReasonTypes.filter((x) => x.Id > 2);
          if (reasonTypes.length > 0) {
            reasonTypes = _orderby.orderBy(reasonTypes, "Id", "asc");
          }
          if (this.isPlivoFirstLogin) {
            this.$store.dispatch(globalTypes.GET_ONLINE_STATUS, {
              IsOnline: false,
              PauseReasonId: reasonTypes.length > 0 ? reasonTypes[0].Id : 1,
            });
          }

          this.isPlivoFirstLogin = false;
        },
        //Plivo Callbacks
        (cause) => {
          //On Login Failed
          try {
            //console.error("crm-->onloginfailed:" + cause);
            this.userLoggedIn = false;
            this.$emit("updateShowPhone", false);
            this.$emit("updatePlivoLoggedIn", false);
            this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
              Message:
                "Plivo:Login Failed UserId: " +
                this.userId +
                "#Cause: " +
                cause,
            });
          } catch {
            this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
              Message:
                "Plivo callback Exception:onLoginFailed UserId: " +
                this.userId +
                "#Cause: " +
                cause,
            });
          }
        },
        () => {
          //On Logout
          //console.log("crm-->onlogout");
        },
        () => {
          //On Calling
          //console.log("crm-->onCalling");
        },
        (cause, callInfo) => {
          //console.log("crm-->onCallFailed");
          //On Call Failed
          try {
            this.clearAdCall(callInfo);
            if (!this.isCallRejected) {
              if (this.activeCall) {
                if (this.activeCall.callUUID == callInfo.callUUID) {
                  this.state = callState.HungUp;
                  this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
                    Message:
                      "Call Failed2 - Cause: " +
                      cause +
                      " - callInfo: " +
                      JSON.stringify(callInfo),
                  });
                } else {
                  this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
                    Message:
                      "Call Failed3 - Cause: " +
                      cause +
                      " - callInfo: " +
                      JSON.stringify(callInfo),
                  });
                }
              } else {
                this.state = callState.HungUp;
                this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
                  Message:
                    "Call Failed1 - Cause: " +
                    cause +
                    " - callInfo: " +
                    JSON.stringify(callInfo),
                });
              }
            }
            this.isCallRejected = false;
          } catch {
            this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
              Message:
                "Plivo callback Exception:onCallFialed UserId: " + this.userId,
            });
          }
        },
        (callInfo) => {
          //console.log("crm-->onCallRemoteRinging");
          //On Call Remote Ringing
        },
        async (callInfo) => {
          console.log("crm-->onCallAnswered");

          //On Call Answered
          //console.log("call info: " + callInfo.callUUID);

          try {
            this.resetInfoPanel();
            this.callMetricsNetworkIssues = [];
            this.callMetricsAudioIssues = [];
            if (
              !callInfo.extraHeaders["X-PH-IsListener"] ||
              callInfo.extraHeaders["X-PH-IsListener"] == "false"
            ) {
              let autoCallIndex = this.autoCallIncomingCallList.findIndex(
                (x) => x.callUUID == callInfo.callUUID
              );
              let triggerLogId = 0;
              if (
                callInfo.extraHeaders["X-Ph-Triggerid"] != null &&
                !isNaN(parseInt(callInfo.extraHeaders["X-Ph-Triggerid"]))
              ) {
                triggerLogId = parseInt(
                  callInfo.extraHeaders["X-Ph-Triggerid"]
                );
              }
              if (triggerLogId > 0) {
                // post triggerId fro csr task call logs
                let errTrigger, resultTrigger;

                [errTrigger, resultTrigger] = await this.$store.dispatch(
                  globalTypes.UPDATE_CSR_TRIGGER_LOG_ANSWERED_USER,
                  {
                    TriggerLogId: triggerLogId,
                  }
                );
              }
              let isAdCall = false;

              if (autoCallIndex > -1) {
                isAdCall =
                  this.autoCallIncomingCallList[autoCallIndex].isAdCall;
              }

              if (this.userPhoneSettings.isAutoAcceptCall && isAdCall) {
                let adCallCampaignId = 0;
                if (callInfo.extraHeaders["X-Ph-Campaignid"] != null) {
                  adCallCampaignId = parseInt(
                    callInfo.extraHeaders["X-Ph-Campaignid"]
                  );
                }

                //console.log("crm--> campaign id: " + adCallCampaignId);

                if (adCallCampaignId > 0) {
                  let campIndx = this.campaignList.findIndex(
                    (x) => x.Id == adCallCampaignId
                  );
                  //console.log("crm--> campaign index: " + campIndx);
                  if (campIndx > -1) {
                    let dtmfValue = this.campaignList[campIndx].AutoDtmfValue;

                    //console.log("crm--> campaign dtmf value: " + dtmfValue);
                    if (dtmfValue != null && dtmfValue != "") {
                      await this.sleep(500);
                      this.sendDtmf(dtmfValue);
                    }
                  }
                }

                //console.log("before 5 sec");
                await this.sleep(5000);

                let indx = this.autoCallIncomingCallList.findIndex(
                  (x) => x.callUUID == callInfo.callUUID
                );
                if (indx > -1) {
                  this.clearAdCall(callInfo);
                  this.activeCall = Object.assign(
                    {},
                    this.autoCallIncomingCallList[indx]
                  );

                  this.outgoingCallerId = this.callerIdFormatter(
                    this.activeCall
                  );
                  this.outgoingClientPhone = this.tryFormatPhone(
                    this.activeCall.phoneNumber
                  );
                } else {
                  this.activeCall.campaignId = 0;
                  this.activeCall.callerId = null;
                  return;
                }
              }

              if (
                typeof this.activeCall != "undefined" &&
                this.activeCall != null
              ) {
                this.state = callState.Connected;
                this.activeCall.isCallActive = true;
                //console.log("call active");
                if (callInfo.extraHeaders["X-PH-CallStartTime"] != null) {
                  this.activeCall.callStartTime = parseInt(
                    callInfo.extraHeaders["X-PH-CallStartTime"]
                  );
                }

                if (callInfo.extraHeaders["X-PH-IsConferenceCall"]) {
                  this.activeCallTimer = setInterval(
                    this.handleActiveCallTimer,
                    1000
                  );
                  this.activeCall.dispositionRequired = true;
                  this.removeFromCallQueue(callInfo.dest);
                  this.isCallQueuePanelVisible = true;
                  this.activeCall.callUUID = callInfo.callUUID;
                } else {
                  this.activeCall.callUUID = callInfo.callUUID;

                  this.activeCall.isInbound = callInfo.direction === "incoming";
                  this.activeCall.ringTime = 0;

                  if (!this.activeCall.isInternal) {
                    await this.sleep(1000);
                    if (this.state == callState.Connected) {
                      this.activeCall.callerId = this.activeCall.isInbound
                        ? callInfo.src
                        : callInfo.extraHeaders["X-PH-CallerId"];
                      this.activeCall.otherLeg = this.activeCall.isInbound
                        ? callInfo.src.replace("+", "")
                        : callInfo.dest;

                      this.activeCallTimer = setInterval(
                        this.handleActiveCallTimer,
                        1000
                      );
                      if (
                        typeof this.activeCall != "undefined" &&
                        this.activeCall &&
                        this.activeCall.callerId
                      )
                        this.activeCall.callerId = this.activeCall.callerId
                          .replace("(", "")
                          .replace(")", "")
                          .replace(" ", "")
                          .replace("-", "");
                    }
                  } else {
                    this.activeCallTimer = setInterval(
                      this.handleActiveCallTimer,
                      1000
                    );
                  }
                }
              }

              if (
                callInfo.extraHeaders["X-PH-IsAnnounceRoleNew"] &&
                callInfo.extraHeaders["X-PH-AnnouncePhoneNumber"]
              ) {
                if (this.userPhoneSettings.isAutoAcceptCall) {
                  await this.sleep(5000);
                }

                if (
                  this.state == callState.Connected ||
                  this.state == callState.Conference
                ) {
                  let phone = callInfo.extraHeaders["X-PH-AnnouncePhoneNumber"];
                  let announceConferenceName = "";
                  if (callInfo.extraHeaders["X-PH-AnnounceConf"]) {
                    announceConferenceName =
                      callInfo.extraHeaders["X-PH-AnnounceConf"];
                  }

                  let logErr, logResult;
                  [logErr, logResult] = await this.$store.dispatch(
                    types.INSERT_ANNOUNCE_CALL_LOG,
                    {
                      CallUUID: callInfo.callUUID,
                      PhoneNumber: phone,
                      ConferenceName: announceConferenceName,
                      EndpointId: this.userPhoneSettings.endPointId,
                      IsTransfered: false,
                    }
                  );

                  this.activeCall.announceCallEmployee = true;
                  this.activeCall.announceCallPhoneNumber = phone;
                }
              }
            }
            this.stopIncomingCallPlayer();
          } catch (err) {
            //console.log("Call Answered" + JSON.stringify(err));
            this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
              Message:
                "Plivo callback Exception:onCallAnswered Error: CallInfo: " +
                JSON.stringify(callInfo),
            });
          }
        },
        async (hangupInfo, callInfo) => {
          //console.log("##### Call Terminated: " + JSON.stringify(hangupInfo));

          //console.log("##### Call info: " + JSON.stringify(callInfo));
          //On Call Terminated

          try {
            this.blockUnmuteCall = false;
            let endpointCount = 0;
            if (
              callInfo.extraHeaders["X-Ph-Endpointcount"] != null &&
              typeof callInfo.extraHeaders["X-Ph-Endpointcount"] != "undefined"
            ) {
              endpointCount = parseInt(
                callInfo.extraHeaders["X-Ph-Endpointcount"]
              );
            }

            if (
              this.userPhoneSettings.isAutoAcceptCall &&
              hangupInfo.originator == "remote" &&
              this.activeCall &&
              this.activeCall.ringTime < 5 &&
              endpointCount > 1
            ) {
              this.$emit("updateShowPhonePanel", true);
              this.activeCall = null;
              this.outgoingCallerId = "";
              this.outgoingClientPhone = "";
              this.state = callState.NoCall;
              this.openInfoPanel = true;
              this.infoPanelClass = "errorInfo";
              this.infoPanelText = "Call accepted by another agent.";
              clearInterval(this.activeCallTimer);
              this.activeCallTimer = null;
            }

            let sendDispo = null;
            if (this.activeCall) {
              sendDispo = this.activeCall.dispositionRequired;

              this.state = callState.HungUp;

              this.$store.dispatch(globalTypes.RESET_IDLE);
              if (this.activeCall.clientId == 0) {
                let callerId = "";

                if (
                  this.activeCall.callerId &&
                  this.activeCall.callerId.length > 0
                ) {
                  callerId = this.activeCall.callerId
                    .replace("(", "")
                    .replace(")", "")
                    .replace(" ", "")
                    .replace("-", "");

                  this.clearAutoAcceptCallList(callerId);
                }

                if (callerId.length > 0) {
                  let err, result;
                  [err, result] = await this.$store.dispatch(
                    types.GET_DETAILS_BY_PHONE,
                    { PhoneNumber: callerId }
                  );
                  if (result) {
                    if (result.Data) {
                      if (result.Data.Id > 0) {
                        this.activeCall.clientId = result.Data.Id;
                        this.activeCall.statusId = result.Data.StatusId;
                        this.activeCall.officeId = result.Data.OfficeId;
                        this.activeCall.phoneNumber = this.activeCall.callerId;
                        this.activeCall.statusName = result.Data.StatusName;
                        this.activeCall.callerId = result.Data.Name;
                        this.activeCall.customerName = result.Data.Name;
                      } else if (result.Data.CreditorContactId > 0) {
                        this.activeCall.creditorContactId =
                          result.Data.CreditorContactId;
                        this.activeCall.creditorContactName = result.Data.Name;
                      }
                    }
                  }
                }
              }

              if (this.activeCall.clientId > 0) {
                await this.$store.dispatch(globalTypes.GET_DISPOSITIONTYPES);

                if (sendDispo) {
                  if (this.checkAuth(1865) && this.activeCall.ringTime > 10) {
                    this.pushToDispositionList(this.activeCall.clientId);
                    this.$store.dispatch(types.INSERT_MANDATORY_DISPO, {
                      CustomerId: this.activeCall.clientId,
                    });
                  }
                }
              }

              if (this.activeCall.callUUID == callInfo.callUUID)
                this.activeCall.isCallActive = false;

              if (this.incomingCallList && this.incomingCallList.length > 0) {
                this.$emit("updateShowPhonePanel", true);
                if (!this.userPhoneSettings.isAutoAcceptCall) {
                  this.playIncomingCallSound();
                }
              }

              clearInterval(this.activeCallTimer);
              this.activeCallTimer = null;
            }
            if (this.mergedCallItem != null) {
              await this.joinConference(
                this.mergedCallItem.call.conferenceName
              );
              await this.sleep(500);
              this.updateActiveCallInfo(this.mergedCallItem.call);
              this.activeCall.is3rdPartyCall = true;
              this.activeCall.isConferenceCall = true;
              this.activeCall.conferenceName =
                this.mergedCallItem.call.conferenceName;
              this.activeCall.ringTime += Math.ceil(
                this.getTimeDifference(
                  new Date(),
                  this.mergedCallItem.callQueueTime
                )
              );

              this.mergedCallItem = null;
            }

            this.checkPendingIncomingCalls();
            this.removeAutoIncomingCallList(callInfo.callUUID);

            if (
              this.activeCall &&
              this.activeCall.isAdCall == 1 &&
              this.activeCall.ringTime > 10
            ) {
              //UPDATE LAST CALL TIME

              try {
                if (this.state == callState.HungUp) {
                  this.$store.dispatch(globalTypes.UPDATE_LAST_CALL_TIME);
                }
              } catch (err) {
                //console.log(err);
              }
            }
            this.clearAdCall(callInfo);

            this.callTerminatedStarted = true;
          } catch (err) {
            this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
              Message:
                "Plivo callback Exception:onCallTerminated Error: #CallInfo: " +
                JSON.stringify(callInfo) +
                "#HangupInfo: " +
                JSON.stringify(hangupInfo),
            });
          }
        },
        async (callerId, extraHeaders, callInfo) => {
          //console.log("crm-->onIncomingCall");
          //console.log("Incoming call info: " + JSON.stringify(extraHeaders));
          //On Incoming Call
          try {
            let callRejected = false;
            if (this.rejectedCallList.length > 0) {
              callRejected =
                this.rejectedCallList.findIndex((x) => x.src == callInfo.src) >
                -1;
            }

            if (callRejected) {
              this.isCallRejected = true;
              this.plivosdk.client.reject(callInfo.callUUID);

              return;
            }

            let phoneForLog = this.unformatPhone(callInfo.src);

            this.$store.dispatch(types.SEND_INCOMING_CALL_LOG, {
              PhoneNumber: phoneForLog,
              StatusId: this.isUserOnline ? 1 : 2,
            });

            let newIncoming = Object.assign({}, incomingCallModel);
            let callerUserName = "";
            if (extraHeaders["X-Ph-Calleruser"]) {
              callerUserName = extraHeaders["X-Ph-Calleruser"].replace(
                "-",
                " "
              );
            }

            if (extraHeaders["X-Ph-Callstarttime"] != null) {
              newIncoming.callStartTime = parseInt(
                extraHeaders["X-Ph-Callstarttime"]
              );
            }

            let isCampaignSpanish = null;
            if (extraHeaders["X-Ph-Campaignid"] != null) {
              newIncoming.campaignId = parseInt(
                extraHeaders["X-Ph-Campaignid"]
              );
              let indx = this.campaignTypes.findIndex(
                (x) => x.Id == newIncoming.campaignId
              );

              if (indx > -1) {
                newIncoming.campaignName = this.campaignTypes[indx].Name;
                isCampaignSpanish = this.campaignTypes[indx].SpanishCampaign;
              }
            }

            newIncoming.callUUID = callInfo.callUUID;
            newIncoming.src = callInfo.src;
            newIncoming.ringTime = 90;

            if (extraHeaders["X-Ph-Calltimeout"] != null) {
              newIncoming.ringTime = parseInt(extraHeaders["X-Ph-Calltimeout"]);
              this.callTimeout = newIncoming.ringTime;
            }

            if (callerId && callerId != "" && callerId.startsWith("+1")) {
              let phoneNumber = this.cleanFormatPhone(callerId);

              let err, result;
              [err, result] = await this.$store.dispatch(
                types.GET_DETAILS_BY_PHONE,
                { PhoneNumber: phoneNumber }
              );

              if (result) {
                if (result.Data.Id > 0) {
                  //set incoming call fields with client data.
                  newIncoming.clientId = result.Data.Id;
                  newIncoming.statusId = result.Data.StatusId;
                  newIncoming.officeId = result.Data.OfficeId;
                  newIncoming.callerId = result.Data.Name;
                  newIncoming.customerName = result.Data.Name;
                  newIncoming.phoneNumber = this.formatPhone(phoneNumber);
                  newIncoming.statusName = result.Data.StatusName;

                  // get vm drops by status
                  await this.onCallClientLists(
                    result.Data.StatusId,
                    result.Data.Id
                  );
                } else if (result.Data.CreditorContactId > 0) {
                  newIncoming.creditorContactId = result.Data.CreditorContactId;
                  newIncoming.creditorContactName = result.Data.Name;
                  newIncoming.phoneNumber = this.formatPhone(phoneNumber);
                } else {
                  newIncoming.callerId = this.formatPhone(phoneNumber);
                }
              }
            } else if (callerId && callerId != "") {
              // get all callerIds in endpoint calls

              if (callerUserName != "") {
                newIncoming.callerId = callerUserName;
                newIncoming.isInternal = true;
              } else {
                let result = await this.$store.dispatch(
                  types.GET_CALLER_ID_ENDPOINT
                );

                if (
                  result &&
                  result.Data &&
                  this.employees &&
                  this.employees.length > 0
                ) {
                  if (
                    result.Data.CallerEndpoint &&
                    result.Data.CallerEndpoint != ""
                  ) {
                    // match any user endpoint name equal result
                    let indx = this.employees.findIndex(
                      (x) => x.EndpointName == result.Data.CallerEndpoint
                    );

                    if (indx >= 0) {
                      newIncoming.callerId = `${this.employees[indx].Name}`;
                    }
                  }
                  // set incoming call is endpoint call = internal call
                  newIncoming.isInternal = true;
                }
              }
            }
            let endpointCount = 0;
            if (
              extraHeaders["X-Ph-Endpointcount"] != null &&
              typeof extraHeaders["X-Ph-Endpointcount"] != "undefined"
            ) {
              endpointCount = parseInt(extraHeaders["X-Ph-Endpointcount"]);
              newIncoming.endpointCount = endpointCount;
            }

            if (endpointCount > 0) {
              //is Ad Call Start
              if (newIncoming.campaignId > 0) {
                newIncoming.isAdCall = 1;
              }

              if (this.userPhoneSettings.isAutoAcceptCall) {
                //isAutoAccept call start
                let indexOfAutoCallList = this.autoAcceptCalls.findIndex(
                  (x) =>
                    this.unformatPhone(x.CallerId) ==
                    this.createCallerId(
                      newIncoming.phoneNumber,
                      newIncoming.callerId
                    )
                );

                if (indexOfAutoCallList > -1) {
                  //check user call status available for auto call.

                  if (
                    this.isUserOnline &&
                    (this.state == callState.NoCall ||
                      this.state == callState.HungUp)
                  ) {
                    this.autoCallIncomingCallList.push(newIncoming);

                    let endPointIndex =
                      this.autoAcceptCalls[indexOfAutoCallList].EndpointIndex;

                    if (endPointIndex == 0) {
                      //console.log("answered index" + endPointIndex);
                      this.answerAutoCall(newIncoming);
                    } else if (endPointIndex > 0) {
                      // console.log("answered index" + endPointIndex);

                      let waitingMiliSeconds = endPointIndex * 3000;
                      // console.log(
                      //   "##### waiting auto call miliseconds:" +
                      //     waitingMiliSeconds
                      // );

                      // console.log(
                      //   "####Before waiting: " + new Date().getTime()
                      // );

                      await this.sleep(waitingMiliSeconds);
                      // console.log("####After waiting: " + new Date().getTime());

                      this.answerAutoCall(newIncoming);
                    } else {
                      this.pushPendingIncomingCall(newIncoming);
                    }
                  } else {
                    this.pushPendingIncomingCall(newIncoming);
                  }
                } else {
                  //indexOfAutoCallList == 0
                  if (
                    this.isUserOnline &&
                    (this.state == callState.NoCall ||
                      this.state == callState.HungUp)
                  ) {
                    this.plivosdk.client.answer(
                      newIncoming.callUUID,
                      "letring"
                    );

                    this.activeCall = Object.assign({}, newIncoming);

                    this.outgoingCallerId = this.callerIdFormatter(
                      this.activeCall
                    );

                    this.outgoingClientPhone = this.tryFormatPhone(
                      this.activeCall.phoneNumber
                    );

                    this.$emit("updateShowPhonePanel", true);
                    this.playAutoCallSound = true;
                    await this.sleep(2000);
                    this.playAutoCallSound = false;
                  } else {
                    this.pushPendingIncomingCall(newIncoming);
                  }

                  // if (this.isUserOnline && (this.incomingCallList.length == 0 || this.checkAuth(100040)) ) {
                  //   this.adCallTimer[newIncoming.callUUID] = setTimeout(
                  //     async () => {

                  //       this.incomingCallList.push(newIncoming);
                  //       await this.sleep(100);
                  //       this.cancelIncomingCallRing();
                  //       document.getElementById("incomingRingPlayer").play();

                  //       this.$emit("updateShowPhonePanel", true);
                  //       let incomingCallIndex = this.incomingCallList.findIndex(
                  //         (x) => x.callUUID == newIncoming.callUUID
                  //       );
                  //       newIncoming.callTimer = setInterval(() => {
                  //         if (newIncoming) {
                  //           if (newIncoming.ringTime > 0) {
                  //             if (incomingCallIndex >= 0) {
                  //               this.handleIncomingCallTimer(incomingCallIndex);
                  //             } else {
                  //               clearInterval(newIncoming.callTimer);
                  //               newIncoming.callTimer = null;
                  //             }
                  //           } else {
                  //             clearInterval(newIncoming.callTimer);
                  //             newIncoming.callTimer = null;
                  //           }
                  //         }
                  //       }, 1000);
                  //     },
                  //     (this.leadTier - 1) * this.tierTimeOut * 1000
                  //   );
                  // } else {
                  //   this.pushPendingIncomingCall(newIncoming);
                  // }
                }
                //isAutoAccept call end
              } else {
                // is not auto accept call start
                if (
                  this.isUserOnline &&
                  (this.incomingCallList.length == 0 || this.checkAuth(100040))
                ) {
                  let campaignTierTimeout = null;
                  let userCampaignTierNumber = null;
                  if (this.tierSettings) {
                    let indx = this.tierSettings.findIndex(
                      (x) => x.CampaignId == newIncoming.campaignId
                    );
                    if (indx > -1) {
                      campaignTierTimeout = parseInt(
                        this.tierSettings[indx].TierTimeout
                      );
                      userCampaignTierNumber = parseInt(
                        this.tierSettings[indx].TierNumber
                      );
                      if (isCampaignSpanish == true) {
                        //if it is spanish campaign, check user can speak spanish
                        if (this.userCanSpeakSpanish == false) {
                          userCampaignTierNumber += 1;
                        }
                      }
                    }
                  }

                  let waitingSeconds = 7000;
                  if (campaignTierTimeout > 0 && userCampaignTierNumber > 0) {
                    waitingSeconds =
                      (userCampaignTierNumber - 1) * campaignTierTimeout * 1000;
                  }

                  if (isNaN(waitingSeconds)) {
                    waitingSeconds = 7000;
                  }

                  this.adCallTimer[newIncoming.callUUID] = setTimeout(
                    async () => {
                      this.cleanUpDuplicatedIncomingCall(newIncoming);
                      this.incomingCallList.push(newIncoming);

                      await this.sleep(100);
                      let incomingCallIndex = this.incomingCallList.findIndex(
                        (x) => x.callUUID == newIncoming.callUUID
                      );

                      if (this.incomingCallList.length > 0) {
                        // decrease ring time based on tier...
                        let newRingTime =
                          parseInt(
                            this.incomingCallList[incomingCallIndex].ringTime
                          ) - waitingSeconds;

                        if (newRingTime < 10) newRingTime = 10;

                        this.incomingCallList[incomingCallIndex].ringTime =
                          newRingTime;
                      }

                      //set incoming call timer
                      newIncoming.callTimer = setInterval(() => {
                        if (newIncoming) {
                          if (newIncoming.ringTime > 0) {
                            if (incomingCallIndex >= 0) {
                              this.handleIncomingCallTimer(incomingCallIndex);
                            } else {
                              clearInterval(newIncoming.callTimer);
                              newIncoming.callTimer = null;
                            }
                          } else {
                            clearInterval(newIncoming.callTimer);
                            newIncoming.callTimer = null;
                          }
                        }
                      }, 1000);
                      this.cancelIncomingCallRing();
                      this.playIncomingCallSound();
                      //document.getElementById("incomingRingPlayer").play();
                      if (this.checkAuth(100040)) {
                        this.$emit("updateShowPhonePanel", true);
                      } else if (
                        (this.state == callState.NoCall ||
                          this.state == callState.HungUp) &&
                        (this.activeCall == null ||
                          this.activeCall.isCallActive == false)
                      ) {
                        this.$emit("updateShowPhonePanel", true);
                      }
                    },
                    waitingSeconds
                  );
                } else {
                  this.pushPendingIncomingCall(newIncoming);
                }
                // is not auto accept call end
              }

              //is Ad Call End
            } else {
              if (this.userPhoneSettings.isAutoAcceptCall) {
                if (
                  this.isUserOnline &&
                  (this.state == callState.NoCall ||
                    this.state == callState.HungUp)
                ) {
                  this.plivosdk.client.answer(newIncoming.callUUID, "letring");

                  this.activeCall = Object.assign({}, newIncoming);

                  this.outgoingCallerId = this.callerIdFormatter(
                    this.activeCall
                  );

                  this.outgoingClientPhone = this.tryFormatPhone(
                    this.activeCall.phoneNumber
                  );
                  this.$emit("updateShowPhonePanel", true);
                  this.playAutoCallSound = true;
                  await this.sleep(2000);
                  this.playAutoCallSound = false;
                } else {
                  this.pushPendingIncomingCall(newIncoming);
                }
              } else {
                if (
                  this.incomingCallList.length == 0 ||
                  this.checkAuth(100040)
                ) {
                  this.cleanUpDuplicatedIncomingCall(newIncoming);
                  this.incomingCallList.push(newIncoming);
                  await this.sleep(100);
                  this.cancelIncomingCallRing();
                  this.playIncomingCallSound();
                  // document.getElementById("incomingRingPlayer").play();
                  this.$emit("updateShowPhonePanel", true);

                  let incomingCallIndex = this.incomingCallList.findIndex(
                    (x) => x.callUUID == newIncoming.callUUID
                  );
                  newIncoming.callTimer = setInterval(() => {
                    if (newIncoming) {
                      if (newIncoming.ringTime > 0) {
                        if (incomingCallIndex >= 0) {
                          this.handleIncomingCallTimer(incomingCallIndex);
                        } else {
                          clearInterval(newIncoming.callTimer);
                          newIncoming.callTimer = null;
                        }
                      } else {
                        clearInterval(newIncoming.callTimer);
                        newIncoming.callTimer = null;
                      }
                    }
                  }, 1000);
                } else {
                  this.pushPendingIncomingCall(newIncoming);
                }
              }
            }
          } catch (err) {
            this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
              Message:
                "Plivo callback Exception:OnIncominCall Error: #CallInfo: " +
                JSON.stringify(callInfo) +
                "#CallerId: " +
                JSON.stringify(callerId) +
                "#Extraheaders: " +
                JSON.stringify(extraHeaders),
            });
          }
        },
        (cnn) => {
          //On Connection Changed
          if (this.ignoreDisconnectError == false) {
            if (cnn.state == "disconnected") {
              this.disconnectCode = cnn.eventCode;

              this.$swal(
                "Warning",
                "Your connection is unstable. You may have problems with the phone.",
                "warning"
              );
            } else if (cnn.state == "connected") {
              this.isPlivoConnected += 1;
              if (this.isPlivoConnected > 1) {
                this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
                  Message:
                    "Plivo Re-connected UserId: " +
                    this.userId +
                    " Disconnect Code: " +
                    this.disconnectCode,
                });
              }
            }
          } else {
            this.ignoreDisconnectError = false;
          }
        },
        (callInfo) => {
          //console.log("incoming call cancelled");
          //On Incoming Call Cancelled
          try {
            let myItem = this.incomingCallList.filter(
              (x) => x.callUUID == callInfo.callUUID
            );

            this.incomingCallList = this.incomingCallList.filter(
              (x) =>
                this.cleanFormatPhone(x.src) !=
                this.cleanFormatPhone(callInfo.src)
            );
            if (myItem && myItem.length > 0) {
              this.clearAutoAcceptCallList(myItem[0].callerId);
              clearInterval(myItem[0].callTimer);
              myItem[0].callTimer = null;
              this.incomingCallList = this.incomingCallList.filter(
                (x) => x.callUUID != myItem[0].callUUID
              );
              this.incomingCallList.push();
            }
            this.removePendingIncomingCall(callInfo);
            this.removeAutoIncomingCallList(callInfo.callUUID);
            this.clearAdCall(callInfo);
          } catch (err) {
            this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
              Message:
                "Plivo callback Exception:OnIncominCall Error: #CallInfo: " +
                JSON.stringify(callInfo),
            });
          }
        },
        (info) => {
          //console.log("call metrics callback");
          try {
            this.resetInfoPanel();
            if (info) {
              //console.log("call metrics info: " + JSON.stringify(info));

              if (info.active == true) {
                this.addCallMetricIssues(info);
              } else if (info.active == false) {
                this.removeCallMetricIssues(info);
              }

              let networkIssuesText = "";
              let audioIssuesText = "";
              if (this.callMetricsNetworkIssues.length > 0) {
                networkIssuesText =
                  "<div style='float:left; text-align:left;'>Network Issues: " +
                  this.callMetricsNetworkIssues.map((x) => x.type).join(",") +
                  " </div>";
              } else if (this.callMetricsAudioIssues.length > 0) {
                audioIssuesText =
                  "<div style='float:right; text-align:left;'>Audio Issues: " +
                  this.callMetricsAudioIssues.map((x) => x.type).join(",") +
                  " </div>";
              }

              if (networkIssuesText != "" || audioIssuesText != "") {
                this.openInfoPanel = true;
                this.infoPanelText = networkIssuesText + " " + audioIssuesText;
                this.infoPanelClass = "errorInfo";
              }
            }
          } catch (err) {
            //console.log(err)
          }
        }
      );
    },
    addCallMetricIssues(info) {
      try {
        if (info.group == "network") {
          let match = this.callMetricsNetworkIssues.filter(
            (x) => x.group == info.group && x.type == info.type
          );
          if (match.length == 0) {
            this.callMetricsNetworkIssues.push(info);
            this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
              Message: "Plivo Call Metrics: " + JSON.stringify(info),
            });
          }
        } else if (info.group == "audio") {
          let match = this.callMetricsAudioIssues.filter(
            (x) => x.group == info.group && x.type == info.type
          );
          if (match.length == 0) {
            this.callMetricsAudioIssues.push(info);
            this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
              Message: "Plivo Call Metrics: " + JSON.stringify(info),
            });
          }
        }
      } catch {
        this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
          Message: "Plivo AddCallMetricIssues info: " + JSON.stringify(info),
        });
      }
    },
    removeCallMetricIssues(info) {
      try {
        if (info.group == "network") {
          let indx = this.callMetricsNetworkIssues.findIndex(
            (x) => x.group == info.group && x.type == info.type
          );
          if (indx > -1) {
            this.callMetricsNetworkIssues.splice(indx, 1);
          }
        } else if (info.group == "audio") {
          let indx = this.callMetricsAudioIssues.findIndex(
            (x) => x.group == info.group && x.type == info.type
          );
          if (indx > -1) {
            this.callMetricsAudioIssues.splice(indx, 1);
          }
        }
      } catch {
        this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
          Message: "Plivo RemoveCallMetricIssues info: " + JSON.stringify(info),
        });
      }
    },
    logOut() {
      try {
        if (this.plivosdk && this.plivosdk.client) {
          this.plivosdk.client.logout();
          this.isUserLogout = false;
          this.$emit("updatePlivoLoggedIn", false);

          window.clearInterval(this.csrTaskCheckTimer);
          window.clearInterval(this.liveConferenceTimer);
          window.clearInterval(this.pendingCallTimer);
          this.pendingCallTimer = null;
          this.liveConferenceTimer = null;
          this.csrTaskCheckTimer = null;
          for (let i in this.adCallTimer) {
            try {
              clearTimeout(this.adCallTimer[i]);
              this.stopRing();
            } catch (err) {
              //console.log("logout Error: " + JSON.stringify(err));
            }
          }
        }
      } catch (err) {
        this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
          Message: "logOut Error: #UserId: " + this.userId,
        });
      }
    },
    async redialClick() {
      try {
        if (this.activeCall) {
          if (this.activeCall.creditorContactId > 0) {
            this.callCreditor(
              this.unformatPhone(this.activeCall.phoneNumber),
              this.activeCall.creditorContactId,
              this.activeCall.creditorContactName
            );
          } else {
            this.callDid(
              this.unformatPhone(this.activeCall.phoneNumber),
              this.activeCall.clientId,
              false,
              "",
              true,
              false
            );
          }
        } else {
          this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
            Message: "Plivo Redial: Redial clicked but there is no active call",
          });
        }
      } catch (err) {
        this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
          Message:
            "Redial Error: #ActiveCall: " + this.activeCall
              ? JSON.stringify(this.activeCall)
              : "active call is null",
        });
      }
    },
    async callCreditor(did, id, name) {
      try {
        if (document.getElementById("expandCollapsePhoneButton") == null)
          return;

        if (!this.userLoggedIn) return;

        //always use assignedUserDid for callerId
        let callerId = this.userPhoneSettings.userDid;

        if (callerId == "") {
          callerId = this.userPhoneSettings.outboundCallerId;
        }
        if (callerId == "") {
          callerId = this.matchDids(did);
        }

        if (!callerId.startsWith("1")) {
          callerId = "1" + callerId;
        }

        let extraHeaders = {
          "X-PH-IsDidCall": "true",
          "X-PH-From": this.userPhoneSettings.endPointName,
          "X-PH-CallerId": callerId,
          "X-PH-EndpointAuthId": this.userPhoneSettings.endPointAuthId,
        };

        if (this.plivosdk != null && this.userLoggedIn) {
          if (this.activeCall) {
            if (this.activeCall.isCallActive) {
              if (this.state == 5) {
                await this.grabCall();
              }
              if (this.activeCall.isConferenceCall) {
                let isKicked = await this.kickConference(
                  this.activeCall.conferenceName
                );
                if (isKicked) {
                  this.addToCallQueue();
                }
              } else {
                let isTransfered = await this.transferToConference(
                  this.activeCall
                );
                if (isTransfered) {
                  this.addToCallQueue();
                }
              }
              await this.sleep(500);
            }
            this.activeCall.otherLeg = did;
          }
          this.callingInfo.PhoneNumber = did;
          this.callingInfo.CallerId = callerId;
          this.callingInfo.IsJoinConference = false;
          await this.plivosdk.client.call("1" + did, extraHeaders);
        }

        let tmp = Object.assign({}, incomingCallModel);
        tmp.ringTime = 0;
        let activeCall = tmp;
        activeCall.phoneNumber = this.formatPhone(did);
        activeCall.callerId = callerId;
        activeCall.creditorContactId = id;
        activeCall.creditorContactName = name;
        this.activeCall = Object.assign({}, activeCall);

        this.$emit("updateShowPhonePanel", true);
      } catch (err) {
        this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
          Message:
            "callCreditor Error: ActiveCall: " + this.activeCall
              ? JSON.stringify(this.activeCall)
              : "active call is null",
        });
      }
    },
    async callDid(
      did,
      customerId,
      is3rdPCall,
      relatedPhoneNumber,
      skipDisposition,
      muteOnMerge
    ) {
      try {
        if (
          document.getElementById("expandCollapsePhoneButton") == null ||
          !this.userLoggedIn
        ) {
          this.$swal(
            "Error!",
            "Cannot initialize the Phone system. Please log out then log back in.",
            "error"
          );
          return;
        }

        let custId = null;
        if (customerId || typeof customerId != "undefined") {
          if (customerId > 0) {
            custId = customerId;
          }
        }

        let skipDispoCheck =
          skipDisposition == true ? true : !this.checkAuth(1865);

        if (this.customerDispositionList.length > 0) {
          let dispoIndex = this.customerDispositionList.findIndex(
            (x) => x == custId
          );
          if (dispoIndex > -1) {
            skipDispoCheck = true;
          }
        }

        this.plivoLoading = true;

        if (!skipDispoCheck) {
          let mandatoryRes = null;
          mandatoryRes = await this.$store.dispatch(types.GET_MANDATORY_DISPO);
          if (!is3rdPCall) {
            if (
              mandatoryRes &&
              mandatoryRes.Data &&
              mandatoryRes.Data.CustomerId &&
              mandatoryRes.Data.CustomerId > 0 &&
              mandatoryRes.Data.CustomerId != custId
            ) {
              this.$swal
                .fire({
                  title: "PENDING DISPOSITION",
                  text:
                    "Please send a disposition to " +
                    mandatoryRes.Data.CustomerName,
                  type: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "View Customer",
                })
                .then(async (dialog) => {
                  if (dialog.value) {
                    this.outSideCustomerClick(
                      mandatoryRes.Data.CustomerId,
                      mandatoryRes.Data.CustomerName
                    );
                  }
                });
              this.plivoLoading = false;
              return;
            } else {
              this.customerDispositionList = [];
            }
          }
        }

        let callerId = "";
        let skipTimeZoneWarning = false;
        let err1, result1;

        let customerId1 =
          customerId && customerId != "" ? parseInt(customerId) : 0;
        [err1, result1] = await this.$store.dispatch(
          types.GET_CALLER_ID_BY_CUSTOMER,
          { CustomerId: customerId1, PhoneNumber: did }
        );

        if (result1 && result1.Data) {
          if (result1.Data.CallerId) {
            callerId = result1.Data.CallerId;
          }
          if (result1.Data.TimeZoneInfo) {
            if (
              result1.Data.TimeZoneInfo.LocalTimeHour &&
              parseInt(result1.Data.TimeZoneInfo.LocalTimeHour) < 9
            ) {
              let message =
                "The current time in " +
                result1.Data.TimeZoneInfo.StateShort +
                " is " +
                this.getTime12Hours(
                  result1.Data.TimeZoneInfo.LocalTimeHour,
                  new Date().getMinutes()
                ) +
                ". Are you sure you want to proceed?";

              await this.$swal({
                title: "Time Zone Warning",
                text: message,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Ok",
              }).then(async (dialog) => {
                if (dialog.value) {
                  {
                    skipTimeZoneWarning = true;
                  }
                }
              });
            } else {
              skipTimeZoneWarning = true;
            }
          } else {
            skipTimeZoneWarning = true;
          }
        } else {
          let errMsg = "An Error Occured!";
          if (typeof err1 != "undefined" && err1 != null) {
            if (
              err1.response &&
              err1.response.data &&
              err1.response.data.Errors
            ) {
              errMsg = err1.response.data.Errors.join(",");
              errMsg += "<br/>Please check your internet connection.";
            } else {
              errMsg =
                "Conection issue!<br/>Please check your internet connection.";
            }
          } else {
            errMsg =
              "Connection issue!<br/>Please check your internet connection.";
          }

          this.$swal("Error!", errMsg, "error");
          this.plivoLoading = false;
          return;
        }

        if (!skipTimeZoneWarning) {
          this.plivoLoading = false;
          return;
        }
        if (callerId == "") {
          this.$swal(
            "Error!",
            "Caller Id could not assign for calling customer!",
            "error"
          );
          this.plivoLoading = false;
          return;
        }

        if (!callerId.startsWith("1")) {
          callerId = "1" + callerId;
        }

        let extraHeaders = {
          "X-PH-IsDidCall": "true",
          "X-PH-From": this.userPhoneSettings.endPointName,
          "X-PH-CallerId": callerId,
          "X-PH-EndpointAuthId": this.userPhoneSettings.endPointAuthId,
        };

        if (this.plivosdk != null) {
          if (
            this.pendingIncomingCalls &&
            this.pendingIncomingCalls.length > 0
          ) {
            this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
              Message:
                "Outbound call: pending incoming call count: " +
                this.pendingIncomingCalls.length,
            });

            for (let pendingCalls of this.pendingIncomingCalls) {
              await this.plivosdk.client.reject(pendingCalls.callUUID);
            }
          }

          if (this.activeCall) {
            if (this.activeCall.isCallActive) {
              if (this.state == 5) {
                await this.grabCall();
              }
              if (this.activeCall.isConferenceCall) {
                let isKicked = await this.kickConference(
                  this.activeCall.conferenceName
                );
                if (isKicked) {
                  this.addToCallQueue();
                }
              } else {
                let isTransfered = await this.transferToConference(
                  this.activeCall
                );
                if (isTransfered) {
                  this.addToCallQueue();
                }
              }
              await this.sleep(500);
            }
            this.activeCall.otherLeg = did;
          }

          this.callingInfo.PhoneNumber = did;
          this.callingInfo.CallerId = callerId;
          this.callingInfo.IsJoinConference = false;
          await this.plivosdk.client.call("1" + did, extraHeaders);

          let tmp = Object.assign({}, incomingCallModel);
          tmp.ringTime = 0;
          let activeCall = tmp;
          activeCall.callerId = callerId;

          let err, result;
          [err, result] = await this.$store.dispatch(
            types.GET_DETAILS_BY_PHONE,
            {
              PhoneNumber: did,
              CustomerId: custId,
            }
          );

          if (result) {
            if (result.Data && result.Data.Id > 0) {
              activeCall.clientId = result.Data.Id;
              activeCall.statusId = result.Data.StatusId;
              activeCall.officeId = result.Data.OfficeId;
              activeCall.callerId = result.Data.Name;
              activeCall.customerName = result.Data.Name;
              activeCall.phoneNumber = this.formatPhone(did);
              activeCall.statusName = result.Data.StatusName;

              this.onCallClientLists(result.Data.StatusId, result.Data.Id);

              if (this.checkAuth(3994) && parseInt(activeCall.clientId) > 0) {
                this.$store.dispatch(globalTypes.INSERT_USER_ACTIVITY, {
                  CustomerId: parseInt(activeCall.clientId),
                  UserActivityTypeId: 17,
                  AdditionalNotes: "Sent customer communication",
                });
              }
            } else {
              activeCall.callerId = this.formatPhone(did);
              activeCall.phoneNumber = this.formatPhone(did);
            }
          }
          if (is3rdPCall) {
            activeCall.is3rdPartyCall = true;
            activeCall.relatedPhoneNumber = relatedPhoneNumber;
            activeCall.phone3rdParty = this.unformatPhone(did);
            activeCall.muteCallOnMerge = muteOnMerge;
          }

          this.activeCall = Object.assign({}, activeCall);
          this.outgoingCallerId = "CID:" + this.formatPhone(callerId);
          this.outgoingClientPhone = this.tryFormatPhone(
            this.activeCall.phoneNumber
          );

          this.$emit("updateShowPhonePanel", true);
        }
      } catch (err) {
        this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
          Message:
            "callDid Error: ActiveCall: " + this.activeCall
              ? JSON.stringify(this.activeCall)
              : "active call is null",
        });
      }
      this.plivoLoading = false;
    },

    unformatPhone(did) {
      let rtn = "";
      try {
        if (did && did.length > 2) {
          rtn = did
            .replace("(", "")
            .replace(")", "")
            .replace("-", "")
            .replace(" ", "")
            .replace("+1", "")
            .replace("+", "")
            .replace("(", "")
            .replace(")", "")
            .replace("-", "")
            .replace(" ", "")
            .replace("+1", "")
            .replace("+", "");
        }
      } catch (err) {
        this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
          Message: "unformatPhone Error: Phone: " + did,
        });
      }
      return rtn;
    },
    createCallerId(phoneNumber, callerId) {
      let rtn = this.unformatPhone(callerId);
      try {
        let unFormatPhoneNumber = this.unformatPhone(phoneNumber);

        let isInteger = /^\d+$/.test(parseInt(unFormatPhoneNumber));
        if (isInteger && unFormatPhoneNumber.length == 10) {
          rtn = unFormatPhoneNumber;
        }
      } catch (err) {
        this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
          Message:
            "createCallerId Error: #phone: " +
            phoneNumber +
            "#callerId: " +
            callerId,
        });
      }
      return rtn;
    },
    matchDids(did) {
      let rtn = "";
      try {
        if (did.length > 2 && this.callerIdList.length > 0) {
          let matchstring = did.slice(0, 3);

          let temp = this.callerIdList.filter(
            (x) => x.indexOf(matchstring) == 0
          );

          if (temp.length > 0) {
            rtn = temp[Math.floor(Math.random() * temp.length)];
          } else {
            rtn =
              this.callerIdList[
                Math.floor(Math.random() * this.callerIdList.length)
              ];
          }
        }
        if (rtn.length == 10) rtn = "1" + rtn;
      } catch (err) {
        this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
          Message:
            "matchDids Error: #Did: " +
            did +
            "#callerIdList: " +
            this.callerIdList
              ? JSON.stringify(this.callerIdList)
              : "caller id list is empty",
        });
      }
      return rtn;
    },
    callEndpoint(endpointName) {
      try {
        this.resetPopups();
        let extraHeaders = {
          "X-PH-IsEndpointCall": "true",
          "X-PH-VmGreetingName": "",
          "X-PH-EndpointAuthId": this.userPhoneSettings.endPointAuthId,
          "X-PH-CallerUser": this.userFullName,
        };

        if (this.plivosdk != null && this.userLoggedIn)
          this.callingInfo.PhoneNumber = endpointName;
        this.callingInfo.CallerId = this.userFullName;
        this.callingInfo.IsJoinConference = false;
        this.plivosdk.client.call(endpointName, extraHeaders);

        let tmp = Object.assign({}, incomingCallModel);
        tmp.ringTime = 0;
        let activeCall = tmp;
        if (this.employees) {
          let indx = this.employees.findIndex(
            (x) => x.EndpointName == endpointName
          );
          if (indx > -1) {
            activeCall.callerId = this.employees[indx].Name;
          }
        }
        activeCall.isInternal = true;
        this.activeCall = Object.assign({}, activeCall);
        this.outgoingCallerId = this.callerIdFormatter(this.activeCall);
        this.outgoingClientPhone = this.tryFormatPhone(
          this.activeCall.phoneNumber
        );
      } catch (err) {
        this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
          Message:
            "callEndpoint Error: #UserId: " +
            this.userId +
            "#ToEndpoint: " +
            endpointName,
        });
      }
    },

    rejectCall(callUUID) {
      try {
        this.stopRing();
        let myItem = this.incomingCallList.filter(
          (x) => x.callUUID == callUUID
        );
        if (myItem && myItem.length > 0) {
          clearInterval(myItem[0].callTimer);
          myItem[0].callTimer = null;
          this.incomingCallList = this.incomingCallList.filter(
            (x) => x.callUUID != myItem[0].callUUID
          );
          this.incomingCallList.push();

          if (
            myItem[0].isAdCall == 0 &&
            myItem[0].campaignId == 0 &&
            myItem[0].endpointCount < 2
          ) {
            this.pushRejectedCallList({ src: myItem[0].src, date: new Date() });

            if (this.plivosdk != null && this.userLoggedIn) {
              this.isCallRejected = true;
              if (callUUID && callUUID != "")
                this.plivosdk.client.reject(callUUID);
            }
          }
        }
      } catch (err) {
        this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
          Message:
            "rejectCall Error: #CallUUID: " +
            callUUID +
            "#Incoming Call List" +
            this.incomingCallList
              ? JSON.stringify(this.incomingCallList)
              : "incoming call list is empty",
        });
      }
    },
    pushRejectedCallList(call) {
      try {
        if (this.rejectedCallList && this.rejectedCallList.length > 0) {
          let indx = this.rejectedCallList.findIndex((x) => x.src == call.src);
          if (indx < 0) this.rejectedCallList.push(call);
        } else {
          this.rejectedCallList.push(call);
        }
      } catch (err) {
        this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
          Message:
            "pushRejectedCallList Error: #Call: " +
            call +
            "#Rejected Call list: " +
            this.rejectedCallList
              ? JSON.stringify(this.rejectedCallList)
              : "rejected call list is empty",
        });
      }
    },
    clearRejectedCallList(secondsForDelete) {
      try {
        if (this.rejectedCallList && this.rejectedCallList.length > 0) {
          this.rejectedCallList = this.rejectedCallList.filter(
            (x) => this.getTimeDifference(new Date(), x.date) < secondsForDelete
          );
          this.rejectedCallList.push();
        }
      } catch (err) {
        this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
          Message:
            "clearRejectedCallList Error: #SecondsForDelete: " +
            secondsForDelete +
            "#Rejected Call List: " +
            this.rejectedCallList
              ? JSON.stringify(this.activeCall)
              : "active call is null",
        });
      }
    },
    async holdCall() {
      try {
        if (this.activeCall.callUUID && this.activeCall.callUUID != "") {
          let payload = {
            CallUuid: this.activeCall.callUUID,
            EndPointName: this.userPhoneSettings.endPointName,
          };
          if (this.activeCall.isConferenceCall) {
            payload = {
              CallUuid: this.activeCall.callUUID,
              EndPointName: this.userPhoneSettings.endPointName,
              ConferenceName: this.activeCall.conferenceName,
            };
          }
          this.plivoLoading = true;
          let result = await this.$store.dispatch(types.HOLD_CALL, payload);
          if (result) {
            this.state = callState.Hold;
          }
          this.plivoLoading = false;
        }
      } catch (err) {
        this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
          Message:
            "holdCall Error: ActiveCall: " + this.activeCall
              ? JSON.stringify(this.activeCall)
              : "active call is null",
        });
      }
    },
    async grabCall() {
      try {
        if (this.activeCall.callUUID && this.activeCall.callUUID != "") {
          let payload = {
            CallUuid: this.activeCall.callUUID,
            EndPointName: this.userPhoneSettings.endPointName,
          };
          if (this.activeCall.isConferenceCall) {
            payload = {
              CallUuid: this.activeCall.callUUID,
              EndPointName: this.userPhoneSettings.endPointName,
              ConferenceName: this.activeCall.conferenceName,
            };
          }
          this.plivoLoading = true;
          let result = await this.$store.dispatch(types.GRAB_CALL, payload);
          if (result) {
            this.state = callState.Connected;
          }
          this.plivoLoading = false;
        }
      } catch (err) {
        this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
          Message:
            "grabCall Error: ActiveCall: " + this.activeCall
              ? JSON.stringify(this.activeCall)
              : "active call is null",
        });
      }
    },
    async sendDtmf(digits) {
      try {
        if (this.plivosdk != null && this.userLoggedIn) {
          let arr = digits.split("");
          if (arr.length > 0) {
            for (let i = 0; i < arr.length; i++) {
              this.plivosdk.client.sendDtmf(arr[i]);

              await this.sleep(250);
            }
          }
        }
      } catch (err) {
        this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
          Message: "sendDtmf Error: digits: " + digits,
        });
      }
    },
    async answerIncomingCall(callItem) {
      try {
        let myItem = this.incomingCallList.filter(
          (x) => x.callUUID == callItem.callUUID
        );
        if (myItem && myItem.length > 0) {
          clearInterval(myItem[0].callTimer);
          myItem[0].callTimer = null;

          this.incomingCallList = this.incomingCallList.filter(
            (x) => x.callUUID != myItem[0].callUUID
          );
          this.incomingCallList = this.incomingCallList.filter(
            (x) =>
              this.cleanFormatPhone(x.src) !=
              this.cleanFormatPhone(myItem[0].src)
          );
          this.incomingCallList.push();
        }

        if (this.plivosdk != null && this.userLoggedIn) {
          if (this.activeCall && this.activeCall.isCallActive) {
            if (this.state == 5) {
              await this.grabCall();
            }

            this.queueCall(this.activeCall, callItem);
          } else {
            this.plivosdk.client.answer(callItem.callUUID, "letring");

            this.activeCall = Object.assign({}, callItem);

            this.outgoingCallerId = this.callerIdFormatter(this.activeCall);

            this.outgoingClientPhone = this.tryFormatPhone(
              this.activeCall.phoneNumber
            );
          }
        }
      } catch (err) {
        this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
          Message:
            "answerIncomingCall Error: #Call: " + callItem
              ? JSON.stringify(callItem)
              : "call is null",
        });
      }
    },
    async answerAutoCall(callItem) {
      try {
        let callExists = this.autoCallIncomingCallList
          .map((x) => x.callUUID)
          .includes(callItem.callUUID);

        if (callExists) {
          if (
            this.plivosdk != null &&
            this.userLoggedIn &&
            (this.activeCall == null || !this.activeCall.isCallActive) &&
            (this.state == callState.NoCall || this.state == callState.HungUp)
          ) {
            if (
              this.getTimeDifference(new Date(), this.answerAutoCallTime) > 10
            ) {
              this.answerAutoCallTime = new Date();

              this.playAutoCallSound = true;
              this.plivosdk.client.answer(callItem.callUUID, "letring");
              callItem.isAutoCall = true;
              this.activeCall = Object.assign({}, callItem);

              this.outgoingCallerId = this.callerIdFormatter(this.activeCall);

              this.outgoingClientPhone = this.tryFormatPhone(
                this.activeCall.phoneNumber
              );
              this.$emit("updateShowPhonePanel", true);

              await this.sleep(2000);
              this.playAutoCallSound = false;
            } else {
              let indx = this.pendingIncomingCalls.findIndex(
                (x) => x.callUUID == callItem.callUUID
              );
              if (indx == -1) {
                callItem.isAutoCall = true;
                if (
                  this.incomingCallList.length == 0 ||
                  this.checkAuth(100040)
                ) {
                  this.cleanUpDuplicatedIncomingCall(call);
                  this.incomingCallList.push(call);
                  await this.sleep(100);
                  this.cancelIncomingCallRing();
                  this.playIncomingCallSound();
                  // document.getElementById("incomingRingPlayer").play();

                  this.$emit("updateShowPhonePanel", true);
                  let incomingCallIndex = this.incomingCallList.findIndex(
                    (x) => x.callUUID == newIncoming.callUUID
                  );
                  if (incomingCallIndex > -1) {
                    newIncoming.callTimer = setInterval(() => {
                      if (newIncoming) {
                        if (newIncoming.ringTime > 0) {
                          if (incomingCallIndex >= 0) {
                            this.handleIncomingCallTimer(incomingCallIndex);
                          } else {
                            clearInterval(newIncoming.callTimer);
                            newIncoming.callTimer = null;
                          }
                        } else {
                          clearInterval(newIncoming.callTimer);
                          newIncoming.callTimer = null;
                        }
                      }
                    }, 1000);
                  }
                }
              }
            }
          }
        }
      } catch (err) {
        this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
          Message:
            "answerAutoCall Error: #Call: " + callItem
              ? JSON.stringify(callItem)
              : "call is null",
        });
      }
    },
    updateActiveCall(customerId, customerName) {
      try {
        if (this.activeCall) {
          this.activeCall.clientId = customerId;
          this.activeCall.customerName = customerName;
          this.activeCall.campaignId = 0;
        }
      } catch (err) {
        this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
          Message:
            "updateActiveCall Error #CustomerId: " +
            customerId +
            "#ActiveCall: " +
            this.activeCall
              ? JSON.stringify(this.activeCall)
              : "active call is null",
        });
      }
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    getWaitingSyncSeconds(difference) {
      let rtn = 0;
      if (difference < 0) {
        rtn = difference + 1000;
      }

      if (rtn < 0) rtn = 0;

      return rtn;
    },
    callNegotiatorContact(number) {
      if (number != "") {
        this.callDid(number, 0, false, "", false, false);
      }
    },
    openCreditorContactForm(contactItem) {
      this.showCreditorContactForm = true;
      this.creditorContactData = contactItem;
    },
    async closeCreditorContactForm(data) {
      try {
        this.showCreditorContactForm = false;
        if (data.RefreshList) {
          await this.$store.dispatch(types.GET_CREDITOR_CONTACTS, {
            ForceRefresh: data.RefreshList,
          });
        }
      } catch (err) {
        this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
          Message:
            "closeCreditorContactForm Error: #Data: " + this.data
              ? JSON.stringify(this.data)
              : "Data is null",
        });
      }
    },
  },
};
</script>
<style lang="scss">
.pPanelWrapper {
  width: calc(100% - 240px);
  position: fixed;
  bottom: 0;
  padding: 16px 22px;
  left: 240px;

  z-index: 999;

  .loading {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.6);
    z-index: 1000;
  }

  .row {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #e4e4e4;
    height: 72px;
    display: flex;
    align-items: center;
  }

  .active-row {
    padding: 16px;
    background-color: #f5f5f5;
    border: solid 1px #e4e4e4;
    border-radius: 8px;

    &.disposition-enabled {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .contact-wrapper {
    .contact-details {
      display: flex;
      flex-flow: column;
      padding-left: 10px;

      span {
        color: #676666;
        font-size: 14px;
      }

      .bold {
        font-weight: 600;
      }
    }

    & > span {
      display: flex;
    }
  }

  .duration {
    display: block;
    border-radius: 18px;
    font-weight: bold;
    font-size: 14px;
    padding: 9px 16px;
    height: 40px;
    border: solid 1px #e4e4e4;
    background-color: #ffffff;
  }

  .no-calls {
    display: inline-block;
    height: 40px;
    border-radius: 6px;
    padding: 0 16px;
    line-height: 40px;
    font-size: 14px;
    border: solid 1px #e6e6e6;
    color: #2a404c;
    background-color: #ffffff;
    cursor: pointer;
  }

  .on-hold {
    display: inline-block;
    height: 40px;
    border-radius: 6px;
    padding: 0 16px;
    line-height: 40px;
    font-size: 14px;
    color: #fff;
    background-color: #d7a505;
    cursor: pointer;
  }

  .connected {
    display: inline-block;
    height: 40px;
    border-radius: 6px;
    padding: 0 16px;
    line-height: 40px;
    font-size: 14px;
    color: #fff;
    background-color: #24d15f;
    cursor: pointer;
  }

  .hung-up {
    display: inline-block;
    height: 40px;
    border-radius: 6px;
    padding: 0 16px;
    line-height: 40px;
    font-size: 14px;
    color: #fff;
    background-color: #ed3030;
    cursor: pointer;
  }

  .incoming {
    display: inline-block;
    height: 40px;
    border-radius: 6px;
    padding: 0 16px;
    line-height: 40px;
    font-size: 14px;
    color: #fff;
    background-color: #6080f7;
  }

  .infoRow {
    width: 100%;
    position: absolute;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    top: -15px;
    left: 0px;
    margin: 0 0;
    padding: 3px 12px;
    font-size: 0.8em;
    color: white !important;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  }

  .errorInfo {
    background-color: #f1556c;
  }

  .warningInfo {
    background-color: #f7b84b;
  }

  .successInfo {
    background-color: #1abc9c;
  }

  .row {
    background-color: #f5f5f5;
    border-radius: 10px;
    margin: 0 0;
  }

  .texts {
    width: 100%;
    float: left;
    font-size: 20px;
    margin: 15px 0;

    .icons {
      float: left;
      padding: 0 10px;
    }
  }

  .button {
    float: right;
    margin: 0 5px;
    cursor: pointer;

    .icons {
      font-size: 32px;
      padding: 5px;
    }
  }

  .hangup {
    .icons {
      background-color: #ed3030 !important;
      cursor: pointer !important;

      i {
        color: #fff !important;
        font-size: 18px !important;
        position: relative !important;
        top: -8px !important;
      }
    }
  }

  .muteButton {
    .icons {
      background-color: #ea9807 !important;
      cursor: pointer !important;

      i {
        color: #fff !important;
        font-size: 18px !important;
        position: relative !important;
        top: -8px !important;
      }
    }
  }

  .disabledMuteButton {
    .icons {
      background-color: gray !important;
      opacity: 0.5;
      cursor: pointer !important;

      i {
        color: #fff !important;
        font-size: 18px !important;
        position: relative !important;
        top: -8px !important;
      }
    }
  }

  .callline {
    .icons {
      i {
        color: #fff !important;
        font-size: 20px !important;
        position: relative !important;
        top: -8px !important;
      }
    }
  }

  .pauseline {
    .icons {
      background-color: #d7a505 !important;

      i {
        color: #fff !important;
        font-size: 20px !important;
        position: relative !important;
        top: -8px !important;
      }
    }
  }

  .moreline {
    .icons {
      background-color: #e4e4e4 !important;

      i {
        color: #000 !important;
        font-size: 20px !important;
        position: relative !important;
        top: -8px !important;
      }
    }
  }

  .recordline {
    .icons {
      background-color: #e4e4e4 !important;

      i {
        color: #000 !important;
        font-size: 20px !important;
        position: relative !important;
        top: -8px !important;
      }
    }
  }

  .forwardline {
    .icons {
      background-color: #e4e4e4 !important;

      i {
        color: #000 !important;
        font-size: 20px !important;
        position: relative !important;
        top: -8px !important;
        left: -1px !important;
      }
    }
  }

  .userline {
    .icons {
      background-color: #e4e4e4 !important;

      i {
        color: #000 !important;
        font-size: 20px !important;
        position: relative !important;
        top: -8px !important;
        left: -1px !important;
      }
    }
  }

  .playline {
    .icons {
      background-color: #24d15f !important;

      i {
        color: #fff !important;
        font-size: 20px !important;
        position: relative !important;
        top: -8px !important;
      }
    }
  }

  .dialnext {
    height: 40px;
    border-radius: 6px;
    background-color: #3bafda;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    font-size: 13px;
    padding: 0 16px;
  }

  .action-button {
    position: relative;
  }

  .disposition {
    display: flex;
    align-items: center;
    flex-grow: 2;
    justify-content: flex-start;
    background-color: #fff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border: 1px solid #e4e4e4;
    float: left;
    width: 100%;
    padding: 10px 10px;
    margin: 0 0 5px 0;
    clear: both;

    .align-right {
      display: flex;
      justify-content: flex-end;
    }

    .green-button {
      background-color: #24d171;
      color: #fff;
      font-size: 13px;
      padding: 3px 7px;
      border-radius: 4px;
      cursor: pointer;
    }

    .blue-button {
      background-color: #72afe1;
      color: #fff;
      font-size: 13px;
      padding: 3px 7px;
      border-radius: 4px;
      cursor: pointer;
    }

    .col-md-2 {
      display: flex;
    }

    .col-md-8 {
      display: flex;
    }

    .col-md-2 {
      display: flex;
    }

    button {
      margin-left: 16px;
      background-color: #ea9807;
      padding: 9px 16px;
      border-radius: 8px;
    }
  }

  .dispoLogPanel {
    position: absolute;
    width: 500px !important;
    bottom: 40px;

    right: 0;
    z-index: 9999;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    padding: 0.25rem;
  }

  .dispoButtons:hover {
    color: #007bff;
  }

  .button {
    .icons {
      display: inline-block;
      width: 40px;
      height: 40px;
      border-radius: 20px;
      background-color: #24d15f;
      padding: 0 !important;
      text-align: center;

      i {
        color: var(--white);
        font-size: 20px;
        top: -8px;
        position: relative;
      }

      &.keyboard-icon {
        background-color: var(--dark-gray);
      }
    }
  }
  .callPanelScroll {
    max-height: 300px;
    overflow: hidden auto;
    padding-left: 30px;
    padding-right: 30px;
    background-color: #ffff;
    border-radius: 10px;
    padding-top: 15px;
    margin-bottom: 10px;
  }
}
</style>
