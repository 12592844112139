<template>
  <div>
    <div class="swal2-header text-center">
      <h2 class="swal2-title" id="swal2-title" style="display: inline-block">
        {{ title }}
      </h2>
    </div>
    <div class="swal2-content mt-4">
      <div
        id="swal2-content"
        class="swal2-html-container"
        style="display: block"
      >
        {{ content }}
      </div>
    </div>
    <div class="swal2-actions mt-4">
      <button
        type="button"
        class="swal2-confirm swal2-styled"
        aria-label
        style="
          display: inline-block;
          background-color: rgb(48, 133, 214);
          border-left-color: rgb(48, 133, 214);
          border-right-color: rgb(48, 133, 214);
        "
        @click="acceptCustomer()"
      >
        Accept
      </button>
      <button
        type="button"
        class="swal2-cancel swal2-styled"
        aria-label
        style="display: inline-block; background-color: rgb(221, 51, 51)"
        @click="cancel()"
      >
        Cancel
      </button>
    </div>
  </div>
</template>
<script>
export default {
  name: "HotLeadPopup",
  props: {
    customerId: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      title: "NEW HOT LEAD",
      content: "Are you available?",
    };
  },
  mounted() {},
  methods: {
    acceptCustomer() {
      this.$emit("handleHotLeadAccept", this.customerId);
    },
    cancel() {
      this.$emit("closeHotLeadPopup");
    },
  },
};
</script>
<style></style>
