<template>
  <Popover class="internal-call">
    <template v-slot:header>
      <div class="container">
        Internal Call
        <input
          class="form-control"
          type="text"
          placeholder="Filter by name"
          v-model="contactListFilter"
        />
      </div>
    </template>
    <template v-slot:body>
      <div class="contact-list">
        <div
          class="contact"
          :key="item.Id"
          v-for="item in softPhoneEmployees"
          @click="handleInternalCall(item.EndpointName)"
        >
          {{ item.Name }}

          <i
            :style="'background-color: ' + statusColor(item.EndpointName)"
            class="ri-phone-fill"
          ></i>
        </div>
      </div>
    </template>
  </Popover>
</template>

<script>
import Popover from "../../Popover";
import types from "../types";
import { mapState } from "vuex";
import utilitiesMixin from "@/mixins/utilitiesMixin";

export default {
  components: {
    Popover,
  },
  props: {
    endPointName: {
      type: String,
      default: () => "",
    },
  },
  mixins: [utilitiesMixin],
  data() {
    return {
      contactListFilter: "",
      userOfficeId: null,
    };
  },
  computed: mapState({
    employeeList: (state) => state.globals.allEmployees,
    endPointList: (state) => state.phoneManagement.endPointList,
    softPhoneEmployees() {
      return this.checkAuth(100005)
        ? this.employeeList.filter(
            (x) =>
              x.Name.toLowerCase().indexOf(
                this.contactListFilter.toLowerCase()
              ) > -1
          )
        : this.employeeList
            .filter((x) => x.OfficeId == this.userOfficeId)
            .filter((item) => item.IsPhoneEnabled)
            .filter((item) => item.EndpointName != "")
            .filter((item) => item.EndpointName != this.endPointName)
            .filter(
              (x) =>
                x.Name.toLowerCase().indexOf(
                  this.contactListFilter.toLowerCase()
                ) > -1
            );
    },
  }),
  async mounted() {
    await this.$store.dispatch(types.GET_ENDPOINT_LIST);

    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo) {
      this.userOfficeId = userInfo.officeId;
    }
  },
  methods: {
    handleInternalCall(endPointName) {
      this.$emit("callEndpoint", endPointName);
    },
    statusColor(EndpointName) {
      let rtn = "gray";

      if (
        this.endPointList &&
        this.endPointList.length > 0 &&
        this.softPhoneEmployees.length > 0
      ) {
        let indx = this.endPointList.findIndex(
          (x) => x.UserName == EndpointName
        );
        if (indx > -1) {
          let item = this.endPointList[indx];
          if (item.IsOnCall) {
            rtn = "#ed3030";
          } else if (item.IsOnline) {
            rtn = "#24d15f";
          }
        }
      }
      return rtn;
    },
  },
};
</script>
