<template>
  <div class="col-md-12">
    <ReportSummary
      :exportData="reportData"
      :reportName="'NsfGlobalReport'"
      :recordCount="recordCount"
      @updateLoadingStatus="reportLoading = $event"
      @refreshReport="refreshReport"
      @clearReport="clearReport"
      @setPagination="setPagination"
      :loadingSpinner="loadingSpinner"
    />

    <div
      class="datagrid-table gcsTabsTableScroll report-wrapper"
      :style="'max-height: ' + reportsPanelHeight + 'px; overflow-y:auto;'"
    >
      <o-table
        :data="filteredNSFGlobalReport"
        :paginated="isPaginated && filteredNSFGlobalReport.length > 0"
        :per-page="perPage"
        :pagination-simple="isPaginationSimple"
        :pagination-position="paginationPosition"
        :sticky-header="true"
      >
        <o-table-column
          field="Account_ID"
          label="Account Id"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="Account_IDFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.Account_ID }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.Account_ID }}
            </span>
          </template>
        </o-table-column>

        <o-table-column field="Client_ID" label="Client Id" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="Client_IDFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <span @click="viewCustomer(props.row.Client_ID, props.row.First_Name, props.row.Last_Name)"
              :class="getTagReportHyperLinkClass(props.row.Client_ID && props.row.First_Name && props.row.Last_Name)">
              <strong v-if="props.row.IsSummary"
                >{{ props.row.Client_ID }}
              </strong>
              <span v-if="!props.row.IsSummary">
                {{ props.row.Client_ID }}
              </span>
            </span>
          </template>
        </o-table-column>

        <o-table-column field="Last_Name" label="Last Name" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="Last_NameFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.Last_Name }}
          </template>
        </o-table-column>

        <o-table-column
          field="First_Name"
          label="First Name"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="First_NameFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.First_Name }}
          </template>
        </o-table-column>

        <o-table-column field="State" label="State" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="StateFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.State }}
          </template>
        </o-table-column>

        <o-table-column
          field="Creation_Date"
          label="Creation Date"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="Creation_DateFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ formatShortDate(props.row.Creation_Date) }}
          </template>
        </o-table-column>

        <o-table-column
          field="Check_Date"
          label="Check Date"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="Check_DateFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ formatShortDate(props.row.Check_Date) }}
          </template>
        </o-table-column>

        <o-table-column
          field="Debit_Date"
          label="Debit Date"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="Debit_DateFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ formatShortDate(props.row.Debit_Date) }}
          </template>
        </o-table-column>

        <o-table-column
          field="NSF_Amount"
          label="NSF Amount"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="NSF_AmountFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.NSF_Amount }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatMoney(props.row.NSF_Amount) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column field="NSF_Date" label="NSF Date" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="NSF_DateFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ formatShortDate(props.row.NSF_Date) }}
          </template>
        </o-table-column>

        <o-table-column
          field="NSF_Reason"
          label="NSF Reason"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="NSF_ReasonFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.NSF_Reason }}
          </template>
        </o-table-column>

        <o-table-column
          field="Policy_Group"
          label="Policy Group"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="Policy_GroupFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.Policy_Group }}
          </template>
        </o-table-column>

        <o-table-column
          field=""
          label="View Customer"
          position="centered"
          v-slot="props"
        >
          <button
            @click="
              viewCustomer(
                props.row.Client_ID,
                props.row.First_Name,
                props.row.Last_Name
              )
            "
            class="btn btn-info waves-effect waves-light ml-3"
            v-if="!props.row.IsSummary"
          >
            <i class="far fa-address-card"></i>
          </button>
        </o-table-column>
      </o-table>
      <section
        v-if="filteredNSFGlobalReport.length == 0"
        class="section"
        style="text-align: center"
      >
        <div class="content has-text-grey has-text-centered mb-4">
          <p class="noMargin">
            <i
              style="font-size: 24px; color: #939393"
              class="ri-question-line"
            ></i>
          </p>
          <p class="noMargin">Nothing here.</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import ReportSummary from "./ReportSummary.vue";
import utilitiesMixin from "@/mixins/utilitiesMixin";

export default {
  name: "NsfGlobalReport",
  components: { ReportSummary },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      reportsPanelHeight: 300,
      loadingSpinner: false,
      isPaginationSimple: false,
      isPaginated: true,
      paginationPosition: "both",
      currentPage: 1,
      perPage: 200,

      recordCount: 0,
      reportLoading: false,
      reportData: [],

      nsfGlobalReportInitSummary: {
        Account_ID: "",
        Client_ID: "",
        Last_Name: "",
        First_Name: "",
        State: "",
        Creation_Date: "",
        Check_Date: "",
        Debit_Date: "",
        NSF_Amount: "",
        NSF_Date: "",
        NSF_Reason: "",
        Policy_Group: "",
      },
      Account_IDFilter: "",
      Client_IDFilter: "",
      Last_NameFilter: "",
      First_NameFilter: "",
      StateFilter: "",
      Creation_DateFilter: "",
      Check_DateFilter: "",
      Debit_DateFilter: "",
      NSF_AmountFilter: "",
      NSF_DateFilter: "",
      NSF_ReasonFilter: "",
      Policy_GroupFilter: "",
    };
  },
  computed: mapState({
    nsfGlobalList: (state) => state.reports.nsfGlobalList,

    filteredNSFGlobalReport() {
      let rtn = [];

      let tmpList =
        this.nsfGlobalList.length > 0
          ? this.nsfGlobalList.filter((item) => {
              return (
                this.filterString(item.Account_ID, this.Account_IDFilter) &&
                this.filterString(item.Client_ID, this.Client_IDFilter) &&
                this.filterString(item.Last_Name, this.Last_NameFilter) &&
                this.filterString(item.First_Name, this.First_NameFilter) &&
                this.filterString(item.State, this.StateFilter) &&
                new Date(item.Creation_Date)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .indexOf(this.Creation_DateFilter) > -1 &&
                new Date(item.Check_Date)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .indexOf(this.Check_DateFilter) > -1 &&
                new Date(item.Debit_Date)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .indexOf(this.Debit_DateFilter) > -1 &&
                new Date(item.NSF_Date)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .indexOf(this.NSF_DateFilter) > -1 &&
                this.filterString(item.NSF_Amount, this.NSF_AmountFilter) &&
                this.filterString(item.NSF_Reason, this.Last_NameFilter) &&
                this.filterString(item.Policy_Group, this.Policy_GroupFilter)
              );
            })
          : [];

      if (tmpList.length == 0) {
        this.reportData = [];
        return rtn;
      }
      let totalCustomer = tmpList.length.toString();

      this.nsfGlobalReportInitSummary.Account_ID = "Total: " + totalCustomer;
      this.recordCount = tmpList.length;
      this.nsfGlobalReportInitSummary.IsSummary = true;

      rtn.push(...tmpList);
      rtn.push(this.nsfGlobalReportInitSummary);
      this.reportData = rtn;

      return rtn;
    },
  }),
  async mounted() {
    this.reportsPanelHeight = this.setReportGridHeight(
      window.innerHeight,
      document.getElementById("reportsSummary")
    );
    await this.sleep(1000);
    this.fixStickyHeaders();
  },
  methods: {
    setPagination(data) {
      this.isPaginated = data;
    },
    async refreshReport(value) {
      this.loadingSpinner = true;
      await this.$store.dispatch(types.GET_NSF_GLOBAL_REPORT, value);
      this.loadingSpinner = false;
      await this.sleep(500);
      this.fixStickyHeaders();
    },
    clearReport() {
      this.$store.dispatch(types.RESET_REPORTS);
      this.reportData = [];
    },
    async viewCustomer(id, firstname, lastname) {
      this.$Emitter.emit("viewCustomer", {
        customerId: id.replace(/\D/g, ""),
        customerName: firstname + "" + lastname,
      });
    },
  },
};
</script>

<style scoped></style>
