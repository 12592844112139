<template>
  <Popover class="numpad">
    <template #header><div class="container">Type a number</div></template>
    <template #body>
      <div class="full-width p-12">
        <input
          class="form-control full-width"
          @change="refreshValue"
          type="text"
          v-model="did"
          v-myMask="{ mask: ['(999) 999-9999'] }"
          v-on:keydown.enter.prevent="callNumber(did)"
        />
        <button
          type="button"
          @click="callNumber(did)"
          class="btn btn-primary btn-primary-bordered full-width mt-12"
        >
          Call This Number
        </button>
      </div>
    </template>
  </Popover>
</template>

<script>
import Popover from "../../Popover";
export default {
  components: {
    Popover,
  },
  emits: ["callDid"],
  data() {
    return {
      did: "",
    };
  },
  methods: {
    refreshValue(event) {
      if (event.target.value && event.target.value != "") {
        this.did = event.target.value;
      }
    },
    callNumber(did) {
      if (did != "" && did.length >= 10) {
        this.$emit("callDid", did);
      }
    },
  },
};
</script>
