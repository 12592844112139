import customerApi from "@/services/client/customerApi";
import api from "@/services/admin/api";
import to from "@/helpers/to";
export default {
  state: {},
  actions: {
    GET_SMS_LOGS: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(customerApi.getSmsLogs(payload));

      return result;
    },
    OPT_OUT_NUMBER: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(customerApi.optOutNumber(payload));
      return [err, result];
    },
    SEND_SMS: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(customerApi.sendSms(payload));
      return [err, result];
    },
    GET_CALLER_ID_FOR_SMS: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.getCallerId(payload));
      return [err, result];
    },
    SEND_OPTIN_SMS: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(customerApi.sendOptinRequest(payload));
      return [err, result];
    },
  },
};
