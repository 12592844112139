<template>
  <div
    class="col-lg-12 col-md-12 col-sm-12 newPanelWrapper noMargin"
    style="background-color: #ffffff"
  >
    <div class="form-group row">
      <div class="col-md-12 noMargin">
        <label class="generalLabel" for="triggerDate">Reminder Date</label>
      </div>
      <div class="col-md-12 noMargin">
        <DatePicker
          id="triggerDate"
          mode="datetime"
          :available-dates="datepickerConfig"
          :locale="'en-US'"
          v-model="selectedRow.TriggerDate"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <input
              id="DateOfBirthofReminder"
              class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
              :value="inputValue"
              v-on="inputEvents"
            />
          </template>
        </DatePicker>
      </div>
    </div>

    <div class="form-group row mt-2">
      <div class="col-md-12 noMargin">
        <label class="generalLabel" for="ReminderComment">Reminder Text</label>
      </div>
      <div class="col-md-12 noMargin">
        <textarea
          id="ReminderComment"
          ref="reminderComment"
          v-model="selectedRow.NotificationText"
          class="form-control generalTextBox"
          rows="5"
          style="resize: none"
        ></textarea>
      </div>
    </div>
    <div class="form-group row mt-2">
      <div class="col-md-12">
        <button
          type="button"
          @click="saveReminder()"
          :disabled="customerId <= 0"
          class="btn generalButton float-left"
          style="background-color: #3bafda; color: #ffffff"
        >
          {{ isEditing ? "Save" : "Add" }}
        </button>
        <button
          type="button"
          v-if="isEditing"
          @click="cancel()"
          class="btn generalButton float-left ml-2"
          style="background-color: #ed3030; color: #ffffff"
        >
          Cancel
        </button>
      </div>
    </div>

    <div class="form-group row mt-2">
      <div class="col-md-12">
        <label class="generalLabel">Pending / Existing Reminders</label>
      </div>
    </div>
    <div
      class="datagrid-table"
      style="
        max-height: 320px;
        overflow-y: auto;
        overflow-x: hidden;
        font-size: 12px !important;
      "
    >
      <section>
        <o-table
          :data="isEmpty ? [] : sortedReminders"
          :default-sort-direction="defaultSortDirection"
          :sort-icon="sortIcon"
          :sort-icon-size="sortIconSize"
          :striped="isStriped"
          :hoverable="isHoverable"
          :loading="isLoading"
        >
          <template>
            <o-table-column
              field="AssignedUser"
              label="User"
              width="23%"
              sortable
              v-slot="props"
              >{{ props.row.AssignedUser }}</o-table-column
            >
            <o-table-column
              field="TriggerDate"
              label="Trigger On"
              style="text-align: left"
              width="20%"
              sortable
              v-slot="props"
              >{{ formatDate(props.row.TriggerDate) }}</o-table-column
            >
            <o-table-column
              style="text-align: left"
              field="NotificationText"
              label="Reminder Text"
              width="40%"
              sortable
              v-slot="props"
            >
              {{ props.row.NotificationText }}
            </o-table-column>
            <o-table-column
              width="17%"
              style="text-align: right; padding: 11px 0 !important"
              v-slot="props"
            >
              <button
                v-if="
                  (canEditOthers || userId == props.row.AssignedUserId) &&
                  !props.row.IsDismissed
                "
                type="button"
                class="btn"
                style="background-color: #24d171; color: White"
                @click="editRow(props.row)"
              >
                <i style="font-size: 16px" class="ri-edit-line"></i>
              </button>
              <button
                v-if="
                  (canEditOthers || userId == props.row.AssignedUserId) &&
                  !props.row.IsDismissed
                "
                type="button"
                class="btn"
                style="background-color: rgb(237, 48, 48); color: White"
                @click="deleteRow(props.row)"
              >
                <i class="ri-delete-bin-line"></i>
              </button>
            </o-table-column>
          </template>

          <template>
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <!--
                  <p>
                  <b-icon icon="emoticon-sad" size="is-large"></b-icon>
                </p>
                -->
                <p class="noMargin">
                  <i
                    style="font-size: 24px; color: #939393"
                    class="ri-question-line"
                  ></i>
                </p>
                <p class="noMargin">Nothing here.</p>
              </div>
            </section>
          </template>
        </o-table>
        <section
          v-if="sortedReminders.length == 0"
          class="section"
          style="text-align: center"
        >
          <div class="content has-text-grey has-text-centered mb-4">
            <p class="noMargin">
              <i
                style="font-size: 24px; color: #939393"
                class="ri-question-line"
              ></i>
            </p>
            <p class="noMargin">Nothing here.</p>
          </div>
        </section>
      </section>
    </div>
  </div>
</template>
<script>
import api from "@/services/client/customerApi";
import to from "@/helpers/to";
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import _orderby from "lodash";
import notificationTypes from "./types";
import { mapState } from "vuex";
import { DatePicker } from "v-calendar";
import deepCopy from "@/helpers/deepCopy";
import globalTypes from "@/store/types";
export default {
  name: "ReminderGrid",
  components: { DatePicker },
  props: {
    customerId: {
      type: Number,
      default: () => 0,
    },
    autoClose: {
      type: Boolean,
      default: () => false,
    },
    customerReminderLogs: {
      type: Array,
      default: () => [],
    },
    fromEdit: {
      type: Number,
      default: () => 0,
    },
    customerPrimaryPhone: {
      type: String,
      default: () => "",
    },
    tabClicked: {
      Type: Boolean,
      Default: false,
    },
  },
  watch: {
    tabClicked(newVal, oldVal) {
      if (newVal != oldVal && newVal) {
        this.watcherRefresh();
      }
    },
  },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      gridData: [],
      isEditing: false,
      reminders: [],
      selectedRow: {
        Id: 0,
        AssignedUserId: 0,
        ForeignId: 0,
        NotificationTypeId: 1,
        NotificationText: "",
        NotificationPayload: "",
        TriggerDate: this.addMinutes(new Date(), 5),
      },
      defaultSortDirection: "asc",
      sortIcon: "arrow-up",
      sortIconSize: "is-small",
      isStriped: true,
      isHoverable: true,
      isLoading: false,
      isEmpty: false,
      datepickerConfig: {
        start: new Date(),
      },
      userId: 0,
      canEditOthers: false,
    };
  },
  computed: mapState({
    customerReminders: (state) => state.notifications.customerReminders,
    sortedReminders() {
      return _orderby.orderBy(this.gridData, "TriggerDate", "desc");
    },
  }),
  unmounted() {
    // this.emptyTheObjectsOnUnmount();
  },
  async mounted() {
    this.isLoading = true;
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    this.userId = userInfo.userId;
    let arrPermission = userInfo.moduleFunctions
      .split(",")
      .map(function (item) {
        return parseInt(item, 10);
      });

    if (arrPermission.includes(9600)) {
      this.canEditOthers = true;
    } else {
      this.canEditOthers = false;
    }

    if (this.customerId > 0) {
      if (this.fromEdit != 1) {
        await this.$store.dispatch(
          notificationTypes.GET_CUSTOMERREMINDERS,
          this.customerId
        );
        this.gridData = deepCopy(this.customerReminders);
      }
      /* else
      {

          //this.gridData = deepCopy(this.customerReminderLogs);
      }*/
    }

    this.isLoading = false;

    this.$Emitter.on("updateReminderLogs", async (data) => {
      if (this.customerId == data.CustomerId) {
        await this.$store.dispatch(
          notificationTypes.GET_CUSTOMERREMINDERS,
          this.customerId
        );
        this.gridData = deepCopy(this.customerReminders);
      }
    });
  },
  methods: {
    async watcherRefresh() {
      await this.$store.dispatch(
        notificationTypes.GET_CUSTOMERREMINDERS,
        this.customerId
      );
      this.gridData = deepCopy(this.customerReminders);
    },
    emptyTheObjectsOnUnmount() {
      this.gridData = null;
      this.isEditing = null;
      this.reminders = null;
      this.selectedRow = null;
      this.defaultSortDirection = null;
      this.sortIcon = null;
      this.sortIconSize = null;
      this.isStriped = null;
      this.isHoverable = null;
      this.isLoading = null;
      this.isEmpty = null;
      this.datepickerConfig = null;
      this.userId = null;
      this.canEditOthers = null;
    },
    addMinutes(date, minutes) {
      return new Date(date.getTime() + minutes * 60000);
    },
    async editRow(val) {
      this.isEditing = true;
      this.selectedRow.Id = val.Id;
      this.selectedRow.AssignedUserId = val.AssignedUserId;
      this.selectedRow.ForeignId = this.customerId;
      this.selectedRow.NotificationText = val.NotificationText;
      this.selectedRow.TriggerDate = new Date(val.TriggerDate);
    },
    async deleteRow(val) {
      this.reminders = this.sortedReminders;
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then(async (dialog) => {
          if (dialog.value) {
            let rowIndex = this.reminders.indexOf(val);
            let rowIndex2 = this.gridData.indexOf(val);
            let err, result;

            [err, result] = await to(api.deleteNotification(val.Id));
            if (result) {
              this.reminders.splice(rowIndex, 1);
              this.$store
                .dispatch(
                  notificationTypes.MODIFY_CUSTOMERREMINDERS,
                  this.reminders
                )
                .catch();
              this.gridData.splice(rowIndex2, 1);

              await this.$store.dispatch(globalTypes.GET_NOTIFICATIONS, {
                RemoveAutoCallReminders: this.checkAuth(8004),
              });

              this.$swal("Deleted!", result.Message, "success");
            } else {
              let errMsg = this.getApiErrorMessage(err);
              this.$swal("Error!", errMsg, "error");
            }
          }
        });
    },
    async getCustomerPrimaryPhone() {
      let rtn = "";
      if (!this.customerPrimaryPhone || this.customerPrimaryPhone != "") {
        let err, result;
        [err, result] = await this.$store.dispatch(
          globalTypes.GET_CUSTOMER_PRIMARY_PHONE,
          { Id: this.customerId }
        );

        if (result && result.Data) {
          rtn = result.Data;
        }
      } else {
        rtn = this.customerPrimaryPhone;
      }

      return rtn;
    },
    async saveReminder() {
      if (this.selectedRow.NotificationText.trim() == "") {
        this.$swal("Reminder Text Required!", "", "warning");
        return;
      } else if (this.selectedRow.TriggerDate < new Date()) {
        this.$swal(
          "Reminder date and time should be in the future.",
          "",
          "warning"
        );
        return;
      }
      if (this.checkAuth(8004)) {
        let phone = await this.getCustomerPrimaryPhone();
        if (phone != "") {
          this.selectedRow.NotificationPayload = phone;
        } else {
          this.$swal(
            "Error!",
            "Customer hasn't any phone number. Phone required to create reminder.",
            "error"
          );
          return;
        }
      }

      this.reminders = this.sortedReminders;
      let err, result;

      this.selectedRow.TriggerDate = new Date(
        this.selectedRow.TriggerDate
      ).toISOString();
      if (this.selectedRow.Id == 0) {
        let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        this.selectedRow.ForeignId = this.customerId;
        this.selectedRow.AssignedUserId = userInfo.userId;

        [err, result] = await to(api.insertNotification(this.selectedRow));
        if (result) {
          if (this.autoClose != true) {
            this.$swal("Success!", result.Message, "success");
          }
          this.reminders.unshift(result.Data);
          this.gridData.unshift(result.Data);
          //this.reminders = _.orderBy(this.reminders, "TriggerDate", "desc");
          this.$store
            .dispatch(
              notificationTypes.MODIFY_CUSTOMERREMINDERS,
              this.reminders
            )
            .catch();

          await this.$store.dispatch(globalTypes.GET_NOTIFICATIONS, {
            RemoveAutoCallReminders: this.checkAuth(8004),
          });
        } else {
          let errMsg = this.getApiErrorMessage(err);
          this.$swal("Error!", errMsg, "error");
        }
      } else {
        [err, result] = await to(api.updateNotification(this.selectedRow));
        if (result) {
          this.$swal("Success!", result.Message, "success");

          let itemIndex = this.reminders
            .map((x) => x.Id)
            .indexOf(this.selectedRow.Id);
          if (itemIndex >= 0) {
            this.reminders[itemIndex] = result.Data;
            this.reminders.push();
            this.$store
              .dispatch(
                notificationTypes.MODIFY_CUSTOMERREMINDERS,
                this.reminders
              )
              .catch();
          }
          let itemIndex2 = this.gridData
            .map((x) => x.Id)
            .indexOf(this.selectedRow.Id);
          if (itemIndex2 >= 0) {
            this.gridData[itemIndex2] = result.Data;
            this.gridData.push();
          }

          await this.$store.dispatch(globalTypes.GET_NOTIFICATIONS, {
            RemoveAutoCallReminders: this.checkAuth(8004),
          });
        } else {
          let errMsg = this.getApiErrorMessage(err);
          this.$swal("Error!", errMsg, "error");
        }
      }

      if (this.autoClose == true) {
        this.$emit("closeReminderPanel");
      }
      this.cancel();
    },
    cancel() {
      this.isEditing = false;
      this.selectedRow.Id = 0;
      this.selectedRow.ForeignId = 0;
      this.selectedRow.NotificationText = "";
      this.selectedRow.TriggerDate = new Date();
    },
  },
};
</script>
