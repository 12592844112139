<template>
  <div class="col-md-12 newPanelWrapper noMargin">
    <div style="margin-top: -10px">
      <label style="font-size: 15px">Docusign Sending Options</label>
    </div>
    <div>
      <div class="closeButton" v-on:click.prevent.stop="closePanel">
        <i class="fas fa-window-close"></i>
      </div>
    </div>
    <hr />
    <div class="col-md-12 pl-2 form-check form-switch">
      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="radio"
          name="inlineRadioOptions"
          value="email"
          v-model="selectedOption"
        />
        <label class="form-check-label" for="inlineRadio1">Email</label>
      </div>
      <div class="form-check form-check-inline ml-4">
        <input
          class="form-check-input"
          type="radio"
          name="inlineRadioOptions"
          value="sms"
          v-model="selectedOption"
        />
        <label class="form-check-label" for="inlineRadio2">SMS</label>
      </div>
      <div class="form-check form-check-inline ml-4">
        <input
          class="form-check-input"
          type="radio"
          name="inlineRadioOptions"
          value="both"
          v-model="selectedOption"
        />
        <label class="form-check-label" for="inlineRadio3">Both</label>
      </div>
    </div>
    <div class="col-md-12 mt-4">
      <button
        @click="submitOption()"
        type="button"
        style="width: 100%"
        class="btn btn-success waves-effect waves-light"
      >
        Submit
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "DocusignSendingOptionsPopup",
  components: {},
  data() {
    return {
      selectedOption: "email",
    };
  },
  async mounted() {},
  methods: {
    submitOption() {
      this.$emit("closeSendingOptionsPopup", this.selectedOption);
    },
    closePanel() {
      this.$emit("closeSendingOptionsPopup");
    },
  },
};
</script>

<style></style>
