<template>
  <div class="col-md-12">
    <ReportSummary
      :exportData="reportData"
      :reportName="'PaymentsAndFeesReport'"
      :recordCount="recordCount"
      @updateLoadingStatus="reportLoading = $event"
      @setPayload="setPayload"
      @clearReport="clearReport"
      @setPagination="setPagination"
      :loadingSpinner="loadingSpinner"
    />

    <div
      class="datagrid-table gcsTabsTableScroll report-wrapper"
      :style="'max-height: ' + reportsPanelHeight + 'px; overflow-y:auto;'"
    >
      <o-table
        :data="filteredPaymentsAndFeesReport"
        :paginated="isPaginated && filteredPaymentsAndFeesReport.length > 0"
        :per-page="perPage"
        :pagination-simple="isPaginationSimple"
        :pagination-position="paginationPosition"
        :sticky-header="true"
      >
        <o-table-column
          field="CustomerId"
          label="Customer ID"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CustomerIdFilter"
                placeholder=""
                v-myMask="{
                  alias: 'numeric',
                  rightAlign: false,
                }"
              />
            </div>
          </template>

          <template v-slot="props">
            <span
              @click="
                viewCustomer(props.row.CustomerId, props.row.CustomerName)
              "
              :class="
                getTagReportHyperLinkClass(
                  props.row.CustomerId && props.row.CustomerName
                )
              "
            >
              <strong v-if="props.row.IsSummary"
                >{{ props.row.CustomerId }}
              </strong>
              <span v-if="!props.row.IsSummary">
                {{ props.row.CustomerId }}
              </span>
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="CustomerName"
          label="Customer Name"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CustomerNameFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.CustomerName }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.CustomerName }}
            </span>
          </template>
        </o-table-column>

        <o-table-column field="OfficeName" label="Office" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="OfficeNameFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.OfficeName }}
          </template>
        </o-table-column>

        <o-table-column field="State" label="State" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="StateFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.State }}
          </template>
        </o-table-column>

        <o-table-column
          field="CreditorId"
          label="Creditor ID"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CreditorIdFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.CreditorId }}
          </template>
        </o-table-column>

        <o-table-column field="Creditor" label="Creditor" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CreditorFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.Creditor }}
          </template>
        </o-table-column>

        <o-table-column
          field="CreditorAccount"
          label="Account Number"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CreditorAccountFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.CreditorAccount }}
          </template>
        </o-table-column>

        <o-table-column
          field="NegotiatorName"
          label="Negotiations Agent"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="NegotiatorNameFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.NegotiatorName }}
          </template>
        </o-table-column>

        <o-table-column
          field="TransactionType"
          label="Tran Type"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="TransactionTypeFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.TransactionType }}
          </template>
        </o-table-column>

        <o-table-column
          field="TransactionAmount"
          label="Tran Amount"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="TransactionAmountFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.TransactionAmount }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatMoney(props.row.TransactionAmount) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="TransactionDate"
          label="Tran Date"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="TransactionDateFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ formatShortDate(props.row.TransactionDate) }}
          </template>
        </o-table-column>

        <o-table-column
          field="TransactionStatus"
          label="Tran Status"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="TransactionStatusFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.TransactionStatus }}
          </template>
        </o-table-column>

        <o-table-column
          field="ClearedDate"
          label="Cleared Date"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="ClearedDateFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ formatShortDate(props.row.ClearedDate) }}
          </template>
        </o-table-column>

        <o-table-column
          field="CreditorBalance"
          label="Creditor Balance"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CreditorBalanceFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.CreditorBalance }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatMoney(props.row.CreditorBalance) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="SettlementDate"
          label="Settled Date"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="SettlementDateFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ formatShortDate(props.row.SettlementDate) }}
          </template>
        </o-table-column>

        <o-table-column
          field="SettlementAmount"
          label="Settlement Amount"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="SettlementAmountFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.SettlementAmount }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatMoney(props.row.SettlementAmount) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="FeeAmount"
          label="Fee Amount"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="FeeAmountFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.FeeAmount }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatMoney(props.row.FeeAmount) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="PaymentCount"
          label="Duration of Settlement Payments"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="PaymentCountFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.PaymentCount }}
          </template>
        </o-table-column>

        <o-table-column
          field="FeeCount"
          label="Duration of Settlement Fees"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="FeeCountFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.FeeCount }}
          </template>
        </o-table-column>

        <o-table-column
          field="PaymentsCleared"
          label="Cleared Pmt Amt"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="PaymentsClearedFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.PaymentsCleared }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{
                props.row.PaymentsCleared == null
                  ? formatMoney(0)
                  : formatMoney(props.row.PaymentsCleared)
              }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="FeesCleared"
          label="Cleared Fee Amt"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="FeesClearedFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.FeesCleared }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{
                props.row.FeesCleared == null
                  ? formatMoney(0)
                  : formatMoney(props.row.FeesCleared)
              }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="FeesRemain"
          label="Remaining Fee Amt"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="FeesRemainFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.FeesRemain }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{
                props.row.FeesRemain == null
                  ? formatMoney(0)
                  : formatMoney(props.row.FeesRemain)
              }}
            </span>
          </template>
        </o-table-column>
        <o-table-column
          v-if="canSeePaymentPlanner"
          field="PaymentPlanner"
          label="Payment Planner"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="PaymentPlannerFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            <span v-if="!props.row.IsSummary">
              {{ getPaymentPlannerValue(props.row.SettlementDate) }}
            </span>
          </template>
        </o-table-column>
        <o-table-column
          field=""
          label="View Customer"
          position="centered"
          v-slot="props"
        >
          <button
            @click="viewCustomer(props.row.CustomerId, props.row.CustomerName)"
            class="btn btn-info waves-effect waves-light ml-3"
            v-if="!props.row.IsSummary"
          >
            <i class="far fa-address-card"></i>
          </button>
        </o-table-column>
      </o-table>
      <section
        v-if="filteredPaymentsAndFeesReport.length == 0"
        class="section"
        style="text-align: center"
      >
        <div class="content has-text-grey has-text-centered mb-4">
          <p class="noMargin">
            <i
              style="font-size: 24px; color: #939393"
              class="ri-question-line"
            ></i>
          </p>
          <p class="noMargin">Nothing here.</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import ReportSummary from "./ReportSummary.vue";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import deepCopy from "@/helpers/deepCopy";
export default {
  name: "PaymentsAndFeesReport",
  components: { ReportSummary },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      customerPrefix: null,
      reportsPanelHeight: 300,
      loadingSpinner: false,
      isPaginationSimple: false,
      isPaginated: true,
      paginationPosition: "both",
      currentPage: 1,
      perPage: 200,
      recordCount: 0,
      reportLoading: false,
      reportData: [],
      canSeePaymentPlanner: false,
      paymentsAndFeesReportInitSummary: {
        CustomerId: "",
        CustomerName: "",
        OfficeName: "",
        State: "",
        Creditor: "",
        CreditorAccount: "",
        TransactionType: "",
        TransactionAmount: "",
        TransactionDate: "",
        TransactionStatus: "",
        CreditorBalance: "",
        SettlementDate: "",
        SettlementAmount: "",
        FeeAmount: "",
        PaymentCount: "",
        FeeCount: "",
        PaymentsCleared: "",
        FeesCleared: "",
        FeesRemain: "",
        ClearedDate: "",
        CleareNegotiatorNamedDate: "",
        CreditorId: "",
      },
      NegotiatorNameFilter: "",
      CustomerIdFilter: "",
      CustomerNameFilter: "",
      OfficeNameFilter: "",
      StateFilter: "",
      CreditorFilter: "",
      CreditorAccountFilter: "",
      TransactionTypeFilter: "",
      TransactionAmountFilter: "",
      TransactionDateFilter: "",
      TransactionStatusFilter: "",
      CreditorBalanceFilter: "",
      SettlementDateFilter: "",
      SettlementAmountFilter: "",
      FeeAmountFilter: "",
      PaymentCountFilter: "",
      FeeCountFilter: "",
      PaymentsClearedFilter: "",
      FeesClearedFilter: "",
      FeesRemainFilter: "",
      PaymentPlannerFilter: "",
      ClearedDateFilter: "",
      CreditorIdFilter: "",
    };
  },
  computed: mapState({
    paymentsAndFeesList: (state) => state.reports.paymentsAndFeesList,

    filteredPaymentsAndFeesReport() {
      let rtn = [];
      let data = deepCopy(this.paymentsAndFeesList);
      for (let item of data) {
        item["PaymentPlanner"] = this.getPaymentPlannerValue(
          item["SettlementDate"]
        );
      }
      let tmpList =
        data.length > 0
          ? data.filter((item) => {
              return (
                this.filterString(item.CustomerId, this.CustomerIdFilter) &&
                this.filterString(item.CreditorId, this.CreditorIdFilter) &&
                this.filterString(item.CustomerName, this.CustomerNameFilter) &&
                this.filterString(
                  item.NegotiatorName,
                  this.NegotiatorNameFilter
                ) &&
                this.filterString(item.OfficeName, this.OfficeNameFilter) &&
                this.filterString(item.State, this.StateFilter) &&
                this.filterString(item.Creditor, this.CreditorFilter) &&
                this.filterString(
                  item.CreditorAccount,
                  this.CreditorAccountFilter
                ) &&
                this.filterString(
                  item.TransactionType,
                  this.TransactionTypeFilter
                ) &&
                this.filterString(
                  item.TransactionAmount,
                  this.TransactionAmountFilter
                ) &&
                new Date(item.TransactionDate)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .indexOf(this.TransactionDateFilter) > -1 &&
                new Date(item.ClearedDate)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .indexOf(this.ClearedDateFilter) > -1 &&
                this.filterString(
                  item.TransactionStatus,
                  this.TransactionStatusFilter
                ) &&
                this.filterString(
                  item.CreditorBalance,
                  this.CreditorBalanceFilter
                ) &&
                new Date(item.SettlementDate)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .indexOf(this.SettlementDateFilter) > -1 &&
                this.filterString(
                  item.SettlementAmount,
                  this.SettlementAmountFilter
                ) &&
                this.filterString(item.FeeAmount, this.FeeAmountFilter) &&
                this.filterString(item.PaymentCount, this.PaymentCountFilter) &&
                this.filterString(item.FeeCount, this.FeeCountFilter) &&
                this.filterString(
                  item.PaymentsCleared,
                  this.PaymentsClearedFilter
                ) &&
                this.filterString(item.FeesCleared, this.FeesClearedFilter) &&
                this.filterString(item.FeesRemain, this.FeesRemainFilter) &&
                this.filterString(
                  item.PaymentPlanner,
                  this.PaymentPlannerFilter
                )
              );
            })
          : [];

      if (tmpList.length == 0) {
        this.reportData = [];
        return rtn;
      }
      let totalCustomer = tmpList.length.toString();

      this.paymentsAndFeesReportInitSummary.CustomerId =
        "Total: " + totalCustomer;
      this.recordCount = tmpList.length;
      this.paymentsAndFeesReportInitSummary.IsSummary = true;

      rtn.push(...tmpList);
      rtn.push(this.paymentsAndFeesReportInitSummary);
      this.reportData = rtn;

      return rtn;
    },
  }),
  async mounted() {
    this.reportsPanelHeight = this.setReportGridHeight(
      window.innerHeight,
      document.getElementById("reportsSummary")
    );
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

    if (
      this.userInfo != null &&
      this.userInfo.customerIdPrefix != null &&
      this.userInfo.customerIdPrefix.length > 0
    ) {
      this.customerPrefix = this.userInfo.customerIdPrefix;
    }
    if (this.userInfo != null) {
      let arrPermission = this.userInfo.moduleFunctions
        .split(",")
        .map(function (item) {
          return parseInt(item, 10);
        });
      if (arrPermission.includes(55015)) {
        this.canSeePaymentPlanner = true;
      }
    }
    await this.sleep(1000);
    this.fixStickyHeaders();
  },
  methods: {
    getPaymentPlannerValue(settDate) {
      let rtn = "No";
      let initialDate = "2024-01-12";

      if (
        settDate &&
        Date.parse(settDate.toString()) >= Date.parse(initialDate)
      ) {
        rtn = "Yes";
      }

      return rtn;
    },
    addPrefixToCustomerId(customerId) {
      let rtn = customerId.toString();
      if (this.customerPrefix != null) {
        rtn = this.customerPrefix + rtn;
      }
      return rtn;
    },
    setPagination(data) {
      this.isPaginated = data;
    },
    async setPayload(value) {
      this.loadingSpinner = true;
      await this.$store.dispatch(types.GET_PAYMENTS_AND_FEES_REPORT, value);
      this.loadingSpinner = false;

      await this.sleep(500);
      this.fixStickyHeaders();
    },
    clearReport() {
      this.$store.dispatch(types.RESET_REPORTS);
      this.reportData = [];
    },
    async viewCustomer(id, name) {
      this.$Emitter.emit("viewCustomer", {
        customerId: id.replace(/\D/g, ""),
        customerName: name,
      });
    },
  },
};
</script>

<style scoped></style>
