import types from "./types";
import api from "@/services/admin/api";
import to from "@/helpers/to";

export default {
  state: {
    taskTypeList: [],
    subTaskTypeList: [],
    quickEmails: [],
    triggerTypeList: [],
  },
  actions: {
    GET_SPEECH_TEXT: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.getSpeechCoicebySpeechText(payload));
      return [err, result];
    },

    GET_CSR_TASK_TRIGGER_TYPE: async ({ state, commit }) => {
      if (state.triggerTypeList.length > 0) return;
      let err, result;

      [err, result] = await to(api.getCsrTaskTriggerType());

      if (result) {
        commit(types.SET_CSR_TASK_TRIGGER_TYPE, result.Data);
      }
    },

    GET_QUICK_EMAILS: async ({ commit }) => {
      let err, result;

      [err, result] = await to(api.getQuickEmails());

      if (result) {
        commit(types.SET_QUICK_EMAILS, result.Data);
      }
    },

    GET_TASK_TYPES: async ({ commit }) => {
      let err, result;

      [err, result] = await to(api.getCsrTaskTypes());

      if (result) {
        commit(types.SET_TASK_TYPE, result.Data);
      }
    },
    GET_TASK_TYPES_Simplified: async ({ commit }) => {
      let err, result;

      [err, result] = await to(api.getCsrTaskTypesSimplified());

      if (result) {
        commit(types.SET_TASK_TYPE_Simplified, result.Data);
      }
    },
    INSERT_TASK_TYPE: async ({ commit }, payload) => {
      return await to(api.saveCsrTaskType(payload));
    },
    UPDATE_TASK_TYPE: async ({ commit }, payload) => {
      let result = await to(api.updateCsrTaskType(payload));
      return result;
    },
  },
  mutations: {
    SET_TASK_TYPE: (state, payload) => {
      state.taskTypeList = payload;
    },
    SET_TASK_TYPE_Simplified: (state, payload) => {
      state.subTaskTypeList = payload;
    },
    SET_QUICK_EMAILS: (state, payload) => {
      state.quickEmails = payload;
    },
    SET_CSR_TASK_TRIGGER_TYPE: (state, payload) => {
      state.triggerTypeList = payload;
    },
  },
};
