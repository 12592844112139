<template>
  <Popover>
    <template v-slot:header><div class="container">VM Drop</div></template>
    <template v-slot:body>
      <div class="contact-list">
        <p
          v-for="item in availableVmDrops"
          :key="item.VoicemailTemplateId"
          class="contact"
          @click="handleVmDrop(item)"
        >
          {{ item.VoicemailTemplateName }}
        </p>
      </div>
    </template>
  </Popover>
</template>

<script>
import Popover from "../../Popover";
import { mapState } from "vuex";
export default {
  components: {
    Popover,
  },
  emits: ["transferToVmDrop"],
  computed: {
    ...mapState({
      availableVmDrops: (state) => state.phoneManagement.availableVmDrops,
    }),
  },
  methods: {
    handleVmDrop(item) {
      this.$emit("transferToVmDrop", item);
    },
  },
};
</script>
