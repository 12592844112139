<template>
  <div class="col-md-12">
    <ReportSummary
      :exportData="reportData"
      :reportName="'FirstPremierBankScrubReport'"
      :reportClass="'ScrubReports'"
      :recordCount="recordCount"
      @updateLoadingStatus="reportLoading = $event"
      @setPayload="setPayload"
      @clearReport="clearReport"
      @setPagination="setPagination"
      :loadingSpinner="loadingSpinner"
    />
    <div
      class="datagrid-table gcsTabsTableScroll report-wrapper"
      :style="'max-height: ' + reportsPanelHeight + 'px; overflow-y:auto;'"
    >
      <o-table
        :data="filteredFirstPremierBankScrubReport"
        :paginated="
          isPaginated && filteredFirstPremierBankScrubReport.length > 0
        "
        :per-page="perPage"
        :pagination-simple="isPaginationSimple"
        :pagination-position="paginationPosition"
        :sticky-header="true"
      >
        <o-table-column
          field="FirstName"
          label="Primary First Name"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="FirstNameFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.FirstName }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.FirstName }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="LastName"
          label="Primary Last Name"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="LastNameFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.LastName }}
          </template>
        </o-table-column>

        <o-table-column
          field="Creditor.AcctN"
          label="Creditor Account Number"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="AccountNumberFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.Creditor == null ? "" : props.row.Creditor.AcctN }}
          </template>
        </o-table-column>

        <o-table-column field="Ssn" label="Primary SSN" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="SsnFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.Ssn }}
          </template>
        </o-table-column>

        <o-table-column
          field="Address.ShortCode"
          label="State"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="ShortCodeFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.Address == null ? "" : props.row.Address.ShortCode }}
          </template>
        </o-table-column>

        <o-table-column
          field="PolicyGroup"
          label="Policy Group"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="PolicyGroupFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.PolicyGroup }}
          </template>
        </o-table-column>
      </o-table>
      <section
        v-if="filteredFirstPremierBankScrubReport.length == 0"
        class="section"
        style="text-align: center"
      >
        <div class="content has-text-grey has-text-centered mb-4">
          <p class="noMargin">
            <i
              style="font-size: 24px; color: #939393"
              class="ri-question-line"
            ></i>
          </p>
          <p class="noMargin">Nothing here.</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import ReportSummary from "./ReportSummary.vue";
import utilitiesMixin from "@/mixins/utilitiesMixin";

export default {
  name: "FirstPremierBankScrubReport",
  components: { ReportSummary },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      reportsPanelHeight: 300,
      loadingSpinner: false,
      isPaginationSimple: false,
      isPaginated: true,
      paginationPosition: "both",
      currentPage: 1,
      perPage: 200,
      recordCount: 0,
      reportLoading: false,
      reportData: [],

      firstPremierBankScrubReportInitSummary: {
        FirstName: "",
      },
      LastNameFilter: "",
      FirstNameFilter: "",
      AccountNumberFilter: "",
      SsnFilter: "",
      ShortCodeFilter: "",
      PolicyGroupFilter: "",
    };
  },
  computed: mapState({
    firstBankScrubList: (state) => state.reports.firstBankScrubList,

    filteredFirstPremierBankScrubReport() {
      let rtn = [];
      let tmpList =
        this.firstBankScrubList.length > 0
          ? this.firstBankScrubList.filter((item) => {
              return (
                this.filterString(item.LastName, this.LastNameFilter) &&
                this.filterString(item.FirstName, this.FirstNameFilter) &&
                this.filterString(item.PolicyGroup, this.PolicyGroupFilter) &&
                this.filterString(
                  item.Creditor == null ? "" : item.Creditor.AcctN,
                  this.AccountNumberFilter
                ) &&
                this.filterString(item.Ssn, this.SsnFilter) &&
                this.filterString(item.Address.ShortCode, this.ShortCodeFilter)
              );
            })
          : [];

      if (tmpList.length == 0) {
        this.reportData = [];
        return rtn;
      }
      let totalCustomer = tmpList.length.toString();

      this.firstPremierBankScrubReportInitSummary.FirstName =
        "Total: " + totalCustomer;
      this.recordCount = tmpList.length;
      this.firstPremierBankScrubReportInitSummary.IsSummary = true;

      rtn.push(...tmpList);
      rtn.push(this.firstPremierBankScrubReportInitSummary);
      this.reportData = rtn;

      return rtn;
    },
  }),
  async mounted() {
    this.reportsPanelHeight = this.setReportGridHeight(
      window.innerHeight,
      document.getElementById("reportsSummary")
    );

    await this.sleep(1000);
    this.fixStickyHeaders();
  },
  methods: {
    setPagination(data) {
      this.isPaginated = data;
    },
    async setPayload(value) {
      this.loadingSpinner = true;
      let err, result;
      [err, result] = await this.$store.dispatch(
        types.GET_DISCOVER_SCRUB_REPORT,
        value
      );
      if (result && result.Data) {
        let apiResult = result.Data;
        let firstBankFormattedData = [];
        if (result) {
          if (apiResult.length > 0) {
            for (let item of apiResult) {
              if (item.Ssn != "") {
                item.Ssn = await this.AES256_GCM_decrypt(item.Ssn);
              }
              if (item.CoCustomer != null && item.CoCustomer.Ssn != null) {
                item.CoCustomer.Ssn = await this.AES256_GCM_decrypt(
                  item.CoCustomer.Ssn
                );
              }
            }
            for (let item of apiResult) {
              if (item.Creditors) {
                for (let c of item.Creditors) {
                  firstBankFormattedData.push({
                    CustomerId: item.CustomerId,
                    FirstName: item.FirstName,
                    LastName: item.LastName,
                    Name: item.Name,
                    Ssn: item.Ssn,
                    Dob: item.Dob,
                    CoCustomer: {
                      FirstName:
                        item.CoCustomer == null
                          ? ""
                          : item.CoCustomer.FirstName,
                      LastName:
                        item.CoCustomer == null ? "" : item.CoCustomer.LastName,
                      Name: item.CoCustomer == null ? "" : item.CoCustomer.Name,
                      Ssn: item.CoCustomer == null ? "" : item.CoCustomer.Ssn,
                      Dob: item.CoCustomer == null ? "" : item.CoCustomer.Dob,
                    },
                    Address: {
                      Line1: item.Address == null ? "" : item.Address.Line1,
                      City: item.Address == null ? "" : item.Address.City,
                      ShortCode:
                        item.Address == null ? "" : item.Address.ShortCode,
                      Zip: item.Address == null ? "" : item.Address.Zip,
                    },
                    Creditor: {
                      Name: c.Name,
                      CurrName: c.CurrName,
                      AcctN: c.AcctN,
                      CurrAmt: c.CurrAmt,
                    },
                    PolicyGroup: item.PolicyGroup,
                  });
                }
              }
            }
            await this.$store.dispatch(
              types.MODIFY_FIRST_BANK_SCRUB_REPORT,
              firstBankFormattedData
            );
          }
        }
      }
      this.loadingSpinner = false;
      await this.sleep(500);
      this.fixStickyHeaders();
    },
    clearReport() {
      this.$store.dispatch(types.RESET_REPORTS);
      this.reportData = [];
    },
  },
};
</script>

<style scoped></style>
