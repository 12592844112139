import types from "./types";
import api from "@/services/admin/api";
import to from "@/helpers/to";

export default {
  state: {
    quickNoteList: [],
  },

  actions: {
    GET_QUICKNOTES: async ({ commit }) => {
      let err, result;

      [err, result] = await to(api.getQuickNotes());

      if (result) {
        commit(types.SET_QUICKNOTE, result.Data);
      }
    },

    POST_QUICKNOTE: async ({ commit }, payload) => {
      return await to(api.saveQuickNotes(payload));
    },
    PUT_QUICKNOTE: async ({ commit }, payload) => {
      let result = await to(api.updateQuickNotes(payload));
      return result;
    },
    DELETE_QUICKNOTE: async ({ commit }, payload) => {
      let result = await to(api.deleteQuickNotes(payload));
      return result;
    },
  },
  mutations: {
    SET_QUICKNOTE: (state, payload) => {
      state.quickNoteList = payload;
    },
  },
};
