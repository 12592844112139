<template>
  <div
    class="col-md-12 newPanelWrapper noPadding"
    style="
      background-color: #ffffff;
      z-index: 1000;
      padding-left: 0;
      padding-right: 0;
    "
  >
    <div class="closeButton" v-on:click.prevent.stop="closePanel">
      <i class="fas fa-window-close"></i>
    </div>

    <div class="spinnerBackground" v-if="loadingSpinner">
      <div class="col-md-12 spinnerIconPosition text-center">
        <i class="spinner-border avatar-lg text-primary m-2"></i>
      </div>
    </div>

    <perfect-scrollbar
      style="
        width: 100%;
        margin: 5px 0;
        padding: 20px;
        max-height: 70vh;
        overflow-x: hidden !important;
      "
    >
      <div class="form-group row">
        <div class="col-md-12 noMargin">
          <label class="generalLabel" for="name">Name</label>
          <input
            type="text"
            id="Name"
            v-model="creditorContact.Name"
            class="generalTextBox form-control"
            placeholder="Contact Name"
            :class="creditorContact.Name.trim().length == 0 ? 'has-error' : ''"
            @blur="creditorContact.Name.trim().length == 0 && suggestBoxBlur()"
            @keydown="openSuggestBox"
          />
          <div
            v-if="suggestBoxOpen"
            v-click-outside="closeSuggestBoxPopup"
            class="datagrid-table"
            style="position: absolute; top: 66px; left: 15px; z-index: 1000"
          >
            <SuggestBox
              :list="suggestCreditorNames"
              :searchParam="creditorContact.Name"
              @assignCreditorName="assignCreditorName"
            >
            </SuggestBox>
          </div>
        </div>
      </div>
      <div class="form-group row mt-2">
        <div class="col-md-12">
          <label class="generalLabel" for="Action">Phones</label>
          <CreditorPhoneGrid
            :Phones="creditorContact.CreditorContactPhones"
            :ContactId="creditorContact.Id"
            :ContactName="creditorContact.Name"
            @updatePhones="updatePhones"
            @closeParentForm="closeParentForm"
            style="boder-radius: 8px"
          ></CreditorPhoneGrid>
        </div>
      </div>
      <div class="form-group row mt-2">
        <div class="col-md-6 col-xs-12">
          <label class="generalLabel" for="Action">Fax</label>
          <input
            autocomplete="off"
            v-model="creditorContact.Fax"
            type="text"
            id="Fax"
            placeholder="Fax"
            v-myMask="{ mask: ['(999) 999-9999'] }"
            class="generalTextBox form-control"
          />
        </div>
        <div class="col-md-6 col-xs-12">
          <label class="generalLabel" for="Action">Fax 2</label>
          <input
            v-model="creditorContact.Fax2"
            type="text"
            placeholder="Fax 2"
            v-myMask="{ mask: ['(999) 999-9999'] }"
            class="generalTextBox form-control"
          />
        </div>
      </div>
      <div class="form-group row mt-2">
        <div class="col-md-6">
          <label class="generalLabel" for="Action">Email</label>
          <input
            autocomplete="new-email"
            v-model="creditorContact.Email"
            type="text"
            id="Email"
            class="generalTextBox form-control"
            placeholder="Email"
          />
        </div>
        <div class="col-md-6">
          <label class="generalLabel" for="Action">Email 2</label>
          <input
            autocomplete="new-email"
            v-model="creditorContact.Email2"
            type="text"
            class="generalTextBox form-control"
            placeholder="Email 2"
          />
        </div>
      </div>
      <div class="mt-2">
        <label class="generalLabel mt-1" for="Action">Notes</label>
        <textarea
          v-model="creditorContact.Notes"
          id="Notes"
          class="form-control textareaInput generalTextBox"
          rows="2"
          placeholder="Notes"
        ></textarea>
      </div>
    </perfect-scrollbar>
    <div class="form-group row mr-1 ml-1">
      <div class="col-md-12 col-xs-12">
        <div style="display: flex;">
     
        <button
          v-show-if-authorized="'6472'"
          type="button"
 
          class="btn btn-success flex-fill btn-md"
          :disabled="!validateForm()"
          @click="saveContact()"
        >
          Save
        </button>
        <button
          v-show-if-authorized="'6472'"
          v-if="
            creditorContact.CreditorContactPhones &&
            creditorContact.CreditorContactPhones.length > 0
          "
          type="button"
   
          class="btn btn-success flex-fill btn-md  ml-2"
          @click="saveAndCall()"
          :disabled="!validateForm()"
        >
          Save and Call
        </button>
      </div>
      </div>
      <div class="col-md-12 col-xs-12 mb-2" v-show-if-authorized="'4612'">
        <button
          v-if="creditorContact.Id != null"
          @click="deleteContact()"
          type="button"
          style="width: 100%"
          class="btn btn-danger"
        >
          Delete
        </button>
      </div>
      
    </div>
  </div>
</template>

<script>
import CreditorPhoneGrid from "@/components/Grids/CreditorPhoneGrid.vue";
import plivoTypes from "@/components/PlivoParts/types.js";
import globalTypes from "@/store/types.js";
import { mapState } from "vuex";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import SuggestBox from "@/components/SuggestBox.vue";

export default {
  name: "CreditorContactForm",
  mixins: [utilitiesMixin],
  components: {
    CreditorPhoneGrid,
    SuggestBox,
  },
  props: {
    contactData: {
      Type: Object,
      default: null,
    },
  },
  computed: mapState({
    negotiatorContacts: (state) => state.phoneManagement.negotiatorContacts,
    suggestCreditorNames: (state) => state.globals.suggestCreditorNames,
  }),
  data() {
    return {
      suggestBoxOpen: false,
      loadingSpinner: false,
      creditorContact: Object.assign({}, this.contactData),
      oldValue: "",
    };
  },
  async mounted() {
    await Promise.all([
      this.$store.dispatch(globalTypes.GET_PHONETYPES),
      this.$store.dispatch(globalTypes.GET_PRIORITYTYPES),
      this.$store.dispatch(plivoTypes.GET_CREDITOR_CONTACTS),
      this.$store.dispatch(globalTypes.GET_SUGGEST_CREDITOR_PATTERN),
    ]);

    this.oldValue = JSON.stringify(this.creditorContact);
  },
  methods: {
    //Suggest box methods
    openSuggestBox() {
      this.suggestBoxOpen = true;
    },
    closeSuggestBoxPopup() {
      this.suggestBoxOpen = false;
    },
    suggestBoxBlur() {
      if (
        !this.suggestCreditorNames.includes(this.creditorContact.Name) &&
        this.checkAuth(55004)
      ) {
        this.creditorContact.Name = "";
      }
    },
    assignCreditorName(value) {
      this.creditorContact.Name = value;
      this.suggestBoxOpen = false;
    },
    //

    refreshValue(event) {
      if (event.target.value && event.target.value != "") {
        this.creditorContact.Fax = event.target.value;
      }
    },

    closePanel(isRefresh) {
      this.$emit("closeCreditorContactForm", { RefreshList: isRefresh });
    },
    async saveContact() {
      this.loadingSpinner = true;

      if (JSON.stringify(this.creditorContact) == this.oldValue) {
        this.$swal("Warning!", "No Changes Detected!", "warning");
        this.loadingSpinner = false;
        return;
      }

      let regex =
        /^[a-zA-Z0-9._-]+@([a-zA-Z0-9.-]+.)+([.])+[a-zA-Z0-9.-]{2,4}$/;

      if (this.creditorContact.Email) {
        if (regex.test(this.creditorContact.Email) == false) {
          this.$swal("Warning!", "Email address is not valid!", "warning");
          this.loadingSpinner = false;
          return;
        }
      }
      if (this.creditorContact.Email2) {
        if (regex.test(this.creditorContact.Email2) == false) {
          this.$swal(
            "Warning!",
            "Second email address is not valid!",
            "warning"
          );
          this.loadingSpinner = false;
          return;
        }
      }

      let type = this.creditorContact.Id
        ? plivoTypes.UPDATE_CREDITOR_CONTACT
        : plivoTypes.INSERT_CREDITOR_CONTACT;
      let err, result;
      [err, result] = await this.$store.dispatch(type, this.creditorContact);
      if (result) {
        if (type == plivoTypes.INSERT_CREDITOR_CONTACT) {
          this.$swal("Success!", "Added successfully.", "success");
        } else {
          this.$swal("Success!", "Updated successfully.", "success");
        }
        await this.$store.dispatch(globalTypes.GET_ALL_CREDITOR_CONTACT, {
          ForceRefresh: true,
        });
        this.closePanel(true);
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
      this.loadingSpinner = false;
    },
    async saveAndCall() {
      this.loadingSpinner = true;

      this.saveContact();
      if (this.creditorContact.CreditorContactPhones.length > 0) {
        let phone = this.creditorContact.CreditorContactPhones[0].Phone;

        // contactId > 0 control in plivo inteface for only show-hide labels.
        let contactId =
          this.creditorContact.Id < 1 ? 1 : this.creditorContact.Id;

        this.$Emitter.emit("callCreditor", {
          PhoneNumber: phone,
          Id: contactId,
          Name: this.creditorContact.Name,
        });
      }
      this.loadingSpinner = false;
      this.closePanel(true);
    },
    async deleteContact() {
      await this.$swal({
        title: "",
        text: "Are you sure to delete this contact?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (dialog) => {
        if (dialog.value) {
          this.loadingSpinner = true;
          let err, result;
          [err, result] = await this.$store.dispatch(
            plivoTypes.DELETE_CREDITOR_CONTACT,
            this.contactData.Id
          );
          if (result) {
            let indx = this.negotiatorContacts.findIndex(
              (x) => x.Id == this.contactData.Id
            );
            if (indx > -1) {
              this.negotiatorContacts.splice(indx, 1);
            }

            this.$swal("Deleted!", result.Message, "success");
            this.closePanel();
          } else {
            let errMsg = this.getApiErrorMessage(err);
            this.$swal("Error!", errMsg, "error");
          }
        } else {
          this.$swal("Error!", "An error occured!", "error");
        }
        this.loadingSpinner = false;
      });
    },
    updatePhones(value) {
      this.creditorContact.CreditorContactPhones = value;
    },
    closeParentForm() {
      this.closePanel(false);
    },
    validateForm() {
      return (
        this.creditorContact.Name && this.creditorContact.Name.trim().length > 0
      );
    },
  },
};
</script>

<style>
.closeButton {
  position: absolute;
  top: -5px;
  right: 15px;
  z-index: 1000;
  font-size: 30px;
  cursor: pointer;
}
.closeButton:hover {
  color: #ff3860;
}

.spinnerIconPosition {
  z-index: 2;
  position: absolute;
  top: 270px;
}

.spinnerBackground {
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: #ffffffb0;
  position: absolute;
}
</style>
