import types from "./types";
import api from "@/services/admin/api";
import to from "@/helpers/to";

export default {
  state: {
    list: [],
  },
  actions: {
    GET_VMDropManagement: async ({ commit }) => {
      let err, result;

      [err, result] = await to(api.getVMTemplates());

      if (result) {
        commit(types.SET_VMDropManagement, result.Data);
      }
    },

    POST_VMDROP: async ({ commit }, payload) => {
      return await to(api.insertVMTemplate(payload));
    },
    PUT_VMDROP: async ({ commit }, payload) => {
      let result = await to(api.updateVMTemplate(payload));
      return result;
    },
    DELETE: async ({ commit }, roleId) => {
      let result = await to(api.deleteRole(roleId));
      return result;
    },
  },
  mutations: {
    SET_VMDropManagement: (state, payload) => {
      state.list = payload;
    },
  },
};
