<template>
  <div
    v-if="emailTemplateCopy"
    class="table-responsive gridHeight newPanelWrapper"
    style="box-shadow: 0 0 1px 1px #d1d1d1 !important; padding: 1px !important"
  >
    <form>
      <table
        class="datagrid-table mb-0"
        id="btn-editable-etg2"
        style="width: 100%; border: none !important; background-color: #ffffff"
      >
        <thead>
          <tr>
            <th>Status</th>
            <th>Schedule After(hour)</th>
            <th>Actions</th>
          </tr>

          <tr v-if="isNewRowActive">
            <th>
              <select
                v-model="newRow.StatusId"
                class="form-control generalSelectBox"
              >
                <option
                  v-for="item in statusList"
                  :value="item.Id"
                  :key="item.Id"
                >
                  {{ item.Name }}
                </option>
              </select>
            </th>
            <th>
              <div class="row">
                <div class="col-md-4 noPadding">
                  <input
                    autocomplete="off"
                    class="tabledit-input form-control generalTextBox"
                    id="newSchedule"
                    type="number"
                    name="col1"
                    min="1"
                    v-model="newRow.ScheduleAfter"
                  />
                </div>
                <div class="col-md-6 noPadding ml-1">
                  <label class="col-form-label">
                    {{ formatHoursToDay(newRow.ScheduleAfter) }}
                  </label>
                </div>
              </div>
            </th>
            <th>
              <button
                :disabled="myInvalid"
                type="button"
                class="btn"
                style="background-color: #24d15f"
                v-on:click.prevent="handleNewRowSave"
              >
                <i class="fas fa-save"></i>
              </button>
              <button
                :disabled="myInvalid"
                type="button"
                class="btn"
                style="background-color: rgb(237, 48, 48)"
                v-on:click.prevent="cancelNewRow"
              >
                <i class="mdi mdi-cancel"></i>
              </button>
            </th>
          </tr>
        </thead>

        <tbody v-if="emailTemplateCopy.Triggers">
          <tr v-for="item in emailTemplateCopy.Triggers" :key="item.Id">
            <td class="tabledit-view-mode">
              <span v-show="!item.Edit" class="tabledit-span">
                {{
                  statusList
                    .filter((x) => x.Id === item.StatusId)
                    .map((x) => x.Name)[0]
                }}
              </span>
              <select
                v-on:keydown.enter.prevent="resetEdit(null)"
                v-on:keydown.esc.prevent="resetEdit(null)"
                v-show="item.Edit"
                v-model="item.StatusId"
                class="form-control generalSelectBox"
              >
                <option
                  v-for="item in statusList"
                  :value="item.Id"
                  :key="item.Id"
                >
                  {{ item.Name }}
                </option>
              </select>
            </td>
            <td class="tabledit-view-mode">
              <div v-show="!item.Edit">
                <span class="tabledit-span tabledit-identifier">{{
                  formatHoursToDay(item.ScheduleAfter)
                }}</span>
              </div>
              <div v-show="item.Edit" class="row">
                <div class="col-md-4 noPadding">
                  <input
                    autocomplete="off"
                    class="generalTextBox form-control"
                    type="number"
                    name="col1"
                    v-model="item.ScheduleAfter"
                    style="display: none"
                    :class="{ 'has-errors': myInvalid }"
                    v-show="item.Edit"
                    min="0"
                  />
                </div>
                <div class="col-md-6 noPadding ml-1">
                  <label class="col-form-label">
                    {{ formatHoursToDay(item.ScheduleAfter) }}
                  </label>
                </div>
              </div>
            </td>

            <td style="white-space: nowrap; width: 1%">
              <button
                type="button"
                title="Edit"
                :disabled="myInvalid"
                class="btn"
                style="background-color: #24d171"
                v-on:click.prevent.stop="itemEdit(item)"
              >
                <i v-show="!item.Edit" class="ri-edit-line"></i>
                <i
                  @click="updateTrigger()"
                  v-show="item.Edit"
                  class="fe-check"
                ></i>
              </button>
              <button
                type="button"
                title="Delete"
                class="btn"
                style="background-color: #ed3030"
                :disabled="myInvalid"
                v-on:click.prevent.stop="() => handleDelete(item)"
              >
                <i class="fe-trash-2"></i>
              </button>
            </td>
          </tr>
          <tr
            v-if="!isNewRowActive"
            style="background-color: #ffffff !important"
          >
            <td colspan="4">
              <button
                type="button"
                title="Delete"
                class="addNewButton"
                :disabled="myInvalid"
                v-on:click.prevent.stop="() => startNewRow()"
              >
                <i style="font-size: 18px" class="fe-plus-circle"></i>
                Add New Trigger
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  </div>
</template>
<script>
import { mapState } from "vuex";
import globalTypes from "@/store/types";
import types from "./types";
import deepCopy from "../../../helpers/deepCopy";

const initRow = {
  Id: 0,
  StatusId: 0,
  ScheduleAfter: 0,
  Edit: false,
};
let newRowClicked = 0;
export default {
  name: "EmailTriggerGrid",
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapState({
      statusList: (state) => state.globals.customerStatuses,
    }),
  },
  async mounted() {
    await this.$store.dispatch(globalTypes.GET_STATUS_NAMES).catch((err) => {});

    this.emailTemplateCopy = deepCopy(this.value);
  },
  data() {
    return {
      isNewRowActive: false,
      newRow: Object.assign({}, initRow),
      emailTemplateCopy: null,
      myInvalid: false,
    };
  },

  methods: {
    updateTrigger() {
      this.$emit("updateSelectedRow", this.emailTemplateCopy);
    },

    itemEdit(item) {
      this.resetEdit(item);
      item.Edit = !item.Edit;
      this.isNewRowActive = false;
      if (item.ScheduleAfter == null) item.ScheduleAfter = 0;
    },
    resetEdit(obj) {
      if (this.myInvalid) return;

      for (let item of this.emailTemplateCopy.Triggers) {
        if (item !== obj) item.Edit = false;
      }
    },
    resetEnter({ type, target }) {
      this.resetEdit(null);
    },

    startNewRow() {
      if (this.myInvalid) return;
      this.resetEdit(null);
      this.isNewRowActive = true;
      if (this.statusList.length > 0)
        this.newRow.StatusId = this.statusList[0].Id;
      newRowClicked = 0;
    },
    handleNewRowSave() {
      this.resetEdit(null);
      this.isNewRowActive = false;
      let newId = -1;
      if (this.emailTemplateCopy.Triggers.length > 0) {
        let minId = Math.min(
          ...this.emailTemplateCopy.Triggers.map((x) => x.Id)
        );
        if (minId < 0) {
          newId = minId - 1;
        }
      }
      if (this.newRow.ScheduleAfter == null || this.newRow.ScheduleAfter < 0)
        this.newRow.ScheduleAfter = 0;
      this.newRow.Id = newId;
      this.emailTemplateCopy.Triggers.push(this.newRow);
      this.$emit("updateSelectedRow", this.emailTemplateCopy);
      this.newRow = Object.assign({}, initRow);
    },
    cancelNewRow() {
      this.newRow = Object.assign({}, initRow);
      this.isNewRowActive = false;
      this.myInvalid = false;
    },
    async handleDelete(item) {
      await this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then(async (dialog) => {
          if (dialog.value) {
            let result;

            if (item.Id > 0) {
              result = await this.$store
                .dispatch(types.DELETE_TRIGGER, item.Id)
                .catch((err) => {});
            } else {
              result = "success";
            }

            if (result) {
              let rowIndex = this.emailTemplateCopy.Triggers.findIndex(
                (x) => x.Id == item.Id
              );
              if (rowIndex > -1) {
                this.emailTemplateCopy.Triggers.splice(rowIndex, 1);
              }

              this.isNewRowActive = false;
              this.$emit("updateSelectedRow", this.emailTemplateCopy);
              this.$swal.fire("Deleted!", result.Message, "success");
            } else {
              let errMsg = "An Error Occured!";

              this.$swal.fire("Error!", errMsg, "error");
            }
          }
        });
    },
    formatHoursToDay(hours) {
      let day = Math.floor(hours / 24);
      let extra = hours - day * 24;

      let dayText = day > 1 ? day + " days" : day + " day";

      let hourText = extra + " hour";
      if (day == 0) {
        if (extra == 0) {
          hourText = "Immediately";
        } else if (extra > 1) {
          hourText += "s";
        }
      } else {
        if (extra == 0) {
          hourText = "";
        } else if (extra > 1) {
          hourText += "s";
        }
      }

      if (day == 0) dayText = "";

      return dayText + " " + hourText;
    },
  },
};
</script>
