<template>
  <div class="col-md-12">
    <ReportSummary
      :exportData="reportData"
      :reportName="'CSRTaskPerformanceReport'"
      :recordCount="recordCount"
      @updateLoadingStatus="reportLoading = $event"
      @setPayload="setPayload"
      @clearReport="clearReport"
      :loadingSpinner="loadingSpinner"
    />

    <div
      class="datagrid-table gcsTabsTableScroll report-wrapper"
      :style="'max-height: ' + reportsPanelHeight + 'px; overflow-y:auto;'"
    >
      <o-table :data="filteredCSRTaskPerformanceReport" :sticky-header="true">
        <o-table-column
          field="AgentName"
          label="Agent Name"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="agentNameFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.AgentName }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.AgentName }}
            </span>
          </template>
        </o-table-column>

        <o-table-column field="Task" label="Task" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="taskFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.Task }}
          </template>
        </o-table-column>

        <o-table-column field="Postponed" label="Postponed" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="postponedFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.Postponed }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.Postponed }}
            </span>
          </template>
        </o-table-column>
        <o-table-column field="Cancelled" label="Cancelled" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="cancelledFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.Cancelled }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.Cancelled }}
            </span>
          </template>
        </o-table-column>
        <o-table-column field="Completed" label="Completed" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="completedFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.Completed }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.Completed }}
            </span>
          </template>
        </o-table-column>
        <o-table-column
          field="TotalAttempts"
          label="Total Attempts"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="totalAttemptsFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.TotalAttempts }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.TotalAttempts }}
            </span>
          </template>
        </o-table-column>
      </o-table>
      <section
        v-if="filteredCSRTaskPerformanceReport.length == 0"
        class="section"
        style="text-align: center"
      >
        <div class="content has-text-grey has-text-centered mb-4">
          <p class="noMargin">
            <i
              style="font-size: 24px; color: #939393"
              class="ri-question-line"
            ></i>
          </p>
          <p class="noMargin">Nothing here.</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import ReportSummary from "./ReportSummary.vue";
import utilitiesMixin from "@/mixins/utilitiesMixin";

export default {
  name: "CSRTaskPerformanceReport",
  components: { ReportSummary },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      reportsPanelHeight: 300,
      loadingSpinner: false,
      recordCount: 0,
      reportLoading: false,
      reportData: [],

      CSRTaskPerformanceReportInitSummary: {
        AgentName: "",
        Task: "",
        Postponed: null,
        Cancelled: null,
        Completed: null,
        TotalAttempts: null,
      },

      agentNameFilter: "",
      taskFilter: "",
      postponedFilter: "",
      cancelledFilter: "",
      completedFilter: "",
      totalAttemptsFilter: "",
    };
  },
  computed: mapState({
    documentsCsrTaskPerformanceList: (state) =>
      state.reports.csrTaskPerformanceList,

    filteredCSRTaskPerformanceReport() {
      let rtn = [];

      let tmpList =
        this.documentsCsrTaskPerformanceList.length > 0
          ? this.documentsCsrTaskPerformanceList.filter((item) => {
              return (
                this.filterString(item.AgentName, this.agentNameFilter) &&
                this.filterString(item.Task, this.taskFilter) &&
                this.filterNumeric(item.Postponed, this.postponedFilter) &&
                this.filterNumeric(item.Cancelled, this.cancelledFilter) &&
                this.filterNumeric(item.Completed, this.completedFilter) &&
                this.filterNumeric(item.TotalAttempts, this.totalAttemptsFilter)
              );
            })
          : [];

      if (tmpList.length == 0) {
        this.reportData = [];
        return rtn;
      }
      let totalAgentName = tmpList.length.toString();
      this.recordCount = tmpList.length;

      let totalPostponed = 0;
      let totalCancelled = 0;
      let totalCompleted = 0;
      let totalAttempts = 0;

      for (let item of tmpList) {
        totalPostponed += item.Postponed;
        totalCancelled += item.Cancelled;
        totalCompleted += item.Completed;
        totalAttempts += item.TotalAttempts;
      }

      this.CSRTaskPerformanceReportInitSummary.AgentName =
        "Total: " + totalAgentName;
      this.CSRTaskPerformanceReportInitSummary.Postponed = totalPostponed;
      this.CSRTaskPerformanceReportInitSummary.Cancelled = totalCancelled;
      this.CSRTaskPerformanceReportInitSummary.Completed = totalCompleted;
      this.CSRTaskPerformanceReportInitSummary.TotalAttempts = totalAttempts;

      this.CSRTaskPerformanceReportInitSummary.IsSummary = true;

      rtn.push(...tmpList);
      rtn.push(this.CSRTaskPerformanceReportInitSummary);
      this.reportData = rtn;

      return rtn;
    },
  }),
  async mounted() {
    this.reportsPanelHeight = this.setReportGridHeight(
      window.innerHeight,
      document.getElementById("reportsSummary")
    );
    await this.sleep(1000);
    this.fixStickyHeaders();
  },
  methods: {
    async setPayload(payload) {
      this.loadingSpinner = true;
      await this.$store.dispatch(
        types.GET_CSR_TASK_PERFORMANCE_REPORT,
        payload
      );
      this.loadingSpinner = false;

      await this.sleep(500);
      this.fixStickyHeaders();
    },
    clearReport() {
      this.$store.dispatch(types.RESET_REPORTS);
      this.reportData = [];
    },
  },
};
</script>

<style scoped></style>
