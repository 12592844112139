<template>
  <div class="col-md-12">
    <div class="form-group row">
      <div class="col-md-12">
        <form class="newPanelWrapper">
          <div class="row mt-2">
            <div class="col-xl-2 col-lg-3 col-md-6 col-sm-12">
              <label class="generalLabel">Date</label>
              <DatePicker mode="date" :locale="'en-US'" v-model="date1">
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                    class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                    :value="inputValue"
                    v-on="inputEvents"
                    placeholder="Select Date"
                  />
                </template>
              </DatePicker>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-6 col-sm-12">
              <label class="generalLabel">Start Time</label>
              <DatePicker mode="time" :locale="'en-US'" v-model="startTime">
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                    placeholder="Start Time"
                    autocomplete="off"
                    class="form-control px-2 py-1 border rounded"
                    :value="inputValue"
                    v-on="inputEvents"
                  />
                </template>
              </DatePicker>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-6 col-sm-12">
              <label class="generalLabel">End Time</label>
              <DatePicker mode="time" :locale="'en-US'" v-model="endTime">
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                    placeholder="End Time"
                    autocomplete="off"
                    class="form-control px-2 py-1 border rounded"
                    :value="inputValue"
                    v-on="inputEvents"
                  />
                </template>
              </DatePicker>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-6 col-sm-12">
              <label class="generalLabel">Agent</label>
              <select
                v-model="agents"
                class="form-control px-2 py-1 border rounded"
                placeholder="Select Agent"
              >
                <option :value="null">Select Agent</option>
                <option
                  v-for="item in sortedAgents"
                  :key="item.Id"
                  :value="item.Id"
                >
                  {{ item.Name }}
                </option>
              </select>
            </div>

            <div class="col-xl-2 col-lg-3 col-md-6 col-sm-12">
              <label class="generalLabel">Metric</label>
              <div
                class="col-md-6 noPadding"
                style="display: flex; align-items: center"
              >
                <input
                  type="radio"
                  name="MetricRadio"
                  v-model="metric"
                  value="audio"
                  class="mr-1 ml-1"
                />

                <label class="generalLabel mt-1">Audio</label>
                <input
                  type="radio"
                  name="MetricRadio"
                  v-model="metric"
                  value="network"
                  class="mr-1 ml-2"
                />
                <label class="generalLabel mt-1">Network</label>
              </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-6 col-sm-12">
              <button
                type="button"
                class="generalButton btn-sm btn-primary"
                :disabled="agents == null || date1 == null || metric == ''"
                style="
                  width: 75%;
                  background-color: #3bafda;
                  color: #ffffff;
                  margin-top: 27px;
                "
                @click="filterReport()"
              >
                <i
                  v-if="loadingSpinner"
                  class="spinner-border spinner-border-sm mr-1"
                ></i>
                Filter
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div
      v-if="filteredCallMetricsReport.length > 0"
      class="col-xl-2 col-lg-2 col-md-12 col-sm-12 noPadding"
    >
      <span class="badge badge-success" style="padding: 10px 16px">
        Total Records: {{ filteredCallMetricsReport.length - 1 }}</span
      >
    </div>

    <div
      class="datagrid-table gcsTabsTableScroll report-wrapper"
      :style="'max-height: ' + reportsPanelHeight + 'px; overflow-y:auto;'"
    >
      <o-table :data="filteredCallMetricsReport" :sticky-header="true">
        <o-table-column field="Date" label="Date" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="DateFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary">{{ props.row.Date }} </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatGridDateCallM(props.row.Date) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column field="Level" label="Level" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="LevelFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary">{{ props.row.Level }} </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.Level }}
            </span>
          </template>
        </o-table-column>

        <o-table-column field="Type" label="Type" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="TypeFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.Type }}
          </template>
        </o-table-column>

        <o-table-column field="Value" label="Value" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="ValueFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary">{{ props.row.Value }} </strong>
            <span v-if="!props.row.IsSummary">
              {{ parseFloat(props.row.Value).toFixed(2) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="Description"
          label="Description"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="DescriptionFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.Description }}
          </template>
        </o-table-column>

        <o-table-column field="Stream" label="Stream" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="StreamFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.Stream }}
          </template>
        </o-table-column>
      </o-table>
      <section
        v-if="filteredCallMetricsReport.length == 0"
        class="section"
        style="text-align: center"
      >
        <div class="content has-text-grey has-text-centered mb-4">
          <p class="noMargin">
            <i
              style="font-size: 24px; color: #939393"
              class="ri-question-line"
            ></i>
          </p>
          <p class="noMargin">Nothing here.</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import { DatePicker } from "v-calendar";
import globalTypes from "@/store/types";
import _orderby from "lodash";

export default {
  name: "CallMetricsReport",
  components: { DatePicker },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      userSubOffices: [],
      loadingSpinner: false,
      reportsPanelHeight: 300,
      date1: null,
      startTime: new Date(2023, 1, 1, 9, 0, 0),
      endTime: new Date(2023, 1, 1, 17, 0, 0),
      agents: null,
      hasAccessOtherOffices: false,
      userOfficeId: null,
      metric: "",

      callMetricsReportInitSummary: {
        Date: "",
        Level: "",
        Type: "",
        Value: "",
        Description: "",
        Stream: "",
      },

      DateFilter: "",
      LevelFilter: "",
      TypeFilter: "",
      ValueFilter: "",
      DescriptionFilter: "",
      StreamFilter: "",
    };
  },
  computed: mapState({
    callMetricsList: (state) => state.reports.callMetricsList,
    allEmployees: (state) => state.globals.allEmployees,
    // filteredAgents() {
    //   return this.allEmployees.filter((x) => x.IsPhoneEnabled == true && this.hasAccessOtherOffices ? x : x.OfficeId == this.userOfficeId);
    // },

    sortedAgents() {
      let rtn = [];
      if (this.checkAuth(1454)) {
        rtn = this.allEmployees.filter((x) => x.IsPhoneEnabled == true);
      } else if (this.checkAuth(55011)) {
        rtn = this.userSubOffices
          ? this.allEmployees.filter(
              (x) =>
                this.userSubOffices.includes(x.Id) || x.Id == this.userOfficeId
            )
          : this.allEmployees.filter((x) => x.OfficeId == this.userOfficeId);
      } else {
        rtn = this.allEmployees.filter((x) => x.OfficeId == this.userOfficeId);
      }

      return _orderby.orderBy(rtn, "Name", "asc");
    },

    filteredCallMetricsReport() {
      let rtn = [];

      let tmpList =
        this.callMetricsList.length > 0
          ? this.callMetricsList.filter((item) => {
              return (
                new Date(item.Date)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .indexOf(this.DateFilter) > -1 &&
                this.filterString(item.Level, this.LevelFilter) &&
                this.filterString(item.Type, this.TypeFilter) &&
                this.filterString(item.Value, this.ValueFilter) &&
                this.filterString(item.Description, this.DescriptionFilter) &&
                this.filterString(item.Stream, this.StreamFilter)
              );
            })
          : [];

      if (tmpList.length == 0) {
        this.reportData = [];
        return rtn;
      }
      let totalCustomer = tmpList.length.toString();

      this.callMetricsReportInitSummary.Date = "Total: " + totalCustomer;
      this.recordCount = tmpList.length;
      this.callMetricsReportInitSummary.IsSummary = true;

      rtn.push(...tmpList);
      rtn.push(this.callMetricsReportInitSummary);
      this.reportData = rtn;

      return rtn;
    },
  }),

  async mounted() {
    await this.$store.dispatch(globalTypes.GET_ALL_ACTIVE_EMPLOYEES);

    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo != null && userInfo.moduleFunctions != null) {
      this.userOfficeId = userInfo.officeId;
      this.userSubOffices = userInfo.subOffices;
    }
    this.hasAccessOtherOffices = this.canSeeAllOffices();

    this.reportsPanelHeight = this.setReportGridHeight(
      window.innerHeight,
      document.getElementById("reportsSummary")
    );

    let retrievedData = sessionStorage.getItem(
      "reportFilters-" + "CallMetricsReport"
    );
    if (typeof retrievedData != undefined && retrievedData) {
      let data = JSON.parse(retrievedData);
      if (data) {
        this.agents = data.UserId;
        this.startTime = data.StartTime;
        this.endTime = data.EndTime;
        this.date1 = new Date(data.EndTime);
      }
    }
    await this.sleep(1000);
    this.fixStickyHeaders();
  },
  methods: {
    formatGridDateCallM(date) {
      if (date == null || date == "") {
        return "";
      }

      let utcDate = date.replace("T", " ");
      let formattedDate = new Date(utcDate).toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "numeric",
        minute: "2-digit",
      });

      return formattedDate;
    },

    async filterReport() {
      let date = new Date(this.date1);
      let start = new Date(this.startTime);
      let end = new Date(this.endTime);

      let hour1 = start.getHours();
      let hour2 = end.getHours();

      if (hour1 >= hour2) {
        this.$swal(
          "Warning!",
          "End time must be greater than start time",
          "warning"
        );
        return;
      }

      this.loadingSpinner = true;
      let payload = {
        UserId: this.agents,
        StartTime: new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          start.getHours(),
          start.getMinutes()
        ),
        EndTime: new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          end.getHours(),
          end.getMinutes()
        ),
        ReportType: this.metric,
      };

      sessionStorage.setItem(
        "reportFilters-" + "CallMetricsReport",
        JSON.stringify({
          UserId: this.agents,
          StartTime: new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            start.getHours(),
            start.getMinutes()
          ),
          EndTime: new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            end.getHours(),
            end.getMinutes()
          ),
          ReportType: this.metric,
        })
      );

      await this.$store.dispatch(types.GET_CALL_METRICS_REPORT, payload);

      this.loadingSpinner = false;

      await this.sleep(500);
      this.fixStickyHeaders();
    },
  },
};
</script>

<style scoped></style>
