<template>
  <div class="col-md-12">
    <ReportSummary
      :exportData="reportData"
      :reportName="'PRAScrubReport'"
      :reportClass="'ScrubReports'"
      :recordCount="recordCount"
      @updateLoadingStatus="reportLoading = $event"
      @setPayload="setPayload"
      @clearReport="clearReport"
      @setPagination="setPagination"
      :loadingSpinner="loadingSpinner"
    />
    <div
      class="datagrid-table gcsTabsTableScroll report-wrapper"
      :style="'max-height: ' + reportsPanelHeight + 'px; overflow-y:auto;'"
    >
      <o-table
        :data="filteredPRAScrubReport"
        :paginated="isPaginated && filteredPRAScrubReport.length > 0"
        :per-page="perPage"
        :pagination-simple="isPaginationSimple"
        :pagination-position="paginationPosition"
        :sticky-header="true"
      >
        <o-table-column
          field="CustomerId"
          label="Client ID"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CustomerIdFilter"
                placeholder=""
                v-myMask="{
                  alias: 'numeric',
                  rightAlign: false,
                }"
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.CustomerId }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ addPrefixToCustomerId(props.row.CustomerId) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column field="Ssn" label="Primary SSN" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="SsnFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.Ssn }}
          </template>
        </o-table-column>

        <o-table-column
          field="Creditor.AcctN"
          label="Creditor Account Number"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="AccountNumberFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.Creditor == null ? "" : props.row.Creditor.AcctN }}
          </template>
        </o-table-column>

        <o-table-column
          field="PolicyGroup"
          label="Policy Group"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="PolicyGroupFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.PolicyGroup }}
          </template>
        </o-table-column>
      </o-table>
      <section
        v-if="filteredPRAScrubReport.length == 0"
        class="section"
        style="text-align: center"
      >
        <div class="content has-text-grey has-text-centered mb-4">
          <p class="noMargin">
            <i
              style="font-size: 24px; color: #939393"
              class="ri-question-line"
            ></i>
          </p>
          <p class="noMargin">Nothing here.</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import ReportSummary from "./ReportSummary.vue";
import utilitiesMixin from "@/mixins/utilitiesMixin";

export default {
  name: "PRAScrubReport",
  components: { ReportSummary },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      customerPrefix: null,
      reportsPanelHeight: 300,
      loadingSpinner: false,
      isPaginationSimple: false,
      isPaginated: true,
      paginationPosition: "both",
      currentPage: 1,
      perPage: 200,
      recordCount: 0,
      reportLoading: false,
      reportData: [],

      scrubListReportforPRAInitSummary: {
        CustomerId: "",
      },
      CustomerIdFilter: "",
      SsnFilter: "",
      AccountNumberFilter: "",
      PolicyGroupFilter: "",
    };
  },
  computed: mapState({
    scrubPraList: (state) => state.reports.scrubPraList,

    filteredPRAScrubReport() {
      let rtn = [];
      let tmpList =
        this.scrubPraList.length > 0
          ? this.scrubPraList.filter((item) => {
              return (
                this.filterString(item.CustomerId, this.CustomerIdFilter) &&
                this.filterString(item.PolicyGroup, this.PolicyGroupFilter) &&
                this.filterString(
                  item.Creditor.AcctN,
                  this.AccountNumberFilter
                ) &&
                this.filterString(item.Ssn, this.SsnFilter)
              );
            })
          : [];

      if (tmpList.length == 0) {
        this.reportData = [];
        return rtn;
      }
      let totalCustomer = tmpList.length.toString();

      this.scrubListReportforPRAInitSummary.CustomerId =
        "Total: " + totalCustomer;
      this.recordCount = tmpList.length;

      this.scrubListReportforPRAInitSummary.IsSummary = true;

      rtn.push(...tmpList);
      rtn.push(this.scrubListReportforPRAInitSummary);
      this.reportData = rtn;

      return rtn;
    },
  }),
  async mounted() {
    this.reportsPanelHeight = this.setReportGridHeight(
      window.innerHeight,
      document.getElementById("reportsSummary")
    );

    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

    if (
      userInfo != null &&
      userInfo.customerIdPrefix != null &&
      userInfo.customerIdPrefix.length > 0
    ) {
      this.customerPrefix = userInfo.customerIdPrefix;
    }
    await this.sleep(1000);
    this.fixStickyHeaders();
  },
  methods: {
    setPagination(data) {
      this.isPaginated = data;
    },
    addPrefixToCustomerId(customerId) {
      let rtn = customerId.toString();
      if (this.customerPrefix != null) {
        rtn = this.customerPrefix + rtn;
      }
      return rtn;
    },
    async setPayload(value) {
      this.loadingSpinner = true;
      let err, result;
      [err, result] = await this.$store.dispatch(
        types.GET_SCRUB_LIST_REPORT_PRA,
        value
      );
      if (result && result.Data) {
        let apiResult = result.Data;
        let formattedData = [];
        if (result) {
          if (apiResult.length > 0) {
            for (let item of apiResult) {
              if (item.Ssn != "") {
                item.Ssn = await this.AES256_GCM_decrypt(item.Ssn);
              }
              if (item.CoCustomer != null && item.CoCustomer.Ssn != null) {
                item.CoCustomer.Ssn = await this.AES256_GCM_decrypt(
                  item.CoCustomer.Ssn
                );
              }
            }
            for (let item of apiResult) {
              if (item.Creditors) {
                for (let c of item.Creditors) {
                  formattedData.push({
                    CustomerId: item.CustomerId,
                    FirstName: item.FirstName,
                    LastName: item.LastName,
                    Name: item.Name,
                    Ssn: item.Ssn,
                    Dob: item.Dob,
                    CoCustomer: {
                      FirstName:
                        item.CoCustomer == null
                          ? ""
                          : item.CoCustomer.FirstName,
                      LastName:
                        item.CoCustomer == null ? "" : item.CoCustomer.LastName,
                      Name: item.CoCustomer == null ? "" : item.CoCustomer.Name,
                      Ssn: item.CoCustomer == null ? "" : item.CoCustomer.Ssn,
                      Dob: item.CoCustomer == null ? "" : item.CoCustomer.Dob,
                    },
                    Address: {
                      Line1: item.Address == null ? "" : item.Address.Line1,
                      City: item.Address == null ? "" : item.Address.City,
                      ShortCode:
                        item.Address == null ? "" : item.Address.ShortCode,
                      Zip: item.Address == null ? "" : item.Address.Zip,
                    },
                    Creditor: {
                      Name: c.Name,
                      CurrName: c.CurrName,
                      AcctN: c.AcctN,
                      CurrAmt: c.CurrAmt,
                    },
                    PolicyGroup: item.PolicyGroup,
                  });
                }
              }
            }
            await this.$store.dispatch(
              types.MODIFY_SCRUB_LIST_REPORT_PRA,
              formattedData
            );
          }
        }
      }
      this.loadingSpinner = false;

      await this.sleep(500);
      this.fixStickyHeaders();
    },
    clearReport() {
      this.$store.dispatch(types.RESET_REPORTS);
      this.reportData = [];
    },
  },
};
</script>

<style scoped></style>
