import types from "./types";
import api from "@/services/admin/api";
import to from "@/helpers/to";
import downloadApi from "@/services/fileServices/downloadService";

export default {
  state: {
    templateList: [],
    templateTypeList: [],
    availablePlaceholder: null,
    officeSettings: [],
    globalSettingList: [],
    companySettingsList: [],
  },

  actions: {
    UPDATE_EDIT_DOCUSIGN_TEMPLATE: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.updateEditDocusignTemplate(payload));
      return [err, result];
    },

    GET_GLOBAL_SETTINGS_LIST: async ({ commit }) => {
      let err, result;

      [err, result] = await to(api.getCompanyGlobalSettings());
      if (result) {
        commit(types.SET_GLOBAL_SETTINGS_LIST, result.Data);
      }
    },

    SEARCH_DOCUSIGN_TEMPLATES: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.searchDocusignTemplate(payload));

      return [err, result];
    },

    GET_DOCUSIGN_TEMPLATE: async ({ commit }) => {
      let err, result;

      [err, result] = await to(api.getDocusignTemplate());
      if (result) {
        commit(types.SET_DOCUSIGN_TEMPLATE, result.Data);
      }
    },
    GET_DOCUSIGN_PLACEHOLDER: async ({ commit, state }) => {
      if (state.availablePlaceholder != null) return;

      let err, result;

      [err, result] = await to(api.getDocusignPlaceHolder());

      if (result) {
        commit(types.SET_DOCUSIGN_PLACEHOLDER, result.Data);
      }
    },
    GET_OFFICE_SETTINGS: async ({ commit }) => {
      let err, result;

      [err, result] = await to(api.getAllDocusignSettings());
      if (result) {
        commit(types.SET_OFFICE_SETTINGS, result.Data);
      }
    },

    GET_COMPANY_SETTINGS_LIST: async ({ commit }) => {
      let err, result;

      [err, result] = await to(api.getCompanySettingsList());
      if (result) {
        commit(types.SET_COMPANY_SETTINGS_LIST, result.Data);
      }
    },

    GET_DOCUSIGN_TEMPLATE_TYPE: async ({ commit }) => {
      let err, result;

      [err, result] = await to(api.getDocusignTemplateType());
      if (result) {
        commit(types.SET_DOCUSIGN_TEMPLATE_TYPE, result.Data);
      }
    },
    DOWNLOAD_DOCUSIGN_TEMPLATE: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(downloadApi.downloadDocusignTemplate(payload));
      return [err, result];
    },
    DELETE_DOCUSIGN_TEMPLATE: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.deleteDocusignTemplate(payload));
      return [err, result];
    },
    POST_DOCUSIGN_TEMPLATE: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.insertorUpdateDocusignTemplate(payload));
      return [err, result];
    },
    POST_DOCUSIGN_SETTINGS: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.addDocusignSetting(payload));
      return [err, result];
    },
    POST_DOCUSIGN_SETTINGS_NEW_DATE: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.addDocusignSettingsNewDate(payload));
      return [err, result];
    },
    INSERT_DOCUSIGN_TEMPLATE_TYPE: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.insertDocusignTemplateType(payload));
      return [err, result];
    },
    UPDATE_DOCUSIGN_TEMPLATE_TYPE: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.updateDocusignTemplateType(payload));
      return [err, result];
    },
  },

  mutations: {
    SET_COMPANY_SETTINGS_LIST: (state, payload) => {
      state.companySettingsList = payload;
    },
    SET_GLOBAL_SETTINGS_LIST: (state, payload) => {
      state.globalSettingList = payload;
    },
    SET_DOCUSIGN_TEMPLATE: (state, payload) => {
      state.templateList = payload;
    },
    SET_DOCUSIGN_TEMPLATE_TYPE: (state, payload) => {
      state.templateTypeList = payload;
    },
    SET_DOCUSIGN_PLACEHOLDER: (state, payload) => {
      state.availablePlaceholder = payload;
    },
    SET_OFFICE_SETTINGS: (state, payload) => {
      state.officeSettings = payload;
    },
  },
};
