<template>
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
    <div v-if="isLoading" class="loading">
      <div class="text-center" style="margin-top: 150px">
        <i class="spinner-grow text-info m-2"></i>
      </div>
    </div>
    <div class="backgroundLock" v-if="isTaskPopupOpen">
      <ClientTaskPopUp
        :task="manageTaskItem"
        @closeTaskPopup="closeTaskPopup"
        @updateTask="updateTask"
      ></ClientTaskPopUp>
    </div>
    <div class="backgroundLock" v-if="isEmployeePopupOpen">
      <EmployeeList
        :header="'Assign To'"
        @closeEmployeePopup="closeEmployeePopup"
        @selectEmployee="selectEmployee"
      ></EmployeeList>
    </div>
    <div class="row" v-if="checkAuth(10020)">
      <div class="col-xl-6 col-lg-6 col-md-12">
        <label class="generalLabel" for="addTask">Add a new task: </label>
        <select
          v-model="selectedTaskTypeId"
          id="slcTasks"
          class="noSizeSelectBox ml-2"
          style="width: 300px"
        >
          <option :value="0">Select a task type</option>
          <option
            v-for="item in filteredTaskTypes"
            :key="item.Id"
            :value="item.Id"
          >
            {{ item.Name }}
          </option>
        </select>
        <button
          @click="addNewTask()"
          type="button"
          id="btnAddTask"
          class="btn btn-sm generalButton ml-2"
          style="background-color: #3bafda; color: #ffffff; margin-top: -1px"
        >
          Add Task
        </button>
      </div>
      <div
        class="col-xl-6 col-lg-6 col-md-12 text-right noPadding"
        v-if="phoneNumber != '' && autoCallOptinStatus != null"
      >
        <label
          v-if="autoCallOptinStatus"
          class="generalLabel"
          style="color: #1abc9c"
          >Opted-In</label
        >
        <label
          v-if="!autoCallOptinStatus"
          class="generalLabel"
          style="color: #f0556c"
          >Opt-Out</label
        >
        <button
          v-if="autoCallOptinStatus"
          @click="optOutAutoCall(3)"
          type="button"
          class="btn btn-sm generalButton ml-2"
          style="background-color: #f0556c; color: #ffffff; margin-top: -1px"
        >
          Opt-Out of Auto Calls
        </button>
        <button
          v-if="!autoCallOptinStatus"
          @click="optInAutoCall(2)"
          type="button"
          class="btn btn-sm generalButton ml-2"
          style="background-color: #1abc9c; color: #ffffff; margin-top: -1px"
        >
          Opt-In Auto Calls
        </button>
      </div>
    </div>
    <div class="row" v-if="!isLoading">
      <div class="col-md-12 datagrid-table taskTable" style="overflow-x: auto">
        <o-table :data="tasksSorted">
          <o-table-column
            id="colorColumn"
            field="CsrTaskType.CsrTaskTypeSetting.Color"
            label=""
            sortable
            position="centered"
            v-slot="props"
            style="margin: 0 !important; padding: 0 !important"
          >
            <div
              style="width: 8px; height: 32px; margin: 0; position: relative"
            >
              <span
                style="
                  width: 100%;
                  height: 100%;
                  display: block;
                  position: absolute;
                  top: 0;
                  left: 0;
                "
                :style="{
                  backgroundColor:
                    props.row.CsrTaskType.CsrTaskTypeSetting.Color,
                }"
              ></span>
            </div>
          </o-table-column>
          <o-table-column
            field="Id"
            label="#"
            sortable
            position="centered"
            v-slot="props"
          >
            {{ props.row.Id }}
          </o-table-column>
          <o-table-column
            id="dueDate"
            field="DueDate"
            label="Due Date"
            sortable
            position="centered"
            v-slot="props"
          >
            {{
              props.row.DueDate != null
                ? new Date(props.row.DueDate).toLocaleString()
                : ""
            }}
          </o-table-column>
          <o-table-column
            field="CsrTaskTypeId"
            label="Task Type"
            sortable
            v-slot="props"
          >
            {{
              props.row.CsrTaskType.CsrTaskTypeSetting.IsTicketType
                ? "Ticket: " + getTaskName(props.row.CsrTaskTypeId)
                : getTaskName(props.row.CsrTaskTypeId)
            }}
            <label v-if="props.row.CsrTaskType.CsrTaskTypeSetting.Priority">
              {{
                "(Pr:" + props.row.CsrTaskType.CsrTaskTypeSetting.Priority + ")"
              }}</label
            >
          </o-table-column>

          <o-table-column
            id="assignedTo"
            field="AssignedUserId"
            label="Assigned To"
            sortable
            v-slot="props"
          >
            {{
              getNameByList(props.row.AssignedUserId, employees, "Unassigned")
            }}
          </o-table-column>

          <o-table-column
            field="CsrTaskStatusId"
            label="Status"
            sortable
            v-slot="props"
          >
            <span v-if="props.row.CsrTaskStatusId > 0">
              {{ getNameByList(props.row.CsrTaskStatusId, taskStatuses) }}
            </span>
          </o-table-column>

          <o-table-column
            id="completedDate"
            field="CompletedDate"
            label="Completed Date"
            sortable
            position="centered"
            v-slot="props"
          >
            {{
              props.row.CompletedDate != null
                ? new Date(props.row.CompletedDate).toLocaleString()
                : ""
            }}
          </o-table-column>

          <o-table-column
            field="Notes"
            label="Note"
            width="20"
            id="note"
            v-slot="props"
          >
            <div class="text-center">
              <button
                v-if="props.row.Id && props.row.Id > 0"
                @click="openNote(props.row.Id)"
                class="btn btn-xs"
                style="background-color: #f19066"
              >
                <i class="ri-sticky-note-line"></i>
              </button>
            </div>
          </o-table-column>

          <o-table-column
            field="Manage"
            label="Manage"
            width="20"
            id="manage"
            v-slot="props"
          >
            <div class="text-center" v-show="props.row.CsrTaskStatusId == 1">
              <button
                v-if="checkAuth(10027)"
                class="btn btn-xs"
                style="background-color: #3498db"
                @click="manageTask(props.row)"
              >
                <i class="bx bx-edit"></i>
              </button>
            </div>
          </o-table-column>

          <o-table-column
            field="Schedule"
            label="Schedule"
            width="20"
            id="manage"
            v-slot="props"
            v-if="checkAuth(55554)"
          >
            <div class="text-center" v-if="props.row.TriggerCount">
              <button
                v-if="props.row.TriggerCount > 0"
                class="btn btn-xs"
                style="background-color: #8d778e"
                @click="taskTrigger(props.row.Id)"
              >
                <i class="bx bx-time-five"></i>
              </button>
            </div>
          </o-table-column>

          <o-table-column
            field="assign"
            label="Assign"
            width="20"
            id="assign"
            v-slot="props"
          >
            <div
              class="text-center"
              v-if="
                props.row.AssignedUserId == 0 && props.row.CsrTaskStatusId == 1
              "
            >
              <button
                v-if="
                  checkAuth(10025) &&
                  ((props.row.CsrTaskType &&
                    props.row.CsrTaskType.CsrTaskTypeSetting &&
                    props.row.CsrTaskType.CsrTaskTypeSetting.IsTicketType !=
                      true) ||
                    (props.row.CsrTaskType &&
                      props.row.CsrTaskType.CsrTaskTypeSetting == null))
                "
                class="btn btn-xs btn-success"
                @click="assignTask(props.row.Id, props.row.AssignedUserId)"
              >
                <i class="bx bx-user-check"></i>
              </button>
            </div>
          </o-table-column>

          <o-table-column
            field="unassign"
            label="Unassign"
            width="20"
            id="unassign"
            v-slot="props"
          >
            <div
              class="text-center"
              v-if="
                props.row.AssignedUserId != 0 && props.row.CsrTaskStatusId == 1
              "
            >
              <button
                v-if="
                  checkAuth(10026) &&
                  ((props.row.CsrTaskType &&
                    props.row.CsrTaskType.CsrTaskTypeSetting &&
                    props.row.CsrTaskType.CsrTaskTypeSetting.IsTicketType !=
                      true) ||
                    (props.row.CsrTaskType &&
                      props.row.CsrTaskType.CsrTaskTypeSetting == null))
                "
                class="btn btn-xs btn-danger"
                @click="unAssignTask(props.row.Id, props.row.AssignedUserId)"
              >
                <i class="bx bx-x"></i>
              </button>
            </div>
          </o-table-column>
        </o-table>
        <section
          v-if="tasksSorted.length == 0"
          class="section"
          style="text-align: center"
        >
          <div class="content has-text-grey has-text-centered mb-4">
            <p class="noMargin">
              <i
                style="font-size: 24px; color: #939393"
                class="ri-question-line"
              ></i>
            </p>
            <p class="noMargin">Nothing here.</p>
          </div>
        </section>
      </div>
    </div>
    <div class="row">
      <div
        class="backgroundLock"
        v-if="isClientAddNewTaskPopUp"
        style="-webkit-box-align: center; align-items: center; display: flex"
      >
        <div
          v-if="customerId > 0 && selectedTaskType"
          style="position: relative; margin: 10% auto"
          class="offset-xl-4 col-xl-3 offset-lg-3 col-lg-6 col-md-12 col-sm-12 col-xs-12"
        >
          <ClientAddNewTaskPopUp
            @refreshTaskGrid="refreshTaskGrid"
            @closeClientAddNewTaskPopUp="closeClientAddNewTaskPopUp"
            :customerId="customerId"
            :taskType="selectedTaskType"
          ></ClientAddNewTaskPopUp>
        </div>
      </div>
    </div>
    <div class="row">
      <div
        class="backgroundLock"
        v-if="isTicketPopupOpen"
        style="-webkit-box-align: center; align-items: center; z-index: 400"
      >
        <div
          style="position: relative; margin: 80px auto; overflow-y: auto"
          :style="isMobileDevice() ? 'max-height:100%;' : 'max-height:40vmax;'"
          class="bg-white offset-xl-3 col-xl-8 col-lg-12 col-md-12 col-sm-12 col-xs-12"
        >
          <div
            class="exitButton"
            style="right: 22px; top: -5px; color: white"
            @click="closeTicketPopup()"
          >
            <i class="fas fa-window-close"></i>
          </div>
          <CsrTaskModule
            :ticketId="selectedTicketId"
            @closeTaskPopup="closeTicketPopup"
          ></CsrTaskModule>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="backgroundLock" v-if="isSchedulePopUpOpen">
        <div
          style="position: relative; margin: 10% auto"
          class="offset-xl-4 col-xl-6 offset-lg-3 col-lg-6 col-md-12 col-sm-12 col-xs-12"
        >
          <ClientTaskTriggerPopUp
            @closeTaskTriggerPopUp="closeTaskTriggerPopUp"
            :taskId="selectedTaskId"
          ></ClientTaskTriggerPopUp>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClientTaskTriggerPopUp from "./ClientTaskTriggerPopUp.vue";
import { mapState } from "vuex";
import types from "./types";
import globalTypes from "@/store/types";
import deepCopy from "@/helpers/deepCopy";
import ClientTaskPopUp from "./ClientTaskPopUp";
import ClientAddNewTaskPopUp from "./ClientAddNewTaskPopUp";
import EmployeeList from "@/components/EmployeeList";
import _orderby from "lodash";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import formatMixin from "@/mixins/formatMixin";
import CsrTaskModule from "@/views/agent/CsrTaskDistro/CsrTaskDistro";
export default {
  name: "ClientTasks",
  components: {
    ClientTaskTriggerPopUp,
    ClientTaskPopUp,
    EmployeeList,
    ClientAddNewTaskPopUp,
    CsrTaskModule,
  },
  mixins: [utilitiesMixin, formatMixin],
  props: {
    customerId: {
      Type: Number,
      default: () => 0,
    },
    phoneNumber: {
      Type: String,
      default: () => "",
    },
    tabClicked: {
      Type: Boolean,
      Default: false,
    },
  },
  watch: {
    tabClicked(newVal, oldVal) {
      if (newVal != oldVal && newVal) {
        this.watcherRefresh();
      }
    },
  },
  computed: mapState({
    tasksSorted() {
      return _orderby.orderBy(
        _orderby.orderBy(this.tasks, "DueDate", "desc"),
        "CsrTaskStatusId",
        "asc"
      );
    },
    taskStatuses: (state) => state.globals.csrTaskStatus,
    taskTypes: (state) => state.globals.taskTypeNames,
    employees: (state) => state.globals.allEmployees,
    filteredTaskTypes() {
      return this.checkAuth(200010)
        ? _orderby.orderBy(this.taskTypes, "Name", "asc")
        : _orderby.orderBy(
            this.taskTypes.filter((x) => x.isTicketType != true),
            "Name",
            "asc"
          );
    },
  }),
  data() {
    return {
      isSchedulePopUpOpen: false,
      isLoading: false,
      isTaskPopupOpen: false,
      isEmployeePopupOpen: false,
      selectedTaskId: 0,
      selectedTaskTypeId: 0,
      selectedTaskType: null,
      manageTaskItem: null,
      loggedUserId: 0,
      isClientAddNewTaskPopUp: false,
      tasks: [],
      isTicketPopupOpen: false,
      selectedTicketId: 0,
      autoCallOptinStatus: null,
    };
  },
  unmounted() {
    this.$Emitter.off("updateCsrTask");
  },
  async mounted() {
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    this.loggedUserId = userInfo.userId;

    this.$Emitter.on("updateCsrTask", async (data) => {
      if (data != null && data.Id > 0) {
        await this.getCustomerTasks();
      }
    });
  },

  methods: {
    async optInAutoCall() {
      await this.setOptinAutoCall(2);
    },
    async optOutAutoCall() {
      await this.setOptinAutoCall(3);
    },
    async setOptinAutoCall(statusId) {
      if (this.customerId > 0) {
        let err, result;
        [err, result] = await this.$store.dispatch(types.OPTOUT_AUTOCALL, {
          PhoneNumber: this.phoneNumber,
          OptinStatusId: statusId,
        });
        if (result) {
          this.autoCallOptinStatus = statusId == 3 ? false : true;
          this.$swal("Success", result.Messsage, "success");
        } else {
          let errMsg = this.getApiErrorMessage(err);
          this.$swal("Error!", errMsg, "error");
        }
      }
    },

    taskTrigger(taskId) {
      this.selectedTaskId = taskId;
      this.isSchedulePopUpOpen = true;
    },
    closeTaskTriggerPopUp() {
      this.isSchedulePopUpOpen = false;
    },
    async watcherRefresh() {
      await Promise.all([
        this.$store.dispatch(globalTypes.GET_CSRTASKSTATUS),
        // this.$store.dispatch(globalTypes.GET_CSRS),
        this.$store.dispatch(globalTypes.GET_TASK_TYPE_NAMES),
        this.$store.dispatch(globalTypes.GET_ALL_ACTIVE_EMPLOYEES),
      ]);

      await this.getCustomerTasks();
    },
    emptyTheObjectsOnUnmount() {
      this.isTaskPopupOpen = null;
      this.isEmployeePopupOpen = null;
      this.selectedTaskId = null;
      this.selectedTaskTypeId = null;
      this.selectedTaskType = null;
      this.manageTaskItem = null;
      this.loggedUserId = null;
      this.isClientAddNewTaskPopUp = null;
      this.tasks = null;
    },
    async closeTicketPopup() {
      this.isTicketPopupOpen = false;
      await this.getCustomerTasks();
    },
    async getCustomerTasks() {
      this.isLoading = true;
      try {
        if (this.checkAuth(10028)) {
          let err, result;

          [err, result] = await this.$store.dispatch(types.GET_CLIENT_TASKS, {
            CustomerId: this.customerId,
            PhoneNumber: this.phoneNumber,
          });

          if (result && result.Data) {
            //old code
            // this.tasks = deepCopy(result.Data);

            this.tasks = deepCopy(result.Data.CsrTasks);
            this.autoCallOptinStatus = result.Data.IsAutoCallOptIn;
          }
        }
      } catch {
        //logs
      }
      this.isLoading = false;
    },

    refreshTaskGrid(data) {
      this.tasks.push(data);
    },

    openNote(taskId) {
      this.$Emitter.emit("viewNotePanel", {
        CustomerId: this.customerId,
        CsrTaskId: taskId,
      });
    },

    closeClientAddNewTaskPopUp() {
      this.isClientAddNewTaskPopUp = false;
    },

    assignTask(taskId) {
      this.selectedTaskId = taskId;
      this.isEmployeePopupOpen = true;
    },
    async unAssignTask(taskId, assignedUserId) {
      let assignedUserName = this.getNameByList(assignedUserId, this.employees);
      let err, result;
      this.$swal
        .fire({
          title: "Unassign Task",

          text:
            "Are you sure to unassign this task from " + assignedUserName + "?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#1abc9c",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Unassign",
        })
        .then(async (dialog) => {
          if (dialog.value) {
            [err, result] = await this.$store.dispatch(types.UNASSIGN_TASK, {
              Id: taskId,
            });
            if (result && result.Errors.length == 0) {
              let taskIndex = this.tasks.findIndex(
                (x) => x.Id == result.Data.Id
              );
              this.isLoading = true;
              if (taskIndex > -1) {
                this.tasks[taskIndex] = deepCopy(result.Data);
              }
              this.isLoading = false;
              this.$swal("Success", "Task Unassigned.", "success");
            } else {
              let errMsg = this.getApiErrorMessage(err);
              this.$swal("Error!", errMsg, "error");
            }
          }
        });
    },
    async manageTask(row) {
      let err, result;
      [err, result] = await this.$store.dispatch(types.GET_TASK_BY_ID, {
        Id: row.Id,
      });

      if (result) {
        this.manageTaskItem = result.Data;
        let dueDate = new Date(this.manageTaskItem.DueDate);

        if (this.manageTaskItem.AssignedUserId == 0) {
          if (dueDate > new Date()) {
            this.openTaskPopup();
          } else {
            this.$swal({
              title: "Confirm",
              text: "This due task is going to be assigned to you. Would you like to proceed?",
              type: "warning",
              showCancelButton: true,
              confirmButtonColor: "#1abc9c",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes, Assign",
            }).then(async (dialog) => {
              if (dialog.value) {
                [err, result] = await this.$store.dispatch(types.ASSIGN_TASK, {
                  Id: row.Id,
                  AssignedUserId: this.loggedUserId,
                });
                if (result && result.Errors.length == 0) {
                  let taskIndex = this.tasks.findIndex(
                    (x) => x.Id == result.Data.Id
                  );
                  if (taskIndex > -1) {
                    this.tasks[taskIndex] = deepCopy(result.Data);
                  }
                  this.openTaskPopup();
                } else {
                  let errMsg = this.getApiErrorMessage(err);
                  this.$swal("Error!", errMsg, "error");
                }
              }
            });
          }
        } else {
          //todo: check auth for accessing other users tasks
          this.openTaskPopup();
        }
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
    },
    openTaskPopup() {
      if (
        this.manageTaskItem &&
        this.manageTaskItem.CsrTaskType &&
        this.manageTaskItem.CsrTaskType.CsrTaskTypeSetting &&
        this.manageTaskItem.CsrTaskType.CsrTaskTypeSetting.IsTicketType == true
      ) {
        this.selectedTicketId = this.manageTaskItem.Id;
        this.isTicketPopupOpen = true;
      } else {
        this.isTaskPopupOpen = true;
      }
    },
    getNameByList(id, list, defaultText) {
      let index = list.findIndex((x) => x.Id == id);

      return index > -1 ? list[index].Name : defaultText;
    },
    getTaskName(taskTypeId) {
      let rtn = "";
      let indx = this.tasksSorted.findIndex(
        (x) => x.CsrTaskType.Id == taskTypeId
      );
      if (indx > -1) {
        rtn = this.tasksSorted[indx].CsrTaskType.Name;
      }
      return rtn;
    },
    closeTaskPopup() {
      this.isTaskPopupOpen = false;
    },
    async updateTask(data) {
      this.isTaskPopupOpen = false;
      await this.sleep(1000);
      this.getCustomerTasks();
      this.$swal("Success", "Task Submitted.", "success");
    },
    async addNewTask() {
      if (this.selectedTaskTypeId > 0) {
        let typeIndex = this.taskTypes.findIndex(
          (x) => x.Id == this.selectedTaskTypeId
        );

        if (typeIndex > -1) {
          this.selectedTaskType = this.taskTypes[typeIndex];
          this.isClientAddNewTaskPopUp = true;
        }
      } else {
        this.$swal("Warning", "Task type is required.", "warning");
      }
    },
    addDays(date, days) {
      let result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    },
    closeEmployeePopup() {
      this.isEmployeePopupOpen = false;
    },
    selectEmployee(data) {
      let err, result;
      this.$swal({
        title: "Assign Task",
        text: "Are you sure to assgin this task to " + data.EmployeeName + "?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1abc9c",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Assign",
      }).then(async (dialog) => {
        if (dialog.value) {
          this.isEmployeePopupOpen = false;
          [err, result] = await this.$store.dispatch(types.ASSIGN_TASK, {
            Id: this.selectedTaskId,
            AssignedUserId: data.EmployeeId,
          });
          if (result && result.Errors.length == 0) {
            let taskIndex = this.tasks.findIndex((x) => x.Id == result.Data.Id);
            this.isLoading = true;
            if (taskIndex > -1) {
              this.tasks[taskIndex] = deepCopy(result.Data);
            }
            this.isLoading = false;
            this.$swal("Success", "Task Assigned.", "success");
          } else {
            let errMsg = this.getApiErrorMessage(err);
            this.$swal("Error!", errMsg, "error");
          }
        }
      });
    },
  },
};
</script>
