<template>
  <div class="clearfix">
    <div
      v-if="widgetData"
      class="datagrid-table"
      style="
        height: 300px;
        overflow-y: scroll;
        overflow-x: auto;
        border-radius: 8px;
      "
    >
      <!-- <section>
        <o-table :data="widgetData.data" >
          <template v-slot="props">
            <o-table-column
              v-for="item in widgetData.columns"
              :key="item.field"
              :field="item.field"
              :label="item.label"
              :width="item.width"
              >{{ props.row[column.field] }}</o-table-column
            >
          </template>
        </o-table>
      </section> -->

      <o-table :data="widgetData.data">
        <template v-for="column in widgetData.columns" :key="column.id">
          <o-table-column v-bind="column">
            <template v-slot="props">
              <a style="margin-right: -3px" v-if="column.field == 'CustomerId'">
                {{ widgetData.prefix == true ? "Z" : "" }}
              </a>
              {{ formatCell(props.row[column.field], column.type) }}

              <button
                v-if="column.type == 'button'"
                @click="viewCustomer(props.row.CustomerId, props.row.Name)"
                class="btn btn-info waves-effect waves-light ml-3"
              >
                <i class="far fa-address-card"></i>
              </button>
            </template>
          </o-table-column>
        </template>
      </o-table>

      <section class="section" v-if="widgetData.data.length == 0">
        <div class="content has-text-grey has-text-centered text-center">
          <p>
            <i
              style="font-size: 32px; color: #939393"
              class="ri-question-line"
            ></i>
          </p>
          <p>Nothing here.</p>
        </div>
      </section>
    </div>
    <div v-if="widgetData.foot" class="table widget-table">
      <div class="tfoot">
        <div class="tr clearfix">
          <div
            class="td"
            :style="'width:' + item.width"
            v-for="item in widgetData.columns"
            :key="item.field"
          >
            <template v-if="item.showTotal && item.showTotal != ''">{{
              formatCell(
                calculateTotal(item.field, item.type, item.showTotal),
                item.type
              )
            }}</template>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--
  <div class="clearfix">
    <div class="table widget-table">
      <div class="thead">
        <div class="tr clearfix">
          <div
            class="td"
            :style="{width: 100 / widgetData.columns.length + '%'}"
            v-for="item in widgetData.columns"
            :key="item.id"
          >{{item.label}}</div>
        </div>
      </div>
      <div class="tbody">
        <div class="tr clearfix" v-for="item in widgetData.data" :key="item.id">
          <div
            class="td"
            :style="{width: 100 / widgetData.columns.length + '%'}"
            v-for="row in widgetData.columns"
            :key="row.id"
          >{{item[row.name]}}</div>
        </div>
      </div>
      <div class="tfoot">
        <div class="tr clearfix">
          <div
            class="td"
            :style="{width: 100 / widgetData.columns.length + '%'}"
            v-for="row in widgetData.columns"
            :key="row.id"
          >
            <template v-if="row.showTotal">{{row.total}}</template>
          </div>
        </div>
      </div>
    </div>
  </div>
  -->
</template>

<script>
import formatMixin from "@/mixins/formatMixin";
export default {
  mixins: [formatMixin],
  props: {
    widgetData: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return { isStripped: true };
  },
  mounted() {},
  methods: {
    formatCell(value, type) {
      let rtn = value;

      if (type == "decimal") {
        rtn = value == null ? 0 : this.formatMoney(value);
      } else if (type == "date") {
        rtn = this.formatDate(value);
      } else if (type == "time") {
        rtn = this.formatSecond(value);
      } else if (type == "numeric") {
        rtn = value != null ? value.toLocaleString("en-US") : 0;
      } else if (type == "float") {
        rtn = value != null ? this.formatFloatingNumber(value) : 0;
      } else if (type == "onlyDate") {
        rtn = this.formatShortDate(value);
      } else if (type == "money") {
        rtn = this.formatMoney(value);
      }

      return rtn;
    },
    calculateTotal(field, type, totalType) {
      let total = 0;

      for (let item of this.widgetData.data) {
        for (let key in item) {
          if (key == field) {
            let val = 0;
            if (type.toLowerCase() == "numeric") {
              val = parseInt(item[key]);
            } else if (
              type.toLowerCase() == "decimal" ||
              type.toLowerCase() == "float"
            ) {
              val = parseFloat(item[key]);
            }

            if (
              typeof val != "undefined" &&
              val != null &&
              !isNaN(val) &&
              val != ""
            ) {
              total += val;
            }
          }
        }
      }

      if (totalType.toLowerCase() == "total") {
        return isNaN(total) ? "" : total;
      }

      return total;
    },
    async viewCustomer(id, name) {
      this.$Emitter.emit("viewCustomer", {
        customerId: id,
        customerName: name,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.widget-table {
  width: 100%;
  display: table;
  background-color: #fff;
  margin-bottom: 0;
  border-bottom-left-radius: 16px !important;
  border-bottom-right-radius: 16px !important;
  text-align: left;
  .thead {
    font-size: 14px;
    font-weight: 600;
    position: relative;
    z-index: 5;
    color: #2a404c;
    border-radius: 7px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
    .td {
      height: 53px;
      line-height: 53px;
    }
  }
  .tbody {
    position: relative;
    z-index: 4;
    .tr {
      &:nth-child(odd) {
        background-color: #f9f9f9;
      }
      line-height: 49px;
      width: calc(100% + 1em);
    }
    height: 220px;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  .tfoot {
    height: 49px;
    position: relative;
    z-index: 5;
    border-radius: 8px;
    box-shadow: 0 -2px 10px 0 rgba(0, 0, 0, 0.05);
    .td {
      font-weight: bold;
      line-height: 49px;
    }
  }
  .td {
    float: left;
    padding-left: 15px;
    text-align: left;
    height: 49px;
  }
}
</style>
