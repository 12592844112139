import types from "./types";
import api from "@/services/admin/api";
import to from "@/helpers/to";

export default {
  state: {},
  actions: {
    POST_SCRIPT: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.insertScript(payload));

      return [err, result];
    },
    PUT_SCRIPT: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.updateScript(payload));

      return [err, result];
    },
    DELETE_SCRIPT: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.deleteScript(payload));

      return [err, result];
    },
    RESET_SCRIPTS: async ({ commit }, payload) => {
      commit(types.SET_SCRIPTS, []);
    },
  },
  mutations: {},
};
