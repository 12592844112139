<template>
  <div class="row admin-page officePage" style="position: relative">
    <div v-if="isLoading" class="loading">
      <div class="text-center" style="margin-top: 50vh">
        <i class="spinner-grow text-info m-2"></i>
      </div>
    </div>
    <div class="col-md-12">
      <button
        class="col-xl-12 btn btn-primary waves-effect waves-light"
        @click="openAddPopup(null)"
      >
        Add New Document
      </button>
      <div class="filter form-group row">
        <div class="col-md-12 noPadding">
          <div class="col-md-12" style="position: absolute">
            <label>Filter by:</label>
          </div>

          <div class="col-md-12 row noPadding">
            <div class="col-xl-2 col-lg-3 col-md-6 col-12">
              <div
                class="col-md-12 noPadding"
                style="display: flex; justify-content: flex-end"
              >
                <span
                  @click="addAllOffices()"
                  class="badge badge-primary"
                  style="width: 20px; cursor: pointer"
                >
                  <span style="font-weight: 600">+</span>
                </span>

                <span
                  @click="clearAllOffices()"
                  class="badge badge-light ml-1"
                  style="width: 20px; cursor: pointer"
                >
                  <span class="" style="font-weight: 600">-</span>
                </span>
              </div>

              <VueMultiselect
                v-model="filterParameters.Offices"
                :options="filteredOffices"
                :multiple="true"
                selectLabel=""
                deselectLabel=""
                :close-on-select="false"
                placeholder="Offices"
                label="Name"
                track-by="Id"
                :limit="3"
              />
            </div>
            <div class="col-xl-2 col-lg-3 col-md-6 col-12">
              <div
                class="col-md-12 noPadding"
                style="display: flex; justify-content: flex-end"
              >
                <span
                  @click="addAllPolicyG()"
                  class="badge badge-primary"
                  style="width: 20px; cursor: pointer"
                >
                  <span style="font-weight: 600">+</span>
                </span>

                <span
                  @click="clearAllPolicyG()"
                  class="badge badge-light ml-1"
                  style="width: 20px; cursor: pointer"
                >
                  <span class="" style="font-weight: 600">-</span>
                </span>
              </div>
              <VueMultiselect
                v-model="filterParameters.PolicyGroups"
                :options="companyPolicyGroups"
                selectLabel=""
                deselectLabel=""
                :multiple="true"
                :close-on-select="false"
                placeholder="Policy Groups"
                label="Name"
                track-by="PolicyGroupId"
                :limit="3"
              />
            </div>
            <div class="col-xl-2 col-lg-3 col-md-6 col-12">
              <div
                class="col-md-12 noPadding"
                style="display: flex; justify-content: flex-end"
              >
                <span
                  @click="addAllStates()"
                  class="badge badge-primary"
                  style="width: 20px; cursor: pointer"
                >
                  <span style="font-weight: 600">+</span>
                </span>

                <span
                  @click="clearAllStates()"
                  class="badge badge-light ml-1"
                  style="width: 20px; cursor: pointer"
                >
                  <span class="" style="font-weight: 600">-</span>
                </span>
              </div>
              <VueMultiselect
                v-model="filterParameters.States"
                :options="states"
                selectLabel=""
                deselectLabel=""
                :multiple="true"
                :close-on-select="false"
                placeholder="States"
                label="Name"
                track-by="Id"
                :limit="3"
              />
            </div>
            <div class="col-xl-2 col-lg-3 col-md-6 col-12">
              <div
                class="col-md-12 noPadding"
                style="display: flex; justify-content: flex-end"
              >
                <span
                  @click="addAllTemplateT()"
                  class="badge badge-primary"
                  style="width: 20px; cursor: pointer"
                >
                  <span style="font-weight: 600">+</span>
                </span>

                <span
                  @click="clearAllTemplateT()"
                  class="badge badge-light ml-1"
                  style="width: 20px; cursor: pointer"
                >
                  <span class="" style="font-weight: 600">-</span>
                </span>
              </div>
              <VueMultiselect
                v-model="filterParameters.TemplateTypes"
                :options="templateTypeList"
                :multiple="true"
                selectLabel=""
                deselectLabel=""
                :close-on-select="false"
                placeholder="Template Types"
                label="Name"
                track-by="Id"
                :limit="3"
              />
            </div>
            <div class="col-xl-2 col-lg-3 col-md-6 col-12">
              <input
                type="text"
                v-model="filterParameters.DocumentName"
                style="height: 43px"
                autocomplete="off"
                placeholder="Document Name"
                class="form-control mt-3"
              />
            </div>
            <div class="col-xl-2 col-lg-3 col-md-6 col-12">
              <button
                @click="searchTemplates()"
                class="btn btn-success waves-effect waves-light mt-3"
              >
                <i class="fas fa-search"></i>
                <span style="font-weight: 600"> Search</span>
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <button
            v-if="selectedProduct && selectedProduct.length > 0"
            @click="bulkDelete()"
            class="btn btn-danger waves-effect waves-light"
          >
            <span style="font-weight: 600"> Delete Selected</span>
          </button>
        </div>
        <div class="col-md-12" v-if="!isLoading && templateList.length > 0">
          <div class="form-group row">
            <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12">
              <span class="badge badge-success" style="padding: 10px 16px">
                Total Records: {{ templateList.length }}</span
              >
            </div>
          </div>
          <DataTable
            :value="templateList"
            stripedRows
            v-model:filters="filters"
            v-model:selection="selectedProduct"
            dataKey="Id"
            filterDisplay="row"
            scrollable
            size="small"
            selectionMode="multiple"
            showGridlines
            paginator
            :rows="15"
            removableSort
          >
            <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
            <Column
              field="DocumentName"
              header="Document Name"
              style="width: 15rem"
              sortable
            >
              <template #body="{ data }">
                {{ data.DocumentName }}
              </template>
              <template #filter="{ filterModel, filterCallback }">
                <InputText
                  v-model="filterModel.value"
                  type="text"
                  @input="filterCallback()"
                  class="p-column-filter"
                />
              </template>
            </Column>

            <Column
              field="Office.Name"
              header="Office"
              style="width: 15rem"
              sortable
            >
              <template #body="{ data }">
                {{ data.Office.Name }}
              </template>
              <template #filter="{ filterModel, filterCallback }">
                <InputText
                  v-model="filterModel.value"
                  type="text"
                  @input="filterCallback()"
                  class="p-column-filter"
                />
              </template>
            </Column>

            <Column
              field="State.Short"
              header="State"
              style="width: 8rem"
              sortable
            >
              <template #body="{ data }">
                {{ data.State.Short }}
              </template>
              <template #filter="{ filterModel, filterCallback }">
                <InputText
                  v-model="filterModel.value"
                  type="text"
                  @input="filterCallback()"
                  class="p-column-filter"
                />
              </template>
            </Column>

            <Column
              field="PolicyGroupId"
              header="Policy Group"
              style="width: 10rem"
              sortable
            >
              <template #body="{ data }">
                {{ data.PolicyGroupId }}
              </template>
              <template #filter="{ filterModel, filterCallback }">
                <InputText
                  v-model="filterModel.value"
                  type="text"
                  @input="filterCallback()"
                  class="p-column-filter"
                />
              </template>
            </Column>

            <Column
              field="DocusignTemplateType.Name"
              header="Template Type"
              style="width: 15rem"
              sortable
            >
              <template #body="{ data }">
                {{ data.DocusignTemplateType.Name }}
              </template>
              <template #filter="{ filterModel, filterCallback }">
                <InputText
                  v-model="filterModel.value"
                  type="text"
                  @input="filterCallback()"
                  class="p-column-filter"
                />
              </template>
            </Column>

            <Column
              field="EmailSubject"
              header="Email Subject"
              style="width: 15rem"
              sortable
            >
              <template #body="{ data }">
                {{ data.EmailSubject }}
              </template>
              <template #filter="{ filterModel, filterCallback }">
                <InputText
                  v-model="filterModel.value"
                  type="text"
                  @input="filterCallback()"
                  class="p-column-filter"
                />
              </template>
            </Column>

            <Column field="" header="" style="width: 5rem; text-align: center">
              <template #body="{ data }">
                <button
                  type="button"
                  title="Edit"
                  class="btn btn-link waves-effect"
                  style="
                    background-color: #24d171;
                    color: white;
                    padding: 2px 7px 2px 7px;
                  "
                  @click="openEditPopup(data)"
                >
                  <i class="ri-edit-line"></i>
                </button>
              </template>
            </Column>

            <Column field="" header="" style="width: 5rem; text-align: center">
              <template #body="{ data }">
                <button
                  type="button"
                  title="Download"
                  class="btn btn-info waves-effect"
                  @click="downloadDocument(data.Id, data.DocumentName)"
                  :disabled="saving"
                  style="padding: 2px 7px 2px 7px"
                >
                  <i class="fas fa-download"></i>
                </button>
              </template>
            </Column>

            <Column field="" header="" style="width: 5rem; text-align: center">
              <template #body="{ data }">
                <button
                  type="button"
                  title="Delete"
                  class="btn btn-link waves-effect"
                  style="
                    background-color: #ed3030;
                    color: white;
                    padding: 2px 7px 2px 7px;
                  "
                  @click="handleDelete(data.Id)"
                >
                  <i class="fe-trash-2"></i>
                </button>
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
      <section
        v-if="templateList.length == 0"
        class="section mt-5"
        style="text-align: center"
      >
        <div class="content has-text-grey has-text-centered mb-4">
          <p class="noMargin">
            <i
              style="font-size: 24px; color: #939393"
              class="ri-question-line"
            ></i>
          </p>
          <p class="noMargin">Nothing here.</p>
        </div>
      </section>
    </div>

    <div class="row">
      <div
        class="backgroundLock"
        v-if="isDocumentAddNewPopupOpen"
        style="-webkit-box-align: center; align-items: center; display: flex"
      >
        <div
          style="position: relative; margin: 5% auto; z-index: 2000"
          class="offset-xl-4 col-xl-7 offset-lg-3 col-lg-6 col-md-12 col-sm-12 col-xs-12"
        >
          <DocumentAddNewPopup
            @closeAddPopup="closeAddPopup"
            @newDocumentInserted="newDocumentInserted"
          >
          </DocumentAddNewPopup>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="backgroundLock" v-if="isDeletePopupOpen">
        <div
          style="
            width: 300px;
            position: relative;
            margin: 5% auto;
            z-index: 2000;
          "
        >
          <DocumentDeletePopup
            :idList="deleteDocumentIds"
            @closeDeletePopup="closeDeletePopup"
          >
          </DocumentDeletePopup>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="backgroundLock" v-if="isDocumentEditPopupOpen">
        <div
          style="position: relative; margin: 5% auto; z-index: 2000"
          class="offset-xl-4 col-xl-7 offset-lg-3 col-lg-6 col-md-12 col-sm-12 col-xs-12"
        >
          <DocusingDocumentEditPopup
            @closeEditPopup="closeEditPopup"
            :document="selectedItem"
            @documentUpdated="documentUpdated"
          >
          </DocusingDocumentEditPopup>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import _orderby from "lodash";
import types from "./types";
import deepCopy from "@/helpers/deepCopy";
import globalTypes from "@/store/types";
import VueMultiselect from "vue-multiselect";
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import DocumentAddNewPopup from "./DocusingDocumentAddNewPopup";
import DocumentDeletePopup from "./DocusignDocumentBulkDeletePopup";
import DocusingDocumentEditPopup from "./DocusingDocumentEditPopup";

import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { FilterMatchMode } from "primevue/api";

export default {
  name: "DocusignDocumentsManagement",
  components: {
    VueMultiselect,
    DocumentAddNewPopup,
    DocumentDeletePopup,
    DocusingDocumentEditPopup,
    DataTable,
    Column,
  },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      selectedProduct: [],
      isDocumentEditPopupOpen: false,
      isLoading: false,
      saving: false,
      isNewRecord: false,
      hasAccessOtherOffices: false,
      userOfficeId: 0,
      filterParameters: {
        Offices: [],
        PolicyGroups: [],
        States: [],
        TemplateTypes: [],
        DocumentName: "",
      },
      oldFilter: "",
      allDocumentsChecked: false,
      templateList: [],
      searching: false,
      isDocumentAddNewPopupOpen: false,
      selectedItem: null,
      deleteDocumentIds: [],
      isDeletePopupOpen: false,

      filters: {
        DocumentName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        "Office.Name": { value: null, matchMode: FilterMatchMode.CONTAINS },
        "State.Short": { value: null, matchMode: FilterMatchMode.CONTAINS },
        PolicyGroupId: { value: null, matchMode: FilterMatchMode.CONTAINS },
        "DocusignTemplateType.Name": {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
        EmailSubject: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    };
  },
  computed: mapState({
    states: (state) => state.globals.states,
    templateTypeList: (state) => state.docusignManagement.templateTypeList,
    offices: (state) => state.globals.offices,
    policyGroups: (state) => state.globals.policyGroups,
    companyPolicyGroups() {
      return this.policyGroups.map((item) => {
        return {
          PolicyGroupId: +item.PolicyGroupId,
          Name: item.PolicyGroupId + " - " + item.Name,
        };
      });
    },
    filteredOffices() {
      return _orderby.orderBy(
        this.offices.filter((x) =>
          this.hasAccessOtherOffices ? x : x.Id == this.userOfficeId
        )
      );
    },
    activeTemplateTypeList() {
      return this.templateTypeList.filter((item) => item.IsActive);
    },
  }),
  async mounted() {
    await Promise.all([
      this.$store.dispatch(globalTypes.GET_STATES),
      this.$store.dispatch(globalTypes.GET_ALL_POLICY_GROUPS),
      this.$store.dispatch(globalTypes.GET_OFFICE_NAMES),
      this.$store.dispatch(types.GET_DOCUSIGN_TEMPLATE_TYPE),
    ]);

    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo != null && userInfo.moduleFunctions != null) {
      this.userOfficeId = userInfo.officeId;
    }
    this.hasAccessOtherOffices = this.canSeeAllOffices();
  },
  methods: {
    addAllOffices() {
      this.filterParameters.Offices = this.filteredOffices.slice();
    },
    clearAllOffices() {
      this.filterParameters.Offices = [];
    },

    addAllPolicyG() {
      this.filterParameters.PolicyGroups = this.companyPolicyGroups.slice();
    },
    clearAllPolicyG() {
      this.filterParameters.PolicyGroups = [];
    },

    addAllStates() {
      this.filterParameters.States = this.states.slice();
    },
    clearAllStates() {
      this.filterParameters.States = [];
    },

    addAllTemplateT() {
      this.filterParameters.TemplateTypes = this.templateTypeList.slice();
    },
    clearAllTemplateT() {
      this.filterParameters.TemplateTypes = [];
    },

    closeDeletePopup() {
      this.isDeletePopupOpen = false;
      this.searchTemplates(true);
    },
    openAddPopup(item) {
      this.selectedItem = deepCopy(item);
      this.isDocumentAddNewPopupOpen = true;
    },
    closeAddPopup() {
      this.isDocumentAddNewPopupOpen = false;
    },
    openEditPopup(item) {
      this.selectedItem = deepCopy(item);
      this.isDocumentEditPopupOpen = true;
    },
    closeEditPopup() {
      this.isDocumentEditPopupOpen = false;
    },
    newDocumentInserted() {
      this.isDocumentAddNewPopupOpen = false;
      this.isDocumentEditPopupOpen = false;
      this.searchTemplates(true);
    },
    documentUpdated(data) {
      this.isDocumentEditPopupOpen = false;

      if (data && data.Id > 0) {
        let indx = this.templateList.findIndex((x) => x.Id == data.Id);
        if (indx > -1) {
          this.templateList[indx].DocumentName = data.DocumentName;
          this.templateList[indx].EmailSubject = data.EmailSubject;
        }
      }
    },
    async downloadDocument(id, documentName) {
      this.saving = true;
      let err, result;

      [err, result] = await this.$store.dispatch(
        types.DOWNLOAD_DOCUSIGN_TEMPLATE,
        id
      );

      if (result) {
        this.saveByteArray(documentName + ".docx", result);
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
      this.saving = false;
    },
    async searchTemplates(isRefresh = false) {
      if (
        (isRefresh == false &&
          JSON.stringify(this.filterParameters) == this.oldFilter) ||
        this.searching
      ) {
        this.$swal(
          "Warming!",
          "Filter parameters are same. Please change your filter.",
          "warning"
        );
        return;
      }
      let err, result;
      let payload = {
        DocumentName: this.filterParameters.DocumentName,
        TemplateTypeIds:
          this.filterParameters.TemplateTypes.length > 0
            ? this.filterParameters.TemplateTypes.map((x) => x.Id)
            : [],
        OfficeIds:
          this.filterParameters.Offices.length > 0
            ? this.filterParameters.Offices.map((x) => x.Id)
            : [],
        StateIds:
          this.filterParameters.States.length > 0
            ? this.filterParameters.States.map((x) => x.Id)
            : [],
        PolicyGroupIds:
          this.filterParameters.PolicyGroups.length > 0
            ? this.filterParameters.PolicyGroups.map((x) => x.PolicyGroupId)
            : [],
      };
      this.oldFilter = JSON.stringify(this.filterParameters);
      this.searching = true;
      this.isLoading = true;
      [err, result] = await this.$store.dispatch(
        types.SEARCH_DOCUSIGN_TEMPLATES,
        payload
      );
      if (result) {
        if (result.Data) {
          this.templateList = deepCopy(result.Data);
          this.deleteDocumentIds = [];
          this.selectedProduct = [];
        }
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
      this.searching = false;
      this.isLoading = false;
    },

    saveByteArray(name, byte) {
      let blob = new Blob([byte], { type: "application/docx" });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      let fileName = name;
      link.download = fileName;
      link.click();
    },
    async handleDelete(Id) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then(async (dialog) => {
          if (dialog.value) {
            let rowIndex = this.templateList.findIndex((x) => x.Id == Id);
            let dataToDelete = { Id: Id };
            let err, result;
            [err, result] = await this.$store.dispatch(
              types.DELETE_DOCUSIGN_TEMPLATE,
              dataToDelete
            );

            if (result) {
              this.templateList.splice(rowIndex, 1);
              this.$swal("Deleted!", result.Message, "success");
            } else {
              let errMsg = this.getApiErrorMessage(err);
              this.$swal("Error!", errMsg, "error");
            }
          }
        });
    },
    async bulkDelete() {
      let deleteCount =0
      if( this.selectedProduct)
      {
        deleteCount = this.selectedProduct.length;
      }
      let deleteText =
        deleteCount > 1 ? deleteCount + " documents" : "1 document";
      this.$swal
        .fire({
          title: "Delete documents",
          text:
            "Are your sure to delete selected " +
            deleteText +
            "? This cannot be undone.",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        })
        .then(async (dialog) => {
          if (dialog.value) {
            let idsArray = [];
            if (this.selectedProduct) {
              this.selectedProduct.forEach((x) => {
                idsArray.push(x.Id);
              });
            }
            this.deleteDocumentIds = idsArray;
            this.isDeletePopupOpen = true;
          }
        });
    },
  },
};
</script>

<style>
.loading {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.6);
  z-index: 1000;
}
</style>
