import types from "./types";
import api from "@/services/client/customerApi";
import adminApi from "@/services/admin/api";
import to from "@/helpers/to";

export default {
  state: {
    quickNotes: [],
  },
  actions: {
    GET_PORTAL_NOTE: async ({ commit, state }, payload) => {
      let err, result;
      [err, result] = await to(api.getCustomerMessages(payload));
      return [err, result];
    },

    GET_NOTES: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.getNotes(payload));

      return [err, result];
    },

    ADD_NOTE: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.addNotes(payload));

      return [err, result];
    },
    GET_QUICKNOTE_LIST: async ({ commit }) => {
      let err, result;
      [err, result] = await to(adminApi.getQuickNotes());
      return [err, result];
    },
  },
};
