<template>
  <div
    class="col-md-12 newPanelWrapper noPadding"
    style="
      background-color: #ffffff;
      z-index: 1000;
      padding-left: 0;
      padding-right: 0;
    "
  >
    <div class="closeButton" v-on:click.prevent.stop="closePanel">
      <i class="fas fa-window-close"></i>
    </div>

    <div class="form-group row mt-2 mr-1 ml-1">
      <div class="col-md-12 col-xs-12">
        <label class="generalLabel">Hardship Statement:</label>
        <textarea
          readonly
          v-model="hardshipStatement"
          class="form-control textareaInput generalTextBox mt-2 mb-2"
          rows="4"
        ></textarea>
      </div>
    </div>
  </div>
</template>

<script>
import utilitiesMixin from "@/mixins/utilitiesMixin";
export default {
  name: "HardshipStatementPopup",
  mixins: [utilitiesMixin],
  props: {
    statement: {
      Type: String,
      default: "",
    },
  },
  data() {
    return {
      hardshipStatement: "",
    };
  },
  async mounted() {
    this.hardshipStatement = this.statement;
  },
  methods: {
    closePanel() {
      this.$emit("closeHardshipStatementPopup");
    },
  },
};
</script>

<style>
.closeButton {
  position: absolute;
  top: -5px;
  right: 15px;
  z-index: 1000;
  font-size: 30px;
  cursor: pointer;
}
.closeButton:hover {
  color: #ff3860;
}
</style>
