import types from "./types";
import customerApi from "@/services/client/customerApi";
import to from "@/helpers/to";
import deepCopy from "@/helpers/deepCopy";
export default {
  state: {
    customerDispositions: [],
    customerLogs: [],
  },
  actions: {
    GET_CUSTOMERDISPOSITIONS: async ({ commit }, customerId) => {
      let err, result;

      [err, result] = await to(customerApi.getDispositions(customerId));

      return [err, result];
    },
    MODIFY_CUSTOMERDISPOSITIONS: ({ commit }, payload) => {
      commit(types.SET_CUSTOMERDISPOSITIONS, payload);
    },
    GET_DISPOSITIONLOGS: async ({ commit }, customerId) => {
      let err, result;

      [err, result] = await to(customerApi.getCustomerLogs(customerId));

      return [err, result];
    },
    MODIFY_DISPOSITIONLOGS: ({ commit, state }, payload) => {
      let newDispos = deepCopy(state.customerLogs);
      newDispos.push(payload[0]);
      commit(types.SET_DISPOSITIONLOGS, newDispos);
    },
  },
  mutations: {
    SET_CUSTOMERDISPOSITIONS: (state, payload) => {
      state.customerDispositions = payload;
    },
    SET_DISPOSITIONLOGS: (state, payload) => {
      state.customerLogs = payload;
    },
  },
};
