<template>
  <div class="col-md-12">
    <ReportSummary
      :exportData="reportData"
      :reportName="'UsBankElanScrubReport'"
      :reportClass="'ScrubReports'"
      @updateLoadingStatus="reportLoading = $event"
      @setPayload="setPayload"
      @clearReport="clearReport"
      @setPagination="setPagination"
      :loadingSpinner="loadingSpinner"
      :recordCount="recordCount"
    />
    <div
      class="datagrid-table gcsTabsTableScroll report-wrapper"
      :style="'max-height: ' + reportsPanelHeight + 'px; overflow-y:auto;'"
    >
      <o-table
        :data="filteredUsBankElanScrubReport"
        :paginated="isPaginated && filteredUsBankElanScrubReport.length > 0"
        :per-page="perPage"
        :pagination-simple="isPaginationSimple"
        :pagination-position="paginationPosition"
        :sticky-header="true"
      >
        <o-table-column
          field="Creditor.AcctN"
          label="Creditor Account Number"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="AccountNumberFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary">
              {{ props.row.Creditor == null ? "" : props.row.Creditor.AcctN }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.Creditor == null ? "" : props.row.Creditor.AcctN }}
            </span>
          </template>
        </o-table-column>

        <o-table-column field="Ssn" label="Primary Ssn" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="SsnFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.Ssn }}
          </template>
        </o-table-column>
        <o-table-column
          field="Name"
          label="Primary Full Name"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="NameFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.Name }}
          </template>
        </o-table-column>
        <o-table-column
          field="CoCustomer.Ssn"
          label="Co App SSN"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CoAppSsnFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.CoCustomer == null ? "" : props.row.CoCustomer.Ssn }}
          </template>
        </o-table-column>

        <o-table-column
          field="CoCustomer.Name"
          label="Co App Full Name"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CoAppNameFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.CoCustomer == null ? "" : props.row.CoCustomer.Name }}
          </template>
        </o-table-column>

        <o-table-column field="Address.Line1" label="Full Address" sortable>
          <template v-slot="props">
            {{
              props.row.Address == null
                ? ""
                : props.row.Address.Line1 +
                  " " +
                  props.row.Address.City +
                  " " +
                  props.row.Address.ShortCode +
                  " " +
                  props.row.Address.Zip
            }}
          </template>
        </o-table-column>

        <o-table-column
          field="PolicyGroup"
          label="Policy Group"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="PolicyGroupFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.PolicyGroup }}
          </template>
        </o-table-column>
      </o-table>
      <section
        v-if="filteredUsBankElanScrubReport.length == 0"
        class="section"
        style="text-align: center"
      >
        <div class="content has-text-grey has-text-centered mb-4">
          <p class="noMargin">
            <i
              style="font-size: 24px; color: #939393"
              class="ri-question-line"
            ></i>
          </p>
          <p class="noMargin">Nothing here.</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import ReportSummary from "./ReportSummary.vue";
import utilitiesMixin from "@/mixins/utilitiesMixin";

export default {
  name: "UsBankElanScrubReport",
  components: { ReportSummary },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      reportsPanelHeight: 300,
      loadingSpinner: false,
      isPaginationSimple: false,
      isPaginated: true,
      paginationPosition: "both",
      currentPage: 1,
      perPage: 200,
      reportLoading: false,
      reportData: [],
      recordCount: 0,

      usBankElanScrubReportInitSummary: {
        Creditor: {
          AcctN: "",
        },
      },

      AccountNumberFilter: "",
      SsnFilter: "",
      NameFilter: "",
      CoAppSsnFilter: "",
      CoAppNameFilter: "",
      PolicyGroupFilter: "",
    };
  },
  computed: mapState({
    usBankElanScrubList: (state) => state.reports.usBankElanScrubList,

    filteredUsBankElanScrubReport() {
      let rtn = [];
      let tmpList =
        this.usBankElanScrubList.length > 0
          ? this.usBankElanScrubList.filter((item) => {
              return (
                this.filterString(
                  item.Creditor == null ? "" : item.Creditor.AcctN,
                  this.AccountNumberFilter
                ) &&
                this.filterString(item.Ssn, this.SsnFilter) &&
                this.filterString(item.Name, this.NameFilter) &&
                this.filterString(item.CoCustomer.Ssn, this.CoAppSsnFilter) &&
                this.filterString(item.PolicyGroup, this.PolicyGroupFilter) &&
                this.filterString(item.CoCustomer.Name, this.CoAppNameFilter)
              );
            })
          : [];

      if (tmpList.length == 0) {
        this.reportData = [];
        return rtn;
      }
      let totalCustomer = tmpList.length.toString();

      this.usBankElanScrubReportInitSummary.Creditor.AcctN =
        "Total: " + totalCustomer;
      this.recordCount = tmpList.length;
      this.usBankElanScrubReportInitSummary.IsSummary = true;

      rtn.push(...tmpList);
      rtn.push(this.usBankElanScrubReportInitSummary);
      this.reportData = rtn;

      return rtn;
    },
  }),
  async mounted() {
    this.reportsPanelHeight = this.setReportGridHeight(
      window.innerHeight,
      document.getElementById("reportsSummary")
    );
    await this.sleep(1000);
    this.fixStickyHeaders();
  },
  methods: {
    setPagination(data) {
      this.isPaginated = data;
    },

    async setPayload(value) {
      this.loadingSpinner = true;
      let err, result;
      [err, result] = await this.$store.dispatch(
        types.GET_USBANK_ELAN_SCRUB_REPORT,
        value
      );
      if (result && result.Data) {
        let apiResult = result.Data;
        let usBankFormattedData = [];
        if (result) {
          if (apiResult.length > 0) {
            for (let item of apiResult) {
              if (item.Ssn != "") {
                item.Ssn = await this.AES256_GCM_decrypt(item.Ssn);
              }
              if (item.CoCustomer != null && item.CoCustomer.Ssn != null) {
                item.CoCustomer.Ssn = await this.AES256_GCM_decrypt(
                  item.CoCustomer.Ssn
                );
              }
            }
            for (let item of apiResult) {
              if (item.Creditors) {
                for (let c of item.Creditors) {
                  usBankFormattedData.push({
                    CustomerId: item.CustomerId,
                    FirstName: item.FirstName,
                    LastName: item.LastName,
                    Name: item.Name,
                    Ssn: item.Ssn,
                    Dob: item.Dob,
                    CoCustomer: {
                      FirstName:
                        item.CoCustomer == null
                          ? ""
                          : item.CoCustomer.FirstName,
                      LastName:
                        item.CoCustomer == null ? "" : item.CoCustomer.LastName,
                      Name: item.CoCustomer == null ? "" : item.CoCustomer.Name,
                      Ssn: item.CoCustomer == null ? "" : item.CoCustomer.Ssn,
                      Dob: item.CoCustomer == null ? "" : item.CoCustomer.Dob,
                    },
                    Address: {
                      Line1: item.Address == null ? "" : item.Address.Line1,
                      City: item.Address == null ? "" : item.Address.City,
                      ShortCode:
                        item.Address == null ? "" : item.Address.ShortCode,
                      Zip: item.Address == null ? "" : item.Address.Zip,
                    },
                    Creditor: {
                      Name: c.Name,
                      CurrName: c.CurrName,
                      AcctN: c.AcctN,
                      CurrAmt: c.CurrAmt,
                    },
                    PolicyGroup: item.PolicyGroup,
                  });
                }
              }
            }
            await this.$store.dispatch(
              types.MODIFY_USBANK_ELAN_SCRUB_REPORT,
              usBankFormattedData
            );
          }
        }
      }
      this.loadingSpinner = false;
      await this.sleep(500);
      this.fixStickyHeaders();
    },
    clearReport() {
      this.$store.dispatch(types.RESET_REPORTS);
      this.reportData = [];
    },
  },
};
</script>

<style scoped></style>
