<template>
  <div class="row admin-page">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
      <div class="form-group row">
        <div class="editPanel" v-if="selectedDid.Id > 0 && editPanelOpen">
          <div class="editPanelRow">
            <div
              class="editPanelColumnHeader col-xl-3 col-lg-3 col-md-6 col-sm-6 noMargin text-left"
            >
              DID
            </div>
            <div
              class="editPanelColumnHeader col-xl-3 col-lg-3 col-md-6 col-sm-6 noMargin text-left"
            >
              Assign Type
            </div>
            <div
              class="editPanelColumnHeader col-xl-3 col-lg-3 col-md-6 col-sm-6 noMargin text-left"
            >
              Assigned To
            </div>
            <div
              class="editPanelColumnHeader col-xl-3 col-lg-3 col-md-6 col-sm-6 noMargin text-left"
            >
              Actions
            </div>
          </div>
          <div class="editPanelRow">
            <div
              class="editPanelColumn col-xl-3 col-lg-3 col-md-6 col-sm-6 noMargin"
            >
              <label class="form-label col-form-label float-left">{{
                formatPhone(selectedDid.Did)
              }}</label>
            </div>
            <div
              class="editPanelColumn col-xl-3 col-lg-3 col-md-6 col-sm-6 noMargin"
            >
              <select
                v-if="oldValue.DidAssignTypeId == 1"
                v-model="selectedDid.DidAssignTypeId"
                id="ddlAssignTypes"
                class="form-control float-left"
              >
                <option
                  v-for="item in assignTypeList"
                  v-show="!(item.Id == 5 && companyDidExists)"
                  :key="item.Id"
                  :value="item.Id"
                >
                  {{ item.Name }}
                </option>
              </select>
              <label
                v-if="oldValue.DidAssignTypeId != 1"
                class="form-label col-form-label float-left"
                >{{ selectedDid.DidAssignType }}</label
              >
            </div>
            <div
              class="editPanelColumn col-xl-3 col-lg-3 col-md-6 col-sm-6 noMargin"
            >
              <select
                v-if="
                  selectedDid.DidAssignTypeId == 2 &&
                  oldValue.AssignedUserId == 0
                "
                v-model="selectedDid.AssignedUserId"
                id="ddlAssignUserFullName"
                class="form-control float-left"
              >
                <option value="0">Select</option>
                <option
                  v-for="item in assignableUsers"
                  :key="item.Id"
                  :value="item.Id"
                >
                  {{ item.FullName }}
                </option>
              </select>
              <select
                v-if="
                  selectedDid.DidAssignTypeId == 4 &&
                  oldValue.AssignedCampaignId == 0
                "
                v-model="selectedDid.AssignedCampaignId"
                id="ddlAssignUserName"
                class="form-control float-left"
              >
                <option value="0">Select</option>
                <option
                  v-for="item in assignableCampaigns"
                  :key="item.Id"
                  :value="item.Id"
                >
                  {{ item.Name }}
                </option>
              </select>
              <select
                v-if="
                  selectedDid.DidAssignTypeId == 6 &&
                  oldValue.AssignedOfficeId == 0
                "
                v-model="selectedDid.AssignedOfficeId"
                id="ddlAssignUserAssignableOffices"
                class="form-control float-left"
              >
                <option value="0">Select</option>
                <option
                  v-for="item in assignableOffices"
                  :key="item.Id"
                  :value="item.Id"
                >
                  {{ item.Name }}
                </option>
              </select>
              <label
                v-if="
                  oldValue.AssignedUserId > 0 ||
                  oldValue.AssignedCampaignId > 0 ||
                  oldValue.AssignedOfficeId > 0
                "
                class="form-label col-form-label float-left"
                >{{ getAssignedColumnText(selectedDid.DidAssignTypeId) }}</label
              >
            </div>
            <div
              class="editPanelColumn col-xl-3 col-lg-3 col-md-6 col-sm-6 noMargin text-left"
            >
              <button
                type="button"
                @click="unassignDid()"
                v-if="oldValue.DidAssignTypeId != 1"
                :disabled="savingDid"
                style="margin: 5px"
                class="btn btn-icon waves-effect waves-light btn-warning btn-sm"
              >
                Unassign
                <i v-if="savingDid" class="fas fa-spinner mr-1"></i>
              </button>
              <button
                type="button"
                v-if="oldValue.DidAssignTypeId == 1"
                @click="updateDid()"
                :disabled="savingDid"
                style="margin: 5px"
                class="btn btn-icon waves-effect waves-light btn-success btn-sm"
              >
                <i class="fas fa-check"></i>
                <i v-if="savingDid" class="fas fa-spinner mr-1"></i>
              </button>
              <button
                @click="cancel()"
                class="btn btn-icon waves-effect waves-light btn-sm btn-danger"
                style="margin: 5px"
              >
                <i class="fas fa-times"></i>
              </button>
            </div>
          </div>
        </div>
        <div
          class="datagrid-table"
          style="
            width: 100%;
            height: 100%;
            max-height: 600px;
            overflow-y: auto;
            overflow-x: hidden;
          "
        >
          <section>
            <o-table :data="isEmpty ? [] : didList">
              <o-table-column
                field="Did"
                label="DID"
                width="25%"
                sortable
                v-slot="props"
                >{{ formatPhone(props.row.Did) }}</o-table-column
              >
              <o-table-column
                field="PurchaseDate"
                label="Purchase Date"
                sortable
                v-slot="props"
                >{{ formatDate(props.row.PurchaseDate) }}</o-table-column
              >
              <o-table-column
                field="DidAssignType"
                label="Assign Type"
                sortable
                v-slot="props"
                >{{ props.row.DidAssignType }}</o-table-column
              >
              <o-table-column label="Assigned To" v-slot="props">
                {{ getAssignedColumnValue(props.row) }}
              </o-table-column>

              <o-table-column v-slot="props">
                <button
                  @click="editDid(props.row)"
                  class="btn btn-info btn-xs is-small is-light"
                >
                  <i class="ri-pencil-fill"></i>
                </button>
              </o-table-column>

              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p class="noMargin">
                    <i
                      style="font-size: 24px; color: #939393"
                      class="remixicon-question-line"
                    ></i>
                  </p>
                  <p class="noMargin">Nothing here.</p>
                </div>
              </section>
            </o-table>
          </section>
          <section v-if="isEmpty" style="text-align: center" class="mb-4">
            <div class="content has-text-grey has-text-centered">
              <p class="noMargin">
                <i
                  style="font-size: 24px; color: #939393"
                  class="ri-loader-fill"
                ></i>
              </p>
              <p class="noMargin">Loading.</p>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import types from "./types";
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import _orderby from "lodash";

const init = {
  Id: 0,
  Did: "",
  DidAssignTypeId: 1,
  DidAssignType: "",
  AssignedUser: "",
  AssignedUserId: 0,
  AssignedCampaign: "",
  AssignedCampaignId: 0,
  AssignedOfficeId: 0,
  AssignedOffice: "",
};
export default {
  name: "DidsManagement",
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      selectedDid: Object.assign({}, init),
      oldValue: Object.assign({}, init),
      editPanelOpen: false,
      companyDidExists: false,
      savingDid: false,
      didList: [],
      isEmpty: true,
    };
  },
  computed: mapState({
    assignTypeList: (state) => state.didsManagement.assignTypes,
    assignableUsers: (state) => state.didsManagement.assignableUsers,
    assignableCampaigns: (state) => state.didsManagement.assignableCampaigns,
    assignableOffices: (state) => state.didsManagement.assignableOffices,
  }),
  async mounted() {
    await Promise.all([
      this.$store.dispatch(types.GET_ASSIGN_TYPES),
      this.bindAssignableUsers(),
      this.bindAssignableCampaings(),
      this.bindAssignableOffices(),
      this.bindDids(),
    ]);
  },
  methods: {
    async bindAssignableUsers() {
      await this.$store.dispatch(types.GET_ASSIGNABLE_USERS);
    },
    async bindAssignableCampaings() {
      await this.$store.dispatch(types.GET_ASSIGNABLE_CAMPAIGNS);
    },
    async bindAssignableOffices() {
      await this.$store.dispatch(types.GET_ASSIGNABLE_OFFICES);
    },
    getAssignedColumnText(assignTypeId) {
      if (assignTypeId == 2) return this.selectedDid.AssignedUser;
      else if (assignTypeId == 3) return this.selectedDid.AssignedCampaign;
      else if (assignTypeId == 6) return this.selectedDid.AssignedOffice;
      else return "";
    },
    getAssignedColumnValue(row) {
      if (row.DidAssignTypeId == 2) return row.AssignedUser;
      else if (row.DidAssignTypeId == 4) return row.AssignedCampaign;
      else if (row.DidAssignTypeId == 6) return row.AssignedOffice;
      else return "";
    },
    async unassignDid() {
      this.savingDid = true;
      let err, result;
      [err, result] = await this.$store.dispatch(types.SET_DID_ASSIGN_TYPE, {
        DidId: this.selectedDid.Id,
        DidAssignTypeId: 1,
      });
      if (result) {
        if (result.Message)
          this.$swal.fire("Success!", result.Message, "success");

        this.bindDids();
        if (this.selectedDid.DidAssignTypeId == 2) {
          this.bindAssignableUsers();
        } else if (this.selectedDid.DidAssignTypeId == 4) {
          this.bindAssignableCampaings();
        } else if (this.selectedDid.DidAssignTypeId == 6) {
          this.bindAssignableOffices();
        }
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
      this.savingDid = false;
      this.editPanelOpen = false;
    },
    async bindDids() {
      let err, result;
      [err, result] = await this.$store.dispatch(types.GETDIDLIST, 0);
      if (result) {
        if (result.Data) {
          this.didList = result.Data;
          this.isEmpty = false;
          this.companyDidExists =
            this.didList.length > 0
              ? this.didList.filter((x) => x.DidAssignTypeId == 5).length > 0
                ? true
                : false
              : false;
        }
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
      this.isEmpty = false;
    },
    async updateDid() {
      this.savingDid = true;
      if (this.selectedDid.DidAssignTypeId == 2) {
        if (this.selectedDid.AssignedUserId == 0) {
          this.$swal.fire("Warning!", "No user selected!", "warning");
          this.savingDid = false;
          return;
        }

        let err, result;
        [err, result] = await this.$store.dispatch(types.SET_DID_ASSIGN_USER, {
          DidId: this.selectedDid.Id,
          UserId: this.selectedDid.AssignedUserId,
        });
        if (result) {
          this.$swal.fire("Success!", result.Message, "success");
          this.bindDids();
          this.bindAssignableUsers();
          this.bindAssignableOffices();
        } else {
          let errMsg = this.getApiErrorMessage(err);
          this.$swal("Error!", errMsg, "error");
        }
      } else if (this.selectedDid.DidAssignTypeId == 4) {
        if (this.selectedDid.AssignedCampaignId == 0) {
          this.$swal.fire("Warning!", "No campaign selected!", "warning");
          this.savingDid = false;
          return;
        }

        let err, result;
        [err, result] = await this.$store.dispatch(
          types.SET_DID_ASSIGN_CAMPAIGN,
          {
            DidId: this.selectedDid.Id,
            CampaignId: this.selectedDid.AssignedCampaignId,
          }
        );
        if (result) {
          this.$swal.fire("Success!", result.Message, "success");
          this.bindDids();
          this.bindAssignableCampaings();
          this.bindAssignableOffices();
        } else {
          let errMsg = this.getApiErrorMessage(err);
          this.$swal("Error!", errMsg, "error");
        }
      } else if (this.selectedDid.DidAssignTypeId == 6) {
        if (this.selectedDid.AssignedOfficeId == 0) {
          this.$swal.fire("Warning!", "No office selected!", "warning");
          this.savingDid = false;
          return;
        }

        let err, result;
        [err, result] = await this.$store.dispatch(types.UPDATE_OFFICE_DID, {
          DidId: this.selectedDid.Id,
          OfficeId: this.selectedDid.AssignedOfficeId,
        });
        if (result) {
          this.$swal.fire("Success!", result.Message, "success");
          this.bindDids();
          this.bindAssignableCampaings();
          this.bindAssignableOffices();
        } else {
          let errMsg = this.getApiErrorMessage(err);
          this.$swal("Error!", errMsg, "error");
        }
      } else if (this.selectedDid.DidAssignTypeId == 5) {
        let err, result;
        [err, result] = await this.$store.dispatch(types.UPDATE_COMPANY_DID, {
          DidId: this.selectedDid.Id,
        });
        if (result) {
          this.$swal.fire("Success!", result.Message, "success");
          this.bindDids();
          this.bindAssignableCampaings();
          this.bindAssignableOffices();
        } else {
          let errMsg = this.getApiErrorMessage(err);
          this.$swal("Error!", errMsg, "error");
        }
      } else {
        let err, result;

        [err, result] = await this.$store.dispatch(types.SET_DID_ASSIGN_TYPE, {
          DidId: this.selectedDid.Id,
          DidAssignTypeId: this.selectedDid.DidAssignTypeId,
        });

        if (result) {
          if (result.Message)
            this.$swal.fire("Success!", result.Message, "success");

          this.bindDids();
        } else {
          let errMsg = this.getApiErrorMessage(err);
          this.$swal("Error!", errMsg, "error");
        }
      }
      this.savingDid = false;
      this.editPanelOpen = false;
    },
    cancel() {
      this.selectedDid = Object.assign({}, init);
    },
    editDid(row) {
      this.editPanelOpen = true;
      this.oldValue = Object.assign({}, row);
      this.selectedDid = Object.assign({}, row);
    },
  },
};
</script>

<style>
.editPanel {
  width: 100%;
  padding: 0;
  margin: 5px 2px;
  border: 2px solid #3bafda;
}
.editPanelRow {
  width: 100%;
  float: left;
}
.editPanelColumnHeader {
  float: left;
  padding: 5px;
  background-color: #3bafda;
  color: white;
  text-align: center;
}
.editPanelColumn {
  float: left;
  padding: 5px;
}
.phoneList {
  width: 300px;
  float: left;
  height: 400px;
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  margin: 5px 15px;
  border: 2px solid #3bafda;
}
.purchaseButtonWrapper {
  width: 80px;
  float: left;
  margin: 5px;
}
.phoneCheckBox {
  width: 16px;
  float: left;
  margin: 0 5px;
}
</style>
