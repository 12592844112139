<template>
  <div class="row admin-page officePage">
    <div v-if="saving" class="loading">
      <div class="text-center" style="margin-top: 150px">
        <i class="spinner-grow text-info m-2"></i>
      </div>
    </div>
    <div class="col-xl-3 col-lg-4 col-md-12 col-sm-12">
      <div class="filter form-group row">
        <button
          class="col-xl-12 btn btn-primary waves-effect waves-light addTemplateButton"
          @click="handleAddNew()"
        >
          Add New Settings
        </button>
        <div class="col-lg-12 col-md-12 col-sm-12 noPadding">
          <input
            autocomplete="off"
            type="text"
            class="form-control"
            placeholder="Filter Settings"
          />
        </div>
        <div class="list col-md-12">
          <div
            class="office item row"
            v-for="(item, index) in globalSettingsProceeded"
            :key="item.Id"
            :class="{ active: activeItem === index }"
            :style="[
              index % 2 === 0
                ? { 'background-color': '#F1EFEF' }
                : { 'background-color': '#FFFFFF' },
            ]"
            :disabled="saving"
            @click="() => handleSelection(item, index)"
          >
            <span :class="{ adminActiveMenuItem: activeItem === index }">
              <i class="fe-settings"></i>
              <span style="margin-left: 8px">{{ item.Name }}</span>
            </span>
          </div>
        </div>
        <div
          v-if="isMobileDevice()"
          style="
            border-top: 1px solid rgb(0, 0, 0, 0);
            margin: 20px 0 0 9px;
            padding-bottom: 55px;
          "
          class="col-md-12 scrollToSelectedItem"
        ></div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-8 col-md-12 col-sm-12" v-if="isEditing">
      <div class="panelContent" style="padding-top: 0">
        <div class="form-group row">
          <div class="col-md-12">
            <label for="globalNameInput">Name: *</label>
            <input
              autocomplete="off"
              id="globalNameInput"
              class="form-control"
              type="text"
              v-model="selectedRow.Name"
              placeholder="Enter Name"
              :class="v$.selectedRow.Name.$error ? 'has-error' : ''"
              @blur="v$.selectedRow.Name.$touch"
            />
          </div>
          <div class="col-md-6">
            <label for="apiUserInput">Api User: *</label>
            <input
              autocomplete="off"
              id="apiUserInput"
              v-model="selectedRow.ApiUser"
              class="form-control"
              type="text"
              placeholder="Enter Api User"
              @change="getPolicyGroups(true)"
              :class="v$.selectedRow.ApiUser.$error ? 'has-error' : ''"
              @blur="v$.selectedRow.ApiUser.$touch"
            />
          </div>

          <div class="col-md-6">
            <label for="apiTokenInput">Api Token: *</label>
            <input
              autocomplete="off"
              id="apiTokenInput"
              class="form-control"
              type="text"
              v-model="selectedRow.ApiToken"
              placeholder="Enter Api Token"
              @change="getPolicyGroups(true)"
              :class="v$.selectedRow.ApiToken.$error ? 'has-error' : ''"
              @blur="v$.selectedRow.ApiToken.$touch"
            />
          </div>
          <div class="col-md-6 mt-2">
            <label for="apiCompanyId">Api Company Id: *</label>
            <input
              autocomplete="off"
              id="apiCompanyId"
              class="form-control"
              v-model="selectedRow.ApiCompanyId"
              type="text"
              placeholder="Enter Api Company Id"
              :class="v$.selectedRow.ApiCompanyId.$error ? 'has-error' : ''"
              @blur="v$.selectedRow.ApiCompanyId.$touch"
            />
          </div>

          <div class="col-md-6 mt-2">
            <label for="PA-selectDate">Start Date: *</label>
            <DatePicker
              mode="date"
              :locale="'en-US'"
              :model-config="modelConfig"
              :popover="{ visibility: 'click' }"
              v-model="selectedRow.StartDate"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  id="PA-selectDate"
                  :class="v$.selectedRow.StartDate.$error ? 'has-error' : ''"
                  @blur="v$.selectedRow.StartDate.$touch"
                  class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                  :value="inputValue"
                  v-on="inputEvents"
                  placeholder="Select Date"
                />
              </template>
            </DatePicker>
          </div>
          <div class="col-md-6 mt-4">
            <label for="apiOfficesId">Offices:</label>
            <VueMultiselect
              id="apiOfficesId"
              :options="filteredOffices"
              v-model="selectedRow.OfficeList"
              :multiple="true"
              :close-on-select="false"
              placeholder="Please Select"
              label="Name"
              track-by="Id"
            />
          </div>
          <div class="col-md-6 mt-4">
            <label for="states-gsm">States:</label>
            <VueMultiselect
              id="states-gsm"
              v-model="selectedRow.StateList"
              :options="states"
              :multiple="true"
              :close-on-select="false"
              placeholder="Please Select"
              label="Name"
              track-by="Id"
            />
          </div>

          <div class="col-md-6 mt-4 noPadding">
            <div class="col-md-6">
              <input
                autocomplete="off"
                id="isTestModeCbox"
                v-model="selectedRow.IsTestMode"
                @change="getPolicyGroups(true)"
                type="checkbox"
                class="checkbox-input generalCheckBox"
                style="
                  border-radius: 4px !important;
                  border: solid 1px #d1d1d1 !important;
                "
              />

              <label
                class="generalLabel"
                style="font-weight: normal"
                for="isTestModeCbox"
                >Is Test Mode</label
              >
            </div>
          </div>
          <div v-if="selectedRow.IsDpg != true" class="col-md-12 mt-4">
            <label for="policyGroupIds">Policy Groups:</label>

            <div class="col-md-12 noMargin noPadding">
              <label style="font-size: 12px; color: #939393"
                >(Please contact Global/Zenith to configure the Policy Groups in
                the CRM)</label
              >
            </div>
            <label
              v-if="policyValidationMessage.length > 0"
              class="text-danger col-md-12 noPadding"
              >{{ policyValidationMessage }}</label
            >
            <table
              id="policyGroupIds"
              class="datagrid-table gcsTabsTableScroll col-md-12"
              v-if="policyGroupData.length > 0"
            >
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Name</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in policyGroupData" :key="index">
                  <td>{{ item.PolicyGroupId }}</td>
                  <td>{{ item.Name }}</td>
                  <td>{{ item.IsActive ? "ACTIVE" : "INACTIVE" }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            class="col-md-12 mt-5 noPadding"
            style="display: flex; justify-content: flex-end"
          >
            <div class="col-md-3">
              <button
                style="width: 100%"
                class="btn btn-danger btn-bordered-danger waves-effect"
                id="deletteButton-gsm"
                @click="handleDelete()"
                v-if="selectedRow.Id > 0"
                :disabled="saving"
              >
                Delete
              </button>
            </div>
            <div class="col-md-3">
              <button
                style="width: 100%"
                class="btn btn-success btn-bordered-success waves-effect"
                id="officeSettingsSaveButton"
                @click="handleSave()"
                :disabled="v$.$invalid || saving"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useValidate from "@vuelidate/core";
import types from "./types";
import { required } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import _orderby from "lodash";
import deepCopy from "@/helpers/deepCopy";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import VueMultiselect from "vue-multiselect";
import globalTypes from "@/store/types";
import { DatePicker } from "v-calendar";
import formatMixin from "@/mixins/formatMixin";

const init = {
  Id: 0,
  Name: "",
  ApiUser: "",
  ApiToken: "",
  ApiCompanyId: "",
  StateList: [],
  OfficeList: [],
  PolicyGroups: [],
  IsTestMode: false,
  StartDate: "",
  IsDpg: false,
};
export default {
  name: "GlobalSettingsManagement",
  components: { VueMultiselect, DatePicker },

  mixins: [utilitiesMixin, formatMixin],
  data() {
    return {
      v$: useValidate(),
      activeItem: null,
      selectedRow: Object.assign({}, init),
      hasAccessOtherOffices: false,
      userOfficeId: 0,
      modelConfig: {
        type: "string",
        mask: "MM/DD/YYYY",
      },
      policyGroupData: [],
      policyValidationMessage: "",
      isEditing: false,
      oldValue: "",
      saving: false,
      globalSettingsProceeded: [],
    };
  },
  computed: mapState({
    globalSettingList: (state) =>
      state.globalSettingsManagement.globalSettingList,
    states: (state) => state.globals.states,
    offices: (state) => state.globals.offices,

    filteredOffices() {
      return _orderby.orderBy(this.offices);
    },
  }),
  async mounted() {
    await Promise.all([
      this.$store.dispatch(globalTypes.GET_STATES),
      this.$store.dispatch(globalTypes.GET_OFFICE_NAMES),
      this.refreshGlobalSettings(),
    ]);

    await this.getPolicyGroups(false);

    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo != null && userInfo.moduleFunctions != null) {
      this.userOfficeId = userInfo.officeId;
    }
    this.hasAccessOtherOffices = this.canSeeAllOffices();
  },
  methods: {
    async refreshGlobalSettings() {
      await this.$store.dispatch(types.GET_GLOBAL_SETTINGS);
      this.globalSettingsProceeded = await this.decyrptedGlobalSettings();
      //await this.getPolicyGroups(false);
    },
    async decyrptedGlobalSettings() {
      let rtn = deepCopy(this.globalSettingList);

      for (let item of rtn) {
        item.ApiUser = await this.AES256_GCM_decrypt(item.ApiUser);
        item.ApiToken = await this.AES256_GCM_decrypt(item.ApiToken);
        item.ApiCompanyId = await this.AES256_GCM_decrypt(item.ApiCompanyId);
      }

      return rtn;
    },
    async getPolicyGroups(isValidationRequired) {
      let msg = "Valid api user and token are required to get Policy Groups";
      if (
        this.selectedRow.ApiUser.length > 0 &&
        this.selectedRow.ApiToken.length > 0 &&
        this.selectedRow.IsDpg != true
      ) {
        let payloadData = {
          ApiUserEnc: await this.AES256_GCM_ENCRYPT(this.selectedRow.ApiUser),
          ApiTokenEnc: await this.AES256_GCM_ENCRYPT(this.selectedRow.ApiToken),
          IsTest: this.selectedRow.IsTestMode,
        };
        let err, result;
        [err, result] = await this.$store.dispatch(
          types.GET_POLICY_GROUPS,
          payloadData
        );
        if (result && result.Data) {
          this.policyGroupData = result.Data;
          this.policyValidationMessage = "";
        } else {
          this.policyGroupData = [];
          if (isValidationRequired) this.policyValidationMessage = msg;
          else this.policyValidationMessage = "";
        }
      } else {
        if (isValidationRequired) this.policyValidationMessage = msg;
        else this.policyValidationMessage = "";
        this.policyGroupData = [];
      }
    },
    async handleAddNew() {
      let isCancelled = false;
      if (
        this.oldValue != JSON.stringify(this.selectedRow) &&
        JSON.stringify(this.selectedRow) != JSON.stringify(init)
      ) {
        await this.$swal({
          title: "You have unsaved changes",
          text: "You will lose it if you don't save",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, continue without saving!",
        }).then(async (dialog) => {
          if (dialog.value) {
            isCancelled = false;
          } else {
            isCancelled = true;
            return;
          }
        });
      }
      if (!isCancelled) {
        this.policyValidationMessage = "";
        this.policyGroupData = [];
        this.isEditing = true;
        this.selectedRow = deepCopy(init);
        this.activeItem = null;
        this.isEditing = true;
      }
    },
    resetSelection() {
      this.selectedRow = Object.assign({}, init);
      this.isEditing = false;
      this.oldValue = "";
      this.activeItem = null;
    },
    async setSelectionAfterRefresh(selectedId) {
      let indx = this.globalSettingsProceeded.findIndex(
        (x) => x.Id == selectedId
      );
      this.selectedRow = deepCopy(this.globalSettingsProceeded[indx]);
      await this.getPolicyGroups(true);
      this.oldValue = JSON.stringify(this.selectedRow);
      this.activeItem = indx;
      this.isEditing = true;
    },
    async handleSelection(row, index) {
      let isCancelled = false;
      if (
        this.selectedRow &&
        JSON.stringify(this.selectedRow) != this.oldValue &&
        JSON.stringify(this.selectedRow) != JSON.stringify(init)
      ) {
        await this.$swal
          .fire({
            title: "You have unsaved changes",
            text: "You will lose it if you don't save",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, continue without saving!",
          })
          .then(async (dialog) => {
            if (dialog.value) {
              isCancelled = false;
            } else {
              isCancelled = true;
              return;
            }
          });
      }

      if (!isCancelled) {
        this.selectedRow = deepCopy(row);
        await this.getPolicyGroups(true);
        this.oldValue = JSON.stringify(row);
        this.activeItem = index;
        this.isEditing = true;
      }
      isCancelled = false;

      let el = this.$el.querySelector(".scrollToSelectedItem");
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
    async handleDelete() {
      this.saving = true;
      let dataToDelete = {
        Id: this.selectedRow.Id,
      };
      let err, result;
      [err, result] = await this.$store.dispatch(
        types.DELETE_GLOBAL_SETTING,
        dataToDelete
      );
      if (result) {
        this.$swal("Success!", result.Message, "success");
        await this.refreshGlobalSettings();
        //this.selectedRow.Id = result.Data.Id;
        //this.list.push(this.selectedRow);
        //this.oldValue = JSON.stringify(this.selectedRow);
        this.resetSelection();
        //this.oldValue = JSON.stringify(this.selectedRow);
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
      this.saving = false;
    },
    async handleSave() {
      if (this.oldValue == JSON.stringify(this.selectedRow)) {
        this.$swal.fire("Warning!", "No changes detected!", "warning");
      } else {
        this.saving = true;

        if (
          this.selectedRow.IsDpg != true &&
          (this.policyGroupData == null || this.policyGroupData.length == 0)
        ) {
          this.$swal.fire(
            "Warning!",
            "No policy group found for this credentials.",
            "warning"
          );
          this.saving = false;
          return;
        }

        if (this.selectedRow.Id == 0) {
          //insert

          let dataToInsert = {
            Name: this.selectedRow.Name,
            ApiUser: await this.AES256_GCM_ENCRYPT(this.selectedRow.ApiUser),
            ApiToken: await this.AES256_GCM_ENCRYPT(this.selectedRow.ApiToken),
            ApiCompanyId: await this.AES256_GCM_ENCRYPT(
              this.selectedRow.ApiCompanyId
            ),
            IsTestMode: this.selectedRow.IsTestMode,
            IsDpg: this.selectedRow.IsDpg,
            StartDate: this.selectedRow.StartDate,
            OfficeList: this.selectedRow.OfficeList.map((x) => x.Id),
            StateList: this.selectedRow.StateList.map((x) => x.Id),
            PolicyGroupList:
              this.selectedRow.IsDpg == true ? [] : this.policyGroupData,
          };

          let err, result;
          [err, result] = await this.$store.dispatch(
            types.INSERT_GLOBAL_SETTING,
            dataToInsert
          );

          //this.setSelectionAfterRefresh
          if (result) {
            this.$swal("Success!", result.Message, "success");
            await this.refreshGlobalSettings();
            let rowId = 0;
            if (result.Data && result.Data.Id) rowId = result.Data.Id;
            if (rowId == 0) this.resetSelection();
            else {
              this.setSelectionAfterRefresh(rowId);
            }
          } else {
            let errMsg = this.getApiErrorMessage(err);
            this.$swal("Error!", errMsg, "error");
          }
          this.saving = false;
        } else {
          //update
          let dataToUpdate = {
            Id: this.selectedRow.Id,
            Name: this.selectedRow.Name,
            ApiUser: await this.AES256_GCM_ENCRYPT(this.selectedRow.ApiUser),
            ApiToken: await this.AES256_GCM_ENCRYPT(this.selectedRow.ApiToken),
            ApiCompanyId: await this.AES256_GCM_ENCRYPT(
              this.selectedRow.ApiCompanyId
            ),
            IsTestMode: this.selectedRow.IsTestMode,
            StartDate: this.selectedRow.StartDate,
            IsDpg: this.selectedRow.IsDpg,
            OfficeList: this.selectedRow.OfficeList.map((x) => x.Id),
            StateList: this.selectedRow.StateList.map((x) => x.Id),
            PolicyGroupList:
              this.selectedRow.IsDpg == true ? [] : this.policyGroupData,
          };
          let err, result;
          [err, result] = await this.$store.dispatch(
            types.UPDATE_GLOBAL_SETTING,
            dataToUpdate
          );
          if (result) {
            this.$swal("Success!", result.Message, "success");
            this.oldValue = JSON.stringify(this.selectedRow);
            let indx = this.globalSettingsProceeded.findIndex(
              (x) => x.Id == this.selectedRow.Id
            );

            if (indx >= 0) {
              this.globalSettingsProceeded[indx] = this.selectedRow;
              this.globalSettingsProceeded.push();
            }
          } else {
            let errMsg = this.getApiErrorMessage(err);
            this.$swal("Error!", errMsg, "error");
          }
          this.saving = false;
        }
      }
    },
  },

  validations() {
    return {
      selectedRow: {
        Name: { required },
        ApiUser: { required },
        ApiToken: { required },
        ApiCompanyId: { required },
        StartDate: { required },
      },
    };
  },
};
</script>

<style scoped>
.has-error {
  border: 2px solid red !important;
}
</style>
