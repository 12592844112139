export default {
  GET_XCHANGE_CUSTOMERS: "GET_XCHANGE_CUSTOMERS",
  ASSIGN_XCHANGE_CUSTOMER: "ASSIGN_XCHANGE_CUSTOMER",
  GET_CUSTOMERS: "GET_CUSTOMERS",
  SET_CUSTOMERS: "SET_CUSTOMERS",
  SET_PRIORITY: "SET_PRIORITY",
  DELETE_CUSTOMER: "DELETE_CUSTOMER",
  GET5_REMINDERS: "GET5_REMINDERS",
  GET5_DISPOS: "GET5_DISPOS",
  BULK_UPDATE_AGENT: "BULK_UPDATE_AGENT",
  BULK_UPDATE_STATUS: "BULK_UPDATE_STATUS",
  EXPORT_GRID: "EXPORT_GRID",
};
