<template>
  <div class="col-md-12">
    <ReportSummary
      :exportData="reportData"
      :reportName="'DocumentsAuditReport'"
      :recordCount="recordCount"
      @updateLoadingStatus="reportLoading = $event"
      @setPayload="setPayload"
      @clearReport="clearReport"
      :loadingSpinner="loadingSpinner"
    />
  </div>
  <div
    class="custom-table gcsTabsTableScroll report-wrapper"
    style="margin: 11px; max-height: 68vh"
  >
    <table style="margin-bottom: 14px" class="col-md-12">
      <thead>
        <tr>
          <th style="position: sticky; left: 0"></th>
          <th
            style="text-align: center"
            v-for="(state, index) in states"
            :key="index"
          >
            {{ state.Name }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in documentsAuditList" :key="index">
          <td
            style="
              position: sticky;
              left: 0;
              background-color: white;
              white-space: nowrap;
            "
          >
            {{ index + 1 }}- {{ item.TemplateTypeName }}
          </td>
          <td v-for="(state, index) in states" :key="index">
            <div
              class="document-box noMargin"
              style="display: flex"
              :class="getStyle(item, state)"
            ></div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import ReportSummary from "./ReportSummary.vue";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import globalTypes from "@/store/types";

export default {
  name: "DocumentsAuditReport",
  components: { ReportSummary },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      reportData: [],
      loadingSpinner: false,
    };
  },
  computed: mapState({
    states: (state) => state.globals.states,
    documentsAuditList: (state) => state.reports.documentsAuditList,
  }),
  async mounted() {
    await Promise.all([this.$store.dispatch(globalTypes.GET_STATES)]);
    this.reportsPanelHeight = this.setReportGridHeight(
      window.innerHeight,
      document.getElementById("reportsSummary")
    );
  },
  methods: {
    getStyle(item, state) {
      let matchList = item.States.filter((x) => x.StateId == state.Id);
      let rtn = "document-not-available";
      if (matchList.length > 0) {
        if (matchList[0].IsPresent == true) {
          rtn = "document-present";
        } else {
          rtn = "document-missing";
        }
      }
      return rtn;
    },
    async setPayload(value) {
      this.loadingSpinner = true;
      await this.$store.dispatch(types.GET_DOCUMENT_AUDIT_REPORT, value);
      this.loadingSpinner = false;
    },
    clearReport() {
      this.$store.dispatch(types.RESET_REPORTS);
      this.reportData = [];
    },
  },
};
</script>

<style>
.document-box {
  width: 32px;
  height: 32px;
  display: inline-block;
  margin: 10px;
}
.document-not-available {
  background-color: #d7d4d4;
}
.document-present {
  background-color: #33d078;
}
.document-missing {
  background-color: #f1556c;
}
</style>
