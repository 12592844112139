<template>
  <div
    class="pt-3"
    style="
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    "
  >
    <label class="headerText generalLabel mb-2">{{ widgetData.header }}</label>
    <label v-if="widgetData.subHeaderValue" class="subHeaderText generalLabel"
      >{{ widgetData.subHeader }}
      {{
        widgetData.valueFormat == "money"
          ? formatMoney(widgetData.subHeaderValue)
          : widgetData.subHeaderValue
      }}
    </label>
    <label class="middleText generalLabel mb-2" :style="getColorClass()">
      {{
        widgetData.valueFormat == "money"
          ? formatMoney(widgetData.value)
          : widgetData.value && widgetData.valueFormat == "percentage"
          ? widgetData.value.toFixed(2) + " %"
          : widgetData.value
      }}
      <i
        v-if="widgetData.positiveIndicator"
        class="ri-arrow-up-line"
        style="font-size: 20px"
      ></i>
      <i
        v-if="widgetData.negativeIndicator"
        class="ri-arrow-down-line"
        style="font-size: 20px"
      ></i>
    </label>

    <label v-if="widgetData.bottomValue" class="bottomText generalLabel"
      >{{ widgetData.bottomHeader }} {{ widgetData.bottomValue }}</label
    >
  </div>
</template>

<script>
import formatMixin from "@/mixins/formatMixin";
export default {
  props: {
    widgetData: Object,
  },
  mixins: [formatMixin],
  methods: {
    getColorClass() {
      let rtn = "color:#777;";
      if (this.widgetData) {
        if (this.widgetData.positiveIndicator == true) {
          rtn = "color:#24d15f;";
        } else if (this.widgetData.negativeIndicator == true) {
          rtn = "color:#ea2027;";
        }
      }

      return rtn;
    },
  },
};
</script>

<style scoped>
.headerText {
  font-size: 16px;
}
.subHeaderText {
  font-size: 12px;
  color: #999;
}
.middleText {
  font-size: 24px;
}
.bottomText {
  font-size: 13px;
}
</style>
