import types from "./types";
import api from "@/services/admin/api";
import to from "@/helpers/to";

export default {
  state: {
    list: [],
  },
  actions: {
    GET_OFFICES: async ({ commit }) => {
      let err, result;

      [err, result] = await to(api.getOffices());
      if (result) {
        commit(types.SET_OFFICE, result.Data);
      }
    },
    POST_OFFICE: async ({ commit }, payload) => {
      return await to(api.saveOffice(payload));
    },
    PUT_OFFICE: async ({ commit }, payload) => {
      let result = await to(api.updateOffice(payload));
      return result;
    },
    DELETE_OFFICE: async ({ commit }, payload) => {
      let result = await to(api.deleteOffice(payload));
      return result;
    },
  },
  mutations: {
    SET_OFFICE: (state, payload) => {
      state.list = payload;
    },
  },
};
