<template>
  <div
    class="col-md-12 newPanelWrapper noPadding"
    style="
      background-color: #ffffff;
      z-index: 1000;
      padding-left: 0;
      padding-right: 0;
    "
  >
    <div class="closeButton" v-on:click.prevent.stop="closePanel">
      <i class="fas fa-window-close"></i>
    </div>
    <label class="generalLabel ml-2 mt-2">Co-Customers Information</label>
    <div v-if="coCustomerProfiles.length > 0">
      <perfect-scrollbar
        style="padding: 10px; max-height: 450px; background-color: #efefef"
      >
        <div v-for="(item, index) in coCustomerProfiles" :key="item.Id">
          <div class="form-group row mr-1 ml-1">
            <div class="col-md-12">
              <label
                style="border-bottom: 1px solid #999"
                class="w-100 generalLabel pb-1"
                >{{
                  index + 1 + ". " + item.FirstName + " " + item.LastName
                }}</label
              >
            </div>
          </div>

          <div class="form-group row mr-1 ml-1">
            <div class="col-xl-6 col-lg-6 col-md-12 col-xs-12">
              <label class="generalLabel">First Name:</label>
              <input
                type="text"
                readonly
                v-model="item.FirstName"
                class="form-control textareaInput generalTextBox"
              />
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-xs-12">
              <label class="generalLabel">Last Name:</label>
              <input
                type="text"
                readonly
                v-model="item.LastName"
                class="form-control textareaInput generalTextBox"
              />
            </div>
          </div>
          <div class="form-group row mr-1 ml-1">
            <div class="col-xl-6 col-lg-6 col-md-12 col-xs-12">
              <label class="generalLabel">Birthday</label>
              <input
                type="text"
                readonly
                :value="formatShortDate(item.BirthDay)"
                class="form-control textareaInput generalTextBox"
              />
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-xs-12">
              <label class="generalLabel">Income:</label>
              <input
                type="text"
                readonly
                :value="formatMoney(item.Income)"
                class="form-control textareaInput generalTextBox"
              />
            </div>
          </div>
          <div class="form-group row mr-1 ml-1">
            <div class="col-xl-6 col-lg-6 col-md-12 col-xs-12">
              <label class="generalLabel">SSN:</label>
              <input
                type="text"
                readonly
                :value="formatSSN(item.Ssn)"
                class="form-control textareaInput generalTextBox"
              />
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-xs-12">
              <label class="generalLabel">Phone:</label>
              <input
                type="text"
                readonly
                :value="formatPhone(item.Phone)"
                class="form-control textareaInput generalTextBox"
              />
            </div>
          </div>
          <div class="form-group row mr-1 ml-1">
            <div class="col-12">
              <label class="generalLabel">Address:</label>
              <input
                type="text"
                readonly
                v-model="item.Address"
                class="form-control textareaInput generalTextBox"
              />
            </div>
          </div>
          <div class="form-group row mr-1 ml-1">
            <div class="col-12">
              <label class="generalLabel">Email:</label>
              <input
                type="text"
                readonly
                v-model="item.Email"
                class="form-control textareaInput generalTextBox"
              />
            </div>
          </div>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</template>

<script>
import utilitiesMixin from "@/mixins/utilitiesMixin";
import formatMixin from "@/mixins/formatMixin";
import deepCopy from "@/helpers/deepCopy";
export default {
  name: "CoCustomerInfoPopup",
  mixins: [formatMixin, utilitiesMixin],
  props: {
    coCustomers: {
      Type: Array,
      default: [],
    },
  },
  data() {
    return {
      coCustomerProfiles: [],
    };
  },
  mounted() {
    this.coCustomerProfiles = deepCopy(this.coCustomers);
  },
  methods: {
    closePanel() {
      this.$emit("closeCoCustomerInfoPopup");
    },
  },
};
</script>

<style>
.closeButton {
  position: absolute;
  top: -5px;
  right: 15px;
  z-index: 1000;
  font-size: 30px;
  cursor: pointer;
}
.closeButton:hover {
  color: #ff3860;
}
</style>
