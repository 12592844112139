<template>
  <div class="admin-page">
    <div class="col-md-12">
      <div class="form-group row">
        <div
          class="col-xl-6 col-lg-6 col-md-12 col-sm-12 panelWrapper noMargin"
        >
          <perfect-scrollbar
            style="max-height: 720px; padding: 10px 30px 10px 10px"
          >
            <div class="panelContent">
              <div
                v-for="(item, index) in sortedRules"
                :key="'rule-' + index"
                class="form-group row mt-1"
              >
                <button
                  type="button"
                  style="width: 100%; text-align: left"
                  class="btn btn-info waves-effect waves-light"
                  @click="editRule(item.Id)"
                  :class="
                    orderChangeLoading && item.Id == selectedItem
                      ? 'animate__animated animate__fadeOut'
                      : 'animate__animated animate__fadeIn'
                  "
                >
                  <span v-if="!item.IsNewRecord" class="btn-label"
                    ><i class="mdi mdi-lead-pencil"></i>
                  </span>
                  <span
                    v-on:click.prevent.stop="cancelRule(item.Id)"
                    v-if="item.IsNewRecord"
                    class="btn-label"
                    style="padding: 0.6rem 0.3rem"
                    ><i
                      style="
                        color: #ee324d;
                        background-color: white;
                        padding: 1px 3px;
                        border-radius: 4px;
                      "
                      class="mdi mdi-close"
                    ></i>
                  </span>

                  {{ item.Name }}

                  <span v-if="selectedItem == item.Id">
                    <i
                      v-if="parseInt(item.Priority) != maxPriorityNumber"
                      v-on:click.prevent.stop="priorityDown(item.Id)"
                      style="font-size: 16px"
                      class="mdi mdi-arrow-down-drop-circle float-right"
                    ></i>
                    <i
                      v-if="parseInt(item.Priority) != minPriorityNumber"
                      v-on:click.prevent.stop="priorityUp(item.Id)"
                      style="font-size: 16px"
                      class="mdi mdi-arrow-up-drop-circle float-right mr-1"
                    ></i>
                  </span>
                  <span
                    v-on:click.prevent.stop="deleteRule(item.Id)"
                    v-if="selectedItem == item.Id && !item.IsNewRecord"
                    type="button"
                    style="
                      background-color: white;
                      color: #f1556c;
                      font-weight: 600;
                      text-align: center;
                      float: right;
                      border-radius: 6px;
                    "
                    class="btn btn-xs btn-info waves-light ml-2 mr-2"
                  >
                    Delete Rule
                  </span>
                </button>

                <div class="col-md-12 row" v-if="selectedItem == item.Id">
                  <div class="col-md-6 pb-2">
                    <label class="generalLabel">Name</label>
                    <input
                      autocomplete="off"
                      v-model="item.Name"
                      type="text"
                      id="nameInp"
                      class="generalTextBox form-control"
                    />
                  </div>
                  <div class="col-md-6 pb-2">
                    <label class="generalLabel">Assign Type</label>

                    <select
                      v-model="item.Receiver.ReceiverType"
                      id="user"
                      class="form-control"
                    >
                      <option value="0">Please select</option>
                      <option
                        v-for="item in sortedReceiverTypes"
                        :key="item.Key"
                        :value="item.Key"
                      >
                        {{ item.Value }}
                      </option>
                    </select>
                  </div>
                  <div
                    v-if="item.Receiver.ReceiverType == 3"
                    class="col-md-6 pb-2"
                  >
                    <label class="generalLabel">Direct Number</label>
                    <input
                      autocomplete="off"
                      type="text"
                      id="directNumber"
                      class="generalTextBox form-control"
                      @change="refreshValue($event, item)"
                      v-myMask="{ mask: ['(999) 999-9999'] }"
                      v-model="item.Receiver.ReceiverId"
                    />
                  </div>

                  <div
                    v-if="item.Receiver.ReceiverType == 2"
                    class="col-md-6 pb-2"
                  >
                    <label class="generalLabel">User</label>
                    <select
                      v-model="item.Receiver.ReceiverId"
                      id="user"
                      class="form-control"
                    >
                      <option value="">Please select</option>
                      <option
                        :key="item.Id"
                        :value="item.Id"
                        v-for="item in allUsers"
                      >
                        {{ item.Name }}
                      </option>
                    </select>
                  </div>

                  <div
                    v-if="
                      item.Receiver.ReceiverType == 4 ||
                      item.Receiver.ReceiverType == 5
                    "
                    class="col-md-6 pb-2"
                  >
                    <label class="generalLabel">Voice Mail User</label>
                    <select
                      v-model="item.Receiver.VoiceMailUserId"
                      id="voiceMailUser"
                      class="form-control"
                    >
                      <option :value="0">Please select</option>
                      <option
                        :key="item.Id"
                        :value="item.Id"
                        v-for="item in phoneEnabledUsers"
                      >
                        {{ item.Name }}
                      </option>
                    </select>
                  </div>
                  <div
                    v-if="item.Receiver.ReceiverType == 4"
                    class="col-md-6 pb-2"
                  >
                    <label class="generalLabel">Role</label>
                    <select
                      v-model="item.Receiver.ReceiverId"
                      id="role"
                      class="form-control"
                    >
                      <option value="">Please select</option>
                      <option
                        :key="item.Id"
                        :value="item.Id"
                        v-for="item in roleList"
                      >
                        {{ item.Name }}
                      </option>
                    </select>
                  </div>

                  <div class="col-md-6 pb-2">
                    <label class="generalLabel">Customer Statuses</label>
                    <VueMultiselect
                      id="customerStatuses"
                      v-model="item.CustomerStatuses"
                      :options="customerStatuses"
                      :multiple="true"
                      :close-on-select="false"
                      placeholder="Please Select"
                      label="Name"
                      track-by="Id"
                    />
                  </div>
                  <div class="col-md-6 pb-2">
                    <label class="generalLabel">Ring Timeout</label>
                    <input
                      autocomplete="off"
                      type="text"
                      id="ringTimeOut"
                      class="form-control"
                      maxlength="4"
                      @keypress="validateTimeout($event)"
                      v-model="item.Receiver.RingTimeout"
                    />
                  </div>

                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-6 pb-2">
                        <input
                          autocomplete="off"
                          type="checkbox"
                          id="createMissedCallLead"
                          class="checkbox-input form-control"
                          v-model="item.Receiver.CreateMissedCallLead"
                        />

                        <label class="generalLabel"
                          >Create Missed Call Lead</label
                        >
                      </div>

                      <div class="col-md-6 pb-2">
                        <input
                          autocomplete="off"
                          type="checkbox"
                          id="createMissedCallTask"
                          class="checkbox-input form-control"
                          v-model="item.Receiver.CreateMissedCallTask"
                        />

                        <label class="generalLabel"
                          >Create Missed Call Task</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-12 text-right noPadding">
                  <button
                    type="button"
                    style="width: 100%; text-align: left"
                    class="btn btn-primary btn-md waves-effect waves-light mt-2"
                    v-show="!isNewRulesExists()"
                    @click="addNewRule()"
                  >
                    <span class="btn-label float-left">
                      <i class="mdi mdi-plus-circle-outline"></i>
                    </span>
                    Add New Rule
                  </button>
                </div>
              </div>
            </div>
          </perfect-scrollbar>
          <div style="padding: 0 30px 0 10px">
            <div class="form-group row">
              <div class="col-md-12 text-center">
                <button
                  @click="saveInboundRules()"
                  type="button"
                  style="width: 100%"
                  class="btn btn-success btn-md waves-effect waves-light mt-2"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import VueMultiselect from "vue-multiselect";
import _orderby from "lodash";
import globalTypes from "@/store/types";

export default {
  name: "InboundCallRulesManagement",
  components: { VueMultiselect },

  data() {
    return {
      newItemActive: false,
      selectedItem: "",
      orderChangeLoading: false,
      allowedKeyCodes: [48, 49, 50, 51, 52, 53, 54, 55, 56, 57], //0-9
      oldInboundRules: "",
    };
  },
  computed: mapState({
    receiverTypes: (state) => state.inboundCallRulesManagement.receiverTypes,
    sortedReceiverTypes() {
      return _orderby.orderBy(this.receiverTypes, "Value", "asc");
    },
    inboundRules: (state) => state.inboundCallRulesManagement.inboundRules,
    sortedRules() {
      return _orderby.orderBy(this.inboundRules, "Priority", "asc");
    },
    maxPriorityNumber() {
      return this.sortedRules.length > 0
        ? Math.max(...this.sortedRules.map((x) => parseInt(x.Priority)))
        : 0;
    },
    minPriorityNumber() {
      return this.sortedRules.length > 0
        ? Math.min(...this.sortedRules.map((x) => parseInt(x.Priority)))
        : 0;
    },
    allUsers: (state) => state.globals.allEmployees,
    phoneEnabledUsers() {
      return this.allUsers.filter((x) => x.IsPhoneEnabled);
    },
    roleList: (state) => state.globals.roles,
    customerStatuses: (state) => state.globals.customerStatuses,
  }),
  async mounted() {
    await Promise.all([
      this.$store.dispatch(types.GET_INBOUND_RULES_ICRM),
      this.$store.dispatch(globalTypes.GET_STATUS_NAMES),
      this.$store.dispatch(globalTypes.GET_ROLES),
      this.$store.dispatch(globalTypes.GET_ALL_ACTIVE_EMPLOYEES),
    ]);

    this.bindRules();
    this.bindRuleStatuses();
    this.oldInboundRules = JSON.stringify(this.inboundRules);
  },
  methods: {
    editRule(id) {
      if (this.newItemActive) {
        this.$swal.fire(
          "Warning",
          "Please complete your new rule and save settings.",
          "warning"
        );
        return;
      }

      if (this.selectedItem == id) this.selectedItem = "";
      else this.selectedItem = id;
    },
    async cancelRule(id) {
      this.newItemActive = false;
      let indx = this.inboundRules.findIndex((x) => x.Id == id);

      if (indx > -1) {
        this.inboundRules.splice(indx, 1);
      }
      await this.bindRules();
    },
    async bindRules() {
      for (let index = 0; index < this.inboundRules.length; index++) {
        this.inboundRules[index].Id =
          this.sortedRules.indexOf(this.inboundRules[index]) + 1;
      }
      await this.$store.dispatch(
        types.UPDATE_PRIORITY_LOCALLY,
        this.inboundRules
      );
    },
    priorityDown(id) {
      this.orderChangeLoading = true;
      let priority = 0;
      let indx = this.inboundRules.findIndex((x) => x.Id == id);
      if (indx > -1) {
        priority = this.inboundRules[indx].Priority;
      }

      let indx2 = this.inboundRules.findIndex(
        (x) => x.Priority == priority + 1
      );

      if (indx > -1) {
        this.inboundRules[indx].Priority += 1;
      }
      if (indx2 > -1) {
        this.inboundRules[indx2].Priority -= 1;
      }

      setTimeout(() => {
        this.orderChangeLoading = false;
      }, 250);
    },
    priorityUp(id) {
      this.orderChangeLoading = true;
      let priority = 0;
      let indx = this.inboundRules.findIndex((x) => x.Id == id);
      if (indx > -1) {
        priority = this.inboundRules[indx].Priority;
      }

      let indx2 = this.inboundRules.findIndex(
        (x) => x.Priority == priority - 1
      );

      if (indx > -1) {
        this.inboundRules[indx].Priority -= 1;
      }

      if (indx2 > -1) {
        this.inboundRules[indx2].Priority += 1;
      }

      setTimeout(() => {
        this.orderChangeLoading = false;
      }, 250);
    },
    async deleteRule(id) {
      let indx = this.inboundRules.findIndex((x) => x.Id == id);

      if (indx > -1) {
        await this.$swal({
          title: "Confirm",
          text: "Are you sure to delete this rule?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it.",
        }).then(async (dialog) => {
          if (dialog.value) {
            this.inboundRules.splice(indx, 1);
          }
        });
      }

      this.cancelRule(this.selectedItem);
      this.selectedItem = "";
    },
    refreshValue(event, item) {
      if (event.target.value && event.target.value != "") {
        item.Receiver.ReceiverId = event.target.value;
      }
    },
    validateTimeout(event) {
      if (!this.allowedKeyCodes.includes(event.keyCode)) {
        event.preventDefault();
      }
    },
    isNewRulesExists() {
      return this.inboundRules.findIndex((x) => x.IsNewRecord) > -1
        ? true
        : false;
    },
    async addNewRule() {
      this.newItemActive = true;
      let newId = this.inboundRules.length + 1;
      this.inboundRules.push({
        Id: newId,
        IsNewRecord: true,
        Priority: newId,
        Name: "",
        CustomerStatuses: [],
        Receiver: {
          ReceiverType: 0,
          ReceiverId: "",
          VoiceMailUserId: 0,
          CreateMissedCallLead: false,
          CreateMissedCallTask: false,
          RingTimeout: 120,
        },
      });
      this.selectedItem = newId;
      await this.bindRules();
    },
    async saveInboundRules() {
      let namesCheckIndex = this.inboundRules.findIndex(
        (x) => x.Name.trim() == ""
      );
      if (namesCheckIndex > -1) {
        this.$swal.fire("Warning!", "Name  required for each rule.", "warning");
        return;
      }

      let assignTypesCheckIndex = this.inboundRules.findIndex(
        (x) => x.Receiver.ReceiverType == 0
      );
      if (assignTypesCheckIndex > -1) {
        this.$swal.fire(
          "Warning!",
          "Assign Type required for each rule.",
          "warning"
        );
        return;
      }

      if (this.oldInboundRules == JSON.stringify(this.inboundRules)) {
        this.$swal.fire("Warning!", "No Changes detected.", "warning");
        return;
      }

      let allRulesValid = true;
      for (let index = 0; index < this.inboundRules.length; index++) {
        let detailedValidationResult = await this.validateDetailed(
          this.inboundRules[index]
        );

        if (detailedValidationResult.IsSuccessful == false) {
          this.$swal.fire(
            "Rule Name: " + detailedValidationResult.Title,
            detailedValidationResult.Message,
            "warning"
          );
          allRulesValid = false;
          break;
        }
      }

      if (!allRulesValid) return;

      for (let index = 0; index < this.inboundRules.length; index++) {
        delete this.inboundRules[index].Id;
        delete this.inboundRules[index].IsNewRecord;

        this.inboundRules[index].CustomerStatuses = this.inboundRules[
          index
        ].CustomerStatuses.map((x) => x.Id);
      }

      let err, result;
      [err, result] = await this.$store.dispatch(types.UPDATE_INBOUND_RULES, {
        Rules: this.inboundRules,
      });
      if (result) {
        this.newItemActive = false;
        this.selectedItem = "";
        this.$swal.fire("Success!", "Settings updated.", "success");
        await this.$store
          .dispatch(types.GET_INBOUND_RULES_ICRM)
          .then(async () => {
            await this.bindRules();
            this.bindRuleStatuses();
            this.oldInboundRules = JSON.stringify(this.inboundRules);
          });
      }
    },
    async validateDetailed(item) {
      let data = { IsSuccessful: true, Title: item.Name, Message: "" };
      if (item.Receiver.ReceiverType == 2) {
        data.IsSuccessful = item.Receiver.ReceiverId != "";
        data.Message = "User field is mandatory for this assign type.";
      } else if (item.Receiver.ReceiverType == 3) {
        data.IsSuccessful = item.Receiver.ReceiverId != "";
        data.Message = "Direct Number field is mandatory for this assign type.";
      } else if (item.Receiver.ReceiverType == 4) {
        data.IsSuccessful = item.Receiver.ReceiverId != "";
        data.Message = "Role field is mandatory for this assign type.";
      }

      return data;
    },
    async bindRuleStatuses() {
      for (let index = 0; index < this.inboundRules.length; index++) {
        for (
          let j = 0;
          j < this.inboundRules[index].CustomerStatuses.length;
          j++
        ) {
          let statusItem = this.inboundRules[index].CustomerStatuses[j];

          let statuses = this.customerStatuses.filter(
            (x) => x.Id == statusItem
          );

          this.inboundRules[index].CustomerStatuses[j] = {
            Id: statusItem,
            Name: statuses.length > 0 ? statuses[0].Name : "Invalid Status",
          };
        }
      }
    },
  },
};
</script>

<style></style>
