<template>
  <div class="p-4">
    <div
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      "
    >
      <div v-if="isLoading" class="loading">
        <div class="text-center" style="margin-top: 150px">
          <i class="spinner-grow text-info m-2"></i>
        </div>
      </div>
      <i
        style="font-size: 60px; color: #3bafda"
        class="ri-lock-password-fill"
      ></i>
      <label style="font-size: 15px">Multi-Factor Authentication (MFA)</label>

      <div>
        <label class="mt-3">
          Your account security is our priority. We've activated Multi-Factor
          Authentication for added protection.
        </label>
        <label class="text-muted mt-3">
          Step 1: First, begin by selecting where you'd like to receive the
          verification code.
        </label>

        <div class="form-check form-switch text-center mt-3">
          <div
            v-if="mfaRadioControl && mfaRadioControl.includes('sms')"
            class="form-check-inline"
          >
            <input
              class="form-check-input"
              type="radio"
              name="inlineRadioOptions"
              id="inlineRadio1"
              value="sms"
              v-model="selectedRadio"
            />
            <label class="form-check-label" for="inlineRadio1">SMS</label>
          </div>
          <div
            v-if="mfaRadioControl && mfaRadioControl.includes('email')"
            class="form-check form-check-inline"
          >
            <input
              class="form-check-input"
              type="radio"
              name="inlineRadioOptions"
              id="inlineRadio2"
              value="email"
              v-model="selectedRadio"
            />
            <label class="form-check-label" for="inlineRadio2">Email</label>
          </div>
          <div
            v-if="mfaRadioControl && mfaRadioControl.includes('call')"
            class="form-check form-check-inline"
          >
            <input
              class="form-check-input"
              type="radio"
              name="inlineRadioOptions"
              id="inlineRadio3"
              value="call"
              v-model="selectedRadio"
            />
            <label class="form-check-label" for="inlineRadio3">Call</label>
          </div>
        </div>

        <div
          class="pt-3"
          style="display: flex; justify-content: center; text-align: center"
        >
          <label v-if="selectedRadio == 'email'" class="form-check-label">
            The code will be sent to: {{ maskedEmail }}</label
          >
          <label
            v-if="selectedRadio == 'sms' || selectedRadio == 'call'"
            class="form-check-label"
            style="text-align: center"
          >
            The code will be sent to: {{ maskedPhone }}</label
          >
        </div>

        <button
          class="btn btn-primary col-md-12 mt-3"
          @click="sendCode"
          :disabled="isLoading"
        >
          Send
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import globalTypes from "@/store/types";
import utilitiesMixin from "@/mixins/utilitiesMixin.js";

export default {
  mixins: [utilitiesMixin],
  data() {
    return {
      selectedRadio: "",
      isLoading: false,
      mfaRadioControl: null,
      maskedEmail: "",
      maskedPhone: "",
    };
  },
  computed: {},
  async mounted() {
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

    if (
      userInfo != null &&
      userInfo.mfa != null &&
      userInfo.mfa.Options != null
    ) {
      this.mfaRadioControl = userInfo.mfa.Options;
    }

    if (userInfo != null && userInfo.mfa != null) {
      if (userInfo.mfa.Masked) {
        if (userInfo.mfa.Masked.Email) {
          this.maskedEmail = userInfo.mfa.Masked.Email;
        }
        if (userInfo.mfa.Masked.Phone) {
          this.maskedPhone = userInfo.mfa.Masked.Phone;
        }
      }
    }
  },
  props: {
    authenticate: {
      Type: Boolean,
      Defult: false,
    },
  },
  methods: {
    async sendCode() {
      if (this.selectedRadio == "") {
        this.$swal("Warning!", "Please make a choice", "warning");
        return;
      }
      this.isLoading = true;
      let payload = {
        Option: this.selectedRadio,
      };
      let err, result;
      [err, result] = await this.$store.dispatch(
        globalTypes.MFA_VERIFICATION_SEND,
        payload
      );
      this.isLoading = false;
      if (result) {
        let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        if (userInfo != null) {
          await this.resetTokenStorage(userInfo.email, result.Data.Token);
        }
        this.$emit("authCode", true);
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
    },
    async submitAuth() {
      this.isLoading = true;
      let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      let mfaToken = null;
      if (userInfo != null) {
        mfaToken = await this.getTokenFromLocalStorage(userInfo.email);
      }
      let payload = {
        Code: this.inputs,
        Token: mfaToken,
      };
      let err, result;
      [err, result] = await this.$store.dispatch(
        globalTypes.MFA_VERIFICATION_VERIFY,
        payload
      );
      this.isLoading = false;
      if (result) {
        this.$router.replace({ name: "home" });
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
    },
  },
};
</script>

<style></style>
