<template>
  <div class="col-md-12 noPadding">
    <div
      class="closeButton"
      style="position: absolute; top: 0px; right: 10px; z-index: 1000"
      v-on:click.prevent.stop="closePanel"
    >
      <i class="fas fa-window-close"></i>
    </div>
    <div class="col-md-12 newPanelWrapper" style="background-color: #ffffff">
      <div class="form-group row">
        <div class="col-md-12">
          <label class="generalLabel">Quick Emails</label>
        </div>
      </div>
      <div class="form-group row">
        <input
          type="text"
          id="txtFilter"
          class="form-control"
          v-model="quickEmailFilter"
          placeholder="Search quick emails"
        />
      </div>

      <perfect-scrollbar
        class="form-group row mt-2"
        style="
          min-height: 100px;
          max-height: 240px;
          overflow-y: auto;
          overflow-x: hidden !important;
        "
      >
        <div
          v-for="(item, index) in filteredEmails"
          :key="item.Id"
          class="col-md-12 noPadding noMargin"
        >
          <div
            v-on:click.prevent.stop="selectQuickEmail(item)"
            class="quickEmailItem"
            :style="[
              index % 2 === 0
                ? { 'background-color': '#F1EFEF' }
                : { 'background-color': '#FFFFFF' },
            ]"
          >
            <label class="generalLabel">{{ item.Title }}</label>
          </div>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</template>
<script>
import deepCopy from "@/helpers/deepCopy";
import { mapState } from "vuex";
import quickEmailTypes from "@/views/admin/QuickEmailManagement/types";
import utilitiesMixin from "@/mixins/utilitiesMixin";
export default {
  name: "QuickEmails",
  props: {
    customerId: {
      Type: Number,
      Default: () => 0,
    },
    languageId: {
      Type: Number,
      Default: () => 0,
    },
  },
  mixins: [utilitiesMixin],
  computed: mapState({
    quickEmailList: (state) => state.quickEmailManagement.list,

    quickEmailListWithEmptyEmail() {
      let emailList = deepCopy(this.quickEmailList);
      emailList.unshift(this.emptyEmail);
      return emailList;
    },

    filteredEmails() {
      let qemails = 
      // this.canSeeAllOffices()
      //   ? this.quickEmailListWithEmptyEmail
      //   : 
        this.quickEmailListWithEmptyEmail.filter(
            (x) =>
              x.QuickEmailRoles == null ||
              (x.QuickEmailRoles &&
                (x.QuickEmailRoles.length == 0 ||
                  x.QuickEmailRoles.some((y) => this.userRoles.includes(y.Id))))
          );
      return qemails
        .filter(
          (item) =>
            item.Title.toLowerCase().indexOf(
              this.quickEmailFilter.toLowerCase()
            ) > -1
        );
    },
  }),
  data() {
    return {
      userOfficeId: 0,
      userRoles: [],
      quickEmailFilter: "",
      userId: 0,
      emptyEmail: {
        Id: 0,
        Title: "Empty Email",
        Subject: "",
        Body: "",
        BccAddress: "",
        SpanishContent: "",
        ToAddress: "",
        TypeId: 1,
        QuickEmailRoles: [],
      },
    };
  },
  async mounted() {
    await this.$store.dispatch(quickEmailTypes.GET_QUICKEMAILS);
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo != null && userInfo.moduleFunctions != null) {
      this.userOfficeId = userInfo.officeId;
      this.userRoles = userInfo.roles;
    }
  },
  methods: {
    selectQuickEmail(item) {
      this.$emit("quickEmailSelected", item);
    },
    closePanel() {
      this.$emit("closeQuickEmailPopup");
    },
  },
};
</script>
<style lang="css" scoped>
.quickEmailItem {
  cursor: pointer;
  width: 100%;
  padding: 10px;
}
.quickEmailItem:active,
.quickEmailItem:hover {
  cursor: pointer;
  background: #daffe3 !important;
}
</style>
