<template>
  <div class="col-md-12">
    <ReportSummary
      :exportData="reportData"
      :reportName="'VoidedSettlementReport'"
      :recordCount="recordCount"
      @updateLoadingStatus="reportLoading = $event"
      @setPayload="setPayload"
      @clearReport="clearReport"
      @setPagination="setPagination"
      @refreshReport="refreshReport"
      :loadingSpinner="loadingSpinner"
    />

    <div
      id="active-enrollment-report"
      class="datagrid-table gcsTabsTableScroll report-wrapper"
      :style="'max-height: ' + reportsPanelHeight + 'px; overflow-y:auto;'"
    >
      <o-table
        :data="filteredVoidedSettlementReport"
        :paginated="isPaginated && filteredVoidedSettlementReport.length > 0"
        :per-page="perPage"
        :pagination-simple="isPaginationSimple"
        :pagination-position="paginationPosition"
        :sticky-header="true"
        :scrollable="true"
      >
        <o-table-column
          field="CustomerId"
          label="Customer Id"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                style="width: 90px"
                type="text"
                class="datagrid-table creditorTable"
                v-model="CustomerIdFilter"
                placeholder=""
                v-myMask="{
                  alias: 'numeric',
                  rightAlign: false,
                }"
              />
            </div>
          </template>
          <template v-slot="props">
            <span
              @click="
                viewCustomer(
                  props.row.CustomerId,
                  props.row.FirstName + ' ' + props.row.LastName
                )
              "
              :class="
                getTagReportHyperLinkClass(
                  props.row.CustomerId &&
                    props.row.FirstName &&
                    props.row.LastName
                )
              "
            >
              <strong v-if="props.row.IsSummary">
                {{ props.row.CustomerId }}
              </strong>
              <span v-if="!props.row.IsSummary">
                {{ addPrefixToCustomerId(props.row.CustomerId) }}
              </span>
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="FirstName"
          label="First Name"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="FirstNameFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            <div>{{ props.row.FirstName }}</div>
          </template>
        </o-table-column>
        <o-table-column field="LastName" label="Last Name" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="LastNameFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            <div>{{ props.row.LastName }}</div>
          </template>
        </o-table-column>

        <o-table-column field="State" label="State" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="StateFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            <div>{{ props.row.State }}</div>
          </template>
        </o-table-column>

        <o-table-column field="Office" label="Office" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="OfficeFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            <div>{{ props.row.Office }}</div>
          </template>
        </o-table-column>

        <o-table-column field="Creditor" label="Creditor" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CreditorFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            <div>{{ props.row.Creditor }}</div>
          </template>
        </o-table-column>

        <o-table-column
          field="CurrentCreditor"
          label="Current Creditor"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CurrentCreditorFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.CurrentCreditor }}
          </template>
        </o-table-column>

        <o-table-column
          field="OriginalBalance"
          label="Original Balance"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="OriginalBalanceFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.OriginalBalance }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatMoney(props.row.OriginalBalance) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="SettlementRequestDate"
          label="Settlement Request Date"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="SettlementRequestDateFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            <div>
              {{ formatGridDate(props.row.SettlementRequestDate) }}
            </div>
          </template>
        </o-table-column>

        <o-table-column
          field="ModifiedDate"
          label="Modified Date"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="ModifiedDateFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            <div>
              {{ formatGridDate(props.row.ModifiedDate) }}
            </div>
          </template>
        </o-table-column>

        <o-table-column
          field="FeeAmount"
          label="Fee Amount"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="FeeAmountFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.FeeAmount }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatMoney(props.row.FeeAmount) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="ClearedFeePerc"
          label="Cleared Fee %"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="ClearedFeePercFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            <div>
              {{ props.row.ClearedFeePerc }}
            </div>
          </template>
        </o-table-column>

        <o-table-column
          field="ClearedPaymentPerc"
          label="Cleared Payment %"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="ClearedPaymentPercFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            <div>
              {{ props.row.ClearedPaymentPerc }}
            </div>
          </template>
        </o-table-column>

        <o-table-column
          field=""
          label="View Customer"
          position="centered"
          v-slot="props"
        >
          <button
            @click="
              viewCustomer(
                props.row.CustomerId,
                props.row.FirstName,
                props.row.LastName
              )
            "
            class="btn btn-info waves-effect waves-light ml-3"
            v-if="!props.row.IsSummary"
          >
            <i class="far fa-address-card"></i>
          </button>
        </o-table-column>
      </o-table>
      <div v-if="loadingSpinner" class="text-center" style="margin: 6em 0">
        <i class="spinner-border avatar-lg text-primary m-2"></i>
      </div>
      <section
        v-if="filteredVoidedSettlementReport.length == 0 && !loadingSpinner"
        class="section"
        style="text-align: center"
      >
        <div class="content has-text-grey has-text-centered mb-4">
          <p class="noMargin">
            <i
              style="font-size: 24px; color: #939393"
              class="ri-question-line"
            ></i>
          </p>
          <p class="noMargin">Nothing here.</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import ReportSummary from "./ReportSummary.vue";
import utilitiesMixin from "@/mixins/utilitiesMixin";

export default {
  name: "VoidedSettlementReport",
  components: { ReportSummary },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      customerPrefix: null,
      reportsPanelHeight: 0,
      loadingSpinner: false,
      isPaginationSimple: false,
      isPaginated: true,
      paginationPosition: "both",
      currentPage: 1,
      perPage: 200,
      recordCount: 0,
      reportLoading: false,
      reportData: [],

      voidedSettlementReportInitSummary: {
        CustomerId: "",
      },
      CustomerIdFilter: "",
      FirstNameFilter: "",
      LastNameFilter: "",
      StateFilter: "",
      OfficeFilter: "",
      CreditorFilter: "",
      CurrentCreditorFilter: "",
      OriginalBalanceFilter: "",
      SettlementRequestDateFilter: "",
      ModifiedDateFilter: "",
      ClearedFeePercFilter: "",
      ClearedPaymentPercFilter: "",
      FeeAmountFilter: "",
    };
  },
  computed: mapState({
    voidedSettlementList: (state) => state.reports.voidedSettlementList,

    filteredVoidedSettlementReport() {
      let rtn = [];

      let tmpList =
        this.voidedSettlementList.length > 0
          ? this.voidedSettlementList.filter((item) => {
              return (
                this.filterString(item.CustomerId, this.CustomerIdFilter) &&
                this.filterString(item.FirstName, this.FirstNameFilter) &&
                this.filterString(item.LastName, this.LastNameFilter) &&
                this.filterString(item.State, this.StateFilter) &&
                this.filterString(item.Office, this.OfficeFilter) &&
                this.filterString(item.Creditor, this.CreditorFilter) &&
                this.filterString(
                  item.CurrentCreditor,
                  this.CurrentCreditorFilter
                ) &&
                this.filterString(
                  item.OriginalBalance,
                  this.OriginalBalanceFilter
                ) &&
                this.filterString(
                  item.ClearedFeePerc,
                  this.ClearedFeePercFilter
                ) &&
                this.filterString(
                  item.ClearedPaymentPerc,
                  this.ClearedPaymentPercFilter
                ) &&
                new Date(item.SettlementRequestDate)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .indexOf(this.SettlementRequestDateFilter) > -1 &&
                new Date(item.ModifiedDate)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .indexOf(this.ModifiedDateFilter) > -1
              );
            })
          : [];

      if (tmpList.length == 0) {
        this.reportData = [];
        return rtn;
      }
      let totalCustomer = tmpList.length.toString();

      this.voidedSettlementReportInitSummary.CustomerId =
        "Total: " + totalCustomer;
      this.recordCount = tmpList.length;
      this.voidedSettlementReportInitSummary.IsSummary = true;

      rtn.push(...tmpList);
      rtn.push(this.voidedSettlementReportInitSummary);
      this.reportData = rtn;

      return rtn;
    },
  }),
  async mounted() {
    await this.setPayload();
    this.reportsPanelHeight = this.setReportGridHeight(
      window.innerHeight,
      document.getElementById("reportsSummary")
    );
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

    if (
      this.userInfo != null &&
      this.userInfo.customerIdPrefix != null &&
      this.userInfo.customerIdPrefix.length > 0
    ) {
      this.customerPrefix = this.userInfo.customerIdPrefix;
    }
    await this.sleep(1000);
    this.fixStickyHeaders();
  },

  methods: {
    addPrefixToCustomerId(customerId) {
      let rtn = customerId.toString();
      if (this.customerPrefix != null) {
        rtn = this.customerPrefix + rtn;
      }
      return rtn;
    },

    setPagination(data) {
      this.isPaginated = data;
    },
    async setPayload(value) {
      this.loadingSpinner = true;
      await this.$store.dispatch(types.GET_VOIDED_SETTLEMENT_REPORT, value);
      this.loadingSpinner = false;
      await this.sleep(500);
      this.fixStickyHeaders();
    },
    clearReport() {
      this.$store.dispatch(types.RESET_REPORTS);
      this.reportData = [];
    },
    async refreshReport() {
      this.loadingSpinner = true;
      await this.$store.dispatch(types.GET_VOIDED_SETTLEMENT_REPORT);
      this.loadingSpinner = false;
    },
    async viewCustomer(id, firstName, lastName) {
      this.$Emitter.emit("viewCustomer", {
        customerId: id,
        customerName: firstName + " " + lastName,
      });
    },
  },
};
</script>

<style scoped></style>
