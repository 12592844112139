<template>
  <div
    class="col-md-12 newPanelWrapper noMargin dcp-mediaScreen"
    style="background-color: #ffffff; z-index: 1000"
  >
    <div
      style="display: flex; justify-content: space-between"
      v-on:click.prevent.stop="closePanel"
    >
      <label>Add Bulk Drafts</label>
      <i class="fas fa-window-close closeButtonBulk"></i>
    </div>

    <div class="row">
      <div class="col-md-4">
        <label for="serviceFee" class="generalLabel">Start Date:</label>

        <DatePicker
          mode="date"
          :locale="'en-US'"
          v-model="draftItem.StartDate"
          :model-config="modelConfig"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <input
              id="DCP-feeDate"
              class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
              :value="inputValue"
              v-on="inputEvents"
              placeholder="Select Date"
            />
          </template>
        </DatePicker>
      </div>
      <div class="col-md-4">
        <label for="serviceFee" class="generalLabel">Draft Amount:</label>
        <input
          v-model="draftItem.DraftAmount"
          class="form-control"
          v-myMask="{
            alias: 'currency',
            prefix: '$',
            min: 0.01,
            max: 99999.99,
            allowMinus: false,
            rightAlign: false,
          }"
          placeholder="$0.00"
        />
      </div>
      <div class="col-md-3">
        <label for="serviceFee" class="generalLabel">Draft Count:</label>
        <input
          v-model="draftItem.DraftCount"
          class="form-control"
          v-myMask="{
            alias: 'numeric',
            min: 1,
            max: 120,
            digits: 0,
            allowMinus: false,
            rightAlign: false,
          }"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <label class="generalLabel">Schedule Type:</label>

        <select
          style="width: 200px"
          class="form-control"
          v-model="draftItem.ScheduleType"
        >
          <option :value="null">Please Select</option>
          <option v-for="item in schedules" :value="item.Id" :key="item.Id">
            {{ item.Value }}
          </option>
        </select>
      </div>
      <div class="col-md-3" v-if="draftItem && draftItem.ScheduleType == 2">
        <label class="generalLabel">Day:</label>
        <CustomNumericInput
                        class="generalTextBox ml-1"
         
                        :value="twiceAmonthDay"
                        @updateValue="updateTwiceAMonthDay"
                        :minNumber="1"
                        :maxNumber="
                          getMaxDayofMonth(
                            draftItem.StartDate
                          )
                        "
                        placeholder="Day"
                      />
       
      </div>
    </div>
    <div class="row">
      <div class="col-md-11 text-right">
        <button @click="applyChanges()" class="btn btn-primary">
          Submit Drafts
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import types from "./types";
import formatMixin from "@/mixins/formatMixin";
import { DatePicker } from "v-calendar";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import deepCopy from "@/helpers/deepCopy";
import CustomNumericInput from "@/components/CustomNumericInput";

export default {
  name: "BulkAddDraftPopup",
  components: { DatePicker,CustomNumericInput },
  props: {},
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      draftItem: {
        StartDate: "",
        DraftAmount: 0.01,
        DraftCount: 1,
        ScheduleType: 1,
      },
      twiceAmonthDay:0,
      addedList: [],
      schedules: [
        { Id: 1, Value: "Monthly" },
        { Id: 2, Value: "Twice a Month" },
        { Id: 3, Value: "Bi-weekly" },
        { Id: 4, Value: "Weekly" },
    
      ],
      modelConfig: {
        type: "string",
        mask: "MM/DD/YYYY",
      },
    };
  },
  unmounted() {},
  async mounted() {},
  methods: {
    closePanel() {
      this.$emit("closeBulkAddDraftPopup");
    },
    getDraftObject() {
      return {
        EffectiveDate: "",
        DebitId: 0,
        Amount: 0,
        Memo: "",
        isEditing: true,
        isDeleting: false,
        NewDate: "",
        NewAmount: 0,
      };
    },
    updateTwiceAMonthDay(value){
      this.twiceAmonthDay = value;
    },
    applyChanges() {
      if (this.draftItem.StartDate == "") {
        this.$swal("Warning!", "Start Date is required", "warning");
        return;
      }

      let draftAmount = parseFloat(this.draftItem.DraftAmount);
      if (draftAmount < 0.01) {
        this.$swal("Warning!", "Draft Amount is required", "warning");
        return;
      }

      let draftCount = parseInt(this.draftItem.DraftCount);
      if (draftCount < 1) {
        this.$swal("Warning!", "Draft Count is required", "warning");
        return;
      }

      if (this.draftItem.ScheduleType == 2) {
      
        if(this.twiceAmonthDay == 0)
        {
        this.$swal("Warning!", "The 'Day' field is required for this schedule type.", "warning");
        return;
        }
        else if(this.twiceAmonthDay  == new Date(this.draftItem.StartDate).getDate())
        {
          this.$swal("Warning!", "The day for twice-a-month scheduling cannot be the same as the start date.", "warning");
            return;
        }
      }

      this.addedList = [];

      for (let i = 0; i < this.draftItem.DraftCount; i++) {
        let draft = this.getDraftObject();
        if (i > 0) {
          if (this.draftItem.ScheduleType == 1) {
            draft.NewDate = this.formatShortDate(
              this.addMonthsFix(new Date(this.draftItem.StartDate), i)
            );
          }
          else if(this.draftItem.ScheduleType == 2)
          {
            let addMonths = Math.floor(i/2);
            let startDate = new Date(this.draftItem.StartDate);
            if(i%2== 0)
            {
              draft.NewDate = this.formatShortDate(
              this.addMonthsFix(startDate, addMonths)
              );
            }
            else
            {

               let startDay = startDate.getDate();
               let newDate = this.addMonthsFix(startDate, addMonths);
               let month = newDate.getMonth();
               let year = newDate.getFullYear();
               if(this.twiceAmonthDay > 0)
               {
                let maxDay = this.getMaxDayofMonth(new Date(year,month,1));
                let monthsToAdd = startDay > this.twiceAmonthDay ? 1 :0;
                draft.NewDate = new Date(year,month+monthsToAdd,this.twiceAmonthDay > maxDay ? maxDay : this.twiceAmonthDay);
             
              

               
               }

            }
          }
           else if (this.draftItem.ScheduleType == 3) {
            draft.NewDate = this.formatShortDate(
              this.addDays(new Date(this.draftItem.StartDate), i * 14)
            );
          } else if (this.draftItem.ScheduleType == 4) {
            draft.NewDate = this.formatShortDate(
              this.addDays(new Date(this.draftItem.StartDate), i * 7)
            );
          }
        
          draft.DebitId = -1 * i;
          draft.NewAmount = draftAmount;
          this.addedList.unshift(draft);
        } else {
          draft.NewDate = this.formatShortDate(
            new Date(this.draftItem.StartDate)
          );
          draft.NewAmount = draftAmount;
          this.addedList.unshift(draft);
        }
      }

      if (this.addedList.length > 0) {
        this.$emit("addBulkDrafts", this.addedList);
      } else {
        this.$swal("Warning!", "No draft to add.", "warning");
      }
    },
  },
};
</script>

<style>
.closeButton {
  position: absolute;
  top: -5px;
  right: 15px;
  z-index: 1000;
  font-size: 30px;
  cursor: pointer;
}
.closeButton:hover {
  color: #ff3860;
}
@media screen and (max-height: 390px) {
  .dcp-mediaScreen {
    max-height: 200px !important;
    overflow-y: scroll;
  }
}
</style>