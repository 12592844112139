<template>
  <div
    class="col-md-12 newPanelWrapper noMargin"
    style="background-color: #ffffff; z-index: 1000"
  >
    <div class="closeButton" v-on:click.prevent.stop="closePanel">
      <i class="fas fa-window-close"></i>
    </div>
    <div
      style="display: flex; flex-direction: column; align-items: center"
      class="form-group row"
    >
      <i
        style="font-size: 110px; color: #61b4fa"
        class="ri-rotate-lock-line"
      ></i>

      <label style="font-size: 25px; margin-top: -20px; color: #61b4fa"
        >FORGOT PASSWORD ?</label
      >

      <p style="text-align: center; width: 70%">
        Provide your account's email for which you want to reset your password.
      </p>

      <div class="col-md-12">
        <input
          v-model="resetPswEmail"
          autocomplete="off"
          id="email"
          class="form-control"
          type="text"
          placeholder="Email"
        />
      </div>

      <div class="col-md-12">
        <button
          @click="resetPsw()"
          class="col-md-12 btn btn-primary"
          :disabled="resetPswEmail == '' || isLoading"
          style="background-color: #61b4fa"
        >
          Send
          <i v-if="isLoading" class="spinner-border spinner-border-sm"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/services/login.service";
import to from "@/helpers/to";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";

export default {
  name: "LoginOverlayPopUp",
  components: {},
  props: {
    formUserName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isLoading: false,
      resetPswEmail: "",
      saving: false,
      regex: /^[a-zA-Z0-9._-]+@([a-zA-Z0-9.-]+.)+([.])+[a-zA-Z0-9.-]{2,4}$/,
    };
  },
  methods: {
    async resetPsw() {
      if (this.resetPswEmail) {
        if (this.regex.test(this.resetPswEmail) == false) {
          this.$swal("Warning!", "Email address is not valid!", "warning");
          return;
        } else {
          this.isLoading = true;
          let err, result;
          [err, result] = await to(
            api.resetPasswordRequest(this.resetPswEmail, 0)
          );
          this.isLoading = false;
          if (result) {
            this.$swal("Success!", result.Message, "success");
            this.$emit("closeLoginOverlayPopUp");
          } else {
            let errMsg = this.getApiErrorMessage(err);
            this.$swal("Error!", errMsg, "error");
          }
        }
      }
    },
    closePanel() {
      this.$emit("closeLoginOverlayPopUp");
    },
  },
  async mounted() {
    if (this.regex.test(this.formUserName)) {
      this.resetPswEmail = this.formUserName;
    }
  },

  computed: mapState({}),
  mixins: [formatMixin, utilitiesMixin],
};
</script>

<style>
.closeButton {
  position: absolute;
  top: -5px;
  right: 15px;
  z-index: 1000;
  font-size: 30px;
  cursor: pointer;
}
.closeButton:hover {
  color: #ff3860;
}
</style>
