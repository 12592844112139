<template>
  <div class="col-md-12">
    <div class="mt-2 ml-2" v-if="verifyAddressResult" style="float: right">
      <i
        v-if="verifyAddressResult.Success"
        class="fas fa-check mr-2"
        style="font-size: 20px; color: #58a56b"
      ></i>
      <i
        v-if="verifyAddressResult.Error"
        class="fas fa-times mr-2"
        style="font-size: 20px; color: #ed3030"
      ></i>
    </div>
    <button
      type="button"
      :disabled="verifyingAddress"
      class="btn generalButton float-right"
      style="width: 200px; background-color: #3bafda; color: #fff"
      @click="verifyAddress()"
    >
      Verify Address
      <i
        v-if="verifyingAddress"
        class="fas fa-spinner"
        style="font-size: 18px"
      ></i>
    </button>
  </div>
</template>
<script>
export default {
  name: "UspsAddressVerification",
  props: {
    SalesUserId: {
      type: Number,
      default: () => null,
    },
    OfficeId: {
      type: Number,
      default: () => null,
    },
    Address: {
      type: String,
      default: () => "",
    },
    Address2: {
      type: String,
      default: () => "",
    },
    City: {
      type: String,
      default: () => "",
    },
    Zip: {
      type: String,
      default: () => "",
    },
    State: {
      type: String,
      default: () => "",
    },
    CustomerId: {
      type: Number,
      default: () => null,
    },
  },
  data() {
    return {
      verifyingAddress: false,
      verifyAddressResult: {
        Success: false,
        Error: false,
        Text: "",
      },
    };
  },
  methods: {
    async verifyAddress() {
      if (!this.SalesUserId || !this.OfficeId) {
        this.$swal(
          "Warning",
          this.CustomerId
            ? "Agent and office must be assigned to verify address."
            : "Please save the customer record to verify address.",
          "warning"
        );
        return;
      }

      if (
        this.Address.trim().length == 0 &&
        this.City.trim().length == 0 &&
        this.Zip.trim().length == 0 &&
        this.State.trim().length == 0
      ) {
        this.$swal("Warning!", "Please fill address fields!", "warning");
      } else {
        let payload = {
          Address: this.Address,
          Address2: this.Address2,
          City: this.City,
          Zip: this.Zip,
          StateShort: this.State,
        };

        this.verifyingAddress = true;

        let err, result;

        [err, result] = await this.$store.dispatch("USPS_VERIFY", payload);

        if (result.Data) {
          this.verifyingAddress = false;
          if (result.Data.HasErrors) {
            this.verifyAddressResult.Success = false;
            this.verifyAddressResult.Error = true;
            this.verifyAddressResult.Text = "Address invalid.";
            this.$swal("Error!", result.Data.ErrorDescription, "error");
            this.sendVerifyResult(result.Data);
          } else {
            if (result.Data.ReturnText == "") {
              this.verifyAddressResult.Success = true;
              this.verifyAddressResult.Error = false;
              this.verifyAddressResult.Text = "Address valid.";
              this.$swal("Success", "Address valid.", "success");
            } else {
              this.verifyAddressResult.Success = true;
              this.verifyAddressResult.Error = false;
              this.verifyAddressResult.Text = "Address found.";
              this.$swal("Warning!", result.Data.ReturnText, "warning");
            }
            this.sendVerifyResult(result.Data);
          }
        } else {
          this.verifyingAddress = false;
          this.$swal(
            "Error!",
            "An error occured while checking address. Please try again later.",
            "error"
          );
        }

        this.verifyingAddress = false;
      }
    },
    sendVerifyResult(data) {
      this.$emit("sendVerifyResult", data);
    },
  },
};
</script>
