import types from "./types";
import api from "@/services/admin/api";
import to from "@/helpers/to";

export default {
  state: {
    list: [],
  },
  actions: {
    GET_TASK_ACTIONS: async ({ commit }) => {
      let err, result;

      [err, result] = await to(api.getCsrTaskActions());

      if (result) {
        commit(types.SET_TASK_ACTION, result.Data);
      }
    },
    INSERT_TASK_ACTION: async ({ commit }, payload) => {
      return await to(api.saveCsrTaskActions(payload));
    },
    UPDATE_TASK_ACTION: async ({ commit }, payload) => {
      let result = await to(api.updateCsrTaskActions(payload));
      return result;
    },
  },
  mutations: {
    SET_TASK_ACTION: (state, payload) => {
      state.list = payload;
    },
  },
};
