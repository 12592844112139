<template>
  <div class="col-md-12 noPadding">
    <div v-if="isLoading" class="loading">
      <div class="text-center" style="margin-top: 150px">
        <i class="spinner-grow text-info m-2"></i>
      </div>
    </div>
    <div class="col-md-12" style="display: flex; gap: 10px">
      <div style="height: 20px; width: 20px; background-color: #33d078"></div>
      <label class="generalLabel">Required</label>
      <div style="height: 20px; width: 20px; background-color: #f1556c"></div>
      <label class="generalLabel">Not Required</label>
    </div>
    <div
      class="custom-table gcsTabsTableScroll report-wrapper"
      style="margin: 11px"
    >
      <perfect-scrollbar style="max-height: 68vh">
        <table class="col-md-12 noMargin">
          <thead>
            <tr>
              <th style="position: sticky; left: 0; background-color: #efefef">
                Templates
              </th>
              <th
                style="
                  min-width: 80px !important;
                  position: sticky;
                  left: 125px;
                  background-color: #efefef;
                "
              >
                All States
              </th>
              <th
                style="text-align: center"
                v-for="(state, index) in states"
                :key="index"
              >
                {{ state.Name }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, rowIndex) in newList" :key="rowIndex">
              <td
                style="
                  position: sticky;
                  left: 0;
                  background-color: white;
                  white-space: nowrap;

                  background-color: #efefef;
                "
              >
                {{ rowIndex + 1 }}- {{ item.TemplateName }}
              </td>
              <td
                style="
                  min-width: 80px !important;
                  text-align: center;
                  position: sticky;
                  left: 125px;
                  padding-left: 5px;
                  background-color: #efefef;
                "
              >
                <input
                  type="checkbox"
                  class="document-checkBox"
                  @change="allStatesSelected($event, item)"
                />
              </td>
              <td
                v-for="(state, colIndex) in states"
                :key="colIndex"
                style="padding: 0; border: 1px solid white; text-align: center"
                @click="toggleState(item, state)"
              >
                <div
                  class="document-box"
                  :class="getStyleAdmin(item, state)"
                ></div>
              </td>
            </tr>
          </tbody>
        </table>
      </perfect-scrollbar>
    </div>
    <div class="col-md-12" style="display: flex; justify-content: flex-end">
      <button class="btn btn-success" @click="saveData" :disabled="isLoading">
        Save Configuration
      </button>
    </div>
  </div>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import globalTypes from "@/store/types";
import deepCopy from "@/helpers/deepCopy";

export default {
  name: "AuditMatrixConfigurations",
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      newList: [],
      oldList: [],
      isLoading: false,
    };
  },
  computed: mapState({
    states: (state) => state.globals.states,
    auditMatrixConfigList: (state) =>
      state.auditMatrixConfigurations.auditMatrixConfigList,
  }),
  async mounted() {
    this.isLoading = true;
    await Promise.all([
      this.$store.dispatch(types.GET_AUDIT_MATRIX_CONFIG),
      this.$store.dispatch(globalTypes.GET_STATES),
    ]);
    this.transformData();
    this.oldList = deepCopy(this.newList);
    this.isLoading = false;
  },
  methods: {
    allStatesSelected(event, item) {
      if (event && event.target) {
        let isChecked = event.target.checked;
        if (isChecked) {
          item.States = this.states.map((x) => x.Id);
        } else {
          item.States = [];
        }
      }
    },
    async saveData() {
      if (JSON.stringify(this.newList) == JSON.stringify(this.oldList)) {
        this.$swal("Warning!", "No change in configuration.", "warning");
        return;
      }
      this.isLoading = true;
      let err, result;
      [err, result] = await this.$store.dispatch(
        types.UPDATE_AUDIT_MATRIX_CONFIG,
        this.newList
      );
      if (result) {
        this.$swal("Success!", "Configurations successfully saved.", "success");
        this.oldList = deepCopy(this.newList);
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
      this.isLoading = false;
    },
    transformData() {
      this.newList = this.auditMatrixConfigList.map((item) => {
        let parsedConfigs = item.Configs ? JSON.parse(item.Configs) : null;
        return {
          Id: item.Id,
          TemplateName: item.Name,
          States: parsedConfigs ? parsedConfigs.States : [],
        };
      });
    },
    getStyleAdmin(item, state) {
      let rtn = "document-default";
      if (item.States.includes(state.Id)) {
        rtn = "document-required";
      } else {
        rtn = "document-not-required";
      }
      return rtn;
    },
    toggleState(item, state) {
      let stateIndex = item.States.indexOf(state.Id);
      if (stateIndex == -1) {
        item.States.push(state.Id);
      } else {
        item.States.splice(stateIndex, 1);
      }
    },
  },
};
</script>

<style>
.document-box {
  width: 32px;
  height: 32px;
  display: inline-block;
  margin: 10px;
}
.document-default {
  background-color: #999;
}
.document-required {
  background-color: #33d078;
}
.document-not-required {
  background-color: #f1556c;
}
.document-checkBox {
  width: 24px;
  height: 24px;
}
</style>
