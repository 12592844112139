export default {
  GET_COMPANY_SETTINGS_LIST: "GET_COMPANY_SETTINGS_LIST",
  SET_COMPANY_SETTINGS_LIST: "SET_COMPANY_SETTINGS_LIST",
  GET_GLOBAL_SETTINGS_LIST: "GET_GLOBAL_SETTINGS_LIST",
  SET_GLOBAL_SETTINGS_LIST: "SET_GLOBAL_SETTINGS_LIST",
  SEARCH_DOCUSIGN_TEMPLATES: "SEARCH_DOCUSIGN_TEMPLATES",
  GET_OFFICE_SETTINGS: "GET_OFFICE_SETTINGS",
  SET_OFFICE_SETTINGS: "SET_OFFICE_SETTINGS",
  GET_DOCUSIGN_TEMPLATE: "GET_DOCUSIGN_TEMPLATE",
  SET_DOCUSIGN_TEMPLATE: "SET_DOCUSIGN_TEMPLATE",
  GET_DOCUSIGN_TEMPLATE_TYPE: "GET_DOCUSIGN_TEMPLATE_TYPE",
  SET_DOCUSIGN_TEMPLATE_TYPE: "SET_DOCUSIGN_TEMPLATE_TYPE",
  GET_DOCUSIGN_PLACEHOLDER: "GET_DOCUSIGN_PLACEHOLDER",
  SET_DOCUSIGN_PLACEHOLDER: "SET_DOCUSIGN_PLACEHOLDER",
  POST_DOCUSIGN_TEMPLATE: "POST_DOCUSIGN_TEMPLATE",
  INSERT_DOCUSIGN_TEMPLATE_TYPE: "INSERT_DOCUSIGN_TEMPLATE_TYPE",
  UPDATE_DOCUSIGN_TEMPLATE_TYPE: "UPDATE_DOCUSIGN_TEMPLATE_TYPE",
  DELETE_DOCUSIGN_TEMPLATE: "DELETE_DOCUSIGN_TEMPLATE",
  DOWNLOAD_DOCUSIGN_TEMPLATE: "DOWNLOAD_DOCUSIGN_TEMPLATE",
  POST_DOCUSIGN_SETTINGS: "POST_DOCUSIGN_SETTINGS",
  POST_DOCUSIGN_SETTINGS_NEW_DATE: "POST_DOCUSIGN_SETTINGS_NEW_DATE",
  UPDATE_EDIT_DOCUSIGN_TEMPLATE: "UPDATE_EDIT_DOCUSIGN_TEMPLATE",
};
