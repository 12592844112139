import types from "./types";
import api from "@/services/admin/api";
import to from "@/helpers/to";
export default {
  state: {},
  actions: {
    UPDATE_INBOUND_CALL_SETTINGS_ICSM: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.updateInboundCallSettings(payload));
      return [err, result];
    },
    GET_INBOUND_CALL_SETTINGS_ICSM: async ({ commit }) => {
      let err, result;
      [err, result] = await to(api.getInboundCallSettings());
      return [err, result];
    },
    INSERT_INBOUND_CALL_SETTINGS_ICSM: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.insertInboundCallSettings(payload));
      return [err, result];
    },

    UPDATE_COMPANY_CALL_SETTINGS: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.updateCompanyCallSettings(payload));
      return [err, result];
    },

    GET_COMPANY_CALL_SETTINGS: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.getCompanyCallSettings(payload));
      return [err, result];
    },
  },
  mutations: {},
};
