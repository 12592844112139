<template>
  <div class="row admin-page officePage">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
      <div class="datagrid-table gcsTabsTableScroll report-wrapper">
        <o-table :data="filteredSmsBrands">
          <o-table-column
            field="BrandName"
            label="Brand Name"
            sortable
            searchable
          >
            <template v-slot:searchable>
              <div>
                <input
                  type="text"
                  class="datagrid-table creditorTable"
                  v-model="BrandNameFilter"
                  placeholder=""
                />
              </div>
            </template>

            <template v-slot="props">
              <strong v-if="props.row.IsSummary"
                >{{ props.row.BrandName }}
              </strong>
              <span v-if="!props.row.IsSummary">
                {{ props.row.BrandName }}
              </span>
            </template>
          </o-table-column>

          <o-table-column field="Priority" label="Priority" sortable searchable>
            <template v-slot:searchable>
              <div>
                <input
                  type="text"
                  class="datagrid-table creditorTable"
                  v-model="PriorityFilter"
                  placeholder=""
                />
              </div>
            </template>

            <template v-slot="props">
              <strong v-if="props.row.IsSummary"
                >{{ props.row.Priority }}
              </strong>
              <span v-if="!props.row.IsSummary">
                {{ props.row.Priority }}
              </span>
            </template>
          </o-table-column>

          <o-table-column
            field="OutboundDid"
            label="Outbound Did"
            sortable
            searchable
          >
            <template v-slot:searchable>
              <div>
                <input
                  type="text"
                  class="datagrid-table creditorTable"
                  v-model="OutboundDidFilter"
                  placeholder=""
                />
              </div>
            </template>
            <template v-slot="props">
              {{ props.row.OutboundDid }}
            </template>
          </o-table-column>

          <o-table-column field="Offices" label="Offices" sortable searchable>
            <template v-slot:searchable>
              <div>
                <input
                  type="text"
                  class="datagrid-table creditorTable"
                  v-model="OfficesFilter"
                  placeholder=""
                />
              </div>
            </template>
            <template v-slot="props">
              {{ props.row.Offices }}
            </template>
          </o-table-column>

          <o-table-column field="Roles" label="Roles" sortable searchable>
            <template v-slot:searchable>
              <div>
                <input
                  type="text"
                  class="datagrid-table creditorTable"
                  v-model="RolesFilter"
                  placeholder=""
                />
              </div>
            </template>
            <template v-slot="props">
              {{ props.row.Roles }}
            </template>
          </o-table-column>

          <o-table-column
            field="Attorneys"
            label="Attorneys"
            sortable
            searchable
          >
            <template v-slot:searchable>
              <div>
                <input
                  type="text"
                  class="datagrid-table creditorTable"
                  v-model="AttorneysFilter"
                  placeholder=""
                />
              </div>
            </template>
            <template v-slot="props">
              {{ props.row.Attorneys }}
            </template>
          </o-table-column>
        </o-table>
        <section
          v-if="filteredSmsBrands.length == 0"
          class="section"
          style="text-align: center"
        >
          <div class="content has-text-grey has-text-centered mb-4">
            <p class="noMargin">
              <i
                style="font-size: 24px; color: #939393"
                class="ri-question-line"
              ></i>
            </p>
            <p class="noMargin">Nothing here.</p>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import types from "./types";
import formatMixin from "@/mixins/formatMixin";

import _orderby from "lodash";
import utilitiesMixin from "@/mixins/utilitiesMixin";

export default {
  name: "SMSBrandsManagement",
  components: {},
  mixins: [formatMixin, utilitiesMixin],

  data() {
    return {
      smsBrandsInitSummary: {
        BrandName: "",
        Priority: "",
        OutboundDid: "",
        Offices: "",
        Roles: "",
        Attorneys: "",
      },

      BrandNameFilter: "",
      PriorityFilter: "",
      OutboundDidFilter: "",
      OfficesFilter: "",
      RolesFilter: "",
      AttorneysFilter: "",
    };
  },
  computed: mapState({
    SmsBrandsList: (state) => state.smsBrandsManagement.SmsBrandsList,

    filteredSmsBrands() {
      let rtn = [];

      let tmpList =
        this.SmsBrandsList.length > 0
          ? this.SmsBrandsList.filter((item) => {
              return (
                this.filterString(item.BrandName, this.BrandNameFilter) &&
                this.filterString(item.Priority, this.PriorityFilter) &&
                this.filterString(item.OutboundDid, this.OutboundDidFilter) &&
                this.filterString(item.Offices, this.OfficesFilter) &&
                this.filterString(item.Roles, this.RolesFilter) &&
                this.filterString(item.Attorneys, this.AttorneysFilter)
              );
            })
          : [];

      if (tmpList.length == 0) {
        this.reportData = [];
        return rtn;
      }
      let totalCustomer = tmpList.length.toString();

      this.smsBrandsInitSummary.BrandName = "Total: " + totalCustomer;
      this.recordCount = tmpList.length;
      this.smsBrandsInitSummary.IsSummary = true;

      rtn.push(...tmpList);
      rtn.push(this.smsBrandsInitSummary);
      this.reportData = rtn;

      return rtn;
    },
  }),
  async mounted() {
    await this.$store.dispatch(types.GET_SMS_BRANDS);
  },
};
</script>

<style></style>
