import types from "./types";
import api from "@/services/client/customerApi";
import to from "@/helpers/to";
export default {
  state: {
    paymentList: [],
    negotiationPhaseList: [],
    csrCorrespondenceList: [],
    negotiatorTaskGridTotalRecord: 0,
    settlementApprovalGridTotalRecord: 0,
  },
  actions: {
    GET_ONE_TIME_SETTLEMENT_FEE: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.getOneTimeSettlementFee(payload));
      return [err, result];
    },

    GET_PROCESSED_FEES: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.getProcessedFees(payload));
      return [err, result];
    },
    GET_AUDITOR_USERS: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.getAuditorUsers(payload));
      return [err, result];
    },
    BULK_ASSIGN_NEGOTIATOR_TASK: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.bulkAssignNegotiatorTask(payload));
      return [err, result];
    },
    PHONE_PAY_INFO_EDIT_NEGO_TASK: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.phonePayInfo(payload));
      return [err, result];
    },
    GET_ATTONERY_INFO: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getNegotiatorTaskAttoneryInfo(payload));

      return [err, result];
    },
    GET_CASHFLOW_CREDITOR: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getNewGcsTabCashflow(payload));

      return [err, result];
    },
    NEG_PHASE_GCS_VALIDATE: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.negotiatonPhaseGcsValidate(payload));
      return [err, result];
    },
    GET_PAYMENT_TYPES: async ({ commit, state }) => {
      let err, result;

      [err, result] = await to(api.getNegotiatorTasksPaymentTypes());

      return [err, result];
    },
    GET_PAYMENT_TYPES_BY_POLICY_GROUP: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getPaymentTypesByPolicyGroup(payload));
      return [err, result];
    },
    SAVE_NEGOTIATOR_TASK: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.saveNegotiatorTask(payload));
      return [err, result];
    },
    GET_NEGOTIATOR_TASK_DETAILS: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.getNegotiatorTaskDetails(payload));
      return [err, result];
    },
    GET_ALL_NEGOTIATOR_TASK: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.getAllNegotiatorTasks(payload));

      return [err, result];
    },
    GET_SETTLEMENT_APPROVALS: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.getAllNegotiatorTasks(payload));

      return [err, result];
    },
    POSTPONE_NEGOTIATOR_TASK: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.postponeNegotiatorTask(payload));
      return [err, result];
    },
    REMOVE_NEGOTIATOR_TASK: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.removeNegotiatorTask(payload));
      return [err, result];
    },
    REQUEST_SETTLEMENT: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.requestReview(payload));
      return [err, result];
    },
    ATTORNEY_TASK_APPROVE: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.approveReview(payload));
      return [err, result];
    },
    ATTORNEY_TASK_DECLINE: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.attorneyTaskDecline(payload));
      return [err, result];
    },

    GET_NEGOTIATOR_PHASES: async ({ commit, state }, payload) => {
      if (state.negotiationPhaseList.length > 0) return;

      let err, result;

      [err, result] = await to(api.getNegotiatorPhases(payload));
      if (result) {
        commit(types.SET_NEGOTIATOR_PHASES, result.Data);
      }
    },
    GET_NEGOTIATOR_CSR_CORRESPONDENCE: async ({ commit, state }) => {
      if (state.csrCorrespondenceList.length > 0) return;

      let err, result;

      [err, result] = await to(api.getNegotiatorCsrCorrespondence());
      if (result) {
        commit(types.SET_NEGOTIATOR_CSR_CORRESPONDENCE, result.Data);
      }
    },
    SEARCH_PAYEE: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.searchPayee(payload));

      return [err, result];
    },
    ADD_NEW_PAYEE: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.addPayee(payload));

      return [err, result];
    },
    GET_PAYEE_DETAILS: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.getPayee(payload));

      return [err, result];
    },
    UPDATE_BANK: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.editPayeeBank(payload));

      return [err, result];
    },
    ADD_NEW_BANK: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.addPayeeBank(payload));

      return [err, result];
    },
    REMOVE_BANK: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.removePayeeBank(payload));

      return [err, result];
    },
    ADD_NEW_CONTACT: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.addPayeeContact(payload));

      return [err, result];
    },
    UPDATE_CONTACT: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.editPayeeContact(payload));

      return [err, result];
    },
    REMOVE_CONTACT: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.removePayeeContact(payload));

      return [err, result];
    },
    ADD_NEW_ADDRESS: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.addPayeeAddress(payload));

      return [err, result];
    },
    UPDATE_ADDRESS: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.editPayeeAddress(payload));

      return [err, result];
    },
    REMOVE_ADDRESS: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.removePayeeAddress(payload));

      return [err, result];
    },
    ADD_SETTLEMENT: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.addSettlement(payload));

      return [err, result];
    },
    RESET_LISTS: async ({ commit, state }) => {
      commit("SET_RESET_LISTS");
    },
  },
  mutations: {
    SET_PAYMENT_TYPES: (state, payload) => {
      state.paymentList = payload;
    },
    SET_NEGOTIATOR_PHASES: (state, payload) => {
      state.negotiationPhaseList = payload;
    },
    SET_NEGOTIATOR_CSR_CORRESPONDENCE: (state, payload) => {
      state.csrCorrespondenceList = payload;
    },
  },
};
