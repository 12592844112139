<template>
  <div class="col-md-12">
    <ReportSummary
      :exportData="reportData"
      :reportName="'RespondersReport'"
      :recordCount="recordCount"
      @updateLoadingStatus="reportLoading = $event"
      @setPayload="setPayload"
      @clearReport="clearReport"
      @setPagination="setPagination"
      :loadingSpinner="loadingSpinner"
    />

    <div
      class="datagrid-table gcsTabsTableScroll report-wrapper"
      :style="'max-height: ' + reportsPanelHeight + 'px; overflow-y:auto;'"
    >
      <o-table
        :data="filteredRespondersReport"
        :paginated="isPaginated && filteredRespondersReport.length > 0"
        :per-page="perPage"
        :pagination-simple="isPaginationSimple"
        :pagination-position="paginationPosition"
        :sticky-header="true"
      >
        <o-table-column
          field="CustomerId"
          label="Customer Id"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CustomerIdFilter"
                placeholder=""
                v-myMask="{
                  alias: 'numeric',
                  rightAlign: false,
                }"
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.CustomerId }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.CustomerId }}
            </span>
          </template>
        </o-table-column>
        <o-table-column field="First" label="First Name" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="FirstFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.First }}
          </template>
        </o-table-column>
        <o-table-column field="Last" label="Last Name" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="LastFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.Last }}
          </template>
        </o-table-column>

        <o-table-column
          field="SalesAgentName"
          label="Sales Agent Name"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="SalesAgentNameFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.SalesAgentName }}
          </template>
        </o-table-column>

        <o-table-column field="Address" label="Address" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="AddressFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary">{{ props.row.Address }} </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.Address }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="PhoneNumber"
          label="Phone Number"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="PhoneNumberFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ tryFormatPhone(props.row.PhoneNumber) }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ tryFormatPhone(props.row.PhoneNumber) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column field="City" label="City" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CityFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.City }}
          </template>
        </o-table-column>
        <o-table-column field="State" label="State" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="StateFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.State }}
          </template>
        </o-table-column>
        <o-table-column field="ZipCode" label="Zip Code" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="ZipCodeFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.ZipCode }}
          </template>
        </o-table-column>
        <o-table-column
          field="CampaignName"
          label="Campaign Name"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CampaignNameFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            {{ props.row.CampaignName }}
          </template>
        </o-table-column>

        <o-table-column
          field="CampaignCode"
          label="Reference Code"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="CampaignCodeFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.CampaignCode }}
          </template>
        </o-table-column>

        <o-table-column field="Source" label="Tier" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="SourceFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.Source }}
          </template>
        </o-table-column>

        <o-table-column field="Status" label="Status" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="StatusFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ props.row.Status }}
          </template>
        </o-table-column>

        <o-table-column
          field="EnrolledDebt"
          label="Enrolled Debt"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="EnrolledDebtFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.EnrolledDebt }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ formatMoney(props.row.EnrolledDebt) }}
            </span>
          </template>
        </o-table-column>

        <o-table-column
          field="UpdatedDate"
          label="Updated Date"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="UpdatedDateFilter"
                placeholder=""
              />
            </div>
          </template>
          <template v-slot="props">
            {{ formatShortDate(props.row.UpdatedDate) }}
          </template>
        </o-table-column>
      </o-table>
      <section
        v-if="filteredRespondersReport.length == 0"
        class="section"
        style="text-align: center"
      >
        <div class="content has-text-grey has-text-centered mb-4">
          <p class="noMargin">
            <i
              style="font-size: 24px; color: #939393"
              class="ri-question-line"
            ></i>
          </p>
          <p class="noMargin">Nothing here.</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import ReportSummary from "./ReportSummary.vue";
import utilitiesMixin from "@/mixins/utilitiesMixin";

export default {
  name: "RespondersReport",
  components: { ReportSummary },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      reportsPanelHeight: 300,
      loadingSpinner: false,
      isPaginationSimple: false,
      isPaginated: true,
      paginationPosition: "both",
      currentPage: 1,
      perPage: 200,
      recordCount: 0,
      reportLoading: false,
      reportData: [],

      respondersReportInitSummary: {
        SalesAgentName: "",
        CustomerId: "",
        First: "",
        Last: "",
        Address: "",
        PhoneNumberFilter: "",
        City: "",
        State: "",
        ZipCode: "",
        CampaignName: "",
        EnrolledDebt: "",
        CampaignCode: "",
        Status: "",
        UpdatedDate: "",
        Source: "",
      },
      SourceFilter: "",
      SalesAgentNameFilter: "",
      CustomerIdFilter: "",
      FirstFilter: "",
      LastFilter: "",
      AddressFilter: "",
      PhoneNumberFilter: "",
      CityFilter: "",
      StateFilter: "",
      ZipCodeFilter: "",
      CampaignNameFilter: "",
      EnrolledDebtFilter: "",
      CampaignCodeFilter: "",
      StatusFilter: "",
      UpdatedDateFilter: "",
    };
  },
  computed: mapState({
    respondersList: (state) => state.reports.respondersList,
    filteredRespondersReport() {
      let rtn = [];

      let tmpList =
        this.respondersList.length > 0
          ? this.respondersList.filter((item) => {
              return (
                this.filterString(
                  item.SalesAgentName,
                  this.SalesAgentNameFilter
                ) &&
                this.filterString(item.CustomerId, this.CustomerIdFilter) &&
                this.filterString(item.First, this.FirstFilter) &&
                this.filterString(item.Last, this.LastFilter) &&
                this.filterString(item.Address, this.AddressFilter) &&
                this.filterString(item.Source, this.SourceFilter) &&
                this.filterString(item.PhoneNumber, this.PhoneNumberFilter) &&
                this.filterString(item.City, this.CityFilter) &&
                this.filterString(item.State, this.StateFilter) &&
                this.filterString(item.ZipCode, this.ZipCodeFilter) &&
                this.filterString(item.CampaignName, this.CampaignNameFilter) &&
                this.filterString(item.EnrolledDebt, this.EnrolledDebtFilter) &&
                this.filterString(item.CampaignCode, this.CampaignCodeFilter) &&
                this.filterString(item.Status, this.StatusFilter) &&
                new Date(item.UpdatedDate)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .indexOf(this.UpdatedDateFilter) > -1
              );
            })
          : [];

      if (tmpList.length == 0) {
        this.reportData = [];
        return rtn;
      }
      let totalCustomer = tmpList.length.toString();

      this.respondersReportInitSummary.CustomerId = "Total: " + totalCustomer;
      this.recordCount = tmpList.length;
      this.respondersReportInitSummary.IsSummary = true;

      rtn.push(...tmpList);
      rtn.push(this.respondersReportInitSummary);
      this.reportData = rtn;

      return rtn;
    },
  }),
  async mounted() {
    this.reportsPanelHeight = this.setReportGridHeight(
      window.innerHeight,
      document.getElementById("reportsSummary")
    );

    await this.sleep(1000);
    this.fixStickyHeaders();
  },
  methods: {
    setPagination(data) {
      this.isPaginated = data;
    },
    async setPayload(value) {
      this.loadingSpinner = true;
      await this.$store.dispatch(types.GET_RESPONDERS_REPORT, value);
      this.loadingSpinner = false;

      await this.sleep(500);
      this.fixStickyHeaders();
    },
    clearReport() {
      this.$store.dispatch(types.RESET_REPORTS);
      this.reportData = [];
    },
  },
};
</script>

<style scoped></style>
