import types from "./types";
import api from "@/services/admin/api";
import to from "@/helpers/to";

export default {
  state: {},
  actions: {
    GET_MONTHLY_DASHBOARD_CONFIG: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(
        api.getMonthlyDashboardConfigManagement(payload)
      );
      return [err, result];
    },

    ADD_MONTHLY_DASHBOARD_CONFIG: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(
        api.addMonthlyDashboardConfigManagement(payload)
      );
      return [err, result];
    },
    UPDATE_MONTHLY_DASHBOARD_CONFIG: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(
        api.updateMonthlyDashboardConfigManagement(payload)
      );
      return [err, result];
    },
  },
  mutations: {},
};
