<template>
  <div
    class="popover fade bs-popover-top show"
    role="tooltip">
    <div class="arrow" style="left: 124px"></div>
    <h3 class="popover-header">
      <slot name="header" />
    </h3>
    <div 
    class="popover-body" 
    :style="{ height: height + 'px' }">
      <slot name="body" />
    </div>
    </div>
</template>

<script>
export default {
  props: {
    height: {
      type: Number,
      default: 200,
    },
  
  },
};
</script>
