<template>
  <div
    class="col-md-12 newPanelWrapper noMargin"
    style="background-color: #ffffff; z-index: 1000"
  >
    <div v-if="isLoading" class="loading">
      <div class="text-center" style="margin-top: 150px">
        <i class="spinner-grow text-info m-2"></i>
      </div>
    </div>

    <div
      style="display: flex; justify-content: space-between"
      v-on:click.prevent.stop="closePanel"
    >
      <label>Payments </label>

      <i class="fas fa-window-close closeButtonBulk"></i>
    </div>
    <div
      v-if="
        creditorData &&
        creditorData.PaymentDetails &&
        creditorData.PaymentDetails.length > 0
      "
    >
      <!-- <span
        class="badge badge-success"
        style="padding: 10px 16px"
      >
        Total: {{ creditorData.PaymentDetails.length }}
      </span> -->
      <span  class="badge badge-success" style="padding: 10px 16px">
        Total Payments: {{ payments.length }}
      </span>
      <span
        class="badge badge-success ml-2"
        style="padding: 10px 16px"
      >
        Total Fees: {{ fees.length }}
      </span>
    </div>

    <div class="row">
      <perfect-scrollbar
        style="width: 100%; max-height: 600px; overflow-x: hidden !important"
      >
        <!-- <div v-if="creditorData && !isDpg" class="col-md-12">
          <table
            class="custom-table"
            style="width: 100%"
            v-if="creditorData.IsTermSettlement == true"
          >
            <thead>
              <tr>
                <th></th>
                <th>Date</th>
                <th>Amount</th>
                <th>Fee</th>
                <th>Fee %</th>
              </tr>
            </thead>
            <tbody>
              <tr
                :key="item.Id"
                v-for="(item, index) in creditorData.PaymentDetails"
              >
                <td>
                  <strong> {{ index + 1 }}. </strong>
                </td>
                <td>{{ formatShortDate(item.PaymentDate) }}</td>
                <td>{{ formatMoney(item.PaymentAmount) }}</td>
                <td>{{ formatMoney(item.FeeAmount) }}</td>
                <td>
                  {{
                    item.FeePercentage && item.FeePercentage != ""
                      ? parseFloat(item.FeePercentage).toFixed(2)
                      : ""
                  }}
                  <a v-if="item.FeePercentage">%</a>
                  <a v-if="item.FeePercentage == ''">0 %</a>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="col-md-12 noPadding" v-else>
            <table class="custom-table" style="width: 100%">
              <tbody>
                <tr v-if="creditorData.SettlementOffer">
                  <td>Settlement Offer:</td>
                  <td>{{ formatMoney(creditorData.SettlementOffer) }}</td>
                </tr>
                <tr
                  v-if="
                    creditorData.SettlementFee ||
                    creditorData.SettlementFeeOverride
                  "
                >
                  <td>Settlement Fee:</td>
                  <td>
                    {{
                      creditorData.SettlementFeeOverride != null
                        ? formatMoney(creditorData.SettlementFeeOverride)
                        : formatMoney(creditorData.SettlementFee)
                    }}
                  </td>
                </tr>
                <tr v-if="creditorData.PaymentDate">
                  <td>Payment Date:</td>
                  <td>{{ formatShortDate(creditorData.PaymentDate) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div> -->
        <div v-if="creditorData" class="col-md-12 mt-2">
          <label v-if="creditorData.IsTermSettlement == true" class="generalLabel">Payments </label>
          <table
            class="custom-table"
            style="width: 100%"
            v-if="creditorData.IsTermSettlement == true"
          >
            <thead>
              <tr>
                <th></th>
                <th>Date</th>
                <th>Payment Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr :key="item.Id" v-for="(item, index) in payments">
                <td>
                  <strong> {{ index + 1 }}. </strong>
                </td>
                <td>{{ formatShortDate(item.PaymentDate) }}</td>
                <td>{{ formatMoney(item.PaymentAmount) }}</td>
              </tr>
            </tbody>
          </table>
          <div class="col-md-12 noPadding" v-else>
            <table class="custom-table" style="width: 100%">
              <tbody>
                <tr v-if="creditorData.SettlementOffer">
                  <td>Settlement Offer:</td>
                  <td>{{ formatMoney(creditorData.SettlementOffer) }}</td>
                </tr>
                <tr
                  v-if="
                    creditorData.SettlementFee ||
                    creditorData.SettlementFeeOverride
                  "
                >
                  <td>Settlement Fee:</td>
                  <td>
                    {{
                      creditorData.SettlementFeeOverride != null
                        ? formatMoney(creditorData.SettlementFeeOverride)
                        : formatMoney(creditorData.SettlementFee)
                    }}
                  </td>
                </tr>
                <tr v-if="creditorData.PaymentDate">
                  <td>Payment Date:</td>
                  <td>{{ formatShortDate(creditorData.PaymentDate) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-if="creditorData" class="col-md-12 mt-2">
          <label v-if="creditorData.IsTermSettlement == true"  class="generalLabel">Fees </label>
          <table
            class="custom-table"
            style="width: 100%"
            v-if="creditorData.IsTermSettlement == true"
          >
            <thead>
              <tr>
                <th></th>
                <th>Date</th>
                <th>Fee</th>
                <th>Fee %</th>
              </tr>
            </thead>
            <tbody>
              <tr :key="item.Id" v-for="(item, index) in fees">
                <td>
                  <strong> {{ index + 1 }}. </strong>
                </td>
                <td>{{ formatShortDate(item.PaymentDate) }}</td>
                <td>{{ formatMoney(item.FeeAmount) }}</td>
                <td>
                  {{
                    item.FeePercentage && item.FeePercentage != ""
                      ? parseFloat(item.FeePercentage).toFixed(2)
                      : ""
                  }}
                  <a v-if="item.FeePercentage">%</a>
                  <a v-if="item.FeePercentage == ''">0 %</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div v-if="!creditorData" class="col-md-12 noDataLabel">
          There is no payment data.
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import deepCopy from "@/helpers/deepCopy";
import _orderby from "lodash";
export default {
  name: "ShowPayments",
  mixins: [formatMixin, utilitiesMixin],
  props: {
    creditorId: {
      type: Number,
      default: () => 0,
    },
    isDpg: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      creditorData: null,
      isLoading: false,
    };
  },
  computed: mapState({
    payments() {
      return this.creditorData && this.creditorData.PaymentDetails
        ? this.creditorData.PaymentDetails.filter(
            (x) => parseFloat(x.PaymentAmount) > 0
          )
        : [];
    },
    fees() {
      return this.creditorData && this.creditorData.PaymentDetails
        ? this.creditorData.PaymentDetails.filter(
            (x) => parseFloat(x.PaymentAmount) == 0 || parseFloat(x.FeeAmount) > 0
          )
        : [];
    },
  }),
  async mounted() {
    let err, result;
    this.isLoading = true;
    [err, result] = await this.$store.dispatch(types.GET_SETTLEMENT_DETAILS, {
      Id: this.creditorId,
    });
    this.isLoading = false;

    if (result) {
      if (result.Data && result.Data.length > 0) {
        this.creditorData = deepCopy(result.Data[0]);

        if (this.creditorData && this.creditorData.PaymentDetails) {
          this.creditorData.PaymentDetails = _orderby.orderBy(
            this.creditorData.PaymentDetails,
            "PaymentDate",
            "asc"
          );
        }
      }
    } else {
      let errMsg = this.getApiErrorMessage(err);
      this.$swal("Error!", errMsg, "error");
    }
  },
  methods: {
    closePanel() {
      this.$emit("closeShowPayments");
    },
  },
};
</script>

<style>
.closeButton {
  position: absolute;
  top: -5px;
  right: 15px;
  z-index: 1000;
  font-size: 30px;
  cursor: pointer;
}
.closeButton:hover {
  color: #ff3860;
}
.noDataLabel {
  min-height: 150px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  justify-content: space-around;
}
@media screen and (max-height: 390px) {
  .dcp-mediaScreen {
    max-height: 200px !important;
    overflow-y: scroll;
  }
}
.loading {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.6);
  z-index: 1000;
}
</style>
