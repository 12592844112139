<template>
  <div class="col-md-12">
    <ReportSummary
      :exportData="reportData"
      :reportName="'AgentPhoneReport'"
      :recordCount="recordCount"
      @updateLoadingStatus="reportLoading = $event"
      @setPayload="setPayload"
      @clearReport="clearReport"
      @setPagination="setPagination"
      :loadingSpinner="loadingSpinner"
    />

    <div
      class="datagrid-table gcsTabsTableScroll report-wrapper"
      :style="'max-height: ' + reportsPanelHeight + 'px; overflow-y:auto;'"
    >
      <o-table
        :data="filteredAgentPerformanceNewReport"
        :paginated="isPaginated && filteredAgentPerformanceNewReport.length > 0"
        :per-page="perPage"
        :pagination-simple="isPaginationSimple"
        :pagination-position="paginationPosition"
        :sticky-header="true"
      >
        <o-table-column field="UserName" label="User" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="UserNameFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.UserName }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.UserName }}
            </span>
          </template>
        </o-table-column>
        <o-table-column
          field="OnlineTime"
          label="Logged Time"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="OnlineTimeFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ convertSeconds(props.row.OnlineTime) }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ convertSeconds(props.row.OnlineTime) }}
            </span>
          </template>
        </o-table-column>
        <o-table-column
          field="AgentIdleTime"
          label="Idle Time"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="AgentIdleTimeFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ convertSeconds(props.row.AgentIdleTime) }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ convertSeconds(props.row.AgentIdleTime) }}
            </span>
          </template>
        </o-table-column>
        <o-table-column field="AwayTime" label="Away Time" sortable searchable>
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="AwayTimeFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ convertSeconds(props.row.AwayTime) }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ convertSeconds(props.row.AwayTime) }}
            </span>
          </template>
        </o-table-column>
        <o-table-column
          field="OnlineTimePerc"
          label="Online %"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="OnlineTimePercFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.OnlineTimePerc }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.OnlineTimePerc }}%
            </span>
          </template>
        </o-table-column>
        <o-table-column
          field="AvgAnswerTime"
          label="Avg Answer Time"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="AvgAnswerTimeFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.AvgAnswerTime }} secs
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.AvgAnswerTime.toFixed(2) }} secs
            </span>
          </template>
        </o-table-column>
        <o-table-column
          field="TotalCalls"
          label="Total Calls"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="TotalCallsFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.TotalCalls }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.TotalCalls }}
            </span>
          </template>
        </o-table-column>
        <o-table-column
          field="TotalTalkTime"
          label="Total Talk Time"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="TotalTalkTimeFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ convertSeconds(props.row.TotalTalkTime) }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ convertSeconds(props.row.TotalTalkTime) }}
            </span>
          </template>
        </o-table-column>
        <o-table-column
          field="AvgTalkTime"
          label="Average Talk Time"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="AvgTalkTimeFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary">
              {{ props.row.AvgTalkTime }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ convertSeconds(props.row.AvgTalkTime) }}
            </span>
          </template>
        </o-table-column>
        <o-table-column
          field="InboundCalls"
          label="Inbound Calls"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="InboundCallsFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.InboundCalls }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.InboundCalls }}
            </span>
          </template>
        </o-table-column>
        <o-table-column
          field="InboundCallPerc"
          label="Inbound %"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="InboundCallPercFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.InboundCallPerc }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.InboundCallPerc }}%
            </span>
          </template>
        </o-table-column>
        <o-table-column
          field="OutboundCalls"
          label="Outbound Calls"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="OutboundCallsFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.OutboundCalls }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.OutboundCalls }}
            </span>
          </template>
        </o-table-column>
        <o-table-column
          field="OutboundCallsPerc"
          label="Oubound %"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="OutboundCallsPercFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.OutboundCallsPerc }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.OutboundCallsPerc }}%
            </span>
          </template>
        </o-table-column>
        <o-table-column
          field="MissedCalls"
          label="Direct Missed Calls"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="MissedCallsFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.MissedCalls }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.MissedCalls }}
            </span>
          </template>
        </o-table-column>
        <o-table-column
          field="MissedCallsPerc"
          label="Missed %"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="MissedCallsPercFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.MissedCallsPerc }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.MissedCallsPerc }}%
            </span>
          </template>
        </o-table-column>
        <o-table-column
          field="AvgTimeForMissedCalls"
          label="Avg Time for Missed Calls"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="AvgTimeForMissedCallsFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.AvgTimeForMissedCalls }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.AvgTimeForMissedCalls.toFixed(2) }}
            </span>
          </template>
        </o-table-column>
        <o-table-column
          field="VmDropCalls"
          label="Total Vm Drops"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="VmDropCallsFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.VmDropCalls }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.VmDropCalls }}
            </span>
          </template>
        </o-table-column>
        <o-table-column
          field="VmDropPerc"
          label="VM drop %"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table creditorTable"
                v-model="VmDropPercFilter"
                placeholder=""
              />
            </div>
          </template>

          <template v-slot="props">
            <strong v-if="props.row.IsSummary"
              >{{ props.row.VmDropPerc }}
            </strong>
            <span v-if="!props.row.IsSummary">
              {{ props.row.VmDropPerc }}%
            </span>
          </template>
        </o-table-column>
      </o-table>
    </div>
  </div>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import ReportSummary from "./ReportSummary.vue";
import utilitiesMixin from "@/mixins/utilitiesMixin";

export default {
  name: "AgentPhoneReport",
  components: { ReportSummary },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      reportsPanelHeight: 300,
      loadingSpinner: false,
      isPaginationSimple: false,
      isPaginated: true,
      paginationPosition: "both",
      currentPage: 1,
      perPage: 200,

      recordCount: 0,
      reportLoading: false,
      reportData: [],

      AgentPhoneReportInitSummary: {
        UserName: "",
        OnlineTime: "",
        AgentIdleTime: "",
        AwayTime: "",
        OnlineTimePerc: "",
        AvgAnswerTime: "",
        TotalCalls: "",
        TotalTalkTime: "",
        AvgTalkTime: "",
        InboundCalls: "",
        InboundCallPerc: "",
        OutboundCalls: "",
        OutboundCallsPerc: "",
        MissedCalls: "",
        MissedCallsPerc: "",
        AvgTimeForMissedCalls: "",
        VmDropCalls: "",
        VmDropPerc: "",
      },

      UserNameFilter: "",
      OnlineTimeFilter: "",
      AgentIdleTimeFilter: "",
      AwayTimeFilter: "",
      OnlineTimePercFilter: "",
      AvgAnswerTimeFilter: "",
      TotalCallsFilter: "",
      TotalTalkTimeFilter: "",
      AvgTalkTimeFilter: "",
      InboundCallsFilter: "",
      InboundCallPercFilter: "",
      OutboundCallsFilter: "",
      OutboundCallsPercFilter: "",
      MissedCallsFilter: "",
      MissedCallsPercFilter: "",
      AvgTimeForMissedCallsFilter: "",
      VmDropCallsFilter: "",
      VmDropPercFilter: "",
    };
  },
  computed: mapState({
    agentPhoneList: (state) => state.reports.agentPhoneList,
    filteredAgentPerformanceNewReport() {
      let rtn = [];

      let tmpList =
        this.agentPhoneList.length > 0
          ? this.agentPhoneList.filter((item) => {
              return (
                this.filterString(item.UserName, this.UserNameFilter) &&
                this.filterString(item.OnlineTime, this.OnlineTimeFilter) &&
                this.filterString(
                  item.AgentIdleTime,
                  this.AgentIdleTimeFilter
                ) &&
                this.filterString(
                  item.OnlineTimePerc,
                  this.OnlineTimePercFilter
                ) &&
                this.filterString(item.AwayTime, this.AwayTimeFilter) &&
                this.filterString(item.TotalCalls, this.TotalCallsFilter) &&
                this.filterString(
                  item.TotalTalkTime,
                  this.TotalTalkTimeFilter
                ) &&
                this.filterString(item.AvgTalkTime, this.AvgTalkTimeFilter) &&
                this.filterString(
                  item.AvgAnswerTime,
                  this.AvgAnswerTimeFilter
                ) &&
                this.filterString(item.InboundCalls, this.InboundCallsFilter) &&
                this.filterString(
                  item.InboundCallPerc,
                  this.InboundCallPercFilter
                ) &&
                this.filterString(
                  item.OutboundCalls,
                  this.OutboundCallsFilter
                ) &&
                this.filterString(
                  item.OutboundCallsPerc,
                  this.OutboundCallsPercFilter
                ) &&
                this.filterString(item.MissedCalls, this.MissedCallsFilter) &&
                this.filterString(
                  item.MissedCallsPerc,
                  this.MissedCallsPercFilter
                ) &&
                this.filterString(item.VmDropCalls, this.VmDropCallsFilter) &&
                this.filterString(item.VmDropPerc, this.VmDropPercFilter) &&
                this.filterString(
                  item.AvgTimeforMissedCalls,
                  this.AvgTimeForMissedCallsFilter
                )
              );
            })
          : [];

      if (tmpList.length == 0) {
        this.reportData = [];
        return rtn;
      }
      let totalCustomer = tmpList.length.toString();
      this.AgentPhoneReportInitSummary.UserName = "Total: " + totalCustomer;
      this.recordCount = tmpList.length;
      this.AgentPhoneReportInitSummary.IsSummary = true;

      let totalInboundCall = 0.0;
      let totalOutBoundCall = 0.0;
      let totalMissedCall = 0.0;
      let totalVmDrop = 0.0;
      let totalCall = 0.0;
      let totalTalkTime = 0.0;
      let totalAvgAnswerTime = 0.0;
      let totalAwayTime = 0.0;
      let totalAgentIdleTime = 0.0;
      let totalAvgTimeforMissedCalls = 0.0;
      let totalLogged = 0.0;
      let timeCount = tmpList.length;

      for (let listItem of tmpList) {
        totalLogged += listItem.OnlineTime;
        totalAgentIdleTime += listItem.AgentIdleTime;
        totalAwayTime += listItem.AwayTime;
        totalAvgAnswerTime += listItem.AvgAnswerTime * listItem.InboundCalls;
        totalCall += listItem.TotalCalls;
        totalTalkTime += listItem.TotalTalkTime;
        totalInboundCall += listItem.InboundCalls;
        totalOutBoundCall += listItem.OutboundCalls;
        totalMissedCall += listItem.MissedCalls;
        totalAvgTimeforMissedCalls +=
          listItem.AvgTimeForMissedCalls * listItem.MissedCalls;
        totalVmDrop += listItem.VmDropCalls;
      }
      let totalOutBoundCalls = totalOutBoundCall;
      this.AgentPhoneReportInitSummary.OutboundCalls = totalOutBoundCalls;

      let totalInboundCalls = totalInboundCall;
      this.AgentPhoneReportInitSummary.InboundCalls = totalInboundCalls;

      let totalMissedCalls = totalMissedCall;
      this.AgentPhoneReportInitSummary.MissedCalls = totalMissedCalls;

      let totalavgforMissedCalls = (
        totalAvgTimeforMissedCalls / totalMissedCalls
      ).toFixed(2);
      this.AgentPhoneReportInitSummary.AvgTimeForMissedCalls =
        totalavgforMissedCalls;

      let total_calls = totalCall;
      this.AgentPhoneReportInitSummary.TotalCalls = total_calls;

      let total_Talk_Time = totalTalkTime;
      this.AgentPhoneReportInitSummary.TotalTalkTime = total_Talk_Time;

      let avgTalkTime = totalTalkTime / totalCall;
      if (totalTalkTime == 0 || totalCall == 0) avgTalkTime = 0;
      this.AgentPhoneReportInitSummary.AvgTalkTime =
        this.convertSeconds(avgTalkTime);

      let avgAnswerPerc = (totalAvgAnswerTime / totalInboundCalls).toFixed(2);
      this.AgentPhoneReportInitSummary.AvgAnswerTime = avgAnswerPerc;

      this.AgentPhoneReportInitSummary.AwayTime = totalAwayTime.toString();
      this.AgentPhoneReportInitSummary.AgentIdleTime =
        totalAgentIdleTime.toString();

      let totalInboundCallPerc =
        ((totalInboundCalls / totalCall) * 100).toFixed(2) + "%";
      if ((totalInboundCalls = 0 || totalCall == 0)) totalInboundCallPerc = 0;
      this.AgentPhoneReportInitSummary.InboundCallPerc = totalInboundCallPerc;

      let totalOutboundCallPerc =
        ((totalOutBoundCalls / totalCall) * 100).toFixed(2) + "%";
      if ((totalOutBoundCall = 0 || totalCall == 0)) totalOutboundCallPerc = 0;
      this.AgentPhoneReportInitSummary.OutboundCallsPerc =
        totalOutboundCallPerc;

      let missedCallPerc =
        ((totalMissedCalls / totalInboundCall) * 100).toFixed(2) + "%";
      if (totalMissedCalls == 0 || totalInboundCall == 0) missedCallPerc = "0%";
      this.AgentPhoneReportInitSummary.MissedCallsPerc = missedCallPerc;

      let vmDropPerc = ((totalVmDrop / timeCount) * 100).toFixed(2) + "%";
      this.AgentPhoneReportInitSummary.VmDropPerc = vmDropPerc;

      let onlineTimePerc =
        (((totalAgentIdleTime + totalTalkTime) / totalLogged) * 100).toFixed(
          2
        ) + "%";
      if (totalAgentIdleTime == 0 || totalTalkTime == 0 || totalLogged == 0)
        onlineTimePerc = "0%";
      this.AgentPhoneReportInitSummary.OnlineTimePerc = onlineTimePerc;

      let totalVmDrops = totalVmDrop.toString();
      this.AgentPhoneReportInitSummary.VmDropCalls = totalVmDrops;

      let totalOnlineTimes = totalLogged.toString();
      this.AgentPhoneReportInitSummary.OnlineTime = totalOnlineTimes;

      rtn.push(...tmpList);
      rtn.push(this.AgentPhoneReportInitSummary);
      this.reportData = rtn;

      return rtn;
    },
  }),
  async mounted() {
    this.reportsPanelHeight = this.setReportGridHeight(
      window.innerHeight,
      document.getElementById("reportsSummary")
    );
    await this.sleep(1000);
    this.fixStickyHeaders();
  },
  methods: {
    convertSeconds(seconds) {
      let hours = Math.floor(seconds / 3600);
      seconds %= 3600;
      let minutes = Math.floor(seconds / 60);
      seconds %= 60;
      let formattedSeconds = seconds.toFixed(0).toString().padStart(2, "0");
      return `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${formattedSeconds}`;
    },

    setPagination(data) {
      this.isPaginated = data;
    },
    async setPayload(value) {
      this.loadingSpinner = true;
      await this.$store.dispatch(types.GET_AGENT_PHONE_REPORT, value);
      this.loadingSpinner = false;
      await this.sleep(500);
      this.fixStickyHeaders();
    },
    clearReport() {
      this.$store.dispatch(types.RESET_REPORTS);
      this.reportData = [];
    },
  },
};
</script>

<style scoped></style>
