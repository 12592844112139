<template>
  <div class="form-group row" id="reportsSummary">
    <div class="col-md-12">
      <form class="newPanelWrapper" role="form">
        <div class="row mt-2">
          <div
            class="col-xl-2 col-lg-3 col-md-12 col-sm-12 float-left"
            v-if="
              !hideFilters &&
              reportClass != 'ScrubReports' &&
              reportName != 'AgentActivityReport' &&
              reportName != 'DocumentsAuditReport' &&
              reportName != 'PaymentsAndFeesReport' &&
              reportName != 'GlobalDraftsReport' &&
              reportName != 'GlobalPaymentsAndFeesReport' &&
              reportName != 'LeadTracEnrollmentsReport' &&
              reportName != 'SnfPayoutReport' &&
              reportName != 'SettlementCandidatesReport'
            "
          >
            <label class="generalLabel" for="">{{ dateFilterText }}</label>
            <select
              v-model="dateCombo"
              placeholder="Select Timeframe"
              class="generalSelectBox"
              style="width: 100% !important"
            >
              <option
                v-for="item in dateOptions"
                :key="item.key"
                :value="item.key"
              >
                {{ item.label }}
              </option>
            </select>
          </div>

          <div
            class="col-xl-1 col-lg-3 col-md-6 col-sm-12"
            v-if="reportName == 'SettlementCandidatesReport'"
          >
            <label class="generalLabel">Balance %</label>
            <input
              v-model="balancePercentage"
              class="generalSelectBox"
              type="text"
              v-myMask="{
                alias: 'integer',
                rightAlign: false,
              }"
              @keydown.enter.prevent
            />
          </div>

          <div
            class="col-xl-2 col-lg-3 col-md-6 col-sm-12"
            v-if="
              reportName == 'SnfPayoutReport' ||
              reportName == 'PaymentsAndFeesReport' ||
              reportName == 'GlobalPaymentsAndFeesReport'||
              reportName == 'GlobalDraftsReport'
            "
          >
            <label class="generalLabel">Start Date</label>
            <DatePicker mode="date" :locale="'en-US'" v-model="thirtyDayDate1">
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  autocomplete="off"
                  class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                  :value="inputValue"
                  v-on="inputEvents"
                  placeholder="Start Date"
                />
              </template>
            </DatePicker>
          </div>
          <div
            class="col-xl-2 col-lg-3 col-md-6 col-sm-12"
            v-if="
              reportName == 'SnfPayoutReport' ||
              reportName == 'PaymentsAndFeesReport' ||
              reportName == 'GlobalPaymentsAndFeesReport'||
              reportName == 'GlobalDraftsReport'
            "
          >
            <label class="generalLabel">End Date</label>
            <DatePicker
              mode="date"
              :locale="'en-US'"
              v-model="thirtyDayDate2"
              :available-dates="disabledEndDates"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  autocomplete="off"
                  class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                  :value="inputValue"
                  v-on="inputEvents"
                  placeholder="End Date"
                />
              </template>
            </DatePicker>
          </div>

          <div
            class="col-xl-2 col-lg-3 col-md-12 col-sm-12 float-left"
            v-if="reportName === 'PendingChangesReport'"
          >
            <label class="generalLabel" for="">Status</label>
            <select
              v-model="pendingChangeStatusId"
              placeholder="Select Status"
              class="generalSelectBox"
              style="width: 100% !important"
            >
              <option value="0">Please Select</option>
              <option
                v-for="item in pendingChangeStatuses"
                :key="item.Id"
                :value="item.Id"
              >
                {{ item.Name }}
              </option>
            </select>
          </div>
          <div
            class="col-xl-2 col-lg-3 col-md-12 col-sm-12 float-left"
            v-if="reportName === 'PendingChangesReport'"
          >
            <label class="generalLabel" for="">Type</label>
            <select
              v-model="pendingChangeTypeId"
              placeholder="Select Type"
              class="generalSelectBox"
              style="width: 100% !important"
            >
              <option value="0">Please Select</option>

              <option
                v-for="item in pendingChangeTypes"
                :key="item.Id"
                :value="item.Id"
              >
                {{ item.Name }}
              </option>
            </select>
          </div>

          <div
            class="col-xl-2 col-lg-3 col-md-6 col-sm-12"
            v-if="
              dateCombo === 'customDateRange' ||
              dateCombo === 'specificDate' ||
              reportName === 'AgentActivityReport'
            "
          >
            <label class="generalLabel">Start Date</label>
            <DatePicker mode="date" :locale="'en-US'" v-model="date1">
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  autocomplete="off"
                  class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                  :value="inputValue"
                  v-on="inputEvents"
                  placeholder="Start Date"
                />
              </template>
            </DatePicker>
          </div>
          <div
            class="col-xl-2 col-lg-3 col-md-6 col-sm-12"
            v-if="dateCombo === 'customDateRange'"
          >
            <label class="generalLabel">End Date</label>
            <DatePicker mode="date" :locale="'en-US'" v-model="date2">
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  autocomplete="off"
                  class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                  :value="inputValue"
                  v-on="inputEvents"
                  placeholder="End Date"
                />
              </template>
            </DatePicker>
          </div>
          <div
            class="col-xl-2 col-lg-3 col-md-12 col-sm-12 float-left"
            v-if="reportName == 'LeadAssignReport' && checkAuth(81550)"
          >
            <label class="generalLabel">Filter By</label>
            <select
              v-model="filterBy"
              class="generalSelectBox"
              style="width: 100% !important"
            >
              <option :value="1">Lead Created Date</option>
              <option :value="2">Deal Submitted Date</option>
            </select>
          </div>

          <div
            class="col-xl-2 col-lg-3 col-md-12 col-sm-12 float-left"
            v-if="reportName == 'CampaignPerformanceReport' && checkAuth(81551)"
          >
            <label class="generalLabel">Filter By</label>
            <select
              v-model="filterBy"
              class="generalSelectBox"
              style="width: 100% !important"
            >
              <option :value="1">Lead Created Date</option>
              <option :value="2">Deal Submitted Date</option>
            </select>
          </div>

          <div
            style="display: flex; align-items: flex-end"
            v-if="reportName === 'CampaignPerformanceReport'"
          >
            <div class="col-xl-12">
              <div style="width: 20px; float: left; margin: 0 12px 0 0">
                <input
                  v-model="groupByAgent"
                  type="checkbox"
                  class="form-control"
                />
              </div>
              <label class="col-form-label">Group by Agent</label>
            </div>
          </div>

          <div
            class="col-xl-2 col-lg-3 col-md-12 col-sm-12"
            v-if="reportName === 'AgentActivityReport'"
          >
            <label class="generalLabel">Agent</label>
            <select
              v-model="Agents"
              class="form-control px-2 py-1 border rounded"
              placeholder="Select Agent"
              @change="agentChange"
            >
              <option :value="null">Select Agent</option>
              <option
                v-for="item in filteredAgents"
                :key="item.Id"
                :value="item.Id"
              >
                {{ item.Name }}
              </option>
            </select>
          </div>

          <div
            class="col-xl-2 col-lg-3 col-md-12 col-sm-12"
            v-if="reportName === 'CustomerTagReport'"
          >
            <label class="generalLabel" for="">Tag Type</label>
            <select
              v-model="TagTypeId"
              class="generalSelectBox"
              style="width: 100% !important"
            >
              <option :value="null">Select Tag Type</option>
              <option
                v-for="item in sortedCustomerTagTypes"
                :key="item.Id"
                :value="item.Id"
              >
                {{ item.TagName }}
              </option>
            </select>
          </div>
          <div
            class="col-xl-2 col-lg-3 col-md-12 col-sm-12"
            v-if="
              reportName == 'CSRTaskReport' ||
              reportName == 'SnfPendingCancellationReport'
            "
          >
            <label class="generalLabel" for="">Task Status</label>
            <select
              v-model="csrTaskStatusId"
              class="generalSelectBox"
              style="width: 100% !important"
            >
              <option :value="null">Select Task Status</option>
              <option
                v-for="item in taskStatuses"
                :key="item.Id"
                :value="item.Id"
              >
                {{ item.Name }}
              </option>
            </select>
          </div>

          <div
            class="col-xl-2 col-lg-3 col-md-12 col-sm-12 float-left"
            v-if="
              reportName === 'AgentPerformanceNewReport' ||
              reportName === 'AgentPhoneReport' ||
              reportName === 'RespondersReport' ||
              reportName === 'DocumentsAuditReport' ||
              reportName === 'SnfPayoutReport'
            "
          >
            <label class="generalLabel">Office</label>
            <select
              v-model="officeId"
              class="generalSelectBox"
              style="width: 100% !important"
              :disabled="isSelectDisabled"
            >
              <option
                v-if="
                  reportName != 'DocumentsAuditReport' &&
                  reportName != 'SnfPayoutReport'
                "
                :value="0"
              >
                All Offices
              </option>
              <option
                v-if="
                  reportName == 'DocumentsAuditReport' ||
                  reportName == 'SnfPayoutReport'
                "
                :value="0"
              >
                Please Select
              </option>
              <option
                v-for="item in filteredOffices"
                :key="item.Id"
                :value="item.Id"
                :selected="filteredOffices.length == 1"
              >
                {{ item.Name }}
              </option>
            </select>
          </div>
          <div
            class="col-xl-2 col-lg-3 col-md-12 col-sm-12 float-left"
            v-if="reportName === 'CSRTaskContactScheduleReport'"
          >
            <label class="generalLabel">Task Type</label>
            <select
              v-model="taskType"
              class="generalSelectBox"
              style="width: 100% !important"
            >
              <option :value="null">All Task Types</option>
              <option
                v-for="item in taskTypeNames"
                :key="item.Id"
                :value="item.Id"
              >
                {{ item.Name }}
              </option>
            </select>
          </div>

          <div
            class="col-xl-2 col-lg-3 col-md-12 col-sm-12 float-left"
            v-if="reportName === 'CSRTaskContactScheduleReport'"
          >
            <label class="generalLabel">Trigger Type</label>
            <select
              v-model="triggerType"
              class="generalSelectBox"
              style="width: 100% !important"
            >
              <option :value="null">All Trigger Types</option>
              <option
                v-for="item in triggerTypeList"
                :key="item.Id"
                :value="item.Id"
              >
                {{ item.Name }}
              </option>
            </select>
          </div>

          <div
            class="col-xl-2 col-lg-3 col-md-12 col-sm-12 float-left"
            v-if="reportName === 'CSRTaskContactScheduleReport'"
          >
            <label class="generalLabel">Trigger Status</label>
            <select
              v-model="triggerStatus"
              class="generalSelectBox"
              style="width: 100% !important"
            >
              <option :value="null">All Trigger Statuses</option>
              <option
                v-for="item in triggerLogStatus"
                :key="item.Id"
                :value="item.Id"
              >
                {{ item.Name }}
              </option>
            </select>
          </div>

          <div
            class="col-xl-2 col-lg-3 col-md-12 col-sm-12 float-left"
            v-if="
              reportName === 'AgentPhoneReport' ||
              reportName === 'AgentPerformanceNewReport'
            "
          >
            <label class="generalLabel">Role</label>
            <select
              v-model="role"
              class="generalSelectBox"
              style="width: 100% !important"
            >
              <option :value="0">All Roles</option>
              <option
                v-for="item in filteredRoles"
                :key="item.Id"
                :value="item.Id"
              >
                {{ item.Name }}
              </option>
            </select>
          </div>

          <div
            class="col-xl-2 col-lg-3 col-md-12 col-sm-12 float-left"
            v-if="
              reportName == 'DocumentsAuditReport' ||
              reportName == 'LeadTracEnrollmentsReport'
            "
          >
            <label class="generalLabel">Policy Group</label>
            <select
              v-model="policyGroup"
              class="generalSelectBox"
              style="width: 100% !important"
            >
              <option :value="0">Please Select</option>
              <option
                v-for="item in companyPolicyGroups"
                :key="item.PolicyGroupId"
                :value="item.PolicyGroupId"
              >
                {{ item.Name }}
              </option>
            </select>
          </div>

          <div
            class="col-xl-2 col-lg-3 col-md-12 col-sm-12 float-left"
            v-if="
              reportName === 'PaymentsAndFeesReport' ||
              reportName == 'GlobalPaymentsAndFeesReport'
            "
          >
            <label class="generalLabel">Client ID</label>
            <input
              v-model="customerId"
              type="text"
              v-myMask="{
                alias: 'decimal',
                rightAlign: false,
              }"
              placeholder="Client ID"
              class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
            />
          </div>

          <div
            class="col-xl-2 col-lg-3 col-md-12 col-sm-12 float-left"
            v-if="
              reportName === 'PaymentsAndFeesReport' ||
              reportName == 'GlobalPaymentsAndFeesReport'
            "
          >
            <label class="generalLabel">Report Type</label>
            <select
              v-model="reportType"
              class="generalSelectBox"
              style="width: 100% !important"
            >
              <option value="All">All</option>
              <option value="Fee">Fee</option>
              <option value="Payment">Payment</option>
            </select>
          </div>

          <div
            class="col-xl-2 col-lg-3 col-md-12 col-sm-12 float-left"
            v-if="
              reportName === 'PaymentsAndFeesReport' ||
              reportName == 'GlobalPaymentsAndFeesReport'
            "
          >
            <label class="generalLabel">Filter By</label>
            <select
              v-model="filterByPaF"
              class="generalSelectBox"
              style="width: 100% !important"
            >
              <option value="scheduledDate">Scheduled Date</option>
              <option value="clearedDate">Cleared Date</option>
            </select>
          </div>

          <div
            class="col-xl-6 col-lg-6 col-md-12 col-sm-12"
            style="margin-top: 28px"
          >
            <div class="row" style="margin-top: -2px">
              <div class="col-md-3" v-if="hideFilters">
                <button
                  type="button"
                  class="generalButton btn-sm btn-primary waves-effect waves-light float-left"
                  style="width: 100%; background-color: #3bafda; color: #ffffff"
                  @click="refresh()"
                  :disabled="loadingSpinner"
                >
                  <i
                    v-if="loadingSpinner"
                    class="spinner-border spinner-border-sm mr-1"
                  ></i>
                  Submit
                </button>
              </div>
              <div class="col-md-3" v-if="!hideFilters">
                <button
                  type="button"
                  class="generalButton btn-sm btn-primary waves-effect waves-light float-left col-ml-1"
                  style="width: 100%; background-color: #3bafda; color: #ffffff"
                  @click="filterReport()"
                  :disabled="loadingSpinner"
                  v-if="reportClass == 'ScrubReports' && scrubReportId > 0"
                >
                  <i
                    v-if="loadingSpinner"
                    class="spinner-border spinner-border-sm mr-1"
                  ></i>
                  Submit
                </button>
                <button
                  type="button"
                  class="generalButton btn-sm btn-primary waves-effect waves-light float-left col-ml-1"
                  style="width: 100%; background-color: #3bafda; color: #ffffff"
                  @click="filterReport()"
                  :disabled="loadingSpinner"
                  v-if="reportClass != 'ScrubReports'"
                >
                  <i
                    v-if="loadingSpinner"
                    class="spinner-border spinner-border-sm mr-1"
                  ></i>
                  Filter
                </button>
              </div>
              <div
                class="col-md-3"
                v-if="
                  !hideExport &&
                  exportData &&
                  exportData.length > 0 &&
                  reportName != 'SnfPayoutReport'
                "
              >
                <button
                  type="button"
                  class="generalButton btn-sm btn-primary waves-effect waves-light float-left"
                  style="width: 100%; background-color: #3bafda; color: #ffffff"
                  @click="exportReport()"
                >
                  Export
                </button>
              </div>

              <div
                class="col-md-3"
                v-if="
                  reportName == 'SnfPayoutReport' &&
                  allData &&
                  ((allData.FirstEnrollmentPayout &&
                    allData.FirstEnrollmentPayout.length > 0) ||
                    (allData.SettlementFeePayout &&
                      allData.SettlementFeePayout.length > 0) ||
                    (allData.CancelledCustomers &&
                      allData.CancelledCustomers.length > 0) ||
                    (allData.WithdrawnDebt &&
                      allData.WithdrawnDebt.length > 0) ||
                    (allData.CustomerAddsaDebt &&
                      allData.CustomerAddsaDebt.length > 0) ||
                    (allData.DecreasedDebt &&
                      allData.DecreasedDebt.length > 0) ||
                    (allData.WithdrawnDebt && allData.WithdrawnDebt.length > 0))
                "
              >
                <button
                  type="button"
                  class="generalButton btn-sm btn-primary waves-effect waves-light float-left"
                  style="width: 100%; background-color: #3bafda; color: #ffffff"
                  @click="exportReport()"
                >
                  Export
                </button>
              </div>

              <div
                class="col-md-3"
                v-if="!hidePrint && exportData && exportData.length > 0"
              >
                <button
                  type="button"
                  class="generalButton btn-sm btn-primary waves-effect waves-light float-left"
                  style="width: 100%; background-color: #3bafda; color: #ffffff"
                  @click="print()"
                >
                  Print
                </button>
              </div>

              <div
                class="col-md-3"
                v-if="
                  exportData &&
                  exportData.length > 0 &&
                  !hideFilters &&
                  reportName != 'SnfPayoutReport'
                "
              >
                <button
                  type="button"
                  class="generalButton btn-sm btn-primary waves-effect waves-light float-left"
                  style="width: 100%; background-color: #3bafda; color: #ffffff"
                  @click="clear()"
                >
                  Clear
                </button>
              </div>
              <div
                class="col-md-3"
                v-if="
                  reportName == 'SnfPayoutReport' &&
                  allData &&
                  ((allData.FirstEnrollmentPayout &&
                    allData.FirstEnrollmentPayout.length > 0) ||
                    (allData.SettlementFeePayout &&
                      allData.SettlementFeePayout.length > 0) ||
                    (allData.CancelledCustomers &&
                      allData.CancelledCustomers.length > 0) ||
                    (allData.WithdrawnDebt &&
                      allData.WithdrawnDebt.length > 0) ||
                    (allData.CustomerAddsaDebt &&
                      allData.CustomerAddsaDebt.length > 0) ||
                    (allData.DecreasedDebt &&
                      allData.DecreasedDebt.length > 0) ||
                    (allData.WithdrawnDebt && allData.WithdrawnDebt.length > 0))
                "
              >
                <button
                  type="button"
                  class="generalButton btn-sm btn-primary waves-effect waves-light float-left"
                  style="width: 100%; background-color: #3bafda; color: #ffffff"
                  @click="clear()"
                >
                  Clear
                </button>
              </div>

              <div
                class="col-md-9"
                style="display: flex; align-items: center"
                v-if="reportName == 'DocumentsAuditReport'"
              >
                <div
                  style="
                    height: 17px;
                    width: 17px;
                    background-color: #33d078;
                    border-radius: 3px;
                  "
                ></div>
                <label class="generalLabel ml-1 mt-1">Available</label>

                <div
                  class="ml-2"
                  style="
                    height: 17px;
                    width: 17px;
                    background-color: #f1556c;
                    border-radius: 3px;
                  "
                ></div>
                <label class="generalLabel ml-1 mt-1">Missing</label>

                <div
                  class="ml-2"
                  style="
                    height: 17px;
                    width: 17px;
                    background-color: #d7d4d4;
                    border-radius: 3px;
                  "
                ></div>
                <label class="generalLabel ml-1 mt-1">Not Available</label>
              </div>

              <div
                class="col-md-3"
                v-if="
                  exportData &&
                  exportData.length > 0 &&
                  this.reportName == 'CustomerDispositionSettingsReport'
                "
              >
                <button
                  type="button"
                  class="generalButton btn-sm btn-primary waves-effect waves-light float-left"
                  style="width: 100%; background-color: #3bafda; color: #ffffff"
                  @click="changeView()"
                >
                  {{ viewType == "gridView" ? "Show Graph" : "Show Grid" }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-1" v-if="reportName === 'CustomerStatusReport'">
          <div class="col-xl-2 col-lg-3 col-md-6 col-sm-12 float-left">
            <div style="width: 20px; float: left; margin: 0 12px 0 0">
              <input
                v-model="groupedByCampaign"
                type="checkbox"
                class="form-control"
                @change="groupByCampaignChange()"
              />
            </div>
            <label class="col-form-label">Group by campaign</label>
          </div>
        </div>
      </form>
    </div>
    <div class="form-group row" v-if="exportData && exportData.length > 0">
      <div class="col-md-12">
        <div
          class="col-xl-2 col-lg-2 col-md-12 col-sm-12"
          v-if="
            reportName != 'InboundStatisticsReport' &&
            reportName != 'SnfPayoutReport' &&
            reportName != 'PurchasesClientInformationReport'
          "
        >
          <span class="badge badge-success" style="padding: 10px 16px">
            Total Records: {{ recordCount }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utilitiesMixin from "@/mixins/utilitiesMixin";
import formatMixin from "@/mixins/formatMixin";
import globalTypes from "@/store/types";
import { mapState } from "vuex";
import { Printd } from "printd";
import _orderby from "lodash";
import { DatePicker } from "v-calendar";

export default {
  name: "ReportSummary",
  components: { DatePicker },
  props: {
    exportData: {
      default() {
        return [];
      },
    },
    allData: {
      default() {
        return [];
      },
    },
    reportName: {
      type: String,
      default: () => "",
    },
    reportClass: {
      type: String,
      default: () => "",
    },
    recordCount: {
      type: Number,
      default: () => 0,
    },
    loadingSpinner: {
      Type: Boolean,
      Defult: false,
    },
  },
  data() {
    return {
      balancePercentage: 30,
      groupByAgent: false,
      userSubOffices: [],
      thirtyDayDate1: null,
      thirtyDayDate2: null,
      reportType: "All",
      filterByPaF: "scheduledDate",
      customerId: null,
      policyGroup: 0,
      filterBy: 1,
      Agents: null,
      AgentName: "",
      scrubReportId: 0,
      reportIds: 0,
      userOfficeId: 0,
      hasAccessOtherOffices: false,
      officeId: 0,
      role: 0,
      triggerStatus: null,
      TagTypeId: null,
      taskType: null,
      triggerType: null,
      pendingChangeStatusId: 0,
      pendingChangeTypeId: 0,
      csrTaskStatusId: null,
      date1: null,
      date2: null,
      config: {
        format: "MM/DD/YYYY",
        useCurrent: false,
        showClear: false,
        showClose: true,
        maxDate: new Date(),
      },
      dateFilterText: "Date Option",
      dateCombo: "today",
      dateOptions: [
        {
          key: "today",
          label: "Today",
        },
        {
          key: "yesterday",
          label: "Yesterday",
        },
        {
          key: "thisWeek",
          label: "This Week",
        },
        {
          key: "thisMonth",
          label: "This Month",
        },
        {
          key: "lastMonth",
          label: "Last Month",
        },
        {
          key: "thisYear",
          label: "This Year",
        },
        {
          key: "specificDate",
          label: "Specific Date",
        },
        {
          key: "customDateRange",
          label: "Custom Date Range",
        },
      ],
      hideFilters: false,
      hideExport: false,
      hidePrint: false,
      groupedByCampaign: false,
      uri: "data:application/vnd.ms-excel;charset=UTF-8;base64,",
      template:
        '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
      base64: function (s) {
        return window.btoa(unescape(encodeURIComponent(s)));
      },
      format: function (s, c) {
        return s.replace(/{(\w+)}/g, function (m, p) {
          return c[p];
        });
      },
      viewType: "gridView",
    };
  },

  async mounted() {
    await Promise.all([
      this.$store.dispatch(globalTypes.GET_TASK_TYPE_NAMES),
      this.$store.dispatch(globalTypes.GET_CUSTOMER_TAG_TYPE),
      this.$store.dispatch(globalTypes.GET_OFFICE_NAMES),
      this.$store.dispatch(globalTypes.GET_ROLES),
      this.$store.dispatch(globalTypes.GET_ALL_ACTIVE_EMPLOYEES),
      this.$store.dispatch(globalTypes.GET_ALL_POLICY_GROUPS),
    ]);

    if (
      this.reportName == "CSRTaskReport" ||
      this.reportName == "SnfPendingCancellationReport"
    ) {
      await this.$store.dispatch(globalTypes.GET_CSRTASKSTATUS);
    } else if (
      this.reportName == "NegotiatorAssignReport" ||
      this.reportName == "SnfDncReport"
    ) {
      this.hideFilters = true;
    } else if (this.reportName == "CSRRealtimeTasksReport") {
      this.hideFilters = true;
      this.hideExport = true;
      this.hidePrint = true;
    } else if (this.reportName == "GlobalDraftsReport") {
      this.hideExport = false;
      this.hidePrint = false;
    }else if (this.reportName == "PendingDispositionReport") {
      this.hideFilters = true;
    } else if (this.reportName == "ActiveEnrollmentsReport") {
      this.hideFilters = true;
    } else if (this.reportName == "VoidedSettlementReport") {
      this.hideFilters = true;
    } else if (this.reportName == "NewLineReport") {
      this.hideFilters = true;
      this.hidePrint = true;
    } else if (this.reportName == "DocumentsAuditReport") {
      this.hidePrint = true;
      this.hideExport = true;
    } else if (this.reportName == "NexGenReport") {
      this.hideFilters = true;
      this.hidePrint = true;
    } else if (this.reportName == "NsfGlobalReport") {
      this.hideFilters = true;
    } else if (this.reportName == "SnfAttorneyActivityReport") {
      this.hideFilters = true;
    } else if (this.reportName == "CustomerDispositionSettingsReport") {
      this.hideFilters = true;
    } else if (this.reportName == "CSRTaskDistroQueueReport") {
      this.hideFilters = true;
    } else if (this.reportName == "SnfPayoutReport") {
      this.hidePrint = true;
    } else if (this.reportName == "DebtBlueSalesCompensationReport") {
      this.dateFilterText = "Draft Date";
    } else if (this.reportName == "AgentDropRateReport") {
      this.dateFilterText = "Enrolled Date";
    } else if (
      this.reportName == "CSRTaskReport" ||
      this.reportName == "SnfPendingCancellationReport"
    ) {
      this.dateFilterText = "Due Date";
    } else if (this.reportName == "AgentPerformanceNewReport") {
      this.reportIds = 9327;
    } else if (this.reportName == "AgentPhoneReport") {
      this.reportIds = 55417;
    } else if (this.reportName == "FortivaScrubReport") {
      this.scrubReportId = 54000;
    } else if (this.reportName == "AmericanExpressScrubReport") {
      this.scrubReportId = 54001;
    } else if (this.reportName == "MasterScrubReport") {
      this.scrubReportId = 54002;
    } else if (this.reportName == "DiscoverScrubReport") {
      this.scrubReportId = 54003;
    } else if (this.reportName == "OneMainScrubReport") {
      this.scrubReportId = 54004;
    } else if (this.reportName == "UsBankElanScrubReport") {
      this.scrubReportId = 54008;
    } else if (this.reportName == "FirstPremierBankScrubReport") {
      this.scrubReportId = 54006;
    } else if (this.reportName == "CapitalOneScrubReport") {
      this.scrubReportId = 54007;
    } else if (this.reportName == "PRAScrubReport") {
      this.scrubReportId = 54009;
    } else if (this.reportName == "SOFIScrubReport") {
      this.scrubReportId = 54010;
    } else if (this.reportName == "SettlementAssistReport") {
      this.scrubReportId = 54005;
    } else if (this.reportName == "LeadAssignReport") {
      this.dateFilterText = "Lead Created Date";
    } else if (this.reportName == "CampaignPerformanceReport") {
      this.dateFilterText = "Lead Created Date";
    } else if (this.reportName == "CSRTaskContactScheduleReport") {
      this.$store.dispatch(globalTypes.GET_TASK_TRIGGER_TYPE);
      this.$store.dispatch(globalTypes.GET_CSR_TASK_TRIGGER_LOG_STATUS);
    } else {
      this.dateFilterText = "Date Option";
    }

    let retrievedData = sessionStorage.getItem(
      "reportFilters-" + this.reportName
    );
    if (typeof retrievedData != undefined && retrievedData) {
      let data = JSON.parse(retrievedData);
      if (data) {
        this.dateCombo = data.Option;
        this.date1 = data.StartDate;
        this.date2 = data.EndDate;

        if (typeof data.ThirtyDayDate1 != undefined && data.ThirtyDayDate1) {
          this.thirtyDayDate1 = data.ThirtyDayDate1;
        }

        if (typeof data.ThirtyDayDate2 != undefined && data.ThirtyDayDate2) {
          this.thirtyDayDate2 = data.ThirtyDayDate2;
        }

        if (
          typeof data.PaymentsAndFeesReportType != undefined &&
          data.PaymentsAndFeesReportType
        ) {
          this.reportType = data.PaymentsAndFeesReportType;
        }
        if (
          typeof data.PaymentsAndFeesFilterBy != undefined &&
          data.PaymentsAndFeesFilterBy
        ) {
          this.filterByPaF = data.PaymentsAndFeesFilterBy;
        }

        if (
          typeof data.PaymentsAndFeesCustomerId != undefined &&
          data.PaymentsAndFeesCustomerId
        ) {
          this.customerId = data.PaymentsAndFeesCustomerId;
        }

        if (typeof data.ScrubReportId != undefined && data.ScrubReportId) {
          this.scrubReportId = data.ScrubReportId;
        }

        if (typeof data.ReportId != undefined && data.ReportId) {
          this.reportIds = data.ReportId;
        }

        if (typeof data.TaskStatusId != undefined && data.TaskStatusId) {
          this.csrTaskStatusId = data.TaskStatusId;
        }

        if (
          typeof data.IsGroupedByCampaign != undefined &&
          data.IsGroupedByCampaign
        ) {
          this.groupedByCampaign = data.IsGroupedByCampaign;
        }

        if (typeof data.TagTypeId != undefined && data.TagTypeId) {
          this.TagTypeId = data.TagTypeId;
        }

        if (
          typeof data.PendingChangeStatusId != undefined &&
          data.PendingChangeStatusId
        ) {
          this.pendingChangeStatusId = data.PendingChangeStatusId;
        }

        if (
          typeof data.PendingChangeTypeId != undefined &&
          data.PendingChangeTypeId
        ) {
          this.pendingChangeTypeId = data.PendingChangeTypeId;
        }
        if (typeof data.OfficeId != undefined && data.OfficeId) {
          this.officeId = data.OfficeId;
        }
        if (typeof data.RoleId != undefined && data.RoleId) {
          this.role = data.RoleId;
        }
        if (typeof data.Agents != undefined && data.Agents) {
          this.Agents = data.Agents;
        }

        if (typeof data.IsGroupByAgent != undefined && data.IsGroupByAgent) {
          this.groupByAgent = data.IsGroupByAgent;
        }

        if (typeof data.TaskTypeId != undefined && data.TaskTypeId) {
          this.taskType = data.TaskTypeId;
        }
        if (typeof data.TriggerTypeId != undefined && data.TriggerTypeId) {
          this.triggerType = data.TriggerTypeId;
        }
        if (typeof data.TriggerStatusId != undefined && data.TriggerStatusId) {
          this.triggerStatus = data.TriggerStatusId;
        }

        if (typeof data.PolicyGroupId != undefined && data.PolicyGroupId) {
          this.policyGroup = data.PolicyGroupId;
        }

        if (
          typeof data.DateFilterTypeId != undefined &&
          data.DateFilterTypeId
        ) {
          this.filterBy = data.DateFilterTypeId;
        }
      }
    }

    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo != null && userInfo.moduleFunctions != null) {
      this.userOfficeId = userInfo.officeId;
      this.userSubOffices = userInfo.subOffices;
    }

    this.hasAccessOtherOffices = this.canSeeAllOffices();
  },
  computed: mapState({
    triggerLogStatus: (state) => state.globals.triggerLogStatus,
    triggerTypeList: (state) => state.globals.triggerTypeList,
    taskTypeNames: (state) => state.globals.taskTypeNames,
    taskStatuses: (state) => state.globals.csrTaskStatus,
    pendingChangeTypes: (state) => state.globals.pendingChangeTypesList,
    pendingChangeStatuses: (state) => state.globals.pendingChangeStatusesList,
    customerTagTypes: (state) => state.globals.customerTagTypes,
    sortedCustomerTagTypes() {
      let rtn = [];
      if (this.checkAuth(7586)) {
        rtn = this.customerTagTypes;
      } else {
        rtn = this.customerTagTypes.filter((item) => !item.IsHidden);
      }
      return rtn;
    },
    offices: (state) => state.globals.offices,
    roles: (state) => state.globals.roles,
    filteredOffices() {
      let rtn = [];
      if (this.checkAuth(1454)) {
        rtn = this.offices;
      } else if (this.checkAuth(55011)) {
        rtn = this.userSubOffices
          ? this.offices.filter(
              (x) =>
                this.userSubOffices.includes(x.Id) || x.Id == this.userOfficeId
            )
          : this.offices.filter((x) => x.Id == this.userOfficeId);
      } else {
        rtn = this.offices.filter((x) => x.Id == this.userOfficeId);
      }
      return _orderby.orderBy(rtn, "Name", "asc");
    },

    isSelectDisabled() {
      return this.filteredOffices.length == 1;
    },

    salesRoles() {
      return this.roles.filter((x) => x.ModuleFunctions.includes(1848));
    },
    filteredRoles() {
      let rtn = [];
      if (this.checkAuth(1454)) {
        rtn = this.salesRoles;
      } else if (this.checkAuth(55011)) {
        rtn = this.userSubOffices
          ? this.salesRoles.filter(
              (x) =>
                this.userSubOffices.includes(x.OfficeId) ||
                x.OfficeId == this.userOfficeId ||
                x.OfficeId == null
            )
          : this.salesRoles.filter(
              (x) => x.OfficeId == this.userOfficeId || x.OfficeId == null
            );
      } else {
        rtn = this.salesRoles.filter(
          (x) => x.OfficeId == this.userOfficeId || x.OfficeId == null
        );
      }

      rtn =
        this.officeId > 0
          ? rtn.filter((x) => x.OfficeId == this.officeId || x.OfficeId == null)
          : rtn;

      return _orderby.orderBy(rtn, "Name", "asc");
    },

    allEmployees: (state) => state.globals.allEmployees,
    filteredAgents() {
      return this.allEmployees.filter((x) =>
        x.IsPhoneEnabled == true && this.hasAccessOtherOffices
          ? x
          : x.OfficeId == this.userOfficeId
      );
    },
    policyGroups: (state) => state.globals.policyGroups,
    companyPolicyGroups() {
      return this.policyGroups.map((item) => {
        return {
          PolicyGroupId: +item.PolicyGroupId,
          Name: item.PolicyGroupId + " - " + item.Name,
        };
      });
    },
    disabledEndDates() {
      let startDate = new Date(this.thirtyDayDate1);
      let endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + 30);

      return {
        start: startDate,
        end: endDate,
      };
    },
  }),
  methods: {
    agentChange() {
      let matchedAgent = this.filteredAgents.find((x) => x.Id == this.Agents);
      this.AgentName = matchedAgent.Name;
      this.$emit("selectedAgentChange", this.Agents, this.AgentName);
    },

    filterReport() {
      this.checkDateFields();
      let payload = {
        Option: this.dateCombo.toLowerCase(),
        StartDate:
          this.dateCombo == "specificDate" && this.date1
            ? new Date(this.date1).toLocaleDateString("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })
            : this.dateCombo == "customDateRange" && this.date1
            ? new Date(this.date1).toLocaleDateString("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })
            : null,
        EndDate:
          this.dateCombo == "customDateRange" && this.date2
            ? new Date(this.date2).toLocaleDateString("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })
            : null,
      };

      let snfPayoutPayload = {
        Option: "customdaterange",
        StartDate: new Date(this.thirtyDayDate1).toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }),
        EndDate: new Date(this.thirtyDayDate2).toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }),
      };

      let leadTracEnrollmentReportPayload = {
        PolicyGroupId: this.policyGroup,
      };

      let scrubPayload = {
        ScrubReportId: this.scrubReportId,
      };

      let documentAuditReportPayload = {
        OfficeId: this.officeId,
        PolicyGroupId: this.policyGroup,
      };

      let globalDraftsPayload = {
        Data: {
          StartDate: this.formatShortDate(this.thirtyDayDate1),
          EndDate: this.formatShortDate(this.thirtyDayDate2)
        }
      }

      let paymentsAndFeesPayload = {
        Data: {
          CustomerId: this.customerId != "" ? parseInt(this.customerId) : null,
          StartDate: this.formatShortDate(this.thirtyDayDate1),
          EndDate: this.formatShortDate(this.thirtyDayDate2),
          ReportType: this.reportType,
          FilterBy: this.filterByPaF,
        },
      };

      let globalPaymentsAndFeesPayload = {
        Data: {
          CustomerId: this.customerId != "" ? parseInt(this.customerId) : null,
          StartDate: this.formatShortDate(this.thirtyDayDate1),
          EndDate: this.formatShortDate(this.thirtyDayDate2),
          ReportType: this.reportType,
          FilterBy: this.filterByPaF,
        },
      };

      if (
        this.reportName == "PaymentsAndFeesReport" ||
        this.reportName == "SnfPayoutReport" ||
        this.reportName == "GlobalPaymentsAndFeesReport"||
        this.reportName == "GlobalDraftsReport"
      ) {
        if (
          this.thirtyDayDate1 == null ||
          this.thirtyDayDate1 == "" ||
          this.thirtyDayDate2 == null ||
          this.thirtyDayDate2 == ""
        ) {
          this.$swal(
            "Warning!",
            "Date fields cannot be left empty.",
            "warning"
          );
          return;
        }
      }

      if (
        this.reportName == "DocumentsAuditReport" ||
        this.reportName == "SnfPayoutReport"
      ) {
        if (this.officeId == 0) {
          this.$swal("Warning!", "Office cannot be left empty.", "warning");
          return;
        }
      }
      if (
        this.reportName == "DocumentsAuditReport" ||
        this.reportName == "LeadTracEnrollmentsReport"
      ) {
        if (this.policyGroup == 0) {
          this.$swal(
            "Warning!",
            "Policy group cannot be left empty.",
            "warning"
          );
          return;
        }
      }

      let year = new Date(this.date1).getFullYear();
      let month = new Date(this.date1).getMonth();
      let day = new Date(this.date1).getDate();

      let localStartDt = new Date(year, month, day, 0, 0, 0);

      let localEndDt = new Date(localStartDt.getTime() + 24 * 60 * 60 * 1000);

      let utcStartDt = localStartDt.toISOString();

      let utcEndDt = localEndDt.toISOString();

      let agentActivityPayload = {
        StartDt: utcStartDt,
        EndDt: utcEndDt,
        UserId: this.Agents,
      };

      let settlementCandidatesReportPayload = {
        BalancePercentage: this.balancePercentage / 100,
      };

      if (this.reportName === "AgentActivityReport") {
        if (agentActivityPayload.UserId == null) {
          this.$swal("Warning!", "Please select an agent.", "warning");
          return;
        }
        this.dateCombo = "specificDate";
      }

      if (this.reportClass == "ScrubReports") {
        scrubPayload;
      }

      if (this.reportName == "DocumentsAuditReport") {
        documentAuditReportPayload;
      }
      if (this.reportName == "LeadTracEnrollmentsReport") {
        leadTracEnrollmentReportPayload;
      }

      if (this.reportName == "PaymentsAndFeesReport") {
        paymentsAndFeesPayload;
      }

      if (this.reportName == "GlobalPaymentsAndFeesReport") {
        globalPaymentsAndFeesPayload;
      }

      if (this.reportName == "GlobalDraftsReport") {
        globalDraftsPayload;
      }

      if (this.reportName == "AgentPerformanceNewReport") {
        payload.OfficeId = this.officeId;
        payload.RoleId = this.role;
        payload.ReportId = this.reportIds;
      }
      if (this.reportName == "AgentPhoneReport") {
        payload.OfficeId = this.officeId;
        payload.RoleId = this.role;
        payload.ReportId = this.reportIds;
      }

      if (this.reportName == "CSRTaskReport") {
        payload.TaskStatusId = this.csrTaskStatusId;
        payload.ReportId = 0;
      }

      if (this.reportName == "SnfPendingCancellationReport") {
        payload.TaskStatusId = this.csrTaskStatusId;
        payload.ReportId = 1;
      }

      if (this.reportName == "CustomerStatusReport") {
        payload.IsGroupedByCampaign = this.groupedByCampaign;
      }
      if (this.reportName == "PendingChangesReport") {
        payload.PendingChangeStatusId = this.pendingChangeStatusId;
        payload.PendingChangeTypeId = this.pendingChangeTypeId;
      }
      if (this.reportName == "RespondersReport") {
        payload.OfficeId = this.officeId;
      }
      if (this.reportName == "SnfPayoutReport") {
        snfPayoutPayload.OfficeId = this.officeId;
      }

      if (this.reportName == "CustomerTagReport") {
        payload.TagTypeId = this.TagTypeId;
      }
      if (this.reportName == "HotLeadReport") {
        payload.LogType = 3;
      }
      if (this.reportName == "XChangeReport") {
        payload.LogType = 6;
      }
      if (this.reportName == "LeadAssignReport") {
        payload.DateFilterTypeId = this.filterBy;
      }
      if (this.reportName == "CampaignPerformanceReport") {
        payload.DateFilterTypeId = this.filterBy;
        payload.IsGroupByAgent = this.groupByAgent;
      }
      if (this.reportName == "NSFReport") {
        payload.NsfReportId = 7452;
      }
      if (this.reportName == "NSFCancelationReport") {
        payload.NsfReportId = 7453;
      }
      if (this.reportName == "CSRTaskContactScheduleReport") {
        payload.TaskTypeId = this.taskType;
        payload.TriggerTypeId = this.triggerType;
        payload.TriggerStatusId = this.triggerStatus;
      }

      sessionStorage.setItem(
        "reportFilters-" + this.reportName,
        JSON.stringify({
          Option: this.dateCombo,
          StartDate:
            this.dateCombo == "specificDate" && this.date1
              ? new Date(this.date1).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })
              : this.dateCombo == "customDateRange" && this.date1
              ? new Date(this.date1).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })
              : null,
          EndDate:
            this.dateCombo == "customDateRange" && this.date2
              ? new Date(this.date2).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })
              : null,
          TaskStatusId: this.csrTaskStatusId,
          IsGroupedByCampaign: this.groupedByCampaign,
          TagTypeId: this.TagTypeId,
          PendingChangeStatusId: this.pendingChangeStatusId,
          PendingChangeTypeId: this.pendingChangeTypeId,
          OfficeId: this.officeId,
          RoleId: this.role,
          ReportId: this.reportIds,
          ScrubReportId: this.scrubReportId,
          Agents: this.Agents,
          AgentName: this.AgentName,
          DateFilterTypeId: this.filterBy,
          TaskTypeId: this.taskType,
          TriggerTypeId: this.triggerType,
          TriggerStatusId: this.triggerStatus,
          PolicyGroupId: this.policyGroup,
          ThirtyDayDate1: this.thirtyDayDate1,
          ThirtyDayDate2: this.thirtyDayDate2,
          PaymentsAndFeesReportType: this.reportType,
          PaymentsAndFeesCustomerId: this.customerId,
          PaymentsAndFeesFilterBy: this.filterByPaF,
          IsGroupByAgent: this.groupByAgent,
        })
      );

      if (this.reportClass == "ScrubReports") {
        this.$emit("setPayload", scrubPayload);
      } else if (this.reportName == "SettlementCandidatesReport") {
        this.$emit("setPayload", settlementCandidatesReportPayload);
      } else if (this.reportName == "AgentActivityReport") {
        this.$emit("setPayload", agentActivityPayload);
      } else if (this.reportName == "DocumentsAuditReport") {
        this.$emit("setPayload", documentAuditReportPayload);
      } else if (this.reportName == "PaymentsAndFeesReport") {
        this.$emit("setPayload", paymentsAndFeesPayload);
      } else if (this.reportName == "GlobalPaymentsAndFeesReport") {
        this.$emit("setPayload", globalPaymentsAndFeesPayload);
      } else if (this.reportName == "LeadTracEnrollmentsReport") {
        this.$emit("setPayload", leadTracEnrollmentReportPayload);
      } else if (this.reportName == "SnfPayoutReport") {
        this.$emit("setPayload", snfPayoutPayload);
      } else if (this.reportName == "GlobalDraftsReport"){
        this.$emit("setPayload", globalDraftsPayload);
      }else {
        this.$emit("setPayload", payload);
      }
    },

    groupByCampaignChange() {
      this.filterReport();
    },
    changeView() {
      if (this.viewType == "gridView") {
        this.viewType = "graphView";
      } else {
        this.viewType = "gridView";
      }
      this.$emit("viewChanged", this.viewType);
    },
    tableToExcel(table, name) {
      let ctx = {
        worksheet: name || "Worksheet",
        table: table.innerHTML,
      };

      let element = document.createElement("a");
      element.setAttribute(
        "href",
        this.uri + this.base64(this.format(this.template, ctx))
      );
      element.setAttribute(
        "download",
        name + " _" + new Date().getTime() + ".xls"
      );
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);

      this.$emit("setPagination", true);
    },
    checkDateFields() {
      if (this.dateCombo == "specificDate") {
        if (this.date1 == null) {
          this.$swal(
            "Warning!",
            "Date fields cannot be left empty.",
            "warning"
          );
        }
        return;
      }

      if (this.dateCombo == "customDateRange") {
        if (!this.date1 || !this.date2) {
          this.$swal(
            "Warning!",
            "Date fields cannot be left empty.",
            "warning"
          );
          return;
        }

        if (this.date1 >= this.date2) {
          this.date1 = new Date(
            this.date2.getFullYear(),
            this.date2.getMonth(),
            this.date2.getDate(),
            0,
            0,
            0
          );
          this.date1 = this.date1.addDays(-1);
        }
      }
    },

    async exportReport() {
      this.$emit("setPagination", false);
      await this.sleep(1000);

      if (this.reportName == "LeadAssignReport") {
        let table = document.querySelector(".report-wrapper > table");
        let temp = table.cloneNode(true);
        if (temp.hasChildNodes) {
          this.tableToExcel(temp, this.reportName);
        }
      } else if (this.reportName == "InboundStatisticsReport") {
        let tables = document.querySelectorAll(
          ".report-wrapper > .table-wrapper > table"
        );
        let exportTable = document.createElement("table");
        tables.forEach((item) => {
          let temp = item.cloneNode(true);
          if (temp.hasChildNodes) {
            let theadNode = temp.childNodes[1];
            if (theadNode.hasChildNodes) {
              let headers = theadNode.childNodes[0];
              for (let i = 1; i < theadNode.childNodes.length; i++) {
                theadNode.removeChild(theadNode.childNodes[i]);
              }
              theadNode.appendChild(headers);
            }
            exportTable.appendChild(theadNode);
            exportTable.appendChild(temp.childNodes[1]);
          }
        });

        this.tableToExcel(exportTable, this.reportName);
      } else if (this.reportName === "SnfPayoutReport") {
        let allData = this.allData || {};

        let firstEnrollmentTable = this.convertToTable(
          allData.FirstEnrollmentPayout || [],
          "First Enrollment Payout"
        );
        let settlementFeeTable = this.convertToTable(
          allData.SettlementFeePayout || [],
          "Settlement Fee Payout"
        );
        let cancelledCustomersTable = this.convertToTable(
          allData.CancelledCustomers || [],
          "Cancelled Customers"
        );
        let withdrawnDebtTable = this.convertToTable(
          allData.WithdrawnDebt || [],
          "Withdrawn Debt"
        );
        let customerAddsDebtTable = this.convertToTable(
          allData.CustomerAddsaDebt || [],
          "Customer Adds a Debt"
        );
        let increasedDebtTable = this.convertToTable(
          allData.IncreasedDebt || [],
          "Increased Debt"
        );
        let decreasedDebtTable = this.convertToTable(
          allData.DecreasedDebt || [],
          "Decreased Debt"
        );

        let tables =
          firstEnrollmentTable +
          settlementFeeTable +
          cancelledCustomersTable +
          withdrawnDebtTable +
          customerAddsDebtTable +
          increasedDebtTable +
          decreasedDebtTable;

        let url = this.tableToExcelSnfPayout(tables, "Data");
        let link = document.createElement("a");
        link.href = url;
        link.download = "SnfPayoutReport.xls";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else if (this.reportName == "DebtBlueSalesCompensationReport") {
        let table = document.querySelector(
          ".report-wrapper > .table-wrapper > table"
        );
        let temp = table.cloneNode(true);
        if (temp.hasChildNodes) {
          let theadNode = temp.childNodes[0];

          if (theadNode.hasChildNodes) {
            let headers = theadNode.childNodes[0];

            for (let i = 1; i < theadNode.childNodes.length; i++) {
              theadNode.removeChild(theadNode.childNodes[i]);
            }
            theadNode.appendChild(headers);
          }
          this.tableToExcel(temp, this.reportName);
        }
      } else {
        let table = document.querySelector(
          ".report-wrapper > .table-wrapper > table"
        );
        let temp = table.cloneNode(true);
        if (temp.hasChildNodes) {
          let theadNode = temp.childNodes[1];

          if (theadNode.hasChildNodes) {
            let headers = theadNode.childNodes[0];

            for (let i = 1; i < theadNode.childNodes.length; i++) {
              theadNode.removeChild(theadNode.childNodes[i]);
            }
            theadNode.appendChild(headers);
          }
          this.tableToExcel(temp, this.reportName);
        }
      }
    },

    // SNF PAYOUT REPORT EXPORT METHODS
    convertToTable(data, title) {
      if (!data || Object.keys(data).length == 0) {
        return `<table><thead><tr><th colspan="100%">${title}</th></tr></thead><tbody><tr><td>No data available</td></tr></tbody></table>`;
      }

      let headers = Object.keys(data[0]);
      let rows = data
        .map((row) => {
          return `<tr>${headers
            .map((header) => `<td>${row[header]}</td>`)
            .join("")}</tr>`;
        })
        .join("");

      let headerRow = `<tr>${headers
        .map((header) => `<th>${header}</th>`)
        .join("")}</tr>`;

      return `<table><thead><tr><th colspan="${headers.length}">${title}</th></tr>${headerRow}</thead><tbody>${rows}</tbody></table>`;
    },

    tableToExcelSnfPayout(table, name) {
      let uri = "data:application/vnd.ms-excel;base64,";
      let template = `<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40">
                      <head><meta charset="UTF-8"><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head>
                      <body><table>{table}</table></body></html>`;
      let base64 = (s) => window.btoa(unescape(encodeURIComponent(s)));
      let format = (s, c) => s.replace(/{(\w+)}/g, (m, p) => c[p]);

      let ctx = { worksheet: name || "Worksheet", table: table };
      let html = format(template, ctx);

      return uri + base64(html);
    },
    ///////////////////////////////////

    async print() {
      let cssText = `
              thead {

                text-align:left !important;
              }
              th{
                width:200px;
              }
              input{display:none !important;}
              tbody td .btn {display:none !important;}
            `;
      let d = new Printd();

      this.$emit("setPagination", false);
      await this.sleep(1000);

      if (this.reportName == "LeadAssignReport") {
        let table = document.querySelector(".report-wrapper > table");
        if (table == null) return;
        let temp = table.cloneNode(true);
        if (temp.hasChildNodes) {
          d.print(temp, [cssText]);
        }
      } else if (this.reportName == "InboundStatisticsReport") {
        let tables = document.querySelectorAll(
          ".report-wrapper > .table-wrapper > table"
        );

        let printTable = document.createElement("table");
        tables.forEach((item) => {
          let temp = item.cloneNode(true);
          printTable.appendChild(temp.childNodes[1]);
          printTable.appendChild(temp.childNodes[1]);
        });
        d.print(printTable, [cssText]);
      } else {
        let table = document.querySelector(
          ".report-wrapper >.table-wrapper > table"
        );
        if (table == null) return;
        let temp = table.cloneNode(true);
        if (temp.hasChildNodes) {
          d.print(temp, [cssText]);
        }
      }
      this.$emit("setPagination", true);
    },

    clear() {
      this.balancePercentage = 30;
      this.TagTypeId = null;
      this.dateCombo = "today";
      this.date1 = null;
      this.date2 = null;
      this.Agents = null;
      this.thirtyDayDate1 = null;
      this.thirtyDayDate2 = null;
      this.$emit("clearReport");
    },
    refresh() {
      this.$emit("refreshReport");
    },
  },
  watch: {
    filterBy: function (newVal) {
      if (
        this.reportName == "LeadAssignReport" ||
        this.reportName == "CampaignPerformanceReport"
      ) {
        if (newVal == 1) {
          this.dateFilterText = "Lead Created Date";
        } else {
          this.dateFilterText = "Deal Submitted Date";
        }
      }
    },
    filteredOffices() {
      if (this.filteredOffices.length === 1) {
        this.officeId = this.filteredOffices[0].Id;
      }
    },
    thirtyDayDate1(newValue, oldValue) {
      if (newValue && this.thirtyDayDate2) {
        let startDate = new Date(newValue);
        let endDate = new Date(this.thirtyDayDate2);
        let endDate30DaysAfterStartDate = new Date(startDate);

        if (
          endDate30DaysAfterStartDate &&
          endDate30DaysAfterStartDate != "Invalid Date"
        ) {
          endDate30DaysAfterStartDate.setDate(startDate.getDate() + 30);
          endDate30DaysAfterStartDate.setHours(0, 0, 0, 0);
        }
        if (endDate && endDate != "Invalid Date") {
          endDate.setHours(0, 0, 0, 0);
        }

        if (
          endDate30DaysAfterStartDate &&
          endDate30DaysAfterStartDate != "Invalid Date" &&
          endDate &&
          endDate != "Invalid Date" &&
          endDate > endDate30DaysAfterStartDate
        ) {
          this.thirtyDayDate2 = "";
        }
      }
    },
  },
  mixins: [utilitiesMixin, formatMixin],
};
</script>

<style scoped>
.o-drop__item--active {
  background-color: #ffffff;
  color: #3bafda;
}
</style>
