import types from "./types";
import api from "@/services/admin/api";
import to from "@/helpers/to";
export default {
  state: {
    customerCancelationList: [],
  },
  actions: {
    GET_CUSTOMER_CANCELATION_REASON: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.getCustomerCancelationReason(payload));
      if (result) {
        commit(types.SET_CUSTOMER_CANCELATION_REASON, result.Data);
      }
    },
    ADD_CUSTOMER_CANCELATION_REASON: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.addCustomerCancelationReason(payload));
      return [err, result];
    },

    UPDATE_CUSTOMER_CANCELATION_REASON: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.updateCustomerCancelationReason(payload));
      return [err, result];
    },
  },
  mutations: {
    SET_CUSTOMER_CANCELATION_REASON: (state, payload) => {
      state.customerCancelationList = payload;
    },
  },
};
