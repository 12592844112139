<template>
  <div
    class="col-md-12 newPanelWrapper noMargin"
    style="background-color: #ffffff; z-index: 1000"
  >
    <div v-if="isLoading" class="newLoading">
      <div class="text-center" style="margin-top: 150px">
        <i class="spinner-grow text-info m-2"></i>
      </div>
    </div>
    <div
      style="display: flex; justify-content: space-between"
      v-on:click.prevent.stop="closePanel"
    >
    <div class="align-items-center" style="height:25px; display: flex; justify-content: left;">
      <label>Bulk Charge Changes</label>
      <button
              type="button"
              class="btn btn-xs btn-danger waves-effect waves-light ml-2"
              v-on:click.prevent.stop="openCashFlowPanel"
              v-if="isCashFlowNegative"
            >
              Negative Cash Flow!
            </button>
    </div>
      <i class="fas fa-window-close closeButtonBulk"></i>
    </div>
    <hr />
    <perfect-scrollbar style="max-height: 600px; margin-top: -20px">
      <div class="col-md-12 admin-page">
        <div
          class="mt-1"
          style="
            display: flex;
            border-bottom: 1px dashed gray;
            align-items: baseline;
          "
        >
          <div>
            <input
              type="checkbox"
              :checked="editFeeDaysChecked"
              @change="changeEditFeeDays"
              class="checkbox-input generalCheckBox"
            />
          </div>
          <label class="generalLabel" style="margin-right: 10px"
            >Edit Charge Days</label
          >
        </div>
        <div class="mt-1" style="display: flex; align-items: baseline">
          <div>
            <input
              name="feeOption"
              :checked="feeDayOption == 1"
              @change="changeFeeDayOption(1)"
              type="radio"
              class="checkbox-input generalCheckBox"
            />
          </div>
          <label for="pushFEEFoward" class="generalLabel"
            >Push charge forward (days):</label
          >
          <input
            type="text"
            :disabled="feeDayOption != 1"
            v-model="feeForwardDays"
            class="generalTextBox miniTextBox ml-1"
            :class="feeDayOption != 1 ? 'readonlyInput' : ''"
            style="width: 40px"
            v-myMask="{
              alias: 'numeric',
              min: 0,
              digits: 0,
              allowMinus: false,
              rightAlign: false,
            }"
          />
        </div>
        <div class="mt-1" style="display: flex; align-items: baseline">
          <div>
            <input
              name="feeOption"
              :checked="feeDayOption == 2"
              @change="changeFeeDayOption(2)"
              type="radio"
              class="checkbox-input generalCheckBox"
            />
          </div>
          <label for="pullFeesBackward" class="generalLabel"
            >Pull charge backward (days):</label
          >
          <input
            type="text"
            :disabled="feeDayOption != 2"
            v-model="feeBackwardDays"
            class="generalTextBox miniTextBox ml-1"
            :class="feeDayOption != 2 ? 'readonlyInput' : ''"
            style="width: 40px"
            v-myMask="{
              alias: 'numeric',
              min: -29,
              max: 0,
              rightAlign: false,
            }"
          />
        </div>
        <div class="mt-2" style="display: flex; align-items: baseline">
          <div>
            <input
              name="feeOption"
              type="radio"
              :checked="feeDayOption == 3"
              @change="changeFeeDayOption(3)"
              class="checkbox-input generalCheckBox"
            />
          </div>
          <label class="generalLabel">Change charge day from:</label>
        </div>
        <div
          class="mt-1"
          style="margin-left: 31px; width: 320px"
          v-if="feeDayOption == 3"
        >
          <perfect-scrollbar style="min-width: 320px">
            <table class="datagrid-table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Day From</th>
                  <th>Day To</th>
                  <th></th>
                </tr>
                <tr v-if="isDayGridNewRowActive">
                  <th>
                    <span
                      style="cursor: pointer; margin: 5px; padding: 5px"
                      class="badge badge-danger"
                      @click="dayGridCancelRow"
                      >Cancel</span
                    >
                  </th>
                  <th>
                    <input
                      v-myMask="{
                        alias: 'numeric',
                        max: 31,
                        digits: 0,
                        allowMinus: false,
                        rightAlign: false,
                      }"
                      class="generalTextBox miniTextBox"
                      type="text"
                      v-model="dayGridFrom"
                      v-on:keydown.enter.prevent="dayGridSave"
                      v-on:keydown.esc.prevent="dayGridCancelRow"
                      autocomplete="off"
                    />
                  </th>
                  <th>
                    <input
                      v-myMask="{
                        alias: 'numeric',
                        max: 31,
                        digits: 0,
                        allowMinus: false,
                        rightAlign: false,
                      }"
                      class="generalTextBox miniTextBox"
                      type="text"
                      v-model="dayGridTo"
                      v-on:keydown.enter.prevent="dayGridSave"
                      v-on:keydown.esc.prevent="dayGridCancelRow"
                      autocomplete="off"
                    />
                  </th>
                  <th>
                    <i
                      style="color: #1abc9c; font-size: 17px; cursor: pointer"
                      class="ri-checkbox-circle-line"
                      @click="dayGridSave"
                    ></i>
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <span
                    v-if="!isDayGridNewRowActive"
                    style="cursor: pointer; margin: 5px; padding: 5px"
                    class="badge badge-success badgeStatusPadding"
                    @click="addNewDayGridData"
                    >Add</span
                  >
                </tr>
                <tr v-for="(item, index) in dayGridData" :key="item.Id">
                  <th class="text-center" style="padding: 5px">
                    <label class="generalLabel">{{ index + 1 }}</label>
                  </th>
                  <th class="text-center" style="padding: 5px">
                    <label class="generalLabel">{{ item.DayFrom }}</label>
                  </th>
                  <th class="text-center" style="padding: 5px">
                    <label class="generalLabel">{{ item.DayTo }}</label>
                  </th>
                  <th class="text-center">
                    <i
                      style="color: red; font-size: 17px; cursor: pointer"
                      class="ri-close-circle-line"
                      @click="removeDayGridItem(item.Id)"
                    ></i>
                  </th>
                </tr>
              </tbody>
            </table>
          </perfect-scrollbar>
        </div>
        <div class="mt-2" style="display: flex; align-items: baseline">
          <div>
            <input
              name="feeOption"
              type="radio"
              :checked="feeDayOption == 4"
              @change="changeFeeDayOption(4)"
              class="checkbox-input generalCheckBox"
            />
          </div>
          <label for="pullFeeForward" class="generalLabel"
            >Change to the next day of week:</label
          >
        </div>
        <div
          class="mt-2"
          style="display: flex; align-items: baseline"
          v-if="feeDayOption == 4"
        >
          <label class="generalLabel">Day of Week:</label>

          <select
            style="width: 200px"
            class="form-control ml-2"
            v-model="nextDayOfWeek"
          >
            <option :value="null">Please Select</option>
            <option
              v-for="item in daysOfWeekList"
              :value="item.Id"
              :key="item.Id"
            >
              {{ item.Name }}
            </option>
          </select>
        </div>
        <div class="mt-2" style="display: flex; align-items: baseline">
          <div>
            <input
              name="feeOption"
              type="radio"
              :checked="feeDayOption == 5"
              @change="changeFeeDayOption(5)"
              class="checkbox-input generalCheckBox"
            />
          </div>
          <label for="pullFeeForward" class="generalLabel"
            >Change to the previous day of week:</label
          >
        </div>
        <div
          class="mt-2"
          style="display: flex; align-items: baseline"
          v-if="feeDayOption == 5"
        >
          <label class="generalLabel">Day of Week:</label>

          <select
            style="width: 200px"
            class="form-control ml-2"
            v-model="prevDayOfWeek"
          >
            <option :value="null">Please Select</option>
            <option
              v-for="item in daysOfWeekList"
              :value="item.Id"
              :key="item.Id"
            >
              {{ item.Name }}
            </option>
          </select>
        </div>
        <div
          class="mt-2"
          style="
            display: flex;
            border-top: 1px dashed gray;
            padding-top: 20px;
            align-items: baseline;
          "
        >
          <div>
            <input
              id="pullFeeForward"
              type="checkbox"
              :checked="feeMonthSelected"
              @change="changeMonthsSelect()"
              class="checkbox-input generalCheckBox"
            />
          </div>
          <label for="pullFeeForward" class="generalLabel"
            >Push charge forward (months):</label
          >
          <input
            type="text"
            class="generalTextBox miniTextBox ml-1"
            :disabled="feeMonthSelected == false"
            :class="feeMonthSelected == false ? 'readonlyInput' : ''"
            style="width: 40px"
            v-model="feeMonthCount"
            v-myMask="{
              alias: 'numeric',
              digits: 0,
              allowMinus: false,
              rightAlign: false,
            }"
          />
        </div>
        <div class="mt-1" style="display: flex; align-items: baseline">
          <div>
            <input
              type="checkbox"
              :checked="feeAmountSelected"
              @change="changeFeeAmountSelect()"
              class="checkbox-input generalCheckBox"
            />
          </div>
          <label for="pullFeeForward" class="generalLabel"
            >Change charge amount:</label
          >
          <input
            type="text"
            :disabled="feeAmountSelected == false"
            :class="feeAmountSelected == false ? 'readonlyInput' : ''"
            class="generalTextBox miniTextBox ml-1"
            style="width: 100px"
            v-model="feeAmount"
            v-myMask="{
              alias: 'currency',
              prefix: '$',
              min: 0,
              allowMinus: false,
              rightAlign: false,
            }"
          />
        </div>
        <!-- <div
          v-if="isDpg == false"
          class="mt-1"
          style="display: flex; align-items: baseline"
        >
          <div>
            <input
              type="checkbox"
              :checked="feeTypeSelected"
              @change="changeFeeTypeSelect()"
              class="checkbox-input generalCheckBox"
            />
          </div>
          <label for="pullFeeForward" class="generalLabel"
            >Change fee type:</label
          >
          <select
            v-model="feeType"
            :disabled="feeTypeSelected == false"
            :class="feeTypeSelected == false ? 'readonlyInput' : ''"
            class="form-control ml-1"
            style="width: 150px"
          >
            <option value="">Please Select</option>
            <option
              v-for="item in feesTypesList"
              :value="item.FeeType"
              :key="item.FeeType"
            >
              {{ item.Description }}
            </option>
          </select>
        </div> -->

        <div
          class="mt-4"
          v-if="
            editFeeDaysChecked ||
            feeMonthSelected ||
            feeAmountSelected ||
            feeTypeSelected
          "
        >
          <button @click="applyChanges()" class="btn btn-primary">
            Apply Changes and Show Preview
          </button>
        </div>

        <!-- Ikinci alan -->

        <div class="mt-4">
          <label class="generalLabel" style="text-decoration: underline"
            >Preview</label
          >
          <div class="mt-2" style="width: 100%">
            <perfect-scrollbar>
              <table class="datagrid-table">
                <thead>
                  <tr>
                    <th>
                      <input
                        id="pullFeeForward"
                        type="checkbox"
                        class="checkbox-input"
                        :checked="selectAllCharges"
                        @change="selectAllChargeChanges()"
                      />
                    </th>
                    <th class="text-left" style="padding: 10px">
                      Effective Date
                    </th>
                    <th class="text-left" style="padding: 10px">Amount</th>
                    <th class="text-left" style="padding: 10px">
                      Transaction Class/Type
                    </th>
                    <th class="text-left" style="padding: 10px">
                      New Effective Date
                    </th>
                    <th class="text-left" style="padding: 10px">New Amount</th>
                    <th class="text-left" style="padding: 10px">Delete</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td style="padding: 5px 0 !important">
                      <span
                        title="Add new fee"
                        style="cursor: pointer; margin: 5px 10px; padding: 5px"
                        class="badge badge-success badgeStatusPadding"
                        @click="addNewCharge"
                        >Add</span
                      >
                    </td>
                    <td
                      v-if="addedChargeList.length > 0"
                      style="padding: 5px 0 !important"
                    >
                      <span
                        title="Cancel added fee"
                        style="cursor: pointer; float: left"
                        class="badge badge-danger badgeStatusPadding"
                        @click="cancelBulkCharges"
                        >Cancel added charges</span
                      >
                    </td>
                    <td :colspan="addedChargeList.length > 0 ? 4 : 5" style="text-align: right; " >
                    </td>
                    <td style="text-align: center;">
                      <input
                        type="checkbox"
                        class="checkbox-input"
                        v-model="isAllDeleted"
                        @change="deleteSelected"
                      />
                     

                    </td>
                  </tr>
                  <tr v-for="item in chargeList" :key="item.ChargeId">
                    <td class="text-center" style="padding: 5px">
                      <input
                        id="pullFeeForward"
                        type="checkbox"
                        class="checkbox-input"
                        :checked="item.isEditing"
                        @change="selectFee(item)"
                      />
                    </td>
                    <td class="text-left" style="padding: 5px 10px">
                      <label class="generalLabel">{{
                        formatShortDate(item.EffectiveDate)
                      }}</label>
                    </td>
                    <td class="text-left" style="padding: 5px 10px">
                      <label class="generalLabel">{{
                        formatMoney(item.Amount)
                      }}</label>
                    </td>

                    <td class="text-center" style="padding: 5px 10px">
                      <label class="generalLabel"
                        >{{ item.TransactionCombined }}
                      </label>
                    </td>
                    <td class="text-left" style="padding: 5px 10px">
                      <label
                        class="generalLabel"
                        :class="
                          getFeeColorClass(
                            item.NewDate,
                            item.EffectiveDate,
                            item.isEditing
                          )
                        "
                        >{{ formatShortDate(item.NewDate) }}</label
                      >
                    </td>
                    <td class="text-left" style="padding: 5px 10px">
                      <label
                        class="generalLabel"
                        :class="
                          getFeeDateColorClass(
                            item.NewAmount,
                            item.Amount,
                            item.isEditing
                          )
                        "
                        >{{ formatMoney(item.NewAmount) }}</label
                      >
                    </td>

                    <td class="text-center" style="padding: 5px">
                      <input
                        type="checkbox"
                        :disabled="!item.isEditing"
                        class="checkbox-input"
                        :checked="item.isDeleting"
                        @change="deleteCharge(item)"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </perfect-scrollbar>
          </div>

          <div class="col-md-6 noPadding mt-3">
            <label class="generalLabel" style="text-decoration: underline"
              >Notes:</label
            >
            <textarea
              v-model="feeNotes"
              placeholder="Notes here..."
              class="form-control textareaInput generalTextBox"
              rows="4"
            ></textarea>
          </div>
          <div class="col-md-12 noPadding mt-3">
            <div>
              <input
                type="checkbox"
                :checked="isEmergency"
                @change="changeEmergency()"
                class="checkbox-input generalCheckBox"
              />
              <label class="generalLabel" style="margin-right: 27px"
                >Emergency (2 B.days):</label
              >
            </div>
          </div>
          <div v-if="checkAuth(54983) && isDpg == false" class="col-md-12 mt-4">
            <div class="row">
              <label class="generalLabel" style="text-decoration: underline"
                >Related Fees:</label
              >
            </div>
            <div class="row">
              <div class="col-md-4 noPadding">
                <label class="generalLabel">Fee Type</label>
                <select class="form-control" v-model="extraFees.GlobalFeeType">
                  <option :value="null">Please Select</option>
                  <option
                    v-for="item in feesTypesList"
                    :value="item.FeeType"
                    :key="item.FeeType"
                  >
                    {{ item.Description }}
                  </option>
                </select>
              </div>
              <div class="col-md-3">
                <label for="serviceFee" class="generalLabel">Amount</label>
                <input
                  v-model="extraFees.FeeAmount"
                  class="form-control"
                  v-myMask="{
                    alias: 'currency',
                    prefix: '$',
                    rightAlign: false,
                  }"
                  placeholder="$0.00"
                />
              </div>
              <div class="col-md-3">
                <label for="serviceFee" class="generalLabel">Date</label>

                <DatePicker
                  mode="date"
                  :locale="'en-US'"
                  v-model="extraFees.FeeDate"
                  :model-config="modelConfig"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      id="DCP-feeDate"
                      class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                      :value="inputValue"
                      v-on="inputEvents"
                      placeholder="Select Date"
                    />
                  </template>
                </DatePicker>
              </div>
              <div
                v-showIfAuthorized="54983"
                class="col-md-1"
                style="padding-top: 28px"
              >
                <button
                  @click="addFees()"
                  type="button"
                  class="btn btn-success waves-effect waves-light"
                >
                  <i class="fas fa-plus-circle"></i>
                </button>
              </div>
            </div>

            <div class="row pt-3">
              <div class="w-100" v-for="(item, index) in applicableFees" :key="index">
                <div style="display: flex; align-items: stretch">
                  <label class="generalLabel pr-2">{{ index + 1 }}.</label>
                  <label class="generalLabel pr-2"
                    >Fee Type:<label class="ml-1" style="color: #6c757d">{{
                      item.GlobalFeeType
                    }}</label></label
                  >
                  <label class="generalLabel pr-2"
                    >Amount:<label class="ml-1" style="color: #6c757d">{{
                      formatMoney(item.FeeAmount)
                    }}</label></label
                  >
                  <label class="generalLabel pr-1"
                    >Amount Date:<label class="ml-1" style="color: #6c757d">{{
                      item.FeeDate
                    }}</label></label
                  >

                  <span
                    style="padding: 5px; cursor: pointer; height: 20px"
                    class="badge badge-danger"
                    @click="deleteExtraFee(index)"
                  >
                    Cancel</span
                  >
                </div>
              </div>
            </div>
          </div>

          <div
            class="col-md-12"
            style="display: flex; justify-content: flex-end"
          >
            <button
              @click="requestChanges()"
              class="btn btn-primary"
              :disabled="isLoading"
            >
              {{
                checkAuth(12411) ? "Request and Approve" : "Request Changes "
              }}
            </button>
          </div>
        </div>
      </div>
    </perfect-scrollbar>
    <div
      class="backgroundLock"
      v-if="isBulkAddChargePopupOpen"
      style="-webkit-box-align: center; align-items: center; display: flex"
    >
      <div
        style="position: relative; margin: 10% auto"
        class="offset-lg-12 col-xl-5 offset-lg-2 col-lg-8 col-md-12 col-sm-12"
      >
        <BulkAddChargePopup
          @closeBulkAddChargePopup="closeBulkAddChargePopup"
          @addBulkCharges="addBulkCharges"
          :isDpg="isDpg"
          :customerId="CustomerId"
          :officeId="OfficeId"
          :policyGroupId="PolicyGroupId"
          :feeTypes="feesTypesList"
        />
      </div>
    </div>
  </div>
</template>

<script>
import types from "./types";
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import deepCopy from "@/helpers/deepCopy";
import _orderby from "lodash";
import { DatePicker } from "v-calendar";
import BulkAddChargePopup from "./BulkAddChargePopup";
export default {
  name: "BulkChargeRequestPopup",
  components: {
    BulkAddChargePopup,
    DatePicker,
  },
  mixins: [formatMixin, utilitiesMixin],
  props: {
    CustomerId: {
      Type: Number,
      default: 0,
    },
    OfficeId: {
      Type: Number,
      default: 0,
    },
    PolicyGroupId: {
      Type: Number,
      default: 0,
    },
    fees: {
      Type: Array,
      default: [],
    },
    isDpg: {
      Type: Boolean,
      default: false,
    },
    feesTypesList: {
      Type: Array,
      default: [],
    },
    cashFlowList: {
      Type: Array,
      default: [],
    },
  },
  data() {
    return {
      isCashFlowNegative:false,
      isLoading: false,
      isLoadingButton: false,
      isBulkAddChargePopupOpen: false,
      chargeList: [],
      chargeListBackup: [],
      appliedList: [],
      addedChargeList: [],
      selectAllCharges: true,

      editFeeDaysChecked: false,
      feeDayOption: 0,
      feeForwardDays: 3,
      feeBackwardDays: -3,
      feeMonthSelected: false,
      feeMonthCount: 1,
      feeAmountSelected: false,
      feeAmount: 0,
      feeTypeSelected: false,
      feeType: "",
      isDayGridNewRowActive: false,
      dayGridFrom: null,
      dayGridTo: null,
      dayGridData: [],
      changesApplied: false,
      isEmergency: false,
      feeNotes: "",
      extraFees: {
        GlobalFeeType: null,
        FeeAmount: 0,
        FeeDate: "",
      },
      applicableFees: [],
      chargeListSelection: [],
      isFeeGridNewRowActive: false,
      newFee: {
        NewDate: "",
        Amount: 0,
        GlobalFeeType: "",
      },
      modelConfig: {
        type: "string",
        mask: "MM/DD/YYYY",
      },
      nextDayOfWeek: null,
      prevDayOfWeek: null,
      daysOfWeekList: [
        { Id: 0, Name: "Sunday" },
        { Id: 1, Name: "Monday" },
        { Id: 2, Name: "Tuesday" },
        { Id: 3, Name: "Wednesday" },
        { Id: 4, Name: "Thursday" },
        { Id: 5, Name: "Friday" },
        { Id: 6, Name: "Saturday" },
      ],
      isAllDeleted:false,
    };
  },
  unmounted() {},
  async mounted() {
    await this.bindCharges();
  },

  methods: {
    calculateCashFlowForBulkCharge() {
      if (this.checkAuth(956782)) {
        if (this.cashFlowList && this.cashFlowList.length > 0) {
          let list = deepCopy(this.cashFlowList);
          for (let item of this.chargeList) {
            let chargeId = parseInt(item.ChargeId);

            if (item.isDeleting == true) {
              //for deleting
              let deletedIndx = list.findIndex(
                (x) => parseInt(x.ReferenceId) == chargeId
              );
              if (deletedIndx > -1) {
                list[deletedIndx].IsDeletedPay = true;
              }
            } else if (item.isEditing == true && chargeId > 0) {
              //for updating
              let updatedIndx = list.findIndex(
                (x) => parseInt(x.ReferenceId) == chargeId
              );
              if (updatedIndx > -1) {
                list[updatedIndx].IsDeletedPay = true;
              }
              list.push({
                Id: "X",
                ReferenceId: chargeId,
                IsPay: true,
                TransactionType: list[updatedIndx].TransactionType,
                TransactionTypeDesc: list[updatedIndx].TransactionTypeDesc,
                Date:
                  item.NewDate == item.OriginalDate
                    ? list[updatedIndx].Date
                    : item.NewDate,
                Amount: parseFloat(item.NewAmount),
                Memo: list[updatedIndx].Memo,
                Balance: 0,
              });
            } else if (item.isEditing == true) {
              //for inserting
              list.push({
                Id: "X",
                ReferenceId: chargeId,
                IsPay: true,
                TransactionType:item.TransactionType,
                TransactionTypeDesc:item.TransactionType,
                Date: item.NewDate,
                Amount: parseFloat(item.NewAmount),
                Memo: "Charge",
                Balance: 0,
              });
            }
          }

          let fees = this.applicableFees;
          if (fees && fees.length > 0) {
            for (let item of fees) {
              let feeAmount = parseFloat(item.FeeAmount);
              let feeDate = new Date(item.FeeDate);
              list.push({
                Id: "X",
                IsPay: true,
                TransactionType: item.GlobalFeeType,
                TransactionTypeDesc: this.getFeeTypeName(item.GlobalFeeType),
                Date: feeDate.toString("MM/dd/yyyy"),
                Amount: feeAmount,
                Memo: this.getFeeTypeName(item.GlobalFeeType),
                Balance: 0,
              });
            }
          }
          this.$emit("calculateCashFlowForBulkCharge", list);
          this.isCashFlowNegative = this.checkCashFlowNegative();
        }
      }
    },
    openCashFlowPanel() {
      this.$emit("openCashFlowPanel");
    },
    checkCashFlowNegative() {
      let rtn = false;
      if (this.$parent) {
        rtn = this.$parent.checkCashFlowNegativeForPopups();
      }
      return rtn;
    },
    deleteSelected(event) {
      if (event && event.currentTarget) {
        let checked = event.currentTarget.checked;

        for (let item of this.chargeList) {
          if (item.isEditing == true) {
            item.isDeleting = checked;
          }
        }
        this.calculateCashFlowForBulkCharge();
      }
    },
    getFeeTypeName(value) {
      let rtn = "";
      if (this.feesTypesList.length > 0 && value != "") {
        let index = this.feesTypesList.findIndex((x) => x.FeeType == value);
        if (index > -1) {
          rtn = this.feesTypesList[index].Description;
        }
      }
      return rtn;
    },
    getFeeTypeKey(value) {
      let rtn = "";
      if (this.feesTypesList.length > 0 && value != "") {
        let index = this.feesTypesList.findIndex((x) => x.Description == value);
        if (index > -1) {
          rtn = this.feesTypesList[index].FeeType;
        }
      }
      return rtn;
    },
    addBulkCharges(list) {
      for (let item of list) {
        this.chargeList.unshift(item);
        this.addedChargeList.push(item);
        this.calculateCashFlowForBulkCharge();
      }
      this.isBulkAddChargePopupOpen = false;
    },
    closeBulkAddChargePopup() {
      this.isBulkAddChargePopupOpen = false;
    },
    async bindCharges() {
      this.isLoading = true;
      let err, result;
      [err, result] = await this.$store.dispatch(types.GET_CHARGE_LIST, {
        CustomerId: this.CustomerId,
        OfficeId: this.OfficeId,
      });
      this.isLoading = false;
      if (result && result.Data) {
        this.chargeList =
          result.Data.length > 0
            ? _orderby.orderBy(
                result.Data.filter((x) => x.Active == "Y").map((x) => {
                  return {
                    EffectiveDate: x.EffectiveDate,
                    ChargeId: x.ChargeId,
                    Amount: x.Amount,
                    TransactionCombined: x.TransactionCombined,
                    isEditing: true,
                    isDeleting: false,
                    NewDate: x.EffectiveDate,
                    NewAmount: x.Amount,
                  };
                }),
                "EffectiveDate",
                "asc"
              )
            : [];
        this.chargeListBackup = deepCopy(this.chargeList);
      }
    },
    async requestChanges() {
      if (this.CustomerId == 0) {
        this.$swal("Warning!", "Invalid customer id", "warning");
        return;
      }

      let selectedCharges = this.chargeList.filter((x) => x.isEditing == true);

      if (selectedCharges.length == 0) {
        this.$swal("Warning!", "No charge selected.", "warning");
        return;
      }

      if (this.feeNotes.trim().length == 0) {
        this.$swal("Warning!", "Notes field is mandatory", "warning");
        return;
      }

      let changeDetails = [];
      for (let i = 0; i < selectedCharges.length; i++) {
        if (
          selectedCharges[i].EffectiveDate != selectedCharges[i].NewDate ||
          selectedCharges[i].Amount != selectedCharges[i].NewAmount ||
          selectedCharges[i].isDeleting
        ) {
          let amount = parseFloat(selectedCharges[i].NewAmount);
          if (selectedCharges[i].isDeleting) {
            amount = 0;
          }

          changeDetails.push({
            OriginalDate: this.formatShortDate(
              selectedCharges[i].EffectiveDate
            ),
            ChargeDate: this.formatShortDate(selectedCharges[i].NewDate),
            TransactionType: selectedCharges[i].TransactionType, 
            ChargeId:
              selectedCharges[i].ChargeId > 0 ? selectedCharges[i].ChargeId : 0,
            ChargeAmount: amount,
          });
        }
      }



      if (changeDetails.length == 0) {
        this.$swal("Warning!", "Nothing to change", "warning");
        return;
      }

      this.$swal
        .fire({
          title: "Confirm",
          text: "Are you sure to submit this request?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!",
        })
        .then(async (dialog) => {
          this.isLoading = true;
          if (dialog.value) {
            let err, result;
            [err, result] = await this.$store.dispatch(
              types.CREATE_CHARGE_CHANGES,
              {
                CustomerId: this.CustomerId,
                ApplicableFees: this.applicableFees,
                Notes: this.feeNotes,
                IsEmergency: this.isEmergency,
                ChangeDetails: changeDetails,
                IsNegativeCashFlow:this.isCashFlowNegative
              }
            );

            if (result) {
              let successMessage = this.checkAuth(12411)
                ? "The requested changes are approved."
                : "Charge changes requested.";
              this.$swal("Success!", successMessage, "success");
              this.$emit("closeBulkChargePopup",this.checkAuth(12411));
            } else {
              let errMsg = this.getApiErrorMessage(err);
              this.$swal("Error!", errMsg, "error");
            }
          }
          this.isLoading = false;
        });
    },
    addNewCharge() {
      this.isBulkAddChargePopupOpen = true;
    },
    cancelBulkCharges() {
      this.addedChargeList = [];
      this.chargeList = this.chargeList.filter((x) => x.ChargeId > 0);
      this.calculateCashFlowForBulkCharge();
    },
    addFees() {
      if (
        this.extraFees.GlobalFeeType != null &&
        this.extraFees.FeeAmount > 0 &&
        this.extraFees.FeeDate != ""
      ) {
        let item = deepCopy(this.extraFees);
        item.FeeDate = new Date(item.FeeDate).toString("M/d/yyyy");
        item.FeeAmount = parseFloat(item.FeeAmount);

        this.applicableFees.push(item);
        this.extraFees.GlobalFeeType = null;
        this.extraFees.FeeAmount = 0;
        this.extraFees.FeeDate = "";
        this.calculateCashFlowForBulkCharge();
      } else {
        this.$swal(
          "Warning!",
          "Fee type, amount and date fields are required",
          "warning"
        );
      }
    },
    deleteCharge(item) {
      item.isDeleting = !item.isDeleting;
      this.calculateCashFlowForBulkCharge();
    },
    deleteExtraFee(indx) {
      if (indx > -1) {
        this.applicableFees.splice(indx, 1);
        this.calculateCashFlowForBulkCharge();
      }
    },
    changeEmergency() {
      this.isEmergency = !this.isEmergency;
    },
    selectAllChargeChanges() {
      this.selectAllCharges = !this.selectAllCharges;
      for (let item of this.chargeList) {
        item.isEditing = this.selectAllCharges;
        item.isDeleting = this.isAllDeleted;
        this.updateFeeData(item);
        this.calculateCashFlowForBulkCharge();
      }
    },
    selectFee(item) {
      item.isEditing = !item.isEditing;
      item.isDeleting = this.isAllDeleted;
      this.updateFeeData(item);
      this.calculateCashFlowForBulkCharge();
    },
    updateFeeData(item) {
      if (item.isEditing == false) {
        let indx = this.chargeListBackup.findIndex(
          (x) => x.ChargeId == item.ChargeId
        );
        if (indx > -1) {
          item.NewDate = this.chargeListBackup[indx].EffectiveDate;
          item.NewAmount = this.chargeListBackup[indx].Amount;
        }
      } else {
        let indx = this.appliedList.findIndex((x) => x.ChargeId == item.ChargeId);
        if (indx > -1) {
          item.NewDate = this.appliedList[indx].NewDate;
          item.NewAmount = this.appliedList[indx].NewAmount;
        }
      }
    },

    getFeeDateColorClass(item1, item2, isEditing) {
      let rtn = "";
      if (
        this.formatShortDate(item1) != this.formatShortDate(item2) &&
        isEditing
      ) {
        rtn = "selectedDraft";
      }
      return rtn;
    },
    getFeeColorClass(item1, item2, isEditing) {
      let rtn = "";
      if (item1 != item2 && isEditing) {
        rtn = "selectedDraft";
      }

      return rtn;
    },
    getSelection(item, index) {
      let indx = this.chargeListSelection.findIndex(
        (x) => x.ChargeId == item.ChargeId
      );
      if (indx > -1) {
        if (index > -1) {
          this.chargeList[index].isEditing =
            this.chargeListSelection[indx].isEditing;
          this.chargeList[index].isDeleting =
            this.chargeListSelection[indx].isDeleting;
        }
      }
    },
    applyChanges() {
      this.chargeListSelection = this.chargeList.map((x) => {
        return {
          ChargeId: x.ChargeId,
          isEditing: x.isEditing,
          isDeleting: x.isDeleting,
        };
      });
      this.chargeList = deepCopy(this.chargeListBackup);
      for (let item of this.chargeList) {
        this.getSelection(item, this.chargeList.indexOf(item));
      }

      for (let chargeItem of this.chargeList) {
        let forwardDays = parseInt(this.feeForwardDays);
        let backwardDays = parseInt(this.feeBackwardDays);
        if (this.feeDayOption == 1 && forwardDays > 0) {
          chargeItem.NewDate = this.formatShortDate(
            this.addDays(chargeItem.NewDate, forwardDays)
          );
        } else if (this.feeDayOption == 2 && backwardDays < 0) {
          chargeItem.NewDate = this.formatShortDate(
            this.addDays(chargeItem.NewDate, backwardDays)
          );
        } else if (this.feeDayOption == 3 && this.dayGridData.length > 0) {
          let feeDay = new Date(chargeItem.NewDate).getDate();

          for (let dayItem of this.dayGridData) {
            if (parseInt(dayItem.DayFrom) == parseInt(feeDay)) {
              let feeDate = new Date(chargeItem.NewDate);

              let maxDayCount = new Date(
                feeDate.getFullYear(),
                feeDate.getMonth() + 1,
                0
              ).getDate();

              let day = parseInt(dayItem.DayTo);
              if (day > maxDayCount) {
                day = maxDayCount;
              }

              let newDate = new Date(
                feeDate.getFullYear(),
                feeDate.getMonth(),
                day
              );
              while (newDate.getMonth() != feeDate.getMonth()) {
                newDate = this.addDays(newDate, -1);
              }

              chargeItem.NewDate = this.formatShortDate(newDate);
            }
          }
        } else if (this.feeDayOption == 4) {
          if (this.nextDayOfWeek != null) {
            let currentDay = new Date(chargeItem.NewDate).getDay();

            if (currentDay == this.nextDayOfWeek) {
              chargeItem.NewDate = this.addDays(chargeItem.NewDate, 7);
            } else if (currentDay < this.nextDayOfWeek) {
              chargeItem.NewDate = this.addDays(
                chargeItem.NewDate,
                parseInt(this.nextDayOfWeek - currentDay)
              );
            } else {
              chargeItem.NewDate = this.addDays(
                chargeItem.NewDate,
                7 - parseInt(currentDay - this.nextDayOfWeek)
              );
            }
            chargeItem.NewDate = this.formatShortDate(chargeItem.NewDate);
          } else {
            this.$swal("Warning!", "Please select a day.", "warning");
          }
        } else if (this.feeDayOption == 5) {
          if (this.prevDayOfWeek != null) {
            let currentDay = new Date(chargeItem.NewDate).getDay();

            if (currentDay == this.prevDayOfWeek) {
              chargeItem.NewDate = this.addDays(chargeItem.NewDate, -7);
            } else if (currentDay < this.prevDayOfWeek) {
              let diff = parseInt(this.prevDayOfWeek - currentDay);
              chargeItem.NewDate = this.addDays(
                chargeItem.NewDate,
                parseInt(7 - diff) * -1
              );
            } else {
              chargeItem.NewDate = this.addDays(
                chargeItem.NewDate,
                parseInt(currentDay - this.prevDayOfWeek) * -1
              );
            }
            chargeItem.NewDate = this.formatShortDate(chargeItem.NewDate);
          } else {
            this.$swal("Warning!", "Please select a day.", "warning");
          }
        }

        let feeMonths = parseInt(this.feeMonthCount);
        if (this.feeMonthSelected == true && feeMonths > 0) {
          chargeItem.NewDate = this.formatShortDate(
            this.addMonthsFix(new Date(chargeItem.NewDate), feeMonths)
          );
        }

        if (this.feeAmount > 0 && this.feeAmount != chargeItem.Amount) {
          chargeItem.NewAmount = this.feeAmount;
        }
      }

      this.changesApplied = true;
      this.appliedList = deepCopy(this.chargeList);
      for (let item of this.chargeList.filter((x) => x.isEditing == false)) {
        this.updateFeeData(item);
      }
      for (let item of this.addedChargeList) {
        this.chargeList.unshift(item);
      }
      this.calculateCashFlowForBulkCharge();
    },
    feeGridCancelRow() {
      this.isFeeGridNewRowActive = false;
    },

    dayGridCancelRow() {
      this.dayGridFrom = null;
      this.dayGridTo = null;
      this.isDayGridNewRowActive = false;
    },
    addNewDayGridData() {
      this.isDayGridNewRowActive = true;
    },
    dayGridSave() {
      this.dayGridFrom = parseInt(this.dayGridFrom);
      this.dayGridTo = parseInt(this.dayGridTo);

      if (this.dayGridFrom > 0 && this.dayGridTo > 0) {
        let indx = this.dayGridData.findIndex(
          (x) => x.DayFrom == this.dayGridFrom
        );
        if (indx == -1) {
          let latestItem = _orderby.orderBy(this.dayGridData, "Id", "desc");
          let latestId =
            latestItem && latestItem.length > 0 ? latestItem[0].Id + 1 : 0;

          this.dayGridData.push({
            Id: latestId,
            DayFrom: this.dayGridFrom,
            DayTo: this.dayGridTo,
          });
          this.dayGridCancelRow();
        } else {
          this.$swal(
            "Warning!",
            "Another setting already exists in grid for this day.",
            "warning"
          );
        }
      } else {
        this.$swal("Warning!", "Day parameters invalid.", "warning");
      }
    },
    removeDayGridItem(id) {
      let indx = this.dayGridData.findIndex((x) => x.Id == id);
      if (indx > -1) {
        this.dayGridData.splice(indx, 1);
      }
    },
    changeFeeDayOption(value) {
      this.feeDayOption = parseInt(value);
      this.editFeeDaysChecked = true;
      if (this.feeDayOption != 4) {
        this.nextDayOfWeek = null;
      }
      if (this.feeDayOption != 5) {
        this.prevDayOfWeek = null;
      }
    },
    changeEditFeeDays() {
      this.editFeeDaysChecked = !this.editFeeDaysChecked;
      if (this.editFeeDaysChecked == false) {
        this.feeDayOption = 0;
        this.dayGridData = [];
      }
    },
    changeMonthsSelect() {
      this.feeMonthSelected = !this.feeMonthSelected;
      if (this.feeMonthSelected == false) {
        this.feeMonthCount = 1;
      }
    },
    changeFeeAmountSelect() {
      this.feeAmountSelected = !this.feeAmountSelected;
      if (this.feeAmountSelected == false) {
        this.feeAmount = 0;
      }
    },
    changeFeeTypeSelect() {
      this.feeTypeSelected = !this.feeTypeSelected;
      if (this.feeTypeSelected == false) {
        this.feeType = "";
      }
    },
    closePanel() {
      this.$emit("closeBulkChargePopup",false);
    },
  },
};
</script>

<style>
.closeButtonBulk {
  z-index: 1000;
  font-size: 30px;
  cursor: pointer;
}
.closeButtonBulk:hover {
  color: #ff3860;
}
.selectedDraft {
  color: #24d15f !important;
}
.editedDraft {
  color: #000 !important;
}
.miniTextBox {
  padding: 2px 4px !important;
  line-height: 13px !important;
  height: 28px !important;
}
</style>
